import { Component, Inject } from '@angular/core';
import { DisplayType } from "../../../../../../common/model/display";
import { WorkQueue } from "../../../../../../common/model/work/queue";
import { DateRange } from "../../../../../../common/toolbox/time";
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AccountService } from '../../../common/service/account.service';
import { AuthService } from '../../../common/service/auth.service';
import { Stat, StatType } from '../../../common/toolbox/stat';
import { CollectionsAgentData } from './collections-agent.model';

/** List of stats to display for collections agent. */
const COLLECTOR_STATS = [
  StatType.CallsOutbound,
  StatType.AccountsTotalWorked,
  StatType.Promises,
  StatType.LettersProduced,
  StatType.EmailsSent,
  StatType.TextsSent
];

@Component({
  selector: 'app-collections-agent',
  templateUrl: './collections-agent.component.html',
  styleUrls: ['./collections-agent.component.scss'],
  host: {
    class: 'column fill'
  }
})
export class CollectionsAgentComponent {
  readonly DisplayType = DisplayType;

  /** Displayed date range of stats. */
  range = new DateRange();
  /** List of stats to display. */
  stats = Stat.random(COLLECTOR_STATS);

  constructor(
    @Inject(TAB_DATA)
    public data: CollectionsAgentData,
    public accounts: AccountService,
    public auth: AuthService
  ) {}

  /** Default title for tab. */
  static title() {
    return 'Collector Activity';
  }

  /** Callback when selecting a queue. */
  onQueue(queue: WorkQueue) {
    this.accounts.queue(queue);
  }

  /** Callback when selecting new date range. */
  onRange(range: DateRange) {
    this.range = range;
    this.stats = Stat.random(COLLECTOR_STATS);
  }
}