import { Component, Injector, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TabBarComponent } from '../../tab/bar/tab-bar.component';
import { TabComponent } from '../../tab/tab.component';
import { PanelComponent } from '../panel.component';

@Component({
  selector: 'app-panel-list',
  templateUrl: './panel-list.component.html',
  styleUrls: ['./panel-list.component.scss'],
  host: {
    class: 'row'
  }
})
export class PanelListComponent extends TabBarComponent<PanelComponent> {
  /** True to show navigation on side. */
  @Input() navigation = true;
  
  /** Listener for onscreen elements. */
  observer: IntersectionObserver
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    injector: Injector
  ) {
    super(injector);
    this.observer = new IntersectionObserver(entries => {
      for (let entry of entries) {
        let tab = this.tabs.find(tab => tab.element.nativeElement === entry.target);
        if (tab) tab.onscreen = entry.isIntersecting;
      }
    }, { root: null, threshold: 0 });

    this.added.pipe(takeUntil(this.destroy)).subscribe(tab => this.observer.observe(tab.element.nativeElement));
    this.removed.pipe(takeUntil(this.destroy)).subscribe(tab => this.observer.unobserve(tab.element.nativeElement));
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.observer.disconnect();
  }

  /** Scroll to specified tab. */
  scroll($event: MouseEvent, tab: TabComponent) {
    $event.preventDefault();
    tab.element.nativeElement.scrollIntoView();
  }
}
