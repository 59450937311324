<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header class="inputs">
      <app-field>
        <app-label>Name</app-label>
        <app-string [(ngModel)]="value.name"></app-string>
      </app-field>
      <app-field>
        <app-label>Model</app-label>
        <app-resource-select collection="models" [required]="false" [(ngModel)]="value._model" (selected)="onModel($event)"></app-resource-select>
      </app-field>
      <app-field>
        <app-label>Highlight Formula</app-label>
        <app-resource-select autoselect="false" collection="formulas" [(ngModel)]="value._highlightFormula" [required]="false"></app-resource-select>
      </app-field>
    </app-setup-base-header>
    <app-setup-base-body>
      <div class="list" [list-grid]="value.columns">
        <app-drag-chip cdkDrag cdkDragBoundary=".list" *ngFor="let c of value.columns; let i = index">
          <div class="item">
            <app-field full>
              <app-label>Key</app-label>
              <app-select compact [(ngModel)]="c.key">
                <app-option *ngFor="let p of pairs[i]" [pair]="p" (click)="refresh()"></app-option>
              </app-select>
            </app-field>
            <app-field full>
              <app-label>Name</app-label>
              <app-string [(ngModel)]="c.name" [required]="false" [placeholder]="c.key | display:undefined:map[c.key] | async"></app-string>
            </app-field>
            <app-field full>
              <app-label>Size</app-label>
              <app-sublabel>
                <app-hover-help [tooltip]="COLUMN_UNIT_HELP"></app-hover-help>
              </app-sublabel>
              <app-column-size [(ngModel)]="c.size" [required]="false"></app-column-size>
            </app-field>
            <div class="buttons">
              <button icon-button [toggle-button]="c.wrap" [toggle-icon]="['horizontal_rule', 'wrap_text', '']" (click)="c.wrap = !c.wrap" tooltip="Wrap Text"></button>
              <button icon-button (click)="onDelete(i)" tooltip="Delete">delete</button>
              <button icon-button cdkDragHandle>drag_indicator</button>
            </div>
          </div>
        </app-drag-chip>
        <button button-prefix="add" position="before" (click)="onNew()">New</button>
      </div>
    </app-setup-base-body>
    <app-setup-base-footer>
      <button (click)="onSubmit()">Save</button>
      <button *ngIf="dev.on" (click)="onLog()">Log</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>