import { DocumentTemplateType } from "../code/standard/common";
import { TypeInfo } from "../info/type";
import { DocumentTemplate, DocumentTemplateFile, DocumentTemplatePreview, DocumentTemplateBundle } from "../model/document-template/base";
import { DocumentTemplateClass } from "../model/document-template/data";
import { BufferLike, BufferLikeValidator } from "../toolbox/binary";
import { ID_DEFAULT, MaybeId, idOmit } from "../toolbox/id";
import { PartialDeep } from "../toolbox/object";
import { ItemRequest } from "./item";
import { PaginateRequest } from "./paginate";
import { SuccessResponse } from "./success";

/** Get list of document templates matching query. */
export class DocumentTemplateGetRequest extends PaginateRequest<DocumentTemplate> {
  constructor(
    /** Institutions owning document templates. */
    public _insts: string[] = [],
    /** Unique identifiers of document templates. */
    public _ids?: string[],
    /** Types of document templates to filter. */
    public types?: DocumentTemplateType[],
    /** Configured name of document template. */
    public name?: string,
    /** Names of document templates. */
    public names?: string[]
  ) {
    super();
  }

  static typeinfo: TypeInfo<DocumentTemplateGetRequest> = {
    ...PaginateRequest.typeinfoFor(new DocumentTemplate()),
    _insts: [ID_DEFAULT, ID_DEFAULT],
    _ids: [ID_DEFAULT, ID_DEFAULT],
    types: [DocumentTemplateType.General, DocumentTemplateType.General],
    name: '',
    names: ['']
  }
}

/** List of document templates fetched from backend. */
export type DocumentTemplateGetResponse = DocumentTemplateFile[];

/** Get a list of document template previews matching query. */
export class DocumentTemplatePreviewGetRequest extends DocumentTemplateGetRequest {};

/** Get a list of document template previews matching query. */
export type DocumentTemplatePreviewGetResponse = DocumentTemplatePreview[];

/** Upload a new document template to system. */
export class DocumentTemplatePostRequest {
  constructor(
    /** List of document templates to upload. */
    public items: DocumentTemplateBundle[] = [],
    /** Override the template validation scan results during upload */
    public override?: boolean
  ) {}

  static typeinfo: TypeInfo<DocumentTemplatePostRequest> = {
    items: [new DocumentTemplateBundle()],
    override: false
  }
}

/** Result of uploading document templates. */
export type DocumentTemplatePostResponse = SuccessResponse;

/** Request the specified document template to build. */
export class DocumentTemplateBuildPostRequest<T extends DocumentTemplateType = DocumentTemplateType> extends DocumentTemplateGetRequest {
  constructor(
    /** Override for template. */
    public template?: MaybeId<DocumentTemplate<T>>,
    /** Override for template file. */
    public buffer?: BufferLike,
    /** Output filename. */
    public output = '',
    /** Data for document template. */
    public data = {} as PartialDeep<DocumentTemplateClass[T]>
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DocumentTemplateBuildPostRequest> = {
    ...DocumentTemplateGetRequest.typeinfo,
    template: idOmit(new DocumentTemplate()),
    buffer: new BufferLikeValidator()
  }
}

/** Result of building document templates. */
export type DocumentTemplateBuildPostResponse = BufferLike;

/** Delete document template templates from institution */
export class DocumentTemplateDeleteRequest extends ItemRequest {}

/** Result of getting jobs. */
export type DocumentTemplateDeleteResponse = SuccessResponse;