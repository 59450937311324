import { Component, Inject } from '@angular/core';
import { ClaimDetailsType, ClaimReportReason, ClaimStatus, ClaimType } from '../../../../../../common/code/standard/disputes';
import { DisputesCode, SystemCode } from '../../../../../../common/code/system';
import { CLAIM_TYPE_DETAILS_FILTER } from '../../../../../../common/model/claim/claim';
import { DisplayType } from "../../../../../../common/model/display";
import { Permission } from '../../../../../../common/model/permission';
import { QueryOperator } from '../../../../../../common/model/query';
import { claimDetailsCode } from '../../../../../../common/toolbox/claim';
import { ID_DEFAULT } from '../../../../../../common/toolbox/id';
import { DateRange, dateOffset } from '../../../../../../common/toolbox/time';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { DisputesAgentData } from './disputes-agent.model';

@Component({
  selector: 'app-disputes-agent',
  templateUrl: './disputes-agent.component.html',
  styleUrls: ['./disputes-agent.component.scss']
})
export class DisputesAgentComponent {
  readonly ID_DEFAULT = ID_DEFAULT;
  readonly ClaimStatus = ClaimStatus;
  readonly DisplayType = DisplayType;
  readonly DisputesCode = DisputesCode;
  readonly Permission = Permission;
  readonly QueryOperator = QueryOperator;

  /** Displayed date range of disputes. */
  range = new DateRange(new Date(), dateOffset(new Date(), 6));
  /** Claim details filter. */
  details?: ClaimDetailsType;
  /** Claim type filter */
  type?: ClaimType;
  /** Claim reported date filter */
  reportDate?: number;

  /** Code category for current reason. */
  category?: SystemCode;
  /** Filter for current reason. */
  filter?: ClaimDetailsType[] | ClaimReportReason[];

  /** Default title for tab. */
  static title() {
    return 'Dispute Agent Dashboard';
  }

  constructor(
    @Inject(TAB_DATA)
    public data: DisputesAgentData,
    public auth: AuthService
  ) { }

  /** Callback when changing filtered claim type. */
  onType(type?: ClaimType) {
    this.category = claimDetailsCode(type);
    this.filter = type ? CLAIM_TYPE_DETAILS_FILTER[type] : undefined;
  }
}
