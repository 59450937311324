import { AccountCategory } from "../../code/standard/common";
import { ImportCode } from "../../code/system";
import { IndexInfo } from "../../info";
import { PropertyInfo } from "../../info/prop";
import { SchemaInfo } from "../../info/schema";
import { TypeInfo } from "../../info/type";
import { CURRENCY_DEFAULT, currency } from "../../toolbox/currency";
import { ID_DEFAULT } from "../../toolbox/id";
import { Card } from "../card";
import { CommentData } from "../comment/comment";
import { Member } from "../member";
import { PropertyType } from "../property-type";
import { SchemaIndex } from "../schema";
import { SCHEMA_LOOKUP } from "../schema/base";

/** A host-created comment attached to an account. */
export class AccountComment {
  constructor(
    /** Date comment was written. */
    public date = new Date(),
    /** Institution-provided unique identifier of comment. */
    public id = '',
    /** Content of comment. */
    public text = '',
    /** Host-defined code for comment type. */
    public type = ''
  ) {}

  static propinfo: PropertyInfo<AccountComment> = {
    type: { type: PropertyType.Code, category: ImportCode.AccountComment }
  }
}

/** Base fields shared by all accounts. */
export class AccountBase {
  constructor(
    /** Unique identifier of account. */
    public _id = ID_DEFAULT,
    /** Institution of account. */
    public _inst = ID_DEFAULT,
    /** Access level for account. */
    public access?: string,
    /** Current balance of account. */
    public balance?: currency,
    /** Branch servicing account. */
    public branch?: string,
    /** Closed date for account. */
    public closeDate?: Date,
    /** Comment attached to loan or share. */
    public comment?: string,
    /** Host-defined list of comments attached to main account. */
    public accountComments?: AccountComment[],
    /** Amount account was charged off for. */
    public chargeOffAmount?: currency,
    /** Date account was charged off. */
    public chargeOffDate?: Date,
    /** Host-provided charge-off code, if account was charged off. */
    public chargeOffType?: string,
    /** Department servicing this account. */
    public department?: string,
    /** Host-provided description of account. */
    public description?: string,
    /** Host-provided unique identifier for share or loan. */
    public subAccount = '',
    /** Date of last transaction. */
    public lastTransactionDate?: Date,
    /** Date when final payment is due. */
    public maturityDate?: Date,
    /** Host-provided identifier for account. */
    public number = '',
    /** Original balance account was borrowed for. */
    public originalBalance?: currency,
    /** Original date account was opened or issued. */
    public openDate?: Date,
    /** True if this is test data. */
    public test?: boolean,
    /** Host-provided type code of this account. */
    public type?: string,
    /** List of warnings on this top-level account. */
    public warningCodes?: string[],

    // APPLICATION-DEFINED FIELDS

    /** True if this is an application-defined account that was not pulled from the core. */
    public application?: boolean,
    /** Account-level attachments. */
    public _attachments?: string[],
    /** Application-defined date this account was viewed. */
    public viewDate?: Date,
    /** Application-defined date this account was worked. */
    public workDate?: Date,
    /** Comments on this account. */
    public comments: undefined | CommentData[] = undefined,

    // DATABASE LOOKUPS ONLY

    /** Relationship of this account to currently displayed member. */
    public relationship?: string
  ) {}

  static typeinfo: TypeInfo<AccountBase> = {

    // Base fields

    access: '',
    balance: CURRENCY_DEFAULT,
    branch: '',
    closeDate: new Date(),
    comment: '',
    accountComments: [new AccountComment()],
    chargeOffAmount: CURRENCY_DEFAULT,
    chargeOffDate: new Date(),
    chargeOffType: '',
    description: '',
    department: '',
    subAccount: '',
    lastTransactionDate: new Date(),
    maturityDate: new Date(),
    originalBalance: CURRENCY_DEFAULT,
    number: '',
    openDate: new Date(),
    test: false,
    type: '',
    warningCodes: [''],

    // Application-defined fields

    application: false,
    _attachments: [ID_DEFAULT],
    viewDate: new Date(),
    workDate: new Date(),
    comments: [new CommentData()],

    // Lookup fields

    relationship: ''
  }

  static propinfo: PropertyInfo<AccountBase> = {
    access: { type: PropertyType.Code, category: ImportCode.AccessStatus },
    balance: { type: PropertyType.Currency },
    chargeOffType: { type: PropertyType.Code, category: ImportCode.ChargeOff },
    originalBalance: { type: PropertyType.Currency },
    relationship: { type: PropertyType.Code, category: ImportCode.AccountMember },
    type: { type: PropertyType.Code, category: ImportCode.AccountType },
    warningCodes: { type: PropertyType.Code, category: ImportCode.AccountWarning }
  };

  static schemainfo: SchemaInfo<AccountBase> = {
    relationship: SCHEMA_LOOKUP
  };

  static indexinfo: IndexInfo<AccountBase> = [
    { key: { _inst: 1, number: 1, category: 1, subAccount: 1 }, unique: true } as SchemaIndex<AccountBase>
  ];
}

/** Unique fields of an account for lookup. */
export class AccountReference {
  constructor(
    /** Institution of account. */
    public _inst = ID_DEFAULT,
    /** Host-provided identifier for account. */
    public number = '',
    /** Category of account. */
    public category = AccountCategory.Loan,
    /** Host-provided unique identifier for share or loan. */
    public subAccount = ''
  ) {}
}

/** Preview for an account. */
export class AccountPreview {
  constructor(
    /** Unique identifier of account. */
    public _id = ID_DEFAULT,
    /** Host-provided identifier for account. */
    public number = '',
    /** Category of account. */
    public category = AccountCategory.Loan,
    /** Host-provided unique identifier for share or loan. */
    public subAccount = ''
  ) { }
}

/** Additional collections linked to account. */
export class AccountLookup {
  /** List of members owning this account. */
  members: Member[] = [];
  /** List of cards linked to account. */
  cards: Card[] = [];

  static typeinfo: TypeInfo<AccountLookup> = {
    members: [new Member()],
    cards: [new Card()]
  }
}