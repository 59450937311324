import { UnionInfo } from "../../../info/union";
import { EventCategory } from "../category";
import { AttachmentResult } from "./base";

/** Tagged type of DocumentTemplate event. */
export enum DocumentTemplateResultType {
  DocumentTemplateAttachmentAdd,
  DocumentTemplateAttachmentDelete
}

/** Result of performing action on a document template. */
export type DocumentTemplateResult =
  | DocumentTemplateAttachmentAddResult
  | DocumentTemplateAttachmentDeleteResult;

/** Result of attaching an attachment to a DocumentTemplate. */
export class DocumentTemplateAttachmentAddResult extends AttachmentResult {
  readonly category = EventCategory.DocumentTemplate;
  readonly type = DocumentTemplateResultType.DocumentTemplateAttachmentAdd;
}

/** Result of removing attachment from a DocumentTemplate. */
export class DocumentTemplateAttachmentDeleteResult extends AttachmentResult {
  readonly category = EventCategory.DocumentTemplate;
  readonly type = DocumentTemplateResultType.DocumentTemplateAttachmentDelete;
}

export class DocumentTemplateResultClass {
  [DocumentTemplateResultType.DocumentTemplateAttachmentAdd] = new DocumentTemplateAttachmentAddResult();
  [DocumentTemplateResultType.DocumentTemplateAttachmentDelete] = new DocumentTemplateAttachmentDeleteResult();
}

/** Union type information for DocumentTemplate results. */
export const DOCUMENT_TEMPLATE_RESULT_UNIONINFO: UnionInfo<DocumentTemplateResult, DocumentTemplateResultType> = {
  tag: 'type',
  classes: new DocumentTemplateResultClass()
}
