import { OverlayModule } from "@angular/cdk/overlay";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { DialogConfirmationComponent } from "./common/component/dialog/confirmation/dialog-confirmation.component";
import { DialogComponent } from "./common/component/dialog/dialog.component";
import { BootstrapService, initializeApp } from "./common/service/bootstrap.service";
import { AccountModule } from "./module/account/account.module";
import { BuildModule } from "./module/build/build.module";
import { ClaimModule } from "./module/claim/claim.module";
import { CollectionsModule } from "./module/collections/collections.module";
import { DemoModule } from "./module/demo/demo.module";
import { DisputesModule } from "./module/disputes/disputes.module";
import { DocumentationModule } from "./module/documentation/documentation.module";
import { LedgerModule } from "./module/ledger/ledger.module";
import { ActivityComponent } from './module/main/activity/activity.component';
import { DashboardComponent } from "./module/main/dashboard/dashboard.component";
import { DebugButtonComponent } from "./module/main/debug-button/debug-button.component";
import { ErrorComponent } from "./module/main/error/error.component";
import { LoginComponent } from "./module/main/login/login.component";
import { MenuBarComponent } from "./module/main/menu-bar/menu-bar.component";
import { NotFoundComponent } from "./module/main/not-found/not-found.component";
import { ProfileMenuComponent } from "./module/main/profile-menu/profile-menu.component";
import { ResetPasswordComponent } from "./module/main/reset-password/reset-password.component";
import { SetupButtonComponent } from "./module/main/setup-button/setup-button.component";
import { UnsubscribeComponent } from "./module/main/unsubscribed/unsubscribe.component";
import { UserSettingsComponent } from "./module/main/user-settings/user-settings.component";
import { MemberModule } from "./module/member/member.module";
import { SetupModule } from "./module/setup/setup.module";
import { ThemeComponent } from "./module/theme/theme.component";
import { WorkModule } from "./module/work/work.module";
import { AppRoutingModule } from "./routing.module";
import { SharedModule } from "./shared.module";

@NgModule({
  declarations: [
    // Top-level components
    ActivityComponent,
    AppComponent,
    DashboardComponent,
    DebugButtonComponent,
    SetupButtonComponent,
    DialogComponent,
    DialogConfirmationComponent,
    ErrorComponent,
    LoginComponent,
    MenuBarComponent,
    NotFoundComponent,
    ProfileMenuComponent,
    ResetPasswordComponent,
    ThemeComponent,
    UnsubscribeComponent,
    UserSettingsComponent
  ],
  imports: [
    AccountModule,
    AppRoutingModule,
    BrowserModule,
    BuildModule,
    ClaimModule,
    CollectionsModule,
    DemoModule,
    DisputesModule,
    DocumentationModule,
    LedgerModule,
    MemberModule,
    OverlayModule,
    SetupModule,
    SharedModule.forRoot(),
    WorkModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [BootstrapService],
      multi: true
    }
  ],
  bootstrap: [
      AppComponent
  ]
})
export class AppModule { }
