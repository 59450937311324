import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Institution, InstitutionPreview } from '../../../../../common/model/institution';
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { AuthService } from './auth.service';
import { LogService } from './log.service';

/** A query to fetch a specific institution. */
export class InstitutionQuery {
  constructor(
    /** Organization of institution. */
    public _org = ID_DEFAULT,
    /** ID of institution. */
    public _id = ID_DEFAULT
  ) {}
}

/** Caches information about tasks. */
@Injectable({
  providedIn: 'root'
})
export class InstitutionService extends CachePreviewService<Institution, InstitutionQuery, InstitutionPreview> {
  readonly route = 'institutions/preview';
  readonly Type = Institution;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService
  ) {
    super(InstitutionQuery, log, dialog, http);
  }

  protected override multiple(queries: ArraySome<InstitutionQuery>) {
    return getRequest(this.http, 'institutions', { _orgs: queries.map(q => q._org), _ids: queries.map(q => q._id) });
  }

  protected override preview(_inst: string) {
    return getRequest(this.http, this.route, { _orgs: [this.auth._org], _ids: this.auth.session.institutions() });
  }
}
