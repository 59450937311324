<div class="overlay">
  <app-label>{{value | percent:max}}</app-label>
</div>
<svg>
  <circle class="bg"
          fill="transparent"
          [attr.r]="radius"
          [attr.cx]="center.x"
          [attr.cy]="center.y"
          [style.stroke-width]="strokeWidth"
          [style.stroke-dasharray]="strokeDashArray"
          [style.stroke-dashoffset]="fullDashOffset" />
  <circle class="fg"
          fill="transparent"
          [attr.r]="radius"
          [attr.cx]="center.x"
          [attr.cy]="center.y"
          [style.stroke-width]="strokeWidth"
          [style.stroke-dasharray]="strokeDashArray"
          [style.stroke-dashoffset]="strokeDashOffset" />
</svg>