import { Injectable } from '@angular/core';
import { FusionCollectionName } from '../../../../../common/model/fusion';
import { FUSION_COLLECTION_NAME } from '../../../../../common/model/fusion-map';
import { enumBankMapResolve, enumMapPairs } from '../../../../../common/toolbox/enum';
import { idNull } from '../../../../../common/toolbox/id';
import { Pair } from '../../../../../common/toolbox/object';
import { SetupClaimFilterComponent } from '../../module/setup/claim-filter/setup-claim-filter.component';
import { SetupCodeTypeComponent } from '../../module/setup/code-type/setup-code-type.component';
import { SetupDocumentTemplateComponent } from '../../module/setup/document-template/setup-document-template.component';
import { SetupFilterRuleComponent } from '../../module/setup/filter-rule/setup-filter-rule.component';
import { SetupFormListComponent } from '../../module/setup/form-list/setup-form-list.component';
import { SetupFormComponent } from '../../module/setup/form/setup-form.component';
import { SetupFormulaComponent } from '../../module/setup/formula/setup-formula.component';
import { SetupJobComponent } from '../../module/setup/job/setup-job.component';
import { SetupLedgerConfigComponent } from '../../module/setup/ledger-config/setup-ledger-config.component';
import { SetupModelComponent } from '../../module/setup/model/setup-model.component';
import { SetupProfileComponent } from '../../module/setup/profile/setup-profile.component';
import { SetupData } from '../../module/setup/setup.model';
import { SetupTableComponent } from '../../module/setup/table/setup-table.component';
import { SetupTaskComponent } from '../../module/setup/task/setup-task.component';
import { SetupTriggerComponent } from '../../module/setup/trigger/setup-trigger.component';
import { SetupWorkQueueComponent } from '../../module/setup/work-queue/setup-work-queue.component';
import { SetupWorkflowComponent } from '../../module/setup/workflow/setup-workflow.component';
import { PreviewLike } from '../toolbox/preview';
import { AuthService } from './auth.service';
import { ClaimFilterQuery, ClaimFilterService } from './claim-filter.service';
import { CodeTypeQuery, CodeTypeService } from './code-type.service';
import { DocumentTemplateQuery, DocumentTemplateService } from './document-template.service';
import { FilterRuleQuery, FilterRuleService } from './filter-rule.service';
import { FormListQuery, FormListService } from './form-list.service';
import { FormQuery, FormService } from './form.service';
import { FormulaQuery, FormulaService } from './formula.service';
import { JobQuery, JobService } from './job.service';
import { LedgerConfigQuery, LedgerConfigService } from './ledger-config.service';
import { LogService } from './log.service';
import { ModelQuery, ModelService } from './model.service';
import { ProfileQuery, ProfileService } from './profile.service';
import { TabService } from './tab.service';
import { TableQuery, TableService } from './table.service';
import { TaskQuery, TaskService } from './task.service';
import { TriggerQuery, TriggerService } from './trigger.service';
import { WorkQueueQuery, WorkQueueService } from './work-queue.service';
import { WorkflowQuery, WorkflowService } from './workflow.service';

/** List of collections that can be queried dynamically. */
export type FusionCollectionQuery = keyof CollectionQuery;

/** Mapping of collections to their queries. */
export interface CollectionQuery {
  codeTypes: CodeTypeQuery,
  claimFilters: ClaimFilterQuery,
  documentTemplates: DocumentTemplateQuery,
  filterRules: FilterRuleQuery,
  forms: FormQuery,
  formLists: FormListQuery,
  formulas: FormulaQuery,
  jobs: JobQuery,
  ledgerConfigs: LedgerConfigQuery,
  models: ModelQuery,
  profiles: ProfileQuery,
  tables: TableQuery,
  tasks: TaskQuery,
  triggers: TriggerQuery,
  workflows: WorkflowQuery,
  workQueues: WorkQueueQuery
}

/** Mapping of collections to setup screens. */
const COLLECTION_COMPONENT = {
  claimFilters: SetupClaimFilterComponent,
  codeTypes: SetupCodeTypeComponent,
  documentTemplates: SetupDocumentTemplateComponent,
  filterRules: SetupFilterRuleComponent,
  formLists: SetupFormListComponent,
  forms: SetupFormComponent,
  formulas: SetupFormulaComponent,
  jobs: SetupJobComponent,
  ledgerConfigs: SetupLedgerConfigComponent,
  models: SetupModelComponent,
  profiles: SetupProfileComponent,
  tables: SetupTableComponent,
  tasks: SetupTaskComponent,
  triggers: SetupTriggerComponent,
  workflows: SetupWorkflowComponent,
  workQueues: SetupWorkQueueComponent
}

/** A meta-service for dynamically looking up items by collection. */
@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(
    public claimFilters: ClaimFilterService,
    public codeTypes: CodeTypeService,
    public documentTemplates: DocumentTemplateService,
    public filterRules: FilterRuleService,
    public formLists: FormListService,
    public forms: FormService,
    public formulas: FormulaService,
    public jobs: JobService,
    public ledgerConfigs: LedgerConfigService,
    public models: ModelService,
    public profiles: ProfileService,
    public tables: TableService,
    public tasks: TaskService,
    public triggers: TriggerService,
    public workflows: WorkflowService,
    public workQueues: WorkQueueService,

    private auth: AuthService,
    private tabs: TabService,
    private log: LogService
  ) {}

  /** Get a particular resource. */
  item<K extends FusionCollectionQuery>(collection: K, query: CollectionQuery[K]): ReturnType<ResourceService[K]['item']> {
    return this[collection].item(query as any) as any;
  }

  /** Get name of a particular resource. */
  async name(_id: string | undefined, collection: FusionCollectionQuery): Promise<string> {
    if (idNull(_id)) return `${collection}:${_id}`;
    return this[collection].name(this.auth._inst, _id);
  }

  /** Get list of previews for a resource. */
  previews(collection: FusionCollectionQuery): Promise<PreviewLike[]> {
    return this[collection].previews(this.auth._inst) ?? [];
  }

  /** Configure a particular resource. */
  configure(_id: string, collection: FusionCollectionQuery) {
    let component = COLLECTION_COMPONENT[collection];
    if (!component) {
      this.log.show(`Collection cannot be configured: ${collection}`);
      return;
    }

    this.tabs.open(component, new SetupData(_id));
  }

  /** Check if a particular collection can be queried. */
  queryable(collection: FusionCollectionName): collection is FusionCollectionQuery {
    return collection in COLLECTION_COMPONENT;
  }

  /** Get list of available collections for selection. */
  available(): Pair<FusionCollectionName>[] {
    return enumMapPairs(enumBankMapResolve(FUSION_COLLECTION_NAME, this.auth.institution.bank)).filter(({ value }) => this.queryable(value));
  }
}
