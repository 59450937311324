
/** Minimum 32-bit signed integer. */
export const INT32_MIN = -2147483648;
/** Maximum 32-bit signed integer. */
export const INT32_MAX = 2147483647;
/** Regex for integers. */
export const INTEGER_REGEX = /^-?\d+$/;
/** Regex for positive integers. */
export const NATURAL_REGEX = /^\d+$/;
/** Regex for floating point numbers. */
export const FLOAT_REGEX = /^[+-]?([0-9]*[.])?[0-9]+$/;

/** A range of values. */
export class Range {
  constructor(
    /** Low end of range. */
    public low = 0,
    /** High end of range. */
    public high = 0
  ) {}

  /** Check if a value is inside range. */
  static has(range: Range, value: number) {
    return value >= range.low && value <= range.high;
  }
}

/** Clamp number to given range. */
export function numberClamp(value = INT32_MAX, min = INT32_MIN, max = INT32_MAX) {
  return Math.max(min, Math.min(max, value));
}

/** Coerce a value into a number. */
export function numberCoerce(value: any, required: true, min?: number, max?: number): number
export function numberCoerce(value: any, required: boolean, min?: number, max?: number): number | undefined
export function numberCoerce(value: any, required: any, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER) {
  let invalid = value === '' || isNaN(+value);
  if (invalid) return required ? numberClamp(0, min, max) : undefined;
  return numberClamp(+value, min, max);
}

/** Coerce a value into a number, with fallback. */
export function numberFallback(value: string | undefined, fallback: number) {
  return numberCoerce(value, false) ?? fallback;
}

/** Multiply together two fixed point numbers, producing the requested output scale.
 *  @param a First value to multiply as a fixed-point integer.
 *  @param sa Scaling factor of a, the number to divide it by to arrive at an unscaled value.
 *  @param b Second fixed-point value.
 *  @param sb Scaling factor of b.
 *  @param so Scaling factor of the output.
*/
export function numberFixedMultiply(a: number, sa: number, b: number, sb: number, so: number) {
  return Math.floor((a * b) / (sa * sb) * so);
}

/** Pad a signed number, forcing sign to be present. */
export function numberPadSigned(value: number, length: number, fill = '0') {
  if (value >= 0) return `+${`${value}`.padStart(length, fill)}`;
  else return `-${`${Math.abs(value)}`.padStart(length, fill)}`;
}