import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { unzip } from 'unzipit';
import { DOCUMENT_TEMPLATE_TYPE_NAME, DocumentTemplateType } from '../../../../../../../common/code/standard/common';
import { DocumentTemplatePostResponse } from '../../../../../../../common/message/document-template';
import { ErrorResponse } from '../../../../../../../common/message/error';
import { AttachmentUpload } from "../../../../../../../common/model/attachment";
import { DocumentTemplate, DocumentTemplateFile } from '../../../../../../../common/model/document-template/base';
import { DocumentTemplateClass } from '../../../../../../../common/model/document-template/data';
import { DocumentTemplateFormat } from '../../../../../../../common/model/document-template/format';
import { arraySingle } from "../../../../../../../common/toolbox/array";
import { BufferLike, binaryBlobPart } from '../../../../../../../common/toolbox/binary';
import { DOCUMENT_TEMPLATE_KEYWORDS, documentTemplateScan } from '../../../../../../../common/toolbox/document-template';
import { enumValues } from '../../../../../../../common/toolbox/enum';
import { FILENAME_REGEX } from '../../../../../../../common/toolbox/file';
import { MaybeId, idMaybe, idNull } from '../../../../../../../common/toolbox/id';
import { errorResponse } from "../../../../../../../common/toolbox/message";
import { DialogService } from '../../../../common/component/dialog/dialog.service';
import { AuthService } from '../../../../common/service/auth.service';
import { CodeTypeService } from '../../../../common/service/code-type.service';
import { LogService } from '../../../../common/service/log.service';
import { fileCreate } from '../../../../common/toolbox/file';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';
import { SetupDocumentTemplateScanDialogComponent } from '../scan/setup-document-template-scan-dialog.component';
import { SetupDocumentTemplateScanDialogData } from '../scan/setup-document-template-scan-dialog.model';

@Component({
  selector: 'app-setup-document-template-edit',
  templateUrl: './setup-document-template-edit.component.html',
  styleUrls: ['./setup-document-template-edit.component.scss'],
  host: {
    class: 'row fill'
  }
})
export class SetupDocumentTemplateEditComponent extends SetupEditComponent<DocumentTemplate, DocumentTemplatePostResponse> {
  readonly DOCUMENT_TEMPLATE_TYPE_NAME = DOCUMENT_TEMPLATE_TYPE_NAME;
  readonly DOCUMENT_TEMPLATE_KEYWORDS = DOCUMENT_TEMPLATE_KEYWORDS;
  readonly FILENAME_REGEX = FILENAME_REGEX;
  readonly DocumentTemplateFormat = DocumentTemplateFormat;
  readonly types = enumValues<DocumentTemplateType>(DocumentTemplateType);

  /** Document template being edited. */
  value = idMaybe(new DocumentTemplateFile());
  /** Base64 encoded value of document template */
  data: BufferLike = '';
  /** File to display in preview */
  file?: File;

  /** Configuration object to display type information for. */
  proxy?: DocumentTemplateClass[DocumentTemplateType];
  /** List of uploads for new document template. */
  uploads: AttachmentUpload[] = [];
  /** Override validation of template */
  override = false

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private auth: AuthService,
    private dialog: DialogService,
    private http: HttpClient,
    private codes: CodeTypeService
  ) {
    super(elementRef, log);
  }

  /** Perform validation of document template before uploading. */
  override async onSubmit() {
    if (!this.file) return new ErrorResponse('No file specified.');

    let codes = (await this.codes.previews(this.auth._inst)).map(c => c.category);
    let scan = await documentTemplateScan(unzip, new DOMParser(), this.value.name, this.value.type, this.file, codes);
    if (errorResponse(scan)) {
      this.log.show(scan);
      return scan;
    }
    
    if (scan && !scan.success) {
      let data = new SetupDocumentTemplateScanDialogData(scan);
      let result = await this.dialog.open(SetupDocumentTemplateScanDialogComponent, data);
      if (result !== true) return new ErrorResponse('Document template was not submitted due to errors.');

      // User chose to ignore validation and upload anyway.
      this.override = true
    }

    return super.onSubmit();
  }

  /** Upload new document to replace document template */
  async onUpload(uploads: AttachmentUpload[]) {
    if (arraySingle(uploads)) {
      this.value.name = uploads[0].name;
      this.refile(uploads[0].data);
    }
  }

  /** Submit current changes to document template. */
  push() {
    let upload = { ...this.value, data: this.data, uploads: idNull(this.value._id) ? this.uploads : undefined };
    return postRequest(this.http, 'document-templates', {
      items: [upload],
      override: this.override
    });
  }

  /** Toggle display of type information. */
  refield(show: boolean) {
    this.proxy = show ? new DocumentTemplateClass()[this.value.type] : undefined;
  }

  /** Reset current form with new document template. */
  async reset(value: MaybeId<DocumentTemplate>) {
    let file = DocumentTemplate.file(value);
    this.value = file;
    this.refile(file.data);
    this.refield(!!this.proxy);
  }

  /** Set new data for document template display. */
  private async refile(data: BufferLike) {
    this.file = fileCreate([binaryBlobPart(data)], this.value.name);
    this.data = data;
  }
}
