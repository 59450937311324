import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LedgerAccountType } from '../../../../../common/model/ledger/account';
import { LedgerConfig, LedgerConfigPreview } from '../../../../../common/model/ledger/config';
import { LedgerItem } from '../../../../../common/model/ledger/item';
import { ArraySome } from "../../../../../common/toolbox/array";
import { formulaRun } from '../../../../../common/toolbox/formula/formula';
import { ID_DEFAULT, NoIdInst } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { FORMULA_TRUE } from '../component/form/list/form-list.component';
import { ExpressionPipe } from '../pipe/expression.pipe';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { DisplayDispute } from '../toolbox/dispute';
import { getRequest } from '../toolbox/request';
import { AccountName, AccountService } from './account.service';
import { AuthService } from './auth.service';
import { FormulaService } from './formula.service';
import { LogService } from './log.service';
import { TabService } from './tab.service';

/** A query to fetch a specific ledger configuration. */
export class LedgerConfigQuery {
  constructor(
    /** ID of ledger configuration. */
    public _id = ID_DEFAULT,
    /** Institution of ledger configuration. */
    public _inst = ID_DEFAULT
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class LedgerConfigService extends CachePreviewService<LedgerConfig, LedgerConfigQuery, LedgerConfigPreview> {
  readonly route = 'ledger-configs/preview';
  readonly Type = LedgerConfig;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    public tabs: TabService,
    private auth: AuthService,
    private accounts: AccountService,
    private formulas: FormulaService,
    private expression: ExpressionPipe
  ) {
    super(LedgerConfigQuery, log, dialog, http);
  }

  /** Create a ledger item from provided context. */
  async create(_config: string, partial: DisplayDispute): Promise<[NoIdInst<LedgerItem>, AccountName, LedgerConfig]> {
    let config = await this.item({ _inst: this.auth._inst, _id: _config });
    let [account, description, amountFormula] = await Promise.all([
      this.accounts.item({ _inst: this.auth._inst, _id: config._glAccount }),
      this.expression.transform(config.description ?? config.name, partial),
      config._amountFormula ? this.formulas.item(({ _inst: this.auth._inst, _id: config._amountFormula })) : FORMULA_TRUE
    ]);

    // Determine initial amount of ledger item.
    let amount = formulaRun(amountFormula, partial);
    if (typeof amount !== 'number') amount = 0;

    return [{
      _config, amount, description,
      account: config.account === LedgerAccountType.Consumer ? partial.account.number : partial.ledger.account,
      accountCategory: account.category,
      accountType: account.type ?? '',
      subAccount: account.subAccount,
      date: new Date(),
      tranCode: config.tranCode,
      type: config.type,
      status: partial.dispute.status
    }, account, config];
  }

  protected override multiple(queries: ArraySome<LedgerConfigQuery>) {
    return getRequest(this.http, 'ledger-configs', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}