import { TypeInfo } from "../info/type";
import { IMPORT_CONFIG_UNIONINFO, ImportConfig } from "../model/import";
import { ID_DEFAULT, MaybeId } from "../toolbox/id";
import { validatorUnionOmit } from "../toolbox/validate";
import { ErrorResponse } from "./error";
import { SuccessResponse } from "./success";

/** Trigger a manual import for institution. */
export class ImportPostRequest {
  constructor(
    /** Organization to pull import configuration from. */
    public _org = ID_DEFAULT,
    /** Institution to import into. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Result of importing a value. */
export class ImportResult {
  constructor(
    /** Number of imported values. */
    public count = 0,
    /** List of errors from importing this value. */
    public errors?: ErrorResponse[]
  ) {}

  static typeinfo: TypeInfo<ImportResult> = {
    errors: [new ErrorResponse()]
  }
}

/** An import result with an attached title. */
export class ImportResultTitle extends ImportResult {
  constructor(
    /** Title of resource. */
    public title = ''
  ) {
    super();
  }
}

/** Result returned when performing import. */
export class ImportPostResponse {
  constructor(
    /** Errors from importing accounts. */
    public account = new ImportResult(),
    /** Errors from importing cards. */
    public card = new ImportResult(),
    /** Errors from importing claims */
    public claim = new ImportResult(),
    /** Errors from importing transactions. */
    public transaction = new ImportResult(),
    /** Errors from importing members. */
    public member = new ImportResult(),
    /** Errors from importing addresses. */
    public address = new ImportResult(),
    /** Errors from importing account-member relationships. */
    public accountMember = new ImportResult(),
    /** Errors from importing account-card relationships. */
    public accountCard = new ImportResult(),
    /** Errors from importing member-card relationships. */
    public memberCard = new ImportResult(),
  ) {}
}

/** Get import config for organization. */
export class ImportConfigGetRequest {
  constructor(
    /** Organization to fetch config from. */
    public _org = ID_DEFAULT
  ) {}
}

/** Result of getting import configuration. */
export type ImportConfigGetResponse = ImportConfig;

/** Update import config for organization. */
export class ImportConfigPostRequest {
  constructor(
    /** New configuration for organization. */
    public items: MaybeId<ImportConfig>[] = []
  ) {}

  static typeinfo: TypeInfo<ImportConfigPostRequest> = {
    items: [validatorUnionOmit(IMPORT_CONFIG_UNIONINFO, '_id')]
  };
}

/** Result of updating import configuration for institution. */
export type ImportConfigPostResponse = SuccessResponse;

/** Request to clean out test data. */
export class ImportCleanupDeleteRequest {
  constructor(
    /** Organizations to perform cleanup on. */
    public _insts: string[] = []
  ) {}

  static typeinfo: TypeInfo<ImportCleanupDeleteRequest> = {
    _insts: [ID_DEFAULT, ID_DEFAULT]
  }
}

/** Result of cleaning out test data. */
export type ImportCleanupDeleteResponse = SuccessResponse;