import { Pipe } from '@angular/core';
import { dateNull } from "../../../../../common/toolbox/time";

@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe {
  transform(value?: Date): number {
    if (dateNull(value)) return 0;
    return value.getTime() - new Date().getTime();
  }
}
