import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { DisplayPartial, DisplayType } from "../../../../../../common/model/display";
import { ApplicationSettingsKey } from '../../../../../../common/model/setting-group';
import { ID_DEFAULT } from "../../../../../../common/toolbox/id";
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AccountService } from '../../../common/service/account.service';
import { LogService } from '../../../common/service/log.service';
import { TableService } from '../../../common/service/table.service';
import { ResultsComponent } from '../../../common/toolbox/results/results.component';
import { AccountResultsData } from './account-results.model';

@Component({
  selector: 'app-account-results',
  templateUrl: '../../../common/toolbox/results/results.component.html',
  styleUrls: ['../../../common/toolbox/results/results.component.scss']
})
export class AccountResultsComponent extends ResultsComponent<'accounts'> {

  route: 'accounts' = 'accounts';
  table: ApplicationSettingsKey = 'general.account.table';
  type = DisplayType.Account;

  static override title() {
    return 'Account Results';
  }
  
  constructor(
    @Inject(TAB_DATA)
    data: AccountResultsData,
    log: LogService,
    tables: TableService,
    http: HttpClient,
    private accounts: AccountService
  ) {
    super(data, http, log, tables);
  }

  /** Callback when opening specified item. */
  async onRow(partial: DisplayPartial) {
    this.accounts.open({
      _insts: [partial.account?._inst ?? ID_DEFAULT],
      _ids: [partial.account?._id ?? ID_DEFAULT]
    });
  }
}
