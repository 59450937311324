import { TypeInfo } from "../../info/type";
import { keyNestedMerge, NestedKey } from "../../toolbox/keys";
import { EnumValidator } from "../../validator/enum";
import { Direction } from "../direction";

/** Definition of how to sort given value. */
export class Sort<T = any> {

  constructor(
    /** Field to sort over. */
    public field: NestedKey<T> = '' as NestedKey<T>,
    /** Direction for sort. */
    public direction: Direction = 1
  ) {}

  static typeinfo: TypeInfo<Sort> = {
    direction: new EnumValidator([-1, 1])
  }

  /** Create sort with type information for given objects. */
  static from(...objects: Object[]) {
    return new class extends Sort {
      static override typeinfo: TypeInfo<Sort> = {
        ...Sort.typeinfo,
        field: new EnumValidator(new Set(keyNestedMerge(...objects)))
      }
    }
  }
}