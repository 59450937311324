import { ColorCode } from "../../../../../../common/toolbox/color";
import { dateEqual, DateMap, DateRangePosition, DateRange, DateModel, monthEqual, monthName, yearEqual } from "../../../../../../common/toolbox/time";

/** Number of days to display in calendar. */
export const CALENDAR_DAYS = 7 * 6;

/** Allowed zoom levels of calendar. */
export enum CalendarZoom { Days, Months, Years }
/** Current selection mode of calendar. */
export enum CalendarMode { Date, Start, End }

/** An event bound to a calendar date. */
export class CalendarEvent {
  constructor(
    /** Date event will occur. */
    public date = new Date(0),
    /** Title of event. */
    public name = 'Event',
    /** Color of event. */
    public color = ColorCode.Green
  ) {}
}

/**
 *  Get first date on calendar, given day.
 *  @param date Date belonging to month.
 *  @param time True to preseve time of date, false to clamp to midnight.
 */
export function calendarDay(date: Date, time = false) {
  let copy = new Date(date);
  let first = new Date(copy.setDate(1));
  copy.setDate(copy.getDate() - copy.getDay());
  if (!time) copy.setHours(0, 0, 0, 0);

  if (first.getDay() <= 1) {
    // First of month falls on sunday or monday, rewind a week.
    copy.setDate(copy.getDate() - 7);
  }

  return copy;
}

/** Get first month on calendar, given day. */
export function calendarMonth(date: Date) {
  let copy = new Date(date);
  copy.setMonth(0);
  copy.setDate(1);
  return copy;
}

/** Get first year on calendar, given day. */
export function calendarYear(date: Date) {
  let copy = new Date(date);
  copy.setFullYear(Math.floor(copy.getFullYear() / 12) * 12);
  copy.setMonth(1);
  copy.setDate(1);
  return copy;
}

/** Single day displayed in calendar. */
export class Day {

  constructor(
    /** Text to display. */
    public display = '',
    /** Date of day. */
    public date = new Date(0),
    /** Valid range for date. */
    public valid = new DateRange(),
    /** True if day is selected. */
    public selected = false,
    /** True to disable selecting date. */
    public disabled = false,
    /** True if date falls on today. */
    public today = false,
    /** True to display active styling. */
    public active = false,
    /** List of events to display on this date. */
    public events: CalendarEvent[] = []
  ) {
    this.refresh(date, new DateRange(date, date), valid);
  }

  /** Set current display after changing date. */
  refresh(date: Date, selected: DateModel, valid: DateRange, events?: DateMap<CalendarEvent>) {
    this.date = new Date(date);
    this.disabled = !DateRange.contains(date, valid);
    this.display = `${date.getDate()}`;
    this.today = dateEqual(date, new Date());
    if (events) this.events = events.get(date);

    switch (DateRange.compare(date, selected)) {
    case DateRangePosition.Start:
    case DateRangePosition.End:
      this.selected = true;
      this.active = false;
      break;
    case DateRangePosition.Middle:
      this.selected = false;
      this.active = true;
      break;
    default:
      this.selected = this.active = false;
      break;
    }
  }
}

/** Single month displayed in calendar. */
export class Month extends Day {
  override refresh(date: Date, selected: DateModel) {
    this.date = new Date(date);
    this.selected = monthEqual(date, DateRange.date(selected));
    this.display = monthName(date);
  }
}

/** Single year displayed in calendar. */
export class Year extends Day {
  override refresh(date: Date, selected: DateModel) {
    this.date = new Date(date);
    this.selected = yearEqual(date, DateRange.date(selected));
    this.display = `${date.getFullYear()}`;
  }
}