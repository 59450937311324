import { IndexInfo } from "../info";
import { SchemaInfo } from "../info/schema";
import { ID_DEFAULT } from "../toolbox/id";

/** A generic cache class used for caching any values from expensive computations/queries */
export class CachedItem<T> {
  constructor(
    /** ID of cache item. */
    public _id = ID_DEFAULT,
    /** Institution of address. */
    public _inst = ID_DEFAULT,
    /** Institution unique key to lookup value from cache. */
    public key = '',
    /** Value of the cache */
    public cacheValue = {} as T,
    /** Timestamp cache expires*/
    public expireTime = new Date()
  ) { }

  static indexinfo: IndexInfo<CachedItem<any>> = [
    { key: { _inst: 1, key: 1 }, unique: true },
  ];

  static schemainfo: SchemaInfo<CachedItem<any>> = {
    cacheValue: {
      bsonType: 'object',
      properties: {},
      additionalProperties: true
    }
  }
}