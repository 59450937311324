import { NgModule } from '@angular/core';
import { CollectionsAgentModule } from './agent/collections-agent.module';
import { CollectionsExecutiveModule } from './executive/collections-executive.module';
import { CollectionsSupervisorModule } from './supervisor/collections-supervisor.module';

@NgModule({
  declarations: [],
  imports: [
    CollectionsAgentModule,
    CollectionsSupervisorModule,
    CollectionsExecutiveModule
  ]
})
export class CollectionsModule { }
