<app-card [formGroup]="builder">
  <app-card-body scroll>
    <div class="row">
      <app-field>
        <button icon-button type="submit" disabled>search</button>
        <input placeholder="Filter" formControlName="filter">
      </app-field>
      <div class="chip">
        <button icon-button class="delete placeholder">delete</button>
        <button icon-button cdkDrag cdkDragHandle class="delete" (cdkDragEnded)="onDrag($event)">delete</button>
      </div>
    </div>
    <div class="container" (mousemove)="onMove($event)" #container>
      <div class="groups" [style.pointerEvents]="inside ? 'all' : 'none'">
        <div *ngFor="let group of groups">
          <div class="title">
            <app-icon>{{group.icon}}</app-icon>
            <h3>{{group.name}}</h3>
          </div>
          <div class="chips">
            <div class="chip" *ngFor="let chip of group.chips">
              <app-setup-formula-block class="placeholder" [attr.color]="group.color">{{chip.name}}</app-setup-formula-block>
              <app-setup-formula-block cdkDrag cdkDragHandle (cdkDragEnded)="onDrag($event, chip)" [attr.color]="group.color">{{chip.name}}</app-setup-formula-block>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-card-body>
</app-card>