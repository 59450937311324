import { ErrorResponse } from "../message/error";
import { PaginateResponse } from "../message/paginate";
import { arrayOrderKey } from "./array";
import { errorResponse } from "./message";

/** Coerce a value into a list of items + available. */
export function paginateSplit<T>(value: T[] | PaginateResponse<T>): [items: T[], available: number] {
  return Array.isArray(value) ? [value, value.length] : [value.items, value.available];
}

/** Reorder a pagination given a list of elements. */
export function paginateOrder<T, K extends keyof T>(array: T[], key: K, order: T[K][]): T[] | ErrorResponse
export function paginateOrder<T, K extends keyof T>(pagination: PaginateResponse<T>, key: K, order: T[K][]): PaginateResponse<T> | ErrorResponse
export function paginateOrder<T, K extends keyof T>(value: T[] | PaginateResponse<T>, key: K, order: T[K][]): T[] | PaginateResponse<T> | ErrorResponse
export function paginateOrder<T, K extends keyof T>(value: T[] | PaginateResponse<T>, key: K, order: T[K][]): T[] | PaginateResponse<T> | ErrorResponse {
  if (Array.isArray(value)) return arrayOrderKey(value, key, order);
  let items = arrayOrderKey(value.items, key, order);
  return errorResponse(items) ? items : new PaginateResponse(items, value.available);
}