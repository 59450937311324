import { Newable, ObjectKeys, deepCopy, objectNewable } from "../toolbox/object";
import { UnionValidator } from "../validator/union";
import { CommentList } from "./comment/comment";
import { ModelValue } from "./model";
import { Revision } from "./revision";
import { Schema, SchemaIndex, SchemaProperty } from "./schema";

/** Collections containing test data that can be wiped. */
export type CollectionTest<C> = ObjectKeys<C, { test?: boolean }>;
/** Collections containing system data that cannot be wiped by users. */
export type CollectionSystem<C> = ObjectKeys<C, { system?: boolean }>;
/** Collections containing data that can be marked as dirty. */
export type CollectionDirty<C> = ObjectKeys<C, { dirty?: boolean }>;
/** Collections containing comments. */
export type CollectionComments<C> = ObjectKeys<C, CommentList>;
/** Collections that have attachments associated with them. */
export type CollectionAttachments<C> = ObjectKeys<C, { _attachments?: string[] }>;
/** Collections that support revision history. */
export type CollectionRevisions<C> = ObjectKeys<C, Revision>;
/** Collections that support revision history. */
export type CollectionModels<C> = ObjectKeys<C, ModelValue>;

/** Schemas of all collections. */
export type CollectionMap<C> = { [K in keyof C]: Schema<C[K]> };
/** Classes of all collections. */
export type CollectionClass<C> = { [K in keyof C]: Newable<C[K]> | UnionValidator<C[K], any> };
/** List of overrides for a collection's type. */
export type CollectionOverride<C> = { [K in keyof C]?: Partial<C[K]> };
/** Hashes for all collections. */
export type CollectionHash<C> = Record<keyof C, string>;
/** Information about a collection. */
export type CollectionStates<C> = { [K in keyof C]: CollectionState<C[K]> }
/** Set of tags for a collection. */
export type CollectionTags<C, T extends string = string> = Record<keyof C, T>;

/** Information about a single collection. */
export interface CollectionState<T = any> {
  /** Hash of this collection's validator. */
  hash: string
  /** Validator of this collection. */
  validator?: SchemaProperty<T>
  /** Indexes of this collection. */
  indexes: SchemaIndex[]
}

/** Pull a value from a collection info. */
export function collectionValue<C, K extends keyof C>(classes: CollectionClass<C>, collection: K): C[K]
export function collectionValue<C, K extends keyof C>(classes: CollectionOverride<C>, collection: K): C[K] | undefined
export function collectionValue<C, K extends keyof C>(classes: CollectionClass<C> | CollectionOverride<C>, collection: K): C[K] | undefined {
  let info: any = classes[collection];
  if (!info) return undefined;

  let value = info;
  if (info instanceof UnionValidator) {
    value = info.merged;
  } else if (objectNewable(info)) {
    value = new info();
  }

  return deepCopy(value);
}