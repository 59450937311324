import { IndexInfo } from "../../info";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { Authentications } from "./authentication";
import { Features } from "./feature";

/** Organization owning one or more institutions. */
export class Organization {
  constructor(
    /** ID of organization. */
    public _id = ID_DEFAULT,
    /** Name of organization. */
    public name = '',
    /** Features granted to organization. */
    public features = new Features(),
    /** Available authentication types for organization. */
    public authentications = new Authentications()
  ) {}

  static typeinfo: TypeInfo<Organization> = {
    features: new Features()
  }

  static indexinfo: IndexInfo<Organization> = [
    { key: { name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** Short preview of an organization viewed in lists. */
export class OrganizationPreview {
  constructor(
    /** ID of organization. */
    public _id = ID_DEFAULT,
    /** Name of organization. */
    public name = ''
  ) {}
}

/** A unique reference to an organization. */
export class OrganizationReference {
  constructor(
    /** Name of organization. */
    public name = ''
  ) {}
}