import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Claim, ClaimACH } from '../../../../../../common/model/claim/claim';
import { JOB_NOTIFICATION_CLAIM, Job } from '../../../../../../common/model/job/job';
import { JOB_TYPE_NAME, JobType } from '../../../../../../common/model/job/payload';
import { enumMapPairs } from '../../../../../../common/toolbox/enum';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { getRequest } from '../../../common/toolbox/request';

@Component({
  selector: 'app-claim-notifications',
  templateUrl: './claim-notifications.component.html',
  styleUrls: ['./claim-notifications.component.scss']
})
export class ClaimNotificationsComponent {

  /** Claim that is being configured. */
  @Input() claim: Claim = new ClaimACH();

  /** List of claim notification job types. */
  types = enumMapPairs<JobType>(JOB_TYPE_NAME).filter(job => job.value in JOB_NOTIFICATION_CLAIM);
  /** List of claim jobs to display. */
  jobs: Job[] = [];

  constructor(
    private log: LogService,
    private auth: AuthService,
    private http: HttpClient
  ) { }

  async refresh() {
    /** Fetch list of jobs related to this claim. */
    let jobs = await getRequest(this.http, 'jobs', { _insts: [this.auth._inst], _claim: this.claim._id });
    if (errorResponse(jobs)) {
      this.log.show(jobs);
      return;
    }

    this.jobs = jobs;
  }
}
