import { Component } from '@angular/core';
import { SettingType } from '../../../../../../../common/info/settings';
import { SettingsAccessor } from '../settings-accessor';

@Component({
  selector: 'app-settings-boolean',
  templateUrl: './settings-boolean.component.html',
  styleUrls: ['./settings-boolean.component.scss']
})
export class SettingsBooleanComponent extends SettingsAccessor<SettingType.Boolean> {
  static override description() {
    return true;
  }
}
