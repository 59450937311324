import { Component } from '@angular/core';
import { DisputesCode } from '../../../../../../../../common/code/system';
import { ClaimFilterClaimStatus } from '../../../../../../../../common/model/claim/filter';
import { ClaimFilterAccessor } from '../../edit/setup-claim-filter-edit.model';

@Component({
  selector: 'app-setup-claim-filter-claim-status',
  templateUrl: './setup-claim-filter-claim-status.component.html',
  styleUrls: ['./setup-claim-filter-claim-status.component.scss']
})
export class SetupClaimFilterClaimStatusComponent extends ClaimFilterAccessor<ClaimFilterClaimStatus> {
  readonly DisputesCode = DisputesCode;
}