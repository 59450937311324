import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { FeatureType } from '../../../../../../common/model/organization/feature';
import { ApplicationSettings, SettingGroup } from '../../../../../../common/model/setting-group';
import { arrayDefined } from '../../../../../../common/toolbox/array';
import { hydrateObject } from '../../../../../../common/toolbox/hydrate';
import { idInstOmit } from '../../../../../../common/toolbox/id';
import { errorPartition, errorResponse } from '../../../../../../common/toolbox/message';
import { deepCopy } from '../../../../../../common/toolbox/object';
import { AnyValidator } from '../../../../../../common/validator/any';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { LogService } from '../../../common/service/log.service';
import { SettingGroupService } from '../../../common/service/setting-group.service';
import { clipboardJSON } from '../../../common/toolbox/clipboard';
import { postRequest } from '../../../common/toolbox/request';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-settings',
  templateUrl: './setup-settings.component.html',
  styleUrls: ['./setup-settings.component.scss'],
  host: {
    class: 'row fill'
  }
})
export class SetupSettingsComponent {

  /** Settings being configured. */
  settings?: SettingGroup;
  /** Blacklist of top-level settings to exclude. */
  blacklist: (keyof ApplicationSettings)[] = [];

  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  static title() {
    return 'Settings';
  }

  constructor(
    public auth: AuthService,
    private settingGroups: SettingGroupService,
    private log: LogService,
    private http: HttpClient,
    private collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {}

  ngOnInit() {
    this.reset();
  }
  
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  async onSubmit() {
    if (!this.settings) return;
    
    let update = await postRequest(this.http, 'setting-groups', { items: [this.settings] });
    if (errorResponse(update)) return this.log.show(update);
    this.settingGroups.set(this.settings);
    this.log.show(update.success);
  }

  onPreview() {}

  onCancel() {
    this.ngOnInit();
  }
  
  async onDownload() {
    if (this.settings === undefined) return;
    let item = await this.collections.export('settingGroups', this.settings);
    if (errorResponse(item)) return this.log.show(item); 

    await navigator.clipboard.writeText(JSON.stringify([item]));
    this.log.show(`Copied item to clipboard.`);
  }

  /** Callback when uploading items from clipboard. */
  async onUpload() {
    let clipboard = await clipboardJSON(new AnyValidator());
    if (errorResponse(clipboard)) {
      this.log.show(clipboard);
      return;
    }

    let results = await Promise.all(clipboard.map(async (item: any) => {
      return {
        ...idInstOmit(await this.collections.import(item)),
        _inst: this.auth._inst
      }
    }));
    let [error, items] = errorPartition('There was an error loading items from clipboard.', results);
    if (error) return this.log.show(error);

    let result = await postRequest(this.http, 'setting-groups', { items });
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }

    this.log.show(`Successfully uploaded ${items.length} items from clipboard.`);
  }

  /** Reset list of settings. */
  async reset() {
    let settings = await this.settingGroups.item({ _id: this.auth.institution._settingGroup, _inst: this.auth._inst });
    this.blacklist = arrayDefined([
      this.auth.enabled(FeatureType.Collections) ? undefined : 'collections',
      this.auth.enabled(FeatureType.Disputes) ? undefined : 'disputes'
    ]);
    this.settings = hydrateObject(deepCopy(settings), new SettingGroup());
  }

}
