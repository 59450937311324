<main [class.center]="centered">
  <ng-content></ng-content>
</main>
<footer (click)="$event.stopPropagation()" [style.display]="grid.expanded ? 'flex' : 'none'">
  <ng-container *ngIf="key!==undefined || useNameAsKey">
    <app-string size="1" [ngModel]="filter" (ngModelChange)="onInput($event)" [required]="false"></app-string>
    <button icon-button (click)="resort()">
      <ng-container [ngSwitch]="direction">
        <ng-container *ngSwitchCase="1">arrow_upward</ng-container>
        <ng-container *ngSwitchCase="-1">arrow_downward</ng-container>
        <ng-container *ngSwitchCase="undefined">import_export</ng-container>
      </ng-container>
    </button>  
  </ng-container>
</footer>
