import { Component, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClaimJoin, ClaimJoinACH } from '../../../../../../../common/model/claim/claim';
import { EventResult } from "../../../../../../../common/model/event/result";
import { EventListComponent } from '../../../../common/component/event-list/event-list.component';
import { EventListData } from '../../../../common/component/event-list/event-list.model';
import { PanelListComponent } from '../../../../common/component/panel/list/panel-list.component';
import { TabConfig } from '../../../../common/component/tab/bar/tab-bar.model';
import { TaskOpen } from '../../../../common/toolbox/task';
import { ClaimPanelComponent } from '../claim-panel.component';

@Component({
  selector: 'app-claim-panel-list',
  templateUrl: './claim-panel-list.component.html',
  styleUrls: ['./claim-panel-list.component.scss']
})
export class ClaimPanelListComponent {
  /** Claim being displayed. */
  @Input() set claim(claim: ClaimJoin) { this.reclaim(claim); }

  /** Emits when existing task result should be opened. */
  @Output() open = new Subject<TaskOpen>();
  /** Reference to navigation panel. */
  @ViewChild(PanelListComponent) list!: PanelListComponent;

  /** Emits when claim is changed. */
  private _claim = new BehaviorSubject<ClaimJoin>(new ClaimJoinACH());

  constructor(
  ) {}

  ngOnDestroy() {
    this._claim.complete();
  }

  /** Update current displayed claim. */
  private async reclaim(claim: ClaimJoin) {
    this._claim.next(claim);
    if (this.list && !this.list.tabs.length) this.add();
  }

  /** Add a panel to the end.
   *  TODO Hardcoded for just event list right now.
   */
  add() {
    let options: TabConfig = {
      title: 'Events',
      container: ClaimPanelComponent,
      autoselect: false
    };

    let eventData = new EventListData(undefined, this._claim, undefined, this.open as Subject<TaskOpen<EventResult>>, true);
    this.list.open(EventListComponent, eventData, options);
  }
}
