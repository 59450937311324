import { ComponentPortal } from "@angular/cdk/portal";
import { Component, ComponentRef, Inject, Input, Optional, ViewChild, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { AccountPanelType } from "../../../../../common/model/account-panel";
import { AccountJoin, AccountJoinLoan } from "../../../../../common/model/account/account";
import { Member } from "../../../../../common/model/member";
import { FeatureType } from "../../../../../common/model/organization/feature";
import { Task } from "../../../../../common/model/task";
import { accountName } from "../../../../../common/toolbox/account";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { StatusLevel } from "../../../../../common/toolbox/status";
import { DialogOptions, DialogOutlet, DialogService } from "../../common/component/dialog/dialog.service";
import { TAB_DATA } from "../../common/component/tab/bar/tab-bar.model";
import { AuthService } from "../../common/service/auth.service";
import { LogService } from "../../common/service/log.service";
import { MemberName, MemberService } from "../../common/service/member.service";
import { TaskService } from "../../common/service/task.service";
import { TaskOpen } from "../../common/toolbox/task";
import { AccountData } from "./account.model";
import { AccountPanelListComponent } from "./panel/list/account-panel-list.component";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  host: {
    class: 'column fill'
  }
})
export class AccountComponent implements DialogOutlet {
  readonly AccountPanelType = AccountPanelType;
  readonly FeatureType = FeatureType;

  /** Main form to project fields into. */
  @ViewChild('task', { read: ViewContainerRef }) taskRef!: ViewContainerRef;
  /** Reference to panel list. */
  @ViewChild(AccountPanelListComponent) panels!: AccountPanelListComponent;

  /** Current account being viewed. */
  account: AccountJoin = new AccountJoinLoan();
  /** Primary borrower of this account. */
  member?: Member | undefined;
  /** Information for each member. */
  members: Member[] = [];
  /** Emits when created task panels should be destroyed. */
  discard = new Subject<void>();

  /** Current account being viewed. */
  @Input() set data(data: AccountData | undefined) { this.review(data); }

  /** Get title to display for tab. */
  static title(data: AccountData) {
    return accountName(data.account);
  }

  constructor(
    @Optional() @Inject(TAB_DATA) tab: unknown,
    private log: LogService,
    private memberService: MemberService,
    private taskService: TaskService,
    private dialog: DialogService,
    private auth: AuthService
  ) {
    // Will receive different TAB_DATA depending on injected context.
    if (AccountData.check(tab)) this.review(tab);
  }

  /** Callback when a task should be opened. */
  async onTask(open: TaskOpen): Promise<void> {
    // Open dialog with requested task.
    let options = open.panel ? new DialogOptions(this, ['card']) : undefined;
    await this.taskService.open({
      dialog: this.dialog,
      input: {
        partial: {},
        task: await this.taskService.item({ _id: open._task, _inst: this.auth._inst }) as Task<any>,
        account: this.account
      }, options
    });

    // Destroy any task panels in page.
    this.discard.next();
  }

  // PortalOutlet implementation for injecting into template instead of dialog.
  
  attach(portal: ComponentPortal<any>): ComponentRef<any> { return this.taskRef.createComponent(portal.component, { injector: portal.injector ?? undefined }); }
  detach() { this.taskRef.detach(); }
  dispose() { this.detach(); }
  hasAttached(): boolean { return !!this.taskRef.length; }

  /** Update current account being viewed. */
  private async review(view?: AccountData) {
    if (!view) return;
    this.account = view.account;
    this.member = view.account.members[0];
    this.memberService.set(...view.account.members.map(MemberName.from));

    // Open task passed to tab.
    if (view.task) {
      let _id = view.task.event?.result._account || ID_DEFAULT;
      if (_id !== this.account._id) {
        this.log.show(`Tried opening task for account: ${_id}\nNeed task for account: ${this.account._id}`, StatusLevel.Alert)
        return;
      }
      this.onTask(view.task);
    }
  }
}
