import { Component, ElementRef } from '@angular/core';
import { PanelComponent } from '../../../common/component/panel/panel.component';
import { TabRef } from '../../../common/component/tab/bar/tab-bar.model';

@Component({
  selector: 'app-claim-panel',
  templateUrl: './claim-panel.component.html',
  styleUrls: ['./claim-panel.component.scss']
})
export class ClaimPanelComponent extends PanelComponent {

  constructor(
    element: ElementRef<HTMLDivElement>,
    public ref: TabRef
  ) {
    super(element);
  }
}
