import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { AccountUnion } from '../../../../../../common/model/account/account';
import { DisplayPartial } from "../../../../../../common/model/display";
import { MemberJoin } from "../../../../../../common/model/member-join";
import { arrayEmpty } from "../../../../../../common/toolbox/array";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { GridConfig } from '../../../common/component/model/grid/model-grid.model';
import { AccountService } from '../../../common/service/account.service';
import { LogService } from '../../../common/service/log.service';
import { TableService } from '../../../common/service/table.service';
import { getRequest } from '../../../common/toolbox/request';
import { ClientSource } from '../../../common/toolbox/source/client';

@Component({
  selector: 'app-member-accounts',
  templateUrl: './member-accounts.component.html',
  styleUrls: ['./member-accounts.component.scss']
})
export class MemberAccountsComponent {

  /** Configuration for table */
  config?: GridConfig;

  /** Accounts being displayed. */
  private list: AccountUnion[] = [];
  
  /** Member to display accounts for. */
  @Input() set member(member: MemberJoin) { this.remember(member); }

  constructor(
    public accounts: AccountService,
    private log: LogService,
    private tables: TableService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    let table = await this.tables.setting('general.member.accountTable');
    let partials = this.list.map<DisplayPartial>(account => ({ account }));
    this.config = new GridConfig(new ClientSource(partials), table);
  }

  /** Callback when selecting an account. */
  onAccount(partial: DisplayPartial) {
    let account = partial.account;
    if (!account) {
      this.log.show('Selected account did not exist.', StatusLevel.Alert);
      return;
    }

    this.accounts.work({ account });
  }

  /** Update view after new member bound. */
  private async remember(member: MemberJoin) {
    if (arrayEmpty(member.accounts)) return;
    let accounts = await getRequest(this.http, 'accounts', { _insts: [member._inst], _ids: member.accounts.map(a => a._id) });
    if (errorResponse(accounts)) {
      this.log.show(accounts);
      return;
    }
    
    this.list = accounts.items;
    if (this.config) this.config.source.items = this.list.map<DisplayPartial>(account => ({ account }));
  }
}
