import { Directive, HostListener, Input } from '@angular/core';
import { AttachmentLike } from "../../../../../common/model/attachment";
import { AttachmentService } from '../service/attachment.service';

@Directive({
  selector: '[attachment-link]'
})
export class AttachmentLinkDirective {
  @Input('attachment-link') set attachment(attachment: AttachmentLike) { this._attachment = attachment; }

  /** Last bound attachment value. */
  private _attachment?: AttachmentLike;

  constructor(
    public attachments: AttachmentService
  ) {}

  /** Called when clicking download. */
  @HostListener('click', ['$event'])
  async onClick($event: MouseEvent) {
    $event.preventDefault();
    if (!this._attachment) return;
    this.attachments.download(this._attachment);
  }
}
