import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { SchemaInfo } from "../info/schema";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT } from "../toolbox/id";
import { EnumValidator } from "../validator/enum";
import { DisplayType, DisplayValue } from "./display";
import { Condition } from "./formula/condition";
import { Sort } from "./formula/sort";
import { FusionCollection } from "./fusion";
import { SCHEMA_OBJECT, schemaArray } from "./schema/base";

/** List of conditions to execute. */
export class FilterRule<T extends DisplayType = DisplayType> {
  
  constructor(
    /** Unique identifier of filter rule. */
    public _id = ID_DEFAULT,
    /** Institution that created filter rule. */
    public _inst = ID_DEFAULT,
    /** Model this filter rule is associated with. */
    public _model?: string,
    /** Name of filter rule. */
    public name = '',
    /** Type of filter rule. */
    public type = DisplayType.Account as T,
    /** True if this is a sytem filter rule and cannot be edited. */
    public system?: boolean,
    /** True if filter rule has been modified. */
    public dirty?: boolean,
    /** List of conditions. */
    public conditions: Condition<DisplayValue[T]>[] = [],
    /** List of sorts. */
    public sorts: Sort<DisplayValue[T]>[] = []
  ) {}
  
  static typeinfo: TypeInfo<FilterRule> = {
    dirty: false,
    system: false,
    type: new EnumValidator(DisplayType),
    _model: ID_DEFAULT,
    conditions: [{} as Condition],
    sorts: [{} as Sort]
  }

  static collectioninfo: CollectionInfo<FusionCollection, FilterRule> = {
    _inst: 'institutions',
    _model: 'models'
  }

  // Can only validate top-level statement due to infinite recursion of filter rule.
  static schemainfo: SchemaInfo<FilterRule> = {
    conditions: schemaArray(SCHEMA_OBJECT),
    sorts: schemaArray(SCHEMA_OBJECT)
  }

  static indexinfo: IndexInfo<FilterRule> = [
    { key: { _inst: 1, _model: 1 } },
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** A preview for a formula. */
export class FilterRulePreview {
  constructor(
    /** Unique identifier of filter rule. */
    public _id = ID_DEFAULT,
    /** Institution that created filter rule. */
    public _inst = ID_DEFAULT,
    /** Name of filter rule. */
    public name = '',
    /** Type of filter rule. */
    public type = DisplayType.Account,
    /** True if this is a sytem filter rule and cannot be edited. */
    public system?: boolean
  ) { }
  
  static typeinfo: TypeInfo<FilterRulePreview> = {
    system: false
  }
}