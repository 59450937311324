import { CollectionsInboundContactResponse, CollectionsOutboundContactResponse, CollectionsPromiseResponse } from "../../../code/standard/collections"
import { CollectionsCode } from "../../../code/system"
import { PropertyInfo } from "../../../info/prop"
import { CURRENCY_DEFAULT } from "../../../toolbox/currency"
import { EMAIL_DEFAULT } from "../../../toolbox/email"
import { ID_DEFAULT } from "../../../toolbox/id"
import { PHONE_DEFAULT } from "../../../toolbox/phone"
import { EventReminder } from "../../event/reminder"
import { PropertyType } from "../../property-type"

/** Built-in collections models. */
export enum CollectionsModel {
  InboundContact    = 'Collections - Inbound Contact',
  OutboundContact   = 'Collections - Outbound Contact',
  Payment           = 'Collections - Payment',
  Promise           = 'Collections - Promise',
}

/** Result of performing an inbound contact. */
export class ModelCollectionsInboundContact {
  constructor(
    /** Member that was contacted. */
    public _member = ID_DEFAULT,
    /** Number that was contacted from. */
    public phone = PHONE_DEFAULT,
    /** Result of customer's call. */
    public response = CollectionsInboundContactResponse.Email,
    /** Comment on result. */
    public comment = ''
  ) {}

  static propinfo: PropertyInfo<ModelCollectionsInboundContact> = {
    _member: { type: PropertyType.Member },
    phone: { type: PropertyType.Phone, link: '_member' },
    response: { type: PropertyType.Code, category: CollectionsCode.InboundContactResponse }
  }
}

/** Result of performing an outbound contact. */
export class ModelCollectionsOutboundContact {
  constructor(
    /** Member that was contacted. */
    public _member = ID_DEFAULT,
    /** Number used for contact. */
    public phone = PHONE_DEFAULT,
    /** Email used for contact. */
    public email = EMAIL_DEFAULT,
    /** Result of calling customer. */
    public response = CollectionsOutboundContactResponse.BadPhone,
    /** Document template that was used. */
    public _documentTemplate = '',
    /** Comment on result. */
    public comment = ''
  ) {}

  static propinfo: PropertyInfo<ModelCollectionsOutboundContact> = {
    _member: { type: PropertyType.Member },
    phone: { type: PropertyType.Phone, link: '_member' },
    email: { type: PropertyType.Email, link: '_member'},
    response: { type: PropertyType.Code, category: CollectionsCode.OutboundContactResponse }
  }
}

/** Result of initiating payment. */
export class ModelCollectionsPayment {
  constructor(
    /** Member that completed payment. */
    public _member = ID_DEFAULT,
    /** Amount that was received. */
    public amount = CURRENCY_DEFAULT,
    /** Comment on result. */
    public comment = ''
  ) {}

  static propinfo: PropertyInfo<ModelCollectionsPayment> = {
    _member: { type: PropertyType.Member }
  }
}

/** Result of establishing a promise to pay. */
export class ModelCollectionsPromise extends EventReminder {
  constructor(
    /** Customer that promsied this amount. */
    public _member = ID_DEFAULT,
    /** Amount that was promised. */
    public amount = CURRENCY_DEFAULT,
    /** Date that amount will be paid. */
    public deadline = new Date(),
    /** Result of a customer's promise. */
    public response = CollectionsPromiseResponse.Broken,
    /** Comment on result. */
    public comment = ''
  ) {
    super();
  }

  static override propinfo: PropertyInfo<ModelCollectionsPromise> = {
    ...EventReminder.propinfo,
    _member: { type: PropertyType.Member, locked: true },
    amount: { type: PropertyType.Currency, locked: true },
    deadline: { type: PropertyType.Date, value: 7, min: 1, max: 14, locked: true },
    response: { type: PropertyType.Code, category: CollectionsCode.PromiseResponse }
  }
}