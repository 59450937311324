import { Component } from '@angular/core';
import { CommonCode } from '../../../../../../../../common/code/system';
import { ClaimFilterAssigned } from '../../../../../../../../common/model/claim/filter';
import { AuthService } from '../../../../../common/service/auth.service';
import { ClaimFilterAccessor } from '../../edit/setup-claim-filter-edit.model';

@Component({
  selector: 'app-setup-claim-filter-assigned',
  templateUrl: './setup-claim-filter-assigned.component.html',
  styleUrls: ['./setup-claim-filter-assigned.component.scss']
})
export class SetupClaimFilterAssignedComponent extends ClaimFilterAccessor<ClaimFilterAssigned> {
  readonly CommonCode = CommonCode;

  constructor(
    public auth: AuthService
  ) {
    super();
  }
}
