import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { TypeInfo } from "../../../../../../../common/info/type";
import { ErrorResponse } from "../../../../../../../common/message/error";
import { DisplayPartial } from "../../../../../../../common/model/display";
import { Dispute, DisputeACH, DisputeUnion } from "../../../../../../../common/model/dispute/dispute";
import { arrayDefined, arraySet, arraySome } from "../../../../../../../common/toolbox/array";
import { arrayMap } from "../../../../../../../common/toolbox/enum";
import { formulaRun } from "../../../../../../../common/toolbox/formula/formula";
import { NoIdInst } from "../../../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../../../common/toolbox/message";
import { deepCopy } from "../../../../../../../common/toolbox/object";
import { DIALOG_DATA, DialogRef } from "../../../../common/component/dialog/dialog.model";
import { AuthService } from "../../../../common/service/auth.service";
import { FormulaService } from "../../../../common/service/formula.service";
import { LedgerConfigService } from "../../../../common/service/ledger-config.service";
import { LogService } from "../../../../common/service/log.service";
import { RevisionService } from "../../../../common/service/revision.service";
import { postRequest } from "../../../../common/toolbox/request";
import { LedgerStepperDialogData, LedgerStepperDialogReturn } from "./ledger-stepper-dialog.model";
import { DialogService } from "../../../../common/component/dialog/dialog.service";
import { LedgerWithPostInfo } from "../../list/ledger-list.model";
import { LedgerItem } from "../../../../../../../common/model/ledger/item";



/** A dispute and associated list of ledger items to add. */
export class DisputeJoinLedgerUploads {
  constructor(
    /** Dispute to attach ledger items to. */
    public dispute: Dispute = new DisputeACH(),
    /** List of ledger items to add. */
    public ledgers: NoIdInst<LedgerWithPostInfo>[] = []
  ) {}

  static typeinfo: TypeInfo<DisputeJoinLedgerUploads> = {
    ledgers: [{
      ledger: new LedgerItem(),
      shouldPost: true
    }]
  }
}

@Component({
  selector: 'app-ledger-stepper-dialog',
  templateUrl: './ledger-stepper-dialog.component.html',
  styleUrls: ['./ledger-stepper-dialog.component.scss']
})
export class LedgerStepperDialogComponent {

  static title() {
    return 'Confirm Ledger Items';
  }

  /** List of dispute ledger items to push. */
  uploads: DisputeJoinLedgerUploads[] = [];

  constructor(
    @Inject(DIALOG_DATA)
    public data: LedgerStepperDialogData,
    protected dialogRef: DialogRef<LedgerStepperDialogReturn>,
    private ledgerConfigs: LedgerConfigService,
    private formulas: FormulaService,
    private revisions: RevisionService,
    private log: LogService,
    private http: HttpClient,
    private auth: AuthService,
    private dialog: DialogService
  ) {}

  /** Callback when submitting ledger items. */
  async onSubmit() {
    // Pre-fetch list of configs associated with these items and latest archived revision of config.
    let _configs = this.uploads.flatMap(upload => upload.ledgers.map(ledger => ledger.ledger._config));
    let [configMap, revisionMap] = await Promise.all([
      this.ledgerConfigs.map(arraySet(arrayDefined(_configs)).map(_id => ({ _inst: this.auth._inst, _id }))),
      this.revisions.map(arraySet(arrayDefined(_configs)).map(_id => ({ _inst: this.auth._inst, _id, collection: 'ledgerConfigs' })))
    ]);

    // Pre-fetch list of formulas associated with these configs.
    let _formulas = [...configMap.values()].flatMap(config => config._submitFormulas);
    let formulaQueries = arraySet(arrayDefined(_formulas)).map(_id => ({ _inst: this.auth._inst, _id }));
    let formulaMap = await this.formulas.map(formulaQueries);

    // Execute formulas for newly-added items.
    for (let upload of this.uploads) {
      for (let ledger of upload.ledgers) {
        // Get config of this item.
        if (!ledger.ledger._config) continue;
        let config = configMap.get(ledger.ledger._config);
        if (!config) continue;

        // Run each formula of this config.
        if (!config._submitFormulas) continue;
        for (let _formula of config._submitFormulas) {
          let formula = formulaMap.get(_formula);
          if (!formula) continue;

          let partial: DisplayPartial = {
            ...this.data.data.partial,
            dispute: upload.dispute as DisputeUnion
          };
  
          formulaRun(formula, partial);
        }
      }
    }

    // Swap ledger config IDs over to latest revisions.
    let uploads = deepCopy(this.uploads);
    for (let upload of uploads) {
      let ledgers = upload.ledgers.map(ledger => ledger.ledger);
      for (let ledger of ledgers) {
        if (ledger._config === undefined) continue;
        let _id = revisionMap.get(ledger._config)?._id;
        if (!_id) return this.log.show(new ErrorResponse(`Failed to remap ledger config to latest revision: ${ledger._config}`, undefined, upload));
        ledger._config = _id;
      }
    }

    // Submit pending list of ledger items.
    if (!arraySome(uploads)) return this.dialogRef.close(true);
    let result = await postRequest(this.http, 'disputes/ledgers', {
      // Strip each dispute down to just _inst and _id.
      uploads: arrayMap(uploads, upload => ({
        _inst: upload.dispute._inst,
        _dispute: upload.dispute._id,
        ledgers: upload.ledgers.map(ledger=>ledger.ledger)
      }))
    });
    if (errorResponse(result)) return this.log.show(result);

    // Apply changes to disputes locally.
    for (let dispute of LedgerStepperDialogData.disputes(this.data)) {
      let _ledgers = result.find(({ _dispute }) => _dispute === dispute._id)?._ledgers;
      if (!_ledgers) return this.log.show(new ErrorResponse(`Failed to find ledger items for dispute: ${dispute._id}`, undefined, dispute));
      dispute._ledgers.push(..._ledgers);
    }

    //Attempt to post any applicable ledger items to the core
    let ledgersToPost = this.uploads.flatMap(upload => upload.ledgers.filter(ledger => ledger.shouldPost).map(ledger => ledger.ledger));
    if (ledgersToPost.length > 0 && await this.dialog.confirm(`Please confirm you would like to post ${ledgersToPost.length} transaction(s) to the core`)) {
      let postResults = await postRequest(this.http, 'transactions', { _inst: this.auth._inst, ledgers: ledgersToPost });
      if (errorResponse(postResults)) this.log.show(postResults.error);

    }
    this.dialogRef.close(true);
  }
}
