import { Component } from '@angular/core';
import { TerminalPermission } from "../../../../../../../../common/model/formula/terminal";
import { PERMISSION_NAME } from '../../../../../../../../common/model/permission';
import { enumMapPairs } from '../../../../../../../../common/toolbox/enum';
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-permission',
  templateUrl: './setup-formula-permission.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-permission.component.scss']
})
export class SetupFormulaPermissionComponent extends BlockComponent<TerminalPermission> {
  /** List of permissions for selection. */
  permissions = enumMapPairs(PERMISSION_NAME);
}
