import { Component, EventEmitter } from '@angular/core';
import { Direction } from '../../../../../../../common/model/direction';
import { Transaction } from "../../../../../../../common/model/transaction";

/** Base interface shared by all claim steps. */
@Component({
  template: '',
  host: {
    class: 'flex fill'
  }
})
export class ClaimIntakeStepComponent<T = any> {
  /** Emits when step should be changed. */
  offset = new EventEmitter<Direction>();
  /** Emits when steps after current should be locked. */
  lock = new EventEmitter<void>();  
  /**
   *  Emits when new transactions are selected.
   *  TODO Hack to ensure that outer transaction list and inner one stay in sync.
   */
  transactions = new EventEmitter<Transaction[]>();

  /** Data of component. */
  data!: T;
}