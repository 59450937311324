import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, InjectionToken, Injector, Input, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CodeType } from '../../../../../../../common/model/code-type';
import { Display, DisplayPartial, DisplayValue } from "../../../../../../../common/model/display";
import { Formula } from '../../../../../../../common/model/formula/formula';
import { Model } from "../../../../../../../common/model/model";
import { ColumnSize, Table } from "../../../../../../../common/model/table";
import { formulaRun } from '../../../../../../../common/toolbox/formula/formula';
import { NestedKey, keyNestedGet } from '../../../../../../../common/toolbox/keys';
import { errorResponse } from '../../../../../../../common/toolbox/message';
import { modelMap } from "../../../../../../../common/toolbox/model";
import { PropertyValuePipe } from '../../../pipe/property-value.pipe';
import { AuthService } from '../../../service/auth.service';
import { CodeTypeService } from '../../../service/code-type.service';
import { FormulaService } from '../../../service/formula.service';
import { LogService } from '../../../service/log.service';
import { ModelService } from '../../../service/model.service';
import { setupElementMake } from '../../../toolbox/element/setup';
import { fileDownload } from '../../../toolbox/file';
import { ColumnSort } from '../../../toolbox/grid';
import { ClientSource } from '../../../toolbox/source/client';
import { GridSource } from '../../../toolbox/source/grid';
import { GridColumn, GridConfig } from './model-grid.model';

 /** Injection token for custom columns in grid rows. */
export const GRID_ROW = new InjectionToken('GRID_ROW');

@Component({
  selector: 'app-model-grid',
  templateUrl: './model-grid.component.html',
  styleUrls: ['./model-grid.component.scss'],
  providers: [
    PropertyValuePipe
  ],
})
export class ModelGridComponent<T extends DisplayPartial = DisplayPartial> {
  /** Pre-initialized display value for getting type information. */
  readonly VALUE: DisplayPartial = new DisplayValue();

  /** True to expand table to full height, without scrollbar. */
  @Input() expand = false;
  /** True to display paginator at bottom. */
  @Input() paginator = true;
  /** True if value selection is required. */
  @Input() required = false;
  /** True to display loading spinner. */
  @Input() loading = false;
  /** True to show download button. */
  @Input() showDownload = true;
  /** Additional columns to add before main list. */
  @Input() precolumns: GridColumn[] = [];
  /** Additional columns to add after main list. */
  @Input() postcolumns: GridColumn[] = [];
  
  /** Configuration for model table. */
  @Input() set config(config: GridConfig<T> | undefined) { this.refresh(config); }
  /** True if multiple values can be selected in table. */
  @Input() set multiple(multiple: BooleanInput) { this._multiple = coerceBooleanProperty(multiple); }

  /** Emits when selecting a row. */
  @Output() rowChange = new EventEmitter<T>();
  /** Emits when sort changes. */
  @Output() sortChange = new EventEmitter<ColumnSort<T>>();
  /** Emits when selecting rows. */
  @Output() selectionChange = new EventEmitter<T[]>();

  /** Model for table. */
  model = new Model();
  /** Map for formatting columns. */
  map = modelMap(this.model);
  /** Table configuration for display. */
  table = new Table();
  /** Formula to determine how to highlight a row of a table. */
  highlightFormula?: Formula
  /** Formulas used to highlight cells within a row of a table. */
  cellHighlightFormulas: (Formula | undefined)[] = [];
  /** Sizes for table columns. */
  sizes?: ColumnSize[];
  /** Names of columns to display. */
  names = Table.names(this.table.columns);
  /** List of items in table. */
  source: GridSource<T> = new ClientSource<T>();
  /** True if multivalue model grid. */
  _multiple = false;

  /** List of enums available for formatting properties. */
  protected enums = CodeType.enum([]);

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();
  /** Cached formulas of current model. */
  private cache: Record<string, Formula> = {};

  constructor(
    protected auth: AuthService,
    protected models: ModelService,
    private pipe: PropertyValuePipe,
    private log: LogService,
    private types: CodeTypeService,
    private injector: Injector,
    private formulas: FormulaService,
    private elementRef: ElementRef
  ) { }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when downloading data of grid. */
  async onDownload() {
    let report = await this.pipe.rows(this.auth._inst, this.source.items, this.VALUE, Table.pairs(this.table), this.enums);
    if (errorResponse(report)) return this.log.show(report);
    fileDownload(report, 'table.csv');
  }

  /** Internal list of injectors for each row. */
  protected injectors: Injector[] = [];

  /** Refresh table after getting new config. */
  protected async refresh(config: GridConfig<T> | undefined) {
    if (!config?.table.columns.length) return;
    setupElementMake(this.elementRef.nativeElement, 'tables', config.table._id);

    // Fetch model of this table.
    let [model, highlightFormula, cellHighlightFormulas] = await Promise.all([
      config.table._model ? await this.models.item({ _inst: this.auth._inst, _id: config.table._model }) : new Model(),
      config.table._highlightFormula ? await this.formulas.item({ _inst: this.auth._inst, _id: config.table._highlightFormula }) : undefined,
      await Promise.all(config.table.columns.map(async (column) => column._colorFormula ? await this.formulas.item({ _inst: this.auth._inst, _id: column._colorFormula }): undefined))
    ]);
    let columns = [...this.precolumns, ...config.table.columns, ...this.postcolumns];

    // Pre-fetch displayed formulas.
    let formulas = await this.formulas.items(Display.formulas(columns).map(_id => ({ _inst: this.auth._inst, _id })));
    for (let formula of formulas) this.cache[formula._id] = formula;

    // Fetch all needed codes.
    let value = DisplayValue.model(model);
    let categories = Table.codes(config.table, value, formulas);
    let types = await this.types.items(categories.map(category => ({ _inst: this.auth._inst, category })));
    this.enums = CodeType.enum(types);

    this.model = model;
    this.map = modelMap(this.model);
    this.table = config.table;
    this.highlightFormula = highlightFormula;
    this.cellHighlightFormulas = cellHighlightFormulas;

    this.sizes = Table.size(columns);
    this.names = Table.names(columns);
    this.resource(config.source);
  }

  private resource(source: GridSource<T>) {
    this.source = source;

    this.source.dataChange.pipe(takeUntil(this.destroy))
      .subscribe(rows => this.reinject(rows));

    source.extract = (key: NestedKey<DisplayPartial>, row: DisplayPartial) => {
      let formula = this.cache[Display.formula(key) ?? ''];
      
      if (formula) {
        // If it's a formula column, then use the value of the formula.
        return formulaRun(formula, row);
      } else {
        // Otherwise, use the default accessor.
        return keyNestedGet(key, row);
      }
    }
  }
  /** Recreate list of injectors. */
  private reinject(rows: T[]) {
    this.injectors = rows.map(row => Injector.create([{
      provide: GRID_ROW,
      useValue: row
    }], this.injector));
  }
}
