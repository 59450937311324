import { Diff } from "../../../../../../common/toolbox/diff";
import { HasName } from "../../../../../../common/toolbox/id";

export class SetupDiscardDialogData {
  constructor(
    /** Diff of values. */
    public diff: Diff,
    /** Value currently opened in interface. */
    public open: HasName | undefined,
    /** New value. */
    public opening: HasName | undefined
  ) {}
}

/** Result of opening setup discard dialog. */
export type SetupDiscardDialogReturn = boolean;