import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[visible-if]'
})
export class VisibleIfDirective {
  /** Sets text of tooltip. */
  @Input('visible-if') set value(value: any) {
    this.visibility = value ? 'visible' : 'hidden';
  }

  @HostBinding('style.visibility')
  visibility: 'visible' | 'hidden' = 'hidden';
}
