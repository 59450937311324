import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { CommonCode } from '../../../../../../common/code/system';
import { Address } from '../../../../../../common/model/address';
import { idNull } from '../../../../../../common/toolbox/id';
import { AuthService } from '../../service/auth.service';
import { fieldControlProviders } from '../../toolbox/angular';
import { debugElementMake } from '../../toolbox/element/debug';
import { FieldControl } from '../field/field-control';

/** Types of string inputs. */
export type AddressEditComponentType = 'password' | null;

@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss'],
  providers: fieldControlProviders(AddressEditComponent)
})
export class AddressEditComponent extends FieldControl implements ControlValueAccessor {
  readonly CommonCode = CommonCode;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  
  /** Institution to resolve code types. */
  institution = this.auth._inst;
  /** Current input value. */
  value = new Address();

  constructor(
    public elementRef: ElementRef,
    protected auth: AuthService
  ) {
    super();
    debugElementMake(this);
  }

  writeValue(value: Address) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    if (!idNull(value._inst)) this.institution = value._inst;
    this.value = value;
  }
}