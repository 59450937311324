import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { DocumentationComponent } from '../documentation/documentation.component';

@NgModule({
  declarations: [
    DocumentationComponent
  ],
  imports: [
    SharedModule
  ]
})
export class DocumentationModule { }
