import { Component, Inject } from '@angular/core';
import { ColorCode } from "../../../../../../common/toolbox/color";
import { PercentItem } from '../../../common/component/percent/percent.model';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { ExecutiveReport } from '../../executive/report/executive-report.model';
import { DisputesExecutiveData } from './disputes-executive.model';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../common/service/auth.service';
import { postRequest } from '../../../common/toolbox/request';
import { Condition, ConditionType } from '../../../../../../common/model/formula/condition';
import { ClaimJoin } from '../../../../../../common/model/claim/claim';
import { TerminalType } from '../../../../../../common/model/formula/terminal';
import { ClaimStatus } from '../../../../../../common/code/standard/disputes';
import { DisputesCode } from '../../../../../../common/code/system';
import { errorPartition, errorResponse } from '../../../../../../common/toolbox/message';
import { LogService } from '../../../common/service/log.service';
import { CodeTypeService } from '../../../common/service/code-type.service';

@Component({
  selector: 'app-disputes-executive',
  templateUrl: './disputes-executive.component.html',
  styleUrls: ['./disputes-executive.component.scss'],
  host: {
    class: 'column'
  }
})
export class DisputesExecutiveComponent {
  readonly builtinReports: [Condition<ClaimJoin>[], Omit<ExecutiveReport, 'metrics' | 'headers' | 'conditions'>][] = [
    [[{
      type: ConditionType.GreaterEqual,
      left: {
        type: TerminalType.Identifier,
        key: 'intakeDate'
      },
      right: {
        type: TerminalType.Date,
        value: -10
      }
    }], {
      title: 'Open 0-10 Days',
      color: ColorCode.Secondary,
      options: {}
    }], [[{
      type: ConditionType.LesserEqual,
      left: {
        type: TerminalType.Identifier,
        key: 'intakeDate'
      },
      right: {
        type: TerminalType.Date,
        value: -10
      }
    }, {
      type: ConditionType.GreaterEqual,
      left: {
        type: TerminalType.Identifier,
        key: 'intakeDate'
      },
      right: {
        type: TerminalType.Date,
        value: -30
      }
    }], {
      title: 'Open 10-30 Days',
      color: ColorCode.Green,
      options: {}
    }],
    [[{
      type: ConditionType.LesserEqual,
      left: {
        type: TerminalType.Identifier,
        key: 'intakeDate'
      },
      right: {
        type: TerminalType.Date,
        value: -30
      }
    }, {
      type: ConditionType.GreaterEqual,
      left: {
        type: TerminalType.Identifier,
        key: 'intakeDate'
      },
      right: {
        type: TerminalType.Date,
        value: -60
      }
    }], {
      title: 'Open 31-60 Days',
      color: ColorCode.Yellow,
      options: {}
    }],
    [[{
      type: ConditionType.LesserEqual,
      left: {
        type: TerminalType.Identifier,
        key: 'intakeDate'
      },
      right: {
        type: TerminalType.Date,
        value: -60
      }
    }, {
      type: ConditionType.GreaterEqual,
      left: {
        type: TerminalType.Identifier,
        key: 'intakeDate'
      },
      right: {
        type: TerminalType.Date,
        value: -90
      }
    }], {
      title: 'Open 60-90 Days',
      color: ColorCode.Orange,
      options: {}
    }],
    [[{
      type: ConditionType.LesserEqual,
      left: {
        type: TerminalType.Identifier,
        key: 'intakeDate'
      },
      right: {
        type: TerminalType.Date,
        value: -90
      }
    }], {
      title: 'Open 90+ Days',
      color: ColorCode.Red,
      options: {}
    }]
  ];
  /** List of reports to view. */
  reports: ExecutiveReport[] = [];

  /** Default title for tab. */
  static title() {
    return 'Disputes Executive Dashboard';
  }

  constructor(
    @Inject(TAB_DATA)
    public data: DisputesExecutiveData,
    private http: HttpClient,
    private auth: AuthService,
    private log: LogService,
    private codes: CodeTypeService
  ) { }

  async ngOnInit() {
    let openCondition: Condition<ClaimJoin> = {
      type: ConditionType.Unequal,
      left: {
        type: TerminalType.Identifier,
        key: 'status'
      },
      right: {
        type: TerminalType.Code,
        value: ClaimStatus.Resolved,
        category: DisputesCode.ClaimStatus
      }
    }
    
    let result = await Promise.all(this.builtinReports.map(async ([conditions, report]) => {
      let stats = await postRequest(this.http, 'claims/stat', { _insts: [this.auth._inst], groupKey: 'type', conditions: [openCondition, ...conditions] });
      if (errorResponse(stats)) return stats;
      let percentItems = await Promise.all(stats.map(async stat => {
        let name = await this.codes.codename(stat._id, { _inst: this.auth._inst, category: DisputesCode.ClaimType });
        return new PercentItem(name, stat.count)
      }));
      percentItems.sort((a, b) => a.label.localeCompare(b.label));
      return {
        ...report,
        headers: [],
        metrics: percentItems,
        options: {
          total: true,
          percentages: true
        },
        conditions
      }
    }));
    let [errors, reports] = errorPartition('Failed to get claim stats', result);
    if (errors) return this.log.show(errors);
    this.reports = reports;
  }
}
