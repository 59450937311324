import { Pipe } from '@angular/core';
import { Icon, IconMap } from '../../../../../common/model/icon';

@Pipe({
  name: 'icon'
})
export class IconPipe {

  transform<T extends PropertyKey>(value: T, map: IconMap<T>): Icon
  transform<T extends PropertyKey>(value: T | undefined, map: IconMap<T>, fallback: Icon): Icon
  transform<T extends PropertyKey>(value: T | undefined, map: IconMap<T>, fallback?: Icon) {
    return value === undefined ? fallback : map[value];
  }
}
