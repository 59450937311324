import { EnumBankMap } from "../toolbox/enum"
import { NestedKey } from "../toolbox/keys"
import { objectCompose, objectKeys } from "../toolbox/object"
import { StringRename } from "../toolbox/string"
import { CollectionComments, CollectionRevisions, CollectionSystem, CollectionTags, CollectionTest } from "./collection"
import { FusionCollection, FusionCollectionName } from "./fusion"

/*
 * Additional collection mappings pulled into a separate file to prevent circular dependencies.
 * Any mappings not dependent on the objects themselves should be tracked here.
 */

/** List of additional tag annotations for collections. */
export enum FusionCollectionTag {
  /** User data created operators, eg. accounts, members. */
  User = 'user',
  /** Configurable resources edited by supervisors, eg. forms, tables. */
  Setup = 'setup'
}

/** List of schemas that can be commented on. */
export const FUSION_COLLECTION_COMMENTS: Record<CollectionComments<FusionCollection>, 1> = {
  accounts: 1,
  attachments: 1,
  claims: 1,
  disputes: 1,
  members: 1
}

/** List of schemas that contain test data. */
export const FUSION_COLLECTION_TEST: Record<CollectionTest<FusionCollection>, 1> = {
  accounts: 1,
  addresses: 1,
  cards: 1,
  claims: 1,
  members: 1,
  transactions: 1
}

/** List of schemas that contain potentially system data. */
export const FUSION_COLLECTION_SYSTEM: Record<CollectionSystem<FusionCollection>, 1> = {
  addresses: 1,
  codeTypes: 1,
  documentTemplates: 1,
  filterRules: 1,
  forms: 1,
  formLists: 1,
  formulas: 1,
  models: 1,
  tables: 1,
  tasks: 1,
  workQueues: 1
}

/** List of schemas with a revision history. */
export const FUSION_COLLECTION_REVISIONS: Record<CollectionRevisions<FusionCollection>, 1> = {
  ledgerConfigs: 1
}

/**
 *  Name of each Fusion collection.
 *  NOTE: Doubles as a runtime list of collection names that won't trigger circular imports.
 */
export const FUSION_COLLECTION_NAME: EnumBankMap<FusionCollectionName> = {
  accountCards: 'Account Cards',
  accountMembers: { bank: 'Account Customers', creditUnion: 'Account Members' },
  accounts: 'Accounts',
  addresses: 'Addresses',
  attachments: 'Attachments',
  cache: 'Cache',
  cards: 'Cards',
  claims: 'Claims',
  codeTypes: 'Code Types',
  claimFilters: 'Claim Filters',
  counters: 'Counters',
  disputes: 'Disputes',
  documentTemplates: 'Document Templates',
  events: 'Events',
  filterRules: 'Filter Rules',
  forms: 'Forms',
  formLists: 'Form Lists',
  formulas: 'Formulas',
  importConfig: 'Import Configurations',
  institutions: 'Institutions',
  jobs: 'Jobs',
  ledgers: 'Ledgers',
  ledgerConfigs: 'GL Posting Configuration',
  memberCards: { bank: 'Customer Cards', creditUnion: 'Member Cards' },
  members: { bank: 'Customers', creditUnion: 'Members' },
  models: 'Models',
  organizations: 'Organizations',
  profiles: 'Profiles',
  settingGroups: 'Setting Groups',
  tables: 'Tables',
  tasks: 'Tasks',
  transactions: 'Transactions',
  triggers: 'Triggers',
  users: 'Users',
  workflows: 'Workflows',
  workItems: 'Work Items',
  workQueues: 'Work Queues'
}

/** Tag for each fusion collection. */
export const FUSION_COLLECTION_TAG: CollectionTags<FusionCollection, FusionCollectionTag> = {
  accountCards: FusionCollectionTag.User,
  accountMembers: FusionCollectionTag.User,
  accounts: FusionCollectionTag.User,
  addresses: FusionCollectionTag.User,
  attachments: FusionCollectionTag.User,
  cache: FusionCollectionTag.User,
  cards: FusionCollectionTag.User,
  claims: FusionCollectionTag.User,
  codeTypes: FusionCollectionTag.Setup,
  claimFilters: FusionCollectionTag.Setup,
  counters: FusionCollectionTag.User,
  disputes: FusionCollectionTag.User,
  documentTemplates: FusionCollectionTag.Setup,
  events: FusionCollectionTag.User,
  filterRules: FusionCollectionTag.Setup,
  forms: FusionCollectionTag.Setup,
  formLists: FusionCollectionTag.Setup,
  formulas: FusionCollectionTag.Setup,
  importConfig: FusionCollectionTag.Setup,
  institutions: FusionCollectionTag.User,
  jobs: FusionCollectionTag.Setup,
  ledgers: FusionCollectionTag.User,
  ledgerConfigs: FusionCollectionTag.Setup,
  memberCards: FusionCollectionTag.User,
  members: FusionCollectionTag.User,
  models: FusionCollectionTag.Setup,
  organizations: FusionCollectionTag.User,
  profiles: FusionCollectionTag.Setup,
  settingGroups: FusionCollectionTag.Setup,
  tables: FusionCollectionTag.Setup,
  tasks: FusionCollectionTag.Setup,
  transactions: FusionCollectionTag.User,
  triggers: FusionCollectionTag.Setup,
  users: FusionCollectionTag.User,
  workflows: FusionCollectionTag.Setup,
  workItems: FusionCollectionTag.User,
  workQueues: FusionCollectionTag.Setup
}

/** Whitelist of object keys that are safe to rename in each collection. */
export const FUSION_COLLECTION_RENAME: { [K in FusionCollectionName]: NestedKey<FusionCollection[K]>[] } = {
  accountCards: [],
  accountMembers: [],
  accounts: [],
  addresses: [],
  attachments: [],
  cache: [],
  cards: [],
  claims: [],
  codeTypes: ['name'],
  claimFilters: ['name'],
  counters: [],
  disputes: [],
  documentTemplates: ['name'],
  events: [],
  filterRules: ['name'],
  forms: ['fields.name', 'name'],
  formLists: ['name'],
  formulas: ['name'],
  importConfig: [],
  institutions: [],
  jobs: ['name'],
  ledgers: [],
  ledgerConfigs: ['name'],
  memberCards: [],
  members: [],
  models: ['name', 'properties.name'],
  organizations: [],
  profiles: ['name'],
  settingGroups: ['name'],
  tables: ['columns.name', 'name'],
  tasks: ['config.approve.message', 'config.deny.message', 'description', 'message', 'name'],
  transactions: [],
  triggers: ['name'],
  users: [],
  workflows: ['actions.completion.description', 'actions.description', 'actions.enable.description', 'actions.name', 'name', 'steps.actions.completion.description', 'steps.actions.description', 'steps.actions.enable.description', 'steps.actions.name', 'steps.enable.description', 'steps.name'],
  workItems: [],
  workQueues: ['name']
};

/**
 *  Additional per-collection renaming logic to run.
 *  Accepts a document and returns true if replaces were made within document.
 */
export const FUSION_COLLECTION_RENAME_CALLBACK: Partial<{ [K in FusionCollectionName]: (value: FusionCollection[K], renames: StringRename[]) => boolean }> = {
  codeTypes: (type, renames) => {
    let dirty = false;
    
    for (let key in type.codes) {
      let value = type.codes[key]!;
      for (let rename of renames) {
        let renamed = value.replace(rename.from, rename.to);
        if (renamed === value) continue;

        type.codes[key] = value = renamed;
        dirty = true;
      }
    }

    return dirty;
  }
}

/** Get a fusion collection map for a particular type. */
export function fusionCollectionRecord<T>(value: T, partial: true): { [C in FusionCollectionName]?: T }
export function fusionCollectionRecord<T>(value: T, partial: false): { [C in FusionCollectionName]: T }
export function fusionCollectionRecord<T>(value: T, partial: boolean): { [C in FusionCollectionName]?: T } {
  return new class {
    constructor() {
      for (let collection in FUSION_COLLECTION_NAME) {
        (this as any)[collection] = partial ? undefined : value;
      }
    }

    static typeinfo = objectCompose(objectKeys(FUSION_COLLECTION_NAME).map(c => [c, value]));
  }
}