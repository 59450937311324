<app-card>
  <div class="row">
    <div class="controls">
      <button icon-button (click)="onSeek(+1)" tooltip="First item" [disabled]="!prev">first_page</button>
      <button icon-button (click)="onProgress(-1)" tooltip="Previous item" [disabled]="!prev">chevron_left</button>
      <button icon-button (click)="onProgress(+1,item,true)" tooltip="Mark as worked" [disabled]="item.stage === WorkStage.Worked">check</button>
      <button icon-button (click)="onProgress(+1)" tooltip="Next item" [disabled]="!next">chevron_right</button>
      <button icon-button (click)="onSeek(-1)" tooltip="Last item" [disabled]="!next">last_page</button>
    </div>
    <a (click)="toggle.next()" tooltip="Toggle view">{{queue.name}}</a>
    <div class="fill"></div>
    <app-select field tooltip="Work queue filter" [(ngModel)]="filter" (selected)="onFilter();" >
      <app-option *ngFor="let f of filters" [pair]="f"></app-option>
    </app-select>
    <app-progress [value]="queue.worked ?? 0" [max]="queue.count ?? 0" tooltip="Worked items" ></app-progress>
    <div class="controls">
      <app-badge inline tooltip="Work queue position">{{item.index + 1}}</app-badge>
      <button icon-button class="icon" [tooltip]="tooltip">{{icon}}</button>
      <button icon-button class="icon" [disabled]="!(Permission.WorkItemsEdit | permission)" (click)="onBookmark()" tooltip="Bookmark item">{{item.bookmark ? 'star' : 'star_outline'}}</button>
    </div>
  </div>
</app-card>