<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header class="row">
      <app-field class="fill">
        <app-label>Name</app-label>
        <app-string [(ngModel)]="value.name"></app-string>
      </app-field>
    </app-setup-base-header>
    <app-setup-base-body>
      <div class="list" [list-grid]="value.sections">
        <app-drag-chip cdkDrag cdkDragBoundary=".list" *ngFor="let s of value.sections; let i = index">
          <div class="inputs">
            <app-field full class="fill">
              <app-label>Form</app-label>
              <app-resource-select collection="forms" [(ngModel)]="s._form"></app-resource-select>
            </app-field>
            <app-field full class="fill">
              <app-label>Formula</app-label>
              <app-sublabel>
                <app-hover-help [tooltip]="SECTION_FORMULA_HELP"></app-hover-help>
              </app-sublabel>
              <app-resource-select collection="formulas" [(ngModel)]="s._formula" [required]="false"></app-resource-select>
            </app-field>
          </div>
          <button icon-button (click)="onDelete(s)" tooltip="Delete">delete</button>
          <button icon-button cdkDragHandle>drag_indicator</button>
        </app-drag-chip>
        <button button-prefix="add" position="before" (click)="onNew()">New</button>
      </div>
    </app-setup-base-body>
    <app-setup-base-footer>
      <app-setup-preview-button (partialChange)="partial = $event"></app-setup-preview-button>
      <button (click)="onSubmit()">Save</button>
      <button (click)="onPreview()">Preview</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>