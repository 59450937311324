import { Component, Inject } from '@angular/core';
import { TAB_DATA } from '../../../../common/component/tab/bar/tab-bar.model';
import { DemoStepData } from './demo-step.model';

@Component({
  selector: 'app-demo-step',
  templateUrl: './demo-step.component.html',
  styleUrls: ['./demo-step.component.scss']
})
export class DemoStepComponent {
  constructor(
    @Inject(TAB_DATA) public data: DemoStepData
  ) { }
}
