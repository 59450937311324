import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ImportCode } from '../../../../../../common/code/system';
import { Email } from '../../../../../../common/model/email';
import { ID_DEFAULT, idNull } from '../../../../../../common/toolbox/id';
import { AuthService } from '../../service/auth.service';
import { fieldControlProviders } from '../../toolbox/angular';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-emails-edit',
  templateUrl: './emails-edit.component.html',
  styleUrls: ['./emails-edit.component.scss'],
  providers: fieldControlProviders(EmailsEditComponent)
})
export class EmailsEditComponent extends FieldControl implements ControlValueAccessor {
  readonly ImportCode = ImportCode;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  
  /** Institution to resolve code types. */
  institution = ID_DEFAULT;
  /** Current input value. */
  value: Email[] = [];

  constructor(
    protected auth: AuthService
  ) {
    super();
  }

  writeValue(value: Email[]) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.institution = idNull(this.institution) ? this.auth._inst : this.institution;
    this.value = value;
  }

  /** Called when adding a new phone. */
  onNew() {
    this.value.push(new Email());
    this.dirtyChange.next(this.dirty = true);
  }

  /** Delete specified phone from list. */
  onDelete(i: number) {
    this.value.splice(i, 1);
    this.dirtyChange.next(this.dirty = true);
  }
}