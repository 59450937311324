import { BankUnion } from "../bank";
import { DisplayType } from "../display";
import { Field } from "../form/field";
import { Pos } from "../pos";
import { AccountForm, BUILTIN_ACCOUNT_FORMS } from "./form/account";
import { BUILTIN_CLAIM_FORMS, ClaimForm } from "./form/claim";
import { BUILTIN_DISPUTE_FORMS, DisputeForm } from "./form/dispute";
import { BUILTIN_MEMBER_FORMS, MemberForm } from "./form/member";
import { BUILTIN_MODEL_FORMS, ModelForm } from "./form/model";
import { BUILTIN_TEST_FORMS, TestForm } from "./form/test";
import { SystemFormula } from "./formula";
import { SystemModel } from "./model";

/** List of built-in system Forms. */
export type SystemForm =
  | AccountForm
  | ClaimForm
  | DisputeForm
  | MemberForm
  | ModelForm
  | TestForm;

/**
 *  List of standard built-in Forms added to new institution.
 *  TODO Add checks at institution setup that these are valid nested keys.
 */
export const BUILTIN_FORMS: Record<SystemForm, BuiltinForm> = {
  ...BUILTIN_ACCOUNT_FORMS,
  ...BUILTIN_CLAIM_FORMS,
  ...BUILTIN_DISPUTE_FORMS,
  ...BUILTIN_MEMBER_FORMS,
  ...BUILTIN_MODEL_FORMS,
  ...BUILTIN_TEST_FORMS
}

/** Configuration for a built-in form. */
export interface BuiltinForm {
  /** Name override for form. */
  name?: BankUnion<string>
  /** Explicit size for form, or default to shrink-wrapping fields. */
  size?: Pos
  /** Model used by form. */
  model?: SystemModel
  /** Formulas to execute on form open. */
  formulas?: SystemFormula[]
  /** Configuration for which display objects are editable. */
  editable?: DisplayType[]
  /** List of fields in form. */
  fields?: Field[]
}