import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { merge, Subject, takeUntil } from "rxjs";
import { Pos } from "../../../../../../common/model/pos";
import { objectDelete } from "../../../../../../common/toolbox/object";
import { overlayCreate } from "../../toolbox/angular";
import { ResizeComponent } from "./resize.component";

@Directive({
  selector: '[resize-trigger]',
  exportAs: 'resizeTrigger',
  host: {
    '(click)': 'toggle($event)'
  }
})
export class ResizeTriggerDirective {

  /** Sets initial size to display in calendar. */
  @Input('resize-trigger')
  get size() { return this._size; }
  set size(size: Pos | undefined) {
    this._size = size;
    this.resize?.writeValue(size);
  }

  /** Minimum selectable size. */
  @Input('resize-min')
  get min() { return this._min; }
  set min(min: Pos) {
    this._min = min;
    if (this.resize) this.resize.min = min;
  }

  /** Maximum selectable size. */
  @Input('resize-max')
  get max() { return this._max; }
  set max(max: Pos) {
    this._max = max;
    if (this.resize) this.resize.max = max;
  }

  /** Emits when bound size changes. */
  @Output() sizeChange = new EventEmitter<Pos>();

  /** Minimum selectable size. */
  private _min = new Pos(1, 1);
  /** Maximum selectable size. */
  private _max = new Pos(5, 5);
  /** Current bound size. */
  private _size?: Pos;

  /** Reference to resize this trigger opens. */
  private resize?: ResizeComponent;
  /** Opened overlay containing menu. */
  private overlayRef?: OverlayRef;
  /** Emits when new value is selected. */
  private select = new Subject<void>();
  /** Emits when element is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private element: ElementRef<HTMLElement>
  ) {}

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.overlayRef?.dispose();
  }

  /** Opens the resizer. */
  open() {
    if (this.resize) return;

    // Attach menu's template to portal.
    this.overlayRef = overlayCreate(this.element, this.overlay, this.overlayRef);
    const componentPortal = new ComponentPortal(ResizeComponent);
    let componentRef = this.overlayRef.attach(componentPortal);
    this.resize = componentRef.instance;
    this.resize.min = this._min;
    this.resize.max = this._max;
    this.resize.writeValue(this._size);
    this.resize.changed = (size: Pos) => {
      this.sizeChange.next(this._size = size);
      this.select.next();
    };

    // Listen for close clicks and value changes.
    merge(this.overlayRef.backdropClick(), this.select).pipe(takeUntil(this.destroy)).subscribe(() => {
      this.close();
    });
  }

  /** Closes the menu. */
  close() {
    this.overlayRef?.detach();
    objectDelete(this, 'resize' as any);
  }

  /** Callback when menu is clicked. */
  toggle() {
    this.resize ? this.close() : this.open();
  }
}

