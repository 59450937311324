import { BuiltinMap } from "../../toolbox/builtinmap";
import { modelFrom } from "../../toolbox/model";
import { Newable } from "../../toolbox/object";
import { CollectionsModel, ModelCollectionsInboundContact, ModelCollectionsOutboundContact, ModelCollectionsPayment, ModelCollectionsPromise } from "./model/collections";
import { DisputesModel, ModelClaimNoticeDate, ModelClaimViewReason, ModelDisputeIssueCredit } from "./model/disputes";
import { ModelStandardFollowUp, StandardModel } from "./model/standard";
import { ModelTestFields, ModelTestMultiple, TestModel } from "./model/test";

/** List of all builtin models. */
export type SystemModel = StandardModel | CollectionsModel | DisputesModel | TestModel;

/** Configuration for a built-in model. */
interface BuiltinModel {
  /** Override for model name. */
  name?: string
  /** Class used for configuring model. */
  class: Newable
  /** False if this model can be edited. */
  system?: boolean
}

/** List of standard built-in models added to new instituti+ons. */
export const BUILTIN_MODELS: Record<SystemModel, BuiltinModel> = {
  [CollectionsModel.InboundContact]: {
    class: ModelCollectionsInboundContact
  },
  [CollectionsModel.OutboundContact]: {
    class: ModelCollectionsOutboundContact
  },
  [CollectionsModel.Payment]: {
    class: ModelCollectionsPayment
  },
  [CollectionsModel.Promise]: {
    class: ModelCollectionsPromise
  },
  [DisputesModel.ClaimViewReason]: {
    class: ModelClaimViewReason
  },
  [DisputesModel.ClaimNoticeDate]: {
    class: ModelClaimNoticeDate
  },
  [DisputesModel.DisputeIssueCredit]: {
    class: ModelDisputeIssueCredit
  },
  [StandardModel.FollowUp]: {
    class: ModelStandardFollowUp
  },
  [TestModel.Field]: {
    class: ModelTestFields,
    system: false
  },
  [TestModel.Multiple]: {
    class: ModelTestMultiple,
    system: false
  },
  [TestModel.Empty]: {
    class: Object
  },
};

/** Create model from a builtin. */
export function builtinModel(map: BuiltinMap, name: SystemModel) {
  let builtin = map.models.builtin(name);
  let model = modelFrom(new builtin.class());
  model.name = name;
  return model;
}
