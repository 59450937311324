import { NATURAL_REGEX } from "../toolbox/number";
import { objectDefined } from "../toolbox/object";
import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";

/** Validate object is big integer. */
export class BigIntValidator extends Validator<bigint> {

  value() {
    return BigInt(0);
  }

  parse(value: string) {
    if (this.undefined(value)) return undefined;

    try {
      return BigInt(value);
    } catch (e) {
      return undefined;
    }
  }

  schema() {
    return this.property ?? objectDefined({
      bsonType: 'string',
      pattern: NATURAL_REGEX.source
    });
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    return typeof value === 'bigint' ? ValidationStatus.Okay : ValidationStatus.Error;
  }

  override logs() { return [new ValidatorLog(`Expected bigint.`)]; }
}