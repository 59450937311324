import { DisplayPartial } from "../../../../../../../common/model/display";
import { FormList } from "../../../../../../../common/model/form/list";
import { MaybeId } from "../../../../../../../common/toolbox/id";

export class SetupFormListPreviewData {
  constructor(
    /** Form list to preview. */
    public list: MaybeId<FormList>,
    /** Data to display in form list. */
    public partial: DisplayPartial
  ) {}
}