import { Component, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Step, StepStatus } from '../../../../../../common/model/step';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { DemoStepComponent } from './step/demo-step.component';
import { DemoStepData } from './step/demo-step.model';

@Component({
  selector: 'app-demo-stepper',
  templateUrl: './demo-stepper.component.html',
  styleUrls: ['./demo-stepper.component.scss'],
  host: {
    class: 'column'
  }
})
export class DemoStepperComponent {

  /** Container for current demo step. */
  @ViewChild('container', {read: ViewContainerRef}) container!: ViewContainerRef;

  /** Current step in process. */
  current = 1;
  /** List of steps in demo proces. */
  steps = [
    new Step(new DemoStepData('Start Process', 'Choose type of process.'), undefined),
    new Step(new DemoStepData('Enter Information', 'Enter additional information.'), undefined),
    new Step(new DemoStepData('Detailed Information', 'Detailed information is being validated.'), undefined, StepStatus.Loading),
    new Step(new DemoStepData('Resolve Warnings', 'Step is complete but additional actions are needed.'), undefined, StepStatus.Warning),
    new Step(new DemoStepData('Select Item', 'Select item from table.')),
    new Step(new DemoStepData('Incomplete Action', 'This step failed to process.'), undefined, StepStatus.Alert),
    new Step(new DemoStepData('Final Notes', 'Complete final notes.'), true),
    new Step(new DemoStepData('Review Details', 'Review process and complete.'), true)
  ];

  constructor(
    private injector: Injector
  ) {
    setTimeout(() => this.onStep(this.steps[this.current]!));
  }

  static title() {
    return 'Stepper';
  }

  /** Step progression can be achieved with a ViewContainerRef, nested route or simple ngSwitch in the template. */
  onStep(step: Step<DemoStepData>) {
    let injector = Injector.create({
      parent: this.injector,
      providers: [{
        provide: TAB_DATA,
        useValue: step.data
      }]
    });

    this.container.clear();
    this.container.createComponent(DemoStepComponent, { injector });
  }
}
