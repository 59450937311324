import { ConnectedPosition, FlexibleConnectedPositionStrategy, HorizontalConnectionPos, Overlay, OverlayRef, VerticalConnectionPos } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { ElementRef, NgZone, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";
import { Observable, first, merge } from "rxjs";
import { Pos } from "../../../../../common/model/pos";
import { arrayDefined } from "../../../../../common/toolbox/array";
import { safeAssign } from "../../../../../common/toolbox/object";
import { boxOnscreen } from "./box";

/** Interface with an openable overlay. */
export interface OverlayComponent {
  /** Reference to dropdown template. */
  dropdownRef: TemplateRef<any>;
  /** Emits whenever the component is destroyed. */
  destroy: Observable<void>;
  /** Observer for size changes. */
  observer?: ResizeObserver;
  /** Emits when one of options is selected. */
  overlayClose?: Observable<any>;
  /** Reference to opened overlay. */
  overlayRef?: OverlayRef;

  /** Angular zone to run changes in. */
  zone: NgZone;
  /** Reference to angular overlay. */
  overlay: Overlay;
  /** View container of component. */
  containerRef: ViewContainerRef;
  /** Reference to component's containing element. */
  elementRef: ElementRef<HTMLElement>;
}

/** Options for opening an overlay. */
export interface OverlayOpenOptions {
  /** True force element to fit width of parent. */
  fitWidth?: boolean
  /** Position for overlay. */
  position?: Partial<ConnectedPosition>;
}

/** Open overlay for specified component. */
export function overlayOpen(component: OverlayComponent, options: OverlayOpenOptions = {}) {
  component.overlayRef = component.overlay.create({
    hasBackdrop: true,
    scrollStrategy: component.overlay.scrollStrategies.reposition(),
    positionStrategy: component.overlay
      .position()
      .flexibleConnectedTo(component.elementRef)
      .withPositions([safeAssign({
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: 0
      }, options.position ?? {})])
  });

  // Attach template to overlay.
  const templatePortal = new TemplatePortal(component.dropdownRef, component.containerRef);
  component.overlayRef.attach(templatePortal);

  // Listen to select element size changes.
  component.observer = new ResizeObserver(entries => component.zone.run(() => {
    for (let { borderBoxSize } of entries) {
      let width = borderBoxSize[0]!.inlineSize;
      component.overlayRef?.updateSize({
        minWidth: width,
        maxWidth: options.fitWidth ? width : undefined
      });
      component.overlayRef?.updatePosition();
    }
  }));
  component.observer.observe(component.elementRef.nativeElement);

  // Clean up dropdown once it's closed.
  let closed = merge(...arrayDefined([component.destroy, component.overlayClose, component.overlayRef.backdropClick(), component.overlayRef.detachments()]));
  closed.pipe(first()).subscribe(
    () => {
      component.observer?.disconnect();
      component.overlayRef?.detach();
      component.overlayRef?.dispose();
    }
  );
  return closed;
}

/** Set dropdown positioning strategy on overlay. */
export function overlayDropdown(strategy: FlexibleConnectedPositionStrategy, parent = false) {
  type HP = HorizontalConnectionPos;
  type VP = VerticalConnectionPos;
  let originX: HP = 'start';
  let overlayX: HP = 'start';
  let originFallbackX: HP = 'end';
  let overlayFallbackX: HP = 'end';
  let originY: VP = 'top';
  let overlayY: VP = 'top';
  let originFallbackY: VP = 'bottom';
  let overlayFallbackY: VP = 'bottom';

  if (parent) {
    // If submenu, always align self to edges of trigger.
    originX = 'end';
    originFallbackX = 'start';
  } else {
    originY = 'bottom';
    originFallbackY = 'top';
  }

  strategy.withPositions([
    { originX, originY, overlayX, overlayY },
    { originX: originFallbackX, originY, overlayX: overlayFallbackX, overlayY },
    { originX, originY: originFallbackY, overlayX, overlayY: overlayFallbackY },
    { originX: originFallbackX, originY: originFallbackY, overlayX: overlayFallbackX, overlayY: overlayFallbackY },
  ]);
}

/** Create listener for when mouse leaves element. */
export function overlayMouseExit(elementRef: ElementRef, renderer: Renderer2, callback: Function): Function {
  return renderer.listen('document', 'mousemove', ($event: MouseEvent) => {
    let mouse = new Pos($event.clientX, $event.clientY);
    let element = boxOnscreen(elementRef.nativeElement);
    if (Pos.inside(mouse, element)) return;
    callback();
  });
}