import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorResponse } from '../../../../../common/message/error';
import { ApplicationSettingsKey } from '../../../../../common/model/setting-group';
import { Trigger, TriggerPreview } from '../../../../../common/model/trigger';
import { FormulaRunTriggerConfig, LedgerAddTriggerConfig, TriggerType } from '../../../../../common/model/trigger-config';
import { ArraySome } from "../../../../../common/toolbox/array";
import { claimDisputes, claimUnjoin } from '../../../../../common/toolbox/claim';
import { disputeUnjoin } from '../../../../../common/toolbox/dispute';
import { formulaRun } from '../../../../../common/toolbox/formula/formula';
import { ID_DEFAULT, idNull } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { LedgerStepperDialogComponent } from '../../module/ledger/stepper/dialog/ledger-stepper-dialog.component';
import { LedgerStepperDialogData, LedgerStepperDialogReturn } from '../../module/ledger/stepper/dialog/ledger-stepper-dialog.model';
import { TaskBase, TaskData } from '../../module/task/task.model';
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { DIALOG_CANCEL_SYMBOL } from '../toolbox/dialog';
import { getRequest, patchRequest } from '../toolbox/request';
import { serviceSettingsItem } from '../toolbox/service';
import { AuthService } from './auth.service';
import { DisplayService } from './display.service';
import { FormulaService } from './formula.service';
import { LogService } from './log.service';
import { SettingGroupService } from './setting-group.service';

/** A query to fetch a specific trigger. */
export class TriggerQuery {
  constructor(
    /** ID of trigger. */
    public _id = ID_DEFAULT,
    /** Institution of trigger. */
    public _inst = ID_DEFAULT
  ) { }
}

/** Caches information about triggers. */
@Injectable({
  providedIn: 'root'
})
export class TriggerService extends CachePreviewService<Trigger, TriggerQuery, TriggerPreview> {
  readonly route = 'triggers/preview';
  readonly Type = Trigger;

  /** Cache of global institution triggers. */
  protected globalcache = new Map<string, string[]>();

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private display: DisplayService,
    private settings: SettingGroupService,
    private formulas: FormulaService
  ) {
    super(TriggerQuery, log, dialog, http);
  }

  /** Fetch a trigger from settings. */
  setting(key: ApplicationSettingsKey) {
    return serviceSettingsItem('Trigger', this, this.auth, this.log, this.settings, key);
  }

  protected override multiple(queries: ArraySome<TriggerQuery>) {
    return getRequest(this.http, 'triggers', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }

  /** Retrieves and runs a list of triggers with the provided input. */
  async runAll(_triggers: string[] | undefined, data: TaskData): Promise<TaskData> {
    if (!_triggers?.length) return data;
    this.display.fallback(data.partial);

    const triggers = await this.items(_triggers.map(_id => ({ _inst: this.auth._inst, _id })));
    for (let trigger of triggers) {
      const result = await this.run(trigger, data);
      if (errorResponse(result)) {
        this.log.show(result);
        break;
      }

      data = result;
    }

    return data;
  }

  /** Runs a trigger with the provided input */
  async run(trigger: Trigger, data: TaskData): Promise<TaskData | ErrorResponse> {
    // If condition exists, and it returns a falsy value then don't run the trigger.
    if (trigger._condition) {
      const formula = await this.formulas.item({ _inst: this.auth._inst, _id: trigger._condition });
      if (!formulaRun(formula, data.partial)) return data;
    }

    switch (trigger.config.type) {
    case TriggerType.ClaimSave:
      return await this.claimSave(data);
    case TriggerType.DisputeSave:
      return await this.disputeSave(data);
    case TriggerType.FormulaRun:
      return await this.formulaRun(data, trigger.config);
    case TriggerType.LedgerAdd:
      return await this.ledgerAdd(data, trigger.config);
    case TriggerType.TransactionPost:
      //TO-DO: NOT IMPLEMENTED
      return data;
    }
  }

  /** Save current displayed claim. */
  private async claimSave(data: TaskData): Promise<TaskData | ErrorResponse> {
    if (!('claim' in data)) return new ErrorResponse('No input claim available for trigger.', [], data);
    if (idNull(data.claim._id)) return data; // Testing mode.

    const claimSaveResult = await patchRequest(this.http, 'claims', {
      patches: [{
        claim: claimUnjoin(data.claim),
        disputes: claimDisputes(data.claim).map(dispute => disputeUnjoin(dispute))
      }]
    });

    return errorResponse(claimSaveResult) ? claimSaveResult : data;
  }

  /** Save current displayed disputes. */
  private async disputeSave(data: TaskData): Promise<TaskData | ErrorResponse> {
    const result = await patchRequest(this.http, 'disputes', {
      disputes: TaskBase.disputes(data).map(d => disputeUnjoin(d))
    });

    return errorResponse(result) ? result : data;
  }

  /** Execute a formula in current context. */
  private async formulaRun(data: TaskData, config: FormulaRunTriggerConfig): Promise<TaskData | ErrorResponse> {
    const _inst = this.auth._inst;
    const formula = await this.formulas.item({ _inst, _id: config._formula });

    if (config._filters?.length && 'claim' in data && 'disputes' in data.claim) {
      // Temporarily filter disputes of claim.
      let formulas = await this.formulas.items(config._filters.map(_id => ({ _inst, _id })));
      let disputes: any[] = data.claim.disputes;
      data.claim.disputes = disputes.filter(dispute => formulas.every(formula => formulaRun(formula, { dispute })));

      // Run formulas on filtered disputes and revert.
      formulaRun(formula, data.partial);
      data.claim.disputes = disputes;
    } else {
      // Run formulas as normal.
      formulaRun(formula, data.partial);
    }

    return data;
  }

  /** Open ledger dialog to confirm */
  private async ledgerAdd(data: TaskData, config: LedgerAddTriggerConfig): Promise<TaskData | ErrorResponse> {
    let result = await this.dialog.open<LedgerStepperDialogReturn, LedgerStepperDialogData>(LedgerStepperDialogComponent, new LedgerStepperDialogData(data, config._form, config.configs, config._filter));
    if (result === DIALOG_CANCEL_SYMBOL) return new ErrorResponse('Cancelled submission. No ledger items were posted.');
    return data;
  }
}
