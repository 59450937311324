import { MimeCategory } from "../model/mime";
import { BufferLike, binaryBase64 } from "./binary";
import { docxExtract } from "./docx";
import { errorResponse } from "./message";
import { mimeCategory, mimeChange } from "./mime";
import { XmlParser } from "./xml";
import { Unzip } from "./zip";

/** Pattern for valid filenames. */
export const FILENAME_REGEX = /^[\w\-.() ]+$/;

/** Extracted plaintext of a file. */
export class FilePlaintext {
  constructor(
    /** New display name for file. */
    public name = '',
    /** Contents of file. */
    public text = ''
  ) {}
}

/** Extract plaintext of a file. */
export async function filePlaintext(unzip: Unzip, parser: XmlParser, name: string, data: BufferLike) {
  switch (mimeCategory(name)) {
  case MimeCategory.Document:
    let docx = await docxExtract(unzip, parser, data);
    if (errorResponse(docx)) return docx;
    return new FilePlaintext(mimeChange(name, 'txt'), docx);
  case MimeCategory.Text:
    return new FilePlaintext(name, await binaryBase64(data));
  default:
    return new FilePlaintext(name);
  }
}