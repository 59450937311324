
/** Signals that component implements its own title. */
interface TitleComponent<T> {
  title: (data: T) => string
}

/** Signals that component overrides padding. */
interface PadComponent<T> {
  padding: (data: T) => boolean
}

/** True if component implements TitleComponent interface. */
export function titleComponent<T>(component: any): component is TitleComponent<T> {
  return 'title' in component && typeof component['title'] === 'function';
}

/** True if component implements TabPad interface. */
export function padComponent<T>(component: any): component is PadComponent<T> {
  return 'padding' in component && typeof component['padding'] === 'function';
}