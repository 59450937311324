import { Component, ContentChildren, Input, QueryList } from "@angular/core";
import { ExpansionPanelItemComponent } from "./item/expansion-panel-item.component";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent {
  /** List of expansion panel items. */
  @ContentChildren(ExpansionPanelItemComponent) items!: QueryList<ExpansionPanelItemComponent>;

  /** True if expanding one panel closes the others. */
  @Input() closeOthers = true;

  /** Subscription for individual panels expanding or collapsing. */
  private subscription = Subscription.EMPTY;

  ngAfterContentInit() {
    this.items.changes.subscribe(() => this.resubscribe());
    this.resubscribe();
  }

  /** Close all items except the specified one. */
  closeOtherItems(openItem: ExpansionPanelItemComponent) {
    if (!this.closeOthers) return;

    for (let item of this.items) {
      if (item === openItem) continue;
      item.expanded = false;
    }
  }

  /** Refresh listeners on expansion panel items. */
  private resubscribe() {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();

    // Update listeners when list of expansion panels change (e.g. an ngFor is re-evaluated).
    for (let item of this.items) {
      this.subscription.add(item.expandedChange.subscribe(expanded => {
        if (expanded) this.closeOtherItems(item);
      }))
    }
  }
}