import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClaimJoin, ClaimJoinACH } from '../../../../../../common/model/claim/claim';
import { EventCategory } from '../../../../../../common/model/event/category';
import { EVENT_RESULT_NAME, EventResult } from '../../../../../../common/model/event/result';
import { ClaimResultType } from '../../../../../../common/model/event/result/claim';
import { Pair, objectEntries } from '../../../../../../common/toolbox/object';
import { TaskOpen } from '../../../common/toolbox/task';

@Component({
  selector: 'app-claim-events',
  templateUrl: './claim-events.component.html',
  styleUrls: ['./claim-events.component.scss']
})
export class ClaimEventsComponent {

  /** Set current claim being viewed. */
  @Input() claim: ClaimJoin = new ClaimJoinACH();
  /** Emits when a task should be opened. */
  @Output() task = new EventEmitter<TaskOpen<EventResult>>();

  /** List of available event result types to select. */
  types = objectEntries(EVENT_RESULT_NAME[EventCategory.Claim]).map(e => new Pair(e[0], e[1]));
  /** Selected event type. */
  type?: ClaimResultType = undefined;
}
