import { ClaimType, DisputeCreditStatus } from "../../../code/standard/disputes";
import { DisputesCode } from "../../../code/system";
import { ConditionType } from "../../formula/condition";
import { OperatorType } from "../../formula/operator";
import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { BuiltinFormula } from "../formula";
import { builtinFormulaSubKey } from "./formula";

/** Formulas that operate on lists of transactions. */
export enum TransactionsFormula {
  ProvisionalCredit = 'Transactions - Provisional Credit'
}

export const BUILTIN_TRANSACTIONS_FORMULAS: Record<TransactionsFormula, BuiltinFormula> = {
  [TransactionsFormula.ProvisionalCredit]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Map,
        expression: {
          type: OperatorType.Filter,
          expression: {
            type: TerminalType.Identifier,
            key: 'claim.disputes'
          },
          statements: [{
            type: StatementType.Return,
            expression: {
              type: ConditionType.And,
              left: {
                type: ConditionType.Equal,
                left: {
                  type: TerminalType.Identifier,
                  key: builtinFormulaSubKey('claim.disputes', 'type')
                },
                right: {
                  type: TerminalType.Code,
                  category: DisputesCode.ClaimType,
                  value: ClaimType.Card
                }
              },
              right: {
                type: ConditionType.Equal,
                left: {
                  type: TerminalType.Identifier,
                  key: builtinFormulaSubKey('claim.disputes', 'creditStatus')
                },
                right: {
                  type: TerminalType.Code,
                  category: DisputesCode.DisputeCreditStatus,
                  value: DisputeCreditStatus.Provisional
                }
              }
            }
          }]
        },
        statements: [{
          type: StatementType.Return,
          expression: {
            type: TerminalType.Identifier,
            key: builtinFormulaSubKey('claim.disputes', 'transaction')
          }
        }]
      }
    }]
  }
}