import { AccountJoin, AccountJoinLoan } from "../../../../../common/model/account/account";
import { WorkQueue } from "../../../../../common/model/work/queue";
import { AccountTaskOpen } from "../../common/toolbox/account";

/** Configuration for opening account. */
export class AccountData {
  constructor(
    /** Account to open. */
    public account: AccountJoin = new AccountJoinLoan(),
    /** Queue currently being viewed. */
    public queue?: WorkQueue,
    /** Task to automatically open over account. */
    public task?: AccountTaskOpen
  ) {}

  /** Check if a value is AccountData. */
  static check(value: any): value is AccountData {
    return value instanceof Object && 'account' in value;
  }
}