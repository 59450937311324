import { Component, ElementRef, Input } from '@angular/core';
import { LedgerType } from '../../../../../../common/code/standard/disputes';
import { AccountJoin } from '../../../../../../common/model/account/account';
import { LedgerAccountType } from '../../../../../../common/model/ledger/account';
import { safeAssign } from '../../../../../../common/toolbox/object';
import { LedgerConfigService } from '../../../common/service/ledger-config.service';
import { setupElementMake } from '../../../common/toolbox/element/setup';
import { LedgerListState, LedgerWithPostInfo } from '../list/ledger-list.model';
import { SettingGroupService } from '../../../common/service/setting-group.service';

@Component({
  selector: 'app-ledger-item',
  templateUrl: './ledger-item.component.html',
  styleUrls: ['./ledger-item.component.scss']
})
export class LedgerItemComponent {

  /** Set current ledger state. */
  @Input() state = new LedgerListState();

  /** Set ledger item being edited. */
  @Input() set ledger(ledger: LedgerWithPostInfo) {
    this._ledger = ledger;
    if (ledger.ledger._config) this.onConfig(ledger.ledger._config);
  }

  /** Ledger item being edited. */
  protected _ledger = new LedgerWithPostInfo();
  /** Last bound state. */
  protected list?: LedgerListState;
  /** Account being debited. */
  protected debit?: AccountJoin;
  /** Account being credited. */
  protected credit?: AccountJoin;
  /** Whether we are currently an acquirer or consumer ledgerItem */
  public isAcquirer = false;
  /** Enable posting to core. */
  public enablePosting = false;
  constructor(
    private ledgerConfigs: LedgerConfigService,
    private elementRef: ElementRef,
    private settings: SettingGroupService
  ) {
  }

  async ngOnInit() {
    this.enablePosting = (await this.settings.inst()).disputes.claim.postTransactions;
  }

  /** Callback when changing config of a ledger item. */
  protected async onConfig(_config: string) {
    let [ledger, account, config] = await this.ledgerConfigs.create(_config, this.state.partial);
    setupElementMake(this.elementRef.nativeElement, 'ledgerConfigs', _config);
    safeAssign(this._ledger.ledger, ledger);

    if (this._ledger.ledger.type === LedgerType.Debit) {
      this.debit = account;

      if (config.account === LedgerAccountType.Consumer) {
        // GL Account > Consumer Account
        this.credit = this.state.partial.account;
      } else {
        // GL Account > Acquirer Account
        this.credit = undefined;
      }
    } else {
      // Consumer Account > GL Account
      this.credit = account;

      if (config.account === LedgerAccountType.Consumer) {
        // Consumer Account > GL Account
        this.debit = this.state.partial.account;
      } else {
        // Acquirer Account > GL Account
        this.debit = undefined;
      }
    }
    this.isAcquirer = config.account === LedgerAccountType.Acquirer;
    this._ledger.shouldPost = this.enablePosting && !this.isAcquirer;
  }
}
