import { InjectionToken } from "@angular/core";
import { Newable } from "../../../../../../../common/toolbox/object";
import { Subject } from "rxjs";
import { TabComponent } from "../tab.component";
import { TabBarComponent } from "./tab-bar.component";

/** Injection token for accessing data passed to tab. */
export const TAB_DATA = new InjectionToken('TAB_DATA');

/** Data returned from closing a dialog. */
export interface TabCloseEvent<R> {
  data: R | undefined
}

/** Reference to opened tab. */
export class TabRef<T extends TabComponent = TabComponent, D = unknown, R = unknown> {
  /** Emits when tab is closed. */
  closed = new Subject<TabCloseEvent<R>>();

  constructor(
    /** Reference to parent tabs. */
    public tabs: TabBarComponent<T>,
    /** Data passed to dialog's component. */
    public data: D,
    /** Tab to be closed. */
    public tab = undefined as unknown as T
  ) {}

  /** Force tab to close. */
  close(data?: R) {
    this.tabs.close(this.tab);
    this.closed.next({ data });
    this.closed.complete();
  }

  /** Set new title for tab. */
  title(title: string) {
    setTimeout(() => this.tab.title = title);
  }
}

/** Configuration passed when opening new tabs. */
export interface TabConfig<T extends TabComponent = any> {
  /** Title to display for tab. */
  title?: string
  /** True to show close button on tab. */
  closeable?: boolean
  /** True to disable autoselect when this tab is opened. */
  autoselect?: boolean
  /** True to retain default tab padding. */
  padding?: boolean
  /** Container to use for new tabs. */
  container?: Newable<T>
}