import { Component, Inject } from '@angular/core';
import { arrayFill, arraySum } from "../../../../../../common/toolbox/array";
import { enumValues } from "../../../../../../common/toolbox/enum";
import { randomId, randomName, randomRange } from "../../../../../../common/toolbox/random";
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { Stat, StatGoals, StatHistory, StatTotal, StatType, userStats } from '../../../common/toolbox/stat';
import { CollectionsSupervisorData } from './collections-supervisor.model';

/** List of overview stats to show for team. */
export const TOTAL_STATS = [
  StatType.CallsOutbound,
  StatType.AccountsTotalWorked,
  StatType.Promises,
  StatType.LettersProduced,
  StatType.EmailsSent,
  StatType.TextsSent
];

@Component({
  selector: 'app-collections-supervisor',
  templateUrl: './collections-supervisor.component.html',
  styleUrls: ['./collections-supervisor.component.scss'],
  host: {
    class: 'column fill'
  }
})
export class CollectionsSupervisorComponent {
  /** List of overview stats to show for team. */
  types = TOTAL_STATS;
  /** Range to get user stats for. */
  hours: [number, number] = [7, 21];
  /** Date to display team statitics for. */
  date = new Date();
  /** Goals of each team member. */
  goals: StatGoals[] = arrayFill(5, () => ({ id: randomId(), name: randomName().join(), ...this.userGoals() }));
  /** Stats by team member. */
  history: StatHistory[] = [];
  /** Team summary displayed on main page. */
  totals: StatTotal[] = [];
  /** Totals displayed at top of page. */
  stats: Stat[] = [];

  static title() {
    return 'Team Activity';
  }

  constructor(
    @Inject(TAB_DATA)
    public data: CollectionsSupervisorData
  ) {
    this.refresh();
  }

  /** Callback when date is changed. */
  onDate(date: Date) {
    this.date = date;
    this.refresh();
  }

  /** Regenerated new list of stats. */
  refresh() {
    // Number of hours to calculate across.
    let hours = this.hours[1] - this.hours[0];

    // Get stats of all team members.
    this.history = this.goals.map(user => {
      let stats: Record<StatType, number[]> = userStats([]);
      for (let stat of enumValues<StatType>(StatType)) {
        let target = randomRange(0, 1.5 * user[stat] / hours);
        stats[stat] = arrayFill(hours, () => randomRange(0, 1.5 * target));
      }
      return { id: user.id, name: user.name, ...stats }
    });

    // Merge stats into main page summaries.
    this.totals = [];
    for (let goal of this.goals) {
      let stats = this.history.find(stat => stat.id === goal.id)!;
      let summary: StatTotal = { id: goal.id, name: goal.name, ...userStats(new Stat()) };

      for (let type of TOTAL_STATS) {
        summary[type].goal = goal[type];
        summary[type].actual = arraySum(stats[type]);
      }
      this.totals.push(summary);
    }

    // Merge summaries into totals.
    this.stats = TOTAL_STATS.map(type => {
      let stat = new Stat(type);
      for (let summary of this.totals) {
        stat.actual += summary[type].actual ?? 0;
        stat.goal += summary[type].goal ?? 0;
      }
      return stat;
    });
  }

  /** Goals used for testing. */
private userGoals(): Record<StatType, number> {
  return {
    [StatType.AccountsDistinctWorked]:  0,
    [StatType.AccountsTotalWorked]:     800,
    [StatType.CallsInbound]:            10,
    [StatType.CallsOutbound]:           100,
    [StatType.CallsTotal]:              120,
    [StatType.Contacts]:                10,
    [StatType.EmailsReceived]:          60,
    [StatType.EmailsSent]:              100,
    [StatType.FollowUpsScheduled]:      80,
    [StatType.LettersProduced]:         40,
    [StatType.LettersSent]:             10,
    [StatType.MeetingsInhouse]:         1,
    [StatType.Promises]:                80,
    [StatType.PromisesHonored]:         0,
    [StatType.TextsSent]:               20
  };
}
}
