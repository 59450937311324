import { Subject } from "rxjs";
import { AccountJoin } from "../../../../../../common/model/account/account";
import { MaybeId } from "../../../../../../common/toolbox/id";

/** Data passed to account setup dialog. */
export class AccountSetupData {
  constructor(
    /** Account to configure. */
    public account?: MaybeId<AccountJoin>,
    /** Emits when changes are made to account. */
    public save?: Subject<void>
  ) {}
}