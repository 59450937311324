import { WorkFilter } from "../../../../../../common/model/work/filters";
import { WorkQueue } from "../../../../../../common/model/work/queue";

/** Data passed to work list view. */
export class WorkListData {
  constructor(
    /** Queue currently being displayed. */
    public queue = new WorkQueue(),
    /** Current queue filter. */
    public filter = WorkFilter.All,
    /** Current position within queue. */
    public index?: number
  ) {}
}