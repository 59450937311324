import { Pipe } from '@angular/core';
import { ClaimFilterService } from '../service/claim-filter.service';

@Pipe({
  name: 'claimFilter'
})
export class ClaimFilterPipe {

  constructor(
    private service: ClaimFilterService
  ) {}
  transform(_filter: string, _inst: string) {
    return this.service.name(_inst, _filter);
  }
}
