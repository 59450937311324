import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FusionCollectionName } from '../../../../../../../common/model/fusion';
import { FusionCollectionQuery, ResourceService } from '../../../service/resource.service';
import { fieldControlProviders } from '../../../toolbox/angular';
import { EditListComponent } from '../../edit-list/edit-list.component';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
  providers: fieldControlProviders(ResourceListComponent)
})
export class ResourceListComponent extends EditListComponent<string> {
  /** Reference to edit list within template. */
  @ViewChild(EditListComponent) list!: EditListComponent<string>;

  /** Set current configured collection. */
  @Input() set collection(collection: FusionCollectionName) {
    if (!this.resource.queryable(collection)) return;
    this.refresh(collection);
  }
  
  /** Collection to pull values from. */
  protected _collection: FusionCollectionName = 'accounts';

  constructor(
    elementRef: ElementRef,
    private resource: ResourceService,
  ) {
    super(elementRef);
  }

  /** Fetch new list of values. */
  protected async refresh(collection: FusionCollectionQuery) {
    let previews = await this.resource.previews(this._collection = collection);
    this.default = previews[0]?._id;
  }
}
