import { ImportCode, SystemCode } from "../code/system";
import { CollectionInfo } from "../info/collection";
import { PropertyInfo } from "../info/prop";
import { TypeInfo, typeinfoValue } from "../info/type";
import { UnionInfo, unioninfoType } from "../info/union";
import { CURRENCY_DEFAULT, currency } from "../toolbox/currency";
import { EMAIL_DEFAULT } from "../toolbox/email";
import { enumHas } from "../toolbox/enum";
import { ID_DEFAULT } from "../toolbox/id";
import { deepCopy, objectType } from "../toolbox/object";
import { PHONE_DEFAULT } from "../toolbox/phone";
import { titleCase } from "../toolbox/string";
import { DAYS_MAX, DAYS_MIN, dateOffset } from "../toolbox/time";
import { PatternValidator } from "../validator/pattern";
import { FusionCollection } from "./fusion";
import { PropertyType } from "./property-type";

/** Configuration for a field on a model. */
export type Property =  BooleanProperty     |
                        NumberProperty      |
                        StringProperty      |
                        CurrencyProperty    |
                        DateProperty        |
                        CodeProperty        |
                        UserProperty        |
                        MemberProperty      |
                        PhoneProperty       |
                        EmailProperty       |
                        TransactionProperty;

/** Valid patterns for property keys. */
export const PROPERTY_KEY_REGEX = /^[a-zA-Z0-9_]+$/;
/** Pattern for sanitizing property keys. */
export const PROPERTY_KEY_SANITIZE_REGEX = /[^a-zA-Z0-9_]/g;

/** Base configuration for all properties. */
export class PropertyTag<T extends PropertyType = any> {
  constructor(
    /** Tagged type of property. */
    readonly type: T,
    /** Object key for property. */
    public key = '',
    /** Display name override for property. */
    public name = '',
    /** True to make this an array of values. */
    public multiple = false,
    /** True if property is required. */
    public required = true,
    /** True if property cannot be edited after reopening. */
    public locked = false
  ) {}

  static typeinfo: TypeInfo<PropertyTag<any>> = {
    key: new PatternValidator(PROPERTY_KEY_REGEX)
  }

  /** Sanitize a property's key. */
  static sanitize(key: string) {
    return PROPERTY_KEY_REGEX.test(key) ? key : key.replace(PROPERTY_KEY_SANITIZE_REGEX, () => '');
  }
}

/** Boolean property on a model. */
export class BooleanProperty extends PropertyTag<PropertyType.Boolean> {
  constructor(
    /** Default value for boolean. */
    public value?: boolean,
    /** Alternate name for "true" label. */
    public on?: string,
    /** Alternate name for "false" label. */
    public off?: string
  ) {
    super(PropertyType.Boolean);
  }

  static override typeinfo: TypeInfo<BooleanProperty> = {
    ...PropertyTag.typeinfo,
    value: false,
    on: '',
    off: ''
  }
}

/** Number property on a model. */
export class NumberProperty extends PropertyTag<PropertyType.Number> {
  constructor(
    /** Default value for number. */
    public value?: number,
    /** Minimum value for number. */
    public min?: number,
    /** Maximum value for number. */
    public max?: number
  ) {
    super(PropertyType.Number);
  }

  static override typeinfo: TypeInfo<NumberProperty> = {
    ...PropertyTag.typeinfo,
    value: 0,
    min: 0,
    max: 0
  }
}

/** Text property on a model. */
export class StringProperty extends PropertyTag<PropertyType.String> {
  constructor(
    /** Default value for string. */
    public value?: string,
    /** Maximum length for string. */
    public maxLength?: number,
    /** Minimum length for string. */
    public minLength?: number
  ) {
    super(PropertyType.String);
  }

  static override typeinfo: TypeInfo<StringProperty> = {
    ...PropertyTag.typeinfo,
    value: '',
    minLength: 0,
    maxLength: 0
  }
}

/** Currency property on a model */
export class CurrencyProperty extends PropertyTag<PropertyType.Currency> {
  constructor(
    /** Default value for currency. */
    public value?: currency,
    /** Minimum value for amount. */
    public min?: number,
    /** Maximum value for amount. */
    public max?: number
  ) {
    super(PropertyType.Currency);
  }

  static override typeinfo: TypeInfo<CurrencyProperty> = {
    ...PropertyTag.typeinfo,
    value: CURRENCY_DEFAULT,
    min: 0,
    max: 0
  }
}

/** Date property on a model. */
export class DateProperty extends PropertyTag<PropertyType.Date> {
  constructor(
    /** Default value for property, in number of days from today. */
    public value?: number,
    /** Minimum offset for date, in number of days from today. */
    public min?: number,
    /** Maximum offset for date, in numbers of days from today. */
    public max?: number,
    /** Date this date field is associated with. */
    public link?: string
  ) {
    super(PropertyType.Date);
  }

  static override typeinfo: TypeInfo<DateProperty> = {
    ...PropertyTag.typeinfo,
    value: 0,
    min: DAYS_MIN,
    max: DAYS_MAX,
    link: ''
  }
}

/** Code type on a model. */
export class CodeProperty extends PropertyTag<PropertyType.Code> {
  constructor(
    /** Default value for code. */
    public value?: string,
    /** Category to pull code types from. */
    public category: SystemCode = ImportCode.AccountType
  ) {
    super(PropertyType.Code);
  }

  static override typeinfo: TypeInfo<CodeProperty> = {
    ...PropertyTag.typeinfo,
    value: ''
  }
}

/** User assignment on a model. */
export class UserProperty extends PropertyTag<PropertyType.User> {
  constructor(
    /** Default value for user. */
    public value?: string
  ) {
    super(PropertyType.User);
  }

  static override typeinfo: TypeInfo<UserProperty> = {
    ...PropertyTag.typeinfo,
    value: ID_DEFAULT
  };

  static collectioninfo: CollectionInfo<FusionCollection, UserProperty> = {
    value: 'users'
  }
}

/** Member assignment on a model. */
export class MemberProperty extends PropertyTag<PropertyType.Member> {
  constructor(
    /** Default value for member. */
    public value?: string
  ) {
    super(PropertyType.Member);
  }

  static override typeinfo: TypeInfo<MemberProperty> = {
    ...PropertyTag.typeinfo,
    value: ID_DEFAULT
  };

  static collectioninfo: CollectionInfo<FusionCollection, UserProperty> = {
    value: 'members'
  }
}

/** Phone assignment on a model. */
export class PhoneProperty extends PropertyTag<PropertyType.Phone> {
  constructor(
    /** Default value for phone. */
    public value?: string,
    /** Field this phone selection is associated with. */
    public link?: string
  ) {
    super(PropertyType.Phone);
  }

  static override typeinfo: TypeInfo<PhoneProperty> = {
    ...PropertyTag.typeinfo,
    value: PHONE_DEFAULT,
    link: ''
  }
}

/** Phone assignment on a model. */
export class EmailProperty extends PropertyTag<PropertyType.Email> {
  constructor(
    /** Default value for phone. */
    public value?: string,
    /** Field this phone selection is associated with. */
    public link?: string
  ) {
    super(PropertyType.Email);
  }

  static override typeinfo: TypeInfo<EmailProperty> = {
    ...PropertyTag.typeinfo,
    value: EMAIL_DEFAULT,
    link: ''
  }
}

/** Transaction assignment on a model. */
export class TransactionProperty extends PropertyTag<PropertyType.Transaction> {
  constructor(
    /** Default value for transaction. */
    public value?: string
  ) {
    super(PropertyType.Transaction);
  }

  static override typeinfo: TypeInfo<TransactionProperty> = {
    ...PropertyTag.typeinfo,
    value: ID_DEFAULT
  }

  static collectioninfo: CollectionInfo<FusionCollection, UserProperty> = {
    value: 'transactions'
  }
}

/** Mapping of property types to property classes. */
export class PropertyClass {
  [PropertyType.Boolean] = new BooleanProperty();
  [PropertyType.Number] = new NumberProperty();
  [PropertyType.String] = new StringProperty();
  [PropertyType.Currency] = new CurrencyProperty();
  [PropertyType.Date] = new DateProperty();
  [PropertyType.Code] = new CodeProperty();
  [PropertyType.User] = new UserProperty();
  [PropertyType.Member] = new MemberProperty();
  [PropertyType.Phone] = new PhoneProperty();
  [PropertyType.Email] = new EmailProperty();
  [PropertyType.Transaction] = new TransactionProperty();
}

/** Mapping of property types to value classes. */
export interface PropertyValue {
  [PropertyType.Boolean]: boolean
  [PropertyType.Number]: number
  [PropertyType.String]: string
  [PropertyType.Currency]: string
  [PropertyType.Date]: Date
  [PropertyType.Code]: string
  [PropertyType.User]: string
  [PropertyType.Member]: string
  [PropertyType.Phone]: string
  [PropertyType.Email]: string
  [PropertyType.Transaction]: string
}

/** Iterate over a list of properties, getting those of a specific type.*/
export class PropertyFilter<T extends PropertyType> implements Iterable<PropertyClass[T]> {
  /** Current position. */
  private i = 0;

  constructor(
    private properties: Property[] = [],
    private type: T
  ) {}

  [Symbol.iterator](): Iterator<PropertyClass[T]> {
    return {
      next: () => {
        for (; this.i < this.properties.length; ++this.i) {
          if (this.properties[this.i]!.type === this.type) {
            return {
              done: false,
              value: this.properties[this.i++]! as PropertyClass[T]
            }
          }
        }

        return {
          done: true,
          value: {} as PropertyClass[T]
        }
      }
    }
  }
}

/** Check if a value is a property. */
export function propertyCheck(value: any): value is Property {
  return value instanceof Object && enumHas(PropertyType, value.type);
}

/** Create model property from a given value. */
export function propertyFrom(propinfo: PropertyInfo<any>, typeinfo: TypeInfo<any>, object: any, key: string, name: string | undefined): Property {
  let value = typeinfoValue(object, key, typeinfo);
  let property: Property;

  if (propinfo[key]) {
    property = propertyCreate(propinfo[key]!.type ?? PropertyType.String);
    Object.assign(property, propinfo[key]);
  } else switch (objectType(value)) {
  case 'boolean':
    property = new BooleanProperty();
    break;
  case 'number':
    property = new NumberProperty();
    break;
  case 'string':
    property = value === PHONE_DEFAULT ? new PhoneProperty() : new StringProperty(value.length ? value : undefined);
    break;
  case 'date':
    property = new DateProperty();
    break;
  default:
    property = new StringProperty();
    break;
  }

  property.key = key;
  property.name = propertyName(property.name, key, name);
  return property;
}

/** Create model property of a given type. */
export function propertyCreate(type: PropertyType): Property {
  return deepCopy(unioninfoType(PROPERTY_UNIONINFO, type));
}

/** Get name of a property.*/
export function propertyName(name: string | undefined, key: string, parent: string | undefined) {
  // Handle special case for Object ID properties.
  let subname = `${titleCase(name || key)}${key.startsWith('_') ? ' 🆔' : ''}`;
  return parent ? `${parent} - ${subname}` : subname;
}

/** Get default value of a property. */
export function propertyValue(property: Property): unknown {
  if (!property.required) return undefined;
  if (property.value !== undefined) return property.value;

  switch (property.type) {
    case PropertyType.Boolean:
      return false;
    case PropertyType.Number:
      return 0;
    case PropertyType.Currency:
      return CURRENCY_DEFAULT;
    case PropertyType.Date:
      return dateOffset(new Date(), property.value);
    case PropertyType.String:
    case PropertyType.Code:
      return '';
    case PropertyType.Phone:
      return PHONE_DEFAULT;
    case PropertyType.Email:
      return EMAIL_DEFAULT;
    case PropertyType.User:
    case PropertyType.Member:
    case PropertyType.Transaction:
      return ID_DEFAULT;
  }
}

/** Type information for property union. */
export const PROPERTY_UNIONINFO: UnionInfo<Property, PropertyType> = {
  tag: 'type',
  classes: new PropertyClass()
}