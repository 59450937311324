import { Injectable } from '@angular/core';

/** Contains information about current user's configuration. */
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /** User configuration for date formatting. */
  dateFormat = 'M/d/yy';
  /** User configuration for date formatting. */
  dateTimeFormat = 'M/d/yy HH:mm:ss';
}
