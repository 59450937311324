<div class="fill flex">
  <app-field left>
    <app-select institution [ngModel]="auth._inst">
      <app-option *ngFor="let i of auth.institutions" [view]="i | institution" [value]="i._id" (selectionChange)="auth.reinstitution(i)"></app-option>
    </app-select>
  </app-field>
  <app-field right>
    <app-select feature [ngModel]="auth.feature">
      <app-option *ngFor="let f of auth.session.features" [view]="f | enum:FEATURE_NAMES" [value]="f" (selectionChange)="auth.refeature(f)"></app-option>
    </app-select>
  </app-field>
</div>