import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ErrorResponse } from '../../../../../../../common/message/error';
import { PaginateResponse } from '../../../../../../../common/message/paginate';
import { DisplayType, DisplayValue } from "../../../../../../../common/model/display";
import { DisputeClass } from "../../../../../../../common/model/dispute/dispute";
import { Formula } from '../../../../../../../common/model/formula/formula';
import { IndexMap } from '../../../../../../../common/model/indexmap';
import { Transaction, TransactionReference } from "../../../../../../../common/model/transaction";
import { arrayDefined, arrayEmpty } from "../../../../../../../common/toolbox/array";
import { claimDisputes } from '../../../../../../../common/toolbox/claim';
import { formulaRun } from '../../../../../../../common/toolbox/formula/formula';
import { idOmit } from '../../../../../../../common/toolbox/id';
import { errorResponse } from "../../../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../../../common/toolbox/status";
import { DateRange, dateOffset } from "../../../../../../../common/toolbox/time";
import { GridColumn, GridConfig } from '../../../../common/component/model/grid/model-grid.model';
import { TAB_DATA } from '../../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../../common/service/auth.service';
import { FormulaService } from '../../../../common/service/formula.service';
import { LogService } from '../../../../common/service/log.service';
import { SettingGroupService } from '../../../../common/service/setting-group.service';
import { TableService } from '../../../../common/service/table.service';
import { getRequest } from '../../../../common/toolbox/request';
import { gridPaginateRequest } from '../../../../common/toolbox/source/grid';
import { sourceReselect } from '../../../../common/toolbox/source/select';
import { ServerSource } from '../../../../common/toolbox/source/server';
import { CLAIM_INTAKE_STEP_STATE, ClaimIntakeState } from '../claim-intake.model';
import { ClaimIntakeStepComponent } from '../step/claim-intake-step.component';
import { ClaimIntakeTransactionsData } from './claim-intake-transactions.model';

/** Display value with additional disputed flag for transaction. */
type DisplayTransaction = DisplayValue & { disputed: ClaimTransactionStatus };

enum ClaimTransactionStatus {
  Disputed = 'Disputed',
  NotDisputed = 'Not Disputed'
}

@Component({
  selector: 'app-claim-intake-transactions',
  templateUrl: './claim-intake-transactions.component.html',
  styleUrls: ['./claim-intake-transactions.component.scss']
})
export class ClaimIntakeTransactionsComponent extends ClaimIntakeStepComponent<ClaimIntakeTransactionsData> {
  /** Maximum for search transactions, in days. */
  readonly max = 0;

  /** Minimum for searching transactions, in days. */
  min = -90;
  /** True to show already disputed transactions */
  showExisting = true;

  /** True to display loading spinner. */
  loading = false;
  /** Configuration for transaction table. */
  config = new GridConfig(new ServerSource<DisplayTransaction>(undefined, undefined, DisplayType.Transaction));
  /** Last received transaction response. */
  response = new PaginateResponse<DisplayTransaction>();
  /** List of transaction codes to filter by. */
  tranCodes?: string[];
  
  /** Insert event type icon at start of row. */
  postcolumns: GridColumn<DisplayTransaction>[] = [{ key: 'disputed', component: 'disputed', name: 'Already Disputed?' }];
  /** True if multiple transactions can be selected. */
  multiple = true;
  /** Formula for emitting errors for invalid transaction selections. */
  selectFormula = new Formula();

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();

  constructor(
    @Inject(CLAIM_INTAKE_STEP_STATE) public state: ClaimIntakeState,
    @Inject(TAB_DATA) public override data: ClaimIntakeTransactionsData,
    private log: LogService,
    private tables: TableService,
    private formulas: FormulaService,
    private http: HttpClient,
    private auth: AuthService,
    private settingGroups: SettingGroupService
  ) {
    super();
  }

  async ngOnInit() {
    let [table, formula] = await Promise.all([
      this.tables.fallback(`disputes.${this.state.claim.type}.claim.transactionTable`, 'general.transaction.table'),
      this.formulas.setting(`disputes.${this.state.claim.type}.intake.transactionSelectFormula`)
    ]);

    // Pull table configuration from settings.
    let settings = await this.settingGroups.inst();
    let defaultMin = -settings.disputes.claim.defaultTransactionDate;
    this.config = new GridConfig(this.config.source, table);
    this.min = -settings.disputes.claim.earliestTransactionDate;
    this.multiple = settings.disputes[this.state.claim.type].intake.multipleTransactions;
    this.showExisting = settings.disputes.claim.showDisputedTransactions;
    this.selectFormula = formula;
    
    // Reset displayed list when changing pagination.
    if (!this.data.search) this.data.post = DateRange.clamp(new DateRange(dateOffset(new Date(), defaultMin)), DateRange.minmax(this.min, this.max));
    this.config.source.dataRequest.pipe(takeUntil(this.destroy)).subscribe(() => this.onSearch());
    this.onSearch();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when searching new range of transactions. */
  async onSearch(post = this.data.post) {
    this.loading = true;
    this.data.search = true;

    if (!this.tranCodes) {
      let settings = await this.settingGroups.inst();
      this.tranCodes = settings.disputes[this.state.claim.type].intake.transactionCodes;
    }

    let transactions = await getRequest(this.http, 'transactions', {
      ...gridPaginateRequest(DisplayType.Transaction, this.config.source),
      _inst: this.auth._inst,
      _account: this.state.account._id,
      category: this.state.account.category,
      subAccount: this.state.account.subAccount,
      number: this.state.account.number,
      type: this.state.account.type,
      postStart: post.start,
      postEnd: post.end,
      core: true,
      tranCodes: this.tranCodes
    });
    this.loading = false;

    if (errorResponse(transactions)) return this.log.show(transactions);
    if (arrayEmpty(transactions.items)) return this.log.show('No transactions were found for the specified query.');
    
    // Reset list of displayed transactions.
    let disputed = new Set(this.state.claims.flatMap(claim => claimDisputes(claim).map(dispute => dispute._transaction)));
    this.response = new PaginateResponse(transactions.items.map(transaction => ({
      transaction,
      disputed: disputed.has(transaction._id) ? ClaimTransactionStatus.Disputed : ClaimTransactionStatus.NotDisputed
    }) as DisplayTransaction), transactions.available);
    this.config.source.available = this.response.available;
    this.onFilter();

    // Autoselect last selected transactions.
    let [_inst, account] = [this.auth._inst, this.state.account];
    let transactionMap = new IndexMap<TransactionReference, string>(new Transaction());
    sourceReselect(
      this.state.transactions, this.config.source,
      transaction => transactionMap.key({ ...transaction, ...account }),
      partial => transactionMap.key({ ...partial.transaction!, ...account })
    );
  }

  /** Filter out any transactions that are attached to existing claims */
  onFilter() {
    if (this.showExisting) {
      this.config.source.items = this.response.items;
    } else {
      this.config.source.items = this.response.items.filter(display => display.disputed !== ClaimTransactionStatus.Disputed);
    }

    if (this.config.source.items.length < this.response.items.length) {
      this.log.show('Some transactions were hidden because they already have an ongoing claim', StatusLevel.Warning);
    }
  }

  /** Updates when list of transactions change. */
  onTransactions(partials: DisplayTransaction[]) {
    setTimeout(() => {
      if (this.loading) return;
      let transactions = arrayDefined(partials.map(d => d.transaction));
      this.state.transactions = transactions;

      // Loop through transactions, determine invalid selection.
      let invalid: DisplayTransaction[] = [];
      for (let partial of partials) {
        let result = formulaRun(this.selectFormula, partial);
        if (typeof result === 'string' && result.length) {
          if (invalid.length === 0) this.log.show(new ErrorResponse(result));
          invalid.push(partial);
        }
      }

      // Deselect these items.
      if (invalid.length) {
        this.config.source.toggle(...invalid);
        return;
      }

      // Create a dispute for each transaction.
      this.state.disputes = transactions.map(transaction => {
        let result = new DisputeClass()[this.state.claim.type];
        result._transaction = transaction._id;
        result._inst = this.auth._inst;
        return idOmit(result) as any;
      });

      // Bubble new list of transactions up to parent component.
      this.transactions.next(transactions);
      this.lock.emit();
    });
  }
}
