import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { ErrorResponse } from '../../../../../../../common/message/error';
import { TablePostResponse } from '../../../../../../../common/message/table';
import { Display, DisplayType, DisplayValue } from "../../../../../../../common/model/display";
import { FormulaProxy } from "../../../../../../../common/model/formula/proxy";
import { Model, ModelMap } from "../../../../../../../common/model/model";
import { Table } from "../../../../../../../common/model/table";
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { NestedKey } from '../../../../../../../common/toolbox/keys';
import { modelMap, modelValue } from '../../../../../../../common/toolbox/model';
import { Pair } from '../../../../../../../common/toolbox/object';
import { DialogService } from '../../../../common/component/dialog/dialog.service';
import { AuthService } from '../../../../common/service/auth.service';
import { DevService } from '../../../../common/service/dev.service';
import { FormulaService } from '../../../../common/service/formula.service';
import { LogService } from '../../../../common/service/log.service';
import { ModelService } from '../../../../common/service/model.service';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';

@Component({
  selector: 'app-setup-table-edit',
  templateUrl: './setup-table-edit.component.html',
  styleUrls: ['./setup-table-edit.component.scss']
})
export class SetupTableEditComponent extends SetupEditComponent<Table, TablePostResponse> {
  readonly COLUMN_UNIT_HELP = 'Size in CSS grid units.\n * fr - Fractional size of full width.\n * rem - Fixed width, in characters.';

  /** Current table being edited. */
  value = idMaybe(new Table());
  /** Display value for fetching possible keys. */
  proxy = new DisplayValue();
  /** Type information about current selected model. */
  map: ModelMap = {};

  /** Selectable keys for each table column. */
  pairs: Pair<NestedKey<DisplayValue>>[][] = [];

  /** All valid keys for table columns. */
  private all = DisplayValue.pairs(this.proxy);

  constructor(
    elementRef: ElementRef,
    log: LogService,
    public dev: DevService,
    private auth: AuthService,
    private dialog: DialogService,
    private http: HttpClient,
    private formulaService: FormulaService,
    private modelService: ModelService
  ) {
    super(elementRef, log);
  }

  async ngOnInit() {
    // Fetch list of formulas for column selection.
    let formulas = await this.formulaService.previews(this.auth._inst);
    this.proxy.formula = FormulaProxy.previews(formulas);
  }

  /** 
   *  Callback when selecting new model from dropdown.
   *  TODO update this if model caching is implemented.
   */
  async onModel(_id?: string) {
    if (this.value.columns.length && _id !== this.value._model) {
      let columns = this.value.columns.filter(f => Display.split(f)[0] !== DisplayType.Model);
      let deleted = this.value.columns.length - columns.length;
      if (deleted) {
        // Columns would be removed after change.
        if (!await this.dialog.confirm(`Change model for this table? This will delete ${deleted} model columns.`, 'Change model?')) return;
      }

      // Set new model for table.
      this.value._model = _id;
      this.value.columns = columns;
    }
   
    // Set current displayed model.
    let model = _id ? await this.modelService.item({ _inst: this.auth._inst, _id }) : new Model();
    this.proxy.model = modelValue(model);
    this.all = DisplayValue.pairs(this.proxy);
    this.map = modelMap(model);
    this.refresh();
  }

  /** Add new column to table. */
  onNew() {
    let used = new Set<NestedKey<DisplayValue>>(this.value.columns.map(c => c.key));
    let pair = this.all.find(pair => !used.has(pair.value));
    if (!pair) {
      this.log.show(new ErrorResponse('Could not add column: no keys available.'));
      return;
    }

    this.value.columns.push({ key: pair.value });
    this.refresh();
  }

  /** Delete specified column from table. */
  onDelete(i: number) {
    this.value.columns.splice(i, 1);
    this.refresh();
  }

  /** Refresh list of available pairs. */
  refresh() {
    let used = new Set<NestedKey<DisplayValue>>(this.value.columns.map(c => c.key));
    this.pairs = this.value.columns.map(column => this.all.filter(pair => pair.value === column.key || !used.has(pair.value)));
  }

  /** Submit current changes to table. */
  push() {
    return postRequest(this.http, 'tables', { items: [this.value] });
  }

  /** Reset current form with new table. */
  async reset(value: MaybeId<Table>) {
    this.value = value;

    // Set initial model if not valid.
    this.onModel(this.value._model);
    this.refresh();
  }
}
