import { Component } from '@angular/core';
import { PropertyType } from '../../../../../../../../common/model/property-type';
import { AuthService } from '../../../../../common/service/auth.service';
import { FormFieldAccessor } from '../setup-form-field.model';

@Component({
  selector: 'app-setup-form-field-code',
  templateUrl: './setup-form-field-code.component.html',
  styleUrls: ['./setup-form-field-code.component.scss'],
  host: {
    class: 'column'
  }
})
export class SetupFormFieldCodeComponent extends FormFieldAccessor<PropertyType.Code> {
  /** Prefix being edited. */
  prefix: [string, string] = ['', ''];
  /** Suffix being edited. */
  suffix: [string, string] = ['', ''];

  constructor(
    public auth: AuthService
  ) {
    super();
  }

  override reset() {
    if (this.config.prefix) this.prefix = this.config.prefix;
    if (this.config.suffix) this.suffix = this.config.suffix;
  }
}
