import { LedgerConditionalConfig } from "../../../../../../common/model/trigger-config";
import { TaskBase, TaskData } from "../../task/task.model";

/** Configuration for ledger stepper. */
export class LedgerStepperState  {

  /** Get list of disputes. */
  static disputes(config: LedgerStepperState) {
    return TaskBase.disputes(config.data);
  }
  
  constructor(
    /** Current task context. */
    public data: TaskData,
    /** Form to display in stepper dialog. */
    public _form?: string,
    /** Default list of ledger configs to display */
    public configs?: LedgerConditionalConfig[],
    /** List of configs to display in dropdowns. */
    public _filter?: string[]
  ) { }
}