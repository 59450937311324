import { TypeInfo } from "../info/type";
import { ColorCode } from "../toolbox/color";
import { formulaRun } from "../toolbox/formula/formula";
import { StatusLevel } from "../toolbox/status";
import { DisplayPartial } from "./display";
import { Formula } from "./formula/formula";

/** A chip to display in a panel. */
export class Chip {
  constructor(
    /** Color for this chip. */
    public status = StatusLevel.Notice,
    /** Formula to evaluate for chip. */
    public formula = new Formula(),
    /** Color override for chip. */
    public color?: ColorCode
  ) {}

  static typeinfo: TypeInfo<ChipResult> = {
    color: ColorCode.Blue
  }

  /** Evaluate chip. */
  static run(chip: Chip, value: DisplayPartial) {
    let output = formulaRun(chip.formula, value);
    return new ChipResult(chip.status, output === undefined ? undefined : `${output}`, chip.color);
  }

  /** Evaluate a list of chips and return results. */
  static list(chips: Chip[], value: DisplayPartial) {
    return chips.map(c => this.run(c, value)).filter(r => r.text);
  }

  /** Evaluate a list of chips and return first result. */
  static first(chips: Chip[], value: DisplayPartial) {
    for (let chip of chips) {
      let result = this.run(chip, value);
      if (result.text) return result;
    }

    return undefined;
  }
}

/** Result of evaluating a chip. */
export class ChipResult {
  constructor(
    /** Status for chip. */
    public status = StatusLevel.Notice,
    /** Text for chip. */
    public text = '',
    /** Color override for chip. */
    public color?: ColorCode
  ) {}
}