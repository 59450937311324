import { Subscription } from "rxjs";
import { Loop } from "../../../../../../common/model/formula/loop";
import { ArraySome } from "../../../../../../common/toolbox/array";
import { formulaContext } from "../../../../../../common/toolbox/formula/formula";
import { keyNestedSubkeys } from "../../../../../../common/toolbox/keys";
import { Newable, Pair } from "../../../../../../common/toolbox/object";
import { BlockComponent } from "./block.component";
import { SetupFormulaIdentifierComponent } from "./terminal/identifier/setup-formula-identifier.component";

/** A formula block mixin that creates new formula contexts in a loop body. */
export function LoopComponent<T extends Loop>(Base: Newable<BlockComponent<T>>) {
  return class GridBase extends Base {
    
    /** Last determined context for statement. */
  private context?: string;
  /** List of keys in current identifier context. */
  private subkeys: Pair[] = [];
  /** Mapping of identifier components to subscriptions. */
  private subscriptions = new Map<BlockComponent, Subscription>();

    ngOnDestroy() {
      for (let subscription of this.subscriptions.values()) subscription.unsubscribe();
      this.subscriptions.clear();
    }

    override onAttach(component: BlockComponent) {
      super.onAttach(component);
  
      // Listen to changes on this identifier.
      if (component instanceof SetupFormulaIdentifierComponent) {
        this.subscriptions.set(component, component.identifier.subscribe(() => this.recontext()));
        this.recontext();
      }
    }
  
    override onRemove(component: BlockComponent) {
      super.onRemove(component);
      this.subscriptions.get(component)?.unsubscribe();
      this.subscriptions.delete(component);
    }

    /** Callback when a new list of keys is available. */
    override onKeys(keys: ArraySome<Pair[]>) {
      this.keys = keys;
      for (let i = 0; i < this.slots.length; ++i) {
        let keys: ArraySome<Pair[]> = i === 1 && this.subkeys.length ? [this.subkeys, ...this.keys] : this.keys;
        this.slots[i]!.component.instance.onKeys(keys);
      }
    }

    /** Callback when right expression context should be updated. */
    private recontext() {
      // Determine context of right expression.
      let key = formulaContext(this.formula, this.block.statements);
      if (key === this.context) return;
      this.context = key;
  
      // Get list of subkeys under this key.
      this.subkeys = typeof key === 'string' ? keyNestedSubkeys(key, this.keys.flat()) : [];
      this.onKeys(this.keys);
    }
  }
}