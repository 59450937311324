import { Component } from '@angular/core';
import { SettingType } from '../../../../../../../common/info/settings';
import { AuthService } from '../../../service/auth.service';
import { SettingsAccessor } from '../settings-accessor';

@Component({
  selector: 'app-settings-codes',
  templateUrl: './settings-codes.component.html',
  styleUrls: ['./settings-codes.component.scss']
})
export class SettingsCodesComponent extends SettingsAccessor<SettingType.Codes> {
  constructor(
    public auth: AuthService
  ) {
    super();
  }
}