import { RouteGetRequest, RouteGetResponse } from "../../../../../../common/toolbox/api";
import { RoutePaginatePath } from "../request";

/** Request data passed to a results tab. */
export class ResultsData<R extends RoutePaginatePath> {
  constructor(
    /** Initial query to receive results. */
    public request: RouteGetRequest[R],
    /** Initial set of items to display. */
    public response: RouteGetResponse[R]
  ) {}
}