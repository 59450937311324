import { Component, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { Box } from '../../../../../../common/model/box';
import { Pos } from "../../../../../../common/model/pos";
import { fieldControlProviders } from '../../toolbox/angular';
import { overlayMouseExit } from '../../toolbox/overlay';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-resize',
  templateUrl: './resize.component.html',
  styleUrls: ['./resize.component.scss'],
  providers: fieldControlProviders(ResizeComponent)
})
export class ResizeComponent extends FieldControl {
  /** Size for grid. */
  @HostBinding('style.grid-template') style = Pos.css(new Pos(1, 1), undefined, '2rem')['grid-template'];

  /** Minimum selectable size. */
  get min() { return this._min; }
  @Input() set min(min: Pos) {
    this._min = min;
  }

  /** Maximum selectable size. */
  get max() { return this._max; }
  @Input() set max(max: Pos) {
    this._max = max;
    this.style = Pos.css(max, undefined, '2rem')['grid-template'];
    this.area = Pos.array(max, new Pos(1, 1));
  }

  /** Minimum selectable size. */
  _min = new Pos();
  /** Maximum selectable size. */
  _max = new Pos();

  /** Current size. */
  value?: Pos;
  /** Current position being hovered over. */
  hover = new Pos();
  /** Current previewed size. */
  preview = new Pos();
  /** List of positions for generating grid. */
  area: Pos[] = [];

  /** Listener for when mouse leaves resize area. */
  private _listener?: Function;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super();
    this._listener = overlayMouseExit(this.elementRef, this.renderer, () => this.exit());
  }

  writeValue(value?: Pos) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.value = value;
    this.preview = Pos.from(this.value ?? this.preview);
    this.hover = Pos.from(this.value ?? this.preview);
  }

  /** Callback when mouse enters a new field. */
  move(pos: Pos) {
    this.preview = Pos.clamp(Pos.from(pos), new Box(this.min.x, this.min.y, this.max.x, this.max.y))
    this.hover = Pos.from(pos);
  }

  /** Submit a position after clicking. */
  submit(pos: Pos) {
    if (pos.x < this._min.x || pos.y < this._min.y) return;
    this.changed(pos);
  }

  /** Cleanup listener after exiting. */
  private exit() {
    this.preview = Pos.from(this.value ?? this.preview);
    this.hover = Pos.from(this.value ?? this.preview);
    if (this._listener) this._listener();
  }
}
