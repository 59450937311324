<app-banner *ngIf="state.claims.length" [color]="'warning' | statusColor">
  <button banner-prefix color="black" (click)="onDetails()">Click here to view existing claims.</button>
  <div>There are already existing claims for the current cardholder.</div>
</app-banner>
<app-stepper [steps]="steps" [current]="current" (currentChange)="restep($event)">
  <div class="row fill">
    <div class="column fill">
      <app-member-info title="Disputed Cardholder Info" [class.hide]="state.member._id | idNull" [loading]="state.loading" [member]="state.member" [details]="true"></app-member-info>
      <app-account-info title="Disputed Account Info" [class.hide]="state.account._id | idNull" [form]="accountForm" [account]="state.account" [card]="state.card"></app-account-info>
      <app-card class="fill" [class.hide]="!state.transactions.length">
        <app-card-header>Disputed Transactions Info</app-card-header>
        <app-model-grid [config]="config" [expand]="true"></app-model-grid>
      </app-card>
    </div>
    <div class="flex fill-2">
      <ng-template #container></ng-template>
    </div>
  </div>
</app-stepper>