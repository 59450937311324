import { Validator } from "../validator/base";

/** Extract type annotations from an object. */
export function infoOf(value: any, key: string, fallback = {}): any {
  if (value instanceof Validator) {
    return infoOf(value.value(), key);
  } else if (value instanceof Object) {
    let object = value as any;
    return object.constructor[key] ?? object[key] ?? fallback;
  } else {
    return fallback;
  }
}