import { AccountCategory } from "../../code/standard/common";
import { TypeInfo } from "../../info/type";
import { UnionInfo } from "../../info/union";
import { PickPartial } from "../../toolbox/object";
import { Card } from "../card";
import { Member } from "../member";
import { AccountLookup } from "./base";
import { AccountLoan } from "./loan";
import { AccountShare } from "./share";

/** Account with subaccount information attached. */
export type Account = AccountLoan | AccountShare;
/** An account with attached members and transactions. */
export type AccountJoin = AccountJoinLoan | AccountJoinShare;
/** Union of all possible account fields. */
export type AccountUnion = { category: AccountCategory } & AccountLookup & Omit<AccountLoan, 'category'> & Omit<AccountShare, 'category'>;
/** A partial update of an existing account. */
export type AccountPatch = PickPartial<Account, '_id'>;

/** A loan with attached members and cards. */
export class AccountJoinLoan extends AccountLoan implements AccountLookup {
  
  constructor(
    /** List of members owning this account. */
    public members: Member[] = [],
    /** List of cards linked to account. */
    public cards: Card[] = []
  ) {
    super();
  }

  static override typeinfo: TypeInfo<AccountJoinLoan> = {
    ...AccountLoan.typeinfo,
    members: [new Member()],
    cards: [new Card()]
  }
}

/** A share with attached members and cards. */
export class AccountJoinShare extends AccountShare implements AccountLookup {
  
  constructor(
    /** List of members owning this account. */
    public members: Member[] = [],
    /** List of cards linked to account. */
    public cards: Card[] = []
  ) {
    super();
  }

  static override typeinfo: TypeInfo<AccountJoinShare> = {
    ...AccountShare.typeinfo,
    members: [new Member()],
    cards: [new Card()]
  }
}

/** Mapping of account categories to classes. */
export class AccountClass {
  [AccountCategory.Loan] = new AccountLoan();
  [AccountCategory.Share] = new AccountShare();
}

/** Mapping of account categories to join classes. */
export class AccountJoinClass {
  [AccountCategory.Loan] = new AccountJoinLoan();
  [AccountCategory.Share] = new AccountJoinShare();
}

/** Type information for each account category. */
export const ACCOUNT_UNIONINFO: UnionInfo<Account, AccountCategory> = {
  tag: 'category',
  classes: new AccountClass()
};

/** Type information for each joined account category. */
export const ACCOUNT_JOIN_UNIONINFO: UnionInfo<AccountJoin, AccountCategory> = {
  tag: 'category',
  classes: new AccountJoinClass()
};