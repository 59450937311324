<form [formGroup]="builder" class="fill" (ngSubmit)="onSearch()">
  <div class="flex">
    <app-field left>
      <button icon-button type="submit" [disabled]="!builder.valid">search</button>
      <app-string placeholder="Search" formControlName="query"></app-string>
    </app-field>
    <app-field right>
      <app-select formControlName="category" [autoselect]="false" (selected)="onCategory($event)">
        <ng-container *ngFor="let o of options">
          <app-option [view]="o.category | codeType:auth._inst:CommonCode.SearchCategory | async" [value]="o.category" *ngIf="o.permissions | permissionSome">
            <div class="fill"></div>
            <button icon-button type="button" (click)="onAdvanced(o.category)">manage_search</button>
          </app-option>
        </ng-container>
      </app-select>
    </app-field>
  </div>
</form>