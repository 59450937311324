import { TypeValidation } from "../info/type";
import { ValidationStatus, Validator, ValidatorLog } from "./base";

/** A validator that can return multiple results. */
export abstract class MultiValidator<T> extends Validator<T> {
  protected list: ValidatorLog[] = [];

  /** Push new entry to internal validation list. */
  protected push(validation: TypeValidation<T>) {
    if (typeof validation === 'string') {
      this.list.push(new ValidatorLog(`Invalid property: ${validation}`));
      return ValidationStatus.Error;
    } else if (Array.isArray(validation)) {
      this.list = validation;
      return ValidationStatus.Error;
    }

    return ValidationStatus.Okay;
  }

  override logs() {
    if (!this.list.length) return super.logs();

    let list = this.list;
    this.list = [];
    return list;
  }
}