import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventCategory } from '../../../../../../common/model/event/category';
import { ClaimResultType } from '../../../../../../common/model/event/result/claim';
import { Job } from '../../../../../../common/model/job/job';
import { JOB_TYPE_NAME, JobType } from '../../../../../../common/model/job/payload';
import { enumMapPairs } from '../../../../../../common/toolbox/enum';
import { MaybeId, idOmit } from '../../../../../../common/toolbox/id';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { getRequest, postRequest } from '../../../common/toolbox/request';
import { Claim } from '../../../../../../common/model/claim/claim';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent {

  /** List of jobs to configure. */
  @Input() set jobs(jobs: Job[]) { this._jobs = jobs; this.dirty = []; }
  /** List of allowed job types to select. */
  @Input() types = enumMapPairs<JobType>(JOB_TYPE_NAME);
  /** Claim being viewed, if applicable. */
  @Input() claim?: Claim;

  /** Emits when a new job is created. */
  @Output() added = new EventEmitter<void>();

  /** List of jobs being edited. */
  _jobs: Job[] = [];
  /** New job being created. */
  job = idOmit(new Job());
  /** Mapping of job indexes to which are dirty. */
  dirty: boolean[] = [];
  /** True if currently waiting on jobs to submit. */
  loading = false;

  constructor(
    private log: LogService,
    private http: HttpClient,
    private auth: AuthService
  ) {}

  ngOnInit() {
    if (this.types[0]) {
      this.repayload(this.job, this.types[0].value);
    }
  }

  /** Callback when submitting changes to a notification. */
  async onSubmit(job: MaybeId<Job>, index?: number) {
    this.loading = true;

    let result = await postRequest(this.http, 'jobs', { items: [job] });
    if (errorResponse(result)) {
      this.log.show(result);
      this.loading = false;
      return;
    }

    this.added.emit();
    this.loading = false;
    if (index !== undefined) {
      this.dirty[index] = false;
    } else if (this.types[0]) {
      this.onType(this.job, this.types[0].value);
    }
  }

  /** Callback when changing type of a job. */
  onType(job: MaybeId<Job>, type: JobType, index?: number) {
    this.repayload(job, type);

    // Set new object reference to refresh configuration component.
    if (index !== undefined) {
      this._jobs[index] = { ...this._jobs[index]! };
      this.dirty[index] = true;
    } else this.job = { ...this.job };
  }

  /** Callback when unsubscribing from specified notification. */
  async onUnsubscribe(i: number) {
    let result = await getRequest(this.http, 'notifications/unsubscribe', { _job: this._jobs[i]!._id });
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }

    this._jobs.splice(i, 1);
  }

  /** Initialize default fields of job. */
  private repayload(job: MaybeId<Job>, type = job.payload.type) {
    job._inst = this.auth._inst;
    job._user = this.auth.session._id;
    job.payload = Job.payload(type);
    if (Job.notification(job)) job.payload._users = [this.auth.session._id];
    if ('_claim' in job.payload && this.claim) job.payload._claim = this.claim._id;

    switch (job.payload.type) {
    case JobType.NotificationClaimDate:
      job.payload.date = 'reportDate';
      job.payload.days = 3;
      break;
    case JobType.NotificationEvent:
      if (this.claim) {
        job.payload.category = EventCategory.Claim;
        job.payload.result = ClaimResultType.ClaimAdd;
      } break;
    }

    job.name = Job.autoname(job, this.auth.session.name, this.claim);
    return job;
  }
}
