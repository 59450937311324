import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { UnionValidator } from "../../validator/union";
import { Box } from "../box";
import { Display } from "../display";
import { FIELD_CONFIG_UNIONINFO, FieldConfig } from "./field-config";

/** An onscreen field with a position. */
export class Field extends Display {

  constructor(
    /** Onscreen position of field. */
    public box = new Box(),
    /** Table of this field if in table mode. */
    public _table?: string,
    /** Additional configuration for this field. */
    public config?: FieldConfig,
    /** Validator formula for this field, if applicable. */
    public _validator?: string
  ) {
    super();
  }

  static override typeinfo: TypeInfo<Field> = {
    name: '',
    _table: ID_DEFAULT,
    config: new UnionValidator(FIELD_CONFIG_UNIONINFO),
    _validator: ID_DEFAULT,
  };

  static override collectioninfo = {
    ...super.collectioninfo,
    _table: 'tables'
  };
}