import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { CommonCode, DisputesCode } from '../../../../../../../common/code/system';
import { LedgerConfigPostResponse } from '../../../../../../../common/message/ledger-config';
import { DisplayValue } from '../../../../../../../common/model/display';
import { LEDGER_ACCOUNT_TYPE_NAME } from '../../../../../../../common/model/ledger/account';
import { LedgerConfig } from '../../../../../../../common/model/ledger/config';
import { enumMapPairs } from '../../../../../../../common/toolbox/enum';
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { LogService } from '../../../../common/service/log.service';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';

@Component({
  selector: 'app-setup-ledger-config-edit',
  templateUrl: './setup-ledger-config-edit.component.html',
  styleUrls: ['./setup-ledger-config-edit.component.scss'],
  host: {
    class: 'row fill'
  }
})
export class SetupLedgerConfigEditComponent extends SetupEditComponent<LedgerConfig, LedgerConfigPostResponse> {
  readonly CommonCode = CommonCode;
  readonly DisputesCode = DisputesCode;
  readonly LEDGER_ACCOUNT_TYPE_NAME = LEDGER_ACCOUNT_TYPE_NAME;
  
  /** Document template being edited. */
  value = idMaybe(new LedgerConfig());
  /** Selectable ledger account types. */
  types = enumMapPairs(LEDGER_ACCOUNT_TYPE_NAME);
  /** Object to display properties for. */
  proxy?: DisplayValue;

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private http: HttpClient
  ) {
    super(elementRef, log);
  }

  /** Submit current changes to ledger configuration. */
  push() {
    return postRequest(this.http, 'ledger-configs', { items: [this.value] });
  }

  /** Reset current ledger configuration with new one. */
  async reset(value: MaybeId<LedgerConfig>) {
    this.value = value;
  }

  protected refield() {
    this.proxy = this.proxy ? undefined : new DisplayValue();
  }
}
