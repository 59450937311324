import { DocumentTemplateType } from "../../code/standard/common";
import { BuiltinMap } from "../../toolbox/builtinmap";
import { StandardAttachment, SystemAttachment } from "./attachment";
import { DisputesFilterRule, SystemFilterRule } from "./filter-rule";

/** List of all document templates. */
export type SystemDocumentTemplate = StandardDocumentTemplate | DisputesDocumentTemplate;

/** Standard document templates provided for all products. */
export enum StandardDocumentTemplate {
  Notification                          = 'Notification.html',
  NotificationImport                    = 'Notification - Import.html'
}

/** Disputes document template templates. */
export enum DisputesDocumentTemplate {
  ClaimACHIntake                        = 'ACH Claim - Intake.docx',
  ClaimCardChargeback                   = 'Card Claim - Chargeback.html',
  ClaimCardIntake                       = 'Card Claim - Intake.docx',
  ClaimCardProvisionalCreditLetter      = 'Card Claim - Provisional Credit Letter.docx',
  ClaimCardResolutionChargebackCredit   = 'Card Claim Resolution - Chargeback Credit.docx',
  ClaimCardResolutionFinalCredit        = 'Card Claim Resolution - Final Credit.docx',
  ClaimCardResolutionNoCredit           = 'Card Claim Resolution - No Credit.docx',
  ClaimCardResolutionProvisionalCredit  = 'Card Claim Resolution - Provisional Credit.docx',
  ClaimCardResolutionWithdrawn          = 'Card Claim Resolution - Withdrawn.docx',
  ClaimCheckForgeryIntake               = 'Check Forgery Claim - Intake.docx',
  ClaimCheckUnauthorizedIntake          = 'Check Unauthorized Claim - Intake.docx',
  OpenClaims                            = 'Open Claims.csv',
  OperatorClaims                        = 'Operator Claims.csv',
  ReasonClaims                          = 'Reason Claims.csv',
  ClaimTotals                           = 'Claim Totals.csv'
}

/** Configuration for a built-in document template. */
interface BuiltinDocumentTemplate {
  /** Override for document template name. */
  name?: string
  /** Context this document template is used in. */
  type: DocumentTemplateType
  /** Description of document template. */
  description?: string
  /** Condition to add item to this document template. */
  rule?: SystemFilterRule
  /** Uploads to associate with document template. */
  uploads?: SystemAttachment[]
}

/** List of builtin document templates that can be used by institutions. */
export const BUILTIN_DOCUMENT_TEMPLATES: Record<SystemDocumentTemplate, BuiltinDocumentTemplate> = {
  [DisputesDocumentTemplate.ClaimACHIntake]: {
    type: DocumentTemplateType.ClaimIntake,
    description: 'Sent to members after a new ACH dispute is opened.'
  },
  [DisputesDocumentTemplate.ClaimCardChargeback]: {
    type: DocumentTemplateType.ClaimCardChargeback,
    description: 'Sent to the card processor to initiate the chargeback process.'
  },
  [DisputesDocumentTemplate.ClaimCardIntake]: {
    type: DocumentTemplateType.ClaimIntake,
    description: 'Sent to members after a new card dispute is opened.'
  },
  [DisputesDocumentTemplate.ClaimCardResolutionChargebackCredit]: {
    type: DocumentTemplateType.ClaimCardResolution,
    description: 'Sent if a claim is denied and the provisional credit is withdrawn from the member\'s account.'
  },
  [DisputesDocumentTemplate.ClaimCardResolutionFinalCredit]: {
    type: DocumentTemplateType.ClaimCardResolution,
    description: 'Sent if a claim is approved and provisional credit has not been granted yet.'
  },
  [DisputesDocumentTemplate.ClaimCardResolutionNoCredit]: {
    type: DocumentTemplateType.ClaimCardResolution,
    description: 'Sent if a claim is denied and no provisional credit was ever granted.'
  },
  [DisputesDocumentTemplate.ClaimCardResolutionProvisionalCredit]: {
    type: DocumentTemplateType.ClaimCardResolution,
    description: 'Sent if a claim is approved and provisional credit may be kept.'
  },
  [DisputesDocumentTemplate.ClaimCardResolutionWithdrawn]: {
    type: DocumentTemplateType.ClaimCardResolution,
    description: 'Notification that the customer requested that the claim should be closed.'
  },
  [DisputesDocumentTemplate.ClaimCardProvisionalCreditLetter]: {
    type: DocumentTemplateType.ClaimCardInitialCorrespondence,
    description: 'General notification that a member will be granted provisional credit.'
  },
  [DisputesDocumentTemplate.ClaimCheckForgeryIntake]: {
    type: DocumentTemplateType.ClaimIntake,
    description: 'Sent to members after a new check forgery dispute is opened.'
  },
  [DisputesDocumentTemplate.ClaimCheckUnauthorizedIntake]: {
    type: DocumentTemplateType.ClaimIntake,
    description: 'Sent to members after a new unauthorized check dispute is opened.'
  },
  [DisputesDocumentTemplate.OpenClaims]: {
    type: DocumentTemplateType.ClaimList,
    description: 'Report of all open claims.',
    rule: DisputesFilterRule.OpenClaims
  },
  [DisputesDocumentTemplate.OperatorClaims]: {
    type: DocumentTemplateType.ClaimList,
    description: 'Report of claims by operator.',
    rule: DisputesFilterRule.OperatorClaims
  },
  [DisputesDocumentTemplate.ReasonClaims]: {
    type: DocumentTemplateType.ClaimList,
    description: 'Report of claims by reason.',
    rule: DisputesFilterRule.ReasonClaims
  },
  [StandardDocumentTemplate.Notification]: {
    type: DocumentTemplateType.Notification,
    description: 'General template for emitting system notifications.',
    uploads: [StandardAttachment.FintegrateLogo200px]
  },
  [StandardDocumentTemplate.NotificationImport]: {
    type: DocumentTemplateType.NotificationImport,
    description: 'Snippet used for import nofications.',
    uploads: []
  },
  [DisputesDocumentTemplate.ClaimTotals]: {
    type: DocumentTemplateType.ClaimList,
    description: 'Report of all claims.',
    rule: DisputesFilterRule.AllClaims
  }
}

/** Get filter rules used by a builtin document template. */
export function builtinDocumentTemplateFilterRules(map: BuiltinMap, name: SystemDocumentTemplate) {
  let builtin = map.documentTemplates.builtin(name);
  return builtin.rule ? [builtin.rule] : [];
}
