import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ErrorResponse } from "../../../../../../common/message/error";
import { WorkQueueBuildPostResponse } from '../../../../../../common/message/work/queue';
import { errorResponse } from "../../../../../../common/toolbox/message";
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { postRequest } from '../../../common/toolbox/request';

@Component({
  selector: 'app-build-work-queues',
  templateUrl: './build-work-queues.component.html',
  styleUrls: ['./build-work-queues.component.scss']
})
export class BuildWorkQueuesComponent {

  /** True if import is being processed. */
  loading = false;
  /** Results of performing import. */
  response?:  WorkQueueBuildPostResponse | ErrorResponse;

  static title() {
    return 'Work Queues';
  }

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private log: LogService
  ) {}

  /** Rebuild work queues */
  async build() {
    this.loading = true;
    this.response = await postRequest(this.http, 'work-queues/build', {_inst: this.auth._inst});
    if (errorResponse(this.response)) this.log.show(this.response);
    else this.log.show('Rebuilt work queues.');
    this.loading = false;
  }
}
