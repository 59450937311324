<app-card body class="column">
  <h2>Status Levels</h2>
  <div class="row">
    <button color="blue" (click)="show('This is a notice log.', StatusLevel.Notice)">Notice</button>
    <button color="yellow" (click)="show('This is a warning log.', StatusLevel.Warning)">Warning</button>
    <button color="red" (click)="show('This is an alert log.', StatusLevel.Alert)">Alert</button>
  </div>
  <h2>Log Tests</h2>
  <div class="row">
    <button (click)="show('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus sapien ut lobortis elementum. Vestibulum maximus enim eget turpis ornare, vitae sagittis tellus blandit. Nam elit elit, hendrerit eget accumsan sed, pellentesque viverra dolor. Vestibulum in magna non ante sollicitudin fringilla. Nulla facilisi. Nunc id ex vel ante mattis pharetra. Quisque aliquet mattis porta. Donec gravida augue vitae eros pulvinar, ac ornare sapien faucibus. Sed laoreet mattis nisi vel ullamcorper. Etiam bibendum odio id dui pellentesque ultricies. Praesent vel massa sed nibh accumsan lacinia sit amet eget ipsum. Integer tincidunt sapien eu nunc tristique semper. Nulla laoreet vestibulum ipsum nec fringilla. Cras pretium urna ac ipsum varius lobortis.')">Lorem Ipsum</button>
    <button color="red" (click)="validate()">Network Error</button>
  </div>
</app-card>
