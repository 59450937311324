import { Component } from '@angular/core';
import { FEATURE_NAMES } from '../../../../../../../common/model/organization/feature';
import { AuthService } from '../../../../common/service/auth.service';

@Component({
  selector: 'app-menu-bar-feature',
  templateUrl: './menu-bar-feature.component.html',
  styleUrls: ['./menu-bar-feature.component.scss']
})
export class MenuBarFeatureComponent {
  readonly FEATURE_NAMES = FEATURE_NAMES;

  constructor(
    public auth: AuthService
  ) {}
}
