import { HttpClient } from "@angular/common/http";
import { Component, Input, ViewChild } from "@angular/core";
import { Subject, debounceTime, takeUntil } from "rxjs";
import { DisplayPartial, DisplayType } from "../../../../../../common/model/display";
import { Table } from "../../../../../../common/model/table";
import { WorkItemJoin } from "../../../../../../common/model/work/item";
import { idNull } from "../../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { ModelGridComponent } from "../../../common/component/model/grid/model-grid.component";
import { GridColumn, GridConfig } from "../../../common/component/model/grid/model-grid.model";
import { AccountService } from "../../../common/service/account.service";
import { AuthService } from "../../../common/service/auth.service";
import { LogService } from "../../../common/service/log.service";
import { SettingGroupService } from "../../../common/service/setting-group.service";
import { getOneRequest, getRequest } from "../../../common/toolbox/request";
import { gridPaginateRequest } from "../../../common/toolbox/source/grid";
import { ServerSource } from "../../../common/toolbox/source/server";
import { WorkListButtonsComponent } from "./buttons/work-list-buttons.component";
import { WorkListData } from "./work-list.model";

@Component({
  selector: 'app-work-list',
  templateUrl: './work-list.component.html',
  styleUrls: ['./work-list.component.scss'],
  host: {
    class: 'column'
  }
})
export class WorkListComponent {

  /** Reference to grid within template. */
  @ViewChild(ModelGridComponent) grid?: ModelGridComponent;

  /** Type of work to display. */
  @Input() type = DisplayType.Account;
  /** Set new work list. */
  @Input() set data(data: WorkListData | undefined) { if (data) this._data = data; this.refetch(); }

  /** Cached table that was last fetched. */
  cache?: Table;
  /** Configurable for work list table. */
  config = new GridConfig(new ServerSource<WorkItemJoin>());
  /** Columns to display after configurable columns. */
  postcolumns: GridColumn[] = [{ key: 'item' as any, size: '8rem', component: WorkListButtonsComponent }];

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();
  /** Last bound list. */
  private _data = new WorkListData();

  constructor(
    private log: LogService,
    private http: HttpClient,
    private auth: AuthService,
    private settingGroups: SettingGroupService,
    private accounts: AccountService,
  ) {}

  /** Work selected item. */
  async onWork(row: DisplayPartial) {
    switch (this._data?.queue.type) {
    case DisplayType.Account:
      this.accounts.queue(this._data.queue, row as WorkItemJoin);
      break;
    }
  }

  /** Refresh visible list of items. */
  async refetch() {
    // Not yet initialized.
    if (idNull(this._data.queue._id) || !this.grid) return;

    // Fetch work items of this queue joined with their collection.
    let queue = this._data.queue;
    let items = await getRequest(this.http, 'work-items/queue', {
      ...gridPaginateRequest(this.type, this.grid!.source),
      _inst: queue._inst,
      _queue: queue._id,
      type: queue.type,
      filter: this._data.filter
    });

    if (errorResponse(items)) {
      this.log.show(items);
      return;
    }

    // Find current position in queue to highlight.
    let selected = items.items.find(r => r.index === this._data.index);
    if (selected) this.grid!.source.toggle(selected);

    // Refresh data
    this.grid!.source.available = items.available;
    this.grid!.source.items = items.items;
  }

  /** Configure model configuration and refreshing. */
  async ngOnInit() {

    // Fetch table configuration for work list.
    let settings = await this.settingGroups.inst();
    if (idNull(settings.collections.workflow.table)) {
      this.log.show('No work list table configured for institution.');
      return;
    }

    let table = this.cache ?? await getOneRequest(this.http, 'tables', {
      _insts: [this.auth._inst],
      _ids: [settings.collections.workflow.table]
    });
    if (errorResponse(table)) {
      this.log.show(table);
      return;
    }

    // We debounce time to prevent multiple requests at once.
    let source = new ServerSource();
    this.config = new GridConfig(source, table);
    source.dataRequest
      .pipe(takeUntil(this.destroy), debounceTime(0))
      .subscribe(() => this.refetch());
    this.refetch();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
