import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { Formula, FormulaPreview } from '../../../../../../common/model/formula/formula';
import { MaybeId, idExists, idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { FormulaQuery, FormulaService } from '../../../common/service/formula.service';
import { LogService } from '../../../common/service/log.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-formula',
  templateUrl: './setup-formula.component.html',
  styleUrls: ['./setup-formula.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupFormulaComponent extends SetupComponent<Formula, FormulaQuery, FormulaPreview> {
  readonly Type = Formula;
  readonly collection = 'formulas';
  readonly route = 'formulas';
  resource = idMaybe(new Formula());

  static title() {
    return 'Formulas';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public http: HttpClient,
    public auth: AuthService,
    public service: FormulaService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }

  /** Drop cached return type of this formula. */
  override async onSubmit(item: MaybeId<Formula>) {
    if (idExists(item)) this.service.returnCache.delete(item._id);
    return super.onSubmit(item);
  }
}
