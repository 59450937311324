import { CollectionInfo } from "../../info/collection"
import { SettingsInfo, SettingType } from "../../info/settings"
import { ID_DEFAULT } from "../../toolbox/id"
import { FusionCollection } from "../fusion"

/** Settings for collection feature in institution. */
export class CollectionsSettings {
  
  /** Options for workflows. */
  workflow = new class {
    /** Table to display for work list. */
    table = ID_DEFAULT

    static settingsinfo: SettingsInfo<CollectionsSettings['workflow']> = {
      table: {
        type: SettingType.Table,
        name: 'Work List Table',
        description: 'The table to display when viewing the items of a work queue.'
      }
    }
    
    static collectioninfo: CollectionInfo<FusionCollection, CollectionsSettings['workflow']> = {
      table: 'tables'
    }

  }
}