import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorResponse } from '../../../../../common/message/error';
import { DocumentationSplit } from '../../../../../common/model/documentation';
import { RouteMethod } from '../../../../../common/toolbox/api';
import { DOCUMENTATION, DocumentationKey } from '../../../../../common/toolbox/docapi';
import { MarkdownDocument } from '../../../../../common/toolbox/markdown';
import { errorResponse } from '../../../../../common/toolbox/message';
import { DocumentationComponent } from '../../module/documentation/documentation.component';
import { DocumentationData } from '../../module/documentation/documentation.model';
import { rawRequest } from '../toolbox/request';
import { LogService } from './log.service';
import { TabService } from './tab.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {

  /** Cached pages. */
  private pages = new Map<string, string>();

  constructor(
    private tabs: TabService,
    private http: HttpClient,
    private log: LogService
  ) {}

  /** Get documentation for given page. */
  async page(key: DocumentationKey, force = false) {
    let split = DocumentationSplit.from(key);
    if (!split.page) return new ErrorResponse(`Documentation key did not contain page: ${key}`);
    let { category, page } = split;

    // Try pulling page from cache first.
    if (!force && this.pages.has(page)) return this.pages.get(page);

    // Fetch this page's markdown source.
    let markdown = await rawRequest<string>(this.http, RouteMethod.Get, `assets/doc/${DOCUMENTATION[category]}/${DOCUMENTATION[page]}.md`, undefined, { responseType: 'text' });
    if (errorResponse(markdown)) {
      this.log.show(markdown);
      return;
    }

    // Build page into HTML.
    let document = new MarkdownDocument(markdown, DOCUMENTATION[page]);
    let error = document.error();
    if (error) return error;

    // Add built HTML to cache.
    let html = `${document}`;
    this.pages.set(page, html);
    return html;
  }

  /** Open documentation for given category and page. */
  open(page: DocumentationKey) {
    this.tabs.open(DocumentationComponent, new DocumentationData(page));
  }
}
