import { FusionCollectionName } from "../../../../../../common/model/fusion";
import { DiffResult } from "../../../../../../common/toolbox/diff";
import { CollectionService } from "../../../common/service/collection.service";
import { SetupRoute } from "../../../common/toolbox/setup";

/** Data passed to document template scan dialog. */
export class SetupCompareDialogData {
  constructor(
    /** Collection being exported. */
    public collection: FusionCollectionName,
    /** Route to contact to update resources. */
    public route: SetupRoute,
    /** Resolver to upload and download items. */
    public collections: CollectionService,
    /** Diffs to display. */
    public diffs: DiffResult[]
  ) { }
}

export type SetupCompareDialogReturn = never;