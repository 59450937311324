import { Directive, ElementRef, Input } from '@angular/core';
import { HTMLSetupElement } from '../toolbox/element/setup';
import { FusionCollectionQuery } from '../service/resource.service';

@Directive({
  selector: '[setup]'
})
export class SetupDirective<C extends FusionCollectionQuery> {
  /** Sets collection to configure. */
  @Input() set setup(collection: C) {
    let element = this.elementRef.nativeElement as HTMLSetupElement<C>;
    element.dataset.collection = collection;
  }
  
  /** Sets ID to configure. */
  @Input('setup-id') set id(_id: string | undefined) {
    let element = this.elementRef.nativeElement as HTMLSetupElement<C>;
    element.dataset.id = _id ?? '';
  }

  constructor(
    private elementRef: ElementRef
  ) {}
}
