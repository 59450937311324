<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
    </app-setup-base-header>
    <app-setup-base-body>
      <div class="row align-end">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Type</app-label>
          <app-select [disabled]="value | setupReadonly" [ngModel]="value.config.type" (selected)="onType($event)">
            <app-option *ngFor="let t of types" [view]="t | enum:TASK_TYPE_NAME" [value]="t"></app-option>
          </app-select>
        </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>Description</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Displayed when viewing result of task in tables."></app-hover-help>
          </app-sublabel>
          <app-string [(ngModel)]="value.description" [required]="false"></app-string>
          <button icon-button tooltip="Available fields" (click)="refield(!proxy)">storage</button>
        </app-field>
        <app-field class="fill">
          <app-label>Message</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Shown to operator when opening the task."></app-hover-help>
          </app-sublabel>
          <app-string [(ngModel)]="value.message"></app-string>
        </app-field>
      </div>
      <app-labels>
        <app-label>Triggers</app-label>
        <app-sublabel>
          <app-hover-help tooltip="Executed after task dialog is closed and all other triggers have been run."></app-hover-help>
        </app-sublabel>
      </app-labels>
      <app-resource-list collection="triggers" [(ngModel)]="value._triggers"></app-resource-list>
      <ng-template #container></ng-template>
    </app-setup-base-body>
    <app-setup-base-footer>
      <app-setup-preview-button [showMode]="true" (partialChange)="partial = $event" (modeChange)="mode = $event"></app-setup-preview-button>
      <button (click)="onSubmit()">Save</button>
      <button (click)="onPreview()">Preview</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>
<app-setup-property-list *ngIf="proxy" [object]="proxy"></app-setup-property-list>