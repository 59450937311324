import { Component, Inject } from '@angular/core';
import { SearchDistance, closestString } from '../../../../../../../common/toolbox/levenshtein';
import { DIALOG_DATA, DialogRef } from '../../../../common/component/dialog/dialog.model';
import { SetupDocumentTemplateScanDialogData, SetupDocumentTemplateScanDialogReturn } from './setup-document-template-scan-dialog.model';

/** A list of document template details. */
class DocumentTemplateDetailList {
  constructor(
    /** Title of list. */
    public title = '',
    /** List of details. */
    public details: DocumentTemplateDetail[] = []
  ) { }
}

/** A single detail of a document template. */
class DocumentTemplateDetail {
  constructor(
    /** Field this detail is for. */
    public key = '',
    /** Suggestion for field name. */
    public suggestion?: SearchDistance
  ) { }
}

@Component({
  selector: 'app-setup-document-template-scan-dialog',
  templateUrl: './setup-document-template-scan-dialog.component.html',
  styleUrls: ['./setup-document-template-scan-dialog.component.scss']
})
export class SetupDocumentTemplateScanDialogComponent {

  /** List of document template details. */
  lists: DocumentTemplateDetailList[] = [];

  static title(data: SetupDocumentTemplateScanDialogData) {
    let count = data.scan.identifiers.invalid.size + data.scan.pipes.invalid.size;
    return `Template Errors (${count})`;
  }
  
  constructor(
    @Inject(DIALOG_DATA)
    public data: SetupDocumentTemplateScanDialogData,
    protected dialogRef: DialogRef<SetupDocumentTemplateScanDialogReturn>
  ) {
    this.lists = ([
      ['Valid Fields', 'Invalid Fields', this.data.scan.identifiers],
      ['Valid Pipes', 'Invalid Pipes', this.data.scan.pipes],
      ['Valid Codes', 'Invalid Codes', this.data.scan.codes]
    ] as const).flatMap(([valid, invalid, result]) => [{
      title: valid,
      details: [...result.valid].map(identifier => new DocumentTemplateDetail(identifier))
    }, {
      title: invalid,
      details: [...result.invalid].map(identifier => new DocumentTemplateDetail(identifier, closestString(identifier, [...this.data.scan.identifiers.allowed])))
    }]);
  }
}
