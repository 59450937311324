import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { AccountGetRequest } from "../../../../../../common/message/account";
import { WorkFilter } from "../../../../../../common/model/work/filters";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { DisplayType } from "../../../../../../common/model/display";
import { TabRef, TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { LogService } from '../../../common/service/log.service';
import { getOneRequest } from '../../../common/toolbox/request';
import { WorkListData } from '../../work/list/work-list.model';
import { AccountComponent } from '../account.component';
import { AccountData } from '../account.model';
import { AccountWorkData } from './account-work.model';

@Component({
  selector: 'app-account-work',
  templateUrl: './account-work.component.html',
  styleUrls: ['./account-work.component.scss'],
  host: {
    class: 'column fill'
  }
})
export class AccountWorkComponent {
  readonly DisplayType = DisplayType;
  
  /** True to show work page. */
  work = true;
  /** Current filter being displayed. */
  filter = WorkFilter.All;
  
  /** Data passed to account component. */
  data?: AccountData;
  /** Data passed to work item list. */
  list?: WorkListData;

  constructor(
    @Inject(TAB_DATA)
    public bar: AccountWorkData,
    private log: LogService,
    private http: HttpClient,
    private tabRef: TabRef
  ) {}

  /** Display new account in main view. */
  async refresh(cursor = this.bar.cursor): Promise<any> {
    this.bar.cursor = cursor;
    if (!cursor) return;

    // Get next account.
    let request: AccountGetRequest = { _insts: [this.bar.queue._inst], _ids: [cursor._item] };
    let account = this.bar.account._id === this.bar.cursor?._item ? this.bar.account : await getOneRequest(this.http, 'accounts', request);
    if (errorResponse(account)) return this.log.show(account);
    this.bar.account = account;

    // Set title, main view and list.
    this.tabRef.title(AccountComponent.title({ account }));
    this.list = { queue: this.bar.queue, filter: this.filter, index: cursor.index };
    this.data = { ...this.bar };
  }
}
