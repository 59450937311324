import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { Subscription, merge } from "rxjs";
import { ClaimType } from "../../../../../../../common/code/standard/disputes";
import { PaginateResponse } from "../../../../../../../common/message/paginate";
import { AccountCardReference, AccountCardRelationship } from "../../../../../../../common/model/account-card";
import { Card } from "../../../../../../../common/model/card";
import { DisplayPartial } from "../../../../../../../common/model/display";
import { Icon } from "../../../../../../../common/model/icon";
import { IndexMap } from "../../../../../../../common/model/indexmap";
import { RouteMethod } from "../../../../../../../common/toolbox/api";
import { arrayEmpty } from "../../../../../../../common/toolbox/array";
import { claimTyped } from "../../../../../../../common/toolbox/claim";
import { errorPartition } from "../../../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../../../common/toolbox/status";
import { GridIconComponent } from "../../../../common/component/grid/icon/grid-icon.component";
import { DisplayIcon, GridIcon } from "../../../../common/component/grid/icon/grid-icon.model";
import { GridColumn, GridConfig } from "../../../../common/component/model/grid/model-grid.model";
import { TAB_DATA } from "../../../../common/component/tab/bar/tab-bar.model";
import { AuthService } from "../../../../common/service/auth.service";
import { LogService } from "../../../../common/service/log.service";
import { TableService } from "../../../../common/service/table.service";
import { bulkRequest } from "../../../../common/toolbox/request";
import { ClientSource } from "../../../../common/toolbox/source/client";
import { sourceReselect } from "../../../../common/toolbox/source/select";
import { CLAIM_INTAKE_STEP_STATE, ClaimIntakeState } from "../claim-intake.model";
import { ClaimIntakeStepComponent } from "../step/claim-intake-step.component";
import { ClaimIntakeAccountsData } from "./claim-intake-accounts.model";

@Component({
  selector: 'app-claim-intake-accounts',
  templateUrl: './claim-intake-accounts.component.html',
  styleUrls: ['./claim-intake-accounts.component.scss']
})
export class ClaimIntakeAccountsComponent extends ClaimIntakeStepComponent<ClaimIntakeAccountsData> {

  /** Dummy configuration for form. */
  config = new GridConfig<DisplayIcon>(new ClientSource());
  /** True to display loading spinner. */
  loading = true;
  
  /** Subscription to row icon clicks. */
  protected subscription = new Subscription();
  /** Arrow at the start of each row. */
  protected precolumns: GridColumn[] = [{ key: 'icon' as any, size: '2rem', component: GridIconComponent }];

  constructor(
    @Inject(CLAIM_INTAKE_STEP_STATE) public state: ClaimIntakeState,
    @Inject(TAB_DATA) public override data: ClaimIntakeAccountsData,
    private log: LogService,
    private tables: TableService,
    private auth: AuthService,
    private http: HttpClient
  ) {
    super();
  }

  async ngOnInit() {
    let table = this.state.claim.type === ClaimType.Card ? await this.tables.setting('general.account.cardTable') : await this.tables.setting('general.account.table');
    this.config = new GridConfig(this.config.source, table);

    // TODO Optimize this to not send one request per account.
    this.loading = true;
    let [error, accounts] = errorPartition('There was an error fetching some accounts.', await bulkRequest(this.http, ...this.state.member.accounts.map(account => ({
      method: RouteMethod.Get as const,
      route: 'accounts' as const,
      body: {
        _insts: [this.auth._inst],
        _ids: [account._id],
        core: true,
        category: account.category,
        type: account.type,
        number: account.number,
        subAccount: account.subAccount,
        getCards: this.state.claim.type === ClaimType.Card
      }
    }))));
    if (error) this.log.show(error);

    if (this.state.claim.type === ClaimType.Card) {
      this.config.source.items = PaginateResponse.flat(accounts).flatMap(account => account.cards.map(card => ({
        account, card, icon: new GridIcon(Icon.ArrowForward)
      })));
      if (arrayEmpty(this.config.source.items)) this.log.show('No cards were found for the specified account.');
    } else {
      this.config.source.items = PaginateResponse.flat(accounts).map(account => ({
        account, icon: new GridIcon(Icon.ArrowForward)
      }));
    }

    // Autoselect last selected account + card pair.
    let map = new IndexMap<any, string>(new AccountCardRelationship());
    sourceReselect(
      this.state.account, this.config.source,
      () => map.key({ _account: this.state.account._id, _card: this.state.card._id }),
      partial => map.key(partial.card ? { _account: partial.account!._id, _card: partial.card._id } : { _account: partial.account })
    );

    // Auto-navigate when row icon is clicked.
    this.subscription.unsubscribe();
    this.subscription = merge(...this.config.source.items.map(item => item.icon!.click)).subscribe(async partial => {
      await this.onAccount(partial);
      this.offset.emit(+1);
    });

    this.loading = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAccount(partial: DisplayPartial) {
    // Reset transactions if selecting a new account + card combination.
    let _inst = this.auth._inst;
    let account = partial.account!;
    let card = partial.card;
    let map = new IndexMap<AccountCardReference, string>(new AccountCardRelationship());
    if (map.key({ _account: this.state.account._id, _card: this.state.card._id }) !== map.key({ _account: account._id, _card: card?._id ?? 'undefined' })) this.state.transactions = [];

    // Apply card details to card claim.
    if (claimTyped(this.state.claim, ClaimType.Card)) {
      if (!card) return this.log.show('Selected account had no available cards.', StatusLevel.Alert);
      this.state.claim.cardDetails._card = card._id;
    }

    // Assign account and cards.
    this.state.claim._inst = _inst;
    this.state.claim._account = account._id;
    this.state.account = account;
    this.state.card = card ?? new Card();
    this.lock.emit();
  }
}
