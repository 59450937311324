import { Component, Input } from '@angular/core';
import { BankUnion } from '../../../../../../common/model/bank';
import { Member } from "../../../../../../common/model/member";
import { ID_DEFAULT } from "../../../../../../common/toolbox/id";
import { FormConfig } from '../../../common/component/form/form.model';
import { AuthService } from '../../../common/service/auth.service';
import { FormService } from '../../../common/service/form.service';
import { MemberService } from '../../../common/service/member.service';

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.scss'],
  host: {
    class: 'card'
  }
})
export class MemberInfoComponent {
  /** Configuration for account form. */
  config?: FormConfig;

  /** Title to display at top of panel. */
  @Input() title: BankUnion<string> = {
    bank: 'Customer Info',
    creditUnion: 'Member Info'
  };

  /** True to display loading spinner. */
  @Input() loading = false;
  /** True to show details button */
  @Input() details = false;
  /** Member currently being viewed. */
  @Input() set member(member: Member | undefined) { this.refresh(member); }

  constructor(
    private forms: FormService,
    private auth: AuthService,
    private members: MemberService
  ) {}

  /** Fetch configuration for new account to display. */
  private async refresh(member?: Member): Promise<any> {
    if (!member) return;

    // Fetch default member form.
    this.config = {
      form: await this.forms.setting('general.member.form'),
      value: { member }
    };
  }

  onDetails() {
    let member = this.config?.value?.member;
    if (member && member._id !== ID_DEFAULT) this.members.open({ _inst: this.auth._inst, _ids: [member._id] });
  }
}
