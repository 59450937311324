import { DocumentTemplateScan } from "../../../../../../../common/model/document-template/scan";

/** Data passed to document template scan dialog. */
export class SetupDocumentTemplateScanDialogData {
  constructor(
    /** Scan to display. */
    public scan: DocumentTemplateScan
  ) {}
}

export type SetupDocumentTemplateScanDialogReturn = true;