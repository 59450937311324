import { Component } from '@angular/core';
import { TerminalUndefined } from "../../../../../../../../common/model/formula/terminal";
import { ColorCode } from '../../../../../../../../common/toolbox/color';
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-undefined',
  templateUrl: './setup-formula-undefined.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-undefined.component.scss'],
  host: { color: ColorCode.Primary }
})
export class SetupFormulaUndefinedComponent extends BlockComponent<TerminalUndefined> {}
