<app-card class="fill">
  <app-card-header>
    <div>Events</div>
    <div class="row">
      <app-label color="white">Filter by</app-label>
      <app-field>
        <app-select empty="All" [(ngModel)]="type" [required]="false">
          <app-option *ngFor="let type of types" [pair]="type"></app-option>
        </app-select>
      </app-field>
    </div>
  </app-card-header>
  <app-event-list [expand]="true" [claim]="claim" [type]="type" [limit]="10" (task)="task.next($event)"></app-event-list>
</app-card>