import { Component, ViewChild } from '@angular/core';
import { BUILTIN_FORMS } from "../../../../../../common/model/builtin/form";
import { AccountForm } from '../../../../../../common/model/builtin/form/account';
import { DisplayType } from "../../../../../../common/model/display";
import { Form } from "../../../../../../common/model/form/form";
import { objectTruthy } from "../../../../../../common/toolbox/object";
import { FormComponent } from '../../../common/component/form/form.component';
import { FormConfig } from '../../../common/component/form/form.model';
import { TabRef } from '../../../common/component/tab/bar/tab-bar.model';
import { AccountService } from '../../../common/service/account.service';
import { AuthService } from '../../../common/service/auth.service';

@Component({
  selector: 'app-account-search',
  templateUrl: './account-search.component.html',
  styleUrls: ['./account-search.component.scss']
})
export class AccountSearchComponent {
  readonly DisplayType = DisplayType;

  /** Reference to form. */
  @ViewChild(FormComponent) form!: FormComponent;

  /** Configuration for form. */
  config: FormConfig = {
    optional: true,
    form: {
      ...new Form(),
      name: 'Account Search',
      editable: [DisplayType.Account, DisplayType.Model],
      fields: BUILTIN_FORMS[AccountForm.Search].fields ?? []
    }
  };

  /** Default title for tab. */
  static title() {
    return 'Account Search';
  }

  constructor(
    public tab: TabRef,
    public auth: AuthService,
    public accounts: AccountService
  ) {}

  /** Callback when submitting account. */
  async onSubmit() {
    this.accounts.open({
      ...objectTruthy(this.form.value.account),
      _insts: [this.auth._inst],
      members: undefined
    });
  }
}