import { OBJECTID_REGEX } from "../toolbox/id";
import { ValidatorLog } from "./base";
import { PatternValidator } from "./pattern";

/** Validate value is an object ID. */
export class IdValidator extends PatternValidator {

  constructor() {
    super(OBJECTID_REGEX);
  }

  override schema() {
    return this.property ?? { bsonType: 'objectId' };
  }

  override logs() { return [new ValidatorLog('Expected valid ObjectId.')]; }
}