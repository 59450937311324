import { BehaviorSubject } from "rxjs";

/** Possible fade animation states. */
export enum FadeState {
  In,
  Idle,
  Out,
  Done
};

/** Host bindings for fade animation states. */
export const FADE_ANIMATIONS = {
  '[class.fade-in]': 'fade.value === 0',
  '[class.fade-out]': 'fade.value === 2'
};

/** Host bindings for fade + scale animation states. */
export const FADE_SCALE_ANIMATIONS = {
  '[class.fade-scale-in]': 'fade.value === 0',
  '[class.fade-scale-out]': 'fade.value === 2'
};

/** Object that implements animation states. */
export class FadeComponent {
  /** Current fade state. */
  fade = new BehaviorSubject(FadeState.In);

  /** Set fadeout to begin. */
  fadeout() {
    this.fade.next(FadeState.Out);
  }

  /** Handle fade animation states. */
  refade({ animationName }: AnimationEvent) {
    if (animationName.endsWith('-in')) {
      this.fade.next(FadeState.Idle);
    } else if (animationName.endsWith('-out')) {
      this.fade.next(FadeState.Done);
      this.fade.complete();
    }
  }
}