<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header class="row">
      <app-field class="fill">
        <app-label>Name</app-label>
        <app-string [(ngModel)]="value.name"></app-string>
      </app-field>
      <app-field class="fill">
        <app-label>Type</app-label>
        <app-select autoselect="false" [ngModel]="value.type" (selected)="onType($event)">
          <app-option *ngFor="let t of types" [view]="t | enum:DISPLAY_TYPE_NAME" [value]="t"></app-option>
        </app-select>
      </app-field>
      <app-field *ngIf="value.type === DisplayType.Model" class="fill">
        <app-label>Model</app-label>
        <app-select [(ngModel)]="value._model">
          <app-option *ngFor="let m of models" [view]="m.name" [value]="m._id"></app-option>
        </app-select>
      </app-field>
    </app-setup-base-header>
    <app-setup-base-body>
      <app-setup-formula-conditions [value]="value.conditions" [pairs]="keys" #container></app-setup-formula-conditions>
    </app-setup-base-body>
    <app-setup-base-footer>
      <button (click)="onSubmit()">Save</button>
      <button *ngIf="dev.on" (click)="onLog()">Log</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>
<app-setup-formula-palette (block)="onBlock($event)" [types]="groups"></app-setup-formula-palette>