import { SEARCH_REGEX, searchQueryPattern } from "../../../../../../common/toolbox/search";
import { ColumnSort, GridFilter } from "../grid";
import { BaseSource } from "./base";

/** Standard container for client-side paginated, filtered and sorted data. */
export class ClientSource<T = any> extends BaseSource<false, T> {
  readonly asynchronous = false;

  /** Unfiltered list of items. */
  override get items() { return super.items; }
  override set items(items: T[]) {
    super.items = items;
    this.available = items.length;
    this.refilter(this.filter);
    this.reselect();
  }

  override get filteredItems() { return this.paginated; }

  /** Additional sorting applied after main sort. */
  postsort?: (values: T[]) => T[];


  /** List of items after applying filter and sort. */
  protected filtered: T[] = [];
  /** List of sorted and filtered items. */
  protected sorted: T[] = [];
  /** List of sorted, filtered, paginated items. */
  protected paginated: T[] = [];
  
  constructor(items: T[] = []) {
    super(items);
    this.refilter();
  }

  /** Add a new row to source. */
  override push(...items: T[]) {
    super.push(...items);
    this.refilter(this.filter);
  }

  /** Splice an item from source. */
  override splice(start: number, count?: number | undefined) {
    super.splice(start, count);
    this.refilter(this.filter);
  }

  refilter(filter?: GridFilter<T>[]) {
    let last = this.filtered;
    this.filtered = this.availableData;
    this.filter = filter;

    if (filter !== undefined) {
      // Filter callback and query provided.
      this.filtered = [];

      loop:
      for (let item of this.data) {
        
        for (let [key, query] of filter) {
          let value = `${this.extract(key, item)}`;
          if (!searchQueryPattern(SEARCH_REGEX, value).includes(query)) continue loop;
        }

        this.filtered.push(item);
      }
    }
    
    // Update pagination if filtered list changed.
    if (this.filtered !== last) {
      this.resort(this.sort);
    }
  }

  /** Resort data after filtering. */
  resort(sort?: ColumnSort<T>) {
    let last = this.sorted;
    this.sorted = this.filtered;
    this.sort = sort;

    if (this.sort !== undefined && this.sort?.direction) {
      // If sort is provided use it, otherwise use the default sort
      let callback = this.sortCallback(this.sort);
      if (callback) this.sorted = [...this.filtered].sort(callback as any);
    }
    
    // Apply additional sorting of child class.
    if (this.postsort) this.sorted = this.postsort(this.sorted);
    if (this.sorted !== last) this.repage();
    this.data = this.sorted;
  }

  /** Set current page. */
  override repage(page = this.page) {
    this.available = this.sorted.length;
    super.repage(page);

    // Set displayed items before emit.
    this.paginated = this.sorted.slice(this.start, this.start + this.limit);
    this.dataChange.next(this.paginated);
  }

  /** Recalculate selected items after new list is bound. */
  private reselect() {
    let items = new Set([...this.items]);
    let dirty = false;

    // Check for removed items.
    for (let item of this.selection.value.values()) {
      if (items.has(item)) continue;
      this.selection.value.delete(item);
      dirty = true;
    }

    if (!dirty) return;
    this.selection.next(this.selection.value);
  }

}