import { TypeInfo } from "../../info/type";
import { ArraySome, arrayEqual } from "../../toolbox/array";
import { ID_DEFAULT } from "../../toolbox/id";
import { NON_WHITESPACE_REGEX } from "../../toolbox/string";
import { PatternValidator } from "../../validator/pattern";

/** Contents of a comment. */
export class CommentText {
  constructor(
    /** The comment's text */
    public text = '',
    /** Other users mentioned in this comment */
    public mentions?: string[],
    /** Datetime this revision was created*/
    public createDate = new Date(),
  ) {
    text = text.trim();
  }

  static typeinfo: TypeInfo<CommentText> = {
    text: new PatternValidator(NON_WHITESPACE_REGEX),
    mentions: [ID_DEFAULT]
  }
}

/** A comment made on a resource. */
export class CommentData {
  constructor(
    /** User that created the comment, or empty if system comment. */
    public _user?: string,
    /** this comment's text history. */
    public revisions: ArraySome<CommentText> = [new CommentText()],
    /** True if this comment is marked as deleted. */
    public deleted?: boolean,
    /** True if this comment is privileged/private and thus requires PrivateComments permission. */
    public privated?: boolean
  ) { }

  static typeinfo: TypeInfo<CommentData> = {
    _user: ID_DEFAULT,
    revisions: [new CommentText(), new CommentText()],
    deleted: true,
    privated: true
  }

  /** Check if any significant changes were made to a comment. */
  static differs(comment: CommentData, text?: string, mentions?: string[]): boolean {
    let revision = comment.revisions[0];
    return (text && text.trim() !== revision.text) || !!(mentions && !arrayEqual(mentions, revision.mentions));
  }
}

/** An object containing a list of coments. */
export class CommentList {
  constructor(
    /** ID of resource. */
    public _id = ID_DEFAULT,
    /** Institution of resource. */
    public _inst = ID_DEFAULT,
    /** List of comments on resource. */
    public comments: undefined | CommentData[] = undefined
  ) {}

  static typeinfo: TypeInfo<CommentList> = {
    comments: [new CommentData()]
  }
}
