import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Permission } from '../../../../../../../common/model/permission';
import { WorkItemJoin } from '../../../../../../../common/model/work/item';
import { errorResponse } from '../../../../../../../common/toolbox/message';
import { GRID_ROW } from '../../../../common/component/model/grid/model-grid.component';
import { LogService } from '../../../../common/service/log.service';
import { patchRequest } from '../../../../common/toolbox/request';
import { WORK_STAGE_ICON, WORK_STAGE_MAP } from '../../bar/work-bar.model';

@Component({
  selector: 'app-work-list-buttons',
  templateUrl: './work-list-buttons.component.html',
  styleUrls: ['./work-list-buttons.component.scss']
})
export class WorkListButtonsComponent {
  readonly WORK_STAGE_ICON = WORK_STAGE_ICON;
  readonly WORK_STAGE_MAP = WORK_STAGE_MAP;
  readonly Permission = Permission;
  
  constructor(
    @Inject(GRID_ROW) protected row: WorkItemJoin,
    private http: HttpClient,
    private log: LogService,
  ) {}

  /** Bookmark selected work item. */
  async onBookmark(row: WorkItemJoin) {
    let result = await patchRequest(this.http, 'work-items', { _id: row._id, bookmark: !row.bookmark });
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }

    row.bookmark = !row.bookmark;
  }

}
