import { Component, Input } from '@angular/core';
import { arraySum } from "../../../../../../../common/toolbox/array";
import { ColorCode, ColorIcon } from "../../../../../../../common/toolbox/color";
import { CollectionsExecutiveHistory } from './collections-executive-history.model';

/** Possible icons to display for each history increase. */
const HISTORY_ICON: Record<number, ColorIcon> = {
  [-1]: new ColorIcon(ColorCode.Green, 'arrow_downward'),
  [ 0]: new ColorIcon(ColorCode.Blue, 'remove'),
  [ 1]: new ColorIcon(ColorCode.Red, 'arrow_upward')
};

@Component({
  selector: 'app-collections-executive-history',
  templateUrl: './collections-executive-history.component.html',
  styleUrls: ['./collections-executive-history.component.scss']
})
export class CollectionsExecutiveHistoryComponent {
  /** Set displayed history in chart. */
  @Input() public set periods(history: CollectionsExecutiveHistory[]) {
    this._periods = history.map(h => ({ ...h, total: arraySum(h.amounts) }));
    this.balance = this._periods[this._periods.length - 1]?.amounts[8] ?? 0;
    this.icon = undefined;

    if (this._periods.length >= 2) {
      let last = this._periods.slice(-2) as [CollectionsExecutiveHistory, CollectionsExecutiveHistory];
      this.increase = Math.round((last[1].total - last[0].total) / last[0].total * 100);
      this.icon = HISTORY_ICON[Math.sign(this.increase)];
      this.increase *= Math.sign(this.increase);
    }
  }

  /** Amount of increase in last period. */
  increase = 0;
  /** Total balance displayed in lower-left. */
  balance = 0;
  /** Periods to display in chart. */
  _periods: CollectionsExecutiveHistory[] = [];
  /** Icon to display for increase. */
  icon?: ColorIcon | undefined;
}
