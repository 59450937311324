import { Pipe } from '@angular/core';
import { MaybeSystem } from '../../../../../common/toolbox/system';

@Pipe({
  name: 'setupReadonly'
})
export class SetupReadonlyPipe {
  transform(value: MaybeSystem): boolean {
    return !!value.system;
  }
}
