import { Component, Inject, Injector, Optional, ViewContainerRef } from "@angular/core";
import { Formula } from "../../../../../../common/model/formula/formula";
import { ArraySome, arrayLast } from "../../../../../../common/toolbox/array";
import { blockPlaceholder } from "../../../../../../common/toolbox/formula/block";
import { MaybeId } from "../../../../../../common/toolbox/id";
import { numberClamp } from "../../../../../../common/toolbox/number";
import { Newable, Pair, deepCopy } from "../../../../../../common/toolbox/object";
import { BlockComponentMap } from "../setup.module";
import { BlockParameters, BlockSlot } from "./block.model";

/** A formula component that can have code blocks slotted into it. */
@Component({ template: '' })
export class BlockComponent<T = any> {
  /** Last list of bound keys. */
  keys: ArraySome<Pair[]> = [[]];
  /** List of available slots. */
  slots: BlockSlot[] = [];
  /** Top level formula being configured. */
  formula!: MaybeId<Formula>;
  /** Block being edited. */
  block!: T;

  /** Get reference to first slot instance. */
  protected get first() { return this.slots[0]?.component.instance; }
  
  /** Get reference to last slot instance. */
  protected get last() { return arrayLast(this.slots)?.component.instance; }

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') private BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Optional() @Inject('BLOCK_PARENT') private parent: BlockComponent | undefined
  ) {}

  /** Callback when a new child component is attached. */
  onAttach(component: BlockComponent) {
    this.parent?.onAttach(component);
  }

  /** Callback when a new child component is removed. */
  onRemove(component: BlockComponent) {
    this.parent?.onRemove(component);
  }

  /** Callback when a new list of keys is available. */
  onKeys(keys: ArraySome<Pair[]>) {
    this.keys = keys;
    for (let slot of this.slots) {
      slot.component.instance.onKeys(keys);
    }
  }

  /** Replace a given block slot with a new one. */
  protected replace<T>(slot: BlockSlot | undefined, params: BlockParameters<T>) {
    // Attach if no slot passed.
    if (!slot) return this.attach(params);

    // Find position of this slot.
    let index = this.slots.findIndex(item => item === slot);
    if (index === -1) throw new Error('Failed to find slot to replace.');
    let subslot = this.slots[index]!;

    // Remove existing component and slot.
    let component = subslot.component;
    if (!params.next && !subslot.deletable) params.next = BlockSlot.placeholder(params.accepts);
    params.container.remove(params.container.indexOf(component.hostView));
    this.onRemove(component.instance);
    this.slots.splice(index, 1);

    // Add block.
    return this.attach(params, index);
  }

  /** Inject a new block component into given container. */
  protected attach<T>(params: BlockParameters<T>, i = Math.max(0, this.slots.length)) {
    if (!params.next) return this.remove(params);

    // Create injection token to pass block data down.
    let block = deepCopy(params.next);
    let context = params.current.block as any;
    if (Array.isArray(context)) {
      // Insert block into position.
      if (!Array.isArray(block) && !blockPlaceholder(block)) (context as any)[params.current.key] = block;
    } else {
      // Set property for block.
      context[params.current.key] = block;
    }
    
    let injector = Injector.create({
      parent: params.parent,
      providers: [
        { provide: 'BLOCK_PARENT', useValue: this },
        { provide: 'BLOCK', useValue: block }
      ]
    });
  
    // Create component and set block.
    let index = numberClamp(i, 0, params.container.length);
    let type: Newable<BlockComponent> = Array.isArray(block) ? this.BLOCK_COMPONENT_MAP.statements : this.BLOCK_COMPONENT_MAP[block.type];
    let component = params.container.createComponent<BlockComponent>(type, { index, injector });
    component.instance.formula = this.formula;
    component.instance.block = block;

    // Inject slot into list.
    let slot = new BlockSlot(component, params.accepts, params.replaced, !!params.deletable);
    this.slots.splice(i, 0, slot);
    this.onAttach(component.instance);
    this.onKeys(this.keys);
    return slot.component.instance;
  }

  /** Remove block content from given position. */
  protected remove<T>(params: BlockParameters<T>) {
    let context = params.current.block as any;
    if (Array.isArray(context)) {
      // Remove existing array entry.
      (context as any).splice(params.current.key, 1);
    } else {
      // Clear object property.
      context[params.current.key] = {};
    }

    return undefined;
  }

  /** Clear contents of block. */
  protected clear(container: ViewContainerRef) {
    container.clear();
    this.slots = [];
  }

  /** Widen a statement if condition passes. */
  protected widen(check: boolean, slot: BlockSlot) {
    let element: HTMLElement = slot.component.location.nativeElement;
    element.style.flex = check ? '4' : '';
  }
}