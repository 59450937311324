import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { PropertyType } from "../../../../../../common/model/property-type";
import { ObjectKeys } from "../../../../../../common/toolbox/object";
import { DAYS_MAX, DAYS_MIN, DatePeriod, DateRange, dateFloor, dateNull, dateParts, datePeriod } from "../../../../../../common/toolbox/time";
import { fieldControlProviders } from '../../toolbox/angular';
import { KeyResponse, KeyResponseType, inputProcessKey } from '../../toolbox/input';
import { PROPERTY_TYPE_ICON } from '../../toolbox/property';
import { FieldControl } from '../field/field-control';

/** Keys of DateComponent that are numbers. */
type DateString = 'day' | 'month' | 'year' | 'hour' | 'minute' | 'second';
/** Keys of DateComponent that are element references. */
type DateElement = ObjectKeys<DateComponent, ElementRef<HTMLInputElement> | undefined>;

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: fieldControlProviders(DateComponent),
  host: {
    '(focusout)': 'onFocusOut($event)'
  }
})
export class DateComponent extends FieldControl implements ControlValueAccessor {
  readonly PROPERTY_TYPE_ICON = PROPERTY_TYPE_ICON;
  readonly PropertyType = PropertyType;
  readonly DatePeriod = DatePeriod;

  /** Reference to day input. */
  @ViewChild('dayInput', { static : true }) dayRef!: ElementRef<HTMLInputElement>;
  /** Reference to month input. */
  @ViewChild('monthInput', { static : true }) monthRef!: ElementRef<HTMLInputElement>;
  /** Reference to year input. */
  @ViewChild('yearInput', { static : true }) yearRef!: ElementRef<HTMLInputElement>;
  /** Reference to hour input. */
  @ViewChild('hourInput', { static : true }) hourRef!: ElementRef<HTMLInputElement>;
  /** Reference to minute input. */
  @ViewChild('minuteInput', { static : true }) minuteRef!: ElementRef<HTMLInputElement>;
  /** Reference to second input. */
  @ViewChild('secondInput', { static : true }) secondRef!: ElementRef<HTMLInputElement>;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }

  /** True to display time editor. */
  @Input() time = false;
  /** True to make a value required. */
  @Input() required = true;
  /** True to show button. */
  @Input() button = true;
  /** Minimum value. */
  @Input() min = DAYS_MIN;
  /** Maximum value. */
  @Input() max = DAYS_MAX;

  /** Emits when value changes after user input or picker selection. */
  @Output() selected = new EventEmitter<Date | undefined>();

  /** Day of date. */
  day = '';
  /** Month of date. */
  month = '';
  /** Year of date. */
  year = '';
  /** Hour of date. */
  hour = '';
  /** Minute of date. */
  minute = '';
  /** Second of date. */
  second = '';
  /** Current period of date. */
  period = DatePeriod.AM;

  /** Value bound to date field. */
  value: Date | undefined;

  constructor(
    private element: ElementRef
  ) {
    super();
  }

  ngOnInit() {
    if (this.value === undefined) this.format();
  }

  writeValue(value?: string | Date) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    if (typeof value === 'string') {
      let date = new Date(value);
      value = isNaN(date.getTime()) ? undefined : date;
    }
    if (dateNull(value)) value = this.required ? new Date() : undefined;
    value = this.clamp(value);

    this.changed(this.value = value);
    this.format(value);
  }

  /** Callback when typing into month field. */
  onMonthKey($event: KeyboardEvent) {
    this.onTypeField($event, 'month', 'monthRef', 'day', 'dayRef');
  }

  /** Callback when typing into day field. */
  onDayKey($event: KeyboardEvent) {
    this.onTypeField($event, 'day', 'dayRef', 'year', 'yearRef', 'monthRef');
  }

  /** Callback when typing into year field. */
  onYearKey($event: KeyboardEvent) {
    this.onTypeField($event, 'year', 'yearRef', 'hour', 'hourRef', 'dayRef');
  }

  /** Callback when typing into hour field. */
  onHourKey($event: KeyboardEvent) {
    this.onTypeField($event, 'hour', 'hourRef', 'minute', 'minuteRef', 'yearRef');
  }

  /** Callback when typing into minute field. */
  onMinuteKey($event: KeyboardEvent) {
    this.onTypeField($event, 'minute', 'minuteRef', 'second', 'secondRef', 'hourRef');
  }

  /** Callback when typing into second field. */
  onSecondKey($event: KeyboardEvent) {
    this.onTypeField($event, 'second', 'secondRef', undefined, undefined, 'minuteRef');
  }

  /** Callback when toggling period. */
  onPeriod() {
    this.period = this.period === DatePeriod.AM ? DatePeriod.PM : DatePeriod.AM;

    let next = this.clamp(dateParts(this.year, this.month, this.day, this.hour, this.minute, this.second, this.period));
    if (next && isNaN(next.getTime())) return;
    this.changed(this.value = next);
  }

  /** Callback when focus changes within or outside component. */
  onFocusOut($event: FocusEvent) {
    if (this.element.nativeElement.contains($event.relatedTarget)) return;
    this.format(this.value);
  }

  /** Callback when selecting new date from picker. */
  onSelect(date: Date) {
    this.changed(this.value = date);
    this.selected.next(date);
    this.format(date);
  }

  /** Split date into components and set input fields. */
  private format(value?: Date) {
    value = value ?? (this.time ? new Date() : dateFloor());
    this.day = this.dayRef.nativeElement.value = `${value.getDate()}`.padStart(2, '0');
    this.month = this.monthRef.nativeElement.value = `${value.getMonth() + 1}`.padStart(2, '0');
    this.year = this.yearRef.nativeElement.value = `${value.getFullYear()}`.padStart(4, '0');
    this.hour = this.hourRef.nativeElement.value = `${value.getHours() % 12 || 12}`.padStart(2, '0');
    this.minute = this.minuteRef.nativeElement.value = `${value.getMinutes()}`.padStart(2, '0');
    this.second = this.secondRef.nativeElement.value = `${value.getSeconds()}`.padStart(2, '0');
    this.period = datePeriod(value);
  }

  /** Handle typing into field. */
  private async onTypeField(
    $event: KeyboardEvent,
    text: DateString,
    ref: DateElement,
    nextText?: DateString,
    nextRef?: DateElement,
    prevRef?: DateElement
  ) {
    if (this._readonly) { $event.preventDefault(); return; }
    let response = await inputProcessKey($event, true, nextRef ? this[nextRef].nativeElement : undefined);
    switch (response.type) {
      case KeyResponseType.Edit:
        return this.onSetField(text, ref, response);
      case KeyResponseType.EditNext:
        if (!nextText || !nextRef) return;
        return this.onSetField(nextText, nextRef, response);
      case KeyResponseType.ExitLeft:
        if (!prevRef) return;
        this[prevRef].nativeElement.focus();
        break;
      case KeyResponseType.ExitRight:
        if (!nextRef) return;
        this[nextRef].nativeElement.focus();
        this[nextRef].nativeElement.setSelectionRange(0, 0);
        break;
    }
  }

  /** Set value at position of field. */
  private onSetField(text: DateString, ref: DateElement, response: KeyResponse) {
    //date values should always be numeric
    if (isNaN(Number.parseInt(response.value))) return;

    this[text] = response.value;
    this[ref].nativeElement.value = this[text];
    this[ref].nativeElement.focus();
    this[ref].nativeElement.setSelectionRange(response.position + 1, response.position + 1);

    // Assemble values into date and update validity.
    let next = this.clamp(dateParts(this.year, this.month, this.day, this.hour, this.minute, this.second, this.period));
    if (next && isNaN(next.getTime())) return;
    this.changed(this.value = next);
    this.selected.next(next);
  }

  /** Clamp date value if needed. */
  private clamp(date: Date | undefined) {
    if (this._readonly || date === undefined) return date;
    return DateRange.clamp(date, DateRange.minmax(this.min, this.max));
  }
}
