import { Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Claim } from '../../../../../../common/model/claim/claim';
import { Job } from '../../../../../../common/model/job/job';
import { MaybeId, idOmit } from '../../../../../../common/toolbox/id';
import { Newable } from '../../../../../../common/toolbox/object';
import { AuthService } from '../../../common/service/auth.service';
import { JobAccessor } from '../../setup/job/edit/setup-job-edit.model';
import { JOB_SETUP_COMPONENT } from '../../setup/job/setup-job.model';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {

  /** Container to inject components. */
  @ViewChild('container', { static : true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** Claim being viewed, if applicable. */
  @Input() claim?: Claim;

  /** Job being configured. */
  @Input() set job(job: MaybeId<Job>) {
    this._job = job;
    this.repayload();
  }

  /** Emits when changes are made to payload. */
  @Output() touched = new EventEmitter<void>();

  /** Job being configured. */
  _job: MaybeId<Job> = idOmit(new Job());
  /** Component injected into template. */
  component?: JobAccessor;

  /** Subscription to notification changes. */
  private subscription = Subscription.EMPTY;

  constructor(
    private auth: AuthService
  ) {}

  /** Update configuration form for job payload. */
  private repayload() {
    this.containerRef.clear();
    this.component = undefined;

    // Stop listening to last component's changes.
    this.subscription.unsubscribe();
    this.subscription = new Subscription();

    let prototype: Newable<JobAccessor> = JOB_SETUP_COMPONENT[this._job.payload.type];
    this.component = this.containerRef.createComponent(prototype).instance;
    this.component.personal = true;
    this.component.claim = this.claim;
    this.component.job = this._job;
    this.component.reset();

    // Subscribe to changes in component.
    this.subscription = this.component.dirty.subscribe(() => {
      this.touched.next();
      this.rename()
    });
  }

  /** Update name in response to component changes. */
  private rename() {
    this._job.name = Job.autoname(this._job, this.auth.session.name, this.claim);
  }
}
