<app-card-body class="column">
  <div>Errors were found in the uploaded template. Invalid fields will display <code>{{'{{placeholders}}'}}</code> in the rendered template.</div>
  <div class="lists">
    <div class="list" *ngFor="let list of lists">
      <app-label>{{list.title}} <ng-container *ngIf="list.details.length">({{list.details.length}})</ng-container></app-label>
      <code class="block fill">
        <div class="detail" *ngFor="let detail of list.details">
          <div>{{detail.key}}</div>
          <div class="suggestion" *ngIf="detail.suggestion">{{detail.suggestion.value}}?</div>
        </div>
      </code>
    </div>
  </div>
  <div style="text-align: end">Ignore errors and upload template anyway?</div>
  <div class="button-row">
    <button color="red" (click)="dialogRef.close(true)">Override</button>
    <button color="secondary" (click)="dialogRef.cancel()">Cancel</button>
  </div>
</app-card-body>