<app-field>
  <app-label>{{type}} Message</app-label>
  <app-string [required]="false" [placeholder]="type" [(ngModel)]="config.message"></app-string>
</app-field>
<div class="row">
  <app-field class="fill">
    <app-label>{{type}} Show</app-label>
    <app-boolean [required]="false" [(ngModel)]="config.show"></app-boolean>
  </app-field>
  <app-field class="fill">
    <app-label>{{type}} Enable</app-label>
    <app-sublabel>
      <app-hover-help tooltip="Formula that determines if button should be enabled. If excluded, button is always enabled."></app-hover-help>
    </app-sublabel>
    <app-resource-select collection="formulas" [required]="false" [(ngModel)]="config._enable"></app-resource-select>
  </app-field>
</div>
<div>
  <app-labels>
    <app-label>{{type}} Triggers</app-label>
    <app-sublabel>
      <app-hover-help tooltip="Executed when button is clicked. Leave empty to exclude button."></app-hover-help>
    </app-sublabel>
  </app-labels>
  <app-resource-list collection="triggers" [(ngModel)]="config._triggers"></app-resource-list>
</div>