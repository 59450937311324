import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { deepCopy } from "../../../../../common/toolbox/object";
import { ISO_TIMESTAMP_REGEX } from "../../../../../common/toolbox/time";
import { binaryBase64 } from "../../../../../common/toolbox/binary";

/** Recursively format request. */
export function formatRequest(req: any): any {
  if (!req) return req;

  if (Array.isArray(req)) {
    for (let i = 0; i < req.length; ++i) {
      req[i] = formatRequest(req[i]);
    }
  } else if (req instanceof Date) {
    return req.toISOString();
  } else if (req instanceof Blob || req instanceof ArrayBuffer) {
    return binaryBase64(req);
  } else if (req instanceof Object) {
    for (let key of Object.keys(req)) {
      req[key] = formatRequest(req[key]);
    }
  }

  return req;
}

/** Recursively parse all response dates. */
export function parseDates(resp: any): any {
  if (!resp || resp instanceof Blob) return resp;

  if (Array.isArray(resp)) {
    for (let i = 0; i < resp.length; ++i) {
      resp[i] = parseDates(resp[i]);
    }
  } else if (typeof resp == 'string') {
    return ISO_TIMESTAMP_REGEX.test(resp) ? new Date(resp) : resp;
  } else if (resp instanceof Object) {
    for (let key in resp) {
      resp[key] = parseDates(resp[key]);
    }
  }

  return resp;
}

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Format request.
    req = req.clone({ body: formatRequest(deepCopy(req.body)) });

    // Format response.
    return next.handle(req).pipe(map(event => {
      if (event instanceof HttpResponse) {
        parseDates(event.body);
      }

      return event;
    }));
  }
}