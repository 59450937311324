import { AttachmentType } from "../../../../../common/code/standard/common";
import { ErrorResponse } from "../../../../../common/message/error";
import { AttachmentUpload } from "../../../../../common/model/attachment";
import { errorResponse } from "../../../../../common/toolbox/message";
import { mimeType } from "../../../../../common/toolbox/mime";
import { ObjectKeys } from "../../../../../common/toolbox/object";

/** Possible types of responses that can be received. */
export type ResponseFormat = ObjectKeys<Response, () => Promise<any>>;

/** Create a new file object, automatically assigning MIME type. */
export function fileCreate(parts: BlobPart[], filename: string) {
  return new File(parts, filename, { type: mimeType(filename) });
}

/** Convert file to a base64 string. */
export function fileBase64(file: Blob) {
  return new Promise<string | ErrorResponse>(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let url = reader.result as string;
      resolve(url.split(',')[1] ?? '');
    };
    reader.onerror = error => resolve(new ErrorResponse(`${error}`));
  })
}

/** Create an object URL to specified file. */
export function fileObjectURL(file: File, filename?: string): string
export function fileObjectURL(file: string | Object | Blob, filename: string): string
export function fileObjectURL(file: string | Object | Blob | File, filename: any): string {
  if (file instanceof File) {
    file = fileCreate([file], filename ?? file.name);
    return URL.createObjectURL(file as File);
  } else if (file instanceof Blob) {
    return URL.createObjectURL(file);
  } else if (file instanceof Object) {
    return URL.createObjectURL(new Blob([JSON.stringify(file)], { type: 'application/json' }));
  } else {
    return URL.createObjectURL(new Blob([file], { type: mimeType(filename) }));
  }
}

/** Download a blob to specified filename. */
export function fileDownload(file: File, filename?: string): void
export function fileDownload(file: string | Object | Blob, filename: string): void
export function fileDownload(file: string | Object | Blob | File, filename: any): void {
  // Create dummy link to download file.
  filename = filename ?? (file as File).name;
  let url = fileObjectURL(file, filename);

  // Create temporary link to file.
  let a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);

  // Download file and cleanup temporary URL.
  a.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

/** Parse a blob into a JSON object. */
export async function fileBlobJson<T>(blob: Blob): Promise<T | undefined> {
  try {
    let text = await blob.text();
    return JSON.parse(text);
  } catch (e) {
    return undefined;
  }
}

/** Convert a file to an attachment upload. */
export async function fileAttachmentUpload(file: File, type: string = AttachmentType.Other): Promise<AttachmentUpload | ErrorResponse> {
  let data = await fileBase64(file);
  if (errorResponse(data)) return data;

  return { name: file.name, data, type };
}