import { ImportCode } from "../code/system";
import { IndexInfo } from "../info";
import { TypeInfo } from "../info/type";
import { UnionInfo } from "../info/union";
import { ID_DEFAULT, idInteger } from "../toolbox/id";
import { Range } from "../toolbox/number";
import { safeAssign } from "../toolbox/object";
import { EnumValidator } from "../validator/enum";
import { STATE_LIST, State } from "./state";

/** Configuration for all possible import types. */
export type ImportConfig = ImportConfigFile | ImportConfigRandom | ImportConfigNone;

/** Blacklist for values generated so far. */
export class RandomImportBlacklist {
  constructor(
    /** List of already-created account numbers. */
    public account = new Set<string>(),
    /** List of already-created address numbers. */
    public address = new Set<string>(),
    /** List of already-created card numbers. */
    public cards = new Set<string>(),
    /** List of already-created emails. */
    public email = new Set<string>(),
    /** License numbers generated. */
    public license = new Set<string>(),
    /** List of already-created phone numbers. */
    public phone = new Set<string>(),
    /** List of already-created tax IDs. */
    public taxId = new Set<string>(),
    /** Card locators created so far. */
    public cardLocator = new Set<string>(),
    /** Member locators created so far. */
    public memberLocator = new Set<string>()
  ) {}
}

/** Configuration for generating random data. */
export class RandomImportOptions {
  constructor(
    /** Random seed to use. */
    public seed?: number,
    /** List of states to generate random data for. */
    public states?: State[],
    /** Number of accounts to generate. */
    public accounts?: Range,
    /** Number of members to generate. */
    public members?: Range,
    /** Number of claims to generate. */
    public claims?: Range,
    
    /** Range for account transfer amounts. */
    public accountTransfer?: Range,
    /** Number of account transactions per day. */
    public accountTransactions?: Range,
    /** Number of accounts a business owns. */
    public businessAccounts?: Range,
    /** Number of cards a business owns. */
    public businessCards?: Range,
    /** Number of phones a business owns. */
    public businessPhones?: Range,
    /** Number of emails a business owns. */
    public businessEmails?: Range,
    /** Range for credit scores. */
    public creditScore?: Range,
    /** Delinquency periods to generate. */
    public delinquencyPeriods?: number[],
    /** Range for interest payment amounts. */
    public interestPayment?: Range,
    /** Random balances for loan accounts. */
    public loanBalance?: Range,
    /** Number of addresses owned by member. */
    public memberAddresses?: Range,
    /** Low end of monthly income. */
    public monthlyIncome?: Range,
    /** Range for member ages. */
    public personAge?: Range,
    /** Number of accounts a person owns. */
    public personAccounts?: Range,
    /** Number of cards a person owns. */
    public personCards?: Range,
    /** Number of phones a person owns. */
    public personPhones?: Range,
    /** Number of emails a person owns. */
    public personEmails?: Range,
    /** Range for service payment amounts. */
    public servicePayment?: Range,
    /** Random balances for share accounts. */
    public shareBalance?: Range,
    /** Range (in days) to generate transaction data. */
    public transactionRange?: Range,
    /** Max number of transactions per claim */
    public claimTransactions?: Range,
    /** True to generate claims for deceased members. */
    public claimsDeceased?: boolean,
    /** Max number of ledger items per dispute */
    public ledgerRange?: Range
  ) {}

  static typeinfo: TypeInfo<RandomImportOptions> = {
    seed: 0,
    states: [new EnumValidator(State)],
    members: new Range(1000, 4000),
    accounts: new Range(2000, 8000),
    claims: new Range(500, 1000),
    
    accountTransfer: new Range(100000, 999999),
    accountTransactions: new Range(0, 8),
    businessAccounts: new Range(1, 4),
    businessCards: new Range(0, 2),
    businessPhones: new Range(0, 4),
    businessEmails: new Range(0, 4),
    creditScore: new Range(300, 850),
    delinquencyPeriods: [5, 10, 15, 30, 60, 90, 120],
    interestPayment: new Range(100, 500),
    loanBalance: new Range(100000, 1000000),
    memberAddresses: new Range(0, 4),
    monthlyIncome: new Range(375000, 2000000),
    personAge: new Range(18, 80),
    personAccounts: new Range(1, 2),
    personCards: new Range(0, 2),
    personPhones: new Range(0, 2),
    personEmails: new Range(0, 2),
    servicePayment: new Range(10000, 99999),
    shareBalance: new Range(100000, 1000000),
    transactionRange: new Range(-3, 0),
    ledgerRange: new Range(0, 5),
    claimTransactions: new Range(1, 5),
    claimsDeceased: false
  }

  /** Make all properties in config required, falling back on defaults. */
  static required(_inst: string, config?: RandomImportOptions): Required<RandomImportOptions> {
    return safeAssign({
      ...RandomImportOptions.typeinfo as Required<RandomImportOptions>,
      seed: idInteger(_inst),
      states: STATE_LIST
    }, config ?? {})
  }
}

/** List of codes available to import. */
export class RandomImportCodes {
  [ImportCode.AccountMember]: string[] = [];
  [ImportCode.MemberCard]: string[] = [];
}

/** Possible types of imports that can be performed. */
export enum ImportType {
  /** Perform no action at import. */
  None = 'none',
  /** Import directly from a file. */
  File = 'file',
  /** Generate random data to dummy import. */
  Random = 'random'
}

/* Tagged type for import config. */
export interface ImportConfigTag {
  /** Union tag for type. */
  type: ImportType;
  /** Organization this configuration is for. */
  _org: string;
  /** Unique identifier of this configuration. */
  _id: string;
}

/** Configuration for nop data import. */
export class ImportConfigNone implements ImportConfigTag {
  readonly type = ImportType.None;
  _org = ID_DEFAULT;
  _id = ID_DEFAULT;

  static indexinfo: IndexInfo<ImportConfig> = [
    { key: { _org: 1 }, unique: true }
  ]
}

/** Configuration for direct file import. */
export class ImportConfigFile implements ImportConfigTag {
  readonly type = ImportType.File;
  _org = ID_DEFAULT;
  _id = ID_DEFAULT;
}

/** Configuration for random data import. */
export class ImportConfigRandom extends RandomImportOptions implements ImportConfigTag {
  readonly type = ImportType.Random;
  _org = ID_DEFAULT;
  _id = ID_DEFAULT;
}

/** Mapping of import configuration types to classes. */
export class ImportConfigClass {
  [ImportType.None] = new ImportConfigNone();
  [ImportType.File] = new ImportConfigFile();
  [ImportType.Random] = new ImportConfigRandom();
}

/** Type information about account panels. */
export const IMPORT_CONFIG_UNIONINFO: UnionInfo<ImportConfig, ImportType> = {
  tag: 'type',
  classes: new ImportConfigClass()
}