import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PERMISSION_LIST, PERMISSION_NAME, Permission, PermissionEdit } from '../../../../../../common/model/permission';
import { ColumnSize } from "../../../../../../common/model/table";
import { DevService } from '../../service/dev.service';
import { ClientSource } from '../../toolbox/source/client';
import { PermissionsGridData } from './permissions-grid.model';

@Component({
  selector: 'app-permissions-grid',
  templateUrl: './permissions-grid.component.html',
  styleUrls: ['./permissions-grid.component.scss'],
  host: {
    class: 'fill'
  }
})
export class PermissionsGridComponent {
  readonly PERMISSION_NAME = PERMISSION_NAME;
  readonly Permission = Permission;

  /** True if this is a readonly list. */
  @Input() readonly = false;

  /** Current grid editing context. */
  @Input() set data(data: PermissionsGridData) {
    this.source.items = PERMISSION_LIST.map(p => data.main[p] ?? new PermissionEdit(p, false));
    this.fallback = data.fallback;
    this.main = data.main;
  }

  /** Emits when new list of permissions are selected. */
  @Output() selectionChange = new EventEmitter<PermissionEdit[]>();

  /** Upper-level fallback parent permissions. */
  fallback = PermissionEdit.record();
  /** Main list of permissions being edited. */
  main = PermissionEdit.record();

  /** Columns to display in grid. */
  columns = ['permission', 'name'];
  /** Sizes for permission columns. */
  sizes: ColumnSize[] = ['2rem', '1fr'];
  /** Source for displaying permissions. */
  source = new ClientSource<PermissionEdit>();

  constructor(
    public dev: DevService
  ) {}

  /** Copy current list of permissions to code. */
  async onCode() {
    let text: string[] = [];

    for (let item of this.source.items) {
      if (!item.access) continue;
      text.push(`      [Permission.${Permission[item.permission]}]: true,\n`);
    }

    text.sort();
    if (text.length) text[text.length - 1] = text[text.length - 1]!.slice(0, -2);
    await navigator.clipboard.writeText(text.join(''));
  }
}
