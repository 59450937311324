import { HasIdName } from "./id";
import { KeyJoin } from "./keys";
import { objectSort, objectValues } from "./object";

/** Handled to saved preview tree state. */
export type PreviewTreeState = Set<string>;

/** A node of a nested preview tree. */
export interface PreviewNode<T extends HasIdName = HasIdName> {
  /** Display name for node. */
  name: string;
  /** Value of node if this is a leaf, or leaf with children. */
  value?: T;
  /** Original index of node, for deleting. */
  index?: number;
  /** True if node is currently expanded. */
  expanded?: boolean;
  /** List of child nodes, if applicable. */
  children?: Record<string, PreviewNode<T>>;
};

/** Convert flat list of previews into a tree. */
export function previewTree<T extends HasIdName>(previews: T[], sort = true) {
  let node: PreviewNode<T> = { name: '', children: {} };

  for (let i = 0; i < previews.length; ++i) {
    let preview = previews[i]!;
    let subnode = previewTreeDeep(node.children!, preview, preview.name.split(KeyJoin.Separator), 0);
    subnode.index = i;
  }

  if (sort) previewTreeSort(node);
  return objectValues(node.children!);
}

/** Insert a preview into its place in a tree. */
function previewTreeDeep<T extends HasIdName>(parent: Record<string, PreviewNode<T>>, value: T, path: string[], i: number): PreviewNode<T> {
  let name = path[i]!;
  if (i === path.length - 1) return parent[name] = { name, value };

  let child = parent[name];
  if (child) {
    // Attach preview to existing parent.
    child.children = child.children ?? {};
    return previewTreeDeep(child.children, value, path, i + 1);
  } else {
    // Create new node and attach.
    parent[name] = { name, children: {} };
    return previewTreeDeep(parent[name]!.children!, value, path, i + 1);
  }
}

/** Save state of a preview tree. */
export function previewTreeSave(tree: PreviewNode[]): PreviewTreeState {
  let state: PreviewTreeState = new Set();

  let path: string[] = [];
  for (let node of tree) {
    previewTreeSaveDeep(node, path, state);
  }

  return state;
}

/** Recursively save state of preview tree. */
function previewTreeSaveDeep(node: PreviewNode, path: string[], state: PreviewTreeState) {
  path.push(node.name);

  if (node.expanded) {
    state.add(path.join(KeyJoin.Separator));
  }

  if (node.children) for (let subnode of objectValues(node.children)) {
    previewTreeSaveDeep(subnode, path, state);
  }
  
  path.pop();
}

/** Apply saved state to a preview tree. */
export function previewTreeLoad(tree: PreviewNode[], state: PreviewTreeState) {

  let path: string[] = [];
  for (let node of tree) {
    previewTreeLoadDeep(node, path, state);
  }
}

/** Recursively reload state of preview tree. */
function previewTreeLoadDeep(node: PreviewNode, path: string[], state: PreviewTreeState) {
  path.push(node.name);

  if (state.has(path.join(KeyJoin.Separator))) {
    node.expanded = true;
  }

  if (node.children) for (let subnode of objectValues(node.children)) {
    previewTreeLoadDeep(subnode, path, state);
  }
  
  path.pop();
}

/** Recursively sort subnodes of a tree. */
function previewTreeSort<T extends HasIdName>(node: PreviewNode<T>) {
  if (!node.children) return;

  node.children = objectSort(node.children, (a, b) => {
    if ( a.children && !b.children) return -1;
    if (!a.children &&  b.children) return 1;
    return a.name.localeCompare(b.name);
  });

  for (let subnode of objectValues(node.children)) previewTreeSort(subnode);
}