import { ClaimJoin } from "../../../../../../common/model/claim/claim";
import { Condition } from "../../../../../../common/model/formula/condition";
import { ColorCode } from "../../../../../../common/toolbox/color";
import { PercentItem } from "../../../common/component/percent/percent.model";

export class ExecutiveReportOptions {
  constructor(
    /** True to also show percentage bars. */
    public percentages?: boolean,
    /** True to also show totals. */
    public total?: boolean
  ) {}
}

/** Preview of an executive report with stats. */
export class ExecutiveReport {
  constructor(
    /** Title of report. */
    public title = '',
    /** Color for report. */
    public color = ColorCode.Primary,
    /** List of headers for report. */
    public headers: PercentItem[] = [],
    /** List of metrics for report. */
    public metrics: PercentItem[] = [],
    /** Additional options for showing report. */
    public options = new ExecutiveReportOptions(),
    /** Conditions used to generate report. */
    public conditions: Condition<ClaimJoin>[] = []
  ) {}
}