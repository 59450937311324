import { Attachment, AttachmentLike, AttachmentUpload } from "../../../../../common/model/attachment";
import { base64ObjectURL } from "../../../../../common/toolbox/base64";
import { binaryBase64 } from "../../../../../common/toolbox/binary";
import { mimeType } from "../../../../../common/toolbox/mime";

/** Normalized download information for a file. */
export class AttachmentInfo {
  
  constructor(
    /** Name of file. */
    public name = '',
    /** MIME type of file. */
    public type = '',
    /** Path to file, base64 or URL. */
    public data = ''
  ) {}

  /** Get information about an attachment. */
  static async from(attachment: AttachmentLike): Promise<AttachmentInfo | undefined> {
    if (Attachment.check(attachment)) {
      return {
        data: Attachment.fullpath(API_URL, attachment._inst, attachment._id),
        type: mimeType(attachment.name),
        name: attachment.name
      };
    } else if (AttachmentUpload.check(attachment)) {
      return {
        data: base64ObjectURL(attachment.name, await binaryBase64(attachment.data)),
        type: mimeType(attachment.name),
        name: attachment.name
      }
    } return undefined;
  }
}