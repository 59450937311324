import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ClaimType } from '../../../../../../common/code/standard/disputes';
import { ErrorResponse } from "../../../../../../common/message/error";
import { Attachment } from '../../../../../../common/model/attachment';
import { ClaimJoin, ClaimJoinACH, ClaimDisputesJoin } from '../../../../../../common/model/claim/claim';
import { DisplayPartial, DisplayType, DisplayValue } from '../../../../../../common/model/display';
import { Dispute, DisputeUnion } from '../../../../../../common/model/dispute/dispute';
import { DisputeEngine } from '../../../../../../common/model/dispute/engine';
import { DisputeTotal } from '../../../../../../common/model/dispute/total';
import { DocumentTemplate } from '../../../../../../common/model/document-template/base';
import { Formula } from "../../../../../../common/model/formula/formula";
import { PropertyType } from "../../../../../../common/model/property-type";
import { Transaction } from "../../../../../../common/model/transaction";
import { WorkContext } from '../../../../../../common/model/work/context';
import { Workflow } from '../../../../../../common/model/work/flow';
import { claimIntakeTemplate, claimUnion } from '../../../../../../common/toolbox/claim';
import { formulaRun } from '../../../../../../common/toolbox/formula/formula';
import { idNull } from '../../../../../../common/toolbox/id';
import { errorPartition, errorResponse } from "../../../../../../common/toolbox/message";
import { deepCopy } from '../../../../../../common/toolbox/object';
import { FormConfig } from '../../../common/component/form/form.model';
import { FORMULA_TRUE } from '../../../common/component/form/list/form-list.component';
import { GridConfig } from '../../../common/component/model/grid/model-grid.model';
import { AuthService } from '../../../common/service/auth.service';
import { ClaimService } from '../../../common/service/claim.service';
import { FormService } from '../../../common/service/form.service';
import { FormulaService } from '../../../common/service/formula.service';
import { LogService } from '../../../common/service/log.service';
import { SettingGroupService } from '../../../common/service/setting-group.service';
import { TableService } from '../../../common/service/table.service';
import { WorkflowService } from '../../../common/service/workflow.service';
import { DISPLAY_TYPE_ICON } from '../../../common/toolbox/display';
import { ClientSource } from '../../../common/toolbox/source/client';
import { TaskOpen } from '../../../common/toolbox/task';
import { TaskMode } from '../../task/task.model';

/** UI state for a single dispute. */
class ClaimDisputeState {
  constructor(
    /** Header form configuration. */
    public header: FormConfig,
    /** Body form configuration. */
    public body: FormConfig,
    /** Context for workflow. */
    public context: WorkContext,
    /** Configuration for ledger table. */
    public ledger: GridConfig,
    /** True to show dispute form, false to show ledger table. */
    public form = true,
    /** Whether the dispute meets the configured threshold. */
    public meetsThreshold = false
  ) { }
}

@Component({
  selector: 'app-claim-disputes',
  templateUrl: './claim-disputes.component.html',
  styleUrls: ['./claim-disputes.component.scss']
})
export class ClaimDisputesComponent {
  readonly DISPLAY_TYPE_ICON = DISPLAY_TYPE_ICON;
  readonly PropertyType = PropertyType;
  readonly DisplayType = DisplayType;
  readonly TaskMode = TaskMode;

  /** Set new claim to display. */
  @Input() set claim(claim: ClaimDisputesJoin) {
    this._claim = claim;
    this._originalClaim = deepCopy(claim);
    this.refresh();
  }

  /** List of existing attachments to display. */
  @Input() set attachments(attachments: Attachment[]) {
    this._attachments = attachments;
    this.refresh();
  }

  /** Emits when changes are made to claim. */
  @Output() changed = new EventEmitter<Dispute>();
  /** Emits if the claim is detected to be out of compliance. */
  @Output() error = new EventEmitter<string>();
  /** Emits when a task should be opened. */
  @Output() task = new EventEmitter<[TaskOpen, Dispute]>();

  /** Configuration for header row displaying labels. */
  headerConfig?: FormConfig;
  /** UI state for each dispute. */
  states: ClaimDisputeState[] = [];
  /** Formula for calculating provision credit threshold. */
  thresholdFormula = new Formula();
  /** Claim intake template to download. */
  intakeTemplate?: DocumentTemplate;

  /** List of attachments attached to claim. */
  _attachments: Attachment[] = [];
  /** Last bound claim to component. */
  _claim: ClaimDisputesJoin = new ClaimJoinACH();
  /** Original claim, used for dirty checking */
  _originalClaim: ClaimDisputesJoin = new ClaimJoinACH();


  /** Emits when created panels should be destroyed. */
  discard = new Subject<void>();

  /** Current loaded workflow. */
  protected workflow?: Workflow;
  /** Total for disputes. */
  total = new DisputeTotal();

  constructor(
    private log: LogService,
    private auth: AuthService,
    private claims: ClaimService,
    private forms: FormService,
    private formulas: FormulaService,
    private tables: TableService,
    private workflows: WorkflowService,
    private settingGroups: SettingGroupService,
  ) { }

  /** Called when changes happen within main form. */
  onChange(dispute: Dispute) {
    this.changed.next(dispute);
    this.retotal();
    this.recontext();
  }

  /** Callback when a task should be opened. */
  onTask(open: TaskOpen, state: ClaimDisputeState): void {
    let dispute = state.body.value?.dispute;
    if (dispute) this.task.emit([open, dispute]);
  }

  /** Update displayed totals. */
  private retotal() {
    this.total = new DisputeEngine().runAll(this._claim.disputes, this._claim.account);
    if (this.total.loss < 0) this.error.emit('The amount recovered exceeds the amount of credit issued.');
  }

  /** Refresh disputes after list of transactions change. */
  private async refresh() {
    // Wait for disputes to be bound.
    if (!this._claim.disputes.length) return;

    // Fetch form for previewing disputes.
    let settings = await this.settingGroups.inst();
    if (idNull(settings.disputes[this._claim.type].dispute.previewForm)) return this.log.show('Dispute preview form is not configured for current institution.');

    // Get intake document template.
    let template = claimIntakeTemplate(this._claim);
    if (template) {
      this.claims.intakeTemplate(this._claim).then(template => {
        if (errorResponse(template)) return this.log.show(template);
        this.intakeTemplate = template;
      });
    }

    // Fetch dispute-level workflow.
    if (!idNull(settings.disputes[this._claim.type].dispute.workflow)) {
      this.workflows.item({
        _inst: this.auth._inst,
        _id: settings.disputes[this._claim.type].dispute.workflow
      }).then(workflow => this.workflow = workflow);
    }

    // Fetch threshold formula.
    if (this._claim.type === ClaimType.Card) {
      this.formulas.item({
        _inst: this.auth._inst,
        _id: settings.disputes.card.dispute.thresholdFormula
      }).then(formula => {
        if (errorResponse(formula)) this.log.show(new ErrorResponse(`Error getting dispute amount threshold formula.`));
        else this.thresholdFormula = formula;
      });
    } else {
      this.thresholdFormula = FORMULA_TRUE;
    }

    // Fetch default form for disputes and table for ledgers.
    let [headerForm, bodyForm, ledgerTable] = await Promise.all([
      this.forms.setting(`disputes.${this._claim.type}.dispute.previewForm`),
      this.forms.setting(`disputes.${this._claim.type}.dispute.form`),
      this.tables.setting('general.ledger.table')
    ]);

    // Build display values from disputes.
    let results = this._claim.disputes.map(dispute => {
      let result = new DisplayValue();
      result.claim = claimUnion(this._claim);
      result.dispute = dispute as DisputeUnion;
      result.transaction = dispute.transaction ?? new Transaction();
      return result;
    });
    let [error, values] = errorPartition('There was an error loading disputes.', results);
    if (error) this.log.show(error);

    // Update form configurations.
    this.headerConfig = { form: headerForm, inputs: false };
    this.states = values.map((value, i) => {
      let dispute = value.dispute;
      let meetsThreshold = formulaRun(this.thresholdFormula, { dispute, transaction: dispute.transaction });

      // Configure inputs for ledger table.
      let source = new ClientSource();
      source.limit = 10;
      source.items = (value.dispute.ledger ?? []).map(ledger => ({ ...value, ledger }) as DisplayPartial);

      let state: ClaimDisputeState = {
        header: { form: headerForm, value, mutate: true, labels: false },
        body: { form: bodyForm, value, mutate: true },
        ledger: { table: ledgerTable, source },
        context: {
          claim: value.claim as ClaimJoin,
          dispute: value.dispute,
          attachments: this._attachments
        },
        form: this.states[i]?.form ?? true,
        meetsThreshold
      };
      return state;
    });
    this.retotal();
  }

  /** Refresh context of workflow so that engine is re-run. */
  recontext() {
    for (let state of this.states) {
      state.context = {
        ...state.context
      };
    }
  }
}
