import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT, MaybeId } from "../toolbox/id";
import { Pair, objectEntries } from "../toolbox/object";
import { PatternValidator } from "../validator/pattern";
import { RecordValidator } from "../validator/record";
import { FusionCollection } from "./fusion";

/** A mapping of code type categories to codes. */
export type CodeMap = Record<string, Code[]>;
/** A map of code type categories to mapped code values. */
export type CodeEnum = Map<string, Map<string, string>>;

/** Valid patterns for code type categories. */
export const CODE_CATEGORY_REGEX = /^[a-zA-Z0-9_]+$/;

/** A generic code type that can be fetched from the database. */
export class Code {
  constructor(
    /** Institution-provided code for type. */
    public key = '',
    /** Display name of code type. */
    public value = '',
    /** True if code is read-only. */
    public system = false
  ) { }
}

/** A mapping of code types for an institution. */
export class CodeType {
  constructor(
    /** Unique identifier of code type. */
    public _id = ID_DEFAULT,
    /** Institution that defined code types. */
    public _inst = ID_DEFAULT,
    /** Institution identifier for this code type. */
    public category = '',
    /** Display name for code type. */
    public name = '',
    /** True if this is a system code type that cannot be edited. */
    public system?: boolean,
    /** True if code type has been edited */
    public dirty?: boolean,
    /** True if this code type is locked, new codes cannot be added. */
    public locked?: boolean,
    /** Record of codes for this institution. */
    public codes: Record<string, string> = {},
    /** List of codes that must exist. */
    public required?: string[]
  ) {}

  static collectioninfo: CollectionInfo<FusionCollection, CodeType> = {
    _inst: 'institutions'
  }

  static typeinfo: TypeInfo<CodeType> = {
    system: false,
    dirty: false,
    locked: false,
    category: new PatternValidator(CODE_CATEGORY_REGEX),
    codes: new RecordValidator(''),
    required: ['']
  };

  static indexinfo: IndexInfo<CodeType> = [
    { key: { _inst: 1, category: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  /** Convert codes of a code type to a list of codes. */
  static list(type: MaybeId<CodeType>): Code[] {
    let system = new Set(type.required);
    return objectEntries(type.codes).map(([key, value]) => new Code(key, value, system.has(key)));
  }

  /** Convert code types to a map. */
  static map(types: CodeType[]): CodeMap {
    return types.reduce<CodeMap>((map, code) => { map[code.category] = this.list(code); return map; }, {});
  }

  /** Apply list of codes to a map. */
  static apply(type: MaybeId<CodeType>, list: Code[]) {
    let codes: Record<string, string> = {};
    for (let code of list) {
      codes[code.key] = code.value;
    }

    type.codes = codes;
    return type;
  }

  /** Convert list of codes to list of pairs. */
  static pairs(codes: Code[], sort?: boolean, prefix?: [string, string], suffix?: [string, string]): Pair[] {
    codes = sort ? [...codes].sort((a, b) => a.key.localeCompare(b.key)) : codes;
    return codes.map(({ key, value }) => {
      let out: string[] = [];
      if (prefix) out.push(prefix[0], key, prefix[1]);
      out.push(value);
      if (suffix) out.push(suffix[0], key, suffix[1]);
      return new Pair(key, out.join(''));
    });
  }

  /** Create a nested map of code types for formula execution. */
  static enum(types: CodeType[]): CodeEnum {
    let map = new Map<string, Map<string, string>>();

    for (let type of types) {
      let submap = new Map<string, string>();
      for (let [key, value] of objectEntries(type.codes)) {
        submap.set(key, value);
      }
      map.set(type.category, submap);
    }

    return map;
  }

  /** Revert any illegal changes made to a system code type. */
  static revert(edited: MaybeId<CodeType>, system: MaybeId<CodeType>) {
    // Revert flags.
    edited.system = system.system;
    edited.required = system.required;
    edited.locked = system.locked;
    let required = new Set(system.required);

    // Restore any required code types.
    for (let [key, value] of objectEntries(system.codes)) {
      if (!required.has(key)) {
        if (system.locked) delete edited.codes[key];
        continue;
      }
      edited.codes[key] = edited.codes[key] ?? value;
    }
  }
}

/** Preview for a code type. */
export class CodePreview {
  constructor(
    /** Unique identifier of code type. */
    public _id = ID_DEFAULT,
    /** Institution identifier for this code type. */
    public category = '',
    /** Display name for code type. */
    public name = '',
    /** True if this is a system code type that cannot be edited. */
    public system?: boolean
  ) { }

  static typeinfo: TypeInfo<CodePreview> = {
    system: false
  }
}