import { CollectionExport } from "../../info/collection";
import { propinfoProperty } from "../../info/prop";
import { BLOCK_TYPE, Block, BlockType, BlockUnion } from "../../model/formula/block";
import { Placeholder, PlaceholderType } from "../../model/formula/placeholder";
import { TerminalType } from "../../model/formula/terminal";
import { PropertyType } from "../../model/property-type";
import { enumHas } from "../enum";
import { NestedKey } from "../keys";
import { objectDeleteFilter } from "../object";

/** Check if block union is a particular block type. */
export function blockType(union: BlockUnion, type: BlockType) {
  return BLOCK_TYPE[union].includes(type);
}

/** Check if given block is a placeholder. */
export function blockPlaceholder(block: Block): block is Placeholder {
  return enumHas(PlaceholderType, block.type);
}

/** Recursively walk a block from top to bottom. */
export function blockWalk(block: Block | Block[], visit: (block: Block) => void) {
  if (!(block instanceof Object)) return;

  if (Array.isArray(block)) {
    for (let item of block) blockWalk(item, visit);
    return;
  } else {
    if (typeof block.type === 'string') visit(block);
    for (let key in block) blockWalk((block as any)[key], visit);
  }
}

/** List all identifiers used in a block. */
export function blockIdentifiers(block: Block | Block[]) {
  let keys = new Set<string>();

  blockWalk(block, block => {
    switch (block.type) {
    case TerminalType.Identifier:
      keys.add(block.key);
      return;
    }
  });

  return [...keys];
}

/** List all codes used in a block. */
export function blockCodes<T>(block: Block | Block[], input: T) {
  let codes = new Set<string>();

  blockWalk(block, block => {
    switch (block.type) {
    case TerminalType.Code:
      codes.add(block.category);
      return;
    case TerminalType.Identifier:
      let property = propinfoProperty(block.key as NestedKey<T>, input);
      if (property?.type === PropertyType.Code) codes.add(property.category);
      return;
    }
  });

  return [...codes];
}

/** Recursively export all Ids of a block. */
export function blockIds(block: Block | Block[]): Block | Block[] {
  blockWalk(block, block => {
    switch (block.type) {
    case TerminalType.Resource:
      block.value = new CollectionExport([[block.collection, block.value]]) as any;
      return;
    }
  });
  return block;
}

/** Strip any placeholders out of a block. */
export function blockStrip(block: Block | Block[]) {
  return blockStripDeep(block);
}

/** Recursively strip any placeholders out of a block. */
function blockStripDeep(block: Block | Block[]) {
  if (Array.isArray(block)) {
    block.filter(value => !blockPlaceholder(value));
    for (let child of block) blockStripDeep(child);
  } else if (block instanceof Object) {
    objectDeleteFilter(block, (_, value) => !blockPlaceholder(value));
    for (let child of Object.values(block)) blockStripDeep(child);
  }

  return block;
}