import { Icon, IconMap } from "../../../../../../common/model/icon";
import { WorkFilter } from "../../../../../../common/model/work/filters";
import { WorkItem, WorkStage } from "../../../../../../common/model/work/item";
import { WorkQueue } from "../../../../../../common/model/work/queue";
import { EnumMap } from "../../../../../../common/toolbox/enum";
import { Pair } from "../../../../../../common/toolbox/object";

/** Data passed to work bar. */
export class WorkBarData {
  constructor(
    /** Information about queue being worked. */
    public queue = new WorkQueue(),
    /** Initial cursor for queue, if applicable. */
    public cursor?: WorkItem
  ) {}
}

/** Icon for each work stage. */
export const WORK_STAGE_ICON: IconMap<WorkStage> = {
  [WorkStage.Unviewed]: Icon.VisibilityOff,
  [WorkStage.Viewed]: Icon.Visibility,
  [WorkStage.Worked]: Icon.TaskAlt
}

/** Name for each work stage. */
export const WORK_STAGE_MAP: EnumMap<WorkStage> = {
  [WorkStage.Unviewed]: 'Unviewed',
  [WorkStage.Viewed]: 'Viewed',
  [WorkStage.Worked]: 'Worked'
}

/** List of standard work filters. */
export const WORK_FILTERS: Pair<WorkFilter>[] = [
  new Pair(WorkFilter.All, 'All'),
  new Pair(WorkFilter.Bookmarks, 'Bookmarks'),
  new Pair(WorkFilter.Unviewed, 'Unviewed'),
  new Pair(WorkFilter.Viewed, 'Viewed'),
  new Pair(WorkFilter.Unworked, 'Unworked'),
  new Pair(WorkFilter.Worked, 'Worked')
];