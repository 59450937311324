<app-card-body scroll [formGroup]="builder">
  <header>
    <ng-container *ngIf="expanded">
      <button icon-button [toggle-button]="selected && selected === filtered.length" [toggle-button-indeterminate]="selected && selected < filtered.length" (click)="onSelectAll(); $event.stopPropagation()"></button>
      <app-field class="fill">
        <button icon-button type="submit" disabled>search</button>
        <input placeholder="Filter" formControlName="filter">
      </app-field>
      <button icon-button [tooltip]="list ? 'View Tree' : 'View List'" (click)="onList()">{{list === true ? 'sort' : list === false ? 'account_tree' : ''}}</button>
    </ng-container>
    <button icon-button (click)="onExpand()">{{expanded ? 'first_page' : 'last_page'}}</button>
  </header>
  <div *ngIf="!expanded" class="fill"></div>
  <ng-container *ngIf="expanded">
    <div *ngIf="list === undefined" class="list"></div>
    <div *ngIf="list === true" class="list">
      <div class="preview" *ngFor="let item of filtered; let i = index" [class.hover]="item.preview._id === preview?._id" (click)="previewChange.next(preview = item.preview)">
        <button icon-button [toggle-button]="item.selected" (click)="onSelect(item); $event.stopPropagation()"></button>
        <div class="name" [tooltip]="item.preview.name">{{item.preview.name}}</div>
        <app-icon *ngIf="item.preview.dirty">history_edu</app-icon>
        <button *ngIf="!system || (Permission.System | permission)" icon-button type="button" tooltip="Delete {{item.preview.name}}" [class.hidden]="!(Permission.System | permission) && system ?? item.preview.system" (click)="onDelete(i); $event.stopPropagation()">delete</button>
      </div>
    </div>
    <cdk-tree *ngIf="list === false" class="list" [dataSource]="source" [treeControl]="control">
      <cdk-nested-tree-node *cdkTreeNodeDef="let node">
        <div class="preview" (click)="previewChange.next(preview = node.value)">
          <app-icon *ngIf="node.children" cdkTreeNodeToggle [cdkTreeNodeToggleRecursive]="true" (click)="node.expanded = !node.expanded; $event.stopPropagation()">{{node.expanded ? 'expand_more' : 'chevron_right'}}</app-icon>
          <app-icon *ngIf="node.value">description</app-icon>
          <div class="name" [tooltip]="node.name">{{node.name}}</div>
          <ng-container *ngIf="node.value">
            <app-icon *ngIf="node.value.dirty">history_edu</app-icon>
            <button *ngIf="!system || (Permission.System | permission)" icon-button type="button" tooltip="Delete {{node.value.name}}" [class.hidden]="!(Permission.System | permission) && system ?? node.value.system" (click)="onDelete(node.index); $event.stopPropagation()">delete</button>
          </ng-container>
        </div>
        <ng-container *ngIf="node.children && node.expanded">
          <ng-container cdkTreeNodeOutlet></ng-container>
        </ng-container>
      </cdk-nested-tree-node>
    </cdk-tree>
  </ng-container>
  <div [class.button-row]="expanded" *ngIf="clipboard">
    <button *ngIf="deletable" icon-button tooltip="Delete selected items" (click)="onDeleteSelected()">delete</button>
    <div class="download" *ngIf="selected">
      <button icon-button tooltip="Copy to clipboard" (click)="onDownload()">content_paste</button>
      <app-badge overlay top-right color="primary" *ngIf="selected">{{selected}}</app-badge>
    </div>
    <button icon-button tooltip="Upload from clipboard" (click)="onUpload()">upload</button>
    <button icon-button tooltip="Compare to clipboard" (click)="onDiff()">difference</button>
  </div>
</app-card-body>