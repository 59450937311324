import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WORK_CONDITION_NAME, WorkCondition, WorkConditionBase, WorkConditionFormula, WorkConditionType } from '../../../../../../../../common/model/work/condition';
import { enumValues } from '../../../../../../../../common/toolbox/enum';

@Component({
  selector: 'app-setup-workflow-condition-base',
  templateUrl: './setup-workflow-condition-base.component.html',
  styleUrls: ['./setup-workflow-condition-base.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupWorkflowConditionBaseComponent {
  readonly WORK_CONDITION_NAME = WORK_CONDITION_NAME;
  readonly WORK_CONDITION_TYPES = enumValues<WorkConditionType>(WorkConditionType);

  /** Condition to edit. */
  @Input() condition: WorkCondition = new WorkConditionFormula();
  /** Emits when condition changes. */
  @Output() conditionChange = new EventEmitter<WorkCondition>();

  /** Callback when changing type of a work condition. */
  onType(condition: WorkCondition, type: WorkConditionType) {
    WorkConditionBase.retype(condition, type);
    this.conditionChange.next(condition);
  }
}
