import { GlobalPositionStrategy, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalOutlet } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { arraySome } from "../../../../../../common/toolbox/array";
import { safeAssign } from "../../../../../../common/toolbox/object";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { filter } from 'rxjs';
import { Log, LogMessage } from '../../toolbox/log';
import { FadeState } from '../../toolbox/fade';
import { SnackbarComponent } from './snackbar.component';
import { SnackbarConfig } from './snackbar.model';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  /** Snackbar currently being displayed. */
  private snackbar?: SnackbarComponent;
  /** Queue of additional snackbars to open. */
  private queue: SnackbarConfig[] = [];

  constructor(
    private overlay: Overlay
  ) {}

  /** Open a new dialog with specified component and data. */
  open(config: SnackbarConfig) {
    if (this.snackbar) {
      // Complete existing snackbars first.
      this.queue.push(config);
      return;
    }

    let overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['snackbar-panel'],
      backdropClass: 'snackbar-backdrop',
      positionStrategy: new GlobalPositionStrategy().centerHorizontally().bottom('bottom')
    });

    // Append snackbar to portal.
    let outlet: PortalOutlet = this.overlay.create(overlayConfig);
    let componentRef: ComponentRef<SnackbarComponent> = outlet.attach(new ComponentPortal(SnackbarComponent, null));
    let snackbar = this.snackbar = componentRef.instance;
    safeAssign(componentRef.instance, config);

    // Close snackbar (and open next) after fadeout.
    snackbar.fade.pipe(filter(state => state === FadeState.Done)).subscribe(() => {
      outlet.dispose();
      this.snackbar = undefined;
      if (arraySome(this.queue)) this.open(this.queue.shift()!);
    });
  }

  /** Open a snackbar for specified log. */
  show(message: LogMessage, status?: StatusLevel) {
    return this.open(Log.from(message, status));
  }
}
