
/** Set of visited nodes in workflow engine. */
export class WorkPath {
  /** List of visited nodes. */
  private visited = new Set<string>();

  /** Add new node to visited nodes. */
  add(step: number, action?: number) {
    this.visited.add(this.key(step, action));
  }

  /** Check if given node has been seen. */
  has(step: number, action?: number) {
    return this.visited.has(this.key(step, action));
  }

  /** Clear list of visited nodes. */
  clear() {
    this.visited.clear();
  }

  /** Get the key of a particular node. */
  private key(step: number, action?: number) {
    return action === undefined ? `${step}` : `${step}_${action}`;
  }
}