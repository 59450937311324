
/** Pattern used to strip out non-alphanumeric characters for searches. */
export const SEARCH_REGEX = /[^a-z0-9 ]/g;

/** Convert string to a search query. */
export function searchQuery(...queries: string[]) {
  return queries.join(' ').toLocaleLowerCase().replace(/[^a-z0-9. ]/g, '');
}

/** Convert string to a search query, using a specific regex. */
export function searchQueryPattern(pattern: RegExp, ...queries: string[]) {
  return queries.join(' ').toLocaleLowerCase().replace(pattern, '');
}