import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { BlockType } from "../../../../../../../common/model/formula/block";
import { Pos } from "../../../../../../../common/model/pos";
import { arrayDefined } from "../../../../../../../common/toolbox/array";
import { enumValues } from "../../../../../../../common/toolbox/enum";
import { boxOffset } from '../../../../common/toolbox/box';
import { BLOCK_GROUPS, BlockChip, BlockDrag } from '../block.model';

@Component({
  selector: 'app-setup-formula-palette',
  templateUrl: './setup-formula-palette.component.html',
  styleUrls: ['./setup-formula-palette.component.scss'],
  host: {
    class: 'flex'
  }
})
export class SetupFormulaPaletteComponent {
  /** Subcontainer element to send inputs to. */
  @ViewChild('container') container!: ElementRef<HTMLDivElement>; 

  /** Displayed list of blocks. */
  @Input() types: BlockType[] = enumValues(BlockType);
  /** Emits when a chip is dragged. */
  @Output() block = new EventEmitter<BlockDrag>();

  /** List of blocks that can be added to form. */
  groups = BLOCK_GROUPS;
  /** True if currently hovering inside palette area. */
  inside = false;
  /** Data for main form. */
  builder = new FormBuilder().nonNullable.group({
    filter: ['', [Validators.required, Validators.email]]
  });
  
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  ngOnInit() {
    this.regroup('');
    this.builder.controls.filter.valueChanges.pipe(takeUntil(this.destroy)).subscribe(filter => this.regroup(filter));
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when chip drag ends.  */
  onDrag($event: CdkDragEnd, chip?: BlockChip) {
    this.block.emit({ ...$event, block: chip?.block  });
    $event.source.reset();
  }

  /** Callback when moving mouse inside palette. */
  onMove($event: MouseEvent) {
    this.inside = Pos.inside({ x: $event.clientX, y: $event.clientY }, boxOffset(this.container.nativeElement))
  }

  /** Update list of groups. */
  private regroup(filter: string) {
    this.groups = arrayDefined(BLOCK_GROUPS.filter(group => this.types.includes(group.type)).map(group => {
      let chips = group.chips.filter(chip => chip.name.includes(filter));
      return chips.length ? { ...group, chips } : undefined;
    }));
  }
}
