import { Pipe } from '@angular/core';
import { mimeCategory } from "../../../../../common/toolbox/mime";

@Pipe({
  name: 'mimeCategory'
})
export class MimeCategoryPipe {
  transform(filename: string) {
    return mimeCategory(filename);
  }
}
