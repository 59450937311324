import { typeinfoOf, typeinfoValue } from "../info/type";
import { unioninfoResolve } from "../info/union";
import { Validator } from "../validator/base";
import { arraySome } from "./array";
import { objectType, objectKeys } from "./object";
import { ISO_TIMESTAMP_REGEX } from "./time";

/** Rehydrate an object with type information. */
export function hydrateObject<T>(stripped: T, typed: T): T {
  return hydrateObjectDeep(stripped, typed);
}

/** Deeply rehydrate an object with type information. */
function hydrateObjectDeep(stripped: any, typed: any) {
  switch (objectType(typed)) {
  case 'array':
    if (!Array.isArray(stripped)) return stripped;
    if (!arraySome(typed)) return stripped;
    for (let i = 0; i < stripped.length; ++i) {
      stripped[i] = hydrateObjectDeep(stripped[i], typed[0]);
    } break;
  case 'date':
    return ISO_TIMESTAMP_REGEX.test(stripped) ? new Date(stripped) : stripped;
  case 'object':
    if (!(stripped instanceof Object)) return stripped;
    typed = unioninfoResolve(stripped, typed);
    let typeinfo = typeinfoOf(typed);
    if (typed instanceof Validator) typed = typed.value();
    
    Object.setPrototypeOf(stripped, Object.getPrototypeOf(typed))
    for (let key of objectKeys(typed)) {
      stripped[key] = hydrateObjectDeep(stripped[key], typeinfoValue(typed, key, typeinfo));
    } break;
  }

  return stripped;
}
