import { Component, Input } from '@angular/core';
import { CollectionComments } from '../../../../../../../common/model/collection';
import { CommentList } from '../../../../../../../common/model/comment/comment';
import { FusionCollection } from '../../../../../../../common/model/fusion';
import { DialogService } from '../../dialog/dialog.service';
import { CommentListDialogData } from '../dialog/comment-list-dialog.model';
import { CommentListDialogComponent } from '../dialog/comment-list-dialog.component';

@Component({
  selector: 'app-comment-list-button',
  templateUrl: './comment-list-button.component.html',
  styleUrls: ['./comment-list-button.component.scss']
})
export class CommentListButtonComponent {

  /** Title to display for dialog. */
  @Input() header?: string;
  /** Route to contact to make changes. */
  @Input() collection?: CollectionComments<FusionCollection>;
  /** Value to display comments for. */
  @Input() value?: CommentList;
  /** True to disable button. */
  @Input() disabled = false;

  constructor(
    private dialog: DialogService
  ) { }

  /** Callback when opening comments dialog. */
  protected onComments() {
    if (!this.value) return;
    this.dialog.open(CommentListDialogComponent, new CommentListDialogData(this.value, this.collection), { title: this.header });
  }
}
