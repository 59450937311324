import { TypeInfo } from "../../info/type"
import { EnumValidator } from "../../validator/enum"
import { UnionValidator } from "../../validator/union"
import { EventResultClass } from "./result"
import { AccountResultClass } from "./result/account"
import { AuthResultClass } from "./result/auth"
import { ClaimResultClass } from "./result/claim"
import { DocumentTemplateResultClass } from "./result/document-template"
import { ImportResultClass } from "./result/import"
import { PersonalResultClass } from "./result/personal"
import { TriggerResultClass } from "./result/trigger"

/** Mapping of event categories to event result types. */
export type EventCategoryResultType = { [C in EventCategory]: EventResultClass[C] extends UnionValidator<any, infer K> ? K : never };

/** Categories for events. */
export enum EventCategory {
  /** General system events, powering on or off, database activity. */
  System,
  /** Authentication, logging in and out of users. */
  Authentication,
  /** Admin modifications to users or profiles. */
  User,
  /** Modifying jobs and job execution. */
  Job,
  /** Organization configuration, changing core configuration. */
  Organization,
  /** Institution configuration, editing or deleting institutions. */
  Institution,
  /** Code type modifications, creating or deleting values. */
  CodeType,
  /** Import status, syncing accounts and members from cores. */
  Import,
  /** Account activity, actions performed by users to work accounts. */
  Account,
  /** Modification of work queues */
  WorkQueue,
  /** Personal reminders and notifications. */
  Personal,
  /** Claim activity, actions performed by users to work claims. */
  Claim,
  /** Modifications to document templates. */
  DocumentTemplate,
  /** Trigger related events. */
  Trigger
}

/** Mapping of event categories to mappings of event results. */
export interface EventCategoryClass {
  [EventCategory.Account]: AccountResultClass;
  [EventCategory.Authentication]: AuthResultClass;
  [EventCategory.Claim]: ClaimResultClass;
  [EventCategory.DocumentTemplate]: DocumentTemplateResultClass;
  [EventCategory.CodeType]: {};
  [EventCategory.Import]: ImportResultClass;
  [EventCategory.Institution]: {};
  [EventCategory.Job]: {};
  [EventCategory.Organization]: {};
  [EventCategory.Personal]: PersonalResultClass;
  [EventCategory.System]: {};
  [EventCategory.User]: {};
  [EventCategory.WorkQueue]: {};
  [EventCategory.Trigger]: TriggerResultClass;
}

/** Mapping of event categories to names. */
export const EVENT_CATEGORY_NAME: Record<EventCategory, string> = {
  [EventCategory.System]: 'System',
  [EventCategory.Authentication]: 'Authentication',
  [EventCategory.User]: 'User',
  [EventCategory.Job]: 'Job',
  [EventCategory.Organization]: 'Organization',
  [EventCategory.Institution]: 'Institution',
  [EventCategory.CodeType]: 'Code Type',
  [EventCategory.Import]: 'Import',
  [EventCategory.Account]: 'Account',
  [EventCategory.WorkQueue]: 'Work Queue',
  [EventCategory.Personal]: 'Personal',
  [EventCategory.Claim]: 'Claim',
  [EventCategory.DocumentTemplate]: 'Document Template',
  [EventCategory.Trigger]: 'Trigger'
};

/** A category-result event pair. */
export class EventCategoryResult<C extends EventCategory = EventCategory, R extends EventCategoryResultType[C] = any> {
  constructor(
    /** Category of event. */
    public category: C,
    /** Result of event. */
    public result: R
  ) {}

  static typeinfo: TypeInfo<EventCategoryResult> = {
    category: new EnumValidator(EventCategory),
    result: 0
  }
}
