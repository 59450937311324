import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { NotificationItemComponent } from "./item/notification-item.component";
import { NotificationListComponent } from "./list/notification-list.component";

@NgModule({
  declarations: [
    NotificationItemComponent,
    NotificationListComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    NotificationItemComponent,
    NotificationListComponent
  ]
})
export class NotificationModule { }
