<button icon-button *ngIf="dev.on" class="code" tooltip="Copy code" (click)="onCode()">code</button>
<table app-grid selectable="false" class="fill-2 elevation-low" [source]="source" [sizes]="sizes" (selectionChange)="selectionChange.next($event)">
  
  <ng-container grid-column-def="permission">
    <grid-header-cell hide *grid-header-cell-def></grid-header-cell>
    <grid-cell *grid-cell-def="let row">
      <app-boolean  compact
                    [disabled]="readonly"
                    [indeterminate]="($any(fallback)[(row.permission | string)].access || $any(fallback)[Permission.System].access || $any(main)[Permission.System].access)"
                    [(ngModel)]="row.access"></app-boolean>
    </grid-cell>
  </ng-container>
  
  <ng-container grid-column-def="name">
    <grid-header-cell *grid-header-cell-def>Permission</grid-header-cell>
    <grid-cell *grid-cell-def="let row">{{row.name}}</grid-cell>
  </ng-container>

  <grid-header-row *grid-header-row-def="columns"></grid-header-row>
  <grid-row *gridRowDef="let row; columns: columns;" [class.selected]="row.access"></grid-row>
</table>