import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { CodePreview, CodeType } from "../../../../../../common/model/code-type";
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CodeTypeQuery, CodeTypeService } from '../../../common/service/code-type.service';
import { CollectionService } from '../../../common/service/collection.service';
import { LogService } from '../../../common/service/log.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-code-type',
  templateUrl: './setup-code-type.component.html',
  styleUrls: ['./setup-code-type.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupCodeTypeComponent extends SetupComponent<CodeType, CodeTypeQuery, CodePreview> {
  readonly Type = CodeType;
  readonly collection = 'codeTypes';
  readonly route = 'code-types';
  resource = idMaybe(new CodeType());

  static title() {
    return 'Code Types';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public auth: AuthService,
    public http: HttpClient,
    public service: CodeTypeService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }
}
