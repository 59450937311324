import { TypeInfo } from "../info/type";
import { Organization, OrganizationPreview } from "../model/organization/organization";
import { ID_DEFAULT } from "../toolbox/id";
import { SuccessResponse } from "./success";

/** Request to get organizations. */
export class OrganizationGetRequest {
  constructor(
    /** ID of organizations. */
    public _orgs: string[] = [],
    /** Names of organizations. */
    public names?: string[]
  ) {}

  static typeinfo: TypeInfo<OrganizationGetRequest> = {
    _orgs: [ID_DEFAULT, ID_DEFAULT],
    names: ['']
  }
}

/** Response of getting organizations. */
export type OrganizationGetResponse = Organization[];

/** Request to get organizations. */
export class OrganizationPreviewGetRequest extends OrganizationGetRequest {}

/** Response of getting organizations. */
export type OrganizationPreviewGetResponse = OrganizationPreview[];

/** Perform setup of system institution for testing. */
export class OrganizationSystemGetRequest {}

/** Result of setting up system organization. */
export type OrganizationSystemGetResponse = SuccessResponse;