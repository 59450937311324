import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberGetRequest } from "../../../../../common/message/member";
import { PaginateResponse } from '../../../../../common/message/paginate';
import { Member } from "../../../../../common/model/member";
import { MemberJoin } from '../../../../../common/model/member-join';
import { ArraySome, arraySingle } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { MemberComponent } from '../../module/member/member.component';
import { MemberData } from '../../module/member/member.model';
import { MemberResultsComponent } from '../../module/member/results/member-results.component';
import { MemberResultsData } from '../../module/member/results/member-results.model';
import { DialogService } from '../component/dialog/dialog.service';
import { CacheService } from '../toolbox/cache-service';
import { getRequest } from '../toolbox/request';
import { LogService } from './log.service';
import { TabService } from './tab.service';

/** A query to fetch a specific member. */
export class MemberQuery {
  constructor(
    /** ID of member. */
    public _id = ID_DEFAULT,
    /** Institution of member. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Member with a pre-calculated name. */
export class MemberName extends Member {
  public name = '';

  /** Attach name to a member. */
  static from(member: Member) {
    let out = member as MemberName;
    out.name = Member.fullname(member);
    return out;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MemberService extends CacheService<MemberName, MemberQuery> {
  readonly route = 'members';
  readonly Type = MemberName;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private tabs: TabService
  ) {
    super(MemberQuery, log, dialog, http);
  }

  /** Query specified members and open. */
  async open(request: MemberGetRequest, members?: MemberJoin[]) {
    let response = members ? new PaginateResponse(members) : await getRequest(this.http, 'members', request);
    if (errorResponse(response)) return this.log.show(response);
    
    if (arraySingle(response.items)) return this.tabs.open(MemberComponent, new MemberData(response.items[0]));
    this.tabs.open(MemberResultsComponent, new MemberResultsData(request, response));
  }

  protected override async multiple(queries: ArraySome<MemberQuery>) {
    let members = await getRequest(this.http, 'members', { _inst: queries[0]._inst, _ids: queries.map(q => q._id) });
    return errorResponse(members) ? members : members.items.map(MemberName.from);
  }
}