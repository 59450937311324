import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ClaimCount } from "../../../../../../common/model/claim/count";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { AuthService } from "../../../common/service/auth.service";
import { ClaimService } from "../../../common/service/claim.service";
import { LogService } from "../../../common/service/log.service";
import { getRequest } from "../../../common/toolbox/request";

@Component({
  selector: 'app-claim-counts',
  templateUrl: './claim-counts.component.html',
  styleUrls: ['./claim-counts.component.scss']
})
export class ClaimCountsComponent {
  
  /** List of counts to display. */
  counts: ClaimCount[] = [];

  constructor(
    protected auth: AuthService,
    private http: HttpClient,
    private log: LogService,
    private claims: ClaimService
  ) { }

  async ngOnInit() {
    let counts = await getRequest(this.http, 'claims/count', { _inst: this.auth._inst });
    if (errorResponse(counts)) return this.log.show(counts);
    this.counts = counts;
  }

  /** Callback when selecting count. */
  async onCount(count: ClaimCount) {
    this.claims.open({ _insts: [this.auth._inst], _filter: count._filter });
  }
}
