import { Pipe } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'setHas'
})
export class SetHasPipe<T> {

  public transform(value: T, set: Set<T>): SafeResourceUrl {
    return set.has(value);
  }
}