import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Code, CodePreview, CodeType } from "../../../../../common/model/code-type";
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from "../component/dialog/dialog.service";
import { CachePreviewService } from "../toolbox/cache-preview-service";
import { getRequest } from "../toolbox/request";
import { LogService } from "./log.service";

/** A query to fetch a specific task. */
export class CodeTypeQuery {
  constructor(
    /** Institution of code type. */
    public _inst = ID_DEFAULT,
    /** Category of task. */
    public category = ''
  ) {}
}

/** Global cache to get display values for code types. */
@Injectable({
  providedIn: 'root'
})
export class CodeTypeService extends CachePreviewService<CodeType, CodeTypeQuery, CodePreview> {
  readonly route = 'code-types/preview';
  readonly Type = CodeType;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient
  ) {
    super(CodeTypeQuery, log, dialog, http);
  }

  /** Get name of a particular code. */
  async codename(key: string, query: CodeTypeQuery) {
    if (key === undefined) return '';
    let type = await this.item(query);
    return type.codes[key] || `#${key}`;
  }

  /** Get list of code types matching query. */
  async list(query: CodeTypeQuery): Promise<Code[]> {
    let type = await this.item(query);
    return CodeType.list(type);
  }

  protected override multiple(queries: ArraySome<CodeTypeQuery>) {
    return getRequest(this.http, 'code-types', { _insts: [queries[0]._inst], categories: queries.map(q => q.category), viewOnly: true });
  }
}