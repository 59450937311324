import { ErrorResponse } from "../../../../../common/message/error";
import { HasIdName } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { CacheService } from "./cache-service";
import { PreviewLike } from "./preview";
import { getRequest } from "./request";

/** Cache service with additional preview functionality. */
export abstract class CachePreviewService<T extends HasIdName, Q extends Object, P extends PreviewLike> extends CacheService<T, Q> {

  /** Cache of previews by institution. */
  protected previewCache = new Map<string, Promise<P[]>>();
  /** Cache of names by institution. */
  protected nameCache = new Map<string, string>();

  /** Push items into internal cache. */
  override set(...items: T[]) {
    super.set(...items);
    for (let item of items) {
      this.nameCache.set(this.key(item), item.name);
    }
  }

  /** Delete given item from cache. */
  override async delete(_id: string) {
    super.delete(_id);
    this.nameCache.delete(_id);
    for (let [key, value] of [...this.previewCache.entries()]) {
      let list = await value;
      for (let i = 0; i < list.length; ++i) {
        let item = list[i]!;
        if (item._id !== _id) continue;
        list.splice(i--, 1);
      }

      this.previewCache.set(key, Promise.resolve(list));
    }
  }

  /** Clear all items from cache. */
  override clear() {
    super.clear();
    this.previewCache.clear();
  }

  /** Get all previews of an institution. */
  async previews(_inst: string, force?: boolean): Promise<P[]> {
    // Fetch existing previews from cache.
    let previews = this.previewCache.get(_inst);
    if (previews && !force) return previews;

    // Get list of previews for institution.
    let response = this.preview(_inst);
    this.previewCache.set(_inst, response.then(items => {
      if (errorResponse(items)) {
        this.log.show(items);
        return [];
      }

      for (let item of items) this.nameCache.set(item._id, item.name);
      return items;
    }));

    // Results definitely exist in map now.
    return this.previews(_inst);
  }

  /** @ts-ignore Get name of given item. */
  override async name(_inst: string, _id: string) {
    // Find in name cache.
    let name = this.nameSync(_id);
    if (name !== undefined) return name;

    // Refresh name cache.
    // WARNING: Preview call may have failed so we cannot recurse here.
    await this.previews(_inst);
    return this.nameCache.get(_id) ?? '';
  }

  /** Get name of an item synchrously. */
  nameSync(_id: string) {
    return this.nameCache.get(_id);
  }

  /** Get list of previews for institution. */
  protected preview(_inst: string) {
    return getRequest(this.http, this.route, { _insts: [_inst] }) as Promise<P[] | ErrorResponse>;
  }
}