import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DragChipComponent } from '../../drag-chip/drag-chip.component';

@Component({
  selector: 'app-edit-list-item',
  templateUrl: './edit-list-item.component.html',
  styleUrls: ['../../drag-chip/drag-chip.component.scss']
})
export class EditListItemComponent<T> extends DragChipComponent {

  /** Form value of option. */
  @Input() value: T = undefined as unknown as T;
  /** Value being created. */
  @Input() creating?: T;
  /** Emits when value being created changes. */
  @Output() creatingChange = new EventEmitter<T>();

  /** Emits when item is deleted. */
  @Output() deleted = new EventEmitter<EditListItemComponent<T>>();
  /** Emits when item is confirmed. */
  @Output() confirm = new EventEmitter<void>();
}
