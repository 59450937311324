import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.scss'],
  host: {
    'class': 'elevation-high',
    '[class.disabled]': '_disabled',
    '[class.readonly]': '_readonly'
  }
})
export class SaveComponent {
  /** Emits when changes are saved. */
  @Output() save = new EventEmitter<void>();
}