import { RouteMethod } from "../toolbox/api";
import { ArraySome, arraySome } from "../toolbox/array";
import { PartialDeep, deepAssign, deepCopy, objectEmpty, objectKeys, objectType } from "../toolbox/object";
import { ValidatorLog, Validator } from "../validator/base";
import { CustomValidator } from "../validator/custom";
import { infoOf } from "./base";

/** Special keys to replace with Postman environment variables. */
const POSTMAN_ENV = new Set(['email', 'password', 'challenge', '_user', '_inst', '_org', '_job', '_profile', '_queue']);
/** Exceptions for above rules. */
const POSTMAN_OVERRIDE: any = {
  _id: '{{ID_DEFAULT}}',
  _ids: ['000000000000000000000000', '000000000000000000000001', '000000000000000000000002'],
  _insts: ['{{_inst}}'],
  _assigned: '{{_user}}'
};

/** Result of manually validating an object. */
export type TypeValidation<T> = void | keyof T | ValidatorLog[];
/** Function used to manually validate type. */
export type TypeFunction<T> = (item: T) => TypeValidation<T>;
/** Validation for a given type. */
export type TypeValidator<T> = T | Validator<T>;

/** Type annotations manually added to object. Required on any optional properties. */
export type TypeInfo<T> = {
  [K in keyof T]?: Required<T>[K] extends Array<infer I> ? TypeValidator<I[]> | ArraySome<TypeValidator<I>> : TypeValidator<T[K]>
}

/** Pull typeinfo from object. */
export function typeinfoOf<T>(value: T): TypeInfo<T> {
  return infoOf(value, 'typeinfo', {});
}

/** Pull a value from an object, falling back on typeinfo. */
export function typeinfoValue<T>(object: T, key: keyof T, typeinfo: TypeInfo<T>) {
  return typeinfo[key] ?? object[key];
}

/** Pull a postman value from an object, using environment variables and fallbacks. */
export function typeinfoEnvironment<T>(object: T, key: keyof T | undefined, typeinfo: TypeInfo<T>): any {
  if (POSTMAN_ENV.has(key as any)) return `{{${String(key)}}}`;
  else if (POSTMAN_OVERRIDE[key]) return POSTMAN_OVERRIDE[key];
  let value = key ? typeinfoValue(object, key, typeinfo) : deepAssign(deepCopy(object), typeinfo);

  switch (objectType(value)) {
  case 'date':
    return new Date(0).toISOString();
  case 'function':
    return '';
  case 'object':
    let out: any = deepCopy(value);

    for (let key of Object.keys(out)) {
      out[key] = typeinfoEnvironment(out, key, typeinfo);
    }

    return out;
  case 'array':
    let array: any = value;
    if (arraySome(array)) return [typeinfoEnvironment(array[0], undefined, typeinfoOf(array[0]))];
    return [];
  default:
    return value;
  }
}

/** Log out Postman key-value pairs for object. */
export function typeinfoPostman<T extends Object>(method: RouteMethod, object: T, comment = false) {
  let typeinfo = typeinfoOf(object);
  switch (method) {
  case RouteMethod.Get:
    return objectKeys(object).map(key => `${comment ? '//' : ''}${String(key)}:${typeinfoEnvironment(object, key, typeinfo)}`).join('\n');
  default:
    return typeinfoEnvironment(object, undefined, typeinfo);
  }
}

/** Mark all properties in object as optional and return partial with type information. */
export function typeinfoPartial<T>(object: T): Partial<T> {
  return new class {

    constructor() {
      for (let key in object) {
        (this as any)[key] = undefined;
      }
    }

    static typeinfo = {
      ...object,
      ...typeinfoOf(object)
    };
  };
}

/** Filter keys of object that match the specified typeinfo. */
export function objectStripTypeinfo<T>(object: T, typeinfoMap: Map<string, any>): PartialDeep<T> {
  return objectStripTypeinfoDeep(object, typeinfoMap, []);
}

/** Recursively filter out an object to specified set of keys. */
function objectStripTypeinfoDeep(object: any, typeinfoMap: Map<string, any>, path: string[]): any {
  let typeinfo = typeinfoMap.get(path.join('.'));
  switch (typeof object) {
  case 'number':
  case 'string':
  case 'boolean':
    return typeinfo !== undefined ? object : undefined;
  case 'object':
    if (object instanceof Date || object instanceof Map || object instanceof Set) {
      return object;
    } else if (Array.isArray(object)) {
      if (object.length === 0 && typeinfo !== undefined) return [];
      let out = [];
      for (let i = 0; i < object.length; ++i) {
        let value = objectStripTypeinfoDeep(object[i], typeinfoMap, path);
        if (value !== undefined) {
          out[i] = value;
        }
      }

      if (out.length) return out;
    } else if (object) {
      if (
        typeinfo instanceof CustomValidator ||
        Array.isArray(typeinfo) ||
        (typeinfo !== undefined && typeof typeinfo == 'object' && objectEmpty(typeinfo))
      ) return object;
      let out: any = {};
      for (let key in object) {
        path.push(key);
        let value = objectStripTypeinfoDeep(object[key], typeinfoMap, path);
        path.pop();

        if (value !== undefined) {
          out[key] = value;
        }
      }

      if (!objectEmpty(out)) return out;
    }
  }
}