import { AccountCategory } from "../../code/standard/common";
import { CommonCode, ImportCode } from "../../code/system";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { currency, CURRENCY_DEFAULT } from "../../toolbox/currency";
import { PropertyType } from "../property-type";
import { AccountBase } from "./base";

/** Information about a share subaccount attached to an account. */
export class AccountShare extends AccountBase {
  readonly category = AccountCategory.Share;

  constructor(
    /** Current balance on share. */
    public availableBalance?: currency,
    /** Percentage paid by company for share. */
    public dividendRate?: currency,
    /** Type of insurance for share, if applicable. */
    public insurance?: string,
    /** Date that negative balance was attained on share. */
    public negativeBalanceDate?: Date,
    /** Short nickname description of share. */
    public nickname?: string,
    /** Insufficient fund fees accumulated last year. */
    public nsfFeeLastYear?: currency,
    /** Insufficient fund fees, month to day. */
    public nsfFeeMtd?: currency,
    /** Insufficient fund fees, year to day. */
    public nsfFeeYtd?: currency,
    /** Insufficient funds accumulated last year. */
    public nsfLastYear?: currency,
    /** Insufficient funds, month to day. */
    public nsfMtd?: currency,
    /** Insufficient funds, year to day. */
    public nsfYtd?: currency,
    /** Original deposit date of share. */
    public originalDepositDate?: Date,
    /** System-defined code for category of share. */
    public shareCode?: string
  ) {
    super();
  }

  static override typeinfo: TypeInfo<AccountShare> = {
    ...AccountBase.typeinfo,
    availableBalance: CURRENCY_DEFAULT,
    dividendRate: CURRENCY_DEFAULT,
    insurance: '',
    negativeBalanceDate: new Date(),
    nickname: '',
    nsfFeeLastYear: CURRENCY_DEFAULT,
    nsfFeeMtd: CURRENCY_DEFAULT,
    nsfFeeYtd: CURRENCY_DEFAULT,
    nsfLastYear: CURRENCY_DEFAULT,
    nsfMtd: CURRENCY_DEFAULT,
    nsfYtd: CURRENCY_DEFAULT,
    originalDepositDate: new Date(),
    shareCode: ''
  }

  static override propinfo: PropertyInfo<AccountShare> = {
    ...AccountBase.propinfo,
    availableBalance: { type: PropertyType.Currency },
    dividendRate: { type: PropertyType.Currency },
    category: { type: PropertyType.Code, category: CommonCode.AccountCategory },
    insurance: { type: PropertyType.Code, category: ImportCode.ShareInsurance },
    nsfFeeLastYear: { type: PropertyType.Currency },
    nsfFeeMtd: { type: PropertyType.Currency },
    nsfFeeYtd: { type: PropertyType.Currency },
    nsfLastYear: { type: PropertyType.Currency },
    nsfMtd: { type: PropertyType.Currency },
    nsfYtd: { type: PropertyType.Currency },
    shareCode: { type: PropertyType.Code, category: ImportCode.ShareCode }
  }
}