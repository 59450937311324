import { Box } from "../../box";
import { DisplayType } from "../../display";
import { BuiltinForm } from "../form";
import { ModelTestFields, ModelTestMultiple, TestModel } from "../model/test";
import { builtinFormModel } from "./form";

/** Built in test forms. */
export enum TestForm {
  Empty    = 'Test - Empty',
  Field    = 'Test - Field',
  Multiple = 'Test - Multiple',
  Table    = 'Test - Table'
}

/** List of builtin forms. */
export const BUILTIN_TEST_FORMS: Record<TestForm, BuiltinForm> = {
  [TestForm.Empty]: {
    editable: [DisplayType.Model]
  },
  [TestForm.Field]: {
    model: TestModel.Field,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelTestFields>('boolean'),
      builtinFormModel<ModelTestFields>('number'),
      builtinFormModel<ModelTestFields>('string'),
      builtinFormModel<ModelTestFields>('currency'),
      builtinFormModel<ModelTestFields>('date'),
      builtinFormModel<ModelTestFields>('code'),
      builtinFormModel<ModelTestFields>('user'),
      builtinFormModel<ModelTestFields>('member'),
      builtinFormModel<ModelTestFields>('phone'),
      builtinFormModel<ModelTestFields>('transaction'),
      builtinFormModel<ModelTestFields>('dateLink')
    ]
  },
  [TestForm.Multiple]: {
    model: TestModel.Multiple,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelTestMultiple>('boolean'),
      builtinFormModel<ModelTestMultiple>('number'),
      builtinFormModel<ModelTestMultiple>('string'),
      builtinFormModel<ModelTestMultiple>('currency'),
      builtinFormModel<ModelTestMultiple>('date'),
      builtinFormModel<ModelTestMultiple>('code'),
      builtinFormModel<ModelTestMultiple>('user'),
      builtinFormModel<ModelTestMultiple>('member'),
      builtinFormModel<ModelTestMultiple>('phone'),
      builtinFormModel<ModelTestMultiple>('transaction'),
      builtinFormModel<ModelTestMultiple>('dateLink')
    ]
  },
  [TestForm.Table]: {
    model: TestModel.Multiple,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelTestMultiple>('boolean', { box: new Box(0, 0, 0, 2) }),
      builtinFormModel<ModelTestMultiple>('number', { box: new Box(1, 0, 1, 2) }),
      builtinFormModel<ModelTestMultiple>('string', { box: new Box(2, 0, 2, 2) }),
      builtinFormModel<ModelTestMultiple>('currency', { box: new Box(0, 3, 0, 5) }),
      builtinFormModel<ModelTestMultiple>('date', { box: new Box(1, 3, 1, 5) }),
      builtinFormModel<ModelTestMultiple>('code', { box: new Box(2, 3, 2, 5) }),
      builtinFormModel<ModelTestMultiple>('user', { box: new Box(0, 6, 0, 8) }),
      builtinFormModel<ModelTestMultiple>('member', { box: new Box(1, 6, 1, 8) }),
      builtinFormModel<ModelTestMultiple>('phone', { box: new Box(2, 6, 2, 8) }),
      builtinFormModel<ModelTestMultiple>('transaction', { box: new Box(0, 9, 0, 11) }),
      builtinFormModel<ModelTestMultiple>('dateLink', { box: new Box(1, 9, 1, 11) })
    ]
  }
};