import { Component, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountPanel, AccountPanelType } from "../../../../../../../common/model/account-panel";
import { AccountJoin, AccountJoinLoan } from '../../../../../../../common/model/account/account';
import { EventResult } from "../../../../../../../common/model/event/result";
import { Member } from "../../../../../../../common/model/member";
import { EventListComponent } from '../../../../common/component/event-list/event-list.component';
import { EventListData } from '../../../../common/component/event-list/event-list.model';
import { PanelListComponent } from '../../../../common/component/panel/list/panel-list.component';
import { TabConfig } from '../../../../common/component/tab/bar/tab-bar.model';
import { TaskOpen } from '../../../../common/toolbox/task';
import { AccountTransactionsComponent } from '../../transactions/account-transactions.component';
import { AccountTransactionsData } from '../../transactions/account-transactions.model';
import { AccountPanelComponent } from '../account-panel.component';
import { AccountAttachmentListComponent } from '../attachment-list/account-attachment-list.component';
import { AccountAttachmentsData } from '../attachment-list/account-attachment-list.model';
import { AccountDisputesComponent } from '../disputes/account-disputes.component';
import { AccountDisputesData } from '../disputes/account-disputes.model';

/** Default name for account panel types. */
const ACCOUNT_PANEL_NAME: { [A in AccountPanelType]: string } = {
  [AccountPanelType.Attachments]: 'Attachments',
  [AccountPanelType.Claims]: 'Claims',
  [AccountPanelType.History]: 'Events',
  [AccountPanelType.Transactions]: 'Transactions'
};

/** Override for showing outer panels. */
const ACCOUNT_PANEL_HIDE = new Set<AccountPanelType>([AccountPanelType.Attachments]);

@Component({
  selector: 'app-account-panel-list',
  templateUrl: './account-panel-list.component.html',
  styleUrls: ['./account-panel-list.component.scss']
})
export class AccountPanelListComponent {
  /** Account being displayed. */
  @Input() set account(account: AccountJoin) { this.reaccount(account); }
  /** Primary borrower of this account. */
  @Input() set member(member: Member | undefined) { this._member.next(member); }

  /** Emits when existing task result should be opened. */
  @Output() open = new Subject<TaskOpen>();
  /** Reference to navigation panel. */
  @ViewChild(PanelListComponent) list?: PanelListComponent;

  /** Emits when account is changed. */
  private _account = new BehaviorSubject<AccountJoin>(new AccountJoinLoan());
  /** Emits when primary borrower changes. */
  private _member = new BehaviorSubject<Member | undefined>(undefined);

  ngOnDestroy() {
    this._account.complete();
  }

  /** Update current displayed account. */
  private async reaccount(account: AccountJoin) {
    this._account.next(account);

    // TODO Reintroduce code to determine list of panels to display upon returning to collections product.
    this.list?.clear(true);
    if (this.list?.tabs.length) this.list.tab = this.list.tabs[0];
  }

  /** Append the specified panel to the end. */
  add(panel: AccountPanel) {
    let options: TabConfig = {
      title: ACCOUNT_PANEL_NAME[panel.type],
      container: ACCOUNT_PANEL_HIDE.has(panel.type) ? undefined : AccountPanelComponent,
      autoselect: false
    };

    switch (panel.type) {
    case AccountPanelType.Attachments:
      let attachmentsData = new AccountAttachmentsData(this._account);
      this.list?.open(AccountAttachmentListComponent, attachmentsData, options);
      break;
    case AccountPanelType.Claims:
      let disputesData = new AccountDisputesData(this._account);
      this.list?.open(AccountDisputesComponent, disputesData, options);
      break;
    case AccountPanelType.History:
      let eventData = new EventListData(this._account, undefined, undefined, this.open as Subject<TaskOpen<EventResult>>);
      this.list?.open(EventListComponent, eventData, options);
      break;
    case AccountPanelType.Transactions:
      let transactionsData = new AccountTransactionsData(this._account);
      this.list?.open(AccountTransactionsComponent, transactionsData, options);
      break;
    }
  }
}
