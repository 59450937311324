import { TypeInfo, typeinfoOf } from "../info/type";
import { BankUnion } from "../model/bank";
import { ArraySome } from "./array";
import { bankUnionResolve } from "./bank";
import { Pair, deepCopy, objectCompose, objectEntries } from "./object";
import { titleCase } from "./string";

/**
 *  A mapping of enum values to display names.
 *  TODO: Remove these if proper internationalization support is added.
 */
export type EnumMap<T extends PropertyKey = string> = Record<T, string>;

/** A mapping of enum values to bank-sensitive display terminology. */
export type EnumBankMap<T extends PropertyKey = string> = Record<T, BankUnion<string>>;

/** Check if enum contains requested value. */
export function enumHas<T>(map: Record<number, T>, value: any): value is T {
  return Object.values(map).includes(value);
}

/** Check if value is in enum, otherwise fallback on default value. */
export function enumFallback<T>(map: Record<number, T>, value: any, fallback: T): T {
  return enumHas(map, value) ? value : fallback;
}

/** Get values of an integer enumeration. */
export function enumValues<T = any>(map: Record<number, any>, unsorted?: boolean): ArraySome<T> {
  let set = new Set<T>();
  let values = Object.values(map);
  let numbers = values.filter(v => typeof v === 'number');
  let list = numbers.length ? numbers : values;

  for (let v of list) set.add(v);
  return (unsorted ? [...set] : [...set].sort()) as ArraySome<T>;
}

/** Map an array, preserving arity of array. */
export function arrayMap<T, U>(array: ArraySome<T>, callback: (value: T, index: number, array: T[]) => U, thisArg?: any): ArraySome<U>
export function arrayMap<T, U>(array: T[], callback: (value: T, index: number, array: T[]) => U, thisArg?: any): U[]
export function arrayMap(array: any[], callback: any): any[] {
  return array.map(callback);
}

/** Create a set of all enum values. */
export function enumSet<T = any>(map: Record<number, any>): Set<T> {
  return new Set(enumValues(map));
}

/** Get enum as a list of key-value pairs. */
export function enumKeyValues<T>(map: Record<number, unknown>): Pair<T>[] {
  return enumValues(map).map(value => new Pair(value, titleCase(`${value}`))) as Pair<T>[];
}

/** Create typed objectwith given enum key-value pairs. */
export function enumPrototype<K extends PropertyKey = any, V = any>(map: Record<number, any> | K[], value: V, optional = false): Record<K, V> {
  let values = Array.isArray(map) ? map : enumValues(map);
  let Prototype = class {

    constructor() {
      if (optional) return;
      for (let key of values) {
        (this as any)[key] = deepCopy(value);
      }
    }

    static typeinfo: TypeInfo<any> = {};
  }

  let instance = new Prototype();
  if (optional) {
    let typeinfo: any = typeinfoOf(instance);
    for (let key of values) {
      typeinfo[key] = deepCopy(value);
    }
  }

  return instance as Record<K, V>;
}

/** Get pairs of an enum map. */
export function enumMapPairs<T extends string | number>(map: EnumMap<T>): Pair<T>[] {
  let entries = objectEntries(map);
  let numbers = entries.every(([key]) => !isNaN(+key));
  return numbers ? entries.map(([key, value]) => new Pair(+key as T, value)) : entries.map(([key, value]) => new Pair(key, value));
}

/** Get mapping of enum values to source code names. */
export function enumCodeMap<T extends number | string | symbol>(map: Record<number, unknown>): Record<T, string> {
  let keys = Object.keys(map);
  let numbers = keys.filter(i => !isNaN(+i));
  if (numbers.length) return Object.fromEntries(numbers.map(key => [+key, map[key as any]])) as any;
  return Object.fromEntries(Object.keys(map).map(key => [map[key as any], key])) as any;
}

/** Resolve a bank union map to a standard enum map. */
export function enumBankMapResolve<T extends string | number>(map: EnumBankMap<T>, bank: boolean): EnumMap<T> {
  return objectCompose(objectEntries(map).map(([key, value]) => [key, bankUnionResolve(value, bank)]));
}