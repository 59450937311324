import { Component, Input } from '@angular/core';
import { PROPERTY_TYPE_ICON } from '../../../toolbox/property';
import { FormAccessor } from '../form-accessor';

@Component({
  selector: 'app-form-string',
  templateUrl: './form-string.component.html',
  styleUrls: ['./form-string.component.scss']
})
export class FormStringComponent extends FormAccessor<string | undefined> {
  readonly PROPERTY_TYPE_ICON = PROPERTY_TYPE_ICON;
  readonly Number = Number;

  /** True if text input is in large mode. */
  @Input() large = false;
  /** Maximum length of string. */
  maxLength?: number;
  /** Minimum length of string. */
  minLength?: number;

  override writeValue(value?: string) {
    this.update(value || (this.required ? '' : undefined));
  }
}
