import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../../common/model/formula/block";
import { StatementIf } from "../../../../../../../../common/model/formula/statement";
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';
import { BlockSlot } from '../../block.model';

@Component({
  selector: 'app-setup-formula-if',
  templateUrl: './setup-formula-if.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-if.component.scss']
})
export class SetupFormulaIfComponent extends BlockComponent<StatementIf> {
  /** Attachment point for if expression. */
  @ViewChild('if', { static : true, read: ViewContainerRef }) if!: ViewContainerRef;
  /** Attachment point for then statement. */
  @ViewChild('then', { static : true, read: ViewContainerRef }) then!: ViewContainerRef;
  /** Attachment point for else statement. */
  @ViewChild('else', { static : true, read: ViewContainerRef }) else!: ViewContainerRef;

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  ngOnInit() {
    this.reif(undefined, this.block.if);
    this.rethen(undefined, this.block.then);
    this.reelse(undefined, this.block.else);
  }

  /** Replace if expression. */
  private reif(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.if,
      current: { block: this.block, key: 'if' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.reif.bind(this)
    });
  }

  /** Replace then expression. */
  private rethen(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.then,
      current: { block: this.block, key: 'then' },
      next: block,
      accepts: [BlockType.Statement],
      replaced: this.rethen.bind(this)
    });
  }

  /** Replace else expression. */
  private reelse(slot?: BlockSlot, block?: Block | Block[]) {
    if (!this.block.else) this.block.else = [];

    this.replace(slot, {
      parent: this.injector,
      container: this.else,
      current: { block: this.block, key: 'else' },
      next: block ?? [],
      accepts: [BlockType.Statement],
      replaced: this.reelse.bind(this)
    });
  }
}
