<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
      <app-field class="fill">
        <app-label>Name</app-label>
        <app-string [(ngModel)]="value.name" [disabled]="value | setupReadonly"></app-string>
      </app-field>
    </app-setup-base-header>
    <app-setup-base-body>
      <div class="list" [list-grid]="value.properties">
        <app-drag-chip cdkDrag cdkDragBoundary=".list" *ngFor="let p of value.properties; let i = index">
          <div class="inputs">
            <app-field full [readonly]="">
              <app-label>Key</app-label>
              <button icon-button [menu-trigger]="propertyMenu"[disabled]="value | setupReadonly" tooltip="Type">{{p.type | icon:PROPERTY_TYPE_ICON}}</button>
              <app-string [ngModel]="p.key" (ngModelChange)="onKey(p, $event)" [disabled]="value | setupReadonly"></app-string>
            </app-field>

            <app-field full>
              <app-label>Name</app-label>
              <app-string [(ngModel)]="p.name" [placeholder]="p.key | titleCase" [required]="false"></app-string>
            </app-field>

            <!-- 
                  Forms for specific property values.
                  $any() cast is necessary on these because Angular templates lack type narrowing for ngSwitch.
                  See: https://github.com/angular/angular/issues/20780
            -->

            <ng-container [ngSwitch]="p.type">
              <ng-container *ngSwitchCase="PropertyType.Boolean">
                <app-field full>
                  <app-label>Default</app-label>
                  <app-boolean on="true" off="false" [required]="false" [(ngModel)]="p.value"></app-boolean>
                </app-field>
                <app-field full>
                  <app-label>On Label</app-label>
                  <app-string placeholder="Yes" [required]="false" [(ngModel)]="$any(p).on"></app-string>
                </app-field>
                <app-field full>
                  <app-label>Off Label</app-label>
                  <app-string placeholder="No" [required]="false" [(ngModel)]="$any(p).off"></app-string>
                </app-field>
              </ng-container>

              <ng-container *ngSwitchCase="PropertyType.Number">
                <app-field full>
                  <app-label>Default</app-label>
                  <app-number [(ngModel)]="p.value" [required]="true" [min]="$any(p).min" [max]="$any(p).max"></app-number>
                </app-field>
                <app-field full>
                  <app-label>Min</app-label>
                  <app-number [(ngModel)]="$any(p).min" [min]="INT32_MIN" [max]="INT32_MAX"></app-number>
                </app-field>
                <app-field full>
                  <app-label>Max</app-label>
                  <app-number [(ngModel)]="$any(p).max" [min]="INT32_MIN" [max]="INT32_MAX"></app-number>
                </app-field>
              </ng-container>
  
              <ng-container *ngSwitchCase="PropertyType.String">
                <app-field full>
                  <app-label>Default</app-label>
                  <app-string [maxlength]="$any(p).length" [required]="false" [(ngModel)]="p.value"></app-string>
                </app-field>
                <app-field full>
                  <app-label>Length</app-label>
                  <app-number [(ngModel)]="$any(p).length" [min]="1" [max]="INT32_MAX" [required]="true"></app-number>
                </app-field>
              </ng-container>

              <ng-container *ngSwitchCase="PropertyType.Currency">
                <app-field full>
                  <app-label>Default</app-label>
                  <app-currency [(ngModel)]="p.value" [min]="$any(p).min" [max]="$any(p).max" [required]="true"></app-currency>
                </app-field>
                <app-field full>
                  <app-label>Min</app-label>
                  <app-currency [(ngModel)]="$any(p).min" [min]="CURRENCY_MIN" [max]="CURRENCY_MAX"></app-currency>
                </app-field>
                <app-field full>
                  <app-label>Max</app-label>
                  <app-currency [(ngModel)]="$any(p).max" [min]="CURRENCY_MIN" [max]="CURRENCY_MAX"></app-currency>
                </app-field>
              </ng-container>

              <ng-container *ngSwitchCase="PropertyType.Date">
                <app-field full>
                  <app-label>Default</app-label>
                  <app-sublabel>
                    <app-hover-help [tooltip]="DATE_HELP"></app-hover-help>
                  </app-sublabel>
                  <app-number [(ngModel)]="p.value" [min]="$any(p).min" [max]="$any(p).max" [required]="false"></app-number>
                </app-field>
                <app-field full>
                  <app-label>Min</app-label>
                  <app-sublabel>
                    <app-hover-help [tooltip]="DATE_HELP"></app-hover-help>
                  </app-sublabel>
                  <app-number [(ngModel)]="$any(p).min" [min]="DAYS_MIN" [max]="DAYS_MAX"></app-number>
                </app-field>
                <app-field full>
                  <app-label>Max</app-label>
                  <app-sublabel>
                    <app-hover-help [tooltip]="DATE_HELP"></app-hover-help>
                  </app-sublabel>
                  <app-number [(ngModel)]="$any(p).max" [min]="DAYS_MIN" [max]="DAYS_MAX"></app-number>
                </app-field>
                <app-field full>
                  <app-label>Link</app-label>
                  <app-select [(ngModel)]="$any(p).link" [required]="false">
                    <ng-container *ngFor="let l of links.date">
                      <app-option *ngIf="l !== p" [view]="l.key | string" [value]="l.key"></app-option>
                    </ng-container>
                  </app-select>
                </app-field>
              </ng-container>

              <ng-container *ngSwitchCase="PropertyType.Code">
                <app-field full>
                  <app-label>Default</app-label>
                  <app-code [category]="$any(p).category" [(ngModel)]="p.value"></app-code>
                </app-field>
                <app-field full>
                  <app-label>Category</app-label>
                  <app-select [(ngModel)]="$any(p).category">
                    <app-option *ngFor="let t of types" [view]="t.name" [value]="t.category"></app-option>
                  </app-select>
                </app-field>
              </ng-container>

              <ng-container *ngSwitchCase="PropertyType.User">
                <app-field full>
                  <app-label>Default</app-label>
                  <app-user [(ngModel)]="p.value" [required]="false"></app-user>
                </app-field>
              </ng-container>

              <ng-container *ngSwitchCase="PropertyType.Phone">
                <app-field full>
                  <app-label>Default</app-label>
                  <app-string [(ngModel)]="p.value" [required]="false"></app-string>
                </app-field>
                <app-field full>
                  <app-label>Link</app-label>
                  <app-select [(ngModel)]="$any(p).link">
                    <ng-container *ngFor="let l of links.phone">
                      <app-option *ngIf="l !== p" [view]="l.key | string" [value]="l.key"></app-option>
                    </ng-container>
                  </app-select>
                </app-field>
              </ng-container>

            </ng-container>
          </div>
          <button icon-button [toggle-button]="p.required" (click)="p.required = !p.required" tooltip="Required"[disabled]="value | setupReadonly"></button>
          <button icon-button [toggle-button]="p.locked" [toggle-icon]="['lock_open', 'lock', '']" (click)="p.locked = !p.locked" tooltip="Lock after submission"></button>
          <button icon-button [toggle-button]="p.multiple" [toggle-icon]="['layers_clear', 'layers', '']" (click)="p.multiple = !p.multiple" tooltip="Multiple"[disabled]="value | setupReadonly"></button>
          <button icon-button (click)="onDelete(p)" tooltip="Delete"[disabled]="value | setupReadonly">delete</button>
          <button icon-button cdkDragHandle>drag_indicator</button>
          <app-menu color="secondary" #propertyMenu="appMenu">
            <app-menu-item icon *ngFor="let type of propertyTypes" (click)="onType(type.value, i)">
              <app-icon>{{type.value | icon:PROPERTY_TYPE_ICON}}</app-icon> {{type.view}}
            </app-menu-item>
          </app-menu>
        </app-drag-chip>
        <button button-prefix="add" position="before" *ngIf="(Permission.System | permission) || !value.system" (click)="onNew()">New</button>
      </div>
    </app-setup-base-body>
    <app-setup-base-footer>
      <button (click)="onSubmit()">Save</button>
      <button *ngIf="dev.on" (click)="onLog()">Log</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>