import { infoOf } from "./base";

/**
 *  Additional annotations for database queries.
 *  TODO: Hardcoded to just support { $ne: true } for now.
 */
export type QueryInfo<T = any> = {
  [K in keyof T]?: { $ne: true }
};

/** Pull queryinfo from object. */
export function queryinfoOf<T>(value: T): QueryInfo<T> {
  return infoOf(value, 'queryinfo');
}