<app-field>
  <app-label>Required Value</app-label>
  <app-sublabel>
    <app-hover-help tooltip="Required value for this field." tooltip-position="right"></app-hover-help>
  </app-sublabel>
  <app-boolean align-right [required]="false" [(ngModel)]="config.require"></app-boolean>
</app-field>
<app-field>
  <app-label>On Label</app-label>
  <app-string placeholder="Yes" [required]="false" [(ngModel)]="config.on"></app-string>
</app-field>
<app-field>
  <app-label>Off Label</app-label>
  <app-string placeholder="No" [required]="false" [(ngModel)]="config.off"></app-string>
</app-field>