import { Component } from "@angular/core";
import { PropertyType } from "../../../../../../../common/model/property-type";
import { FieldConfigClass } from "../../../../../../../common/model/form/field-config";
import { PropertyClass } from "../../../../../../../common/model/property";

/** Interface that allows a component to configure a specific form field type. */
@Component({
  selector: 'app-form-field-accessor',
  template: ''
})
export abstract class FormFieldAccessor<T extends PropertyType = PropertyType> {

  /** Property being configured. */
  property!: PropertyClass[T];
  /** Settings being configured. */
  config!: FieldConfigClass[T];

  /** Called after config is set. */
  reset() {}
}