import { Component, ElementRef, Input, NgZone } from "@angular/core";
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexPlotOptions, ApexStroke, ApexXAxis, ApexYAxis } from "ng-apexcharts";
import { Subject, debounceTime, takeUntil } from "rxjs";
import { arrayWrap } from "../../../../../../common/toolbox/array";
import { COLOR_SERIES, COLOR_VALUES } from "../../../../../../common/toolbox/color";
import { ChartSeries } from "./chart.model";

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"]
})
export class AppChartComponent {

  /** Title for x-axis. */
  @Input() set xAxis(title: string) {
    this.apexXAxis.title = { text: title };
    this.resize();
  }

  /** Title for y-axis. */
  @Input() set yAxis(title: string) {
    this.apexYAxis.title = { text: title };
    this.resize();
  }

  /** Categories to display in chart. */
  @Input() set categories(categories: string[]) {
    this.apexXAxis.categories = categories;
    this.resize();
  }

  /** Series to display in chart. */
  @Input() set series(series: ChartSeries[]) {
    // hideSeries() causes Apex Chart to crash internally, so we filter instead.
    this.apexSeries = series.filter(s => s.show !== false).map((s, i) => ({
      name: s.name,
      data: s.data,
      color: s.color ? COLOR_VALUES[s.color] : COLOR_VALUES[arrayWrap(COLOR_SERIES, i)]
    }));
    this.resize();
  }

  /** Series for chart. */
  protected apexSeries: ApexAxisChartSeries = [];

  /** Type of chart to display. */
  protected apexChart: ApexChart = {
    type: 'bar',
    toolbar: {
      show: true
    }
  };

  /** List of labels for chart. */
  protected apexDataLabels: ApexDataLabels = {
    enabled: false
  };

  /** Plot options for chart. */
  protected apexPlotOptions: ApexPlotOptions = {
    bar: {
      horizontal: false,
      columnWidth: '75%',
    }
  };

  /** Title on y-axis. */
  protected apexYAxis: ApexYAxis = {
    title: {
      text: ''
    }
  };

  /** Title on x-axis. */
  protected apexXAxis: ApexXAxis = {
    categories: [''],
    title: {
      text: ''
    }
  };

  /** Fill value for chart. */
  protected apexFill: ApexFill = {
    opacity: 1
  };

  /** Stroke of chart. */
  protected apexStroke: ApexStroke = {
    show: true,
    width: 2,
    colors: ['transparent']
  };

  /** Legend to display. */
  protected apexLegend: ApexLegend = {
    position: 'right',
    labels: {
      useSeriesColors: true
    }
  };
  
  /** Last observed size of element. */
  private size?: ResizeObserverSize;
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();
  /** Emits when size changes. */
  private sizeChange = new Subject<void>();
  /** Observer for size changes. */
  private observer?: ResizeObserver;

  constructor(
    private zone: NgZone,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit() {
    // Listen to select element size changes.
    this.observer = new ResizeObserver(entries => this.zone.run(() => {
      let borderBoxSize = entries[0]?.borderBoxSize[0];
      if (!borderBoxSize) return;
      this.size = borderBoxSize;
      this.sizeChange.next();
    }));

    // Throttle chart resizes to prevent chart not respecting new size.
    // 200ms delay can be increased as needed.
    this.sizeChange.pipe(takeUntil(this.destroy), debounceTime(200)).subscribe(() => this.resize());
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.observer?.disconnect();
  }

  /** Respond to element resizes. */
  private resize() {
    if (!this.size) return;

    this.apexChart.width = `${this.size.inlineSize}px`;
    this.apexChart.height = `${this.size.blockSize}px`;
    this.apexChart = { ...this.apexChart };
  }
}
