import { Component, Input } from '@angular/core';
import { ColorCode } from "../../../../../../common/toolbox/color";

@Component({
  selector: 'app-drag-chip',
  templateUrl: './drag-chip.component.html',
  styleUrls: ['./drag-chip.component.scss'],
  host: {
    '[class.borderless]': 'borderless'
  }
})
export class DragChipComponent {
  /** Color set on chip. */
  @Input() color?: ColorCode;
  /** True to not display chip border. */
  @Input() borderless?: boolean;
}