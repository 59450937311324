import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteMethod } from "../../../../../common/toolbox/api";
import { errorResponse } from "../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../common/toolbox/status";
import { Database } from '../toolbox/database';
import { FUSION_STORE_CLASS, FUSION_STORE_VERSION } from '../toolbox/fusion';
import { rawRequest } from '../toolbox/request';
import { LogService } from './log.service';

/** Default configuration if file could not be found. */
export class AppConfig {
  constructor(
    public API_URL = 'https://localhost:8443'
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  constructor(
    private log: LogService,
    private http: HttpClient
  ) { }

  /** Load up external configuration file at startup. */
  async load() {
    let config = new AppConfig();
    window.LOCATION_HOSTNAME = location.pathname;

    // Initialize database.
    let db = window.DB = new Database('fusion', FUSION_STORE_VERSION, FUSION_STORE_CLASS);
    let result = await db.init();
    if (errorResponse(result)) {
      this.log.show(result);
    }
    
    // Get configuration from file, fallback on default config.
    let response = await rawRequest<AppConfig>(this.http, RouteMethod.Get, 'config.json');
    if (errorResponse(response)) {
      this.log.show(`Failed to load app configuration: ${response.error}`, StatusLevel.Alert);
    } else {
      config = response;
    }

    Object.assign(window, config);
  }
}

/** Factory method to initialize app's environment at startup. */
export function initializeApp(bootstrap: BootstrapService) {
  return () => bootstrap.load();
}
