import { Box } from "../../../../../common/model/box";
import { Pos } from "../../../../../common/model/pos";

/** Get in-page offset of an element. */
export function boxOffset(e: HTMLElement) {
  let size = new Pos(e.offsetWidth, e.offsetHeight);
  let offset = new Pos(e.offsetLeft, e.offsetTop);

  // Keep recursively adding parent offset.
  while (e.offsetParent) {
    e = e.offsetParent as HTMLElement;
    offset.x += e.offsetLeft;
    offset.y += e.offsetTop;
  }

  return new Box(offset.x, offset.y, offset.x + size.x - 1, offset.y + size.y - 1);
}

/** Get onscreen box of an element. */
export function boxOnscreen(e: HTMLElement) {
  let rect = e.getBoundingClientRect();
  return new Box(rect.x | 0, rect.y | 0, rect.x + rect.width | 0, rect.y + rect.height | 0);
}