import { SchemaProperty } from "../model/schema";
import { ValidationStatus, ValidatorLog } from "./base";
import { NeverValidator } from "./never";

/** A validator for a specific explicit version. */
export class VersionValidator extends NeverValidator {

  constructor(
    /** Version number to emit in schema. */
    public version: number
  ) {
    super();
  }

  override schema() {
    return {
      bsonType: 'int',
      minimum: this.version,
      maximum: this.version
    } as SchemaProperty;
  }

  override validate(value: never) {
    return value === undefined ? ValidationStatus.Okay : ValidationStatus.Error;
  }

  override logs() { return [new ValidatorLog('Expected undefined.')]; }
}