import { NestedKey } from "./keys";
import { titleCase } from "./string";
import { dateDay } from "./time";

/** Get values of given object. */
export type ValueOf<T> = T[keyof T];

/** Extended types for object. */
export type ObjectType = 'boolean' | 'number' | 'bigint' | 'string' | 'symbol' | 'date' | 'array' | 'object' | 'function' | 'undefined';

/** Make all properties in type optional. Similar to Partial<T> but allows undefined. */
export type Optional<T> = { [P in keyof T]?: T[P] | undefined; };
/** Make some properties in type optional. */
export type Maybe<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
/** Make all properties in object optional except specified property. */
export type PickPartial<T, K extends keyof T> = Pick<T, K> & Partial<Exclude<T, K>>;
/** Make some properties in object required. */
export type PickRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
/** Make every property and nested property of object partial. */
export type PartialDeep<T> = { [P in keyof T]?: T[P] extends Function ? T[P] : PartialDeep<T[P]> };
/** Force all properties in type to be writable. */
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
/** Method of passing around constructor for object. */
export type Newable<T = Object> = { new(...args: any[]): T; };
/** Remove null and undefined from all keys of a type. */
export type NonNullableMap<T> = { [K in keyof T]?: NonNullable<T[K]> };
/** Get subtype of object where values are specified type. */
export type ObjectKeys<T, V> = keyof ObjectValues<T, V>;
/** Get subtype of object with only values of type. */
export type ObjectValues<T, V> = { [K in keyof T as T[K] extends V ? K : never]: T[K] };
/** Convert object to one where all keys are type. */
export type ObjectRemap<T, V, F = any> = { [K in keyof T]: T[K] extends F ? V : T[K] };

/** Result of performing a comparison. */
export enum Comparison {
  Equal,
  Unequal,
  Greater,
  Lesser
}

/** A key-value pair, like displayed in a dropdown. */
export class Pair<T = string> {
  constructor(
    public value: T,
    public view: any = titleCase(`${value}`)
  ) { }

  toString() {
    return `${this.value}=${this.view}`;
  }

  /** Convert from list of entries. */
  static from<T>(list: [T, string][]) {
    return list.map(([key, value]) => new Pair(key, value));
  }
}

/** Copy properties from source to target, ensuring B is a subtype. */
export function safeAssign<T extends Object>(target: T, ...sources: Partial<T>[]) {
  return Object.assign(target, ...sources);
}

/** Copy properties from multiple sources to target. */
export function safeAssignAll<T extends Object>(target: T, ...sources: Partial<T>[]) {
  for (let source of sources) Object.assign(target, source);
  return target;
}

/** Sort properties of object based on callback. */
export function objectSort<T extends Object>(object: T, compare: (a: T[keyof T], b: T[keyof T]) => number) {
  let entries = Object.entries(object);
  entries.sort((a, b) => compare(a[1], b[1]));
  return objectCompose(entries);
}

/** Deeply copy an object. */
export function deepCopy<T>(object: T, sortKeys?: boolean): T {
  switch (typeof object) {
  case 'object':
    if (object instanceof Date) {
      return new Date(object) as any;
    } else if (Array.isArray(object)) {
      let out = [];
      for (let i = 0; i < object.length; ++i) out[i] = deepCopy(object[i], sortKeys);
      return out as any;
    } else if (object instanceof Map) {
      return new Map([...object.entries()].map(([key, value]) => [key, deepCopy(value, sortKeys)])) as any;
    } else if (object instanceof Set) {
      return new Set(object) as any;
    } else if (object instanceof RegExp) {
      return object;
    } else if (object) {
      let out = Object.create(object as any);
      let keys = sortKeys ? Object.keys(object).sort() : Object.keys(object);
      for (let key of keys) out[key] = deepCopy((object as any)[key], sortKeys);
      return out;
    } return object;
  default:
    return object;
  }
}

/** Perform top-level shallow assignment of objects, preserving type information on target.  */
export function shallowAssign(target: any, source: any): any {
  if (objectType(source) !== 'object' || objectType(target) !== 'object') return target;

  for (let key of Object.keys(source)) {
    if (objectType(source[key]) === 'object' && objectType(target[key]) === 'object') {
      // Copy over object, preserving old prototype.
      let prototype = objectPrototypeSelect(target[key], source[key]);
      target[key] = source[key];
      Object.setPrototypeOf(target[key], prototype);
    } else {
      // Just copy over value.
      target[key] = source[key];
    }
  }

  return target;
}

/**
 *  Deeply assign properties of source to target.
 *  WARNING: This process intentionally skips deleting properties in target not present in source.
 */
export function deepAssign(target: any, source: any): any {
  if (objectType(source) !== 'object') return target;

  // Copy over object properties from source to target.
  for (let key of Object.keys(source)) {
    if (objectType(source[key]) === 'object' && objectType(target[key]) === 'object') {
      // Copy prototype if present.
      let prototype = objectPrototypeSelect(target[key], source[key]);
      Object.setPrototypeOf(target[key], prototype);

      // Deep assign object property in source to target.
      deepAssign(target[key], source[key]);
    } else {
      // Make copy of non-object property in source to target.
      target[key] = deepCopy(source[key]);
    }
  }

  return target;
}

/** Deeply assign many properties to an object. */
export function deepAssignAll(target: any, ...sources: any[]): any {
  for (let source of sources) {
    deepAssign(target, source);
  }

  return target;
}

/** Check if an object has no propeties. */
export function objectEmpty<T extends Object>(object: T) {
  return Object.keys(object).length === 0;
}

/** Create a map from the given list of properties. */
export function objectMap<T>(list: T[], key: ObjectKeys<T, number | string>): Record<number | string, T> {
  let out: any = {};
  for (let value of list) out[value[key]] = value;
  return out;
}

/** Get object minus given properties. */
export function objectOmit<T extends Object, K extends keyof T>(object: T, ...keys: K[]): Omit<T, K> {
  let omitted: Partial<T> = deepCopy(object);
  for (let key of keys) objectDelete(omitted, key);
  return omitted as Omit<T, K>;
}

/** Delete a key from object and mark as non-enumerable. */
export function objectDelete<T>(object: T, key: keyof T): T {
  if (!(object instanceof Object)) return object;
  Object.defineProperty(object, key, { value: undefined, enumerable: false });
  return object;
}

/** Delete multiple keys from object and mark as non-enumerable. */
export function objectDeleteAll<T>(object: T, ...keys: (keyof T)[]): T {
  for (let key of keys) objectDelete(object, key);
  return object;
}

/** Strip out top-level object properties that fail a callback. */
export function objectDeleteFilter<T>(object: T, callback: (key: string, value: any) => any) {
  let value: any = object;
  let keys = Object.keys(value).filter(key => !callback(key, value[key]));
  return objectDeleteAll(value, ...keys);
}

/** Perform shallow copy of top-level properties from one object to another, preserving object reference. */
export function objectReplace(target: any, source: any): any {
  for (let key in target) target[key] = undefined;
  for (let key in source) target[key] = source[key];
  return target;
}

/** Get list of object fields as type-asserted array of keys. */
export function objectKeys<T extends Object>(object: T): (keyof T)[] {
  return Object.keys(typeof object === 'function' ? new (object as any)() : object) as (keyof T)[];
}

/** Get type-asserted list of object values. */
export function objectValues<K extends string | number | symbol, V>(object: { [Key in K]?: V }): V[] {
  return Object.values(object) as V[];
}

/** Get list of object entries as type-asserted array of pairs. */
export function objectEntries<K extends string | number | symbol, V>(object: { [Key in K]?: V }): [K, V][] {
  return Object.entries(object) as [K, V][];
}

/** Strongly-typed version of Object.fromEntries. */
export function objectCompose<K extends number | string | symbol, V>(entries: [K, V][]): Record<K, V> {
  return Object.fromEntries(entries) as Record<K, V>;
}

/** Filter out empty numbers, strings and dates of object. */
export function objectTruthy<T>(object: T): T {
  let out: any = {};
  for (let key in object) {
    let value: any = object[key];
    switch (objectType(value)) {
      case 'array':
        if (value.length) out[key] = value;
        break;
      case 'string':
        if (value !== '') out[key] = value;
        break;
      case 'undefined':
        break;
      default:
        out[key] = value;
    }
  } return out;
}

/** Filter out undefined values of object. */
export function objectDefined<T>(object: T): T {
  let out: any = {};
  for (let key in object) {
    if (object[key] === undefined) continue;
    out[key] = object[key];
  } return out;
}

/** Get unique objects by a set of 2 properties. */
export function objectUnique<T>(objects: T[], key1: keyof T, key2: keyof T): T[] {
  let set = new Set<string>();
  let unique: T[] = [];

  for (let item of objects) {
    let key = `${item[key1]}_${item[key2]}`;
    if (set.has(key)) continue;

    set.add(key);
    unique.push(item);
  }

  return unique;
}

/** Get the normalized type of an object. */
export function objectType(object: any): ObjectType {
  switch (typeof object) {
    case 'object':
      if (object instanceof Date) {
        return 'date';
      } else if (Array.isArray(object)) {
        return 'array';
      } else if (object) {
        return 'object';
      } else {
        return 'undefined';
      }
    default:
      return typeof object;
  }
}

/** Recursively find value anywhere in object, as key or value. */
export function objectFind(object: any, value: any): boolean {
  if (object === value) return true;
  if (!(object instanceof Object)) return false;
  for (let key of Object.keys(object)) {
    if (key === value || objectFind(object[key], value)) return true;
  }

  return false;
}

/** Compare two values to evaluate a condition. */
export function objectCompare(a: any, b: any): Comparison {
  switch (objectType(a)) {
    case 'boolean':
      let bb = Boolean(b);
      if (a === false && bb === true) return Comparison.Lesser;
      else if (a === true && bb === false) return Comparison.Greater;
      else return Comparison.Equal;
    case 'number':
      let nb = Number(b);
      if (a < nb) return Comparison.Lesser;
      else if (a > nb) return Comparison.Greater;
      else if (a === nb) return Comparison.Equal;
      else return Comparison.Unequal;
    case 'string':
      let sb = String(b);
      switch (a.localeCompare(sb)) {
        case 1: return Comparison.Greater;
        case -1: return Comparison.Lesser;
        default: return Comparison.Equal;
      }
    case 'date':
      return objectCompare(dateDay(a), dateDay(b));
    case 'undefined':
      return b === undefined ? Comparison.Equal : Comparison.Unequal;
    default:
      return Comparison.Unequal;
  }
}

/** Perform deep equality check of two objects. */
export function objectEqual(a: any, b: any) {
  return objectCanon(a) === objectCanon(b);
}

/** Pad a value by specified amount. */
export function objectPad(value: any, length: any, fill: any) {
  length = isNaN(length) ? 0 : +length;
  switch (objectType(value)) {
    case 'number':
    case 'string':
      return length >= 0
        ? `${value}`.padStart(length, fill)
        : `${value}`.padEnd(length, fill);
    case 'array':
      return length >= 0
        ? Array(length).fill(fill).concat(value).slice(Math.min(-value.length, -length))
        : value.concat(Array(-length).fill(fill)).slice(0, Math.max(value.length, -length))
    default:
      return value;
  }
}

/** Merge a list of object properties into an object. */
export function objectMerge<K extends number | string, V = any>(props: [K, V][]): Record<K, V> {
  let out: any = {};
  for (let prop of props) out[prop[0]] = prop[1];
  return out;
}

/** Check if a given object is a newable. */
export function objectNewable<T, V>(value: Newable<T> | V): value is Newable<T> {
  return typeof value === 'function';
}

/** Get canonical representation of an object. */
export function objectCanon(object: any): string {
  switch (typeof object) {
    case 'boolean':
      return `${object}`;
    case 'number':
      return `${Math.floor(object)}`;
    case 'string':
      return `"${object}"`;
    case 'object':
      if (object instanceof Date) {
        return `"${object.toISOString()}"`;
      } else if (Array.isArray(object)) {
        return `[${object.map(objectCanon).sort().join(',')}]`;
      } else if (object instanceof Map) {
        return objectCanon(Object.fromEntries(object.entries()));
      } else if (object instanceof Set) {
        return objectCanon([...object]);
      } else if (object) {
        let entries = Object.entries(object).map(([key, value]) => [key, objectCanon(value)] as const);
        entries.sort((a, b) => a[0].localeCompare(b[0]));
        return `{${entries.map(([key, value]) => `"${key}":${value}`).join(',')}}`;
      } return 'null';
    default:
      return 'undefined';
  }
}

/** Return a deep copy of object with circular references removed.
 *  @see deepCopy for most of this logic.
*/
export function objectDecircular<T>(object: T): any {
  let seen = new Set();
  return objectDecircularDeep(object, seen);
}

/** Deeply drop circular references in an object.*/
function objectDecircularDeep(object: any, ancestors: Set<any>): any {
  switch (typeof object) {
    case 'object':
      if (ancestors.has(object)) return '[Circular]';
      ancestors.add(object);
      let out = object;

      if (object instanceof Date) {
        out = new Date(object);
      } else if (Array.isArray(object)) {
        out = [];
        for (let i = 0; i < object.length; ++i) out[i] = objectDecircularDeep(object[i], ancestors);
      } else if (object instanceof Map) {
        out = new Map([...object.entries()].map(([key, value]) => [key, objectDecircularDeep(value, ancestors)]));
      } else if (object instanceof Set) {
        out = new Set(object);
      } else if (object) {
        out = {};
        Object.setPrototypeOf(out, Object.getPrototypeOf(object));
        for (let key of Object.keys(object)) out[key] = objectDecircularDeep(object[key], ancestors);
      }

      ancestors.delete(object);
      return out;
    default:
      return object;
    }
}

/** Filter out an object to the specified set of keys. */
export function objectStrip<T>(object: T, whitelist: Set<NestedKey<T>>): PartialDeep<T> {
  return objectStripDeep(object, whitelist, []);
}

/** Recursively filter out an object to specified set of keys. */
function objectStripDeep(object: any, whitelist: Set<string>, path: string[]): any {
  switch (typeof object) {
  case 'number':
  case 'string':
  case 'boolean':
    return whitelist.has(path.join('.')) ? object : undefined;
  case 'object':
    if (object instanceof Date || object instanceof Map || object instanceof Set) {
      return object;
    } else if (Array.isArray(object)) {
      let out = [];
      for (let i = 0; i < object.length; ++i) {
        let value = objectStripDeep(object[i], whitelist, path);
        if (value !== undefined) {
          out[i] = value;
        }
      }

      if (out.length) return out;
    } else if (object) {
      let out: any = {};
      for (let key in object) {
        path.push(key);
        let value = objectStripDeep(object[key], whitelist, path);
        path.pop();

        if (value !== undefined) {
          out[key] = value;
        }
      }

      if (!objectEmpty(out)) return out;
    }
  }
}

/** Copy an object's prototype to another. */
export function objectPrototype<T extends Object = any>(target: T, source: T): T
export function objectPrototype<T extends Object = any>(target: undefined, source: T): any
export function objectPrototype<T extends Object = any>(target = {}, source: T) {
  Object.setPrototypeOf(target, Object.getPrototypeOf(source));
  return target;
}

/** Pick prototype between two objects with type information present. */
export function objectPrototypeSelect(a: Object, b: Object) {
  let prototype = Object.getPrototypeOf(a);
  if (prototype !== NULL_PROTOTYPE) return prototype;
  return Object.getPrototypeOf(b);
}

/** Prototype for objects without type information. */
const NULL_PROTOTYPE = Object.getPrototypeOf({});
