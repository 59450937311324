import { Component } from '@angular/core';
import { WORK_CONDITION_MODE_NAME, WorkConditionFormula } from '../../../../../../../../common/model/work/condition';
import { enumMapPairs } from '../../../../../../../../common/toolbox/enum';
import { WorkConditionAccessor } from '../setup-workflow-condition.model';

@Component({
  selector: 'app-setup-workflow-condition-formula',
  templateUrl: './setup-workflow-condition-formula.component.html',
  styleUrls: ['./setup-workflow-condition-formula.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupWorkflowConditionFormulaComponent extends WorkConditionAccessor<WorkConditionFormula> {

  /** List of modes to select. */
  modes = enumMapPairs(WORK_CONDITION_MODE_NAME);
}
