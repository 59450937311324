import { DisputeCreditStatus } from "../../../code/standard/disputes";
import { Box } from "../../box";
import { DisplayType } from "../../display";
import { Pos } from "../../pos";
import { PropertyType } from "../../property-type";
import { BuiltinForm } from "../form";
import { DisputeFormula } from "../formula/dispute";
import { DisputesModel, ModelDisputeIssueCredit } from "../model/disputes";
import { builtinFormField, builtinFormModel } from "./form";

/** Built-in dispute forms. */
export enum DisputeForm {
  ACH                 = 'Dispute - ACH',
  ACHPreview          = 'Dispute - ACH - Preview',
  Card                = 'Dispute - Card',
  CardPreview         = 'Dispute - Card - Preview',
  Check               = 'Dispute - Check',
  CheckPreview        = 'Dispute - Check - Preview',
  CreditBureau        = 'Dispute - Credit Bureau',
  CreditBureauPreview = 'Dispute - Credit Bureau - Preview',
  CreditStatus        = 'Dispute - Credit Status',
  IssueCredit         = 'Dispute - Issue Credit',
  Ledger              = 'Dispute - Ledger',
  ResolvedReason      = 'Dispute - Resolved Reason',
  PrearbitrationDate  = 'Dispute - Pre-Arbitration Date'
}

/** List of builtin forms. */
export const BUILTIN_DISPUTE_FORMS: Record<DisputeForm, BuiltinForm> = {
  [DisputeForm.ACH]: {
    fields: [
      builtinFormField('transaction.description'),
      builtinFormField('transaction.amount'),
      builtinFormField('transaction.effectiveDate')
    ],
    size: { x: 3, y: 1 }
  },
  [DisputeForm.ACHPreview]: {
    fields: []
  },
  [DisputeForm.Card]: {
    fields: [
      builtinFormField('dispute.investigationCompletedDate', { name: 'Investigation Completed', box: new Box(1, 0, 1, 0) }),
      builtinFormField('dispute.provisionalCreditDate', { name: 'Credit Date', box: new Box(1, 1, 1, 1) }),
      builtinFormField('dispute.provisionalCreditNoticeDate', { name: 'Notice of Provisional Credit', box: new Box(2, 1, 2, 1) }),
      builtinFormField('dispute.representmentDate', { name: 'Represented Date', box: new Box(1, 4, 1, 4) }),
      builtinFormField('dispute.recoveryDate', { box: new Box(1, 3, 1, 3) }),
      builtinFormField('dispute.chargebackDate', { box: new Box(1, 2, 1, 2) }),
      builtinFormField('dispute.writtenProvidedDate', { name: 'Written Notice Provided', box: new Box(2, 0, 2, 0) }),
      builtinFormField('dispute.investigationDueDate', { name: 'Due Date', box: new Box(0, 0, 0, 0) })
    ],
    size: new Pos(3, 5)
  },
  [DisputeForm.CardPreview]: {
    fields: [
      builtinFormField('transaction.description'),
      builtinFormField('transaction.amount'),
      builtinFormField('transaction.effectiveDate'),
      builtinFormField('dispute.creditStatus'),
      builtinFormField('dispute.status'),
    ],
    size: { x: 5, y: 1 }
  },
  [DisputeForm.Check]: {
    fields: []
  },
  [DisputeForm.CheckPreview]: {
    fields: [
      builtinFormField('transaction.description'),
      builtinFormField('transaction.amount'),
      builtinFormField('transaction.effectiveDate')
    ],
    size: { x: 3, y: 1 }
  },
  [DisputeForm.CreditBureau]: {
    fields: []
  },
  [DisputeForm.CreditBureauPreview]: {
    fields: []
  },
  [DisputeForm.CreditStatus]: {
    editable: [DisplayType.Dispute],
    fields: [
      builtinFormField('dispute.creditStatus', { config: { type: PropertyType.Code, filter: [DisputeCreditStatus.Final, DisputeCreditStatus.Reversed] } })
    ]
  },
  [DisputeForm.IssueCredit]: {
    model: DisputesModel.DisputeIssueCredit,
    editable: [DisplayType.Dispute, DisplayType.Model],
    formulas: [
      DisputeFormula.TransactionCreditTransactionAmountSet,
      DisputeFormula.ProvisionalCreditDateSet
    ],
    fields: [
      builtinFormModel<ModelDisputeIssueCredit>('feeCredit'),
      builtinFormModel<ModelDisputeIssueCredit>('interestCredit'),
      builtinFormModel<ModelDisputeIssueCredit>('transactionCredit'),
      builtinFormField('dispute.creditStatus', { name: 'Credit Type', config: { type: PropertyType.Code, filter: [DisputeCreditStatus.Provisional, DisputeCreditStatus.Final] } }),
      builtinFormField('dispute.provisionalCreditDate', { name: 'Credit Issue Date' })
    ]
  },
  [DisputeForm.Ledger]: {
    editable: [DisplayType.Dispute],
    fields: [
      builtinFormField('transaction.description'),
      builtinFormField('transaction.amount'),
      builtinFormField('transaction.effectiveDate'),
      builtinFormField('dispute.creditStatus')
    ]
  },
  [DisputeForm.ResolvedReason]: {
    size: new Pos(1, 2),
    editable: [DisplayType.Dispute],
    fields: [
      builtinFormField('dispute.resolvedReason', { name: 'How was this dispute resolved?', box: new Box(0, 0, 0, 1) })
    ]
  },
  [DisputeForm.PrearbitrationDate]: {
    editable: [DisplayType.Dispute],
    formulas: [
      DisputeFormula.PrearbitrationDateSet
    ],
    fields: [
      builtinFormField('dispute.secondChargebackDate', { name: 'Pre-arbitration Date',  })
    ]
  }
};
