import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from "rxjs";
import { BulkPostRequest, BulkPostResponse } from '../../../../../common/message/bulk';
import { ErrorResponse } from "../../../../../common/message/error";
import { PaginateResponse } from '../../../../../common/message/paginate';
import { RouteDeletePath, RouteDeleteRequest, RouteDeleteResponse, RouteGetPath, RouteGetRequest, RouteGetResponse, RouteMethod, RoutePatchPath, RoutePatchRequest, RoutePatchResponse, RoutePostPath, RoutePostRequest, RoutePostResponse } from "../../../../../common/toolbox/api";
import { arraySingle } from "../../../../../common/toolbox/array";
import { querystring } from "../../../../../common/toolbox/http";
import { errorResponse } from "../../../../../common/toolbox/message";
import { ObjectValues } from '../../../../../common/toolbox/object';
import { paginateSplit } from "../../../../../common/toolbox/paginate";
import { fileBlobJson } from './file';

/** List of routes to request that return array or paginate responses. */
export type RouteListPath = keyof ObjectValues<RouteGetResponse, any[] | PaginateResponse>;
/** List of routes to request that return paginate responses. */
export type RoutePaginatePath = keyof ObjectValues<RouteGetResponse, PaginateResponse>;

/** Get type of item in array or paginate response. */
export type ListType<T> = T extends Array<infer I> ? I : (T extends PaginateResponse<infer I> ? I : never);

/** Valid options to pass to request. */
export interface RouteOptions {
  /** True to pass credentials. */
  withCredentials?: boolean
  /** Response type. */
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'
}

/** Make a request to API with specified parameters. */
async function apiRequest<Req, Resp>(http: HttpClient, url: string, method: RouteMethod, request?: Req, options: RouteOptions = {}, querystring = ''): Promise<Resp | ErrorResponse> {
  let path = `${API_URL}/${url}${querystring}`;
  try {
    let response = await firstValueFrom(http.request(method, path, { body: request, withCredentials: true, ...options }));
    
    if (response instanceof Blob && response.type === 'application/json') {
      let json = JSON.parse(await response.text());
      if (errorResponse(json)) return json;
    }

    return response;
  } catch (e: any) {
    if (e?.error instanceof Blob) e.error = await fileBlobJson(e.error);
    if (errorResponse(e?.error)) return e.error;
    return new ErrorResponse(`Request to ${path} failed.`);
  }
}

/** Make a GET request to API, deducing request and response types from route name. */
export async function getRequest<P extends RouteGetPath>(http: HttpClient, url: P, request: RouteGetRequest[P], options: RouteOptions = {}): Promise<RouteGetResponse[P] | ErrorResponse> {
  return apiRequest(http, url, RouteMethod.Get, request, options, querystring(request));
}

/** Make a GET request to API, retrieving only a single item. */
export async function getOneRequest<P extends RouteListPath>(http: HttpClient, url: P, request: RouteGetRequest[P], options: RouteOptions = {}): Promise<ListType<RouteGetResponse[P]> | ErrorResponse> {
  let response = await getRequest(http, url, request, options);
  if (errorResponse(response)) return response;

  let [items] = paginateSplit<any>(response);
  if (arraySingle(items)) return items[0];
  return new ErrorResponse(`There was an error getting one item from ${url}.`);
}

/** Make a POST request to API, deducing request and response types from route name. */
export function postRequest<P extends RoutePostPath>(http: HttpClient, url: P, request: RoutePostRequest[P], options: RouteOptions = {}): Promise<RoutePostResponse[P] | ErrorResponse> {
  return apiRequest(http, url, RouteMethod.Post, request, options);
}

/** Make a PATCH request to API, deducing request and response types from route name. */
export function patchRequest<P extends RoutePatchPath>(http: HttpClient, url: P, request: RoutePatchRequest[P], options: RouteOptions = {}): Promise<RoutePatchResponse[P] | ErrorResponse> {
  return apiRequest(http, url, RouteMethod.Patch, request, options);
}

/** Make a DELETE request to API, deducing request and response types from route name. */
export function deleteRequest<P extends RouteDeletePath>(http: HttpClient, url: P, request: RouteDeleteRequest[P], options: RouteOptions = {}): Promise<RouteDeleteResponse[P] | ErrorResponse> {
  return apiRequest(http, url, RouteMethod.Delete, request, options);
}

/** Make a bulk request to API, fetching a reseponse for each request. */
export function bulkRequest<T extends BulkPostRequest>(http: HttpClient, ...requests: T): Promise<BulkPostResponse<T>> {
  return postRequest(http, 'bulk' as any, requests);
}

/** Make a raw HTTP request to a non-API location. */
export async function rawRequest<Resp>(http: HttpClient, method: RouteMethod, url: string, request: any = undefined, options: RouteOptions = {}): Promise<Resp | ErrorResponse> {
  try {
    return <Resp>await firstValueFrom(http.request(method, url, { body: request, ...options }));
  } catch (e) {
    return new ErrorResponse(`Request to ${url} failed.`);
  }
}