import { BuiltinMap } from "../../../toolbox/builtinmap";
import { blockCodes } from "../../../toolbox/formula/block";
import { NestedKey, NestedSubkey } from "../../../toolbox/keys";
import { DisplayValue } from "../../display";
import { SystemFormula } from "../formula";

/** Recursively determine codes used by builtin formula. */
export function builtinFormulaCodes(map: BuiltinMap, name: SystemFormula) {
  let builtin = map.formulas.builtin(name);
  return blockCodes(builtin.statements, {});
}

/** Type-safe wrapper for nested loop keys. */
export function builtinFormulaSubKey<K extends NestedKey<DisplayValue>>(_: K, subkey: NestedSubkey<NestedKey<DisplayValue>, K>): NestedKey<DisplayValue> {
  return subkey as unknown as any;
}

/** Type-safe wrapper for model keys. */
export function builtinFormulaModelKey<T>(key: NestedKey<T>): NestedKey<DisplayValue> {
  return `model.${key}` as NestedKey<DisplayValue>;
}