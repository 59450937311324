import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MemberJoin } from '../../../../../../common/model/member-join';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { deepCopy } from '../../../../../../common/toolbox/object';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { LogService } from '../../../common/service/log.service';
import { patchRequest } from '../../../common/toolbox/request';
import { MemberComponent } from '../member.component';
import { MemberSetupData } from './member-setup.model';

@Component({
  selector: 'app-member-setup',
  templateUrl: './member-setup.component.html',
  styleUrls: ['./member-setup.component.scss'],
  host: {
    class: 'column'
  }
})
export class MemberSetupComponent {

  /** Member being configured. */
  member: MemberJoin;

  /** True if changes have been made to addresses. */
  addressesDirty = false;
  /** True if changes have been made to emails. */
  emailsDirty = false;
  /** True if changes have been made to phones. */
  phonesDirty = false;

  static title(data: MemberSetupData) {
    return `Setup - ${MemberComponent.title(data)}`;
  }

  constructor(
    @Inject(TAB_DATA)
    public data: MemberSetupData,
    private log: LogService,
    private http: HttpClient
  ) {
    this.member = deepCopy(data.member);
  }

  ngOnDestroy() {
    this.data.save?.complete();
  }

  /** Callback when saving changes. */
  async onSave() {
    let response = await patchRequest(this.http, 'members', {
      _id: this.member._id,
      _inst: this.member._inst,
      addresses: this.addressesDirty ? this.member.addresses : undefined,
      emails: this.emailsDirty ? this.member.emails : undefined,
      phones: this.phonesDirty ? this.member.phones : undefined
    });

    if (errorResponse(response)) {
      this.log.show(response);
      return;
    }

    this.addressesDirty = this.emailsDirty = this.phonesDirty = false;
    this.data.save?.next();
  }
}
