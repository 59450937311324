import { objectDefined } from "../toolbox/object";
import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";

/** Validate object is number. */
export class NumberValidator extends Validator<number> {

  constructor(public min?: number,public max?: number) {
    super();
  }

  value() {
    return 0;
  }

  parse(value: string) {
    if (this.undefined(value)) return undefined;

    let number = +value;
    return isNaN(number) ? undefined : number;
  }

  schema() {
    return this.property ?? objectDefined({
      bsonType: 'int',
      minimum: this.min,
      maximum: this.max
    });
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    return typeof value === 'number' ? ValidationStatus.Okay : ValidationStatus.Error;
  }

  override logs() {return [new ValidatorLog(`Expected number in range ${this.min ?? '-Inf'} ~ ${this.max ?? '+Inf'}.`)]; }
}