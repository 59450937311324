import { Permission } from "./permission";

/** An aggregate grouping of multiple permissions. */
export enum PermissionGroup {
  ClaimIntake
}

/** List of subpermissions under each group. */
export const PERMISSION_GROUP: Record<PermissionGroup, Permission[]> = {
  [PermissionGroup.ClaimIntake]: [Permission.ClaimsACHIntake, Permission.ClaimsCardIntake, Permission.ClaimsCheckIntake, Permission.ClaimsCreditBureauIntake]
}

/** Resolve list of permissions from a value. */
export function permissionGroupArray(permissions: PermissionGroup | Permission[]): Permission[]
export function permissionGroupArray(permissions?: PermissionGroup | Permission[]): Permission[] | undefined
export function permissionGroupArray(permissions?: PermissionGroup | Permission[]): Permission[] | undefined {
  if (!permissions) return undefined;
  return Array.isArray(permissions) ? permissions : PERMISSION_GROUP[permissions];
}