import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRule, FilterRulePreview } from "../../../../../common/model/filter-rule";
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { LogService } from './log.service';
import { TabService } from './tab.service';

/** A query to fetch a specific formula. */
export class FilterRuleQuery {
  constructor(
    /** ID of formula. */
    public _id = ID_DEFAULT,
    /** Institution of formula. */
    public _inst = ID_DEFAULT
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class FilterRuleService extends CachePreviewService<FilterRule, FilterRuleQuery, FilterRulePreview> {
  readonly route = 'filter-rules/preview';
  readonly Type = FilterRule;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    public tabs: TabService
  ) {
    super(FilterRuleQuery, log, dialog, http);
  }

  protected override multiple(queries: ArraySome<FilterRuleQuery>) {
    return getRequest(this.http, 'filter-rules', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}