import { EnumMap } from "../toolbox/enum";
import { HasName } from "../toolbox/id";

/** Possible statuses for a step. */
export enum StepStatus {
  Ok,
  Loading,
  Warning,
  Alert
}

/** Information about a task in a stepper. */
export class Step<T extends HasName = HasName> {
  constructor(
    /** Additional data attached to step. */
    public data = undefined as unknown as T,
    /** True if step is inaccessible. */
    public locked?: boolean,
    /** Current status of step. */
    public status?: StepStatus
  ) {}
}

/** Name of each step status. */
export const STEP_STATUS_NAME: EnumMap<StepStatus> = {
  [StepStatus.Ok]: 'OK',
  [StepStatus.Loading]: 'Loading',
  [StepStatus.Warning]: 'Warning',
  [StepStatus.Alert]: 'Alert'
}