import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorResponse } from '../../../../../common/message/error';
import { CollectionRevisions } from '../../../../../common/model/collection';
import { FusionCollection } from '../../../../../common/model/fusion';
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { errorPartition, errorResponse } from '../../../../../common/toolbox/message';
import { DialogService } from '../component/dialog/dialog.service';
import { CacheService } from '../toolbox/cache-service';
import { getRequest } from '../toolbox/request';
import { LogService } from './log.service';

/** A query to fetch a latest revision */
export class RevisionQuery {
  constructor(
    /** ID of record. */
    public _id = ID_DEFAULT,
    /** Institution of record. */
    public _inst = ID_DEFAULT,
    /** Collection of record. */
    public collection: CollectionRevisions<FusionCollection>
  ) {}
}

/** A fetched revision ID. */
export class RevisionName {
  constructor(
    /** ID of revision. */
    public _id = ID_DEFAULT,
    /** Added to satisfy type-checker. */
    public name = ''
  ) {}
}

/** Caches information about revisions. */
@Injectable({
  providedIn: 'root'
})
export class RevisionService extends CacheService<RevisionName, RevisionQuery> {
  readonly route = 'revisions';
  readonly Type = RevisionName;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient
  ) {
    super(RevisionQuery, log, dialog, http);
  }

  /** Create a document -> revision mapping. */
  override async map(queries: RevisionQuery[]): Promise<Map<string, RevisionName>> {
    return new Map((await this.items(queries)).map((value, i) => [queries[i]!._id, value]));
  }

  protected override async multiple(queries: ArraySome<RevisionQuery>) {
    let { _inst, collection } = queries[0];
    let response = await getRequest(this.http, 'revisions', { _inst, collection, _ids: queries.map(q => q._id) });
    if (errorResponse(response)) return response;

    let map = response;
    let [error, revisions] = errorPartition(`Failed to find latest revisions in collection: ${collection}`, queries.map(query => {
      let _id = map[query._id];
      return _id ? new RevisionName(_id) : new ErrorResponse(`Unknown ID: ${_id}`);
    }));
    return error ?? revisions;
  }
}
