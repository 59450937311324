import { AttachmentType } from "../../../code/standard/common";
import { CommonCode } from "../../../code/system";
import { CollectionInfo } from "../../../info/collection";
import { PropertyInfo } from "../../../info/prop";
import { ID_DEFAULT } from "../../../toolbox/id";
import { FusionCollection } from "../../fusion";
import { PropertyType } from "../../property-type";

/** Result of adding or removing attachments from a system resource. */
export class AttachmentResult {
  constructor(
    /** Resource that was modified. */
    public _id = ID_DEFAULT,
    /** ID of attachment. */
    public _attachment = ID_DEFAULT,
    /** Type of attachment. */
    public attachmentType: string = AttachmentType.ClaimChargeback
  ) { }

  static propinfo: PropertyInfo<AttachmentResult> = {
    attachmentType: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  }

  static collectioninfo: CollectionInfo<FusionCollection, AttachmentResult> = {
    _id: 'documentTemplates',
    _attachment: 'attachments'
  }
}