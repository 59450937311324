import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { BuildImportComponent } from "./import/build-import.component";
import { BuildWorkQueuesComponent } from "./work-queues/build-work-queues.component";

@NgModule({
  declarations: [
    BuildImportComponent,
    BuildWorkQueuesComponent
  ],
  imports: [
    SharedModule
  ],
  providers: []
})
export class BuildModule { }
