import { Observable, Subject } from "rxjs";
import { DisplayPartial, DisplayValue } from "../../../../../../common/model/display";
import { Form } from "../../../../../../common/model/form/form";
import { FormList } from "../../../../../../common/model/form/list";
import { Member } from "../../../../../../common/model/member";
import { Transaction } from "../../../../../../common/model/transaction";
import { MaybeId } from "../../../../../../common/toolbox/id";
import { NestedKey } from "../../../../../../common/toolbox/keys";

/** Base configuration shared for forms and form lists. */
export interface FormBaseConfig {
  /** Values to make available in context. */
  value?: DisplayPartial
  /** Transactions available in current context. */
  transactions?: Transaction[]
  /** List of members available in current context. */
  members?: Member[]
  /** Emits when changes should be reflected in form. */
  changes?: Subject<FormChange>
  /** True to make all fields optional, eg. a search form. */
  optional?: boolean
  /** True to mutate input. */
  mutate?: boolean
  /** True to display labels. */
  labels?: boolean
  /** True to display inputs. */
  inputs?: boolean
}

/** Configuration for displaying a form.  */
export interface FormConfig extends FormBaseConfig {
  /** Configuration for displaying this form. */
  form: MaybeId<Form>
}

/** Configuration for displaying a list of forms.  */
export interface FormListConfig extends FormBaseConfig {
  /** Group shown in this list. */
  list: MaybeId<FormList>;
  /** Emits when changes have been made to form value and should be redisplayed. */
  dirty?: Observable<NestedKey<DisplayValue>[]>;
}

/** Current status for model form. */
export class FormStatus {
  constructor(
    /** True if model was reopened, existing data is being edited. */
    public reopened = false,
    /** True if all form fields are optional, eg. a search form. */
    public optional = false,
    /** True if input should be mutated. */
    public mutate = false,
    /** True if labels should be displayed. */
    public labels = true,
    /** True if inputs should be displayed. */
    public inputs = true
  ) {}
}

/** Emits whenever changes happen within form. */
export class FormChange {
  constructor(
    /** Key that changed within form. */
    public key = '' as NestedKey<DisplayValue>,
    /** New value. */
    public value: any
  ) {}
}
