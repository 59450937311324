import { UnionInfo } from "../../info/union";
import { UnionValidator } from "../../validator/union";
import { EventMap } from "../event";
import { EventCategory, EventCategoryClass, EventCategoryResultType } from "./category";
import { ACCOUNT_RESULT_UNIONINFO, AccountResultType } from "./result/account";
import { AUTH_RESULT_UNIONINFO, AuthResultType } from "./result/auth";
import { CLAIM_RESULT_UNIONINFO, ClaimResultType } from "./result/claim";
import { DOCUMENT_TEMPLATE_RESULT_UNIONINFO, DocumentTemplateResultType } from "./result/document-template";
import { IMPORT_RESULT_UNIONINFO, ImportResultType } from "./result/import";
import { PERSONAL_RESULT_UNIONINFO, PersonalResultType } from "./result/personal";
import { TODO_RESULT_UNIONINFO } from "./result/todo";
import { TRIGGER_RESULT_UNIONINFO, TriggerResultType } from "./result/trigger";

/** Union of all event results. */
export type EventResult = { [C in EventCategory]: { [R in keyof EventCategoryClass[C]]: EventCategoryClass[C][R] }[keyof EventCategoryClass[C]] }[EventCategory];
/** Union of all event result types. */
export type EventResultType = EventCategoryResultType[EventCategory];

/** Union type information for event results. */
export class EventResultClass {
  [EventCategory.Account]           = new UnionValidator(ACCOUNT_RESULT_UNIONINFO);
  [EventCategory.Authentication]    = new UnionValidator(AUTH_RESULT_UNIONINFO);
  [EventCategory.Claim]             = new UnionValidator(CLAIM_RESULT_UNIONINFO);
  [EventCategory.CodeType]          = new UnionValidator(TODO_RESULT_UNIONINFO);
  [EventCategory.DocumentTemplate]  = new UnionValidator(DOCUMENT_TEMPLATE_RESULT_UNIONINFO);
  [EventCategory.Import]            = new UnionValidator(IMPORT_RESULT_UNIONINFO);
  [EventCategory.Institution]       = new UnionValidator(TODO_RESULT_UNIONINFO);
  [EventCategory.Job]               = new UnionValidator(TODO_RESULT_UNIONINFO);
  [EventCategory.Organization]      = new UnionValidator(TODO_RESULT_UNIONINFO);
  [EventCategory.Personal]          = new UnionValidator(PERSONAL_RESULT_UNIONINFO);
  [EventCategory.System]            = new UnionValidator(TODO_RESULT_UNIONINFO);
  [EventCategory.User]              = new UnionValidator(TODO_RESULT_UNIONINFO);
  [EventCategory.WorkQueue]         = new UnionValidator(TODO_RESULT_UNIONINFO);
  [EventCategory.Trigger]           = new UnionValidator(TRIGGER_RESULT_UNIONINFO);
}

/** Default title for each event type. */
export const EVENT_RESULT_NAME: EventMap<string> = {
  [EventCategory.System]: {},
  [EventCategory.Authentication]: {
    [AuthResultType.LoginSuccess]: 'Login Success',
    [AuthResultType.Logout]: 'Logout',
  },
  [EventCategory.User]: {},
  [EventCategory.Job]: {},
  [EventCategory.Organization]: {},
  [EventCategory.Institution]: {},
  [EventCategory.CodeType]: {},
  [EventCategory.Import]: {
    [ImportResultType.Update]: 'Import Complete'
  },
  [EventCategory.Account]: {
    [AccountResultType.Import]: 'Import',
    [AccountResultType.Form]: 'Form'
  },
  [EventCategory.Personal]: {
    [PersonalResultType.Reminder]: 'Reminder'
  },
  [EventCategory.Claim]: {
    [ClaimResultType.ClaimAdd]: 'Claim Added',
    [ClaimResultType.ClaimAttachmentAdd]: 'Claim Attachment Added',
    [ClaimResultType.ClaimAttachmentDelete]: 'Claim Attachment Deleted',
    [ClaimResultType.ClaimComments]: 'Claim Comment',
    [ClaimResultType.ClaimDelete]: 'Claim Deleted',
    [ClaimResultType.ClaimForm]: 'Claim Form',
    [ClaimResultType.ClaimIntakeReview]: 'Claim Review',
    [ClaimResultType.ClaimDocumentTemplate]: 'Claim Document Template',
    [ClaimResultType.ClaimModify]: 'Claim Modified',
    [ClaimResultType.ClaimResolve]: 'Claim Resolved',
    [ClaimResultType.ClaimReview]: 'Claim Review',
    [ClaimResultType.ClaimView]: 'Claim Viewed',
    [ClaimResultType.DisputeAdd]: 'Dispute Added',
    [ClaimResultType.DisputeModify]: 'Dispute Modified',
    [ClaimResultType.DisputeWithdraw]: 'Dispute Withdrawn',
    [ClaimResultType.Unused1]: 'Unused 1',
    [ClaimResultType.Unused2]: 'Claim Chargeback Review',
    [ClaimResultType.Unused3]: 'Claim Representment Review',
    [ClaimResultType.Unused4]: 'Claim Arbitration Review',
    [ClaimResultType.Unused5]: 'Claim Pre-Arbitration Review',
    [ClaimResultType.Unused6]: 'Claim Return Date Updated',
    [ClaimResultType.Unused7]: 'Dispute Issued Credit'
  },
  [EventCategory.WorkQueue]: {},
  [EventCategory.DocumentTemplate]: {
    [DocumentTemplateResultType.DocumentTemplateAttachmentAdd]: 'Document Template Attachment Added',
    [DocumentTemplateResultType.DocumentTemplateAttachmentDelete]: 'Document Template Attachment Deleted'
  },
  [EventCategory.Trigger]: {
    [TriggerResultType.TriggerRun]: 'Triggers Have Run'
  }
};

/** Default format string for each event category / type. */
export const EVENT_RESULT_DESCRIPTION: EventMap<string> = {
  [EventCategory.System]: {},
  [EventCategory.Authentication]: {
    [AuthResultType.LoginSuccess]: '{{_user | user}} logged in.',
    [AuthResultType.Logout]: '{{_user | user}} logged out.'
  },
  [EventCategory.User]: {},
  [EventCategory.Job]: {},
  [EventCategory.Organization]: {},
  [EventCategory.Institution]: {},
  [EventCategory.CodeType]: {},
  [EventCategory.Import]: {
    [ImportResultType.Update]: 'Performed an import.'
  },
  [EventCategory.Account]: {
    [AccountResultType.Form]: '{{_user | user}} completed: {{result._task | task}}.',
    [AccountResultType.Import]: 'Updated account after import.'
  },
  [EventCategory.WorkQueue]: {},
  [EventCategory.Personal]: {
    [PersonalResultType.Reminder]: 'Created a personal reminder.'
  },
  [EventCategory.Claim]: {
    [ClaimResultType.ClaimAdd]: '{{_user | user}} created a new claim.',
    [ClaimResultType.ClaimAttachmentAdd]: "{{_user | user}} attached: {{result.attachmentType | code:'attachmentType'}}.",
    [ClaimResultType.ClaimAttachmentDelete]: "{{_user | user}} deleted: {{result.attachmentType | code:'attachmentType'}}.",
    [ClaimResultType.ClaimComments]: "{{_user | user}} updated a claim's comments.",
    [ClaimResultType.ClaimDelete]: "{{_user | user}} {{result.restore | boolean:'restored':'deleted'}} the claim.",
    [ClaimResultType.ClaimDocumentTemplate]: '{{_user | user}} generated: {{result._attachment | attachment}}',
    [ClaimResultType.ClaimForm]: '{{_user | user}} completed: {{result._task | task}}',
    [ClaimResultType.ClaimIntakeReview]: '{{_user | user}} reviewed the claim.',
    [ClaimResultType.ClaimModify]: '{{_user | user}} modified the claim.',
    [ClaimResultType.ClaimResolve]: '{{_user | user}} resolved the claim.',
    [ClaimResultType.ClaimReview]: "{{_user | user}} {{result.approved | boolean:'approved':'denied'}} the claim: {{result._task | task}}",
    [ClaimResultType.ClaimView]: '{{_user | user}} viewed the claim.',
    [ClaimResultType.DisputeAdd]: '{{_user | user}} created a new dispute.',
    [ClaimResultType.DisputeModify]: '{{_user | user}} modified a dispute.',
    [ClaimResultType.DisputeWithdraw]: '{{_user | user}} withdrew the dispute.',
    [ClaimResultType.Unused1]: '',
    [ClaimResultType.Unused2]: "{{_user | user}} reviewed the chargeback. It was {{result.approved | boolean:'approved':'denied'}}.",
    [ClaimResultType.Unused3]: "{{_user | user}} reviewed the representment. It was {{result.approved | boolean:'approved':'denied'}}.",
    [ClaimResultType.Unused4]: '{{_user | user}} reviewed the arbitration.',
    [ClaimResultType.Unused5]: '{{_user | user}} reviewed the pre-arbitration.',
    [ClaimResultType.Unused6]: '{{_user | user}} updated date.',
    [ClaimResultType.Unused7]: '{{_user | user}} issued credit for dispute.'
  },
  [EventCategory.DocumentTemplate]: {
    [DocumentTemplateResultType.DocumentTemplateAttachmentAdd]: "{{_user | user}} attached: {{result.attachmentType | code:'attachmentType'}}.",
    [DocumentTemplateResultType.DocumentTemplateAttachmentDelete]: "{{_user | user}} deleted: {{result.attachmentType | code:'attachmentType'}}."
  },
  [EventCategory.Trigger]: {
    [TriggerResultType.TriggerRun]: "{{_user | user}} ran triggers."//the following triggers: {{ }}"// TODO: improve this to include list of trigger ids
  }
};

/** Doubly-nested tagged type information for event results. */
export const EVENT_RESULT_UNIONINFO: UnionInfo<EventResult, EventCategory> = {
  tag: 'category',
  classes: new EventResultClass()
}
