import { Component, Input } from '@angular/core';
import { TabService } from '../../../../common/service/tab.service';
import { Stat, StatHistory, StatTotal, StatType, STAT_TYPES, STAT_TYPE_NAME, userStats } from '../../../../common/toolbox/stat';
import { CollectionsSupervisorHistoryComponent } from '../history/collections-supervisor-history.component';
import { CollectionsSupervisorHistoryData } from '../history/collections-supervisor-history.model';
import { ClientSource } from '../../../../common/toolbox/source/client';

@Component({
  selector: 'app-collections-supervisor-summary',
  templateUrl: './collections-supervisor-summary.component.html',
  styleUrls: ['./collections-supervisor-summary.component.scss']
})
export class CollectionsSupervisorSummaryComponent {
  /** Mapping of stat values to names. */
  readonly STAT_TYPE_NAME = STAT_TYPE_NAME;

  /** Date statistics are being displayed for. */
  @Input() date = new Date();
  /** Range of hours to display. */
  @Input() hours: [number, number] = [0, 0];
  /** List of history entries. */
  @Input() history: StatHistory[] = [];
  
  /** Date range of events to display. */
  @Input() set totals(totals: StatTotal[]) {
    this.items = totals;
    this.source.items = totals;
    this.refresh();
  }

  /** List of stats to display. */
  @Input() set types(types: StatType[]) {
    this.display = types;
    this.columns = ['name', ...types];
    this.refresh();
  }

  constructor(
    private tabs: TabService
  ) {}

  /** List of stat totals. */
  items: StatTotal[] = [];
  /** Calculated total for each stat. */
  footer = userStats(new Stat());
  /** Columns to display in stats table. */
  columns: (keyof StatTotal)[] = ['name'];
  /** Data displayed in stats table. */
  source = new ClientSource<StatTotal>();
  /** Types to show in stats table. */
  display: StatType[] = [];

  /** Callback when clicking one of stat rows. */
  onStats(stats: StatTotal) {
    let history = this.history.find(h => h.id === stats.id)!;
    this.tabs.open(CollectionsSupervisorHistoryComponent, new CollectionsSupervisorHistoryData(history, STAT_TYPES, this.date, this.hours));
  }

  /** Recalculate total after updating parameters. */
  private refresh() {
    this.footer = userStats(new Stat());
    for (let item of this.items) {
      for (let type of this.display) {
        this.footer[type].actual += item[type].actual;
        this.footer[type].goal += item[type].goal;
      }
    }
  }
}
