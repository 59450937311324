import { Component } from '@angular/core';
import { ID_DEFAULT } from "../../../../../../../common/toolbox/id";
import { NestedKey } from '../../../../../../../common/toolbox/keys';
import { PROPERTY_TYPE_ICON } from '../../../toolbox/property';
import { FormMultiAccessor } from '../form-multi-accessor';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent<T> extends FormMultiAccessor<T> {
  readonly PROPERTY_TYPE_ICON = PROPERTY_TYPE_ICON;
  readonly ID_DEFAULT = ID_DEFAULT;

  set items(items: T[]) { this.list = items; this.writeValue(this.value.map); }
  get items() { return this.list; }

  key: NestedKey<T> = '' as any;
  display = (_: T) => '';

  /** List of available items. */
  list: T[] = [];
}
