import { Attachment } from "../attachment";
import { ClaimJoin } from "../claim/claim";
import { Dispute } from "../dispute/dispute";

/** A context for work engine to execute. */
export class WorkContext {
  constructor(
    /** Current viewed claim. */
    public claim?: ClaimJoin,
    /** Main viewed dispute. */
    public dispute?: Dispute,
    /** Current viewed attachments */
    public attachments?: Attachment[]
  ) {}
}
