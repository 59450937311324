import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TriggerPostResponse } from '../../../../../../../common/message/trigger';
import { DISPLAY_TYPE_NAME } from "../../../../../../../common/model/display";
import { Trigger } from '../../../../../../../common/model/trigger';
import { TRIGGER_TYPE_NAME, TriggerConfigClass, TriggerType } from '../../../../../../../common/model/trigger-config';
import { enumValues } from '../../../../../../../common/toolbox/enum';
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { DevService } from '../../../../common/service/dev.service';
import { LogService } from '../../../../common/service/log.service';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';
import { SetupTriggerConfigEmptyComponent } from '../config/empty/setup-trigger-config-empty.component';
import { SetupTriggerConfigFormulaRunComponent } from '../config/formula-run/setup-trigger-config-formula-run.component';
import { SetupTriggerConfigLedgerAddComponent } from '../config/ledger-add/setup-trigger-config-ledger-add.component';
import { TriggerAccessor } from './setup-trigger-edit.model';

/** Component for configuring each trigger type. */
export const TRIGGER_SETUP_COMPONENT = {
  [TriggerType.ClaimSave]: SetupTriggerConfigEmptyComponent,
  [TriggerType.DisputeSave]: SetupTriggerConfigEmptyComponent,
  [TriggerType.LedgerAdd]: SetupTriggerConfigLedgerAddComponent,
  [TriggerType.TransactionPost]: SetupTriggerConfigEmptyComponent,
  [TriggerType.FormulaRun]: SetupTriggerConfigFormulaRunComponent,
};

@Component({
  selector: 'app-setup-trigger-edit',
  templateUrl: './setup-trigger-edit.component.html',
  styleUrls: ['./setup-trigger-edit.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupTriggerEditComponent extends SetupEditComponent<Trigger, TriggerPostResponse> {
  readonly DISPLAY_TYPE_NAME = DISPLAY_TYPE_NAME;
  readonly TRIGGER_TYPE_NAME = TRIGGER_TYPE_NAME;
  readonly SECTION_FORMULA_HELP = 'Formula for this trigger to be run.';

  /** Container to inject components. */
  @ViewChild('container', { static: true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** List of task types to pick from. */
  types: TriggerType[] = enumValues<TriggerType>(TriggerType);

  /** Current trigger being edited. */
  value = idMaybe(new Trigger());

  /** Component injected into template. */
  component?: TriggerAccessor;

  constructor(
    elementRef: ElementRef,
    log: LogService,
    public dev: DevService,
    private http: HttpClient
  ) {
    super(elementRef, log);
  }

  /** Submit current changes to trigger. */
  push() {
    return postRequest(this.http, 'triggers', { items: [this.value] });
  }

  /** Reset current form with new trigger. */
  async reset(value: MaybeId<Trigger>) {
    this.value = value;
    this.reconfig();
  }

  /** Callback when changing type of task. */
  onType(type: TriggerType) {
    this.value.config = new TriggerConfigClass()[type];
    this.reconfig();
  }

  /** Update configuration form for task config. */
  private reconfig() {
    this.containerRef.clear();
    this.component = undefined;

    let proto = TRIGGER_SETUP_COMPONENT[this.value.config.type];
    this.component = this.containerRef.createComponent(proto).instance;
    this.component.config = this.value.config;
    this.component.reset();
  }
}
