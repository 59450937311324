import { TypeValidator } from "../info/type";
import { ArraySome, arrayDefined } from "../toolbox/array";
import { arrayMap } from "../toolbox/enum";
import { validatorValue } from "../toolbox/validate";
import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";
import { MultiValidator } from "./multi";

/** Validate one of a list of values. */
export class OneOfValidator<T> extends MultiValidator<T> {
  /** List of validators. */
  validators: ArraySome<Validator<T>>;

  constructor(value: TypeValidator<T>, ...values: TypeValidator<T>[]) {
    super();
    let list: ArraySome<TypeValidator<T>> = [value, ...values];
    this.validators = arrayMap(list, value => value instanceof Validator ? value : validatorValue(value));
  }

  value() {
    return this.validators[0].value();
  }

  parse(value: string) {
    return this.validators[0].parse(value);
  }

  override schema() {
    return {
      oneOf: arrayDefined(this.validators.map(validator => validator.schema()))
    }
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    this.list = [];

    // Check if object valid against any subvalidator.
    let okay = this.validators.some(validator => validator.validate(value, options) === ValidationStatus.Okay);
    if (okay) return ValidationStatus.Okay;

    // Return all suberrors.
    let log = new ValidatorLog(`Expected one of possible objects.`, undefined, []);
    this.list.push(log);
    for (let validator of this.validators) {
      for (let sublog of validator.logs()) log.logs!.push(sublog);
    }
    
    return ValidationStatus.Error;
  }
}