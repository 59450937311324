import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { DisplayPartial, DisplayType, DisplayValue } from "../../../../../../common/model/display";
import { ApplicationSettingsKey } from "../../../../../../common/model/setting-group";
import { RouteGetResponse } from "../../../../../../common/toolbox/api";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { paginateSplit } from "../../../../../../common/toolbox/paginate";
import { GridConfig } from "../../component/model/grid/model-grid.model";
import { LogService } from "../../service/log.service";
import { TableService } from "../../service/table.service";
import { RoutePaginatePath, getRequest } from "../request";
import { gridPaginateRequest } from "../source/grid";
import { ServerSource } from "../source/server";
import { ResultsData } from "./results.model";
import { hydrateObject } from "../../../../../../common/toolbox/hydrate";

/** A component for viewing list of results in a table tab. */
@Component({ template: '' })
export abstract class ResultsComponent<R extends RoutePaginatePath> {

  /** Route to contact to fetch new items. */
  abstract readonly route: R;
  /** Table to use to display items. */
  abstract readonly table: ApplicationSettingsKey;
  /** Display type to pull filter fields from. */
  abstract readonly type: DisplayType;

  /** Default title for results. */
  static title() {
    return 'Results';
  }

  /** Configuration for table */
  config?: GridConfig;
  /** True if currently loading results. */
  loading = false;

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();
  
  constructor(
    private data: ResultsData<R>,
    private http: HttpClient,
    private log: LogService,
    private tables: TableService
  ) {}

  async ngOnInit() {
    let table = await this.tables.setting(this.table);
    this.config = new GridConfig(new ServerSource(), table);
    this.config.source.dataRequest.pipe(takeUntil(this.destroy)).subscribe(() => this.refresh());
    this.relist(this.data.response);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when opening specified item. */
  abstract onRow(partial: DisplayPartial): Promise<any>

  /** Pull down new list of items. */
  async refresh() {
    if (!this.config) return;

    this.loading = true;
    let response = await getRequest(this.http, this.route, {
      ...gridPaginateRequest(this.type, this.config.source),
      ...this.data.request
    });

    this.loading = false;
    if (errorResponse(response)) {
      this.log.show(response);
      return;
    }
    
    this.relist(response);
  }

  /** Set new list of displayed items. */
  async relist(response: RouteGetResponse[R]) {
    if (!this.config) return;

    let [items] = paginateSplit(response);
    this.config.source.available = response.available;
    this.config.source.items = items.map<DisplayPartial>(item => {
      return this.toPartial(item);
    });
  }

  toPartial(item:unknown): DisplayPartial {
    let partial: DisplayPartial = { [this.type]: item };
    return hydrateObject(partial, new DisplayValue())
  }
}