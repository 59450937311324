import { Component } from '@angular/core';
import { PropertyType } from '../../../../../../../../common/model/property-type';
import { FormFieldAccessor } from '../setup-form-field.model';

@Component({
  selector: 'app-setup-form-field-empty',
  templateUrl: './setup-form-field-empty.component.html',
  styleUrls: ['./setup-form-field-empty.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupFormFieldEmptyComponent<T extends PropertyType> extends FormFieldAccessor<T> {}
