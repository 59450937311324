import { CdkDragEnd } from "@angular/cdk/drag-drop";
import { ComponentRef, Injector, ViewContainerRef } from "@angular/core";
import { AccountCategory } from "../../../../../../common/code/standard/common";
import { CommonCode } from "../../../../../../common/code/system";
import { BLOCK_PLACEHOLDER, BLOCK_TYPE, Block, BlockType, BlockUnion } from "../../../../../../common/model/formula/block";
import { ConditionType } from "../../../../../../common/model/formula/condition";
import { OperatorType } from "../../../../../../common/model/formula/operator";
import { PLACEHOLDER_EXPRESSION, PLACEHOLDER_IDENTIFIER, PLACEHOLDER_PERMISSION } from "../../../../../../common/model/formula/placeholder";
import { StatementType } from "../../../../../../common/model/formula/statement";
import { TerminalType } from "../../../../../../common/model/formula/terminal";
import { Permission } from "../../../../../../common/model/permission";
import { Pos } from "../../../../../../common/model/pos";
import { ArraySome } from "../../../../../../common/toolbox/array";
import { ColorCode } from "../../../../../../common/toolbox/color";
import { CURRENCY_DEFAULT } from "../../../../../../common/toolbox/currency";
import { ID_DEFAULT } from "../../../../../../common/toolbox/id";
import { ObjectKeys } from "../../../../../../common/toolbox/object";
import { boxOnscreen } from "../../../common/toolbox/box";
import { BlockComponent } from "./block.component";

/** Callback when a block slot is replaced. */
export type BlockCallback = (slot: BlockSlot, block?: Block | Block[]) => void;

/** A group of blocks that can be added to formula. */
export class BlockGroup {
  constructor(
    /** Name of group. */
    public name = '',
    /** Icon of group. */
    public icon = '',
    /** Type of group. */
    public type = BlockType.Statement,
    /** Color for chips. */
    public color: ColorCode | '' = '',
    /** List of chipss. */
    public chips: BlockChip[] = []
  ) { }
}

/** A labelled block that can be added to formula. */
export class BlockChip {
  constructor(
    /** Name of chip. */
    public name = '',
    /** Block itself for chip. */
    public block: Block = PLACEHOLDER_EXPRESSION
  ) { }
}

/** A slot that can accept a block. */
export class BlockSlot {

  /** Get type of block. */
  get type(): BlockUnion { return this.component.instance.block.type; }

  /** Get default placeholder. */
  static placeholder(accepts: ArraySome<AcceptType>) {
    return BLOCK_PLACEHOLDER[BLOCK_TYPE[accepts[0]][0]];
  }

  constructor(
    /** Component of block. */
    public component: ComponentRef<BlockComponent>,
    /** Accepted replacements for this slot. */
    public accepts: ArraySome<AcceptType>,
    /** Callback when a block is dropped in. */
    public replaced: BlockCallback,
    /** True to allow deleting block. */
    public deletable: boolean
  ) { }

  /** Drop a block into this slot, return true if successful. */
  drop(drag: BlockDrag) {
    // Check if drop point inside this slot.
    let component = this.component.instance;
    let inside = Pos.inside(drag.dropPoint, boxOnscreen(this.component.location.nativeElement));
    if (!inside) return false;

    // Try dropping into child slots first.
    for (let slot of component.slots) {
      if (slot.drop(drag)) return true;
    }

    // Delete block at position?
    if (!drag.block) {
      this.replaced(this);
      return true;
    }

    // Can block slot into this position?
    if (!BLOCK_TYPE[drag.block.type].some(type => this.accepts.includes(type)) && !this.accepts.includes(drag.block.type)) return false;
    this.replaced(this, drag.block);
    return true;
  }
}

/** Valid types that can be accepted. */
export type AcceptType = BlockType | BlockUnion;

/** Parameters for attaching a block. */
export class BlockParameters<T = any> {
  constructor(
    public parent: Injector,
    public container: ViewContainerRef,
    public current: { block: T, key: ObjectKeys<T, Block | Block[] | undefined> },
    public next: Block | Block[] | undefined,
    public accepts: ArraySome<AcceptType>,
    public replaced: BlockCallback,
    public deletable?: boolean
  ) { }
}

/** Event emitted when block is dragged. */
export interface BlockDrag extends CdkDragEnd {
  /** Chip that was dragged. Undefined to signal delete. */
  block?: Block
}

/** Standard groups to add to formulas. */
export const BLOCK_GROUPS: BlockGroup[] = [{
  name: 'Statements',
  icon: 'functions',
  color: ColorCode.Secondary,
  type: BlockType.Statement,
  chips: [{
    name: 'if',
    block: {
      type: StatementType.If,
      if: PLACEHOLDER_EXPRESSION,
      then: []
    }
  }, {
    name: 'for',
    block: {
      type: StatementType.For,
      expression: PLACEHOLDER_EXPRESSION,
      statements: []
    }
  }, {
    name: 'return',
    block: {
      type: StatementType.Return,
      expression: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'assignment',
    block: {
      type: StatementType.Assignment,
      left: PLACEHOLDER_IDENTIFIER,
      right: PLACEHOLDER_EXPRESSION
    }
  }]
}, {
  name: 'Conditions',
  icon: 'balance',
  color: ColorCode.Primary,
  type: BlockType.Condition,
  chips: [{
    name: 'and',
    block: {
      type: ConditionType.And,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'or',
    block: {
      type: ConditionType.Or,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'equal',
    block: {
      type: ConditionType.Equal,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'not equal',
    block: {
      type: ConditionType.Unequal,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'greater than',
    block: {
      type: ConditionType.Greater,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'less than',
    block: {
      type: ConditionType.Lesser,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'greater than or equal',
    block: {
      type: ConditionType.GreaterEqual,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'less than or equal',
    block: {
      type: ConditionType.LesserEqual,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'like',
    block: {
      type: ConditionType.Like,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'between',
    block: {
      type: ConditionType.Between,
      left: PLACEHOLDER_EXPRESSION,
      middle: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'in',
    block: {
      type: ConditionType.In,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'every',
    block: {
      type: ConditionType.Every,
      left: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'some',
    block: {
      type: ConditionType.Some,
      left: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'match',
    block: {
      type: ConditionType.Match,
      left: PLACEHOLDER_EXPRESSION,
      arms: []
    }
  }]
}, {
  name: 'Operators',
  icon: 'add',
  color: ColorCode.Primary,
  type: BlockType.Expression,
  chips: [{
    name: 'not',
    block: {
      type: OperatorType.Not,
      left: PLACEHOLDER_EXPRESSION,
    }
  }, {
    name: 'length',
    block: {
      type: OperatorType.Length,
      left: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'display',
    block: {
      type: OperatorType.Display,
      left: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'sum',
    block: {
      type: OperatorType.Sum,
      left: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'min',
    block: {
      type: OperatorType.Min,
      left: PLACEHOLDER_EXPRESSION,
    }
  },
  {
    name: 'max',
    block: {
      type: OperatorType.Max,
      left: PLACEHOLDER_EXPRESSION,
    }
  }, {
    name: 'add',
    block: {
      type: OperatorType.Add,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION,
    }
  }, {
    name: 'subtract',
    block: {
      type: OperatorType.Subtract,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION,
    }
  }, {
    name: 'multiply',
    block: {
      type: OperatorType.Multiply,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION,
    }
  }, {
    name: 'divide',
    block: {
      type: OperatorType.Divide,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION,
    }
  }, {
    name: 'access',
    block: {
      type: OperatorType.Access,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION,
    }
  }, {
    name: 'index',
    block: {
      type: OperatorType.Index,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION,
    }
  }, {
    name: 'join',
    block: {
      type: OperatorType.Join,
      left: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
  }, {
    name: 'map',
    block: {
      type: OperatorType.Map,
      expression: PLACEHOLDER_EXPRESSION,
      statements: []
    }
  }, {
    name: 'filter',
    block: {
      type: OperatorType.Filter,
      expression: PLACEHOLDER_EXPRESSION,
      statements: []
    }
  }, {
    name: 'pad',
    block: {
      type: OperatorType.Pad,
      left: PLACEHOLDER_EXPRESSION,
      middle: PLACEHOLDER_EXPRESSION,
      right: PLACEHOLDER_EXPRESSION
    }
    }, {
      name: 'slice',
      block: {
        type: OperatorType.Slice,
        left: PLACEHOLDER_EXPRESSION,
        middle: PLACEHOLDER_EXPRESSION,
        right: PLACEHOLDER_EXPRESSION
      }
    }, {
    name: 'granted',
    block: {
      type: OperatorType.Granted,
      left: PLACEHOLDER_PERMISSION
    }
  }]
}, {
  name: 'Terminals',
  icon: 'tag',
  color: '',
  type: BlockType.Expression,
  chips: [{
    name: 'boolean',
    block: {
      type: TerminalType.Boolean,
      value: true
    }
  }, {
    name: 'number',
    block: {
      type: TerminalType.Number,
      value: 0
    }
  }, {
    name: 'string',
    block: {
      type: TerminalType.String,
      value: ''
    }
  }, {
    name: 'currency',
    block: {
      type: TerminalType.Currency,
      value: CURRENCY_DEFAULT
    }
  }, {
    name: 'date',
    block: {
      type: TerminalType.Date,
      value: 0
    }
  }, {
    name: 'code',
    block: {
      type: TerminalType.Code,
      category: CommonCode.AccountCategory,
      value: AccountCategory.Loan
    }
  }, {
    name: 'code list',
    block: {
      type: TerminalType.CodeList,
      category: CommonCode.AccountCategory,
      value: []
    }
  }, {
    name: 'identifier',
    block: {
      type: TerminalType.Identifier,
      key: ''
    }
  }, {
    name: 'array',
    block: {
      type: TerminalType.Array,
      expressions: []
    }
  }, {
    name: 'permission',
    block: {
      type: TerminalType.Permission,
      value: Permission.System
    }
  }, {
    name: 'resource',
    block: {
      type: TerminalType.Resource,
      collection: 'addresses',
      value: ID_DEFAULT
    }
  }]
}];