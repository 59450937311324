import { propinfoOf } from "../../info/prop";
import { blockIdentifiers } from "../../toolbox/formula/block";
import { formulaPreview, formulaRun } from "../../toolbox/formula/formula";
import { CodeEnum } from "../code-type";
import { DisplayValue } from "../display";
import { PropertyType } from "../property-type";
import { Engine } from "./engine";
import { Formula, FormulaPreview } from "./formula";

/** A proxy for a list of formulas. */
export class FormulaProxy<T = DisplayValue> {
  /** Execute formulas and pull calculated values. */
  [key: string]: any
  /** Context for executing formulas. */
  input!: T
  /** Available enums for executing formulas. */
  enums!: CodeEnum
  /** Cached list of keys used by each property. */
  keys!: Record<string, Set<string>>;

  constructor() {
    Object.defineProperties(this, {
      // Manually define properties to prevent enumeration later.
      input: { writable: true, value: {} },
      enums: { writable: true, value: new Map() },
      keys: { writable: true, value: {} },
      constructor: { value: { name: 'FormulaProxy', propinfo: {} } }
    });
  }

  /** Add new property to proxy. */
  static add<T>(proxy: FormulaProxy<T>, formula: Formula) {
    Object.defineProperty(proxy, formula._id, {
      enumerable: true,
      get() {
        Engine.enums = proxy.enums;
        return formulaRun(formula, proxy['input']);
      },
      set() {}
    });

    // TODO add better type inference to formula returns later.
    let propinfo = propinfoOf(proxy);
    propinfo[formula._id] = { type: PropertyType.String, name: formula.name };
    proxy.keys[formula._id] = new Set(blockIdentifiers(formula.statements));
  }

  /** Produce a formula proxy from formulas. */
  static formulas(formulas: Formula[]) {
    let proxy = new FormulaProxy();

    for (let formula of formulas) {
      FormulaProxy.add(proxy, formula);
    }

    return proxy;
  }

  /** Produce formula proxy from previews. */
  static previews(previews: FormulaPreview[]) {
    return this.formulas(previews.map(formulaPreview));
  }
}