<div class="panels">
  <ng-container *ngFor="let panel of panels">
    <app-action-panel *ngIf="panel.permission | permission" [icon]="panel.icon" (click)="onClaim(panel.type)">
      <h2>New {{panel.type | codeType:auth._inst:DisputesCode.ClaimType | async}} Claim</h2>
    </app-action-panel>
  </ng-container>
  <app-action-panel icon="manage_search" (click)="onSearch()">
    <h2>Find a Claim</h2>
    <app-menu-bar-search (click)="$event.stopPropagation()"></app-menu-bar-search>
  </app-action-panel>
</div>