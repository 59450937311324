import { Component } from '@angular/core';
import { TerminalCurrency } from "../../../../../../../../common/model/formula/terminal";
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-currency',
  templateUrl: './setup-formula-currency.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-currency.component.scss']
})
export class SetupFormulaCurrencyComponent extends BlockComponent<TerminalCurrency> {}
