import { CommonCode, SystemCode } from "../code/system";
import { ErrorResponse } from "../message/error";
import { BankUnion } from "../model/bank";
import { SystemDocumentTemplate } from "../model/builtin/document-template";
import { SystemForm } from "../model/builtin/form";
import { SystemFormList } from "../model/builtin/form-list";
import { SystemFormula } from "../model/builtin/formula";
import { SystemModel } from "../model/builtin/model";
import { SystemTable } from "../model/builtin/table";
import { SystemTask } from "../model/builtin/task";
import { SystemWorkflow } from "../model/builtin/workflow";
import { bankUnionResolve } from "../toolbox/bank";
import { BuiltinMap } from "../toolbox/builtinmap";
import { InstMap, NameMap } from "../toolbox/idmap";
import { NestedKey, keyNestedGet, keyNestedSet } from "../toolbox/keys";
import { errorResponse } from "../toolbox/message";
import { Newable, ObjectKeys } from "../toolbox/object";
import { infoOf } from "./base";
import { typeinfoOf, typeinfoValue } from "./type";

/** Types of settings that can be displayed. */
export enum SettingType {
  Boolean,
  Codes,
  CodeType,
  DocumentTemplate,
  Form,
  FormList,
  Formula,
  Model,
  Number,
  String,
  Table,
  Task,
  Workflow,
}

/** Information about a specific setting. */
export type Setting = 
  | SettingBoolean
  | SettingCodes
  | SettingCodeType
  | SettingDocumentTemplate
  | SettingForm
  | SettingFormList
  | SettingFormula
  | SettingModel
  | SettingNumber
  | SettingString
  | SettingTable
  | SettingTask
  | SettingWorkflow;

/** Information about a setting displayed in interface. */
export class SettingTag<T extends SettingType = SettingType> {
  constructor(
    /** Type of value stored. */
    public type: T,
    /** Display name of setting. */
    public name: string,
    /** Description of setting. */
    public description: string,
    /** True if this setting is required. */
    public required?: boolean
  ) {}
}

/** Configuration for a boolean setting. */
export class SettingBoolean extends SettingTag<SettingType.Boolean> {
  override readonly type = SettingType.Boolean;
}

/** Configuration for a code setting. */
export class SettingCodeType extends SettingTag<SettingType.CodeType> {
  override readonly type = SettingType.CodeType;
}

/** Configuration for a codes setting. */
export class SettingCodes extends SettingTag<SettingType.Codes> {
  override readonly type = SettingType.Codes;

  /** Category to pull codes from. */
  category: SystemCode = CommonCode.AccountCategory
  /** True if multiple codes can be selected. */
  multiple?: boolean
}

/** Configuration for a DocumentTemplate setting. */
export class SettingDocumentTemplate extends SettingTag<SettingType.DocumentTemplate> {
  override readonly type = SettingType.DocumentTemplate;
}

/** Configuration for a Form setting. */
export class SettingForm extends SettingTag<SettingType.Form> {
  override readonly type = SettingType.Form;
}

/** Configuration for a FormList setting. */
export class SettingFormList extends SettingTag<SettingType.FormList> {
  override readonly type = SettingType.FormList;
}

/** Configuration for a Formula setting. */
export class SettingFormula extends SettingTag<SettingType.Formula> {
  override readonly type = SettingType.Formula;

  /** True if multiple formulas can be selected. */
  multiple?: boolean
}

/** Configuration for a Model setting. */
export class SettingModel extends SettingTag<SettingType.Model> {
  override readonly type = SettingType.Model;
}

/** Configuration for a Number setting. */
export class SettingNumber extends SettingTag<SettingType.Number> {
  override readonly type = SettingType.Number;
}

/** Configuration for a String setting. */
export class SettingString extends SettingTag<SettingType.String> {
  override readonly type = SettingType.String;
}

/** Configuration for a Table setting. */
export class SettingTable extends SettingTag<SettingType.Table> {
  override readonly type = SettingType.Table;
}

/** Configuration for a Task setting. */
export class SettingTask extends SettingTag<SettingType.Task> {
  override readonly type = SettingType.Task;
}

/** Configuration for a Workflow setting. */
export class SettingWorkflow extends SettingTag<SettingType.Workflow> {
  override readonly type = SettingType.Workflow;
}

/** Type annotations for values displayed in settings pages. */
export type SettingsInfo<T = any> = {
  [K in keyof T]: BankUnion<Setting>
}

/** Get settings info of a value. */
export function settingsinfoOf<T>(value: Newable<T>): SettingsInfo<T>
export function settingsinfoOf<T>(value: T): SettingsInfo<T>
export function settingsinfoOf(value: any): SettingsInfo {
  return infoOf(value, 'settingsinfo');
}

/** Get flattened setting information of a value. */
export function settingsinfoFlat<T>(value: T, bank: boolean, filter?: SettingType): [NestedKey<T>, Setting][] {
  let list: [NestedKey<T>, Setting][] = [];
  settingsinfoDeep(value, [], list, bank, filter);
  return list;
}

/** Recursively extract settings from value. */
function settingsinfoDeep(object: any, path: string[], list: [string, Setting][], bank: boolean, filter?: SettingType) {
  if (!(object instanceof Object)) return;
  let typeinfo = typeinfoOf(object);
  let settingsinfo = settingsinfoOf(object);
  let merged = { ...object, ...typeinfo };
  
  for (let key of Object.keys(merged)) {
    // Add setting to list.
    let setting = bankUnionResolve(settingsinfo[key], bank);
    path.push(key);
    if (setting && (filter ?? setting.type) === setting.type) {
      setting.required = setting.required ?? object[key] !== undefined;
      list.push([path.join('.'), setting]);
    }

    // Recurse into object for additional settings.
    let value = typeinfoValue(object, key, typeinfo);
    settingsinfoDeep(value, path, list, bank, filter);
    path.pop();
  }
}

/** Remap settings info to object IDs. */
export function settingsinfoRemap<B, V>(_inst: string, builtin: B, value: V, builtins: BuiltinMap) {
  let errors: ErrorResponse[] = [];
  let infoFlat = settingsinfoFlat(value, false)
  for (let [key, setting] of infoFlat) {
    // Check if this setting was set.
    let name = keyNestedGet(key, builtin as unknown as V);
    if (!name) continue;

    // Check if this setting needs to be remapped.
    let map = SETTINGS_INFO_KEY[setting.type];
    let newValue = name;
    if (map) {
      let id = Array.isArray(name) ? builtins[map].ids(_inst, name) : builtins[map].id(_inst, name);
      if (errorResponse(id)) {
        errors.push(id);
        continue;
      }
      newValue = id;
    }

    keyNestedSet(key, value, newValue);
  }

  if (errors.length) return new ErrorResponse('There was an error mapping builtins.', errors);
  return value;
}

/** Mapping of settings types to builtin keys. */
const SETTINGS_INFO_KEY: Record<SettingType, ObjectKeys<BuiltinMap, NameMap | InstMap> | undefined> = {
  [SettingType.Boolean]: undefined,
  [SettingType.CodeType]: 'codeTypes',
  [SettingType.Codes]: undefined,
  [SettingType.DocumentTemplate]: 'documentTemplates',
  [SettingType.Form]: 'forms',
  [SettingType.FormList]: 'formLists',
  [SettingType.Formula]: 'formulas',
  [SettingType.Model]: 'models',
  [SettingType.Number]: undefined,
  [SettingType.String]: undefined,
  [SettingType.Table]: 'tables',
  [SettingType.Task]: 'tasks',
  [SettingType.Workflow]: 'workflows'
};

/** Class of each setting type. */
export interface SettingClass {
  [SettingType.Boolean]: SettingBoolean,
  [SettingType.CodeType]: SettingCodeType,
  [SettingType.Codes]: SettingCodes,
  [SettingType.DocumentTemplate]: SettingDocumentTemplate,
  [SettingType.Form]: SettingForm,
  [SettingType.FormList]: SettingFormList,
  [SettingType.Formula]: SettingFormula,
  [SettingType.Model]: SettingModel,
  [SettingType.Number]: SettingNumber,
  [SettingType.String]: SettingString,
  [SettingType.Table]: SettingTable,
  [SettingType.Task]: SettingTask,
  [SettingType.Workflow]: SettingWorkflow
}

/** Value of each setting. */
export interface SettingValue {
  [SettingType.Boolean]: boolean,
  [SettingType.CodeType]: string,
  [SettingType.Codes]: string[],
  [SettingType.DocumentTemplate]: string,
  [SettingType.Form]: string,
  [SettingType.FormList]: string,
  [SettingType.Formula]: string,
  [SettingType.Model]: string,
  [SettingType.Number]: number,
  [SettingType.String]: string,
  [SettingType.Table]: string,
  [SettingType.Task]: string,
  [SettingType.Workflow]: string
}

/** Mapping of setting types to builtin enumerations. */
export interface SettingEnumeration {
  [SettingType.Boolean]: undefined
  [SettingType.CodeType]: undefined
  [SettingType.Codes]: undefined
  [SettingType.DocumentTemplate]: SystemDocumentTemplate
  [SettingType.Form]: SystemForm
  [SettingType.FormList]: SystemFormList
  [SettingType.Formula]: SystemFormula
  [SettingType.Model]: SystemModel
  [SettingType.Number]: undefined
  [SettingType.String]: undefined
  [SettingType.Table]: SystemTable
  [SettingType.Task]: SystemTask
  [SettingType.Workflow]: SystemWorkflow
}