<aside>
  <h1>{{APP_NAME}}</h1>
  <ng-container *ngFor="let c of contents | keyvalue">
    <div class="category" [class.hover]="c.key === category" (click)="toggle($any(c.key))">
      <app-icon>{{c.key === category ? 'expand_more' : 'chevron_right'}}</app-icon>
      <div>{{c.key | docName}}</div>
    </div>
    <div [class.hidden]="c.key !== category">
      <div class="page" *ngFor="let p of c.value | keyvalue" [class.selected]="p.key === page"  (click)="open($any(p.key))">
        <app-icon></app-icon>
        <div>{{p.value}}</div>
      </div>
    </div>
  </ng-container>
</aside>
<main [innerHTML]="text">Page Content</main>