
/** Possible modifiers for a query parameter. */
export enum QueryOperator {
  Equal = 'eq',
  Unequal = 'ne',
  Greater = 'gt',
  Lesser = 'lt',
  GreaterEqual = 'gte',
  LesserEqual = 'lte',
  Unexists = 'nexists'
}