import { Pipe } from '@angular/core';
import { idNull } from "../../../../../common/toolbox/id";

@Pipe({
  name: 'idNull'
})
export class IdNullPipe {
  transform(value: string | undefined, expect = true): boolean {
    return expect === idNull(value);
  }
}
