import { Component, Input } from '@angular/core';
import { AccountJoin } from '../../../../../../common/model/account/account';
import { BankUnion } from '../../../../../../common/model/bank';
import { BUILTIN_FORMULAS } from "../../../../../../common/model/builtin/formula";
import { MemberFormula } from '../../../../../../common/model/builtin/formula/member';
import { Chip, ChipResult } from "../../../../../../common/model/chip";
import { Member } from '../../../../../../common/model/member';
import { formulaStatements } from '../../../../../../common/toolbox/formula/formula';
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { FormConfig } from '../../../common/component/form/form.model';
import { AuthService } from '../../../common/service/auth.service';
import { FormService } from '../../../common/service/form.service';
import { MemberService } from '../../../common/service/member.service';

/** Configuration for displaying a member. */
interface MemberConfig {
  /** Form configuration. */
  form: FormConfig;
  /** Member being displayed. */
  member: Member;
  /** Chips to display along top of member. */
  results: ChipResult[];
}

@Component({
  selector: 'app-account-members',
  templateUrl: './account-members.component.html',
  styleUrls: ['./account-members.component.scss'],
  host: {
    class: 'card'
  }
})
export class AccountMembersComponent {

  /** Label to display under each member */
  readonly MEMBER_LABEL: BankUnion<string> = {
    bank: 'Customer Detail',
    creditUnion: 'Member Detail'
  };
  
  /** Members to display. */
  configs?: MemberConfig[];

  /** Additional title to display at top. */
  @Input() title: BankUnion<string> = 'Account Holder Info';

  /** Context for form. */
  @Input() set account(account: AccountJoin) {
    this.refresh(account);
  }

  constructor(
    private auth: AuthService,
    private members: MemberService,
    private forms: FormService
  ) {}

  /** Callback when clicking member detail. */
  onMember(member: Member) {
    this.members.open({ _inst: this.auth._inst, _ids: [member._id] });
  }

  /** Fetch configuration for member to display. */
  private async refresh(account: AccountJoin): Promise<any> {
    // TODO associate chips with a particular account type and fetch these from API.
    let chips: Chip[] = [new Chip(StatusLevel.Alert, formulaStatements(BUILTIN_FORMULAS[MemberFormula.Deceased].statements))];
    this.configs = await Promise.all(account.members.map(async member => ({
      results: Chip.list(chips, { account, member }), member,
      form: {
        form: await this.forms.setting('general.account.memberForm'),
        value: { account, member }
      }
    })));
  }
}
