import { SchemaObjectProperties } from "../model/schema";
import { keyNestedPop } from "../toolbox/keys";
import { validatorValue } from "../toolbox/validate";
import { ValidationOptions, ValidationStatus, Validator, ValidatorContext, ValidatorLog } from "./base";
import { MultiValidator } from "./multi";

/** Validate object has specified properties. */
export class ObjectValidator<T> extends MultiValidator<T> {

  /** List of subvalidators. */
  properties: [keyof T, Validator<any>][];
  /** Copy of object. */
  private object: T;

  constructor(value: T, context = new ValidatorContext()) {
    super();
    let object = value instanceof Validator ? value.value() : value;
    this.properties = Object.keys(object).map(key => [key, validatorValue(object[key], context, value, key)]) as any;
    this.object = object;
  }

  value() {
    return this.object;
  }

  parse(value: string) {
    if (this.undefined(value)) return undefined;

    try {
      let object = JSON.parse(value);

      for (let [key, validator] of this.properties) {
        object[key] = validator.parse(object[key]);
      }

      return object;
    } catch {
      return undefined
    }
  }

  override subparse(text: string, key?: string) {
    let [current, next] = keyNestedPop(key);
    if (!current) return undefined;

    // Attempt to find matching subvalidator.
    let validator = this.properties.find(([key]) => key === current)?.[1];
    return validator?.subparse(text, next);
  }

  schema() {
    if (this.property) return this.property;
    let optional: (keyof T)[] = [];
    let properties: SchemaObjectProperties = {};
    let additionalProperties: any = undefined;
    for (let [key, validator] of this.properties) {
      let schema = validator.schema();
      if (!schema) {
        additionalProperties = true;
        continue;
      }

      properties[key] = schema;
      if (validator.optional) optional.push(key);
    }

    return { bsonType: 'object' as const, optional, properties, additionalProperties };
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    this.list = [];

    if (!(value instanceof Object)) {
      this.list.push(new ValidatorLog('Expected object.'));
      return ValidationStatus.Error;
    }

    let status = ValidationStatus.Okay;
    for (let [key, validator] of this.properties) {
      let substatus = validator.validate(value[key], options);
      status = Math.max(status, substatus);

      if (substatus) for (let log of validator.logs()) {
        log.unshift(`.${String(key)}`);
        this.list.push(log);
      }
    }

    return status;
  }

  override query(value: any) {
    if (!value || typeof value != 'object') return;

    for (let [key, validator] of this.properties) {
      let out = validator.parse(value[key]);
      if (out != undefined) value[key] = out;
    }
  }

  /** Get iterator over optional properties. */
  optionalKeys(_: T) {
    let properties = this.properties;
    return {
      [Symbol.iterator]: function* () {
        for (let i = 0; i < properties.length; ++i) {
          if (!properties[i]![1].optional) continue;
          yield properties[i]![0];
        }
      }
    };
  }
}