import { Injectable } from '@angular/core';
import { Newable } from "../../../../../common/toolbox/object";
import { StatusLevel } from "../../../../../common/toolbox/status";
import { TabBarComponent } from '../component/tab/bar/tab-bar.component';
import { TabConfig, TabRef } from '../component/tab/bar/tab-bar.model';
import { TabComponent } from '../component/tab/tab.component';
import { padComponent, titleComponent } from '../toolbox/component';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  /** Reference to tab bar component. */
  private component: TabBarComponent | undefined;

  constructor(
    private log: LogService
  ) {}

  /** Hook up tab bar component. */
  set(component: TabBarComponent) {
    this.component = component;
  }

  /** Remove reference to tab bar component. */
  clear() {
    this.component?.clear();
    this.component = undefined;
  }

  /** Add a new tab to bar, injecting provided data. */
  open<T extends TabComponent = TabComponent, D = unknown, R = unknown>(component: Newable<any>, data: D, config: string | TabConfig<T> = ''): TabRef<T, D, R> {
    if (!this.component) {
      this.log.show('Failed to open new tab.', StatusLevel.Alert);
      return <any>null;
    }

    // Fall back on default tab title if component implements it.
    config = config instanceof Object ? config : { title: config };
    config.closeable = config.closeable ?? true;
    if (!config.title && titleComponent<D>(component)) config.title = component.title(data);
    if (config.padding === undefined && padComponent(component)) config.padding = component.padding(data);
    
    return this.component.open(component, data, config) as unknown as TabRef<T, D, R>;
  }

  /** Close current tab and open new tab, injecting provided data. */
  replace<T extends TabComponent = TabComponent, D = any, R = any>(component: Newable<any>, data: D, config: string | TabConfig<T> = ''): TabRef<T, D, R> {
    
    if (!this.component) {
      this.log.show('Failed to open new tab.', StatusLevel.Alert);
      return <any>null;
    }

    let currentSelectedTab = this.component.tab;
    if(currentSelectedTab) this.component.close(currentSelectedTab);
    
    return this.open(component, data, config);
  }

  /** Focus first available tab. */
  first() { if (this.component) this.component.tab = this.component.tabs[0]; }
}
