import { NestedKey, keyLast, keyNestedGet } from "./keys";
import { titleCase } from "./string";

/** Escape a single CSV cell value. */
export function csvEscape(value: unknown) {
  let text = typeof value === 'string' ? value : `${value ?? ''}`;
  return /[,"]/g.test(text) ? `"${text.replace(/"/g, '""')}"` : text;
}

/** Converts an array of objects into a csv. */
export function csvFormat<T>(input: T[], keys: NestedKey<T>[]): string {
  let headers = keys.map(header => csvEscape(titleCase(keyLast(header))));
  let rows = input.map(value => keys.map(header => csvEscape(keyNestedGet(header, value))));
  return [headers, ...rows.map(row => row.join(','))].join('\r\n');
}

/** Correct CSV text that may include unescaped cell values. */
export function csvCorrect(text: string) {
  return text.replace(/[^,\n]+/g, match => {
    if (match.startsWith('"') && match.endsWith('"')) return match;
    return csvEscape(match);
  });
}

/**
 * Split correctly-formatted, RFC 4180 standard CSV into an array of arrays.
 * This function cannot handle incorrectly-escaped or nonstandard CSV.
 * @link https://www.rfc-editor.org/rfc/rfc4180
 */
export function csvSplit(text: string, separator = ','): string[][] {
  let i = 0;
  let start = 0;
  let subtext: string[] = [];
  let lines: string[][] = [[]];
  let line = lines[0]!;

  while (1) switch(text[i]) {
  case separator:
    line.push(text.slice(start, i));
    start = ++i;
    break;
  case '"':
    start = ++i;
    escape: while (1) switch(text[i]) {
    case '"':
      if (text[i + 1] === '"') {
        // Escaped string.
        subtext.push(text.slice(start, ++i));
        start = ++i;
        continue;
      }
      
      subtext.push(text.slice(start, i));
      line.push(subtext.join(''));
      subtext.length = 0;
      start = ++i;
      if (text[i] === separator) start = ++i;
      break escape;
    case undefined:
      // Encountered EOL before end of string.
      subtext.push(text.slice(start, i));
      line.push(subtext.join(''));
      return lines;
    default:
      i++;
      continue escape;
    } break;
  case '\n':
    line.push(text.slice(start, i));
    line = lines[lines.push([]) - 1]!;
    start = ++i;
    break;
  case undefined:
    if (text[i - 1] === separator || start !== i) line.push(text.slice(start, i));
    return lines;
  default:
    i++;
  }
  
  return lines;
}