import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, Subject, debounceTime, merge, takeUntil } from 'rxjs';
import { AccountJoin, AccountJoinLoan } from '../../../../../../common/model/account/account';
import { BUILTIN_FORMULAS } from "../../../../../../common/model/builtin/formula";
import { EventFormula } from '../../../../../../common/model/builtin/formula/event';
import { Chip } from "../../../../../../common/model/chip";
import { ClaimJoin, ClaimJoinACH } from '../../../../../../common/model/claim/claim';
import { DisplayPartial, DisplayType } from "../../../../../../common/model/display";
import { EventCategory } from "../../../../../../common/model/event/category";
import { EventResult, EventResultType } from "../../../../../../common/model/event/result";
import { AccountResultType } from "../../../../../../common/model/event/result/account";
import { ClaimResultType } from "../../../../../../common/model/event/result/claim";
import { formulaStatements } from '../../../../../../common/toolbox/formula/formula';
import { idNull } from "../../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { DateRange } from "../../../../../../common/toolbox/time";
import { AccountService } from '../../service/account.service';
import { AuthService } from '../../service/auth.service';
import { ClaimService } from '../../service/claim.service';
import { EventService } from '../../service/event.service';
import { LogService } from '../../service/log.service';
import { ReminderService } from '../../service/reminder.service';
import { TableService } from '../../service/table.service';
import { AccountTaskOpen } from '../../toolbox/account';
import { DisputeTaskOpen } from '../../toolbox/dispute';
import { getRequest } from '../../toolbox/request';
import { gridPaginateRequest } from '../../toolbox/source/grid';
import { ServerSource } from '../../toolbox/source/server';
import { TaskOpen } from '../../toolbox/task';
import { ModelGridComponent } from '../model/grid/model-grid.component';
import { GridColumn, GridConfig } from '../model/grid/model-grid.model';
import { TAB_DATA } from '../tab/bar/tab-bar.model';
import { EventListData } from './event-list.model';
import { EventListIconComponent } from './icon/event-list-icon.component';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent {

  /** Reference to icon. */
  @ViewChild(EventListIconComponent) icon!: EventListIconComponent;
  /** Reference to grid containing events. */
  @ViewChild(ModelGridComponent) grid?: ModelGridComponent;

  /** Automatically refresh table every few seconds. */
  @Input() set autorefresh(autorefresh: BooleanInput) {
    if (!coerceBooleanProperty(autorefresh)) return;
    this.eventService.updated.pipe(takeUntil(this.destroy)).subscribe(() => this.refetch());
  }

  /** Set current event type being viewed. */
  @Input() set type(type: EventResultType | undefined) {
    this.builder.controls.type.patchValue(type ?? null);
  }

  /** Set current claim being viewed. */
  @Input() set claim(claim: ClaimJoin) {
    this.builder.controls.category.patchValue(EventCategory.Claim);
    this.claimChange.next(claim);
  }

  /** Assigned user to for events. */
  @Input() set assigned(_assigned: string) {
    if (_assigned === this.builder.value._assigned) return;
    this.builder.controls._assigned.patchValue(_assigned);
  }

  /** Date range for event creation date. */
  @Input() set eventDate(eventDate: DateRange) {
    if (DateRange.equal(eventDate, this.builder.value.eventDate)) return;
    this.builder.controls.eventDate.patchValue(eventDate);
  }

  /** Date range for event assignment date. */
  @Input() set assignDate(assignDate: DateRange) {
    if (DateRange.equal(assignDate, this.builder.value.eventDate)) return;
    this.builder.controls.assignDate.patchValue(assignDate);
  }

  /** Limit to use for list. */
  @Input() set limit(limit: number) {
    this.config.source.relimit(limit);
  };

  /** True to stretch to fit contents, false = scrollbar */
  @Input() expand = false;
  /** Explicit check for completed or incomplete events. */
  @Input() complete?: boolean;

  /** Emits when a task should be opened. */
  @Output() task = new EventEmitter<TaskOpen<EventResult>>();

  /** Current selection of event parameters. */
  protected builder = new FormBuilder().group({
    category: undefined as unknown as EventCategory,
    type: undefined as unknown as EventResultType,
    _assigned: undefined as unknown as string,
    eventDate: undefined as unknown as DateRange,
    assignDate: undefined as unknown as DateRange
  });

  /** Emits when viewed account changes. */
  protected account = new BehaviorSubject<AccountJoin>(new AccountJoinLoan());
  /** Emits when viewed claim changes */
  protected claimChange = new BehaviorSubject<ClaimJoin>(new ClaimJoinACH());
  /** Emits when a task result should be opened. */
  protected open: Subject<TaskOpen> | undefined;
  /** Configuration for displaying events. */
  protected config = new GridConfig(new ServerSource<DisplayPartial>());
  /** Insert event type icon at start of row. */
  protected precolumns: GridColumn[] = [{ key: 'icon' as any, size: '2rem', component: EventListIconComponent }];

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();

  constructor(
    @Optional() @Inject(TAB_DATA)
    data: EventListData | null,
    private auth: AuthService,
    private log: LogService,
    private reminderService: ReminderService,
    private accountService: AccountService,
    private claimService: ClaimService,
    private tableService: TableService,
    private eventService: EventService,
    private http: HttpClient
  ) {
    if (data instanceof EventListData) {
      // Injected into page via tab, no inputs available.
      if (data.form) this.builder.patchValue(data.form as any);
      if (data.accountChange) this.account = data.accountChange;
      if (data.claimChange) this.claimChange = data.claimChange;
      this.open = data.open; // TODO passing this down to pass result back out is a bit of a hack.
      this.expand = data.expand ?? false;
    }
  }

  /** Configure model configuration and refreshing. */
  async ngOnInit() {
    let table = await this.tableService.setting('general.event.table');
    this.config = new GridConfig(this.config.source, table);
    this.config.source.limit = 10;

    /**
     * Refresh if:
     * 1) Selected new account OR institution.
     * 2) Updated form value (filter type, page size).
     * 3) Changed table pagination.
     *
     * We debounce time to prevent multiple requests at once.
     */
    merge(this.account, this.claimChange, this.builder.valueChanges, this.config.source.dataRequest)
      .pipe(takeUntil(this.destroy), debounceTime(0))
      .subscribe(() => this.refetch());
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Execute action when clicking an event row. */
  async onEvent(value: DisplayPartial) {
    let event = value.event;
    if (!event) {
      this.log.show('Selected row had no event.', StatusLevel.Alert);
      return;
    }

    let result = event.result;
    let task: TaskOpen | undefined;
    switch (result.category) {
    case EventCategory.Account:
      let account = value.account;
      if (!account) break;

      switch (result.type) {
        case AccountResultType.Form:
          let accountTask: AccountTaskOpen = task = { _task: result._task, event: { ...event, result } };
          if (this.account.value._id !== account._id) {
            this.accountService.work({ account, task: accountTask });
            return;
          }
      }
      break;
    case EventCategory.Claim:
      let claim = value.claim;
      if (!claim) break;

      switch (result.type) {
        case ClaimResultType.ClaimForm:
          let disputeTask: DisputeTaskOpen = task = { _task: result._task, event: { ...event, result } };
          if (this.claimChange.value._id !== claim._id) {
            this.claimService.open({ _insts: [this.auth._inst], _ids: [claim._id] }, [claim as ClaimJoin], disputeTask);
            return;
          }
      } break;
    }

    // If item already open, display task result.
    if (task) {
      this.task.emit(task);
      this.open?.next(task);
    }
  }

  /** Refresh data of table. */
  private async refetch() {
    let [dateStart, dateEnd] = DateRange.query(this.builder.value.eventDate);
    let [assignStart, assignEnd] = DateRange.query(this.builder.value.assignDate);

    // Request events and optionally, list of accounts to join on.
    let response = await getRequest(this.http, 'events', {
      ...gridPaginateRequest(DisplayType.Event, this.grid!.source),
      _inst: this.auth._inst,
      _account: idNull(this.account.value._id) ? undefined : this.account.value._id,
      _claim: idNull(this.claimChange.value._id) ? undefined : this.claimChange.value._id,
      _assigned: this.builder.value._assigned ?? undefined,
      complete: this.complete,
      dateStart, dateEnd,
      dueStart: assignStart, dueEnd: assignEnd,
      category: this.builder.value.category ?? undefined,
      type: this.builder.value.type ?? undefined
    });
    if (errorResponse(response)) {
      this.log.show(response);
      return;
    }

    // TODO Hardcoded event highlight chips for now.
    let chips: Chip[] = [
      new Chip(StatusLevel.Notice, formulaStatements(BUILTIN_FORMULAS[EventFormula.Incomplete].statements))
    ];

    // Merge events, evaluate chips, set title/description.
    let items = await this.eventService.merge(response.items, chips, response.accounts, response.claims);
    this.reminderService.add(response.items);
    this.grid!.source.available = response.available;
    this.grid!.source.items = items;
  }
}
