import { Component, Inject, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { MENU_PARENT } from '../menu.model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  host: {
    '[class.disabled]': 'disabled',
    '(mouseenter)': 'onMouseEnter()'
  }
})
export class MenuItemComponent {
  /** Stream that emits when the menu item is hovered. */
  readonly hovered = new Subject<MenuItemComponent>();

  /** True if disabled. */
  @Input() disabled = false;

  /** True if this item triggers a submenu. */
  submenu = false;

  constructor(
    /** Parent menu, type as object instead of MenuComponent to prevent circular dependency. */
    @Inject(MENU_PARENT) public parent: Object
  ) {}

  ngOnDestroy() {
    this.hovered.complete();
  }

  /** Callback when mouse hovers over item. */
  onMouseEnter() {
    this.hovered.next(this);
  }
}
