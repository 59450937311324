import { AccountCategory } from "../../code/standard/common"
import { ClaimStatus } from "../../code/standard/disputes"
import { CommonCode, DisputesCode } from "../../code/system"
import { DisplayType, DisplayValue } from "../display"
import { Condition, ConditionType } from "../formula/condition"
import { Sort } from "../formula/sort"
import { TerminalType } from "../formula/terminal"
import { SystemModel } from "./model"

/** List of all work queues. */
export type SystemFilterRule = AccountsFilterRule | DisputesFilterRule;

/** Standard accounts work queues. */
export enum AccountsFilterRule {
  AllNonRealEstate = 'All Non-RE',
  AllRealEstate = 'All Real Estate'
}

/** Standard claims work queues. */
export enum DisputesFilterRule {
  CardClaims = 'Card Claims',
  InitiatedClaims = 'Initiated Claims',
  ChargebackClaims = 'Chargeback Claims',
  OpenClaims = 'Open Claims',
  OperatorClaims = 'Operator Claims',
  OverdueClaims = 'Overdue Claims',
  ReasonClaims = 'Reason Claims',
  ResolvedClaims = 'Resolved Claims',
  AllClaims = 'All Claims'
}

/** Tagged type of built-in work queue. */
interface BuiltinFilterRule<T extends DisplayType> {
  /** Resource of this builtin work queue. */
  readonly type: T
  /** Model this filter rule is linked to. */
  model?: SystemModel
  /** Override for filter rule name. */
  name?: string
  /** Conditions of this list. */
  conditions?: Condition<DisplayValue[T]>[]
  /** Sorting criteria of this list. */
  sorts?: Sort<DisplayValue[T]>[]
}

export const BUILTIN_FILTER_RULES: Record<SystemFilterRule, BuiltinFilterRule<DisplayType>> = {
  [AccountsFilterRule.AllNonRealEstate]: {
    type: DisplayType.Account,
    conditions: [{
      type: ConditionType.Equal,
      left: { type: TerminalType.Identifier, key: 'category' },
      right: { type: TerminalType.Code, category: CommonCode.AccountCategory, value: AccountCategory.Loan }
    }, {
      type: ConditionType.LesserEqual,
      left: { type: TerminalType.Identifier, key: 'type' },
      right: { type: TerminalType.String, value: '20' }
    }, {
      type: ConditionType.Greater,
      left: { type: TerminalType.Identifier, key: 'pastDueAmount' },
      right: { type: TerminalType.String, value: '0' }
    }]
  },
  [AccountsFilterRule.AllRealEstate]: {
    type: DisplayType.Account,
    conditions: [{
      type: ConditionType.Equal,
      left: { type: TerminalType.Identifier, key: 'category' },
      right: { type: TerminalType.Code, category: CommonCode.AccountCategory, value: AccountCategory.Loan }
    }, {
      type: ConditionType.GreaterEqual,
      left: { type: TerminalType.Identifier, key: 'type' },
      right: { type: TerminalType.String, value: '100' }
    }, {
      type: ConditionType.Greater,
      left: { type: TerminalType.Identifier, key: 'pastDueAmount' },
      right: { type: TerminalType.String, value: '0' }
    }]
  },
  [DisputesFilterRule.CardClaims]: {
    type: DisplayType.Claim
  },
  [DisputesFilterRule.OverdueClaims]: {
    type: DisplayType.Claim,
    conditions: [{
      type: ConditionType.In,
      left: { type: TerminalType.Identifier, key: 'status' },
      right: {
        type: TerminalType.CodeList,
        category: DisputesCode.ClaimStatus,
        value: [ClaimStatus.Initiated, ClaimStatus.Chargeback]
      }
    }, {
      type: ConditionType.Lesser,
      left: { type: TerminalType.Identifier, key: 'expectedResolutionDate' },
      right: { type: TerminalType.Date, value: 0 }
    }]
  },
  [DisputesFilterRule.OpenClaims]: {
    type: DisplayType.Claim,
    conditions: [{
      type: ConditionType.Unequal,
      left: { type: TerminalType.Identifier, key: 'status' },
      right: { type: TerminalType.Code, category: DisputesCode.ClaimStatus, value: ClaimStatus.Resolved }
    }]
  },
  [DisputesFilterRule.OperatorClaims]: {
    type: DisplayType.Claim,
    sorts: [{
      field: '_assigned',
      direction: 1
    }]
  },
  [DisputesFilterRule.ReasonClaims]: {
    type: DisplayType.Claim,
    sorts: [{
      field: 'cardDetails.reason',
      direction: 1
    }]
  },
  [DisputesFilterRule.InitiatedClaims]: {
    type: DisplayType.Claim,
    conditions: [{
      type: ConditionType.Equal,
      left: { type: TerminalType.Identifier, key: 'status' },
      right: { type: TerminalType.String, value: ClaimStatus.Initiated }
    }]
  },
  [DisputesFilterRule.ChargebackClaims]: {
    type: DisplayType.Claim,
    conditions: [{
      type: ConditionType.Equal,
      left: { type: TerminalType.Identifier, key: 'status' },
      right: { type: TerminalType.String, value: ClaimStatus.Chargeback }
    }]
  },
  [DisputesFilterRule.ResolvedClaims]: {
    type: DisplayType.Claim,
    conditions: [{
      type: ConditionType.Equal,
      left: { type: TerminalType.Identifier, key: 'status' },
      right: { type: TerminalType.String, value: ClaimStatus.Resolved }
    }]
  },
  [DisputesFilterRule.AllClaims]: {
    type: DisplayType.Claim    
  }
}
