import { AttachmentType } from "../code/standard/common";
import { CommonCode } from "../code/system";
import { IndexInfo } from "../info";
import { PropertyInfo } from "../info/prop";
import { QueryInfo } from "../info/query";
import { TypeInfo } from "../info/type";
import { BufferLike, BufferLikeValidator } from "../toolbox/binary";
import { ID_DEFAULT } from "../toolbox/id";
import { pathInstData, pathJoin } from "../toolbox/path";
import { EnumValidator } from "../validator/enum";
import { BUILTIN_ATTACHMENTS, SystemAttachment } from "./builtin/attachment";
import { CommentData } from "./comment/comment";
import { PropertyType } from "./property-type";

/** An attachment that may or may not be uploaded yet. */
export type AttachmentLike = Attachment | AttachmentUpload;

/** A file attachment on a system resource. */
export class Attachment {
  constructor(
    /** Unique identifier of attachment. */
    public _id = ID_DEFAULT,
    /** Institution owning attachment. */
    public _inst = ID_DEFAULT,
    /** Name of attachment. */
    public name = '',
    /** Upload date of attachment. */
    public date = new Date(),
    /** Type of attachment. */
    public type: string = AttachmentType.Other,
    /** User who uploaded attachment */
    public _user?: string,
    //** Comments on this attachment */
    public comments: undefined | CommentData[] = undefined,
    /** True if attachment was deleted. */
    public deleted?: boolean,
    /** Increase to force schema update. */
    public version?: never
  ) { }

  static typeinfo: TypeInfo<Attachment> = {
    _user: ID_DEFAULT,
    comments: [new CommentData()],
    deleted: true,
    version: new EnumValidator([1]) as any
  };

  static propinfo: PropertyInfo<Attachment> = {
    type: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  };

  static queryinfo: QueryInfo<Attachment> = {
    deleted: { $ne: true }
  };

  static indexinfo: IndexInfo<Attachment> = [
    { key: { _inst: 1 } },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  /** True if a value is an attachment. */
  static check(attachment?: AttachmentLike): attachment is Attachment {
    return !!attachment && '_id' in attachment;
  }

  /** Get path to an attachments directory of institution. */
  static path(root: string, _inst: string) {
    return pathInstData(root, _inst, 'attach');
  }

  /** Get path to a specific attachment. */
  static fullpath(root: string, _inst: string, _attachment: string) {
    return pathJoin(Attachment.path(root, _inst), _attachment.slice(-2, -1), _attachment.slice(-1), _attachment);
  }
}

/** An attachment to be uploaded. */
export class AttachmentUpload {
  constructor(
    /** Name of attachment. */
    public name = '',
    /** Data of attachment. */
    public data: BufferLike = '',
    /** Type of attachment */
    public type: string = AttachmentType.Other
  ) { }

  static typeinfo: TypeInfo<AttachmentUpload> = {
    data: new BufferLikeValidator()
  }

  static propinfo: PropertyInfo<AttachmentUpload> = {
    type: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  }

  /** True if a value is an attachment upload. */
  static check(attachment?: AttachmentLike): attachment is AttachmentUpload {
    return !!attachment && !('_id' in attachment);
  }

  /** Create from a builtin. */
  static from(name: SystemAttachment, data: string | Buffer): AttachmentUpload {
    let builtin = BUILTIN_ATTACHMENTS[name];
    return {
      name: builtin.name ?? name,
      type: builtin.type ?? AttachmentType.Other,
      data
    };
  }
}

/** A preview of an attachment. */
export class AttachmentPreview {
  constructor(
    /** Unique identifier of attachment. */
    public _id = ID_DEFAULT,
    /** Name of attachment. */
    public name = '',
    /** Type of attachment. */
    public type: string = AttachmentType.Other
  ) { }

  static propinfo: PropertyInfo<AttachmentPreview> = {
    type: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  }
}/** A resource containing a list of attachments. */
export class AttachmentList {
  constructor(
    /** ID of resource. */
    public _id = ID_DEFAULT,
    /** Institution of resource. */
    public _inst = ID_DEFAULT,
    /** List of attachments on resource. */
    public _attachments: undefined | string[] = undefined
  ) {}

  static typeinfo: TypeInfo<AttachmentList> = {
    _attachments: [ID_DEFAULT]
  }
}