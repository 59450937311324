import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { ClaimFilter, ClaimFilterAssigned, ClaimFilterPreview } from '../../../../../../common/model/claim/filter';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { ClaimFilterQuery, ClaimFilterService } from '../../../common/service/claim-filter.service';
import { CollectionService } from '../../../common/service/collection.service';
import { LogService } from '../../../common/service/log.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-claim-filter',
  templateUrl: './setup-claim-filter.component.html',
  styleUrls: ['./setup-claim-filter.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupClaimFilterComponent extends SetupComponent<ClaimFilter, ClaimFilterQuery, ClaimFilterPreview> {
  readonly Type = ClaimFilterAssigned;
  readonly collection = 'claimFilters';
  readonly route = 'claim-filters';
  resource = new ClaimFilterAssigned();

  static title() {
    return 'Claim Filters';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public http: HttpClient,
    public auth: AuthService,
    public service: ClaimFilterService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }
}
