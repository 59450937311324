import { Component } from '@angular/core';
import { TaskConfigFormList } from '../../../../../../../../common/model/task-config';
import { TaskAccessor } from '../../edit/setup-task-edit.model';

@Component({
  selector: 'app-setup-task-config-form-list',
  templateUrl: './setup-task-config-form-list.component.html',
  styleUrls: ['./setup-task-config-form-list.component.scss']
})
export class SetupTaskConfigFormListComponent extends TaskAccessor<TaskConfigFormList> {}
