import { DisputeStatus } from "../../code/standard/disputes";
import { DisputesCode } from "../../code/system";
import { IndexInfo } from "../../info";
import { PropertyInfo } from "../../info/prop";
import { QueryInfo } from "../../info/query";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { CommentData } from "../comment/comment";
import { LedgerItem } from "../ledger/item";
import { PropertyType } from "../property-type";
import { Transaction } from "../transaction";
import { DisputeCard } from "./dispute";

/** Common fields shared by all disputes. */
export class DisputeBase {

  constructor(
    /** ID of this dispute */
    public _id = ID_DEFAULT,
    /** Institution of this dispute */
    public _inst = ID_DEFAULT,
    /** Unique identifier of transaction for this dispute. */
    public _transaction = ID_DEFAULT,
    /** Ledger items for this dispute */
    public _ledgers: string[] = [],
    /** Current status of dispute. */
    public status = DisputeStatus.NotWorked,
    /** Date that dispute was denied. */
    public deniedDate?: Date,
    /** True if this dispute is marked as deleted. */
    public deleted?: boolean,
    /** Comments on this claim */
    public comments: undefined | CommentData[] = undefined,
    /** Reason this dispute was closed  - users wanted this separate from comments*/
    public resolvedReason?: string,
  ) {}

  static typeinfo: TypeInfo<DisputeBase> = {
    comments: [new CommentData()],
    deleted: true,
    deniedDate: new Date(),
    resolvedReason: '',
    _ledgers: [ID_DEFAULT]
  }
  
  static propinfo: PropertyInfo<DisputeBase> = {
    status: { type: PropertyType.Code, category: DisputesCode.DisputeStatus }
  };

  static queryinfo: QueryInfo<DisputeBase> = {
    deleted: { $ne: true }
  };

  static indexinfo: IndexInfo<DisputeCard> = [
    { key: { _inst: 1 } }
  ];
}

/** Fields a dispute can be joined with. */
export class DisputeLookup {
  constructor(
    /** Transaction linked to dispute. */
    public transaction?: Transaction,
    /** Ledger items linked to a dispute */
    public ledger?: LedgerItem[]
  ) {}

  static typeinfo: TypeInfo<DisputeLookup> = {
    transaction: new Transaction(),
    ledger: [new LedgerItem()]
  }
}