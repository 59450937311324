
/** Data passed to confirmation dialog. */
export class DialogConfirmationData {
  constructor(
    /** Message to display. */
    public message?: string,
    /** Alternate label for yes button. */
    public yes?: string,
    /** Alternate label for no button. */
    public no?: string
  ) {}
}

/** Result of opening confirmation dialog. */
export type DialogConfirmationReturn = boolean;