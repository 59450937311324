<div class="row">
  <button rounded-button button-prefix="event" position="before">{{data.date | dateIso}}</button>
  <div class="fill"></div>
  <button button-prefix="refresh" position="before">Refresh Activity</button>
</div>
<app-card class="fill">
  <table app-grid [source]="source">

    <ng-container grid-column-def="time">
      <grid-header-cell *grid-header-cell-def>Time</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.time | date:'h a'}}</grid-cell>
      <grid-footer-cell *grid-footer-cell-def></grid-footer-cell>
    </ng-container>

    <ng-container *ngFor="let type of data.types" [grid-column-def]="type">
      <grid-header-cell *grid-header-cell-def>{{type | enum:STAT_TYPE_NAME | multiline}}</grid-header-cell>
      <grid-cell *grid-cell-def="let row" [class.disable]="STAT_DISABLE[type]">{{row[type]}}</grid-cell>
      <grid-footer-cell *grid-footer-cell-def>{{footer[type]}}</grid-footer-cell>
    </ng-container>
    
    <grid-header-row *grid-header-row-def="columns"></grid-header-row>
    <grid-row *gridRowDef="let row; columns: columns;"></grid-row>
    <grid-footer-row *grid-footer-row-def="columns"></grid-footer-row>
  </table>
</app-card>