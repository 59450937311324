import { SchemaInfo } from "../../info/schema";
import { TypeInfo } from "../../info/type";
import { ArraySome } from "../../toolbox/array";
import { EnumValidator } from "../../validator/enum";

/** Authentication type for user. */
export type UserAuthentication = UserAuthenticationFusion | UserAuthenticationMicrosoft;

/** Simple password authentication. */
export class UserAuthenticationFusion {
  constructor(
    /** Password of user, stored as bcrypt hash. */
    public password = '',
    /** True if user must reset their password. */
    public reset?: boolean
  ) {}

  /** Check if a value is password validation. */
  static check(authentication: UserAuthentication): authentication is UserAuthenticationFusion {
    return 'password' in authentication;
  }

  static schemainfo: SchemaInfo<UserAuthenticationFusion> = {
    password: {
      bsonType: 'string',
      minLength: 1
    }
  }

  static typeinfo: TypeInfo<UserAuthenticationFusion> = {
    reset: false
  }
}

/** Simple password authentication. */
export class UserAuthenticationMicrosoft {
  constructor(
    /** Sentinel value this is a microsoft login. */
    public microsoft: true = true
  ) {}

  static typeinfo: TypeInfo<UserAuthenticationMicrosoft> = {
    microsoft: new EnumValidator([true])
  }

  /** Check if a value is microsoft validation. */
  static check(authentication: UserAuthentication): authentication is UserAuthenticationMicrosoft {
    return 'microsoft' in authentication;
  }
}

/** List of user authentication types. */
export const USER_AUTHENTICATIONS: ArraySome<UserAuthentication> = [new UserAuthenticationFusion(), new UserAuthenticationMicrosoft()];