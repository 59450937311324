import { Component } from '@angular/core';
import { PropertyType } from '../../../../../../../../common/model/property-type';
import { AuthService } from '../../../../../common/service/auth.service';
import { FormFieldAccessor } from '../setup-form-field.model';

@Component({
  selector: 'app-setup-form-field-boolean',
  templateUrl: './setup-form-field-boolean.component.html',
  styleUrls: ['./setup-form-field-boolean.component.scss'],
  host: {
    class: 'column'
  }
})
export class SetupFormFieldBooleanComponent extends FormFieldAccessor<PropertyType.Boolean> {
  constructor(
    public auth: AuthService
  ) {
    super();
  }
}
