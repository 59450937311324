import { ErrorResponse } from "../../message/error";
import { arrayDefined } from "../../toolbox/array";
import { BuiltinMap } from "../../toolbox/builtinmap";
import { NoId, idOmit } from "../../toolbox/id";
import { errorPartition, errorResponse } from "../../toolbox/message";
import { FormList } from "../form/list";
import { SystemForm } from "./form";
import { ClaimForm } from "./form/claim";
import { DisputeForm } from "./form/dispute";
import { TestForm } from "./form/test";
import { SystemFormula } from "./formula";
import { ClaimFormula } from "./formula/claim";
import { StandardFormula } from "./formula/standard";

/** List of all built-in forms. */
export type SystemFormList = DisputesFormList | StandardFormList;

/** Built-in general form lists. */
export enum StandardFormList {
  Test = 'Test'
}

/** Built-in disputes form lists. */
export enum DisputesFormList {
  ClaimACHAdvanced            = 'Claim - ACH - Advanced',
  ClaimACHIntake              = 'Claim - ACH - Intake',
  ClaimCardAdvanced           = 'Claim - Card - Advanced',
  ClaimCardIntake             = 'Claim - Card - Intake',
  ClaimCheckAdvanced          = 'Claim - Check - Advanced',
  ClaimCheckIntake            = 'Claim - Check - Intake',
  ClaimCreditBureauAdvanced   = 'Claim - Credit Bureau - Advanced',
  ClaimResolution             = 'Claim - Resolution',
  DisputeCard                 = 'Dispute - Card',
  DisputePrearbitrationReview = 'Dispute - Pre-Arbitration Review'
}

/** Subform of a form list. */
interface BulitinFormSection {
  /** Form of subform. */
  form: SystemForm
  /** Condition to make subform appear. */
  formula?: SystemFormula
}

/** Configuration for a built-in form list. */
interface BuiltinFormList {
  /** Name override for form list. */
  name?: string
  /** Sections of form list. */
  sections: BulitinFormSection[]
}

/** List of builtin forms. */
export const BUILTIN_FORM_LISTS: Record<SystemFormList, BuiltinFormList> = {
  [DisputesFormList.DisputePrearbitrationReview]: {
    sections: [{
      form: DisputeForm.PrearbitrationDate
    },
    ]
  },
  [DisputesFormList.ClaimACHAdvanced]: {
    sections: [{
      form: ClaimForm.ACHAdvancedReason
    }, {
      form: ClaimForm.ACHAdvancedReasonNotInAccordance,
      formula: ClaimFormula.ACHDetailsTypeNotInAccordanceConsumer
    }, {
      form: ClaimForm.ACHAdvancedReasonRCKEntryIneligible,
      formula: ClaimFormula.ACHDetailsTypeRCKEntryIneligible
    }, {
      form: ClaimForm.AdvancedAdditionalDetails
    }]
  },
  [DisputesFormList.ClaimACHIntake]: {
    sections: [{
      form: ClaimForm.ACHIntakeReason
    }, {
      form: ClaimForm.ACHIntakeReasonNotInAccordance,
      formula: ClaimFormula.ACHDetailsTypeNotInAccordanceConsumer
    }, {
      form: ClaimForm.ACHIntakeReasonRCKEntryIneligible,
      formula: ClaimFormula.ACHDetailsTypeRCKEntryIneligible
    }, {
      form: ClaimForm.IntakeAdditionalDetails
    }]
  },
  [DisputesFormList.ClaimCardAdvanced]: {
    sections: [{
      form: ClaimForm.CardAdvancedReason
    }, {
      form: ClaimForm.CardAdvancedReasonAtmTransaction,
      formula: ClaimFormula.CardDetailsTypeAtmTransaction
    }, {
      form: ClaimForm.CardAdvancedReasonCancellation,
      formula: ClaimFormula.CardDetailsTypeCancellation
    }, {
      form: ClaimForm.CardAdvancedCancellationCancelProduct,
      formula: ClaimFormula.CancellationCancelProduct
    }, {
      form: ClaimForm.CardAdvancedCancellationNotExpected,
      formula: ClaimFormula.CancellationNotExpected
    }, {
      form: ClaimForm.CardAdvancedCancellationNotReceived,
      formula: ClaimFormula.CancellationNotReceived
    }, {
      form: ClaimForm.CardAdvancedCancellationPaidOtherMeans,
      formula: ClaimFormula.CancellationPaidOtherMeans
    }, {
      form: ClaimForm.CardAdvancedCancellationReturnedRefused,
      formula: ClaimFormula.CancellationReturnedRefused
    }, {
      form: ClaimForm.CardAdvancedReasonFraudAbsent,
      formula: ClaimFormula.CardDetailsTypeFraudAbsent
    }, {
      form: ClaimForm.CardAdvancedPoliceReport,
      formula: ClaimFormula.PoliceReport
    }, {
      form: ClaimForm.CardAdvancedPoliceReportFiled,
      formula: ClaimFormula.PoliceReportFiled
    }, {
      form: ClaimForm.CardAdvancedReasonFraudPresent,
      formula: ClaimFormula.CardDetailsTypeFraudPresent
    }, {
      form: ClaimForm.CardAdvancedReasonProcessingError,
      formula: ClaimFormula.CardDetailsTypeProcessingError
    }, {
      form: ClaimForm.CardAdvancedProcessingErrorAdditionalCharge,
      formula: ClaimFormula.ProcessingErrorAdditionalCharge
    }, {
      form: ClaimForm.CardAdvancedProcessingErrorComputationalError,
      formula: ClaimFormula.ProcessingErrorComputationalError
    }, {
      form: ClaimForm.CardAdvancedProcessingErrorCreditNotReceived,
      formula: ClaimFormula.ProcessingErrorCreditNotReceived
    }, {
      form: ClaimForm.CardAdvancedProcessingErrorDuplicateTransaction,
      formula: ClaimFormula.ProcessingErrorDuplicateTransaction
    }, {
      form: ClaimForm.CardAdvancedProcessingErrorIncorrectTransactionAmount,
      formula: ClaimFormula.ProcessingErrorIncorrectTransactionAmount
    }, {
      form: ClaimForm.CardAdvancedProcessingErrorIncorrectTransactionCode,
      formula: ClaimFormula.ProcessingErrorIncorrectTransactionCode
    }, {
      form: ClaimForm.CardAdvancedProcessingErrorLatePresentment,
      formula: ClaimFormula.ProcessingErrorLatePresentment
    }, {
      form: ClaimForm.CardAdvancedProcessingErrorUnrecognizedTransaction,
      formula: ClaimFormula.ProcessingErrorUnrecognizedTransaction
    }, {
      form: ClaimForm.CardAdvancedClosed
    }, {
      form: ClaimForm.CardAdvancedCloseReason,
      formula: ClaimFormula.CardNotClosed
    }, {
      form: ClaimForm.AdvancedAdditionalDetails
    }]
  },
  [DisputesFormList.ClaimResolution]: {
    sections: [{
      form: ClaimForm.AdvancedAdditionalDetails
      }, {
      form: ClaimForm.ResolvedReason
    }]
  },
  [DisputesFormList.ClaimCardIntake]: {
    sections: [{
      form: ClaimForm.CardIntakeReason
    }, {
      form: ClaimForm.CardIntakeReasonAtmTransaction,
      formula: ClaimFormula.CardDetailsTypeAtmTransaction
    }, {
      form: ClaimForm.CardIntakeReasonCancellation,
      formula: ClaimFormula.CardDetailsTypeCancellation
    }, {
      form: ClaimForm.CardIntakeCancellationCancelProduct,
      formula: ClaimFormula.CancellationCancelProduct
    }, {
      form: ClaimForm.CardIntakeCancellationNotExpected,
      formula: ClaimFormula.CancellationNotExpected
    }, {
      form: ClaimForm.CardIntakeCancellationNotReceived,
      formula: ClaimFormula.CancellationNotReceived
    }, {
      form: ClaimForm.CardIntakeCancellationPaidOtherMeans,
      formula: ClaimFormula.CancellationPaidOtherMeans
    }, {
      form: ClaimForm.CardIntakeCancellationReturnedRefused,
      formula: ClaimFormula.CancellationReturnedRefused
    }, {
      form: ClaimForm.CardIntakeReasonFraudAbsent,
      formula: ClaimFormula.CardDetailsTypeFraudAbsent
    }, {
      form: ClaimForm.CardIntakePoliceReport,
      formula: ClaimFormula.PoliceReport
    }, {
      form: ClaimForm.CardIntakePoliceReportFiled,
      formula: ClaimFormula.PoliceReportFiled
    }, {
      form: ClaimForm.CardIntakeReasonFraudPresent,
      formula: ClaimFormula.CardDetailsTypeFraudPresent
    }, {
      form: ClaimForm.CardIntakeReasonProcessingError,
      formula: ClaimFormula.CardDetailsTypeProcessingError
    }, {
      form: ClaimForm.CardIntakeProcessingErrorAdditionalCharge,
      formula: ClaimFormula.ProcessingErrorAdditionalCharge
    }, {
      form: ClaimForm.CardIntakeProcessingErrorComputationalError,
      formula: ClaimFormula.ProcessingErrorComputationalError
    }, {
      form: ClaimForm.CardIntakeProcessingErrorCreditNotReceived,
      formula: ClaimFormula.ProcessingErrorCreditNotReceived
    }, {
      form: ClaimForm.CardIntakeProcessingErrorDuplicateTransaction,
      formula: ClaimFormula.ProcessingErrorDuplicateTransaction
    }, {
      form: ClaimForm.CardIntakeProcessingErrorIncorrectTransactionAmount,
      formula: ClaimFormula.ProcessingErrorIncorrectTransactionAmount
    }, {
      form: ClaimForm.CardIntakeProcessingErrorIncorrectTransactionCode,
      formula: ClaimFormula.ProcessingErrorIncorrectTransactionCode
    }, {
      form: ClaimForm.CardIntakeProcessingErrorLatePresentment,
      formula: ClaimFormula.ProcessingErrorLatePresentment
    }, {
      form: ClaimForm.CardIntakeProcessingErrorUnrecognizedTransaction,
      formula: ClaimFormula.ProcessingErrorUnrecognizedTransaction
    }, {
      form: ClaimForm.CardIntakeClosed
    }, {
      form: ClaimForm.CardIntakeCloseReason,
      formula: ClaimFormula.CardNotClosed
    }, {
      form: ClaimForm.IntakeAdditionalDetails
    }]
  },
  [DisputesFormList.ClaimCheckAdvanced]: {
    sections: [{
      form: ClaimForm.Check
    }]
  },
  [DisputesFormList.ClaimCheckIntake]: {
    sections: [{
      form: ClaimForm.CheckIntakeReason
    }, {
      form: ClaimForm.CheckIntakeReasonForgery,
      formula: ClaimFormula.CheckCategoryForgery
    },
    {
      form: ClaimForm.CheckIntakeReasonUnauthorized,
      formula: ClaimFormula.CheckCategoryUnauthorized
    }]
  },
  [DisputesFormList.ClaimCreditBureauAdvanced]: {
    sections: [{
      form: ClaimForm.CreditBureau
    }]
  },
  [StandardFormList.Test]: {
    sections: [{
      form: TestForm.Field
    }, {
      form: TestForm.Multiple,
      formula: StandardFormula.Test
    }]
  },
  [DisputesFormList.DisputeCard]: {
    sections: [{
      form: DisputeForm.Card
    }]
  },
};

/** Recursively determine forms needed by form list. */
export function builtinFormListForms(map: BuiltinMap, name: SystemFormList) {
  let builtin = map.formLists.builtin(name);
  return arrayDefined(builtin.sections.flatMap(section => section.form));
}

/** Recursively determine formulas needed by form list. */
export function builtinFormListFormulas(map: BuiltinMap, name: SystemFormList) {
  let builtin = map.formLists.builtin(name);
  return arrayDefined(builtin.sections.map(section => section.formula));
}

/** Create a concrete form list from a builtin form list. */
export function builtinFormList(map: BuiltinMap, name: SystemFormList, _inst: string): NoId<FormList> | ErrorResponse {
  let builtin = map.formLists.builtin(name);
  let list = idOmit(new FormList());
  list.name = builtin.name ?? name;
  list._inst = _inst;

  let result = builtin.sections.map(section => {
    let _form = map.forms.id(_inst, section.form);
    if (errorResponse(_form)) return _form;

    let _formula = section.formula ? map.formulas.id(_inst, section.formula) : undefined;
    if (errorResponse(_formula)) return _formula;
    return { _form, _formula };
  });

  let [error, sections] = errorPartition('There was an error mapping form list sections.', result);
  if (error) return error;
  list.sections = sections;

  return list;
}
