import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { TerminalIdentifier } from "../../../../../../../../common/model/formula/terminal";
import { ArraySome } from '../../../../../../../../common/toolbox/array';
import { Pair } from "../../../../../../../../common/toolbox/object";
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-identifier',
  templateUrl: './setup-formula-identifier.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-identifier.component.scss']
})
export class SetupFormulaIdentifierComponent extends BlockComponent<TerminalIdentifier> {

  /** Emits when identifier changes. */
  identifier = new Subject<string>();

  override onKeys(keys: ArraySome<Pair[]>) {
    super.onKeys(keys);
    if (!keys.flat().find(pair => pair.value === this.block.key)) this.block.key = keys[0][0]?.value ?? '';
  }
}
