import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from "../../../../../../common/model/task";
import { AuthService } from '../../../common/service/auth.service';
import { TaskService } from '../../../common/service/task.service';
import { TASK_TYPE_ICON, TaskOpen } from '../../../common/toolbox/task';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  host: {
    class: 'row'
  }
})
export class TaskListComponent {
  readonly TASK_TYPE_ICON = TASK_TYPE_ICON;
  
  /** Tasks to display. */
  @Input() set tasks(_tasks: string[]) { this.refresh(_tasks); }
  /** Callback when a task should be opened. */
  @Output() open = new EventEmitter<TaskOpen>();

  /** Tasks to display. */
  protected _tasks: Task[] = [];

  constructor(
    private authService: AuthService,
    private taskService: TaskService
  ) {}

  /** Callback when selecting a task. */
  async onTask(task: Task) {
    this.open.next({ _task: task._id });
  } 

  /** Refresh list of displayed tasks. */
  private async refresh(_tasks: string[]) {
    this._tasks = await this.taskService.items(_tasks.map(_id => ({ _inst: this.authService._inst, _id })));
  }
}
