<app-card body class="column fill">
  <div class="row">
    <button color="red">Red</button>
    <button color="orange">Orange</button>
    <button color="yellow">Yellow</button>
    <button color="green">Green</button>
    <button color="blue">Blue</button>
    <button color="purple">Purple</button>
    <button color="pink">Pink</button>
    <button color="navy">Navy</button>
    <button color="black">Black</button>
    <button color="white">White</button>
  </div>
  <div class="colors">
    <button class="color" *ngFor="let color of colors" [attr.color]="color"></button>
  </div>
</app-card>