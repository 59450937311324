import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorResponse } from '../../../../../../../common/message/error';
import { UserPostResponse } from '../../../../../../../common/message/user';
import { AttachmentUpload } from '../../../../../../../common/model/attachment';
import { InstitutionPreview } from "../../../../../../../common/model/institution";
import { FeatureType } from '../../../../../../../common/model/organization/feature';
import { PermissionEdit } from '../../../../../../../common/model/permission';
import { ColumnSize } from "../../../../../../../common/model/table";
import { UserAuthenticationFusion } from '../../../../../../../common/model/user/authentication';
import { UserGrant } from '../../../../../../../common/model/user/grant';
import { User, UserUpsert } from "../../../../../../../common/model/user/user";
import { WorkQueuePreview } from "../../../../../../../common/model/work/queue";
import { arrayRemove, arraySingle, arraySome } from '../../../../../../../common/toolbox/array';
import { atobUnicode, binaryBase64 } from '../../../../../../../common/toolbox/binary';
import { csvSplit } from '../../../../../../../common/toolbox/csv';
import { MaybeId, idNull } from "../../../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../../../common/toolbox/message";
import { deepCopy, objectValues, safeAssign } from '../../../../../../../common/toolbox/object';
import { DialogService } from '../../../../common/component/dialog/dialog.service';
import { PermissionsGridData } from '../../../../common/component/permissions-grid/permissions-grid.model';
import { AuthService } from '../../../../common/service/auth.service';
import { LogService } from '../../../../common/service/log.service';
import { ProfileService } from '../../../../common/service/profile.service';
import { WorkQueueService } from '../../../../common/service/work-queue.service';
import { DIALOG_CANCEL_SYMBOL } from '../../../../common/toolbox/dialog';
import { patchRequest, postRequest } from '../../../../common/toolbox/request';
import { ClientSource } from '../../../../common/toolbox/source/client';
import { SetupEditComponent } from '../../setup-edit.component';
import { SetupUserPasswordDialogComponent } from '../password-dialog/setup-user-password-dialog.component';
import { SetupUserPasswordData, SetupUserPasswordReturn } from '../password-dialog/setup-user-password-dialog.model';

@Component({
  selector: 'app-setup-user-edit',
  templateUrl: './setup-user-edit.component.html',
  styleUrls: ['./setup-user-edit.component.scss']
})
export class SetupUserEditComponent extends SetupEditComponent<UserUpsert, UserPostResponse> {
  readonly FeatureType = FeatureType;

  /** Current user being edited. */
  value = User.upsert();
  /** Grant being edited, or undefined if grant is revoked. */
  grant = new UserGrant();
  /** Current data bound to grid. */
  data = new PermissionsGridData();
  /** List of available institutions select. */
  institutions: InstitutionPreview[] = [];
  
  /** True if user already has access to current institution. */
  @Input() access = false;
  /** User before any changes were made. */
  @Input() backup?: MaybeId<User>;

  /** Emits when backup is changed. */
  @Output() backupChange = new EventEmitter<MaybeId<User>>();

  /** List of work queues to assign this user to. */
  queues = new ClientSource<WorkQueuePreview>();
  /** Columns to display in work queue grid. */
  queueColumns: (keyof WorkQueuePreview)[] = ['name'];
  /** Sizes for work queue columns. */
  sizes: ColumnSize[] = ['1fr'];
  /** Mapping of IDs to work queues present in profile. */
  queueFallback: Record<string, boolean> = {};

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private auth: AuthService,
    private dialog: DialogService,
    private http: HttpClient,
    private profileService: ProfileService,
    private queueService: WorkQueueService
  ) {
    super(elementRef, log);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when revoking user access to institution. */
  async onRevoke() {
    if (!arrayRemove(this.value.grants, this.grant)) {
      return this.log.show(new ErrorResponse(`Failed to find user grant for institution: ${this.auth._inst}`));
    }

    this.value.grants = [...this.value.grants];
    this.onSubmit();
  }

  override async onSubmit() {
    // Confirm password if new user.
    if (idNull(this.value._id)) {
      let name = this.value.name ?? 'New User';
      let result = await this.dialog.open<SetupUserPasswordReturn, SetupUserPasswordData>(SetupUserPasswordDialogComponent, { name });
      if (typeof result !== 'string') return new ErrorResponse('A new user was not created.');
      this.value.authentication = new UserAuthenticationFusion(result);
    }

    // Reset to new user if successful.
    let response = await super.onSubmit();
    if (!errorResponse(response) && idNull(this.value._id)) this.reset(new User());
    return response;
  }

  /** Callback when changing current profile. */
  async onProfile(_id: string) {
    this.grant._profile = _id;
    this.relist();
  }

  /** Get and import users from a .CSV file */
  async onImport(upload: AttachmentUpload[]) {
    if (!arraySingle(upload)) return;

    // Validate expected headers were found.
    let parsed = await binaryBase64(upload[0].data);
    let rows = csvSplit(atobUnicode(parsed));
    let expectedHeaders = ['Name', 'Email', 'Password', 'Profile'];
    if (!arraySome(rows)) return this.log.show('Could not find headers');
    for (let i = 0; i < expectedHeaders.length; ++i) {
      if (rows[0][i]?.trim() !== expectedHeaders[i])
        return this.log.show(`Column ${i} was invalid. (expected: ${expectedHeaders[i]}, found: ${rows[0][i]}`);
    }

    rows = rows.slice(1);
    if (rows.length === 0) return;
    let profiles = await this.profileService.previews(this.auth._inst);
    let profileMap = new Map<string, string>(profiles.map(({ name, _id }) => [name, _id]));

    // Columns are expected in order - name, email, password, profile.
    // TODO consider revisiting this and automate serialization from CSV utilizing validator.parse
    let users: UserUpsert[] = [];
    for (let [i, row] of rows.entries()) {
      let [name, email, password, profile] = row;
      // Skip any rows missing information.
      if (!name || !email || !password || !profile) {
        this.log.show(`Skipping row ${i} because it was missing a column.`);
        continue;
      }

      let _profile = profileMap.get(profile.trim());
      if (!_profile) {
        this.log.show(`Skipping row ${i} because there is no profile named: ${profile}`);
        continue;
      }

      users.push({
        ...User.upsert(),
        _org: this.auth._org,
        name,
        email,
        authentication: { password },
        grants: [new UserGrant(this.auth._inst, _profile)]
      });
    }

    let response = await postRequest(this.http, 'users', { items: users });
    if (errorResponse(response)) {
      this.log.show(response);
      return;
    }

    // Refresh with new user.
    this.submit.emit(this.value);
    this.log.show(`Successfully added ${users.length} new users.`);
    if (idNull(this.value._id)) this.reset(new User());
  }

  /** Reset password for user. */
  async onResetPassword() {
    if (!this.value._id) return this.log.show('Cannot reset password for new users.');

    const name = this.value.name ?? 'User';
    const password = await this.dialog.open<SetupUserPasswordReturn, SetupUserPasswordData>(SetupUserPasswordDialogComponent, { name, reset: true });
    if (!password || password === DIALOG_CANCEL_SYMBOL) return;

    const result = await patchRequest(this.http, 'users/reset-password', { _user: this.value._id, password });
    this.log.show(errorResponse(result) ? result : result.success);
  }

  /** Submit current changes to user. */
  async push() {
    // Apply new permissions and queues to grant.
    return postRequest(this.http, 'users', { items: [this.value] });
  }

  /** Reset current form with new user. */
  async reset(value: MaybeId<User>) {
    this.value = User.upsert();
    this.value._org = value._org = this.auth._org;
    safeAssign(this.value, value);

    // Grant access to current institution if not provided.
    let profiles = await this.profileService.previews(this.auth._inst);
    let grant = value.grants.find(grant => grant._inst === this.auth._inst);
    if (grant) this.grant = grant;
    else value.grants.push(this.grant = new UserGrant(this.auth._inst, profiles[0]!._id));
    this.access = !!grant;

    // Set this modified user as new backup.
    this.backupChange.next(this.backup = deepCopy(value));
    this.relist();
  }

  /** Decompress profile permissions into editable list. */
  private async relist() {
    // Ensure a valid profile is selected.
    await this.reprofile();

    // Determine selected work queues of user.
    let queues = new Set(this.grant._queues);
    this.queues.items = await this.queueService.previews(this.auth._inst);
    this.queues.selection.next(new Set(this.queues.items.filter(d => queues.has(d._id))));

    // Get list of selected permissions for user and profile.
    let profile = await this.profileService.item({ _inst: this.auth._inst, _id: this.grant._profile });
    this.data.fallback = PermissionEdit.record(profile.permissions);
    this.data.main = PermissionEdit.record(this.grant.permissions);
    this.data = { ...this.data };

    // Get list of selected queues for profile.
    this.queueFallback = {};
    for (let _queue of profile._queues) {
      this.queueFallback[_queue] = true;
    }
  }

  /** Set initial profile if not valid. */
  private async reprofile() {
    let profiles = await this.profileService.previews(this.auth._inst);
    let valid = profiles.find(p => p._id === this.grant._profile);
    this.grant._profile = valid ? this.grant._profile : profiles[0]!._id;
  }

  /** Synchronize changes made back to profile. */
  protected resync() {
    this.grant.permissions = PermissionEdit.array(objectValues(this.data.main));
    this.grant._queues = [...this.queues.selection.value].map(d => d._id);
  }
}
