import { ID_DEFAULT } from "../toolbox/id";
import { IndexInfo } from "../info";
import { PropertyInfo } from "../info/prop";
import { ImportCode } from "../code/system";
import { PropertyType } from "./property-type";

/** Many-to-many account-card relationship. */
export class AccountCardRelationship {
  constructor(
    /** Unique identifier of this relationship. */
    public _id = ID_DEFAULT,
    /** Account linked to card. */
    public _account = ID_DEFAULT,
    /** Unique ID of card. */
    public _card = ID_DEFAULT,
    /** Relationship code. */
    public relationship = ''
  ) {}

  static propinfo: PropertyInfo<AccountCardRelationship> = {
    relationship: { type: PropertyType.Code, category: ImportCode.AccountCard }
  }

  static indexinfo: IndexInfo<AccountCardRelationship> = [
    { key: { _account: 1, _card: 1 }, unique: true }
  ];
}

/** Unique fields of an account-card pair for lookup. */
export class AccountCardReference {
  constructor(
    /** Account linked to card. */
    public _account = ID_DEFAULT,
    /** Unique ID of card. */
    public _card = ID_DEFAULT
  ) {}
}