import { InjectionToken } from "@angular/core";
import { AccountJoin, AccountJoinLoan } from "../../../../../../common/model/account/account";
import { Card } from "../../../../../../common/model/card";
import { ClaimACH, ClaimDisputes, ClaimDisputesType, ClaimJoin } from "../../../../../../common/model/claim/claim";
import { Dispute } from "../../../../../../common/model/dispute/dispute";
import { MemberJoin } from "../../../../../../common/model/member-join";
import { Transaction } from "../../../../../../common/model/transaction";
import { NoId } from "../../../../../../common/toolbox/id";

/** Injection token for accessing claim intake data. */
export const CLAIM_INTAKE_STEP_STATE = new InjectionToken('CLAIM_INTAKE_STEP_STATE');

/** Data passed to claim intake. */
export class ClaimIntakeData {
  constructor(
    /** Type of claim to open. */
    public type: ClaimDisputesType
  ) {}
}

/** State of claim intake. */
export class ClaimIntakeState {
  constructor(
    /** Claim being opened. */
    public claim: NoId<ClaimDisputes> = new ClaimACH(),
    /** List of disputes for new claim. */
    public disputes: NoId<Dispute>[] = [],
    /** Account of claim. */
    public account: AccountJoin = new AccountJoinLoan(),
    /** Primary member of claim. */
    public member = new MemberJoin(),
    /** Card of claim */
    public card = new Card(),
    /** Whether to show loading indicator on side panels (currently only used for member) */
    public loading = false,
    /** List of transactions on claim. */
    public transactions: Transaction[] = [],
    /** List of existing claims found for the current member, if any exist. */
    public claims: ClaimJoin[] = [],
    /** Name of current step. */
    public name = ''
  ) { }
}