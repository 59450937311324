import { AttachmentType, DocumentTemplateType } from "../code/standard/common";
import { CommonCode } from "../code/system";
import { CollectionExporter, CollectionInfo, collectioninfoIds, collectioninfoWalk } from "../info/collection";
import { PropertyInfo } from "../info/prop";
import { TypeInfo } from "../info/type";
import { UnionInfo } from "../info/union";
import { ArraySome } from "../toolbox/array";
import { BuiltinEnumeration, BuiltinKey } from "../toolbox/builtinmap";
import { EnumMap } from "../toolbox/enum";
import { ID_DEFAULT } from "../toolbox/id";
import { EnumValidator } from "../validator/enum";
import { EventResult } from "./event/result";
import { AccountFormResult } from "./event/result/account";
import { ClaimAttachmentAddResult, ClaimReviewResult, DisputeWithdrawResult } from "./event/result/claim";
import { TriggerRunResult } from "./event/result/trigger";
import { FusionCollection, FusionCollectionName } from "./fusion";
import { PropertyType } from "./property-type";

/** Configuration for a specific task type. */
export type TaskConfig =
  | TaskConfigClaimAttachment
  | TaskConfigClaimReview
  | TaskConfigDisputeWithdraw
  | TaskConfigDocumentTemplate
  | TaskConfigForm
  | TaskConfigFormList
  | TaskConfigHeadless;

/** Possible types of tasks that can be performed. */
export enum TaskType {
  ClaimAttachment   = 'claimAttachment',
  ClaimReview       = 'claimReview',
  DisputeWithdraw   = 'disputeWithdraw',
  DocumentTemplate  = 'documentTemplate',
  Form              = 'form',
  FormList          = 'formList',
  Headless          = 'headless'
}

/** Tagged type of all task types. */
export interface TaskConfigTag<T extends TaskType = TaskType> {
  /** Type of task. */
  type: T
}

/** Configuration for a claim review result. */
export class TaskConfigClaimReviewConfig {

  constructor(
    /** Message to display. */
    public message?: string,
    /** Triggers to run. */
    public _triggers?: string[],
    /** Formula to enable selection. */
    public _enable?: string,
    /** Set to false to hide button. */
    public show?: boolean
  ) {}

  static typeinfo: TypeInfo<TaskConfigClaimReviewConfig> = {
    message: '',
    _triggers: [ID_DEFAULT],
    _enable: '',
    show: true
  }

  static collectioninfo: CollectionInfo<FusionCollection, TaskConfigClaimReviewConfig> = {
    _triggers: 'triggers',
    _enable: 'triggers'
  }
}

/** Configuration for reviewing a dispute. */
export class TaskConfigClaimReview implements TaskConfigTag<TaskType.ClaimReview> {
  readonly type = TaskType.ClaimReview;

  constructor(
    /** Form list to display for this task. */
    public _formList = ID_DEFAULT,
    /** Approve configuration. */
    public approve = new TaskConfigClaimReviewConfig(),
    /** Deny configuration. */
    public deny = new TaskConfigClaimReviewConfig()
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, TaskConfigClaimReview> = {
    _formList: 'formLists'
  }
}

/** Configuration for attaching a file. */
export class TaskConfigClaimAttachment implements TaskConfigTag<TaskType.ClaimAttachment> {
  readonly type = TaskType.ClaimAttachment;

  constructor(
    /** List of attachment types to display. */
    public types: ArraySome<string> = [AttachmentType.Other]
  ) { }

  static propinfo: PropertyInfo<TaskConfigClaimAttachment> = {
    types: { type: PropertyType.Code, category: CommonCode.AttachmentType, multiple: true }
  }
}

/** Configuration for withdrawing a dispute. */
export class TaskConfigDisputeWithdraw implements TaskConfigTag<TaskType.DisputeWithdraw> {
  readonly type = TaskType.DisputeWithdraw;
}

/** Configuration for running a headless trigger-only task. */
export class TaskConfigHeadless implements TaskConfigTag<TaskType.Headless> {
  readonly type = TaskType.Headless;
}

/** Configuration for editing a form. */
export class TaskConfigForm implements TaskConfigTag<TaskType.Form> {
  readonly type = TaskType.Form;

  constructor(
    /** Form used by task (if no form list is provided). */
    public _form = ID_DEFAULT,
  ) { }


  static collectioninfo: CollectionInfo<FusionCollection, TaskConfigForm> = {
    _form: 'forms',
  }
}

/** Configuration for editing a form list. */
export class TaskConfigFormList implements TaskConfigTag<TaskType.FormList> {
  readonly type = TaskType.FormList;

  constructor(
    /** Form list used by this task. */
    public _formList = ID_DEFAULT
  ) { }


  static collectioninfo: CollectionInfo<FusionCollection, TaskConfigFormList> = {
    _formList: 'formLists'
  }
}

/** Configuration for sending a letter. */
export class TaskConfigDocumentTemplate implements TaskConfigTag<TaskType.DocumentTemplate> {
  readonly type = TaskType.DocumentTemplate;

  constructor(
    /** Form to display above document template selector. */
    public _form?: string,
    /** Filter for which transactions to display. */
    public _transactionFilter?: string,
    /** List of available document template types to show. */
    public documentTemplateTypes: ArraySome<DocumentTemplateType> = [DocumentTemplateType.General],
    /** Attachment type for newly-attached documents, defaulting to other. */
    public attachmentType?: string
  ) { }

  static typeinfo: TypeInfo<TaskConfigDocumentTemplate> = {
    _form: ID_DEFAULT,
    _transactionFilter: ID_DEFAULT,
    documentTemplateTypes: [new EnumValidator(DocumentTemplateType), new EnumValidator(DocumentTemplateType)],
    attachmentType: ID_DEFAULT
  }

  static propinfo: PropertyInfo<TaskConfigDocumentTemplate> = {
    documentTemplateTypes: { type: PropertyType.Code, category: CommonCode.DocumentTemplate, multiple: true },
    attachmentType: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  }

  static collectioninfo: CollectionInfo<FusionCollection, TaskConfigDocumentTemplate> = {
    _form: 'forms',
    _transactionFilter: 'formulas'
  }
}

/** Mapping of task types to configuration objects. */
export class TaskConfigClass {
  [TaskType.ClaimAttachment] = new TaskConfigClaimAttachment();
  [TaskType.ClaimReview] = new TaskConfigClaimReview();
  [TaskType.DisputeWithdraw] = new TaskConfigDisputeWithdraw();
  [TaskType.DocumentTemplate] = new TaskConfigDocumentTemplate();
  [TaskType.Form] = new TaskConfigForm();
  [TaskType.FormList] = new TaskConfigFormList();
  [TaskType.Headless] = new TaskConfigHeadless();
}

/** Walk collection info of a task config */
export function taskConfigWalk(config: TaskConfig, callback: (parent: any, key: keyof any, collection: FusionCollectionName | CollectionExporter<FusionCollection>) => any): void {
  let typed = TASK_CONFIG_UNIONINFO.classes[config.type];
  collectioninfoWalk<FusionCollection, TaskConfig>(config, typed, callback);
}

/** Get list of system values in a task config. */
export function taskConfigValues<C extends BuiltinKey>(config: TaskConfig, collection: C): BuiltinEnumeration[C][] {
  return collectioninfoIds<FusionCollection, TaskConfig>(config, TASK_CONFIG_UNIONINFO.classes[config.type]).filter(id => id[0] === collection).map(id => id[1] as BuiltinEnumeration[C]);
}

/** Union type information for features. */
export const TASK_CONFIG_UNIONINFO: UnionInfo<TaskConfig, TaskType> = {
  tag: 'type',
  classes: new TaskConfigClass()
}

/** Name of each task type. */
export const TASK_TYPE_NAME: EnumMap<TaskType> = {
  [TaskType.ClaimAttachment]: 'Attach Claim File',
  [TaskType.ClaimReview]: 'Review Claim',
  [TaskType.DisputeWithdraw]: 'Withdraw Dispute',
  [TaskType.DocumentTemplate]: 'Document Template',
  [TaskType.Form]: 'Form',
  [TaskType.FormList]: 'Form List',
  [TaskType.Headless]: 'Headless'
};

/** Mapping of task types to event result objects. */
export const TASK_EVENT_CLASS: Record<TaskType, EventResult> = {
  [TaskType.ClaimAttachment]: new ClaimAttachmentAddResult(),
  [TaskType.ClaimReview]: new ClaimReviewResult(),
  [TaskType.DisputeWithdraw]: new DisputeWithdrawResult(),
  [TaskType.DocumentTemplate]: new AccountFormResult(),
  [TaskType.Form]: new AccountFormResult(),
  [TaskType.FormList]: new AccountFormResult(),
  [TaskType.Headless]: new TriggerRunResult()
};
