import { ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Output, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MENU_PARENT, MenuCloseReason } from './menu.model';
import { merge, Observable, startWith, switchMap } from 'rxjs';
import { MenuItemComponent } from './item/menu-item.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'appMenu',
  host: {
    '(click)': 'onClick()'
  },
  providers: [{
    provide: MENU_PARENT,
    useExisting: MenuComponent
  }]
})
export class MenuComponent {
  /** Getter for MenuCloseReason in template. */
  readonly MenuCloseReason = MenuCloseReason;
  /** Event emitted when the menu is closed. */
  @Output() readonly close: EventEmitter<MenuCloseReason> = new EventEmitter<MenuCloseReason>();

  /** Contents to display inside menu. */
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  /** All items inside the menu. Includes items nested inside another menu. */
  @ContentChildren(MenuItemComponent, {descendants: true}) subitems!: QueryList<MenuItemComponent>;
  /** Only the direct descendant menu items. */
  private items = new QueryList<MenuItemComponent>();

  ngAfterContentInit() {
    this.updateItems();
  }

  /** Stream that emits when hovered menu item changes. */
  hovered(): Observable<MenuItemComponent> {
    const changes = this.items.changes as Observable<QueryList<MenuItemComponent>>;
    return changes.pipe(
      startWith(this.items), switchMap(items => merge(...items.map((item: MenuItemComponent) => item.hovered)))
    ) as Observable<MenuItemComponent>;
  }

  /** Callback when menu clicked. */
  onClick() {
    this.close.emit(MenuCloseReason.Click);
  }

  /** Manually fetch list of direct descendant items since subitems would contain them all. */
  private updateItems() {
    this.subitems.changes.pipe(
      startWith(this.subitems)).subscribe((items: QueryList<MenuItemComponent>) => {
        this.items.reset(items.filter(item => item.parent === this));
        this.items.notifyOnChanges();
      }
    );
  }
}
