import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { TypeInfo } from "../../info/type";
import { EMAIL_REGEX } from "../../toolbox/email";
import { ID_DEFAULT, MaybeId } from "../../toolbox/id";
import { objectDeleteAll, objectOmit } from "../../toolbox/object";
import { OneOfValidator } from "../../validator/one-of";
import { PatternValidator } from "../../validator/pattern";
import { FusionCollection } from "../fusion";
import { FeatureType } from "../organization/feature";
import { USER_AUTHENTICATIONS, UserAuthentication, UserAuthenticationFusion } from "./authentication";
import { SessionGrant, UserGrant } from "./grant";

/** 
 *  Pattern for valid username.
 *  [\w\d_]{5,32}               5-32 characters a-z, A-Z, 0-9 or _
 */
export const USERNAME_REGEX = /^[\w\d_]{5,32}$/;

/** User to be added to database. */
export type UserUpsert = MaybeId<User>;
/** Fields in common with both users and sessions. */
export type UserLike = Omit<User, 'authentication' | 'grants'>;
/** A user cut down to minimal fields for email notifications. */
export type UserNameEmail = Pick<User, '_id' | 'name' | 'email'>;

/** Possible status types for user. */
export enum UserStatus {
  /** Registered but not logged in. */
  Registered = 'registered',
  /** Can log in. */
  Valid = 'valid',
  /** Locked out of account. */
  Locked = 'locked',
  /** Deleted. */
  Deleted = 'deleted'
};

/** An user with access to the system. */
export class User {
  constructor(
    /** Unique identifier of user. */
    public _id = ID_DEFAULT,
    /** Organization of user. */
    public _org = ID_DEFAULT,
    /** Display name of user. */
    public name = '',
    /** Registered email of user. */
    public email = '',
    /** List of institution grants for user. */
    public grants: UserGrant[] = [],
    /** Authentication method for user. */
    public authentication: UserAuthentication = new UserAuthenticationFusion()
  ) {}

  static typeinfo: TypeInfo<User> = {
    grants: [new UserGrant()],
    email: new PatternValidator(EMAIL_REGEX),
    authentication: new OneOfValidator<UserAuthentication>(...USER_AUTHENTICATIONS)
  };

  static collectioninfo: CollectionInfo<FusionCollection, User> = {
    _org: 'organizations'
  }

  static indexinfo: IndexInfo<User> = [
    { key: { email: 1 }, unique: true },
    { key: { 'grants._inst': 1, 'grants._profile': 1 } },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  /** Convert a user to an upsert. */
  static upsert(user = new User()): UserUpsert {
    return objectDeleteAll(user as User, '_id');
  }

  /** Get a UserLike object. */
  static like(): UserLike {
    return objectOmit(new User(), 'authentication', 'grants');
  }

  /** Get primary institution of user. */
  static institution(user: MaybeId<User>) {
    return user.grants[0]?._inst;
  }
}

/** Unique fields of a user for lookup. */
export class UserReference {
  constructor(
    /** Email of this user. */
    public email = ''
  ) {}
}

/** Preview of a user to display in lists. */
export class UserPreview implements UserLike {
  constructor(
    /** Unique identifier of user. */
    public _id = ID_DEFAULT,
    /** Organization of user. */
    public _org = ID_DEFAULT,
    /** Display name of user. */
    public name = '',
    /** Registered email of user. */
    public email = ''
  ) {}
}

/** Session information returned to user. */
export class UserSession {
  
  constructor(
    /** ID of validated user. */
    public _id = ID_DEFAULT,
    /** Organization of user. */
    public _org = ID_DEFAULT,
    /** Display name of user. */
    public name = '',
    /** Registered email of user. */
    public email = '',
    /** User authentication. */
    public authentication: UserAuthentication = new UserAuthenticationFusion(),
    /** List of grants for user. */
    public grants: SessionGrant[] = [],
    /** Features of user's organization. */
    public features: FeatureType[] = []
  ) {}

  /** Get institutions of this user session. */
  institutions() {
    return this.grants.map(({ _inst }) => _inst);
  }

  /** Convert to a user. */
  static user(session: UserSession): User {
    return {
      ...session,
      grants: session.grants.map(UserGrant.from)
    }
  }
}

/** A reset token prompting user to enter new password. */
export class UserReset {
  constructor(
    /** Email for reset. */
    public email = ''
  ) {}

  /** Check if given value is a user reset. */
  static check(value: any): value is UserReset {
    return value instanceof Object && Object.keys(value).length === 1 && typeof value['email'] === 'string';
  }
}