import { ClaimViewReason } from "../../../code/standard/disputes";
import { DisputesCode } from "../../../code/system";
import { PropertyInfo } from "../../../info/prop";
import { CURRENCY_DEFAULT } from "../../../toolbox/currency";
import { PropertyType } from "../../property-type";

/** Built-in disputes models. */
export enum DisputesModel {
  ClaimViewReason = 'Claim - View Reason',
  ClaimNoticeDate = 'Claim - Notice Date',
  DisputeIssueCredit = 'Dispute - Issue Credit'
}

/** Reason provided for viewing a claim. */
export class ModelClaimViewReason {

  constructor(
    /** Dropdown reason. */
    public reason = ClaimViewReason.CustomerInquiry,
    /** Additional details for view reason. */
    public additionalDetails = ''
  ) { }

  static propinfo: PropertyInfo<ModelClaimViewReason> = {
    reason: { type: PropertyType.Code, category: DisputesCode.ClaimViewReason, value: ClaimViewReason.CustomerInquiry }
  }
}

/** Provisional credit notice date */
export class ModelClaimNoticeDate {

  constructor(
    /** Date notice was provided. */
    public date = new Date()
  ) { }
}

/** Credit sub values for issuing provisional/final credit. */
export class ModelDisputeIssueCredit {

  constructor(
    /** Fee Credit */
    public feeCredit = CURRENCY_DEFAULT,
    /** Interest Credit */
    public interestCredit = CURRENCY_DEFAULT,
    /** Transaction Credit */
    public transactionCredit = CURRENCY_DEFAULT
  ) { }

  static propinfo: PropertyInfo<ModelDisputeIssueCredit> = {
    feeCredit: { type: PropertyType.Currency, min: 0 },
    interestCredit: { type: PropertyType.Currency, min: 0 },
    transactionCredit: { type: PropertyType.Currency, min: 0 }
  }
}
