import { Component } from '@angular/core';
import { DAYS_MAX, DAYS_MIN } from "../../../../../../../common/toolbox/time";
import { FormAccessor } from '../form-accessor';

@Component({
  selector: 'app-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss']
})
export class FormDateComponent extends FormAccessor<Date | undefined> {
  /** Minimum value. */
  min = DAYS_MIN;
  /** Maximum value. */
  max = DAYS_MAX;
}
