import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { UserPreview } from "../../../../../../common/model/user/user";
import { arrayCoerce, arrayEmpty } from '../../../../../../common/toolbox/array';
import { idNull } from '../../../../../../common/toolbox/id';
import { AuthService } from '../../service/auth.service';
import { UserService } from '../../service/user.service';
import { fieldControlProviders } from '../../toolbox/angular';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: fieldControlProviders(UserComponent)
})
export class UserComponent extends FieldControl implements ControlValueAccessor {
  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }

  /** Enable multi-select. */
  @Input() multiple = false;
  /** True to make a value required. */
  @Input() required = true;

  /** Emits when a new user is selected from dropdown. */
  @Output() selected = new EventEmitter<string>();
  /** Emits when a new list of users is available. */
  @Output() selection = new EventEmitter<string[]>();

  /** Current selected user. */
  value?: string | string[];
  /** List of users. */
  list: UserPreview[] = [];

  constructor(
    public users: UserService,
    private auth: AuthService
  ) {
    super();
  }

  async ngOnInit() {
    this.list = await this.users.previews(this.auth._inst);

    // Force selection of a value.
    let first = this.list[0]?._id;
    if (this.required && !idNull(first) && !this.list.find(c => c._id === this.value)) {
      this.writeValue(this._multiple ? [first] : first);
    }
  }

  writeValue(value?: string | string[]) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    let array = arrayCoerce(value);
    if (this.required && arrayEmpty(array)) {
      if (idNull(this.list[0]?._id)) return;
      value = this.multiple ? [this.list[0]!._id] : this.list[0]!._id;
    }

    this.changed(this.value = value);
  }
}
