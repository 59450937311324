import { Component, HostListener, Type } from '@angular/core';
import { Subject, first } from 'rxjs';
import { ColorCode } from "../../../../../../common/toolbox/color";
import { FADE_SCALE_ANIMATIONS, FadeComponent, FadeState } from '../../toolbox/fade';
import { DialogConfig, DialogRef } from './dialog.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  host: {
    ...FADE_SCALE_ANIMATIONS,
    class: 'elevation-dialog'
  }
})
export class DialogComponent extends FadeComponent implements DialogConfig {
  /** Component injected into dialog. */
  component!: Type<any>;
  /** Color for dialog header. */
  color?: ColorCode;
  /** Title for dialog */
  title = '';
  /** True to allow exiting dialog. */
  cancel = true;
  /** Emits when dialog should be destroyed. */
  discard = new Subject<void>();

  constructor(
    private dialogRef: DialogRef
  ) {
    super();
    this.component = this.dialogRef.component;
    this.dialogRef.data.pipe(first()).subscribe(() => this.fadeout());
  }

  @HostListener('animationend', ['$event'])
  onAnimationEnd($event: AnimationEvent) {
    this.refade($event);
    if (this.fade.value === FadeState.Done) {
      this.discard.next();
    }
  }

  close() {
    this.dialogRef.cancel();
    this.fadeout();
  }
}
