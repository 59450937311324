import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User, UserPreview, UserSession } from "../../../../../common/model/user/user";
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from "../component/dialog/dialog.service";
import { CachePreviewService } from "../toolbox/cache-preview-service";
import { getRequest } from "../toolbox/request";
import { LogService } from "./log.service";

/** A query to fetch a specific user. */
export class UserQuery {
  constructor(
    /** ID of user. */
    public _id = ID_DEFAULT,
    /** Institution of user. */
    public _inst = ID_DEFAULT
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends CachePreviewService<User, UserQuery, UserPreview> {
  readonly route = 'users/preview';
  readonly Type = User;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient
  ) {
    super(UserQuery, log, dialog, http);
  }

  /** Push current logged-in user into cache. */
  session(session?: UserSession) {
    if (!session) return;
    this.set(UserSession.user(session));
  }

  protected override multiple(queries: ArraySome<UserQuery>) {
    return getRequest(this.http, 'users', { _insts: queries.map(q => q._inst), _ids: queries.map(q => q._id) });
  }
}
