import { Component } from '@angular/core';
import { ColorCode } from "../../../../../../../common/toolbox/color";
import { BlockComponent } from '../block.component';

@Component({
  selector: 'app-setup-formula-condition',
  templateUrl: './setup-formula-condition.component.html',
  styleUrls: ['../block.component.scss', './setup-formula-condition.component.scss'],
  host: { color: ColorCode.Secondary }
})
export class SetupFormulaConditionComponent extends BlockComponent {}
