import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { DisplayPartial, DisplayType } from "../../../../../../common/model/display";
import { ApplicationSettingsKey } from '../../../../../../common/model/setting-group';
import { ID_DEFAULT } from '../../../../../../common/toolbox/id';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { LogService } from '../../../common/service/log.service';
import { MemberService } from '../../../common/service/member.service';
import { TableService } from '../../../common/service/table.service';
import { ResultsComponent } from '../../../common/toolbox/results/results.component';
import { MemberResultsData } from './member-results.model';

@Component({
  selector: 'app-member-results',
  templateUrl: '../../../common/toolbox/results/results.component.html',
  styleUrls: ['../../../common/toolbox/results/results.component.scss']
})
export class MemberResultsComponent extends ResultsComponent<'members'> {

  route: 'members' = 'members';
  table: ApplicationSettingsKey = 'general.member.table';
  type = DisplayType.Member;

  static override title() {
    return 'Member Results';
  }

  constructor(
    @Inject(TAB_DATA)
    data: MemberResultsData,
    log: LogService,
    tables: TableService,
    http: HttpClient,
    private members: MemberService
  ) {
    super(data, http, log, tables);
  }

  /** Callback when opening specified member. */
  async onRow(partial: DisplayPartial) {
    this.members.open({
      _ids: [partial.member?._id ?? ID_DEFAULT],
      _inst: partial.member?._inst ?? ID_DEFAULT
    });
  }
}
