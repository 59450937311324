import { Overlay } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { Icon } from '../../../../../../common/model/icon';
import { ResourceService } from '../../../common/service/resource.service';
import { elementFind } from '../../../common/toolbox/element/base';
import { HTMLSetupElement, setupElement } from '../../../common/toolbox/element/setup';
import { HighlightButtonComponent } from '../highlight-button/highlight-button.component';

@Component({
  selector: 'app-setup-button',
  templateUrl: '../highlight-button/highlight-button.component.html',
  styleUrls: ['../highlight-button/highlight-button.component.scss']
})
export class SetupButtonComponent extends HighlightButtonComponent<HTMLSetupElement> {
  readonly icon = Icon.Build;
  readonly tooltip = 'Click to Setup';

  constructor(
    overlay: Overlay,
    private resource: ResourceService
  ) {
    super(overlay);
  }

  find(target: EventTarget | null) {
    return elementFind(target, setupElement);
  }

  trigger(element: HTMLSetupElement) {
    this.resource.configure(element.dataset.id, element.dataset.collection);
  }
}
