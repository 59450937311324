import { Pair } from "./object";

/** Convert object to querystring, including prepending '?'. */
export function querystring<T>(request: T | undefined) {
  if (!request) return '';
  
  let fields: Pair<string>[] = [];
  for (let entry of Object.entries(request)) {
    let [key, value] = entry;
    switch (typeof value) {
    case 'boolean':
    case 'number':
    case 'bigint':
    case 'string':
    case 'symbol':
      fields.push(new Pair(key, String(value)));
      break;
    case 'object':
      if (value instanceof Date) {
        fields.push(new Pair(key, value.toISOString()));
      } else if (Array.isArray(value)) {
        fields.push(new Pair(key, String(value)));
      } break;
    case 'undefined':
    case 'function':
      break;
    }
  }

  return fields.length ? `?${fields.join('&')}` : '';
}