import { Component } from '@angular/core';
import { SettingType } from '../../../../../../../common/info/settings';
import { SettingsAccessor } from '../settings-accessor';

@Component({
  selector: 'app-settings-string',
  templateUrl: './settings-string.component.html',
  styleUrls: ['./settings-string.component.scss']
})
export class SettingsStringComponent extends SettingsAccessor<SettingType.String> {}
