import { UnionInfo } from "../../info/union";
import { DebugEchoGetRequest } from "../../message/debug";
import { WorkQueueBuildPostRequest } from "../../message/work/queue";
import { EnumMap } from "../../toolbox/enum";
import { NotificationClaimDate, NotificationEvent } from "../notification";

/** Job payload with tagged type. */
export type JobPayload = JobPayloadClass[JobType];

/** Possible types for jobs. */
export enum JobType {
  DebugEcho = 'debug/echo',
  Import = 'import',
  NotificationClaimDate = 'notifications/claim-date',
  NotificationEvent = 'notifications/event',
  WorkQueueBuild = 'work-queues/build'
}

/** Tagged class associated with each job payload type. */
export class JobPayloadClass {
  [JobType.DebugEcho] = new class extends DebugEchoGetRequest { readonly type = JobType.DebugEcho; };
  [JobType.Import] = new class { readonly type = JobType.Import; };
  [JobType.NotificationClaimDate] = new class extends NotificationClaimDate { readonly type = JobType.NotificationClaimDate; };
  [JobType.NotificationEvent] = new class extends NotificationEvent { readonly type = JobType.NotificationEvent; };
  [JobType.WorkQueueBuild] = new class extends WorkQueueBuildPostRequest { readonly type = JobType.WorkQueueBuild; };
}

/** Type information about job payload union. */
export const JOB_PAYLOAD_UNIONINFO: UnionInfo<JobPayload, JobType> = {
  tag: 'type',
  classes: new JobPayloadClass()
};

/** Name of each job payload type. */
export const JOB_TYPE_NAME: EnumMap<JobType> = {
  [JobType.DebugEcho]: 'Echo Message',
  [JobType.Import]: 'Import',
  [JobType.NotificationClaimDate]: 'Notify Claim Dates',
  [JobType.NotificationEvent]: 'Notify Events',
  [JobType.WorkQueueBuild]: 'Build Work Queues'
};

/**
 *  True if a job runs in response to system events.
 *  Jobs marked as true here will not be queued up as cron jobs.
 */
export const JOB_TYPE_INSTANT: Record<JobType, true | false> = {
  [JobType.DebugEcho]: false,
  [JobType.Import]: false,
  [JobType.NotificationClaimDate]: false,
  [JobType.NotificationEvent]: true,
  [JobType.WorkQueueBuild]: false
};