import { DisputeStatus, LedgerType } from "../../code/standard/disputes";
import { CommonCode, DisputesCode, ImportCode } from "../../code/system";
import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { CURRENCY_DEFAULT } from "../../toolbox/currency";
import { ID_DEFAULT } from "../../toolbox/id";
import { FusionCollection } from "../fusion";
import { PropertyType } from "../property-type";

/** A ledger item is a record of a transaction with associated dispute related data. */
export class LedgerItem {

  constructor(
    /** ID of this LedgerItem */
    public _id = ID_DEFAULT,
    /** Institution of this LedgerItem */
    public _inst = ID_DEFAULT,
    /** The ledger config associated with this LedgerItem, or undefined for external transactions. */
    public _config?: string,
    /** Account number for account this ledger item is associated with (e.g. a customer or merchant's account) */
    public account = '',
    /** Type for the account this ledger item is associated with. */
    public accountType = '',
    /** Category for the account this ledger item is associated with. */
    public accountCategory = '',
    /** Subaccount number for account this ledger item is associated with */
    public subAccount = '',
    /** Amount - Amount of the transaction. Positive for Credits, Negative for Debits */
    public amount = CURRENCY_DEFAULT,
    /** Date this ledger transaction was created. */
    public date = new Date(),
    /** Description of this ledger transaction. */
    public description = '',
    /** Trancode used when this ledger transaction was posted. */
    public tranCode = '',
    /** Type for tracking the Ledger transaction type (e.g. Credit/ Debit) */
    public type = LedgerType.Credit,
    /** What portion of the dispute process triggered this ledger item. */
    public status = DisputeStatus.NotWorked
  ) {}

  static typeinfo: TypeInfo<LedgerItem> = {
    _config: ID_DEFAULT,
    accountType: ''
  };

  static propinfo: PropertyInfo<LedgerItem> = {
    accountType: { type: PropertyType.Code, category: ImportCode.AccountType },
    amount: { type: PropertyType.Currency },
    status: {type: PropertyType.Code, category: DisputesCode.DisputeStatus },
    tranCode: { type: PropertyType.Code, category: CommonCode.Transaction },
    type: { type: PropertyType.Code, category: DisputesCode.LedgerType }
  };

  static indexinfo: IndexInfo<LedgerItem> = [
    { key: { _inst: 1 } }
  ];

  static collectioninfo: CollectionInfo<FusionCollection, LedgerItem> = {
    _inst: 'institutions',
    _config: 'ledgerConfigs'
  }
}