import { ApplicationSettingsKey } from "../../../../../common/model/setting-group";
import { arrayCoerce, arraySome } from "../../../../../common/toolbox/array";
import { HasIdInst, HasIdName, ID_DEFAULT } from "../../../../../common/toolbox/id";
import { keyNestedGet } from "../../../../../common/toolbox/keys";
import { AuthService } from "../service/auth.service";
import { LogService } from "../service/log.service";
import { SettingGroupService } from "../service/setting-group.service";
import { CachePreviewService } from "./cache-preview-service";
import { PreviewLike } from "./preview";

/** Fetch an item from institution settings. */
export async function serviceSettingsItems<T extends HasIdName, Q extends HasIdInst, P extends PreviewLike>(
  type: string,
  cache: CachePreviewService<T, Q, P>,
  auth: AuthService,
  log: LogService,
  groups: SettingGroupService,
  key: ApplicationSettingsKey
) {
  let settings = await groups.inst();
  let _id = keyNestedGet(key, settings);
  if (_id === undefined) return [];
  if (_id === ID_DEFAULT) {
    log.show(`${type} not configured: ${key}`);
    return [];
  }

  let _ids = arrayCoerce<string>(_id);
  return cache.items(_ids.map(_id => ({ _inst: auth._inst, _id }) as Q));
}

/** Fetch a list of items from institution settings. */
export async function serviceSettingsItem<T extends HasIdName, Q extends HasIdInst, P extends PreviewLike>(
  type: string,
  cache: CachePreviewService<T, Q, P>,
  auth: AuthService,
  log: LogService,
  groups: SettingGroupService,
  key: ApplicationSettingsKey
) {
  let items = await serviceSettingsItems(type, cache, auth, log, groups, key);
  return arraySome(items) ? items[0] : new cache.Type();
}