import { FUSION_COLLECTION_NAME } from "../../../../../../common/model/fusion-map";
import { idNull } from "../../../../../../common/toolbox/id";
import { FusionCollectionQuery } from "../../service/resource.service";

/** Properties set on an element that supports setup. */
export interface HTMLSetupElement<C extends FusionCollectionQuery = FusionCollectionQuery> extends HTMLElement {
  dataset: { collection: C, id: string }
}

/** Check if a value is a setup element. */
export function setupElement(target: any): target is HTMLSetupElement {
  return !!target && target instanceof HTMLElement && (target.dataset['collection'] ?? '') in FUSION_COLLECTION_NAME && (!idNull(target.dataset['id']));
}

/** Programatically make an element a setup element. */
export function setupElementMake<C extends FusionCollectionQuery>(element: HTMLElement, collection: C, _id?: string): HTMLSetupElement<C> {
  let setup = element as HTMLSetupElement<C>;
  setup.dataset.collection = collection;
  setup.dataset.id = _id ?? '';
  return setup;
}