import { Component, HostBinding, Input } from '@angular/core';
import { PercentItem, PercentItemColor } from './percent.model';

/** Check if list of items or pre-calculated percents. */

@Component({
  selector: 'app-percent',
  templateUrl: './percent.component.html',
  styleUrls: ['./percent.component.scss'],
  host: {
    '[ngStyle]': 'style'
  }
})
export class PercentComponent {
  /** Set list of items to display. */
  @Input() set items(items: PercentItem[]) {
    this._items = PercentItemColor.from(items);
    this.style = this._items.map(p => `${p.percent}fr`).join(' ');
  }

  /** List of items to display with pre-calculated percentage. */
  _items: PercentItemColor[] = [];
  /** Calculated style for grid items. */
  @HostBinding('style.grid-template-columns') style = '';
}
