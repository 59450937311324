import { Component } from '@angular/core';
import { SettingType } from '../../../../../../../common/info/settings';
import { SettingsAccessor } from '../settings-accessor';

@Component({
  selector: 'app-settings-number',
  templateUrl: './settings-number.component.html',
  styleUrls: ['./settings-number.component.scss']
})
export class SettingsNumberComponent extends SettingsAccessor<SettingType.Number> {}
