import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AccountSelectComponent } from './common/component/account-select/account-select.component';
import { ActionPanelComponent } from './common/component/action-panel/action-panel.component';
import { AddressEditComponent } from './common/component/address-edit/address-edit.component';
import { AddressesEditComponent } from './common/component/addresses-edit/addresses-edit.component';
import { AttachmentListComponent } from './common/component/attachment/list/attachment-list.component';
import { BadgeComponent } from './common/component/badge/badge.component';
import { BannerComponent } from './common/component/banner/banner.component';
import { BooleanComponent } from './common/component/boolean/boolean.component';
import { BreadcrumbComponent } from './common/component/breadcrumb/breadcrumb.component';
import { ButtonRowComponent } from './common/component/button-row/button-row.component';
import { CalculatorComponent } from "./common/component/calculator/calculator.component";
import { CalendarTriggerDirective } from './common/component/calendar/calendar-trigger.directive';
import { CalendarComponent } from "./common/component/calendar/calendar.component";
import { CardBodyComponent } from './common/component/card/body/card-body.component';
import { CardComponent } from "./common/component/card/card.component";
import { CardHeaderComponent } from "./common/component/card/header/card-header.component";
import { CardPanelComponent } from './common/component/card/panel/card-panel.component';
import { AppChartComponent } from './common/component/chart/chart.component';
import { ChipSelectComponent } from './common/component/chip-select/chip-select.component';
import { ChipComponent } from './common/component/chip/chip.component';
import { ClockComponent } from './common/component/clock/clock.component';
import { CodeGridComponent } from './common/component/code-grid/code-grid.component';
import { CodeTabComponent } from './common/component/code-tab/code-tab.component';
import { CodeComponent } from './common/component/code/code.component';
import { ColumnSizeComponent } from './common/component/column-size/column-size.component';
import { CommentListButtonComponent } from './common/component/comment-list/button/comment-list-button.component';
import { CommentListComponent } from './common/component/comment-list/comment-list.component';
import { CommentListDialogComponent } from './common/component/comment-list/dialog/comment-list-dialog.component';
import { CurrencyComponent } from './common/component/currency/currency.component';
import { DateRangeComponent } from './common/component/date-range/date-range.component';
import { DateComponent } from './common/component/date/date.component';
import { DialogService } from "./common/component/dialog/dialog.service";
import { DialogFooterComponent } from "./common/component/dialog/footer/dialog-footer.component";
import { DialogSourceComponent } from './common/component/dialog/source/dialog-source.component';
import { DocumentTemplateListComponent } from './common/component/document-template-list/document-template-list.component';
import { DotComponent } from './common/component/dot/dot.component';
import { DragChipComponent } from './common/component/drag-chip/drag-chip.component';
import { DurationComponent } from './common/component/duration/duration.component';
import { EditListComponent } from './common/component/edit-list/edit-list.component';
import { EditListItemComponent } from './common/component/edit-list/item/edit-list-item.component';
import { EmailsEditComponent } from './common/component/emails-edit/emails-edit.component';
import { EventListComponent } from './common/component/event-list/event-list.component';
import { EventListIconComponent } from './common/component/event-list/icon/event-list-icon.component';
import { ExpansionPanelComponent } from './common/component/expansion-panel/expansion-panel.component';
import { ExpansionPanelItemBodyComponent } from './common/component/expansion-panel/item-body/expansion-panel-item-body.component';
import { ExpansionPanelItemHeaderComponent } from './common/component/expansion-panel/item-header/expansion-panel-item-header.component';
import { ExpansionPanelItemComponent } from './common/component/expansion-panel/item/expansion-panel-item.component';
import { DescriptionComponent } from './common/component/field/description/description.component';
import { FieldComponent } from "./common/component/field/field.component";
import { LabelComponent } from "./common/component/field/label/label.component";
import { LabelsComponent } from './common/component/field/labels/labels.component';
import { FieldPrefixComponent } from "./common/component/field/prefix/field-prefix.component";
import { SublabelComponent } from "./common/component/field/sublabel/sublabel.component";
import { FieldSuffixComponent } from "./common/component/field/suffix/field-suffix.component";
import { FormBooleanComponent } from './common/component/form/boolean/form-boolean.component';
import { FormCodeComponent } from './common/component/form/code/form-code.component';
import { FormControlComponent } from './common/component/form/control/form-control.component';
import { FormCurrencyComponent } from './common/component/form/currency/form-currency.component';
import { FormDateComponent } from './common/component/form/date/form-date.component';
import { FormComponent } from './common/component/form/form.component';
import { FormGridComponent } from './common/component/form/grid/form-grid.component';
import { FormListComponent } from './common/component/form/list/form-list.component';
import { FormNumberComponent } from './common/component/form/number/form-number.component';
import { FormSelectComponent } from './common/component/form/select/form-select.component';
import { FormStringComponent } from './common/component/form/string/form-string.component';
import { GridCell, GridCellDef, GridColumnDef, GridFooterCell, GridFooterCellDef, GridHeaderCellDef } from "./common/component/grid/cell.directive";
import { GridFooterRow } from './common/component/grid/footer-row/grid-footer-row.component';
import { GridComponent, GridRecycleRows } from './common/component/grid/grid.component';
import { GridHeaderCell } from "./common/component/grid/header-cell/grid-header-cell.component";
import { GridHeaderRow } from './common/component/grid/header-row/grid-header-row.component';
import { GridIconComponent } from './common/component/grid/icon/grid-icon.component';
import { GridPaginatorComponent } from './common/component/grid/paginator/grid-paginator.component';
import { GridFooterRowDef, GridHeaderRowDef, GridNoDataRow, GridRowDef } from './common/component/grid/row.directive';
import { GridRow } from './common/component/grid/row/grid-row.component';
import { HighlightComponent } from './common/component/highlight/highlight.component';
import { HoverHelpComponent } from "./common/component/hover-help/hover-help.component";
import { IconComponent } from "./common/component/icon/icon.component";
import { KeyValueComponent } from './common/component/key-value/key-value.component';
import { LoaderComponent } from "./common/component/loader/loader.component";
import { MenuItemComponent } from "./common/component/menu/item/menu-item.component";
import { MenuTriggerDirective } from "./common/component/menu/item/menu-trigger.directive";
import { MenuComponent } from "./common/component/menu/menu.component";
import { ModelGridComponent } from './common/component/model/grid/model-grid.component';
import { NoteComponent } from "./common/component/note/note.component";
import { NumberComponent } from './common/component/number/number.component';
import { ObjectComponent } from './common/component/object/object.component';
import { OptionGroupComponent } from './common/component/option/group/option-group.component';
import { OptionListComponent } from './common/component/option/list/option-list.component';
import { OptionComponent } from "./common/component/option/option.component";
import { PanelListComponent } from "./common/component/panel/list/panel-list.component";
import { PanelComponent } from "./common/component/panel/panel.component";
import { PercentComponent } from './common/component/percent/percent.component';
import { PermissionsGridComponent } from './common/component/permissions-grid/permissions-grid.component';
import { PhonesEditComponent } from './common/component/phones-edit/phones-edit.component';
import { ProgressComponent } from "./common/component/progress/progress.component";
import { PulseComponent } from './common/component/pulse/pulse.component';
import { RadioButtonComponent } from './common/component/radio-group/radio-button/radio-button.component';
import { RadioGroupComponent } from './common/component/radio-group/radio-group.component';
import { ResizeTriggerDirective } from './common/component/resize/resize-trigger.directive';
import { ResizeComponent } from './common/component/resize/resize.component';
import { ResourceListComponent } from './common/component/resource/list/resource-list.component';
import { ResourceSelectComponent } from './common/component/resource/select/resource-select.component';
import { RingComponent } from './common/component/ring/ring.component';
import { SaveComponent } from './common/component/save/save.component';
import { SearchFieldComponent } from './common/component/search-field/search-field.component';
import { SelectComponent } from "./common/component/select/select.component";
import { SettingsBooleanComponent } from './common/component/settings/boolean/settings-boolean.component';
import { SettingsCodesComponent } from './common/component/settings/codes/settings-codes.component';
import { SettingsControlComponent } from './common/component/settings/control/settings-control.component';
import { SettingsNumberComponent } from './common/component/settings/number/settings-number.component';
import { SettingsResourceComponent } from './common/component/settings/resource/settings-resource.component';
import { SettingsComponent } from './common/component/settings/settings.component';
import { SettingsStringComponent } from './common/component/settings/string/settings-string.component';
import { SizeComponent } from './common/component/size/size.component';
import { SnackbarComponent } from './common/component/snackbar/snackbar.component';
import { SpinnerComponent } from "./common/component/spinner/spinner.component";
import { SSNComponent } from "./common/component/ssn/ssn.component";
import { StatsComponent } from "./common/component/stats/stats.component";
import { StepperStepComponent } from './common/component/stepper/step/stepper-step.component';
import { StepperComponent } from './common/component/stepper/stepper.component';
import { StringComponent } from './common/component/string/string.component';
import { TabBarComponent } from "./common/component/tab/bar/tab-bar.component";
import { TabLabelComponent } from "./common/component/tab/label/tab-label.component";
import { TabComponent } from "./common/component/tab/tab.component";
import { UploadComponent } from './common/component/upload/upload.component';
import { UserComponent } from './common/component/user/user.component';
import { WorkflowComponent } from './common/component/workflow/workflow.component';
import { AttachmentLinkDirective } from './common/directive/attachment-link.directive';
import { BoxGridDirective } from './common/directive/box-grid.directive';
import { ButtonPrefixDirective } from './common/directive/button-prefix.directive';
import { IconButtonDirective } from "./common/directive/icon-button.directive";
import { ListGridDirective } from './common/directive/list-grid.directive';
import { LoaderDirective } from './common/directive/loader.directive';
import { SetupDirective } from './common/directive/setup.directive';
import { TooltipDirective } from "./common/directive/tooltip.directive";
import { VisibleIfDirective } from './common/directive/visible-if.directive';
import { APIInterceptor } from "./common/injectable/http-interceptor";
import { AccountPipe } from './common/pipe/account.pipe';
import { ArrayHasPipe } from './common/pipe/array-has-pipe';
import { BankUnionPipe } from './common/pipe/bank-union.pipe';
import { BoxAreaPipe } from './common/pipe/box-area.pipe';
import { CardNumberPipe } from './common/pipe/card-number.pipe';
import { CentsPipe } from './common/pipe/cents.pipe';
import { ClaimFilterPipe } from './common/pipe/claim-filter.pipe';
import { CodeListPipe } from './common/pipe/code-list.pipe';
import { CodeTypePipe } from "./common/pipe/code-type.pipe";
import { CustomerPipe } from './common/pipe/customer.pipe';
import { DateDifferencePipe } from './common/pipe/date-difference.pipe';
import { DateIsoPipe } from "./common/pipe/date-iso.pipe";
import { DateNullPipe } from './common/pipe/date-null.pipe';
import { DisplayPipe } from './common/pipe/display.pipe';
import { DocNamePipe } from './common/pipe/doc-name.split';
import { DocumentTemplateFormatPipe } from './common/pipe/document-template-format';
import { EnumValuesPipe } from './common/pipe/enum-values.pipe';
import { EnumPipe } from './common/pipe/enum.pipe';
import { EventDescriptionPipe } from './common/pipe/event-description.pipe';
import { EventTitlePipe } from './common/pipe/event-title.pipe';
import { ExpressionPipe } from './common/pipe/expression.pipe';
import { FeaturePipe } from './common/pipe/feature.pipe';
import { FlattenPipe } from './common/pipe/flatten.pipe';
import { FormulaPipe } from './common/pipe/formula.pipe';
import { FunctionPipe } from './common/pipe/function.pipe';
import { IconPipe } from './common/pipe/icon.pipe';
import { IdNullPipe } from './common/pipe/id-null.pipe';
import { InstanceOfPipe } from './common/pipe/instanceof.pipe';
import { InstitutionPipe } from "./common/pipe/institution.pipe";
import { JsonSafePipe } from './common/pipe/json-safe.pipe';
import { MemberNamePipe } from './common/pipe/member-name';
import { MimeCategoryPipe } from './common/pipe/mime-category.pipe';
import { MultilinePipe } from './common/pipe/multiline.pipe';
import { PercentColorPipe } from './common/pipe/percent-color.pipe';
import { PercentPipe } from './common/pipe/percent.pipe';
import { PermissionSomePipe } from './common/pipe/permission-some.pipe';
import { PermissionPipe } from './common/pipe/permission.pipe';
import { PhonePipe } from './common/pipe/phone.pipe';
import { PromisifyPipe } from './common/pipe/promisify.pipe';
import { PropertyNamePipe } from './common/pipe/property-name.pipe';
import { PropertyReadonlyPipe } from './common/pipe/property-readonly';
import { PropertyValuePipe } from "./common/pipe/property-value.pipe";
import { ResourceNamePipe } from './common/pipe/resource-name.pipe';
import { ResourcePipe } from './common/pipe/resource.pipe';
import { SafePipe } from './common/pipe/safe.pipe';
import { SetHasPipe } from './common/pipe/set-has.pipe';
import { SetupReadonlyPipe } from './common/pipe/setup-readonly.pipe';
import { StatusColorPipe } from './common/pipe/status-color.pipe';
import { StringPipe } from './common/pipe/string.pipe';
import { TaskDataPipe } from './common/pipe/task-data.pipe';
import { TitleCasePipe } from './common/pipe/title-case.pipe';
import { TodayPipe } from './common/pipe/today.pipe';
import { TypeofPipe } from './common/pipe/typeof.pipe';
import { UserPipe } from './common/pipe/user.pipe';
import { WorkConditionPipe } from './common/pipe/work-condition.pipe';
import { CodeTypeService } from "./common/service/code-type.service";
import { ConfigService } from "./common/service/config.service";
import { DevService } from "./common/service/dev.service";
import { LogService } from './common/service/log.service';
import { ToggleButtonDirective } from './common/toggle-button.directive';
import { AccountInfoComponent } from './module/account/info/account-info.component';
import { AccountMembersComponent } from './module/account/member/account-members.component';
import { ClaimAttachmentListComponent } from './module/claim/attachment-list/claim-attachment-list.component';
import { ClaimCountsComponent } from './module/claim/counts/claim-counts.component';
import { MenuBarFeatureComponent } from './module/main/menu-bar/feature/menu-bar-feature.component';
import { MenuBarSearchComponent } from './module/main/menu-bar/search/menu-bar-search.component';
import { MemberInfoComponent } from './module/member/info/member-info.component';
import { TaskClaimAttachmentComponent } from './module/task/claim/attachment/task-claim-attachment.component';
import { TaskMessageComponent } from './module/task/claim/message/task-message.component';
import { TaskClaimReviewComponent } from './module/task/claim/review/task-claim-review.component';
import { TaskDisputeWithdrawComponent } from './module/task/dispute/withdraw/task-dispute-withdraw.component';
import { TaskDocumentTemplateComponent } from './module/task/document-template/task-document-template.component';
import { TaskFormListComponent } from './module/task/form/task-form-list.component';
import { TaskListComponent } from './module/task/list/task-list.component';
import { AppMissingHandler, createTranslateLoader } from './translate';

@NgModule({
  declarations: [
    // Pipes
    AccountPipe,
    ArrayHasPipe,
    BankUnionPipe,
    BoxAreaPipe,
    CardNumberPipe,
    CentsPipe,
    ClaimFilterPipe,
    CodeListPipe,
    CodeTypePipe,
    CustomerPipe,
    DateDifferencePipe,
    DateIsoPipe,
    DateNullPipe,
    DisplayPipe,
    DocNamePipe,
    DocumentTemplateFormatPipe,
    EnumPipe,
    EnumValuesPipe,
    EventDescriptionPipe,
    EventTitlePipe,
    ExpressionPipe,
    FeaturePipe,
    FlattenPipe,
    FormulaPipe,
    FunctionPipe,
    IconPipe,
    IdNullPipe,
    InstanceOfPipe,
    InstitutionPipe,
    JsonSafePipe,
    MemberNamePipe,
    MimeCategoryPipe,
    MultilinePipe,
    PercentColorPipe,
    PercentPipe,
    PermissionPipe,
    PermissionSomePipe,
    PhonePipe,
    PromisifyPipe,
    PropertyValuePipe,
    PropertyNamePipe,
    PropertyReadonlyPipe,
    ResourcePipe,
    ResourceNamePipe,
    SafePipe,
    SetHasPipe,
    SetupReadonlyPipe,
    StatusColorPipe,
    StringPipe,
    TaskDataPipe,
    TitleCasePipe,
    TodayPipe,
    TypeofPipe,
    UserPipe,
    WorkConditionPipe,

    // Directives
    AttachmentLinkDirective,
    BoxGridDirective,
    ButtonPrefixDirective,
    CalendarTriggerDirective,
    GridCell,
    GridCellDef,
    GridColumnDef,
    GridFooterCell,
    GridFooterCellDef,
    GridFooterRowDef,
    GridHeaderCell,
    GridHeaderCellDef,
    GridHeaderRowDef,
    GridNoDataRow,
    GridRecycleRows,
    GridRowDef,
    ListGridDirective,
    LoaderDirective,
    MenuTriggerDirective,
    ResizeTriggerDirective,
    SetupDirective,
    ToggleButtonDirective,
    TooltipDirective,
    VisibleIfDirective,

    // Components,
    AccountInfoComponent,
    AccountMembersComponent,
    AccountSelectComponent,
    ActionPanelComponent,
    AddressEditComponent,
    AddressesEditComponent,
    AppChartComponent,
    AttachmentListComponent,
    BadgeComponent,
    BannerComponent,
    BooleanComponent,
    BreadcrumbComponent,
    ButtonRowComponent,
    CalculatorComponent,
    CalendarComponent,
    CardBodyComponent,
    CardComponent,
    CardHeaderComponent,
    CardPanelComponent,
    ChipComponent,
    ChipSelectComponent,
    ClaimAttachmentListComponent,
    ClaimCountsComponent,
    ClockComponent,
    CodeComponent,
    CodeGridComponent,
    CodeTabComponent,
    ColumnSizeComponent,
    CommentListButtonComponent,
    CommentListComponent,
    CommentListDialogComponent,
    CurrencyComponent,
    DateComponent,
    DateRangeComponent,
    DescriptionComponent,
    DialogFooterComponent,
    DialogSourceComponent,
    DocumentTemplateListComponent,
    DotComponent,
    DragChipComponent,
    DurationComponent,
    EditListComponent,
    EditListItemComponent,
    EmailsEditComponent,
    EventListComponent,
    EventListIconComponent,
    ExpansionPanelComponent,
    ExpansionPanelItemBodyComponent,
    ExpansionPanelItemComponent,
    ExpansionPanelItemHeaderComponent,
    FieldComponent,
    FieldPrefixComponent,
    FieldSuffixComponent,
    FormBooleanComponent,
    FormCodeComponent,
    FormComponent,
    FormControlComponent,
    FormCurrencyComponent,
    FormDateComponent,
    FormGridComponent,
    FormListComponent,
    FormNumberComponent,
    FormSelectComponent,
    FormStringComponent,
    GridComponent,
    GridFooterRow,
    GridHeaderRow,
    GridIconComponent,
    GridPaginatorComponent,
    GridRow,
    HighlightComponent,
    HoverHelpComponent,
    IconButtonDirective,
    IconComponent,
    KeyValueComponent,
    LabelComponent,
    LabelsComponent,
    LoaderComponent,
    MemberInfoComponent,
    MenuBarFeatureComponent,
    MenuBarSearchComponent,
    MenuComponent,
    MenuItemComponent,
    ModelGridComponent,
    NoteComponent,
    NumberComponent,
    ObjectComponent,
    OptionComponent,
    OptionGroupComponent,
    OptionListComponent,
    PanelComponent,
    PanelListComponent,
    PercentComponent,
    PermissionsGridComponent,
    PhonesEditComponent,
    ProgressComponent,
    PulseComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    ResizeComponent,
    ResourceListComponent,
    ResourceSelectComponent,
    RingComponent,
    SaveComponent,
    SearchFieldComponent,
    SelectComponent,
    SettingsBooleanComponent,
    SettingsCodesComponent,
    SettingsComponent,
    SettingsControlComponent,
    SettingsNumberComponent,
    SettingsResourceComponent,
    SettingsStringComponent,
    SizeComponent,
    SnackbarComponent,
    SpinnerComponent,
    SSNComponent,
    StatsComponent,
    StepperComponent,
    StepperStepComponent,
    StringComponent,
    SublabelComponent,
    TabBarComponent,
    TabComponent,
    TabLabelComponent,
    TaskClaimAttachmentComponent,
    TaskClaimReviewComponent,
    TaskDisputeWithdrawComponent,
    TaskDocumentTemplateComponent,
    TaskFormListComponent,
    TaskListComponent,
    TaskMessageComponent,
    UploadComponent,
    UserComponent,
    WorkflowComponent
  ],
  imports: [
    // Modules
    CdkTableModule,
    CdkTreeModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    NgApexchartsModule,
    ReactiveFormsModule,

    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [LogService, HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingHandler
      }
    })
  ],
  exports: [
    // Modules
    CdkTreeModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // Pipes
    AccountPipe,
    ArrayHasPipe,
    BankUnionPipe,
    BoxAreaPipe,
    CardNumberPipe,
    CentsPipe,
    ClaimFilterPipe,
    CodeListPipe,
    CodeTypePipe,
    CustomerPipe,
    DateDifferencePipe,
    DateIsoPipe,
    DateNullPipe,
    DisplayPipe,
    DocNamePipe,
    DocumentTemplateFormatPipe,
    EnumPipe,
    EnumValuesPipe,
    EventDescriptionPipe,
    EventTitlePipe,
    ExpressionPipe,
    FeaturePipe,
    FlattenPipe,
    FormulaPipe,
    FunctionPipe,
    IconPipe,
    IdNullPipe,
    InstanceOfPipe,
    InstitutionPipe,
    JsonSafePipe,
    MemberNamePipe,
    MimeCategoryPipe,
    MultilinePipe,
    PercentColorPipe,
    PercentPipe,
    PermissionPipe,
    PermissionSomePipe,
    PhonePipe,
    PromisifyPipe,
    PropertyValuePipe,
    PropertyNamePipe,
    PropertyReadonlyPipe,
    ResourcePipe,
    ResourceNamePipe, 
    SafePipe,
    SetHasPipe,
    SetupReadonlyPipe,
    StatusColorPipe,
    StringPipe,
    TaskDataPipe,
    TitleCasePipe,
    TodayPipe,
    TypeofPipe,
    UserPipe,
    WorkConditionPipe,

    // Directives
    AttachmentLinkDirective,
    BoxGridDirective,
    ButtonPrefixDirective,
    CalendarTriggerDirective,
    GridCell,
    GridCellDef,
    GridColumnDef,
    GridFooterCell,
    GridFooterCellDef,
    GridFooterRowDef,
    GridHeaderCell,
    GridHeaderCellDef,
    GridHeaderRowDef,
    GridNoDataRow,
    GridRecycleRows,
    GridRowDef,
    ListGridDirective,
    LoaderDirective,
    MenuTriggerDirective,
    ResizeTriggerDirective,
    SetupDirective,
    ToggleButtonDirective,
    TooltipDirective,
    VisibleIfDirective,

    // Components
    AccountInfoComponent,
    AccountMembersComponent,
    AccountSelectComponent,
    ActionPanelComponent,
    AddressEditComponent,
    AddressesEditComponent,
    AppChartComponent,
    AttachmentListComponent,
    BadgeComponent,
    BannerComponent,
    BooleanComponent,
    BreadcrumbComponent,
    ButtonRowComponent,
    CalculatorComponent,
    CalendarComponent,
    CardBodyComponent,
    CardComponent,
    CardHeaderComponent,
    CardPanelComponent,
    ChipComponent,
    ChipSelectComponent,
    ClaimAttachmentListComponent,
    ClaimCountsComponent,
    ClockComponent,
    CodeComponent,
    CodeGridComponent,
    CodeTabComponent,
    ColumnSizeComponent,
    CommentListButtonComponent,
    CommentListComponent,
    CommentListDialogComponent,
    CurrencyComponent,
    DateComponent,
    DateRangeComponent,
    DescriptionComponent,
    DialogFooterComponent,
    DialogSourceComponent,
    DocumentTemplateListComponent,
    DotComponent,
    DragChipComponent,
    DurationComponent,
    EditListComponent,
    EditListItemComponent,
    EmailsEditComponent,
    EventListComponent,
    EventListIconComponent,
    ExpansionPanelComponent,
    ExpansionPanelItemBodyComponent,
    ExpansionPanelItemComponent,
    ExpansionPanelItemHeaderComponent,
    FieldComponent,
    FieldPrefixComponent,
    FieldSuffixComponent,
    FormBooleanComponent,
    FormCodeComponent,
    FormComponent,
    FormControlComponent,
    FormCurrencyComponent,
    FormDateComponent,
    FormGridComponent,
    FormListComponent,
    FormNumberComponent,
    FormSelectComponent,
    FormStringComponent,
    GridComponent,
    GridFooterRow,
    GridHeaderRow,
    GridIconComponent,
    GridPaginatorComponent,
    GridRow,
    HighlightComponent,
    HoverHelpComponent,
    HoverHelpComponent,
    IconButtonDirective,
    IconComponent,
    KeyValueComponent,
    LabelComponent,
    LabelsComponent,
    LoaderComponent,
    MemberInfoComponent,
    MenuBarFeatureComponent,
    MenuBarSearchComponent,
    MenuComponent,
    MenuItemComponent,
    ModelGridComponent,
    NoteComponent,
    NumberComponent,
    ObjectComponent,
    OptionComponent,
    OptionGroupComponent,
    OptionListComponent,
    PanelComponent,
    PanelListComponent,
    PercentComponent,
    PermissionsGridComponent,
    PhonesEditComponent,
    ProgressComponent,
    PulseComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    ResizeComponent,
    ResourceListComponent,
    ResourceSelectComponent,
    RingComponent,
    SaveComponent,
    SearchFieldComponent,
    SelectComponent,
    SettingsBooleanComponent,
    SettingsCodesComponent,
    SettingsComponent,
    SettingsControlComponent,
    SettingsNumberComponent,
    SettingsResourceComponent,
    SettingsStringComponent,
    SizeComponent,
    SnackbarComponent,
    SpinnerComponent,
    SSNComponent,
    StatsComponent,
    StepperComponent,
    StepperStepComponent,
    StringComponent,
    SublabelComponent,
    TabBarComponent,
    TabComponent,
    TabLabelComponent,
    TaskClaimAttachmentComponent,
    TaskClaimReviewComponent,
    TaskDisputeWithdrawComponent,
    TaskDocumentTemplateComponent,
    TaskFormListComponent,
    TaskListComponent,
    TaskMessageComponent,
    UploadComponent,
    UserComponent,
    WorkflowComponent
  ],
  providers: []
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // Injectables
        { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },

        // Translations
        TranslateStore,

        // Pipes
        CentsPipe,
        CodeTypePipe,
        EnumPipe,
        EventDescriptionPipe,
        EventTitlePipe,
        ExpressionPipe,
        DateIsoPipe,
        DisplayPipe,
        PhonePipe,
        PropertyValuePipe,
        UserPipe,

        // Services
        CodeTypeService,
        ConfigService,
        DevService,
        DialogService
      ]
    };
  }
}
