import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { keyNestedPairs } from '../../../../../../common/toolbox/keys';
import { Pair } from '../../../../../../common/toolbox/object';

/** A property to display in list. */
class Property {
  constructor(
    /** Text of property. */
    public key: string,
    /** True if property hidden. */
    public hidden = false
  ) {}
}

@Component({
  selector: 'app-setup-property-list',
  templateUrl: './setup-property-list.component.html',
  styleUrls: ['./setup-property-list.component.scss'],
  host: {
    class: 'card column'
  }
})
export class SetupPropertyListComponent {

  /** Additional keywords to display. */
  @Input() keywords: string[] = [];

  /** Object to display type information for. */
  @Input() set object(object: any) {
    let keywords = this.keywords.map(keyword => new Pair(keyword));
    let properties = object ? [...keyNestedPairs(object), ...keywords].map(p => new Property(p.value)) : [];
    this.properties = properties.sort((a, b) => a.key.localeCompare(b.key));
    this.refilter(this.builder.value.filter!);
  }

  /** List of properties to display. */
  properties: Property[] = [];
  /** Data for main form. */
  builder = new FormBuilder().nonNullable.group({
    filter: ['', [Validators.required, Validators.email]]
  });

  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor() {
    this.builder.controls.filter.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(filter => this.refilter(filter));
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Filter list of available items. */
  private refilter(filter: string): any {
    for (let property of this.properties) {
      property.hidden = !property.key.toLowerCase().includes(filter);
    }
  }
}