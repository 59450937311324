import { stringSplice } from "../../../../../common/toolbox/string";

/** Events returned when checking keydown event. */
export enum KeyResponseType {
  /** No action needed. */
  None,
  /** New value available for field. */
  Edit,
  /** Typed a character in next field. */
  EditNext,
  /** Exited left end of field. */
  ExitLeft,
  /** Exited right end of field. */
  ExitRight
};

/** Parsed response when typing into field. */
export class KeyResponse {
  constructor(
    /** What kind of event happened. */
    public type = KeyResponseType.None,
    /** New value of input field. */
    public value = '',
    /** Position to place caret. */
    public position = 0
  ) {}
}

/** Process key event and return action. */
export async function inputProcessKey($event: KeyboardEvent, replace = false, next?: HTMLInputElement): Promise<KeyResponse> {
  // Get information about field and event.
  let response = new KeyResponse();
  let target = $event.target as HTMLInputElement;
  
  // Get field width and caret position.
  let position = target.selectionStart ?? 0;
  let width = target.value.length;
  let text = target.value;

  let insert = $event.key;
  if ($event.ctrlKey) {
    switch ($event.key) {
    case 'v':
      // Handle pasting.
      insert = await navigator.clipboard.readText();
      break;
    default:
      // Allow default behavior.
      return response;
    }
  }

  // Determine how to respond.
  switch (insert) {
  case 'ArrowLeft':
    if (position == 0) {
      response.type = KeyResponseType.ExitLeft;
    } break;
  case 'ArrowRight':
    if (width && position >= width) {
      $event.preventDefault();
      response.type = KeyResponseType.ExitRight;
    } break;
  case 'Tab':
    break;
  case 'Backspace':
    $event.preventDefault();
    if (position == 0 || replace) {
      return response;
    }
    
    response.type = KeyResponseType.Edit;
    response.value = stringSplice(text, position - 1, 1);
    response.position = position - 1;
    break;
  default:
    if (target.maxLength && position == target.maxLength) {
      // Edit text of next field.
      if (!next) return response;
      response.type = KeyResponseType.EditNext;
      response.position = position = 0;
      text = next.value;
      target = next;
    } else {
      response.type = KeyResponseType.Edit;
      response.position = position + insert.length - +replace;
    }

    $event.preventDefault();
    let maxlength = target.maxLength >= 0 ? target.maxLength : Number.MAX_SAFE_INTEGER;
    response.value = stringSplice(text, position, replace ? insert.length : 0, insert).slice(0, maxlength);
  }

  return response;
}