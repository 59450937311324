import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Pair } from "../../../../../../common/toolbox/object";
import { searchQuery } from '../../../../../../common/toolbox/search';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  host: {
    '[style.display]': "visible ? null : 'none'",
    '[class.active]': 'active',
    '[class.disabled]': 'disabled',
    '[attr.view]': 'text'
  }
})
export class OptionComponent<T> implements Pair<T> {

  /** Value to display for option. */
  @Input()
  set view(view: number | string | undefined | null) {
    this.text = view == null ? '' : `${view}`;
    this.contents = searchQuery(this.text);
    this.viewChange.next(this);
  }

  get view() {
    return this.text;
  }

  /** True if this is the select all element. */
  @Input('select-all')
  set all(selectall: BooleanInput) {
    this.selectall = coerceBooleanProperty(selectall);
  }

  get all() {
    return this.selectall;
  }

  /** Pair to display for option. */
  @Input()
  set pair(pair: Pair<any>) {
    this.value = pair.value;
    this.view = pair.view;
  }

  get pair() {
    return new Pair(this.value, this.view);
  }
  
  /** True if option is selected. */
  @Input() selected = false;
  /** Form value of option. */
  @Input() value: T = undefined as unknown as T;
  /** True if disabled. */
  @Input() disabled = false;

  /** Emits when option is selected. */
  @Output() readonly selectionChange = new EventEmitter<OptionComponent<T>>();
  /** Emits when displayed text is changed. */
  @Output() readonly viewChange = new EventEmitter<OptionComponent<T>>();

  /** True if member of multiselect menu. */
  multiple = false;
  /** True if this is select all option. */
  selectall = false;
  /** True if option is currently visible. */
  visible = true;
  /** Current text being displayed. */
  text = '';

  /** Search string for field. */
  private contents = '';

  /** Called when clicking option. */
  @HostListener('click')
  onClick() {
    if (this.disabled) return;
    this.selectionChange.emit(this);
  }

  /** Apply filtering to option. */
  filter(query: string, filter?: Set<any>) {
    this.visible = this.contents.includes(query) && (!filter || filter.has(this.value));
  }
}
