import { ImportCode } from "../code/system";
import { IndexInfo } from "../info";
import { PropertyInfo } from "../info/prop";
import { SchemaInfo } from "../info/schema";
import { TypeInfo } from "../info/type";
import { currency, CURRENCY_DEFAULT } from "../toolbox/currency";
import { ID_DEFAULT, idOmit, MaybeId } from "../toolbox/id";
import { systemRevert } from "../toolbox/system";
import { Address } from "./address";
import { CommentData } from "./comment/comment";
import { Email } from "./email";
import { Phone } from "./phone";
import { PropertyType } from "./property-type";
import { SCHEMA_LOOKUP } from "./schema/base";

/** Values needed in a member's name. */
export class MemberLike {
  /** True if business fields are populated. */
  isBusiness?: boolean
  /** Title of member, eg. Mr. Ms. */
  title?: string
  /** Name of member, if business. */
  businessName?: string
  /** First name of member, if non-business. */
  firstName?: string
  /** Middle name of member, if non-business. */
  middleName?: string
  /** Last name of member, if non-business. */
  lastName?: string
}

/** A member owning one or more accounts. */
export class Member {
  constructor(
    /** Unique identifier of member. */
    public _id = ID_DEFAULT,
    /** Institution member belongs to. */
    public _inst = ID_DEFAULT,
    /** Host-provided access level code for member. */
    public access?: string,
    /** Active duty status of member, if serving. */
    public activeDuty?: string,
    /** Date active duty was ended. */
    public activeDutySeparationDate?: Date,
    /** Date active duty was started. */
    public activeDutyStartDate?: Date,
    /** Addresses of member. */
    public _addresses?: string[],
    /** Birth date of member, if non-business. */
    public birthDate?: Date,
    /** Name of member, if business. */
    public businessName?: string,
    /** True if member should not be visible. */
    public confidential = false,
    /** Death of member, if applicable. */
    public deathDate?: Date,
    /** List of emails to contact member. */
    public emails?: Email[],
    /** Employer of member, if non-business. */
    public employer?: string,
    /** First name of member, if non-business. */
    public firstName?: string,
    /** Gross monthly pay of member, if non-business. */
    public grossMoPay?: currency,
    /** True if business fields are populated. */
    public isBusiness = false,
    /** Last name of member, if non-business. */
    public lastName?: string,
    /** Driver's license number of member, if non-business. */
    public license?: string,
    /** Host-provided unique ID of member. */
    public locator = '',
    /** Middle name of member, if non-business. */
    public middleName?: string,
    /** Mother's maiden name for customer, if non-business. */
    public mothersMaidenName?: string,
    /** Net monthly pay of member, if employed. */
    public netMoPay?: currency,
    /** True if member is not a resident of country. */
    public nonResidentAlien?: boolean,
    /** Host-provided number for member. */
    public number = '',
    /** Short description of member's job. */
    public occupation?: string,
    /** List of phone numbers member can be contacted by. */
    public phones?: Phone[],
    /** Host-provided code for member's gender, if specified. */
    public gender?: string,
    /** Suffix for member's number. */
    public suffix?: string,
    /** Tax ID of member, with annotated type in separate field. */
    public taxId = '',
    /** Host-provided code for tax ID type. */
    public taxIdType = '',
    /** True if this is test data. */
    public test?: boolean,
    /** Title of member, eg. Mr. Ms. */
    public title?: string,
    
    // APPLICATION-DEFINED FIELDS

    /** Comments on this account. */
    public comments: undefined | CommentData[] = undefined,

    // DATABASE LOOKUPS ONLY

    /** Relationship of this member to current displayed account. */
    public relationship?: string
  ) {}

  static typeinfo: TypeInfo<Member> = {
    access: '',
    activeDuty: '',
    activeDutySeparationDate: new Date(),
    activeDutyStartDate: new Date(),
    _addresses: [ID_DEFAULT],
    birthDate: new Date(),
    businessName: '',
    deathDate: new Date(),
    comments: [new CommentData()],
    emails: [new Email()],
    employer: '',
    firstName: '',
    grossMoPay: CURRENCY_DEFAULT,
    lastName: '',
    license: '',
    middleName: '',
    mothersMaidenName: '',
    netMoPay: CURRENCY_DEFAULT,
    nonResidentAlien: false,
    occupation: '',
    phones: [new Phone()],
    gender: '',
    suffix: '',
    test: false,
    title: '',
    relationship: ''
  }

  static propinfo: PropertyInfo<Member> = {
    access: { type: PropertyType.Code, category: ImportCode.AccessStatus },
    activeDuty: { type: PropertyType.Code, category: ImportCode.ActiveDuty },
    grossMoPay: { type: PropertyType.Currency },
    netMoPay: { type: PropertyType.Currency },
    relationship: { type: PropertyType.Code, category: ImportCode.AccountMember },
    gender: { type: PropertyType.Code, category: ImportCode.Gender },
    taxIdType: { type: PropertyType.Code, category: ImportCode.TaxID }
  }

  static schemainfo: SchemaInfo<Member> = {
    relationship: SCHEMA_LOOKUP
  }

  static indexinfo: IndexInfo<Member> = [
    { key: { _inst: 1, number: 1 }, unique: true },
    { key: { firstName: 'text', middleName: 'text', lastName: 'text', businessName: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  /** Get formatted full name of member. */
  static fullname(member: MemberLike) {
    if (member.isBusiness && member.businessName) return member.businessName;
    return [member.title, member.firstName, member.middleName, member.lastName].filter(f => f).join(' ');
  }

  /** Get formatted short name of member. */
  static shortname(member: MemberLike) {
    if (member.isBusiness && member.businessName) return member.businessName;
    return member.firstName ?? '';
  }
}

/** Unique fields of a member for lookup. */
export class MemberReference {
  constructor(
    /** Institution of member. */
    public _inst = ID_DEFAULT,
    /** Number of this member. */
    public number = ''
  ) {}
}

/** Modifications to make to a member. */
export class MemberPatch {
  constructor(
    /** Full list of addresses for member. */
    public addresses?: MaybeId<Address>[],
    /** Full list of emails for member. */
    public emails?: Email[],
    /** Full list of phone numbers for member. */
    public phones?: Phone[]
  ) { }

  static typeinfo: TypeInfo<MemberPatch> = {
    addresses: [idOmit(new Address())],
    emails: [new Email()],
    phones: [new Phone()]
  }

  /** Revert any illegal changes made to system values on member. */
  static revert(member: MaybeId<Member>, patch: MemberPatch, addresses: Address[]) {
    systemRevert(addresses, patch.addresses, (a, b)=> a._id === b._id || a.number === b.number);
    systemRevert(member.emails, patch.emails, (a, b)=> a.address === b.address);
    systemRevert(member.phones, patch.phones, (a, b) => a.number === b.number);
  }
}