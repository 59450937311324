import { Component } from '@angular/core';
import { randomName, randomRange } from "../../../../../../common/toolbox/random";

/** Dummy item for rendering in list. */
class Person {
  constructor(
    /** Name of person. */
    public name = randomName().join(),
    /** Age of person. */
    public age = randomRange(20, 80)
  ) {}
}

@Component({
  selector: 'app-demo-edit-list',
  templateUrl: './demo-edit-list.component.html',
  styleUrls: ['./demo-edit-list.component.scss']
})
export class DemoEditListComponent {

  /** Default item to create. */
  readonly default = new Person('', 0);
  
  /** List of people for edit list. */
  people = Array.from({ length: randomRange(0, 30) }, () => new Person());

  static title() {
    return 'Edit List';
  }
}
