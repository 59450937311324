<svg overflow="visible">
  <polygon [attr.points]="points"/>
  <clipPath [attr.id]="'stepper-step-' + id">
    <polygon [attr.points]="points"/>
  </clipPath>
</svg>
<main [style.clipPath]="url">
  <div class="prefix">
    <ng-container [ngSwitch]="step.status">
      <app-icon *ngSwitchCase="StepStatus.Ok" color="green">check_circle</app-icon>
      <app-spinner icon-spinner *ngSwitchCase="StepStatus.Loading"></app-spinner>
      <app-icon *ngSwitchCase="StepStatus.Warning" color="orange">warning</app-icon>
      <app-icon *ngSwitchCase="StepStatus.Alert" color="red">error</app-icon>
    </ng-container>
  </div>
  <input #input [(ngModel)]="step.data.name" [attr.value]="step.data.name" [size]="step.data.name.length" (blur)="blur.emit()">
  <div class="suffix">
    <ng-content></ng-content>
  </div>
</main>