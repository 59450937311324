import { DocumentTemplateType } from "../../code/standard/common";
import { ErrorResponse } from "../../message/error";
import { csvSplit } from "../../toolbox/csv";
import { DOCUMENT_TEMPLATE_KEYWORDS, DOCUMENT_TEMPLATE_PIPE_KEYS, DocumentTemplatePipe } from "../../toolbox/document-template";
import { enumValues } from "../../toolbox/enum";
import { NestedKey, keyNested } from "../../toolbox/keys";
import { IDENTIFIER_NESTED_GLOBAL_REGEX } from "../../toolbox/string";
import { DocumentTemplateClass } from "./data";

/** Subresult of a document template audit. */
class DocumentTemplateScanResult<T = string> {

  /** Validate that result was successful. */
  get success() { return !this.invalid.size; }

  constructor(
    /** List of allowed values. */
    public allowed = new Set<T>(),
    /** List of valid, used expressions. */
    public valid = new Set<T>(),
    /** List of invalid expressions. */
    public invalid = new Set<string>()
  ) { }

  /** Convert to plaintext arrays. */
  flatten() {
    return {
      valid: [...this.valid],
      invalid: [...this.invalid]
    };
  }
}

/** Result of scanning a document template for errors. */
export class DocumentTemplateScan<T extends DocumentTemplateType = DocumentTemplateType> {

  /** List of identifiers. */
  identifiers = new DocumentTemplateScanResult<NestedKey<DocumentTemplateClass[T]>>();
  /** List of pipes. */
  pipes = new DocumentTemplateScanResult<DocumentTemplatePipe>();
  /** List of codes. */
  codes = new DocumentTemplateScanResult();

  /** Validate that result was successful. */
  get success() { return this.identifiers.success && this.pipes.success && this.codes.success; }

  /** Scan text and validate used identifiers and pipes. */
  constructor(text: string, type: T, codes: string[]) {
    // Remember valid codes.
    this.codes.allowed = new Set(codes);

    // Scan for all {{interpolations}}.
    let keys = [...keyNested(new DocumentTemplateClass()[type]), ...DOCUMENT_TEMPLATE_KEYWORDS];
    this.identifiers.allowed = new Set(keys as any);
    this.pipes.allowed = new Set(enumValues(DocumentTemplatePipe));

    let expressions = [...text.matchAll(/{{([^}]+)}}/g)].map(([_, expression]) => expression!);
    let scope: string[] = [];

    for (let expression of expressions) {

      // Detect pipe usage.
      let [, value, pipe, sargs] = expression.match(/^\s*(.+)\s*\|\s*([a-zA-Z]+)(?::([^,]+(?:,[^,]+)*))?\s*$/) ?? [];
      while (value && pipe) {
        expression = value!;
        if (this.pipes.allowed.has(pipe as DocumentTemplatePipe)) this.pipes.valid.add(pipe as DocumentTemplatePipe);
        else this.pipes.invalid.add(pipe!);

        // Detect code type usage.
        if (pipe === 'code') {
          let category = csvSplit(sargs ?? '', ':')[0]?.[0] ?? '';
          if (this.codes.allowed.has(category)) this.codes.valid.add(category);
          else this.codes.invalid.add(category);
        }
        [, value, pipe, sargs] = expression.match(/^\s*(.+)\s*\|\s*([a-zA-Z]+)(?::([^,]+(?:,[^,]+)*))?\s*$/) ?? [];
      }

      // Strip out array indexing and strings.
      expression = expression.replace(/\[.+\]|"[^"]+"|'[^"]+'/g, '');

      switch (expression[0]) {
        case '/':
          // Pop current scope.
          scope.pop();
          break;
        default:
          // Enter a new scope?
          let hash = expression[0] === '#';
          let next: string | undefined;

          // Find all identifiers.
          let identifiers = expression.match(IDENTIFIER_NESTED_GLOBAL_REGEX);
          if (!identifiers) break;

          // Walk from innermost to root scope trying to find valid identifiers.
          loop: for (let identifier of identifiers) {
            for (let i = scope.length; i >= 0; --i) {
              let subscope = scope.slice(0, i);
              let found = [...subscope, identifier].join('.') as NestedKey<DocumentTemplateClass[T]>;
              if (this.identifiers.allowed.has(found)) {
                this.identifiers.valid.add(found);
                next = next ?? found;

                // Add any subproperties used by pipe.
                if (pipe) for (let subkey of DOCUMENT_TEMPLATE_PIPE_KEYS[pipe as DocumentTemplatePipe] ?? []) {
                  this.identifiers.valid.add([found, subkey].join('.') as NestedKey<DocumentTemplateClass[T]>);
                }

                continue loop;
              }
            }

            this.identifiers.invalid.add(identifier);
          }

          if (hash && next) scope.push(next);
      }
    }
  }

  /** Get error of a scan. */
  error(name: string) {
    if (this.success) return new ErrorResponse(`Document template had no errors: ${name}`);

    let list: string[] = [];
    if (!this.identifiers.success) list.push(`Invalid identifiers: ${[...this.identifiers.invalid].join(', ')}`);
    if (!this.pipes.success) list.push(`Invalid pipes: ${[...this.pipes.invalid].join(', ')}`);
    if (!this.codes.success) list.push(`Invalid codes: ${[...this.codes.invalid].join(', ')}`);
    return ErrorResponse.list(`Document template had errors: ${name}`, list);
  }

  /** Convert to plaintext arrays. */
  flatten() {
    return {
      identifiers: this.identifiers.flatten(),
      pipes: this.pipes.flatten()
    }
  }
}
