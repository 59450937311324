import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { Subject, Subscription, merge, takeUntil } from "rxjs";
import { SearchCategory } from "../../../../../../../common/code/standard/common";
import { MemberPreviewGetRequest } from "../../../../../../../common/message/member";
import { AccountJoinLoan } from "../../../../../../../common/model/account/account";
import { Card } from "../../../../../../../common/model/card";
import { DisplayPartial, DisplayType } from "../../../../../../../common/model/display";
import { Icon } from "../../../../../../../common/model/icon";
import { IndexMap } from "../../../../../../../common/model/indexmap";
import { Member, MemberReference } from "../../../../../../../common/model/member";
import { MemberErrorJoin } from "../../../../../../../common/model/member-join";
import { arrayEmpty, arraySingle } from "../../../../../../../common/toolbox/array";
import { errorPartition, errorResponse } from "../../../../../../../common/toolbox/message";
import { objectKeys, safeAssign } from "../../../../../../../common/toolbox/object";
import { StatusLevel } from "../../../../../../../common/toolbox/status";
import { GridIconComponent } from "../../../../common/component/grid/icon/grid-icon.component";
import { DisplayIcon, GridIcon } from "../../../../common/component/grid/icon/grid-icon.model";
import { GridColumn, GridConfig } from "../../../../common/component/model/grid/model-grid.model";
import { SearchFieldData } from "../../../../common/component/search-field/search-field.model";
import { TAB_DATA } from "../../../../common/component/tab/bar/tab-bar.model";
import { AuthService } from "../../../../common/service/auth.service";
import { LogService } from "../../../../common/service/log.service";
import { TableService } from "../../../../common/service/table.service";
import { getOneRequest, getRequest } from "../../../../common/toolbox/request";
import { sourceReselect } from "../../../../common/toolbox/source/select";
import { ServerSource } from "../../../../common/toolbox/source/server";
import { CLAIM_INTAKE_STEP_STATE, ClaimIntakeState } from "../claim-intake.model";
import { ClaimIntakeStepComponent } from "../step/claim-intake-step.component";
import { ClaimIntakeMembersData } from "./claim-intake-members.model";
import { gridPaginateRequest } from "../../../../common/toolbox/source/grid";

/** Search field corresponding to each search category. */
const SEARCH_KEY_FIELD: { [K in SearchCategory]?: keyof MemberPreviewGetRequest } = {
  [SearchCategory.MemberName]: 'name',
  [SearchCategory.MemberNumber]: 'numbers',
  [SearchCategory.MemberTaxId]: 'taxId'
};

@Component({
  selector: 'app-claim-intake-members',
  templateUrl: './claim-intake-members.component.html',
  styleUrls: ['./claim-intake-members.component.scss']
})
export class ClaimIntakeMembersComponent extends ClaimIntakeStepComponent<ClaimIntakeMembersData> {

  /** Configuration for member results table. */
  config = new GridConfig<DisplayIcon>(new ServerSource(undefined, undefined, DisplayType.Member));
  /** List of categories that can be searched. */
  categories = objectKeys(SEARCH_KEY_FIELD);
  /** True if currently loading results from core. */
  loading = false;
  
  /** Subscription to row icon clicks. */
  protected subscription = new Subscription();
  /** Arrow at the start of each row. */
  protected precolumns: GridColumn[] = [{ key: 'icon' as any, size: '2rem', component: GridIconComponent }];
  /** Emits on component being destroyed. */
  protected destroy = new Subject<void>();

  constructor(
    @Inject(TAB_DATA) public override data: ClaimIntakeMembersData,
    @Inject(CLAIM_INTAKE_STEP_STATE) public state: ClaimIntakeState,
    private log: LogService,
    private tables: TableService,
    private auth: AuthService,
    private http: HttpClient
  ) {
    super();
  }

  async ngOnInit() {
    let table = await this.tables.setting('general.member.table');
    this.config = new GridConfig<DisplayPartial>(this.config.source, table);
    this.config.source.dataRequest.pipe(takeUntil(this.destroy)).subscribe(() => this.onSearch(this.data));
    if (this.data.search) this.onSearch(this.data);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.subscription.unsubscribe();
  }

  async onSearch(search: SearchFieldData) {
    let key = SEARCH_KEY_FIELD[search.category];
    if (!key) return;

    this.data.search = true;
    this.config.source.items = [];
    this.loading = true;
    
    let memberErrors = await getRequest(this.http, 'members/preview', {
      _inst: this.auth._inst,
      [key]: search.query,
      ...gridPaginateRequest(DisplayType.Member, this.config.source),
      core: true
    });

    this.loading = false;
    if (errorResponse(memberErrors)) {
      this.log.show(memberErrors);
      return;
    }

    if (arrayEmpty(memberErrors.items)) {
      this.log.show('No members found for the specified query.');
      return;
    }

    let [memberError, members] = errorPartition('Some members could not be found and were omitted.', memberErrors.items);
    if (memberError) this.log.show(memberError);
    let partials = members.map<DisplayPartial>(member => ({
      member: safeAssign(new Member(), member),
      icon: new GridIcon(Icon.ArrowForward)
    }));

    if (search.category === SearchCategory.MemberNumber && arraySingle(partials)) {
      // Auto-open member's accounts.
      await this.onMember(partials[0]);
      this.offset.emit(+1);
      return;
    } else {
      // Populate table.
      this.config.source.available = memberErrors.available;
      this.config.source.items = partials;
    }

    // Autoselect last selected member.
    let map = new IndexMap<MemberReference, string>(new Member());
    sourceReselect(
      this.state.member, this.config.source,
      member => map.key(member),
      partial => map.key(partial.member!)
    );

    // Auto-navigate when row icon is clicked.
    this.subscription.unsubscribe();
    this.subscription = merge(...this.config.source.items.map(item => item.icon!.click)).subscribe(async partial => {
      await this.onMember(partial);
      this.offset.emit(+1);
    });
  }

  /** Callback when selecting a member. */
  async onMember(partial: DisplayPartial) {
    let member = partial.member;
    if (!member) return this.log.show('Selected row had no member.', StatusLevel.Alert);

    // Fetch member and member's existing claims.
    // TODO consider improving typing on bulkRequest() and use that instead here.
    this.state.loading = true;
    let memberErrorJoin = await getOneRequest(this.http, 'members', {
        _inst: this.auth._inst,
        numbers: [member.number],
        core: true
    })

    if (errorResponse(memberErrorJoin)) return this.log.show(memberErrorJoin);
    let claims = await getRequest(this.http, 'claims', {
      _insts: [this.auth._inst],
      _member: memberErrorJoin._id
    });
    if (errorResponse(claims)) return this.log.show(claims);
    this.state.loading = false;

    // Handle partial errors for fetching members.
    let [memberJoinError, memberJoin] = MemberErrorJoin.partition(memberErrorJoin);
    if (memberJoinError) this.log.show(memberJoinError);

    // Set claim state to just have selected claim and member.
    safeAssign(this.state, {
      claim: safeAssign(this.state.claim, { _member: memberErrorJoin._id }),
      account: new AccountJoinLoan(),
      member: memberJoin,
      card: new Card(),
      transactions: [],
      claims: claims.items
    });
    this.lock.emit();
  }
}
