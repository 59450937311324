import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Injector, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DisplayValue } from "../../../../../../../common/model/display";
import { Block, BlockType } from "../../../../../../../common/model/formula/block";
import { Formula } from "../../../../../../../common/model/formula/formula";
import { FormulaProxy } from "../../../../../../../common/model/formula/proxy";
import { Model } from "../../../../../../../common/model/model";
import { blockStrip } from '../../../../../../../common/toolbox/formula/block';
import { formulaSource } from '../../../../../../../common/toolbox/formula/formula';
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { errorResponse } from "../../../../../../../common/toolbox/message";
import { modelValue } from '../../../../../../../common/toolbox/model';
import { deepCopy } from '../../../../../../../common/toolbox/object';
import { AuthService } from '../../../../common/service/auth.service';
import { DevService } from '../../../../common/service/dev.service';
import { FormulaService } from '../../../../common/service/formula.service';
import { LogService } from '../../../../common/service/log.service';
import { ModelService } from '../../../../common/service/model.service';
import { postRequest } from '../../../../common/toolbox/request';
import { BlockComponentMap } from '../../setup.module';
import { BlockComponent } from '../block.component';
import { BlockDrag, BlockSlot } from '../block.model';

@Component({
  selector: 'app-setup-formula-edit',
  templateUrl: './setup-formula-edit.component.html',
  styleUrls: ['./setup-formula-edit.component.scss'],
  providers: [{
    provide: 'BLOCK_PARENT',
    useValue: undefined
  }],
  host: {
    class: 'row'
  }
})
export class SetupFormulaEditComponent extends BlockComponent {
  /** Root attachment point for statements. */
  @ViewChild('statements', { static : true, read: ViewContainerRef }) statements!: ViewContainerRef;

  /** True if currently loading in a new resource. */
  @Input() loading = false;
  /** Change current item being configured. */
  @Input() set resource(resource: MaybeId<Formula>) {
    this.reset(resource);
  }

  /** Emits when creating new item. */
  @Output() submit = new EventEmitter<MaybeId<Formula>>();
  /** Emits when clearing current item. */
  @Output() cancel = new EventEmitter<void>();

  /** Current formula being edited. */
  value = idMaybe(new Formula());
  
  /** Input for formulas. */
  proxy = new DisplayValue();

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    public dev: DevService,
    protected log: LogService,
    protected auth: AuthService,
    protected http: HttpClient,
    private formulaService: FormulaService,
    private modelService: ModelService,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, undefined);
  }

  async ngOnInit() {
    // Fetch list of formulas to allow recursive formula evaluation.
    let formulas = await this.formulaService.previews(this.auth._inst);
    this.proxy.formula = FormulaProxy.previews(formulas);
  }

  /** Submit current changes to formula. */
  async onSubmit() {
    // Strip out placeholder expressions and statements.
    let formula = deepCopy(this.value);
    blockStrip(formula.statements);

    // Submit formula.
    let response = await postRequest(this.http, 'formulas', { items: [formula] });
    if (errorResponse(response)) {
      this.log.show(response);
      return;
    }

    this.log.show(`Successfully edited ${this.value.name}`);
    this.submit.emit(this.value);
  }

  /** Clear current formula. */
  onCancel() {
    this.cancel.emit();
  }

  /** Callback when ending drag of a chip. */
  onBlock(drag: BlockDrag) {
    this.slots[0]!.drop(drag);
  }

  /** Callback when changing current model. */
  async onModel(_id?: string) {
    this.value._model = _id;
    let model = _id ? await this.modelService.item({ _id, _inst: this.auth._inst }) : new Model();
    this.proxy.model = modelValue(model);
    this.onKeys([DisplayValue.pairs(this.proxy)]);
  }

  /** Copy formula to clipboard. */
  async onCode() {
    let text = formulaSource(this.value);
    await navigator.clipboard.writeText(text);
  }

  /** Refresh formula pane with new formula. */
  async reset(value: MaybeId<Formula>) {
    this.value = this.formula = value;
    this.block = value.statements;

    // Populate list of fields based off this model.
    this.onModel(this.value._model);
    this.restatements(this.slots[0], this.value.statements);
  }

  /** Replace root statement. */
  private restatements(slot: BlockSlot | undefined, block?: Block | Block[]): any {
    this.replace(slot, {
      parent: this.injector,
      container: this.statements,
      current: { block: this.value, key: 'statements' },
      next: block,
      accepts: [BlockType.Statement],
      replaced: this.restatements.bind(this)
    });
  }
}
