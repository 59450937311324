import { Component } from "@angular/core";
import { TaskConfigTag } from "../../../../../../../common/model/task-config";

/** Interface that allows a component to configure a specific task type. */
@Component({
  selector: 'app-task-accessor',
  template: ''
})
export abstract class TaskAccessor<T extends TaskConfigTag = TaskConfigTag> {

  /** Task config being edited. */
  config!: T;

  /** Called after configuration is bound. */
  reset() {}
}