import { DisputeCreditStatus, DisputeType } from "../../code/standard/disputes";
import { DisputesCode } from "../../code/system";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { UnionInfo } from "../../info/union";
import { NoId, NoIdInst, idInstOmit, idOmit } from "../../toolbox/id";
import { EnumValidator } from "../../validator/enum";
import { NoType } from "../claim/type";
import { LedgerItem } from "../ledger/item";
import { PropertyType } from "../property-type";
import { Transaction } from "../transaction";
import { DisputeBase, DisputeLookup } from "./base";

/** Tagged type of dispute. */
export type Dispute = DisputeACH | DisputeCard | DisputeCheck;
/** A dispute with an attached transaction and ledger. */
export type DisputeJoin = DisputeJoinACH | DisputeJoinCard | DisputeJoinCheck;
/** A dispute with an attached transaction and ledger for uploading. */
export type DisputeUploadJoin = NoId<DisputeUploadJoinACH | DisputeUploadJoinCard | DisputeUploadJoinCheck>;
/** Union of all possible dispute fields. */
export type DisputeUnion = { type: DisputeType } & DisputeLookup & NoType<DisputeACH> & NoType<DisputeCard> & NoType<DisputeCheck>;

/** Details specific to ACH disputes. */
export class DisputeACH extends DisputeBase {
  readonly type = DisputeType.ACH;
}

/** An ACH dispute joined with ledger and transactions. */
export class DisputeJoinACH extends DisputeACH implements DisputeLookup {

  constructor(
    /** Transaction linked to dispute. */
    public transaction?: Transaction,
    /** Ledger items linked to a dispute */
    public ledger?: LedgerItem[]
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DisputeJoinACH> = {
    ...DisputeACH.typeinfo,
    ...DisputeLookup.typeinfo,
    transaction: new Transaction(),
    ledger: [new LedgerItem()]
  }
}

/** An ACH dispute joined with ledger and transactions. */
export class DisputeUploadJoinACH extends DisputeACH {

  constructor(
    /** Transaction linked to dispute. */
    public transaction?: NoId<Transaction>,
    /** Ledger items linked to a dispute */
    public ledger?: NoIdInst<LedgerItem>[]
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DisputeUploadJoinACH> = {
    ...DisputeACH.typeinfo,
    transaction: idOmit(new Transaction()),
    ledger: [idInstOmit(new LedgerItem())]
  }
}

/** Details specific to card disputes. */
export class DisputeCard extends DisputeBase {
  readonly type = DisputeType.Card;

  constructor(
    /** Status of credit for this dispute. */
    public creditStatus = DisputeCreditStatus.None,
    /** Date investigation is due */
    public investigationDueDate?: Date,
    /** Date investigation was completed */
    public investigationCompletedDate?: Date,
    /** Date provisional credit was provided */
    public provisionalCreditDate?: Date,
    /** Date provisional credit is due */
    public provisionalCreditDueDate?: Date,
    /** Date consumer is notified of provisional credit */
    public provisionalCreditNoticeDate?: Date,
    /** Date consumer is notified of provisional credit */
    public provisionalCreditNoticeDueDate?: Date,
    /** Date dispute was submitted to card processor */
    public chargebackDate?: Date,
    /** Date chargeback was reviewed */
    public chargebackReviewedDate?: Date,
    /** Date representment docs were provided by merchant */
    public representmentDate?: Date,
    /** Date representment docs were reviewed */
    public representmentReviewedDate?: Date,
    /** Date card provider reimbursed FI */
    public recoveryDate?: Date,
    /** Date of second chargeback (pre-arbitration) */
    public secondChargebackDate?: Date,
    /** Date written confirmation was provided to consumer. */
    public writtenProvidedDate?: Date
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DisputeCard> = {
    ...DisputeBase.typeinfo,
    chargebackDate: new Date(),
    chargebackReviewedDate: new Date(),
    creditStatus: new EnumValidator(DisputeCreditStatus),
    investigationCompletedDate: new Date(),
    investigationDueDate: new Date(),
    provisionalCreditDate: new Date(),
    provisionalCreditDueDate: new Date(),
    provisionalCreditNoticeDate: new Date(),
    provisionalCreditNoticeDueDate: new Date(),
    recoveryDate: new Date(),
    representmentDate: new Date(),
    representmentReviewedDate: new Date(),
    secondChargebackDate: new Date(),
    writtenProvidedDate: new Date(),
  }

  static override propinfo: PropertyInfo<DisputeCard> = {
    ...DisputeBase.propinfo,
    creditStatus: { type: PropertyType.Code, category: DisputesCode.DisputeCreditStatus },
  }
}

/** An ACH dispute joined with ledger and transactions. */
export class DisputeJoinCard extends DisputeCard implements DisputeLookup {

  constructor(
    /** Transaction linked to dispute. */
    public transaction?: Transaction,
    /** Ledger items linked to a dispute */
    public ledger?: LedgerItem[]
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DisputeJoinCard> = {
    ...DisputeCard.typeinfo,
    ...DisputeLookup.typeinfo,
    transaction: new Transaction(),
    ledger: [new LedgerItem()]
  }
}

/** A card dispute joined with ledger and transactions. */
export class DisputeUploadJoinCard extends DisputeCard {

  constructor(
    /** Transaction linked to dispute. */
    public transaction?: NoId<Transaction>,
    /** Ledger items linked to a dispute */
    public ledger?: NoIdInst<LedgerItem>[]
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DisputeUploadJoinCard> = {
    ...DisputeCard.typeinfo,
    transaction: idOmit(new Transaction()),
    ledger: [idInstOmit(new LedgerItem())]
  }
}


/** Details specific to check disputes. */
export class DisputeCheck extends DisputeBase {
  readonly type = DisputeType.Check;
}

/** An ACH dispute joined with ledger and transactions. */
export class DisputeJoinCheck extends DisputeCheck implements DisputeLookup {

  constructor(
    /** Transaction linked to dispute. */
    public transaction?: Transaction,
    /** Ledger items linked to a dispute */
    public ledger?: LedgerItem[]
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DisputeJoinCheck> = {
    ...DisputeCheck.typeinfo,
    ...DisputeLookup.typeinfo,
    transaction: new Transaction(),
    ledger: [new LedgerItem()]
  }
}

/** An Check dispute joined with ledger and transactions. */
export class DisputeUploadJoinCheck extends DisputeCheck {

  constructor(
    /** Transaction linked to dispute. */
    public transaction?: NoId<Transaction>,
    /** Ledger items linked to a dispute */
    public ledger?: NoIdInst<LedgerItem>[]
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DisputeUploadJoinCheck> = {
    ...DisputeCheck.typeinfo,
    transaction: idOmit(new Transaction()),
    ledger: [idInstOmit(new LedgerItem())]
  }
}

/** Mapping of dispute types to classes. */
export class DisputeClass {
  [DisputeType.ACH] = new DisputeACH();
  [DisputeType.Card] = new DisputeCard();
  [DisputeType.Check] = new DisputeCheck();
};

/** Type information for each dispute type. */
export const DISPUTE_UNIONINFO: UnionInfo<Dispute, DisputeType> = {
  tag: 'type',
  classes: new DisputeClass()
};

/** Mapping of joined dispute types to classes. */
export class DisputeJoinClass {
  [DisputeType.ACH] = new DisputeJoinACH();
  [DisputeType.Card] = new DisputeJoinCard();
  [DisputeType.Check] = new DisputeJoinCheck();
};

/** Type information for each joined dispute type. */
export const DISPUTE_JOIN_UNIONINFO: UnionInfo<DisputeJoin, DisputeType> = {
  tag: 'type',
  classes: new DisputeJoinClass()
};

/** Mapping of joined dispute types to classes. */
export class DisputeUploadJoinClass {
  [DisputeType.ACH] = idOmit(new DisputeUploadJoinACH());
  [DisputeType.Card] = idOmit(new DisputeUploadJoinCard());
  [DisputeType.Check] = idOmit(new DisputeUploadJoinCheck());
};

/** Type information for each joined dispute type. */
export const DISPUTE_UPLOAD_JOIN_UNIONINFO: UnionInfo<DisputeUploadJoin, DisputeType> = {
  tag: 'type',
  classes: new DisputeUploadJoinClass()
};