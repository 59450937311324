<div class="row">
  <app-field class="fill">
    <app-label>Name</app-label>
    <app-string [(ngModel)]="action.name"></app-string>
  </app-field>
  <app-field class="fill">
    <app-label>Description</app-label>
    <app-string [(ngModel)]="action.description"></app-string>
  </app-field>
  <app-field class="fill">
    <app-label>Tasks</app-label>
    <app-resource-select collection="tasks" [multiple]="true" [required]="false" [(ngModel)]="action._tasks"></app-resource-select>
  </app-field>
</div>
<div class="row">
  <div class="fill">
    <app-label class="indent">Enable Conditions</app-label>
    <app-setup-workflow-conditions [conditions]="action.enable" (condition)="condition.emit($event)"></app-setup-workflow-conditions>
  </div>
  <div class="fill">
    <app-label class="indent">Completion Conditions</app-label>
    <app-setup-workflow-conditions [conditions]="action.completion" (condition)="condition.emit($event)"></app-setup-workflow-conditions>
  </div>
</div>
