<div class="row">
  <app-field class="fill">
    <app-label>Form</app-label>
    <app-resource-select collection="forms" [required]="false" [(ngModel)]="config._formList"></app-resource-select>
  </app-field>
  <app-field class="fill">
    <app-label>Form List</app-label>
    <app-resource-select collection="formLists" [required]="false" [(ngModel)]="config._formList"></app-resource-select>
  </app-field>
</div>
<app-setup-task-config-claim-review-config type="Approve" [config]="config.approve"></app-setup-task-config-claim-review-config>
<app-setup-task-config-claim-review-config type="Deny" [config]="config.deny"></app-setup-task-config-claim-review-config>