<app-field class="fill">
  <app-label>Users</app-label>
  <app-select empty="All Users" [(ngModel)]="job.payload._users" [multiple]="true" [required]="false" (selected)="onUser($event)">
    <app-option *ngFor="let user of users" [view]="user.name" [value]="user._id">
      <div class="fill"></div>
      <button *ngIf="dev.on && job._id" icon-button class="link" tooltip="Copy unsubscribe link" (click)="onUnsubscribeLink(user); $event.stopPropagation()">link</button>
      <app-icon [visible-if]="user._id | setHas:notUsers" icon-button color="red" tooltip="This user has unsubscribed and will not be notified.">unsubscribe</app-icon>
    </app-option>
  </app-select>
</app-field>
<app-field class="fill">
  <app-label>Profiles</app-label>
  <app-select empty="No Profiles" [(ngModel)]="job.payload._profiles" [multiple]="true" [required]="false">
    <app-option *ngFor="let p of profiles" [view]="p.name" [value]="p._id"></app-option>
  </app-select>
</app-field>