import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { ClaimModule } from "../claim/claim.module";
import { WorkModule } from "../work/work.module";
import { AccountComponent } from "./account.component";
import { AccountPanelComponent } from "./panel/account-panel.component";
import { AccountAttachmentListComponent } from "./panel/attachment-list/account-attachment-list.component";
import { AccountDisputesComponent } from "./panel/disputes/account-disputes.component";
import { AccountPanelListComponent } from "./panel/list/account-panel-list.component";
import { AccountPanelTestComponent } from "./panel/test/account-panel-test.component";
import { AccountResultsComponent } from "./results/account-results.component";
import { AccountSearchComponent } from "./search/account-search.component";
import { AccountSetupComponent } from "./setup/account-setup.component";
import { AccountToolbarComponent } from "./toolbar/account-toolbar.component";
import { AccountTransactionsComponent } from './transactions/account-transactions.component';
import { AccountWorkComponent } from './work/account-work.component';

@NgModule({
  declarations: [
    AccountAttachmentListComponent,
    AccountComponent,
    AccountPanelComponent,
    AccountDisputesComponent,
    AccountPanelListComponent,
    AccountPanelTestComponent,
    AccountResultsComponent,
    AccountResultsComponent,
    AccountSearchComponent,
    AccountSetupComponent,
    AccountTransactionsComponent,
    AccountToolbarComponent,
    AccountWorkComponent
  ],
  imports: [
    ClaimModule,
    SharedModule,
    WorkModule
  ]
})
export class AccountModule { }
