<app-currency
  [min]="min"
  [max]="max"
  [disabled]="disabled"
  [required]="required"
  [readonly]="readonly | propertyReadonly:locked:reopened"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  (dirtyChange)="setDirty($event)"
  (touchChange)="setTouched($event)">
</app-currency>