<app-card-body class="column">
  <div class="column">
    <app-field>
      <app-label>Table</app-label>
      <app-select [(ngModel)]="data.field._table" [required]="false">
        <app-option *ngFor="let table of tables" [view]="table.name" [value]="table._id"></app-option>
      </app-select>
    </app-field>
    <ng-template #container></ng-template>
  </div>
  <div class="button-row">
    <button (click)="apply()">Apply</button>
    <button (click)="dialogRef.cancel()">Cancel</button>
  </div>
</app-card-body>