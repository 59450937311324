import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { Setting, settingsinfoFlat } from "../../../../../../common/info/settings";
import { NestedKey, keyNestedGet, keyNestedSet } from '../../../../../../common/toolbox/keys';
import { searchQuery } from '../../../../../../common/toolbox/search';
import { AuthService } from '../../service/auth.service';
import { SettingsControlComponent } from './control/settings-control.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  host: {
    class: 'column'
  }
})
export class SettingsComponent<T> {
  /** Main form to project fields into. */
  @ViewChild('fields', { static: true, read: ViewContainerRef }) private fieldsRef!: ViewContainerRef;

  /** List of top-level settings to blacklist from view. */
  @Input() blacklist: string[] = [];
  /** Bind a new value to be configured. */
  @Input() set value(value: T) {
    if (!value) return;
    this.model = value;
    this.populate();
    this.patch();
    this.listen();
  }

  /** Search form over available settings. */
  builder = new FormBuilder().nonNullable.group({
    query: ['', [Validators.required]]
  });

  /** Current value bound to settings. */
  private model!: T;
  /** Mapping from field names to concrete components. */
  private components: SettingsControlComponent[] = [];
  /** Subscriptions for changing fields. */
  private subscription = Subscription.EMPTY;
  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();

  constructor(
    private auth: AuthService
  ) {
    this.builder.valueChanges
     .pipe(takeUntil(this.destroy), debounceTime(250))
     .subscribe(() => this.onSearch());
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.subscription.unsubscribe();
  }

  /** Callback when search query changes. */
  onSearch() {
    let query = searchQuery(this.builder.value.query!);
    for (let component of this.components) {
      component.filter(query);
    }
  }

  /** Create control for each field in form. */
  private populate() {
    this.clear();

    let settings = settingsinfoFlat(this.model, this.auth.institution.bank);
    settings = settings.filter(([key]) => !this.blacklist.some(prefix => (key as string).startsWith(prefix)));

    for (let [key, setting] of settings) {
      let value = keyNestedGet(key, this.model);
      this.setting(key as NestedKey<Setting>, setting, value);
    }
  }

  /** Patch controls with initial value. */
  private patch() {
    for (let component of this.components) {
      let value = keyNestedGet(component.key as NestedKey<T>, this.value);
      if (value !== undefined) component.writeValue(value);
    }
  }

  /** Update model when new values entered into controls. */
  private listen() {
    // Clear existing listeners.
    this.subscription.unsubscribe();
    this.subscription = new Subscription();

    for (let component of this.components) {
      component.valueChange.subscribe(value => {
        keyNestedSet(component.key as NestedKey<T>, this.model, value);
      });
    }
  }

  /** Clear all components from field list. */
  private clear() {
    this.fieldsRef.clear();
    this.components = [];
  }

  /** Add a new setting to list. */
  private setting(key: NestedKey<Setting>, setting: Setting, value: any) {
    // Create component and inject into field.
    let reference = this.fieldsRef.createComponent<SettingsControlComponent>(SettingsControlComponent);
    let component = reference.instance;
    component.populate(key, setting, value);
    this.components.push(component);
  }
}
