<ng-container *ngIf="!radio && !large; else radios">
  <button icon-button type="button" [class.undefined]="value === undefined" [toggle-button]="value" [toggle-button-indeterminate]="indeterminate || value === undefined" (click)="onValue(!value)" [disabled]="_disabled || _readonly"></button>
  <div class="container">
    <div class="text">{{value ? on : off}}</div>
  </div>
  <button icon-button #input type="button" *ngIf="value !== undefined && !required && !_readonly" (click)="onValue(undefined)">clear</button>
</ng-container>
<ng-template #radios>
  <app-radio-group [ngModel]="value" [horizontal]="!large">
    <app-radio-button [value]="true" (click)="onValue(true)">{{on}}</app-radio-button>
    <app-radio-button [value]="false" (click)="onValue(false)">{{off}}</app-radio-button>
  </app-radio-group>
</ng-template>
