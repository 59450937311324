import { Component } from '@angular/core';
import { LedgerAddTriggerConfig, LedgerConditionalConfig } from '../../../../../../../../common/model/trigger-config';
import { TriggerAccessor } from '../../edit/setup-trigger-edit.model';

@Component({
  selector: 'app-setup-trigger-config-ledger-add',
  templateUrl: './setup-trigger-config-ledger-add.component.html',
  styleUrls: ['./setup-trigger-config-ledger-add.component.scss']
})
export class SetupTriggerConfigLedgerAddComponent extends TriggerAccessor<LedgerAddTriggerConfig> {
  
  onCreate() {
    this.config.configs = this.config.configs ?? [];
    this.config.configs.push(new LedgerConditionalConfig());
  }
}
