import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { arrayLast } from "../../../../../../common/toolbox/array";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  host: {
    class: 'center dialog-backdrop'
  }
})
export class ErrorComponent {

  /** Error message to displayed. */
  error?: string;

  constructor(route: ActivatedRoute) {
    this.error = decodeURIComponent(arrayLast(route.snapshot.url)?.path ?? 'An error has occurred.');
  }
}
