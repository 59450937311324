import { Component } from '@angular/core';
import { CommentData, CommentList, CommentText } from "../../../../../../common/model/comment/comment";
import { AuthService } from '../../../common/service/auth.service';
import { UserService } from '../../../common/service/user.service';
import { CommentAction } from '../../../../../../common/model/comment/action';

@Component({
  selector: 'app-demo-comment-list',
  templateUrl: './demo-comment-list.component.html',
  styleUrls: ['./demo-comment-list.component.scss']
})
export class DemoCommentListComponent {

  /** List of comments being edited. */
  list = new CommentList();

  constructor(
    public auth: AuthService,
    private users: UserService,
  ) { }

  async ngOnInit() {
    let inst = this.auth._inst;
    let me = this.auth.session._id;
    let another = (await this.users.previews(inst)).filter(u => u._id !== me)[0]?._id ?? me;


    this.list = new CommentList(undefined, undefined, [
      new CommentData(another, [new CommentText("Other user's comment.")]),
      new CommentData(another, [new CommentText("Other user's deleted comment. this should not display")], true),
      new CommentData(me, [new CommentText("My deleted comment. this should not display")], true),
      new CommentData(me, [new CommentText("My comment 1.")], false),
      new CommentData(me, [new CommentText("My comment 2.")], false),
      new CommentData(me, [new CommentText("My comment 3.")], false)
    ]);
  }

  onUpdated(actions: CommentAction[]) {
    console.log(actions);
  }

  static title() {
    return 'Comment List';
  }
}
