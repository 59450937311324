import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { Subject, Subscription, combineLatest, startWith, takeUntil } from 'rxjs';
import { ColorCode } from '../../../../../../common/toolbox/color';
import { FieldControl } from './field-control';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  host: {
    '[tooltip]': 'tooltip',
    '[class.readonly]': '_readonly',
    '[class.errors]': 'touched && error'
  }
})
export class FieldComponent {
  readonly ColorCode = ColorCode;
  
  /** Reference to form control inside field. */
  @ContentChildren(FieldControl, { descendants: true }) controlRef!: QueryList<FieldControl>;

  /** True to display readonly style. */
  @Input() set readonly(readonly: BooleanInput) { this._readonly = coerceBooleanProperty(readonly); }
  /** Error to display in tooltip. */
  @Input() error = '';

  /** True if field has been touched. */
  @Input() touched = false;
  /** Emits when field is touched. */
  @Output() touchedChange = new EventEmitter<boolean>();
  
  /** True if in readonly mode. */
  protected _readonly = false;
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  ngAfterContentInit() {
    // Update field validity when fields recalculate errors.
    let subscription = Subscription.EMPTY;
    this.controlRef.changes.pipe(takeUntil(this.destroy), startWith(this.controlRef)).subscribe((controls: QueryList<FieldControl>) => {
      subscription.unsubscribe();
      subscription = new Subscription();

      subscription.add(combineLatest(controls.map(control => control.errors)).subscribe(errors => {
        this.error = errors.flat()[0] ?? '';
      }));

      subscription.add(combineLatest(controls.map(control => control.touches)).subscribe(touches => {
        this.touchedChange.next(this.touched = touches.some(touch => touch));
      }));
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
