<div class="row align-center">
  <h2>Available Claims</h2>
  <div class="fill"></div>
  <app-clock></app-clock>
</div>
<app-claim-counts></app-claim-counts>
<app-claim-list autorefresh title="My Active Claims" [options]="{ type, details, reportDate, _assigned: auth.session._id, status: ClaimStatus.Resolved, statusOperator: QueryOperator.Unequal }">
  <div class="fill"></div>
  <div class="row">
    <app-label color="white">Claim Type</app-label>
    <app-field>
      <app-code empty="All" [(ngModel)]="type" [category]="DisputesCode.ClaimType" [required]="false" (selected)="onType($any($event))"></app-code>
    </app-field>
    <app-label color="white">Reason</app-label>
    <app-field>
      <app-code empty="All" [(ngModel)]="details" [required]="false" [category]="category" [filter]="filter"></app-code>
    </app-field> 
    <app-label color="white">Date Reported</app-label>
    <app-field>
      <app-select empty="All" [(ngModel)]="reportDate" [required]="false">
        <app-option [view]="'Today'" [value]="-1"></app-option>
        <app-option [view]="'Past 7 days'" [value]="-7"></app-option>
        <app-option [view]="'Past 30 days'" [value]="-30"></app-option>
        <app-option [view]="'Past 90 days'" [value]="-90"></app-option>
      </app-select>
    </app-field>
  </div>
</app-claim-list>
<app-claim-list autorefresh title="Unassigned Claims" [options]="{ assignedOperator: QueryOperator.Unexists }"></app-claim-list>