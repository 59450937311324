import { Component, ElementRef, EventEmitter, Injector, Input, Output, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { elementScroll } from '../../../toolbox/element';
import { TabComponent } from '../tab.component';
import { TabConfig, TabRef, TAB_DATA } from './tab-bar.model';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent<T extends TabComponent = TabComponent> {
  /** Main element to project tabs into. */
  @ViewChild('content', { static : true, read: ViewContainerRef }) containerRef!: ViewContainerRef;
  /** Scroll pane holding tabs. */
  @ViewChild('labels') labels?: ElementRef<HTMLInputElement>;

  /** True to always show all tabs. */
  @Input() showall = false;

  /** Set current selected tab. */
  @Input()
  get tab() { return this._tab; }
  set tab(tab: T | undefined) {
    if (!tab) return;
    this._tab = tab;

    // Deactivate all other tabs.
    for (let t of this.tabs) {
      t.active = this.showall || t === tab;
    }

    // Scroll tab into view.
    setTimeout(() => {
      if (!this.labels) return;
      let index = this.tabs.findIndex(t => t === tab);
      let label = this.labels.nativeElement.children.item(index) as HTMLElement;
      elementScroll(this.labels.nativeElement, label);
    });
    
    this.change.next(tab);
  }

  /** Emits when active tab changes. */
  @Output() change = new EventEmitter<T>();
  /** Emits when new tab is added. */
  @Output() added = new EventEmitter<T>();
  /** Emits when tab is removed. */
  @Output() removed = new EventEmitter<T>();

  /** List of tabs along top bar. */
  tabs: T[] = [];
  /** Current selected tab. */
  private _tab: T | undefined;

  constructor(
    private injector: Injector
  ) { }

  /** Add a new tab to bar, injecting provided data. */
  open<D = any, R = any>(component: Type<any>, data: D, config: TabConfig<T>): TabRef<T, D, R> {
    // Get data to inject into component. Initially set tab to null since we haven't created it yet.
    let ref = new TabRef<T, D, R>(this, data);
    let injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: TabRef, useValue: ref },
        { provide: TAB_DATA, useValue: data }
      ]
    });

    // Create tab component and add content.
    let componentRef = this.containerRef.createComponent((config.container ?? TabComponent) as Type<T>, { injector });
    let tab = componentRef.instance;
    tab.title = config.title ?? 'New Tab';
    tab.component = component;
    
    tab.closeable = config?.closeable ?? false;
    tab.padding = config?.padding ?? true;
    ref.tab = tab;

    // Add tab to internal list and select.
    this.tabs.push(tab);
    this.added.emit(tab);
    if (config.autoselect !== false) this.tab = tab;
    return ref;
  }

  /** Close the specified tab. */
  close(tab: T) {
    for (let i = 0; i < this.tabs.length; ++i) {
      let t = this.tabs[i];
      if (t !== tab) continue;
      let active = tab.active;

      // Cut this tab out of list.
      this.tabs.splice(i, 1);
      this.removed.emit(t);
      this.containerRef.remove(i);
      if (active && this.tabs[i - 1]) this.tab = this.tabs[i - 1]!;
    }
  }

  /**
   *  Clear all tabs of tab bar.
   *  @param force True to also remove tabs without a close button.
   */
  clear(force = false) {
    let list = [...force ? this.tabs : this.tabs.filter(t => t.closeable)];
    for (let tab of list) {
      this.close(tab);
    }
  }
}
