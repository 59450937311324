<app-card body class="fill">
  <app-form-list [config]="config"></app-form-list>
  <div class="row align-end">
    <app-field>
      <app-label>Key</app-label>
      <app-string [(ngModel)]="change.key"></app-string>
    </app-field>
    <app-field>
      <app-label>Value</app-label>
      <app-string [(ngModel)]="change.value"></app-string>
    </app-field>
    <button (click)="onDirty()">Change Value</button>
  </div>
</app-card>