import { Pipe } from '@angular/core';
import { FeatureType } from "../../../../../common/model/organization/feature";
import { AuthService } from '../service/auth.service';

/** Check if given feature enabled. */
@Pipe({
  name: 'feature'
})
export class FeaturePipe {

  constructor(
    private auth: AuthService
  ) {}

  transform<T extends FeatureType>(feature?: T): boolean {
    return this.auth.enabled(feature);
  }
}
