<ng-container *ngFor="let view of views, let i = index">
  <div *ngIf="!view.comment.deleted">
    <app-field autosize [readonly]="!view.editing">
      <app-label class="fill">
        <div>{{view.comment._user | user:auth._inst | async}} on {{view.comment.revisions[0].createDate | dateIso:'datetime'}}</div>
      </app-label>
      <app-sublabel *ngIf="auth.session._id === view.comment._user">
        <ng-container *ngIf="view.editing">
          <app-label>Mention:</app-label>
          <app-field color="none" class="fill">
            <app-user [multiple]="true" [required]="false" [(ngModel)]="view.mentions"></app-user>
          </app-field>
          <button icon-button (click)="onEditSave(view, i)" tooltip="Save">check</button>
          <button icon-button (click)="onEditCancel(view)" tooltip="Cancel">close</button>
        </ng-container>
        <ng-container *ngIf="!view.editing">
          <button icon-button (click)="onEditStart(view)" tooltip="Edit">edit</button>
          <button icon-button (click)="onDelete(i)" tooltip="Delete">delete</button>
        </ng-container>
      </app-sublabel>
      <app-string [readonly]="!view.editing" [(ngModel)]="view.text" [large]="true"></app-string>
    </app-field>
  </div>
</ng-container>
<div class="newComment">
  <app-field autosize>
    <app-label class="fill">
      <app-icon>account_circle</app-icon>
      <div>{{auth.session._id | user:auth._inst | async}}</div>
    </app-label>
    <app-sublabel>
      <app-label>Mention:</app-label>
      <app-field color="none">
        <app-user [multiple]="true" [required]="false" [(ngModel)]="comment.mentions"></app-user>
      </app-field>
      <button icon-button (click)="onNewSave()" tooltip="Save">check</button>
      <button icon-button (click)="onNewClear()" tooltip="Clear">close</button>
    </app-sublabel>
    <app-string [(ngModel)]="comment.text" [large]="true"></app-string>
  </app-field>
</div>