<app-card body class="fill">
  <app-edit-list [(ngModel)]="people" [default]="default">
    <app-edit-list-item cdkDrag *ngFor="let person of people">
      <div class="row fill">
        <app-field horizontal class="fill">
          <app-label>Name:</app-label>
          <app-string [(ngModel)]="person.name"></app-string>
        </app-field>
        <app-field horizontal class="fill">
          <app-label>Age:</app-label>
          <app-number [(ngModel)]="person.age"></app-number>
        </app-field>
      </div>
    </app-edit-list-item>
  </app-edit-list>
</app-card>