import { AccountCategory } from "../../code/standard/common";
import { CommonCode, ImportCode } from "../../code/system";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { CURRENCY_DEFAULT, currency } from "../../toolbox/currency";
import { DelinquencyPeriod } from "../delinquency-period";
import { PropertyType } from "../property-type";
import { AccountBase } from "./base";

/** A loan belonging to a user. */
export class AccountLoan extends AccountBase {
  readonly category = AccountCategory.Loan;
  
  constructor(
    /** Host-provided bankruptcy code, if applicable. */
    public bankruptcyCode?: string,
    /** Maximum charge that can be made on revolving account. */
    public creditLimit?: currency,
    /** FICO score of account. */
    public creditScore?: number,
    /** Periods loan was delinquent, and how frequent. */
    public delinquencyPeriods?: DelinquencyPeriod[],
    /** True if this is a high-risk loan. */
    public highRisk?: boolean,
    /** Established interest rate on loan. */
    public interestRate?: currency,
    /** Amount of last payment. */
    public lastPaymentAmount?: currency,
    /** Date last payment was made. */
    public lastPaymentDate?: Date,
    /** Any late fees accrued on loan. */
    public lateFees?: number,
    /** Amount of next payment that needs to be made. */
    public nextPaymentAmount?: number,
    /** Nickname provided for loan. */
    public nickname?: string,
    /** Note number of loan. */
    public noteNumber?: string,
    /** Host-provided occupancy code for loan, if property. */
    public occupancyType?: string,
    /** Original risk grade granted to loan. */
    public originalRiskGrade?: string,
    /** Partial payments that have been made on loan. */
    public partialPayment?: currency,
    /** Accumulated past due amount of loan. */
    public pastDueAmount?: currency,
    /** Number of payments to complete loan. */
    public paymentCount?: number,
    /** Due date of next payment. */
    public paymentDueDate?: Date,
    /** Number of payments made so far. */
    public paymentsMade?: number,
    /** Jurisdiction, if loan is a property. */
    public propertyJurisdiction?: string,
    /** Host-provided code for loan's purpose. */
    public purpose?: string
  ) {
    super();
  }

  static override typeinfo: TypeInfo<AccountLoan> = {
    ...AccountBase.typeinfo,
    bankruptcyCode: '',
    creditLimit: CURRENCY_DEFAULT,
    creditScore: 0,
    delinquencyPeriods: [new DelinquencyPeriod()],
    highRisk: false,
    interestRate: CURRENCY_DEFAULT,
    lastPaymentAmount: CURRENCY_DEFAULT,
    lastPaymentDate: new Date(),
    lateFees: CURRENCY_DEFAULT,
    nickname: '',
    noteNumber: '',
    occupancyType: '',
    originalRiskGrade: '',
    partialPayment: CURRENCY_DEFAULT,
    pastDueAmount: CURRENCY_DEFAULT,
    nextPaymentAmount: CURRENCY_DEFAULT,
    paymentCount: 0,
    paymentDueDate: new Date(),
    paymentsMade: 0,
    propertyJurisdiction: '',
    purpose: '',
  }

  static override propinfo: PropertyInfo<AccountLoan> = {
    ...AccountBase.propinfo,
    bankruptcyCode: { type: PropertyType.Code, category: ImportCode.BankruptcyChapter },
    category: { type: PropertyType.Code, category: CommonCode.AccountCategory },
    creditLimit: { type: PropertyType.Currency },
    interestRate: { type: PropertyType.Currency },
    lastPaymentAmount: { type: PropertyType.Currency },
    lateFees: { type: PropertyType.Currency },
    nextPaymentAmount: { type: PropertyType.Currency },
    occupancyType: { type: PropertyType.Code, category: ImportCode.Occupancy },
    partialPayment: { type: PropertyType.Currency },
    pastDueAmount: { type: PropertyType.Currency },
    purpose: { type: PropertyType.Code, category: ImportCode.LoanPurpose }
  }
}