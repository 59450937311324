import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { User, UserPreview } from '../../../../../../common/model/user/user';
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { LogService } from '../../../common/service/log.service';
import { UserQuery, UserService } from '../../../common/service/user.service';
import { getRequest } from '../../../common/toolbox/request';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-user',
  templateUrl: './setup-user.component.html',
  styleUrls: ['./setup-user.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupUserComponent extends SetupComponent<User, UserQuery, UserPreview> {
  readonly Type = User;
  readonly collection = 'users';
  readonly route = 'users';
  resource = idMaybe(new User());

  static title() {
    return 'Users';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public http: HttpClient,
    public auth: AuthService,
    public service: UserService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }

  protected override fetchPreviews() {
    return getRequest(this.http, 'users/preview', { _insts: [this.auth._inst] });
  }
}
