import { TypeInfo } from "../../info/type";

/** Types of available authentication. */
export enum AuthenticationType {
  Fusion = 'fusion',
  Microsoft = 'microsoft'
}

/** Fusion authentication. */
export class AuthenticationFusion {}

/** Microsoft login authentication. */
export class AuthenticationMicrosoft {
  constructor(
    /** Tenant ID of organization. */
    public tenant = '',
    /** Configured application ID for Fusion. */
    public clientId = ''
  ) {}
}

/** List of supported authentication flows for organization. */
export class Authentications {
  [AuthenticationType.Fusion]?: AuthenticationFusion;
  [AuthenticationType.Microsoft]?: AuthenticationMicrosoft;

  static typeinfo: TypeInfo<Authentications> = {
    [AuthenticationType.Fusion]: new AuthenticationFusion(),
    [AuthenticationType.Microsoft]: new AuthenticationMicrosoft()
  }
}