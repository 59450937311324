import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormList, FormListPreview } from "../../../../../common/model/form/list";
import { ApplicationSettingsKey } from '../../../../../common/model/setting-group';
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { serviceSettingsItem } from '../toolbox/service';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { SettingGroupService } from './setting-group.service';

/** A query to fetch a specific form list. */
export class FormListQuery {
  constructor(
    /** ID of form list. */
    public _id = ID_DEFAULT,
    /** Institution of form list. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Caches inform listation about tasks. */
@Injectable({
  providedIn: 'root'
})
export class FormListService extends CachePreviewService<FormList, FormListQuery, FormListPreview> {
  readonly route = 'form-lists/preview';
  readonly Type = FormList;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private settings: SettingGroupService
  ) {
    super(FormListQuery, log, dialog, http);
  }

  /** Fetch a form list from settings. */
  setting(key: ApplicationSettingsKey) {
    return serviceSettingsItem('Form list', this, this.auth, this.log, this.settings, key);
  }

  protected override multiple(queries: ArraySome<FormListQuery>) {
    return getRequest(this.http, 'form-lists', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}
