import { UnionValidator } from "../validator/union";
import { AccountCardRelationship } from "./account-card";
import { AccountMemberRelationship } from "./account-member";
import { ACCOUNT_UNIONINFO, Account } from "./account/account";
import { Address } from "./address";
import { Attachment } from "./attachment";
import { CachedItem } from "./cache";
import { Card } from "./card";
import { CLAIM_UNIONINFO, Claim } from "./claim/claim";
import { CLAIM_FILTER_UNIONINFO, ClaimFilter } from "./claim/filter";
import { CodeType } from "./code-type";
import { CollectionClass } from "./collection";
import { Counter } from "./counter";
import { DISPUTE_UNIONINFO, Dispute } from "./dispute/dispute";
import { DocumentTemplate } from "./document-template/base";
import { Event } from "./event";
import { FilterRule } from "./filter-rule";
import { Form } from "./form/form";
import { FormList } from "./form/list";
import { Formula } from "./formula/formula";
import { IMPORT_CONFIG_UNIONINFO, ImportConfig } from "./import";
import { Institution } from "./institution";
import { Job } from "./job/job";
import { LedgerConfig } from "./ledger/config";
import { LedgerItem } from "./ledger/item";
import { Member } from "./member";
import { MemberCardRelationship } from "./member-card";
import { Model } from "./model";
import { Organization } from "./organization/organization";
import { Profile } from "./profile";
import { SettingGroup } from "./setting-group";
import { Table } from "./table";
import { Task } from "./task";
import { Transaction } from "./transaction";
import { Trigger } from "./trigger";
import { User } from "./user/user";
import { Workflow } from "./work/flow";
import { WorkItem } from "./work/item";
import { WorkQueue } from "./work/queue";

/** Name of a fusion collection. */
export type FusionCollectionName = keyof FusionCollection;

/** Mapping between collections and returned types in the Fusion API. */
export interface FusionCollection {
  accountCards: AccountCardRelationship
  accountMembers: AccountMemberRelationship
  accounts: Account
  addresses: Address
  attachments: Attachment
  cache: CachedItem<any>
  cards: Card,
  claims: Claim
  codeTypes: CodeType
  claimFilters: ClaimFilter
  counters: Counter
  disputes: Dispute
  documentTemplates: DocumentTemplate
  events: Event
  filterRules: FilterRule;
  forms: Form
  formLists: FormList
  formulas: Formula
  importConfig: ImportConfig
  institutions: Institution
  jobs: Job
  ledgers: LedgerItem
  ledgerConfigs: LedgerConfig
  memberCards: MemberCardRelationship
  members: Member
  models: Model
  organizations: Organization
  profiles: Profile
  settingGroups: SettingGroup
  tables: Table
  tasks: Task
  transactions: Transaction
  triggers: Trigger
  users: User
  workflows: Workflow
  workItems: WorkItem
  workQueues: WorkQueue
}

/** Class of each collection. */
export const FUSION_COLLECTION_CLASS: CollectionClass<FusionCollection> = {
  accountCards: AccountCardRelationship,
  accountMembers: AccountMemberRelationship,
  accounts: new UnionValidator(ACCOUNT_UNIONINFO),
  addresses: Address,
  attachments: Attachment,
  cache: CachedItem,
  cards: Card,
  codeTypes: CodeType,
  counters: Counter,
  claims: new UnionValidator(CLAIM_UNIONINFO),
  claimFilters: new UnionValidator(CLAIM_FILTER_UNIONINFO),
  disputes: new UnionValidator(DISPUTE_UNIONINFO),
  events: Event,
  filterRules: FilterRule,
  forms: Form,
  formLists: FormList,
  formulas: Formula,
  importConfig: new UnionValidator(IMPORT_CONFIG_UNIONINFO),
  institutions: Institution,
  jobs: Job,
  ledgers: LedgerItem,
  ledgerConfigs: LedgerConfig,
  memberCards: MemberCardRelationship,
  members: Member,
  models: Model,
  organizations: Organization,
  profiles: Profile,
  documentTemplates: DocumentTemplate,
  settingGroups: SettingGroup,
  tables: Table,
  tasks: Task,
  transactions: Transaction,
  triggers: Trigger,
  users: User,
  workflows: Workflow,
  workItems: WorkItem,
  workQueues: WorkQueue
};