import { DisplayType } from "../../display";
import { BuiltinForm } from "../form";
import { MemberFormula } from "../formula/member";
import { builtinFormField } from "./form";

/** Built-in general forms. */
export enum MemberForm {
  Member = 'Member',
  Search = 'Member - Search'
}

/** List of builtin forms. */
export const BUILTIN_MEMBER_FORMS: Record<MemberForm, BuiltinForm> = {
  [MemberForm.Member]: {
    name: {
      bank: 'Customer',
      creditUnion: 'Member'
    },
    fields: [
      builtinFormField(`formula.${MemberFormula.Name}`),
      builtinFormField('member.taxId'),
      builtinFormField('member.number'),
      builtinFormField('member.gender'),
      builtinFormField('member.occupation'),
      builtinFormField('member.grossMoPay')
    ]
  },
  [MemberForm.Search]: {
    name: {
      bank: 'Customer - Search',
      creditUnion: 'Member - Search'
    },
    editable: [DisplayType.Member],
    fields: [
      builtinFormField('member.firstName'),
      builtinFormField('member.number'),
      builtinFormField('member.taxId'),
      builtinFormField('member.birthDate'),
      builtinFormField('member.deathDate')
    ]
  }
};