import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { BuiltinFormula } from "../formula";

/** Formulas that operate on events. */
export enum EventFormula {
  Incomplete = 'Event - Incomplete',
}

export const BUILTIN_EVENT_FORMULAS: Record<EventFormula, BuiltinFormula> = {
  [EventFormula.Incomplete]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'event.result._assigned'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: 'Event has not been completed.'
        }
      }]
    }]
  }
}