import { Component, Inject } from '@angular/core';
import { FormConfig } from '../../../../common/component/form/form.model';
import { TAB_DATA, TabRef } from '../../../../common/component/tab/bar/tab-bar.model';
import { SetupFormPreviewData } from './setup-form-preview.model';

@Component({
  selector: 'app-setup-form-preview',
  templateUrl: './setup-form-preview.component.html',
  styleUrls: ['./setup-form-preview.component.scss']
})
export class SetupFormPreviewComponent {
  /** Configuration for previewing form. */
  config: FormConfig;

  constructor(
    @Inject(TAB_DATA)
    public data: SetupFormPreviewData,
    public ref: TabRef
  ) {
    this.config = { form: data.form };
  }

  static title(data: SetupFormPreviewData) {
    return data.form.name;
  }

  /** Callback when closing test form. */
  close() {
    this.ref.close();
  }
}
