import { Component } from "@angular/core";
import { PermissionEdit } from "../../../../../../common/model/permission";
import { UserAuthenticationFusion } from "../../../../../../common/model/user/authentication";
import { UserSession } from "../../../../../../common/model/user/user";
import { deepCopy } from "../../../../../../common/toolbox/object";
import { PermissionsGridData } from "../../../common/component/permissions-grid/permissions-grid.model";
import { AuthService } from "../../../common/service/auth.service";
import { DevService } from "../../../common/service/dev.service";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent {
  readonly UserAuthenticationFusion = UserAuthenticationFusion;

  /** Current logged in user. */
  user = new UserSession();
  /** List of permissions on user. */
  data = new PermissionsGridData();

  static title() {
    return 'User Settings';
  }

  constructor(
    protected dev: DevService,
    private auth: AuthService
  ) {
    this.user = deepCopy(auth.session);
    this.data.main = PermissionEdit.record(this.auth.grant.permissions);
  }

  /** Callback when developer mode is toggled. */
  onDev(on: boolean) {
    this.dev.toggle(this.auth.session._id, on);
  }
}
