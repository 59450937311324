import { LicenseFormat } from "../toolbox/license";
import { Range } from "../toolbox/number";
import { EnumMap } from "../toolbox/enum";
import { ArraySome } from "../toolbox/array";

/** Mapping of states to cities. */
export type StateCities = Record<State, string[]>;

/** List of valid state codes. */
export enum State {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}

/** A flat list of all states. */
export const STATE_LIST = [
  State.AK,
  State.AL,
  State.AR,
  State.AZ,
  State.CA,
  State.CO,
  State.CT,
  State.DC,
  State.DE,
  State.FL,
  State.GA,
  State.HI,
  State.IA,
  State.ID,
  State.IL,
  State.IN,
  State.KS,
  State.KY,
  State.LA,
  State.MA,
  State.MD,
  State.ME,
  State.MI,
  State.MN,
  State.MO,
  State.MS,
  State.MT,
  State.NC,
  State.ND,
  State.NE,
  State.NH,
  State.NJ,
  State.NM,
  State.NV,
  State.NY,
  State.OH,
  State.OK,
  State.OR,
  State.PA,
  State.PR,
  State.RI,
  State.SC,
  State.SD,
  State.TN,
  State.TX,
  State.UT,
  State.VA,
  State.VT,
  State.WA,
  State.WI,
  State.WV,
  State.WY
];

/** Mapping from state codes to names. */
export const STATE_NAME: EnumMap<State> = {
  [State.AK]: 'Alaska',
  [State.AL]: 'Alabama',
  [State.AR]: 'Arkansas',
  [State.AZ]: 'Arizona',
  [State.CA]: 'California',
  [State.CO]: 'Colorado',
  [State.CT]: 'Connecticut',
  [State.DC]: 'Washington, DC',
  [State.DE]: 'Delaware',
  [State.FL]: 'Florida',
  [State.GA]: 'Georgia',
  [State.HI]: 'Hawaii',
  [State.IA]: 'Iowa',
  [State.ID]: 'Idaho',
  [State.IL]: 'Illinois',
  [State.IN]: 'Indiana',
  [State.KS]: 'Kansas',
  [State.KY]: 'Kentucky',
  [State.LA]: 'Louisiana',
  [State.MA]: 'Massachusetts',
  [State.MD]: 'Maryland',
  [State.ME]: 'Maine',
  [State.MI]: 'Michigan',
  [State.MN]: 'Minnesota',
  [State.MO]: 'Missouri',
  [State.MS]: 'Mississippi',
  [State.MT]: 'Montana',
  [State.NC]: 'North Carolina',
  [State.ND]: 'North Dakota',
  [State.NE]: 'Nebraska',
  [State.NH]: 'New Hampshire',
  [State.NJ]: 'New Jersey',
  [State.NM]: 'New Mexico',
  [State.NV]: 'Nevada',
  [State.NY]: 'New York',
  [State.OH]: 'Ohio',
  [State.OK]: 'Oklahoma',
  [State.OR]: 'Oregon',
  [State.PA]: 'Pennsylvania',
  [State.PR]: 'Puerto Rico',
  [State.RI]: 'Rhode Island',
  [State.SC]: 'South Carolina',
  [State.SD]: 'South Dakota',
  [State.TN]: 'Tennessee',
  [State.TX]: 'Texas',
  [State.UT]: 'Utah',
  [State.VA]: 'Virginia',
  [State.VT]: 'Vermont',
  [State.WA]: 'Washington',
  [State.WI]: 'Wisconsin',
  [State.WV]: 'West Virginia',
  [State.WY]: 'Wyoming'
};

/** Reverse mapping from state names to codes. */
export const STATE_CODE: Record<string, State> = {
  'Alaska': State.AK,
  'Alabama': State.AL,
  'Arkansas': State.AR,
  'Arizona': State.AZ,
  'California': State.CA,
  'Colorado': State.CO,
  'Connecticut': State.CT,
  'Washington, DC': State.DC,
  'Delaware': State.DE,
  'Florida': State.FL,
  'Georgia': State.GA,
  'Hawaii': State.HI,
  'Idaho': State.ID,
  'Illinois': State.IL,
  'Iowa': State.IA,
  'Indiana': State.IN,
  'Kansas': State.KS,
  'Kentucky': State.KY,
  'Louisiana': State.LA,
  'Maine': State.ME,
  'Massachusetts': State.MA,
  'Michigan': State.MI,
  'Maryland': State.MD,
  'Minnesota': State.MN,
  'Missouri': State.MO,
  'Mississippi': State.MS,
  'Montana': State.MT,
  'Nebraska': State.NE,
  'Nevada': State.NV,
  'New Hampshire': State.NH,
  'New Jersey': State.NJ,
  'New Mexico': State.NM,
  'New York': State.NY,
  'North Carolina': State.NC,
  'North Dakota': State.ND,
  'Ohio': State.OH,
  'Oklahoma': State.OK,
  'Oregon': State.OR,
  'Pennsylvania': State.PA,
  'Puerto Rico': State.PR,
  'Rhode Island': State.RI,
  'South Carolina': State.SC,
  'South Dakota': State.SD,
  'Tennessee': State.TN,
  'Texas': State.TX,
  'Utah': State.UT,
  'Virginia': State.VA,
  'Vermont': State.VT,
  'Washington': State.WA,
  'Wisconsin': State.WI,
  'West Virginia': State.WV,
  'Wyoming': State.WY
};

/** Format for driver's license in each state. */
export const STATE_LICENSE: Record<State, LicenseFormat> = {
  [State.AK]: new LicenseFormat([/^\d{1,7}$/]),
  [State.AL]: new LicenseFormat([/^\d{1,8}$/]),
  [State.AR]: new LicenseFormat([/^\d{4,9}$/]),
  [State.AZ]: new LicenseFormat([/^[A-Z]\d{8}$/, /^\d{9}$/]),
  [State.CA]: new LicenseFormat([/^[A-Z]\d{7}$/]),
  [State.CO]: new LicenseFormat([/^\d{9}$/, /^[A-Z]\d{3,6}$/, /^[A-Z]{2}\d{2,5}$/]),
  [State.CT]: new LicenseFormat([/^\d{9}$/]),
  [State.DC]: new LicenseFormat([/^\d{7}$/, /^\d{9}$/]),
  [State.DE]: new LicenseFormat([/^\d{1,7}$/]),
  [State.FL]: new LicenseFormat([/^[A-Z]\d{12}$/]),
  [State.GA]: new LicenseFormat([/^\d{7,9}$/]),
  [State.HI]: new LicenseFormat([/^[A-Z]\d{8}$/, /^\d{9}$/]),
  [State.IA]: new LicenseFormat([/^\d{9}$/, /^\d{3}[A-Z]{2}\d{4}$/]),
  [State.ID]: new LicenseFormat([/^[A-Z]{2}\d{6}Z$/, /^\d{9}$/]),
  [State.IL]: new LicenseFormat([/^[A-Z]\d{11,12}$/]),
  [State.IN]: new LicenseFormat([/^[A-Z]\d{9}$/, /^\d{9,10}$/]),
  [State.KS]: new LicenseFormat([/^[A-Z]\d[A-Z]\dZ$/, /^[A-Z]\d{8}$/, /^\d{9}$/]),
  [State.KY]: new LicenseFormat([/^[A-Z]\d{8}$/, /^[A-Z]\d{9}$/, /^\d{9}$/]),
  [State.LA]: new LicenseFormat([/^\d{9}$/]),
  [State.MA]: new LicenseFormat([/^[A-Z]\d{8}$/, /^\d{9}$/]),
  [State.MD]: new LicenseFormat([/^[A-Z]\d{12}$/]),
  [State.ME]: new LicenseFormat([/^\d{7}$/, /^\d{7}Z$/, /^\d{8}$/]),
  [State.MI]: new LicenseFormat([/^[A-Z]\d{10}$/, /^[A-Z]\d{12}$/]),
  [State.MN]: new LicenseFormat([/^[A-Z]\d{12}$/]),
  [State.MO]: new LicenseFormat([/^\d{3}[A-Z]\d{6}$/, /^[A-Z]\d{5,9}$/, /^[A-Z]\d{6}R/, /^\d{8}[A-Z]{2}$/, /^\d{9}Z$/, /^\d{9}$/]),
  [State.MS]: new LicenseFormat([/^\d{9}$/]),
  [State.MT]: new LicenseFormat([/^[A-Z]\d{8}$/, /^\d{9}$/, /^\d{13,14}$/]),
  [State.NC]: new LicenseFormat([/^\d{1,12}$/]),
  [State.ND]: new LicenseFormat([/^[A-Z]{3}\d{6}$/, /^\d{9}$/]),
  [State.NE]: new LicenseFormat([/^[A-Z]\d{6,8}$/]),
  [State.NH]: new LicenseFormat([/^\d{2}[A-Z]{3}\d{5}$/]),
  [State.NJ]: new LicenseFormat([/^[A-Z]\d{14}$/]),
  [State.NM]: new LicenseFormat([/^\d{8,9}$/]),
  [State.NV]: new LicenseFormat([/^\d{9,10}$/, /^\d{12}$/, /^X\d{8}$/]),
  [State.NY]: new LicenseFormat([/^[A-Z]\d{7}$/, /^[A-Z]\d{18}$/, /^\d{8,9}$/, /^\d{16}$/, /^[A-Z]{8}$/]),
  [State.OH]: new LicenseFormat([/^[A-Z]\d{4,8}$/, /^[A-Z]{2}\d{3,7}$/, /^\d{8}$/]),
  [State.OK]: new LicenseFormat([/^[A-Z]\d{9}$/, /^\d{9}$/]),
  [State.OR]: new LicenseFormat([/^\d{1,9}$/]),
  [State.PA]: new LicenseFormat([/^\d{8}$/]),
  [State.PR]: new LicenseFormat([/^\d{7}$/, /^\d{9}$/]),
  [State.RI]: new LicenseFormat([/^\d{7}$/, /^[A-Z]\d{6}$/]),
  [State.SC]: new LicenseFormat([/^\d{5,11}$/]),
  [State.SD]: new LicenseFormat([/^\d{6,10}$/, /^\d{12}$/]),
  [State.TN]: new LicenseFormat([/^\d{7,9}$/]),
  [State.TX]: new LicenseFormat([/^\d{7,8}$/]),
  [State.UT]: new LicenseFormat([/^\d{4,10}$/]),
  [State.VA]: new LicenseFormat([/^[A-Z]\d{8,11}$/, /^\d{9}$/]),
  [State.VT]: new LicenseFormat([/^\d{8}$/, /^\d{7}A/]),
  [State.WA]: new LicenseFormat([/^\d{1,7}[A-Z]{5}$/]),
  [State.WI]: new LicenseFormat([/^[A-Z]\d{13}$/]),
  [State.WV]: new LicenseFormat([/^\d{7}$/, /^[A-Z]{1,2}\d{5,6}/]),
  [State.WY]: new LicenseFormat([/^\d{9,10}$/])
};

/** Valid range of zip codes in each state. */
export const STATE_ZIP: Record<State, ArraySome<Range>> = {
  [State.AK]: [
    new Range(99501, 99950)
  ],
  [State.AL]: [
    new Range(35004, 36925)
  ],
  [State.AR]: [
    new Range(71601, 72959),
    new Range(75502, 75502)
  ],
  [State.AZ]: [
    new Range(85001, 86556)
  ],
  [State.CA]: [
    new Range(90001, 96162)
  ],
  [State.CO]: [
    new Range(80001, 81658)
  ],
  [State.CT]: [
    new Range(6001, 6389),
    new Range(6401, 6928)
  ],
  [State.DC]: [
    new Range(20001, 20039),
    new Range(20042, 20599),
    new Range(20799, 20799)
  ],
  [State.DE]: [
    new Range(19701, 19980)
  ],
  [State.FL]: [
    new Range(32004, 34997)
  ],
  [State.GA]: [
    new Range(30001, 31999),
    new Range(39901, 39901)
  ],
  [State.HI]: [
    new Range(96701, 96898)
  ],
  [State.IA]: [
    new Range(50001, 52809),
    new Range(68119, 68120)
  ],
  [State.ID]: [
    new Range(83201, 83876)
  ],
  [State.IL]: [
    new Range(60001, 62999)
  ],
  [State.IN]: [
    new Range(46001, 47997)
  ],
  [State.KS]: [
    new Range(66002, 67954)
  ],
  [State.KY]: [
    new Range(40003, 42788)
  ],
  [State.LA]: [
    new Range(70001, 71232),
    new Range(71234, 71497)
  ],
  [State.MA]: [
    new Range(1001, 2791),
    new Range(5501, 5544)
  ],
  [State.MD]: [
    new Range(20331, 20331),
    new Range(20335, 20797),
    new Range(20812, 21930)
  ],
  [State.ME]: [
    new Range(3901, 4992)
  ],
  [State.MI]: [
    new Range(48001, 49971)
  ],
  [State.MN]: [
    new Range(55001, 56763)
  ],
  [State.MO]: [
    new Range(63001, 65899)
  ],
  [State.MS]: [
    new Range(38601, 39776),
    new Range(71233, 71233)
  ],
  [State.MT]: [
    new Range(59001, 59937)
  ],
  [State.NC]: [
    new Range(27006, 28909)
  ],
  [State.ND]: [
    new Range(58001, 58856)
  ],
  [State.NE]: [
    new Range(68001, 68118),
    new Range(68122, 69367)
  ],
  [State.NH]: [
    new Range(3031, 3897)
  ],
  [State.NJ]: [
    new Range(7001, 8989)
  ],
  [State.NM]: [
    new Range(87001, 88441)
  ],
  [State.NV]: [
    new Range(88901, 89883)
  ],
  [State.NY]: [
    new Range(10001, 14975),
    new Range(6390, 6390)
  ],
  [State.OH]: [
    new Range(43001, 45999)
  ],
  [State.OK]: [
    new Range(73001, 73199),
    new Range(73401, 74966)
  ],
  [State.OR]: [
    new Range(97001, 97920)
  ],
  [State.PA]: [
    new Range(15001, 19640)
  ],
  [State.PR]: [
    new Range(0, 0)
  ],
  [State.RI]: [
    new Range(2801, 2940)
  ],
  [State.SC]: [
    new Range(29001, 29948)
  ],
  [State.SD]: [
    new Range(57001, 57799)
  ],
  [State.TN]: [
    new Range(37010, 38589)
  ],
  [State.TX]: [
    new Range(73301, 73301),
    new Range(75001, 75501),
    new Range(75503, 79999),
    new Range(88510, 88589)
  ],
  [State.UT]: [
    new Range(84001, 84784)
  ],
  [State.VA]: [
    new Range(20040, 20041),
    new Range(20040, 20167),
    new Range(20042, 20042),
    new Range(22001, 24658)
  ],
  [State.VT]: [
    new Range(5001, 5495),
    new Range(5601, 5907)
  ],
  [State.WA]: [
    new Range(98001, 99403)
  ],
  [State.WI]: [
    new Range(53001, 54990)
  ],
  [State.WV]: [
    new Range(24701, 26886)
  ],
  [State.WY]: [
    new Range(82001, 83128
    )]
}

/** Area codes of each state. */
export const STATE_AREA_CODE: Record<State, number[]> = {
  [State.AK]: [907],
  [State.AL]: [205, 251, 256, 334, 938],
  [State.AR]: [479, 501, 870],
  [State.AZ]: [480, 520, 602, 623, 928],
  [State.CA]: [209, 213, 279, 310, 323, 408, 415, 424, 442, 510, 530, 559, 562, 619, 626, 628, 650, 657, 661, 669, 707, 714, 747, 760, 805, 818, 820, 831, 858, 909, 916, 925, 949, 951],
  [State.CO]: [303, 719, 720, 970],
  [State.CT]: [203, 475, 860, 959],
  [State.DC]: [202],
  [State.DE]: [302],
  [State.FL]: [239, 305, 321, 352, 386, 407, 561, 727, 754, 772, 786, 813, 850, 863, 904, 941, 954],
  [State.GA]: [229, 404, 470, 478, 678, 706, 762, 770, 912],
  [State.HI]: [808],
  [State.IA]: [319, 515, 563, 641, 712],
  [State.ID]: [208, 986],
  [State.IL]: [217, 224, 309, 312, 331, 618, 630, 708, 773, 779, 815, 847, 872],
  [State.IN]: [219, 260, 317, 463, 574, 765, 812, 930],
  [State.KS]: [316, 620, 785, 913],
  [State.KY]: [270, 364, 502, 606, 859],
  [State.LA]: [225, 318, 337, 504, 985],
  [State.MA]: [339, 351, 413, 508, 617, 774, 781, 857, 978],
  [State.MD]: [240, 301, 410, 443, 667],
  [State.ME]: [207],
  [State.MI]: [231, 248, 269, 313, 517, 586, 616, 734, 810, 906, 947, 989],
  [State.MN]: [218, 320, 507, 612, 651, 763, 952],
  [State.MO]: [314, 417, 573, 636, 660, 816],
  [State.MS]: [228, 601, 662, 769],
  [State.MT]: [406],
  [State.NC]: [252, 336, 704, 743, 828, 910, 919, 980, 984],
  [State.ND]: [701],
  [State.NE]: [308, 402, 531],
  [State.NH]: [603],
  [State.NJ]: [201, 551, 609, 640, 732, 848, 856, 862, 908, 973],
  [State.NM]: [505, 575],
  [State.NV]: [702, 725, 775],
  [State.NY]: [212, 315, 332, 347, 516, 518, 585, 607, 631, 646, 680, 716, 718, 838, 845, 914, 917, 929, 934],
  [State.OH]: [216, 220, 234, 330, 380, 419, 440, 513, 567, 614, 740, 937],
  [State.OK]: [405, 539, 580, 918],
  [State.OR]: [458, 503, 541, 971],
  [State.PA]: [215, 223, 267, 272, 412, 445, 484, 570, 610, 717, 724, 814, 878],
  [State.PR]: [787, 939],
  [State.RI]: [401],
  [State.SC]: [803, 843, 854, 864],
  [State.SD]: [605],
  [State.TN]: [423, 615, 629, 731, 865, 901, 931],
  [State.TX]: [210, 214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512, 682, 713, 726, 737, 806, 817, 830, 832, 903, 915, 936, 940, 956, 972, 979],
  [State.UT]: [385, 435, 801],
  [State.VA]: [276, 434, 540, 571, 703, 757, 804],
  [State.VT]: [802],
  [State.WA]: [206, 253, 360, 425, 509, 564],
  [State.WI]: [262, 414, 534, 608, 715, 920],
  [State.WV]: [304, 681],
  [State.WY]: [307]
}