import { Pipe } from '@angular/core';
import { ColorCode } from "../../../../../common/toolbox/color";

@Pipe({
  name: 'percentColor'
})
export class PercentColorPipe {
  /** Hardcoded list of colors to progress through. */
  private readonly COLORS = [ColorCode.Red, ColorCode.Orange, ColorCode.Yellow, ColorCode.Green];
  
  transform(actual = 0, goal = 100): ColorCode {
    return this.COLORS[Math.max(0, Math.min(Math.floor((actual / goal) * this.COLORS.length), this.COLORS.length - 1))]!;
  }
}
