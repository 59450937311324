import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationSettingsKey } from '../../../../../common/model/setting-group';
import { Table, TablePreview } from "../../../../../common/model/table";
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT, idNull } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { serviceSettingsItem } from '../toolbox/service';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { SettingGroupService } from './setting-group.service';

/** A query to fetch a specific table. */
export class TableQuery {
  constructor(
    /** ID of table. */
    public _id = ID_DEFAULT,
    /** Institution of table. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Caches information about tasks. */
@Injectable({
  providedIn: 'root'
})
export class TableService extends CachePreviewService<Table, TableQuery, TablePreview> {
  readonly route = 'tables/preview';
  readonly Type = Table;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private settings: SettingGroupService
  ) {
    super(TableQuery, log, dialog, http);
  }

  /** Fetch a table from settings. */
  setting(key: ApplicationSettingsKey) {
    return serviceSettingsItem('Table', this, this.auth, this.log, this.settings, key);
  }

  /** Fetch a table from settings, with fallback. */
  async fallback(key: ApplicationSettingsKey, fallback: ApplicationSettingsKey) {
    let table = await this.setting(key);
    return idNull(table._id) ? this.setting(fallback) : table;
  }

  protected override multiple(queries: ArraySome<TableQuery>) {
    return getRequest(this.http, 'tables', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}
