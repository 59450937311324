import { Expression } from "./expression";
import { Statement } from "./statement";

/** Operators that work on values. */
export type Operator<T = any> = OperatorUnary<T> | OperatorBinary<T> | OperatorTernary<T> | OperatorLoop<T>;

/** Operator with single value. */
export type OperatorUnary<T = any> =
  | OperatorNot<T>
  | OperatorLength<T>
  | OperatorDisplay<T>
  | OperatorMin<T> 
  | OperatorMax<T>
  | OperatorSum<T>
  | OperatorGranted<T>;

/** Operator with two values. */
export type OperatorBinary<T = any> =
  | OperatorAdd<T>
  | OperatorSubtract<T>
  | OperatorMultiply<T>
  | OperatorDivide<T>
  | OperatorAccess<T>
  | OperatorIndex<T>
  | OperatorJoin<T>;

/** Operator with an expression and statement. */
export type OperatorLoop<T = any> = 
  | OperatorMap<T>
  | OperatorFilter<T>;

/** Operator with three values. */
export type OperatorTernary<T = any> =
  OperatorPad<T>
  | OperatorSlice<T>;

/** Operators to work on expressions. */
export enum OperatorType {
  Add = '+',
  Access = 'access',
  Display = 'display',
  Filter = 'filter',
  Granted = 'granted',
  Join = 'join',
  Index = 'index',
  Length = 'length',
  Map = 'map',
  Max = 'max',
  Min = 'min',
  Multiply = '*',
  Divide = '/',
  Not = 'not',
  Pad = 'pad',
  Slice = 'slice',
  Subtract = '-',
  Sum = 'sum'
}

/** Basic configuration shared by all expression operators. */
export interface OperatorExpressionTag<C extends OperatorType, T> {
  /** Type of operator. */
  type: C
  /** First value in operator. */
  left: Expression<T>
}

/** Basic configuration shared by all statement operators. */
export interface OperatorStatementTag<C extends OperatorType, T> {
  /** Type of operator. */
  type: C
  /** Expression of operator. */
  expression: Expression<T>
  /** Statements to evaluate. */
  statements: Statement<T>[]
}

/** Negates a value. */
export interface OperatorNot<T = any> extends OperatorExpressionTag<OperatorType.Not, T> { }

/** Get length of a value */
export interface OperatorLength<T = any> extends OperatorExpressionTag<OperatorType.Length, T> { }

/** Get display of a value. */
export interface OperatorDisplay<T = any> extends OperatorExpressionTag<OperatorType.Display, T> { }

/** Sum together a list of values. */
export interface OperatorSum<T = any> extends OperatorExpressionTag<OperatorType.Sum, T> { }

/** Perform a mapping over an array of values. */
export interface OperatorMap<T = any> extends OperatorStatementTag<OperatorType.Map, T> { }

/** Find max value of an array of values. */
export interface OperatorMax<T = any> extends OperatorExpressionTag<OperatorType.Max, T> { }

/** Find min value of an array of values. */
export interface OperatorMin<T = any> extends OperatorExpressionTag<OperatorType.Min, T> { }

/** Perform a filter over an array of values. */
export interface OperatorFilter<T = any> extends OperatorStatementTag<OperatorType.Filter, T> { }

/** Add together two values. */
export interface OperatorAdd<T = any> extends OperatorExpressionTag<OperatorType.Add, T> {
  right: Expression<T>
}

/** Subtract two values. */
export interface OperatorSubtract<T = any> extends OperatorExpressionTag<OperatorType.Subtract, T> {
  right: Expression<T>
}

/** Multiply two values. */
export interface OperatorMultiply<T = any> extends OperatorExpressionTag<OperatorType.Multiply, T> {
  right: Expression<T>
}

/** Divide two values. */
export interface OperatorDivide<T = any> extends OperatorExpressionTag<OperatorType.Divide, T> {
  right: Expression<T>
}

/**
 *  Access a value with dot notation, eg. a.b
 *  Has special case for identifiers, treating them as keys.
 */
export interface OperatorAccess<T = any> extends OperatorExpressionTag<OperatorType.Access, T> {
  right: Expression<T>
}

/**
 *  Index a value with bracket notation, eg. a[b]
 *  Identifiers will be evaluated and result will be used as key.
 */
export interface OperatorIndex<T = any> extends OperatorExpressionTag<OperatorType.Index, T> {
  right: Expression<T>
}

/** Join together a list of values with a delimiter. */
export interface OperatorJoin<T = any> extends OperatorExpressionTag<OperatorType.Join, T> {
  right: Expression<T>
}

/** Pad a value with specified items. */
export interface OperatorPad<T = any> extends OperatorExpressionTag<OperatorType.Pad, T> {
  middle: Expression<T>
  right: Expression<T>
}


/** Slices from middle value number to right value number out of left value. */
export interface OperatorSlice<T = any> extends OperatorExpressionTag<OperatorType.Slice, T> {
  right: Expression<T>
  middle: Expression<T>
}

/**
 *  Index a value with bracket notation, eg. a[b]
 *  Identifiers will be evaluated and result will be used as key.
 */
export interface OperatorIndex<T = any> extends OperatorExpressionTag<OperatorType.Index, T> {
  right: Expression<T>
}

/** Check if current user has given permission. */
export interface OperatorGranted<T = any> extends OperatorExpressionTag<OperatorType.Granted, T> {
  left: Expression<T>
}