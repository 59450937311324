import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT } from "../toolbox/id";
import { UnionValidator } from "../validator/union";
import { FusionCollection } from "./fusion";
import { PROPERTY_UNIONINFO, Property } from "./property";

/** Model configuration merged into a mapped object. */
export type ModelMap<T = any> = { [K in keyof T]?: Property };

/** A database value that contains model data. */
export interface ModelValue {
  /** Unique identifier of resource. */
  _id: string;
  /** Institution of resource. */
  _inst: string;
  /** Model data of resource. */
  value: Object | undefined;
}

/** Model utilized by a form or document template. */
export class Model {
  constructor(
    /** Unique identifier of model. */
    public _id = ID_DEFAULT,
    /** Institution defining this model. */
    public _inst = ID_DEFAULT,
    /** Name of this model. */
    public name = '',
    /** True if this is a system model and cannot be deleted. */
    public system?: boolean,
    /** True if model has been edited. */
    public dirty?: boolean,
    /** List of properties on this model. */
    public properties: Property[] = []
  ) {}

  static typeinfo: TypeInfo<Model> = {
    system: false,
    dirty: false,
    properties: [new UnionValidator(PROPERTY_UNIONINFO)]
  }

  static collectioninfo: CollectionInfo<FusionCollection, Model> = {
    _inst: 'institutions'
  }

  static indexinfo: IndexInfo<Model> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** Preview for a model. */
export class ModelPreview {
  constructor(
    /** Unique identifier of model. */
    public _id = ID_DEFAULT,
    /** Name of this model. */
    public name = '',
    /** True if this is a system model and cannot be deleted. */
    public system?: boolean,
    /** True if model and has been edited. */
    public dirty?: boolean,
  ) { }

  static typeinfo: TypeInfo<ModelPreview> = {
    system: false,
    dirty: false
  }
}