import { Pipe } from '@angular/core';
import { ATTACHMENT_TYPE_NAME } from '../../../../../common/code/standard/common';
import { CLAIM_STATUS_NAME, DISPUTE_STATUS_NAME } from '../../../../../common/code/standard/disputes';
import { WorkCondition, WorkConditionClass, WorkConditionMode, WorkConditionType } from '../../../../../common/model/work/condition';
import { FormulaService } from '../service/formula.service';

@Pipe({
  name: 'workCondition'
})
export class WorkConditionPipe {

  /** Mapping of work condition types to description callbacks. */
  private readonly CALLBACK: { [C in WorkConditionType]: ((condition: WorkConditionClass[C]) => string) } = {
    [WorkConditionType.Attachment]: condition => {
      let name = ATTACHMENT_TYPE_NAME[condition.attachmentType];
      switch (condition.mode) {
        case WorkConditionMode.DisputesAll:
          return `Attach document to all disputes: ${name}`;
        case WorkConditionMode.DisputesAny:
          return `Attach document to at least one dispute: ${name}`;
        case undefined:
          return `Attach document: ${name}`;
      }
    },
    [WorkConditionType.ClaimStatus]: condition => {
      let name = CLAIM_STATUS_NAME[condition.status];
      return `Claim must be at stage: ${name}`;
    },
    [WorkConditionType.DisputeStatus]: condition => {
      let name = DISPUTE_STATUS_NAME[condition.status];
      switch (condition.mode) {
        case WorkConditionMode.DisputesAll:
          return `All disputes must be at stage: ${name}`;
        case WorkConditionMode.DisputesAny:
          return `At least one dispute must be at stage: ${name}`
        case undefined:
          return `Dispute must be at stage: ${name}`;
      }
    },
    [WorkConditionType.Formula]: condition => {
      let name = this.formulas.nameSync(condition._formula);
      switch (condition.mode) {
        case WorkConditionMode.DisputesAll:
          return `Fulfill formula for all disputes: ${name}`;
        case WorkConditionMode.DisputesAny:
          return `Fulfill formula for at least one dispute: ${name}`;
        case undefined:
          return `Fulfill formula: ${name}`;
      }
    }
  };

  constructor(
    private formulas: FormulaService
  ) { }

  transform(condition: WorkCondition): string {
    let callback = condition.description || this.CALLBACK[condition.type];
    return typeof callback === 'string' ? callback : callback(condition as any);
  }
}
