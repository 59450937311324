import { Pipe } from '@angular/core';

@Pipe({ name: 'promisify' })
export class PromisifyPipe {

  /** Format a code type by ID. */
  transform<T>(value: T | Promise<T>): Promise<T> {
    return value instanceof Promise ? value : Promise.resolve(value);
  }
}
