import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ColumnSize } from "../../../../../../common/model/table";
import { arrayCoerce } from "../../../../../../common/toolbox/array";
import { DiffResult } from "../../../../../../common/toolbox/diff";
import { errorPartition, errorResponse } from "../../../../../../common/toolbox/message";
import { DIALOG_DATA } from "../../../common/component/dialog/dialog.model";
import { LogService } from "../../../common/service/log.service";
import { deleteRequest, postRequest } from "../../../common/toolbox/request";
import { ClientSource } from "../../../common/toolbox/source/client";
import { SetupCompareDialogData } from "./setup-compare.model";
import { AuthService } from "../../../common/service/auth.service";

@Component({
  selector: 'app-setup-compare',
  templateUrl: './setup-compare.component.html',
  styleUrls: ['./setup-compare.component.scss'],
})
export class SetupCompareComponent {

  /** Paginated source for table. */
  source = new ClientSource<DiffResult>();
  /** Columns to display in grid. */
  columns = ['upload', 'delete', 'uploaded', 'current'];
  /** Width of each column. */
  sizes: ColumnSize[] = ['2rem', '2rem', '1fr', '1fr'];

  /** Number of items to be uploaded. */
  uploadable = 0;
  /** Number of items to be deleted. */
  deleteable = 0;
  /** True to perform document template override. */
  override = false;
  /** Current diff being viewed. */
  result = new DiffResult(undefined, undefined, false, undefined);

  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  static title() {
    return 'Setup Compare';
  }

  constructor(
    @Inject(DIALOG_DATA)
    public data: SetupCompareDialogData,
    public log: LogService,
    private auth: AuthService,
    private http: HttpClient
  ) {
    this.source.items = data.diffs;
    this.source.available = data.diffs.length;
    this.source.selection.pipe(takeUntil(this.destroy)).subscribe(() => this.reselected());
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Upload one or more diffs. */
  async onUpload(diffs: Set<DiffResult> | DiffResult[]) {
    diffs = arrayCoerce(diffs);
    diffs = diffs.filter(d => d.dirty);

    // Convert list of uploaded clipboard items to uploadable items.
    let imported = await Promise.all(diffs.map(diff => this.data.collections.import(diff.uploaded)));
    let [error, items] = errorPartition('There was an error importing items.', imported);
    if (error) {
      this.log.show(error);
      return;
    }

    // Upload list of items.
    let result = await postRequest(this.http, this.data.route, {
      items: items as any,
      override: this.data.route === 'document-templates' ? this.override : undefined
    });
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }

    this.log.show(`Successfully updated ${diffs.length} item(s).`);
    for (let diff of diffs) diff.dirty = false;
    this.reselected();
  }

  /** Deletes one or more diffs. */
  async onDelete(diffs: Set<DiffResult> | DiffResult[]) {
    diffs = arrayCoerce(diffs);
    let _ids: string[] = diffs.filter(diff=>diff.current!==undefined).map(diff => diff.current._id);
    let result = await deleteRequest(this.http, this.data.route, { _ids, _inst: this.auth._inst });
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }
    this.log.show(`Successfully deleted ${diffs.length} item(s).`);
    for (let diff of diffs) {
      diff.current = undefined;
    }
    this.reselected();
  }

  /** Store copy of selection size to prevent excess change detection. */
  private reselected() {
    //remove deleted items from list
    this.source.items = this.source.items.filter(diff => !(diff.current === undefined && diff.uploaded === undefined));
    this.uploadable = [...this.source.selection.value].filter(diff => diff.dirty && diff.uploaded !== undefined).length;
    this.deleteable = [...this.source.selection.value].filter(diff => diff.current !== undefined).length;
  }
}