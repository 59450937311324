import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { COLUMN_UNITS, ColumnSize, ColumnUnit, Table } from "../../../../../../common/model/table";
import { fieldControlProviders } from '../../toolbox/angular';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-column-size',
  templateUrl: './column-size.component.html',
  styleUrls: ['./column-size.component.scss'],
  providers: fieldControlProviders(ColumnSizeComponent)
})
export class ColumnSizeComponent extends FieldControl implements ControlValueAccessor {
  /** Allowed units. */
  readonly COLUMN_UNITS = COLUMN_UNITS;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  
  /** Placeholder for input field. */
  @Input() placeholder: string | null = '';
  /** True to make a value required. */
  @Input() required = true;
  /** Maximum length for string. */
  @Input() length = Number.MAX_SAFE_INTEGER;

  /** Current amount value. */
  amount: number | undefined = 0;
  /** Current unit. */
  unit: ColumnUnit = 'fr';

  writeValue(value: ColumnSize | undefined) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    let [amount, unit] = Table.split(value, this.required);
    this.amount = amount;
    this.unit = unit ?? 'fr';
  }

  /** Callback when typing into field. */
  onAmount(amount?: number) {
    this.changed(amount ? `${amount}${this.unit}` : undefined);
    this.amount = amount;
  }

  /** Callback when selecting a new unit. */
  onUnit() {
    let unit: ColumnUnit = this.unit === 'fr' ? 'rem' : 'fr';
    let size: ColumnSize | undefined = this.amount ? `${this.amount}${unit}` : undefined;
    this.changed(size);
    this.unit = unit;
  }
}