import { Component, Input } from '@angular/core';
import { arraySome } from '../../../../../../common/toolbox/array';
import { AuthService } from '../../../common/service/auth.service';
import { LedgerConfigService } from '../../../common/service/ledger-config.service';
import { LedgerListState, LedgerWithPostInfo } from './ledger-list.model';

@Component({
  selector: 'app-ledger-list',
  templateUrl: './ledger-list.component.html',
  styleUrls: ['./ledger-list.component.scss'],
  host: { class: 'column' }
})
export class LedgerListComponent {

  /** Current context being edited. */
  @Input() set state(state: LedgerListState) {
    this.list = state;
    this.restate(state);
  }

  /** Last bound state. */
  protected list?: LedgerListState;
  /** Default ledger item to create. */
  protected default?: LedgerWithPostInfo;

  constructor(
    private auth: AuthService,
    private ledgerConfigs: LedgerConfigService,
  ) {}

  /** Update after binding a new config. */
  private async restate(config: LedgerListState) {
    // Get ID of first selectable ledger config.
    let filter = new Set(config._filter ?? []);
    let configs = await this.ledgerConfigs.previews(this.auth._inst);
    let allowed = filter.size ? configs.filter(c => filter.has(c._id)) : configs;
    if (!arraySome(allowed)) return;

    // Set new default item to create.
    this.default = { ledger: (await this.ledgerConfigs.create(allowed[0]._id, config.partial))[0], shouldPost: false };

  }
}