import { CdkHeaderRow } from "@angular/cdk/table";
import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from "@angular/core";
import { GridBase } from "../grid.base";

@Component({
  selector: 'grid-header-row',
  styleUrls: ['./grid-header-row.component.scss'],
  templateUrl: './grid-header-row.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'gridHeaderRow',
  providers: [ { provide: CdkHeaderRow, useExisting: GridHeaderRow } ]
})
// @ts-ignore Supress TS2417
export class GridHeaderRow extends GridBase(CdkHeaderRow) {
  /** Calculated style for grid items. */
  @HostBinding('style.grid-template-columns') style = '';
  
  /** True if at least one item must be selected. */
  required = false;
  /** True if all items are selected. */
  all = false;
}