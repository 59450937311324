import { Pipe } from '@angular/core';
import { dateFormat, DateRange, dateNull } from "../../../../../common/toolbox/time";
import { ConfigService } from '../service/config.service';

@Pipe({
  name: 'dateIso'
})
export class DateIsoPipe {

  constructor(
    private config: ConfigService
  ) {}

  transform(value: unknown, format?: string): string {
    switch (format) {
    case 'date':
    case undefined:
      format = this.config.dateFormat;
      break;
    case 'datetime':
      format = this.config.dateTimeFormat;
      break;
    }

    let date = new Date(0);
    switch (typeof value) {
    case 'number':
    case 'string': 
      date = new Date(value);
      break;
    case 'object':
      if (value instanceof Date) {
        date = value;
      } else if (DateRange.valid(value)) {
        return `${this.transform(value.start)} - ${this.transform(value.end)}`
      }
    }

    // Return empty string if this is an invalid date or epoch.
    return dateNull(date) ? '' : dateFormat(date, format) ?? '';
  }
}
