import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { WorkQueuePostResponse } from '../../../../../../../common/message/work/queue';
import { DISPLAY_TYPE_NAME, DisplayType } from "../../../../../../../common/model/display";
import { WorkQueue } from "../../../../../../../common/model/work/queue";
import { arrayDefined } from '../../../../../../../common/toolbox/array';
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { AuthService } from '../../../../common/service/auth.service';
import { DevService } from '../../../../common/service/dev.service';
import { FilterRuleService } from '../../../../common/service/filter-rule.service';
import { LogService } from '../../../../common/service/log.service';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';

@Component({
  selector: 'app-setup-work-queue-edit',
  templateUrl: './setup-work-queue-edit.component.html',
  styleUrls: ['./setup-work-queue-edit.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupWorkQueueEditComponent extends SetupEditComponent<WorkQueue, WorkQueuePostResponse> {
  readonly DISPLAY_TYPE_NAME = DISPLAY_TYPE_NAME;

  /** Current work queue being edited. */
  value = idMaybe(new WorkQueue());
  /** List of allowed filter rules to select. */
  filter: string[] = [];
  /** Valid types for work queues. */
  types: DisplayType[] = [DisplayType.Account, DisplayType.Claim];

  constructor(
    elementRef: ElementRef,
    log: LogService,
    public dev: DevService,
    private auth: AuthService,
    private http: HttpClient,
    private ruleService: FilterRuleService
  ) {
    super(elementRef, log);
  }

  async ngOnInit() {
    this.refilter();
  }

  /** Callback when changing type. */
  async onType(type: DisplayType) {
    if (this.value.type === type) return;

    // Set current displayed model.
    this.value.type = type;
    this.refilter();
  }

  /** Submit current changes to work queue. */
  push() {
    return postRequest(this.http, 'work-queues', { items: [this.value] });
  }

  /** Reset current form with new work queue. */
  async reset(value: MaybeId<WorkQueue>) {
    this.value = value;
    this.refilter();
  }

  /** Update list of available rules. */
  private async refilter() {
    let rules = await this.ruleService.previews(this.auth._inst);
    this.filter = arrayDefined(rules.map(c => c.type === this.value.type ? c._id : undefined));
  }
}
