import { Pipe } from '@angular/core';
import { DOCUMENTATION, DocumentationKey } from '../../../../../common/toolbox/docapi';

/** Get name of a documentation key. */
@Pipe({
  name: 'docName'
})
export class DocNamePipe {
  transform(key: string): string {
    return DOCUMENTATION[key as DocumentationKey] ?? 'Unknown';
  }
}
