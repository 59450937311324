<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row align-end">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Size</app-label>
          <app-size [ngModel]="value.size" (ngModelChange)="onResize($event)"[min]="min" [max]="Form.SIZE_MAX"></app-size>
        </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>Model</app-label>
          <app-resource-select collection="models" [required]="false" [(ngModel)]="value._model" (selected)="onModel($event)"></app-resource-select>
        </app-field>
        <app-field class="fill">
          <app-label>Editable</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Which values in this form will be editable."></app-hover-help>
          </app-sublabel>
          <app-select [(ngModel)]="value.editable" [multiple]="true" [required]="false">
            <app-option *ngFor="let type of DISPLAY_TYPES" [pair]="type"></app-option>
          </app-select>
        </app-field>
      </div>
    </app-setup-base-header>
    <app-setup-base-body>
      <div class="container">
        <div class="list preview" [ngStyle]="style">
          <div *ngFor="let a of area" (click)="onNew(a)" tooltip="New field"></div>
        </div>
        <div class="list" [box-grid]="value.fields" [ngStyle]="style" (moved)="onFieldMove()">
          <app-drag-chip cdkDrag cdkDragBoundary=".list" *ngFor="let f of value.fields; let i = index" [attr.data-index]="i" [ngStyle]="f.box | boxArea">
            <div class="key">
              <app-select [(ngModel)]="f.key" tooltip="Key">
                <app-option *ngFor="let p of pairs" [pair]="p"></app-option>
              </app-select>
            </div>
            <app-string class="value" [(ngModel)]="f.name" [placeholder]="f.key | display:undefined:map[f.key] | async" tooltip="Name"></app-string>
            <button icon-button [menu-trigger]="fieldMenu" (click)="onFieldClick(f)" tooltip="Options">settings</button>
            <button icon-button [resize-trigger]="size" [resize-max]="max" (click)="onFieldClick(f)" (sizeChange)="onFieldResize($event)" tooltip="Resize">grid_view</button>
            <button icon-button cdkDragHandle>drag_indicator</button>
          </app-drag-chip>
        </div>
      </div>
      <div class="fill"></div>
      <app-labels>
        <app-label>Formulas</app-label>
        <app-sublabel>
          <app-hover-help tooltip="Executed when the form is displayed."></app-hover-help>
        </app-sublabel>
      </app-labels>
      <app-resource-list collection="formulas" [required]="false" [(ngModel)]="value._formulas"></app-resource-list>
    </app-setup-base-body>
    <app-setup-base-footer>
      <div class="size row">
        <label>Min size:</label>
        <div>{{min.x}}</div>
        <label>x</label>
        <div>{{min.y}}</div>
      </div>
      <button icon-button *ngIf="dev.on" tooltip="Copy code" (click)="onCode()">code</button>
      <button (click)="onSubmit()">Save</button>
      <button (click)="onPreview()">Preview</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>
<app-menu #fieldMenu="appMenu">
  <app-menu-item (click)="onConfigure()">Configure</app-menu-item>
  <app-menu-item (click)="onDelete()">Delete</app-menu-item>
</app-menu>