import { Component, Inject } from '@angular/core';
import { GRID_ROW } from '../../model/grid/model-grid.component';
import { DisplayIcon } from './grid-icon.model';

@Component({
  selector: 'app-grid-icon',
  templateUrl: './grid-icon.component.html',
  styleUrls: ['./grid-icon.component.scss']
})
export class GridIconComponent {
  constructor(
    @Inject(GRID_ROW) protected row: DisplayIcon
  ) {}
}
