import { Component } from '@angular/core';
import { TerminalString } from "../../../../../../../../common/model/formula/terminal";
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-string',
  templateUrl: './setup-formula-string.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-string.component.scss']
})
export class SetupFormulaStringComponent extends BlockComponent<TerminalString> {}
