import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountGetRequest } from "../../../../../common/message/account";
import { PaginateResponse } from "../../../../../common/message/paginate";
import { AccountJoin, AccountJoinLoan } from "../../../../../common/model/account/account";
import { WorkFilter } from "../../../../../common/model/work/filters";
import { WorkItem } from "../../../../../common/model/work/item";
import { WorkQueue } from "../../../../../common/model/work/queue";
import { accountName } from "../../../../../common/toolbox/account";
import { ArraySome, arraySingle } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { AccountComponent } from "../../module/account/account.component";
import { AccountData } from "../../module/account/account.model";
import { AccountResultsComponent } from "../../module/account/results/account-results.component";
import { AccountResultsData } from "../../module/account/results/account-results.model";
import { AccountWorkComponent } from "../../module/account/work/account-work.component";
import { AccountWorkData } from "../../module/account/work/account-work.model";
import { DialogService } from "../component/dialog/dialog.service";
import { CacheService } from "../toolbox/cache-service";
import { getOneRequest, getRequest, patchRequest } from "../toolbox/request";
import { LogService } from "./log.service";
import { TabService } from "./tab.service";
import { WorkQueueService } from "./work-queue.service";

/** A joined account with an attached name. */
export type AccountName = AccountJoin & { name: string }

/** A query to fetch a specific account. */
export class AccountQuery {
  constructor(
    /** ID of account. */
    public _id = ID_DEFAULT,
    /** Institution ofaccount. */
    public _inst = ID_DEFAULT
  ) {}
}

/** An account with a pre-calculated name. */
export class AccountJoinLoanName extends AccountJoinLoan {
  constructor(
    /** Pre-calculated display name of account. */
    public name = ''
  ) {
    super();
  }

  /** Attach name to a setting group. */
  static from(account: AccountJoin) {
    let out = account as AccountName;
    out.name = accountName(account);
    return out;
  }
};

@Injectable({
  providedIn: 'root'
})
export class AccountService extends CacheService<AccountName, AccountQuery> {
  readonly route = 'accounts';
  readonly Type = AccountJoinLoanName;

  constructor (
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private workQueues: WorkQueueService,
    private tabs: TabService
  ) {
    super(AccountQuery, log, dialog, http);
  }

  /**
   *  Query for specified accounts and work or display results.
   *  @param request Request re-issued when paginating through table.
   *  @param accounts Initial list of accounts to view instead of querying.
   */
  async open(request: AccountGetRequest, accounts?: AccountJoin[]) {
    let response = accounts ? new PaginateResponse(accounts) : await getRequest(this.http, 'accounts', request);
    if (errorResponse(response)) return this.log.show(response);
    
    if (arraySingle(response.items)) return this.work({ account: response.items[0] });
    this.tabs.open(AccountResultsComponent, new AccountResultsData(request, response));
  }

  /** Open account via it's work queue. */
  async queue(queue: WorkQueue, item?: WorkItem) {
    // Get next work item.
    item = await this.workQueues.initialize(queue, item);
    if (!item) return;

    // Get account.
    let account = await getOneRequest(this.http, 'accounts', ({ _insts: [queue._inst], _ids: [item._item] }));
    if (errorResponse(account)) {
      this.log.show(account);
      return;
    }

    // Open work queue.
    let data: AccountWorkData = { account, queue, cursor: item };
    this.tabs.open(AccountWorkComponent, data);
  }

  /** Open work item for specified account, falling back on account itself. */
  async work(data: AccountData) {
    // Find work item for this account.
    let next = await patchRequest(this.http, 'work-items/next', {
      _inst: data.account._inst,
      filter: WorkFilter.All,
      direction: 1,
      cursor: WorkItem.cursor(data.queue?._id ?? ID_DEFAULT, data.account._id)
    });
    if (errorResponse(next)) {
      this.log.show(next);
      return;
    }

    if (next.cursor) {
      // Open work queue.
      let work: AccountWorkData = { ...data, queue: next.queue, cursor: next.cursor };
      this.tabs.open(AccountWorkComponent, work);
      return;
    }

    this.tabs.open(AccountComponent, data);
  }

  protected override async multiple(queries: ArraySome<AccountQuery>) {
    let accounts = await getRequest(this.http, 'accounts', { _insts: queries.map(q => q._inst), _ids: queries.map(q => q._id) });
    return errorResponse(accounts) ? accounts : accounts.items.map(AccountJoinLoanName.from);
  }
}