import { CollectionExporter, CollectionInfo, collectioninfoIds, collectioninfoWalk } from "../info/collection";
import { TypeInfo } from "../info/type";
import { UnionInfo } from "../info/union";
import { BuiltinEnumeration, BuiltinKey } from "../toolbox/builtinmap";
import { EnumMap } from "../toolbox/enum";
import { ID_DEFAULT } from "../toolbox/id";
import { FusionCollection, FusionCollectionName } from "./fusion";

export enum TriggerType {
  ClaimSave = 'claimSave',
  DisputeSave = 'disputeSave',
  FormulaRun = 'formulaRun',
  LedgerAdd = 'ledgerAdd',
  TransactionPost = 'transactionPost',
}

/** Configuration for a specific trigger type. */
export type TriggerConfig =
  | ClaimSaveTriggerConfig
  | DisputeSaveTriggerConfig
  | FormulaRunTriggerConfig
  | LedgerAddTriggerConfig
  | TransactionPostTriggerConfig;

/** Tagged type of all trigger types. */
export interface TriggerConfigTag<T extends TriggerType = TriggerType> {
  /** Type of trigger. */
  type: T
}

/** Trigger to run a formula in current context. */
export class FormulaRunTriggerConfig implements TriggerConfigTag<TriggerType.FormulaRun> {
  readonly type = TriggerType.FormulaRun;

  constructor(
    /** The formula to run. */
    public _formula = ID_DEFAULT,
    /** Filters to run over disputes before executing. */
    public _filters?: string[]
  ) { }

  static typeinfo: TypeInfo<FormulaRunTriggerConfig> = {
    _filters: [ID_DEFAULT]
  }

  static collectioninfo: CollectionInfo<FusionCollection, FormulaRunTriggerConfig> = {
    _formula: 'formulas',
    _filters: 'formulas'
  }
}

/** Trigger to save changes to current disputes. */
export class DisputeSaveTriggerConfig implements TriggerConfigTag<TriggerType.DisputeSave>  {
  readonly type = TriggerType.DisputeSave;
}

/** Trigger to save changes to current claim. */
export class ClaimSaveTriggerConfig implements TriggerConfigTag<TriggerType.ClaimSave> {
  readonly type = TriggerType.ClaimSave;
}

/** A ledger config that is only available if a certain condition is met. */
export class LedgerConditionalConfig {
  constructor(
    /** The ledger config to make available */
    public _config = ID_DEFAULT,
    /** The condition to make it available (undefined = always available) */
    public _condition?: string,
  ) {
  }

  static typeinfo: TypeInfo<LedgerConditionalConfig> = {
    _condition: ID_DEFAULT
  }

  static collectioninfo: CollectionInfo<FusionCollection, LedgerConditionalConfig> = {
    _config: 'ledgerConfigs',
    _condition: 'formulas'
  }
}

/** Trigger to add an item to ledger. */
export class LedgerAddTriggerConfig implements TriggerConfigTag<TriggerType.LedgerAdd> {
  readonly type = TriggerType.LedgerAdd;

  constructor(
    /** Form to display along top bar. */
    public _form?: string,
    /** List of configs to allow user to select. */
    public configs?: LedgerConditionalConfig[],
    /** Filter for list of selectable configs. */
    public _filter?: string[]
  ) { }

  static typeinfo: TypeInfo<LedgerAddTriggerConfig> = {
    configs: [new LedgerConditionalConfig()],
    _form: ID_DEFAULT,
    _filter: [ID_DEFAULT]
  }

  static collectioninfo: CollectionInfo<FusionCollection, LedgerAddTriggerConfig> = {
    _form: 'forms',
    _filter: 'ledgerConfigs'
  }
}

/** Trigger to post a transaction. */
export class TransactionPostTriggerConfig {
  readonly type = TriggerType.TransactionPost;
}

/** Mapping of trigger types to configuration objects. */
export class TriggerConfigClass {
  [TriggerType.ClaimSave] = new ClaimSaveTriggerConfig();
  [TriggerType.DisputeSave] = new DisputeSaveTriggerConfig();
  [TriggerType.FormulaRun] = new FormulaRunTriggerConfig();
  [TriggerType.LedgerAdd] = new LedgerAddTriggerConfig();
  [TriggerType.TransactionPost] = new TransactionPostTriggerConfig();
}

/** Union type information for features. */
export const TRIGGER_CONFIG_UNIONINFO: UnionInfo<TriggerConfig, TriggerType> = {
  tag: 'type',
  classes: new TriggerConfigClass()
}

/** Walk collection info of a trigger config */
export function triggerConfigWalk(config: TriggerConfig, callback: (parent: any, key: keyof any, collection: FusionCollectionName | CollectionExporter<FusionCollection>) => any) {
  let typed = TRIGGER_CONFIG_UNIONINFO.classes[config.type];
  return collectioninfoWalk<FusionCollection, TriggerConfig>(config, typed, callback);
}

/** Get list of system values in a trigger config. */
export function triggerConfigValues<C extends BuiltinKey>(config: TriggerConfig, collection: C): BuiltinEnumeration[C][] {
  return collectioninfoIds<FusionCollection, TriggerConfig>(config, TRIGGER_CONFIG_UNIONINFO.classes[config.type]).filter(id => id[0] === collection).map(id => id[1] as BuiltinEnumeration[C]);
}

/** Name of each trigger type. */
export const TRIGGER_TYPE_NAME: EnumMap<TriggerType> = {
  [TriggerType.ClaimSave]: 'Save Claim',
  [TriggerType.DisputeSave]: 'Save Dispute',
  [TriggerType.FormulaRun]: 'Run Formula',
  [TriggerType.LedgerAdd]: 'Add Ledger',
  [TriggerType.TransactionPost]: 'Post Transaction',
};
