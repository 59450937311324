import { JobType } from "../../../../../../common/model/job/payload";
import { SetupJobPayloadDebugEchoComponent } from "./payload/debug-echo/setup-job-payload-debug-echo.component";
import { SetupJobPayloadImportComponent } from "./payload/import/setup-job-payload-import.component";
import { SetupJobPayloadNotificationClaimDateComponent } from "./payload/notification/claim-date/setup-job-payload-notification-claim-date.component";
import { SetupJobPayloadNotificationEventComponent } from "./payload/notification/event/setup-job-payload-notification-event.component";
import { SetupJobPayloadWorkQueueBuildComponent } from "./payload/work-queue-build/setup-job-payload-work-queue-build.component";

/** Component for configuring each job type. */
export const JOB_SETUP_COMPONENT = {
  [JobType.DebugEcho]: SetupJobPayloadDebugEchoComponent,
  [JobType.Import]: SetupJobPayloadImportComponent,
  [JobType.NotificationClaimDate]: SetupJobPayloadNotificationClaimDateComponent,
  [JobType.NotificationEvent]: SetupJobPayloadNotificationEventComponent,
  [JobType.WorkQueueBuild]: SetupJobPayloadWorkQueueBuildComponent
};