import { Component, Inject, ViewChild } from '@angular/core';
import { AttachmentUpload } from "../../../../../../common/model/attachment";
import { Event } from "../../../../../../common/model/event";
import { EventCategory } from "../../../../../../common/model/event/category";
import { AccountFormResult, AccountResultType } from "../../../../../../common/model/event/result/account";
import { ClaimFormResult, ClaimResultType } from "../../../../../../common/model/event/result/claim";
import { FormList } from '../../../../../../common/model/form/list';
import { Permission } from '../../../../../../common/model/permission';
import { TaskType } from '../../../../../../common/model/task-config';
import { errorResponse } from "../../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { AttachmentListComponent } from '../../../common/component/attachment/list/attachment-list.component';
import { DIALOG_DATA, DialogRef } from '../../../common/component/dialog/dialog.model';
import { FormListConfig } from '../../../common/component/form/form.model';
import { AuthService } from '../../../common/service/auth.service';
import { EventService } from '../../../common/service/event.service';
import { FormListService } from '../../../common/service/form-list.service';
import { LogService } from '../../../common/service/log.service';
import { TaskFormListData, TaskFormListReturn } from './task-form-list.model';

// TODO lots of switches in this component between account and claim-specific logic, find better method later.
@Component({
  selector: 'app-task-form-list',
  templateUrl: './task-form-list.component.html',
  styleUrls: ['./task-form-list.component.scss'],
  host: {
    class: 'column'
  }
})
export class TaskFormListComponent {
  readonly Permission = Permission;
  /** Context to load into form. */
  config?: FormListConfig;
  /** List of attachments pending upload. */
  uploads: AttachmentUpload[] = [];
  /** Reference to attachment list within template. */
  @ViewChild(AttachmentListComponent) list?: AttachmentListComponent;

  /** Get existing event, if applicable. */
  event?: Event;

  constructor(
    @Inject(DIALOG_DATA)
    public data: TaskFormListData,
    public dialogRef: DialogRef<TaskFormListReturn>,
    private log: LogService,
    private events: EventService,
    private formLists: FormListService,
    private auth: AuthService
  ) {
    this.event = data.open?.event;
  }

  async ngOnInit() {
    // Pull down form list or build one from provided form.
    const task = this.data.task;
    const list = task.config.type === TaskType.FormList
      ? await this.formLists.item({ _id: task.config._formList, _inst: task._inst })
      : FormList.single(this.auth._inst, task.config._form);

    // Pass in previous form result to form.
    this.config = {
      list,
      value: this.data.partial,
      mutate: true
    };
  }

  /** Callback when submitting task. */
  async onSubmit(): Promise<void> {
    const value = this.config?.value;
    if (!value) return;

    if (this.list) {
      const result = await this.list.submit();
      if (errorResponse(result)) return this.log.show(result);
    }

    if (value.claim && 'claim' in this.data) {
      const result: ClaimFormResult = {
        category: EventCategory.Claim,
        type: ClaimResultType.ClaimForm,
        _claim: value.claim._id,
        _task: this.data.task._id,
        value: value.model
      };

      // Update existing event if applicable.
      const response = await this.events.add({
        event: {
          ...Event.fallback(this.data.claim._inst, this.data.open?.event),
          result: { ...value.event?.result, ...result }
        },
        uploads: this.uploads
      });
      if (errorResponse(response)) return this.log.show(response);
      this.dialogRef.close(true);
    } else if ('account' in this.data) {
      const result: AccountFormResult = {
        category: EventCategory.Account,
        type: AccountResultType.Form,
        _account: this.data.account._id,
        _task: this.data.task._id,
        value: value
      };

      // Update existing event if applicable.
      const response = await this.events.add({
        event: {
          ...Event.fallback(this.data.account._inst, this.data.open?.event),
          result: { ...value.event?.result, ...result }
        },
        uploads: this.uploads
      });
      if (errorResponse(response)) return this.log.show(response);

      this.dialogRef.close(true);

    } else {
      this.log.show('Could not find claim or account to attach result.', StatusLevel.Alert);
      this.dialogRef.cancel();
    }
  }
}
