import { Phone } from "../model/phone";
import { NATURAL_REGEX } from "./number";

/** Default value for phone numbers. */
export const PHONE_DEFAULT = '0000000';
/** Regex for validating phone numbers. */
export const PHONE_REGEX = NATURAL_REGEX;

/** True if given phone is null. */
export function phoneNull(phone: string | undefined) {
  return !phone || phone === PHONE_DEFAULT;
}

/** Format out a phone number. */
export function phoneFormat(phone: string | Phone): string {
  let number = `${typeof phone === 'string' ? phone : phone.number}`.replace(/\D/g, '');
  switch (number.length) {
    case 7: return `${number.slice(0, 3)}-${number.slice(3, 7)}`;
    case 10: return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
    case 11: return `${number[0]} (${number.slice(1, 4)}) ${number.slice(4, 7)}-${number.slice(7, 11)}`;
    default: return number;
  }
}