import { Component, Inject } from '@angular/core';
import { ErrorResponse } from '../../../../../../../common/message/error';
import { Event } from '../../../../../../../common/model/event';
import { EventCategory } from '../../../../../../../common/model/event/category';
import { ClaimResultType } from '../../../../../../../common/model/event/result/claim';
import { Formula } from '../../../../../../../common/model/formula/formula';
import { ID_DEFAULT } from '../../../../../../../common/toolbox/id';
import { errorResponse } from '../../../../../../../common/toolbox/message';
import { DIALOG_DATA, DialogRef } from '../../../../common/component/dialog/dialog.model';
import { FormListConfig } from '../../../../common/component/form/form.model';
import { AuthService } from '../../../../common/service/auth.service';
import { EventService } from '../../../../common/service/event.service';
import { FormListService } from '../../../../common/service/form-list.service';
import { FormulaService } from '../../../../common/service/formula.service';
import { LogService } from '../../../../common/service/log.service';
import { TriggerService } from '../../../../common/service/trigger.service';
import { TaskClaimReviewData, TaskClaimReviewReturn } from './task-claim-review.model';

@Component({
  selector: 'app-task-claim-review',
  templateUrl: './task-claim-review.component.html',
  styleUrls: ['./task-claim-review.component.scss'],
  host: {
    class: 'column'
  }
})
export class TaskClaimReviewComponent {
  readonly ID_DEFAULT = ID_DEFAULT;

  /** Form to edit ledgerItem. */
  public config?: FormListConfig;

  /** Formula to allow approval. */
  approveFormula?: Formula;
  /** Formula to allow denial. */
  denyFormula?: Formula;

  constructor(
    @Inject(DIALOG_DATA)
    public data: TaskClaimReviewData,
    public dialogRef: DialogRef<TaskClaimReviewReturn>,
    protected auth: AuthService,
    private log: LogService,
    private events: EventService,
    private formulas: FormulaService,
    private formLists: FormListService,
    private triggers: TriggerService,
  ) { }

  async ngOnInit() {
    // Load button formulas, form list in parallel.

    if (this.data.task.config.approve._enable) {
      this.formulas.item({
        _inst: this.auth._inst,
        _id: this.data.task.config.approve._enable
      }).then(formula => this.approveFormula = formula);
    }

    if (this.data.task.config.deny._enable) {
      this.formulas.item({
        _inst: this.auth._inst,
        _id: this.data.task.config.deny._enable
      }).then(formula => this.denyFormula = formula);
    }

    this.config = {
      list: await this.formLists.item({ _id: this.data.task.config._formList, _inst: this.auth._inst }),
      mutate: true,
      value: this.data.partial
    };
  }

  /** Claim is approved to continue - update status and assign to current user */
  async onApprove() {
    await this.runTriggers(this.data.task.config.approve._triggers);
    this.saveAndExit(true);
  }

  /** Negative response. */
  async onDeny() {
    await this.runTriggers(this.data.task.config.deny._triggers);
    this.saveAndExit(false);
  }

  /** Submit result and exit dialog. */
  async saveAndExit(approved: boolean) {
    // Update existing event if applicable.
    if ('claim' in this.data) {
      let response = await this.events.add({
        event: {
          ...Event.fallback(this.data.claim._inst, this.data.open?.event),
          result: {
            category: EventCategory.Claim,
            type: ClaimResultType.ClaimReview,
            _claim: this.data.claim._id,
            _dispute: 'dispute' in this.data ? this.data.dispute._id : undefined,
            _task: this.data.task._id,
            approved
          }
        },
      });
      if (errorResponse(response)) return this.log.show(response);
    }
    
    this.dialogRef.close(true);
  }

  /** Run a list of triggers. */
  private runTriggers(triggers?: string[]) {
    if (!this.config?.value) return this.log.show(new ErrorResponse('Failed to run triggers. Config lacks value.', undefined, this.config));
    return this.triggers.runAll(triggers, this.data);
  }
}
