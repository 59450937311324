import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { WorkModule } from '../../work/work.module';
import { CollectionsAgentComponent } from './collections-agent.component';

@NgModule({
  declarations: [
    CollectionsAgentComponent
  ],
  imports: [
    SharedModule,
    WorkModule
  ]
})
export class CollectionsAgentModule { }
