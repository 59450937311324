import { SchemaProperty } from "../model/schema";
import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "../validator/base";

/**
 *  An opaque value representing binary data.
 *  Can be {string | ArrayBuffer | Buffer} in NodeJS.
 *  Can be {string | ArrayBuffer | Blob} in a browser.
 *  Always converted to a base64 value when sent over network.
 */
export type BufferLike = string | Blob | ArrayBuffer | Buffer;

/** A validator for buffer-like data. */
export class BufferLikeValidator extends Validator<BufferLike> {
  
  override value(): BufferLike {
    return '';
  }

  override parse(text: string): BufferLike | undefined {
    return text;
  }

  override schema(): SchemaProperty | undefined {
    return {
      oneOf: [{
        bsonType: 'string'
      }, {
        bsonType: 'binData'
      }]
    };
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    if (typeof value === 'string' || value instanceof Blob || value instanceof ArrayBuffer || value instanceof Buffer) {
      return ValidationStatus.Okay;
    } else {
      return ValidationStatus.Error;
    }
  }

  override logs() { return [new ValidatorLog('Expected base64 string, Blob, ArrayBuffer or Buffer.')]; }
}

/** atob implementation that supports full UTF-8 strings. */
export function atobUnicode(data: string) {
  return decodeURIComponent(escape(atob(data)));
}

/** btoa implementation that supports full UTF-8 strings. */
export function btoaUnicode(data: string) {
  return btoa(unescape(encodeURIComponent(data)));
}

/** Convert a buffer-like object to a base64 string. */
export async function binaryBase64(data: BufferLike): Promise<string> {
  if (typeof data === 'string') {
    return data;
  } else if (data instanceof Blob) {
    return btoaUnicode(await data.text());
  } else if (data instanceof ArrayBuffer) {
    return btoaUnicode(new TextDecoder('utf-8').decode(data));
  } return data.toString('base64');
}

/** Convert a buffer-like object to plaintext. */
export async function binaryText(data: BufferLike): Promise<string> {
  if (typeof data === 'string') {
    return atobUnicode(data);
  } else if (data instanceof Blob) {
    return await data.text();
  } else if (data instanceof ArrayBuffer) {
    return new TextDecoder('utf-8').decode(new Uint8Array(data));
  } return data.toString('utf-8');
}

/** Convert a buffer-like object to a buffer. */
export async function binaryBuffer(data: BufferLike): Promise<Buffer> {
  if (typeof data === 'string') {
    return Buffer.from(data, 'base64'); // Assume base64.
  } else if (data instanceof Blob) {
    return Buffer.from(await data.arrayBuffer());
  } else if (data instanceof ArrayBuffer) {
    return Buffer.from(data);
  } return data;
}

/** Convert a buffer-like object to any value that is not a blob. */
export async function binaryUnblob(data: BufferLike): Promise<string | Buffer | ArrayBuffer> {
  if (data instanceof Blob) {
    return await data.arrayBuffer();
  } return data;
}

/** Convert a buffer-like object to any value that is not a string. */
export async function binaryUnstring(data: BufferLike): Promise<Blob | ArrayBuffer | Buffer> {
  if (typeof data === 'string') {
    return Buffer.from(data, 'base64'); // Assume base64.
  } return data;
}

/** Coerce a buffer-like object into a blob part */
export function binaryBlobPart(data: BufferLike): Blob | ArrayBuffer {
  if (typeof data === 'string') {
    return Uint8Array.from(atob(data), c => c.charCodeAt(0)).buffer;
  } else if (data instanceof Blob || data instanceof ArrayBuffer) {
    return data;
  } else return data.buffer;
}

/** Overrides prototypes of Buffer and ArrayBuffer to JSON serialize to base64. */
export function binaryOverride() {

  // @ts-ignore
  Buffer.prototype.toJSON = function() { return this.toString('base64'); }

  // @ts-ignore
  ArrayBuffer.prototype.toJSON = function() {
    return btoaUnicode(new TextDecoder('utf-8').decode(this));
  }
}