import { ErrorResponse } from "../../../../../common/message/error";
import { errorResponse } from "../../../../../common/toolbox/message";
import { Validator } from "../../../../../common/validator/base";

/** Read contents of clipboard. */
export async function clipboardText() {
  try {
    return await navigator.clipboard.readText();
  } catch (e) {
    return new ErrorResponse('Failed to fetch contents of clipboard.', undefined, e);
  }
}

/** Parse contents of clipboard as JSON. */
export async function clipboardJSON(validator: Validator) {
  try {
    let text = await clipboardText();
    if (errorResponse(text)) return text;

    let value = JSON.parse(text);
    return validator.validate(value) ? validator.error(value) : value;
  } catch (e) {
    return new ErrorResponse('Clipboard contents were not valid JSON.', undefined, e);
  }
}