import { Pipe } from '@angular/core';
import { Permission } from '../../../../../common/model/permission';
import { AuthService } from '../service/auth.service';

@Pipe({
  name: 'permission'
})
export class PermissionPipe {

  constructor(
    private auth: AuthService
  ) {}

  transform(permission?: Permission) {
    return this.auth.permission(permission);
  }
}
