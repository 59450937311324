<app-boolean
  [on]="on"
  [off]="off"
  [large]="large"
  [require]="require"
  [disabled]="disabled"
  [required]="required"
  [readonly]="readonly | propertyReadonly:locked:reopened"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  (dirtyChange)="setDirty($event)"
  (touchChange)="setTouched($event)">
</app-boolean>