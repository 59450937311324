<div class="labels">
  <app-label>Address</app-label>
  <app-label>Type</app-label>
</div>
<div class="list" [list-grid]="value">
  <app-drag-chip cdkDrag cdkDragBoundary=".list" *ngFor="let e of value; let i = index" [borderless]="true">
    <app-field [readonly]="e.system">
      <app-string [(ngModel)]="e.address" [readonly]="e.system" [dirty]="dirty" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-string>
    </app-field>
    <app-field [readonly]="e.system">
      <app-code [(ngModel)]="e.type" [readonly]="e.system" [category]="ImportCode.Email" [dirty]="dirty" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-code>
    </app-field>
    <div class="buttons">
      <button icon-button *ngIf="!e.system" (click)="onDelete(i)" tooltip="Delete">delete</button>
      <button icon-button cdkDragHandle>drag_indicator</button>
    </div>
  </app-drag-chip>
  <button crystal button-prefix="add" position="before" (click)="onNew()">New Email</button>
</div>