import { Component } from '@angular/core';
import { SettingType, SettingsInfo } from '../../../../../../common/info/settings';
import { TypeInfo } from '../../../../../../common/info/type';

/** First demo settings feature. */
class DemoFirstSettings {
  constructor(
    public toggle = false,
    public value = 0
  ) {}

  static settingsinfo: SettingsInfo<DemoFirstSettings> = {
    toggle: {
      name: 'Toggle',
      description: 'Click to toggle.',
      type: SettingType.Boolean
    }, 
    value: {
      name: 'Value',
      description: 'Enter a new value.',
      type: SettingType.Number
    }
  }
}

class DemoSecondSettings {
  constructor(
    public name?: string
  ) {}

  static typeinfo: TypeInfo<DemoSecondSettings> = {
    name: ''
  }

  static settingsinfo: SettingsInfo<DemoSecondSettings> = {
    name: {
      name: 'Name',
      description: 'Type in a new name.',
      type: SettingType.String
    }
  }
}

/** Test data for settings. */
class DemoSettings {
  constructor(
    /** First feature. */
    public first = new DemoFirstSettings(),
    /** Second feature. */
    public second = new DemoSecondSettings()
  ) {}

  static typeinfo: TypeInfo<DemoSettings> = {
    second: new DemoSecondSettings
  }
}

@Component({
  selector: 'app-demo-settings',
  templateUrl: './demo-settings.component.html',
  styleUrls: ['./demo-settings.component.scss']
})
export class DemoSettingsComponent {
  /** Current settings value. */
  settings = new DemoSettings();

  static title() {
    return 'Settings';
  }

  /** Callback when logging current value. */
  onLog() {
    console.log(this.settings);
  }
}
