import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { DisputeUnion } from "../../../../../../common/model/dispute/dispute";
import { Transaction } from "../../../../../../common/model/transaction";
import { arrayDefined } from "../../../../../../common/toolbox/array";
import { CURRENCY_DEFAULT } from "../../../../../../common/toolbox/currency";
import { idNull } from "../../../../../../common/toolbox/id";
import { DIALOG_DATA, DialogRef } from "../../../common/component/dialog/dialog.model";
import { FormConfig } from "../../../common/component/form/form.model";
import { AuthService } from "../../../common/service/auth.service";
import { FormService } from "../../../common/service/form.service";
import { LedgerConfigService } from "../../../common/service/ledger-config.service";
import { LogService } from "../../../common/service/log.service";
import { DisplayDispute } from "../../../common/toolbox/dispute";
import { LedgerListState } from "../list/ledger-list.model";
import { DisputeJoinLedgerUploads } from "./dialog/ledger-stepper-dialog.component";
import { LedgerStepperDialogData, LedgerStepperDialogReturn } from "./dialog/ledger-stepper-dialog.model";
import { LedgerStepperState } from "./ledger-stepper.model";
import { LedgerItem } from "../../../../../../common/model/ledger/item";
import { FormulaService } from "../../../common/service/formula.service";
import { formulaRun } from "../../../../../../common/toolbox/formula/formula";

/** Preview for a ledger item to be added. */
class LedgerPreview {
  constructor(
    /** Transaction being added to. */
    public transaction = new Transaction(),
    /** Amount of ledger item. */
    public amount = CURRENCY_DEFAULT
  ) {}
}

@Component({
  selector: 'app-ledger-stepper',
  templateUrl: './ledger-stepper.component.html',
  styleUrls: ['./ledger-stepper.component.scss'],
  host: { class: 'column' }
})
export class LedgerStepperComponent {

  /** Configuration for ledger stepper. */
  @Input() set state(state: LedgerStepperState) { this.restate(state); }

  /** Emits when new ledger items are available to post. */
  @Output() uploads = new EventEmitter<DisputeJoinLedgerUploads[]>();

  /** Current page being displayed. */
  protected page = 0;
  /** Configuration for each child list. */
  protected states: LedgerListState[] = [];
  /** Flattened list of added ledger items. */
  protected previews: LedgerPreview[] = [];

  /** Configuration for header form. */
  protected config?: FormConfig;



  static title() {
    return 'Confirm Ledger Items';
  }

  constructor(
    @Inject(DIALOG_DATA)
    public data: LedgerStepperDialogData,
    protected dialogRef: DialogRef<LedgerStepperDialogReturn>,
    private auth: AuthService,
    private ledgerConfigs: LedgerConfigService,
    private forms: FormService,
    private log: LogService,
    private formulas: FormulaService
  ) { }

  /** Update current displayed page. */
  async onPage(offset: number) {
    this.page = this.page + offset;
    let state = this.states[this.page];
    if (!state) return;

    this.config = {
      form: idNull(this.data._form)
        ? await this.forms.setting(`disputes.${state.partial.dispute.type}.dispute.previewForm`)
        : await this.forms.item({ _inst: this.auth._inst, _id: this.data._form }),
      value: state.partial,
      mutate: true
    };
  }

  /** Set new ledger state. */
  private async restate(state: LedgerStepperState) {
    if (!state.data.partial.account) {
      this.log.show(`Failed to initialize ledger item list. No account provided.`);
      return;
    }

    this.states = arrayDefined(await Promise.all(LedgerStepperState.disputes(state).map<Promise<LedgerListState | undefined>>(async dispute => {
      let transaction = dispute.transaction;
      if (!transaction || !state.data.partial.account) {
        this.log.show(`Failed to initialize ledger item list. Dispute was missing a transaction: ${dispute._id}`);
        return undefined;
      }

      let partial: DisplayDispute = {
        ...state.data.partial,
        account: state.data.partial.account,
        dispute: dispute as DisputeUnion,
        ledger: new LedgerItem(),
        transaction
      };

      return {
        partial,
        _filter: state._filter,
        ledger: arrayDefined(await Promise.all(state.configs?.map(async config => {
          if (config._condition) {
            let condition = await this.formulas.item({ _inst: this.auth._inst, _id: config._condition });
            let shouldRun = formulaRun(condition, partial);
            if (!shouldRun) return;
          }
          let [ledger] = await this.ledgerConfigs.create(config._config, partial);
          return {
            ledger,
            shouldPost:false
          }
        }) ?? [])),
      };
    })));

    // Only need to emit posts once, ledger array references are mutated internally.
    this.onPage(0);
    this.uploads.next(this.states.map(state => ({
      dispute: state.partial.dispute,
      ledgers: state.ledger
    })));
  }
}
