import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkAction } from '../../../../../../../common/model/work/flow';

@Component({
  selector: 'app-setup-workflow-actions',
  templateUrl: './setup-workflow-actions.component.html',
  styleUrls: ['./setup-workflow-actions.component.scss']
})
export class SetupWorkflowActionsComponent {
  readonly DEFAULT = new WorkAction('New Action');

  /** Actions being configured. */
  @Input() actions: WorkAction[] = [];

  /** Emits when an action is selected or created. */
  @Output() action = new EventEmitter<WorkAction>();
}
