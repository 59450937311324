import { AttachmentType } from "../../../code/standard/common";
import { DisputeCreditStatus } from "../../../code/standard/disputes";
import { CommonCode, DisputesCode } from "../../../code/system";
import { CollectionInfo } from "../../../info/collection";
import { PropertyInfo } from "../../../info/prop";
import { SchemaInfo } from "../../../info/schema";
import { TypeInfo } from "../../../info/type";
import { UnionInfo } from "../../../info/union";
import { CURRENCY_DEFAULT } from "../../../toolbox/currency";
import { ID_DEFAULT } from "../../../toolbox/id";
import { AnyValidator } from "../../../validator/any";
import { FusionCollection } from "../../fusion";
import { PropertyType } from "../../property-type";
import { SCHEMA_OBJECT } from "../../schema/base";
import { EventCategory } from "../category";
import { EventReminder } from "../reminder";

/** Tagged type of claim event. */
export enum ClaimResultType {
  ClaimAdd,
  ClaimComments,
  ClaimAttachmentAdd,
  ClaimForm,
  ClaimModify,
  ClaimView,
  ClaimDelete,
  ClaimIntakeReview,
  Unused7,
  ClaimResolve,
  Unused1,
  ClaimDocumentTemplate,
  Unused2,
  Unused3,
  DisputeAdd,
  DisputeModify,
  Unused4,
  Unused5,
  ClaimAttachmentDelete,
  Unused6,
  DisputeWithdraw,
  ClaimReview
}

/** Result of performing action on a claim. */
export type ClaimResult =
  | ClaimReviewResult
  | ClaimAddResult
  | ClaimAttachmentAddResult
  | ClaimAttachmentDeleteResult
  | ClaimCommentsResult
  | ClaimDeleteResult
  | ClaimFormResult
  | ClaimIntakeReviewResult
  | ClaimDocumentTemplateResult
  | ClaimModifyResult
  | ClaimResolveResult
  | ClaimViewResult
  | DisputeAddResult
  | DisputeModifyResult
  | DisputeWithdrawResult
  | Unused1Result
  | Unused2Result
  | Unused3Result
  | Unused4Result
  | Unused5Result
  | Unused6Result
  | Unused7Result;

/** Event when a claim is added. */
export class ClaimAddResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimAdd;

  constructor(
    /** Claim that was created. */
    public _claim = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimAddResult> = {
    _claim: 'claims'
  }
}

/** Result of attaching an attachment to a claim. */
export class ClaimAttachmentAddResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimAttachmentAdd;

  constructor(
    /** Claim attachment was uploaded to. */
    public _claim = ID_DEFAULT,
    /** ID of attachment. */
    public _attachment = ID_DEFAULT,
    /** Type of attached file. */
    public attachmentType: string = AttachmentType.ClaimChargeback
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimAttachmentAddResult> = {
    _claim: 'claims',
    _attachment: 'attachments'
  }

  static propinfo: PropertyInfo<ClaimAttachmentAddResult> = {
    attachmentType: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  }
}

/** Result of removing attachment from a claim. */
export class ClaimAttachmentDeleteResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimAttachmentDelete;

  constructor(
    /** Claim attachment was deleted from. */
    public _claim = ID_DEFAULT,
    /** ID of attachment. */
    public _attachment = ID_DEFAULT,
    /** Type of deleted file. */
    public attachmentType: string = AttachmentType.ClaimChargeback
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimAttachmentDeleteResult> = {
    _claim: 'claims',
    _attachment: 'attachments'
  }

  static propinfo: PropertyInfo<ClaimAttachmentDeleteResult> = {
    attachmentType: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  }
}

/** Event when a claim's comments are modified. */
export class ClaimCommentsResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimComments;

  constructor(
    /** Claim that was created. */
    public _claim = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimCommentsResult> = {
    _claim: 'claims'
  }
}

/** Event when a claim is deleted. */
export class ClaimDeleteResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimDelete;

  constructor(
    /** Claim that was deleted. */
    public _claim = ID_DEFAULT,
    /** True if claim was restored. */
    public restore?: boolean
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimDeleteResult> = {
    _claim: 'claims'
  }

  static typeinfo: TypeInfo<ClaimDeleteResult> = {
    restore: true
  }
}

/** Custom form task completed on a claim. */
export class ClaimFormResult extends EventReminder {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimForm;

  constructor(
    /** Claim task was performed on. */
    public _claim = ID_DEFAULT,
    /** Task that was performed. */
    public _task = ID_DEFAULT,
    /** Result of performing task. */
    public value?: Record<string, any>,
    /** Whether front office users can view this event. */
    public privated?: boolean,
    /** User assigned to this event. */
    _assigned?: string,
    /** Due date event must be completed. */
    due?: Date,
    /** User that completed task. */
    _completed?: string,
    /** Date that event was completed. */
    done?: Date,

  ) {
    super(_assigned, due, _completed, done);
  }

  static override typeinfo: TypeInfo<ClaimFormResult> = {
    ...EventReminder.typeinfo,
    privated: false,
    value: new AnyValidator()
  }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimFormResult> = {
    _claim: 'claims',
    _task: 'tasks',
    _assigned: 'users',
    _completed: 'users'
  }

  static schemainfo: SchemaInfo<ClaimFormResult> = {
    value: SCHEMA_OBJECT
  }
}

/** Event when claim chargeback is reviewed. */
export class Unused2Result {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.Unused2;

  constructor(
    /** Claim for dispute that was reviewed. */
    public _claim = ID_DEFAULT,
    /** Dispute that was reviewed. */
    public _dispute = ID_DEFAULT,
    /** Whether the chargeback was approved or denied. */
    public approved = true
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, Unused2Result> = {
    _claim: 'claims',
    _dispute: 'disputes'
  }
}

/** Event when claim representment is reviewed. */
export class Unused3Result {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.Unused3;

  constructor(
    /** Claim for dispute that was reviewed. */
    public _claim = ID_DEFAULT,
    /** Dispute that was reviewed. */
    public _dispute = ID_DEFAULT,
    /** Whether the representment was approved or denied */
    public approved = true,
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, Unused3Result> = {
    _claim: 'claims',
    _dispute: 'disputes'
  }
}

/** Event when claim pre-arbitration is reviewed. */
export class Unused5Result {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.Unused5;

  constructor(
    /** Claim for dispute that was reviewed. */
    public _claim = ID_DEFAULT,
    /** Dispute that was reviewed. */
    public _dispute = ID_DEFAULT,
    /** Whether the pre-arbitration was approved or denied */
    public approved = true,
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, Unused5Result> = {
    _claim: 'claims',
    _dispute: 'disputes'
  }
}

/** Event when claim return date is specified. */
export class Unused6Result {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.Unused6;

  constructor(
    /** Claim that was returned. */
    public _claim = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, Unused6Result> = {
    _claim: 'claims'
  }
}

/** Event when claim arbitration is reviewed. */
export class Unused4Result {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.Unused4;

  constructor(
    /** Claim for dispute that was reviewed. */
    public _claim = ID_DEFAULT,
    /** Dispute that was reviewed. */
    public _dispute = ID_DEFAULT,
    /** Whether the arbitration was approved or denied */
    public approved = true,
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, Unused4Result> = {
    _claim: 'claims',
    _dispute: 'disputes'
  }
}

/** Event when claim intake is reviewed. */
export class ClaimIntakeReviewResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimIntakeReview;

  constructor(
    /** Claim that was reviewed. */
    public _claim = ID_DEFAULT,
    /** Whether the claim was approved or denied */
    public approved = true,
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimIntakeReviewResult> = {
    _claim: 'claims',
  }
}

/** Event when claim intake is reviewed. */
export class ClaimReviewResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimReview;

  constructor(
    /** Claim that was reviewed. */
    public _claim = ID_DEFAULT,
    /** Dispute that was reviewed. */
    public _dispute?: string,
    /** Whether the claim was approved or denied */
    public approved = true,
    /** Task that was performed. */
    public _task = ID_DEFAULT
  ) { }

  static typeinfo: TypeInfo<ClaimReviewResult> = {
    _dispute: ID_DEFAULT
  }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimReviewResult> = {
    _claim: 'claims',
    _task: 'tasks'
  }
}

/** Event when a document template is generated for a claim. */
export class ClaimDocumentTemplateResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimDocumentTemplate;

  constructor(
    /** Claim that was resolved. */
    public _claim = ID_DEFAULT,
    /** Attachment that was generated */
    public _attachment = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimDocumentTemplateResult> = {
    _claim: 'claims',
    _attachment: 'attachments'
  }
}

/** Event when a claim is modified. */
export class ClaimModifyResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimModify;

  constructor(
    /** Claim that was modified. */
    public _claim = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimModifyResult> = {
    _claim: 'claims'
  }
}

/** Event when a claim is marked as resolved. */
export class ClaimResolveResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimResolve;

  constructor(
    /** Claim that was resolved. */
    public _claim = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimModifyResult> = {
    _claim: 'claims'
  }
}

/** Event when a claim is viewed. */
export class ClaimViewResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.ClaimView;

  constructor(
    /** Claim that was viewed. */
    public _claim = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, ClaimViewResult> = {
    _claim: 'claims'
  }
}

/** Event when a dispute is added. */
export class DisputeAddResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.DisputeAdd;

  constructor(
    /** Claim this dispute belongs to. */
    public _claim = ID_DEFAULT,
    /** Dispute that was created. */
    public _dispute = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, DisputeAddResult> = {
    _claim: 'claims',
    _dispute: 'disputes'
  }
}

/** Event when a dispute is issued credit. */
export class Unused7Result {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.Unused7;

  constructor(
    /** Claim that was associated with dispute. */
    public _claim = ID_DEFAULT,
    /** Dispute that was issued credit*/
    public _dispute = ID_DEFAULT,
    /** Amount transaction entered in calculator */
    public amountTransaction = CURRENCY_DEFAULT,
    /** Amount fees entered in calculator */
    public amountFees = CURRENCY_DEFAULT,
    /** Amount fees entered in calculator */
    public amountInterest = CURRENCY_DEFAULT,
    /** The status of the credit */
    public creditStatus = DisputeCreditStatus.Provisional
  ) { }

  static propinfo: PropertyInfo<Unused7Result> = {
    creditStatus: { type: PropertyType.Code, category: DisputesCode.DisputeCreditStatus },
  }

  static collectioninfo: CollectionInfo<FusionCollection, Unused7Result> = {
    _claim: 'claims',
    _dispute: 'disputes'
  }
}

/** Event when a dispute is modified. */
export class DisputeModifyResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.DisputeModify;

  constructor(
    /** Claim this dispute belongs to. */
    public _claim = ID_DEFAULT,
    /** Dispute that was modified. */
    public _dispute = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, DisputeModifyResult> = {
    _claim: 'claims',
    _dispute: 'disputes'
  }
}

/** Event when a dispute is withdrawn. */
export class DisputeWithdrawResult {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.DisputeWithdraw;

  constructor(
    /** Claim this dispute belongs to. */
    public _claim = ID_DEFAULT,
    /** Dispute that was withdrawn. */
    public _dispute = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, DisputeWithdrawResult> = {
    _claim: 'claims',
    _dispute: 'disputes'
  }
}

/** Placeholder event for an unused event. */
export class Unused1Result {
  readonly category = EventCategory.Claim;
  readonly type = ClaimResultType.Unused1;

  constructor(
    /** Associated claim. */
    public _claim = ID_DEFAULT
  ) { }

  static collectioninfo: CollectionInfo<FusionCollection, Unused1Result> = {
    _claim: 'claims'
  }
}

export class ClaimResultClass {
  [ClaimResultType.ClaimAdd] = new ClaimAddResult();
  [ClaimResultType.ClaimAttachmentAdd] = new ClaimAttachmentAddResult();
  [ClaimResultType.ClaimAttachmentDelete] = new ClaimAttachmentDeleteResult();
  [ClaimResultType.ClaimComments] = new ClaimCommentsResult();
  [ClaimResultType.ClaimDelete] = new ClaimDeleteResult();
  [ClaimResultType.ClaimForm] = new ClaimFormResult();
  [ClaimResultType.ClaimIntakeReview] = new ClaimIntakeReviewResult();
  [ClaimResultType.ClaimDocumentTemplate] = new ClaimDocumentTemplateResult();
  [ClaimResultType.ClaimModify] = new ClaimModifyResult();
  [ClaimResultType.ClaimResolve] = new ClaimResolveResult();
  [ClaimResultType.ClaimReview] = new ClaimReviewResult();
  [ClaimResultType.ClaimView] = new ClaimViewResult();
  [ClaimResultType.DisputeAdd] = new DisputeAddResult();
  [ClaimResultType.DisputeModify] = new DisputeModifyResult();
  [ClaimResultType.DisputeWithdraw] = new DisputeWithdrawResult();
  [ClaimResultType.Unused1] = new Unused1Result();
  [ClaimResultType.Unused2] = new Unused2Result();
  [ClaimResultType.Unused3] = new Unused3Result();
  [ClaimResultType.Unused4] = new Unused4Result();
  [ClaimResultType.Unused5] = new Unused5Result();
  [ClaimResultType.Unused6] = new Unused6Result();
  [ClaimResultType.Unused7] = new Unused7Result();
}

/** Union type information for claim results. */
export const CLAIM_RESULT_UNIONINFO: UnionInfo<ClaimResult, ClaimResultType> = {
  tag: 'type',
  classes: new ClaimResultClass()
}
