<app-card class="fill">
  <table app-grid [source]="source" class="fill">

    <ng-container grid-column-def="id">
      <grid-header-cell *grid-header-cell-def>ID</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.id}}</grid-cell>
    </ng-container>

    <ng-container grid-column-def="name">
      <grid-header-cell *grid-header-cell-def>Name</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.name}}</grid-cell>
    </ng-container>

    <ng-container grid-column-def="birth">
      <grid-header-cell *grid-header-cell-def>Birth</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.birth | dateIso}}</grid-cell>
    </ng-container>

    <ng-container grid-column-def="balance">
      <grid-header-cell *grid-header-cell-def>Balance</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.balance | cents}}</grid-cell>
    </ng-container>

    <ng-container grid-column-def="index">
      <grid-header-cell *grid-header-cell-def>Index</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.index}}</grid-cell>
    </ng-container>
    
    <grid-header-row *grid-header-row-def="columns"></grid-header-row>
    <grid-row *gridRowDef="let row; columns: columns;"></grid-row>
    <div class="test">Test test test</div>
  </table>
  <app-grid-paginator [source]="source"></app-grid-paginator>
</app-card>