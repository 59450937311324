import { Component, Input } from '@angular/core';
import { LedgerItem } from '../../../../../../common/model/ledger/item';
import { idInstOmit } from '../../../../../../common/toolbox/id';

@Component({
  selector: 'app-ledger-arrow',
  templateUrl: './ledger-arrow.component.html',
  styleUrls: ['./ledger-arrow.component.scss']
})
export class LedgerArrowComponent {
  /** Ledger being edited. */
  @Input() ledger = idInstOmit(new LedgerItem());
}
