
/** 
 *  Pattern for valid password.
 *  (?=.*[a-z])                 1+ characters a-z
 *  (?=.*[A-Z])                 1+ characters A-Z
 *  (?=.*\d)                    1+ digits 0-9
 *  (?=.*[!@#$%^&*()])          1+ special characters !@#$%^&*()
 *  [A-Za-z\d!@#$%^&*()]{8,32}  8-32 characters
 */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,32}$/

/**
 *  Pattern for bcrypt-hashed password.
 *  \$2[axyb]\$                 $2?$ to signal bcrypt hashing algorithm
 *  \$\d\d\$                    $??$ to signal bcrypt hash difficulty
 *  .{53}                       53 adapted base64 characters
 */
export const BCRYPT_REGEX = /^\$2[axyb]\$\d\d\$.{53}$/;

/** Minimum password length. */
export const PASSWORD_LENGTH_MIN = 8;
/** Maximum password length. */
export const PASSWORD_LENGTH_MAX = 32;
/** List of valid password characters. */
export const PASSWORD_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';