<button *ngIf="button" icon-button type="button" [tooltip]="_readonly ? null : 'Open calendar'" [calendar-trigger]="value" [calendar-time]="time" [calendar-min]="min" [calendar-max]="max" [disabled]="_readonly" (dateChange)="onSelect($event)">{{PropertyType.Date | icon:PROPERTY_TYPE_ICON}}</button>
<input #monthInput class="field-2" maxlength="2" [readonly]="_readonly" (keydown)="onMonthKey($event)" [class.empty]="value | dateNull">
<div class="divider">/</div>
<input #dayInput class="field-2" maxlength="2" [readonly]="_readonly" (keydown)="onDayKey($event)" [class.empty]="value | dateNull">
<div class="divider">/</div>
<input #yearInput class="field-4" maxlength="4" [readonly]="_readonly" (keydown)="onYearKey($event)" [class.empty]="value | dateNull">
<div class="time" [class.hide]="!time">
  <input #hourInput class="field-2" maxlength="2" [readonly]="_readonly" (keydown)="onHourKey($event)" [class.empty]="value | dateNull">
  <div class="divider">:</div>
  <input #minuteInput class="field-2" maxlength="2" [readonly]="_readonly" (keydown)="onMinuteKey($event)" [class.empty]="value | dateNull">
  <div class="divider">:</div>
  <input #secondInput class="field-2" maxlength="2" [readonly]="_readonly" (keydown)="onSecondKey($event)" [class.empty]="value | dateNull">
  <button icon-button class="period" type="button" tooltip="Toggle period" [disabled]="_readonly" (click)="onPeriod()">{{period === DatePeriod.AM ? 'AM' : 'PM'}}</button>
</div>
<div class="fill"></div>
<button *ngIf="button && !required" icon-button type="button" [class.empty]="value | dateNull" [visible-if]="value && !required && !_readonly" (click)="writeValue()">clear</button>