import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { merge, Subject, takeUntil } from "rxjs";
import { objectDelete } from "../../../../../../common/toolbox/object";
import { DateModel, DateRange, DAYS_MAX, DAYS_MIN } from "../../../../../../common/toolbox/time";
import { overlayCreate } from "../../toolbox/angular";
import { CalendarComponent } from "./calendar.component";

@Directive({
  selector: '[calendar-trigger]',
  exportAs: 'calendarTrigger',
  host: {
    '(click)': 'toggle($event)'
  }
})
export class CalendarTriggerDirective {

  /** Sets initial date to display in calendar. */
  @Input('calendar-trigger')
  get date() { return this.model; }
  set date(date: DateModel) {
    this.model = date;
    this.calendar?.writeValue(this.model);
  }

  /** Minimum selectable range in calendar. */
  @Input('calendar-min')
  get min() { return this._min; }
  set min(min: number) {
    this._min = min;
    if (this.calendar) this.calendar.min = min;
  }

  /** Maximum selectable range in calendar. */
  @Input('calendar-max')
  get max() { return this._max; }
  set max(max: number) {
    this._max = max;
    if (this.calendar) this.calendar.max = max;
  }

  /** True to preserve time values bound to calendar. */
  @Input('calendar-time') time = false;

  /** True to force date range mode. */
  @Input('calendar-range')
  get range() { return this._range; }
  set range(range: boolean) {
    this._range = coerceBooleanProperty(range);
    if (this.calendar) this.calendar.range = range;
  }

  /** Emits when bound date changes. */
  @Output() dateChange = new EventEmitter<Date>();
  /** Emits when bound date range changes. */
  @Output() rangeChange = new EventEmitter<DateRange>();

  /** Minimum date offset, in number of days. */
  private _min = DAYS_MIN;
  /** Maximum date offset, in number of days. */
  private _max = DAYS_MAX;
  /** True to force range mode. */
  private _range = false;

  /** Date to display in calendar. */
  private model: DateModel = new Date();
  /** Reference to menu this trigger opens. */
  private calendar?: CalendarComponent;
  /** Opened overlay containing menu. */
  private overlayRef?: OverlayRef;
  /** Emits when new value is selected. */
  private select = new Subject<void>();
  /** Emits when element is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private element: ElementRef<HTMLElement>
  ) {}

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.overlayRef?.dispose();
  }

  /** Opens the calendar. */
  open() {
    if (this.calendar) return;

    // Attach menu's template to portal.
    this.overlayRef = overlayCreate(this.element, this.overlay, this.overlayRef);
    const componentPortal = new ComponentPortal(CalendarComponent);
    let componentRef = this.overlayRef.attach(componentPortal);
    this.calendar = componentRef.instance;
    this.calendar.min = this._min;
    this.calendar.max = this._max;
    this.calendar.time = this.time;
    this.calendar.range = this._range;
    this.calendar.writeValue(this.date);
    this.calendar.dateChange.pipe(takeUntil(this.destroy)).subscribe(date => {
      this.dateChange.next(this.model = date);
      this.select.next();
    });
    this.calendar.rangeChange.pipe(takeUntil(this.destroy)).subscribe(range => {
      this.rangeChange.next(this.model = range);
      this.select.next();
    });

    // Listen for close clicks and value changes.
    merge(this.overlayRef.backdropClick(), this.select).pipe(takeUntil(this.destroy)).subscribe(() => {
      this.close();
    });
  }

  /** Closes the menu. */
  close() {
    this.overlayRef?.detach();
    objectDelete(this, 'calendar' as any);
  }

  /** Callback when menu is clicked. */
  toggle() {
    this.calendar ? this.close() : this.open();
  }
}