import { Pipe } from '@angular/core';
import { dateNull } from "../../../../../common/toolbox/time";

@Pipe({
  name: 'dateNull'
})
export class DateNullPipe {
  transform(value: Date | undefined): boolean {
    return dateNull(value)
  }
}
