import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../../common/model/formula/block";
import { ConditionType } from "../../../../../../../../common/model/formula/condition";
import { ExpressionTernary } from "../../../../../../../../common/model/formula/expression";
import { OperatorType } from "../../../../../../../../common/model/formula/operator";
import { ColorCode } from "../../../../../../../../common/toolbox/color";
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';
import { BlockSlot } from '../../block.model';

/** Labels for each ternary type. */
const LABELS = {
  [OperatorType.Pad]: ['pad', 'with'],
  [OperatorType.Slice]: ['start', 'end'],
  [ConditionType.Between]: ['between', 'and']
} as const;

@Component({
  selector: 'app-setup-formula-ternary',
  templateUrl: './setup-formula-ternary.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-ternary.component.scss'],
  host: { color: ColorCode.Primary }
})
export class SetupFormulaTernaryComponent extends BlockComponent<ExpressionTernary> {
  /** Left expression. */
  @ViewChild('left', { static : true, read: ViewContainerRef }) left!: ViewContainerRef;
  /** Middle expression. */
  @ViewChild('middle', { static : true, read: ViewContainerRef }) middle!: ViewContainerRef;
  /** Right expression. */
  @ViewChild('right', { static : true, read: ViewContainerRef }) right!: ViewContainerRef;

  /** Middle label. */
  mlabel = '';
  /** Right label. */
  rlabel = '';

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  ngOnInit() {
    this.releft(undefined, this.block.left);
    this.remiddle(undefined, this.block.middle);
    this.reright(undefined, this.block.right);

    [this.mlabel, this.rlabel] = LABELS[this.block.type];
  }

  /** Replace left expression. */
  private releft(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.left,
      current: { block: this.block, key: 'left' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.releft.bind(this)
    });
    this.widen(true, this.slots[0]!);
  }

  /** Replace middle expression. */
  private remiddle(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.middle,
      current: { block: this.block, key: 'middle' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.remiddle.bind(this)
    });
  }

  /** Replace expression. */
  private reright(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.right,
      current: { block: this.block, key: 'right' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.reright.bind(this)
    });
  }
}