import { arrayEqual } from "./array";

/** Determine if two sets are equal. */
export function setEqual<T>(a: Set<T>, b: Set<T>) {
  return arrayEqual([...a], [...b]);
}

/** Coerce a value into a set. */
export function setCoerce<T>(value: any): Set<T> {
  if (value instanceof Set) return value;
  if (Array.isArray(value)) return new Set(value);
  if (value === undefined) return new Set();
  return new Set([value]);
}