import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { FormListPostResponse } from '../../../../../../../common/message/form-list';
import { FormList, FormSection } from '../../../../../../../common/model/form/list';
import { arrayRemove } from "../../../../../../../common/toolbox/array";
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { DevService } from '../../../../common/service/dev.service';
import { LogService } from '../../../../common/service/log.service';
import { TabService } from '../../../../common/service/tab.service';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';
import { displaySetup } from '../../setup.model';
import { SetupFormListPreviewComponent } from '../preview/setup-form-list-preview.component';
import { SetupFormListPreviewData } from '../preview/setup-form-list-preview.model';

@Component({
  selector: 'app-setup-form-list-edit',
  templateUrl: './setup-form-list-edit.component.html',
  styleUrls: ['./setup-form-list-edit.component.scss']
})
export class SetupFormListEditComponent extends SetupEditComponent<FormList, FormListPostResponse> {
  readonly ACTIONS_HELP = 'Form to display actions at bottom of form list.';
  readonly SECTION_FORMULA_HELP = 'Condition for this subform to be displayed.';

  /** Display partial to display in form list. */
  partial = displaySetup();

  /** Current form list being edited. */
  value = idMaybe(new FormList());

  constructor(
    elementRef: ElementRef,
    log: LogService,
    public dev: DevService,
    private http: HttpClient,
    private tabs: TabService
  ) {
    super(elementRef, log);
  }

  /** Preview current form list. */
  onPreview() {
    this.tabs.open(SetupFormListPreviewComponent, new SetupFormListPreviewData(this.value, this.partial));
  }

  /** Add new section to list. */
  onNew() {
    this.value.sections.push(new FormSection());
  }

  /** Delete specified section from list. */
  onDelete(section: FormSection) {
    arrayRemove(this.value.sections, section);
  }

  /** Submit current changes to model. */
  push() {
    return postRequest(this.http, 'form-lists', { items: [this.value] });
  }

  /** Reset current form with new form list. */
  async reset(value: MaybeId<FormList>) {
    this.value = value;
  }
}
