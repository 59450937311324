import { Component, Inject } from '@angular/core';
import { FormListConfig } from '../../../../common/component/form/form.model';
import { TAB_DATA, TabRef } from '../../../../common/component/tab/bar/tab-bar.model';
import { SetupFormListPreviewData } from './setup-form-list-preview.model';

@Component({
  selector: 'app-setup-form-list-preview',
  templateUrl: './setup-form-list-preview.component.html',
  styleUrls: ['./setup-form-list-preview.component.scss']
})
export class SetupFormListPreviewComponent {

  /** Configuration for form list. */
  config?: FormListConfig;

  static title(data: SetupFormListPreviewData) {
    return data.list.name;
  }

  constructor(
    @Inject(TAB_DATA)
    public data: SetupFormListPreviewData,
    public ref: TabRef
  ) {}

  ngOnInit() {
    this.config = { list: this.data.list, value: this.data.partial, mutate: true }
  }

  /** Callback when closing test form list. */
  close() {
    this.ref.close();
  }
}
