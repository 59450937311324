import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DisputesCode } from '../../../../../../common/code/system';
import { ClaimJoin, ClaimJoinACH, ClaimUnion } from '../../../../../../common/model/claim/claim';
import { DisplayType } from '../../../../../../common/model/display';
import { Form } from '../../../../../../common/model/form/form';
import { Permission } from '../../../../../../common/model/permission';
import { claimDisputes } from '../../../../../../common/toolbox/claim';
import { idNull } from '../../../../../../common/toolbox/id';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { deepCopy } from '../../../../../../common/toolbox/object';
import { FormComponent } from '../../../common/component/form/form.component';
import { FormConfig } from '../../../common/component/form/form.model';
import { AuthService } from '../../../common/service/auth.service';
import { FormService } from '../../../common/service/form.service';
import { LogService } from '../../../common/service/log.service';
import { MemberService } from '../../../common/service/member.service';
import { SettingGroupService } from '../../../common/service/setting-group.service';
import { deleteRequest } from '../../../common/toolbox/request';

@Component({
  selector: 'app-claim-toolbar',
  templateUrl: './claim-toolbar.component.html',
  styleUrls: ['./claim-toolbar.component.scss'],
  host: {
    class: 'row'
  }
})
export class ClaimToolbarComponent {
  readonly Permission = Permission;
  readonly DisputesCode = DisputesCode;

  /** Reference to grid containing events. */
  @ViewChild(FormComponent) form?: FormComponent;

  /** Claim being viewed in main interface. */
  @Input() set claim(claim: ClaimJoin) {
    this._claim = claim;
    this.reclaim(claim);
  }

  @Input() undoIndex: number = -1;

  /** Emits when changes are made to claim. */
  @Output() changed = new EventEmitter<void>();
  /** Emits when current claim should be refreshed. */
  @Output() refreshed = new EventEmitter<void>();
  /** Emits when current claim should be undone. */
  @Output() undo = new EventEmitter<void>();

  /** Claim being viewed. */
  _claim: ClaimJoin = new ClaimJoinACH();
  /** Form to display next to action buttons. */
  toolbarForm = new Form();
  /** Configuration for toolbar form. */
  config?: FormConfig

  constructor(
    protected auth: AuthService,
    private members: MemberService,
    private log: LogService,
    private http: HttpClient,
    private settings: SettingGroupService,
    private forms: FormService,
  ) {}

  async ngOnInit() {
    let settings = await this.settings.inst();
    let _form = settings.disputes.claim.toolbarForm;
    if (idNull(_form)) return;

    this.toolbarForm = await this.forms.item({ _inst: this.auth._inst, _id: _form });
    if (this.auth.permission(Permission.ClaimsManage)) {
      this.toolbarForm = deepCopy(this.toolbarForm);
      this.toolbarForm.editable = [DisplayType.Claim];
    }
    this.reclaim(this._claim);
  }

  /** Callback when navigating to member's page. */
  onMember() {
    this.members.open({ _inst: this._claim._inst, _ids: [this._claim._member] });
  }

  reclaim(claim: ClaimJoin) {
    if (!this.form) return;
    //lock the form as readonly until we are done updating it to prevent it from emitting extra changed events.
    let readonly = this.form.readonly;
    this.form.readonly = true;
    this.config = {
      form: this.toolbarForm,
      value: { claim: claim as ClaimUnion },
      mutate: true,
      labels: false
    };
    //form is done updating, unlock on the next cycle of the event loop.
    setTimeout(() => {
      this.form!.readonly = readonly;
    })
  }

  /** Callback when claim is deleted/restored. */
  async onDelete() {
    let restore = this._claim.deleted;
    let result = await deleteRequest(this.http, 'claims', { _insts: [this._claim._inst], _claims: [this._claim._id], restore });
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }

    // Apply local changes immediately.
    this.log.show(result.success);
    this._claim.deleted = !this._claim.deleted;
    for (let dispute of claimDisputes(this._claim)) {
      dispute.deleted = !restore;
    }
  }
}
