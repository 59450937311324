import { Component } from "@angular/core";
import { TriggerConfigTag } from "../../../../../../../common/model/trigger-config";

/** Interface that allows a component to configure a specific trigger type. */
@Component({
  selector: 'app-trigger-accessor',
  template: ''
})
export abstract class TriggerAccessor<T extends TriggerConfigTag = TriggerConfigTag> {

  /** Trigger config being edited. */
  config!: T;

  /** Called after configuration is bound. */
  reset() {}
}