import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { propinfoProperties } from '../../../../../../../../../common/info/prop';
import { ClaimPreview } from '../../../../../../../../../common/model/claim/base';
import { JobType } from '../../../../../../../../../common/model/job/payload';
import { PropertyType } from '../../../../../../../../../common/model/property-type';
import { RouteMethod } from '../../../../../../../../../common/toolbox/api';
import { arrayDefined, arrayUnique } from '../../../../../../../../../common/toolbox/array';
import { claimUnion } from '../../../../../../../../../common/toolbox/claim';
import { errorPartition } from '../../../../../../../../../common/toolbox/message';
import { AuthService } from '../../../../../../common/service/auth.service';
import { LogService } from '../../../../../../common/service/log.service';
import { bulkRequest } from '../../../../../../common/toolbox/request';
import { JobAccessor } from '../../../edit/setup-job-edit.model';

@Component({
  selector: 'app-setup-job-payload-notification-claim-date',
  templateUrl: './setup-job-payload-notification-claim-date.component.html',
  styleUrls: ['./setup-job-payload-notification-claim-date.component.scss']
})
export class SetupJobPayloadNotificationClaimDateComponent extends JobAccessor<JobType.NotificationClaimDate> {

  /** List of claims to select. */
  claims: ClaimPreview[] = [];
  /** Available date fields to pick for notifications. */
  properties = propinfoProperties(claimUnion()).filter(p => p.type === PropertyType.Date);
  /** Last claim query. */
  query?: string;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private log: LogService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.claim) this.onQuery('');
  }

  /** Callback when filtering list of claims. */
  async onQuery(query: string) {
    this.query = query;
    let responses = await bulkRequest(this.http, ...arrayDefined([
      this.query ? {
        method: RouteMethod.Get as const,
        route: 'claims/preview' as const,
        body: { _insts: [this.auth._inst], displayIds: [this.query] }
      } : undefined,
      this.job.payload._claim ? {
        method: RouteMethod.Get as const,
        route: 'claims/preview' as const,
        body: { _insts: [this.auth._inst], _ids: [this.job.payload._claim] }
      } : undefined
    ]));

    let [error, claims] = errorPartition('There was an error fetching claims.', responses);
    if (error) return this.log.show(error);
    this.claims = arrayUnique(claims.flat(), '_id').sort((a, b) => a.displayId.localeCompare(b.displayId));
  }
}
