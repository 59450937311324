import { IndexInfo } from "../../info";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { EnumValidator } from "../../validator/enum";
import { Direction } from "../direction";
import { DisplayPartial } from "../display";

/** Status of each work item. */
export enum WorkStage {
  /** Item has not been viewed yet. */
  Unviewed,
  /** Item has been viewed. */
  Viewed,
  /** Item has been worked. */
  Worked
}

/** A single item in a work queue to be worked. */
export class WorkItem {
  constructor(
    /** Unique identifier of work queue item. */
    public _id = ID_DEFAULT,
    /** Queue this item belongs to. */
    public _queue = ID_DEFAULT,
    /** The resource to be worked. */
    public _item = ID_DEFAULT,
    /** Current stage of this item. */
    public stage = WorkStage.Unviewed,
    /** Index of item in queue. */
    public index = 0,
    /** True if item has been bookmarked. */
    public bookmark?: boolean
  ) {}

  static typeinfo: TypeInfo<WorkItem> = {
    bookmark: false,
    stage: new EnumValidator(WorkStage)
  }

  static indexinfo: IndexInfo<WorkItem> = [
    { key: { _queue: 1, index: 1 } },
    { key: { _item: 1 } }
  ];

  /** Returns true if the cursor position is within range. */
  static valid(cursor?: WorkItem): cursor is WorkItem {
    return !!cursor && cursor.index > WORK_QUEUE_START && cursor.index < WORK_QUEUE_END;
  }

  /** Get a cursor to the front of a queue. */
  static cursor(_queue: string, _account = ID_DEFAULT, direction: Direction = 1): WorkItem {
    return { ...new WorkItem(), _queue, _item: _account, index: direction === 1 ? WORK_QUEUE_START : WORK_QUEUE_END };
  }
}

/** A work item joined on its respective collection. */
export type WorkItemJoin = WorkItem & DisplayPartial;

/** Work item position before start of queue. */
export const WORK_QUEUE_START = -1;
/** Work item position after end of queue. */
export const WORK_QUEUE_END = Number.MAX_SAFE_INTEGER;
