import { TypeInfo } from "../info/type";
import { ErrorResponse } from "../message/error";
import { errorPartition } from "../toolbox/message";
import { ChoiceValidator } from "../validator/choice";
import { OneOfValidator } from "../validator/one-of";
import { Account } from "./account/account";
import { AccountLoan } from "./account/loan";
import { AccountShare } from "./account/share";
import { Address } from "./address";
import { Member } from "./member";

/** Member with attached accounts and addresses. */
export class MemberJoin extends Member {
  /** List of accounts member owns. */
  accounts: Account[] = [];
  /** List of contact addresses for member. */
  addresses: Address[] = [];

  static override typeinfo: TypeInfo<MemberJoin> = {
    ...Member.typeinfo,
    accounts: [new AccountLoan()],
    addresses: [new Address()]
  }
}

/** MemberJoin with possible errors for each value. */
export class MemberErrorJoin extends Member {
  /** List of accounts member owns. */
  accounts: (Account | ErrorResponse)[] = [];
  /** List of contact addresses for member. */
  addresses: (Address | ErrorResponse)[] = [];

  static override typeinfo: TypeInfo<MemberErrorJoin> = {
    ...Member.typeinfo,
    accounts: [new OneOfValidator<Account | ErrorResponse>(new AccountLoan(), new AccountShare(), new ErrorResponse())],
    addresses: [new ChoiceValidator(new Address(), new ErrorResponse())]
  }

  /** Split into member and errors. */
  static partition(member: MemberErrorJoin): [ErrorResponse | undefined, MemberJoin] {
    let [accountErrors, accounts] = errorPartition('There was an error fetching accounts.', member.accounts);
    let [addressErrors, addresses] = errorPartition('There was an error fetching addresses.', member.addresses);
    let memberError = errorPartition('There was an error fetching accounts and addresses.', [accountErrors, addressErrors])[0];
    return [memberError, { ...member, accounts, addresses }];
  }
}
