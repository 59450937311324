import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { deepCopy } from "../../../../../common/toolbox/object";
import { randomRange } from "../../../../../common/toolbox/random";
import { EnumMap, enumValues } from "../../../../../common/toolbox/enum";

/** Standard stats tracked. */
export enum StatType {
  AccountsDistinctWorked  = 'accountsDistinctWorked',
  AccountsTotalWorked     = 'accountsTotalWorked',
  CallsInbound            = 'callsInbound',
  CallsOutbound           = 'callsOutbound',
  CallsTotal              = 'callsTotal',
  Contacts                = 'contacts',
  EmailsReceived          = 'emailsReceived',
  EmailsSent              = 'emailsSent',
  FollowUpsScheduled      = 'followUpsScheduled',
  LettersProduced         = 'lettersProduced',
  LettersSent             = 'lettersSent',
  MeetingsInhouse         = 'meetingsInhouse',
  Promises                = 'promises',
  PromisesHonored         = 'promisesHonored',
  TextsSent               = 'textsSent'
}

/** List of standard stats, in default visual order. */
export const STAT_TYPES = [
  StatType.AccountsTotalWorked,
  StatType.AccountsDistinctWorked,
  StatType.Promises,
  StatType.PromisesHonored,
  StatType.Contacts,
  StatType.FollowUpsScheduled,
  StatType.CallsTotal,
  StatType.CallsOutbound,
  StatType.CallsInbound,
  StatType.EmailsSent,
  StatType.EmailsReceived,
  StatType.MeetingsInhouse,
  StatType.LettersProduced,
  StatType.LettersSent,
  StatType.TextsSent
]

/** Name for each stat. */
export const STAT_TYPE_NAME: EnumMap<StatType> = {
  [StatType.AccountsDistinctWorked]:  'Accounts Worked (Distinct)',
  [StatType.AccountsTotalWorked]:     'Accounts Worked',
  [StatType.CallsInbound]:            'Inbound Calls',
  [StatType.CallsOutbound]:           'Outbound Calls',
  [StatType.CallsTotal]:              'Total Calls',
  [StatType.Contacts]:                'Contacts',
  [StatType.EmailsReceived]:          'Received Email',
  [StatType.EmailsSent]:              'Emails Sent',
  [StatType.FollowUpsScheduled]:      'Follow Ups Scheduled',
  [StatType.LettersProduced]:         'Letters Produced',
  [StatType.LettersSent]:             'Letters Sent',
  [StatType.MeetingsInhouse]:         'Inhouse Meeting',
  [StatType.Promises]:                'Promises',
  [StatType.PromisesHonored]:         'Promises Kept',
  [StatType.TextsSent]:               'Texts Sent'
};

/** Information about a user's stat. */
export class Stat {
  constructor(
    /** Stat being tracked. */
    public type = StatType.AccountsTotalWorked,
    /** Actual completion of goal. */
    public actual = 0,
    /** Full amount of goal. */
    public goal = 0
  ) {}

  /** Get a random set of stats. */
  static random(stats?: StatType[]) {
    stats = stats ?? enumValues(StatType);
    return stats.map(stat => new Stat(stat, randomRange(0, 90), 80));
  }
}

/** Information about a team member. */
export class StatInfo {
  constructor(
    /** ID of team member. */
    public id = ID_DEFAULT,
    /** Name of team member. */
    public name = ''
  ) {}
}

/** A team member with a set of goals. */
export type StatGoals = StatInfo & Record<StatType, number>;
/** A team member with a set of hourly stats. */
export type StatHistory = StatInfo & Record<StatType, number[]>;
/** A team member with a stat summary. */
export type StatTotal = StatInfo & Record<StatType, Stat>;

/** Create a stat mapping. */
export function userStats<T>(n: T): Record<StatType, T> {
  return {
    [StatType.AccountsDistinctWorked]:  deepCopy(n),
    [StatType.AccountsTotalWorked]:     deepCopy(n),
    [StatType.CallsInbound]:            deepCopy(n),
    [StatType.CallsOutbound]:           deepCopy(n),
    [StatType.CallsTotal]:              deepCopy(n),
    [StatType.Contacts]:                deepCopy(n),
    [StatType.EmailsReceived]:          deepCopy(n),
    [StatType.EmailsSent]:              deepCopy(n),
    [StatType.FollowUpsScheduled]:      deepCopy(n),
    [StatType.LettersProduced]:         deepCopy(n),
    [StatType.LettersSent]:             deepCopy(n),
    [StatType.MeetingsInhouse]:         deepCopy(n),
    [StatType.Promises]:                deepCopy(n),
    [StatType.PromisesHonored]:         deepCopy(n),
    [StatType.TextsSent]:               deepCopy(n)
  };
}