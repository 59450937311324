import { Component } from '@angular/core';
import { JobType } from '../../../../../../../../common/model/job/payload';
import { JobAccessor } from '../../edit/setup-job-edit.model';

@Component({
  selector: 'app-setup-job-payload-import',
  templateUrl: './setup-job-payload-import.component.html',
  styleUrls: ['./setup-job-payload-import.component.scss']
})
export class SetupJobPayloadImportComponent extends JobAccessor<JobType.Import> {}
