import { Component } from '@angular/core';
import { DialogService } from '../../../common/component/dialog/dialog.service';

@Component({
  selector: 'app-demo-dialog',
  templateUrl: './demo-dialog.component.html',
  styleUrls: ['./demo-dialog.component.scss']
})
export class DemoDialogComponent {
  /** Result received from dialog. */
  result = 'undefined';

  static title() {
    return 'Dialog';
  }

  constructor(
    public dialog: DialogService
  ) { }

  async confirm() {
    let result = await this.dialog.confirm('This is a test of the confirmation dialog.', 'Dialog Test');
    this.result = result === undefined ? 'undefined' : JSON.stringify(result);
  }
}
