import { AccountGetRequest, AccountGetResponse, AccountPatchRequest, AccountPatchResponse, AccountPostRequest, AccountPostResponse } from "../message/account";
import { AddressGetRequest, AddressGetResponse, AddressPostRequest, AddressPostResponse, AddressPreviewGetRequest, AddressPreviewGetResponse } from "../message/address";
import { AttachmentDeleteRequest, AttachmentDeleteResponse, AttachmentGetRequest, AttachmentGetResponse, AttachmentPatchOrderRequest, AttachmentPatchOrderResponse, AttachmentPatchRequest, AttachmentPatchResponse, AttachmentPostRequest, AttachmentPostResponse, AttachmentPreviewGetRequest, AttachmentPreviewGetResponse } from "../message/attachment";
import { AuthLoginPostRequest, AuthLoginPostResponse, AuthLogoutPostResponse, AuthResetPasswordPostRequest, AuthResetPasswordPostResponse, AuthSessionGetResponse } from "../message/auth";
import { CardGetRequest, CardGetResponse } from "../message/card";
import { ClaimAttachmentDeleteRequest, ClaimAttachmentDeleteResponse, ClaimAttachmentPatchOrderRequest, ClaimAttachmentPatchOrderResponse, ClaimAttachmentPostRequest, ClaimAttachmentPostResponse, ClaimCountGetRequest, ClaimCountGetResponse, ClaimDeleteRequest, ClaimDeleteResponse, ClaimDocumentTemplatePostRequest, ClaimDocumentTemplatePostResponse, ClaimGetRequest, ClaimGetResponse, ClaimListPostRequest, ClaimListPostResponse, ClaimPatchRequest, ClaimPatchResponse, ClaimPostRequest, ClaimPostResponse, ClaimPreviewGetRequest, ClaimPreviewGetResponse, ClaimReportPostRequest, ClaimStatPostRequest, ClaimStatPostResponse } from "../message/claim";
import { ClaimFilterDeleteRequest, ClaimFilterDeleteResponse, ClaimFilterGetRequest, ClaimFilterGetResponse, ClaimFilterPostRequest, ClaimFilterPostResponse, ClaimFilterPreviewGetRequest, ClaimFilterPreviewGetResponse } from "../message/claim-filter";
import { CodeTypeDeleteRequest, CodeTypeDeleteResponse, CodeTypeGetRequest, CodeTypeGetResponse, CodeTypePostRequest, CodeTypePostResponse, CodeTypePreviewGetRequest, CodeTypePreviewGetResponse } from "../message/code-type";
import { CommentPatchRequest, CommentPatchResponse } from "../message/comment";
import { DatabaseDropGetRequest, DatabaseDropGetResponse, DatabaseDumpGetRequest, DatabaseDumpGetResponse, DatabaseRestoreGetRequest, DatabaseRestoreGetResponse, DatabaseSetupGetRequest, DatabaseSetupGetResponse } from "../message/database";
import { DebugEchoGetRequest, DebugEchoGetResponse, DebugEmailPostRequest, DebugEmailPostResponse, DebugEmailPreviewGetRequest, DebugEmailPreviewGetResponse, DebugJsonGetRequest, DebugJsonGetResponse, DebugPaginateGetRequest, DebugPaginateGetResponse, DebugPostmanGetRequest, DebugPostmanGetResponse } from "../message/debug";
import { DisputeLedgerPostRequest, DisputeLedgerPostResponse, DisputePatchRequest, DisputePatchResponse, DisputePostRequest, DisputePostResponse } from "../message/dispute";
import { DocumentTemplateBuildPostRequest, DocumentTemplateBuildPostResponse, DocumentTemplateDeleteRequest, DocumentTemplateDeleteResponse, DocumentTemplateGetRequest, DocumentTemplateGetResponse, DocumentTemplatePostRequest, DocumentTemplatePostResponse, DocumentTemplatePreviewGetRequest, DocumentTemplatePreviewGetResponse } from "../message/document-template";
import { DocumentationBuildGetRequest, DocumentationBuildGetResponse } from "../message/documentation";
import { EventGetRequest, EventGetResponse, EventPatchRequest, EventPatchResponse, EventPostRequest, EventPostResponse } from "../message/event";
import { FileConvertPostRequest, FileConvertPostResponse } from "../message/file";
import { FilterRuleDeleteRequest, FilterRuleDeleteResponse, FilterRuleGetRequest, FilterRuleGetResponse, FilterRulePostRequest, FilterRulePostResponse, FilterRulePreviewGetRequest, FilterRulePreviewGetResponse } from "../message/filter-rule";
import { FormDeleteRequest, FormDeleteResponse, FormGetRequest, FormGetResponse, FormPostRequest, FormPostResponse, FormPreviewGetRequest, FormPreviewGetResponse } from "../message/form";
import { FormListDeleteRequest, FormListDeleteResponse, FormListGetRequest, FormListGetResponse, FormListPostRequest, FormListPostResponse, FormListPreviewGetRequest, FormListPreviewGetResponse } from "../message/form-list";
import { FormulaDeleteRequest, FormulaDeleteResponse, FormulaGetRequest, FormulaGetResponse, FormulaPostRequest, FormulaPostResponse, FormulaPreviewGetRequest, FormulaPreviewGetResponse, FormulaTestPostRequest, FormulaTestPostResponse } from "../message/formula";
import { ImportCleanupDeleteRequest, ImportCleanupDeleteResponse, ImportConfigGetRequest, ImportConfigGetResponse, ImportConfigPostRequest, ImportConfigPostResponse, ImportPostRequest, ImportPostResponse } from "../message/import";
import { InstitutionDeleteRequest, InstitutionDeleteResponse, InstitutionGetRequest, InstitutionGetResponse, InstitutionPostRequest, InstitutionPostResponse, InstitutionPreviewGetRequest, InstitutionPreviewGetResponse } from "../message/instititution";
import { JobDeleteRequest, JobDeleteResponse, JobGetRequest, JobGetResponse, JobPostRequest, JobPostResponse, JobPreviewGetRequest, JobPreviewGetResponse } from "../message/job";
import { LedgerConfigDeleteRequest, LedgerConfigDeleteResponse, LedgerConfigGetRequest, LedgerConfigGetResponse, LedgerConfigPostRequest, LedgerConfigPostResponse, LedgerConfigPreviewGetRequest, LedgerConfigPreviewGetResponse } from "../message/ledger-config";
import { MemberGetRequest, MemberGetResponse, MemberPatchRequest, MemberPatchResponse, MemberPreviewGetRequest, MemberPreviewGetResponse } from "../message/member";
import { ModelDeleteRequest, ModelDeleteResponse, ModelGetRequest, ModelGetResponse, ModelPostRequest, ModelPostResponse, ModelPreviewGetRequest, ModelPreviewGetResponse } from "../message/model";
import { NotificationUnsubscribeGetRequest, NotificationUnsubscribeGetResponse } from "../message/notifications";
import { OrganizationGetRequest, OrganizationGetResponse, OrganizationPreviewGetRequest, OrganizationPreviewGetResponse, OrganizationSystemGetRequest, OrganizationSystemGetResponse } from "../message/organization";
import { ProfileDeleteRequest, ProfileDeleteResponse, ProfileGetRequest, ProfileGetResponse, ProfilePostRequest, ProfilePostResponse, ProfilePreviewGetRequest, ProfilePreviewGetResponse } from "../message/profile";
import { ResourceRenamePatchRequest, ResourceRenamePatchResponse } from "../message/resource";
import { RevisionGetRequest, RevisionGetResponse } from "../message/revision";
import { SettingGroupGetRequest, SettingGroupGetResponse, SettingGroupPostRequest, SettingGroupPostResponse, SettingGroupPreviewGetRequest, SettingGroupPreviewGetResponse } from "../message/setting-group";
import { SetupInstitutionPostRequest, SetupInstitutionPostResponse, SetupOrganizationPostRequest, SetupOrganizationPostResponse, SetupUserPostRequest, SetupUserPostResponse } from "../message/setup";
import { SystemCapabilitiesGetRequest, SystemCapabilitiesGetResponse, SystemVersionGetRequest, SystemVersionGetResponse } from "../message/system";
import { TableDeleteRequest, TableDeleteResponse, TableGetRequest, TableGetResponse, TablePostRequest, TablePostResponse, TablePreviewGetRequest, TablePreviewGetResponse } from "../message/table";
import { TaskDeleteRequest, TaskDeleteResponse, TaskGetRequest, TaskGetResponse, TaskPostRequest, TaskPostResponse, TaskPreviewGetRequest, TaskPreviewGetResponse } from "../message/task";
import { TransactionGetRequest, TransactionGetResponse, TransactionPostRequest, TransactionPostResponse } from "../message/transaction";
import { TriggerDeleteRequest, TriggerDeleteResponse, TriggerGetRequest, TriggerGetResponse, TriggerPostRequest, TriggerPostResponse, TriggerPreviewGetRequest, TriggerPreviewGetResponse } from "../message/trigger";
import { UserDeleteRequest, UserDeleteResponse, UserGetRequest, UserGetResponse, UserPostRequest, UserPostResponse, UserPreviewGetRequest, UserPreviewGetResponse, UserResetPasswordPatchRequest, UserResetPasswordPatchResponse } from "../message/user";
import { WorkItemGetRequest, WorkItemGetResponse, WorkItemNextPatchRequest, WorkItemNextPatchResponse, WorkItemPatchRequest, WorkItemPatchResponse, WorkItemPostRequest, WorkItemPostResponse, WorkItemQueueGetRequest, WorkItemQueueGetResponse } from "../message/work/item";
import { WorkQueueAssignedGetRequest, WorkQueueAssignedGetResponse, WorkQueueBuildPostRequest, WorkQueueBuildPostResponse, WorkQueueClearPatchRequest, WorkQueueClearPatchResponse, WorkQueueDeleteRequest, WorkQueueDeleteResponse, WorkQueueFilterRulesGetRequest, WorkQueueFilterRulesGetResponse, WorkQueueGetRequest, WorkQueueGetResponse, WorkQueuePostRequest, WorkQueuePostResponse, WorkQueuePrebuildPostRequest, WorkQueuePrebuildPostResponse, WorkQueuePreviewGetRequest, WorkQueuePreviewGetResponse, WorkQueueRefreshPatchRequest, WorkQueueRefreshPatchResponse } from "../message/work/queue";
import { WorkflowDeleteRequest, WorkflowDeleteResponse, WorkflowGetRequest, WorkflowGetResponse, WorkflowPostRequest, WorkflowPostResponse, WorkflowPreviewGetRequest, WorkflowPreviewGetResponse } from "../message/workflow";

/**
 * When adding a new endpoint to the API, it must be added in 2 places:
 *
 * 1) Add your request/response types to the interfaces in this file.
 * 2) Add your new controller to the CONTROLLERS array in controllers.ts.
 *
 * If set up correctly, you will get type-checking on your server method and Angular calls.
 */

/** Valid methods when accessing API. */
export enum RouteMethod {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete'
}

/** List of all endpoints. */
export type RouteAllPath = RouteGetPath | RoutePostPath | RoutePatchPath | RouteDeletePath;
/** List of all GET endpoints. */
export type RouteGetPath = keyof RouteGetRequest;
/** List of all POST endpoints. */
export type RoutePostPath = keyof RoutePostRequest;
/** List of all PATCH endpoints. */
export type RoutePatchPath = keyof RoutePatchRequest;
/** List of all DELETE endpoints. */
export type RouteDeletePath = keyof RouteDeleteRequest;
/** List of all GET endpoints for previewing resources. */
export type RoutePreviewPath = keyof RouteGetRequest;

/** Type that each GET endpoint accepts. */
export interface RouteGetRequest {
  '': undefined
  'accounts': AccountGetRequest
  'addresses': AddressGetRequest
  'addresses/preview': AddressPreviewGetRequest
  'attachments': AttachmentGetRequest
  'attachments/preview': AttachmentPreviewGetRequest
  'auth/session': undefined
  'cards': CardGetRequest
  'claim-filters': ClaimFilterGetRequest
  'claim-filters/preview': ClaimFilterPreviewGetRequest
  'claims': ClaimGetRequest
  'claims/count': ClaimCountGetRequest
  'claims/preview': ClaimPreviewGetRequest
  'code-types': CodeTypeGetRequest
  'code-types/preview': CodeTypePreviewGetRequest
  'database/drop': DatabaseDropGetRequest
  'database/dump': DatabaseDumpGetRequest
  'database/restore': DatabaseRestoreGetRequest
  'database/setup': DatabaseSetupGetRequest
  'debug/echo': DebugEchoGetRequest
  'debug/emails/preview': DebugEmailPreviewGetRequest
  'debug/json': DebugJsonGetRequest
  'debug/paginate': DebugPaginateGetRequest
  'debug/postman': DebugPostmanGetRequest
  'document-templates': DocumentTemplateGetRequest
  'document-templates/preview': DocumentTemplatePreviewGetRequest
  'documentation/build': DocumentationBuildGetRequest
  'events': EventGetRequest
  'filter-rules': FilterRuleGetRequest
  'filter-rules/preview': FilterRulePreviewGetRequest
  'form-lists': FormListGetRequest
  'form-lists/preview': FormListPreviewGetRequest
  'forms': FormGetRequest
  'forms/preview': FormPreviewGetRequest
  'formulas': FormulaGetRequest
  'formulas/preview': FormulaPreviewGetRequest
  'import/config': ImportConfigGetRequest
  'institutions': InstitutionGetRequest
  'institutions/preview': InstitutionPreviewGetRequest
  'jobs': JobGetRequest
  'jobs/preview': JobPreviewGetRequest
  'ledger-configs': LedgerConfigGetRequest
  'ledger-configs/preview': LedgerConfigPreviewGetRequest
  'members': MemberGetRequest
  'members/preview': MemberPreviewGetRequest
  'models': ModelGetRequest
  'models/preview': ModelPreviewGetRequest
  'notifications/unsubscribe': NotificationUnsubscribeGetRequest
  'organizations': OrganizationGetRequest
  'organizations/preview': OrganizationPreviewGetRequest
  'organizations/system': OrganizationSystemGetRequest
  'profiles': ProfileGetRequest
  'profiles/preview': ProfilePreviewGetRequest
  'revisions': RevisionGetRequest
  'setting-groups': SettingGroupGetRequest
  'setting-groups/preview': SettingGroupPreviewGetRequest
  'system/capabilities': SystemCapabilitiesGetRequest
  'system/version': SystemVersionGetRequest
  'tables': TableGetRequest
  'tables/preview': TablePreviewGetRequest
  'tasks': TaskGetRequest
  'tasks/preview': TaskPreviewGetRequest
  'transactions': TransactionGetRequest
  'triggers': TriggerGetRequest
  'triggers/preview': TriggerPreviewGetRequest
  'users': UserGetRequest
  'users/preview': UserPreviewGetRequest
  'work-items': WorkItemGetRequest
  'work-items/queue': WorkItemQueueGetRequest
  'work-queues': WorkQueueGetRequest
  'work-queues/assigned': WorkQueueAssignedGetRequest
  'work-queues/filter-rules': WorkQueueFilterRulesGetRequest
  'work-queues/preview': WorkQueuePreviewGetRequest
  'workflows': WorkflowGetRequest
  'workflows/preview': WorkflowPreviewGetRequest
}

/** Type that each GET endpoint returns. */
export interface RouteGetResponse {
  '': string
  'accounts': AccountGetResponse
  'addresses': AddressGetResponse
  'addresses/preview': AddressPreviewGetResponse
  'attachments': AttachmentGetResponse
  'attachments/preview': AttachmentPreviewGetResponse
  'auth/session': AuthSessionGetResponse
  'cards': CardGetResponse
  'claim-filters': ClaimFilterGetResponse
  'claim-filters/preview': ClaimFilterPreviewGetResponse
  'claims': ClaimGetResponse
  'claims/count': ClaimCountGetResponse
  'claims/preview': ClaimPreviewGetResponse
  'code-types': CodeTypeGetResponse
  'code-types/preview': CodeTypePreviewGetResponse
  'database/drop': DatabaseDropGetResponse
  'database/dump': DatabaseDumpGetResponse
  'database/restore': DatabaseRestoreGetResponse
  'database/setup': DatabaseSetupGetResponse
  'debug/echo': DebugEchoGetResponse
  'debug/emails/preview': DebugEmailPreviewGetResponse
  'debug/json': DebugJsonGetResponse
  'debug/paginate': DebugPaginateGetResponse
  'debug/postman': DebugPostmanGetResponse
  'document-templates': DocumentTemplateGetResponse
  'document-templates/preview': DocumentTemplatePreviewGetResponse
  'documentation/build': DocumentationBuildGetResponse
  'events': EventGetResponse
  'filter-rules': FilterRuleGetResponse
  'filter-rules/preview': FilterRulePreviewGetResponse
  'form-lists': FormListGetResponse
  'form-lists/preview': FormListPreviewGetResponse
  'forms': FormGetResponse
  'forms/preview': FormPreviewGetResponse
  'formulas': FormulaGetResponse
  'formulas/preview': FormulaPreviewGetResponse
  'import/config': ImportConfigGetResponse
  'institutions': InstitutionGetResponse
  'institutions/preview': InstitutionPreviewGetResponse
  'jobs': JobGetResponse
  'jobs/preview': JobPreviewGetResponse
  'ledger-configs': LedgerConfigGetResponse
  'ledger-configs/preview': LedgerConfigPreviewGetResponse
  'members': MemberGetResponse
  'members/preview': MemberPreviewGetResponse
  'models': ModelGetResponse
  'models/preview': ModelPreviewGetResponse
  'notifications/unsubscribe': NotificationUnsubscribeGetResponse
  'organizations': OrganizationGetResponse
  'organizations/preview': OrganizationPreviewGetResponse
  'organizations/system': OrganizationSystemGetResponse
  'profiles': ProfileGetResponse
  'profiles/preview': ProfilePreviewGetResponse
  'revisions': RevisionGetResponse
  'setting-groups': SettingGroupGetResponse
  'setting-groups/preview': SettingGroupPreviewGetResponse
  'system/capabilities': SystemCapabilitiesGetResponse
  'system/version': SystemVersionGetResponse
  'tables': TableGetResponse
  'tables/preview': TablePreviewGetResponse
  'tasks': TaskGetResponse
  'tasks/preview': TaskPreviewGetResponse
  'transactions': TransactionGetResponse
  'triggers': TriggerGetResponse
  'triggers/preview': TriggerPreviewGetResponse
  'users': UserGetResponse
  'users/preview': UserPreviewGetResponse
  'work-items': WorkItemGetResponse
  'work-items/queue': WorkItemQueueGetResponse
  'work-queues': WorkQueueGetResponse
  'work-queues/assigned': WorkQueueAssignedGetResponse
  'work-queues/filter-rules': WorkQueueFilterRulesGetResponse
  'work-queues/preview': WorkQueuePreviewGetResponse
  'workflows': WorkflowGetResponse
  'workflows/preview': WorkflowPreviewGetResponse
}

/** Type that each POST endpoint accepts. */
export interface RoutePostRequest {
  'accounts': AccountPostRequest
  'addresses': AddressPostRequest
  'attachments': AttachmentPostRequest
  'auth/login': AuthLoginPostRequest
  'auth/logout': undefined
  'auth/reset-password': AuthResetPasswordPostRequest
  'claim-filters': ClaimFilterPostRequest
  'claims': ClaimPostRequest
  'claims/attachments': ClaimAttachmentPostRequest
  'claims/document-template': ClaimDocumentTemplatePostRequest
  'claims/list': ClaimListPostRequest
  'claims/report': ClaimReportPostRequest
  'claims/stat': ClaimStatPostRequest
  'code-types': CodeTypePostRequest
  'debug/emails': DebugEmailPostRequest
  'disputes': DisputePostRequest
  'disputes/ledgers': DisputeLedgerPostRequest
  'document-templates': DocumentTemplatePostRequest
  'document-templates/build': DocumentTemplateBuildPostRequest
  'events': EventPostRequest
  'files/convert': FileConvertPostRequest
  'filter-rules': FilterRulePostRequest
  'form-lists': FormListPostRequest
  'forms': FormPostRequest
  'formulas': FormulaPostRequest
  'formulas/test': FormulaTestPostRequest
  'import': ImportPostRequest
  'import/config': ImportConfigPostRequest
  'institutions': InstitutionPostRequest
  'jobs': JobPostRequest
  'ledger-configs': LedgerConfigPostRequest
  'models': ModelPostRequest
  'profiles': ProfilePostRequest
  'setting-groups': SettingGroupPostRequest
  'setup/institutions': SetupInstitutionPostRequest
  'setup/organizations': SetupOrganizationPostRequest
  'setup/users': SetupUserPostRequest
  'tables': TablePostRequest
  'tasks': TaskPostRequest
  'transactions': TransactionPostRequest
  'triggers': TriggerPostRequest
  'users': UserPostRequest
  'work-items': WorkItemPostRequest
  'work-queues': WorkQueuePostRequest
  'work-queues/build': WorkQueueBuildPostRequest
  'work-queues/prebuild': WorkQueuePrebuildPostRequest
  'workflows': WorkflowPostRequest
}

/** Type that each POST endpoint returns. */
export interface RoutePostResponse { 
  'accounts': AccountPostResponse
  'addresses': AddressPostResponse
  'attachments': AttachmentPostResponse
  'auth/login': AuthLoginPostResponse
  'auth/logout': AuthLogoutPostResponse
  'auth/reset-password': AuthResetPasswordPostResponse
  'claim-filters': ClaimFilterPostResponse
  'claims': ClaimPostResponse
  'claims/attachments': ClaimAttachmentPostResponse
  'claims/document-template': ClaimDocumentTemplatePostResponse
  'claims/list': ClaimListPostResponse
  'claims/report': ClaimGetResponse
  'claims/stat': ClaimStatPostResponse
  'code-types': CodeTypePostResponse
  'debug/emails': DebugEmailPostResponse
  'disputes': DisputePostResponse
  'disputes/ledgers': DisputeLedgerPostResponse
  'document-templates': DocumentTemplatePostResponse
  'document-templates/build': DocumentTemplateBuildPostResponse
  'events': EventPostResponse
  'files/convert': FileConvertPostResponse
  'filter-rules': FilterRulePostResponse
  'form-lists': FormListPostResponse
  'forms': FormPostResponse
  'formulas': FormulaPostResponse
  'formulas/test': FormulaTestPostResponse
  'import': ImportPostResponse
  'import/config': ImportConfigPostResponse
  'institutions': InstitutionPostResponse
  'jobs': JobPostResponse
  'ledger-configs': LedgerConfigPostResponse
  'models': ModelPostResponse
  'profiles': ProfilePostResponse
  'setting-groups': SettingGroupPostResponse
  'setup/institutions': SetupInstitutionPostResponse
  'setup/organizations': SetupOrganizationPostResponse
  'setup/users': SetupUserPostResponse
  'tables': TablePostResponse
  'tasks': TaskPostResponse
  'transactions': TransactionPostResponse
  'triggers': TriggerPostResponse
  'users': UserPostResponse
  'work-items': WorkItemPostResponse
  'work-queues': WorkQueuePostResponse
  'work-queues/build': WorkQueueBuildPostResponse
  'work-queues/prebuild': WorkQueuePrebuildPostResponse
  'workflows': WorkflowPostResponse
}

/** Type that each PATCH endpoint accepts. */
export interface RoutePatchRequest {
  'accounts': AccountPatchRequest
  'attachments': AttachmentPatchRequest
  'attachments/order': AttachmentPatchOrderRequest
  'claims': ClaimPatchRequest
  'claims/attachments/order': ClaimAttachmentPatchOrderRequest
  'comments': CommentPatchRequest
  'disputes': DisputePatchRequest
  'events': EventPatchRequest
  'resources/rename': ResourceRenamePatchRequest
  'members': MemberPatchRequest
  'users/reset-password': UserResetPasswordPatchRequest
  'work-items': WorkItemPatchRequest
  'work-items/next': WorkItemNextPatchRequest
  'work-queues/clear': WorkQueueClearPatchRequest
  'work-queues/refresh': WorkQueueRefreshPatchRequest
}

/** Type that each PATCH endpoint returns. */
export interface RoutePatchResponse {
  'accounts': AccountPatchResponse
  'attachments': AttachmentPatchResponse
  'attachments/order': AttachmentPatchOrderResponse
  'claims': ClaimPatchResponse
  'claims/attachments/order': ClaimAttachmentPatchOrderResponse
  'comments': CommentPatchResponse
  'disputes': DisputePatchResponse
  'events': EventPatchResponse
  'resources/rename': ResourceRenamePatchResponse
  'members': MemberPatchResponse
  'users': UserPostResponse
  'users/reset-password': UserResetPasswordPatchResponse
  'work-items': WorkItemPatchResponse
  'work-items/next': WorkItemNextPatchResponse
  'work-queues/clear': WorkQueueClearPatchResponse
  'work-queues/refresh': WorkQueueRefreshPatchResponse
}

/** Type that each DELETE endpoint accepts. */
export interface RouteDeleteRequest {
  'attachments': AttachmentDeleteRequest
  'claim-filters': ClaimFilterDeleteRequest
  'claims': ClaimDeleteRequest
  'claims/attachments': ClaimAttachmentDeleteRequest
  'code-types': CodeTypeDeleteRequest
  'document-templates': DocumentTemplateDeleteRequest
  'filter-rules': FilterRuleDeleteRequest
  'form-lists': FormListDeleteRequest
  'forms': FormDeleteRequest
  'formulas': FormulaDeleteRequest
  'import/cleanup': ImportCleanupDeleteRequest
  'institutions': InstitutionDeleteRequest
  'jobs': JobDeleteRequest
  'ledger-configs': LedgerConfigDeleteRequest
  'models': ModelDeleteRequest
  'profiles': ProfileDeleteRequest
  'tables': TableDeleteRequest
  'tasks': TaskDeleteRequest
  'triggers': TriggerDeleteRequest
  'users': UserDeleteRequest
  'work-queues': WorkQueueDeleteRequest
  'workflows': WorkflowDeleteRequest
}

/** Type that each DELETE endpoint returns. */
export interface RouteDeleteResponse {
  'attachments': AttachmentDeleteResponse
  'claim-filters': ClaimFilterDeleteResponse
  'claims': ClaimDeleteResponse
  'claims/attachments': ClaimAttachmentDeleteResponse
  'code-types': CodeTypeDeleteResponse
  'document-templates': DocumentTemplateDeleteResponse
  'filter-rules': FilterRuleDeleteResponse
  'form-lists': FormListDeleteResponse
  'forms': FormDeleteResponse
  'formulas': FormulaDeleteResponse
  'import/cleanup': ImportCleanupDeleteResponse
  'institutions': InstitutionDeleteResponse
  'jobs': JobDeleteResponse
  'ledger-configs': LedgerConfigDeleteResponse
  'models': ModelDeleteResponse
  'profiles': ProfileDeleteResponse
  'tables': TableDeleteResponse
  'tasks': TaskDeleteResponse
  'triggers': TriggerDeleteResponse
  'users': UserDeleteResponse
  'work-queues': WorkQueueDeleteResponse
  'workflows': WorkflowDeleteResponse
}
