import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ColorCode } from '../../../../../../common/toolbox/color';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'elevation-high',
    '[attr.color]': 'color',
    '(mouseleave)': 'onMouseLeave($event)'
  }
})
export class TooltipComponent {

  /** Current displayed color. */
  color?: ColorCode;
  /** Message to display in tooltip. */
  message = '';

  constructor(
    public changes: ChangeDetectorRef
  ) {}
}
