import { Box } from "../../box";
import { DisplayType } from "../../display";
import { Pos } from "../../pos";
import { BuiltinForm } from "../form";
import { AccountFormula } from "../formula/account";
import { MemberFormula } from "../formula/member";
import { builtinFormField } from "./form";

/** Built-in general forms. */
export enum AccountForm {
  Account        = 'Account',
  Disputes       = 'Account (Disputes)',
  Intake         = 'Account - Intake',
  MemberDisputes = 'Account - Member (Disputes)',
  Member         = 'Account - Member',
  Search         = 'Account - Search',
  Setup          = 'Account - Setup'
}

/** List of builtin forms. */
export const BUILTIN_ACCOUNT_FORMS: Record<AccountForm, BuiltinForm> = {
  [AccountForm.Account]: {
    fields: [
      builtinFormField('account.description', { box: new Box(0, 0, 1, 0) }),
      builtinFormField(`formula.${AccountFormula.Number}`, { box: new Box(2, 0, 2, 0) }),

      builtinFormField('account.cards.status', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('account.paymentDueDate', { name: 'Due Date', box: new Box(1, 1, 1, 1) }),
      builtinFormField('account.openDate', { name: 'Original Loan Date', box: new Box(2, 1, 2, 1) }),

      builtinFormField('account.balance', { box: new Box(0, 2, 0, 2) }),
      builtinFormField(`formula.${AccountFormula.DaysLate}`, { box: new Box(1, 2, 1, 2) }),
      builtinFormField('account.type', { box: new Box(2, 2, 2, 2) }),

      builtinFormField('account.pastDueAmount', { name: 'Delinquent Amount', box: new Box(0, 3, 0, 3) }),
      builtinFormField(`formula.${AccountFormula.PaymentsMissed}`, { name: 'Payments Missed', box: new Box(1, 3, 1, 3) }),
      builtinFormField('account.originalBalance', { name: 'Original Loan', box: new Box(2, 3, 2, 3) }),

      builtinFormField('account.lateFees', { name: 'Fees', box: new Box(0, 4, 0, 4) }),
      builtinFormField(`formula.${AccountFormula.LatePeriods}`, { name: '30 / 60 / 90', box: new Box(1, 4, 1, 4) }),
      builtinFormField('account.branch', { box: new Box(2, 4, 2, 4) }),

      builtinFormField(`formula.${AccountFormula.TotalDelinquentAmount}`, { name: 'Total Delinquent Amount', box: new Box(0, 5, 0, 5) }),
      builtinFormField('account.nextPaymentAmount', { name: 'Next Payment Amount', box: new Box(1, 5, 1, 5) }),
      builtinFormField(`formula.${AccountFormula.WarningCodes}`, { name: 'Host Warning Codes', box: new Box(2, 5, 2, 6) }),

      builtinFormField('account.lastPaymentDate', { box: new Box(0, 6, 0, 6) }),
      builtinFormField('account.lastPaymentAmount', { name: 'Last Payment Amount', box: new Box(1, 6, 1, 6) })
    ]
  },
  [AccountForm.Disputes]: {
    name: 'Account',
    size: new Pos(2, 3),
    fields: [
      builtinFormField('account.description', { box: new Box(0, 0, 1, 0) }),

      builtinFormField(`formula.${AccountFormula.Number}`, { box: new Box(0, 1, 0, 1) }),
      builtinFormField(`formula.${MemberFormula.Name}`, { box: new Box(1, 1, 1, 1) }),

      builtinFormField('account.balance', { box: new Box(0, 2, 0, 2) }),
      builtinFormField('account.branch', { box: new Box(1, 2, 1, 2) })
    ]
  },
  [AccountForm.Intake]: {
    fields: [
      builtinFormField('account.description', { box: new Box(0, 0, 0, 0) }),
      builtinFormField('account.number', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('card.number', { name: 'Card Number', box: new Box(0, 2, 0, 2) })
    ]
  },
  [AccountForm.Member]: {
    name: {
      bank: 'Account - Customer',
      creditUnion: 'Account - Member'
    },
    fields: [
      builtinFormField(`formula.${MemberFormula.Name}`, { box: new Box(0, 0, 0, 0) }),
      builtinFormField('member.number', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('member.taxId', { box: new Box(0, 2, 0, 2) }),
      builtinFormField('member.birthDate', { box: new Box(0, 3, 0, 3) }),
      builtinFormField('member.relationship', { box: new Box(0, 4, 0, 4) })
    ]
  },
  [AccountForm.MemberDisputes]: {
    name: {
      bank: 'Account - Customer',
      creditUnion: 'Account - Member'
    },
    fields: [
      builtinFormField(`formula.${MemberFormula.Name}`, { box: new Box(0, 0, 0, 0) }),
      builtinFormField('member.number', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('member.taxId', { box: new Box(0, 2, 0, 2) }),
      builtinFormField('member.birthDate', { box: new Box(0, 3, 0, 3) })
    ]
  },
  [AccountForm.Search]: {
    editable: [DisplayType.Account],
    fields: [
      builtinFormField('account.paymentDueDate'),
      builtinFormField('account.lastPaymentDate'),
      builtinFormField('account.cards.status'),
      builtinFormField('account.type'),
      builtinFormField('account.pastDueAmount'),
      builtinFormField('account.balance'),
      builtinFormField('account.openDate'),
      builtinFormField('account.lateFees'),
      builtinFormField('account.paymentsMade'),
      builtinFormField('account.balance'),
      builtinFormField('account.nextPaymentAmount')
    ]
  },
  [AccountForm.Setup]: {
    editable: [DisplayType.Account],
    fields: [
      builtinFormField('account.number', { box: new Box(0, 0, 0, 0) }),
      builtinFormField('account.category', { box: new Box(1, 0, 1, 0) }),
      builtinFormField('account.type', { box: new Box(2, 0, 2, 0) }),
      builtinFormField('account.description', { box: new Box(0, 1, 2, 1) })
    ]
  }
};