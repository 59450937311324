import { CollectionComments } from "../../../../../../../common/model/collection";
import { CommentAction } from "../../../../../../../common/model/comment/action";
import { CommentList } from "../../../../../../../common/model/comment/comment";
import { FusionCollection } from "../../../../../../../common/model/fusion";
import { MaybeId } from "../../../../../../../common/toolbox/id";

/** Data passed to comment dialog. */
export class CommentListDialogData<T extends CommentList = CommentList> {
  constructor(
    /** Item to apply comments to. */
    public value: MaybeId<T>,
    /** Collection being modified. */
    public collection?: CollectionComments<FusionCollection>
  ) {}
};

/** Result of adding/editing comments. */
export type CommentListDialogReturn = CommentAction[];
