import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { CommonCode } from '../../../../../../../common/code/system';
import { AttachmentUpload } from "../../../../../../../common/model/attachment";
import { Dispute } from '../../../../../../../common/model/dispute/dispute';
import { Permission } from '../../../../../../../common/model/permission';
import { errorResponse } from '../../../../../../../common/toolbox/message';
import { DIALOG_DATA, DialogRef } from '../../../../common/component/dialog/dialog.model';
import { AuthService } from '../../../../common/service/auth.service';
import { LogService } from '../../../../common/service/log.service';
import { TriggerService } from '../../../../common/service/trigger.service';
import { ClaimAttachmentListComponent } from '../../../claim/attachment-list/claim-attachment-list.component';
import { TaskMode } from '../../task.model';
import { TaskClaimAttachmentData, TaskClaimAttachmentReturn } from './task-claim-attachment.model';

@Component({
  selector: 'app-task-claim-attachment',
  templateUrl: './task-claim-attachment.component.html',
  styleUrls: ['./task-claim-attachment.component.scss']
})
export class TaskClaimAttachmentComponent {
  readonly CommonCode = CommonCode;
  readonly Permission = Permission;
  readonly TaskMode = TaskMode;

  /** Reference to attachment list within template. */
  @ViewChild(ClaimAttachmentListComponent) list!: ClaimAttachmentListComponent;

  /** True if this is a readonly document view. */
  readonly = false;
  /** Attachments to upload. */
  uploads?: AttachmentUpload[];
  /** Disputes we are attaching this upload to. */
  _disputes?: Dispute[];
  /** Whether we should only view dispute specific attachments. */
  disputesOnly = false;

  /** Get existing event, if applicable. */
  get event() { return this.data.open?.event; }

  constructor(
    @Inject(DIALOG_DATA)
    public data: TaskClaimAttachmentData,
    public auth: AuthService,
    public http: HttpClient,
    protected log: LogService,
    protected triggers: TriggerService,
    private dialogRef: DialogRef<TaskClaimAttachmentReturn>
  ) {
    if ('dispute' in this.data) {
      this._disputes = [this.data.dispute];
      this.disputesOnly = true;
    }
  }

  async onSubmit() {
    // Make new uploads accessible to triggers.
    this.data.partial.uploads = [...this.list.uploads];

    // Post uploads and close.
    let result = await this.list.submit();
    if (errorResponse(result)) return;
    this.dialogRef.close(true);
  }
}
