import { InjectionToken, Type } from "@angular/core";
import { Subject } from "rxjs";
import { ColorCode } from "../../../../../../common/toolbox/color";
import { DIALOG_CANCEL_SYMBOL } from "../../toolbox/dialog";

/** Injection token for accessing data passed to dialog. */
export const DIALOG_DATA = new InjectionToken('DIALOG_DATA');

/** Data returned from closing a dialog. */
export type DialogCloseEvent<R = unknown> = R | typeof DIALOG_CANCEL_SYMBOL;

/** Reference to opened dialog. */
export class DialogRef<R = any> {
  /** Data emitted after dialog is closed. */
  data = new Subject<DialogCloseEvent<R>>();

  constructor(
    /** Component placed inside dialog. */
    public component: Type<any>
  ) { }

  /** Signal dialog to close, returning data. */
  close(data: DialogCloseEvent<R>) {
    this.data.next(data);
  }

  /** Signal that this dialog is closed without completing, e.g. hitting top "x" button or cancel button, or closing due to failure.*/
  cancel() {
    this.close(DIALOG_CANCEL_SYMBOL)
  }
}

/** Configuration for creating new dialog. */
export class DialogConfig {
  /** Title for dialog */
  title?: string;
  /** Color for dialog header. */
  color?: ColorCode;
  /** True to allow exiting dialog. */
  cancel?: boolean;
}
