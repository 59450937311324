import { Component, Input } from '@angular/core';
import { Stat, STAT_TYPE_NAME } from '../../toolbox/stat';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
  host: {
    class: 'row'
  }
})
export class StatsComponent {
  /** Mapping of stat values to names. */
  readonly STAT_TYPE_NAME = STAT_TYPE_NAME;
  /** List of stats to display. */
  @Input() stats: Stat[] = [];
}
