import { TypeValidation } from "../info/type";
import { SchemaProperty } from "../model/schema";
import { ValidatorLog } from "../validator/base";
import { CustomValidator } from "../validator/custom";
import { Range } from "./number";
import { CRON_DEFAULT } from "./time";

/** A cron segment to validate. */
class CronRange extends Range {
  constructor(
    /** Name of segment. */
    public name: string,
    /** Low end of range. */
    low: number,
    /** High end of range. */
    high: number
  ) {
    super(low, high);
  }
}

/** A validator for a statement. */
export class CronValidator extends CustomValidator<string> {

  value() {
    return CRON_DEFAULT;
  }

  parse(text: string) {
    return text;
  }

  schema(): SchemaProperty {
    return { bsonType: 'string' };
  }

  check = cronValidate;
}

/** Segments of a cron pattern to check. */
const CRON_RANGES = [
  new CronRange('second', 0, 59),
  new CronRange('minute', 0, 59),
  new CronRange('hour', 0, 23),
  new CronRange('day', 1, 31),
  new CronRange('month', 1, 12),
  new CronRange('weekday', 0, 7)
];

/** Validate a cron pattern. */
export function cronValidate(cron: string): TypeValidation<string> {
  let logs: ValidatorLog[] = [];
  let segments = cron.split(' ');
  if (segments.length < 5 || segments.length > 6) {
    logs.push(new ValidatorLog(`Invalid number of subexpressions: ${segments.length} (expected 5-6)`));
  }

  // Test each segment.
  let offset = 6 - segments.length;
  for (let i = 0; i < segments.length; ++i) {
    let test = CRON_RANGES[i + offset]!;
    let name = test.name;

    let [, list, range, step, value, any] = segments[i]!.match(/^(?:((?:\d+,)+\d+)|(\d+-\d+)|\*\/(\d+)|(\d+)|(\*))$/) ?? [];
    if (list) {
      let values = list.split(',').map(v => +v);
      for (let v = 0; v < values.length; ++v) {
        if (!Range.has(test, values[v]!)) {
          logs.push(new ValidatorLog(`Invalid ${name} list: ${list} (expected values ${test.low}-${test.high})`));
        }
      }
    } else if (range) {
      let [low, high] = range.split('-').map(v => +v) as [number, number];
      if (!Range.has(test, low) || !Range.has(test, high)) {
        logs.push(new ValidatorLog(`Invalid ${name} range: ${low}-${high} (expected values ${test.low}-${test.high})`));
      }

      if (low > high) {
        logs.push(new ValidatorLog(`Reversed ${name} range: ${low}-${high}`));
      }
    } else if (step) {
      if (+step === 0) {
        logs.push(new ValidatorLog(`Invalid ${name} step: ${step} (expected >=1)`));
      }
    } else if (value) {
      if (!Range.has(test, +value)) {
        logs.push(new ValidatorLog(`Invalid ${name}: ${value} (expected ${test.low}-${test.high})`));
      }
    } else if (!any) {
      logs.push(new ValidatorLog(`Invalid ${name}: ${segments[i]!}`));
    }
  }

  if (!logs.length) return;
  return logs;
}