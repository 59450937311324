import { Box } from "../../box";
import { CLAIM_ACH_REPORT_REASON_CONSUMER_FILTER } from "../../claim/ach";
import { CLAIM_CHECK_REPORT_REASON_FILTER } from "../../claim/check";
import { DisplayType } from "../../display";
import { FieldBoolean } from "../../form/field-config";
import { Pos } from "../../pos";
import { PropertyType } from "../../property-type";
import { BuiltinForm } from "../form";
import { DisputesModel, ModelClaimViewReason } from "../model/disputes";
import { builtinFormField, builtinFormModel } from "./form";

/** Built-in claim forms. */
export enum ClaimForm {
  ACH                                                    = 'Claim - ACH',
  ACHAdvancedReasonNotInAccordance                       = 'Claim - ACH - Advanced - Not In Accordance',
  ACHAdvancedReasonRCKEntryIneligible                    = 'Claim - ACH - Advanced - RCK Entry Ineligible',
  ACHAdvancedReason                                      = 'Claim - ACH - Advanced - Reason',
  ACHIntakeReason                                        = 'Claim - ACH - Intake - Reason',
  ACHIntakeReasonNotInAccordance                         = 'Claim - ACH - Intake - Not In Accordance',
  ACHIntakeReasonRCKEntryIneligible                      = 'Claim - ACH - Intake - RCK Entry Ineligible',
  AdvancedAdditionalDetails                              = 'Claim - Advanced - Additional Details',
  Card                                                   = 'Claim - Card',
  CardAdvancedCancellationCancelProduct                  = 'Claim - Card - Advanced - Cancellation - Cancel Product',
  CardAdvancedCancellationNotExpected                    = 'Claim - Card - Advanced - Cancellation - Not Expected',
  CardAdvancedCancellationNotReceived                    = 'Claim - Card - Advanced - Cancellation - Not Received',
  CardAdvancedCancellationPaidOtherMeans                 = 'Claim - Card - Advanced - Cancellation - Paid Other Means',
  CardAdvancedCancellationReturnedRefused                = 'Claim - Card - Advanced - Cancellation - Returned / Refused',
  CardAdvancedClosed                                     = 'Claim - Card - Advanced - Closed',
  CardAdvancedCloseReason                                = 'Claim - Card - Advanced - Close Reason',
  CardAdvancedPoliceReport                               = 'Claim - Card - Advanced - Police Report',
  CardAdvancedPoliceReportFiled                          = 'Claim - Card - Advanced - Police Report Filed',
  CardAdvancedProcessingErrorAdditionalCharge            = 'Claim - Card - Advanced - Processing Error - Additional Charge',
  CardAdvancedProcessingErrorComputationalError          = 'Claim - Card - Advanced - Processing Error - Computational Error',
  CardAdvancedProcessingErrorCreditNotReceived           = 'Claim - Card - Advanced - Processing Error - Credit Not Received',
  CardAdvancedProcessingErrorDuplicateTransaction        = 'Claim - Card - Advanced - Processing Error - Duplicate Transaction',
  CardAdvancedProcessingErrorIncorrectTransactionAmount  = 'Claim - Card - Advanced - Processing Error - Incorrect Transaction Amount',
  CardAdvancedProcessingErrorIncorrectTransactionCode    = 'Claim - Card - Advanced - Processing Error - Incorrect Transaction Code',
  CardAdvancedProcessingErrorLatePresentment             = 'Claim - Card - Advanced - Processing Error - Late Presentment',
  CardAdvancedProcessingErrorUnrecognizedTransaction     = 'Claim - Card - Advanced - Processing Error - Unrecognized Transaction',
  CardAdvancedReason                                     = 'Claim - Card - Advanced - Reason',
  CardAdvancedReasonAtmTransaction                       = 'Claim - Card - Advanced - Reason - ATM Transaction',
  CardAdvancedReasonCancellation                         = 'Claim - Card - Advanced - Reason - Cancellation / Return',
  CardAdvancedReasonFraudAbsent                          = 'Claim - Card - Advanced - Reason - Fraud (Absent)',
  CardAdvancedReasonFraudPresent                         = 'Claim - Card - Advanced - Reason - Fraud (Present)',
  CardAdvancedReasonProcessingError                      = 'Claim - Card - Advanced - Reason - Processing Error',
  CardIntakeCancellationCancelProduct                    = 'Claim - Card - Intake - Cancellation - Cancel Product',
  CardIntakeCancellationNotExpected                      = 'Claim - Card - Intake - Cancellation - Not Expected',
  CardIntakeCancellationNotReceived                      = 'Claim - Card - Intake - Cancellation - Not Received',
  CardIntakeCancellationPaidOtherMeans                   = 'Claim - Card - Intake - Cancellation - Paid Other Means',
  CardIntakeCancellationReturnedRefused                  = 'Claim - Card - Intake - Cancellation - Returned / Refused',
  CardIntakeClosed                                       = 'Claim - Card - Intake - Closed',
  CardIntakeCloseReason                                  = 'Claim - Card - Intake - Close Reason',
  CardIntakePoliceReport                                 = 'Claim - Card - Intake - Police Report',
  CardIntakePoliceReportFiled                            = 'Claim - Card - Intake - Police Report Filed',
  CardIntakeProcessingErrorAdditionalCharge              = 'Claim - Card - Intake - Processing Error - Additional Charge',
  CardIntakeProcessingErrorComputationalError            = 'Claim - Card - Intake - Processing Error - Computational Error',
  CardIntakeProcessingErrorCreditNotReceived             = 'Claim - Card - Intake - Processing Error - Credit Not Received',
  CardIntakeProcessingErrorDuplicateTransaction          = 'Claim - Card - Intake - Processing Error - Duplicate Transaction',
  CardIntakeProcessingErrorIncorrectTransactionAmount    = 'Claim - Card - Intake - Processing Error - Incorrect Transaction Amount',
  CardIntakeProcessingErrorIncorrectTransactionCode      = 'Claim - Card - Intake - Processing Error - Incorrect Transaction Code',
  CardIntakeProcessingErrorLatePresentment               = 'Claim - Card - Intake - Processing Error - Late Presentment',
  CardIntakeProcessingErrorUnrecognizedTransaction       = 'Claim - Card - Intake - Processing Error - Unrecognized Transaction',
  CardIntakeReason                                       = 'Claim - Card - Intake - Reason',
  CardIntakeReasonAtmTransaction                         = 'Claim - Card - Intake - Reason - ATM Transaction',
  CardIntakeReasonCancellation                           = 'Claim - Card - Intake - Reason - Cancellation / Return',
  CardIntakeReasonFraudAbsent                            = 'Claim - Card - Intake - Reason - Fraud (Absent)',
  CardIntakeReasonFraudPresent                           = 'Claim - Card - Intake - Reason - Fraud (Present)',
  CardIntakeReasonProcessingError                        = 'Claim - Card - Intake - Reason - Processing Error',
  CardSearch                                             = 'Claim - Card - Search',
  Check                                                  = 'Claim - Check',
  CheckIntakeReason                                      = 'Claim - Check - Intake - Reason',
  CheckIntakeReasonForgery                               = 'Claim - Check - Intake - Reason - Forgery',
  CheckIntakeReasonUnauthorized                          = 'Claim - Check - Intake - Reason - Unauthorized',
  CreditBureau                                           = 'Claim - Credit Bureau',
  IntakeAdditionalDetails                                = 'Claim - Intake - Additional Details',
  ResolvedReason                                         = 'Claim - Resolved Reason',
  ReturnDate                                             = 'Claim - Return Date',
  Toolbar                                                = 'Claim - Toolbar',
  ViewReason                                             = 'Claim - View Reason'
}

/** List of builtin forms. */
export const BUILTIN_CLAIM_FORMS: Record<ClaimForm, BuiltinForm> = {
  [ClaimForm.ACH]: {
    size: new Pos(3, 2),
    fields: [
      builtinFormField('claim.displayId', { name: 'Claim ID', box: new Box(0, 0, 0, 0) }),
      builtinFormField('claim.reportDate', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.returnDate', { box: new Box(1, 1, 1, 1) }),
      builtinFormField('claim.achDetails.type', { box: new Box(2, 1, 2, 1) })
    ]
  },
  [ClaimForm.ACHAdvancedReasonNotInAccordance]: {
    size: new Pos(1, 4),
    fields: [
      builtinFormField('claim.achDetails.authorizedAmount', { name: 'The amount debited was different from what I authorized. The authorized amount was:' }),
      builtinFormField('claim.achDetails.authorizedDate', { name: 'The date the amount was debited was different from what I authorized. I authorized no earlier than:' }),
      builtinFormField('claim.achDetails.incompleteTransaction', { config: new FieldBoolean(undefined, undefined, 'The intended payee was not credited.') }),
      builtinFormField('claim.achDetails.improperlyReinitiated', { config: new FieldBoolean(undefined, undefined, 'The originator improperly reinitated the listed debit(s).') })
    ]
  },
  [ClaimForm.ACHAdvancedReasonRCKEntryIneligible]: {
    size: new Pos(1, 4),
    fields: [
      builtinFormField('claim.achDetails.policyUnprovided', { config: new FieldBoolean(undefined, undefined, 'The required notice stating the re-presented check entry policy was not provided by the originator.') }),
      builtinFormField('claim.achDetails.checkIneligible', { config: new FieldBoolean(undefined, undefined, 'The check is ineligible.') }),
      builtinFormField('claim.achDetails.signatureIneligible', { config: new FieldBoolean(undefined, undefined, 'All signatures on the check are not authentic or authorized, or the check has been altered.') }),
      builtinFormField('claim.achDetails.amountInaccurate', { config: new FieldBoolean(undefined, undefined, 'The amount of the entry was not accurately obtained from the item.') })
    ]
  },
  [ClaimForm.ACHAdvancedReason]: {
    size: new Pos(2, 2),
    fields: [
      builtinFormField('claim.reportDate', { name: 'When was the claim reported?' }),
      builtinFormField('claim.originator', { box: new Box(1, 0, 1, 0) }),
      builtinFormField('claim.achDetails.type', { box: new Box(0, 1, 1, 1), config: { type: PropertyType.Code, sort: true, prefix: ['(R', ') '], filter: CLAIM_ACH_REPORT_REASON_CONSUMER_FILTER } })
    ]
  },
  [ClaimForm.ACHIntakeReason]: {
    size: new Pos(2, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.reportDate', { name: 'When was the claim reported?' }),
      builtinFormField('claim.originator', { box: new Box(1, 0, 1, 0) }),
      builtinFormField('claim.achDetails.type', { box: new Box(0, 1, 1, 1), config: { type: PropertyType.Code, sort: true, prefix: ['(R', ') '], filter: CLAIM_ACH_REPORT_REASON_CONSUMER_FILTER } })
    ]
  },
  [ClaimForm.ACHIntakeReasonNotInAccordance]: {
    size: new Pos(1, 4),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.achDetails.authorizedAmount', { name: 'The amount debited was different from what I authorized. The authorized amount was:' }),
      builtinFormField('claim.achDetails.authorizedDate', { name: 'The date the amount was debited was different from what I authorized. I authorized no earlier than:' }),
      builtinFormField('claim.achDetails.incompleteTransaction', { config: new FieldBoolean(undefined, undefined,  'The intended payee was not credited. (Incomplete Transaction)') }),
      builtinFormField('claim.achDetails.improperlyReinitiated', { config: new FieldBoolean(undefined, undefined, 'The originator improperly reinitated the listed debit(s).') })
    ]
  },
  [ClaimForm.ACHIntakeReasonRCKEntryIneligible]: {
    size: new Pos(1, 4),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.achDetails.policyUnprovided', { config: new FieldBoolean(undefined, undefined, 'The required notice stating the re-presented check entry policy was not provided by the originator.') }),
      builtinFormField('claim.achDetails.checkIneligible', { config: new FieldBoolean(undefined, undefined, 'The check is ineligible.') }),
      builtinFormField('claim.achDetails.signatureIneligible', { config: new FieldBoolean(undefined, undefined, 'All signatures on the check are not authentic or authorized, or the check has been altered.') }),
      builtinFormField('claim.achDetails.amountInaccurate', { config: new FieldBoolean(undefined, undefined, 'The amount of the entry was not accurately obtained from the item.') })
    ]
  },
  [ClaimForm.AdvancedAdditionalDetails]: {
    size: new Pos(2, 2),
    fields: [
      builtinFormField('claim.additionalDetails', { box: new Box(0, 0, 1, 1) })
    ]
  },
  [ClaimForm.Card]: {
    fields: [
      builtinFormField('claim.displayId', { name: 'Claim ID' }),
      builtinFormField('card.number', { name: 'Card Number' }),
      builtinFormField('claim.reportDate'),
      builtinFormField('claim.cardDetails.type')
    ]
  },
  [ClaimForm.CardAdvancedCancellationCancelProduct]: {
    size: new Pos(2, 2),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I notified the merchant that I wish to cancel the product/service.') }),
      builtinFormField('claim.cardDetails.cancellationDate', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.cardDetails.spokeWith', { box: new Box(1, 1, 1, 1) })
    ]
  },
  [ClaimForm.CardAdvancedCancellationNotExpected]: {
    size: new Pos(2, 4),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'The merchandise was shipped to me but arrived not as expected. I returned it and requested a credit.') }),
      builtinFormField('claim.cardDetails.reasonForReturn', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.cardDetails.dateReturned', { box: new Box(1, 1, 1, 1) }),
      builtinFormField('claim.cardDetails.shippingCompany', { box: new Box(0, 2, 0, 2) }),
      builtinFormField('claim.cardDetails.whatWasPurchased', { name: 'What was purchased?', box: new Box(1, 2, 1, 2) }),
      builtinFormField('claim.cardDetails.merchantResponse', { box: new Box(1, 3, 1, 3) }),
      builtinFormField('claim.cardDetails.merchantContactDate', { box: new Box(0, 3, 0, 3) })
    ]
  },
  [ClaimForm.CardAdvancedCancellationNotReceived]: {
    size: new Pos(2, 2),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I have not received the merchandise/services. (Must be 30 days from authorization date)') }),
      builtinFormField('claim.cardDetails.expectedDeliveryDate', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.cardDetails.merchantContactDate', { box: new Box(1, 1, 1, 1) })
    ]
  },
  [ClaimForm.CardAdvancedCancellationPaidOtherMeans]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'The merchandise/service was paid for with other means.') })
    ]
  },
  [ClaimForm.CardAdvancedCancellationReturnedRefused]: {
    size: new Pos(2, 2),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'The merchandise was returned/refused. I have asked for a refund.') }),
      builtinFormField('claim.cardDetails.dateReturned', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.cardDetails.shippingCompany', { box: new Box(1, 1, 1, 1) })
    ]
  },
  [ClaimForm.CardAdvancedClosed]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.cardClosed', { name: 'I have closed this card.' })
    ]
  },
  [ClaimForm.CardAdvancedCloseReason]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.cardCloseReason', { box: new Box(0, 0, 1, 1) })
    ]
  },
  [ClaimForm.CardAdvancedPoliceReport]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.cardDetails.reportFiled', { config: new FieldBoolean(true, 'Has a police report been filed?') })
    ]
  },
  [ClaimForm.CardAdvancedPoliceReportFiled]: {
    size: new Pos(2, 1),
    fields: [
      builtinFormField('claim.cardDetails.policeCaseNumber', { name: 'Case #' }),
      builtinFormField('claim.cardDetails.policeAgency', { name: 'Agency' })
    ]
  },
  [ClaimForm.CardAdvancedProcessingErrorAdditionalCharge]: {
    size: new Pos(2, 3),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I authorized a transaction from this merchant. I was billed for an additional charge that I did not authorize.') }),
      builtinFormField('claim.cardDetails.unauthorizedAmountDate', { box: new Box(0, 1, 1, 1) }),
      builtinFormField('claim.cardDetails.authorizedAmount', { box: new Box(0, 2, 0, 2) }),
      builtinFormField('claim.cardDetails.unauthorizedAmount', { box: new Box(1, 2, 1, 2) })
    ]
  },
  [ClaimForm.CardAdvancedProcessingErrorComputationalError]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'A computational error made by the financial institution relating to an EFT.') })
    ]
  },
  [ClaimForm.CardAdvancedProcessingErrorCreditNotReceived]: {
    size: new Pos(2, 2),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 0, 0), config: new FieldBoolean(true, 'A credit from the merchant has not been received.') }),
      builtinFormField('claim.cardDetails.creditPromisedDate', { box: new Box(1, 0, 1, 0) }),
      builtinFormField('claim.cardDetails.amountOfCredit', { box: new Box(0, 1, 0, 1) })
    ]
  },
  [ClaimForm.CardAdvancedProcessingErrorDuplicateTransaction]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(undefined, 'The charge was a single transaction. It posted twice to my account.') })
    ]
  },
  [ClaimForm.CardAdvancedProcessingErrorIncorrectTransactionAmount]: {
    size: new Pos(2, 3),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I received an incorrect transaction amount.') }),
      builtinFormField('claim.cardDetails.processingErrorReason', { box: new Box(0, 1, 1, 1) }),
      builtinFormField('claim.cardDetails.salesDraftIndicated'),
      builtinFormField('claim.cardDetails.amountPosted')
    ]
  },
  [ClaimForm.CardAdvancedProcessingErrorIncorrectTransactionCode]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(undefined, 'An incorrect transaction code was present.') })
    ]
  },
  [ClaimForm.CardAdvancedProcessingErrorLatePresentment]: {
    size: new Pos(1, 2),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 0, 0), config: new FieldBoolean(true, 'Late Presentment (120 calendar days)') }),
      builtinFormField('claim.cardDetails.latePresentmentLength', { name: 'When did the transaction occur?', box: new Box(0, 1, 0, 1) })
    ]
  },
  [ClaimForm.CardAdvancedProcessingErrorUnrecognizedTransaction]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'I do not recognize this transaction. A copy of the sales draft is requested.') })
    ]
  },
  [ClaimForm.CardAdvancedReason]: {
    size: new Pos(2, 2),
    fields: [
      builtinFormField('claim.reportDate', { name: 'When was the claim reported?' }),
      builtinFormField('claim.cardLocation', { name: 'At the time of the dispute my card was:', box: new Box(1, 0, 1, 0) }),
      builtinFormField('claim.cardDetails.type', { name: 'What is the reason for disputing these transactions?', box: new Box(0, 1, 1, 1) })
    ]
  },
  [ClaimForm.CardAdvancedReasonAtmTransaction]: {
    size: new Pos(2, 2),
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I received an incorrect amount of cash from an ATM.') }),
      builtinFormField('claim.cardDetails.requestedAmount', { box: new Box(1, 1, 1, 1) }),
      builtinFormField('claim.cardDetails.receivedAmount', { box: new Box(0, 1, 0, 1) })
    ]
  },
  [ClaimForm.CardAdvancedReasonCancellation]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.cardDetails.cancellationOrReturnType', { name: 'What was the reason for the cancellation / return?' })
    ]
  },
  [ClaimForm.CardAdvancedReasonFraudAbsent]: {
    size: new Pos(1, 5),
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'I did not authorize this transaction and have not provided my card number to the merchant. I did not receive any service or merchandise from this transaction.') }),
      builtinFormField('claim.cardDetails.examinedTransactions', { config: new FieldBoolean(true, 'I have examined all unauthorized transactions and confirm I did not authorize, participate or benefit from them.') }),
      builtinFormField('claim.cardDetails.providedCard', { config: new FieldBoolean(true, 'I have not provided my card to anyone to use in the past.') }),
      builtinFormField('claim.cardDetails.proceedsOrBenefits', { config: new FieldBoolean(true, 'I did not receive any proceeds or benefits from any of these transactions.') }),
      builtinFormField('claim.cardDetails.policeReport', { config: new FieldBoolean(true, 'I am willing to file a police report and prosecute anyone involved in these transaction(s).') })
    ]
  },
  [ClaimForm.CardAdvancedReasonFraudPresent]: {
    size: new Pos(2, 1),
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'I did not authorize, participate or benefit from the transaction.') }),
      builtinFormField('claim.cardDetails.locationDate', { name: 'Date Lost / Stolen' })
    ]
  },
  [ClaimForm.CardAdvancedReasonProcessingError]: {
    size: new Pos(1, 1),
    fields: [
      builtinFormField('claim.cardDetails.processingErrorType', { name: 'What type of processing error was experienced?' })
    ]
  },
  [ClaimForm.CardIntakeCancellationCancelProduct]: {
    size: new Pos(2, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I notified the merchant that I wish to cancel the product/service.') }),
      builtinFormField('claim.cardDetails.cancellationDate', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.cardDetails.spokeWith', { box: new Box(1, 1, 1, 1) })
    ]
  },
  [ClaimForm.CardIntakeCancellationNotExpected]: {
    size: new Pos(2, 4),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'The merchandise was shipped to me but arrived not as expected. I returned it and requested a credit.') }),
      builtinFormField('claim.cardDetails.reasonForReturn', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.cardDetails.dateReturned', { box: new Box(1, 1, 1, 1) }),
      builtinFormField('claim.cardDetails.shippingCompany', { box: new Box(0, 2, 0, 2) }),
      builtinFormField('claim.cardDetails.whatWasPurchased', { name: 'What was purchased?', box: new Box(1, 2, 1, 2) }),
      builtinFormField('claim.cardDetails.merchantResponse', { box: new Box(1, 3, 1, 3) }),
      builtinFormField('claim.cardDetails.merchantContactDate', { box: new Box(0, 3, 0, 3) })
    ]
  },
  [ClaimForm.CardIntakeCancellationNotReceived]: {
    size: new Pos(2, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(undefined, 'I have not received the merchandise/services. (Must be 30 days from authorization date)') }),
      builtinFormField('claim.cardDetails.expectedDeliveryDate', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.cardDetails.merchantContactDate', { box: new Box(1, 1, 1, 1) })
    ]
  },
  [ClaimForm.CardIntakeCancellationPaidOtherMeans]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'The merchandise/service was paid for with other means.') })
    ]
  },
  [ClaimForm.CardIntakeCancellationReturnedRefused]: {
    size: new Pos(2, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'The merchandise was returned/refused. I have asked for a refund.') }),
      builtinFormField('claim.cardDetails.dateReturned', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.cardDetails.shippingCompany', { box: new Box(1, 1, 1, 1) })
    ]
  },
  [ClaimForm.CardIntakeClosed]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.cardClosed', { name: 'I have closed this card.' })
    ]
  },
  [ClaimForm.CardIntakeCloseReason]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.cardCloseReason', { box: new Box(0, 0, 1, 1) })
    ]
  },

  [ClaimForm.CardIntakePoliceReport]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.cardDetails.reportFiled', { config: new FieldBoolean(true, 'Has a police report been filed?') })
    ]
  },
  [ClaimForm.CardIntakePoliceReportFiled]: {
    size: new Pos(2, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.cardDetails.policeCaseNumber', { name: 'Case #' }),
      builtinFormField('claim.cardDetails.policeAgency', { name: 'Agency' })
    ]
  },
  [ClaimForm.CardIntakeProcessingErrorAdditionalCharge]: {
    size: new Pos(2, 3),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I authorized a transaction from this merchant. I was billed for an additional charge that I did not authorize.') }),
      builtinFormField('claim.cardDetails.unauthorizedAmountDate', { box: new Box(0, 1, 1, 1) }),
      builtinFormField('claim.cardDetails.authorizedAmount', { box: new Box(0, 2, 0, 2) }),
      builtinFormField('claim.cardDetails.unauthorizedAmount', { box: new Box(1, 2, 1, 2) })
    ]
  },
  [ClaimForm.CardIntakeProcessingErrorComputationalError]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'A computational error made by the financial institution relating to an EFT.') })
    ]
  },
  [ClaimForm.CardIntakeProcessingErrorCreditNotReceived]: {
    size: new Pos(2, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 0, 0), config: new FieldBoolean(true, 'A credit from the merchant has not been received.') }),
      builtinFormField('claim.cardDetails.creditPromisedDate', { box: new Box(1, 0, 1, 0) }),
      builtinFormField('claim.cardDetails.amountOfCredit', { box: new Box(0, 1, 0, 1) })
    ]
  },
  [ClaimForm.CardIntakeProcessingErrorDuplicateTransaction]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'The charge was a single transaction. It posted twice to my account.') })
    ]
  },
  [ClaimForm.CardIntakeProcessingErrorIncorrectTransactionAmount]: {
    size: new Pos(2, 3),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I received an incorrect transaction amount.') }),
      builtinFormField('claim.cardDetails.processingErrorReason', { box: new Box(0, 1, 1, 1) }),
      builtinFormField('claim.cardDetails.salesDraftIndicated'),
      builtinFormField('claim.cardDetails.amountPosted')
    ]
  },
  [ClaimForm.CardIntakeProcessingErrorIncorrectTransactionCode]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'An incorrect transaction code was present.') })
    ]
  },
  [ClaimForm.CardIntakeProcessingErrorLatePresentment]: {
    size: new Pos(1, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 0, 0), config: new FieldBoolean(true, 'Late Presentment (120 calendar days)') }),
      builtinFormField('claim.cardDetails.latePresentmentLength', { name: 'When did the transaction occur?', box: new Box(0, 1, 0, 1) })
    ]
  },
  [ClaimForm.CardIntakeProcessingErrorUnrecognizedTransaction]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'I do not recognize this transaction. A copy of the sales draft is requested.') })
    ]
  },
  [ClaimForm.CardIntakeReason]: {
    size: new Pos(2, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.reportDate', { name: 'When was the claim reported?' }),
      builtinFormField('claim.cardLocation', { name: 'At the time of the dispute my card was:', box: new Box(1, 0, 1, 0) }),
      builtinFormField('claim.cardDetails.type', { name: 'What is the reason for disputing these transactions?', box: new Box(0, 1, 1, 1) })
    ]
  },
  [ClaimForm.CardIntakeReasonAtmTransaction]: {
    size: new Pos(2, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { box: new Box(0, 0, 1, 0), config: new FieldBoolean(true, 'I received an incorrect amount of cash from an ATM.') }),
      builtinFormField('claim.cardDetails.requestedAmount', { box: new Box(1, 1, 1, 1) }),
      builtinFormField('claim.cardDetails.receivedAmount', { box: new Box(0, 1, 0, 1) })
    ]
  },
  [ClaimForm.CardIntakeReasonCancellation]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.cardDetails.cancellationOrReturnType', { config: new FieldBoolean(true, 'What was the reason for the cancellation / return?') })
    ]
  },
  [ClaimForm.CardIntakeReasonFraudAbsent]: {
    size: new Pos(1, 5),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'I did not authorize this transaction and have not provided my card number to the merchant. I did not receive any service or merchandise from this transaction.') }),
      builtinFormField('claim.cardDetails.examinedTransactions', { config: new FieldBoolean(true, 'I have examined all unauthorized transactions and confirm I did not authorize, participate or benefit from them.') }),
      builtinFormField('claim.cardDetails.providedCard', { config: new FieldBoolean(true, 'I have not provided my card to anyone to use in the past.') }),
      builtinFormField('claim.cardDetails.proceedsOrBenefits', { config: new FieldBoolean(true, 'I did not receive any proceeds or benefits from any of these transactions.') }),
      builtinFormField('claim.cardDetails.policeReport', { config: new FieldBoolean(true, 'I am willing to file a police report and prosecute anyone involved in these transaction(s).') })
    ]
  },
  [ClaimForm.CardIntakeReasonFraudPresent]: {
    size: new Pos(2, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.verify', { config: new FieldBoolean(true, 'I did not authorize, participate or benefit from the transaction.') }),
      builtinFormField('claim.cardDetails.locationDate', { name: 'Date Lost / Stolen' })
    ]
  },
  [ClaimForm.CardIntakeReasonProcessingError]: {
    size: new Pos(1, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.cardDetails.processingErrorType', { name: 'What type of processing error was experienced?' })
    ]
  },
  [ClaimForm.CardSearch]: {
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.displayId', { name: 'Claim Number' })
    ]
  },
  [ClaimForm.Toolbar]: {
    fields: [
      builtinFormField('claim.status'),
      builtinFormField('claim._assigned', { name: 'Assigned User' })
    ]
  },
  [ClaimForm.ViewReason]: {
    model: DisputesModel.ClaimViewReason,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelClaimViewReason>('reason'),
      builtinFormModel<ModelClaimViewReason>('additionalDetails', { box: new Box(0, 1, 2, 2) })
    ]
  },
  [ClaimForm.Check]: {
    fields: [
      builtinFormField('claim.displayId', { name: 'Claim ID' }),
      builtinFormField('claim.reportDate', { name: 'Reported Date' }),
      builtinFormField('claim.checkDetails.reason')
    ]
  },
  [ClaimForm.CheckIntakeReason]: {
    size: new Pos(2, 1),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.reportDate', { name: 'When was the claim reported?' }),
      builtinFormField('claim.checkDetails.reason', { name: 'What is the reason for disputing this transaction?', config: { type: PropertyType.Code, filter: CLAIM_CHECK_REPORT_REASON_FILTER } })
    ]
  },
  [ClaimForm.CheckIntakeReasonForgery]: {
    size: new Pos(2, 4),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.checkDetails.checkNumber', { box: new Box(0, 0, 0, 0) }),
      builtinFormField('claim.checkDetails.checkName', { name: 'Check Recipient Name', box: new Box(1, 0, 1, 0) }),
      builtinFormField('claim.checkDetails.checkDate', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('claim.checkDetails.signedNamed', { box: new Box(1, 1, 1, 1) }),
      builtinFormField('claim.additionalDetails', { box: new Box(0, 2, 1, 3), config: { type: PropertyType.String, minLength: 10 } })
    ]
  },
  [ClaimForm.CheckIntakeReasonUnauthorized]: {
    size: new Pos(1, 3),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.checkDetails.checkName', { box: new Box(0, 0, 0, 0) }),
      builtinFormField('claim.additionalDetails', { box: new Box(0, 1, 0, 2), config: { type: PropertyType.String, minLength: 10 } })
    ]
  },
  [ClaimForm.CreditBureau]: {
    fields: [
      builtinFormField('claim.displayId', { name: 'Claim ID' }),
      builtinFormField('claim.reportDate')
    ]
  },
  [ClaimForm.IntakeAdditionalDetails]: {
    size: new Pos(2, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.additionalDetails', { box: new Box(0, 0, 1, 1), config: { type: PropertyType.String, minLength: 10 } })
    ]
  },
  [ClaimForm.ResolvedReason]: {
    size: new Pos(1, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.resolvedReason', { name: 'How was this claim resolved?', box: new Box(0, 0, 0, 1) })
    ]
  },
  [ClaimForm.ReturnDate]: {
    size: new Pos(1, 2),
    editable: [DisplayType.Claim],
    fields: [
      builtinFormField('claim.returnDate')
    ]
  }
};