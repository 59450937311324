
import { Display, DisplayType } from "../../../../../../common/model/display";
import { SEARCH_REGEX, searchQueryPattern } from "../../../../../../common/toolbox/search";
import { ColumnSort, GridFilter } from "../grid";
import { BaseSource } from "./base";

/** Container for data that is server-side paginated, filtered and sorted. */
export class ServerSource<T = any, K extends DisplayType = any> extends BaseSource<true, T> {
  readonly asynchronous = true;
  private type: K | undefined;

  /** Unfiltered list of items. */
  override get items() { return super.items; }
  override set items(items: T[]) {
    super.items = items;
    this.refilter(this.filter);
    super.repage(this.page);
  }

  constructor(items: T[] = [], available = items.length, type?: K) {
    super(items, available);
    this.available = available ?? 0;
    this.type = type;
    this.refresh();
  }

  refilter(filter?: GridFilter<T>[]) {
    let filtered = this.data;
    this.filter = filter;

    if (filter !== undefined) {
      // Filter callback and query provided.
      filtered = [];

      loop:
      for (let item of this.data) {

        for (let [key, query] of filter) {
          let value = `${this.extract(key, item)}`;
          if (!searchQueryPattern(SEARCH_REGEX, value).includes(query)) continue loop;
        }

        filtered.push(item);
      }
    }

    this.dataChange.next(filtered);
  }

  resort(sort?: ColumnSort<T>) {
    this.sort = sort;
    //if the column we are sorting on isn't something that is sortable by the DB (e.g. a formula column), then fall back on client side sorting
    let clientSortFallback = this.type !== undefined && sort?.column !== undefined && Display.split(sort?.column, this.type) === undefined;
    if (clientSortFallback && this.sort) {
      // If sort is provided use it, otherwise use the default sort
      let callback = this.sortCallback(this.sort);
      if (callback) this.items = [...this.items].sort(callback as any);
    } else {
      this.refresh();      
    }
  }

  override repage(page?: number) {
    super.repage(page);
    this.refresh();
  }
}