import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, debounceTime, merge, takeUntil } from 'rxjs';
import { DOCUMENT_TEMPLATE_TYPE_NAME, DocumentTemplateType } from '../../../../../../common/code/standard/common';
import { DocumentTemplatePreview } from "../../../../../../common/model/document-template/base";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { AuthService } from '../../service/auth.service';
import { DevService } from '../../service/dev.service';
import { LogService } from '../../service/log.service';
import { MIME_CATEGORY_ICON } from '../../toolbox/mime';
import { getRequest } from '../../toolbox/request';

@Component({
  selector: 'app-document-template-list',
  templateUrl: './document-template-list.component.html',
  styleUrls: ['./document-template-list.component.scss'],
  host: {
    class: 'fill'
  }
})
export class DocumentTemplateListComponent {
  readonly DOCUMENT_TEMPLATE_TYPE_NAME = DOCUMENT_TEMPLATE_TYPE_NAME;
  readonly MIME_CATEGORY_ICON = MIME_CATEGORY_ICON;

  /** Available document template types. */
  @Input() set types(types: string[]) {
    if (!this.dev.on) types = types.filter(t => t !== DocumentTemplateType.Test);
    this.available = types as DocumentTemplateType[];
    this.builder.controls.types.patchValue(types as DocumentTemplateType[]);
  }

  /** True to show types dropdown. */
  @Input() showTypes = true;
  /** Emits when a document template is selected. */
  @Output() template = new EventEmitter<DocumentTemplatePreview>();

  /** True if currently displaying in grid mode. */
  grid = true;
  /** List of document templates to preview. */
  templates: DocumentTemplatePreview[] = [];
  /** Valid limits for searching document templates. */
  limits = [10, 25, 50, 100] as const;
  /** List of valid types for document templates. */
  available: DocumentTemplateType[] = [];

  /** Current input values for form. */
  builder = new FormBuilder().nonNullable.group({
    name: ['', [Validators.required]],
    types: [[] as DocumentTemplateType[], [Validators.required]],
    limit: [this.limits[0] as number, [Validators.required]]
  });

  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  static title() {
    return 'Document Templates';
  }

  constructor(
    private dev: DevService,
    private log: LogService,
    private auth: AuthService,
    private http: HttpClient
  ) {
    merge(this.builder.valueChanges)
     .pipe(takeUntil(this.destroy), debounceTime(250))
     .subscribe(() => this.onSearch());
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Refresh list of document templates. */
  async onSearch(): Promise<void | never[]> {
    let value = this.builder.value;
    let types = value.types!;
    if (!types.length) return this.templates = [];

    let templates = await getRequest(this.http, 'document-templates/preview', {
      _insts: [this.auth._inst],
      name: value.name,
      limit: value.limit,
      types
    });

    if (errorResponse(templates)) {
      this.log.show(templates);
      return;
    }

    this.templates = templates;
  }
}
