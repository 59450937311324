import { Component, Inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Account } from '../../../../../../../common/model/account/account';
import { AccountLoan } from '../../../../../../../common/model/account/loan';
import { TAB_DATA } from '../../../../common/component/tab/bar/tab-bar.model';
import { AccountDisputesData } from './account-disputes.model';

@Component({
  selector: 'app-account-disputes',
  templateUrl: './account-disputes.component.html',
  styleUrls: ['./account-disputes.component.scss']
})
export class AccountDisputesComponent {

  /** Current account being viewed. */
  account: Account = new AccountLoan();

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();

  constructor(
    @Inject(TAB_DATA)
    data: AccountDisputesData | null
  ) {
    data?.account.pipe(takeUntil(this.destroy)).subscribe(account => this.account = account);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
