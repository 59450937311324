import { CdkCell, CdkCellDef, CdkColumnDef, CdkFooterCell, CdkFooterCellDef, CdkHeaderCellDef } from "@angular/cdk/table";
import { Directive, Input } from "@angular/core";

@Directive({
  selector: '[grid-cell-def]',
  providers: [ { provide: CdkCellDef, useExisting: GridCellDef } ],
})
export class GridCellDef extends CdkCellDef {}

@Directive({
  selector: '[grid-header-cell-def]',
  providers: [ { provide: CdkHeaderCellDef, useExisting: GridHeaderCellDef } ]
})
export class GridHeaderCellDef extends CdkHeaderCellDef {}

@Directive({
  selector: '[grid-footer-cell-def]',
  providers: [ { provide: CdkFooterCellDef, useExisting: GridFooterCellDef } ]
})
export class GridFooterCellDef extends CdkFooterCellDef {}

@Directive({
  selector: '[grid-column-def]',
  inputs: ['sticky'],
  providers: [
    { provide: CdkColumnDef, useExisting: GridColumnDef },
    { provide: 'SORT_HEADER_COLUMN_DEF' , useExisting: GridColumnDef },
  ]
})
export class GridColumnDef extends CdkColumnDef {
  @Input('grid-column-def')
  override get name() {
    return this._name;
  }

  override set name(name: string) {
    this._setNameInput(name);
  }

  protected override _updateColumnCssClassName() {
    super._updateColumnCssClassName();
    this._columnCssClassName = [`column-${this.cssClassFriendlyName}`];
  }
}

@Directive({
  selector: 'grid-footer-cell',
  host: {
    'role': 'gridcell'
  }
})
export class GridFooterCell extends CdkFooterCell {}

@Directive({
  selector: 'grid-cell',
  host: {
    'role': 'gridcell'
  }
})
export class GridCell extends CdkCell {}
