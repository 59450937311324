import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { ErrorResponse } from '../../../../../../../common/message/error';
import { InstitutionPostResponse } from '../../../../../../../common/message/instititution';
import { SuccessResponse } from '../../../../../../../common/message/success';
import { Address } from '../../../../../../../common/model/address';
import { Institution } from "../../../../../../../common/model/institution";
import { SettingGroupPreview } from '../../../../../../../common/model/setting-group';
import { MaybeId, idMaybe, idNull } from '../../../../../../../common/toolbox/id';
import { errorPartition, errorResponse } from "../../../../../../../common/toolbox/message";
import { AddressService } from '../../../../common/service/address.service';
import { LogService } from '../../../../common/service/log.service';
import { SettingGroupService } from '../../../../common/service/setting-group.service';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';

@Component({
  selector: 'app-setup-institution-edit',
  templateUrl: './setup-institution-edit.component.html',
  styleUrls: ['./setup-institution-edit.component.scss']
})
export class SetupInstitutionEditComponent extends SetupEditComponent<Institution, InstitutionPostResponse> {
  readonly BANK_HELP = 'Use bank terminology for this institution, eg. "customer" instead of "member".\nNote: This flag only affects non-configurable text.\nTo replace names in configured data, see resources/rename.'

  /** Current institution being edited. */
  value = idMaybe(new Institution());
  /** Address being edited. */
  address = new Address();
  /** List of setting groups to select from. */
  groups: SettingGroupPreview[] = [];

  /** True if modifications have been made to address. */
  addressDirty = false;

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private http: HttpClient,
    private addresses: AddressService,
    private settings: SettingGroupService
  ) {
    super(elementRef, log);
  }

  /** Submit current changes to institution. */
  async push() {
    let requests: Promise<SuccessResponse | ErrorResponse>[] = [];
    requests.push(postRequest(this.http, 'institutions', { items: [this.value] }))

    if (this.addressDirty) {
      requests.push(postRequest(this.http, 'addresses', { items: [this.address] }));
    }

    let [error, results] = errorPartition('There was an error updating institution.', await Promise.all(requests));
    return error ?? results[0]!;
  }

  /** Reset current form with new institution. */
  async reset(value: MaybeId<Institution>) {
    this.value = value;
    this.refield();
  }

  /** Change displayed fields after changing institution. */
  async refield() {
    if (idNull(this.value._id)) {
      this.address = new Address();
      this.groups = [];
      return;
    }

    this.settings.previews(this.value._id).then(groups => this.groups = groups);

    if (idNull(this.value._address)) return;
    let address = await this.addresses.item({ _inst: this.value._id, _id: this.value._address });
    if (errorResponse(address)) {
      this.log.show(address);
      return 
    }

    this.address = address;
  }
}
