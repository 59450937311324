import { Component, Input } from '@angular/core';
import { ColorCode } from '../../../../../../common/toolbox/color';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  host: {
    '[class.hidden]': 'hidden',
    '[attr.color]': 'color'
  }
})
export class BannerComponent {
  /** True if currently hidden. */
  @Input() hidden = false;
  /** True if banner can be dismissed. */
  @Input() dismiss = true;
  /** Color set on banner. */
  @Input() color = ColorCode.Primary;
}
