<app-card class="fill">
  <app-card-header>Claim Details</app-card-header>
  <app-card-body class="column">
    <app-form [config]="config"></app-form>
    <app-expansion-panel dark>
      <app-expansion-panel-item>
        <app-expansion-panel-item-header>Advanced Information</app-expansion-panel-item-header>
        <app-expansion-panel-item-body>
          <app-form-list [config]="advancedConfig"></app-form-list>
        </app-expansion-panel-item-body>
      </app-expansion-panel-item>
    </app-expansion-panel>
  </app-card-body>
</app-card>