import { ConditionType } from "../../formula/condition";
import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { BuiltinFormula } from "../formula";

/** General object-independent formulas. */
export enum StandardFormula {
  CurrentDate   = 'Standard - Current Date',
  Test          = 'Standard - Test'
}

export const BUILTIN_STANDARD_FORMULAS: Record<StandardFormula, BuiltinFormula> = {
  [StandardFormula.CurrentDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [StandardFormula.Test]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Greater,
        left: {
          type: TerminalType.Identifier,
          key: 'model.number' as any
        },
        right: {
          type: TerminalType.Number,
          value: 10
        }
      }
    }]
  }
};