import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { PropertyInfo } from "../info/prop";
import { QueryInfo } from "../info/query";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT, NoId, idOmit } from "../toolbox/id";
import { Maybe, PickRequired, objectOmit } from "../toolbox/object";
import { UnionValidator } from "../validator/union";
import { AttachmentUpload } from "./attachment";
import { EventCategory, EventCategoryClass } from "./event/category";
import { EVENT_RESULT_UNIONINFO, EventResult } from "./event/result";
import { FusionCollection } from "./fusion";
import { PropertyType } from "./property-type";

/** Event to be added, with optional id and excluded date. */
export type EventUpsert<T extends EventResult = EventResult> = Omit<Maybe<Event<T>, '_id'>, 'date' | '_user'>;
/** An event broadcasted to a user. */
export type EventUser<T extends EventResult = EventResult> = PickRequired<Event<T>, '_user'>;
/** A simple nested event category mapping. */
export type EventMap<T = unknown> = { [C in EventCategory]: { [R in keyof EventCategoryClass[C]]: T } };
/** A partial event category mapping. */
export type EventPartialMap<T = unknown> = { [C in EventCategory]?: { [R in keyof EventCategoryClass[C]]?: T } };

/** An event that can be displayed in history. */
export class Event<T extends EventResult = EventResult> {
  constructor(
    /** Unique identifier of event. */
    public _id = ID_DEFAULT,
    /** Institution of this event. */
    public _inst = ID_DEFAULT,
    /** Date event was entered. */
    public date = new Date(),
    /** Result payload of task. */
    public result = {} as T,
    /** User that created event. */
    public _user?: string,
    /** Title override for event name. */
    public title?: string,
    /** Description override for event. */
    public description?: string,
    /** Any attachments linked to this event. */
    public _attachments?: string[],
    /** True if event should be hidden from user's events. */
    public deleted?: boolean
  ) {}
  
  static typeinfo: TypeInfo<Event> = {
    result: new UnionValidator(EVENT_RESULT_UNIONINFO),
    _user: ID_DEFAULT,
    title: '',
    description: '',
    _attachments: [ID_DEFAULT],
    deleted: true
  }

  static propinfo: PropertyInfo<Event> = {
    _user: { type: PropertyType.User }
  }

  static collectioninfo: CollectionInfo<FusionCollection, Event> = {
    _inst: 'institutions',
    _user: 'users',
    _attachments: 'attachments'
  }

  static indexinfo: IndexInfo<Event> = [
    { key: { _inst: 1, 'result.category': 1 } },
    { key: { _inst: 1, 'result._assigned': 1, 'result._completed': 1 } }
  ]

  static queryinfo: QueryInfo<Event> = {
    deleted: { $ne: true }
  }

  /** Use specified event, or fallback on new one. */
  static fallback<T extends EventResult>(_inst: string, event?: NoId<Event<T>>): NoId<Event<T>> {
    if (event) return event;

    event = idOmit(new Event<T>());
    event._inst = _inst;
    return event;
  }
}

/** An event to be inserted into the system. */
export class EventUpload<T extends EventResult = EventResult> {
  constructor(
    /** Event to be added, with optional id and excluded date. */
    public event: EventUpsert<T> = objectOmit(new Event<T>(), '_id', '_user', 'date'),
    /** Attachments to add. */
    public uploads?: AttachmentUpload[]
  ) {}

  static typeinfo: TypeInfo<EventUpload> = {
    uploads: [new AttachmentUpload()]
  }
}