import { CdkRow } from "@angular/cdk/table";
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Input, ViewEncapsulation } from "@angular/core";
import { debugElementMake } from "../../../toolbox/element/debug";
import { GridBase } from "../grid.base";

@Component({
  exportAs: 'gridRow',
  selector: 'grid-row',
  styleUrls: ['./grid-row.component.scss'],
  templateUrl: './grid-row.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ { provide: CdkRow, useExisting: GridRow } ],
  host: {
    '[class.selected]': 'selected'
  }
})
// @ts-ignore Supress TS2417
export class GridRow<T extends Object> extends GridBase(CdkRow) {
  /** Calculated style for grid items. */
  @HostBinding('style.grid-template-columns') style = '';
  /** True if row should show indeterminate value. */
  @Input() indeterminate: any;

  /** Value bound to row. */
  value: T = undefined as unknown as T;
  /** True if row is currently selected. */
  selected = false;

  constructor(
    public elementRef: ElementRef
  ) {
    super();
    debugElementMake(this);
  }

  /** Called when clicking option. */
  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.clicked.emit(this);
  }

  /** Called when debugging row. */
  onDebug() {
    return this.value;
  }
}