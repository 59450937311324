import { CommonCode } from "../code/system";
import { IndexInfo } from "../info";
import { PropertyInfo } from "../info/prop";
import { TypeInfo } from "../info/type";
import { arrayDefined } from "../toolbox/array";
import { ID_DEFAULT } from "../toolbox/id";
import { PropertyType } from "./property-type";

/** An address of an account or user. */
export class Address {
  constructor(
    /** Unique ID of address. */
    public _id = ID_DEFAULT,
    /** Institution of address. */
    public _inst = ID_DEFAULT,
    /** Identifiable name provided for address. */
    public name?: string,
    /** Host-provided number of address. */
    public number = '',
    /** Street of address. */
    public street = '',
    /** Extra information about address. */
    public extra?: string,
    /** City of address. */
    public city = '',
    /** ZIP code of address. */
    public zip = '',
    /** Host-provided state code of address. */
    public state = '',
    /** Country of address, if applicable. */
    public country?: string,
    /** True if this is an imported address and cannot be modified. */
    public system?: boolean,
    /** True if this is test data. */
    public test?: boolean
  ) {}

  static typeinfo: TypeInfo<Address> = {
    extra: '',
    name: '',
    country: '',
    system: false,
    test: false
  }

  static propinfo: PropertyInfo<Address> = {
    state: { type: PropertyType.Code, category: CommonCode.State },
    country: { type: PropertyType.Code, category: CommonCode.Country }
  }

  static indexinfo: IndexInfo<Address> = [
    { key: { _inst: 1, number: 1 }, unique: true },
    { key: { street: 'text', city: 'text', zip: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  /** Format address into human-readable string. */
  static format({ street, city, state, zip, country }: Partial<Address>) {
    let local = city && state ? `${city}, ${state}` : (city ?? state);
    return arrayDefined([street, local, zip, country]).join(' ');
  }
}

/** Address with a required name. */
export class AddressName extends Address {
  constructor(
    public override name = ''
  ) {
    super();
  }

  /** Create from a normal address. */
  static from(address: Address): AddressName {
    let named = { ...address, name: address.name ?? Address.format(address) };
    Object.setPrototypeOf(named, AddressName.prototype);
    return named;
  }
}

/** A preview of a full address. */
export class AddressPreview {
  constructor(
    /** Unique ID of address. */
    public _id = ID_DEFAULT,
    /** Identifiable name provided for address. */
    public name?: string,
    /** Street of address. */
    public street = '',
    /** City of address. */
    public city = '',
    /** ZIP code of address. */
    public zip = ''
  ) {}
}

/** Address preview with an added name. */
export class AddressPreviewName extends AddressPreview {
  constructor(
    public override name = ''
  ) {
    super();
  }

  /** Add required name to address preview. */
  static from(address: AddressPreview): AddressPreviewName {
    let named = { ...address, name: address.name ?? Address.format(address) };
    Object.setPrototypeOf(named, AddressPreviewName.prototype);
    return named;
  }
}

/** Unique fields of a claim for lookup. */
export class AddressReference {
  constructor(
    /** Institution of address. */
    public _inst = ID_DEFAULT,
    /** Unique identifier of address. */
    public number = ''
  ) {}
}