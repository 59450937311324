import { Component } from '@angular/core';
import { JobType } from '../../../../../../../../common/model/job/payload';
import { JobAccessor } from '../../edit/setup-job-edit.model';

@Component({
  selector: 'app-setup-job-payload-work-queue-build',
  templateUrl: './setup-job-payload-work-queue-build.component.html',
  styleUrls: ['./setup-job-payload-work-queue-build.component.scss']
})
export class SetupJobPayloadWorkQueueBuildComponent extends JobAccessor<JobType.WorkQueueBuild> {}
