<app-card class="elevation-dialog">
  <app-card-header>{{APP_NAME}} Login</app-card-header>
  <app-card-body class="column">
    <!-- Style guide: https://learn.microsoft.com/en-us/entra/identity-platform/howto-add-branding-in-apps#pictogram-and-sign-in-with-microsoft -->
    <!-- <button class="ms-login" color="black" [disabled]="builder.invalid">
      <svg width="28" height="20">
        <rect width="9" height="9" x="0" y="0" fill="#F65314" />
        <rect width="9" height="9" x="10" y="0" fill="#7CBB00" />
        <rect width="9" height="9" x="0" y="10" fill="#00A1F1" />
        <rect width="9" height="9" x="10" y="10" fill="#FFBB00" />
      </svg>
      Sign in with Microsoft
    </button> -->
    <form [formGroup]="builder" (ngSubmit)="onLogin(builder.value.email!, builder.value.password!)">
      <app-field>
        <app-label>Email</app-label>
        <app-sublabel>
          <app-hover-help [tooltip]="EMAIL_HELP" tooltip-position="right"></app-hover-help>
        </app-sublabel>
        <app-string formControlName="email"></app-string>
      </app-field>
      <app-field>
        <app-label>Password</app-label>
        <app-sublabel>
          <app-hover-help [tooltip]="PASSWORD_HELP" tooltip-position="right"></app-hover-help>
        </app-sublabel>
        <app-string type="password" formControlName="password"></app-string>
      </app-field>
      <div class="button-row" style="margin-top: 1rem">
        <button type="submit" color="primary" [disabled]="builder.invalid">Login</button>
      </div>
    </form>
  </app-card-body>
</app-card>
<div class="hint elevation-low" [tooltip]="APP_COMMIT" (click)="onVersion()">
  {{APP_NAME}} {{APP_VERSION}} - {{APP_BUILT | dateIso:'MM/dd/YYYY HH:mm:ss'}} ({{dev ? 'DEV' : 'PROD'}})
</div>