<app-card body>
  <app-form [config]="config"></app-form>
</app-card>
<main class="fill">
  <app-card>
    <app-card-body scroll>
      <ng-container *ngFor="let state of states">
        <app-label>{{state.partial.transaction.description}}</app-label>
        <div class="item" *ngFor="let item of state.ledger">
          <div>{{item.ledger.amount | cents}}</div>
          <div>{{item.ledger._config | resourceName:'ledgerConfigs' | async}}</div>
        </div>
      </ng-container>
    </app-card-body>
  </app-card>
  <app-card body>
    <app-ledger-list *ngIf="states[page]" [state]="states[page]!"></app-ledger-list>
  </app-card>
</main>
<footer *ngIf="states.length > 1">
  <button icon-button tooltip="Previous page" (click)="onPage(-1)" [disabled]="page === 0">chevron_left</button>
  <div>Dispute {{page + 1}} of {{states.length}}</div>
  <button icon-button tooltip="Next page" (click)="onPage(+1)" [disabled]="page === states.length - 1">chevron_right</button>
</footer>