import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { EMAIL_HELP, PASSWORD_HELP } from "../../../../../../common/model/event/result/auth";
import { PASSWORD_REGEX } from "../../../../../../common/model/user/password";
import { APP_BUILT, APP_COMMIT, APP_NAME, APP_VERSION } from "../../../../../../common/toolbox/app";
import { AuthService } from "../../../common/service/auth.service";
import { DevService } from "../../../common/service/dev.service";
import { LogService } from "../../../common/service/log.service";
import { UserService } from "../../../common/service/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {
    class: 'center dialog-backdrop'
  }
})
export class LoginComponent {
  readonly APP_NAME = APP_NAME;
  readonly APP_BUILT = APP_BUILT;
  readonly APP_VERSION = APP_VERSION;
  readonly APP_COMMIT = APP_COMMIT;
  readonly EMAIL_HELP = EMAIL_HELP;
  readonly PASSWORD_HELP = PASSWORD_HELP;

  /** Login form. */
  builder = new FormBuilder().nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]]
  });

  constructor(
    public dev: DevService,
    private log: LogService,
    private users: UserService,
    private auth: AuthService
  ) {}

  /** Callback when logging in. */
  async onLogin(email: string, password: string) {
    let session = await this.auth.login(email, password);
    this.users.session(session);
  }

  /** Callback when clicking version. */
  onVersion() {
    navigator.clipboard.writeText(APP_COMMIT);
    this.log.show('Copied commit hash to clipboard.');
  }
}
