import { ChipResult } from "../../../../../common/model/chip";
import { DisplayPartial, DisplayType } from "../../../../../common/model/display";
import { Icon, IconMap } from "../../../../../common/model/icon";

/** A display partial with an attached chip. */
export type DisplayChip = DisplayPartial & { chip?: ChipResult };

/** Icon for each display type. */
export const DISPLAY_TYPE_ICON: IconMap<DisplayType> = {
  [DisplayType.Account]: Icon.AccountBalanceWallet,
  [DisplayType.Attachment]: Icon.Attachment,
  [DisplayType.Card]: Icon.CreditCard,
  [DisplayType.Claim]: Icon.CurrencyExchange,
  [DisplayType.Dispute]: Icon.RequestQuote,
  [DisplayType.Email]: Icon.Email,
  [DisplayType.Event]: Icon.Event,
  [DisplayType.Formula]: Icon.Functions,
  [DisplayType.Institution]: Icon.AccountBalance,
  [DisplayType.Ledger]: Icon.ReceiptLong,
  [DisplayType.Member]: Icon.Face,
  [DisplayType.Model]: Icon.Storage,
  [DisplayType.Organization]: Icon.Domain,
  [DisplayType.Phone]: Icon.Call,
  [DisplayType.Transaction]: Icon.Receipt,
  [DisplayType.Uploads]: Icon.UploadFile,
  [DisplayType.User]: Icon.SupportAgent
}