import { Component } from '@angular/core';
import { SettingType } from '../../../../../../../common/info/settings';
import { FusionCollectionName } from '../../../../../../../common/model/fusion';
import { SettingsAccessor } from '../settings-accessor';

@Component({
  selector: 'app-settings-resource',
  templateUrl: './settings-resource.component.html',
  styleUrls: ['./settings-resource.component.scss']
})
export class SettingsResourceComponent extends SettingsAccessor<SettingType> {

  /** Mapping of setting types to collections */
  readonly SETTING_TYPE_COLLECTION: Record<SettingType, FusionCollectionName | undefined> = {
    [SettingType.Boolean]: undefined,
    [SettingType.Codes]: undefined,
    [SettingType.CodeType]: 'codeTypes',
    [SettingType.DocumentTemplate]: 'documentTemplates',
    [SettingType.Form]: 'forms',
    [SettingType.FormList]: 'formLists',
    [SettingType.Formula]: 'formulas',
    [SettingType.Model]: 'models',
    [SettingType.Number]: undefined,
    [SettingType.String]: undefined,
    [SettingType.Table]: 'tables',
    [SettingType.Task]: 'tasks',
    [SettingType.Workflow]: 'workflows'
  };

  /** True to allow multiselect. */
  multiple = false;

  async ngOnInit() {
    this.multiple = 'multiple' in this.setting && !!this.setting.multiple;
  }
}