<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row align-end">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name"></app-string>
        </app-field>
      </div>
    </app-setup-base-header>
    <app-setup-base-body>
      <app-stepper [(current)]="current" [(renaming)]="renaming" [menu]="stepMenu" [steps]="steps" [readonly]="false" [deletable]="false" (step)="onStep($event.data)" (created)="onNew()">
        <app-breadcrumb [(crumb)]="crumb" [(crumbs)]="crumbs" [template]="crumbTemplate"></app-breadcrumb>
        <ng-template #crumbTemplate let-crumb>
          <ng-container *ngIf="crumb.step">Step - {{crumb.step.name}}</ng-container>
          <ng-container *ngIf="crumb.action">Action - {{crumb.action.name}}</ng-container>
          <ng-container *ngIf="crumb.condition">Condition - {{crumb.condition.type | enum:WORK_CONDITION_NAME}}</ng-container>
        </ng-template>

        <!-- Only way to do this, Angular type-checker doesn't like type assertions or ngSwitch here. -->
        <ng-container *ngIf="crumb && crumb.type === 'step'">
          <app-setup-workflow-step [step]="crumb.step" (action)="onAction($event)" (condition)="onCondition($event)"></app-setup-workflow-step>
        </ng-container>
        <ng-container *ngIf="crumb && crumb.type === 'action'">
          <app-setup-workflow-action [action]="crumb.action" (condition)="onCondition($event)"></app-setup-workflow-action>
        </ng-container>
        <ng-container *ngIf="crumb && crumb.type === 'condition'">
          <app-setup-workflow-condition [condition]="crumb.condition"></app-setup-workflow-condition>
        </ng-container>
      </app-stepper>
      <app-menu #stepMenu>
        <app-menu-item icon (click)="renaming = current"><app-icon>edit</app-icon>Rename</app-menu-item>
        <app-menu-item icon (click)="onDelete(current)"><app-icon>delete</app-icon>Delete</app-menu-item>
        <app-menu-item icon [disabled]="current == 0" (click)="onMove(current, -1)"><app-icon>arrow_back</app-icon>Move left</app-menu-item>
        <app-menu-item icon [disabled]="current == value.steps.length - 1" (click)="onMove(current, +1)"><app-icon>arrow_forward</app-icon>Move right</app-menu-item>
      </app-menu>
      <div class="fill"></div>
      <app-labels>
        <app-label>Tasks</app-label>
        <app-sublabel>
          <app-hover-help>List of tasks available in all workflow steps.</app-hover-help>
        </app-sublabel>
      </app-labels>
      <app-resource-list collection="tasks" [required]="false" [(ngModel)]="value._tasks"></app-resource-list>
    </app-setup-base-body>
  <app-setup-base-footer>
    <button icon-button *ngIf="dev.on" tooltip="Copy code" (click)="onCode()">code</button>
    <button (click)="onSubmit()">Save</button>
    <button (click)="onCancel()">Cancel</button>
  </app-setup-base-footer>
  </app-setup-base>
</app-card>