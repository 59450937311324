import { TypeInfo } from "../../../../../../../common/info/type";
import { ChoiceValidator } from "../../../../../../../common/validator/choice";
import { NumberValidator } from "../../../../../../../common/validator/number";
import { StringValidator } from "../../../../../../../common/validator/string";

/** Data passed to source dialog. */
export class DialogSourceData {
  constructor(
    /** Value to edit. */
    public value: Object = {},
    /** True if code is readonly, defaults to true. */
    public readonly?: boolean,
    /** Default indentation when stringifying objects. */
    public indent?: number | string
  ) {}

  static typeinfo: TypeInfo<DialogSourceData> = {
    readonly: true,
    indent: new ChoiceValidator(new NumberValidator(), new StringValidator())
  }
}

/** Result of opening source dialog. */
export type DialogSourceReturn = string;