import { Component } from '@angular/core';
import { TaskConfigForm } from '../../../../../../../../common/model/task-config';
import { AuthService } from '../../../../../common/service/auth.service';
import { TaskAccessor } from '../../edit/setup-task-edit.model';
import { FormPreview } from '../../../../../../../../common/model/form/form';
import { FormService } from '../../../../../common/service/form.service';

@Component({
  selector: 'app-setup-task-config-form',
  templateUrl: './setup-task-config-form.component.html',
  styleUrls: ['./setup-task-config-form.component.scss']
})
export class SetupTaskConfigFormComponent extends TaskAccessor<TaskConfigForm> {
  /** Forms that can be completed. */
  forms: FormPreview[] = [];

  constructor(
    private auth: AuthService,
    private formService: FormService,
  ) {
    super();
  }

  async ngOnInit() {
    this.forms = await this.formService.previews(this.auth._inst);
  }
}
