import { Pipe } from '@angular/core';
import { objectDecircular } from '../../../../../common/toolbox/object';

/** Get display name of a resource. */
@Pipe({ name: 'jsonSafe' })
export class JsonSafePipe {
  transform(value: any, space = 2) {
    return JSON.stringify(objectDecircular(value), null, space);
  }
}
