<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Host ID</app-label>
          <app-string [(ngModel)]="value.hostId"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Setting Group</app-label>
          <app-select [(ngModel)]="value._settingGroup">
            <app-option *ngFor="let group of groups" [view]="group.name" [value]="group._id"></app-option>
          </app-select>
        </app-field>
        <app-field class="fill">
          <app-label>Bank</app-label>
          <app-sublabel>
            <app-hover-help [tooltip]="BANK_HELP"></app-hover-help>
          </app-sublabel>
          <app-boolean [(ngModel)]="value.bank"></app-boolean>
        </app-field>
      </div>
    </app-setup-base-header>
    <app-setup-base-body>
      <app-field autosize>
        <app-label>Contact Info</app-label>
        <app-expansion-panel>
          <app-expansion-panel-item>
            <app-expansion-panel-item-header>Address</app-expansion-panel-item-header>
            <app-expansion-panel-item-body>
              <app-address-edit [(ngModel)]="address" [(dirty)]="addressDirty"></app-address-edit>
            </app-expansion-panel-item-body>
          </app-expansion-panel-item>
          <app-expansion-panel-item>
            <app-expansion-panel-item-header>Phones</app-expansion-panel-item-header>
            <app-expansion-panel-item-body>
              <app-phones-edit [(ngModel)]="value.phones"></app-phones-edit>
            </app-expansion-panel-item-body>
          </app-expansion-panel-item>
          <app-expansion-panel-item>
            <app-expansion-panel-item-header>Emails</app-expansion-panel-item-header>
            <app-expansion-panel-item-body>
              <app-emails-edit [(ngModel)]="value.emails"></app-emails-edit>
            </app-expansion-panel-item-body>
          </app-expansion-panel-item>
        </app-expansion-panel>
      </app-field>
    </app-setup-base-body>
    <app-setup-base-footer>
      <button (click)="onSubmit()">Save</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>