import { Pipe } from '@angular/core';

@Pipe({
  name: 'multiline'
})
export class MultilinePipe {
  transform(value: any): unknown {
    return `${value}`.split(' ').join('\n');
  }
}
