<div class="row">
  <app-field class="fill">
    <app-label>Minimum Length</app-label>
    <app-sublabel>
      <app-hover-help tooltip="Minimum length for this field." tooltip-position="right"></app-hover-help>
    </app-sublabel>
    <app-number align-right [(ngModel)]="config.minLength"></app-number>
  </app-field>
  <app-field class="fill">
    <app-label>Maximum Length</app-label>
    <app-sublabel>
      <app-hover-help tooltip="Maximum length for this field." tooltip-position="right"></app-hover-help>
    </app-sublabel>
    <app-number align-right [(ngModel)]="config.maxLength"></app-number>
  </app-field>
</div>