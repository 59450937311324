import { AccountCategory } from "../code/standard/common";
import { propInfoMerge } from "../info/prop";
import { unioninfoRetype } from "../info/union";
import { ACCOUNT_UNIONINFO, Account, AccountClass, AccountJoin, AccountUnion } from "../model/account/account";
import { AccountBase, AccountLookup } from "../model/account/base";
import { AccountLoan } from "../model/account/loan";
import { AccountShare } from "../model/account/share";
import { Member } from "../model/member";
import { arraySome } from "./array";
import { MaybeId } from "./id";
import { stringJoin } from "./string";

/** Get full name of an account from pieces. */
export function accountName(account: MaybeId<AccountJoin>) {
  if (arraySome(account.members)) {
    return stringJoin(Member.fullname(account.members[0]), `(${account.number})`);
  } else if (account.description?.length) {
    return stringJoin(account.description, `(${account.number})`);
  } else {
    return account.number;
  }
}

/** Creates an account union. */
export function accountUnion(): AccountUnion {
  return propInfoMerge(new AccountLoan(), new AccountShare(), new AccountLookup()) as AccountUnion;
}

/** Converts a joined account back to a basic account. */
export function accountUnjoin(account: AccountJoin): Account
export function accountUnjoin(account: MaybeId<AccountJoin>): MaybeId<Account>
export function accountUnjoin(account: MaybeId<AccountJoin>): MaybeId<Account> {
  return {
    ...account,
    members: undefined,
    cards: undefined
  } as any;
}

/** Change type of an account. */
export function accountRetype<C extends AccountCategory>(account: Account, category: C): AccountClass[C]
export function accountRetype<C extends AccountCategory>(account: MaybeId<Account>, category: C): MaybeId<AccountClass[C]>
export function accountRetype<C extends AccountCategory>(account: MaybeId<Account>, category: C): MaybeId<AccountClass[C]> {
  return unioninfoRetype<MaybeId<AccountBase>, MaybeId<Account>, AccountCategory>(new AccountBase(), account, category, ACCOUNT_UNIONINFO) as MaybeId<AccountClass[C]>;
}