import { BooleanInput } from '@angular/cdk/coercion';
import { Component } from '@angular/core';
import { booleanCoerce } from '../../../../../../../common/toolbox/boolean';
import { FormAccessor } from '../../form/form-accessor';

@Component({
  selector: 'app-form-boolean',
  templateUrl: './form-boolean.component.html',
  styleUrls: ['./form-boolean.component.scss']
})
export class FormBooleanComponent extends FormAccessor<boolean | undefined> {
  
  /** Displayed label for true. */
  on = 'Yes';
  /** Displayed label for false. */
  off = 'No';
  /** True if large input. */
  large = false;
  /** Required value for this boolean to validate, if applicable. */
  require?: boolean;
  
  override writeValue(value: BooleanInput) {
    this.update(booleanCoerce(value, this.required));
  }
}
