import { Component } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { SettingClass, SettingType, SettingValue } from "../../../../../../common/info/settings";

/** Interface that allows a field to be used in a settings form. */
@Component({ template: '' })
export abstract class SettingsAccessor<T extends SettingType = SettingType> {

  /** Setting this component is bound to. */
  setting!: SettingClass[T];
  /** Current value of setting. */
  value!: SettingValue[T];
  /** Emits when value changes. */
  valueChange = new ReplaySubject<SettingValue[T]>(1);
  
  /** True if accessor will be responsible for displaying description. */
  static description() {
    return false;
  }
  
  /** Write value for field. */
  writeValue(value: SettingValue[T]) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.update(value);
  }

  /** Update field value. */
  update(value: SettingValue[T]): boolean {
    if (value === this.value) return false;
    this.valueChange.next(this.value = value);
    return true;
  }
}