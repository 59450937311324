import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { ExecutiveModule } from '../../executive/executive.module';
import { CollectionsExecutiveComponent } from './collections-executive.component';
import { CollectionsExecutiveHistoryComponent } from './history/collections-executive-history.component';
import { CollectionsExecutiveStatComponent } from './stat/collections-executive-stats.component';

@NgModule({
  declarations: [
    CollectionsExecutiveComponent,
    CollectionsExecutiveHistoryComponent,
    CollectionsExecutiveStatComponent
  ],
  imports: [
    ExecutiveModule,
    SharedModule
  ]
})
export class CollectionsExecutiveModule { }
