import { TypeInfo } from "../info/type";
import { Institution, InstitutionPreview } from "../model/institution";
import { ID_DEFAULT, MaybeId, idOmit } from "../toolbox/id";
import { SuccessResponse } from "./success";

/** Get all institutions of an organization. */
export class InstitutionGetRequest {
  constructor(
    /** Organizations to search. */
    public _orgs: string[] = [],
    /** Unique identifiers of institutions. */
    public _ids: string[] = [],
    /** Name of institution. */
    public name?: string
  ) {}

  static typeinfo: TypeInfo<InstitutionGetRequest> = {
    _orgs: [ID_DEFAULT, ID_DEFAULT],
    _ids: [ID_DEFAULT, ID_DEFAULT],
    name: ''
  }
}

/** Response of getting institutions. */
export type InstitutionGetResponse = Institution[];

/** Preview institutions of an organization. */
export class InstitutionPreviewGetRequest extends InstitutionGetRequest {}

/** Response of getting institutions. */
export type InstitutionPreviewGetResponse = InstitutionPreview[];

/** Request to modify institutions. */
export class InstitutionPostRequest {
  constructor(
    /** List of institutions to modify. */
    public items: MaybeId<Institution>[] = []
  ) {}

  static typeinfo: TypeInfo<InstitutionPostRequest> = {
    items: [idOmit(new Institution())]
  }
}

/** Response of adding an institution. */
export type InstitutionPostResponse = SuccessResponse;

/** Request to delete an institution. */
export class InstitutionDeleteRequest {
  constructor(
    /** List of institutions to delete. */
    public _insts: string[]
  ) {}

  static typeinfo: TypeInfo<InstitutionDeleteRequest> = {
    _insts: [ID_DEFAULT, ID_DEFAULT]
  }
}

/** Result of getting jobs. */
export type InstitutionDeleteResponse = SuccessResponse;