import { Pipe } from '@angular/core';
import { Code } from "../../../../../common/model/code-type";
import { CodeTypeService } from '../service/code-type.service';

@Pipe({ name: 'codeList' })
export class CodeListPipe {
  /** Dummy list of code types. */
  private static Empty = Promise.resolve([] as Code[])

  constructor(
    private types: CodeTypeService
  ) {}

  /** Get a list of code types by category. */
  transform(category?: string, _inst?: string): Promise<Code[]> {
    if (category === undefined || _inst === undefined) return CodeListPipe.Empty;
    return this.types.list({ _inst, category });
  }
}
