import { Pipe } from '@angular/core';
import { ColorCode } from "../../../../../common/toolbox/color";
import { StatusLevel } from "../../../../../common/toolbox/status";

/** Name for each status level. */
export type StatusName = 'notice' | 'warning' | 'alert';

/** Mapping of status levels to default colors. */
const STATUS_COLOR: Record<StatusLevel, ColorCode> = {
  [StatusLevel.Notice]: ColorCode.Blue,
  [StatusLevel.Warning]: ColorCode.Yellow,
  [StatusLevel.Alert]: ColorCode.Red
};

/** Name for each status color. */
const STATUS_NAME_LEVEL: Record<StatusName, StatusLevel> = {
  notice: StatusLevel.Notice,
  warning: StatusLevel.Warning,
  alert: StatusLevel.Alert
}

@Pipe({
  name: 'statusColor'
})
export class StatusColorPipe {
  
  transform(status: StatusName): ColorCode
  transform(status: StatusName | StatusLevel, color?: ColorCode): ColorCode
  transform(status: StatusName | StatusLevel | undefined, color: ColorCode | undefined, fallback: ColorCode): ColorCode
  transform(status: StatusName | StatusLevel | undefined, color?: ColorCode, fallback?: ColorCode) {
    status = typeof status === 'string' ? STATUS_NAME_LEVEL[status] : status;
    return color ?? (status === undefined ? status : STATUS_COLOR[status]) ?? fallback;
  }
}
