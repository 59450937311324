<app-card>
  <ng-container *ngFor="let stat of stats">
    <app-card-body class="title">
      <h1>{{stat.value | number}}</h1>
      <app-label multiline><div>{{stat.subtitle}}</div></app-label>
    </app-card-body>
    <div class="percentage">
      <div *ngIf="stat.percentage">{{stat.percentage}}%</div>
    </div>
    <app-card-panel>
      <main>
        <h1>{{stat.amount | cents}}</h1>
        <app-label multiline>{{stat.label}}</app-label>
      </main>
    </app-card-panel>
  </ng-container>
</app-card>