import { UnionInfo } from "../../../info/union";
import { EventCategory } from "../category";

/** Standard string for email help. */
export const EMAIL_HELP = 'Any valid email address.';
/** Standard string for password help. */
export const PASSWORD_HELP = '8-32 characters including:\n * Uppercase and lowercase letters.\n * Letters and numbers.\n * One special character from: !@#$%^&*';

/** Tagged type of account event. */
export enum AuthResultType {
  /** TODO Eventually we want to log failures as well, but for now that is not implemented. */
  LoginSuccess,
  Logout
}

/** Modifications of an account. */
export type AuthResult =
  AuthLoginResult |
  AuthLogoutResult;


/** Result of logging a user in. */
export class AuthLoginResult {
  readonly category = EventCategory.Authentication;
  readonly type = AuthResultType.LoginSuccess;
}

/** Result of logging a user out. */
export class AuthLogoutResult {
  readonly category = EventCategory.Authentication;
  readonly type = AuthResultType.Logout;
}

export class AuthResultClass {
  [AuthResultType.LoginSuccess] = new AuthLoginResult();
  [AuthResultType.Logout] = new AuthLogoutResult();
}

/** Union type information for authentication results. */
export const AUTH_RESULT_UNIONINFO: UnionInfo<AuthResult, AuthResultType> = {
  tag: 'type',
  classes: new AuthResultClass()
}