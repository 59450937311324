import { ClaimStatus } from "../../code/standard/disputes";
import { DisputesCode } from "../../code/system";
import { IndexInfo } from "../../info";
import { PropertyInfo } from "../../info/prop";
import { QueryInfo } from "../../info/query";
import { SchemaInfo } from "../../info/schema";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { EnumValidator } from "../../validator/enum";
import { Account } from "../account/account";
import { AccountLoan } from "../account/loan";
import { CommentData } from "../comment/comment";
import { Member } from "../member";
import { PropertyType } from "../property-type";
import { ClaimAttachment } from "./attachment";

/** Common fields shared by all claims. */
export class ClaimBase {
  constructor(
    /** Unique identifier of claim. */
    public _id = ID_DEFAULT,
    /** Institution of this claim. */
    public _inst = ID_DEFAULT,
    /** Account this claim is linked to. */
    public _account = ID_DEFAULT,
    /** Member this claim is linked to */
    public _member = ID_DEFAULT,
    /** User this claim is assigned to */
    public _assigned?: string,
    /** User that completed intake for this claim, or undefined for an externally-imported claim. */
    public _uploaded?: string,
    /** Displayable ID (not a mongo ID) */
    public displayId = '',
    /** Current status of claim. */
    public status = ClaimStatus.Initiated,
    /** True if account is <30 days old. */
    public newAccount = false,
    /** Generic verification checkbox present on all claim types. */
    public verify = false,
    /** Additional details provided by operator. */
    public additionalDetails = '',
    /** Date that claim was reported. */
    public reportDate = new Date(),
    /** Date that claim completed system intake. */
    public intakeDate = new Date(),
    /** Date that claim was denied. */
    public deniedDate?: Date,
    /** Date that claim was reviewed. */
    public reviewDate?: Date,
    /** Attachments for this claim */
    public attachments: ClaimAttachment[] = [],
    /** Whether this claim was randomly generated for testing purposes */
    public test?: boolean,
    /** True if this claim was deleted and is now hidden. */
    public deleted?: boolean,
    /** Comments on this claim */
    public comments: undefined | CommentData[] = undefined,
    /** Reason claim was resolved. */
    public resolvedReason?: string,
    /** Custom per-institution model data associated with claim. */
    public value: undefined | Object = undefined
  ) {}

  static typeinfo: TypeInfo<ClaimBase> = {
    _assigned: ID_DEFAULT,
    _uploaded: ID_DEFAULT,
    attachments: [new ClaimAttachment()],
    status: new EnumValidator(ClaimStatus),
    deniedDate: new Date(),
    reviewDate: new Date(),
    test: true,
    deleted: true,
    comments: [new CommentData()],
    resolvedReason: '',
    value: {}
  }

  static propinfo: PropertyInfo<ClaimBase> = {
    _assigned: { type: PropertyType.User },
    _uploaded: { type: PropertyType.User },
    newAccount: { type: PropertyType.Boolean, on: 'New', off: 'Mature' },
    status: { type: PropertyType.Code, category: DisputesCode.ClaimStatus }
  }

  static indexinfo: IndexInfo<ClaimBase> = [
    { key: { _inst: 1, displayId: 1 }, unique: true },
    { key: { _inst: 1, _assigned: 1, status: 1 } },
    { key: { _inst: 1, _account: 1 } },
    { key: { _inst: 1, _member: 1 } },
    { key: { _inst: 1, status: 1 } }
  ];

  static schemainfo: SchemaInfo<ClaimBase> = {
    value: {
      bsonType: 'object',
      properties: {},
      additionalProperties: true
    }
  };

  static queryinfo: QueryInfo<ClaimBase> = {
    deleted: { $ne: true }
  }
}

/** Unique fields of a claim for lookup. */
export class ClaimReference {
  constructor(
    /** Institution of claim. */
    public _inst = ID_DEFAULT,
    /** Unique identifier of claim. */
    public displayId = ''
  ) { }
}

/** A preview of a claim. */
export class ClaimPreview {
  constructor(
    /** ID of claim. */
    public _id = ID_DEFAULT,
    /** Display ID of claim. */
    public displayId = ''
  ) {}
}

/** Fields a claim can be joined with. */
export class ClaimLookup {
  constructor(
    /** Account of this claim. */
    public account: Account = new AccountLoan(),
    /** Member of this claim. */
    public member = new Member()
  ) {}
}