import mimeOther from "../data/mime-other.json";
import mimeStandard from "../data/mime-standard.json";
import { MimeCategory, MimePart, MimePartContent, MimePartSimple, MimeTypeSimple } from "../model/mime";
import { binaryBase64, binaryText } from "./binary";

/** Internal map of extensions to mime types. */
let map = new Map<string, MimeTypeSimple>();
for (let mime of [mimeStandard, mimeOther])
    for (let [type, exts] of Object.entries(mime))
        for (let ext of exts)
            map.set(ext, type as MimeTypeSimple);

/** Split file into name and extension. */
export function mimeSplit(filename?: string): [name: string, extension: string] {
  let [, name, extension] = filename?.match(/(.*?)(?:\.([^.]+))?$/) ?? [];
  if (name && extension) return [name, extension];
  return ['', ''];
}

/** Get MIME type of a filename. */
export function mimeType(filename?: string): MimeTypeSimple {
  return map.get(mimeSplit(filename)[1]) ?? 'application/octet-stream';
}

/** Change extension of a filename. */
export function mimeChange(filename: string, extension: string) {
  let [name, ext] = [mimeSplit(filename)[0] || filename, mimeSplit(extension)[1] || extension];
  return ext ? [name, ext].join('.') : filename || extension;
}

/** Get category of the provided MIME type string.
 *  @param mime A filename or mime type.
 *  @param fallback True to try converting mime to a mimeType if no type found.
*/
export function mimeCategory(mime?: string, fallback = true): MimeCategory {
  switch (mime) {
    case 'application/gzip':
    case 'application/java-archive':
    case 'application/vnd.apple.installer+xml':
    case 'application/vnd.rar':
    case 'application/x-7z-compressed':
    case 'application/x-bzip':
    case 'application/x-bzip2':
    case 'application/x-freearc':
    case 'application/x-tar':
    case 'application/zip':
      return MimeCategory.Archive;
    case 'application/ogg':
    case 'application/x-cdf':
    case 'audio/3gpp':
    case 'audio/3gpp2':
    case 'audio/aac':
    case 'audio/midi':
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/opus':
    case 'audio/wav':
    case 'audio/webm':
    case 'audio/x-midi':
      return MimeCategory.Audio;
    case 'text/calendar':
      return MimeCategory.Calendar;
    case 'application/vnd.visio':
      return MimeCategory.Chart;
    case 'application/msword':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return MimeCategory.Document;
    case 'application/vnd.ms-fontobject':
    case 'font/otf':
    case 'font/ttf':
    case 'font/woff':
    case 'font/woff2':
      return MimeCategory.Font;
    case 'image/avif':
    case 'image/bmp':
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
    case 'image/tiff':
    case 'image/vnd.microsoft.icon':
    case 'image/webp':
      return MimeCategory.Image;
    case 'multipart/alternative':
    case 'multipart/form-data':
    case 'multipart/mixed':
    case 'multipart/related':
      return MimeCategory.Multipart;
    case 'application/pdf':
      return MimeCategory.PDF;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return MimeCategory.Slideshow;
    case 'application/vnd.ms-excel':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return MimeCategory.Spreadsheet;
    case 'application/epub+zip':
    case 'application/json':
    case 'application/ld+json':
    case 'application/rtf':
    case 'application/vnd.amazon.ebook':
    case 'application/vnd.mozilla.xul+xml':
    case 'application/x-abiword':
    case 'application/x-csh':
    case 'application/x-httpd-php':
    case 'application/x-sh':
    case 'application/xml':
    case 'application/xhtml+xml':
    case 'text/css':
    case 'text/csv':
    case 'text/html':
    case 'text/javascript':
    case 'text/plain':
    case 'text/xml':
      return MimeCategory.Text;
    case 'application/x-shockwave-flash':
    case 'video/3gpp':
    case 'video/3gpp2':
    case 'video/mp2t':
    case 'video/mp4':
    case 'video/mpeg':
    case 'video/ogg':
    case 'video/webm':
    case 'video/x-msvideo':
      return MimeCategory.Video;
    default:
      return fallback ? mimeCategory(mimeType(mime), false) : MimeCategory.Binary;
  }
}

/** Format out a mime part to a string body. */
export async function mimePartBody(part: MimePart) {
  let text: string[] = [];
  await mimePartBodyDeep(part, text, { boundary: 0 });
  return text.join('\r\n');
}

/** Recursively format out a MIME part to a string body. */
async function mimePartBodyDeep(part: MimePart, text: string[], state: { boundary: number }) {

  switch (part.type) {
  case 'text/csv':
  case 'text/css':
  case 'text/html':
  case 'text/javascript':
  case 'text/plain':
  case 'text/xml':
    text.push(`Content-Type: ${part.type}; charset=utf-8`);
    mimePartContent(part, text);
    text.push('');
    text.push(await binaryText(part.body));
    text.push('');
    break;
  case 'multipart/alternative':
  case 'multipart/form-data':
  case 'multipart/mixed':
  case 'multipart/related':
    let boundary = state.boundary++;
    text.push(`Content-Type: ${part.type}; boundary="${boundary}"`);
    text.push('');
    for (let subpart of part.body) {
      text.push(`--${boundary}`);
      await mimePartBodyDeep(subpart, text, state);
    }
    
    text.push(`--${boundary}--`);
    break;
  default:
    text.push(`Content-Type: ${part.type}`);
    text.push(`Content-Transfer-Encoding: base64`);
    mimePartContent(part, text);
    text.push('');
    text.push(await binaryBase64(part.body));
    text.push('');
  }
}

/** Write out headers for a content mime part. */
function mimePartContent(part: MimePartSimple | MimePartContent, text: string[]) {
  if (!('disposition' in part)) return;
  text.push(`Content-Disposition: ${part.disposition}; filename="${part.filename}"`);
  if (part.disposition === 'inline') text.push(`Content-ID: <${part.filename.replace(/ /g, '-')}>`);
}