<div class="row">
  <app-field class="fill">
    <app-label>Form</app-label>
    <app-sublabel>
      <app-hover-help tooltip="Form to display at top of dialog, defaulting to dispute ledger form."></app-hover-help>
    </app-sublabel>
    <app-resource-select collection="forms" [required]="false" [(ngModel)]="config._form"></app-resource-select>
  </app-field>
  <app-field class="fill">
    <app-label>Filter</app-label>
    <app-sublabel>
      <app-hover-help tooltip="List of ledger configs to allow user to select."></app-hover-help>
    </app-sublabel>
    <app-resource-select collection="ledgerConfigs" [multiple]="true" [required]="false" [(ngModel)]="config._filter"></app-resource-select>
  </app-field>
</div>
<app-labels>
  <app-label>Ledger Items</app-label>
  <app-sublabel>
    <app-hover-help tooltip="List of ledger items to pre-populate popup dialog."></app-hover-help>
  </app-sublabel>
</app-labels>
<app-edit-list [ngModel]="config.configs">
  <app-edit-list-item cdkDrag *ngFor="let config of config.configs">
    <app-field class="fill">
      <app-label>Ledger Config</app-label>
      <app-resource-select collection="ledgerConfigs" [(ngModel)]="config._config"></app-resource-select>
    </app-field>
    <app-field class="fill">
      <app-label>Condition</app-label>
      <app-resource-select [required]="false" collection="formulas" [(ngModel)]="config._condition"></app-resource-select>
    </app-field>
  </app-edit-list-item>
  <button crystal button-prefix="add" position="before" class="add" (click)="onCreate()">Add New</button>
</app-edit-list>