import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { AttachmentType } from '../../../../../../../common/code/standard/common';
import { DisputeType } from '../../../../../../../common/code/standard/disputes';
import { ErrorResponse } from '../../../../../../../common/message/error';
import { AttachmentUpload } from '../../../../../../../common/model/attachment';
import { DisputeFormula } from '../../../../../../../common/model/builtin/formula/dispute';
import { DocumentTemplateFormat } from '../../../../../../../common/model/document-template/format';
import { Formula } from '../../../../../../../common/model/formula/formula';
import { claimJoin } from '../../../../../../../common/toolbox/claim';
import { disputeTyped } from '../../../../../../../common/toolbox/dispute';
import { formulaRun } from '../../../../../../../common/toolbox/formula/formula';
import { idNull, idOmit, idOmitAll } from "../../../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../../../common/toolbox/message";
import { dateOffset } from "../../../../../../../common/toolbox/time";
import { TAB_DATA } from '../../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../../common/service/auth.service';
import { ClaimService } from '../../../../common/service/claim.service';
import { DevService } from '../../../../common/service/dev.service';
import { FormulaService } from '../../../../common/service/formula.service';
import { LogService } from '../../../../common/service/log.service';
import { SettingGroupService } from '../../../../common/service/setting-group.service';
import { fileAttachmentUpload, fileDownload } from '../../../../common/toolbox/file';
import { postRequest } from '../../../../common/toolbox/request';
import { ClaimAttachmentListComponent } from '../../attachment-list/claim-attachment-list.component';
import { CLAIM_INTAKE_STEP_STATE, ClaimIntakeState } from '../claim-intake.model';
import { ClaimIntakeStepComponent } from '../step/claim-intake-step.component';
import { ClaimIntakeSummaryData } from './claim-intake-summary.model';

@Component({
  selector: 'app-claim-intake-summary',
  templateUrl: './claim-intake-summary.component.html',
  styleUrls: ['./claim-intake-summary.component.scss']
})
export class ClaimIntakeSummaryComponent extends ClaimIntakeStepComponent<ClaimIntakeSummaryData> {

  /** Reference to attachment list within template. */
  @ViewChild(ClaimAttachmentListComponent) list!: ClaimAttachmentListComponent;
  
  /** True if currently loading. */
  loading = false;
  /** Formula to calculate due date. */
  dueFormula: Formula = new Formula();
  /** List of uploads for claim. */
  uploads?: AttachmentUpload[] = [];
  /** Claim document to display inside page. */
  image?: File;
  /** Claim document to download. */
  download?: File;

  constructor(
    @Inject(CLAIM_INTAKE_STEP_STATE) public state: ClaimIntakeState,
    @Inject(TAB_DATA) public override data: ClaimIntakeSummaryData,
    private auth: AuthService,
    private formulas: FormulaService,
    private log: LogService,
    private claimService: ClaimService,
    private http: HttpClient,
    private settingGroups: SettingGroupService,
    protected dev: DevService
  ) {
    super();
  }

  async ngOnInit(): Promise<any> {
    // Fetch investigation due date formula.
    let settings = await this.settingGroups.inst();
    let investigationDueDateFormulaId = settings.disputes[this.state.claim.type].intake.investigationDueDateFormula;

    if (!idNull(investigationDueDateFormulaId)) {
      let formula = await this.formulas.item({
        _inst: this.auth._inst,
        _id: investigationDueDateFormulaId
      });
      if (errorResponse(formula)) this.log.show(new ErrorResponse(`Error getting formula: ${DisputeFormula.InvestigationDueDate}`));
      else this.dueFormula = formula;
    }

    // Display template in browser as PNG, make a PDF available for download.
    let [png, pdf] = await Promise.all([
      this.intake(DocumentTemplateFormat.PNG),
      this.intake(DocumentTemplateFormat.PDF)
    ]);
    if (errorResponse(png)) return this.log.show(png);
    if (errorResponse(pdf)) return this.log.show(pdf);
    this.image = png;
    this.download = pdf;
  }

  async submit() {
    if (!this.download) return;

    // Set some compliance dates before submitting.
    this.state.disputes.forEach(dispute => {
      if (!disputeTyped(dispute, DisputeType.Card)) return;
      let endDate = formulaRun(this.dueFormula, { claim: this.state.claim });
      if (!(endDate instanceof Date)) return this.log.show(new ErrorResponse('Investigation due formula was not configured correctly. Please contact your administrator.'));

      let endDayOfWeek = endDate.getDay();
      if (endDayOfWeek == 6) endDate = dateOffset(endDate, 2); // Saturday
      else if (endDayOfWeek == 0) endDate = dateOffset(endDate, 1); // Sunday
      dispute.investigationDueDate = endDate; 
    });

    // Create intake document to attach to new claim.
    this.loading = true;
    let upload = await fileAttachmentUpload(this.download, AttachmentType.ClaimIntakeUnsigned);
    if (errorResponse(upload)) {
      this.log.show('Failed to generate attachment for claim');
      this.loading = false;
      return;
    }

    // Submit changes to uploads, add unsigned intake, run formula logic.
    this.list.confirm();
    this.list.add(upload);
    let result = await this.list.submit();
    if (errorResponse(result)) return;

    // Create new claim, attaching unsigned intake and explicit documents.
    let uploads = this.uploads ?? [];
    let response = await postRequest(this.http, 'claims', { items: [{ claim: idOmit(this.state.claim), disputes: idOmitAll(this.state.disputes), uploads }] });
    if (errorResponse(response)) {
      this.log.show(response);
      this.loading = false;
      return;
    }

    // Download intake document.
    this.claimService.added.next(response);
    await fileDownload(this.download);
    this.offset.emit(+1);
  }

  /** Download intake form. */
  private async intake(extension: DocumentTemplateFormat) {
    let join = claimJoin(this.state.claim, this.state.disputes);
    join.account = this.state.account;
    join.member = this.state.member;
    join.card = this.state.card;

    return await this.claimService.intakeDownload(join, this.state.transactions, extension);
  }
}
