import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkCondition } from '../../../../../../../common/model/work/condition';
import { WorkAction, WorkStep } from '../../../../../../../common/model/work/flow';

@Component({
  selector: 'app-setup-workflow-step',
  templateUrl: './setup-workflow-step.component.html',
  styleUrls: ['./setup-workflow-step.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupWorkflowStepComponent {
  /** Step being configured. */
  @Input() step = new WorkStep();

  /** Emits when an action is selected or created. */
  @Output() action = new EventEmitter<WorkAction>();
  /** Emits when a condition is selected or created. */
  @Output() condition = new EventEmitter<WorkCondition>();
}
