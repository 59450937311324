import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Job, JobPreview } from '../../../../../common/model/job/job';
import { ApplicationSettingsKey } from '../../../../../common/model/setting-group';
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { serviceSettingsItem } from '../toolbox/service';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { SettingGroupService } from './setting-group.service';

/** A query to fetch a specific job. */
export class JobQuery {
  constructor(
    /** ID of job. */
    public _id = ID_DEFAULT,
    /** Institution of job. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Caches information about jobs. */
@Injectable({
  providedIn: 'root'
})
export class JobService extends CachePreviewService<Job, JobQuery, JobPreview> {
  readonly route = 'jobs/preview';
  readonly Type = Job;

  /** Cache of global institution jobs. */
  protected globalcache = new Map<string, string[]>();

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private settings: SettingGroupService
  ) {
    super(JobQuery, log, dialog, http);
  }

  /** Fetch a job from settings. */
  setting(key: ApplicationSettingsKey) {
    return serviceSettingsItem('Job', this, this.auth, this.log, this.settings, key);
  }

  protected override multiple(queries: ArraySome<JobQuery>) {
    return getRequest(this.http, 'jobs', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}
