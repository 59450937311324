import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, merge, takeUntil } from 'rxjs';
import { DisplayType } from "../../../../../../common/model/display";
import { Permission } from '../../../../../../common/model/permission';
import { WorkQueue } from "../../../../../../common/model/work/queue";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { WorkQueueService } from '../../../common/service/work-queue.service';
import { getRequest } from '../../../common/toolbox/request';
import { ClientSource } from '../../../common/toolbox/source/client';

@Component({
  selector: 'app-work-queues',
  templateUrl: './work-queues.component.html',
  styleUrls: ['./work-queues.component.scss'],
  host: {
    class: 'flex column'
  }
})
export class WorkQueuesComponent {
  readonly Permission = Permission;
  
  /** Types of queues to search. */
  @Input() type = DisplayType.Account;
  /** Emits when an item is selected. */
  @Output() queue = new EventEmitter<WorkQueue>();

  /** List of work queues available to current user. */
  source = new ClientSource<WorkQueue>();
  /** Columns to display in grid. */
  columns: (keyof WorkQueue)[] = ['name', 'buildDate', 'worked'];

  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    private http: HttpClient,
    private log: LogService,
    private queues: WorkQueueService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    // Load new set of queues on changing institution, refreshing table or any queues are updated.
    merge(this.queues.updated)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.refresh());
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Refresh list of queues. */
  async refresh() {
    let queues = await getRequest(this.http, 'work-queues/assigned', { _inst: this.auth._inst, type: this.type });
    if (errorResponse(queues)) {
      this.log.show(queues);
      return;
    }

    this.source.items = queues;
  }

  /** Callback when selecting a queue. */
  onQueue(queue: WorkQueue) {
    this.queue.next(queue);
  }
}
