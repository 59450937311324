import { Permission } from "../permission";
import { Condition } from "./condition";
import { Expression } from "./expression";
import { Statement } from "./statement";
import { TerminalIdentifier, TerminalPermission, TerminalType } from "./terminal";

/** A placeholder for an expression or statement. */
export type Placeholder = PlaceholderExpression | PlaceholderStatement | PlaceholderCondition;

/** A placeholder for code blocks. */
export enum PlaceholderType {
  Expression = 'expression',
  Statement = 'statement',
  Condition = 'condition'
}

/** Basic configuration shared by all placeholders. */
export interface PlaceholderTag<E extends PlaceholderType> {
  /** Tagged type of placeholder. */
  type: E
}

export interface PlaceholderExpression extends PlaceholderTag<PlaceholderType.Expression> {}
export interface PlaceholderStatement extends PlaceholderTag<PlaceholderType.Statement> {}
export interface PlaceholderCondition extends PlaceholderTag<PlaceholderType.Condition> {}

/** Placeholder for expressions to be inserted into formulas. */
export const PLACEHOLDER_EXPRESSION = {
  type: PlaceholderType.Expression
} as unknown as Expression;

/** Placeholder for statements to be inserted into formulas. */
export const PLACEHOLDER_STATEMENT = {
  type: PlaceholderType.Statement
} as unknown as Statement;

/** Placeholder for conditions to be inserted into formulas. */
export const PLACEHOLDER_CONDITION = {
  type: PlaceholderType.Condition
} as unknown as Condition;

/** Placeholder for identifiers to be inserted into formulas. */
export const PLACEHOLDER_IDENTIFIER: TerminalIdentifier = {
  type: TerminalType.Identifier,
  key: ''
};

/** Placeholder for permissions to be inserted into formulas. */
export const PLACEHOLDER_PERMISSION: TerminalPermission = {
  type: TerminalType.Permission,
  value: Permission.System
};