import { Component } from "@angular/core";
import { ClaimFilter } from "../../../../../../../common/model/claim/filter";
import { MaybeId } from "../../../../../../../common/toolbox/id";

/** Interface that allows a component to configure a specific claim filter type. */
@Component({
  selector: 'app-claim-filter-accessor',
  template: ''
})
export abstract class ClaimFilterAccessor<T extends MaybeId<ClaimFilter> = MaybeId<ClaimFilter>> {

  /** Claim filter config being edited. */
  filter!: T;

  /** Called after configuration is bound. */
  reset() {}
}