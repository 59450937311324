import { OperatorType } from "../../formula/operator";
import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { BuiltinFormula } from "../formula";

/** Formulas that operate on members. */
export enum MemberFormula {
  Deceased  = 'Member - Deceased',
  Name      = 'Member - Name',
  TableName = 'Member - Table Name'
}

export const BUILTIN_MEMBER_FORMULAS: Record<MemberFormula, BuiltinFormula> = {
  [MemberFormula.Deceased]: {
    name: {
      bank: 'Customer Deceased',
      creditUnion: 'Member Deceased'
    },
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'member.deathDate'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: OperatorType.Join,
          left: {
            type: TerminalType.Array,
            expressions: [
              {
                type: TerminalType.String,
                value: 'Deceased ('
              },
              {
                type: TerminalType.Identifier,
                key: 'member.deathDate'
              },
              {
                type: TerminalType.String,
                value: ')'
              }
            ]
          },
          right: {
            type: TerminalType.String,
            value: ''
          }
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: ''
        }
      }]
    }]
  },
  [MemberFormula.Name]: {
    name: {
      bank: 'Customer - Name',
      creditUnion: 'Member - Name'
    },
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'member.isBusiness'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.Identifier,
          key: 'member.businessName'
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: OperatorType.Join,
          left: {
            type: TerminalType.Array,
            expressions: [
              {
                type: TerminalType.Identifier,
                key: 'member.firstName'
              },
              {
                type: TerminalType.Identifier,
                key: 'member.middleName'
              },
              {
                type: TerminalType.Identifier,
                key: 'member.lastName'
              }
            ]
          },
          right: {
            type: TerminalType.String,
            value: ' '
          }
        }
      }]
    }]
  },
  [MemberFormula.TableName]: {
    name: {
      bank: 'Customer - Table Name',
      creditUnion: 'Member - Table Name'
    },
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'member.isBusiness'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.Identifier,
          key: 'member.businessName'
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: OperatorType.Join,
          left: {
            type: TerminalType.Array,
            expressions: [
              {
                type: TerminalType.Identifier,
                key: 'member.lastName'
              },
              {
                type: TerminalType.String,
                value: ', '
              },
              {
                type: TerminalType.Identifier,
                key: 'member.firstName'
              },
              {
                type: TerminalType.String,
                value: ' '
              },
              {
                type: TerminalType.Identifier,
                key: 'member.middleName'
              }
            ]
          },
          right: {
            type: TerminalType.String,
            value: ''
          }
        }
      }]
    }]
  }
};