import { errorResponse } from "../../../../../common/toolbox/message";
import { MINUTE_MILLISECONDS } from "../../../../../common/toolbox/time";
import { AuthService } from "../service/auth.service";
import { IdleService } from "../service/idle.service";
import { LogService } from "../service/log.service";

/** Minimum interval frequency. */
export const INTERVAL_FREQUENCY_MIN = MINUTE_MILLISECONDS;
/** Minimum idle threshold for interval. */
export const INTERVAL_THRESHOLD_MIN = 5 * MINUTE_MILLISECONDS;

/** Configuration for a ping interval. */
export class IntervalOptions {
  constructor(
    /** Time that must elapse between timer calls, in milliseconds. */
    public frequency = MINUTE_MILLISECONDS,
    /** Idle threshold for issuing timer calls, in milliseconds. */
    public threshold = 5 * MINUTE_MILLISECONDS
  ) {}
}

/** A configurable interval that emits events. */
export class IntervalTimer implements IntervalOptions {

  get frequency() { return this.options.frequency; }
  set frequency(frequency: number) {
    this.config = new IntervalOptions(frequency, this.threshold);
  }

  get threshold() { return this.options.threshold; }
  set threshold(threshold: number) {
    this.config = new IntervalOptions(this.frequency, threshold);
  }

  /** Configuration for timer. */
  get config() { return new IntervalOptions(this.frequency, this.threshold); }
  set config(config: IntervalOptions) { this.reconfig(config); }

  /** Configuration for timer. */
  protected options: IntervalOptions;
  /** Interval for timer. */
  private interval?: number;

  constructor(
    /** Configuration for timer. */
    config: IntervalOptions,
    /** Callback to invoke. */
    public callback: () => void,
    /** Log service for messages. */
    private log: LogService,
    /** Authentication service to fetch user ID. */
    private auth: AuthService,
    /** Idle service to check for changes. */
    private idle: IdleService
  ) {
    this.options = config;
    this.restart();
  }

  /** Invoked when config changes. */
  protected save(_user: string) {}

  /** Restart internal timer. */
  private restart() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.idle.time.value > Math.max(this.threshold, INTERVAL_THRESHOLD_MIN)) return;
      this.callback();
    }, Math.max(this.options.frequency, INTERVAL_FREQUENCY_MIN)) as unknown as number;
  }

  /** Update configuration. */
  private async reconfig(config: IntervalOptions) {
    if (config.frequency === this.frequency && config.threshold === this.threshold) return;
    this.options = config;
    this.restart();
    
    let result = await this.save(this.auth.session._id);
    if (errorResponse(result)) this.log.show(result);
  }
}