import { ClaimStatus, DisputeStatus } from "../../code/standard/disputes";
import { ErrorResponse } from "../../message/error";
import { FormulaGetRequest } from "../../message/formula";
import { arrayCount, arrayLast, arraySingle, arraySome } from "../../toolbox/array";
import { claimDisputes, claimJoin, claimUnion } from "../../toolbox/claim";
import { disputeUnion } from "../../toolbox/dispute";
import { formulaRun } from "../../toolbox/formula/formula";
import { errorPartition, errorPartitionDefined, errorResponse } from "../../toolbox/message";
import { ClaimAttachment } from "../claim/attachment";
import { ClaimACH } from "../claim/claim";
import { DisplayPartial } from "../display";
import { Dispute } from "../dispute/dispute";
import { Formula } from "../formula/formula";
import { WorkCondition, WorkConditionClass, WorkConditionMode, WorkConditionType } from "./condition";
import { WorkContext } from "./context";
import { WorkAction, WorkStep, Workflow } from "./flow";
import { WorkPath } from "./path";
import { WorkActionStatus, WorkConditionResult, WorkStepStatus, WorkflowStatus } from "./status";

/** Ordering for each dispute status.
 *  TODO: Split this out into one list for each claim type.
 */
export const CLAIM_STATUS_ORDER: Record<ClaimStatus, number> = {
  [ClaimStatus.Initiated]: 0,
  [ClaimStatus.Credit]: 1,
  [ClaimStatus.Return]: 1,
  [ClaimStatus.Chargeback]: 2,
  [ClaimStatus.Resolved]: 3,
};

/** Ordering for each dispute status. */
export const DISPUTE_STATUS_ORDER: Record<DisputeStatus, number> = {
  [DisputeStatus.NotWorked]: 0,
  [DisputeStatus.MerchantRefunded]: 1,
  [DisputeStatus.Chargeback]: 2,
  [DisputeStatus.Representment]: 3,
  [DisputeStatus.Prearbitration]: 4,
  [DisputeStatus.Arbitration]: 5,
  [DisputeStatus.Approved]: 6,
  [DisputeStatus.Denied]: 6,
  [DisputeStatus.Withdrawn]: 6
};

/** Engine for executing a workflow. */
export abstract class WorkEngine {

  /** How to evaluate if a workflow condition has been fulfilled. */
  protected callback: { [T in WorkConditionType]: (condition: WorkConditionClass[T]) => Promise<WorkConditionResult | ErrorResponse> } = {
    [WorkConditionType.Attachment]: async condition => {
      let disputes = await this.filterDisputes(condition);
      if (errorResponse(disputes)) return disputes;
      let map = ClaimAttachment.map(disputes, this.context.attachments, this.context.claim?.attachments);

      switch (condition.mode) {
        case WorkConditionMode.DisputesAny: {
          // Check if attached to any dispute.
          let value = disputes.some(dispute => !!map.get(dispute._id)?.find(a => a.type === condition.attachmentType));
          return new WorkConditionResult(+!!value);
        }
        case WorkConditionMode.DisputesAll: {
          // Check if attached to all disputes.
          let value = arrayCount(disputes, dispute => !!map.get(dispute._id)?.find(a => a.type === condition.attachmentType));
          return new WorkConditionResult(value, disputes.length);
        }
        case undefined: {
          // Check if in top level list of attachments.
          let value = this.context.attachments?.some(attachment => attachment.type === condition.attachmentType);
          return new WorkConditionResult(+!!value);
        }
      }
    },
    [WorkConditionType.Formula]: async condition => {
      let _inst = this.context.claim?._inst ?? this.context.dispute?._inst;
      if (!_inst) return new ErrorResponse('No value provided to determine current institution.');

      // Fetch formula referenced by this condition.
      let formulas = await this.formula({ _insts: [_inst], _ids: [condition._formula] });
      if (errorResponse(formulas)) return formulas;
      if (!arraySingle(formulas)) return new ErrorResponse(`Could not find formula: ${condition._formula}`);
      let formula = formulas[0];

      // Filter visible list of disputes.
      let disputes = await this.filterDisputes(condition);
      if (errorResponse(disputes)) return disputes;
      
      // Evaluate formulas.
      let claim = claimJoin(this.context.claim ?? new ClaimACH(), disputes);
      switch (condition.mode) {
      case WorkConditionMode.DisputesAny:
        // Evaluate formula for each dispute.
        let value = arrayCount(disputes, dispute => {
          let input = this.fallback({ claim: claimUnion(claim), dispute: disputeUnion(dispute) });
          return formulaRun(formula, input);
        });
        return new WorkConditionResult(value, disputes.length);
      case WorkConditionMode.DisputesAll:
        // Evaluate formula for each dispute.
        let output = disputes.reduce((prev, dispute) => {
          let input = this.fallback({ claim: claimUnion(claim), dispute: disputeUnion(dispute) });
          let output = formulaRun(formula, input);
          return prev && !!output;
        }, true);
        return new WorkConditionResult(+output, 1);
      case undefined:
        // Evaluate formula at main level.
        let input = this.fallback({ claim: claimUnion(claim), dispute: disputeUnion(this.context.dispute) });
        return new WorkConditionResult(+!!formulaRun(formula, input));
      }
    },
    [WorkConditionType.ClaimStatus]: async condition => {
      let status = this.context.claim?.status ?? ClaimStatus.Initiated;
      return new WorkConditionResult(
        condition.exact
          ? +(status === condition.status)
          : +(CLAIM_STATUS_ORDER[status] >= CLAIM_STATUS_ORDER[condition.status])
      );
    },
    [WorkConditionType.DisputeStatus]: async condition => {
      // Filter visible list of disputes.
      let disputes = await this.filterDisputes(condition);
      if (errorResponse(disputes)) return disputes;

      // Check exact status or at/past step based on workflow configuration.
      let statusCheck = condition.exact
        ? (dispute: Dispute) => dispute.status === condition.status
        : (dispute: Dispute) => DISPUTE_STATUS_ORDER[dispute.status] >= DISPUTE_STATUS_ORDER[condition.status];

      switch (condition.mode) {
      case WorkConditionMode.DisputesAll:
        // Check all disputes pass status.
        return new WorkConditionResult(+disputes.every(statusCheck));
      case WorkConditionMode.DisputesAny:
        // Check at least one dispute's status passes.
        return new WorkConditionResult(arrayCount(disputes, statusCheck), disputes.length);
      case undefined:
        // Check current visible dispute.
        return new WorkConditionResult(+(this.context.dispute ? statusCheck(this.context.dispute) : 0));
      }
    }
  }

  /** Current execution context of engine. */
  protected context = new WorkContext();
  /** Current workflow being walked. */
  private workflow = new Workflow();
  /** Path of executed steps and actions. */
  private path = new WorkPath();

  /** Execute engine on provided workflow. */
  async execute(workflow: Workflow, context: WorkContext): Promise<WorkflowStatus | ErrorResponse> {
    this.workflow = workflow;
    this.context = context;
    let status = new WorkflowStatus();
    if (!this.workflow.steps.length) return status;

    // Walk through workflow and evaluate visible steps/actions.
    this.path.clear();
    let result = await this.runWorkflow() ?? status;
    return result;
  }

  /** Re-execute given action of workflow for debugging. */
  async executeAction(action: WorkActionStatus, context: WorkContext) {
    this.context = context;
    await this.runActions([action.action]);
  }

  /** Produce fallback values for a display value. */
  protected abstract fallback(partial: DisplayPartial): DisplayPartial;
  /** Get specified formulas. */
  protected abstract formula(_request: FormulaGetRequest): Promise<Formula[] | ErrorResponse>;

  /** Walk through workflow, determining visible steps and actions. */
  private async runWorkflow(): Promise<WorkflowStatus | ErrorResponse> {
    let status = new WorkflowStatus();

    // Evaluate list of workflow-level actions.
    let workflowActionResult = await this.runActions(this.workflow.actions);
    if (errorResponse(workflowActionResult)) return workflowActionResult;
    status.actions = workflowActionResult;

    // Determine visible list of steps.
    let [error, steps] = errorPartitionDefined('There was an error evaluating steps.', await Promise.all(this.workflow.steps.map(async step => {
      let [error, enables] = errorPartition(
        'There was an error evaluating enable conditions',
        await Promise.all((step.enable ?? []).map(condition => this.callback[condition.type](condition as any)))
      );
      if (error) return error;

      // Return if all conditions return a truthy value.
      return enables.every(e => e.complete) ? step : undefined;
    })));
    if (error) return error;
    if (!steps.length) return status;

    // Evaluate step-level list of actions.
    let [statusErrors, statusSteps] = errorPartition("There was an error evaluating actions.", await Promise.all(steps.map(step => this.runStep(step, status))));
    if (statusErrors) return statusErrors;
    if (!arraySome(statusSteps)) return status;

    // Update workflow with list of steps.
    status.steps = statusSteps;
    status.step = arrayLast(statusSteps);
    status.current = status.steps.length - 1;
    return status;
  }

  /** Execute a single workflow step. */
  private async runStep(step: WorkStep, status: WorkflowStatus) {
    let stepActions = await this.runActions(step.actions);
    if (errorResponse(stepActions)) return stepActions;
    return new WorkStepStatus(step.name, [...stepActions, ...status.actions], step);
  }

  /** Filter actions to visible ones and evaluate status. */
  private async runActions(actions?: WorkAction[]) {
    let [error, available] = errorPartitionDefined(
      'There was an error evaluating action visibility.',
      await Promise.all((actions ?? []).map(async action => {

      // Filter out all actions that are not enabled
      if (action.enable.length) {
        // All conditions must return a truthy value in order to enable an action.
        let enables = await this.runConditions(action.enable, (status, result) => {
          status.value = +(!!status.value && !!result.value);
          return status;
        }, new WorkActionStatus('', '', [], 1));
        if (errorResponse(enables)) return enables;
        if (!enables.value) return;
      }
      
      // Accumulate all conditions into single status.
      let status = await this.runConditions(action.completion, (status, condition) => {
        status.max += condition.max;
        status.value += condition.value;
        if (condition.status !== undefined) {
          status.status = status.status ? Math.max(status.status, condition.status) : condition.status;
        }
        
        return status;
      }, new WorkActionStatus(action.name, action.description, action._tasks, 0, 0, undefined, action));

      return status;
    })));
    return error ?? available;
  }

  /** Evaluate an array of work conditions, combining them using the specified reducer */
  private async runConditions(conditions: WorkCondition[], reducer: (status: WorkActionStatus, result: WorkConditionResult) => WorkActionStatus, defaultStatus = new WorkActionStatus()): Promise<ErrorResponse | WorkActionStatus> {
    // Get the results from all conditions.
    let [error, results] = errorPartition(
      'There was an error evaluating conditions',
      await Promise.all(conditions.map(condition => this.callback[condition.type](condition as any)
    )));
    return error ?? results.reduce(reducer, defaultStatus);
  }

  /** Apply a condition filter to displayed list of disputes. */
  private async filterDisputes({ _filters }: { _filters?: string[] }): Promise<Dispute[] | ErrorResponse> {
    let disputes = this.context.dispute ? [this.context.dispute] : claimDisputes(this.context.claim);
    if (!_filters?.length) return disputes;
    
    // Determine institution to fetch formula.
    let _inst = this.context.claim?._inst ?? this.context.dispute?._inst;
    if (!_inst) return new ErrorResponse('No value provided to determine current institution.');

    // Fetch formula referenced by this condition.
    let formulas = await this.formula({ _insts: [_inst], _ids: _filters });
    if (errorResponse(formulas)) return formulas;
    // Filter list of disputes.
    let filters = formulas;
    return disputes.filter(dispute => filters.every(filter => !!formulaRun(filter, { dispute })));
  }
};
