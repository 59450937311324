import { Pipe } from '@angular/core';
import { propinfoProperty } from '../../../../../common/info/prop';
import { NestedKey } from '../../../../../common/toolbox/keys';
import { titleCase } from "../../../../../common/toolbox/string";

@Pipe({
  name: 'propertyName'
})
export class PropertyNamePipe<T> {
  transform(key: NestedKey<T>, value: T) {
    return propinfoProperty(key, value)?.name ?? titleCase(key);
  }
}
