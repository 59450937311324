import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DisplayPartial, DisplayValue } from '../../../../../../../common/model/display';
import { Formula } from '../../../../../../../common/model/formula/formula';
import { StatementType } from '../../../../../../../common/model/formula/statement';
import { TerminalType } from '../../../../../../../common/model/formula/terminal';
import { arrayUnique } from '../../../../../../../common/toolbox/array';
import { blockIdentifiers } from '../../../../../../../common/toolbox/formula/block';
import { formulaRun } from '../../../../../../../common/toolbox/formula/formula';
import { ID_DEFAULT } from '../../../../../../../common/toolbox/id';
import { keyNestedFilter, keyNestedGet } from '../../../../../../../common/toolbox/keys';
import { FormService } from '../../../service/form.service';
import { FormulaService } from '../../../service/formula.service';
import { setupElementMake } from '../../../toolbox/element/setup';
import { FormChange, FormConfig, FormListConfig } from '../form.model';

/** Fallback formula that always returns true. */
export const FORMULA_TRUE: Formula = {
  ...new Formula(),
  statements: [{
    type: StatementType.Return,
    expression: {
      type: TerminalType.Boolean,
      value: true
    }
  }]
};

/** Fallback formula that always returns zero dollars. */
export const FORMULA_ZERO: Formula = {
  ...new Formula(),
  statements: [{
    type: StatementType.Return,
    expression: {
      type: TerminalType.Currency,
      value: 0
    }
  }]
};

/** Form configuration with additional form list fields attached. */
interface SubformConfig extends FormConfig {
  /** Formula to determine form is visible. */
  formula: Formula
  /** Pre-calculated identifiers used by formula. */
  keys: Set<string>
  /** Last recorded set of errors on form. */
  errors: string[]
  /** True if this form is visible. */
  visible: boolean
}

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss']
})
export class FormListComponent {

  /** Configuration for form list. */
  get config() { return this._config; }
  @Input() set config(config: FormListConfig | undefined) {
    this._config = config;
    this.reconfigure(config);
  }
  
  /** Emits on value changed */
  @Output() changed = new EventEmitter<FormChange>();
  /** Emits when list of errors change. */
  @Output() errors = new EventEmitter<string[]>();

  /** True if form list is currently valid. */
  valid = true;
  
  /** List of form configurations. */
  protected configs: SubformConfig[] = [];
  /** Input for forms. */
  private value: DisplayPartial = {};
  /** Mutex to prevent excess change propagations down to subforms. */
  private propagate = false;
  /** Subscription to dirty changes. */
  private subscription = Subscription.EMPTY;

  /** Last config bound to form list. */
  private _config?: FormListConfig;

  constructor(
    public forms: FormService,
    public formulas: FormulaService,
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit() {
    this.propagate = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /** Respond to error state changes in subforms. */
  onErrors(errors: string[], config: SubformConfig) {
    config.errors = errors;
    this.reerror();
  }

  /** Trigger visibility and field values for each form. */
  onChange(change: FormChange, config?: SubformConfig) {
    if (!this.propagate) return;
    this.propagate = false;

    for (let c of this.configs) {
      // Send updates down to form.
      if (c === config) continue;

      // Recalculate visibility for form.
      if (c.keys.has(change.key)) {
        c.visible = formulaRun(c.formula, this.value);
      }

      // Let form respond to changes if visible.
      if (!c.visible) continue;
      c.changes?.next(change);
    }

    this.propagate = true;
    if (config) this.changed.next(change);
    this.reerror();
  }

  /** Refresh list of forms after getting new config. */
  private async reconfigure(config?: FormListConfig) {
    if (!config) return;
    setupElementMake(this.elementRef.nativeElement, 'formLists', config.list._id);

    // Fetch list of forms and formulas in parallel.
    let _inst = config.list._inst;
    let sections = [...config.list.sections];
    let [forms, formulas] = await Promise.all([
      this.forms.items(arrayUnique(sections, '_form').map(section => ({ _inst, _id: section._form }))),
      this.formulas.items(arrayUnique(sections, '_formula').map(section => ({ _inst, _id: section._formula ?? ID_DEFAULT })))
    ]);

    // Pipe configs down to each form.
    this.value = config.value ?? {};
    this.configs = sections.map(section => {
      let [form, formula] = [forms.find(f => f._id === section._form)!, formulas.find(f => f._id === section._formula) ?? FORMULA_TRUE];

      return {
        ...config, form, formula,
        changes: new Subject(),
        errors: new Array<string>(),
        keys: new Set(blockIdentifiers(formula.statements)),
        visible: formulaRun(formula, this.value),
        actions: false
      }
    });

    // Propagate dirty changes up out of form list.
    if (config.dirty) {
      let value = new DisplayValue();
      this.subscription.unsubscribe();
      this.subscription = config.dirty.subscribe(keys => {
        for (let key of keyNestedFilter(value, keys)) {
          this.onChange(new FormChange(key, keyNestedGet(key, this.value)));
        }
      });
    }
  }

  /** Update error status. */
  private reerror() {
    let errors = this.configs.flatMap(config => config.visible ? config.errors : []);
    this.errors.next(errors);
    this.valid = !errors.length;
  }
}
