import { TypeInfo } from "../../info/type";
import { UnionInfo } from "../../info/union";
import { ArrayValidator } from "../../validator/array";
import { PropertyType } from "../property-type";

/** Configuration for a specific field type. */
export type FieldConfig =
  | FieldBoolean
  | FieldCode
  | FieldEmpty<PropertyType.Currency>
  | FieldDate
  | FieldEmpty<PropertyType.Email>
  | FieldEmpty<PropertyType.Member>
  | FieldEmpty<PropertyType.Number>
  | FieldEmpty<PropertyType.Phone>
  | FieldString
  | FieldEmpty<PropertyType.Transaction>
  | FieldEmpty<PropertyType.User>;

/** Tagged type of all field types. */
export interface FieldConfigTag<T extends PropertyType = PropertyType> {
  /** Type of field. */
  type: T
}

/** Empty code field configuration. */
export class FieldEmpty<T extends PropertyType> implements FieldConfigTag<T> {
  constructor(
    readonly type: T
  ) {}
}

/** Configuration for a code field. */
export class FieldCode implements FieldConfigTag<PropertyType.Code> {
  readonly type = PropertyType.Code;

  constructor(
    /** Force a sort by key. */
    public sort?: boolean,
    /** Prefix for select dropdown value. */
    public prefix?: [string, string],
    /** Suffix for select dropdown value. */
    public suffix?: [string, string],
    /** Filter for list of dropdown values. */
    public filter?: string[]
  ) { }

  static typeinfo: TypeInfo<FieldCode> = {
    sort: false,
    prefix: new ArrayValidator('', 2, 2, false),
    suffix: new ArrayValidator('', 2, 2, false),
    filter: ['']
  }
}

/** Configuration for a date field. */
export class FieldDate implements FieldConfigTag<PropertyType.Date> {
  readonly type = PropertyType.Date;
}

/** Configuration for a string field. */
export class FieldString implements FieldConfigTag<PropertyType.String> {
  readonly type = PropertyType.String;

  constructor(
    /** Minimum length of this field. */
    public minLength?: number,
    /** Maximum length of this field. */
    public maxLength?: number,

  ) { }

  static typeinfo: TypeInfo<FieldString> = {
    minLength: 0,
    maxLength: 0,
  }
}

/** Configuration for a boolean field. */
export class FieldBoolean implements FieldConfigTag<PropertyType.Boolean> {
  readonly type = PropertyType.Boolean;

  constructor(
    /** Required value of this boolean. */
    public require?: boolean,
    /** Override for on label. */
    public on?: string,
    /** Override for off label. */
    public off = on
  ) { }

  static typeinfo: TypeInfo<FieldBoolean> = {
    require: true,
    on: '',
    off: ''
  }
}

/** Mapping of field types to configuration objects. */
export class FieldConfigClass {
  [PropertyType.Boolean] = new FieldBoolean();
  [PropertyType.Code] = new FieldCode();
  [PropertyType.Currency] = new FieldEmpty(PropertyType.Currency);
  [PropertyType.Date] = new FieldDate();
  [PropertyType.Email] = new FieldEmpty(PropertyType.Email);
  [PropertyType.Member] = new FieldEmpty(PropertyType.Member);
  [PropertyType.Number] = new FieldEmpty(PropertyType.Number);
  [PropertyType.Phone] = new FieldEmpty(PropertyType.Phone);
  [PropertyType.String] = new FieldString();
  [PropertyType.Transaction] = new FieldEmpty(PropertyType.Transaction);
  [PropertyType.User] = new FieldEmpty(PropertyType.User);
}

/** Union type information for fields. */
export const FIELD_CONFIG_UNIONINFO: UnionInfo<FieldConfig, PropertyType> = {
  tag: 'type',
  classes: new FieldConfigClass()
}