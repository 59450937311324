<app-setup-job-payload-notification *ngIf="!personal" [job]="job" (selected)="dirty.emit()"></app-setup-job-payload-notification>
<div class="row">
  <app-field class="fill" *ngIf="categories.length > 1">
    <app-label>Category</app-label>
    <app-select [(ngModel)]="job.payload.category" (selected)="onCategory(); dirty.emit()">
      <app-option *ngFor="let c of categories" [pair]="c"></app-option>
    </app-select>
  </app-field>
  <app-field class="fill">
    <app-label>Result</app-label>
    <app-select [(ngModel)]="job.payload.result" (selected)="dirty.emit()">
      <app-option *ngFor="let t of types" [pair]="t"></app-option>
    </app-select>
  </app-field>
</div>