import { arrayEmpty } from "../toolbox/array";
import { enumSet } from "../toolbox/enum";
import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";

/** Validate object belongs to a list of values. */
export class EnumValidator<T = string> extends Validator<T> {

  /** List of values to pull from. */
  public values: Set<T>;
  /** True if numbers must be coerced to numbers. */
  private numbers: boolean;

  constructor(
    /** Enumeration or set to pull values from. */
    values: { [key: number]: any } | Set<T> | T[]
  ) {
    super();
    if (values instanceof Set) this.values = values;
    else if (Array.isArray(values)) this.values = new Set(values);
    else this.values = enumSet(values);
    this.numbers = [...this.values.values()].every(value => typeof value === 'number');
  }

  override value(): T {
    return this.values.values().next().value;
  }

  parse(value: string): any {
    if (!this.numbers) return value;

    let number = +value;
    return isNaN(number) ? undefined : number;
  }

  schema() {
    if (this.property) return this.property;
    let values = [...this.values.values()] as T[];
    if (arrayEmpty(values)) return {
      bsonType: 'undefined' as const,
      description: 'Failed to generate schema: enumeration was empty.'
    }

    if (typeof values[0] === 'string') return {
      bsonType: 'string' as const,
      enum: values
    }

    return {
      bsonType: 'int' as const,
      enum: values
    }
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    if (this.values.has(value)) return ValidationStatus.Okay;
    return ValidationStatus.Error;
  }

  override logs() {
    return [new ValidatorLog(`Expected one of: ${[...this.values].join(', ')}`)];
  }
}