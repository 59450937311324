import { objectEqual, objectType, PartialDeep } from "./object";

/** A diff of two objects. */
export class Diff<T extends Object = any> {
  /** Properties of old object. */
  from = {} as PartialDeep<T>;
  /** Properties of new object. */
  to = {} as PartialDeep<T>;

  /** True if from and to are exactly equal. */
  static equal(diff: Diff) {
    return objectEqual(diff.from, diff.to);
  }

  constructor(
    /** Old object to compare. */
    from: T,
    /** New object to compare. */
    to: T
  ) {
    this.walk(this.from, this.to, from, to, from, to, []);
    this.walk(this.to, this.from, to, from, to, from, []);
  }

  /** Deeply get diff of two objects. */
  private walk(fromDiff: Record<string, any>, toDiff: Record<string, any>, fromRoot: any, toRoot: any, from: any, to: any, path: string[]) {
    switch (objectType(from)) {
    case 'array':
    case 'object':
      for (let key in from) {
        path.push(key);
        this.walk(fromDiff, toDiff, fromRoot, toRoot, from[key], to instanceof Object ? to[key] : undefined, path);
        path.pop();
      } break;
    case 'date':
      if (+from !== +to) {
        this.add(fromDiff, toDiff, fromRoot, toRoot, path, 0);
      } break;
    default:
      if (from !== to) {
        this.add(fromDiff, toDiff, fromRoot, toRoot, path, 0);
      }
    }
  }

  /** Add property to a diff. */
  private add(fromDiff: any, toDiff: any, from: any, to: any, path: string[], i: number) {
    let key = path[i]!;
    let fromSub = from instanceof Object ? from[key] : undefined;
    let toSub = to instanceof Object ? to[key] : undefined;
    
    switch (path.length - i) {
    case 0:
      // Path was empty array.
      break;
    case 1:
      // Assign value.
      fromDiff[key] = fromSub;
      if (toDiff instanceof Object) (toDiff as any)[key] = toSub;
      break;
    default:
      // Ensure path so far exists in diff.
      if (!(fromDiff[key] instanceof Object)) {
        // Add missing subobject.
        switch (objectType(fromSub)) {
        case 'object':
          fromDiff[key] = {};
          break;
        case 'array':
          fromDiff[key] = [];
          break;
        }

        switch (objectType(toSub)) {
        case 'object':
          toDiff[key] = {};
          break;
        case 'array':
          toDiff[key] = [];
          break;
        }
      }

      this.add(
        fromDiff instanceof Object ? (fromDiff as any)[key] : undefined,
        toDiff instanceof Object ? (toDiff as any)[key] : undefined,
        fromSub, toSub, path, i + 1
      );
    }
  }
}

/** Result from diffing an uploaded value with a diffed value. */
export class DiffResult<T extends Object = any> {
  constructor(
    /** Uploaded value. */
    public uploaded: T | undefined,
    /** Current value. */
    public current: T | undefined,
    /** True if there is a difference. */
    public dirty = false,
    /** Difference of values. */
    public diff: Diff<T> | undefined
  ) {}
}