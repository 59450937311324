import { DisplayPartial, DisplayValue } from "../../../../../../../common/model/display";
import { Model } from "../../../../../../../common/model/model";
import { Column, Table } from "../../../../../../../common/model/table";
import { Newable } from "../../../../../../../common/toolbox/object";
import { GridSource } from "../../../toolbox/source/grid";
import { Formula } from "../../../../../../../common/model/formula/formula";

/** Configuration for model table. */
export class GridConfig<T extends DisplayPartial = DisplayPartial> {
  constructor(
    /** Source to be shared with table. */
    public source: GridSource<T>,
    /** Table configuration. */
    public table = new Table(),
    /** Override for table model. */
    public model?: Model,
    /** Highlight formula for table. */
    public highlightFormula?: Formula
  ) {}
}

/** Column utilizing a custom component to inject. */
export interface GridColumn<D extends DisplayValue = DisplayValue> extends Column<D> {
  /** Component to display in this column. */
  component?: string | Newable<any>
}