import { Validator } from "./base";

/** Null validator that allows any object to pass. */
export class AnyValidator extends Validator<any> {
  constructor() { super(true); }

  value(): any { return undefined; }

  parse(_: string) { return _ as any; }

  schema() {
    return this.property;
  }
}