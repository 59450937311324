import { ImportCode } from "../code/system";
import { PropertyInfo } from "../info/prop";
import { TypeInfo } from "../info/type";
import { PropertyType } from "../model/property-type";

/** One of many emails a member can be contacted with. */
export class Email {
  constructor(
    /** Email address. */
    public address = '',
    /** Host-provided type code for address. */
    public type = '',
    /** True if this is an imported address and cannot be modified. */
    public system?: boolean
  ) {}

  static typeinfo: TypeInfo<Email> = {
    address: '',
    system: false
  }

  static propinfo: PropertyInfo<Email> = {
    type: { type: PropertyType.Code, category: ImportCode.Email }
  }

  /** Emit address of an email. */
  static address(email: Email) {
    return email.address;
  }
}