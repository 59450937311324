import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormAccessor } from '../form-accessor';

@Component({
  selector: 'app-form-currency',
  templateUrl: './form-currency.component.html',
  styleUrls: ['./form-currency.component.scss']
})
export class FormCurrencyComponent extends FormAccessor<string | undefined> {
  /** Reference to currency input. */
  @ViewChild('currencyInput', { static : true }) inputRef!: ElementRef<HTMLInputElement>;

  /** Minimum value. */
  min?: number;
  /** Maximum value. */
  max?: number;
}