import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { arraySum } from '../../../../../../common/toolbox/array';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { PercentItemColor } from '../../../common/component/percent/percent.model';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { TabService } from '../../../common/service/tab.service';
import { postRequest } from '../../../common/toolbox/request';
import { ClaimResultsComponent } from '../../claim/results/claim-results.component';
import { ClaimResultsData } from '../../claim/results/claim-results.model';
import { ExecutiveReport } from './executive-report.model';

@Component({
  selector: 'app-executive-report',
  templateUrl: './executive-report.component.html',
  styleUrls: ['./executive-report.component.scss']
})
export class ExecutiveReportComponent {

  /** Set report to be displayed. */
  @Input() set report(report: ExecutiveReport) {
    this.data = report;
    this.metrics = PercentItemColor.from(report.metrics);
    this.total = arraySum(report.metrics.map(({ value }) => value));
  }

  /** Report currently being displayed. */
  data = new ExecutiveReport();
  /** List of metric rows to display. */
  metrics: PercentItemColor[] = [];
  /** Total of all metrics. */
  total = 0;


  constructor(
    private http: HttpClient,
    private log: LogService,
    private auth: AuthService,
    private tabs: TabService
  ) {

  }
  
  public async onReport() {
    let request = {
      _insts: [this.auth._inst],
      conditions: this.data.conditions
    };
    let response =  await postRequest(this.http, 'claims/report', request);
    if (errorResponse(response)) return this.log.show(response);

    this.tabs.open(ClaimResultsComponent, new ClaimResultsData(request, response));

  }
}
