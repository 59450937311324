import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountCategory } from '../../../../../../common/code/standard/common';
import { ClaimType } from '../../../../../../common/code/standard/disputes';
import { CommonCode, DisputesCode } from '../../../../../../common/code/system';
import { AccountJoinClass } from '../../../../../../common/model/account/account';
import { ClaimDisputesType, ClaimJoinClass, ClaimUnion } from '../../../../../../common/model/claim/claim';
import { DisputeJoin, DisputeJoinACH, DisputeJoinClass, DisputeUnion } from '../../../../../../common/model/dispute/dispute';
import { Member } from '../../../../../../common/model/member';
import { Transaction } from '../../../../../../common/model/transaction';
import { arrayFill, arraySome } from '../../../../../../common/toolbox/array';
import { enumMapPairs } from '../../../../../../common/toolbox/enum';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { safeAssign } from '../../../../../../common/toolbox/object';
import { randomAccountNumber, randomAmount, randomDigits, randomName, randomRange } from '../../../../../../common/toolbox/random';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { SetupOptions } from '../../../common/toolbox/fusion';
import { TASK_MODE_NAME, TaskMode } from '../../task/task.model';
import { DisplaySetup, displaySetup } from '../setup.model';

@Component({
  selector: 'app-setup-preview-button',
  templateUrl: './setup-preview-button.component.html',
  styleUrls: ['./setup-preview-button.component.scss']
})
export class SetupPreviewButtonComponent {
  /** List of task modes for preview selection. */
  readonly TASK_MODES = enumMapPairs(TASK_MODE_NAME);
  readonly CommonCode = CommonCode;
  readonly DisputesCode = DisputesCode;
  
  /** Mode for previewing tasks. */
  @Input() mode = TaskMode.Account;
  /** Value to preview. */
  @Input() partial = displaySetup();
  /** True to show task mode. */
  @Input() showMode = false;

  /** Emits when task mode changes. */
  @Output() modeChange = new EventEmitter<TaskMode>();
  /** Emits when preview changes. */
  @Output() partialChange = new EventEmitter<DisplaySetup>();

  /** Type of account to open in preview. */
  protected accountCategory = AccountCategory.Loan;
  /** Type of claim to open in preview. */
  protected claimType = ClaimType.ACH;

  constructor(
    private auth: AuthService,
    private log: LogService
  ) {}

  async ngOnInit() {
    // Load previously-saved task setup options.
    let options = await DB.get('setupOptions', this.auth.session._id);
    if (errorResponse(options)) return this.log.show(options);
    if (options?.task) safeAssign<SetupPreviewButtonComponent>(this, options.task);

    // Set initial preview value.
    this.refresh();
    this.modeChange.next(this.mode);
  }

  /** Callback when new mode selected. */
  protected onMode(mode: TaskMode) {
    this.modeChange.next(mode);
    this.save();
  }

  /** Callback when account category or claim type changes. */
  protected onChanges() {
    this.save();
    this.refresh();
  }

  /** Save current task options. */
  private async save() {
    let result = await DB.partial('setupOptions', {
      task: {
        mode: this.mode,
        accountCategory: this.accountCategory,
        claimType: this.claimType
      },
    }, new SetupOptions(this.auth.session._id));
    if (errorResponse(result)) return this.log.show(result);
  }

  /** Calculate a new preview value. */
  private refresh() {
    let member = new Member();
    safeAssign(member, randomName());

    let account = new AccountJoinClass()[this.accountCategory];
    account.number = randomAccountNumber();
    account.members = [member];
    
    let claim = new ClaimJoinClass()[this.claimType];
    claim.account = account;
    claim.member = member; 

    let dispute: DisputeJoin = new DisputeJoinACH();
    if (claim.type !== ClaimType.CreditBureau) {
      let type: ClaimDisputesType = claim.type;

      let disputes =  arrayFill(randomRange(1, 7), () => {
        let dispute = new DisputeJoinClass()[type];
        dispute.transaction = new Transaction();
        dispute.transaction.amount = randomAmount(2000, 10000);
        dispute.transaction.description = `TRANSACTION DESCRIPTION #${randomDigits(1, 9999)}`;
        return dispute;
      });

      claim.disputes = disputes as any[];
      if (arraySome(disputes)) dispute = disputes[0];
    }

    this.partialChange.next(this.partial = {
      account,
      claim: claim as ClaimUnion,
      dispute: dispute as DisputeUnion,
      member
    });
  }
}
