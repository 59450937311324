import { Pipe } from '@angular/core';
import { Institution } from "../../../../../common/model/institution";

@Pipe({
  name: 'institution'
})
export class InstitutionPipe {

  transform(value: Institution): string {
    return `${value.hostId.padStart(10, '0')} - ${value.name}`;
  }
}
