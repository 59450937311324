<header>
  <button icon-button type="button" class="display" (click)="onHeader()">{{header}}</button>
  <button icon-button type="button" class="offset" (click)="onOffset(-1)">expand_less</button>
  <button icon-button type="button" class="offset" (click)="onOffset(1)">expand_more</button>
</header>
<div class="calendar" [class.big-calendar]="zoom !== Zoom.Days">
  <div class="cell" *ngFor="let c of cells" [attr.disabled]="c.disabled ? '' : null" [ngClass]="{ selected: c.selected, today: c.today, active: c.active }" (click)="onSelect(c)" (mouseenter)="onHover(c.date)">
    <div class="title">{{c.display}}</div>
    <app-badge color="primary" *ngIf="c.events.length">{{c.events.length}}</app-badge>
  </div>
</div>