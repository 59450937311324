import { Injectable } from "@angular/core"
import { AccountUnion } from "../../../../../common/model/account/account"
import { DisplayPartial } from "../../../../../common/model/display"
import { Email } from "../../../../../common/model/email"
import { Model } from "../../../../../common/model/model"
import { claimCard } from "../../../../../common/toolbox/claim"
import { modelValue } from "../../../../../common/toolbox/model"
import { AuthService } from "./auth.service"

/** Utilities for working with display values. */
@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  constructor(
    private auth: AuthService
  ) { }

  /** Apply default fallbacks to a partial display value. */
  fallback(partial: DisplayPartial, model?: Model): DisplayPartial  {

    // WARNING: Do not implement fallback for dispute.
    // Some app logic checks for existence of dispute to signal claim vs dispute logic.
    // WARNING: Do not implement fallback for ledger.
    // Some app logic checks for existence of ledger to signal creation vs mutation (via triggers for example).

    partial.account = partial.account ?? partial.claim?.account as AccountUnion;
    partial.card = partial.card ?? claimCard(partial.claim) ?? partial.account?.cards?.[0];
    partial.email = partial.email ?? new Email(this.auth.session.email);
    partial.institution = partial.institution ?? this.auth.institution;
    partial.member = partial.member ?? partial.claim?.member ?? partial.account?.members[0];
    partial.organization = partial.organization ?? this.auth.organization;
    partial.phone = partial.phone ?? this.auth.institution.phones[0];
    partial.transaction = partial.transaction ?? partial.dispute?.transaction;
    partial.user = partial.user ?? this.auth.session;
    partial.model = model ? modelValue(model,partial.model) : partial.model;
    return partial;
  }
}
