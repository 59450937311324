import { DocumentTemplateType } from "../../code/standard/common";
import { TypeInfo } from "../../info/type";
import { ErrorResponse } from "../../message/error";
import { ImportResultTitle } from "../../message/import";
import { accountUnion } from "../../toolbox/account";
import { APP_NAME } from "../../toolbox/app";
import { claimUnion } from "../../toolbox/claim";
import { CURRENCY_DEFAULT } from "../../toolbox/currency";
import { disputeUnion } from "../../toolbox/dispute";
import { randomName, randomRange } from "../../toolbox/random";
import { UnionValidator } from "../../validator/union";
import { Address } from "../address";
import { Card } from "../card";
import { CLAIM_JOIN_UNIONINFO, ClaimJoin } from "../claim/claim";
import { Email } from "../email";
import { Institution } from "../institution";
import { Member } from "../member";
import { Organization } from "../organization/organization";
import { Phone } from "../phone";
import { Transaction } from "../transaction";
import { User } from "../user/user";

/** Data accepted by each document template type. */
export class DocumentTemplateClass {
  [DocumentTemplateType.ClaimIntake]                    = new DocumentTemplateDataClaimCardIntake();
  [DocumentTemplateType.ClaimCardChargeback]            = new DocumentTemplateDataClaimCardIntake();
  [DocumentTemplateType.ClaimCardInitialCorrespondence] = new DocumentTemplateDataClaimCardResolution();
  [DocumentTemplateType.ClaimCardResolution]            = new DocumentTemplateDataClaimCardResolution();
  [DocumentTemplateType.ClaimList]                      = new DocumentTemplateDataClaimList();
  [DocumentTemplateType.General]                        = new DocumentTemplateDataGeneral();
  [DocumentTemplateType.Notification]                   = new DocumentTemplateDataNotification();
  [DocumentTemplateType.NotificationImport]             = new DocumentTemplateDataNotificationImport();
  [DocumentTemplateType.Test]                           = new DocumentTemplateDataTest();
};

/** Common data available in all document templates. */
export class DocumentTemplateDataGeneral {
  constructor(
    /** Today's date. */
    public today = new Date(),
    /** Name of application. */
    public application = APP_NAME,
    /** Organization being displayed. */
    public organization = new Organization(),
    /** Institution being displayed. */
    public institution = new Institution(),
    /** Account being displayed. */
    public account = accountUnion(),
    /** Card being displayed */
    public card = new Card(),
    /** All transactions to display. */
    public transactions: Transaction[] = [],
    /** First available member of account. */
    public member = new Member(),
    /** Claim being displayed */
    public claim = claimUnion(),
    /** Dispute being displayed */
    public dispute = disputeUnion(),
    /** Model bound to current context. */
    public model: Record<string, any> = {},
    /** User being viewed. */
    public user = User.like(),
    /** Member address to display. */
    public memberAddress = new Address(),
    /** Institution address to display. */
    public instAddress = new Address(),
    /** Phone number being viewed. */
    public phone = new Phone(),
    /** Primary email being displayed in context. */
    public email = new Email()
  ) {}

  static typeinfo: TypeInfo<DocumentTemplateDataGeneral> = {
    transactions: [new Transaction()]
  }
  
}

/** Data used in claim card resolution. */
export class DocumentTemplateDataClaimCardResolution extends DocumentTemplateDataGeneral {
  constructor(
    /** Latest investigation due date across all disputes. */
    public investigationDueDate = new Date()
  ) {
    super();
  }
}

/** Data used in claim `card intake. */
export class DocumentTemplateDataClaimCardIntake extends DocumentTemplateDataGeneral {
  constructor(
    /** Total disputed amount of transaction. */
    public totalDisputedAmount = CURRENCY_DEFAULT
  ) {
    super();
  }
}

/** A list of claims to render in a document template. */
 export class DocumentTemplateDataClaimList extends DocumentTemplateDataGeneral {
  constructor(
    /** List of claims */
    public claims: ClaimJoin[] = []
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DocumentTemplateDataClaimList> = {
    ...DocumentTemplateDataGeneral.typeinfo,
    claims: [new UnionValidator(CLAIM_JOIN_UNIONINFO)]
  }
}

/** An item in the document template test data list. */
export class DocumentTemplateDataTestPerson {
  constructor(
    /** Name of person. */
    public name = randomName().join(),
    /** Age of person. */
    public age = randomRange(15, 80)
  ) {}
}

/** Document template used for system notifications. */
export class DocumentTemplateDataNotification extends DocumentTemplateDataGeneral {
  constructor(
    /** Title for message. */
    public title = '',
    /** List of paragraphs to display under title. */
    public messages: string[] = [],
    /** Label for link button. */
    public label?: string,
    /** URL for link button. */
    public link?: string,
    /** URL for unsubscribe link. */
    public unsubscribe = ''
  ) {
    super();
  }
  
  static override typeinfo: TypeInfo<DocumentTemplateDataNotification> = {
    ...DocumentTemplateDataGeneral.typeinfo,
    messages: [''],
    label: '',
    link: '',
    unsubscribe: ''
  }
}

/** Document template used for notifying imports. */
export class DocumentTemplateDataNotificationImport extends DocumentTemplateDataNotification {
  constructor(
    /** An error, if any occured. */
    public error?: ErrorResponse,
    /** Rows to display in table. */
    public results?: ImportResultTitle[]
  ) {
    super();
  }
  
  static override typeinfo: TypeInfo<DocumentTemplateDataNotificationImport> = {
    ...DocumentTemplateDataGeneral.typeinfo,
    messages: [''],
    label: '',
    link: '',
    unsubscribe: '',
    error: new ErrorResponse(),
    results: [new ImportResultTitle()]
  }
}

/** Test document template data used for checking templating functionality. */
export class DocumentTemplateDataTest extends DocumentTemplateDataGeneral {
  constructor(
    /** Test number. */
    public value = 0,
    /** Test amount. */
    public amount = CURRENCY_DEFAULT,
    /** Test list of people. */
    public people: DocumentTemplateDataTestPerson[] = []
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DocumentTemplateDataTest> = {
    ...DocumentTemplateDataGeneral.typeinfo,
    people: [new DocumentTemplateDataTestPerson()]
  }
}