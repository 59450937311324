import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-expansion-panel-item',
  templateUrl: './expansion-panel-item.component.html',
  styleUrls: ['./expansion-panel-item.component.scss']
})
export class ExpansionPanelItemComponent {

  /** True to disable header. */
  @Input() headerless = false;
  /** True to disable body. */
  @Input() bodyless = false;
  /** True if expansion panel is currently expanded. */
  @Input() expanded = false;
  /** True to disable opening header. */
  @Input() disabled = false;
  /** Emits when expanded state of panel changes. */
  @Output() expandedChange = new EventEmitter<boolean>();

  /** Toggle expanded state of panel. */
  toggle() {
    if (this.disabled) return;
    this.expandedChange.emit(this.expanded = !this.expanded);
  }
}