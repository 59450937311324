import { propinfoCodes } from "../../../info/prop";
import { ErrorResponse } from "../../../message/error";
import { arraySet, arrayDefined } from "../../../toolbox/array";
import { bankUnionResolve } from "../../../toolbox/bank";
import { BuiltinMap } from "../../../toolbox/builtinmap";
import { NoId, idOmit } from "../../../toolbox/id";
import { NestedKey } from "../../../toolbox/keys";
import { errorResponse } from "../../../toolbox/message";
import { safeAssign } from "../../../toolbox/object";
import { DisplayValue, Display } from "../../display";
import { Field } from "../../form/field";
import { Form } from "../../form/form";
import { SystemForm } from "../form";
import { SystemFormula } from "../formula";
import { builtinModel } from "../model";
import { TestModel } from "../model/test";

/** Create an empty field. */
export function builtinFormField(key: NestedKey<DisplayValue>, options: Partial<Field> = {}): Field {
  let field = new Field();
  safeAssign(field, { key, ...options });
  return field;
}

/** Create an empty field for a given model. */
export function builtinFormModel<T>(key: NestedKey<T>, options: Partial<Field> = {}) {
  return builtinFormField(`model.${key}` as NestedKey<DisplayValue>, options);
}

/** Recursively determine code types needed by form. */
export function builtinFormCodes(map: BuiltinMap, name: SystemForm) {
  let builtin = map.forms.builtin(name);
  let model = builtin.model ? builtinModel(map, builtin.model) : undefined;
  let types = arraySet(builtin.fields?.map(field => Display.split(field)[0]));
  let value = DisplayValue.model(model);

  return types.flatMap(type => propinfoCodes(value[type]));
}

/** Recursively determine formulas needed by form. */
export function builtinFormFormulas(map: BuiltinMap, name: SystemForm) {
  let builtin = map.forms.builtin(name);
  return arrayDefined([
    ...Display.formulas(builtin.fields ?? []) as SystemFormula[],
    ...(builtin.formulas ?? []),
    ...(builtin.fields ?? []).map(field => field._validator as SystemFormula)
  ]);
}

/** Recursively determine models needed by form. */
export function builtinFormModels(map: BuiltinMap, name: SystemForm) {
  let builtin = map.forms.builtin(name);
  return [builtin.model ?? TestModel.Empty];
}

/** Create a concrete form from a builtin form. */
export function builtinForm(map: BuiltinMap, name: SystemForm, _inst: string, bank: boolean): NoId<Form> | ErrorResponse {
  let builtin = map.forms.builtin(name);
  let model = map.models.get(_inst, builtin.model);
  if (errorResponse(model)) return model;

  let result = Form.from({
    _inst,
    _model: model?._id,
    _formulas: builtin.formulas,
    name: bankUnionResolve(builtin.name, bank, name),
    size: map.forms.builtin(name).size ?? Form.SIZE_DEFAULT,
    editable: builtin.editable,
    fields: builtin.fields ?? [],
  }, map);
  if (errorResponse(result)) return result;

  let form = idOmit(result);
  form._inst = _inst;
  form._model = model?._id;
  form.system = model?.system ?? true;
  form.editable = builtin.editable;
  return form;
}
