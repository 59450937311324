import { ClaimCardCancellationOrReturnType, ClaimCardProcessingErrorType, ClaimCardDetailsType, ClaimCheckDetailsType, ClaimStatus, StandardEntryClass, LedgerType, ClaimACHReportReason } from "../../../code/standard/disputes";
import { CommonCode, DisputesCode, ImportCode } from "../../../code/system";
import { ColorCode } from "../../../toolbox/color";
import { DisplayValue } from "../../display";
import { ConditionType } from "../../formula/condition";
import { OperatorType } from "../../formula/operator";
import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { Permission } from "../../permission";
import { BuiltinFormula } from "../formula";

/** Formulas that operate on claims. */
export enum ClaimFormula {
  AssignedSet                               = 'Claim - Set Assigned User',
  CancellationCancelProduct                 = 'Claim - Cancellation - Cancel Product',
  CancellationNotExpected                   = 'Claim - Cancellation - Not Expected',
  CancellationNotReceived                   = 'Claim - Cancellation - Not Received',
  CancellationPaidOtherMeans                = 'Claim - Cancellation - Paid Other Means',
  CancellationReturnedRefused               = 'Claim - Cancellation - Returned / Refused',
  ACHDetailsTypeNotInAccordanceConsumer     = 'Claim - ACH Details - Type - Not in Accordance (Consumer)',
  ACHDetailsTypeRCKEntryIneligible          = 'Claim - ACH Details - Type - RCK Entry Ineligible',
  CardDetailsTypeAtmTransaction             = 'Claim - Card Details - Type - ATM Transaction',
  CardDetailsTypeCancellation               = 'Claim - Card Details - Type - Cancellation / Return',
  CardDetailsTypeFraudAbsent                = 'Claim - Card Details - Type - Fraud (Absent)',
  CardDetailsTypeFraudPresent               = 'Claim - Card Details - Type - Fraud (Present)',
  CardDetailsTypeProcessingError            = 'Claim - Card Details - Type - Processing Error',
  CardNotClosed                             = 'Claim - Card Not Closed',
  CheckCategoryForgery                      = 'Claim - Check Category - Forgery',
  CheckCategoryUnauthorized                 = 'Claim - Check Category - Unauthorized',
  DeniedDateSet                             = 'Claim - Set Denied Date',
  IntakeReviewEnable                        = 'Claim - Enable Intake Review',
  PoliceReport                              = 'Claim - Police Report',
  PoliceReportFiled                         = 'Claim - Police Report Filed',
  ProcessingErrorAdditionalCharge           = 'Claim - Processing Error - Additional Charge',
  ProcessingErrorComputationalError         = 'Claim - Processing Error - Computational Error',
  ProcessingErrorCreditNotReceived          = 'Claim - Processing Error - Credit Not Received',
  ProcessingErrorDuplicateTransaction       = 'Claim - Processing Error - Duplicate Transaction',
  ProcessingErrorIncorrectTransactionAmount = 'Claim - Processing Error - Incorrect Transaction Amount',
  ProcessingErrorIncorrectTransactionCode   = 'Claim - Processing Error - Incorrect Transaction Code',
  ProcessingErrorLatePresentment            = 'Claim - Processing Error - Late Presentment',
  ProcessingErrorUnrecognizedTransaction    = 'Claim - Processing Error - Unrecognized Transaction',
  Reason                                    = 'Claim - Reason',
  ReturnOrDeniedDate                        = 'Claim - Return or Denied Date',
  ReviewDate                                = 'Claim - Review Date',
  ReviewDateSet                             = 'Claim - Set Review Date',
  StandardEntryCodeConsumer                 = 'Claim - SEC Code - Consumer',
  StandardEntryCodeCorporate                = 'Claim - SEC Code - Corporate',
  StandardEntryCodeNone                     = 'Claim - SEC Code - None',
  StatusChargeback                          = 'Claim - Status - Chargeback',
  StatusChargebackSet                       = 'Claim - Status - Set Chargeback',
  StatusCredit                              = 'Claim - Status - Credit',
  StatusCreditSet                           = 'Claim - Status - Set Credit',
  StatusResolved                            = 'Claim - Status - Resolved',
  StatusResolveSet                          = 'Claim - Status - Set Resolved',
  StatusReturnSet                           = 'Claim - Status - Set Return',
  TableHighlight                            = 'Claim - Table - Highlight',
  TableHighlightCell                        = 'Claim - Table - Highlight Cell',
  TotalCredit                               = 'Claim - Total Credit',
  TotalDisputed                             = 'Claim - Total Disputed',
  TotalRecovered                            = 'Claim - Total Recovered'
}

export const BUILTIN_CLAIM_FORMULAS: Record<ClaimFormula, BuiltinFormula> = {
  [ClaimFormula.ACHDetailsTypeNotInAccordanceConsumer]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.achDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimACHReportReason,
            value: ClaimACHReportReason.NotInAccordance
          }
        },
        right: {
          type: OperatorType.Not,
          left: {
            type: ConditionType.Some,
            left: {
              type: OperatorType.Map,
              expression: {
                type: TerminalType.Identifier,
                key: 'claim.disputes.transaction'
              },
              statements: [{
                type: StatementType.Return,
                expression: {
                  type: ConditionType.Equal,
                  left: {
                    type: TerminalType.Identifier,
                    key: 'secCode' as any
                  },
                  right: {
                    type: TerminalType.CodeList,
                    category: ImportCode.StandardEntryClass,
                    value: [StandardEntryClass.CorporateCreditOrDebit, StandardEntryClass.CorporateTradeExchange]
                  }
                }
              }]
            }
          }
        }
      }
    }]
  },
  [ClaimFormula.ACHDetailsTypeRCKEntryIneligible]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.achDetails.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimACHReportReason,
          value: ClaimACHReportReason.RCKEntryIneligible
        }
      }
    }]
  },
  [ClaimFormula.AssignedSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'claim._assigned'
      },
      right: {
        type: TerminalType.Identifier,
        key: 'user._id'
      }
    }]
  },
  [ClaimFormula.CancellationCancelProduct]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.Cancellation
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.cancellationOrReturnType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardCancellationOrReturnType,
            value: ClaimCardCancellationOrReturnType.CancelProduct
          }
        }
      }
    }]
  },
  [ClaimFormula.CancellationNotExpected]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.Cancellation
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.cancellationOrReturnType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardCancellationOrReturnType,
            value: ClaimCardCancellationOrReturnType.NotExpected
          }
        }
      }
    }]
  },
  [ClaimFormula.CancellationNotReceived]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.Cancellation
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.cancellationOrReturnType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardCancellationOrReturnType,
            value: ClaimCardCancellationOrReturnType.NotReceived
          }
        }
      }
    }]
  },
  [ClaimFormula.CancellationPaidOtherMeans]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.Cancellation
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.cancellationOrReturnType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardCancellationOrReturnType,
            value: ClaimCardCancellationOrReturnType.PaidOtherMeans
          }
        }
      }
    }]
  },
  [ClaimFormula.CancellationReturnedRefused]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.Cancellation
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.cancellationOrReturnType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardCancellationOrReturnType,
            value: ClaimCardCancellationOrReturnType.ReturnedRefused
          }
        }
      }
    }]
  },
  [ClaimFormula.CardDetailsTypeAtmTransaction]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.cardDetails.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimCardDetailsType,
          value: ClaimCardDetailsType.AtmTransaction
        }
      }
    }]
  },
  [ClaimFormula.CardDetailsTypeCancellation]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.cardDetails.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimCardDetailsType,
          value: ClaimCardDetailsType.Cancellation
        }
      }
    }]
  },
  [ClaimFormula.CardDetailsTypeFraudAbsent]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.cardDetails.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimCardDetailsType,
          value: ClaimCardDetailsType.FraudAbsent
        }
      }
    }]
  },
  [ClaimFormula.CardDetailsTypeFraudPresent]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.cardDetails.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimCardDetailsType,
          value: ClaimCardDetailsType.FraudPresent
        }
      }
    }]
  },
  [ClaimFormula.CardDetailsTypeProcessingError]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.cardDetails.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimCardDetailsType,
          value: ClaimCardDetailsType.ProcessingError
        }
      }
    }]
  },
  [ClaimFormula.CardNotClosed]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Not,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.cardClosed'
        }
      }
    }]
  },
  [ClaimFormula.CheckCategoryForgery]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.checkDetails.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimCheckDetailsType,
          value: ClaimCheckDetailsType.Forgery
        }
      }
    }]
  },
  [ClaimFormula.CheckCategoryUnauthorized]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.checkDetails.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimCheckDetailsType,
          value: ClaimCheckDetailsType.Unauthorized
        }
      }
    }]
  },
  [ClaimFormula.DeniedDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'claim.deniedDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [ClaimFormula.IntakeReviewEnable]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Granted,
        left: {
          type: TerminalType.Permission,
          value: Permission.ClaimsEdit
        }
      }
    }]
  },
  [ClaimFormula.PoliceReport]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.FraudAbsent
          }
        },
        right: {
          type: TerminalType.Identifier,
          key: 'claim.cardDetails.policeReport'
        }
      }
    }]
  },
  [ClaimFormula.PoliceReportFiled]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Every,
        left: {
          type: TerminalType.Array,
          expressions: [{
            type: ConditionType.Equal,
            left: {
              type: TerminalType.Identifier,
              key: 'claim.cardDetails.type'
            },
            right: {
              type: TerminalType.Code,
              category: DisputesCode.ClaimCardDetailsType,
              value: ClaimCardDetailsType.FraudAbsent
            }
          }, {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.policeReport'
          }, {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.reportFiled'
          }]
        }
      }
    }]
  },
  [ClaimFormula.ProcessingErrorAdditionalCharge]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.ProcessingError
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.processingErrorType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardProcessingErrorType,
            value: ClaimCardProcessingErrorType.AdditionalCharge
          }
        }
      }
    }]
  },
  [ClaimFormula.ProcessingErrorComputationalError]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.ProcessingError
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.processingErrorType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardProcessingErrorType,
            value: ClaimCardProcessingErrorType.ComputationalError
          }
        }
      }
    }]
  },
  [ClaimFormula.ProcessingErrorCreditNotReceived]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.ProcessingError
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.processingErrorType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardProcessingErrorType,
            value: ClaimCardProcessingErrorType.CreditNotReceived
          }
        }
      }
    }]
  },
  [ClaimFormula.ProcessingErrorDuplicateTransaction]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.ProcessingError
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.processingErrorType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardProcessingErrorType,
            value: ClaimCardProcessingErrorType.DuplicateTransaction
          }
        }
      }
    }]
  },
  [ClaimFormula.ProcessingErrorIncorrectTransactionAmount]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.ProcessingError
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.processingErrorType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardProcessingErrorType,
            value: ClaimCardProcessingErrorType.IncorrectTransactionAmount
          }
        }
      }
    }]
  },
  [ClaimFormula.ProcessingErrorIncorrectTransactionCode]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.ProcessingError
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.processingErrorType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardProcessingErrorType,
            value: ClaimCardProcessingErrorType.IncorrectTransactionCode
          }
        }
      }
    }]
  },
  [ClaimFormula.ProcessingErrorLatePresentment]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.ProcessingError
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.processingErrorType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardProcessingErrorType,
            value: ClaimCardProcessingErrorType.LatePresentment
          }
        }
      }
    }]
  },
  [ClaimFormula.ProcessingErrorUnrecognizedTransaction]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardDetailsType,
            value: ClaimCardDetailsType.ProcessingError
          }
        },
        right: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.cardDetails.processingErrorType'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimCardProcessingErrorType,
            value: ClaimCardProcessingErrorType.UnrecognizedTransaction
          }
        }
      }
    }]
  },
  [ClaimFormula.Reason]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.type'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimType,
          value: 'ach'
        }
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: OperatorType.Display,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.achDetails.type'
          }
        }
      }],
      else: [{
        type: StatementType.If,
        if: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimType,
            value: 'card'
          }
        },
        then: [{
          type: StatementType.Return,
          expression: {
            type: OperatorType.Display,
            left: {
              type: TerminalType.Identifier,
              key: 'claim.cardDetails.type'
            }
          }
        }],
        else: [{
          type: StatementType.If,
          if: {
            type: ConditionType.Equal,
            left: {
              type: TerminalType.Identifier,
              key: 'claim.type'
            },
            right: {
              type: TerminalType.Code,
              category: DisputesCode.ClaimType,
              value: 'check'
            }
          },
          then: [{
            type: StatementType.Return,
            expression: {
              type: OperatorType.Display,
              left: {
                type: TerminalType.Identifier,
                key: 'claim.checkDetails.reason'
              }
            }
          }],
          else: [{
            type: StatementType.Return,
            expression: {
              type: TerminalType.String,
              value: ''
            }
          }]
        }]
      }]
    }]
  },
  [ClaimFormula.ReturnOrDeniedDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Or,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.returnDate'
        },
        right: {
          type: TerminalType.Identifier,
          key: 'claim.deniedDate'
        }
      }
    }]
  },
  [ClaimFormula.ReviewDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Identifier,
        key: 'claim.reviewDate'
      }
    }]
  },
  [ClaimFormula.ReviewDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'claim.reviewDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [ClaimFormula.StatusCreditSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'claim.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.ClaimStatus,
        value: ClaimStatus.Credit,
      }
    }]
  },
  [ClaimFormula.StatusResolveSet]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.GreaterEqual,
        left: {
          type: OperatorType.Sum,
          left: {
            type: OperatorType.Map,
            expression: {
              type: TerminalType.Identifier,
              key: 'claim.disputes'
            },
            statements: [{
              type: StatementType.Return,
              expression: {
                type: OperatorType.Sum,
                left: {
                  type: OperatorType.Map,
                  expression: {
                    type: TerminalType.Identifier,
                    key: 'ledger'
                  },
                  statements: [{
                    type: StatementType.If,
                    if: {
                      type: ConditionType.Equal,
                      left: {
                        type: TerminalType.Code,
                        value: LedgerType.Credit,
                        category: DisputesCode.LedgerType
                      },
                      right: {
                        type: TerminalType.Identifier,
                        key: 'type' as keyof DisplayValue
                      }
                    },
                    then: [{
                      type: StatementType.Return,
                      expression: {
                        type: OperatorType.Multiply,
                        left: {
                          type: TerminalType.Identifier,
                          key: 'amount' as keyof DisplayValue
                        },
                        right: {
                          type: TerminalType.Number,
                          value: -1
                        }
                      }
                    }],
                    else: [{
                      type: StatementType.Return,
                      expression: {
                        type: TerminalType.Identifier,
                        key: 'amount' as keyof DisplayValue
                      }
                    }]
                  }]
                }
              }
            }]
          }
        },
        right: {
          type: TerminalType.Number,
          value: 0
        }
      },
      then: [{
        type: StatementType.Assignment,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.status'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimStatus,
          value: ClaimStatus.Resolved
        }
      }]
    }]
  },
  [ClaimFormula.StatusReturnSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'claim.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.ClaimStatus,
        value: ClaimStatus.Return
      }
    }]
  },
  [ClaimFormula.StandardEntryCodeConsumer]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Every,
        left: {
          type: OperatorType.Map,
          expression: {
            type: TerminalType.Identifier,
            key: 'claim.disputes.transaction'
          },
          statements: [{
            type: StatementType.Return,
            expression: {
              type: ConditionType.Equal,
              left: {
                type: TerminalType.Identifier,
                key: 'secCode' as any
              },
              right: {
                type: TerminalType.Code,
                category: ImportCode.StandardEntryClass,
                value: StandardEntryClass.PrearrangedPaymentOrDeposit
              }
            }
          }]
        }
      }
    }]
  },
  [ClaimFormula.StandardEntryCodeCorporate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Every,
        left: {
          type: OperatorType.Map,
          expression: {
            type: TerminalType.Identifier,
            key: 'claim.disputes.transaction'
          },
          statements: [{
            type: StatementType.Return,
            expression: {
              type: ConditionType.Equal,
              left: {
                type: TerminalType.Identifier,
                key: 'secCode' as any
              },
              right: {
                type: TerminalType.Code,
                category: ImportCode.StandardEntryClass,
                value: StandardEntryClass.CorporateCreditOrDebit
              }
            }
          }]
        }
      }
    }]
  },
  [ClaimFormula.StandardEntryCodeNone]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Some,
          left: {
            type: OperatorType.Map,
            expression: {
              type: TerminalType.Identifier,
              key: 'claim.disputes.transaction'
            },
            statements: [{
              type: StatementType.Return,
              expression: {
                type: ConditionType.Unequal,
                left: {
                  type: TerminalType.Identifier,
                  key: 'secCode' as any
                },
                right: {
                  type: TerminalType.Code,
                  category: ImportCode.StandardEntryClass,
                  value: StandardEntryClass.CorporateCreditOrDebit
                }
              }
            }]
          }
        },
        right: {
          type: ConditionType.Some,
          left: {
            type: OperatorType.Map,
            expression: {
              type: TerminalType.Identifier,
              key: 'claim.disputes.transaction'
            },
            statements: [{
              type: StatementType.Return,
              expression: {
                type: ConditionType.Unequal,
                left: {
                  type: TerminalType.Identifier,
                  key: 'secCode' as any
                },
                right: {
                  type: TerminalType.Code,
                  category: ImportCode.StandardEntryClass,
                  value: StandardEntryClass.PrearrangedPaymentOrDeposit
                }
              }
            }]
          }
        }
      }
    }]
  },
  [ClaimFormula.StatusChargeback]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Unequal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.status'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimStatus,
          value: ClaimStatus.Initiated
        }
      }
    }]
  },
  [ClaimFormula.StatusChargebackSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'claim.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.ClaimStatus,
        value: ClaimStatus.Chargeback
      }
    }]
  },
  [ClaimFormula.StatusCredit]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.status'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimStatus,
          value: ClaimStatus.Credit
        }
      }
    }]
  },
  [ClaimFormula.StatusResolved]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.status'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimStatus,
          value: ClaimStatus.Resolved
        }
      }
    }]
  },
  [ClaimFormula.TableHighlight]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.Greater,
        left: {
          type: TerminalType.Date,
          value: -30
        },
        right: {
          type: TerminalType.Identifier,
          key: 'claim.reportDate'
        }
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.Code,
          category: CommonCode.ColorCode,
          value: ColorCode.Red
        }
      }]
    }]
  },
  [ClaimFormula.TableHighlightCell]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Code,
          category: DisputesCode.ClaimCardDetailsType,
          value: ClaimCardDetailsType.ProcessingError
        },
        right: {
          type: TerminalType.Identifier,
          key: 'claim.cardDetails.type'
        }
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.Code,
          category: CommonCode.ColorCode,
          value: ColorCode.Yellow
        }
      }]
    }]
  },
  [ClaimFormula.TotalCredit]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Sum,
        left: {
          type: OperatorType.Map,
          expression: {
            type: TerminalType.Identifier,
            key: 'claim.disputes'
          },
          statements: [{
            type: StatementType.Return,
            expression: {
              type: OperatorType.Sum,
              left: {
                type: OperatorType.Map,
                expression: {
                  type: TerminalType.Identifier,
                  key: 'dispute.ledger'
                },
                statements: [{
                  type: StatementType.If,
                  if: {
                    type: ConditionType.And,
                    left: {
                      type: ConditionType.Equal,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.type'
                      },
                      right: {
                        type: TerminalType.Code,
                        value: LedgerType.Debit,
                        category: DisputesCode.LedgerType
                      }
                    },
                    right: {
                      type: ConditionType.Unequal,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.account'
                      },
                      right: {
                        type: TerminalType.String,
                        value: ''
                      }
                    }
                  },
                  then: [{
                    type: StatementType.Return,
                    expression: {
                      type: TerminalType.Identifier,
                      key: 'ledger.amount'
                    }
                  }]
                }, {
                  type: StatementType.If,
                  if: {
                    type: ConditionType.And,
                    left: {
                      type: ConditionType.Equal,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.type'
                      },
                      right: {
                        type: TerminalType.Code,
                        value: LedgerType.Credit,
                        category: DisputesCode.LedgerType
                      }
                    },
                    right: {
                      type: ConditionType.Unequal,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'account'
                      },
                      right: {
                        type: TerminalType.String,
                        value: ''
                      }
                    }
                  },
                  then: [{
                    type: StatementType.Return,
                    expression: {
                      type: OperatorType.Multiply,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.amount'
                      },
                      right: {
                        type: TerminalType.Number,
                        value: -1
                      }
                    }
                  }]
                }]
              }
            }
          }]
        }
      }
    }]
  },
  [ClaimFormula.TotalDisputed]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Sum,
        left: {
          type: OperatorType.Map,
          expression: {
            type: TerminalType.Identifier,
            key: 'claim.disputes'
          },
          statements: [{
            type: StatementType.Return,
            expression: {
              type: TerminalType.Identifier,
              key: 'transaction.amount'
            }
          }]
        }
      }
    }]
  },
  [ClaimFormula.TotalRecovered]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Sum,
        left: {
          type: OperatorType.Map,
          expression: {
            type: TerminalType.Identifier,
            key: 'claim.disputes'
          },
          statements: [{
            type: StatementType.Return,
            expression: {
              type: OperatorType.Sum,
              left: {
                type: OperatorType.Map,
                expression: {
                  type: TerminalType.Identifier,
                  key: 'ledger'
                },
                statements: [{
                  type: StatementType.If,
                  if: {
                    type: ConditionType.And,
                    left: {
                      type: ConditionType.Equal,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.type'
                      },
                      right: {
                        type: TerminalType.Code,
                        value: LedgerType.Debit,
                        category: DisputesCode.LedgerType
                      }
                    },
                    right: {
                      type: ConditionType.Equal,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.account'
                      },
                      right: {
                        type: TerminalType.String,
                        value: ''
                      }
                    }
                  },
                  then: [{
                    type: StatementType.Return,
                    expression: {
                      type: OperatorType.Multiply,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.amount'
                      },
                      right: {
                        type: TerminalType.Number,
                        value: -1
                      }
                    }
                  }]
                }, {
                  type: StatementType.If,
                  if: {
                    type: ConditionType.And,
                    left: {
                      type: ConditionType.Equal,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.type'
                      },
                      right: {
                        type: TerminalType.Code,
                        value: LedgerType.Credit,
                        category: DisputesCode.LedgerType
                      }
                    },
                    right: {
                      type: ConditionType.Equal,
                      left: {
                        type: TerminalType.Identifier,
                        key: 'ledger.account'
                      },
                      right: {
                        type: TerminalType.String,
                        value: ''
                      }
                    }
                  },
                  then: [{
                    type: StatementType.Return,
                    expression: {
                      type: TerminalType.Identifier,
                      key: 'ledger.amount'
                    }
                  }]
                }]
              }
            }
          }]
        }
      }
    }]
  }
};
