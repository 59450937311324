<button icon-button color="secondary" (click)="open($event)">notifications</button>
<app-badge overlay bottom-left class="elevation-high" [attr.color]="status | statusColor" *ngIf="count">
  <app-pulse circle></app-pulse>
  <div>{{count}}</div>
</app-badge>
<ng-template #panel>
  <div class="panel elevation-high">
    <div class="flex">
      <button color="black" class="fill" [class.fade]="options.tab !== ActivityTab.Reminders" (click)="retab(ActivityTab.Reminders)">Reminders</button>
      <button color="black" class="fill" [class.fade]="options.tab !== ActivityTab.Logs" (click)="retab(ActivityTab.Logs)">Logs</button>
    </div>
    <ng-container [ngSwitch]="options.tab">
      <ng-container *ngSwitchCase="ActivityTab.Reminders">
        <ng-container *ngIf="config">
          <div class="config">
            <app-field color="black">
              <app-label>Refresh frequency</app-label>
              <app-sublabel>
                <app-hover-help tooltip="How frequently server is contacted to pull down reminders."></app-hover-help>
              </app-sublabel>
              <app-duration color="white" [(ngModel)]="config.frequency" [min]="INTERVAL_FREQUENCY_MIN"></app-duration>
            </app-field>
            <app-field color="black">
              <app-label>Idle threshold</app-label>
              <app-sublabel>
                <app-hover-help tooltip="Idle period before reminders are no longer pulled down."></app-hover-help>
              </app-sublabel>
              <app-duration color="white" [(ngModel)]="config.threshold" [min]="INTERVAL_THRESHOLD_MIN"></app-duration>
            </app-field>
          </div>
          <footer>
            <div class="fill"></div>
            <app-icon button-icon type="button" color="green" title="Save Changes" (click)="save()">done</app-icon>
            <app-icon button-icon type="button" color="red" title="Undo Changes" (click)="config = undefined">close</app-icon>
          </footer>
        </ng-container>
        <ng-container *ngIf="reminder">
          <div class="reminder fill">
            <app-field color="black">
              <app-label>Title</app-label>
              <app-string color="white" [required]="false" [(ngModel)]="reminder.title"></app-string>
            </app-field>
            <app-field autosize class="fill" color="black">
              <app-label>Description</app-label>
              <app-string color="white" class="fill" [required]="false" [large]="true" [(ngModel)]="reminder.description"></app-string>
            </app-field>
            <app-field color="black">
              <app-label>Due</app-label>
              <app-date color="white" [min]="0" [time]="true" [(ngModel)]="reminder.result.due"></app-date>
            </app-field>
          </div>
          <footer>
            <app-clock class="fill" color="white" [date]="reminder.result.due" [durationFormat]="DurationFormat.Long"></app-clock>
            <div class="fill"></div>
            <app-icon button-icon type="button" color="green" title="Save Changes" (click)="submit()" [disabled]="(reminder.result.due | dateDifference) <= 0">done</app-icon>
            <app-icon button-icon type="button" color="red" title="Undo Changes" (click)="reminder = undefined">close</app-icon>
          </footer>
        </ng-container>
        <ng-container *ngIf="!config && !reminder">
          <main>
            <div *ngFor="let r of reminders;">
              <header>
                <div>{{r | eventTitle | async}}</div>
                <div class="fill"></div>
                <app-clock [date]="r.result.due" [duration]="true" [tooltip]="r.date | dateIso:'datetime'" color="white"></app-clock>
              </header>
              <div>{{r | eventDescription | async}}</div>
            </div>
          </main>
          <footer>
            <div class="fill"></div>
            <app-icon button-icon color="white" tooltip="Create reminder" (click)="create()">notification_add</app-icon>
            <app-icon button-icon color="white" tooltip="Reminder options" (click)="config = eventService.config">settings</app-icon>
          </footer>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ActivityTab.Logs">
        <main>
          <div *ngFor="let l of logs; let i = index">
            <header>
              <time>{{l.date | dateIso:'datetime' }}</time>
              <div class="fill"></div>
              <app-icon button-icon tooltip="View source" *ngIf="dev.on" (click)="source(l)">code</app-icon>
              <app-icon button-icon tooltip="Download log" [attr.color]="l.status | statusColor:l.color:ColorCode.White" (click)="download(l)">{{l.status | icon:STATUS_LEVEL_ICON:Icon.Menu}}</app-icon>
            </header>
            <div>{{l.message}}</div>
            <details *ngIf="l.list && l.list.length">
              <summary>{{l.list.length}} more details</summary>
              <code class="block">{{l.list | json}}</code>
            </details>
            <details *ngIf="l.details">
              <summary>View object</summary>
              <code class="block">{{l.details | json}}</code>
            </details>
          </div>
        <div class="bottom" *ngIf="hidden" (click)="unhide()">{{hidden}} hidden items...</div>
        </main>
        <footer>
          <div class="fill"></div>
          <app-icon button-icon tooltip="Hide status level" *ngFor="let status of STATUS_LEVELS" [attr.color]="status | statusColor" [class.fade]="!options.filter[status]" (click)="toggle(status)">{{status | icon:STATUS_LEVEL_ICON}}</app-icon>
          <button icon-button color="black" tooltip="Download all logs" (click)="download()">download</button>
        </footer>
      </ng-container>
    </ng-container>
  </div>
</ng-template>