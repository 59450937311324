import { ErrorResponse } from "../message/error";
import { CollectionClass, CollectionOverride, collectionValue } from "../model/collection";
import { arrayMany } from "../toolbox/array";
import { Diff, DiffResult } from "../toolbox/diff";
import { hydrateObject } from "../toolbox/hydrate";
import { HasId, HasName, NoId, NoIdInst } from "../toolbox/id";
import { NestedKey } from "../toolbox/keys";
import { errorPartition, errorResponse } from "../toolbox/message";
import { deepCopy, objectEmpty, objectOmit, objectType, objectValues } from "../toolbox/object";
import { Validator } from "../validator/base";
import { UnionValidator } from "../validator/union";
import { infoOf } from "./base";
import { typeinfoOf, typeinfoValue } from "./type";

const COLLECTION_EXPORT_BLACKLIST: string[] = ['dirty', 'system', '_id', '_inst'];

/** Check if a value is a collection export. */
export function collectionExport<C>(value: any): value is CollectionExport<C> {
  return value instanceof Object && Array.isArray(value.$export) && value.$export.every((v: any) => typeof v === 'string' || (arrayMany(v) && typeof v[0] === 'string' && typeof v[1] === 'string'));
}

/** A tagged object ID exported from a collection. */
export type CollectionId<C> = [keyof C, string];
/** A collection-key pair of an ID within an object. */
export type CollectionKey<C, T> = [keyof C, NestedKey<T>];
/** A custom exporter for a particular field. */
export type CollectionExporter<C, T = any, K extends keyof T = any> = (value: T[K]) => T[K] | CollectionExport<C>;

/** Information about how to map object IDs to collections. */
export type CollectionInfo<C, T> = {
  [K in keyof T]?: T[K] extends (string | string[] | undefined) ? (keyof C | CollectionExporter<C, T, K>) : CollectionExporter<C, T, K>
}

/** Importers for name => ID conversion and exporters for ID => name conversion. */
export type CollectionResolvers<C> = {
  [K in keyof C]: CollectionCallback | undefined
}

/** An exported multi-key ID exported from a value. */
export class CollectionExport<C> {
  constructor(
    /** A list of plain strings and IDs to rejoin into a collection key. */
    public $export: (string | CollectionId<C>)[]
  ) {}
}

/** A resolver for a single collection. */
export interface CollectionCallback {
  import: (name: string) => Promise<HasId | ErrorResponse>
  export: (_id: string) => Promise<HasName | ErrorResponse>
}

/** Engine that can resolve collection mappings for an object. */
export abstract class CollectionResolver<C> {

  /** List of classes for each collection. */
  abstract readonly COLLECTIONS: CollectionClass<C>;
  /** Overrides for specific collections. */
  abstract readonly COLLECTION_OVERRIDES: CollectionOverride<C>;
  /** Callback to import and export each type of resource. */
  abstract resolvers: CollectionResolvers<C>;
  /** Institution being imported into. */
  abstract _inst: string;

  /** Diffs current values with a portable JSON form. */
  diff<T extends Object>(uploaded: T, current: T): DiffResult<T> {
    if (!current) return {
      uploaded: uploaded,
      current: undefined,
      dirty: true,
      diff: undefined
    };

    let diff = new Diff(deepCopy(uploaded), deepCopy(current));
    let result = !objectEmpty(diff.from as T) || !objectEmpty(diff.to as T);
    return {
      uploaded: uploaded,
      current: current,
      dirty: result,
      diff: result ? diff : undefined
    };
  }

  /** Import a value from a portable JSON form. */
  async import<T>(value: NoIdInst<T>): Promise<NoId<T> | ErrorResponse> {
    let copy: any = deepCopy(value);
    copy._inst = this._inst;
    return await this.importDeep(copy) ?? copy;
  }

  /** Export a value to a portable JSON form. */
  async export<K extends keyof C, T extends C[K]>(collection: K, value: T, safe?: boolean): Promise<NoIdInst<T> | ErrorResponse> {
    let typed = collectionValue(this.COLLECTION_OVERRIDES, collection) ?? collectionValue(this.COLLECTIONS, collection);
    let exports: Promise<ErrorResponse | void>[] = [];
    let copy = deepCopy(value);

    collectioninfoWalk<C, C[K]>(copy, typed, async (parent, key, collection) =>
      exports.push((async () => {
        if (typeof collection === 'function') {
          // Custom exporter for this property.
          let value = collection(parent[key]);
          let result = await this.exportDeep(value);
          if (errorResponse(result)) return result;
          parent[key] = value;
        } else {
          // Process ID into a name.
          let name = await this.exportId(collection, parent[key], safe);
          if (errorResponse(name)) return name;
          parent[key] = new CollectionExport<C>([[collection as any, name]]);
        } return;
      })())
    );

    let [error] = errorPartition(`There was an error exporting to collection: ${collection as string}`, await Promise.all(exports));
    if (error) return error;
    return objectOmit(copy as any, ...COLLECTION_EXPORT_BLACKLIST) as T;
  }

  /** Deeply import a value form a portable JSON form. */
  private async importDeep(value: any): Promise<void | ErrorResponse> {
    if (!(value instanceof Object)) return;

    for (let key in value) {
      let subvalue = value[key];
      if (collectionExport(subvalue)) {
        let list: string[] = [];
        for (let part of subvalue.$export) {
          if (typeof part === 'string') {
            // Push string literal.
            list.push(part);
            continue;
          }
          
          // Process name into an ID.
          let _id = await this.importId(part[0], part[1]);
          if (errorResponse(_id)) return _id;
          list.push(_id);
        }

        value[key] = list.join('');
      } else {
        let result = await this.importDeep(value[key]);
        if (errorResponse(result)) return result;
      }
    }
  }

  /** Deeply export a value to a portable JSON form. */
  private async exportDeep(value: any, safe?: boolean): Promise<void | ErrorResponse> {
    if (!(value instanceof Object)) return;
    
    if (collectionExport(value)) {
      for (let part of value.$export) {
        if (typeof part === 'string') continue;

        // Process ID into a name.
        let name = await this.exportId(part[0], part[1], safe);
        if (errorResponse(name)) return name;
        part[1] = name;
      }
    } else for (let subvalue of Object.values(value)) {
      let result = this.exportDeep(subvalue, safe);
      if (errorResponse(result)) return result;
    }
  }

  /** Import a single ID from a name. */
  private async importId(collection: keyof C, name: string) {
    let importer = this.resolvers[collection]?.import;
    if (!importer) return new ErrorResponse(`Import not implemented for collection: ${String(collection)}`);

    let result = await importer(name);
    if (errorResponse(result)) return new ErrorResponse(`Failed to find item in ${String(collection)}: ${name}`, [result]);
    return result._id;
  }

  /** Export a single name to an ID. */
  private async exportId(collection: keyof C, _id: string, safe?: boolean) {
    let exporter = this.resolvers[collection]?.export;
    if (!exporter) return safe ? _id : new ErrorResponse(`Export not implemented for collection: ${String(collection)}`);

    let result = await exporter(_id);
    if (errorResponse(result)) return safe ? _id : new ErrorResponse(`Failed to find item in ${String(collection)}: ${_id}`, [result]);
    return result.name;
  }
}

/** Pull indexinfo from object. */
export function collectioninfoOf<C, T>(value: T): CollectionInfo<C, T> {
  return infoOf(value, 'collectioninfo', {});
}

/** Get foreign keys of a value as a map. */
export function collectioninfoMap<C, T>(value: T): Map<keyof C, Set<NestedKey<T>>> {
  let keys = collectioninfoKeys<C, T>(value);
  let map = new Map<keyof C, Set<NestedKey<T>>>();

  for (let [collection, key] of keys) {
    let set = map.get(collection);
    if (!set) map.set(collection, set = new Set());
    set.add(key);
  }

  return map;
}

/** Walk through collection info, executing a callback at each mappable value. */
export function collectioninfoWalk<C, T>(stripped: T, typed: T | Validator<T>, callback: (parent: any, key: keyof any, collection: keyof C | CollectionExporter<C, T, keyof T>) => any): NoId<T> {
  // Handle validators pulled from unioninfo.
  if (typed instanceof Validator) return typed = typed.value();

  // Hydrate input object with type information.
  typed = hydrateObject(stripped, typed);
  collectioninfoWalkDeep(typed, callback);
  return stripped;
}

/** Deeply walk through collection info. */
function collectioninfoWalkDeep<C>(parent: any, callback: (parent: any, key: keyof any, collection: keyof C | CollectionExporter<C>) => any): void {
  if (!(parent instanceof Object)) return;
  let info: any = collectioninfoOf(parent);

  for (let key in parent) {
    let value = parent[key];
    let collection = info[key];

    switch (objectType(value)) {
    case 'string':
      if (collection && value) callback(parent, key, collection);
      break;
    case 'object':
      collectioninfoWalkDeep(value, callback);
      break;
    case 'array':
      if (collection) {
        if (typeof collection === 'function' && Array.isArray(value)) {
          // Found key list with custom handler.
          callback(parent, key, collection);
        } else for (let i = 0; i < value.length; ++i) {
          // Map list of IDs.
          callback(value, i, collection);
        }
      } else for (let item of value) {
        // Recursively remap IDs.
        collectioninfoWalkDeep(item, callback);
      }
    }
  }

  return parent;
}

/** Get list of foreign keys in a valid. */
export function collectioninfoKeys<C, T>(value: T): CollectionKey<C, T>[] {
  let keys = new Set<string>();
  collectioninfoKeysDeep(value, [], keys);
  return [...keys].map(v => v.split(':') as CollectionKey<C, T>);
}

function collectioninfoKeysDeep(object: any, path: string[], keys: Set<string>) {
  if (!(object instanceof Object)) return;
  if (object instanceof UnionValidator) {
    for (let subvalue of objectValues(object.unioninfo.classes)) {
      collectioninfoKeysDeep(subvalue, path, keys);
    } return;
  }

  let typeinfo = typeinfoOf(object);
  let collectioninfo = collectioninfoOf(object);
  let merged = { ...object, ...typeinfo };

  for (let key of Object.keys(merged)) {
    // Add collection pair to list.
    path.push(key);
    if (typeof collectioninfo[key] === 'string') {
      keys.add(`${collectioninfo[key]}:${path.join('.')}`);
    }

    // Recurse into object for additional collections.
    let value = typeinfoValue(object, key, typeinfo);
    collectioninfoKeysDeep(value, path, keys);
    path.pop();
  }
}

/** Get list of object IDs in an object. */
export function collectioninfoIds<C, T>(stripped: T, typed: T | Validator<T>): CollectionId<C>[] {
  let values = new Set<string>();

  collectioninfoWalk<C, T>(stripped, typed, (parent, key, c) => {
    if (typeof c === 'function') {
      collectioninfoIdsDeep(c(parent[key]), values);
    } else {
      values.add(`${String(c)}:${parent[key]}`);
    }
  });

  return [...values].map(v => v.split(':') as CollectionId<C>);
}

/** Recursively get list of object IDs in an object. */
function collectioninfoIdsDeep(value: any, values: Set<string>) {
  if (!(value instanceof Object)) return;
  if (collectionExport(value)) {
    for (let item of value.$export) {
      if (typeof item === 'string') continue;
      values.add(`${String(item[0])}:${item[1]}`);
    }
  } else for (let subvalue of objectValues(value)) {
    collectioninfoIdsDeep(subvalue, values);
  }
}