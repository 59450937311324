import { HttpClient } from "@angular/common/http";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from "@ngx-translate/core";
import { from, Observable } from "rxjs";
import { RouteMethod } from "../../../common/toolbox/api";
import { errorResponse } from "../../../common/toolbox/message";
import { LogService } from "./common/service/log.service";
import { rawRequest } from "./common/toolbox/request";

/** Initialize translation loader for AoT compilation. */
export function createTranslateLoader(log: LogService, http: HttpClient): TranslateLoader {
  return new AppTranslateLoader(log, http);
}

/** Custom translation loader that fetches file. */
export class AppTranslateLoader implements TranslateLoader {

  constructor(
    private log: LogService,
    private http: HttpClient
  ) {}

  getTranslation(lang: string): Observable<any> {
    return from((async () => {
      // Check if translation cached locally.
      let translation = await DB.get('translations', lang);
      if (errorResponse(translation)) {
        this.log.show(translation);
        return undefined;
      }

      // Fetch translation remotely.
      if (translation) return translation;
      let record = await rawRequest<Object>(this.http, RouteMethod.Get, `./assets/i18n/${lang}.json`);
      if (errorResponse(record)) {
        this.log.show(record);
        return undefined;
      }

      // Save translation.
      let save = await DB.put('translations', { lang, cache: record });
      if (errorResponse(save)) this.log.show(save);
      return record;
    })());
  }
}

/** Custom missing translation handler that logs key. */
export class AppMissingHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return `{{${params.key}}}`;
  }
}