import { Component, Inject } from '@angular/core';
import { ColorCode } from "../../../../../../common/toolbox/color";
import { randomLineChart } from "../../../../../../common/toolbox/random";
import { PercentItem } from '../../../common/component/percent/percent.model';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { ExecutiveReport, ExecutiveReportOptions } from '../../executive/report/executive-report.model';
import { CollectionsExecutiveData } from './collections-executive.model';
import { CollectionsExecutiveHistory } from './history/collections-executive-history.model';
import { CollectionsExecutiveStat } from './stat/collections-executive-stats.model';

@Component({
  selector: 'app-collections-executive',
  templateUrl: './collections-executive.component.html',
  styleUrls: ['./collections-executive.component.scss']
})
export class CollectionsExecutiveComponent {
  /** List of executive stats to display. */
  readonly stats: CollectionsExecutiveStat[] = [
    new CollectionsExecutiveStat(41963, 'Total Loans', 1145467364, 'Loan Balance'),
    new CollectionsExecutiveStat(570, 'Delinquent > 30 Days', 13241402, 'Delinquencies', 1.35),
    new CollectionsExecutiveStat(38393, 'Current', 1053829974, 'Current Loan Balance', 98.65),
    new CollectionsExecutiveStat(76, 'Net Recoveries', 2092060, 'Net Recoveries', 0.18)
  ];

  /** List of executive periods to display. */
  readonly periods: CollectionsExecutiveHistory[] = [
    new CollectionsExecutiveHistory(2020, randomLineChart(12, [40000, 60000], [-5000, 10000])),
    new CollectionsExecutiveHistory(2021, randomLineChart(12, [60000, 80000], [-5000, 20000]))
  ];

  /** List of reports to view. */
  readonly reports = ([
    ['10-30 Days Delinquent', ColorCode.Green, [['This Period', 2503]], [58, 14, 18, 10]],
    ['31-60 Days Delinquent', ColorCode.Yellow, [['From Prior Period', 387], ['10-30 1 Month Prior', 2617]], [55, 17, 18, 10]],
    ['61-90 Days Delinquent', ColorCode.Orange, [['From Prior Period', 129], ['10-30 2 Month Prior', 2403]], [52, 21, 17, 10]],
    ['90+ Days Delinquent', ColorCode.Red, [['From Prior Period', 24], ['10-30 3 Months Prior', 2591]], [50, 25, 15, 10]]
  ] as any).map(this.report);

  /** Overview of all reports. */
  readonly full = this.report(['All Delinquent Loans ≥ 10 Days', ColorCode.Secondary, [], [1744, 449, 578, 274]], { percentages: true, total: true });

  /** Default title for tab. */
  static title() {
    return 'Collections Executive Dashboard';
  }

  constructor(
    @Inject(TAB_DATA)
    public data: CollectionsExecutiveData
  ) {}

  /** Format flat values into executive reports. */
  private report(value: any[], options: ExecutiveReportOptions) {
    return new ExecutiveReport(
      value[0], value[1], value[2].map((v: any) => new PercentItem(v[0], v[1])),
      value[3].map((v: any, i: number) => new PercentItem(['Secured', 'Unsecured', '1st Mort / LOC', 'Other RE LOC'][i]!, v)),
      options
    );
  }
}
