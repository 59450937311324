import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../../common/model/formula/block";
import { PLACEHOLDER_EXPRESSION } from "../../../../../../../../common/model/formula/placeholder";
import { ColorCode } from '../../../../../../../../common/toolbox/color';
import { blockType } from '../../../../../../../../common/toolbox/formula/block';
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';
import { BlockSlot } from '../../block.model';
import { LoopComponent } from '../../loop.component';

@Component({
  selector: 'app-setup-formula-loop',
  templateUrl: './setup-formula-loop.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-loop.component.scss'],
  host: {
    '[attr.color]': 'background'
  }
})
export class SetupFormulaLoopComponent extends LoopComponent(BlockComponent) {
  /** Attachment point for expression. */
  @ViewChild('expression', { static : true, read: ViewContainerRef }) expression!: ViewContainerRef;
  /** Attachment point for statement. */
  @ViewChild('statement', { static : true, read: ViewContainerRef }) statement!: ViewContainerRef;

  /** Background for main element. */
  protected background?: ColorCode = ColorCode.Primary;
  /** Color to display for bars. */
  protected color = ColorCode.Primary;
  
  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  ngOnInit() {
    if (blockType(this.block.type, BlockType.Statement)) {
      this.background = ColorCode.White;
      this.color = ColorCode.Secondary;
    }

    this.reexpression(undefined, this.block.expression ?? PLACEHOLDER_EXPRESSION);
    this.restatement(undefined, this.block.statements ?? []);
  }

  /** Replace expression. */
  private reexpression(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.expression,
      current: { block: this.block, key: 'expression' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.reexpression.bind(this)
    });
  }

  /** Replace statement. */
  private restatement(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.statement,
      current: { block: this.block, key: 'statements' },
      next: block,
      accepts: [BlockType.Statement],
      replaced: this.restatement.bind(this)
    });
  }
}
