import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CollectionCallback, CollectionResolver, CollectionResolvers } from "../../../../../common/info/collection";
import { ErrorResponse } from "../../../../../common/message/error";
import { AccountReference } from "../../../../../common/model/account/base";
import { CollectionClass, CollectionOverride } from "../../../../../common/model/collection";
import { DocumentTemplateBundle } from "../../../../../common/model/document-template/base";
import { FUSION_COLLECTION_CLASS, FusionCollection } from "../../../../../common/model/fusion";
import { User } from "../../../../../common/model/user/user";
import { RouteGetResponse } from "../../../../../common/toolbox/api";
import { HasId, HasName } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { ObjectKeys } from "../../../../../common/toolbox/object";
import { validatorValue } from "../../../../../common/toolbox/validate";
import { ValidationStatus } from "../../../../../common/validator/base";
import { getOneRequest } from "../toolbox/request";
import { AuthService } from "./auth.service";

const ACCOUNT_REFERENCE_VALIDATOR = validatorValue(new AccountReference());

/** Engine for importing and exporting resources from text. */
@Injectable({
  providedIn: 'root'
})
export class CollectionService extends CollectionResolver<FusionCollection> {
  
  readonly COLLECTIONS: CollectionClass<FusionCollection> = FUSION_COLLECTION_CLASS;
  
  readonly COLLECTION_OVERRIDES: CollectionOverride<FusionCollection> = {
    documentTemplates: new DocumentTemplateBundle(),
    users: User.upsert()
  }

  get _inst() { return this.auth._inst; }

  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) {
    super();
  }

  resolvers: CollectionResolvers<FusionCollection> = {
    accountCards: undefined,
    accountMembers: undefined,
    accounts: {
      import: async name => {
        try {
          let reference: AccountReference = JSON.parse(name);
          if (ACCOUNT_REFERENCE_VALIDATOR.validate(reference) === ValidationStatus.Error) return ACCOUNT_REFERENCE_VALIDATOR.error(reference);
          return getOneRequest(this.http, 'accounts', { _insts: [this.auth._inst], number: reference.number, category: reference.category, subAccount: reference.subAccount });
        } catch {
          return new ErrorResponse(`Account was not a valid reference: ${name}`)
        }
      },
      export: async (_id) => {
        let account = await getOneRequest(this.http, 'accounts', { _insts: [this.auth._inst], _ids: [_id] });
        if (errorResponse(account)) return account;

        let reference = new AccountReference(this.auth._inst, account.number, account.category, account.subAccount);
        return { name: JSON.stringify(reference) };
      }
    },
    addresses: {
      import: number => getOneRequest(this.http, 'addresses', { _insts: [this.auth._inst], numbers: [number] }),
      export: async (_id) => {
        let address = await getOneRequest(this.http, 'addresses', { _insts: [this.auth._inst], _ids: [_id] });
        if (errorResponse(address)) return address;
        return { name: address.number };
      }
    },
    attachments: undefined,
    cache: undefined,
    cards: undefined,
    claimFilters: this.callbacks('claim-filters'),
    claims: undefined,
    codeTypes: this.callbacks('code-types'),
    counters: undefined,
    disputes: undefined,
    documentTemplates: this.callbacks('document-templates'),
    events: undefined,
    filterRules: this.callbacks('filter-rules'),
    formLists: this.callbacks('form-lists'),
    forms: this.callbacks('forms'),
    formulas: this.callbacks('formulas'),
    importConfig: undefined,
    institutions: {
      import: async () => ({ _id: this.auth._inst }),
      export: async () => ({ name: '' })
    },
    jobs: this.callbacks('jobs'),
    ledgerConfigs: this.callbacks('ledger-configs'),
    ledgers: undefined,
    memberCards: undefined,
    members: undefined,
    models: this.callbacks('models'),
    organizations: {
      import: name => getOneRequest(this.http, 'organizations', { _orgs: [this.auth._org], names: [name] }),
      export: _id => getOneRequest(this.http, 'organizations', { _orgs: [this.auth._org] })
    },
    profiles: this.callbacks('profiles'),
    settingGroups: this.callbacks('setting-groups'),
    tables: this.callbacks('tables'),
    tasks: this.callbacks('tasks'),
    transactions: undefined,
    triggers: this.callbacks('triggers'),
    users: this.callbacks('users'),
    workflows: this.callbacks('workflows'),
    workItems: undefined,
    workQueues: this.callbacks('work-queues')
  };

  /** Shorthand for the standard id <=> name mapping. */
  private callbacks(route: ObjectKeys<RouteGetResponse, HasName[]>): CollectionCallback {
    return {
      import: name => getOneRequest(this.http, route, { _insts: [this.auth._inst], names: [name] }) as Promise<HasId | ErrorResponse>,
      export: _id => getOneRequest(this.http, route, { _insts: [this.auth._inst], _ids: [_id] }) as Promise<HasName | ErrorResponse>
    }
  }
}