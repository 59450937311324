import { propinfoOf } from "../info/prop";
import { TypeInfo, typeinfoOf, typeinfoValue } from "../info/type";
import { propertyFrom } from "../model/property";
import { Validator } from "../validator/base";
import { UnionValidator } from "../validator/union";
import { arraySet, arraySome } from "./array";
import { Pair, objectValues } from "./object";

/** Retrieve all nested keys of an object. */
export type NestedKey<T> = Join<NestedPaths<T, []>>;

/** A nested key and the associated typeinfo. */
export type NestedKeyInfo<T> = [NestedKey<T>, TypeInfo<any> | unknown];

/** Utility type to recursively period-join arrays of strings. */
type Join<T extends unknown[]> = T extends [] ? '' : T extends [string | number] ? `${T[0]}` : T extends [string | number, ...infer R] ? `${T[0]}.${Join<R>}` : string;

/** Get nested paths of value. Directly copied from MongoDB type. */
type NestedPaths<Type, Depth extends number[]> = Depth['length'] extends 8 ? [] : Type extends number | string | boolean | Date ? []
  : Type extends ReadonlyArray<infer ArrayType> ? [...NestedPaths<ArrayType, [...Depth, 1]>]
  : Type extends object ? {
  [Key in Extract<keyof Type, string>]:
      Type[Key] extends Type ? [Key]
      : Type extends Type[Key] ? [Key]
      : Type[Key] extends ReadonlyArray<infer ArrayType> ?
        Type extends ArrayType ? [Key]
        : ArrayType extends Type ? [Key]
        : [Key, ...NestedPaths<Type[Key], [...Depth, 1]>] | [Key]
      : [Key, ...NestedPaths<Type[Key], [...Depth, 1]>] | [Key];
  } [Extract<keyof Type, string>]
  : [];

/** Get a list of keys under a key. */
export type NestedSubkey<T, K extends string> = T extends `${K}.${infer SK}` ? SK : never;

/** Standard joiner used for formatting out keys. */
export const enum KeyJoin { Separator = ' - ' };

/** Get underscore-separated unique key of a value. */
export function keyUnique<T>(value: T, keys: (keyof T)[]) {
  return keys.map(key => (value as any)[key]).join('_'); 
}

/** Get last segment of a period.separated.key */
export function keyLast(key: string) {
  let parts = key.split('.');
  return parts[parts.length - 1]!;
}

/** Get a list of nested keys at or under given keys. */
export function keyNestedFilter<T>(object: T, whitelist: NestedKey<T>[]): NestedKey<T>[] {
  let set = new Set<NestedKey<T>>();
  for (let key of keyNested(object)) {
    for (let item of whitelist) {
      if (!(key as string).startsWith(item)) continue;
      set.add(key);
    }
  }

  return [...set];
}

/** Pop first segment from a nested key. */
export function keyNestedPop(key?: string): [string | undefined, string | undefined] {
  if (!key) return [undefined, undefined];
  let index = key.indexOf('.');
  if (index >= 0) return [key.slice(0, index), key.slice(index + 1) || undefined];
  return [key || undefined, undefined];
}

/** Get a list of nested keys for an object. */
export function keyNested<T>(object: T): NestedKey<T>[] {
  return keyNestedPairs(object).map(p => p.value);
}

/** Get a list of nested keys for an object. */
export function keyNestedTypeinfo<T>(object: T): NestedKeyInfo<T>[] {
  let pairs:NestedKeyInfo<T>[] = [];
  keyNestedTypeinfoDeep(object,[], pairs, new Set());
  return pairs;
}

/** Get merged list of nested keys for multiple objects. */
export function keyNestedMerge(...objects: Object[]): any[] {
  return arraySet(objects.flatMap(o => keyNested(o))).sort();
}

/** Split a key depending on whether a given key is a subkey.
 *  @returns [NestedKey<T>, undefined]
 */
export function keyNestedSubkey<T>(parent: NestedKey<T>, child: NestedKey<T>): [parentKey: NestedKey<T>, childKey: undefined] | [parentKey: undefined, childKey: NestedKey<T>] {
  let [p, c] = [parent as string, child as string];
  return c.startsWith(`${p}.`) ? [undefined, `${c}`.slice(p.length + 1)] : [child, undefined] as any;
}

/** Get list of subkeys under given key. */
export function keyNestedSubkeys(parent: string, keys: Pair[]) {
  let output: Pair[] = [];
  let length = parent.length;
  let periods = (parent.match(/\./g) ?? []).length;

  for (let key of keys) {
    if (!key.value.startsWith(parent) || key.value[length] !== '.') continue;

    let value = key.value.slice(length + 1);
    let view = key.view.split(KeyJoin.Separator).slice(periods + 1).join(KeyJoin.Separator);
    output.push(new Pair(value, view));
  }

  return output.sort((a, b) => a.view.localeCompare(b.view));
}

/** Get list of nested pairs for an object. */
export function keyNestedPairs<T>(object: T): Pair<NestedKey<T>>[] {
  let pairs = new Map<string, Pair>();
  keyNestedPairsDeep(object, [], undefined, pairs);
  return [...pairs.values()].sort((a, b) => a.view.localeCompare(b.view)) as Pair<NestedKey<T>>[];
}

/** Recursively get all keys from object of a given type. */
function keyNestedPairsDeep(object: any, path: string[], name: string | undefined, pairs: Map<string, Pair>): void {
  if (!(object instanceof Object)) return;
  
  if (object instanceof UnionValidator) {
    // Handle nested union info.
    for (let subvalue of objectValues(object.unioninfo.classes)) {
      keyNestedPairsDeep(subvalue, path, name, pairs);
    } return;
  } else if (object instanceof Validator) {
    return keyNestedPairsDeep(object.value(), path, name, pairs);
  } else if (arraySome(object)) {
    return keyNestedPairsDeep(object[0], path, name, pairs);
  }

  let typeinfo = typeinfoOf(object);
  let propinfo = propinfoOf(object);

  for (let key in object) {
    // Prevent registering duplicate keys.
    path.push(key);

    let subname = propertyFrom(propinfo, typeinfo, object, key, name).name;
    let subkey = path.join('.');
    if (!pairs.has(subkey)) {
      path.pop();
      pairs.set(subkey, new Pair(subkey, propertyFrom(propinfo, typeinfo, object, key, name).name));
      path.push(key);
    }

    // Recursively traverse object.
    let value = typeinfoValue(object, key, typeinfo);
    keyNestedPairsDeep(value, path, subname, pairs);
    path.pop();
  }
}

/** Recursively get all properties from object of a given type. */
function keyNestedTypeinfoDeep<T>(object: T, path: string[], pairs: NestedKeyInfo<T>[], seen: Set<string>): void {
  if (!(object instanceof Object)) return;

  if (object instanceof UnionValidator) {
    // Handle nested union info.
    for (let subvalue of objectValues(object.unioninfo.classes)) {
      keyNestedTypeinfoDeep(subvalue, path, pairs, seen);
    } return;
  } else if (object instanceof Validator) {
    return keyNestedTypeinfoDeep(object.value(), path, pairs, seen);
  } else if (arraySome(object as any)) {
    return keyNestedTypeinfoDeep((object as any)[0], path, pairs, seen);
  }

  let typeinfo = typeinfoOf(object);

  for (let key in object) {
    // Prevent registering duplicate keys.
    path.push(key);
    let subkey = path.join('.');
    let value = typeinfoValue(object, key as keyof T, typeinfo);
    if (!seen.has(subkey)) {
      seen.add(subkey);

      path.pop();
      pairs.push([subkey as NestedKey<T>, value]);
      path.push(key);
    }

    // Recursively traverse object.

    keyNestedTypeinfoDeep(value as any, path, pairs, seen);
    path.pop();
  }
}

/** Get nested value of object. */
export function keyNestedGet<T>(key: NestedKey<T>, context: T) {
  return keyDeepGet(`${key}`.split('.'), context, 0);
}

/** Set nested value of object. */
export function keyNestedSet<T>(key: NestedKey<T>, context: T, value: any) {
  return keyDeepSet(`${key}`.split('.'), context, value, 0);
}

/** Get nested value of object, getting first value of arrays. */
export function keyFlatGet<T>(key: NestedKey<T>, context: T) {
  let value = keyNestedGet(key, context);
  if (arraySome(value)) {
    return value[0];
  } else if (Array.isArray(value)) {
    return undefined;
  } return value;
}

/** Recursively get nested value of object. */
function keyDeepGet(path: string[], context: any, i: number): any {
  if (!context) return undefined;
  context = context[path[i]!];
  if (!path[i + 1]) return context;
  
  if (Array.isArray(context)) {
    return context.map(item => keyDeepGet(path, item, i + 1));
  } else if (context instanceof Object) {
    return keyDeepGet(path, context, i + 1);
  }

  return undefined;
}

/** Recursively set nested value of object. */
function keyDeepSet(path: string[], context: any, value: any, i: number): any {
  if (context instanceof Object) {
    if (path[i + 1]) {
      context = context[path[i]!];
      if (Array.isArray(context)) {
        for (let item in context) keyDeepSet(path, item, context, i + 1);
      } else {
        keyDeepSet(path, context, value, i + 1);
      }
    } else {
      context[path[i]!] = value;
    }
  }
}