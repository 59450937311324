import { Pipe } from '@angular/core';
import { titleCase } from "../../../../../common/toolbox/string";

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe {
  transform(value: any) {
    return titleCase(String(value));
  }
}
