import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT } from "../toolbox/id";
import { UnionValidator } from "../validator/union";
import { FusionCollection } from "./fusion";
import { ClaimSaveTriggerConfig, TRIGGER_CONFIG_UNIONINFO, TriggerConfigClass, TriggerType } from "./trigger-config";

/** An action to perform in the system in response to a user interaction. */
export class Trigger<T extends TriggerType = TriggerType> {
  constructor(
    /** The MongoDB ID of this trigger */
    public _id = ID_DEFAULT,
    /** The institution of this trigger */
    public _inst = ID_DEFAULT,
    /** The name of this trigger */
    public name = '',
    /** The configuration for this trigger */
    public config = new ClaimSaveTriggerConfig() as TriggerConfigClass[T],
    /** Whether to run this trigger or not. */
    public _condition?: string
  ) { }
  
  static typeinfo: TypeInfo<Trigger> = {
    config: new UnionValidator(TRIGGER_CONFIG_UNIONINFO),
    _condition: ID_DEFAULT
  }

  static collectioninfo: CollectionInfo<FusionCollection, Trigger> = {
    _condition: 'formulas'
  }

  static indexinfo: IndexInfo<Trigger> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** A preview of a trigger. */
export class TriggerPreview {
  constructor(
    /** The MongoDB ID of this trigger */
    public _id = ID_DEFAULT,
    /** The name of this trigger */
    public name = ''
  ) {}
}