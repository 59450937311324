import { BankUnion } from '../bank';
import { DisplayValue } from '../display';
import { Statement } from '../formula/statement';
import { AccountFormula, BUILTIN_ACCOUNT_FORMULAS } from './formula/account';
import { BUILTIN_CLAIM_FORMULAS, ClaimFormula } from './formula/claim';
import { BUILTIN_DISPUTE_FORMULAS, DisputeFormula } from './formula/dispute';
import { BUILTIN_DISPUTES_FORMULAS, DisputesFormula } from './formula/disputes';
import { BUILTIN_EVENT_FORMULAS, EventFormula } from './formula/event';
import { BUILTIN_MEMBER_FORMULAS, MemberFormula } from './formula/member';
import { BUILTIN_STANDARD_FORMULAS, StandardFormula } from './formula/standard';
import { BUILTIN_TRANSACTION_FORMULAS, TransactionFormula } from './formula/transaction';
import { BUILTIN_TRANSACTIONS_FORMULAS, TransactionsFormula } from './formula/transactions';

/** List of built-in system formulas. */
export type SystemFormula =
  | AccountFormula
  | ClaimFormula
  | DisputeFormula
  | DisputesFormula
  | EventFormula
  | MemberFormula
  | StandardFormula
  | TransactionFormula
  | TransactionsFormula;
  
/** Configuration for a built-in formula. */
export interface BuiltinFormula {
  /** Override for formula name. */
  name?: BankUnion<string>;
  /** Statement list of formula. */
  statements: Statement<DisplayValue>[];
}

/**
 *  List of standard built-in formulas added to new institution.
 *  TODO Add checks at institution setup that these are valid nested keys.
 */
export const BUILTIN_FORMULAS: Record<SystemFormula, BuiltinFormula> = {
  ...BUILTIN_ACCOUNT_FORMULAS,
  ...BUILTIN_CLAIM_FORMULAS,
  ...BUILTIN_DISPUTE_FORMULAS,
  ...BUILTIN_DISPUTES_FORMULAS,
  ...BUILTIN_EVENT_FORMULAS,
  ...BUILTIN_MEMBER_FORMULAS,
  ...BUILTIN_STANDARD_FORMULAS,
  ...BUILTIN_TRANSACTION_FORMULAS,
  ...BUILTIN_TRANSACTIONS_FORMULAS
}