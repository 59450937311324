import { Component, Input } from '@angular/core';
import { CollectionsExecutiveStat } from './collections-executive-stats.model';

@Component({
  selector: 'app-collections-executive-stats',
  templateUrl: './collections-executive-stats.component.html',
  styleUrls: ['./collections-executive-stats.component.scss']
})
export class CollectionsExecutiveStatComponent {
  /** Stats to display. */
  @Input() stats: CollectionsExecutiveStat[] = [];
}
