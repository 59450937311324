import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Model, ModelPreview } from "../../../../../common/model/model";
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { LogService } from './log.service';
import { ApplicationSettingsKey } from '../../../../../common/model/setting-group';
import { serviceSettingsItem } from '../toolbox/service';
import { AuthService } from './auth.service';
import { SettingGroupService } from './setting-group.service';

/** A query to fetch a specific model. */
export class ModelQuery {
  constructor(
    /** ID of model. */
    public _id = ID_DEFAULT,
    /** Institution of model. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Caches information about tasks. */
@Injectable({
  providedIn: 'root'
})
export class ModelService extends CachePreviewService<Model, ModelQuery, ModelPreview> {
  readonly route = 'models/preview';
  readonly Type = Model;
  
  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private settings: SettingGroupService
  ) {
    super(ModelQuery, log, dialog, http);
  }

  protected override multiple(queries: ArraySome<ModelQuery>) {
    return getRequest(this.http, 'models', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }

  /** Fetch a form from settings. */
  setting(key: ApplicationSettingsKey) {
    return serviceSettingsItem('Model', this, this.auth, this.log, this.settings, key);
  }
}
