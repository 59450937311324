import { CollectionInfo } from "../info/collection";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT } from "../toolbox/id";
import { ObjectKeys } from "../toolbox/object";
import { EnumValidator } from "../validator/enum";
import { ClaimUnion } from "./claim/claim";
import { DocumentTemplateDataGeneral, DocumentTemplateDataNotification } from "./document-template/data";
import { EventCategory, EventCategoryResultType } from "./event/category";
import { AccountResultType } from "./event/result/account";
import { FusionCollection } from "./fusion";

/** Minimum set of populated fields required to send out notifications. */
export type NotificationData<T extends DocumentTemplateDataNotification = DocumentTemplateDataNotification> = Omit<T, 'unsubscribe' | keyof DocumentTemplateDataGeneral>;

/** Base fields used by all notifications. */
export class NotificationBase {
  constructor(
    /** List of users to notify. */
    public _users?: string[],
    /** List of profiles to notify. */
    public _profiles?: string[],
    /** List of unsubscribed users. */
    public _notUsers?: string[]
  ) {}

  static typeinfo: TypeInfo<NotificationBase> = {
    _users: [ID_DEFAULT],
    _profiles: [ID_DEFAULT],
    _notUsers: [ID_DEFAULT]
  }

  static collectioninfo: CollectionInfo<FusionCollection, NotificationBase> = {
    _users: 'users',
    _profiles: 'profiles',
    _notUsers: 'users'
  }
}

/** Emit notification for claim dates nearing deadline. */
export class NotificationClaimDate extends NotificationBase {
  constructor(
    /** Claim to emit notifications for, or undefined to check all claims. */
    public _claim?: string,
    /** Date to check for. */
    public date: ObjectKeys<Required<ClaimUnion>, Date> = 'reviewDate',
    /** Treshold to give warning, in days before date. */
    public days = 0
  ) {
    super();
  }

  static override typeinfo: TypeInfo<NotificationClaimDate> = {
    ...NotificationBase.typeinfo,
    _claim: ID_DEFAULT
  }

  static override collectioninfo: CollectionInfo<FusionCollection, NotificationBase> = {
    ...NotificationBase.collectioninfo as any,
    _claim: 'claims'
  };
}

/** Emit notification for added events. */
export class NotificationEvent<C extends EventCategory = EventCategory, R extends EventCategoryResultType[C] = any> extends NotificationBase {
  constructor(
    /** Category of event. */
    public category = EventCategory.Account as C,
    /** Result of event. */
    public result = AccountResultType.Form as R,
    /** Specific claim to filter events to. */
    public _claim?: string
  ) {
    super();
  }

  static override typeinfo: TypeInfo<NotificationEvent> = {
    ...NotificationBase.typeinfo,
    category: new EnumValidator(EventCategory),
    result: 0,
    _claim: ID_DEFAULT
  }
}
