import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  host: {
    '[attr.disabled]': 'disabled ? "" : null',
  }
})
export class BadgeComponent {
  /** Value to display inside badge. */
  @Input() value = 0;
  /** True if currently disabled. */
  @Input() disabled = false;
}
