import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";
import { JobPostResponse } from "../../../../../../../common/message/job";
import { Job } from "../../../../../../../common/model/job/job";
import { JOB_TYPE_INSTANT, JOB_TYPE_NAME, JobType } from "../../../../../../../common/model/job/payload";
import { DurationFormat } from "../../../../../../../common/toolbox/duration";
import { enumValues } from "../../../../../../../common/toolbox/enum";
import { MaybeId, idMaybe } from "../../../../../../../common/toolbox/id";
import { Newable } from "../../../../../../../common/toolbox/object";
import { dateFormat } from "../../../../../../../common/toolbox/time";
import { CodeTabComponent } from "../../../../common/component/code-tab/code-tab.component";
import { CodeTabData } from "../../../../common/component/code-tab/code-tab.model";
import { ConfigService } from "../../../../common/service/config.service";
import { LogService } from "../../../../common/service/log.service";
import { TabService } from "../../../../common/service/tab.service";
import { postRequest } from "../../../../common/toolbox/request";
import { SetupEditComponent } from "../../setup-edit.component";
import { JOB_SETUP_COMPONENT } from "../setup-job.model";
import { JobAccessor } from "./setup-job-edit.model";

@Component({
  selector: 'app-setup-job-edit',
  templateUrl: './setup-job-edit.component.html',
  styleUrls: ['./setup-job-edit.component.scss'],
  host: {
    class: 'row fill'
  }
})
export class SetupJobEditComponent extends SetupEditComponent<Job, JobPostResponse> {
  readonly JOB_TYPE_NAME = JOB_TYPE_NAME;
  readonly JOB_TYPE_INSTANT = JOB_TYPE_INSTANT;
  readonly DurationFormat = DurationFormat;
  readonly types = enumValues<JobType>(JobType);
  
  /** Container to inject components. */
  @ViewChild('container', { static : true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** Human readable description of cron pattern. */
  frequency?: string;
  /** Current job being edited. */
  value = idMaybe(new Job());
  /** Component injected into template. */
  component?: JobAccessor;
  /** True if forcing job to run on save. */
  force = false;

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private http: HttpClient,
    private config: ConfigService,
    private tabs: TabService
  ) {
    super(elementRef, log);
  }

  /** Callback when changing type of job. */
  onType(type: JobType) {
    this.value.payload = Job.payload(type);
    this.repayload();
  }

  /** Callback when viewing job result. */
  onResult(date: Date) {
    this.tabs.open(CodeTabComponent, new CodeTabData(this.value.result), `${this.value.name} - ${dateFormat(date, this.config.dateTimeFormat)}`);
  }

  /** Callback when clearing job result. */
  onClear() {
    this.value.date = this.value.result = undefined;
  }

  /** Callback when saving and forcing job to run. */
  async onForce() {
    this.force = true;
    await this.onSubmit();
    this.force = false;
  }

  /** Submit current changes to job. */
  push() {
    return postRequest(this.http, 'jobs', { items: [this.value], force: this.force });
  }

  /** Reset current form with new job. */
  async reset(value: MaybeId<Job>) {
    this.value = value;
    this.repayload();
  }

  /** Update configuration form for job payload. */
  private repayload() {
    this.containerRef.clear();
    this.component = undefined;

    let prototype: Newable<JobAccessor> = JOB_SETUP_COMPONENT[this.value.payload.type];
    this.component = this.containerRef.createComponent(prototype).instance;
    this.component.job = this.value;
    this.component.reset();
  }
}
