import { Component, Inject } from '@angular/core';
import { ClaimType, DISPUTE_AVAILABLE_CREDIT_STATUSES, DisputeCreditStatus, DisputeType } from '../../../../../../../common/code/standard/disputes';
import { DisputesCode } from '../../../../../../../common/code/system';
import { Event } from "../../../../../../../common/model/event";
import { EventCategory } from "../../../../../../../common/model/event/category";
import { ClaimResultType } from "../../../../../../../common/model/event/result/claim";
import { errorResponse } from "../../../../../../../common/toolbox/message";
import { DIALOG_DATA, DialogRef } from '../../../../common/component/dialog/dialog.model';
import { FormConfig } from '../../../../common/component/form/form.model';
import { AuthService } from '../../../../common/service/auth.service';
import { EventService } from '../../../../common/service/event.service';
import { FormService } from '../../../../common/service/form.service';
import { LogService } from '../../../../common/service/log.service';
import { TaskDisputeWithdrawData, TaskDisputeWithdrawReturn } from './task-dispute-withdraw.model';

@Component({
  selector: 'app-task-dispute-withdraw',
  templateUrl: './task-dispute-withdraw.component.html',
  styleUrls: ['./task-dispute-withdraw.component.scss']
})
export class TaskDisputeWithdrawComponent {
  readonly DisputeType = DisputeType;
  readonly DisputesCode = DisputesCode;

  /** Configuration for dispute being viewed. */
  config?: FormConfig;
  /** New credit status for dispute. */
  creditStatus = DisputeCreditStatus.None;
  /** Available credit statuses to select from. */
  creditStatuses: DisputeCreditStatus[] = [];

  constructor(
    @Inject(DIALOG_DATA)
    public data: TaskDisputeWithdrawData,
    public dialogRef: DialogRef<TaskDisputeWithdrawReturn>,
    protected auth: AuthService,
    private forms: FormService,
    private events: EventService,
    private log: LogService,
  ) {
    if ('dispute' in data && data.dispute.type === DisputeType.Card) {
      this.creditStatuses = DISPUTE_AVAILABLE_CREDIT_STATUSES[data.dispute.creditStatus];
      this.creditStatus = this.creditStatuses[0]!;
    }
  }

  async ngOnInit() {
    if ('claim' in this.data && this.data.claim.type !== ClaimType.CreditBureau) {
      this.config = {
        form: { ...await this.forms.setting(`disputes.${this.data.claim.type}.dispute.form`), editable: undefined },
        value: this.data.partial
      };
    }
  }

  /** Callback when withdrawing dispute. */
  async onWithdraw() {
    // Update existing event if applicable.
    if ('dispute' in this.data) {
      let response = await this.events.add({
        event: {
          ...Event.fallback(this.data.dispute._inst, this.data.open?.event),
          result: {
            category: EventCategory.Claim,
            type: ClaimResultType.DisputeWithdraw,
            _claim: this.data.claim._id,
            _dispute: this.data.dispute._id
          }
        },
      });
      if (errorResponse(response)) return this.log.show(response);
  
      if (this.data.dispute.type === DisputeType.Card) this.data.dispute.creditStatus = this.creditStatus;
    }

    this.dialogRef.close(true);
  }
}
