import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '../../../../../common/message/error';
import { InstitutionGetRequest } from "../../../../../common/message/instititution";
import { OrganizationGetRequest } from "../../../../../common/message/organization";
import { Engine } from '../../../../../common/model/formula/engine';
import { Institution } from "../../../../../common/model/institution";
import { FeatureType, Features } from "../../../../../common/model/organization/feature";
import { Organization } from "../../../../../common/model/organization/organization";
import { Permission } from '../../../../../common/model/permission';
import { PermissionGroup, permissionGroupArray } from '../../../../../common/model/permission-group';
import { SessionGrant } from '../../../../../common/model/user/grant';
import { UserReset, UserSession } from "../../../../../common/model/user/user";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { permissionHas } from '../../../../../common/toolbox/permission';
import { SearchOptions } from '../toolbox/fusion';
import { getOneRequest, getRequest, postRequest } from '../toolbox/request';
import { DevService } from './dev.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /** Get organization of current user. */
  get _org() { return this.session._org; }

  /** True if currently logged in. */
  valid = false;
  /** Current logged-in user. */
  session = new UserSession();
  /** Grants of current institution. */
  grant = new SessionGrant();
  /** ID of current selected institution. */
  _inst = ID_DEFAULT;

  /** Current selected feature. */
  feature = FeatureType.Collections;
  /** Current selected institution. */
  institution = new Institution();
  /** List of institutions visible to user */
  institutions: Institution[] = [];
  /** Organization of user. */
  organization = new Organization();

  constructor(
    private dev: DevService,
    private log: LogService,
    private http: HttpClient,
    private router: Router
  ) {
    // Hook up permission checking to formula engine.
    Engine.granted = permissions => permissions.every(p => this.permission(p));
  }

  /** Initialize user data after logging in. */
  async login(email: string, password: string): Promise<UserSession | undefined> {
    let user = await postRequest(this.http, 'auth/login', { email, password });
    if (errorResponse(user)) {
      this.log.show(user);
      return undefined;
    }

    if (UserReset.check(user)) {
      // Force password reset.
      this.router.navigate(['/reset-password'], { queryParams: { email: user.email } });
      return undefined;
    }

    this.refresh(user);
    return user;
  }

  /** Log out current user. */
  async logout() {
    let success = await postRequest(this.http, 'auth/logout', undefined);
    if (errorResponse(success)) {
      this.log.show(success);
      return;
    }
    
    // Clear user and return to login page
    this.session = new UserSession();
    this.router.navigate(['login']);
    this.valid = false;
  }

  /** Reset user's password and log in. */
  async reset(email: string, old: string, password: string) {
    let session = await postRequest(this.http, 'auth/reset-password', { email, old, password });
    if (errorResponse(session)) {
      this.log.show(session);
      return;
    }

    this.refresh(session);
  }

  /** Refresh list of visible institutions for current user. */
  async refresh(session: UserSession) {
    // Set user and hide login page.
    Object.setPrototypeOf(session, UserSession.prototype);
    this.session = session;
    this.dev.refresh(session._id);

    // Fetch options of user.
    let options = await DB.get('searchOptions', session._id);
    if (errorResponse(options)) {
      this.log.show(options);
      options = undefined;
    }

    // Fetch current organization of user.
    let organization = await getOneRequest(this.http, 'organizations', new OrganizationGetRequest([this.session._org]));
    if (errorResponse(organization)) return this.log.show(organization);

    // Select first feature or previously-selected one.
    let feature = Features.first(organization.features);
    if (feature === undefined) return this.log.show('No features are enabled for your organization.');
    if (options) feature = Features.enabled(organization.features, options.feature) ? options.feature : feature;
    this.feature = feature;

    // Fetch list of visible institutions for user.
    this.organization = organization;
    let institutions = await getRequest(this.http, 'institutions', new InstitutionGetRequest([this.session._org], this.session.institutions()));
    if (errorResponse(institutions)) return this.log.show(institutions);
    this.institutions = institutions;

    // Select first institution or previously-selected one.
    let institution = institutions[0];
    if (institution === undefined) return this.log.show('You have not been granted access to any institutions.');
    if (options) institution = institutions.find(i => i._id === (options as SearchOptions)._inst) ?? institution;
    this.institution = institution;
    this._inst = institution._id;

    // Find granted permissions of this institution.
    let grant =  this.session.grants.find(({ _inst }) => _inst === this._inst);
    if (!grant) {
      this.log.show(new ErrorResponse(`Grants not found for institution: ${this._inst}`));
      return;
    }
    
    // Show remainder of page.
    this.grant = grant;
    this.valid = true;
    this.router.navigate(['']);
  }

  /** Check if organization has given feature and if it is currently selected.. */
  enabled(feature?: FeatureType) {
    if (feature === undefined) return true;
    return this.session.features.some(f => f === feature) && this.feature == feature;
  }

  /** Check if user has given permission. */
  permission(permission?: Permission) {
    return permission === undefined || permissionHas(this.grant.permissions, permission);
  }

  /** Check if user has any of given permissions. */
  permissionSome(permissions?: PermissionGroup | Permission[]) {
    permissions = permissionGroupArray(permissions);
    return !permissions?.length || permissions.some(permission => permissionHas(this.grant.permissions, permission));
  }

  /** Update currently selected feature. */
  async refeature(feature: FeatureType) {
    let result = await DB.partial('searchOptions', { feature }, new SearchOptions(this.session._id));
    if (errorResponse(result)) this.log.show(result);
    location.reload();
  }

  /** Update currently selected institution. */
  async reinstitution(institution: Institution) {
    let result = await DB.partial('searchOptions', { _inst: institution._id }, new SearchOptions(this.session._id));
    if (errorResponse(result)) this.log.show(result);
    location.reload();
  }
}
