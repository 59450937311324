import { Component } from '@angular/core';
import { TOOLTIP_POSITIONS } from '../../../common/component/tooltip/tooltip.model';

@Component({
  selector: 'app-demo-tooltip',
  templateUrl: './demo-tooltip.component.html',
  styleUrls: ['./demo-tooltip.component.scss']
})
export class DemoTooltipComponent {
  readonly TOOLTIP_POSITIONS = TOOLTIP_POSITIONS;

  static title() {
    return 'Tooltip';
  }
}
