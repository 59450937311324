import { TypeInfo } from "../info/type";
import { DebugData } from "../model/debug";
import { ImapPreview, ImapQuery } from "../model/imap";
import { SmtpMessage } from "../model/smtp";
import { RouteMethod } from "../toolbox/api";
import { ArraySome } from "../toolbox/array";
import { PaginateRequest, PaginateResponse } from "./paginate";

/** Request to echo a message back to user. */
export class DebugEchoGetRequest {
  constructor(
    /** Message to echo to logs. */
    public message = ''
  ) {}
}

/** Result when echoing message. */
export type DebugEchoGetResponse = DebugEchoGetRequest;

/** Request to get some dummy paginated data. */
export class DebugPaginateGetRequest extends PaginateRequest<DebugData> {
  static typeinfo: TypeInfo<DebugPaginateGetRequest> = {
    ...PaginateRequest.typeinfoFor(new DebugData())
  }
}

/** Result when echoing message. */
export type DebugPaginateGetResponse = PaginateResponse<DebugData>;

/** Send a request to retrieve JSON from route. */
export class DebugJsonGetRequest {
  constructor(
    /** Request options to send to route. */
    public options: Record<string, string> = {}
  ) {}
}

/** Result of getting JSON from route. */
export type DebugJsonGetResponse = any;

/** Request to get Postman request configuration for an endpoint. */
export class DebugPostmanGetRequest {
  constructor(
    /** Method for request. */
    public method = RouteMethod.Get,
    /** API endpoint. */
    public route = '',
    /** True to comment out get parameters. */
    public comment?: boolean
  ) {}

  static typeinfo: TypeInfo<DebugPostmanGetRequest> = {
    comment: false
  }
}

/** Result when getting postman request. */
export type DebugPostmanGetResponse = any;

/** Send a test email. */
export class DebugEmailPostRequest extends SmtpMessage {}

/** Result of sending a test email. */
export type DebugEmailPostResponse = ArraySome<string>;

/** Retrieve list of emails. */
export class DebugEmailPreviewGetRequest extends ImapQuery {}

/** Result of getting list of emails. */
export type DebugEmailPreviewGetResponse = ImapPreview[];