import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../../common/model/formula/block";
import { PLACEHOLDER_EXPRESSION } from "../../../../../../../../common/model/formula/placeholder";
import { StatementReturn } from "../../../../../../../../common/model/formula/statement";
import { ColorCode } from "../../../../../../../../common/toolbox/color";
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';
import { BlockSlot } from '../../block.model';

@Component({
  selector: 'app-setup-formula-return',
  templateUrl: './setup-formula-return.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-return.component.scss'],
  host: { color: ColorCode.Secondary }
})
export class SetupFormulaReturnComponent extends BlockComponent<StatementReturn> {
  /** Attachment point for expression. */
  @ViewChild('expression', { static : true, read: ViewContainerRef }) expression!: ViewContainerRef;

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  ngOnInit() {
    this.reexpression(undefined, this.block.expression ?? PLACEHOLDER_EXPRESSION);
  }

  /** Replace expression. */
  private reexpression(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.expression,
      current: { block: this.block, key: 'expression' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.reexpression.bind(this)
    });
  }
}
