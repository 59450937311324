import { Pipe } from '@angular/core';
import { SystemCode } from '../../../../../common/code/system';
import { CodeTypeService } from '../service/code-type.service';

@Pipe({ name: 'codeType' })
export class CodeTypePipe {

  constructor(
    private types: CodeTypeService
  ) {}

  /** Format a code type by ID. */
  transform(key: string, _inst: string, category: SystemCode): Promise<string> {
    return this.types.codename(key, { _inst, category });
  }
}
