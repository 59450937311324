import { Component } from '@angular/core';
import { DisputesCode } from '../../../../../../../../common/code/system';
import { ClaimFilterDisputeCreditStatus } from '../../../../../../../../common/model/claim/filter';
import { ClaimFilterAccessor } from '../../edit/setup-claim-filter-edit.model';

@Component({
  selector: 'app-setup-claim-filter-dispute-credit-status',
  templateUrl: './setup-claim-filter-dispute-credit-status.component.html',
  styleUrls: ['./setup-claim-filter-dispute-credit-status.component.scss']
})
export class SetupClaimFilterDisputeCreditStatusComponent extends ClaimFilterAccessor<ClaimFilterDisputeCreditStatus> {
  readonly DisputesCode = DisputesCode;
}