
import { ImportCode } from "../code/system";
import { IndexInfo } from "../info";
import { PropertyInfo } from "../info/prop";
import { TypeInfo } from "../info/type";
import { PropertyType } from "../model/property-type";
import { enumValues } from "../toolbox/enum";
import { ID_DEFAULT } from "../toolbox/id";
import { Range } from "../toolbox/number";

/** Types of randomly generated cards. */
export enum CardNetwork {
  AmericanExpress,
  ChinaUnionPay,
  Dankort,
  DinersClubCarteBlanche,
  DinersClubInternational,
  DinersClubUnitedStates,
  Discover,
  InterPayment,
  JCB,
  Maestro,
  MasterCard,
  UATP,
  Visa,
  VisaElectron
}

/** A card attached to an account or member. */
export class Card {
  constructor(
    /** Unique identifier of card. */
    public _id = ID_DEFAULT,
    /** Institution of card. */
    public _inst = ID_DEFAULT,
    /** Number on card. */
    public number = '',
    /** Short description of card. */
    public description?: string,
    /** Host-defined code for card block status. */
    public block?: string,
    /** Date card was activated. */
    public effectiveDate?: Date,
    /** Date card was closed, if applicable. */
    public closeDate?: Date,
    /** Current status of card. */
    public status?: string,
    /** Reason for card status. */
    public statusReason?: string,
    /** Locator of card. Used for some core systems to lookup a card */
    public locator?: string,
    /** Type code on card. */
    public type: string = '',
    /** True if this is test data. */
    public test?: boolean
  ) {}

  static typeinfo: TypeInfo<Card> = {
    block: '',
    description: '',
    closeDate: new Date(),
    effectiveDate: new Date(),
    locator: '',
    status: '',
    statusReason: '',
    test: false
  }

  static propinfo: PropertyInfo<Card> = {
    block: { type: PropertyType.Code, category: ImportCode.CardBlock },
    status: { type: PropertyType.Code, category: ImportCode.CardStatus },
    statusReason: { type: PropertyType.Code, category: ImportCode.CardReason },
    type: { type: PropertyType.Code, category: ImportCode.CardType }
  }

  static indexinfo: IndexInfo<Card> = [
    { key: { _inst: 1, number: 1 }, unique: true }
  ];
}

/** Information about a card network. */
export class CardFormat {
  constructor(
    /** Length of card number. */
    public length: number[],
    /** Possible card prefixes. */
    public prefixes: (number | Range)[]
  ) {}
}

/** Fields that make a card unique. */
export class CardReference {
  constructor(
    /** Institution of card. */
    public _inst = ID_DEFAULT,
    /** Debit or credit card number */
    public number = ''
  ) {}
}

/** List of all card networks. */
export const CARD_NETWORKS: CardNetwork[] = enumValues(CardNetwork);

/** Description of each card network. */
export const CARD_NETWORK_NAME: Record<CardNetwork, string> = {
  [CardNetwork.AmericanExpress]: 'American Express',
  [CardNetwork.ChinaUnionPay]: 'China UnionPay',
  [CardNetwork.Dankort]: 'Dankort',
  [CardNetwork.DinersClubCarteBlanche]: 'Diners Club Carte Blanche',
  [CardNetwork.DinersClubInternational]: 'Diners Club International',
  [CardNetwork.DinersClubUnitedStates]: 'Diners Club United States & Canada',
  [CardNetwork.Discover]: 'Discover',
  [CardNetwork.InterPayment]: 'InterPayment',
  [CardNetwork.JCB]: 'JCB',
  [CardNetwork.Maestro]: 'Maestro',
  [CardNetwork.MasterCard]: 'MasterCard',
  [CardNetwork.UATP]: 'UATP',
  [CardNetwork.Visa]: 'Visa',
  [CardNetwork.VisaElectron]: 'Visa Electron'
}

/** Possible formats for each card network. */
export const CARD_NETWORK_FORMAT: Record<CardNetwork, CardFormat[]> = {
  [CardNetwork.AmericanExpress]:          [new CardFormat([15], [34, 37])],
  [CardNetwork.ChinaUnionPay]:            [new CardFormat([16, 17, 18, 19], [62])],
  [CardNetwork.Dankort]:                  [new CardFormat([16], [5019])],
  [CardNetwork.DinersClubCarteBlanche]:   [new CardFormat([14], [new Range(300, 305)])],
  [CardNetwork.DinersClubInternational]:  [new CardFormat([14], [36, 38, 39, new Range(300, 305), 309])],
  [CardNetwork.DinersClubUnitedStates]:   [new CardFormat([16], [54, 55])],
  [CardNetwork.Discover]:                 [new CardFormat([16], [65, new Range(644, 649), 6011, new Range(622126, 622925)])],
  [CardNetwork.InterPayment]:             [new CardFormat([16, 17, 18, 19], [636])],
  [CardNetwork.JCB]:                      [new CardFormat([16], [new Range(3528, 3589)])],
  [CardNetwork.Maestro]:                  [new CardFormat([12, 13, 14, 15, 16, 17, 18, 19], [new Range(500000, 509999), new Range(560000, 589999), new Range(600000, 699999)])],
  [CardNetwork.MasterCard]:               [new CardFormat([16], [new Range(51, 55), new Range(222100, 272099)])],
  [CardNetwork.UATP]:                     [new CardFormat([15], [1])],
  [CardNetwork.Visa]:                     [new CardFormat([13, 14, 15, 16, 17, 18, 19], [4])],
  [CardNetwork.VisaElectron]:             [new CardFormat([16], [4026, 4405, 4508, 4844, 4913, 4917, 417500])]
}