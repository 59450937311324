import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { ClaimFilterPostResponse } from "../../../../../../../common/message/claim-filter";
import { CLAIM_FILTER_NAME, ClaimFilter, ClaimFilterAssigned, ClaimFilterBase, ClaimFilterType } from "../../../../../../../common/model/claim/filter";
import { Icon } from "../../../../../../../common/model/icon";
import { ColorCode } from "../../../../../../../common/toolbox/color";
import { enumValues } from "../../../../../../../common/toolbox/enum";
import { MaybeId, idMaybe } from "../../../../../../../common/toolbox/id";
import { LogService } from "../../../../common/service/log.service";
import { postRequest } from "../../../../common/toolbox/request";
import { SetupEditComponent } from "../../setup-edit.component";
import { SetupClaimFilterAssignedComponent } from "../config/assigned/setup-claim-filter-assigned.component";
import { SetupClaimFilterClaimStatusComponent } from "../config/claim-status/setup-claim-filter-claim-status.component";
import { SetupClaimFilterDisputeCreditStatusComponent } from "../config/dispute-credit-status/setup-claim-filter-dispute-credit-status.component";
import { SetupClaimFilterDisputeStatusComponent } from "../config/dispute-status/setup-claim-filter-dispute-status.component";
import { ClaimFilterAccessor } from "./setup-claim-filter-edit.model";

/** Component for configuring each claim filter type. */
export const CLAIM_FILTER_SETUP_COMPONENT = {
  [ClaimFilterType.Assigned]: SetupClaimFilterAssignedComponent,
  [ClaimFilterType.ClaimStatus]: SetupClaimFilterClaimStatusComponent,
  [ClaimFilterType.DisputeStatus]: SetupClaimFilterDisputeStatusComponent,
  [ClaimFilterType.DisputeCreditStatus]: SetupClaimFilterDisputeCreditStatusComponent
};

@Component({
  selector: 'app-setup-claim-filter-edit',
  templateUrl: './setup-claim-filter-edit.component.html',
  styleUrls: ['./setup-claim-filter-edit.component.scss'],
  host: {
    class: 'row fill'
  }
})
export class SetupClaimFilterEditComponent extends SetupEditComponent<ClaimFilter, ClaimFilterPostResponse> {
  readonly CLAIM_FILTER_NAME = CLAIM_FILTER_NAME;
  readonly icons = enumValues(Icon);
  readonly colors = enumValues(ColorCode);
  /** Container to inject components. */
  @ViewChild('container', { static : true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** Current claim filter being edited. */
  value: MaybeId<ClaimFilter> = idMaybe(new ClaimFilterAssigned());
  /** List of claim filter types to pick from. */
  types = enumValues<ClaimFilterType>(ClaimFilterType);

    /** Component injected into template. */
  component?: ClaimFilterAccessor;

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private http: HttpClient
  ) {
    super(elementRef, log);
  }

  /** Callback when changing type of claim-filter. */
  onType(type: ClaimFilterType) {
    ClaimFilterBase.retype(this.value, type);
    this.reconfig();
  }

  /** Submit current changes to claim-filter. */
  push() {
    return postRequest(this.http, 'claim-filters', { items: [this.value] });
  }

  /** Reset current form with new claim-filter. */
  async reset(value: MaybeId<ClaimFilter>) {
    this.value = value;
    this.reconfig();
  }

  /** Update configuration form for claim-filter config. */
  private reconfig() {
    this.containerRef.clear();
    this.component = undefined;

    let prototype: Type<ClaimFilterAccessor> = CLAIM_FILTER_SETUP_COMPONENT[this.value.type];
    this.component = this.containerRef.createComponent(prototype).instance;
    this.component.filter = this.value;
    this.component.reset();
  }
}
