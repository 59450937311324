import { Pipe } from '@angular/core';
import { BankUnion } from '../../../../../common/model/bank';
import { bankUnionResolve } from '../../../../../common/toolbox/bank';
import { AuthService } from '../service/auth.service';

@Pipe({ name: 'bankUnion' })
export class BankUnionPipe {

  constructor(
    private auth: AuthService
  ) {}

  /** Format a code type by ID. */
  transform<T>(union: BankUnion<T>) {
    return bankUnionResolve(union, this.auth.institution.bank);
  }
}
