
/** A mapping of enum values to icons. */
export type IconMap<T extends PropertyKey = string> = Record<T, Icon>;

/** Standard list of application icons. */
export enum Icon {
  _10k = '10k',
  _10mp = '10mp',
  _11mp = '11mp',
  _123 = '123',
  _12mp = '12mp',
  _13mp = '13mp',
  _14mp = '14mp',
  _15mp = '15mp',
  _16mp = '16mp',
  _17mp = '17mp',
  _18mp = '18mp',
  _18UpRating = '18_up_rating',
  _19mp = '19mp',
  _1k = '1k',
  _1kPlus = '1k_plus',
  _1xMobiledata = '1x_mobiledata',
  _20mp = '20mp',
  _21mp = '21mp',
  _22mp = '22mp',
  _23mp = '23mp',
  _24mp = '24mp',
  _2k = '2k',
  _2kPlus = '2k_plus',
  _2mp = '2mp',
  _30fps = '30fps',
  _30fpsSelect = '30fps_select',
  _360 = '360',
  _3dRotation = '3d_rotation',
  _3gMobiledata = '3g_mobiledata',
  _3k = '3k',
  _3kPlus = '3k_plus',
  _3mp = '3mp',
  _3p = '3p',
  _4gMobiledata = '4g_mobiledata',
  _4gPlusMobiledata = '4g_plus_mobiledata',
  _4k = '4k',
  _4kPlus = '4k_plus',
  _4mp = '4mp',
  _5g = '5g',
  _5k = '5k',
  _5kPlus = '5k_plus',
  _5mp = '5mp',
  _60fps = '60fps',
  _60fpsSelect = '60fps_select',
  _6FtApart = '6_ft_apart',
  _6k = '6k',
  _6kPlus = '6k_plus',
  _6mp = '6mp',
  _7k = '7k',
  _7kPlus = '7k_plus',
  _7mp = '7mp',
  _8k = '8k',
  _8kPlus = '8k_plus',
  _8mp = '8mp',
  _9k = '9k',
  _9kPlus = '9k_plus',
  _9mp = '9mp',
  Abc = 'abc',
  AccessAlarm = 'access_alarm',
  AccessAlarms = 'access_alarms',
  Accessibility = 'accessibility',
  AccessibilityNew = 'accessibility_new',
  Accessible = 'accessible',
  AccessibleForward = 'accessible_forward',
  AccessTime = 'access_time',
  AccessTimeFilled = 'access_time_filled',
  AccountBalance = 'account_balance',
  AccountBalanceWallet = 'account_balance_wallet',
  AccountBox = 'account_box',
  AccountCircle = 'account_circle',
  AccountTree = 'account_tree',
  AcUnit = 'ac_unit',
  Adb = 'adb',
  Add = 'add',
  AddAlarm = 'add_alarm',
  AddAlert = 'add_alert',
  AddAPhoto = 'add_a_photo',
  AddBox = 'add_box',
  AddBusiness = 'add_business',
  AddCard = 'add_card',
  AddChart = 'add_chart',
  Addchart = 'addchart',
  AddCircle = 'add_circle',
  AddCircleOutline = 'add_circle_outline',
  AddComment = 'add_comment',
  AddHome = 'add_home',
  AddHomeWork = 'add_home_work',
  AddIcCall = 'add_ic_call',
  AddLink = 'add_link',
  AddLocation = 'add_location',
  AddLocationAlt = 'add_location_alt',
  AddModerator = 'add_moderator',
  AddPhotoAlternate = 'add_photo_alternate',
  AddReaction = 'add_reaction',
  AddRoad = 'add_road',
  AddShoppingCart = 'add_shopping_cart',
  AddTask = 'add_task',
  AddToDrive = 'add_to_drive',
  AddToHomeScreen = 'add_to_home_screen',
  AddToPhotos = 'add_to_photos',
  AddToQueue = 'add_to_queue',
  AdfScanner = 'adf_scanner',
  Adjust = 'adjust',
  AdminPanelSettings = 'admin_panel_settings',
  AdsClick = 'ads_click',
  AdUnits = 'ad_units',
  Agriculture = 'agriculture',
  Air = 'air',
  Airlines = 'airlines',
  AirlineSeatFlat = 'airline_seat_flat',
  AirlineSeatFlatAngled = 'airline_seat_flat_angled',
  AirlineSeatIndividualSuite = 'airline_seat_individual_suite',
  AirlineSeatLegroomExtra = 'airline_seat_legroom_extra',
  AirlineSeatLegroomNormal = 'airline_seat_legroom_normal',
  AirlineSeatLegroomReduced = 'airline_seat_legroom_reduced',
  AirlineSeatReclineExtra = 'airline_seat_recline_extra',
  AirlineSeatReclineNormal = 'airline_seat_recline_normal',
  AirlineStops = 'airline_stops',
  AirplanemodeActive = 'airplanemode_active',
  AirplanemodeInactive = 'airplanemode_inactive',
  AirplaneTicket = 'airplane_ticket',
  Airplay = 'airplay',
  AirportShuttle = 'airport_shuttle',
  Alarm = 'alarm',
  AlarmAdd = 'alarm_add',
  AlarmOff = 'alarm_off',
  AlarmOn = 'alarm_on',
  Album = 'album',
  AlignHorizontalCenter = 'align_horizontal_center',
  AlignHorizontalLeft = 'align_horizontal_left',
  AlignHorizontalRight = 'align_horizontal_right',
  AlignVerticalBottom = 'align_vertical_bottom',
  AlignVerticalCenter = 'align_vertical_center',
  AlignVerticalTop = 'align_vertical_top',
  AllInbox = 'all_inbox',
  AllInclusive = 'all_inclusive',
  AllOut = 'all_out',
  AlternateEmail = 'alternate_email',
  AltRoute = 'alt_route',
  Analytics = 'analytics',
  Anchor = 'anchor',
  Android = 'android',
  Animation = 'animation',
  Announcement = 'announcement',
  Aod = 'aod',
  Apartment = 'apartment',
  Api = 'api',
  AppBlocking = 'app_blocking',
  AppRegistration = 'app_registration',
  Approval = 'approval',
  Apps = 'apps',
  AppSettingsAlt = 'app_settings_alt',
  AppShortcut = 'app_shortcut',
  AppsOutage = 'apps_outage',
  Architecture = 'architecture',
  Archive = 'archive',
  AreaChart = 'area_chart',
  ArrowBack = 'arrow_back',
  ArrowBackIos = 'arrow_back_ios',
  ArrowBackIosNew = 'arrow_back_ios_new',
  ArrowCircleDown = 'arrow_circle_down',
  ArrowCircleLeft = 'arrow_circle_left',
  ArrowCircleRight = 'arrow_circle_right',
  ArrowCircleUp = 'arrow_circle_up',
  ArrowDownward = 'arrow_downward',
  ArrowDropDown = 'arrow_drop_down',
  ArrowDropDownCircle = 'arrow_drop_down_circle',
  ArrowDropUp = 'arrow_drop_up',
  ArrowForward = 'arrow_forward',
  ArrowForwardIos = 'arrow_forward_ios',
  ArrowLeft = 'arrow_left',
  ArrowOutward = 'arrow_outward',
  ArrowRight = 'arrow_right',
  ArrowRightAlt = 'arrow_right_alt',
  ArrowUpward = 'arrow_upward',
  Article = 'article',
  ArtTrack = 'art_track',
  AspectRatio = 'aspect_ratio',
  Assessment = 'assessment',
  Assignment = 'assignment',
  AssignmentInd = 'assignment_ind',
  AssignmentLate = 'assignment_late',
  AssignmentReturn = 'assignment_return',
  AssignmentReturned = 'assignment_returned',
  AssignmentTurnedIn = 'assignment_turned_in',
  Assistant = 'assistant',
  AssistantDirection = 'assistant_direction',
  AssistantPhoto = 'assistant_photo',
  AssistWalker = 'assist_walker',
  AssuredWorkload = 'assured_workload',
  Atm = 'atm',
  AttachEmail = 'attach_email',
  AttachFile = 'attach_file',
  Attachment = 'attachment',
  AttachMoney = 'attach_money',
  Attractions = 'attractions',
  Attribution = 'attribution',
  AudioFile = 'audio_file',
  Audiotrack = 'audiotrack',
  AutoAwesome = 'auto_awesome',
  AutoAwesomeMosaic = 'auto_awesome_mosaic',
  AutoAwesomeMotion = 'auto_awesome_motion',
  AutoDelete = 'auto_delete',
  AutoFixHigh = 'auto_fix_high',
  AutoFixNormal = 'auto_fix_normal',
  AutoFixOff = 'auto_fix_off',
  AutofpsSelect = 'autofps_select',
  AutoGraph = 'auto_graph',
  AutoMode = 'auto_mode',
  Autorenew = 'autorenew',
  AutoStories = 'auto_stories',
  AvTimer = 'av_timer',
  BabyChangingStation = 'baby_changing_station',
  BackHand = 'back_hand',
  Backpack = 'backpack',
  Backspace = 'backspace',
  Backup = 'backup',
  BackupTable = 'backup_table',
  Badge = 'badge',
  BakeryDining = 'bakery_dining',
  Balance = 'balance',
  Balcony = 'balcony',
  Ballot = 'ballot',
  BarChart = 'bar_chart',
  BatchPrediction = 'batch_prediction',
  Bathroom = 'bathroom',
  Bathtub = 'bathtub',
  Battery0Bar = 'battery_0_bar',
  Battery1Bar = 'battery_1_bar',
  Battery2Bar = 'battery_2_bar',
  Battery3Bar = 'battery_3_bar',
  Battery4Bar = 'battery_4_bar',
  Battery5Bar = 'battery_5_bar',
  Battery6Bar = 'battery_6_bar',
  BatteryAlert = 'battery_alert',
  BatteryChargingFull = 'battery_charging_full',
  BatteryFull = 'battery_full',
  BatterySaver = 'battery_saver',
  BatteryStd = 'battery_std',
  BatteryUnknown = 'battery_unknown',
  BeachAccess = 'beach_access',
  Bed = 'bed',
  BedroomBaby = 'bedroom_baby',
  BedroomChild = 'bedroom_child',
  BedroomParent = 'bedroom_parent',
  Bedtime = 'bedtime',
  BedtimeOff = 'bedtime_off',
  Beenhere = 'beenhere',
  Bento = 'bento',
  BikeScooter = 'bike_scooter',
  Biotech = 'biotech',
  Blender = 'blender',
  Blind = 'blind',
  Blinds = 'blinds',
  BlindsClosed = 'blinds_closed',
  Block = 'block',
  Bloodtype = 'bloodtype',
  Bluetooth = 'bluetooth',
  BluetoothAudio = 'bluetooth_audio',
  BluetoothConnected = 'bluetooth_connected',
  BluetoothDisabled = 'bluetooth_disabled',
  BluetoothDrive = 'bluetooth_drive',
  BluetoothSearching = 'bluetooth_searching',
  BlurCircular = 'blur_circular',
  BlurLinear = 'blur_linear',
  BlurOff = 'blur_off',
  BlurOn = 'blur_on',
  Bolt = 'bolt',
  Book = 'book',
  Bookmark = 'bookmark',
  BookmarkAdd = 'bookmark_add',
  BookmarkAdded = 'bookmark_added',
  BookmarkBorder = 'bookmark_border',
  BookmarkRemove = 'bookmark_remove',
  Bookmarks = 'bookmarks',
  BookOnline = 'book_online',
  BorderAll = 'border_all',
  BorderBottom = 'border_bottom',
  BorderClear = 'border_clear',
  BorderColor = 'border_color',
  BorderHorizontal = 'border_horizontal',
  BorderInner = 'border_inner',
  BorderLeft = 'border_left',
  BorderOuter = 'border_outer',
  BorderRight = 'border_right',
  BorderStyle = 'border_style',
  BorderTop = 'border_top',
  BorderVertical = 'border_vertical',
  Boy = 'boy',
  BrandingWatermark = 'branding_watermark',
  BreakfastDining = 'breakfast_dining',
  Brightness1 = 'brightness_1',
  Brightness2 = 'brightness_2',
  Brightness3 = 'brightness_3',
  Brightness4 = 'brightness_4',
  Brightness5 = 'brightness_5',
  Brightness6 = 'brightness_6',
  Brightness7 = 'brightness_7',
  BrightnessAuto = 'brightness_auto',
  BrightnessHigh = 'brightness_high',
  BrightnessLow = 'brightness_low',
  BrightnessMedium = 'brightness_medium',
  BroadcastOnHome = 'broadcast_on_home',
  BroadcastOnPersonal = 'broadcast_on_personal',
  BrokenImage = 'broken_image',
  BrowseGallery = 'browse_gallery',
  BrowserNotSupported = 'browser_not_supported',
  BrowserUpdated = 'browser_updated',
  BrunchDining = 'brunch_dining',
  Brush = 'brush',
  BubbleChart = 'bubble_chart',
  BugReport = 'bug_report',
  Build = 'build',
  BuildCircle = 'build_circle',
  Bungalow = 'bungalow',
  BurstMode = 'burst_mode',
  BusAlert = 'bus_alert',
  Business = 'business',
  BusinessCenter = 'business_center',
  Cabin = 'cabin',
  Cable = 'cable',
  Cached = 'cached',
  Cake = 'cake',
  Calculate = 'calculate',
  CalendarMonth = 'calendar_month',
  CalendarToday = 'calendar_today',
  CalendarViewDay = 'calendar_view_day',
  CalendarViewMonth = 'calendar_view_month',
  CalendarViewWeek = 'calendar_view_week',
  Call = 'call',
  CallEnd = 'call_end',
  CallMade = 'call_made',
  CallMerge = 'call_merge',
  CallMissed = 'call_missed',
  CallMissedOutgoing = 'call_missed_outgoing',
  CallReceived = 'call_received',
  CallSplit = 'call_split',
  CallToAction = 'call_to_action',
  Camera = 'camera',
  CameraAlt = 'camera_alt',
  CameraEnhance = 'camera_enhance',
  CameraFront = 'camera_front',
  CameraIndoor = 'camera_indoor',
  CameraOutdoor = 'camera_outdoor',
  CameraRear = 'camera_rear',
  CameraRoll = 'camera_roll',
  Cameraswitch = 'cameraswitch',
  Campaign = 'campaign',
  Cancel = 'cancel',
  CancelPresentation = 'cancel_presentation',
  CancelScheduleSend = 'cancel_schedule_send',
  CandlestickChart = 'candlestick_chart',
  CarCrash = 'car_crash',
  CardGiftcard = 'card_giftcard',
  CardMembership = 'card_membership',
  CardTravel = 'card_travel',
  Carpenter = 'carpenter',
  CarRental = 'car_rental',
  CarRepair = 'car_repair',
  Cases = 'cases',
  Casino = 'casino',
  Cast = 'cast',
  CastConnected = 'cast_connected',
  CastForEducation = 'cast_for_education',
  Castle = 'castle',
  CatchingPokemon = 'catching_pokemon',
  Category = 'category',
  Celebration = 'celebration',
  CellTower = 'cell_tower',
  CellWifi = 'cell_wifi',
  CenterFocusStrong = 'center_focus_strong',
  CenterFocusWeak = 'center_focus_weak',
  Chair = 'chair',
  ChairAlt = 'chair_alt',
  Chalet = 'chalet',
  ChangeCircle = 'change_circle',
  ChangeHistory = 'change_history',
  ChargingStation = 'charging_station',
  Chat = 'chat',
  ChatBubble = 'chat_bubble',
  ChatBubbleOutline = 'chat_bubble_outline',
  Check = 'check',
  CheckBox = 'check_box',
  CheckBoxOutlineBlank = 'check_box_outline_blank',
  CheckCircle = 'check_circle',
  CheckCircleOutline = 'check_circle_outline',
  Checklist = 'checklist',
  ChecklistRtl = 'checklist_rtl',
  Checkroom = 'checkroom',
  ChevronLeft = 'chevron_left',
  ChevronRight = 'chevron_right',
  ChildCare = 'child_care',
  ChildFriendly = 'child_friendly',
  ChromeReaderMode = 'chrome_reader_mode',
  Church = 'church',
  Circle = 'circle',
  CircleNotifications = 'circle_notifications',
  Class = 'class',
  CleanHands = 'clean_hands',
  CleaningServices = 'cleaning_services',
  Clear = 'clear',
  ClearAll = 'clear_all',
  Close = 'close',
  ClosedCaption = 'closed_caption',
  ClosedCaptionDisabled = 'closed_caption_disabled',
  ClosedCaptionOff = 'closed_caption_off',
  CloseFullscreen = 'close_fullscreen',
  Cloud = 'cloud',
  CloudCircle = 'cloud_circle',
  CloudDone = 'cloud_done',
  CloudDownload = 'cloud_download',
  CloudOff = 'cloud_off',
  CloudQueue = 'cloud_queue',
  CloudSync = 'cloud_sync',
  CloudUpload = 'cloud_upload',
  Co2 = 'co2',
  Code = 'code',
  CodeOff = 'code_off',
  Coffee = 'coffee',
  CoffeeMaker = 'coffee_maker',
  Collections = 'collections',
  CollectionsBookmark = 'collections_bookmark',
  Colorize = 'colorize',
  ColorLens = 'color_lens',
  Comment = 'comment',
  CommentBank = 'comment_bank',
  CommentsDisabled = 'comments_disabled',
  Commit = 'commit',
  Commute = 'commute',
  Compare = 'compare',
  CompareArrows = 'compare_arrows',
  CompassCalibration = 'compass_calibration',
  Compost = 'compost',
  Compress = 'compress',
  Computer = 'computer',
  ConfirmationNumber = 'confirmation_number',
  ConnectedTv = 'connected_tv',
  ConnectingAirports = 'connecting_airports',
  ConnectWithoutContact = 'connect_without_contact',
  Construction = 'construction',
  ContactEmergency = 'contact_emergency',
  Contactless = 'contactless',
  ContactMail = 'contact_mail',
  ContactPage = 'contact_page',
  ContactPhone = 'contact_phone',
  Contacts = 'contacts',
  ContactSupport = 'contact_support',
  ContentCopy = 'content_copy',
  ContentCut = 'content_cut',
  ContentPaste = 'content_paste',
  ContentPasteGo = 'content_paste_go',
  ContentPasteOff = 'content_paste_off',
  ContentPasteSearch = 'content_paste_search',
  Contrast = 'contrast',
  ControlCamera = 'control_camera',
  ControlPoint = 'control_point',
  ControlPointDuplicate = 'control_point_duplicate',
  Cookie = 'cookie',
  CoPresent = 'co_present',
  CopyAll = 'copy_all',
  Copyright = 'copyright',
  Coronavirus = 'coronavirus',
  CorporateFare = 'corporate_fare',
  Cottage = 'cottage',
  Countertops = 'countertops',
  Create = 'create',
  CreateNewFolder = 'create_new_folder',
  CreditCard = 'credit_card',
  CreditCardOff = 'credit_card_off',
  CreditScore = 'credit_score',
  Crib = 'crib',
  CrisisAlert = 'crisis_alert',
  Crop = 'crop',
  Crop169 = 'crop_16_9',
  Crop32 = 'crop_3_2',
  Crop54 = 'crop_5_4',
  Crop75 = 'crop_7_5',
  CropDin = 'crop_din',
  CropFree = 'crop_free',
  CropLandscape = 'crop_landscape',
  CropOriginal = 'crop_original',
  CropPortrait = 'crop_portrait',
  CropRotate = 'crop_rotate',
  CropSquare = 'crop_square',
  CrueltyFree = 'cruelty_free',
  Css = 'css',
  CurrencyBitcoin = 'currency_bitcoin',
  CurrencyExchange = 'currency_exchange',
  CurrencyFranc = 'currency_franc',
  CurrencyLira = 'currency_lira',
  CurrencyPound = 'currency_pound',
  CurrencyRuble = 'currency_ruble',
  CurrencyRupee = 'currency_rupee',
  CurrencyYen = 'currency_yen',
  CurrencyYuan = 'currency_yuan',
  Curtains = 'curtains',
  CurtainsClosed = 'curtains_closed',
  Cyclone = 'cyclone',
  Dangerous = 'dangerous',
  DarkMode = 'dark_mode',
  Dashboard = 'dashboard',
  DashboardCustomize = 'dashboard_customize',
  DataArray = 'data_array',
  DataExploration = 'data_exploration',
  DataObject = 'data_object',
  DataSaverOff = 'data_saver_off',
  DataSaverOn = 'data_saver_on',
  Dataset = 'dataset',
  DatasetLinked = 'dataset_linked',
  DataThresholding = 'data_thresholding',
  DataUsage = 'data_usage',
  DateRange = 'date_range',
  Deblur = 'deblur',
  Deck = 'deck',
  Dehaze = 'dehaze',
  Delete = 'delete',
  DeleteForever = 'delete_forever',
  DeleteOutline = 'delete_outline',
  DeleteSweep = 'delete_sweep',
  DeliveryDining = 'delivery_dining',
  DensityLarge = 'density_large',
  DensityMedium = 'density_medium',
  DensitySmall = 'density_small',
  DepartureBoard = 'departure_board',
  Description = 'description',
  Deselect = 'deselect',
  DesignServices = 'design_services',
  Desk = 'desk',
  DesktopAccessDisabled = 'desktop_access_disabled',
  DesktopMac = 'desktop_mac',
  DesktopWindows = 'desktop_windows',
  Details = 'details',
  DeveloperBoard = 'developer_board',
  DeveloperBoardOff = 'developer_board_off',
  DeveloperMode = 'developer_mode',
  DeviceHub = 'device_hub',
  Devices = 'devices',
  DevicesFold = 'devices_fold',
  DevicesOther = 'devices_other',
  DeviceThermostat = 'device_thermostat',
  DeviceUnknown = 'device_unknown',
  DialerSip = 'dialer_sip',
  Dialpad = 'dialpad',
  Diamond = 'diamond',
  Difference = 'difference',
  Dining = 'dining',
  DinnerDining = 'dinner_dining',
  Directions = 'directions',
  DirectionsBike = 'directions_bike',
  DirectionsBoat = 'directions_boat',
  DirectionsBoatFilled = 'directions_boat_filled',
  DirectionsBus = 'directions_bus',
  DirectionsBusFilled = 'directions_bus_filled',
  DirectionsCar = 'directions_car',
  DirectionsCarFilled = 'directions_car_filled',
  DirectionsOff = 'directions_off',
  DirectionsRailway = 'directions_railway',
  DirectionsRailwayFilled = 'directions_railway_filled',
  DirectionsRun = 'directions_run',
  DirectionsSubway = 'directions_subway',
  DirectionsSubwayFilled = 'directions_subway_filled',
  DirectionsTransit = 'directions_transit',
  DirectionsTransitFilled = 'directions_transit_filled',
  DirectionsWalk = 'directions_walk',
  DirtyLens = 'dirty_lens',
  DisabledByDefault = 'disabled_by_default',
  DisabledVisible = 'disabled_visible',
  DiscFull = 'disc_full',
  Discount = 'discount',
  DisplaySettings = 'display_settings',
  Diversity1 = 'diversity_1',
  Diversity2 = 'diversity_2',
  Diversity3 = 'diversity_3',
  Dns = 'dns',
  Dock = 'dock',
  DocumentScanner = 'document_scanner',
  DoDisturb = 'do_disturb',
  DoDisturbAlt = 'do_disturb_alt',
  DoDisturbOff = 'do_disturb_off',
  DoDisturbOn = 'do_disturb_on',
  Domain = 'domain',
  DomainAdd = 'domain_add',
  DomainDisabled = 'domain_disabled',
  DomainVerification = 'domain_verification',
  Done = 'done',
  DoneAll = 'done_all',
  DoneOutline = 'done_outline',
  DoNotDisturb = 'do_not_disturb',
  DoNotDisturbAlt = 'do_not_disturb_alt',
  DoNotDisturbOff = 'do_not_disturb_off',
  DoNotDisturbOn = 'do_not_disturb_on',
  DoNotDisturbOnTotalSilence = 'do_not_disturb_on_total_silence',
  DoNotStep = 'do_not_step',
  DoNotTouch = 'do_not_touch',
  DonutLarge = 'donut_large',
  DonutSmall = 'donut_small',
  DoorBack = 'door_back',
  Doorbell = 'doorbell',
  DoorFront = 'door_front',
  DoorSliding = 'door_sliding',
  DoubleArrow = 'double_arrow',
  DownhillSkiing = 'downhill_skiing',
  Download = 'download',
  DownloadDone = 'download_done',
  DownloadForOffline = 'download_for_offline',
  Downloading = 'downloading',
  Drafts = 'drafts',
  DragHandle = 'drag_handle',
  DragIndicator = 'drag_indicator',
  Draw = 'draw',
  DriveEta = 'drive_eta',
  DriveFileMove = 'drive_file_move',
  DriveFileMoveRtl = 'drive_file_move_rtl',
  DriveFileRenameOutline = 'drive_file_rename_outline',
  DriveFolderUpload = 'drive_folder_upload',
  Dry = 'dry',
  DryCleaning = 'dry_cleaning',
  Duo = 'duo',
  Dvr = 'dvr',
  DynamicFeed = 'dynamic_feed',
  DynamicForm = 'dynamic_form',
  Earbuds = 'earbuds',
  EarbudsBattery = 'earbuds_battery',
  East = 'east',
  EdgesensorHigh = 'edgesensor_high',
  EdgesensorLow = 'edgesensor_low',
  Edit = 'edit',
  EditAttributes = 'edit_attributes',
  EditCalendar = 'edit_calendar',
  EditLocation = 'edit_location',
  EditLocationAlt = 'edit_location_alt',
  EditNote = 'edit_note',
  EditNotifications = 'edit_notifications',
  EditOff = 'edit_off',
  EditRoad = 'edit_road',
  Egg = 'egg',
  EggAlt = 'egg_alt',
  Eject = 'eject',
  Elderly = 'elderly',
  ElderlyWoman = 'elderly_woman',
  ElectricalServices = 'electrical_services',
  ElectricBike = 'electric_bike',
  ElectricBolt = 'electric_bolt',
  ElectricCar = 'electric_car',
  ElectricMeter = 'electric_meter',
  ElectricMoped = 'electric_moped',
  ElectricRickshaw = 'electric_rickshaw',
  ElectricScooter = 'electric_scooter',
  Elevator = 'elevator',
  Email = 'email',
  Emergency = 'emergency',
  EmergencyRecording = 'emergency_recording',
  EmergencyShare = 'emergency_share',
  EMobiledata = 'e_mobiledata',
  EmojiEmotions = 'emoji_emotions',
  EmojiEvents = 'emoji_events',
  EmojiFoodBeverage = 'emoji_food_beverage',
  EmojiNature = 'emoji_nature',
  EmojiObjects = 'emoji_objects',
  EmojiPeople = 'emoji_people',
  EmojiSymbols = 'emoji_symbols',
  EmojiTransportation = 'emoji_transportation',
  EnergySavingsLeaf = 'energy_savings_leaf',
  Engineering = 'engineering',
  EnhancedEncryption = 'enhanced_encryption',
  Equalizer = 'equalizer',
  Error = 'error',
  ErrorOutline = 'error_outline',
  Escalator = 'escalator',
  EscalatorWarning = 'escalator_warning',
  Euro = 'euro',
  EuroSymbol = 'euro_symbol',
  Event = 'event',
  EventAvailable = 'event_available',
  EventBusy = 'event_busy',
  EventNote = 'event_note',
  EventRepeat = 'event_repeat',
  EventSeat = 'event_seat',
  EvStation = 'ev_station',
  ExitToApp = 'exit_to_app',
  Expand = 'expand',
  ExpandCircleDown = 'expand_circle_down',
  ExpandLess = 'expand_less',
  ExpandMore = 'expand_more',
  Explicit = 'explicit',
  Explore = 'explore',
  ExploreOff = 'explore_off',
  Exposure = 'exposure',
  ExposureNeg1 = 'exposure_neg_1',
  ExposureNeg2 = 'exposure_neg_2',
  ExposurePlus1 = 'exposure_plus_1',
  ExposurePlus2 = 'exposure_plus_2',
  ExposureZero = 'exposure_zero',
  Extension = 'extension',
  ExtensionOff = 'extension_off',
  Face = 'face',
  Face2 = 'face_2',
  Face3 = 'face_3',
  Face4 = 'face_4',
  Face5 = 'face_5',
  Face6 = 'face_6',
  FaceRetouchingNatural = 'face_retouching_natural',
  FaceRetouchingOff = 'face_retouching_off',
  FactCheck = 'fact_check',
  Factory = 'factory',
  FamilyRestroom = 'family_restroom',
  Fastfood = 'fastfood',
  FastForward = 'fast_forward',
  FastRewind = 'fast_rewind',
  Favorite = 'favorite',
  FavoriteBorder = 'favorite_border',
  Fax = 'fax',
  FeaturedPlayList = 'featured_play_list',
  FeaturedVideo = 'featured_video',
  Feed = 'feed',
  Feedback = 'feedback',
  Female = 'female',
  Fence = 'fence',
  Festival = 'festival',
  FiberDvr = 'fiber_dvr',
  FiberManualRecord = 'fiber_manual_record',
  FiberNew = 'fiber_new',
  FiberPin = 'fiber_pin',
  FiberSmartRecord = 'fiber_smart_record',
  FileCopy = 'file_copy',
  FileDownload = 'file_download',
  FileDownloadDone = 'file_download_done',
  FileDownloadOff = 'file_download_off',
  FileOpen = 'file_open',
  FilePresent = 'file_present',
  FileUpload = 'file_upload',
  Filter = 'filter',
  Filter1 = 'filter_1',
  Filter2 = 'filter_2',
  Filter3 = 'filter_3',
  Filter4 = 'filter_4',
  Filter5 = 'filter_5',
  Filter6 = 'filter_6',
  Filter7 = 'filter_7',
  Filter8 = 'filter_8',
  Filter9 = 'filter_9',
  Filter9Plus = 'filter_9_plus',
  FilterAlt = 'filter_alt',
  FilterAltOff = 'filter_alt_off',
  FilterBAndW = 'filter_b_and_w',
  FilterCenterFocus = 'filter_center_focus',
  FilterDrama = 'filter_drama',
  FilterFrames = 'filter_frames',
  FilterHdr = 'filter_hdr',
  FilterList = 'filter_list',
  FilterListOff = 'filter_list_off',
  FilterNone = 'filter_none',
  FilterTiltShift = 'filter_tilt_shift',
  FilterVintage = 'filter_vintage',
  FindInPage = 'find_in_page',
  FindReplace = 'find_replace',
  Fingerprint = 'fingerprint',
  FireExtinguisher = 'fire_extinguisher',
  FireHydrantAlt = 'fire_hydrant_alt',
  Fireplace = 'fireplace',
  FireTruck = 'fire_truck',
  FirstPage = 'first_page',
  Fitbit = 'fitbit',
  FitnessCenter = 'fitness_center',
  FitScreen = 'fit_screen',
  Flag = 'flag',
  FlagCircle = 'flag_circle',
  Flaky = 'flaky',
  Flare = 'flare',
  FlashAuto = 'flash_auto',
  FlashlightOff = 'flashlight_off',
  FlashlightOn = 'flashlight_on',
  FlashOff = 'flash_off',
  FlashOn = 'flash_on',
  Flatware = 'flatware',
  Flight = 'flight',
  FlightClass = 'flight_class',
  FlightLand = 'flight_land',
  FlightTakeoff = 'flight_takeoff',
  Flip = 'flip',
  FlipCameraAndroid = 'flip_camera_android',
  FlipCameraIos = 'flip_camera_ios',
  FlipToBack = 'flip_to_back',
  FlipToFront = 'flip_to_front',
  Flood = 'flood',
  Fluorescent = 'fluorescent',
  FlutterDash = 'flutter_dash',
  FmdBad = 'fmd_bad',
  FmdGood = 'fmd_good',
  Folder = 'folder',
  FolderCopy = 'folder_copy',
  FolderDelete = 'folder_delete',
  FolderOff = 'folder_off',
  FolderOpen = 'folder_open',
  FolderShared = 'folder_shared',
  FolderSpecial = 'folder_special',
  FolderZip = 'folder_zip',
  FollowTheSigns = 'follow_the_signs',
  FontDownload = 'font_download',
  FontDownloadOff = 'font_download_off',
  FoodBank = 'food_bank',
  Forest = 'forest',
  ForkLeft = 'fork_left',
  ForkRight = 'fork_right',
  FormatAlignCenter = 'format_align_center',
  FormatAlignJustify = 'format_align_justify',
  FormatAlignLeft = 'format_align_left',
  FormatAlignRight = 'format_align_right',
  FormatBold = 'format_bold',
  FormatClear = 'format_clear',
  FormatColorFill = 'format_color_fill',
  FormatColorReset = 'format_color_reset',
  FormatColorText = 'format_color_text',
  FormatIndentDecrease = 'format_indent_decrease',
  FormatIndentIncrease = 'format_indent_increase',
  FormatItalic = 'format_italic',
  FormatLineSpacing = 'format_line_spacing',
  FormatListBulleted = 'format_list_bulleted',
  FormatListNumbered = 'format_list_numbered',
  FormatListNumberedRtl = 'format_list_numbered_rtl',
  FormatOverline = 'format_overline',
  FormatPaint = 'format_paint',
  FormatQuote = 'format_quote',
  FormatShapes = 'format_shapes',
  FormatSize = 'format_size',
  FormatStrikethrough = 'format_strikethrough',
  FormatTextdirectionLToR = 'format_textdirection_l_to_r',
  FormatTextdirectionRToL = 'format_textdirection_r_to_l',
  FormatUnderlined = 'format_underlined',
  Fort = 'fort',
  Forum = 'forum',
  Forward = 'forward',
  Forward10 = 'forward_10',
  Forward30 = 'forward_30',
  Forward5 = 'forward_5',
  ForwardToInbox = 'forward_to_inbox',
  Foundation = 'foundation',
  FreeBreakfast = 'free_breakfast',
  FreeCancellation = 'free_cancellation',
  FrontHand = 'front_hand',
  Fullscreen = 'fullscreen',
  FullscreenExit = 'fullscreen_exit',
  Functions = 'functions',
  Gamepad = 'gamepad',
  Games = 'games',
  Garage = 'garage',
  GasMeter = 'gas_meter',
  Gavel = 'gavel',
  GeneratingTokens = 'generating_tokens',
  Gesture = 'gesture',
  GetApp = 'get_app',
  Gif = 'gif',
  GifBox = 'gif_box',
  Girl = 'girl',
  Gite = 'gite',
  GMobiledata = 'g_mobiledata',
  GolfCourse = 'golf_course',
  GppBad = 'gpp_bad',
  GppGood = 'gpp_good',
  GppMaybe = 'gpp_maybe',
  GpsFixed = 'gps_fixed',
  GpsNotFixed = 'gps_not_fixed',
  GpsOff = 'gps_off',
  Grade = 'grade',
  Gradient = 'gradient',
  Grading = 'grading',
  Grain = 'grain',
  GraphicEq = 'graphic_eq',
  Grass = 'grass',
  Grid3x3 = 'grid_3x3',
  Grid4x4 = 'grid_4x4',
  GridGoldenratio = 'grid_goldenratio',
  GridOff = 'grid_off',
  GridOn = 'grid_on',
  GridView = 'grid_view',
  Group = 'group',
  GroupAdd = 'group_add',
  GroupOff = 'group_off',
  GroupRemove = 'group_remove',
  Groups = 'groups',
  Groups2 = 'groups_2',
  Groups3 = 'groups_3',
  GroupWork = 'group_work',
  GTranslate = 'g_translate',
  Hail = 'hail',
  Handshake = 'handshake',
  Handyman = 'handyman',
  Hardware = 'hardware',
  Hd = 'hd',
  HdrAuto = 'hdr_auto',
  HdrAutoSelect = 'hdr_auto_select',
  HdrEnhancedSelect = 'hdr_enhanced_select',
  HdrOff = 'hdr_off',
  HdrOffSelect = 'hdr_off_select',
  HdrOn = 'hdr_on',
  HdrOnSelect = 'hdr_on_select',
  HdrPlus = 'hdr_plus',
  HdrStrong = 'hdr_strong',
  HdrWeak = 'hdr_weak',
  Headphones = 'headphones',
  HeadphonesBattery = 'headphones_battery',
  Headset = 'headset',
  HeadsetMic = 'headset_mic',
  HeadsetOff = 'headset_off',
  Healing = 'healing',
  HealthAndSafety = 'health_and_safety',
  Hearing = 'hearing',
  HearingDisabled = 'hearing_disabled',
  HeartBroken = 'heart_broken',
  HeatPump = 'heat_pump',
  Height = 'height',
  Help = 'help',
  HelpCenter = 'help_center',
  HelpOutline = 'help_outline',
  Hevc = 'hevc',
  Hexagon = 'hexagon',
  HideImage = 'hide_image',
  HideSource = 'hide_source',
  Highlight = 'highlight',
  HighlightAlt = 'highlight_alt',
  HighlightOff = 'highlight_off',
  HighQuality = 'high_quality',
  Hiking = 'hiking',
  History = 'history',
  HistoryEdu = 'history_edu',
  HistoryToggleOff = 'history_toggle_off',
  Hive = 'hive',
  Hls = 'hls',
  HlsOff = 'hls_off',
  HMobiledata = 'h_mobiledata',
  HolidayVillage = 'holiday_village',
  Home = 'home',
  HomeMax = 'home_max',
  HomeMini = 'home_mini',
  HomeRepairService = 'home_repair_service',
  HomeWork = 'home_work',
  HorizontalDistribute = 'horizontal_distribute',
  HorizontalRule = 'horizontal_rule',
  HorizontalSplit = 'horizontal_split',
  Hotel = 'hotel',
  HotelClass = 'hotel_class',
  HotTub = 'hot_tub',
  HourglassBottom = 'hourglass_bottom',
  HourglassDisabled = 'hourglass_disabled',
  HourglassEmpty = 'hourglass_empty',
  HourglassFull = 'hourglass_full',
  HourglassTop = 'hourglass_top',
  House = 'house',
  Houseboat = 'houseboat',
  HouseSiding = 'house_siding',
  HowToReg = 'how_to_reg',
  HowToVote = 'how_to_vote',
  HPlusMobiledata = 'h_plus_mobiledata',
  Html = 'html',
  Http = 'http',
  Https = 'https',
  Hub = 'hub',
  Hvac = 'hvac',
  Icecream = 'icecream',
  IceSkating = 'ice_skating',
  Image = 'image',
  ImageAspectRatio = 'image_aspect_ratio',
  ImageNotSupported = 'image_not_supported',
  ImageSearch = 'image_search',
  ImagesearchRoller = 'imagesearch_roller',
  ImportantDevices = 'important_devices',
  ImportContacts = 'import_contacts',
  ImportExport = 'import_export',
  Inbox = 'inbox',
  IncompleteCircle = 'incomplete_circle',
  IndeterminateCheckBox = 'indeterminate_check_box',
  Info = 'info',
  Input = 'input',
  InsertChart = 'insert_chart',
  InsertChartOutlined = 'insert_chart_outlined',
  InsertComment = 'insert_comment',
  InsertDriveFile = 'insert_drive_file',
  InsertEmoticon = 'insert_emoticon',
  InsertInvitation = 'insert_invitation',
  InsertLink = 'insert_link',
  InsertPageBreak = 'insert_page_break',
  InsertPhoto = 'insert_photo',
  Insights = 'insights',
  InstallDesktop = 'install_desktop',
  InstallMobile = 'install_mobile',
  IntegrationInstructions = 'integration_instructions',
  Interests = 'interests',
  InterpreterMode = 'interpreter_mode',
  Inventory = 'inventory',
  Inventory2 = 'inventory_2',
  InvertColors = 'invert_colors',
  InvertColorsOff = 'invert_colors_off',
  IosShare = 'ios_share',
  Iron = 'iron',
  Iso = 'iso',
  Javascript = 'javascript',
  JoinFull = 'join_full',
  JoinInner = 'join_inner',
  JoinLeft = 'join_left',
  JoinRight = 'join_right',
  Kayaking = 'kayaking',
  KebabDining = 'kebab_dining',
  Key = 'key',
  Keyboard = 'keyboard',
  KeyboardAlt = 'keyboard_alt',
  KeyboardArrowDown = 'keyboard_arrow_down',
  KeyboardArrowLeft = 'keyboard_arrow_left',
  KeyboardArrowRight = 'keyboard_arrow_right',
  KeyboardArrowUp = 'keyboard_arrow_up',
  KeyboardBackspace = 'keyboard_backspace',
  KeyboardCapslock = 'keyboard_capslock',
  KeyboardCommandKey = 'keyboard_command_key',
  KeyboardControlKey = 'keyboard_control_key',
  KeyboardDoubleArrowDown = 'keyboard_double_arrow_down',
  KeyboardDoubleArrowLeft = 'keyboard_double_arrow_left',
  KeyboardDoubleArrowRight = 'keyboard_double_arrow_right',
  KeyboardDoubleArrowUp = 'keyboard_double_arrow_up',
  KeyboardHide = 'keyboard_hide',
  KeyboardOptionKey = 'keyboard_option_key',
  KeyboardReturn = 'keyboard_return',
  KeyboardTab = 'keyboard_tab',
  KeyboardVoice = 'keyboard_voice',
  KeyOff = 'key_off',
  KingBed = 'king_bed',
  Kitchen = 'kitchen',
  Kitesurfing = 'kitesurfing',
  Label = 'label',
  LabelImportant = 'label_important',
  LabelOff = 'label_off',
  Lan = 'lan',
  Landscape = 'landscape',
  Landslide = 'landslide',
  Language = 'language',
  Laptop = 'laptop',
  LaptopChromebook = 'laptop_chromebook',
  LaptopMac = 'laptop_mac',
  LaptopWindows = 'laptop_windows',
  LastPage = 'last_page',
  Launch = 'launch',
  Layers = 'layers',
  LayersClear = 'layers_clear',
  Leaderboard = 'leaderboard',
  LeakAdd = 'leak_add',
  LeakRemove = 'leak_remove',
  LegendToggle = 'legend_toggle',
  Lens = 'lens',
  LensBlur = 'lens_blur',
  LibraryAdd = 'library_add',
  LibraryAddCheck = 'library_add_check',
  LibraryBooks = 'library_books',
  LibraryMusic = 'library_music',
  Light = 'light',
  Lightbulb = 'lightbulb',
  LightbulbCircle = 'lightbulb_circle',
  LightMode = 'light_mode',
  LinearScale = 'linear_scale',
  LineAxis = 'line_axis',
  LineStyle = 'line_style',
  LineWeight = 'line_weight',
  Link = 'link',
  LinkedCamera = 'linked_camera',
  LinkOff = 'link_off',
  Liquor = 'liquor',
  List = 'list',
  ListAlt = 'list_alt',
  LiveHelp = 'live_help',
  LiveTv = 'live_tv',
  Living = 'living',
  LocalActivity = 'local_activity',
  LocalAirport = 'local_airport',
  LocalAtm = 'local_atm',
  LocalBar = 'local_bar',
  LocalCafe = 'local_cafe',
  LocalCarWash = 'local_car_wash',
  LocalConvenienceStore = 'local_convenience_store',
  LocalDining = 'local_dining',
  LocalDrink = 'local_drink',
  LocalFireDepartment = 'local_fire_department',
  LocalFlorist = 'local_florist',
  LocalGasStation = 'local_gas_station',
  LocalGroceryStore = 'local_grocery_store',
  LocalHospital = 'local_hospital',
  LocalHotel = 'local_hotel',
  LocalLaundryService = 'local_laundry_service',
  LocalLibrary = 'local_library',
  LocalMall = 'local_mall',
  LocalMovies = 'local_movies',
  LocalOffer = 'local_offer',
  LocalParking = 'local_parking',
  LocalPharmacy = 'local_pharmacy',
  LocalPhone = 'local_phone',
  LocalPizza = 'local_pizza',
  LocalPlay = 'local_play',
  LocalPolice = 'local_police',
  LocalPostOffice = 'local_post_office',
  LocalPrintshop = 'local_printshop',
  LocalSee = 'local_see',
  LocalShipping = 'local_shipping',
  LocalTaxi = 'local_taxi',
  LocationCity = 'location_city',
  LocationDisabled = 'location_disabled',
  LocationOff = 'location_off',
  LocationOn = 'location_on',
  LocationSearching = 'location_searching',
  Lock = 'lock',
  LockClock = 'lock_clock',
  LockOpen = 'lock_open',
  LockPerson = 'lock_person',
  LockReset = 'lock_reset',
  Login = 'login',
  LogoDev = 'logo_dev',
  Logout = 'logout',
  Looks = 'looks',
  Looks3 = 'looks_3',
  Looks4 = 'looks_4',
  Looks5 = 'looks_5',
  Looks6 = 'looks_6',
  LooksOne = 'looks_one',
  LooksTwo = 'looks_two',
  Loop = 'loop',
  Loupe = 'loupe',
  LowPriority = 'low_priority',
  Loyalty = 'loyalty',
  LteMobiledata = 'lte_mobiledata',
  LtePlusMobiledata = 'lte_plus_mobiledata',
  Luggage = 'luggage',
  LunchDining = 'lunch_dining',
  Lyrics = 'lyrics',
  MacroOff = 'macro_off',
  Mail = 'mail',
  MailLock = 'mail_lock',
  MailOutline = 'mail_outline',
  Male = 'male',
  Man = 'man',
  Man2 = 'man_2',
  Man3 = 'man_3',
  Man4 = 'man_4',
  ManageAccounts = 'manage_accounts',
  ManageHistory = 'manage_history',
  ManageSearch = 'manage_search',
  Map = 'map',
  MapsHomeWork = 'maps_home_work',
  MapsUgc = 'maps_ugc',
  Margin = 'margin',
  MarkAsUnread = 'mark_as_unread',
  MarkChatRead = 'mark_chat_read',
  MarkChatUnread = 'mark_chat_unread',
  MarkEmailRead = 'mark_email_read',
  MarkEmailUnread = 'mark_email_unread',
  Markunread = 'markunread',
  MarkUnreadChatAlt = 'mark_unread_chat_alt',
  MarkunreadMailbox = 'markunread_mailbox',
  Masks = 'masks',
  Maximize = 'maximize',
  MediaBluetoothOff = 'media_bluetooth_off',
  MediaBluetoothOn = 'media_bluetooth_on',
  Mediation = 'mediation',
  MedicalInformation = 'medical_information',
  MedicalServices = 'medical_services',
  Medication = 'medication',
  MedicationLiquid = 'medication_liquid',
  MeetingRoom = 'meeting_room',
  Memory = 'memory',
  Menu = 'menu',
  MenuBook = 'menu_book',
  MenuOpen = 'menu_open',
  Merge = 'merge',
  MergeType = 'merge_type',
  Message = 'message',
  Mic = 'mic',
  MicExternalOff = 'mic_external_off',
  MicExternalOn = 'mic_external_on',
  MicNone = 'mic_none',
  MicOff = 'mic_off',
  Microwave = 'microwave',
  MilitaryTech = 'military_tech',
  Minimize = 'minimize',
  MinorCrash = 'minor_crash',
  MiscellaneousServices = 'miscellaneous_services',
  MissedVideoCall = 'missed_video_call',
  Mms = 'mms',
  MobiledataOff = 'mobiledata_off',
  MobileFriendly = 'mobile_friendly',
  MobileOff = 'mobile_off',
  MobileScreenShare = 'mobile_screen_share',
  Mode = 'mode',
  ModeComment = 'mode_comment',
  ModeEdit = 'mode_edit',
  ModeEditOutline = 'mode_edit_outline',
  ModeFanOff = 'mode_fan_off',
  ModelTraining = 'model_training',
  ModeNight = 'mode_night',
  ModeOfTravel = 'mode_of_travel',
  ModeStandby = 'mode_standby',
  MonetizationOn = 'monetization_on',
  Money = 'money',
  MoneyOff = 'money_off',
  MoneyOffCsred = 'money_off_csred',
  Monitor = 'monitor',
  MonitorHeart = 'monitor_heart',
  MonitorWeight = 'monitor_weight',
  MonochromePhotos = 'monochrome_photos',
  Mood = 'mood',
  MoodBad = 'mood_bad',
  Moped = 'moped',
  More = 'more',
  MoreHoriz = 'more_horiz',
  MoreTime = 'more_time',
  MoreVert = 'more_vert',
  Mosque = 'mosque',
  MotionPhotosAuto = 'motion_photos_auto',
  MotionPhotosOff = 'motion_photos_off',
  MotionPhotosOn = 'motion_photos_on',
  MotionPhotosPause = 'motion_photos_pause',
  MotionPhotosPaused = 'motion_photos_paused',
  Mouse = 'mouse',
  MoveDown = 'move_down',
  MoveToInbox = 'move_to_inbox',
  MoveUp = 'move_up',
  Movie = 'movie',
  MovieCreation = 'movie_creation',
  MovieFilter = 'movie_filter',
  Moving = 'moving',
  Mp = 'mp',
  MultilineChart = 'multiline_chart',
  MultipleStop = 'multiple_stop',
  Museum = 'museum',
  MusicNote = 'music_note',
  MusicOff = 'music_off',
  MusicVideo = 'music_video',
  MyLocation = 'my_location',
  Nat = 'nat',
  Nature = 'nature',
  NaturePeople = 'nature_people',
  NavigateBefore = 'navigate_before',
  NavigateNext = 'navigate_next',
  Navigation = 'navigation',
  NearbyError = 'nearby_error',
  NearbyOff = 'nearby_off',
  NearMe = 'near_me',
  NearMeDisabled = 'near_me_disabled',
  NestCamWiredStand = 'nest_cam_wired_stand',
  NetworkCell = 'network_cell',
  NetworkCheck = 'network_check',
  NetworkLocked = 'network_locked',
  NetworkPing = 'network_ping',
  NetworkWifi = 'network_wifi',
  NetworkWifi1Bar = 'network_wifi_1_bar',
  NetworkWifi2Bar = 'network_wifi_2_bar',
  NetworkWifi3Bar = 'network_wifi_3_bar',
  NewLabel = 'new_label',
  NewReleases = 'new_releases',
  Newspaper = 'newspaper',
  NextPlan = 'next_plan',
  NextWeek = 'next_week',
  Nfc = 'nfc',
  Nightlife = 'nightlife',
  Nightlight = 'nightlight',
  NightlightRound = 'nightlight_round',
  NightShelter = 'night_shelter',
  NightsStay = 'nights_stay',
  NoAccounts = 'no_accounts',
  NoAdultContent = 'no_adult_content',
  NoBackpack = 'no_backpack',
  NoCell = 'no_cell',
  NoCrash = 'no_crash',
  NoDrinks = 'no_drinks',
  NoEncryption = 'no_encryption',
  NoEncryptionGmailerrorred = 'no_encryption_gmailerrorred',
  NoFlash = 'no_flash',
  NoFood = 'no_food',
  NoiseAware = 'noise_aware',
  NoiseControlOff = 'noise_control_off',
  NoLuggage = 'no_luggage',
  NoMeals = 'no_meals',
  NoMeetingRoom = 'no_meeting_room',
  NoPhotography = 'no_photography',
  NordicWalking = 'nordic_walking',
  North = 'north',
  NorthEast = 'north_east',
  NorthWest = 'north_west',
  NoSim = 'no_sim',
  NoStroller = 'no_stroller',
  NotAccessible = 'not_accessible',
  Note = 'note',
  NoteAdd = 'note_add',
  NoteAlt = 'note_alt',
  Notes = 'notes',
  NotificationAdd = 'notification_add',
  NotificationImportant = 'notification_important',
  Notifications = 'notifications',
  NotificationsActive = 'notifications_active',
  NotificationsNone = 'notifications_none',
  NotificationsOff = 'notifications_off',
  NotificationsPaused = 'notifications_paused',
  NotInterested = 'not_interested',
  NotListedLocation = 'not_listed_location',
  NoTransfer = 'no_transfer',
  NotStarted = 'not_started',
  Numbers = 'numbers',
  OfflineBolt = 'offline_bolt',
  OfflinePin = 'offline_pin',
  OfflineShare = 'offline_share',
  OilBarrel = 'oil_barrel',
  OndemandVideo = 'ondemand_video',
  OnDeviceTraining = 'on_device_training',
  OnlinePrediction = 'online_prediction',
  Opacity = 'opacity',
  OpenInBrowser = 'open_in_browser',
  OpenInFull = 'open_in_full',
  OpenInNew = 'open_in_new',
  OpenInNewOff = 'open_in_new_off',
  OpenWith = 'open_with',
  OtherHouses = 'other_houses',
  Outbound = 'outbound',
  Outbox = 'outbox',
  OutdoorGrill = 'outdoor_grill',
  Outlet = 'outlet',
  OutlinedFlag = 'outlined_flag',
  Output = 'output',
  Padding = 'padding',
  Pages = 'pages',
  Pageview = 'pageview',
  Paid = 'paid',
  Palette = 'palette',
  Panorama = 'panorama',
  PanoramaFishEye = 'panorama_fish_eye',
  PanoramaHorizontal = 'panorama_horizontal',
  PanoramaHorizontalSelect = 'panorama_horizontal_select',
  PanoramaPhotosphere = 'panorama_photosphere',
  PanoramaPhotosphereSelect = 'panorama_photosphere_select',
  PanoramaVertical = 'panorama_vertical',
  PanoramaVerticalSelect = 'panorama_vertical_select',
  PanoramaWideAngle = 'panorama_wide_angle',
  PanoramaWideAngleSelect = 'panorama_wide_angle_select',
  PanTool = 'pan_tool',
  PanToolAlt = 'pan_tool_alt',
  Paragliding = 'paragliding',
  Park = 'park',
  PartyMode = 'party_mode',
  Password = 'password',
  Pattern = 'pattern',
  Pause = 'pause',
  PauseCircle = 'pause_circle',
  PauseCircleFilled = 'pause_circle_filled',
  PauseCircleOutline = 'pause_circle_outline',
  PausePresentation = 'pause_presentation',
  Payment = 'payment',
  Payments = 'payments',
  PedalBike = 'pedal_bike',
  Pending = 'pending',
  PendingActions = 'pending_actions',
  Pentagon = 'pentagon',
  People = 'people',
  PeopleAlt = 'people_alt',
  PeopleOutline = 'people_outline',
  Percent = 'percent',
  PermCameraMic = 'perm_camera_mic',
  PermContactCalendar = 'perm_contact_calendar',
  PermDataSetting = 'perm_data_setting',
  PermDeviceInformation = 'perm_device_information',
  PermIdentity = 'perm_identity',
  PermMedia = 'perm_media',
  PermPhoneMsg = 'perm_phone_msg',
  PermScanWifi = 'perm_scan_wifi',
  Person = 'person',
  Person2 = 'person_2',
  Person3 = 'person_3',
  Person4 = 'person_4',
  PersonAdd = 'person_add',
  PersonAddAlt = 'person_add_alt',
  PersonAddAlt1 = 'person_add_alt_1',
  PersonAddDisabled = 'person_add_disabled',
  PersonalInjury = 'personal_injury',
  PersonalVideo = 'personal_video',
  PersonOff = 'person_off',
  PersonOutline = 'person_outline',
  PersonPin = 'person_pin',
  PersonPinCircle = 'person_pin_circle',
  PersonRemove = 'person_remove',
  PersonRemoveAlt1 = 'person_remove_alt_1',
  PersonSearch = 'person_search',
  PestControl = 'pest_control',
  PestControlRodent = 'pest_control_rodent',
  Pets = 'pets',
  Phishing = 'phishing',
  Phone = 'phone',
  PhoneAndroid = 'phone_android',
  PhoneBluetoothSpeaker = 'phone_bluetooth_speaker',
  PhoneCallback = 'phone_callback',
  PhoneDisabled = 'phone_disabled',
  PhoneEnabled = 'phone_enabled',
  PhoneForwarded = 'phone_forwarded',
  PhoneIphone = 'phone_iphone',
  Phonelink = 'phonelink',
  PhonelinkErase = 'phonelink_erase',
  PhonelinkLock = 'phonelink_lock',
  PhonelinkOff = 'phonelink_off',
  PhonelinkRing = 'phonelink_ring',
  PhonelinkSetup = 'phonelink_setup',
  PhoneLocked = 'phone_locked',
  PhoneMissed = 'phone_missed',
  PhonePaused = 'phone_paused',
  Photo = 'photo',
  PhotoAlbum = 'photo_album',
  PhotoCamera = 'photo_camera',
  PhotoCameraBack = 'photo_camera_back',
  PhotoCameraFront = 'photo_camera_front',
  PhotoFilter = 'photo_filter',
  PhotoLibrary = 'photo_library',
  PhotoSizeSelectActual = 'photo_size_select_actual',
  PhotoSizeSelectLarge = 'photo_size_select_large',
  PhotoSizeSelectSmall = 'photo_size_select_small',
  Php = 'php',
  Piano = 'piano',
  PianoOff = 'piano_off',
  PictureAsPdf = 'picture_as_pdf',
  PictureInPicture = 'picture_in_picture',
  PictureInPictureAlt = 'picture_in_picture_alt',
  PieChart = 'pie_chart',
  PieChartOutline = 'pie_chart_outline',
  Pin = 'pin',
  Pinch = 'pinch',
  PinDrop = 'pin_drop',
  PinEnd = 'pin_end',
  PinInvoke = 'pin_invoke',
  PivotTableChart = 'pivot_table_chart',
  Pix = 'pix',
  Place = 'place',
  Plagiarism = 'plagiarism',
  PlayArrow = 'play_arrow',
  PlayCircle = 'play_circle',
  PlayCircleFilled = 'play_circle_filled',
  PlayCircleOutline = 'play_circle_outline',
  PlayDisabled = 'play_disabled',
  PlayForWork = 'play_for_work',
  PlayLesson = 'play_lesson',
  PlaylistAdd = 'playlist_add',
  PlaylistAddCheck = 'playlist_add_check',
  PlaylistAddCheckCircle = 'playlist_add_check_circle',
  PlaylistAddCircle = 'playlist_add_circle',
  PlaylistPlay = 'playlist_play',
  PlaylistRemove = 'playlist_remove',
  Plumbing = 'plumbing',
  PlusOne = 'plus_one',
  Podcasts = 'podcasts',
  PointOfSale = 'point_of_sale',
  Policy = 'policy',
  Poll = 'poll',
  Polyline = 'polyline',
  Polymer = 'polymer',
  Pool = 'pool',
  PortableWifiOff = 'portable_wifi_off',
  Portrait = 'portrait',
  PostAdd = 'post_add',
  Power = 'power',
  PowerInput = 'power_input',
  PowerOff = 'power_off',
  PowerSettingsNew = 'power_settings_new',
  PrecisionManufacturing = 'precision_manufacturing',
  PregnantWoman = 'pregnant_woman',
  PresentToAll = 'present_to_all',
  Preview = 'preview',
  PriceChange = 'price_change',
  PriceCheck = 'price_check',
  Print = 'print',
  PrintDisabled = 'print_disabled',
  PriorityHigh = 'priority_high',
  PrivacyTip = 'privacy_tip',
  PrivateConnectivity = 'private_connectivity',
  ProductionQuantityLimits = 'production_quantity_limits',
  Propane = 'propane',
  PropaneTank = 'propane_tank',
  Psychology = 'psychology',
  PsychologyAlt = 'psychology_alt',
  Public = 'public',
  PublicOff = 'public_off',
  Publish = 'publish',
  PublishedWithChanges = 'published_with_changes',
  PunchClock = 'punch_clock',
  PushPin = 'push_pin',
  QrCode = 'qr_code',
  QrCode2 = 'qr_code_2',
  QrCodeScanner = 'qr_code_scanner',
  QueryBuilder = 'query_builder',
  QueryStats = 'query_stats',
  QuestionAnswer = 'question_answer',
  QuestionMark = 'question_mark',
  Queue = 'queue',
  QueueMusic = 'queue_music',
  QueuePlayNext = 'queue_play_next',
  Quickreply = 'quickreply',
  Quiz = 'quiz',
  Radar = 'radar',
  Radio = 'radio',
  RadioButtonChecked = 'radio_button_checked',
  RadioButtonUnchecked = 'radio_button_unchecked',
  RailwayAlert = 'railway_alert',
  RamenDining = 'ramen_dining',
  RampLeft = 'ramp_left',
  RampRight = 'ramp_right',
  RateReview = 'rate_review',
  RawOff = 'raw_off',
  RawOn = 'raw_on',
  ReadMore = 'read_more',
  RealEstateAgent = 'real_estate_agent',
  Receipt = 'receipt',
  ReceiptLong = 'receipt_long',
  RecentActors = 'recent_actors',
  Recommend = 'recommend',
  RecordVoiceOver = 'record_voice_over',
  Rectangle = 'rectangle',
  Recycling = 'recycling',
  Redeem = 'redeem',
  Redo = 'redo',
  ReduceCapacity = 'reduce_capacity',
  Refresh = 'refresh',
  RememberMe = 'remember_me',
  Remove = 'remove',
  RemoveCircle = 'remove_circle',
  RemoveCircleOutline = 'remove_circle_outline',
  RemoveDone = 'remove_done',
  RemoveFromQueue = 'remove_from_queue',
  RemoveModerator = 'remove_moderator',
  RemoveRedEye = 'remove_red_eye',
  RemoveRoad = 'remove_road',
  RemoveShoppingCart = 'remove_shopping_cart',
  Reorder = 'reorder',
  Repartition = 'repartition',
  Repeat = 'repeat',
  RepeatOn = 'repeat_on',
  RepeatOne = 'repeat_one',
  RepeatOneOn = 'repeat_one_on',
  Replay = 'replay',
  Replay10 = 'replay_10',
  Replay30 = 'replay_30',
  Replay5 = 'replay_5',
  ReplayCircleFilled = 'replay_circle_filled',
  Reply = 'reply',
  ReplyAll = 'reply_all',
  Report = 'report',
  ReportGmailerrorred = 'report_gmailerrorred',
  ReportOff = 'report_off',
  ReportProblem = 'report_problem',
  RequestPage = 'request_page',
  RequestQuote = 'request_quote',
  ResetTv = 'reset_tv',
  RestartAlt = 'restart_alt',
  Restaurant = 'restaurant',
  RestaurantMenu = 'restaurant_menu',
  Restore = 'restore',
  RestoreFromTrash = 'restore_from_trash',
  RestorePage = 'restore_page',
  Reviews = 'reviews',
  RiceBowl = 'rice_bowl',
  RingVolume = 'ring_volume',
  RMobiledata = 'r_mobiledata',
  Rocket = 'rocket',
  RocketLaunch = 'rocket_launch',
  RollerShades = 'roller_shades',
  RollerShadesClosed = 'roller_shades_closed',
  RollerSkating = 'roller_skating',
  Roofing = 'roofing',
  Room = 'room',
  RoomPreferences = 'room_preferences',
  RoomService = 'room_service',
  Rotate90DegreesCcw = 'rotate_90_degrees_ccw',
  Rotate90DegreesCw = 'rotate_90_degrees_cw',
  RotateLeft = 'rotate_left',
  RotateRight = 'rotate_right',
  RoundaboutLeft = 'roundabout_left',
  RoundaboutRight = 'roundabout_right',
  RoundedCorner = 'rounded_corner',
  Route = 'route',
  Router = 'router',
  Rowing = 'rowing',
  RssFeed = 'rss_feed',
  Rsvp = 'rsvp',
  Rtt = 'rtt',
  Rule = 'rule',
  RuleFolder = 'rule_folder',
  RunCircle = 'run_circle',
  RunningWithErrors = 'running_with_errors',
  RvHookup = 'rv_hookup',
  SafetyCheck = 'safety_check',
  SafetyDivider = 'safety_divider',
  Sailing = 'sailing',
  Sanitizer = 'sanitizer',
  Satellite = 'satellite',
  SatelliteAlt = 'satellite_alt',
  Save = 'save',
  SaveAlt = 'save_alt',
  SaveAs = 'save_as',
  SavedSearch = 'saved_search',
  Savings = 'savings',
  Scale = 'scale',
  Scanner = 'scanner',
  ScatterPlot = 'scatter_plot',
  Schedule = 'schedule',
  ScheduleSend = 'schedule_send',
  Schema = 'schema',
  School = 'school',
  Science = 'science',
  Score = 'score',
  Scoreboard = 'scoreboard',
  ScreenLockLandscape = 'screen_lock_landscape',
  ScreenLockPortrait = 'screen_lock_portrait',
  ScreenLockRotation = 'screen_lock_rotation',
  ScreenRotation = 'screen_rotation',
  ScreenRotationAlt = 'screen_rotation_alt',
  ScreenSearchDesktop = 'screen_search_desktop',
  ScreenShare = 'screen_share',
  Screenshot = 'screenshot',
  ScreenshotMonitor = 'screenshot_monitor',
  ScubaDiving = 'scuba_diving',
  Sd = 'sd',
  SdCard = 'sd_card',
  SdCardAlert = 'sd_card_alert',
  SdStorage = 'sd_storage',
  Search = 'search',
  SearchOff = 'search_off',
  Security = 'security',
  SecurityUpdate = 'security_update',
  SecurityUpdateGood = 'security_update_good',
  SecurityUpdateWarning = 'security_update_warning',
  Segment = 'segment',
  SelectAll = 'select_all',
  SelfImprovement = 'self_improvement',
  Sell = 'sell',
  Send = 'send',
  SendAndArchive = 'send_and_archive',
  SendTimeExtension = 'send_time_extension',
  SendToMobile = 'send_to_mobile',
  SensorDoor = 'sensor_door',
  SensorOccupied = 'sensor_occupied',
  Sensors = 'sensors',
  SensorsOff = 'sensors_off',
  SensorWindow = 'sensor_window',
  SentimentDissatisfied = 'sentiment_dissatisfied',
  SentimentNeutral = 'sentiment_neutral',
  SentimentSatisfied = 'sentiment_satisfied',
  SentimentSatisfiedAlt = 'sentiment_satisfied_alt',
  SentimentVeryDissatisfied = 'sentiment_very_dissatisfied',
  SentimentVerySatisfied = 'sentiment_very_satisfied',
  SetMeal = 'set_meal',
  Settings = 'settings',
  SettingsAccessibility = 'settings_accessibility',
  SettingsApplications = 'settings_applications',
  SettingsBackupRestore = 'settings_backup_restore',
  SettingsBluetooth = 'settings_bluetooth',
  SettingsBrightness = 'settings_brightness',
  SettingsCell = 'settings_cell',
  SettingsEthernet = 'settings_ethernet',
  SettingsInputAntenna = 'settings_input_antenna',
  SettingsInputComponent = 'settings_input_component',
  SettingsInputComposite = 'settings_input_composite',
  SettingsInputHdmi = 'settings_input_hdmi',
  SettingsInputSvideo = 'settings_input_svideo',
  SettingsOverscan = 'settings_overscan',
  SettingsPhone = 'settings_phone',
  SettingsPower = 'settings_power',
  SettingsRemote = 'settings_remote',
  SettingsSuggest = 'settings_suggest',
  SettingsSystemDaydream = 'settings_system_daydream',
  SettingsVoice = 'settings_voice',
  SevereCold = 'severe_cold',
  ShapeLine = 'shape_line',
  Share = 'share',
  ShareLocation = 'share_location',
  Shield = 'shield',
  ShieldMoon = 'shield_moon',
  Shop = 'shop',
  Shop2 = 'shop_2',
  ShoppingBag = 'shopping_bag',
  ShoppingBasket = 'shopping_basket',
  ShoppingCart = 'shopping_cart',
  ShoppingCartCheckout = 'shopping_cart_checkout',
  ShopTwo = 'shop_two',
  Shortcut = 'shortcut',
  ShortText = 'short_text',
  ShowChart = 'show_chart',
  Shower = 'shower',
  Shuffle = 'shuffle',
  ShuffleOn = 'shuffle_on',
  ShutterSpeed = 'shutter_speed',
  Sick = 'sick',
  SignalCellular0Bar = 'signal_cellular_0_bar',
  SignalCellular4Bar = 'signal_cellular_4_bar',
  SignalCellularAlt = 'signal_cellular_alt',
  SignalCellularAlt1Bar = 'signal_cellular_alt_1_bar',
  SignalCellularAlt2Bar = 'signal_cellular_alt_2_bar',
  SignalCellularConnectedNoInternet0Bar = 'signal_cellular_connected_no_internet_0_bar',
  SignalCellularConnectedNoInternet4Bar = 'signal_cellular_connected_no_internet_4_bar',
  SignalCellularNodata = 'signal_cellular_nodata',
  SignalCellularNoSim = 'signal_cellular_no_sim',
  SignalCellularNull = 'signal_cellular_null',
  SignalCellularOff = 'signal_cellular_off',
  SignalWifi0Bar = 'signal_wifi_0_bar',
  SignalWifi4Bar = 'signal_wifi_4_bar',
  SignalWifi4BarLock = 'signal_wifi_4_bar_lock',
  SignalWifiBad = 'signal_wifi_bad',
  SignalWifiConnectedNoInternet4 = 'signal_wifi_connected_no_internet_4',
  SignalWifiOff = 'signal_wifi_off',
  SignalWifiStatusbar4Bar = 'signal_wifi_statusbar_4_bar',
  SignalWifiStatusbarConnectedNoInternet4 = 'signal_wifi_statusbar_connected_no_internet_4',
  SignalWifiStatusbarNull = 'signal_wifi_statusbar_null',
  SignLanguage = 'sign_language',
  Signpost = 'signpost',
  SimCard = 'sim_card',
  SimCardAlert = 'sim_card_alert',
  SimCardDownload = 'sim_card_download',
  SingleBed = 'single_bed',
  Sip = 'sip',
  Skateboarding = 'skateboarding',
  SkipNext = 'skip_next',
  SkipPrevious = 'skip_previous',
  Sledding = 'sledding',
  Slideshow = 'slideshow',
  SlowMotionVideo = 'slow_motion_video',
  SmartButton = 'smart_button',
  SmartDisplay = 'smart_display',
  Smartphone = 'smartphone',
  SmartScreen = 'smart_screen',
  SmartToy = 'smart_toy',
  SmokeFree = 'smoke_free',
  SmokingRooms = 'smoking_rooms',
  Sms = 'sms',
  SmsFailed = 'sms_failed',
  SnippetFolder = 'snippet_folder',
  Snooze = 'snooze',
  Snowboarding = 'snowboarding',
  Snowmobile = 'snowmobile',
  Snowshoeing = 'snowshoeing',
  Soap = 'soap',
  SocialDistance = 'social_distance',
  SolarPower = 'solar_power',
  Sort = 'sort',
  SortByAlpha = 'sort_by_alpha',
  Sos = 'sos',
  SoupKitchen = 'soup_kitchen',
  Source = 'source',
  South = 'south',
  SouthAmerica = 'south_america',
  SouthEast = 'south_east',
  SouthWest = 'south_west',
  Spa = 'spa',
  SpaceBar = 'space_bar',
  SpaceDashboard = 'space_dashboard',
  SpatialAudio = 'spatial_audio',
  SpatialAudioOff = 'spatial_audio_off',
  SpatialTracking = 'spatial_tracking',
  Speaker = 'speaker',
  SpeakerGroup = 'speaker_group',
  SpeakerNotes = 'speaker_notes',
  SpeakerNotesOff = 'speaker_notes_off',
  SpeakerPhone = 'speaker_phone',
  Speed = 'speed',
  Spellcheck = 'spellcheck',
  Splitscreen = 'splitscreen',
  Spoke = 'spoke',
  Sports = 'sports',
  SportsBar = 'sports_bar',
  SportsBaseball = 'sports_baseball',
  SportsBasketball = 'sports_basketball',
  SportsCricket = 'sports_cricket',
  SportsEsports = 'sports_esports',
  SportsFootball = 'sports_football',
  SportsGolf = 'sports_golf',
  SportsGymnastics = 'sports_gymnastics',
  SportsHandball = 'sports_handball',
  SportsHockey = 'sports_hockey',
  SportsKabaddi = 'sports_kabaddi',
  SportsMartialArts = 'sports_martial_arts',
  SportsMma = 'sports_mma',
  SportsMotorsports = 'sports_motorsports',
  SportsRugby = 'sports_rugby',
  SportsScore = 'sports_score',
  SportsSoccer = 'sports_soccer',
  SportsTennis = 'sports_tennis',
  SportsVolleyball = 'sports_volleyball',
  Square = 'square',
  SquareFoot = 'square_foot',
  SsidChart = 'ssid_chart',
  StackedBarChart = 'stacked_bar_chart',
  StackedLineChart = 'stacked_line_chart',
  Stadium = 'stadium',
  Stairs = 'stairs',
  Star = 'star',
  StarBorder = 'star_border',
  StarBorderPurple500 = 'star_border_purple500',
  StarHalf = 'star_half',
  StarOutline = 'star_outline',
  StarPurple500 = 'star_purple500',
  StarRate = 'star_rate',
  Stars = 'stars',
  Start = 'start',
  StayCurrentLandscape = 'stay_current_landscape',
  StayCurrentPortrait = 'stay_current_portrait',
  StayPrimaryLandscape = 'stay_primary_landscape',
  StayPrimaryPortrait = 'stay_primary_portrait',
  StickyNote2 = 'sticky_note_2',
  Stop = 'stop',
  StopCircle = 'stop_circle',
  StopScreenShare = 'stop_screen_share',
  Storage = 'storage',
  Store = 'store',
  Storefront = 'storefront',
  StoreMallDirectory = 'store_mall_directory',
  Storm = 'storm',
  Straight = 'straight',
  Straighten = 'straighten',
  Stream = 'stream',
  Streetview = 'streetview',
  StrikethroughS = 'strikethrough_s',
  Stroller = 'stroller',
  Style = 'style',
  SubdirectoryArrowLeft = 'subdirectory_arrow_left',
  SubdirectoryArrowRight = 'subdirectory_arrow_right',
  Subject = 'subject',
  Subscript = 'subscript',
  Subscriptions = 'subscriptions',
  Subtitles = 'subtitles',
  SubtitlesOff = 'subtitles_off',
  Subway = 'subway',
  Summarize = 'summarize',
  Superscript = 'superscript',
  SupervisedUserCircle = 'supervised_user_circle',
  SupervisorAccount = 'supervisor_account',
  Support = 'support',
  SupportAgent = 'support_agent',
  Surfing = 'surfing',
  SurroundSound = 'surround_sound',
  SwapCalls = 'swap_calls',
  SwapHoriz = 'swap_horiz',
  SwapHorizontalCircle = 'swap_horizontal_circle',
  SwapVert = 'swap_vert',
  SwapVerticalCircle = 'swap_vertical_circle',
  Swipe = 'swipe',
  SwipeDown = 'swipe_down',
  SwipeDownAlt = 'swipe_down_alt',
  SwipeLeft = 'swipe_left',
  SwipeLeftAlt = 'swipe_left_alt',
  SwipeRight = 'swipe_right',
  SwipeRightAlt = 'swipe_right_alt',
  SwipeUp = 'swipe_up',
  SwipeUpAlt = 'swipe_up_alt',
  SwipeVertical = 'swipe_vertical',
  SwitchAccessShortcut = 'switch_access_shortcut',
  SwitchAccessShortcutAdd = 'switch_access_shortcut_add',
  SwitchAccount = 'switch_account',
  SwitchCamera = 'switch_camera',
  SwitchLeft = 'switch_left',
  SwitchRight = 'switch_right',
  SwitchVideo = 'switch_video',
  Synagogue = 'synagogue',
  Sync = 'sync',
  SyncAlt = 'sync_alt',
  SyncDisabled = 'sync_disabled',
  SyncLock = 'sync_lock',
  SyncProblem = 'sync_problem',
  SystemSecurityUpdate = 'system_security_update',
  SystemSecurityUpdateGood = 'system_security_update_good',
  SystemSecurityUpdateWarning = 'system_security_update_warning',
  SystemUpdate = 'system_update',
  SystemUpdateAlt = 'system_update_alt',
  Tab = 'tab',
  TableBar = 'table_bar',
  TableChart = 'table_chart',
  TableRestaurant = 'table_restaurant',
  TableRows = 'table_rows',
  Tablet = 'tablet',
  TabletAndroid = 'tablet_android',
  TabletMac = 'tablet_mac',
  TableView = 'table_view',
  TabUnselected = 'tab_unselected',
  Tag = 'tag',
  TagFaces = 'tag_faces',
  TakeoutDining = 'takeout_dining',
  TapAndPlay = 'tap_and_play',
  Tapas = 'tapas',
  Task = 'task',
  TaskAlt = 'task_alt',
  TaxiAlert = 'taxi_alert',
  TempleBuddhist = 'temple_buddhist',
  TempleHindu = 'temple_hindu',
  Terminal = 'terminal',
  Terrain = 'terrain',
  TextDecrease = 'text_decrease',
  TextFields = 'text_fields',
  TextFormat = 'text_format',
  TextIncrease = 'text_increase',
  TextRotateUp = 'text_rotate_up',
  TextRotateVertical = 'text_rotate_vertical',
  TextRotationAngledown = 'text_rotation_angledown',
  TextRotationAngleup = 'text_rotation_angleup',
  TextRotationDown = 'text_rotation_down',
  TextRotationNone = 'text_rotation_none',
  Textsms = 'textsms',
  TextSnippet = 'text_snippet',
  Texture = 'texture',
  TheaterComedy = 'theater_comedy',
  Theaters = 'theaters',
  Thermostat = 'thermostat',
  ThermostatAuto = 'thermostat_auto',
  ThumbDown = 'thumb_down',
  ThumbDownAlt = 'thumb_down_alt',
  ThumbDownOffAlt = 'thumb_down_off_alt',
  ThumbsUpDown = 'thumbs_up_down',
  ThumbUp = 'thumb_up',
  ThumbUpAlt = 'thumb_up_alt',
  ThumbUpOffAlt = 'thumb_up_off_alt',
  Thunderstorm = 'thunderstorm',
  Timelapse = 'timelapse',
  Timeline = 'timeline',
  Timer = 'timer',
  Timer10 = 'timer_10',
  Timer10Select = 'timer_10_select',
  Timer3 = 'timer_3',
  Timer3Select = 'timer_3_select',
  TimerOff = 'timer_off',
  TimeToLeave = 'time_to_leave',
  TipsAndUpdates = 'tips_and_updates',
  TireRepair = 'tire_repair',
  Title = 'title',
  Toc = 'toc',
  Today = 'today',
  ToggleOff = 'toggle_off',
  ToggleOn = 'toggle_on',
  Token = 'token',
  Toll = 'toll',
  Tonality = 'tonality',
  Topic = 'topic',
  Tornado = 'tornado',
  TouchApp = 'touch_app',
  Tour = 'tour',
  Toys = 'toys',
  TrackChanges = 'track_changes',
  Traffic = 'traffic',
  Train = 'train',
  Tram = 'tram',
  Transcribe = 'transcribe',
  TransferWithinAStation = 'transfer_within_a_station',
  Transform = 'transform',
  Transgender = 'transgender',
  TransitEnterexit = 'transit_enterexit',
  Translate = 'translate',
  TravelExplore = 'travel_explore',
  TrendingDown = 'trending_down',
  TrendingFlat = 'trending_flat',
  TrendingUp = 'trending_up',
  TripOrigin = 'trip_origin',
  Troubleshoot = 'troubleshoot',
  Try = 'try',
  Tsunami = 'tsunami',
  Tty = 'tty',
  Tune = 'tune',
  Tungsten = 'tungsten',
  TurnedIn = 'turned_in',
  TurnedInNot = 'turned_in_not',
  TurnLeft = 'turn_left',
  TurnRight = 'turn_right',
  TurnSharpLeft = 'turn_sharp_left',
  TurnSharpRight = 'turn_sharp_right',
  TurnSlightLeft = 'turn_slight_left',
  TurnSlightRight = 'turn_slight_right',
  Tv = 'tv',
  TvOff = 'tv_off',
  TwoWheeler = 'two_wheeler',
  TypeSpecimen = 'type_specimen',
  Umbrella = 'umbrella',
  Unarchive = 'unarchive',
  Undo = 'undo',
  UnfoldLess = 'unfold_less',
  UnfoldLessDouble = 'unfold_less_double',
  UnfoldMore = 'unfold_more',
  UnfoldMoreDouble = 'unfold_more_double',
  Unpublished = 'unpublished',
  Unsubscribe = 'unsubscribe',
  Upcoming = 'upcoming',
  Update = 'update',
  UpdateDisabled = 'update_disabled',
  Upgrade = 'upgrade',
  Upload = 'upload',
  UploadFile = 'upload_file',
  Usb = 'usb',
  UsbOff = 'usb_off',
  UTurnLeft = 'u_turn_left',
  UTurnRight = 'u_turn_right',
  Vaccines = 'vaccines',
  VapeFree = 'vape_free',
  VapingRooms = 'vaping_rooms',
  Verified = 'verified',
  VerifiedUser = 'verified_user',
  VerticalAlignBottom = 'vertical_align_bottom',
  VerticalAlignCenter = 'vertical_align_center',
  VerticalAlignTop = 'vertical_align_top',
  VerticalDistribute = 'vertical_distribute',
  VerticalShades = 'vertical_shades',
  VerticalShadesClosed = 'vertical_shades_closed',
  VerticalSplit = 'vertical_split',
  Vibration = 'vibration',
  VideoCall = 'video_call',
  Videocam = 'videocam',
  VideoCameraBack = 'video_camera_back',
  VideoCameraFront = 'video_camera_front',
  VideocamOff = 'videocam_off',
  VideoChat = 'video_chat',
  VideoFile = 'video_file',
  VideogameAsset = 'videogame_asset',
  VideogameAssetOff = 'videogame_asset_off',
  VideoLabel = 'video_label',
  VideoLibrary = 'video_library',
  VideoSettings = 'video_settings',
  VideoStable = 'video_stable',
  ViewAgenda = 'view_agenda',
  ViewArray = 'view_array',
  ViewCarousel = 'view_carousel',
  ViewColumn = 'view_column',
  ViewComfy = 'view_comfy',
  ViewComfyAlt = 'view_comfy_alt',
  ViewCompact = 'view_compact',
  ViewCompactAlt = 'view_compact_alt',
  ViewCozy = 'view_cozy',
  ViewDay = 'view_day',
  ViewHeadline = 'view_headline',
  ViewInAr = 'view_in_ar',
  ViewKanban = 'view_kanban',
  ViewList = 'view_list',
  ViewModule = 'view_module',
  ViewQuilt = 'view_quilt',
  ViewSidebar = 'view_sidebar',
  ViewStream = 'view_stream',
  ViewTimeline = 'view_timeline',
  ViewWeek = 'view_week',
  Vignette = 'vignette',
  Villa = 'villa',
  Visibility = 'visibility',
  VisibilityOff = 'visibility_off',
  VoiceChat = 'voice_chat',
  Voicemail = 'voicemail',
  VoiceOverOff = 'voice_over_off',
  Volcano = 'volcano',
  VolumeDown = 'volume_down',
  VolumeMute = 'volume_mute',
  VolumeOff = 'volume_off',
  VolumeUp = 'volume_up',
  VolunteerActivism = 'volunteer_activism',
  VpnKey = 'vpn_key',
  VpnKeyOff = 'vpn_key_off',
  VpnLock = 'vpn_lock',
  Vrpano = 'vrpano',
  Wallet = 'wallet',
  Wallpaper = 'wallpaper',
  Warehouse = 'warehouse',
  Warning = 'warning',
  WarningAmber = 'warning_amber',
  Wash = 'wash',
  Watch = 'watch',
  WatchLater = 'watch_later',
  WatchOff = 'watch_off',
  Water = 'water',
  WaterDamage = 'water_damage',
  WaterDrop = 'water_drop',
  WaterfallChart = 'waterfall_chart',
  Waves = 'waves',
  WavingHand = 'waving_hand',
  WbAuto = 'wb_auto',
  WbCloudy = 'wb_cloudy',
  WbIncandescent = 'wb_incandescent',
  WbIridescent = 'wb_iridescent',
  WbShade = 'wb_shade',
  WbSunny = 'wb_sunny',
  WbTwilight = 'wb_twilight',
  Wc = 'wc',
  Web = 'web',
  WebAsset = 'web_asset',
  WebAssetOff = 'web_asset_off',
  Webhook = 'webhook',
  WebStories = 'web_stories',
  Weekend = 'weekend',
  West = 'west',
  Whatshot = 'whatshot',
  WheelchairPickup = 'wheelchair_pickup',
  WhereToVote = 'where_to_vote',
  Widgets = 'widgets',
  WidthFull = 'width_full',
  WidthNormal = 'width_normal',
  WidthWide = 'width_wide',
  Wifi = 'wifi',
  Wifi1Bar = 'wifi_1_bar',
  Wifi2Bar = 'wifi_2_bar',
  WifiCalling = 'wifi_calling',
  WifiCalling3 = 'wifi_calling_3',
  WifiChannel = 'wifi_channel',
  WifiFind = 'wifi_find',
  WifiLock = 'wifi_lock',
  WifiOff = 'wifi_off',
  WifiPassword = 'wifi_password',
  WifiProtectedSetup = 'wifi_protected_setup',
  WifiTethering = 'wifi_tethering',
  WifiTetheringError = 'wifi_tethering_error',
  WifiTetheringOff = 'wifi_tethering_off',
  Window = 'window',
  WindPower = 'wind_power',
  WineBar = 'wine_bar',
  Woman = 'woman',
  Woman2 = 'woman_2',
  Work = 'work',
  WorkHistory = 'work_history',
  WorkOff = 'work_off',
  WorkOutline = 'work_outline',
  WorkspacePremium = 'workspace_premium',
  Workspaces = 'workspaces',
  WrapText = 'wrap_text',
  WrongLocation = 'wrong_location',
  Wysiwyg = 'wysiwyg',
  Yard = 'yard',
  YoutubeSearchedFor = 'youtube_searched_for',
  ZoomIn = 'zoom_in',
  ZoomInMap = 'zoom_in_map',
  ZoomOut = 'zoom_out',
  ZoomOutMap = 'zoom_out_map'
}