import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { LedgerConfig, LedgerConfigPreview } from '../../../../../../common/model/ledger/config';
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { LedgerConfigQuery, LedgerConfigService } from '../../../common/service/ledger-config.service';
import { LogService } from '../../../common/service/log.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-ledger-config',
  templateUrl: './setup-ledger-config.component.html',
  styleUrls: ['./setup-ledger-config.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupLedgerConfigComponent extends SetupComponent<LedgerConfig, LedgerConfigQuery, LedgerConfigPreview> {
  readonly Type = LedgerConfig;
  readonly collection = 'ledgerConfigs';
  readonly route = 'ledger-configs';
  resource = idMaybe(new LedgerConfig());

  static title() {
    return 'GL Posting Configuration';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public http: HttpClient,
    public auth: AuthService,
    public service: LedgerConfigService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }
}
