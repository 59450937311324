<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Type</app-label>
          <app-select [disabled]="value | setupReadonly" [ngModel]="value.payload.type" (selected)="onType($event)">
            <app-option *ngFor="let t of types" [view]="t | enum:JOB_TYPE_NAME" [value]="t"></app-option>
          </app-select>
        </app-field>
        <app-field class="fill">
          <app-label>Cron</app-label>
          <app-sublabel>
            <app-hover-help [tooltip]="JOB_TYPE_INSTANT[value.payload.type] ? 'This job type runs instantly and does not need a cron expression.' : 'Any valid cron expression.'" tooltip-position="left"></app-hover-help>
          </app-sublabel>
          <app-string [(ngModel)]="value.cron" [disabled]="JOB_TYPE_INSTANT[value.payload.type]"></app-string>
        </app-field>
      </div>
      <ng-template #container></ng-template>
    </app-setup-base-header>
    <app-setup-base-body></app-setup-base-body>
    <app-setup-base-footer>
      <div class="row fill" *ngIf="value.date">
        <app-label>Last Run: {{value.date | dateIso:'datetime'}}</app-label>
        <button icon-button tooltip="View Result" (click)="onResult(value.date)">open_in_new</button>
        <button icon-button tooltip="Clear Result" (click)="onClear()">delete</button>
      </div>
      <button (click)="onSubmit()">Save</button>
      <button (click)="onForce()">Save and Run</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>