import { Component } from '@angular/core';
import { TerminalBoolean } from "../../../../../../../../common/model/formula/terminal";
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-boolean',
  templateUrl: './setup-formula-boolean.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-boolean.component.scss']
})
export class SetupFormulaBooleanComponent extends BlockComponent<TerminalBoolean> {}
