import { CODE_TYPE_MAP } from "../../code/system";
import { BLOCK_NAME_MAP } from "../../model/formula/block";
import { Engine } from "../../model/formula/engine";
import { Formula, FormulaPreview } from "../../model/formula/formula";
import { Statement } from "../../model/formula/statement";
import { Property } from "../../model/property";
import { MaybeId } from "../id";
import { objectType } from "../object";
import { pascalCase } from "../string";
import { dateDay, dateFormat } from "../time";
import { statementContext, statementProperty, statementsRun } from "./statement";

/** Create a formula from the given preview. */
export function formulaPreview(preview: FormulaPreview) {
  return new Formula(preview._id, preview._inst, undefined, preview.name, preview.system);
}

/** Create a formula from the given statements. */
export function formulaStatements(statements: Statement[]) {
  return new Formula(undefined, undefined, undefined, undefined, undefined, undefined, statements);
}

/** Determine type hint context for a formula. */
export function formulaContext(formula: MaybeId<Formula>, target: any): string | undefined {
  let context = statementContext(formula.statements, target, []);
  return context?.join('.');
}

/** Get the return type of a formula. */
export function formulaReturns<T>(formula: MaybeId<Formula>, input: T): Property | undefined {
  return statementProperty(formula.statements, formula.statements, input);
}

/** Evaluate a formula and get its output. */
export function formulaRun<T>(formula: Formula, input: T): any {
  Engine.input = [input];
  Engine.output = undefined;
  Engine.return = false;

  statementsRun(formula.statements);
  
  return Engine.return ? Engine.output : undefined;
}

/** Stringify a value. */
export function formulaStringify(value: any) {
  switch (objectType(value)) {
  case 'date':
    return dateFormat(value, Engine.dateFormat);
  default:
    return `${value}`
  }
}

/** Numberify a formula value. */
export function formulaNumberify(value: any) {
  switch (objectType(value)) {
  case 'date':
    return dateDay(value);
  case 'number':
    return value;
  case 'string':
    return +value;
  case 'undefined':
    return 0;
  default:
    return NaN;
  }
}

/** Convert formula to source code. */
export function formulaSource(formula: MaybeId<Formula>) {
  let out: string[] = [];
  walk({ statements: formula.statements }, out, 0);

  return `[Formula.${pascalCase(formula.name)}]: ${out.join('')}`;
}

/** Recursively stringify formula. */
function walk(token: any, out: string[], indent: number) {
  switch (typeof token) {
    case 'string':
      out.push(`'${token}'`);
      break;
    case 'object':
      if (Array.isArray(token)) {
        out.push('[');
        for (let i = 0; i < token.length; ++i) {
          out.push('\n', ' '.repeat(indent + 2));
          walk(token[i], out, indent + 2);
          if (i + 1 < token.length) out.push(',');
        }
        
        if (token.length) out.push('\n');
        out.push(' '.repeat(indent), ']');
      } else if (token) {
        out.push('{');
        let keys = Object.keys(token);
        for (let i = 0; i < keys.length; ++i) {
          let key = keys[i]!;
          out.push('\n', ' '.repeat(indent + 2));

          switch (key) {
          case 'type':
            out.push(`type: ${BLOCK_NAME_MAP.get(token[key])}`);
            break;
          case 'category':
            out.push(`category: ${CODE_TYPE_MAP.get(token[key])}`);
            break;
          default:
            out.push(`${key}: `);
            walk(token[key], out, indent + 2);
          }

          if (i + 1 < keys.length) out.push(',');
        }

        if (keys.length) out.push('\n');
        out.push(' '.repeat(indent), '}');
      } else {
        out.push('null');
      } break;
    default:
      out.push(token);
      break;
  }

  return out;
}
