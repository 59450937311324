import { Component } from '@angular/core';
import { TerminalNumber } from "../../../../../../../../common/model/formula/terminal";
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-number',
  templateUrl: './setup-formula-number.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-number.component.scss']
})
export class SetupFormulaNumberComponent extends BlockComponent<TerminalNumber> {}
