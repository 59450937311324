import { LedgerType } from "../../code/standard/disputes";
import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { QueryInfo } from "../../info/query";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { EnumValidator } from "../../validator/enum";
import { FusionCollection } from "./../fusion";
import { LedgerAccountType } from "./account";

/** Configuration for posting a pair of ledger transactions. */
export class LedgerConfig {

  constructor(
    /** Unique identifier of this ledger configuration. */
    public _id = ID_DEFAULT,
    /** Institution owning this ledger configuration. */
    public _inst = ID_DEFAULT,
    /** Name of this ledger configuration. */
    public name = '',
    /** Revision of ledger configuration. */
    public revision = 0,
    /** Whether this is a debit or a credit from the GL account. */
    public type = LedgerType.Credit,
    /** Formula to execute to determine this ledger it's amount. */
    public _amountFormula?: string,
    /** Formulas to execute when this config's ledger item is submitted. */
    public _submitFormulas?: string[],
    /** GL account to pull funds from. */
    public _glAccount = ID_DEFAULT,
    /** Other account involved in transaction. */
    public account = LedgerAccountType.Consumer,
    /** Default description for posted transaction if not specified by ledger (supports Angular expressions). */
    public description?: string,
    /** Transaction code of this ledger. */
    public tranCode = '',
    /** Source code for transaction. */
    public sourceCode = '',
    /** Batch number for post, or empty to auto-assign. TODO: Consider tagged type to determine behavior? */
    public batch?: string,
    /** Run number for post, or empty to auto-assign. TODO: Consider tagged type to determine behavior? */
    public run?: string,
    /** True if this ledger item is deleted. */
    public deleted?: boolean
  ) {}

  static typeinfo: TypeInfo<LedgerConfig> = {
    _amountFormula: ID_DEFAULT,
    _submitFormulas: [ID_DEFAULT, ID_DEFAULT],
    account: new EnumValidator(LedgerAccountType),
    description: '',
    batch: '',
    run: '',
    deleted: false
  }

  static collectioninfo: CollectionInfo<FusionCollection, LedgerConfig> = {
    _amountFormula: 'formulas',
    _submitFormulas: 'formulas',
    _inst: 'institutions',
    _glAccount: 'accounts'
  }

  static indexinfo: IndexInfo<LedgerConfig> = [
    { key: { _inst: 1, name: 1, revision: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  static queryinfo: QueryInfo<LedgerConfig> = {
    deleted: { $ne: true }
  };
}

/** Preview for fetching list of ledger configurations. */
export class LedgerConfigPreview {
  constructor(
    /** Unique identifier of ledger config. */
    public _id = ID_DEFAULT,
    /** Title to display for ledger config. */
    public name = ''
  ) {}
}