<app-edit-list [autocreate]="false" [ngModel]="items" (ngModelChange)="onItems($event)" (deleted)="delete($event)" (reordered)="onReorder()">
  <app-edit-list-item cdkDrag *ngFor="let attachment of items">
    <div class="item">
      <button icon-button [attachment-link]="attachment" tooltip="Download">{{attachment.name | mimeCategory | icon:MIME_CATEGORY_ICON}}</button>
      <app-field horizontal [readonly]="editing !== attachment" style="cursor: pointer">
        <app-label>Name:</app-label>
        <app-string [(ngModel)]="attachment.name" [readonly]="editing !== attachment" (click)="editing = attachment" (blur)="onEdit(attachment)"></app-string>
      </app-field>
      <app-field horizontal readonly>
        <app-label>Type:</app-label>
        <app-code [(ngModel)]="attachment.type" [category]="CommonCode.AttachmentType" (selected)="onEdit(attachment)"></app-code>
      </app-field>
      <app-field horizontal readonly>
        <app-label>Date:</app-label>
        <app-date readonly [ngModel]="$any(attachment).date | today | dateIso:'datetime'"></app-date>
      </app-field>
      <app-field horizontal readonly>
        <app-label>Uploaded by:</app-label>
        <app-user readonly [(ngModel)]="$any(attachment)._user"></app-user>
      </app-field>
    </div>
  </app-edit-list-item>
  <app-edit-list-item creating *ngIf="creating" [(creating)]="creating" (confirm)="confirm()">
    <div class="item">
      <button icon-button class="hidden">upload</button>
      <app-field horizontal>
        <app-label>Name:</app-label>
        <app-string [(ngModel)]="creating.name"></app-string>
      </app-field>
      <app-field horizontal>
        <app-label>Type:</app-label>
        <app-code [(ngModel)]="creating.type" [category]="CommonCode.AttachmentType" [types]="types"></app-code>
      </app-field>
    </div>
  </app-edit-list-item>
  <ng-template #buttonTemplate let-attachment let-creating="creating">
    <app-comment-list-button *ngIf="!creating" collection="attachments" tooltip="Comments" header="Attachment Comments" [value]="attachment" [title]="attachment.name" [disabled]="attachment._id | idNull"></app-comment-list-button>
  </ng-template>
  <app-upload [label]="label" [single]="true" [type]="types?.[0] ?? AttachmentType.Other" (uploads)="onUploads($event)"></app-upload>
</app-edit-list>