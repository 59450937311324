import { numberClamp } from "./number";
import { objectDelete, objectOmit } from "./object";

/** Default value for IDs. */
export const ID_DEFAULT = '000000000000000000000000';
/** Regex for valid IDs. */
export const OBJECTID_REGEX = /^[a-f0-9]{24}$/;

/** Value that has an ID. */
export interface HasId { _id: string };
/** Value that has an inst. */
export interface HasInst { _inst: string; };
/** Value that has a name. */
export interface HasName { name: string; };

/** Value that has an ID and inst. */
export interface HasIdInst { _id: string; _inst: string; };
/** Value that has an ID and name. */
export interface HasIdName { _id: string; name: string; };
/** Value that has an ID, inst and name. */
export interface HasIdInstName { _id: string; _inst: string, name?: string; };

/** Value with an optional ID. */
export type MaybeId<T = any> = NoId<T> & { _id?: string | undefined };
/** Value with an optional ID and inst. */
export type MaybeIdInst<T = any> = NoIdInst<T> & { _id?: string | undefined, _inst?: string | undefined };

/** Get a value without an ID. */
export type NoId<T> = Omit<T, '_id'>;
/** Get a value without an ID or inst. */
export type NoIdInst<T> = Omit<T, '_id' | '_inst'>;
/** Get a value without an ID or org. */
export type NoIdOrg<T> = Omit<T, '_id' | '_org'>;

/** A delta for added and removed items. */
export class IdDelta {
  constructor(
    /** New items added. */
    public added?: string[],
    /** Items removed. */
    public deleted?: string[]
  ) { }
}

/** True if given ID is null. */
export function idNull(_id: string | undefined): _id is undefined {
  return !_id || _id === ID_DEFAULT;
}

/** Omit _id from a type that is known to have _id. */
export function idOmit<T>(object: T): NoId<T> {
  return objectOmit(object as any, '_id') as any;
}

/** Recursively omit all IDs from a value. */
export function idOmitDeep<T>(object: T): NoId<T> {
  let out: any = object;
  if (!(out instanceof Object)) return out;

  for (let key of Object.keys(out)) {
    switch (typeof out[key]) {
      case 'string':
        if (key.startsWith('_')) objectDelete(out, key);
        break;
      case 'object':
        if (key.startsWith('_')) {
          if (Array.isArray(out[key])) out[key] = [];
        } else {
          idOmitDeep(out[key]);
        } break;
    }
  }

  return out;
}

/** Omit _id from an object type that might have _id. */
export function idOmitSafe<T extends Object>(object: T): NoId<T> {
  return '_id' in object ? idOmit(object as T) : object;
}

/** Omit _id from a type that is known to have _id, allowing return type to optionally have _id */
export function idMaybe<T>(object: T): MaybeId<T> {
  return idOmit(object);
}

/** Omit IDs from a list of objects. */
export function idOmitAll<T>(objects: MaybeId<T>[]): NoId<T>[]
export function idOmitAll<T>(objects: T[]): NoId<T>[]
export function idOmitAll<T>(objects: T[]): NoId<T>[] {
  return (objects as any).map((o: any) => objectOmit(o, '_id'));
}

/** Assert that a type has an ID. */
export function idExists<T extends MaybeId>(object: MaybeId<T>): object is T {
  return !idNull(object._id);
}

/** Add an ID to a value. */
export function idAdd<T>(object: MaybeId<T>, _id = ID_DEFAULT): T {
  if ('_id' in object) return object as T;
  return { ...object, _id } as any;
}

/** Create object ID from a value. */
export function idFrom(value: number) {
  return `${value}`.padStart(24, '0');
}

/** Extract a 32-bit value from ID. */
export function idInteger(_inst: string): number {
  return numberClamp(Number.parseInt(_inst.slice(-8), 16), 0x00000000, 0x7FFFFFFF);
}

/** Omit ID and inst from a type. */
export function idInstOmit<T>(object: T): NoIdInst<T>
export function idInstOmit<T extends MaybeIdInst>(object: T): NoIdInst<T>
export function idInstOmit(object: any): any {
  return objectOmit(object, '_id', '_inst');
}

/** Walk object top to bottom, setting institutions. */
export function instAdd<T>(object: NoIdInst<T>, _inst: string): NoId<T> {
  instAddDeep(object, _inst);
  return object as NoId<T>;
}

/** Recursively add institution to value. */
export function instAddDeep(object: Record<string, any>, _inst: string) {
  if (typeof object !== 'object' || !object) return;
  for (let [key, value] of Object.entries(object)) {
    switch (key) {
      case '_inst':
        object[key] = idNull(value) ? _inst : value;
        break;
      case '_insts':
        object[key] = Array.isArray(value) ? value.map(v => idNull(v) ? _inst : v) : value;
    }

    if (value instanceof Object) instAddDeep(value, _inst);
  }

  return object;
}

/** Assert that a type has a name. */
export function nameHas(object: any): object is HasName {
  return (object instanceof Object) && typeof object.name === 'string';
}

/** Creates a map of ids on a T to T. */
export function createMap<T extends HasId>(values: T[]): Map<string, T> {
  let map = new Map<string, T>();
  for (let value of values) {
    map.set(value._id, value);
  }
  return map;
}
