import { boxOnscreen } from "./box";

/** Scroll an element horizontally into view. */
export function elementScroll(parent: HTMLElement, child?: HTMLElement) {
  if (child) {
    let [parentbox, childbox] = [boxOnscreen(parent), boxOnscreen(child)];
    if (childbox.l < parentbox.l) {
      parent.scrollLeft = child.offsetLeft + 1;
    } else if (childbox.r > parentbox.r) {
      parent.scrollLeft = -parent.offsetWidth + child.offsetLeft + child.offsetWidth;
    }
  } else {
    // Scroll to end.
    parent.scrollLeft = Number.POSITIVE_INFINITY;
  }
}
