import { indexinfoKeys } from "../info";
import { ErrorResponse } from "../message/error";
import { keyUnique } from "../toolbox/keys";
import { objectCompose } from "../toolbox/object";

/**
 *  Maps a set of unique composite keys to database IDs or full documents. 
 *  @param K A subset of an object's properties that make it unique.
 *  @param V An ObjectID from a collection or a subset of a full document.
 */
export class IndexMap<K extends Object, V extends string | Object> {

  /** List of unique keys of value. */
  private keys: (keyof K)[] = []
  /** Mapping of IDs to items. */
  private map = new Map<string, V>();

  constructor(key: K, _value?: V) {
    this.keys = indexinfoKeys(key);
  }

  /** Generate a record from IndexMap. */
  static record<K extends Object>(map: IndexMap<K, string>) {
    return objectCompose([...map.map.entries()].map(([key, _id]) => [key, _id]));
  }

  /** Get string value of a particular key. */
  key(key: K) {
    return keyUnique(key, this.keys);
  }

  /** Add a new value to map. */
  set(key: K, value: V) {
    this.map.set(this.key(key), value);
  }

  /** Get a value from map. */
  get(key: K) {
    let unique = this.key(key);
    let value = this.map.get(unique);
    return value ?? new ErrorResponse(`Failed to find value: ${unique}`);
  }

  /** Check if item exists in map */
  has(key: K) {
    return this.map.get(this.key(key));
  }

  /** Add list of values using a conversion function. */
  add<T extends K>(values: T[], convert: (key: T) => V): IndexMap<K, V> {
    for (let value of values) this.set(value, convert(value));
    return this;
  }

  /** Get value iterator of map. */
  values() {
    return this.map.values();
  }
}