import { PaginateRequest } from "../../../../../../common/message/paginate";
import { Display, DisplayPartial, DisplayType } from "../../../../../../common/model/display";
import { ClientSource } from "./client";
import { ServerSource } from "./server";

/** Client or server-side grid source. */
export type GridSource<T> = ClientSource<T> | ServerSource<T>;

/** Process a grid source into parameters for a paginate request. */
export function gridPaginateRequest<D extends DisplayPartial, T extends DisplayType>(type: T, source: GridSource<D>): PaginateRequest<NonNullable<D[T]>> {
  return {
    limit: source.limit,
    skip: source.start,
    sort: Display.split(source.sort?.column, type) as any,
    direction: source.sort?.direction
  }
}
