import { currency } from "../../toolbox/currency";
import { enumValues } from "../../toolbox/enum";
import { NestedKey } from "../../toolbox/keys";
import { FusionCollectionName } from "../fusion";
import { Permission } from "../permission";
import { Expression } from "./expression";

/** A terminal in a formula. */
export type Terminal<T = any> =
  | TerminalBoolean
  | TerminalNumber
  | TerminalString
  | TerminalCurrency
  | TerminalDate
  | TerminalCode
  | TerminalCodeList
  | TerminalIdentifier<T>
  | TerminalArray<T>
  | TerminalPermission
  | TerminalResource
  | TerminalUndefined;

/** Sources for an expression. */
export enum TerminalType {
  /** A boolean constant. */
  Boolean = 'boolean',
  /** A number constant. */
  Number = 'number',
  /** A string constant. */
  String = 'string',
  /** A currency constant. */
  Currency = 'currency',
  /** A date constant. */
  Date = 'date',
  /** A code type constant. */
  Code = 'code',
  /** A list of code types. */
  CodeList = 'codeList',
  /** Expression that pulls from current context. */
  Identifier = 'identifier',
  /** Undefined constant value */
  Undefined = 'undefined',
  /** Multiple values joined to a list. */
  Array = 'array',
  /** One of the permission of the application. */
  Permission = 'permission',
  /** An Object ID referencing a system resource. */
  Resource = 'resource'
}

/** Basic configuration shared by all expressions. */
export interface TerminalTag<E extends TerminalType> {
  /** Tagged type of expression. */
  type: E
}

export interface TerminalBoolean extends TerminalTag<TerminalType.Boolean> {
  value: boolean
}

export interface TerminalNumber extends TerminalTag<TerminalType.Number> {
  value: number
}

export interface TerminalString extends TerminalTag<TerminalType.String> {
  value: string
}

export interface TerminalCurrency extends TerminalTag<TerminalType.Currency> {
  value: currency
}

export interface TerminalDate extends TerminalTag<TerminalType.Date> {
  value: number
}

export interface TerminalCode extends TerminalTag<TerminalType.Code> {
  category: string
  value: string
}

export interface TerminalCodeList extends TerminalTag<TerminalType.CodeList> {
  category: string
  value: string[]
}

export interface TerminalIdentifier<T = any> extends TerminalTag<TerminalType.Identifier> {
  key: NestedKey<T>
}

export interface TerminalArray<T = any> extends TerminalTag<TerminalType.Array> {
  expressions: Expression<T>[]
}

export interface TerminalPermission extends TerminalTag<TerminalType.Permission> {
  value: Permission
}

export interface TerminalResource extends TerminalTag<TerminalType.Resource> {
  collection: FusionCollectionName
  value: string
}

export interface TerminalUndefined extends TerminalTag<TerminalType.Undefined> {
}


/** List of all terminal types. */
export const TERMINAL_TYPES = new Set(enumValues(TerminalType));