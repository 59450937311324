import { ApplicationRef, ComponentFactoryResolver, Directive, ElementRef, Injector, Input, Renderer2 } from '@angular/core';
import { IconComponent } from '../component/icon/icon.component';

/** Valid positions for button icon. */
export type ButtonIconPosition = 'before' | 'after';

@Directive({
  selector: '[button-prefix]'
})
export class ButtonPrefixDirective {
  /** Text for icon. */
  @Input('button-prefix') buttonIcon = '';
  /** Position for icon. */
  @Input() position: ButtonIconPosition = 'before';

  constructor(
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private injector: Injector,
    private elementRef: ElementRef<HTMLButtonElement>,
    private app: ApplicationRef
  ) {}

  ngOnInit() {
    // Create HTML element to hold icon component.
    let factory = this.resolver.resolveComponentFactory(IconComponent);
    let node: HTMLElement = this.renderer.createElement('app-icon');

    // Attach component to node and return.
    let ref = factory.create(this.injector, [], node);
    this.app.attachView(ref.hostView);
    node.innerText = this.buttonIcon;
    node.style.minWidth = 'fit-content';

    if (this.position == 'before') {
      node.style.marginRight = '0.5rem';
      this.elementRef.nativeElement.insertBefore(node, this.elementRef.nativeElement.firstChild);
    } else {
      node.style.marginLeft = '0.5rem';
      this.elementRef.nativeElement.appendChild(node);
    }
  }
}
