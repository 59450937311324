import { CdkFooterRowDef, CdkHeaderRowDef, CdkNoDataRow, CdkRowDef } from "@angular/cdk/table";
import { Directive } from "@angular/core";

@Directive({
  selector: '[grid-header-row-def]',
  providers: [ { provide: CdkHeaderRowDef, useExisting: GridHeaderRowDef } ],
  inputs: ['columns: grid-header-row-def', 'sticky: grid-header-row-def-sticky'],
})
export class GridHeaderRowDef extends CdkHeaderRowDef {}

@Directive({
  selector: '[grid-footer-row-def]',
  providers: [ { provide: CdkFooterRowDef, useExisting: GridFooterRowDef } ],
  inputs: ['columns: grid-footer-row-def', 'sticky: grid-footer-row-def-sticky'],
})
export class GridFooterRowDef extends CdkFooterRowDef {}

@Directive({
  selector: '[gridRowDef]',
  providers: [ { provide: CdkRowDef, useExisting: GridRowDef } ],
  inputs: ['columns: gridRowDefColumns', 'when: gridRowDefWhen'],
})
export class GridRowDef<T> extends CdkRowDef<T> {}

@Directive({
  selector: 'ng-template[gridNoDataRow]',
  providers: [ { provide: CdkNoDataRow, useExisting: GridNoDataRow } ],
})
export class GridNoDataRow extends CdkNoDataRow {}
