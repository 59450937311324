import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { LedgerModule } from '../ledger/ledger.module';
import { DemoColorComponent } from './color/demo-color.component';
import { DemoCommentListComponent } from './comment-list/demo-comment-list.component';
import { DemoSettingsComponent } from './demo-settings/demo-settings.component';
import { DemoDialogComponent } from './dialog/demo-dialog.component';
import { DemoEditListComponent } from './edit-list/demo-edit-list.component';
import { DemoExpansionPanelComponent } from './expansion-panel/demo-expansion-panel.component';
import { DemoFormListComponent } from './form-list/demo-form-list.component';
import { DemoGridComponent } from './grid/demo-grid.component';
import { DemoLogComponent } from './log/demo-log.component';
import { DemoStepperComponent } from './stepper/demo-stepper.component';
import { DemoStepComponent } from './stepper/step/demo-step.component';
import { DemoTooltipComponent } from './tooltip/demo-tooltip.component';

@NgModule({
  declarations: [
    DemoColorComponent,
    DemoCommentListComponent,
    DemoDialogComponent,
    DemoEditListComponent,
    DemoExpansionPanelComponent,
    DemoFormListComponent,
    DemoGridComponent,
    DemoLogComponent,
    DemoSettingsComponent,
    DemoStepComponent,
    DemoStepperComponent,
    DemoTooltipComponent
  ],
  imports: [
    LedgerModule,
    SharedModule
  ]
})
export class DemoModule { }
