<app-card>
  <app-card-header>
    <div>Team Members</div>
    <div class="buttons">
      <button color="secondary" button-prefix="print" position="before">Print</button>
      <button color="secondary" button-prefix="file_download" position="before">Export</button>
    </div>
  </app-card-header>
  <table app-grid [source]="source">

    <ng-container grid-column-def="name">
      <grid-header-cell *grid-header-cell-def>User</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.name}}</grid-cell>
      <grid-footer-cell class="stat footer" *grid-footer-cell-def></grid-footer-cell>
    </ng-container>

    <ng-container *ngFor="let type of display" [grid-column-def]="type">
      <grid-header-cell *grid-header-cell-def>{{type | enum:STAT_TYPE_NAME | multiline}}</grid-header-cell>
      <grid-cell lighten class="stat" *grid-cell-def="let row" [attr.color]="row[type].actual | percentColor : row[type].goal">
        <div>{{row[type].actual}}</div>
        <div>{{row[type].goal}}</div>
        <div>{{row[type].actual | percent:row[type].goal}}</div>
      </grid-cell>
      <grid-footer-cell class="stat footer" *grid-footer-cell-def>
        <div>{{footer[type].actual}}</div>
        <div>{{footer[type].goal}}</div>
        <div>{{footer[type].actual | percent:footer[type].goal}}</div>
      </grid-footer-cell>
    </ng-container>
    
    <grid-header-row *grid-header-row-def="columns"></grid-header-row>
    <grid-row *gridRowDef="let row; columns: columns;" (click)="onStats(row)" class="selectable"></grid-row>
    <grid-footer-row *grid-footer-row-def="columns"></grid-footer-row>
  </table>
</app-card>