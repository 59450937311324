import { AttachmentType } from "../../code/standard/common";

/** List of all document templates. */
export type SystemAttachment = StandardAttachment;

/** Standard attachments provided for all products. */
export enum StandardAttachment {
  FintegrateLogo200px = 'Fintegrate Logo 200px.png'
}

/** Configuration for a built-in attachment. */
interface BuiltinAttachment {
  /** Override for attachment name. */
  name?: string
  /** Override for attachment type. */
  type?: AttachmentType
}

/** List of builtin attachments to attach to other resources. */
export const BUILTIN_ATTACHMENTS: Record<SystemAttachment, BuiltinAttachment> = {
  [StandardAttachment.FintegrateLogo200px]: {}
}