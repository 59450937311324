import { Pipe } from '@angular/core';

@Pipe({
  name: 'percent'
})
export class PercentPipe {
  transform(value: number, full = 100) {
    return `${Math.round(100 * value / full)}%`;
  }
}
