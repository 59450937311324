import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT, OBJECTID_REGEX } from "../toolbox/id";
import { UnionValidator } from "../validator/union";
import { FusionCollection } from "./fusion";
import { TASK_CONFIG_UNIONINFO, TASK_TYPE_NAME, TaskConfigClass, TaskConfigHeadless, TaskType } from "./task-config";

/** Configuration for a task. */
export class Task<T extends TaskType = TaskType> {
  constructor(
    /** Unique identifier of task. */
    public _id = ID_DEFAULT,
    /** Institution configuring this task. */
    public _inst = ID_DEFAULT,
    /** Triggers to always run on this task. */
    public _triggers: string[] = [],
    /** Title to display for task. */
    public name = '',
    /** Format string for task result. Leave empty to fall back on default system description. */
    public description?: string,
    /** Message to display to user inside task dialog. */
    public message?: string,
    /** Icon override for this task. */
    public icon?: string,
    /** True if this is a system task and cannot be reconfigured. */
    public system?: boolean,
    /** True if this task has been modified. */
    public dirty?: boolean,
    /** Configuration for specific task type. */
    public config = new TaskConfigHeadless() as TaskConfigClass[T],
  ) {}

  static typeinfo: TypeInfo<Task> = {
    system: false,
    dirty: false,
    icon: '',
    description: '',
    message: '',
    config: new UnionValidator(TASK_CONFIG_UNIONINFO),
    _triggers: [ID_DEFAULT]
  }

  static collectioninfo: CollectionInfo<FusionCollection, Task> = {
    _inst: 'institutions',
    _triggers: 'triggers'
  }

  static indexinfo: IndexInfo<Task> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  /** Get name of a given task. */
  static title(task: Task) {
    return task.name || TASK_TYPE_NAME[task.config.type];
  }
}

/** Preview for fetching list of tasks. */
export class TaskPreview {
  constructor(
    /** Unique identifier of task. */
    public _id = ID_DEFAULT,
    /** Title to display for task. */
    public name = '',
    /** True if this is a system task and cannot be reconfigured. */
    public system?: boolean,
    /** True task has been modified. */
    public dirty?: boolean,
  ) { }
  
  static typeinfo: TypeInfo<TaskPreview> = {
    system: false,
    dirty: false
  }
}

/** Result of performing a task. */
export class TaskResult {
  /** Task that was performed. */
  _task = ID_DEFAULT

  /** Check if a value is a task result. */
  static check(result: any): result is TaskResult {
    return result instanceof Object && OBJECTID_REGEX.test(result._task);
  }
}