import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonCode } from '../../../../../../common/code/system';
import { MemberErrorJoin, MemberJoin } from '../../../../../../common/model/member-join';
import { Permission } from '../../../../../../common/model/permission';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { LogService } from '../../../common/service/log.service';
import { TabService } from '../../../common/service/tab.service';
import { getOneRequest } from '../../../common/toolbox/request';
import { MemberSetupComponent } from '../setup/member-setup.component';
import { MemberSetupData } from '../setup/member-setup.model';

@Component({
  selector: 'app-member-toolbar',
  templateUrl: './member-toolbar.component.html',
  styleUrls: ['./member-toolbar.component.scss'],
  host: {
    class: 'row'
  }
})
export class MemberToolbarComponent {
  readonly Permission = Permission;
  readonly CommonCode = CommonCode;

  /** Member being viewed in main interface. */
  @Input() set member(member: MemberJoin) {
    this._member = member;
  }
  
  /** Emits when changes are made to member. */
  @Output() memberChange = new EventEmitter<MemberErrorJoin>();

  /** Member being viewed. */
  _member = new MemberJoin();

  constructor(
    private log: LogService,
    private http: HttpClient,
    private tabs: TabService
  ) {}

  /** Callback when opening member setup. */
  async onSetup() {
    // Refresh this tab if changes are submitted.
    let save = new Subject<void>();
    save.subscribe(async () => {
      // Changes made, refresh member.
      let member = await getOneRequest(this.http, 'members', { _ids: [this._member._id], _inst: this._member._inst });
      if (errorResponse(member)) {
        this.log.show(member);
        return;
      }

      this.memberChange.next(member);
    });

    // Open tab to configure member.
    let data: MemberSetupData = { member: this._member, save };
    this.tabs.open(MemberSetupComponent, data);
  }
}
