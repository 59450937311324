import { TypeInfo } from "../info/type";
import { AttachmentUpload } from "../model/attachment";
import { CollectionAttachments } from "../model/collection";
import { FusionCollection } from "../model/fusion";
import { ID_DEFAULT, MaybeId } from "../toolbox/id";

/** A generic request to modify items of institution. */
export class ItemRequest {
  constructor(
    /** Institution to modify items. */
    public _inst = ID_DEFAULT,
    /** Items to modify. */
    public _ids: string[] = []
  ) {}

  static typeinfo: TypeInfo<ItemRequest> = {
    _ids: [ID_DEFAULT, ID_DEFAULT]
  }
}

/** An item with an attached list of uploads. */
export class ItemAttachmentUpload<C extends CollectionAttachments<FusionCollection> = 'accounts'> {
  constructor(
    /** Item to be updated. */
    public item: MaybeId<FusionCollection[C]>,
    /** List of attachments for item. */
    public uploads?: AttachmentUpload[]
  ) {}

  static typeinfo: TypeInfo<ItemAttachmentUpload> = {
    uploads: [new AttachmentUpload()]
  }
}