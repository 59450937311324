import { ID_DEFAULT } from "../toolbox/id";
import { IndexInfo } from "../info";
import { TypeInfo } from "../info/type";
import { EnumValidator } from "../validator/enum";

/** A value implementing the minimal counter interface. */
export class CounterLike {
  constructor(
    /** ID for this counter. */
    public _id: string,
    /** Institution for this counter. */
    public _inst: string,
    /** Value of this counter. */
    public count: number | undefined
  ) {}

  static typeinfo: TypeInfo<CounterLike> = {
    count: 0
  }
}

/** Possible types of counters. */
export enum CounterType {
  Claim = 'claim'
}

/** A tracker for an autoincrementing ID. */
export class Counter implements CounterLike {
  constructor(
    /** ID for this counter. */
    public _id = ID_DEFAULT,
    /** Institution for this counter. */
    public _inst = ID_DEFAULT,
    /** Value of this counter. */
    public count: number | undefined,
    /** Collection this counter is counting. */
    public type = CounterType.Claim
  ) {}

  static typeinfo: TypeInfo<Counter> = {
    ...CounterLike.typeinfo,
    type: new EnumValidator(CounterType)
  }

  static indexinfo: IndexInfo<Counter> = [
    { key: { _inst: 1, type: 1 }, unique: true }
  ];
}