import { INTEGER_REGEX, numberClamp } from "./number";

/** Type of currency values. */
export type currency = number;
/** Type of interest rate values. */
export type interest = number;

/** Default value for any currency. */
export const CURRENCY_DEFAULT = 0;
/** Default value for currency string. */
export const CURRENCY_STRING = '0.00';
/** Scaling factor used by currency. Used to convert back to decimals. */
export const CURRENCY_SCALE = 100;
/** Minimum value for currencies. */
export const CURRENCY_MIN = Number.MIN_SAFE_INTEGER;
/** Maximum value for currencies. */
export const CURRENCY_MAX = Number.MAX_SAFE_INTEGER;
/** Regex for validating USD values in format 123.45 */
export const CURRENCY_REGEX = /^-?\d+\.\d{2}$/;

/** Scaling factor used by interest rates. Used to convert back to decimals. */
export const INTEREST_SCALE = 10000;

/** Check if object is a valid currency value. */
export function currencyValidate(value: any): value is currency {
  return Number.isInteger(value);
}

/** Coerce a value into a currency value, in cents. */
export function currencyCoerce(value: any, required?: boolean, min?: number, max?: number): number | undefined
export function currencyCoerce(value: any, required?: true, min?: number, max?: number): number
export function currencyCoerce(value: any, required = false, min = CURRENCY_MIN, max = CURRENCY_MAX) {
  let fallback = required ? numberClamp(CURRENCY_DEFAULT, min, max) : undefined;

  switch (typeof value) {
  case 'string':
    // Attempt parse as format 123.45
    let match = value.match(CURRENCY_REGEX);
    if (match) return numberClamp(+value.replace('.', ''), min, max);

    // Attempt parse as format 12345
    match = value.match(INTEGER_REGEX);
    if (match) return numberClamp(+value, min, max);
    return fallback;
  case 'number':
    return Number.isInteger(value) ? value : fallback;
  default:
    return fallback;
  }
}

/** 
 *  Format a currency out to a string.
 *  Readonly format: $123,456.78
 *  Editable format: 123456.78
 */
export function currencyFormat(value: unknown, required = false, readonly = true) {
  let amount = currencyCoerce(value, required);
  if (amount === undefined) return '';

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping: readonly
  }).format(amount / CURRENCY_SCALE).slice(+!readonly);
}

/** Perform a compound interest calculation on a value.
 *  @param principal Initial amount before adding interest, as a currency value.
 *  @param rate Interest rate applied each period.
 *  @param periods Number of elapsed periods to apply interest.
 *  @returns Amount of interest accrued, excluding initial principal.
 */
export function currencyInterest(principal: currency, rate: interest, periods: number) {
  // Remove scaling factor and convert from fixed point to decimal.
  principal /= CURRENCY_SCALE, rate /= INTEREST_SCALE;

  // Perform calculation, apply scaling factor.
  return Math.round(CURRENCY_SCALE * (principal * Math.pow(1 + rate, periods) - principal));
}