import { Component } from '@angular/core';
import { CLAIM_TYPE_NAME, ClaimType } from '../../../../../../common/code/standard/disputes';
import { DisputesCode } from '../../../../../../common/code/system';
import { FeatureType } from "../../../../../../common/model/organization/feature";
import { Permission } from '../../../../../../common/model/permission';
import { PermissionGroup } from '../../../../../../common/model/permission-group';
import { Newable } from "../../../../../../common/toolbox/object";
import { AuthService } from '../../../common/service/auth.service';
import { CodeTypeService } from '../../../common/service/code-type.service';
import { DevService } from '../../../common/service/dev.service';
import { TabService } from '../../../common/service/tab.service';
import { titleComponent } from '../../../common/toolbox/component';
import { AccountSetupComponent } from '../../account/setup/account-setup.component';
import { AccountSetupData } from '../../account/setup/account-setup.model';
import { BuildImportComponent } from '../../build/import/build-import.component';
import { BuildWorkQueuesComponent } from '../../build/work-queues/build-work-queues.component';
import { ClaimIntakeComponent } from '../../claim/intake/claim-intake.component';
import { ClaimIntakeData } from '../../claim/intake/claim-intake.model';
import { ClaimReportComponent } from '../../claim/report/claim-report.component';
import { DemoColorComponent } from '../../demo/color/demo-color.component';
import { DemoCommentListComponent } from '../../demo/comment-list/demo-comment-list.component';
import { DemoSettingsComponent } from '../../demo/demo-settings/demo-settings.component';
import { DemoDialogComponent } from '../../demo/dialog/demo-dialog.component';
import { DemoEditListComponent } from '../../demo/edit-list/demo-edit-list.component';
import { DemoExpansionPanelComponent } from '../../demo/expansion-panel/demo-expansion-panel.component';
import { DemoFormListComponent } from '../../demo/form-list/demo-form-list.component';
import { DemoGridComponent } from '../../demo/grid/demo-grid.component';
import { DemoLogComponent } from '../../demo/log/demo-log.component';
import { DemoStepperComponent } from '../../demo/stepper/demo-stepper.component';
import { DemoTooltipComponent } from '../../demo/tooltip/demo-tooltip.component';
import { DocumentationComponent } from '../../documentation/documentation.component';
import { SetupClaimFilterComponent } from '../../setup/claim-filter/setup-claim-filter.component';
import { SetupCodeTypeComponent } from '../../setup/code-type/setup-code-type.component';
import { SetupDocumentTemplateComponent } from '../../setup/document-template/setup-document-template.component';
import { SetupFilterRuleComponent } from '../../setup/filter-rule/setup-filter-rule.component';
import { SetupFormListComponent } from '../../setup/form-list/setup-form-list.component';
import { SetupFormComponent } from '../../setup/form/setup-form.component';
import { SetupFormulaComponent } from '../../setup/formula/setup-formula.component';
import { SetupInstitutionComponent } from '../../setup/institution/setup-institution.component';
import { SetupJobComponent } from '../../setup/job/setup-job.component';
import { SetupLedgerConfigComponent } from '../../setup/ledger-config/setup-ledger-config.component';
import { SetupModelComponent } from '../../setup/model/setup-model.component';
import { SetupProfileComponent } from '../../setup/profile/setup-profile.component';
import { SetupSettingsComponent } from '../../setup/settings/setup-settings.component';
import { SetupTableComponent } from '../../setup/table/setup-table.component';
import { SetupTaskComponent } from '../../setup/task/setup-task.component';
import { SetupTriggerComponent } from '../../setup/trigger/setup-trigger.component';
import { SetupUserComponent } from '../../setup/user/setup-user.component';
import { SetupWorkQueueComponent } from '../../setup/work-queue/setup-work-queue.component';
import { SetupWorkflowComponent } from '../../setup/workflow/setup-workflow.component';
import { UserSettingsComponent } from '../user-settings/user-settings.component';

/** A top-level menu of groups. */
class OptionMenu {
  constructor(
    /** Name of menu. */
    public name: string,
    /** List of groups in menu. */
    public groups: OptionGroup[],
    /** List of top-level options in menu. */
    public options: Option[],
    /** Required feature for this menu to be visible. */
    public feature?: FeatureType,
    /** True if menu is visible. */
    public visible = false
  ) { }

  /** Recalculate visibility of menu. */
  refresh(auth: AuthService) {
    let feature = auth.enabled(this.feature);
    let groups = this.groups.map(o => o.refresh(auth)).some(v => v);
    let options = this.options.map(o => o.refresh(auth)).some(v => v);
    this.visible = feature && (groups || options);
  }
}

/** A group of multiple dropdown options. */
class OptionGroup {
  constructor(
    /** Display name for group. */
    public name: string,
    /** List of options in this group. */
    public options: Option[],
    /** True if group is visible. */
    public visible = false
  ) { }

  /** Recalculate visibility of group. */
  refresh(auth: AuthService) {
    return this.visible = this.options.map(o => o.refresh(auth)).some(v => v);
  }
}

/** Additional options for setup menu option. */
class OptionConfig<T = unknown> {
  constructor(
    /** Permission bound to this menu option. */
    public permissions?: PermissionGroup | Permission[],
    /** Enabled feature to show this menu option. */
    public feature?: FeatureType,
    /** Additional data to pass to this component. */
    public data?: T
  ) { }
}

/** Configuration for displaying a setup menu option. */
class Option<T = any> {
  constructor(
    /** Component to open upon selecting option. */
    public component: Newable<T>,
    /** Additional configuration for displaying option. */
    public config = new OptionConfig(),
    /** Name to display for option. */
    public name: string | Promise<string> = '',
    /** True if option is visible. */
    public visible?: boolean
  ) { }

  /** Recalculate visibility of option. */
  refresh(auth: AuthService) {
    this.name = this.name || (titleComponent<void>(this.component) ? this.component.title() : '<NAME ME>');
    return this.visible = auth.permissionSome(this.config.permissions) && auth.enabled(this.config.feature);
  }
}

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  host: { class: 'row' }
})
export class MenuBarComponent {
  readonly DocumentationComponent = DocumentationComponent;
  readonly UserSettingsComponent = UserSettingsComponent;
  readonly FeatureType = FeatureType;
  readonly Permission = Permission;

  /** List of menus. */
  readonly menus: OptionMenu[] = [
    new OptionMenu('Disputes', [
      new OptionGroup('New Claim', [
        new Option(ClaimIntakeComponent, { permissions: [Permission.ClaimsACHIntake], feature: FeatureType.Disputes, data: new ClaimIntakeData(ClaimType.ACH) }, this.claimType(ClaimType.ACH)),
        new Option(ClaimIntakeComponent, { permissions: [Permission.ClaimsCardIntake], feature: FeatureType.Disputes, data: new ClaimIntakeData(ClaimType.Card) }, this.claimType(ClaimType.Card)),
        new Option(ClaimIntakeComponent, { permissions: [Permission.ClaimsCheckIntake], feature: FeatureType.Disputes, data: new ClaimIntakeData(ClaimType.Check) }, this.claimType(ClaimType.Check))
      ])
    ], [], FeatureType.Disputes),
    new OptionMenu('Build', [], [
      new Option(BuildImportComponent, { permissions: [Permission.ImportsRun] }),
      new Option(BuildWorkQueuesComponent, { permissions: [Permission.WorkQueuesBuild] }),
      new Option(ClaimReportComponent, { permissions: [Permission.ClaimsEdit], feature: FeatureType.Disputes })
    ], FeatureType.Disputes),
    new OptionMenu('Setup', [
      new OptionGroup('Data', [
        new Option(AccountSetupComponent, { permissions: [Permission.CodeTypesEdit], data: new AccountSetupData() }, 'Accounts'),
        new Option(SetupCodeTypeComponent, { permissions: [Permission.CodeTypesEdit] }),
        new Option(SetupFormulaComponent, { permissions: [Permission.FormulasEdit] }),
        new Option(SetupModelComponent, { permissions: [Permission.ModelsEdit] })
      ]),
      new OptionGroup('UI', [
        new Option(SetupFormComponent, { permissions: [Permission.FormsEdit] }),
        new Option(SetupFormListComponent, { permissions: [Permission.FormListsEdit] }),
        new Option(SetupTableComponent, { permissions: [Permission.TablesEdit] })
      ]),
      new OptionGroup('Security', [
        new Option(SetupInstitutionComponent, { permissions: [Permission.InstitutionsEdit] }),
        new Option(SetupProfileComponent, { permissions: [Permission.ProfilesEdit] }),
        new Option(SetupUserComponent, { permissions: [Permission.UsersEdit] }),
      ]),
      new OptionGroup('Business', [
        new Option(SetupDocumentTemplateComponent, { permissions: [Permission.DocumentTemplatesEdit] }),
        new Option(SetupFilterRuleComponent, { permissions: [Permission.FilterRulesEdit] }),
        new Option(SetupLedgerConfigComponent, { permissions: [Permission.LedgerConfigsEdit] }),
        new Option(SetupJobComponent, { permissions: [Permission.JobsEdit] }),
        new Option(SetupTaskComponent, { permissions: [Permission.TasksEdit] }),
        new Option(SetupTriggerComponent, { permissions: [Permission.TriggersEdit] }),
        new Option(SetupWorkflowComponent, { permissions: [Permission.WorkflowsEdit] }),
      ]),
      new OptionGroup('Collections', [
        new Option(SetupWorkQueueComponent, { feature: FeatureType.Collections, permissions: [Permission.WorkQueuesEdit] })
      ]),
      new OptionGroup('Disputes', [
        new Option(SetupClaimFilterComponent, { feature: FeatureType.Disputes, permissions: [Permission.ClaimFiltersEdit] })
      ]),
      new OptionGroup('Configuration', [
        new Option(SetupSettingsComponent, { permissions: [Permission.SettingGroupsEdit] })
      ])
    ], []),
    new OptionMenu('Demo', [
      new OptionGroup('Components', [
        new Option(DemoCommentListComponent),
        new Option(DemoEditListComponent),
        new Option(DemoExpansionPanelComponent),
        new Option(DemoFormListComponent),
        new Option(DemoGridComponent),
        new Option(DemoSettingsComponent),
        new Option(DemoStepperComponent),
      ]),
      new OptionGroup('Functionality', [
        new Option(DemoColorComponent),
        new Option(DemoDialogComponent),
        new Option(DemoLogComponent),
        new Option(DemoTooltipComponent)
      ])
    ], [])
  ];

  constructor(
    public auth: AuthService,
    public tabs: TabService,
    protected dev: DevService,
    private codes: CodeTypeService,
  ) {
    this.refresh();
    dev.changed.subscribe(() => this.refresh());
  }

  /** Refresh visible groups. */
  refresh() {
    for (let menu of this.menus) {
      menu.refresh(this.auth);
    }

    let demo = this.menus.find(group => group.name === 'Demo');
    if (demo) demo.visible = this.dev.on;
  }

  /** Open specified tab. */
  open<T>(component: Newable<T>, data?: unknown) {
    this.tabs.open(component, data);
  }

  /** Get name of claim type, if disputes feature is enabled. */
  private claimType(type: ClaimType) {
    if (!this.auth.enabled(FeatureType.Disputes)) return CLAIM_TYPE_NAME[type];
    return this.codes.codename(type, { category: DisputesCode.ClaimType, _inst: this.auth._inst })
  }
}
