import { ClaimCheckDetailsType, ClaimCheckReportReason } from "../../code/standard/disputes";
import { DisputesCode } from "../../code/system";
import { PropertyInfo } from "../../info/prop";
import { UnionInfo, unioninfoRetype } from "../../info/union";
import { PropertyType } from "../property-type";

/** Reasons provided for a card claim. */
export type ClaimCheckDetails = ClaimCheckDetailsForgery | ClaimCheckDetailsUnauthorized;

/** Base fields used by all check claim details. */
export class ClaimCheckDetailsBase<T extends ClaimCheckDetailsType = ClaimCheckDetailsType> {
  
  constructor(
    /** Union tag for check claim. */
    public type: T,
    /** Reason for opening check claim. */
    public reason = ClaimCheckReportReason.Forgery
  ) {}

  static propinfo: PropertyInfo<ClaimCheckDetailsBase> = {
    type: { type: PropertyType.Code, category: DisputesCode.ClaimCheckDetailsType },
    reason: { type: PropertyType.Code, category: DisputesCode.ClaimCheckReportReason }
  }

  /** Reassign type of claim card details while maintaining object reference. */
  static retype<T extends ClaimCheckDetailsType>(details: ClaimCheckDetails, type: T): ClaimCheckDetailsClass[T] {
    return unioninfoRetype<ClaimCheckDetailsBase, ClaimCheckDetails, ClaimCheckDetailsType>(new ClaimCheckDetailsBase(ClaimCheckDetailsType.Forgery), details, type, CLAIM_CHECK_DETAILS_UNIONINFO) as ClaimCheckDetailsClass[T];
  }
}

/** Information about a check forgery claim. */
export class ClaimCheckDetailsForgery extends ClaimCheckDetailsBase<ClaimCheckDetailsType.Forgery> {

  constructor(
    /** Number of check */
    public checkNumber = 0,
    /** Date on check */
    public checkDate = new Date(),
    /** Name of recipient of check */
    public checkName = '',
    /** Name signed on check (default to member name?) */
    public signedNamed = '',
  ) {
    super(ClaimCheckDetailsType.Forgery, ClaimCheckReportReason.Forgery)
  }
}

/** Information about an unauthorized claim. */
export class ClaimCheckDetailsUnauthorized extends ClaimCheckDetailsBase<ClaimCheckDetailsType.Unauthorized> {

  constructor(
    /** Name of recipient of check */
    public checkName = '',
  ) {
    super(ClaimCheckDetailsType.Unauthorized, ClaimCheckReportReason.NotAuthorized)
  }
}

/** Mapping of check claim reasons to classes. */
export class ClaimCheckDetailsClass {
  [ClaimCheckDetailsType.Forgery] = new ClaimCheckDetailsForgery();
  [ClaimCheckDetailsType.Unauthorized] = new ClaimCheckDetailsUnauthorized();
};

/** Type information about check claim reasons. */
export const CLAIM_CHECK_DETAILS_UNIONINFO: UnionInfo<ClaimCheckDetails, ClaimCheckDetailsType> = {
  tag: 'type',
  classes: new ClaimCheckDetailsClass(),
};

/** Standard check report reasons to display in dropdowns. */
export const CLAIM_CHECK_REPORT_REASON_FILTER = [ClaimCheckReportReason.Forgery, ClaimCheckReportReason.NotAuthorized, ClaimCheckReportReason.SignatureIrregularSuspectedForgery,ClaimCheckReportReason.EndorsementIrregular,ClaimCheckReportReason.AlteredOrfFictitiousItem, ClaimCheckReportReason.WarrantyBreach, ClaimCheckReportReason.RCCWarrantyBreach, ClaimCheckReportReason.ForgedAndCounterfeitWarrantyBreach];