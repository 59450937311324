import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayValue } from '../../../../../common/model/display';
import { Formula, FormulaPreview } from "../../../../../common/model/formula/formula";
import { Property } from '../../../../../common/model/property';
import { ApplicationSettingsKey } from '../../../../../common/model/setting-group';
import { ArraySome } from "../../../../../common/toolbox/array";
import { formulaReturns } from '../../../../../common/toolbox/formula/formula';
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { serviceSettingsItem, serviceSettingsItems } from '../toolbox/service';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { SettingGroupService } from './setting-group.service';
import { TabService } from './tab.service';

/** A query to fetch a specific formula. */
export class FormulaQuery {
  constructor(
    /** ID of formula. */
    public _id = ID_DEFAULT,
    /** Institution of formula. */
    public _inst = ID_DEFAULT
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class FormulaService extends CachePreviewService<Formula, FormulaQuery, FormulaPreview> {
  readonly route = 'formulas/preview';
  readonly Type = Formula;

  /** Cache of formula return types. */
  returnCache = new Map<string, Property | undefined>();

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private settings: SettingGroupService,
    public tabs: TabService
  ) {
    super(FormulaQuery, log, dialog, http);
  }

  /** Get return type of a formula. */
  returns(formula: Formula) {
    if (this.returnCache.has(formula._id)) return this.returnCache.get(formula._id);
    let property = formulaReturns(formula, new DisplayValue());
    this.returnCache.set(formula._id, property);
    return property;
  }

  /** Fetch a formula from settings. */
  setting(key: ApplicationSettingsKey) {
    return serviceSettingsItem('Formula', this, this.auth, this.log, this.settings, key);
  }

  /** Fetch a list of formulas from settings. */
  settingList(key: ApplicationSettingsKey) {
    return serviceSettingsItems('Formula', this, this.auth, this.log, this.settings, key);
  }

  protected override multiple(queries: ArraySome<FormulaQuery>) {
    return getRequest(this.http, 'formulas', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}