<div class="page" tooltip="Current page">{{source.available ? source.start + 1 : 0}} - {{source.end + 1}} of {{source.available}}</div>
<div class="flex fill">
  <ng-content></ng-content>
</div>
<div class="flex">
  <button *ngIf="downloadable" icon-button tooltip="Download" (click)="download.emit()">download</button>
  <button icon-button (click)="source.first()" tooltip="First page" [disabled]="!source.prev">first_page</button>
  <button icon-button (click)="source.offset(-1)" tooltip="Previous page" [disabled]="!source.prev">chevron_left</button>
  <button icon-button (click)="source.refresh()" tooltip="Refresh" *ngIf="refresh || source.asynchronous">refresh</button>
  <button icon-button (click)="source.offset(1)" tooltip="Next page" [disabled]="!source.next">chevron_right</button>
  <button icon-button (click)="source.last()" tooltip="Last page" [disabled]="!source.next">last_page</button>
</div>