import { TypeInfo } from "../../info/type";
import { EnumMap, enumValues } from "../../toolbox/enum";
import { objectKeys } from "../../toolbox/object";
import { EnumValidator } from "../../validator/enum";
import { CoreType } from "../core";

/** Features that can be granted to organization. */
export type Feature = FeatureCollections | FeatureDisputes;

/** Features that organization can be allowed to access. */
export enum FeatureType {
  /** Loan collections and debt tracking. */
  Collections = 'collections',
  /** Transaction and card disputes. */
  Disputes = 'disputes'
}

/** Disputes configuration. */
export class FeatureDisputes {
  constructor(
    /** Core used for disputes. */
    public core = CoreType.Standard
  ) {}

  static typeinfo: TypeInfo<FeatureDisputes> = {
    core: new EnumValidator(CoreType)
  }
}

/** Collections configuration. */
export class FeatureCollections {
  constructor(
    /** Core used for collections. */
    public core = CoreType.Standard
  ) {}

  static typeinfo: TypeInfo<FeatureCollections> = {
    core: new EnumValidator(CoreType)
  }
}

/** List of features in an organization. */
export class Features {
  [FeatureType.Collections]?: FeatureCollections;
  [FeatureType.Disputes]?: FeatureDisputes;

  static typeinfo: TypeInfo<Features> = {
    [FeatureType.Collections]: new FeatureCollections(),
    [FeatureType.Disputes]: new FeatureDisputes()
  }

  /** Check if a feature is enabled. */
  static enabled<T extends FeatureType>(features: Features, feature: T) {
    return features[feature] !== undefined;
  }

  /** Get list of available features. */
  static list(features: Features): FeatureType[] {
    return objectKeys(features).filter(feature => features[feature]);
  }

  /** Get first available feature for section. */
  static first(features: Features): FeatureType | undefined {
    return enumValues<FeatureType>(FeatureType).find(feature => Features.enabled(features, feature));
  }
}

/** Mapping of features to names. */
export const FEATURE_NAMES: EnumMap<FeatureType> = {
  [FeatureType.Collections]: 'Collections',
  [FeatureType.Disputes]: 'Disputes'
}