import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent {
  /** Amount of progress that's been completed. */
  @Input() value?: number;
  /** Total amount of progress that needs to be made. */
  @Input() max?: number;
  /** Current width of progress bar. */
  width = 50;

  ngOnChanges() {
    this.width = 100 * ((this.value ?? 0) / (this.max ?? 0));
  }
}
