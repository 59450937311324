import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { UserReset } from "../../../../../common/model/user/user";
import { errorResponse } from "../../../../../common/toolbox/message";
import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';
import { getRequest } from '../toolbox/request';

/** Guard that ensures user is logged in before access is granted main page. */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private users: UserService,
    private translate: TranslateService,
    private router: Router
  ) {};

  async canActivate() {
    // Already logged in.
    if (this.auth.valid) return true;

    // Wait for translation and session to be loaded.
    let [, session] = await Promise.all([
      firstValueFrom(this.translate.use('en-us')),
      getRequest(this.http, 'auth/session', undefined)
    ]);

    if (errorResponse(session)) {
      // Kick to login page.
      this.router.navigate(['/login']);
      return false;
    }

    if (UserReset.check(session)) {
      // Force password reset.
      this.router.navigate(['/reset-password']);
      return false;
    }

    // Set current user and hide login form.
    await this.auth.refresh(session);
    this.users.session(session);
    return true;
  }
}
