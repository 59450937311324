import { Component, Inject, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '../../dialog/dialog.model';
import { CommentListComponent } from '../comment-list.component';
import { CommentListDialogData, CommentListDialogReturn } from './comment-list-dialog.model';

@Component({
  selector: 'app-comment-list-dialog',
  templateUrl: './comment-list-dialog.component.html',
  styleUrls: ['./comment-list-dialog.component.scss'],
  host: {
    class: 'column'
  }
})
export class CommentListDialogComponent {
  /** Reference to comment list in template. */
  @ViewChild(CommentListComponent) component!: CommentListComponent;

  static title() {
    return 'Comments';
  }

  constructor(
    @Inject(DIALOG_DATA)
    public data: CommentListDialogData,
    public dialogRef: DialogRef<CommentListDialogReturn>
  ) { }
}
