import { EnumMap } from "../../toolbox/enum";

/** Result of establishing a follow-up. */
export enum CollectionsFollowUpResponse {
  Always            = 'always',
  UnlessCurrent     = 'unlessCurrent'
}

/** Possible responses for outbound calls. */
export enum CollectionsInboundContactResponse {
  Email             = 'email',
  InhouseMeeting    = 'inhouseMeeting',
  NoPromise         = 'noPromise',
  PayTrace          = 'payTrace',
  Promise           = 'promise',
  VoiceMail         = 'voiceMail'
}

/** Possible responses for outbound calls. */
export enum CollectionsOutboundContactResponse {
  BadPhone          = 'badPhone',
  Busy              = 'busy',
  Disconnected      = 'disconnected',
  FollowUp          = 'followUp',
  LeftMessage       = 'leftMessage',
  NoAnswer          = 'noAnswer',
  NoPromise         = 'noPromise',
  Promise           = 'promise',
  VoiceMailFull     = 'voiceMailFull',
  VoiceMailNone     = 'voiceMailNone'
}

/** Result of establishing a promise to pay. */
export enum CollectionsPromiseResponse {
  Pending           = 'pending',
  Broken            = 'broken',
  Kept              = 'kept'
}

/** Name of each promise type. */
export const COLLECTIONS_FOLLOW_UP_RESPONSE_NAME: EnumMap<CollectionsFollowUpResponse> = {
  [CollectionsFollowUpResponse.Always]: 'Always',
  [CollectionsFollowUpResponse.UnlessCurrent]: 'Unless Current'
};

/** Name of each outbound call type. */
export const COLLECTIONS_INBOUND_CONTACT_RESPONSE_NAME: EnumMap<CollectionsInboundContactResponse> = {
  [CollectionsInboundContactResponse.Email]: 'Bad Phone',
  [CollectionsInboundContactResponse.InhouseMeeting]: 'Busy',
  [CollectionsInboundContactResponse.NoPromise]: 'No Promise',
  [CollectionsInboundContactResponse.PayTrace]: 'Promise',
  [CollectionsInboundContactResponse.Promise]: 'Promise',
  [CollectionsInboundContactResponse.VoiceMail]: 'Voice Mail'
};

/** Name of each outbound call type. */
export const COLLECTIONS_OUTBOUND_CONTACT_RESPONSE_NAME: EnumMap<CollectionsOutboundContactResponse> = {
  [CollectionsOutboundContactResponse.BadPhone]: 'Bad Phone',
  [CollectionsOutboundContactResponse.Busy]: 'Busy',
  [CollectionsOutboundContactResponse.Disconnected]: 'Disconnected',
  [CollectionsOutboundContactResponse.FollowUp]: 'Follow Up',
  [CollectionsOutboundContactResponse.LeftMessage]: 'Left Message',
  [CollectionsOutboundContactResponse.NoAnswer]: 'No Answer',
  [CollectionsOutboundContactResponse.NoPromise]: 'No Promise',
  [CollectionsOutboundContactResponse.Promise]: 'Promise',
  [CollectionsOutboundContactResponse.VoiceMailFull]: 'Voice Mail Full',
  [CollectionsOutboundContactResponse.VoiceMailNone]: 'No Voice Mail'
};

/** Name of each promise type. */
export const COLLECTIONS_PROMISE_RESPONSE_NAME: EnumMap<CollectionsPromiseResponse> = {
  [CollectionsPromiseResponse.Pending]: 'Pending',
  [CollectionsPromiseResponse.Broken]: 'Broken',
  [CollectionsPromiseResponse.Kept]: 'Kept'
};