import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorResponse } from "../../../../../common/message/error";
import { AccountUnion } from '../../../../../common/model/account/account';
import { Chip } from "../../../../../common/model/chip";
import { ClaimJoin } from '../../../../../common/model/claim/claim';
import { DisplayPartial } from "../../../../../common/model/display";
import { Event, EventUpload } from "../../../../../common/model/event";
import { EventUpdate } from '../../../../../common/model/event/reminder';
import { arraySome } from "../../../../../common/toolbox/array";
import { claimUnion } from '../../../../../common/toolbox/claim';
import { keyNestedGet } from "../../../../../common/toolbox/keys";
import { errorResponse } from "../../../../../common/toolbox/message";
import { EventDescriptionPipe } from '../pipe/event-description.pipe';
import { EventTitlePipe } from '../pipe/event-title.pipe';
import { DisplayChip } from '../toolbox/display';
import { EventOptions } from '../toolbox/fusion';
import { IntervalOptions, IntervalTimer } from '../toolbox/interval';
import { patchRequest, postRequest } from '../toolbox/request';
import { AuthService } from './auth.service';
import { IdleService } from './idle.service';
import { LogService } from './log.service';

/** Timer for events that updates local storage as needed. */
class EventTimer extends IntervalTimer {
  override save(_user: string) {
    return DB.put('eventOptions', { _user, interval: this.options });
  }
}

@Injectable({
  providedIn: 'root'
})
export class EventService {
  /** Emits when events are updated. */
  updated = new Subject<void>();

  /** Configuration for event timer. */
  get config() { return this.timer.config; }
  set config(config: IntervalOptions) { this.timer.config = config; }

  /** Timer for performing updates. */
  private timer!: EventTimer;

  constructor(
    private log: LogService,
    private idle: IdleService,
    private http: HttpClient,
    private auth: AuthService,
    private title: EventTitlePipe,
    private description: EventDescriptionPipe
  ) {
    this.load();
  }

  /** Add new events to system. */
  async add(...uploads: EventUpload[]): Promise<ErrorResponse | void> {
    if (!arraySome(uploads)) return;
    let result = await postRequest(this.http, 'events', { upserts: uploads });
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }

    this.updated.next();
  }

  /** Mark events as completed. */
  async complete(events: Event[]): Promise<ErrorResponse | void> {
    let updates: EventUpdate[] = events.map(e => ({
      _id: e._id,
      _completed: this.auth.session._id,
      done: new Date()
    }));

    if (!arraySome(updates)) return;
    let result = await patchRequest(this.http, 'events', { _inst: this.auth._inst, updates });
    if (errorResponse(result)) return result;
    this.updated.next();
  }

  /** Merge events, evaluate titles/descriptions and evaluate chips for event list. */
  async merge(events: Event[], chips: Chip[] = [], accounts: AccountUnion[] = [], claims: ClaimJoin[] = []): Promise<DisplayChip[]> {
    // Get mapping of ids to accounts/claims.
    let accountMap = new Map(accounts.map(a => [a._id, a]));
    let claimMap = new Map(claims.map(c => [c._id, c]));

    return Promise.all(events.map<Promise<DisplayPartial>>(async event => {
      // Find claim or account associated with row.
      let claim = claimMap.get(keyNestedGet('result._claim', event));
      let account = accountMap.get(keyNestedGet('result._account', event));
      let [title, description] = await Promise.all([
        event.title ?? await this.title.transform(event),
        event.description ?? await this.description.transform(event)
      ]);

      // Get full display value for row.
      let value: DisplayPartial = {
        event: { ...event, title, description },
        account: account ?? claim?.account as AccountUnion,
        member: account?.members[0] ?? claim?.member,
        claim: claimUnion(claim)
      };

      // Evaluate all chips for row.
      return { ...value, chip: Chip.first(chips, value) };
    }));
  }

  /** Load initial interval config. */
  private async load() {
    let options = new EventOptions(this.auth.session._id)
    let result = await DB.get('eventOptions', this.auth.session._id);
    if (errorResponse(result)) {
      this.log.show(result);
    } else if (result) {
      options = result;
    }

    this.timer = new EventTimer(options.interval, () => this.updated.next(), this.log, this.auth, this.idle);
  }
}
