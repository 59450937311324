<div class="row fill">
  <app-field class="fill">
    <app-label>Status</app-label>
    <app-code [(ngModel)]="condition.status" [category]="DisputesCode.DisputeStatus"></app-code>
  </app-field>
  <app-field class="fill">
    <app-label>Exact</app-label>
    <app-boolean [required]="false" [(ngModel)]="condition.exact"></app-boolean>
  </app-field>
</div>
<div class="row fill">
  <app-field class="fill">
    <app-label>Mode</app-label>
    <app-select empty="Default" [(ngModel)]="condition.mode" [required]="false">
      <app-option *ngFor="let mode of modes" [pair]="mode"></app-option>
    </app-select>
  </app-field>
  <app-field class="fill">
    <app-label>Filters</app-label>
    <app-resource-select collection="formulas" [multiple]="true" [required]="false" [(ngModel)]="condition._filters"></app-resource-select>
  </app-field>
</div>