import { Component } from '@angular/core';
import { DOCUMENT_TEMPLATE_TYPE_NAME } from '../../../../../../../../common/code/standard/common';
import { CommonCode } from '../../../../../../../../common/code/system';
import { TaskConfigDocumentTemplate } from '../../../../../../../../common/model/task-config';
import { enumMapPairs } from '../../../../../../../../common/toolbox/enum';
import { TaskAccessor } from '../../edit/setup-task-edit.model';

@Component({
  selector: 'app-setup-task-config-document-template',
  templateUrl: './setup-task-config-document-template.component.html',
  styleUrls: ['./setup-task-config-document-template.component.scss']
})
export class SetupTaskConfigDocumentTemplateComponent extends TaskAccessor<TaskConfigDocumentTemplate> {
  readonly CommonCode = CommonCode;
  
  /** Selectable document template types. */
  types = enumMapPairs(DOCUMENT_TEMPLATE_TYPE_NAME);
}
