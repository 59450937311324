import { propinfoProperty, PropertyInfo, propinfoOf } from "../info/prop";
import { Model, ModelMap } from "../model/model";
import { propertyValue } from "../model/property";
import { PropertyType } from "../model/property-type";
import { keyNestedPairs } from "./keys";
import { titleCase } from "./string";

/** Create model configuration from a known concrete type. */
export function modelFrom(...objects: Object[]): Model {
  let model = new Model();

  for (let object of objects) {
    model.name = titleCase(object.constructor.name);
  
    for (let pair of keyNestedPairs(object)) {
      let property = propinfoProperty(pair.value, object);
      if (!property) continue;

      property.name = pair.view;
      model.properties.push(property);
    }
  }

  model.properties.sort((a, b) => a.name.localeCompare(b.name));
  return model;
}

/** Get list of keys for a given model. */
export function modelKeys(model: Model) {
  return model.properties.map(p => p.key);
}

/** Convert flat list of model properties into map. */
export function modelMap(model: Model) {
  let out: ModelMap = {};

  for (let property of model.properties) {
    out[property.key] = property;
  }

  return out;
}

/** Construct dummy object with type information from model.
 *  @param model The model to define type information for.
 *  @param value If specified, type information will be attached to this object and returned.
 */
export function modelValue(model: Model, value?: Record<string, any>) {
  let ModelProxy = class {
    [key: string]: any;
    constructor() { for (let property of model.properties) this[property.key] = propertyValue(property); }
    static propinfo: PropertyInfo<any> = {}
  };

  // Copy model properties into propinfo.
  let instance = new ModelProxy();
  let propinfo = propinfoOf(instance);
  for (let property of model.properties) {
    propinfo[property.key] = property;
  }

  if (value) Object.setPrototypeOf(value, Object.getPrototypeOf(instance));
  return value ?? instance;
}

/** Get list of codes for a given model. */
export function modelCodes(model: Model, codes: Set<string>) {
  for (let property of model.properties) {
    if (property.type === PropertyType.Code) codes.add(property.category);
  }
}