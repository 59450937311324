import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ClaimAttachmentPostRequest } from '../../../../../../common/message/claim';
import { ErrorResponse } from '../../../../../../common/message/error';
import { Attachment } from '../../../../../../common/model/attachment';
import { ClaimAttachment } from '../../../../../../common/model/claim/attachment';
import { Claim, ClaimACH } from '../../../../../../common/model/claim/claim';
import { Dispute } from '../../../../../../common/model/dispute/dispute';
import { arrayDefined, arrayOf, arraySome } from '../../../../../../common/toolbox/array';
import { disputeUnjoin } from '../../../../../../common/toolbox/dispute';
import { MaybeId, idNull } from '../../../../../../common/toolbox/id';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { AttachmentListComponent } from '../../../common/component/attachment/list/attachment-list.component';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { deleteRequest, patchRequest, postRequest } from '../../../common/toolbox/request';

@Component({
  selector: 'app-claim-attachment-list',
  templateUrl: '../../../common/component/attachment/list/attachment-list.component.html',
  styleUrls: ['../../../common/component/attachment/list/attachment-list.component.scss']
})
export class ClaimAttachmentListComponent extends AttachmentListComponent {

  /** Reference to task called when uploading/editing a document. */
  @ViewChild('task', { read: ViewContainerRef }) taskRef!: ViewContainerRef;

  /** The claim we are showing attachments for */
  @Input() set claim(claim: MaybeId<Claim>) {
    this._claim = claim;
    this.refresh(claim.attachments);
  }

  /** List of disputes to mutate. */
  @Input() disputes?: MaybeId<Dispute>[];

  /** Whether to filter only attachments specific to the provided disputes. */
  @Input() set disputesOnly(disputesOnly: boolean) {
    this._disputesOnly = disputesOnly;
    this.refresh(this._claim.attachments);
  }

  /** Last bound claim. */
  _claim: MaybeId<Claim> = new ClaimACH();
  /** True to filter attachments specific to provided disputes. */
  _disputesOnly = false;

  constructor(
    auth: AuthService,
    log: LogService,
    http: HttpClient,
    dialog: DialogService,
  ) {
    super(auth, log, http, dialog);
  }

  /** Callback when deleting attachments. */
  override async delete(i: number) {
    let attachment = this.items[i];
    if (idNull(this._claim._id) || !Attachment.check(attachment)) return;
    let _id = attachment._id;
    
    if (!idNull(this._claim._id)) {
      // Delete attachments from server.
      let result = await deleteRequest(this.http, 'claims/attachments', {
        _claim: this._claim._id,
        _inst: this._claim._inst,
        _ids: [_id]
      });

      if (errorResponse(result)) {
        this.log.show(result);
        return;
      }

      this.log.show(result.success);
      this._claim.attachments = this._claim.attachments.filter(({ _attachment }) => _attachment !== _id);
    }
    
    // Replicate server-side changes locally.
    this.items.splice(i, 1);
    this.refresh(this._claim.attachments);
  }

  /** Submit list of pending uploads. */
  override async submit(): Promise<void | ErrorResponse> {
    // Confirm any pending uploads.
    let uploads = this.uploads;
    this.confirm();

    // Run global claim attachment formulas.
    let disputes = this.disputes ?? [];

    // No additional actions needed if claim has not been uploaded.
    if (!uploads.length || idNull(this._claim._id)) return;
    let _disputes = arraySome(this.disputes) ? arrayDefined(this.disputes.map(dispute => dispute._id)) : undefined;
    let req: ClaimAttachmentPostRequest = {
      _claim: this._claim._id,
      _inst: this._claim._inst,
      _disputes,
      uploads
    };

    // Add new attachments to claim.
    let attachments = await postRequest(this.http, 'claims/attachments', req);
    if (errorResponse(attachments)) {
      this.log.show(attachments);
      return attachments;
    }

    // Submit changes to disputes.
    this.items = [...this.attachments, ...attachments];
    let update = await patchRequest(this.http, 'disputes', { disputes: disputes.map(dispute => disputeUnjoin(dispute)) });
    if (errorResponse(update)) {
      this.log.show(update);
      return update;
    }

    // Replicate server-side changes locally.
    this.log.show('Successfully uploaded attachments.');
    this._claim.attachments.push(...attachments.map(a => new ClaimAttachment(a._id, _disputes)));
  }

  /** Callback when finished reordering items. */
  protected override async onReorder() {
    if (!this._claim._id) return;
    let _attachments = this.attachments.map(a => a._id);
    if (!_attachments.length) return; // No server-side attachments yet.

    let response = await patchRequest(this.http, 'claims/attachments/order', {
      _claim: this._claim._id,
      _inst: this._claim._inst,
      _attachments
    });

    this.log.show(errorResponse(response) ? response : response.success);
  }

  /** Callback when retrieving new attachments. */
  protected override async refresh(attachments: string[] | ClaimAttachment[] = []) {
    if (arrayOf(attachments, ClaimAttachment.check)) {
      if (this._disputesOnly) {
        //if there is at least one dispute overlapping between the attachment and what we provided, show it.
        //claim level attachments have _disputes as undefined and will not count
        let disputeIds = new Set(this.disputes?.map(dispute => dispute._id));
        attachments = attachments.filter(attachment => arraySome(attachment._disputes, (id) => disputeIds.has(id)));
      }
      super.refresh(attachments.map(attachment => attachment._attachment));
      return;
    }

    super.refresh(attachments);
  }
}
