import { ImportCode } from "../code/system"
import { PropertyInfo } from "../info/prop"
import { TypeInfo } from "../info/type"
import { PropertyType } from "../model/property-type"

/** One of many phone numbers a member can be contacted by. */
export class Phone {
  constructor(
    /** Extension of number. */
    public ext?: number,
    /** Host-provided description of phone. */
    public name?: string,
    /** Phone number. */
    public number = '',
    /** Host-provided code for phone's type. */
    public type = '',
    /** True if this is an imported phone and cannot be modified. */
    public system?: boolean
  ) {}

  static typeinfo: TypeInfo<Phone> = {
    ext: 0,
    name: '',
    system: false
  }

  static propinfo: PropertyInfo<Phone> = {
    type: { type: PropertyType.Code, category: ImportCode.Phone }
  }
}