import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { ErrorResponse } from "../../../../../common/message/error";
import { HasName, MaybeId } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { LogService } from "../../common/service/log.service";
import { debugElementMake } from "../../common/toolbox/element/debug";

/** Base class for setup edit detail pages. */
@Component({ template: '' })
export abstract class SetupEditComponent<T extends HasName, R> {

  /** Change current item being configured. */
  @Input() set resource(resource: MaybeId<T>) {
    this.reset(resource);
  }

  /** Emits when creating new item. */
  @Output() submit = new EventEmitter<MaybeId<T>>();
  /** Emits when clearing current item. */
  @Output() cancel = new EventEmitter<void>();

  /** Current resource being edited. */
  protected abstract value: MaybeId<T>;

  constructor(
    public elementRef: ElementRef,
    protected log: LogService
  ) {
    debugElementMake(this);
  }

  /** Submit current changes to resource. */
  async onSubmit() {
    let response = await this.push();
    if (errorResponse(response)) {
      this.log.show(response);
      return response;
    }

    this.log.show(`Successfully updated: ${this.value.name}`);
    this.submit.emit(this.value);
    return response;
  }
  
  /** Log out current resource, for debugging. */
  onLog() {
    console.log(this.value);
  }

  /** Clear current resource. */
  onCancel() {
    this.cancel.emit();
  }

  onDebug() {
    return { value: this.value };
  }

  /** Submit changes to server. */
  protected abstract push(): Promise<R | ErrorResponse>;
  /** Reset current form with new resource. */
  protected abstract reset(resource?: MaybeId<T>): Promise<void>;
}