import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SearchCategory } from '../../../../../../common/code/standard/common';
import { CommonCode } from '../../../../../../common/code/system';
import { AuthService } from '../../service/auth.service';
import { TabService } from '../../service/tab.service';
import { SearchFieldData } from './search-field.model';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  host: {
    class: 'row fill'
  }
})
export class SearchFieldComponent {
  readonly CommonCode = CommonCode;

  /** Set current search query. */
  @Input() set query(query: string) {
    this.builder.patchValue({ query });
  }

  /** Set current search category. */
  @Input() set category(category: SearchCategory) {
    this.builder.patchValue({ category });
  }

  /** Possible search categories to display. */
  @Input() set categories(categories: SearchCategory[]) {
    this.list = categories;
    let next = categories.find(c => c === this.builder.value.category);
    this.builder.controls.category.patchValue(next ?? categories[0]!);
  }

  /** Data for main form. */
  @Input() builder = new FormBuilder().nonNullable.group({
    query: '',
    category: SearchCategory.AccountNumber
  });

  /** Emits when query changes. */
  @Output() queryChange = new EventEmitter<string>();
  /** Emits when search category changes. */
  @Output() categoryChange = new EventEmitter<SearchCategory>();
  /** Emits when a search is performed. */
  @Output() search = new EventEmitter<SearchFieldData>();

  /** List of available search categories. */
  list: SearchCategory[] = [];

  /** Emits when component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    public tabs: TabService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.builder.controls.query.valueChanges.pipe(takeUntil(this.destroy)).subscribe(this.queryChange);
    this.builder.controls.category.valueChanges.pipe(takeUntil(this.destroy)).subscribe(this.categoryChange);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
