import { propinfoOf } from "../info/prop";
import { schemainfoOf } from "../info/schema";
import { TypeValidator, typeinfoOf, typeinfoValue } from "../info/type";
import { UnionInfo } from "../info/union";
import { PropertyType } from "../model/property-type";
import { SchemaProperty, SchemaType, SchemaTypeProperty } from "../model/schema";
import { AnyValidator } from "../validator/any";
import { ArrayValidator } from "../validator/array";
import { Validator, ValidatorContext } from "../validator/base";
import { BigIntValidator } from "../validator/bigint";
import { BooleanValidator } from "../validator/boolean";
import { DateValidator } from "../validator/date";
import { EnumValidator } from "../validator/enum";
import { IdValidator } from "../validator/id";
import { NumberValidator } from "../validator/number";
import { ObjectValidator } from "../validator/object";
import { OneOfValidator } from "../validator/one-of";
import { PatternValidator } from "../validator/pattern";
import { CurrencyValidator, StringValidator } from "../validator/string";
import { UnionValidator } from "../validator/union";
import { arrayMany, arraySome } from "./array";
import { EMAIL_REGEX } from "./email";
import { OBJECTID_REGEX } from "./id";
import { objectEntries } from "./object";
import { PHONE_REGEX } from "./phone";
import { stringYellow } from "./string";

/** Mapping of schema types to how to create validators. */
const JSON_SCHEMA: { [T in SchemaType]?: (property: SchemaTypeProperty[T]) => Validator } = {
  bool: () => new BooleanValidator(),
  int: prop => prop.enum ? new EnumValidator(prop.enum) : new NumberValidator(prop.minimum, prop.maximum),
  string: prop => prop.enum ? new EnumValidator(prop.enum) : prop.pattern ? new PatternValidator(new RegExp(prop.pattern)) : new StringValidator(prop.maxLength, prop.minLength),
  objectId: () => new IdValidator(),
  object: prop => {
    let validator = new ObjectValidator<any>({});

    for (let [key, subproperty] of objectEntries(prop.properties)) {
      let subvalidator = validatorProperty(subproperty);
      if (prop.optional?.find(k => k === key)) subvalidator.optional = true;
      validator.properties.push([key, subvalidator]);
    }

    return validator;
  },
  array: prop => new ArrayValidator(validatorProperty(prop.items), prop.minItems, prop.maxItems, prop.uniqueItems),
  oneOf: prop => {
    let subvalidators = prop.oneOf.map(oneOf => validatorProperty(oneOf));
    if (arraySome(subvalidators)) return new OneOfValidator(subvalidators[0], ...subvalidators.slice(1));
    return new AnyValidator();
  }
};

/** Create validator from provided value. */
export function validatorValue<T>(value: TypeValidator<T>, context = new ValidatorContext(), parent: any = { value }, key: string = 'value'): Validator<T> {
  return walk(value, context, parent, key) as Validator<T>;
}

/** Create validator from provided schema. */
export function validatorProperty<T>(property: SchemaProperty<T>): Validator {
  if ('oneOf' in property) return JSON_SCHEMA.oneOf!(property as any);

  // Lookup specific handler for this property.
  if (Array.isArray(property.bsonType)) {
    return new AnyValidator();
  } else {
    let handler = JSON_SCHEMA[property.bsonType];
    return handler ? handler(property as any) : new AnyValidator();
  }
}

/** Omit given keys from all values of a union validator. */
export function validatorUnionOmit<T, K extends number | string, O extends keyof T>(unioninfo: UnionInfo<T, K>, ...keys: O[]): UnionValidator<Omit<T, O>, K> {
  let validator = new UnionValidator(unioninfo);

  for (let subvalidator of validator.unions.values()) {
    let properties = subvalidator.properties.filter(([key]) => keys.find(k => k === key));
    for (let property of properties) property[1].optional = true;
  }

  return validator as unknown as UnionValidator<Omit<T, O>, K>;
}

/** Type-unsafe internal version of create. */
function walk(value: any, context: ValidatorContext, parent: any, key: string): Validator {
  // Create property for value.
  let validator: Validator;
  let optional = value === undefined;
  let property = propinfoOf(parent)[key];
  value = typeinfoValue(parent, key, typeinfoOf(parent)) ?? value;

  switch (typeof value) {
    case 'boolean':
      validator = new BooleanValidator();
      break;
    case 'number':
      switch (property?.type) {
        case PropertyType.Currency:
          validator = new CurrencyValidator(property.min, property.max);
          break;
        case PropertyType.Number:
          validator = new NumberValidator(property.min, property.max);
          break;
        default:
          validator = new NumberValidator();
          break;
      } break;
    case 'bigint':
      validator = new BigIntValidator();
      break;
    case 'string':
      switch (property?.type) {
        case PropertyType.Code:
          let values = context.enums.get(property.category!);
          if (values) {
            // List of values available;
            validator = new EnumValidator(values);
            break;
          } else {
            // Missing this list in context argument.
            if (context.enums.size) warnEnum(parent, key);
            validator = new StringValidator();
          } break;
        case PropertyType.Email:
          validator = new PatternValidator(EMAIL_REGEX);
          break;
        case PropertyType.Phone:
          validator = new PatternValidator(PHONE_REGEX);
          break;
        case PropertyType.String:
          validator = new StringValidator(property.maxLength, property.minLength);
          break;
        default:
          if (OBJECTID_REGEX.test(value)) {
            validator = new IdValidator();
          } else if (EMAIL_REGEX.test(value)) {
            validator = new PatternValidator(EMAIL_REGEX);
          } else if (PHONE_REGEX.test(value)) {
            validator = new PatternValidator(PHONE_REGEX);
          } else {
            validator = new StringValidator();
          }  break;
      } break;
    default:
      if (value instanceof Validator) {
        validator = value;
      } else if (value instanceof Date) {
        validator = new DateValidator()
      } else if (Array.isArray(value)) {
        // Create array validator.
        if (arraySome(value)) {
          validator = new ArrayValidator(value[0], arrayMany(value) ? 1 : undefined, undefined, true, context);
        } else {
          warnType(parent, key);
          validator = new AnyValidator();
        }
      } else if (value instanceof Object) {
        // Create object validator.
        validator = new ObjectValidator(value);
      } else {
        // No automatic or manual typing (property undefined)
        warnType(parent, key);
        validator = new AnyValidator();
      }
  }

  validator.optional = optional;
  validator.property = schemainfoOf(parent)[key];
  return validator;
}

/** Log type warning for missing enumeration context. */
function warnEnum(parent?: any, key?: string) {
  stringYellow(`${parent?.constructor.name ?? 'unknown'}.${key ?? 'unknown'} had unknown enum. Pass enum context for validation.`);
}

/** Log type warning for missing type information. */
function warnType(parent?: any, key?: string) {
  stringYellow(`${parent?.constructor.name ?? 'unknown'}.${key ?? 'unknown'} had unknown type. Add typeinfo for validation.`);
}