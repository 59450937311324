
/** A circular buffer of values that automatically discards old values. */
export class RingBuffer<T> implements Iterable<T> {
  /** Number of stored items. */
  length = 0;
  
  /** Internal buffer of items. */
  private items: T[];
  /** Start position of items. */
  private start = 0;

  constructor(
    /** Current number of items. */
    readonly capacity = 10
  ) {
    this.items = Array.from({ length: capacity }, () => undefined as any);
  }

  /** Get item at index. */
  at(i: number): T | undefined {
    if (i >= this.length) return undefined;
    return this.items[this.index(i)];
  }

  /** Push new item to buffer. */
  push(item: T) {
    if (this.length === this.capacity) this.start = (this.start + 1) % this.capacity;
    else this.length++;
    this.items[this.index(this.length - 1)] = item;
  }

  /** Remove item at index. */
  remove(i: number) {
    if (i >= this.length) return;
    for (let n = i; n < this.length - 1; ++n) {
      this.items[this.index(n)] = this.items[this.index(n + 1)]!;
    }

    this.length--;
  }

  /** Clear buffer. */
  clear() {
    this.length = 0;
  }

  /** Get real index in ringbuffer. */
  private index(i: number) {
    return (this.start + i) % this.capacity;
  }

  [Symbol.iterator](): Iterator<T> { return new RingIterator(this); }
}

/** Iterator over a ring buffer. */
class RingIterator<T> implements Iterator<T> {
  /** Current iteration position. */
  private i = 0;

  constructor(
    /** Buffer being iterated over. */
    private buffer: RingBuffer<T>
  ) {}

  next() {
    if (this.i === this.buffer.length) return {
      done: true,
      value: undefined as any
    }

    return {
      done: false,
      value: this.buffer.at(this.i++)!
    };
  }
}