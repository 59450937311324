import { StandardEntryClass } from "../../../code/standard/disputes";
import { ImportCode } from "../../../code/system";
import { ConditionType } from "../../formula/condition";
import { OperatorType } from "../../formula/operator";
import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { BuiltinFormula } from "../formula";

/** Formulas that operate on transactions. */
export enum TransactionFormula {
  Amount       = 'Transaction - Amount',
  IntakeSelect = 'Transaction - Intake Select'
}

export const BUILTIN_TRANSACTION_FORMULAS: Record<TransactionFormula, BuiltinFormula> = {
  [TransactionFormula.Amount]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Identifier,
        key: 'transaction.amount'
      }
    }]
  },
  [TransactionFormula.IntakeSelect]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'transaction.secCode'
          },
          right: {
            type: TerminalType.Code,
            category: ImportCode.StandardEntryClass,
            value: StandardEntryClass.CorporateCreditOrDebit
          }
        },
        right: {
          type: ConditionType.Greater,
          left: {
            type: OperatorType.Subtract,
            left: {
              type: TerminalType.Date,
              value: 0
            },
            right: {
              type: TerminalType.Identifier,
              key: 'transaction.postDate'
            }
          },
          right: {
            type: TerminalType.Number,
            value: 2
          }
        }
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: 'ACH disputes cannot be opened for CCD transactions older than 2 days.'
        }
      }],
      else: [{
        type: StatementType.If,
        if: {
          type: ConditionType.And,
          left: {
            type: ConditionType.Equal,
            left: {
              type: TerminalType.Identifier,
              key: 'dispute.transaction.secCode'
            },
            right: {
              type: TerminalType.Code,
              category: ImportCode.StandardEntryClass,
              value: StandardEntryClass.PrearrangedPaymentOrDeposit
            }
          },
          right: {
            type: ConditionType.Greater,
            left: {
              type: OperatorType.Subtract,
              left: {
                type: TerminalType.Date,
                value: 0
              },
              right: {
                type: TerminalType.Identifier,
                key: 'transaction.postDate'
              }
            },
            right: {
              type: TerminalType.Number,
              value: 60
            }
          }
        },
        then: [{
          type: StatementType.Return,
          expression: {
            type: TerminalType.String,
            value: 'ACH disputes cannot be opened for PPD transactions older than 60 days.'
          }
        }],
        else: [{
          type: StatementType.Return,
          expression: {
            type: TerminalType.String,
            value: ''
          }
        }]
      }]
    }]
  }
}