<app-banner *ngIf="!(claim._id | idNull) && !(claim._assigned | idNull) && (claim._assigned !== auth.session._id) && !(Permission.ClaimsManage | permission)" [color]="'warning' | statusColor">
  <div>This claim is assigned to another user. For reassignment, please contact a supervisor.</div>
</app-banner>
<app-banner *ngIf="claim.deleted" [color]="'alert' | statusColor">
  <ng-container *ngIf="Permission.ClaimsDelete | permission; else supervisor">
    <div>This claim has been abandoned.</div>
  </ng-container>
  <ng-template #supervisor>
    <div>This claim has been abandoned. If this was done in error, please contact a supervisor.</div>
  </ng-template>
</app-banner>
<app-banner *ngIf="error" [color]="'alert' | statusColor">{{error}}</app-banner>
<app-claim-toolbar [claim]="claim" (changed)="claimDirty = true" (refreshed)="refetch()" (undo)="undo()" [undoIndex]="undoIndex"></app-claim-toolbar>
<div [class.deleted]="claim.deleted" class="column">
  <app-workflow *ngIf="(Permission.ClaimsEdit | permission)" [workflow]="workflow" [mode]="TaskMode.Claim" [context]="context" [loading]="loading" (task)="onTask($event)"></app-workflow>
  <div class="row">
    <app-claim-info class="flex fill" [claim]="claim"></app-claim-info>
    <app-claim-events class="fill" [claim]="claim" (task)="onTask($event)"></app-claim-events>
  </div>
  <app-claim-disputes *ngIf="(Permission.ClaimsEdit | permission) && claim.type !== ClaimType.CreditBureau" [claim]="claim" [attachments]="attachments" (changed)="claimDirty = true" (task)="onTask($event[0], $event[1])" (error)="error = $event"></app-claim-disputes>
  <app-card *ngIf="Permission.AttachmentsEdit | permission">
    <app-card-header>Claim Attachments</app-card-header>
    <app-card-body>
      <app-claim-attachment-list [claim]="claim" [disputes]="claim.type === ClaimType.CreditBureau ? [] : claim.disputes" (attached)="onAttachments($event)"></app-claim-attachment-list>
    </app-card-body>
  </app-card>
  <app-save *ngIf="claimDirty" (save)="onSave()"></app-save>
</div>