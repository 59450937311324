import { ClaimACHReportReason, ClaimCardLocation, ClaimDetailsType, ClaimReportReason, ClaimType } from "../../code/standard/disputes"
import { DisputesCode } from "../../code/system"
import { PropertyInfo } from "../../info/prop"
import { TypeInfo } from "../../info/type"
import { UnionInfo } from "../../info/union"
import { ID_DEFAULT } from "../../toolbox/id"
import { NestedKey } from "../../toolbox/keys"
import { EnumValidator } from "../../validator/enum"
import { UnionValidator } from "../../validator/union"
import { Account } from "../account/account"
import { AccountLoan } from "../account/loan"
import { Card } from "../card"
import { DisputeJoinACH, DisputeJoinCard, DisputeJoinCheck, DisputeUnion } from "../dispute/dispute"
import { Member } from "../member"
import { PropertyType } from "../property-type"
import { CLAIM_ACH_DETAILS_UNIONINFO, CLAIM_ACH_REPORT_REASON_FILTER, ClaimACHDetails, ClaimACHDetailsBase } from "./ach"
import { ClaimBase, ClaimLookup } from "./base"
import { CLAIM_CARD_DETAILS_UNIONINFO, ClaimCardDetails, ClaimCardDetailsATMTransaction } from "./card"
import { CLAIM_CHECK_DETAILS_UNIONINFO, CLAIM_CHECK_REPORT_REASON_FILTER, ClaimCheckDetails, ClaimCheckDetailsForgery } from "./check"
import { NoType } from "./type"

/** Tagged type of claim. */
export type Claim = ClaimACH | ClaimCard | ClaimCheck | ClaimCreditBureau;
/** Tagged type of joined claim. */
export type ClaimJoin = ClaimJoinACH | ClaimJoinCard | ClaimJoinCheck | ClaimJoinCreditBureau;
/** Union of all possible claim fields. */
export type ClaimUnion = { type: ClaimType, disputes: DisputeUnion[] } & ClaimLookup & NoType<ClaimACH> &  NoType<ClaimCard> & NoType<ClaimCheck> & NoType<ClaimCreditBureau>;

/** Types of claims that can have disputes. */
export type ClaimDisputesType = ClaimType.ACH | ClaimType.Card | ClaimType.Check;
/** A claim that can have disputes. */
export type ClaimDisputes = ClaimACH | ClaimCard | ClaimCheck;
/** A joined claim that can have disputes. */
export type ClaimDisputesJoin = ClaimJoinACH | ClaimJoinCard | ClaimJoinCheck;

/** Details specific to ACH claims. */
export class ClaimACH extends ClaimBase {
  readonly type = ClaimType.ACH;

  constructor(
    /** List of disputes associated with this claim. */
    public _disputes: string[] = [],
    /** Person or company that originated the ACH. */
    public originator = '',
    /** Details about opened ACH claim. */
    public achDetails: ClaimACHDetails = new ClaimACHDetailsBase(ClaimACHReportReason.InsufficientFunds),
    /** Date the return was issued. */
    public returnDate?: Date
  ) {
    super();
  }

  static override typeinfo: TypeInfo<ClaimACH> = {
    ...ClaimBase.typeinfo,
    _disputes: [ID_DEFAULT],
    returnDate: new Date(),
    achDetails: new UnionValidator(CLAIM_ACH_DETAILS_UNIONINFO, true)
  }

  static override propinfo: PropertyInfo<ClaimACH> = {
    ...ClaimBase.propinfo,
    achDetails: { name: 'ACH Details' },
    type: { type: PropertyType.Code, category: DisputesCode.ClaimType }
  }
}

/** ACH claims with attached disputes. */
export class ClaimJoinACH extends ClaimACH implements ClaimLookup {

  constructor(
    /** List of disputes. */
    public disputes: DisputeJoinACH[] = [],
    /** Account of this claim. */
    public account: Account = new AccountLoan(),
    /** Member of this claim. */
    public member = new Member(),
    /** Card of this claim, if applicable */
    public card = new Card()
  ) {
    super();
  }

  static override typeinfo: TypeInfo<ClaimJoinACH> = {
    ...ClaimACH.typeinfo,
    disputes: [new DisputeJoinACH()]
  }
}

/** Details specific to card claims. */
export class ClaimCard extends ClaimBase {
  readonly type = ClaimType.Card;

  constructor(
    /** List of disputes associated with this claim. */
    public _disputes: string[] = [],
    /** Status of card, if stolen. */
    public cardLocation = ClaimCardLocation.Possessed,
    /** Detailed information about this particular claim. */
    public cardDetails: ClaimCardDetails = new ClaimCardDetailsATMTransaction(),
    /** True if card was closed. */
    public cardClosed?: boolean,
    /** Reason card was closed. */
    public cardCloseReason?: string,
    /** Date of last statement among disputed transactions. */
    public lastStatementDate?: Date
  ) {
    super();
  }

  static override typeinfo: TypeInfo<ClaimCard> = {
    ...ClaimBase.typeinfo,
    _disputes: [ID_DEFAULT],
    cardLocation: new EnumValidator(ClaimCardLocation),
    cardDetails: new UnionValidator(CLAIM_CARD_DETAILS_UNIONINFO),
    cardClosed: false,
    cardCloseReason: '',
    lastStatementDate: new Date()
  }

  static override propinfo: PropertyInfo<ClaimCard> = {
    ...ClaimBase.propinfo,
    cardLocation: { type: PropertyType.Code, category: DisputesCode.ClaimCardLocation },
    type: { type: PropertyType.Code, category: DisputesCode.ClaimType }
  }
}

/** Card claims with attached disputes. */
export class ClaimJoinCard extends ClaimCard implements ClaimLookup {

  constructor(
    /** List of disputes. */
    public disputes: DisputeJoinCard[] = [],
    /** Account of this claim. */
    public account: Account = new AccountLoan(),
    /** Member of this claim. */
    public member = new Member(),
    /** Card of this claim, if applicable */
    public card = new Card()
  ) {
    super();
  }

  static override typeinfo: TypeInfo<ClaimJoinCard> = {
    ...ClaimCard.typeinfo,
    disputes: [new DisputeJoinCard()]
  }
}

/** Details specific to check claims. */
export class ClaimCheck extends ClaimBase {
  readonly type = ClaimType.Check;

  constructor(
    /** List of disputes associated with this claim. */
    public _disputes: string[] = [],
    /** Detailed information about this particular claim. */
    public checkDetails: ClaimCheckDetails = new ClaimCheckDetailsForgery(),
    /** The name of the person who originated the check. */
    public originator = '',
    /** Date the return was issued. */
    public returnDate?: Date
  ) {
    super();
  }

  static override typeinfo: TypeInfo<ClaimCheck> = {
    ...ClaimBase.typeinfo,
    _disputes: [ID_DEFAULT],
    checkDetails: new UnionValidator(CLAIM_CHECK_DETAILS_UNIONINFO),
    returnDate: new Date()
  }

  static override propinfo: PropertyInfo<ClaimCheck> = {
    ...ClaimBase.propinfo,
    type: { type: PropertyType.Code, category: DisputesCode.ClaimType }
  }
}

/** Check claims with attached disputes. */
export class ClaimJoinCheck extends ClaimCheck implements ClaimLookup {

  constructor(
    /** List of disputes. */
    public disputes: DisputeJoinCheck[] = [],
    /** Account of this claim. */
    public account: Account = new AccountLoan(),
    /** Member of this claim. */
    public member = new Member(),
    /** Card of this claim, if applicable */
    public card = new Card()
  ) {
    super();
  }

  static override typeinfo: TypeInfo<ClaimJoinCheck> = {
    ...ClaimCheck.typeinfo,
    disputes: [new DisputeJoinCheck()]
  }
}

/** Details specific to check claims. */
export class ClaimCreditBureau extends ClaimBase {
  readonly type = ClaimType.CreditBureau;

  static override propinfo: PropertyInfo<ClaimCreditBureau> = {
    ...ClaimBase.propinfo,
    type: { type: PropertyType.Code, category: DisputesCode.ClaimType }
  }
}

/** Credit bureau claims with attached disputes. */
export class ClaimJoinCreditBureau extends ClaimCreditBureau implements ClaimLookup {
  constructor(
    /** Account of this claim. */
    public account: Account = new AccountLoan(),
    /** Member of this claim. */
    public member = new Member()
  ) {
    super();
  }
}

/** Mapping of claim types to classes. */
export class ClaimClass {
  [ClaimType.ACH] = new ClaimACH();
  [ClaimType.Card] = new ClaimCard();
  [ClaimType.Check] = new ClaimCheck();
  [ClaimType.CreditBureau] = new ClaimCreditBureau();
}

/** Mapping of claim types to join classes. */
export class ClaimJoinClass {
  [ClaimType.ACH] = new ClaimJoinACH();
  [ClaimType.Card] = new ClaimJoinCard();
  [ClaimType.Check] = new ClaimJoinCheck();
  [ClaimType.CreditBureau] = new ClaimJoinCreditBureau();
}

/** Type information for each claim type. */
export const CLAIM_UNIONINFO: UnionInfo<Claim, ClaimType> = {
  tag: 'type',
  classes: new ClaimClass()
};

/** Type information for each joined claim dispute type. */
export const CLAIM_JOIN_UNIONINFO: UnionInfo<Claim, ClaimType> = {
  tag: 'type',
  classes: new ClaimJoinClass()
};

/** Mapping of claim types to claim details filter fields. */
export const CLAIM_TYPE_DETAILS_KEY: { [T in ClaimType]: NestedKey<ClaimClass[T]> | undefined } = {
  [ClaimType.ACH]: 'achDetails.type',
  [ClaimType.Card]: 'cardDetails.type',
  [ClaimType.Check]: 'checkDetails.type',
  [ClaimType.CreditBureau]: undefined
};

/** Mapping of claim types to claim reason filters. */
export const CLAIM_TYPE_DETAILS_FILTER: { [T in ClaimType]: ClaimDetailsType[] | ClaimReportReason[] | undefined } = {
  [ClaimType.ACH]: CLAIM_ACH_REPORT_REASON_FILTER,
  [ClaimType.Card]: undefined,
  [ClaimType.Check]: CLAIM_CHECK_REPORT_REASON_FILTER,
  [ClaimType.CreditBureau]: undefined
};