import { UnionInfo } from "../info/union";

/** Configuration for showing a panel on account screen. */
export type AccountPanel      =
  | AccountPanelAttachments
  | AccountPanelClaims        
  | AccountPanelHistory       
  | AccountPanelTransactions;

/** Panels to be displayed on account page. */
export enum AccountPanelType {
  /** List of claims attached to account. */
  Claims = 'claims',
  /** Transactions list. */
  Transactions = 'transactions',
  /** History list. */
  History = 'history',
  /** Attachments list. */
  Attachments = 'attachments'
}

/** Tagged type of all features. */
export interface AccountPanelTag<A extends AccountPanelType> {
  /** Panel to display. */
  type: A
}

/** Configuration for account claims panel. */
export class AccountPanelClaims implements AccountPanelTag<AccountPanelType.Claims> {
  readonly type = AccountPanelType.Claims;
}

/** Configuration for account transactions panel. */
export class AccountPanelTransactions implements AccountPanelTag<AccountPanelType.Transactions> {
  readonly type = AccountPanelType.Transactions;
}

/** Configuration for account transactions panel. */
export class AccountPanelHistory implements AccountPanelTag<AccountPanelType.History> {
  readonly type = AccountPanelType.History;
}

/** Configuration for account transactions panel. */
export class AccountPanelAttachments implements AccountPanelTag<AccountPanelType.Attachments> {
  readonly type = AccountPanelType.Attachments;
}

/** Mapping of account panel types to classes. */
export class AccountPanelClass {
  [AccountPanelType.Claims] = new AccountPanelClaims();
  [AccountPanelType.Transactions] = new AccountPanelTransactions();
  [AccountPanelType.History] = new AccountPanelHistory();
  [AccountPanelType.Attachments] = new AccountPanelAttachments();
}

/** Pull specified account panel configuration from list. */
export function accountPanelFind<A extends AccountPanelType>(type: A, panels?: AccountPanel[]): AccountPanelClass[A] | undefined {
  return panels?.find(p => p.type === type) as any;
}

/** Type information about account panels. */
export const ACCOUNT_PANEL_UNIONINFO: UnionInfo<AccountPanel, AccountPanelType> = {
  tag: 'type',
  classes: new AccountPanelClass()
}