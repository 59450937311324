import { Component } from '@angular/core';
import { Code } from '../../../../../../common/model/code-type';
import { ColumnSize } from '../../../../../../common/model/table';
import { NestedKey } from '../../../../../../common/toolbox/keys';
import { ClientSource } from '../../toolbox/source/client';
import { FormMultiAccessor } from '../form/form-multi-accessor';

@Component({
  selector: 'app-code-grid',
  templateUrl: './code-grid.component.html',
  styleUrls: ['./code-grid.component.scss'],
  host: {
    class: 'fill'
  }
})
export class CodeGridComponent extends FormMultiAccessor<Code> {

  /** List of items to display. */
  set items(codes: Code[]) { this.source.items = codes; this.writeValue(this.value); }
  get items() { return this.source.items; }

  key: NestedKey<Code> = 'key';
  display = (value: Code) => value.value;

  /** List of columns in table. */
  names = ['value'];
  /** List of displayed codes. */
  source = new ClientSource<Code>([]);
  /** Size for columns. */
  sizes: ColumnSize[] = ['1fr'];

  override update(codes: Code[]) {
    if (super.update(codes)) {
      this.source.selection.next(new Set(codes));
      return true;
    } return false;
  }
}
