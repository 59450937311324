import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { WorkCondition, WorkConditionType } from '../../../../../../../common/model/work/condition';
import { Newable } from '../../../../../../../common/toolbox/object';
import { SetupWorkflowConditionAttachmentComponent } from './attachment/setup-workflow-condition-attachment.component';
import { SetupWorkflowConditionClaimStatusComponent } from './claim-stage/setup-workflow-condition-claim-status.component';
import { SetupWorkflowConditionDisputeStatusComponent } from './dispute-stage/setup-workflow-condition-dispute-status.component';
import { SetupWorkflowConditionFormulaComponent } from './formula/setup-workflow-condition-formula.component';
import { WorkConditionAccessor } from './setup-workflow-condition.model';

/** Component for configuring each workflow condition type. */
export const WORKFLOW_CONDITION_SETUP_COMPONENT = {
  [WorkConditionType.Attachment]: SetupWorkflowConditionAttachmentComponent,
  [WorkConditionType.ClaimStatus]: SetupWorkflowConditionClaimStatusComponent,
  [WorkConditionType.DisputeStatus]: SetupWorkflowConditionDisputeStatusComponent,
  [WorkConditionType.Formula]: SetupWorkflowConditionFormulaComponent
};

@Component({
  selector: 'app-setup-workflow-condition',
  templateUrl: './setup-workflow-condition.component.html',
  styleUrls: ['./setup-workflow-condition.component.scss']
})
export class SetupWorkflowConditionComponent {
  /** Container to inject components. */
  @ViewChild('container', { static : true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** Current condition being configured. */
  @Input() set condition(condition: WorkCondition | undefined) {
    this._condition = condition;
    this.component = undefined;
    this.containerRef.clear();
    if (!condition) return;

    let prototype: Newable<WorkConditionAccessor> = WORKFLOW_CONDITION_SETUP_COMPONENT[condition.type];
    this.component = this.containerRef.createComponent(prototype).instance;
    this.component.condition = condition;
    this.component.reset();
  }

  /** Condition being edited. */
  protected _condition?: WorkCondition;
  /** Component injected into template. */
  private component?: WorkConditionAccessor;

  /** Update component configuring current condition. */
  reset(condition: WorkCondition | undefined) {
    this._condition = condition;
    this.component = undefined;
    this.containerRef.clear();
    if (!condition) return;

    let prototype: Newable<WorkConditionAccessor> = WORKFLOW_CONDITION_SETUP_COMPONENT[condition.type];
    this.component = this.containerRef.createComponent(prototype).instance;
    this.component.condition = condition;
    this.component.reset();
  }
}
