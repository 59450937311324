import { arrayDefined } from "../../toolbox/array";
import { Step, StepStatus } from "../step";
import { WorkCondition } from "./condition";
import { WorkAction, WorkStep } from "./flow";

/** Completion of a work condition. */
export class WorkConditionResult {

  /** Check if condition is completed. */
  get complete() { return this.value >= this.max; }

  constructor(
    /** Amount of completed work for condition. */
    public value = 0,
    /** Total amount of work needed for this condition. */
    public max = 1,
    /** Additional statuses triggered by condition. */
    public status?: StepStatus
  ) {}
}

/** A work condition with attached completion. */
export class WorkConditionStatus extends WorkConditionResult {
  constructor(
    /** Configuration for this condition. */
    public config: WorkCondition,
    /** Amount of completed work for condition. */
    value = 0,
    /** Total amount of work needed for this condition. */
    max = 1,
    /** Additional statuses triggered by condition. */
    status?: StepStatus
  ) {
    super(value, max, status);
  }
}

/** A work requirement to display. */
export class WorkActionStatus {
  constructor(
    /** The name of this action */
    public name: string = '',
    /** The description of this action */
    public description: string = '',
    /** List of available tasks on requirement. */
    public _tasks?: string[],
    /** Amount of completed work for requirement. */
    public value = 0,
    /** Total amount of work needed for requirement. */
    public max = 1,
    /** Additional statuses triggered by condition. */
    public status?: StepStatus,
    /** Action used to generate this status. */
    public action = new WorkAction()
  ) {}
}

/** A work step with attached completion. */
export class WorkStepStatus {
  constructor(
    /** Name of this step. */
    public name = '',
    /** List of actions in this step. */
    public actions: WorkActionStatus[] = [],
    /** Step used to generate this status. */
    public step = new WorkStep()
  ) {}
}

/** Current status of workflow. */
export class WorkflowStatus {
  constructor(
    /** List of available steps. */
    public steps: WorkStepStatus[] = [],
    /** Current step of workflow. */
    public step = new WorkStepStatus(),
    /** Index of current step in workflow. */
    public current = 0,
    /** List of globally determined workflow actions. */
    public actions: WorkActionStatus[] = []
  ) {}

  /** Convert to a list of steps. */
  static steps(status: WorkflowStatus) {
    return status.steps.map(step => {
      let statuses = arrayDefined(step.actions.flatMap(requirement => requirement.status));
      return new Step(step, false, statuses.length ? Math.max(...statuses) : undefined);
    });
  }
}