import { Component } from '@angular/core';
import { EVENT_CATEGORY_NAME, EventCategory } from '../../../../../../../../../common/model/event/category';
import { EVENT_RESULT_NAME, EventResultType } from '../../../../../../../../../common/model/event/result';
import { JobType } from '../../../../../../../../../common/model/job/payload';
import { EnumMap, enumMapPairs } from '../../../../../../../../../common/toolbox/enum';
import { Pair } from '../../../../../../../../../common/toolbox/object';
import { JobAccessor } from '../../../edit/setup-job-edit.model';

@Component({
  selector: 'app-setup-job-payload-notification-event',
  templateUrl: './setup-job-payload-notification-event.component.html',
  styleUrls: ['./setup-job-payload-notification-event.component.scss']
})
export class SetupJobPayloadNotificationEventComponent extends JobAccessor<JobType.NotificationEvent> {
  /** List of selectable categories. */
  categories = enumMapPairs(EVENT_CATEGORY_NAME);
  /** List of selectable result types. */
  types: Pair<EventResultType>[] = [];

  ngOnInit() {
    if (this.claim) this.categories = enumMapPairs(EVENT_CATEGORY_NAME).filter(pair => pair.value === EventCategory.Claim);
    this.onCategory();
  }

  /** Reset list of selectable types. */
  onCategory() {
    this.types = enumMapPairs(EVENT_RESULT_NAME[this.job.payload.category] as EnumMap<EventResultType>);
  }
}
