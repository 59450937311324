import { numberClamp } from "../toolbox/number";
import { Box } from "./box";
import { ColumnSize } from "./table";

/** Regex for matching grid template. */
const GRID_TEMPLATE_REGEX = /^repeat\((\d+).+repeat\((\d+).+$/;

/** A coordinate pair. */
export class Pos {
  constructor(
    /** X coordinate. */
    public x = 0,
    /** Y coordinate. */
    public y = 0
  ) {}

  /** Create a position from existing object. */
  static from(pos: Pos) {
    return new Pos(pos.x, pos.y);
  }

  /** Copy other position's values into this one. */
  static move(to: Pos, from: Pos) {
    to.x = from.x;
    to.y = from.y;
    return to;
  }

  /** Clamp position within range. */
  static clamp(pos: Pos, range: Box) {
    pos.x = numberClamp(pos.x, range.l, range.r);
    pos.y = numberClamp(pos.y, range.t, range.b);
    return pos;
  }

  /** Check if pos is inside a box. */
  static inside(pos: Pos, box: Box) {
    return pos.x >= box.l && pos.y >= box.t && pos.x <= box.r && pos.y <= box.b;
  }

  /** Add a position and return new value. */
  static add(a: Pos, b: Pos) {
    return new Pos(a.x + b.x, a.y + b.y);
  }

  /** Subtract a position and return new value. */
  static sub(a: Pos, b: Pos) {
    return new Pos(a.x - b.x, a.y - b.y);
  }

  /** Generate an array of positions for given size. */
  static array(max: Pos, offset = new Pos()) {
    let area: Pos[] = [];
    for (let y = 0; y < max.y; ++y) {
      for (let x = 0; x < max.x; ++x) {
        area.push(new Pos(x + offset.x, y + offset.y));
      }
    }

    return area;
  }

  /** Format pos for grid-template CSS property. */
  static css(pos: Pos, width: ColumnSize = '1fr', height: ColumnSize = '4rem') {
    return { 'grid-template': `repeat(${pos.y}, ${height}) / repeat(${pos.x}, ${width})` };
  }

  /** Get available boundaries of element. */
  static size(e: HTMLElement) {
    let match = e.style.gridTemplate.match(GRID_TEMPLATE_REGEX);
    return match ? new Pos(+match[2]!, +match[1]!) : new Pos();
  }

  /** Get size of cells in grid template area. */
  static cell(element: Element) {
    let style = getComputedStyle(element);
    return new Pos(
      +(style.gridTemplateColumns.match(/([0-9.]+)/) ?? [0] as const)[0],
      +(style.gridTemplateRows.match(/([0-9.]+)/) ?? [0] as const)[0]
    );
  }

  /** Get gap between cells in grid template area. */
  static gap(element: Element) {
    let style = getComputedStyle(element);
    return new Pos(
      +(style.columnGap.match(/([0-9.]+)/) ?? [0] as const)[0],
      +(style.rowGap.match(/([0-9.]+)/) ?? [0] as const)[0]
    );
  }
}