<app-setup-job-payload-notification *ngIf="!personal" [job]="job" (selected)="dirty.emit()"></app-setup-job-payload-notification>
<div class="row">
  <app-field class="fill" *ngIf="!claim">
    <app-label>Claim</app-label>
    <app-select empty="All Claims" placeholder="Claim Number" [(ngModel)]="job.payload._claim" [autofilter]="false" [required]="false" (query)="onQuery($event)" (selected)="dirty.emit()">
      <app-option *ngFor="let claim of claims" [view]="claim.displayId" [value]="claim._id"></app-option>
    </app-select>
  </app-field>
  <app-field class="fill">
    <app-label>Date</app-label>
    <app-select [(ngModel)]="job.payload.date" (selected)="dirty.emit()">
      <app-option *ngFor="let p of properties" [view]="p.name" [value]="p.key"></app-option>
    </app-select>
  </app-field>
  <app-field class="fill">
    <app-label>Days</app-label>
    <app-sublabel>
      <app-hover-help>Days before this date to send a notification.</app-hover-help>
    </app-sublabel>
    <app-number [(ngModel)]="job.payload.days" (input)="dirty.emit()"></app-number>
  </app-field>
</div>