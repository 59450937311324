import { DocumentTemplateType } from "../../code/standard/common";
import { CommonCode } from "../../code/system";
import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { ErrorResponse } from "../../message/error";
import { BufferLike, BufferLikeValidator, binaryText, btoaUnicode } from "../../toolbox/binary";
import { documentTemplateScan } from "../../toolbox/document-template";
import { FILENAME_REGEX } from "../../toolbox/file";
import { ID_DEFAULT, MaybeId } from "../../toolbox/id";
import { NestedKey } from "../../toolbox/keys";
import { errorResponse } from "../../toolbox/message";
import { pathInstData, pathJoin } from "../../toolbox/path";
import { XmlParser } from "../../toolbox/xml";
import { Unzip } from "../../toolbox/zip";
import { EnumValidator } from "../../validator/enum";
import { PatternValidator } from "../../validator/pattern";
import { AttachmentUpload } from "../attachment";
import { BUILTIN_DOCUMENT_TEMPLATES, SystemDocumentTemplate } from "../builtin/document-template";
import { CODE_CATEGORY_REGEX } from "../code-type";
import { FusionCollection } from "../fusion";
import { PropertyType } from "../property-type";
import { DocumentTemplateClass } from "./data";

/** Information associated with an institution-configured document template. */
export class DocumentTemplate<T extends DocumentTemplateType = DocumentTemplateType> {
  constructor(
    /** Unique identifier of document template. */
    public _id = ID_DEFAULT,
    /** Institution configuring document template. */
    public _inst = ID_DEFAULT,
    /** Name of document template. */
    public name = '',
    /** Context this document template is used in. */
    public type = DocumentTemplateType.General,
    /** Short description of document template. */
    public description?: string,
    /** Foreign key to this document template's filter rule. */
    public _rule?: string,
    /** List of attachments to make available when rendering. */
    public _attachments?: string[],

    /** True if this is a system document template and cannot be deleted. */
    public system?: boolean,
    /** True document template has been modified. */
    public dirty?: boolean,
    /** List of calculated identifiers used within this document template. */
    public identifiers?: NestedKey<DocumentTemplateClass[T]>[],
    /** List of calculated code categories used within this document template. */
    public codes?: string[]
  ) { }

  static typeinfo: TypeInfo<DocumentTemplate> = {
    name: new PatternValidator(FILENAME_REGEX),
    type: new EnumValidator(DocumentTemplateType),
    system: false,
    dirty: false,
    description: '',
    _attachments: [ID_DEFAULT],
    _rule: ID_DEFAULT,
    identifiers: ['account'],
    codes: [new PatternValidator(CODE_CATEGORY_REGEX)]
  };

  static propinfo: PropertyInfo<DocumentTemplate> = {
    type: { type: PropertyType.Code, category: CommonCode.DocumentTemplate }
  };

  static indexinfo: IndexInfo<DocumentTemplate> = [
    { key: { _inst: 1, type: 1 } },
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  /** Create from an uploaded document template. */
  static async from(upload: DocumentTemplateBundle, unzip: Unzip, parser: XmlParser, codes: string[], system?: boolean, override?: boolean): Promise<MaybeId<DocumentTemplate> | ErrorResponse> {
    let scan = await documentTemplateScan(unzip, parser, upload.name, upload.type, upload.data, codes);
    if (errorResponse(scan)) return scan;
    if (!scan.success && !override) return scan.error(upload.name);

    return {
      _id: upload._id,
      _inst: upload._inst,
      name: upload.name,
      type: upload.type,
      description: upload.description,
      system,
      _attachments: [],
      _rule: upload._rule,
      identifiers: scan.identifiers.valid.size ? [...scan.identifiers.valid as any] : undefined,
      codes: scan.codes.valid.size ? [...scan.codes.valid] : undefined
    };
  }

  /** Get path to given document template folder. */
  static path(root: string, _inst: string) {
    return pathInstData(root, _inst, 'template');
  }

  /** Get path to given document template. */
  static fullpath(root: string, _inst: string, name: string) {
    return pathJoin(DocumentTemplate.path(root, _inst), name);
  }

  /** Add data field to a document template. */
  static file(template: MaybeId<DocumentTemplate>): MaybeId<DocumentTemplateFile> {
    let file = template as MaybeId<DocumentTemplateFile>;
    file.data = file.data ?? '';
    return file;
  }
}

/** Fetched document template with attached file data. */
export class DocumentTemplateFile<T extends DocumentTemplateType = DocumentTemplateType> extends DocumentTemplate<T> {
  constructor(
    /** Data of template. */
    public data: BufferLike = ''
  ) {
    super();
  }

  static override typeinfo: TypeInfo<DocumentTemplateFile> = {
    ...DocumentTemplate.typeinfo,
    data: new BufferLikeValidator()
  }
}

/** Short preview of document template displayed in list. */
export class DocumentTemplatePreview {
  constructor(
    /** Unique identifier of document template. */
    public _id = ID_DEFAULT,
    /** Name of document template. */
    public name = '',
    /** Short description of document template. */
    public description?: string,
    /** True if this is a system document template and cannot be deleted. */
    public system?: boolean,
    /** True document template has been modified. */
    public dirty?: boolean,
  ) {}

  static typeinfo: TypeInfo<DocumentTemplatePreview> = {
    system: false,
    dirty: false,
  }
}

/** A new document template bundled with uploads. */
export class DocumentTemplateBundle {
  constructor(
    /** Id of document template, if updating */
    public _id?: string,
    /** Institution owning document template. */
    public _inst = ID_DEFAULT,
    /** Name of document template. */
    public name = '',
    /** Context this document template is used in. */
    public type = DocumentTemplateType.General,
    /** Description of document template. */
    public description?: string,
    /** Document template encoded as base64. */
    public data: BufferLike = '',
    /** foreign key to this document template's filter rule */
    public _rule?: string,
    /** Additional documents to attach. */
    public uploads?: AttachmentUpload[]
  ) {}

  static typeinfo: TypeInfo<DocumentTemplateBundle> = {
    _id: ID_DEFAULT,
    name: new PatternValidator(FILENAME_REGEX),
    description: '',
    data: new BufferLikeValidator(),
    _rule: ID_DEFAULT,
    uploads: [new AttachmentUpload()]
  }

  static collectioninfo: CollectionInfo<FusionCollection, DocumentTemplate> = {
    _inst: 'institutions',
    _rule: 'filterRules'
  }
  
  /** Create from a builtin. */
  static from(_inst: string, name: SystemDocumentTemplate, data: string | Buffer, uploads?: AttachmentUpload[]): DocumentTemplateBundle {
    let builtin = BUILTIN_DOCUMENT_TEMPLATES[name];
    return {
      _inst, data,
      name: builtin.name ?? name,
      type: builtin.type,
      description: builtin.description,
      uploads
    };
  }

  /** Merge two document template bundles into one. */
  static async merge(outer: DocumentTemplateBundle, inner: DocumentTemplateBundle) {
    let outerText = await binaryText(outer.data);
    let innerText = await binaryText(inner.data);

    let uploads = [...(outer.uploads ?? []), ...(inner.uploads ?? [])];
    return { ...outer, data: btoaUnicode(outerText.replace('<!--content-->', innerText)), uploads };
  }
}