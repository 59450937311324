import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { SearchCategory } from '../../../../../../../common/code/standard/common';
import { CommonCode } from '../../../../../../../common/code/system';
import { FEATURE_NAMES } from '../../../../../../../common/model/organization/feature';
import { Permission } from '../../../../../../../common/model/permission';
import { PermissionGroup } from '../../../../../../../common/model/permission-group';
import { ArraySome } from '../../../../../../../common/toolbox/array';
import { errorResponse } from '../../../../../../../common/toolbox/message';
import { AccountService } from '../../../../common/service/account.service';
import { AuthService } from '../../../../common/service/auth.service';
import { ClaimService } from '../../../../common/service/claim.service';
import { LogService } from '../../../../common/service/log.service';
import { MemberService } from '../../../../common/service/member.service';
import { TabService } from '../../../../common/service/tab.service';
import { SearchOptions } from '../../../../common/toolbox/fusion';
import { AccountSearchComponent } from '../../../account/search/account-search.component';
import { MemberSearchComponent } from '../../../member/search/member-search.component';

/** Configuration for displaying a search option. */
class Option<T extends SearchCategory = SearchCategory> {
  constructor(
    /** Category to search. */
    public category: T,
    /** Permissions of this menu option. */
    public permissions?: PermissionGroup | Permission[]
  ) {}
}

@Component({
  selector: 'app-menu-bar-search',
  templateUrl: './menu-bar-search.component.html',
  styleUrls: ['./menu-bar-search.component.scss']
})
export class MenuBarSearchComponent {
  readonly CommonCode = CommonCode;
  readonly FEATURE_NAMES = FEATURE_NAMES;

  builder = new FormBuilder().nonNullable.group({
    category: [SearchCategory.AccountNumber, [Validators.required]],
    query: ['', [Validators.required]],
  });

  /** List of categories to search. */
  options: Option[] = [
    new Option(SearchCategory.AccountNumber),
    new Option(SearchCategory.CardNumber),
    new Option(SearchCategory.MemberName),
    new Option(SearchCategory.MemberNumber),
    new Option(SearchCategory.MemberTaxId),
    new Option(SearchCategory.Claim, PermissionGroup.ClaimIntake)
  ];

  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    public auth: AuthService,
    private log: LogService,
    private tabs: TabService,
    private accounts: AccountService,
    private members: MemberService,
    private claims: ClaimService,
  ) {}

  ngOnInit() {
    // Load last configuration.
    this.load();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when changing categories. */
  async onCategory(category: SearchCategory) {
    await this.save(category);
    this.load(category);
  }

  /** Callback when performing search. */
  async onSearch() {
    let _insts: ArraySome<string> = [this.auth._inst];
    let value = this.builder.value;
    switch (value.category) {
    case SearchCategory.AccountNumber:
      this.accounts.open({ _insts, number: value.query });
      break;
    case SearchCategory.MemberName:
      this.members.open({ _inst: _insts[0], name: value.query });
      break;
    case SearchCategory.MemberTaxId:
      this.members.open({ _inst: _insts[0], taxId: value.query });
      break;
    case SearchCategory.MemberNumber:
      this.members.open({ _inst: _insts[0], numbers: [value.query!] });
      break;
    case SearchCategory.Claim:
      this.claims.open({ _insts, displayIds: [value.query!] });
      break;
    case SearchCategory.CardNumber:
      this.claims.open({ _insts, cardNumber: value.query });
      break;
    }

    this.save(value.category!);
  }

  /** Go to advanced search page for category. */
  onAdvanced(category: SearchCategory) {
    let value = this.builder.value;
    switch (category) {
      case SearchCategory.AccountNumber:
        this.tabs.open(AccountSearchComponent, { number: value.query });
        break;
      case SearchCategory.MemberName:
        this.tabs.open(MemberSearchComponent, { name: value.query });
        break;
      case SearchCategory.MemberTaxId:
        this.tabs.open(MemberSearchComponent, { taxId: value.query });
        break;
      case SearchCategory.MemberNumber:
        this.tabs.open(MemberSearchComponent, { number: value.query });
        break;
    }
  }

  /** Save current search query. */
  async save(category: SearchCategory) {
    let result = await DB.partial('searchOptions', {
      category,
      query: { [this.builder.value.category!]: this.builder.value.query }
    }, new SearchOptions(this.auth.session._id));

    if (errorResponse(result)) return this.log.show(result);
    this.load(category);
  }

  /** Load query for specified category. */
  private async load(category?: SearchCategory) {
    let options = await DB.get('searchOptions', this.auth.session._id);
    if (errorResponse(options)) return this.log.show(options);
    
    // Do not emit event after loading.
    if (!options) return;
    category = category ?? options.category;
    this.builder.patchValue({ category, query: options.query[category] }, { emitEvent: false });
  }
}
