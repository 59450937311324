import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationSettings, SettingGroup, SettingGroupPreview } from '../../../../../common/model/setting-group';
import { ArraySome } from '../../../../../common/toolbox/array';
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { AuthService } from './auth.service';
import { LogService } from './log.service';

/** A query to fetch a specific setting group. */
export class SettingGroupQuery {
  constructor(
    /** ID of setting group. */
    public _id = ID_DEFAULT,
    /** Institution of setting group. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Caches information about tasks. */
@Injectable({
  providedIn: 'root'
})
export class SettingGroupService extends CachePreviewService<SettingGroup, SettingGroupQuery, SettingGroupPreview> {
  readonly route = 'setting-groups/preview';
  readonly Type = SettingGroup;
  
  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService
  ) {
    super(SettingGroupQuery, log, dialog, http);
  }

  /** Get settings of current institution with all marked as required. */
  async inst(): Promise<Required<ApplicationSettings>> {
    let settings = await this.item({ _inst: this.auth._inst, _id: this.auth.institution._settingGroup });
    return ApplicationSettings.required(settings.general, settings.collections, settings.disputes);
  }

  protected override multiple(queries: ArraySome<SettingGroupQuery>) {
    return getRequest(this.http, 'setting-groups', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}
