import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '../dialog.model';
import { DialogSourceData, DialogSourceReturn } from './dialog-source.model';
import { objectDecircular } from '../../../../../../../common/toolbox/object';

@Component({
  selector: 'app-dialog-source',
  templateUrl: './dialog-source.component.html',
  styleUrls: ['./dialog-source.component.scss']
})
export class DialogSourceComponent {
  readonly DialogSourceData = DialogSourceData;

  static title() {
    return 'Viewing Source';
  }

  /** Source code to display. */
  code: string;

  constructor(
    @Inject(DIALOG_DATA)
    public data: DialogSourceData,
    public dialogRef: DialogRef<DialogSourceReturn>
  ) {
    this.code = JSON.stringify(objectDecircular(data.value), null, data.indent ?? 2);
  }
}
