import { DocumentTemplateType } from "../code/standard/common";
import { DisputeCreditStatus, LedgerType } from "../code/standard/disputes";
import { CodeEnum } from "../model/code-type";
import { DisputeJoin } from "../model/dispute/dispute";
import { DOCUMENT_TEMPLATE_FORMAT } from "../model/document-template/format";
import { DocumentTemplateScan } from "../model/document-template/scan";
import { Email } from "../model/email";
import { Member } from "../model/member";
import { Phone } from "../model/phone";
import { arrayExtract, arrayFlatten, arrayMax, arrayMin, arraySum } from "./array";
import { BufferLike } from "./binary";
import { csvEscape } from "./csv";
import { currencyFormat } from "./currency";
import { filePlaintext } from "./file";
import { errorResponse } from "./message";
import { mimeSplit } from "./mime";
import { phoneFormat } from "./phone";
import { stringMask, stringSlice } from "./string";
import { dateFormat, dateOffset } from "./time";
import { XmlParser } from "./xml";
import { Unzip } from "./zip";

/** List of available document template pipes. */
export enum DocumentTemplatePipe {
  Cents = 'cents',
  ClaimCredit = 'claimCredit',
  ClaimFinalCredit = 'claimFinalCredit',
  ClaimProvisionalCredit = 'claimProvisionalCredit',
  ClaimRecovered = 'claimRecovered',
  ClaimRepresentment = 'claimRepresentment',
  Code = 'code',
  Csv = 'csv',
  Date = 'date',
  DateOffset = 'dateOffset',
  Email = 'email',
  Fallback = 'fallback',
  FullName = 'fullname',
  LedgerCredit = 'ledgerCredit',
  LedgerRecovered = 'ledgerRecovered',
  LedgerRepresentment = 'ledgerRepresentment',
  Mask = 'mask',
  ShortName = 'shortname',
  Phone = 'phone',
  SubString = 'substring',
  Extract = 'extract',
  Sum = 'sum',
  Max = 'max',
  Min = 'min',
  Flatten = 'flatten',
  EscapeQuotes = 'escapeQuotes'
}

/** Execution context of current render.
 * 
 *  WARNING:
 *  This is built on the assumption that Docxtemplater is fully synchrous.
 *  Multiple users all rendering templates at once SHOULD each get their own context.
 *  @link https://docxtemplater.com/docs/async/
 */
export class DocumentTemplateContext {
  /** Code types available in current context. */
  static codes: CodeEnum
}

/** List of helper functions available in document templates. */
export const DOCUMENT_TEMPLATE_PIPES: Record<DocumentTemplatePipe, (value: any, ...args: any[]) => void> = {
  cents: wrap(currencyFormat),
  code: wrap(codePipe),
  csv: wrap(csvEscape),
  date: wrap(dateFormat),
  dateOffset: wrap(dateOffsetSafe),
  escapeQuotes: wrap(escapeQuotes),
  email: wrap(Email.address),
  fallback: fallbackPipe,
  flatten: wrap(arrayFlatten),
  fullname: wrap(Member.fullname),
  mask: wrap(stringMask),
  shortname: wrap(Member.shortname),
  phone: wrap(phoneFormat),
  substring: wrap(stringSlice),
  extract: wrap(arrayExtract),
  sum: wrap(arraySum),
  max: wrap(arrayMax),
  min: wrap(arrayMin),
  claimCredit: wrap(claimCredit),
  claimFinalCredit: wrap(claimFinalCredit),
  claimProvisionalCredit: wrap(claimProvisionalCredit),
  claimRecovered: wrap(claimRecovered),
  claimRepresentment: wrap(claimRepresentment),
  ledgerCredit: wrap(ledgerCredit),
  ledgerRecovered: wrap(ledgerRecovered),
  ledgerRepresentment: wrap(ledgerRepresentment)
};

/** Properties used by each document template helper function. */
export const DOCUMENT_TEMPLATE_PIPE_KEYS: Partial<Record<DocumentTemplatePipe, string[]>> = {
  email: new Array<keyof Email>('address'),
  fullname: new Array<keyof Member>('isBusiness', 'businessName', 'title', 'firstName', 'middleName', 'lastName', 'number'),
  shortname: new Array<keyof Member>('isBusiness', 'businessName', 'firstName'),
  phone: new Array<keyof Phone>('number'),
}

/** Special keywords allowed in document templates. */
export const DOCUMENT_TEMPLATE_KEYWORDS = ['$index'];

/** Get normalized type of document template. */
export function documentTemplateFormat(name: string) {
  return DOCUMENT_TEMPLATE_FORMAT.get(mimeSplit(name)[1]);
}

/** Perform a scan of a document template. */
export async function documentTemplateScan(unzip: Unzip, parser: XmlParser, name: string, type: DocumentTemplateType, data: BufferLike, codes: string[]) {
  let plaintext = await filePlaintext(unzip, parser, name, data);
  if (errorResponse(plaintext)) return plaintext;
  return new DocumentTemplateScan(plaintext.text, type, codes);
}

/** Wrap a utility function for templating engine. */
function wrap(callback: Function) {
  return function (value: unknown, ...args: any[]) {
    if (value === undefined || value === null) return "";
    try {
      return callback(value, ...args);
    } catch {
      return "";
    }
  }
}

/** Progressively fallback on values, finding first defined one. */
function fallbackPipe(...values: any[]) {
  for (let value of values) {
    if (value !== undefined) return value;
  }
}

/** Format out a code type in current template execution context. */
function codePipe(value: any, category?: string) {
  let codes = DocumentTemplateContext.codes.get(`${category}`);
  if (!codes) return `{{invalid code category: ${category}}}`;
  return codes.get(`${value}`) ?? `#${value}`;
}

/** Escape quotes within a value. */
function escapeQuotes(value: unknown) {
  let text = typeof value === 'string' ? value : `${value ?? ''}`;
  return /"/g.test(text) ? `${text.replace(/"/g, '""')}` : text;
}


/** Amount representment on a ledger */
function ledgerRepresentment(ledger: any | undefined, account: any) {
  if (!account) return 0
  return (ledger ?? [])
    // filtering ledgers NOT from consumer account
    // we don't want to include reversed provisional credit
    ?.filter((item: any) => item?.account !== account?.number)
    ?.reduce((accum: any, item: any) => {
      //we only want GL credits (credit to GL from merchant)
      if (item?.type === LedgerType.Credit) accum += item?.amount
      return accum
    }, 0);
}

/** Amount recovered on a ledger */
function ledgerRecovered(ledger: any | undefined, account: any) {
  if (!account) return 0
  return (ledger ?? [])
    // filtering ledgers NOT from consumer account
    // we don't want to include reversed provisional credit
    ?.filter((item: any) => item?.account !== account?.number)
    ?.reduce((accum: any, item: any) => {
      //accumulating GL credits (credit to GL from consumer)
      if (item?.type === LedgerType.Credit) accum += item?.amount
      else accum -= item?.amount
      return accum
    }, 0);
}

/** Amount of credit to consumer account for a ledger */
function ledgerCredit(ledger: any, account: any) {
  if (!account) return 0
  return (ledger ?? [])
    // filtering ledgers to consumer account
    // we don't want to include representments
    ?.filter((item: any) => item?.account === account?.number)
    ?.reduce((accum: any, item: any) => {
      //accumulating GL debits (credit from GL to consumer)
      if (item?.type === LedgerType.Debit) accum += item?.amount
      else accum -= item?.amount
      return accum
    }, 0);
}

/** Amount of credit to consumer account for a claim */
function claimCreditSingle(claim: any, creditStatus?: DisputeCreditStatus) {
  const ledger = (claim?.disputes ?? [])
    //not for sure a dispute join, but expected to be - we should still behave defensively here
    .filter((dispute: DisputeJoin) => creditStatus === undefined || ('creditStatus' in dispute && creditStatus === dispute.creditStatus))
    ?.flatMap((dispute: any) => dispute?.ledger)
  return ledgerCredit(ledger, claim?.account)
}

/** Amount recovered on a claim */
function claimRecoveredSingle(claim: any) {
  const ledger = (claim?.disputes ?? [])
    ?.flatMap((dispute: any) => dispute?.ledger)
    return ledgerRecovered(ledger, claim?.account)
}

/** Amount representment on a claim */
function claimRepresentmentSingle(claim: any) {
  const ledger = (claim?.disputes ?? [])
    ?.flatMap((dispute: any) => dispute?.ledger)
  return ledgerRepresentment(ledger, claim?.account)
}

/** Amount recovered on a single or array of claim(s) */
function claimRecovered(claim: any) {
  if(Array.isArray(claim)) return arraySum(claim.map(claimRecoveredSingle));
  else return claimRecoveredSingle(claim)
}

/** Amount representment on a single or array of claim(s) */
function claimRepresentment(claim: any) {
  if (Array.isArray(claim)) return arraySum(claim.map(claimRepresentmentSingle));
  else return claimRepresentmentSingle(claim)
}

/** Amount of final credit to consumer account for a single or array of claim(s) */
function claimFinalCredit(claim: any) {
  return claimCredit(claim, DisputeCreditStatus.Final);

}

/** Amount of provisional credit to consumer account for a single or array of claim(s) */
function claimProvisionalCredit(claim: any) {
  return claimCredit(claim, DisputeCreditStatus.Provisional);
}

/** Amount of credit to consumer account for a single or array of claim(s) */
function claimCredit(claim: any, creditStatus?: DisputeCreditStatus) {
  if(Array.isArray(claim)) return arraySum(claim.map(claim => claimCreditSingle(claim,creditStatus)));
  else return claimCreditSingle(claim, creditStatus)
}

/**
 * Safely offset a date, handling any type of input
 * @param input 
 * @param offset
 * @returns 
 */
function dateOffsetSafe(input: unknown, offset: number) {
  if (input instanceof Date) return dateOffset(input, offset);
  else return dateOffset(new Date(`${input}`), offset);
}