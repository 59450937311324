<div class="flex">
  <label color="secondary">if</label>
  <div class="fill">
    <div class="block bar" color="secondary">
      <ng-template #if></ng-template>
      <label>then</label>
    </div>
    <div class="block indent">
      <ng-template #then></ng-template>
    </div>
  </div>
</div>
<div class="flex">
  <label class="else" style="border-bottom-right-radius: 4px" color="secondary">else</label>
  <div class="fill">
    <div class="block bar" color="secondary"></div>
    <div class="block indent">
      <ng-template #else></ng-template>
    </div>
  </div>
</div>