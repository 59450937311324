import { ImportCode } from "../../../code/system"
import { PropertyInfo } from "../../../info/prop"
import { CURRENCY_DEFAULT } from "../../../toolbox/currency"
import { ID_DEFAULT } from "../../../toolbox/id"
import { PHONE_DEFAULT } from "../../../toolbox/phone"
import { PropertyType } from "../../property-type"

/** Built-in test models. */
export enum TestModel {
  Empty         = 'Test - Empty',
  Field         = 'Test - Field',
  Multiple      = 'Test - Multiple'
}

/** Example model with no fields. */
export class ModelTestEmpty {}

/** Example model using all available field types. */
export class ModelTestFields {
  constructor(
    public boolean = false,
    public number = 0,
    public string = '',
    public currency = CURRENCY_DEFAULT,
    public date = new Date(0),
    public code = '',
    public user = ID_DEFAULT,
    public member = ID_DEFAULT,
    public phone = PHONE_DEFAULT,
    public transaction = ID_DEFAULT,
    public dateLink = new Date(0)
  ) {}

  static system = false;
  
  static propinfo: PropertyInfo<ModelTestFields> = {
    boolean: { type: PropertyType.Boolean, value: true, on: 'Yes', off: 'No' },
    number: { type: PropertyType.Number, value: 100, min: 10, max: 1000 },
    string: { type: PropertyType.String, value: 'Test', maxLength: 50 },
    currency: { type: PropertyType.Currency, max: 50000 },
    date: { type: PropertyType.Date, value: 7, min: 7, max: 31 },
    code: { type: PropertyType.Code, category: ImportCode.AccountMember },
    user: { type: PropertyType.User },
    member: { type: PropertyType.Member },
    phone: { type: PropertyType.Phone, link: 'member' },
    transaction: { type: PropertyType.Transaction },
    dateLink: { type: PropertyType.Date, value: 1, link: 'date' }
  }
}

/** Example model using all available multivalue field types. */
export class ModelTestMultiple {
  constructor(
    public boolean: boolean[] = [],
    public number: number[] = [],
    public string: string[] = [],
    public currency: string[] = [],
    public date: Date[] = [],
    public code: string[] = [],
    public user: string[] = [],
    public member: string[] = [],
    public phone: string[] = [],
    public transaction: string[] = [],
    public dateLink: Date[] = []
  ) {}

  static system = false;

  static propinfo: PropertyInfo<ModelTestMultiple> = {
    boolean: { type: PropertyType.Boolean, value: true, on: 'Yes', off: 'No', multiple: true },
    number: { type: PropertyType.Number, value: 100, min: 10, max: 1000, multiple: true },
    string: { type: PropertyType.String, value: 'Test', maxLength: 50, multiple: true },
    currency: { type: PropertyType.Currency, max: 50000, multiple: true },
    date: { type: PropertyType.Date, value: 7, min: 7, max: 31, multiple: true },
    code: { type: PropertyType.Code, category: ImportCode.AccountMember, multiple: true },
    user: { type: PropertyType.User, multiple: true },
    member: { type: PropertyType.Member, multiple: true },
    phone: { type: PropertyType.Phone, link: 'member', multiple: true },
    transaction: { type: PropertyType.Transaction, multiple: true },
    dateLink: { type: PropertyType.Date, value: 1, link: 'date', multiple: true }
  }
}