
/** Historical amounts to display in chart. */
export class CollectionsExecutiveHistory {
  constructor(
    /** Year this amount applies to. */
    public year = 0,
    /** Amount for each month. */
    public amounts: number[] = [],
    /** Calculated total amount for this year. */
    public total = 0
  ) {}
}