<app-card class="fill">
  <app-card-header>{{state.name}}</app-card-header>
  <app-card-body scroll>
    <app-object [file]="image" [loading]="!image" [download]="download"></app-object>
    <app-label>Additional Documentation</app-label>
    <app-claim-attachment-list [claim]="state.claim" [disputes]="state.disputes" [autosubmit]="false" (uploaded)="uploads = $event"></app-claim-attachment-list>
    <div class="row">
      <div class="fill-2"></div>
      <app-spinner icon-spinner *ngIf="loading"></app-spinner>
      <button (click)="offset.emit(-1)">Back</button>
      <button (click)="submit()" [disabled]="loading || !download">Submit</button>
    </div>
  </app-card-body>
</app-card>
