import { currency } from "../../../../../../../common/toolbox/currency";

/** Stat to display in executive panel. */
export class CollectionsExecutiveStat {
  constructor(
    /** Title of stat. */
    public value = 999 ,
    /** Subtitle of stat. */
    public subtitle = 'Subtitle',
    /** Amount of stat. */
    public amount: currency = 999999,
    /** Label of amount. */
    public label = 'Label',
    /** Percentage of amount. */
    public percentage = 0
  ) {}
}