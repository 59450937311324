import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ClaimModule } from '../claim/claim.module';
import { ExecutiveModule } from '../executive/executive.module';
import { WorkModule } from '../work/work.module';
import { DisputesAgentComponent } from './agent/disputes-agent.component';
import { DisputesIntakeComponent } from './intake/disputes-intake.component';
import { DisputesExecutiveComponent } from './executive/disputes-executive.component';

@NgModule({
  declarations: [
    DisputesAgentComponent,
    DisputesExecutiveComponent,
    DisputesIntakeComponent
  ],
  exports: [
  ],
  imports: [
    ExecutiveModule,
    ClaimModule,
    SharedModule,
    WorkModule,
  ]
})
export class DisputesModule { }
