import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ErrorResponse } from '../../../../../../common/message/error';
import { FusionCollectionName } from '../../../../../../common/model/fusion';
import { Permission } from '../../../../../../common/model/permission';
import { MaybeId } from '../../../../../../common/toolbox/id';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { objectValues } from '../../../../../../common/toolbox/object';
import { PreviewNode, previewTree, previewTreeLoad, previewTreeSave } from '../../../../../../common/toolbox/preview';
import { AnyValidator } from '../../../../../../common/validator/any';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { clipboardJSON } from '../../../common/toolbox/clipboard';
import { SetupOptions } from '../../../common/toolbox/fusion';
import { PreviewLike } from '../../../common/toolbox/preview';
import { ClientSource } from '../../../common/toolbox/source/client';

/** A list of setup items. */
class SetupItem<T extends PreviewLike> {
  constructor(
    /** True if this item is selected. */
    public selected = false,
    /** Preview of item. */
    public preview: T
  ) {}
}

@Component({
  selector: 'app-setup-list',
  templateUrl: './setup-list.component.html',
  styleUrls: ['./setup-list.component.scss'],
  host: {
    class: 'card column'
  }
})
export class SetupListComponent<P extends PreviewLike> {
  
  readonly Permission = Permission;

  /** Collection being configured. */
  @Input() collection: FusionCollectionName = 'accounts';
  /** True to display clipboard options. */
  @Input() clipboard = true;
  /** Hard override for system flag for all resources. */
  @Input() system?: boolean;
  /** Current selected item to edit. */
  @Input() preview?: MaybeId<P> | undefined;
  /** List of available previews. */
  @Input() set previews(previews: P[]) {
    this.all = previews.map(preview => new SetupItem(false, preview));
    this.refilter(this.builder.value.filter!);
  }

  /** Emits when selected item changes. */
  @Output() previewChange = new EventEmitter<P>();
  /** Emits when deleting an item. */
  @Output() delete = new EventEmitter<number[]>();
  /** Emits when items should be downloaded to clipboard. */
  @Output() download = new EventEmitter<string[]>();
  /** Emits when items should be uploaded from clipboard. */
  @Output() upload = new EventEmitter<unknown>();
  /** Emits when items should be diffed from clipboard. */
  @Output() diff = new EventEmitter<unknown>();
  
  /** Number of selected items. */
  selected = 0;
  /** Number of selected items that are available for deletion. */
  deletable = 0;
  /** Data for main form. */
  builder = new FormBuilder().nonNullable.group({
    filter: ['', [Validators.required, Validators.email]]
  });

  /** True if side panel is expanded. */
  protected expanded = true;
  /** True if currently in list view, or undefined if configuration not fetched. */
  protected list?: boolean;
  /** List of filtered items. */
  protected filtered: SetupItem<P>[] = [];
  /** Data source for tree. */
  protected source = new ClientSource<PreviewNode<P>>();
  /** Control for tree. */
  protected control = new NestedTreeControl<PreviewNode<P>>(node => node.children ? objectValues(node.children) : undefined);

  /** List of all available items. */
  private all: SetupItem<P>[] = [];
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    private auth: AuthService,
    private log: LogService
  ) {
    this.builder.controls.filter.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(filter => this.refilter(filter));
  }

  async ngOnInit() {
    // Fetch options of user.
    let options = await DB.get('setupOptions', this.auth.session._id);
    if (errorResponse(options)) {
      this.log.show(options);
      options = undefined;
    }

    this.list = options?.list ?? false;
    let filter = options?.filter[this.collection];
    if (filter?.length) this.builder.controls.filter.patchValue(filter);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when deleting an item */
  onDelete(index: number) {
    let found = this.all.indexOf(this.filtered[index]!);
    if (found < 0) this.log.show(new ErrorResponse('Failed to find item to delete.'));
    this.delete.emit([found]);
  }

  /** Callback when bulk deleting items. */
  onDeleteSelected() {
    let found = this.all.filter(value=>!value.preview.system).map((value, index) => value.selected ? index : -1).filter(index => index !== -1).sort((a,b)=>b-a);
    this.delete.emit(found);
  }
  

  /** Callback when adding an item to selection. */
  onSelect(item: SetupItem<P>) {
    item.selected = !item.selected;
    this.selected += item.selected ? 1 : -1;
    this.deletable += item.preview.system ? 0 : item.selected ? 1 : -1;
  }

  /** Callback when selecting all items. */
  onSelectAll() {
    if (this.selected === this.filtered.length) {
      this.deselectAll();
    } else {
      for (let item of this.filtered) item.selected = true;
      this.selected = this.filtered.length;
      this.deletable = this.filtered.filter(item => !item.preview.system).length;
    }
  }

  /** Callback when expanding list view. */
  onExpand() {
    this.expanded = !this.expanded;
  }

  /** Callback when toggling list view. */
  async onList() {
    this.list = !this.list;
    let result = await DB.partial('setupOptions', { list: this.list }, new SetupOptions(this.auth.session._id));
    if (errorResponse(result)) this.log.show(result);
  }

  /** Callback when uploading items from clipboard. */
  async onUpload() {
    let items = await clipboardJSON(new AnyValidator());
    if (errorResponse(items)) {
      this.log.show(items);
      return;
    }

    this.upload.emit(items);
  }

  /** Callback when uploading items from clipboard. */
  async onDiff() {
    let items = await clipboardJSON(new AnyValidator());
    if (errorResponse(items)) {
      this.log.show(items);
      return;
    }

    this.diff.emit(items);
  }

  /** Callback when downloading selected items. */
  onDownload() {
    let selection = this.filtered.filter(item => item.selected).map(item => item.preview._id);
    this.download.emit(selection);
    this.deselectAll();
  }

  /** Filter list of available items. */
  private async refilter(filter: string) {
    this.filtered = this.all.filter(item => item.preview.name.toLowerCase().includes(filter.toLowerCase()));
    this.selected = this.filtered.filter(item => item.selected).length;

    // Load new tree, retaining expanded items.
    let state = previewTreeSave(this.source.items);
    this.source.items = previewTree(this.filtered.map(f => f.preview));
    previewTreeLoad(this.source.items, state);

    // Save changes to filter.
    let result = await DB.partial('setupOptions', {
      filter: { [this.collection]: filter }
    }, new SetupOptions(this.auth.session._id));
    if (errorResponse(result)) this.log.show(result);
  }

  /** Deselect all items. */
  private deselectAll() {
    for (let item of this.filtered) item.selected = false;
    this.selected = 0;
    this.deletable = 0;
  }
}
