import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";
import { propInfoMerge } from "../../../../../../../common/info/prop";
import { typeinfoOf } from "../../../../../../../common/info/type";
import { TaskPostResponse } from "../../../../../../../common/message/task";
import { DisplayValue } from "../../../../../../../common/model/display";
import { EventResult } from "../../../../../../../common/model/event/result";
import { Task } from "../../../../../../../common/model/task";
import { TASK_EVENT_CLASS, TASK_TYPE_NAME, TaskConfigClass, TaskType } from "../../../../../../../common/model/task-config";
import { enumValues } from "../../../../../../../common/toolbox/enum";
import { ID_DEFAULT, idMaybe } from "../../../../../../../common/toolbox/id";
import { DialogService } from "../../../../common/component/dialog/dialog.service";
import { LogService } from "../../../../common/service/log.service";
import { TaskService } from "../../../../common/service/task.service";
import { postRequest } from "../../../../common/toolbox/request";
import { TaskMode } from "../../../task/task.model";
import { SetupEditComponent } from "../../setup-edit.component";
import { displaySetup } from "../../setup.model";
import { SetupTaskConfigAttachmentComponent } from "../config/attachment/setup-task-config-attachment.component";
import { SetupTaskConfigClaimReviewComponent } from "../config/claim/review/setup-task-config-claim-review.component";
import { SetupTaskConfigDocumentTemplateComponent } from "../config/document-template/setup-task-config-document-template.component";
import { SetupTaskConfigEmptyComponent } from "../config/empty/setup-task-config-empty.component";
import { SetupTaskConfigFormListComponent } from "../config/form-list/setup-task-config-form-list.component";
import { SetupTaskConfigFormComponent } from "../config/form/setup-task-config-form.component";
import { TaskAccessor } from "./setup-task-edit.model";

/** Component for configuring each task type. */
export const TASK_SETUP_COMPONENT = {
  [TaskType.ClaimAttachment]: SetupTaskConfigAttachmentComponent,
  [TaskType.ClaimReview]: SetupTaskConfigClaimReviewComponent,
  [TaskType.DisputeWithdraw]: SetupTaskConfigEmptyComponent,
  [TaskType.DocumentTemplate]: SetupTaskConfigDocumentTemplateComponent,
  [TaskType.Form]: SetupTaskConfigFormComponent,
  [TaskType.FormList]: SetupTaskConfigFormListComponent,
  [TaskType.Headless]: SetupTaskConfigEmptyComponent
};

@Component({
  selector: 'app-setup-task-edit',
  templateUrl: './setup-task-edit.component.html',
  styleUrls: ['./setup-task-edit.component.scss'],
  host: {
    class: 'row fill'
  }
})
export class SetupTaskEditComponent extends SetupEditComponent<Task, TaskPostResponse> {
  readonly TASK_TYPE_NAME = TASK_TYPE_NAME;

  /** Container to inject components. */
  @ViewChild('container', { static: true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** Current task being edited. */
  value = idMaybe(new Task());
  /** List of task types to pick from. */
  types: TaskType[] = enumValues<TaskType>(TaskType);

  /** Component injected into template. */
  component?: TaskAccessor;
  /** Object to view event configuration for. */
  proxy: any;
  
  /** Mode for previewing tasks. */
  mode = TaskMode.Account;
  /** Partial to use for previewing tasks. */
  partial = displaySetup();

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private http: HttpClient,
    private dialog: DialogService,
    private tasks: TaskService
  ) {
    super(elementRef, log);
  }

  /** Callback to preview current task. */
  onPreview() {
    let { account, claim, dispute } = this.partial;
    this.tasks.open({
      dialog: this.dialog,
      input: {
        partial: this.partial,
        task: { _id: ID_DEFAULT, ...this.value } as Task<any>,
        ...this.mode === TaskMode.Account ? { account } : this.mode === TaskMode.Claim ? { claim } : { account, claim, dispute }
      } as any // FIXME
    })
  }

  /** Callback when changing type of task. */
  onType(type: TaskType) {
    this.value.config = new TaskConfigClass()[type];
    this.refield(!!this.proxy);
    this.reconfig();
  }

  /** Submit current changes to task. */
  push() {
    return postRequest(this.http, 'tasks', { items: [this.value] });
  }

  /** Reset current form with new task. */
  async reset(value: Task) {
    this.value = value;
    this.refield(!!this.proxy);
    this.reconfig();
  }

  /** Update displayed type information for task description. */
  refield(show: boolean) {
    let result = TASK_EVENT_CLASS[this.value.config.type];
    this.proxy = show ? this.restrict(result) : undefined;
  }

  /** Update configuration form for task config. */
  private reconfig() {
    this.containerRef.clear();
    this.component = undefined;

    let prototype = TASK_SETUP_COMPONENT[this.value.config.type];
    this.component = this.containerRef.createComponent(prototype).instance;
    this.component.config = this.value.config;
    this.component.reset();
  }

  /** Return type information about a DisplayValue that can only have specified result type. */
  private restrict<T extends EventResult>(result: T): DisplayValue {
    let value = propInfoMerge(new DisplayValue());
    value.event = propInfoMerge(value.event);

    let typeinfo = typeinfoOf(value.event);
    typeinfo.result = result as any;
    return value;
  }
}
