<app-card class="fill">
  <app-card-header>{{state.name}}</app-card-header>
  <app-card-body scroll>
    <div class="row">
      <app-search-field class="fill" [(query)]="data.query" [(category)]="data.category" [categories]="categories" (search)="onSearch($event)"></app-search-field>
      <div class="fill"></div>
    </div>
    <app-model-grid [loading]="loading" class="elevation-low" [config]="config" [precolumns]="precolumns" (rowChange)="onMember($event)"></app-model-grid>
    <div class="button-row">
      <button (click)="offset.emit(-1)">Back</button>
      <button (click)="offset.emit(+1)" [disabled]="state.member._id | idNull">Next</button>  
    </div>
  </app-card-body>
</app-card>
