import { Component } from "@angular/core";
import { PropertyType } from "../../../../../../common/model/property-type";
import { UserPreview } from "../../../../../../common/model/user/user";
import { arrayCoerce, arrayEqual } from "../../../../../../common/toolbox/array";
import { keyNestedGet, NestedKey } from "../../../../../../common/toolbox/keys";
import { setCoerce } from "../../../../../../common/toolbox/set";
import { AuthService } from "../../service/auth.service";
import { FormAccessor } from "./form-accessor";


/** Interface for FormAccessors that can select multiple values. */
@Component({ template: '' })
export abstract class FormMultiAccessor<T = any> extends FormAccessor<T[]> {
  /** Get list of items. */
  abstract set items(items: T[]);
  /** Set list of items. */
  abstract get items(): T[];

  /** Selected list of items. */
  override value: T[] = [];

  /** Unique key for items. */
  abstract key: NestedKey<T>;
  /** Display key for items.. */
  abstract display: (value: T) => string;

  constructor(
    protected auth: AuthService
  ) {
    super();
  }

  override writeValue(value: any) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    let fallback = this.fallback();
    let selected: T[] = [];

    // Map keys to items of list.
    let keys = setCoerce<T>(value);
    for (let item of this.items) {
      if (keys.has(keyNestedGet(this.key, item))) {
        selected.push(item);
      }
    }
    
    // Find items in current list, fall back on first item.
    if (this.required && !selected.length && fallback) {
      selected.push(fallback);
    }

    // Set new selection.
    this.update(selected);
  }

  override update(value: T[]) {
    if (arrayEqual(value, this.value)) return false;
    this.valueChange.next(this.value = value);
    return true;
  }

  /** Map list of items to list of keys. */
  keys(items: T[]) {
    return arrayCoerce(items).map(i => keyNestedGet(this.key, i));
  }

  /** Determine fallback default value for selection. */
  protected fallback(): T | undefined {
    // Type-specific fallback logic.
    switch (this.control.property.type) {
    case PropertyType.User:
      // Default to logged-in user.
      let users = this.items as unknown as UserPreview[];
      let item = users.find(i => i._id === this.auth.session._id) as unknown as T;
      if (item) return item;
    }

    // Fall back on current selection.
    if (this.value[0] && this.items.includes(this.value[0])) return this.value[0];

    // Fall back on first item.
    return this.items[0];
  }
}