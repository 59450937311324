<app-icon>{{PropertyType.String | icon:PROPERTY_TYPE_ICON}}</app-icon>
<app-string
  [large]="large"
  [maxLength]="maxLength ?? Number.MAX_SAFE_INTEGER"
  [minLength]="minLength ?? 0"
  [disabled]="disabled"
  [required]="required"
  [readonly]="readonly | propertyReadonly:locked:reopened"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  (dirtyChange)="setDirty($event)"
  (touchChange)="setTouched($event)">
</app-string>
