import { BehaviorSubject, Subject } from "rxjs";
import { AccountJoin } from "../../../../../../common/model/account/account";
import { ClaimJoin } from "../../../../../../common/model/claim/claim";
import { EventCategory } from "../../../../../../common/model/event/category";
import { EventResultType } from "../../../../../../common/model/event/result";
import { DateRange } from "../../../../../../common/toolbox/time";
import { TaskOpen } from "../../toolbox/task";

/** Data passed to event list. */
export class EventListData {
  constructor(
    /** Emits when viewed account changes. */
    public accountChange?: BehaviorSubject<AccountJoin>,
    /** Emits when viewed claim changes. */
    public claimChange?: BehaviorSubject<ClaimJoin>,
    /** Initial form data for event list. */
    public form?: EventListForm,
    /** Emits when task has been selected in list. */
    public open?: Subject<TaskOpen>,
    /** True to stretch to fit contents, false = scrollbar */
    public expand?: boolean
  ) { }
}

/** Form value tracked by event list. */
export class EventListForm {
  constructor(
    /** Category of results to search. */
    public category?: EventCategory,
    /** Type of results to search. */
    public type?: EventResultType,
    /** Assigned user. */
    public _assigned?: string,
    /** Creation date of event. */
    public eventDate?: DateRange,
    /** Asign date of event. */
    public assignDate?: DateRange
  ) { }
}
