import { TypeInfo } from "../../info/type";
import { UnionInfo } from "../../info/union";
import { ErrorResponse } from "../../message/error";
import { ID_DEFAULT } from "../../toolbox/id";
import { errorPartition } from "../../toolbox/message";
import { permissionRecord } from "../../toolbox/permission";
import { NON_WHITESPACE_REGEX } from "../../toolbox/string";
import { PatternValidator } from "../../validator/pattern";
import { Permission, PermissionMask, PermissionRecord } from "../permission";
import { CommentData, CommentText } from "./comment";

//** An action to perform on an list of FusionComments */
export type CommentAction = CommentActionAdd | CommentActionDelete | CommentActionRevise;

export enum CommentActionType {
  Add = 'add',
  Delete = 'delete',
  Revise = 'revise',
}
//** Tagged type of all comment actions. */
export interface CommentActionTag<T extends CommentActionType = CommentActionType> {
  /** Type of action */
  type: T
}

//** Action to add a new comment */
export class CommentActionAdd implements CommentActionTag<CommentActionType.Add>{
  readonly type = CommentActionType.Add;

  constructor(
    /** Contents of new comment. */
    public text = '',
    /** List of mentions on new comment. */
    public mentions?: string[],
    /** True to make this comment private. */
    public privated?: boolean
  ) { }

  static typeinfo: TypeInfo<CommentActionAdd> = {
    text: new PatternValidator(NON_WHITESPACE_REGEX),
    mentions: [ID_DEFAULT],
    privated: true
  }
}

//** Action to mark an existing comment as deleted. */
export class CommentActionDelete implements CommentActionTag<CommentActionType.Delete>{
  readonly type = CommentActionType.Delete;

  constructor(
    /** Index of the existing comment to mark as deleted. */
    public index = 0
  ) { }
}

//** Action to modify an existing comment by adding a new revision. */
export class CommentActionRevise implements CommentActionTag<CommentActionType.Revise>{
  readonly type = CommentActionType.Revise;

  constructor(
    /** Index of the existing comment to revise. */
    public index = 0,
    /** New text for comment. */
    public text?: string,
    /** New mentions for comment. */
    public mentions?: string[]
  ) { }

  static typeinfo: TypeInfo<CommentActionRevise> = {
    text: new PatternValidator(NON_WHITESPACE_REGEX),
    mentions: [ID_DEFAULT]
  }
}

/** Apply a list of actions to a comment. */
export function applyCommentActions(_user: string | undefined, actions: CommentAction[], permissions?: PermissionMask | PermissionRecord | Permission[], comments?: CommentData[]): CommentData[] | ErrorResponse {
  let record = permissionRecord(permissions ?? {});
  let c = comments ?? [];
  let [error] = errorPartition('There was an error editing comments.', actions.map(a => applyAction(_user, record, a, c)));
  return error ?? c;
}

/** Apply an action to a list of comments. */
function applyAction(_user: string | undefined, permissions: PermissionRecord, action: CommentAction, comments: CommentData[]): ErrorResponse | void {
  switch (action.type) {
    case CommentActionType.Add:
      if (!NON_WHITESPACE_REGEX.test(action.text)) return new ErrorResponse('Comment to add had empty text.');
      comments.push({ _user, revisions: [new CommentText(action.text, action.mentions, new Date())] });
      break;
    case CommentActionType.Delete: {
      // Check if comment can be edited.
      let comment = comments[action.index];
      if (!comment) return new ErrorResponse(`Comment to delete did not exist: ${action.index}`);
      if (comment.deleted) return new ErrorResponse(`Comment to delete already deleted: ${action.index}`);
      if (comment.privated && !permissions[Permission.CommentsPrivateView]) return new ErrorResponse(`Cannot delete private comment: ${action.index}`);
      if (_user && `${comment._user}` !== _user && !permissions[Permission.CommentsDelete]) return new ErrorResponse(`Cannot delete comments of user: ${comment._user}`);

      comment.deleted = true;
      break;
    }
    case CommentActionType.Revise: {
      // Check if comment can be edited.
      let comment = comments[action.index];
      if (!comment) return new ErrorResponse(`Comment to revise did not exist: ${action.index}`);
      if (comment.deleted) return new ErrorResponse(`Comment to revise already deleted: ${action.index}`);
      if (comment.privated && !permissions[Permission.CommentsPrivateView]) return new ErrorResponse(`Cannot revise private comment: ${action.index}`);
      if (_user && `${comment._user}` !== _user) return new ErrorResponse(`Cannot edit comments of user: ${comment._user}`);

      // Check if edit is valid.
      if (!CommentData.differs(comment, action.text, action.mentions)) return new ErrorResponse('No changes were made to comment.');

      // Create new revision with changes, inherit values from last revision.
      let revision = comment.revisions[0];
      comment.revisions.unshift(new CommentText(action.text ?? revision.text, action.mentions ?? revision.mentions, new Date()));
      break;
    }
    default:
      return new ErrorResponse(`Invalid action type: ${(action as CommentAction).type}`);
  }
}

/** Class of each comment action type. */
export class CommentActionClass {
  [CommentActionType.Add] = new CommentActionAdd();
  [CommentActionType.Delete] = new CommentActionDelete();
  [CommentActionType.Revise] = new CommentActionRevise();
}

/** Type information for property union. */
export const COMMENT_ACTION_UNIONINFO: UnionInfo<CommentAction, CommentActionType> = {
  tag: 'type',
  classes: new CommentActionClass()
};