import { Overlay } from '@angular/cdk/overlay';
import { Component, ElementRef, NgZone, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EventUpsert } from "../../../../../../common/model/event";
import { EventCategory } from "../../../../../../common/model/event/category";
import { PersonalReminderResult, PersonalResultType } from "../../../../../../common/model/event/result/personal";
import { Icon } from '../../../../../../common/model/icon';
import { arrayPartition } from "../../../../../../common/toolbox/array";
import { ColorCode } from "../../../../../../common/toolbox/color";
import { DurationFormat } from "../../../../../../common/toolbox/duration";
import { EnumMap, enumPrototype } from "../../../../../../common/toolbox/enum";
import { errorResponse } from '../../../../../../common/toolbox/message';
import { STATUS_LEVELS, StatusLevel } from "../../../../../../common/toolbox/status";
import { MILLISECONDS_MAX, dateFilename, dateOffset } from "../../../../../../common/toolbox/time";
import { AuthService } from '../../../common/service/auth.service';
import { EventService } from '../../../common/service/event.service';
import { LogService } from '../../../common/service/log.service';
import { ReminderService } from '../../../common/service/reminder.service';
import { ActivityTab } from '../../../common/toolbox/activity';
import { fileDownload } from '../../../common/toolbox/file';
import { ActivityOptions } from '../../../common/toolbox/fusion';
import { INTERVAL_FREQUENCY_MIN, INTERVAL_THRESHOLD_MIN, IntervalOptions } from '../../../common/toolbox/interval';
import { Log } from '../../../common/toolbox/log';
import { OverlayComponent, overlayOpen } from '../../../common/toolbox/overlay';
import { Reminder } from '../../../common/toolbox/reminder';
import { STATUS_LEVEL_ICON } from '../../../common/toolbox/status';
import { DevService } from '../../../common/service/dev.service';
import { DialogService } from '../../../common/component/dialog/dialog.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OverlayComponent {
  readonly STATUS_LEVEL_ICON = STATUS_LEVEL_ICON;
  readonly Icon = Icon;
  
  /** List of activity tabs. */
  readonly TABS = [
    ActivityTab.Reminders,
    ActivityTab.Logs
  ];

  /** Name for each activity tab. */
  readonly TAB_NAME: EnumMap<ActivityTab> = {
    [ActivityTab.Reminders]: 'Reminders',
    [ActivityTab.Logs]: 'Logs'
  }

  readonly INTERVAL_FREQUENCY_MIN = INTERVAL_FREQUENCY_MIN;
  readonly INTERVAL_THRESHOLD_MIN = INTERVAL_THRESHOLD_MIN;
  readonly STATUS_LEVELS = STATUS_LEVELS;
  readonly DurationFormat = DurationFormat;
  readonly ActivityTab = ActivityTab;
  readonly ColorCode = ColorCode;

  /** Reference to dropdown template. */
  @ViewChild('panel', { static : true }) dropdownRef!: TemplateRef<HTMLDivElement>;

  /** Options for activity panel. */
  options = new ActivityOptions(this.auth.session._id);

  /** Status color of badge. */
  status = StatusLevel.Notice;
  /** True if showing reminder dates. */
  dates = false;
  /** Count of items to show. */
  count = 0;
  /** Count of hidden items. */
  hidden = 0;

  /** List of logs mirrored from service. */
  logs: Log[] = [];
  /** List of reminders mirrored from service. */
  reminders: Reminder[] = [];
  /** Current reminder being created. */
  reminder?: EventUpsert<PersonalReminderResult>;
  /** Event configuration being edited. */
  config?: IntervalOptions;

  /** Emits whenever the component is destroyed. */
  destroy = new Subject<void>();

  constructor(
    public zone: NgZone,
    public dev: DevService,
    public overlay: Overlay,
    public dialog: DialogService,
    public containerRef: ViewContainerRef,
    public elementRef: ElementRef<HTMLElement>,
    public reminderService: ReminderService,
    public eventService: EventService,
    public logService: LogService,
    private auth: AuthService
  ) {
    reminderService.updates.pipe(takeUntil(this.destroy)).subscribe(reminders => this.refreshReminders(reminders));
    logService.updates.pipe(takeUntil(this.destroy)).subscribe(() => this.refreshLogs());
  }

  async ngOnInit() {
    let options = await DB.get('activityOptions', this.auth.session._id);
    if (errorResponse(options)) {
      this.logService.show(options);
      return;
    }

    this.options = options ?? this.options;
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Called when changing current tab. */
  retab(tab: ActivityTab) {
    this.options.tab = tab;
    this.saveOptions();
  }

  /** Called when opening panel. */
  open($event: MouseEvent) {
    $event.preventDefault();
    overlayOpen(this, { position: { originX: 'end', overlayX: 'end' } });
    this.count = 0;
  }

  /** Toggle visibility of given status. */
  toggle(level: StatusLevel) {
    this.options.filter[level] = !this.options.filter[level];
    this.saveOptions();
    this.refreshLogs();
  }

  /** Unhide all logs. */
  unhide() {
    this.options.filter = enumPrototype<StatusLevel, boolean>(StatusLevel, false);
    this.saveOptions();
    this.refreshLogs();
  }

  /** Start editing a new reminder. */
  create() {
    this.reminder = {
      _inst: this.auth._inst,
      result: {
        category: EventCategory.Personal,
        type: PersonalResultType.Reminder,
        _assigned: this.auth.session._id,
        due: dateOffset(new Date(), 0, 1)
      }
    };
  }

  /** Submit reminder to be created. */
  submit() {
    if (!this.reminder) return;
    this.eventService.add({ event: this.reminder });
    this.reminder = undefined;
  }

  /** Submit new configuration  */
  save() {
    if (!this.config) return;
    this.eventService.config = this.config;
    this.config = undefined;
  }

  /** Download a single log, or all logs. */
  download(log?: Log) {
    if (log) return fileDownload({ ...log, seen: undefined }, `${dateFilename(log.date, true)}.json`);

    let logs: Log[] = this.logs.map(log => ({ ...log, seen: undefined }));
    fileDownload(logs as Object, `${dateFilename(new Date(), true)}.json`);
  }

  /** View source code of a log. */
  source(log: Log) {
    this.dialog.source({ message: log.message, list: log.list, details: log.details });
  }

  /** Save activity options. */
  private async saveOptions() {
    let options = await DB.put('activityOptions', this.options);
    if (errorResponse(options)) this.logService.show(options);
  }

  /** Refresh reminders and badge icon after reminders updated. */
  private refreshReminders(reminders: Reminder[]): any {
    // Filter out hidden reminders.
    this.reminders = reminders.sort((a, b) => (a.result.due?.getTime() || MILLISECONDS_MAX) - (b.result.due?.getTime() || MILLISECONDS_MAX));
    this.status = StatusLevel.Notice;
    this.count = 0;
  }

  /** Refresh list of logs. */
  private refreshLogs() {
    let logs = this.logService.updates.value;
    let [seen, hidden] = arrayPartition(logs, log => !this.options.filter[log.status ?? StatusLevel.Notice]);
    this.hidden = hidden.length;

    seen.sort((a, b) => b.date.getTime() - a.date.getTime());
    this.logs = seen;
  }
}
