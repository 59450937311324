<div class="text" #text [attr.disabled]="_disabled ? '' : null">{{view}}</div>
<div class="suffix">
  <ng-content select="[select-suffix]"></ng-content>
  <app-icon [visible-if]="!_readonly">expand_more</app-icon>
</div>
<ng-template #dropdown>
  <app-option-list class="elevation-high">
    <form class="flex" [formGroup]="builder">
      <input #input [placeholder]="placeholder" formControlName="query" />
      <app-icon button-icon *ngIf="_filter" tooltip="Filter options" (click)="showFilter = !showFilter; refilter()">{{showFilter ? 'filter_list' : 'filter_list_off'}}</app-icon>
    </form>
    <app-option select-all *ngIf="_multiple" [selected]="all" [disabled]="all && required">
      {{all ? 'Deselect All' : 'Select All'}}
      <div class="fill"></div>
      ({{pairs.length}})
    </app-option>
    <app-option *ngIf="!_multiple && !required" [view]="empty"></app-option>
    <ng-content></ng-content>
    <app-option #option *ngFor="let d of dynamic" [pair]="d"></app-option>
  </app-option-list>
</ng-template>
