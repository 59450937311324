import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OperatorType } from '../../../../../../../../common/model/formula/operator';
import { ArraySome } from '../../../../../../../../common/toolbox/array';
import { ColorCode } from '../../../../../../../../common/toolbox/color';
import { formulaContext } from '../../../../../../../../common/toolbox/formula/formula';
import { keyNestedSubkeys } from '../../../../../../../../common/toolbox/keys';
import { Pair } from '../../../../../../../../common/toolbox/object';
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';
import { SetupFormulaBinaryComponent } from '../../expression/binary/setup-formula-binary.component';
import { SetupFormulaIdentifierComponent } from '../../terminal/identifier/setup-formula-identifier.component';

@Component({
  selector: 'app-setup-formula-index',
  templateUrl: './setup-formula-index.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-index.component.scss'],
  host: {
    '[attr.color]': 'background'
  }
})
export class SetupFormulaIndexComponent extends SetupFormulaBinaryComponent {
  /** Attachment point for expression. */
  @ViewChild('expression', { static : true, read: ViewContainerRef }) expression!: ViewContainerRef;
  /** Attachment point for statement. */
  @ViewChild('statement', { static : true, read: ViewContainerRef }) statement!: ViewContainerRef;

  /** Symbols to display. */
  protected symbol: string[] = ['[', ']'];
  /** Background for main element. */
  protected background?: ColorCode = ColorCode.Primary;
  /** Color to display for bars. */
  protected color = ColorCode.Primary;
  
  /** Last determined context for statement. */
  private context?: string;
  /** List of keys in current identifier context. */
  private subkeys: Pair[] = [];
  /** Mapping of identifier components to subscriptions. */
  private subscriptions = new Map<BlockComponent, Subscription>();

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent, injector);
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.block.type === OperatorType.Access) this.symbol = ['.', ''];
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions.values()) subscription.unsubscribe();
    this.subscriptions.clear();
  }

  override onAttach(component: BlockComponent) {
    super.onAttach(component);
    if (this.slots[0]) this.widen(true, this.slots[0]);

    // Listen to changes on this identifier.
    if (component instanceof SetupFormulaIdentifierComponent) {
      this.subscriptions.set(component, component.identifier.subscribe(() => this.recontext()));
      this.recontext();
    }
  }

  override onRemove(component: BlockComponent) {
    super.onRemove(component);
    this.subscriptions.get(component)?.unsubscribe();
    this.subscriptions.delete(component);
  }

  /** Callback when a new list of keys is available. */
  override onKeys(keys: ArraySome<Pair[]>) {
    this.keys = keys;
    for (let i = 0; i < this.slots.length; ++i) {
      let keys: ArraySome<Pair[]> = i === 1 && this.subkeys.length ? [this.subkeys, ...this.keys] : this.keys;
      this.slots[i]!.component.instance.onKeys(keys);
    }
  }

  /** Callback when identifier changes in left slot. */
  private recontext() {
    // Determine context of right expression.
    let key = formulaContext(this.formula, this.block.right);
    if (key === this.context) return;
    this.context = key;

    // Get list of subkeys under this key.
    this.subkeys = typeof key === 'string' ? keyNestedSubkeys(key, this.keys.flat()) : [];
    this.onKeys(this.keys);
  }
}
