import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { Table, TablePreview } from '../../../../../../common/model/table';
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { LogService } from '../../../common/service/log.service';
import { TableQuery, TableService } from '../../../common/service/table.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-table',
  templateUrl: './setup-table.component.html',
  styleUrls: ['./setup-table.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupTableComponent extends SetupComponent<Table, TableQuery, TablePreview> {
  readonly Type = Table;
  readonly collection = 'tables';
  readonly route = 'tables';
  resource = idMaybe(new Table());

  static title() {
    return 'Tables';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public http: HttpClient,
    public auth: AuthService,
    public service: TableService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }
}
