import { Component } from '@angular/core';
import { CommonCode } from '../../../../../../../../common/code/system';
import { WORK_CONDITION_MODE_NAME, WorkConditionAttachment } from '../../../../../../../../common/model/work/condition';
import { enumMapPairs } from '../../../../../../../../common/toolbox/enum';
import { AuthService } from '../../../../../common/service/auth.service';
import { WorkConditionAccessor } from '../setup-workflow-condition.model';

@Component({
  selector: 'app-setup-workflow-condition-attachment',
  templateUrl: './setup-workflow-condition-attachment.component.html',
  styleUrls: ['./setup-workflow-condition-attachment.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupWorkflowConditionAttachmentComponent extends WorkConditionAccessor<WorkConditionAttachment> {
  readonly CommonCode = CommonCode;

  /** List of modes to select. */
  modes = enumMapPairs(WORK_CONDITION_MODE_NAME);

  constructor(
    public auth: AuthService
  ) {
    super();
  }
}
