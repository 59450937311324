import { ArraySome } from './array';
import { EnumMap } from './enum';

/** Standard application colors. */
export enum ColorCode {
  Primary   = 'primary',
  Secondary = 'secondary',
  Black     = 'black',
  White     = 'white',
  
  Red       = 'red',
  Orange    = 'orange',
  Yellow    = 'yellow',
  Green     = 'green',
  Blue      = 'blue',
  Purple    = 'purple',
  Pink      = 'pink',
  Navy      = 'navy'
}

/** A color-icon pair to display. */
export class ColorIcon {
  constructor(
    /** Color for icon. */
    public color = ColorCode.Primary,
    /** Image for icon. */
    public icon = ''
  ) {}
}

/** Convert any value to color hex. */
export function coerceColorProperty(color: any): string {
  switch (typeof color) {
  case 'number':
    if (color <= 0xFFFFFF) return `#${color.toString(16)}`;
    break;
  case 'string':
    if (/^#[0-9a-zA-Z]{1,6}$/.test(color)) return color;
    break;
  }

  return '';
}

/** Hex values of colors. */
export const COLOR_VALUES: EnumMap<ColorCode> = {
  [ColorCode.Primary]: '#2080C0',
  [ColorCode.Secondary]: '#183850',

  [ColorCode.Black]: '#183850',
  [ColorCode.White]: '#FFFFFF',
  [ColorCode.Red]: '#C00000',
  [ColorCode.Orange]: '#F08000',
  [ColorCode.Yellow]: '#ffD020',
  [ColorCode.Green]: '#20C080',
  [ColorCode.Blue]: '#2080C0',
  [ColorCode.Purple]: '#4020C0',
  [ColorCode.Pink]: '#FF60F0',
  [ColorCode.Navy]: '#183850'
}

/** List of application colors in display order, eg. for charts. */
export const COLOR_SERIES: ArraySome<ColorCode> = [
  ColorCode.Blue,
  ColorCode.Red,
  ColorCode.Green,
  ColorCode.Navy,
  ColorCode.Orange,
  ColorCode.Purple,
  ColorCode.Pink,
  ColorCode.Yellow
];
