<app-loader *ngIf="loading"></app-loader>
<app-expansion-panel (click)="$event.stopPropagation()">
  <!-- Dummy select, button to ensure alignment with lower items. -->
  <app-expansion-panel-item [disabled]="true">
    <app-expansion-panel-item-header class="row">
      <app-field class="fill-2" readonly="true">
        <app-string ngModel="Name" readonly="true"></app-string>
      </app-field> 
      <app-field class="fill" readonly="true">
        <app-select empty="Type" [readonly]="true"></app-select>
      </app-field>
      <button icon-button style="visibility: hidden">add</button>
    </app-expansion-panel-item-header>
  </app-expansion-panel-item>
  <app-expansion-panel-item *ngFor="let job of _jobs; let i = index">
    <app-expansion-panel-item-header class="row">
      <app-field class="fill-2" readonly="true">
        <app-string readonly="true" [(ngModel)]="job.name"></app-string>
      </app-field>
      <app-field class="fill">
        <app-select [ngModel]="job.payload.type" (selected)="onType(job, $event, i)" (click)="$event.stopPropagation()">
          <app-option *ngFor="let type of types" [pair]="type"></app-option>
        </app-select>
      </app-field>
      <button icon-button *ngIf= "dirty[i]" tooltip="Submit Changes" (click)="onSubmit(job, i)">done</button>
      <button icon-button *ngIf="!dirty[i]" tooltip="Unsubscribe" (click)="onUnsubscribe(i); $event.stopPropagation()">unsubscribe</button>
    </app-expansion-panel-item-header>
    <app-expansion-panel-item-body>
      <app-notification-item #component [claim]="claim" [job]="job" (touched)="dirty[i] = true"></app-notification-item>
    </app-expansion-panel-item-body>
  </app-expansion-panel-item>
  <app-expansion-panel-item>
    <app-expansion-panel-item-header class="row">
      <app-field class="fill-2" readonly="true">
        <app-string [(ngModel)]="job.name" readonly="true"></app-string>
      </app-field>
      <app-field class="fill">
        <app-select [ngModel]="job.payload.type" (selected)="onType(job, $event)" (click)="$event.stopPropagation()">
          <app-option *ngFor="let type of types" [pair]="type"></app-option>
        </app-select>
      </app-field>
      <button icon-button tooltip="Add" (click)="onSubmit(job); $event.stopPropagation()">add</button>
    </app-expansion-panel-item-header>
    <app-expansion-panel-item-body>
      <app-notification-item #component [claim]="claim" [job]="job"></app-notification-item>
    </app-expansion-panel-item-body>
  </app-expansion-panel-item>
</app-expansion-panel>
