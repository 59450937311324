import { randomDate, randomId, randomName, randomRange, randomSeed } from "../toolbox/random";

/** Debug data used for testing functionality. */
export class DebugData {
  constructor(
    /** ID of this data. */
    public id = '',
    /** Person of this data. */
    public name = '',
    /** Birth date. */
    public birth = new Date(0),
    /** Random amount. */
    public balance = '0',
    /** Index of data. */
    public index = 0
  ) {}

  /** Get a random piece of debug data. */
  static random(index: number) {
    randomSeed(index);
    return new DebugData(randomId(), randomName().join(), randomDate(new Date('1960-01-01'), new Date('2005-12-31')), randomRange(100, 1000.99).toFixed(2), index);
  }
}