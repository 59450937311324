import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { TypeInfo } from "../../info/type";
import { ColorCode } from "../../toolbox/color";
import { ID_DEFAULT, MaybeIdInst } from "../../toolbox/id";
import { EnumValidator } from "../../validator/enum";
import { CounterLike } from "../counter";
import { DisplayType, DisplayValue } from "../display";
import { FilterRule } from "../filter-rule";
import { Sort } from "../formula/sort";
import { FusionCollection } from "../fusion";

/** Work queue joined on its list of conditions. */
export type WorkQueueJoin<T extends DisplayType = DisplayType> = WorkQueue<T> & { rule: MaybeIdInst<FilterRule<T>> }

/** Base fields used by all work queues. */
export class WorkQueue<T extends DisplayType = DisplayType> implements CounterLike {
  constructor(
    /** Unique identifier of work queue. */
    public _id = ID_DEFAULT,
    /** Institution of work queue. */
    public _inst = ID_DEFAULT,
    /** List of additional tasks available in work queue. */
    public _tasks?: string[],
    /** Filter rule used by this queue. */
    public _rule?: string,
    /** Display name for work queue. */
    public name = '',
    /** Type of work queue. */
    public type = DisplayType.Account as T,
    /** Limit of items for this work queue. */
    public limit?: number,
    /** Interface color for this work queue. */
    public color?: ColorCode,
    /** True if system queue and cannot be deleted. */
    public system?: boolean,
    /** Sort order for this work queue. */
    public sort: Sort<DisplayValue[T]>[] = [],

    /** Last build of this work queue. */
    public buildDate?: Date,
    /** Last worked date of queue. */
    public workDate?: Date,
    /** Number of items in built queue. */
    public count: number | undefined = undefined,
    /** Number of worked queue items. */
    public worked?: number
  ) {}

  static typeinfo: TypeInfo<WorkQueue> = {
    _id: ID_DEFAULT,
    _inst: ID_DEFAULT,
    _tasks: [ID_DEFAULT],
    _rule: ID_DEFAULT,
    name: '',
    type: new EnumValidator(DisplayType),
    color: new EnumValidator(ColorCode),
    system: false,
    sort: [Sort.from({})],
    limit: 0,

    buildDate: new Date(),
    workDate: new Date(),
    count: 0,
    worked: 0
  };

  static collectioninfo: CollectionInfo<FusionCollection, WorkQueue> = {
    _inst: 'institutions',
    _tasks: 'tasks',
    _rule: 'filterRules'
  }

  static indexinfo: IndexInfo<WorkQueue> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** A preview of a work queue. */
export class WorkQueuePreview {
  constructor(
    /** Unique identifer of work queue. */
    public _id = ID_DEFAULT,
    /** Institution utilizing this work queue. */
    public _inst = ID_DEFAULT,
    /** Display name for work queue. */
    public name = '',
    /** Type of work queue. */
    public type = DisplayType.Account,

    /** Last build of this work queue. */
    public buildDate?: Date,
    /** Last worked date of queue. */
    public workDate?: Date,
    /** Number of items in built queue. */
    public count: number | undefined = undefined,
    /** Number of worked queue items. */
    public worked?: number
  ) {}
}