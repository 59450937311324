import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { PropertyType } from "../property-type";

/** An event that can be assigned and completed. */
export class EventReminder {
  constructor(
    /** User assigned to this event. */
    public _assigned?: string,
    /** Due date event must be completed. */
    public due?: Date,
    /** User that completed task. */
    public _completed?: string,
    /** Date that event was completed. */
    public done?: Date
  ) {}

  static typeinfo: TypeInfo<EventReminder> = {
    _assigned: ID_DEFAULT,
    due: new Date(),
    _completed: ID_DEFAULT,
    done: new Date()
  }

  static propinfo: PropertyInfo<EventReminder> = {
    _assigned: { type: PropertyType.User },
    _completed: { type: PropertyType.User }
  }
}

/** An update for an event. */
export class EventUpdate extends EventReminder {
  constructor(
    /** Event that was completed. */
    public _id = ID_DEFAULT
  ) {
    super();
  }

  static override typeinfo: TypeInfo<EventUpdate> = {
    ...EventReminder.typeinfo,
    done: new Date()
  }
}