import { Component, EventEmitter, Output } from "@angular/core";
import { Claim } from "../../../../../../../common/model/claim/claim";
import { Job } from "../../../../../../../common/model/job/job";
import { JobType } from "../../../../../../../common/model/job/payload";
import { MaybeId } from "../../../../../../../common/toolbox/id";

/** Interface that allows a component to configure a specific job type. */
@Component({ template: '' })
export abstract class JobAccessor<T extends JobType = JobType> {

  /** Emits when changes are made to payload. */
  @Output() dirty = new EventEmitter<void>();

  /** Top-level job being configured. */
  job!: MaybeId<Job<T>>;
  /** True if being configured as a personal notification. */
  personal = false;
  /** Claim being currently viewed, if applicable. */
  claim?: Claim;

  /** Called after configuration is bound. */
  reset() {}
}