import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { Institution, InstitutionPreview } from '../../../../../../common/model/institution';
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { InstitutionQuery, InstitutionService } from '../../../common/service/institution.service';
import { LogService } from '../../../common/service/log.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-institution',
  templateUrl: './setup-institution.component.html',
  styleUrls: ['./setup-institution.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupInstitutionComponent extends SetupComponent<Institution, InstitutionQuery, InstitutionPreview>{
  readonly Type = Institution;
  readonly collection = 'institutions';
  readonly route = 'institutions';
  resource = idMaybe(new Institution());

  static title() {
    return 'Institutions';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public http: HttpClient,
    public auth: AuthService,
    public service: InstitutionService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    this.resource = await this.service.item({ _id: this.auth._inst, _org: this.auth._org });
  }
}
