<form [formGroup]="builder" class="row fill" (ngSubmit)="search.next($any(builder.value))">
  <app-field class="fill">
    <app-label>Query</app-label>
    <button icon-button type="submit" [disabled]="!builder.valid">search</button>
    <input placeholder="Search" formControlName="query">
  </app-field>
  <app-field class="fill">
    <app-label>Category</app-label>
    <app-select formControlName="category">
      <app-option *ngFor="let c of list" [view]="c | codeType:auth._inst:CommonCode.SearchCategory | async" [value]="c"></app-option>
    </app-select>
  </app-field>
</form>