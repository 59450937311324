import { ElementRef } from "@angular/core";

/** Properties set on an element that supports debugging. */
export interface HTMLDebugElement extends HTMLElement {
  /** Callback to debug element */
  debug: () => Object
}

/** A component that can be debugged. */
export interface Debuggable {

  /** Reference to parent element. */
  elementRef: ElementRef
  
  /**
   *  Callback to debug component.
   *  @returns A value to display as code, or nothing if it internally debugs itself.
   */
  onDebug(): Object;
}

/** Check if a value is a debug element. */
export function debugElement(target: any): target is HTMLDebugElement {
  return !!target && target instanceof HTMLElement && 'debug' in target;
}

/** Programatically make an element debuggable. */
export function debugElementMake(component: Debuggable): HTMLDebugElement {
  let debug = component.elementRef.nativeElement as HTMLDebugElement;
  debug.debug = component.onDebug.bind(component);
  return debug;
}