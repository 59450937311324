import { NgModule } from '@angular/core';
import { BlockUnion } from "../../../../../common/model/formula/block";
import { ConditionType } from "../../../../../common/model/formula/condition";
import { OperatorType } from "../../../../../common/model/formula/operator";
import { PlaceholderType } from "../../../../../common/model/formula/placeholder";
import { StatementType } from "../../../../../common/model/formula/statement";
import { TerminalType } from "../../../../../common/model/formula/terminal";
import { Newable } from "../../../../../common/toolbox/object";
import { SharedModule } from '../../shared.module';
import { LedgerModule } from '../ledger/ledger.module';
import { SetupBaseBodyComponent } from './base/body/setup-base-body.component';
import { SetupBaseFooterComponent } from './base/footer/setup-base-footer.component';
import { SetupBaseHeaderComponent } from './base/header/setup-base-header.component';
import { SetupBaseComponent } from './base/setup-base.component';
import { SetupClaimFilterAssignedComponent } from './claim-filter/config/assigned/setup-claim-filter-assigned.component';
import { SetupClaimFilterClaimStatusComponent } from './claim-filter/config/claim-status/setup-claim-filter-claim-status.component';
import { SetupClaimFilterDisputeCreditStatusComponent } from './claim-filter/config/dispute-credit-status/setup-claim-filter-dispute-credit-status.component';
import { SetupClaimFilterDisputeStatusComponent } from './claim-filter/config/dispute-status/setup-claim-filter-dispute-status.component';
import { SetupClaimFilterEditComponent } from './claim-filter/edit/setup-claim-filter-edit.component';
import { SetupClaimFilterComponent } from './claim-filter/setup-claim-filter.component';
import { SetupCodeTypeEditComponent } from './code-type/edit/setup-code-type-edit.component';
import { SetupCodeTypeComponent } from './code-type/setup-code-type.component';
import { SetupCompareComponent } from './compare/setup-compare.component';
import { SetupDiscardDialogComponent } from './discard-dialog/setup-discard-dialog.component';
import { SetupDocumentTemplateEditComponent } from './document-template/edit/setup-document-template-edit.component';
import { SetupDocumentTemplateScanDialogComponent } from './document-template/scan/setup-document-template-scan-dialog.component';
import { SetupDocumentTemplateComponent } from './document-template/setup-document-template.component';
import { SetupFilterRuleEditComponent } from './filter-rule/edit/setup-filter-rule-edit.component';
import { SetupFilterRuleComponent } from './filter-rule/setup-filter-rule.component';
import { SetupFormListEditComponent } from './form-list/edit/setup-form-list-edit.component';
import { SetupFormListPreviewComponent } from './form-list/preview/setup-form-list-preview.component';
import { SetupFormListComponent } from './form-list/setup-form-list.component';
import { SetupFormEditComponent } from './form/edit/setup-form-edit.component';
import { SetupFormFieldBooleanComponent } from './form/field/boolean/setup-form-field-boolean.component';
import { SetupFormFieldCodeComponent } from './form/field/code/setup-form-field-code.component';
import { SetupFormFieldDialogComponent } from './form/field/dialog/setup-form-field-dialog.component';
import { SetupFormFieldEmptyComponent } from './form/field/empty/setup-form-field-empty.component';
import { SetupFormFieldStringComponent } from './form/field/string/setup-form-field-string.component';
import { SetupFormPreviewComponent } from './form/preview/setup-form-preview.component';
import { SetupFormComponent } from './form/setup-form.component';
import { BlockComponent } from './formula/block.component';
import { SetupFormulaBlockComponent } from './formula/block/setup-formula-block.component';
import { SetupFormulaConditionComponent } from './formula/condition/setup-formula-condition.component';
import { SetupFormulaConditionsComponent } from './formula/conditions/setup-formula-conditions.component';
import { SetupFormulaEditComponent } from './formula/edit/setup-formula-edit.component';
import { SetupFormulaBinaryComponent } from './formula/expression/binary/setup-formula-binary.component';
import { SetupFormulaLoopComponent } from './formula/expression/loop/setup-formula-loop.component';
import { SetupFormulaExpressionComponent } from './formula/expression/setup-formula-expression.component';
import { SetupFormulaTernaryComponent } from './formula/expression/ternary/setup-formula-ternary.component';
import { SetupFormulaUnaryComponent } from './formula/expression/unary/setup-formula-unary-expression.component';
import { SetupFormulaIndexComponent } from './formula/operator/index/setup-formula-index.component';
import { SetupFormulaPaletteComponent } from './formula/palette/setup-formula-palette.component';
import { SetupFormulaComponent } from './formula/setup-formula.component';
import { SetupFormulaSourceComponent } from './formula/source/setup-formula-source.component';
import { SetupFormulaAssignmentComponent } from './formula/statement/assignment/setup-formula-assignment.component';
import { SetupFormulaForComponent } from './formula/statement/for/setup-formula-for.component';
import { SetupFormulaIfComponent } from './formula/statement/if/setup-formula-if.component';
import { SetupFormulaReturnComponent } from './formula/statement/return/setup-formula-return.component';
import { SetupFormulaStatementComponent } from './formula/statement/setup-formula-statement.component';
import { SetupFormulaStatementsComponent } from './formula/statements/setup-formula-statements.component';
import { SetupFormulaArrayComponent } from './formula/terminal/array/setup-formula-array.component';
import { SetupFormulaBooleanComponent } from './formula/terminal/boolean/setup-formula-boolean.component';
import { SetupFormulaCodeComponent } from './formula/terminal/code/setup-formula-code.component';
import { SetupFormulaCurrencyComponent } from './formula/terminal/currency/setup-formula-currency.component';
import { SetupFormulaDateComponent } from './formula/terminal/date/setup-formula-date.component';
import { SetupFormulaIdentifierComponent } from './formula/terminal/identifier/setup-formula-identifier.component';
import { SetupFormulaNumberComponent } from './formula/terminal/number/setup-formula-number.component';
import { SetupFormulaPermissionComponent } from './formula/terminal/permission/setup-formula-permission.component';
import { SetupFormulaResourceComponent } from './formula/terminal/resource/setup-formula-resource.component';
import { SetupFormulaStringComponent } from './formula/terminal/string/setup-formula-string.component';
import { SetupFormulaUndefinedComponent } from './formula/terminal/undefined/setup-formula-undefined.component';
import { SetupInstitutionEditComponent } from './institution/edit/setup-institution-edit.component';
import { SetupInstitutionComponent } from './institution/setup-institution.component';
import { SetupJobEditComponent } from './job/edit/setup-job-edit.component';
import { SetupJobPayloadDebugEchoComponent } from './job/payload/debug-echo/setup-job-payload-debug-echo.component';
import { SetupJobPayloadImportComponent } from './job/payload/import/setup-job-payload-import.component';
import { SetupJobPayloadNotificationClaimDateComponent } from './job/payload/notification/claim-date/setup-job-payload-notification-claim-date.component';
import { SetupJobPayloadNotificationEventComponent } from './job/payload/notification/event/setup-job-payload-notification-event.component';
import { SetupJobPayloadNotificationComponent } from './job/payload/notification/setup-job-payload-notification.component';
import { SetupJobPayloadWorkQueueBuildComponent } from './job/payload/work-queue-build/setup-job-payload-work-queue-build.component';
import { SetupJobComponent } from './job/setup-job.component';
import { SetupLedgerConfigEditComponent } from './ledger-config/edit/setup-ledger-config-edit.component';
import { SetupLedgerConfigComponent } from './ledger-config/setup-ledger-config.component';
import { SetupListComponent } from './list/setup-list.component';
import { SetupModelEditComponent } from './model/edit/setup-model-edit.component';
import { SetupModelComponent } from './model/setup-model.component';
import { SetupPreviewButtonComponent } from './preview-button/setup-preview-button.component';
import { SetupProfileEditComponent } from './profile/edit/setup-profile-edit.component';
import { SetupProfileComponent } from './profile/setup-profile.component';
import { SetupPropertyListComponent } from './property-list/setup-property-list.component';
import { SetupSettingsComponent } from './settings/setup-settings.component';
import { SetupTableEditComponent } from './table/edit/setup-table-edit.component';
import { SetupTableComponent } from './table/setup-table.component';
import { SetupTaskConfigAttachmentComponent } from './task/config/attachment/setup-task-config-attachment.component';
import { SetupTaskConfigClaimReviewConfigComponent } from './task/config/claim/review/config/setup-task-config-claim-review-config.component';
import { SetupTaskConfigClaimReviewComponent } from './task/config/claim/review/setup-task-config-claim-review.component';
import { SetupTaskConfigDocumentTemplateComponent } from './task/config/document-template/setup-task-config-document-template.component';
import { SetupTaskConfigEmptyComponent } from './task/config/empty/setup-task-config-empty.component';
import { SetupTaskConfigFormListComponent } from './task/config/form-list/setup-task-config-form-list.component';
import { SetupTaskConfigFormComponent } from './task/config/form/setup-task-config-form.component';
import { SetupTaskEditComponent } from './task/edit/setup-task-edit.component';
import { SetupTaskComponent } from './task/setup-task.component';
import { SetupTriggerConfigEmptyComponent } from './trigger/config/empty/setup-trigger-config-empty.component';
import { SetupTriggerConfigFormulaRunComponent } from './trigger/config/formula-run/setup-trigger-config-formula-run.component';
import { SetupTriggerConfigLedgerAddComponent } from './trigger/config/ledger-add/setup-trigger-config-ledger-add.component';
import { SetupTriggerEditComponent } from './trigger/edit/setup-trigger-edit.component';
import { SetupTriggerComponent } from './trigger/setup-trigger.component';
import { SetupUserEditComponent } from './user/edit/setup-user-edit.component';
import { SetupUserPasswordDialogComponent } from './user/password-dialog/setup-user-password-dialog.component';
import { SetupUserComponent } from './user/setup-user.component';
import { SetupWorkQueueEditComponent } from './work-queue/edit/setup-work-queue-edit.component';
import { SetupWorkQueueComponent } from './work-queue/setup-work-queue.component';
import { SetupWorkflowActionComponent } from './workflow/action/setup-workflow-action.component';
import { SetupWorkflowActionsComponent } from './workflow/actions/setup-workflow-actions.component';
import { SetupWorkflowConditionAttachmentComponent } from './workflow/condition/attachment/setup-workflow-condition-attachment.component';
import { SetupWorkflowConditionBaseComponent } from './workflow/condition/base/setup-workflow-condition-base.component';
import { SetupWorkflowConditionClaimStatusComponent } from './workflow/condition/claim-stage/setup-workflow-condition-claim-status.component';
import { SetupWorkflowConditionDisputeStatusComponent } from './workflow/condition/dispute-stage/setup-workflow-condition-dispute-status.component';
import { SetupWorkflowConditionFormulaComponent } from './workflow/condition/formula/setup-workflow-condition-formula.component';
import { SetupWorkflowConditionComponent } from './workflow/condition/setup-workflow-condition.component';
import { SetupWorkflowConditionsComponent } from './workflow/conditions/setup-workflow-conditions.component';
import { SetupWorkflowEditComponent } from './workflow/edit/setup-workflow-edit.component';
import { SetupWorkflowComponent } from './workflow/setup-workflow.component';
import { SetupWorkflowStepComponent } from './workflow/step/setup-workflow-step.component';

/** Mapping of formula block types to components. */
export type BlockComponentMap = Record<BlockUnion, Newable<BlockComponent>> & { statements: Newable<SetupFormulaStatementsComponent> };

/** Mapping of types to block components. */
const BLOCK_COMPONENT_MAP: BlockComponentMap = {
  [TerminalType.Boolean]: SetupFormulaBooleanComponent,
  [TerminalType.Number]: SetupFormulaNumberComponent,
  [TerminalType.String]: SetupFormulaStringComponent,
  [TerminalType.Currency]: SetupFormulaCurrencyComponent,
  [TerminalType.Date]: SetupFormulaDateComponent,
  [TerminalType.Code]: SetupFormulaCodeComponent,
  [TerminalType.CodeList]: SetupFormulaCodeComponent,
  [TerminalType.Identifier]: SetupFormulaIdentifierComponent,
  [TerminalType.Array]: SetupFormulaArrayComponent,
  [TerminalType.Permission]: SetupFormulaPermissionComponent,
  [TerminalType.Resource]: SetupFormulaResourceComponent,
  [TerminalType.Undefined]: SetupFormulaUndefinedComponent,

  [OperatorType.Not]: SetupFormulaUnaryComponent,
  [OperatorType.Length]: SetupFormulaUnaryComponent,
  [OperatorType.Display]: SetupFormulaUnaryComponent,
  [OperatorType.Sum]: SetupFormulaUnaryComponent,
  [OperatorType.Map]: SetupFormulaLoopComponent,
  [OperatorType.Max]: SetupFormulaUnaryComponent,
  [OperatorType.Min]: SetupFormulaUnaryComponent,
  [OperatorType.Filter]: SetupFormulaLoopComponent,
  [OperatorType.Add]: SetupFormulaBinaryComponent,
  [OperatorType.Subtract]: SetupFormulaBinaryComponent,
  [OperatorType.Multiply]: SetupFormulaBinaryComponent,
  [OperatorType.Divide]: SetupFormulaBinaryComponent,
  [OperatorType.Access]: SetupFormulaIndexComponent,
  [OperatorType.Index]: SetupFormulaIndexComponent,
  [OperatorType.Join]: SetupFormulaBinaryComponent,
  [OperatorType.Pad]: SetupFormulaTernaryComponent,
  [OperatorType.Slice]: SetupFormulaTernaryComponent,
  [OperatorType.Granted]: SetupFormulaUnaryComponent,

  [ConditionType.Every]: SetupFormulaUnaryComponent,
  [ConditionType.Some]: SetupFormulaUnaryComponent,
  [ConditionType.And]: SetupFormulaBinaryComponent,
  [ConditionType.Or]: SetupFormulaBinaryComponent,
  [ConditionType.Equal]: SetupFormulaBinaryComponent,
  [ConditionType.Unequal]: SetupFormulaBinaryComponent,
  [ConditionType.Greater]: SetupFormulaBinaryComponent,
  [ConditionType.Lesser]: SetupFormulaBinaryComponent,
  [ConditionType.GreaterEqual]: SetupFormulaBinaryComponent,
  [ConditionType.LesserEqual]: SetupFormulaBinaryComponent,
  [ConditionType.Like]: SetupFormulaBinaryComponent, 
  [ConditionType.In]: SetupFormulaBinaryComponent,
  [ConditionType.Between]: SetupFormulaTernaryComponent,
  [ConditionType.Match]: SetupFormulaSourceComponent,

  [StatementType.Return]: SetupFormulaReturnComponent,
  [StatementType.If]: SetupFormulaIfComponent,
  [StatementType.For]: SetupFormulaForComponent,
  [StatementType.Assignment]: SetupFormulaAssignmentComponent,

  [PlaceholderType.Expression]: SetupFormulaExpressionComponent,
  [PlaceholderType.Statement]: SetupFormulaStatementComponent,
  [PlaceholderType.Condition]: SetupFormulaConditionComponent,

  statements: SetupFormulaStatementsComponent
};

@NgModule({
  declarations: [
    SetupBaseBodyComponent,
    SetupBaseComponent,
    SetupBaseFooterComponent,
    SetupBaseHeaderComponent,
    SetupClaimFilterAssignedComponent,
    SetupClaimFilterClaimStatusComponent,
    SetupClaimFilterComponent,
    SetupClaimFilterDisputeCreditStatusComponent,
    SetupClaimFilterDisputeStatusComponent,
    SetupClaimFilterEditComponent,
    SetupCodeTypeComponent,
    SetupCodeTypeEditComponent,
    SetupCompareComponent,
    SetupDiscardDialogComponent,
    SetupDocumentTemplateComponent,
    SetupDocumentTemplateEditComponent,
    SetupDocumentTemplateScanDialogComponent,
    SetupFilterRuleComponent,
    SetupFilterRuleEditComponent,
    SetupFormComponent,
    SetupFormEditComponent,
    SetupFormFieldBooleanComponent,
    SetupFormFieldCodeComponent,
    SetupFormFieldDialogComponent,
    SetupFormFieldEmptyComponent,
    SetupFormFieldStringComponent,
    SetupFormListComponent,
    SetupFormListEditComponent,
    SetupFormListPreviewComponent,
    SetupFormPreviewComponent,
    SetupFormulaArrayComponent,
    SetupFormulaBinaryComponent,
    SetupFormulaBlockComponent,
    SetupFormulaBooleanComponent,
    SetupFormulaCodeComponent,
    SetupFormulaComponent,
    SetupFormulaConditionComponent,
    SetupFormulaConditionsComponent,
    SetupFormulaCurrencyComponent,
    SetupFormulaDateComponent,
    SetupFormulaEditComponent,
    SetupFormulaExpressionComponent,
    SetupFormulaForComponent,
    SetupFormulaIdentifierComponent,
    SetupFormulaIfComponent,
    SetupFormulaIndexComponent,
    SetupFormulaLoopComponent,
    SetupFormulaNumberComponent,
    SetupFormulaPaletteComponent,
    SetupFormulaPermissionComponent,
    SetupFormulaResourceComponent,
    SetupFormulaReturnComponent,
    SetupFormulaSourceComponent,
    SetupFormulaStatementComponent,
    SetupFormulaStringComponent,
    SetupFormulaTernaryComponent,
    SetupFormulaUnaryComponent,
    SetupFormulaUndefinedComponent,
    SetupInstitutionComponent,
    SetupInstitutionEditComponent,
    SetupJobComponent,
    SetupJobEditComponent,
    SetupJobPayloadDebugEchoComponent,
    SetupJobPayloadImportComponent,
    SetupJobPayloadNotificationClaimDateComponent,
    SetupJobPayloadNotificationComponent,
    SetupJobPayloadNotificationEventComponent,
    SetupJobPayloadWorkQueueBuildComponent,
    SetupLedgerConfigComponent,
    SetupLedgerConfigEditComponent,
    SetupListComponent,
    SetupModelComponent,
    SetupModelEditComponent,
    SetupPreviewButtonComponent,
    SetupProfileComponent,
    SetupProfileEditComponent,
    SetupPropertyListComponent,
    SetupSettingsComponent,
    SetupTableComponent,
    SetupTableEditComponent,
    SetupTaskComponent,
    SetupTaskConfigAttachmentComponent,
    SetupTaskConfigClaimReviewComponent,
    SetupTaskConfigClaimReviewConfigComponent,
    SetupTaskConfigDocumentTemplateComponent,
    SetupTaskConfigEmptyComponent,
    SetupTaskConfigFormComponent,
    SetupTaskConfigFormListComponent,
    SetupTaskEditComponent,
    SetupTriggerComponent,
    SetupTriggerEditComponent,
    SetupTriggerConfigEmptyComponent,
    SetupTriggerConfigFormulaRunComponent,
    SetupTriggerConfigLedgerAddComponent,
    SetupUserComponent,
    SetupUserEditComponent,
    SetupUserPasswordDialogComponent,
    SetupWorkflowActionComponent,
    SetupWorkflowActionsComponent,
    SetupWorkflowComponent,
    SetupWorkflowConditionAttachmentComponent,
    SetupWorkflowConditionBaseComponent,
    SetupWorkflowConditionClaimStatusComponent,
    SetupWorkflowConditionComponent,
    SetupWorkflowConditionDisputeStatusComponent,
    SetupWorkflowConditionFormulaComponent,
    SetupWorkflowConditionsComponent,
    SetupWorkflowEditComponent,
    SetupWorkflowStepComponent,
    SetupWorkQueueComponent,
    SetupWorkQueueEditComponent
  ],
  imports: [
    LedgerModule,
    SharedModule
  ],
  providers: [
    { provide: 'BLOCK_COMPONENT_MAP', useValue: BLOCK_COMPONENT_MAP }
  ]
})
export class SetupModule { }
