import { EventEmitter } from "@angular/core";
import { Newable } from "../../../../../../common/toolbox/object";

/** Base mixin for all grid row types. */
export function GridBase<T extends Newable>(Base: T) {
  return class GridBase extends Base {
    
    /** True if row is member of multiselect table. */
    multiple = false;
    /** Emits when row is clicked. */
    clicked = new EventEmitter<this>();
    /** Emits when row is toggled. */
    toggled = new EventEmitter<this>();

    /** Toggle value of row. */
    toggle($event: MouseEvent) {
      $event.preventDefault();
      $event.stopPropagation();
      this.toggled.next(this);
    }
  }
}