<app-card>
  <app-card-header>Work Queues</app-card-header>
  <table app-grid [source]="source">

    <ng-container grid-column-def="name">
      <grid-header-cell *grid-header-cell-def>Name</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.name}}</grid-cell>
    </ng-container>

    <ng-container grid-column-def="buildDate">
      <grid-header-cell *grid-header-cell-def>Build Date</grid-header-cell>
      <grid-cell *grid-cell-def="let row">{{row.buildDate | dateIso}}</grid-cell>
    </ng-container>

    <ng-container grid-column-def="worked">
      <grid-header-cell *grid-header-cell-def>Progress</grid-header-cell>
      <grid-cell *grid-cell-def="let row"><app-progress [value]="row.worked" [max]="row.count"></app-progress></grid-cell>
    </ng-container>
    
    <grid-header-row *grid-header-row-def="columns"></grid-header-row>
    <grid-row class="selectable" *gridRowDef="let row; columns: columns;" (click)="onQueue(row)"></grid-row>
  </table>
  <app-grid-paginator [source]="source"></app-grid-paginator>
</app-card>