import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { Attachment } from "../attachment";
import { Dispute } from "../dispute/dispute";

/** An attachment on a claim with associated disputes. */
export class ClaimAttachment {
  constructor(
    /** The id of the attachment */
    public _attachment = ID_DEFAULT,
    /** The ids of any specific dispute(s) associated with this attachment */
    public _disputes?: string[]
  ) { }

  static typeinfo: TypeInfo<ClaimAttachment> = {
    _disputes: [ID_DEFAULT, ID_DEFAULT],
  }

  /** Check if a value is a claim attachment. */
  static check(value: any): value is ClaimAttachment {
    return (value instanceof Object) && '_attachment' in value;
  }

  /** Check if an attachment is associated with a particular dispute. */
  static for(attachment: ClaimAttachment, _dispute: string) {
    return !attachment._disputes?.length || attachment._disputes.includes(_dispute);
  }

  /** Get mapping of dispute IDs to their associated attachments. */
  static map(disputes?: Dispute[], attachments?: Attachment[], claimAttachments?: ClaimAttachment[]) {
    // Check if all values present first.
    let disputeMap = new Map<string, Attachment[]>();
    if (!disputes || !attachments || !claimAttachments) return disputeMap;

    // Get mapping of attachment IDs to attachments.
    let _allDisputes = disputes.map(d => d._id);
    let attachmentMap = new Map(attachments.map(a => [a._id, a]));

    // Look up attachment of each claim attachment.
    for (let claimAttachment of claimAttachments) {
      let _disputes = claimAttachment._disputes?.length ? claimAttachment._disputes : _allDisputes;
      for (let _dispute of _disputes) {
        let list = disputeMap.get(_dispute);
        if (!list) disputeMap.set(_dispute, list = []);

        let attachment = attachmentMap.get(claimAttachment._attachment);
        if (attachment) list.push(attachment);
      }
    }

    return disputeMap;
  }
}
