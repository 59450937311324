import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkAction } from '../../../../../../../common/model/work/flow';
import { WorkCondition } from '../../../../../../../common/model/work/condition';

@Component({
  selector: 'app-setup-workflow-action',
  templateUrl: './setup-workflow-action.component.html',
  styleUrls: ['./setup-workflow-action.component.scss']
})
export class SetupWorkflowActionComponent {
  readonly DEFAULT = new WorkAction();
  
  /** Actions being configured. */
  @Input() action = new WorkAction();

  /** Emits when a condition is selected or created. */
  @Output() condition = new EventEmitter<WorkCondition>();
}
