<app-card class="fill">
  <app-card-header>Statistics</app-card-header>
  <app-card-body>
    <app-chart class="fill" [xAxis]="xAxis" [yAxis]="yAxis" [categories]="categories" [series]="series"></app-chart>
    <div class="row">
      <div class="fill-4"></div>
      <app-label>Group By</app-label>
      <app-field class="fill">
        <app-select [(ngModel)]="groupKey" (selected)="render()">
          <app-option *ngFor="let key of groupKeys" [view]="key | propertyName:CLAIM_UNION" [value]="key"></app-option>
        </app-select>
      </app-field>
      <app-label>Category</app-label>
      <app-field class="fill">
        <app-select [(ngModel)]="reduceKey" (selected)="render()">
          <app-option *ngFor="let key of reduceKeys" [view]="key | titleCase" [value]="key"></app-option>
        </app-select>
      </app-field>
      <app-icon button-icon tooltip="Download Report" (click)="open()">download</app-icon>
    </div>
  </app-card-body>
</app-card>