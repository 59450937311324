import { Component } from '@angular/core';
import { FormulaRunTriggerConfig } from '../../../../../../../../common/model/trigger-config';
import { TriggerAccessor } from '../../edit/setup-trigger-edit.model';

@Component({
  selector: 'app-setup-trigger-config-formula-run',
  templateUrl: './setup-trigger-config-formula-run.component.html',
  styleUrls: ['./setup-trigger-config-formula-run.component.scss']
})
export class SetupTriggerConfigFormulaRunComponent extends TriggerAccessor<FormulaRunTriggerConfig> {}
