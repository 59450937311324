import { AttachmentType } from "../../../code/standard/common";
import { ClaimType, DisputeStatus } from "../../../code/standard/disputes";
import { CommonCode, DisputesCode } from "../../../code/system";
import { ConditionType } from "../../formula/condition";
import { OperatorType } from "../../formula/operator";
import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { BuiltinFormula } from "../formula";
import { ModelClaimNoticeDate } from "../model/disputes";
import { builtinFormulaModelKey, builtinFormulaSubKey } from "./formula";

/** Disputes formulas that operate on lists of disputes. */
export enum DisputesFormula {
  DeniedDateSet                    = 'Disputes - Set Denied Date',
  InvestigationCompletedDateSet    = 'Disputes - Set Investigation Completed Date',
  ProvisionalCreditDueDateSet      = 'Disputes - Set Provisional Credit Due Date',
  ProvisionalCreditNoticeDateSet   = 'Disputes - Set Provisional Credit Notice Date',
  RepresentmentDateSet             = 'Disputes - Set Representment Date',
  StatusChargebackSet              = 'Disputes - Set Chargeback Status',
  StatusDeniedSet                  = 'Disputes - Set Denied Status',
  StatusWithdrawnSet               = 'Disputes - Set Withdrawn Status',
  WrittenProvidedDateSet           = 'Disputes - Set Written Provided Date',
}

export const BUILTIN_DISPUTES_FORMULAS: Record<DisputesFormula, BuiltinFormula> = {
  [DisputesFormula.DeniedDateSet]: {
    statements: [{
      type: StatementType.For,
      expression: {
        type: TerminalType.Identifier,
        key: 'claim.disputes'
      },
      statements: [{
        type: StatementType.Assignment,
        left: {
          type: TerminalType.Identifier,
          key: builtinFormulaSubKey('claim.disputes', 'deniedDate')
        },
        right: {
          type: TerminalType.Date,
          value: 0
        }
      }]
    }]
  },
  [DisputesFormula.InvestigationCompletedDateSet]: {
    statements: [{
      type: StatementType.For,
      expression: {
        type: TerminalType.Identifier,
        key: 'claim.disputes'
      },
      statements: [{
        type: StatementType.Assignment,
        left: {
          type: TerminalType.Identifier,
          key: builtinFormulaSubKey('claim.disputes', 'investigationCompletedDate')
        },
        right: {
          type: TerminalType.Date,
          value: 0
        }
      }]
    }]
  },
  [DisputesFormula.ProvisionalCreditDueDateSet]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'dispute'
      },
      then: [{
        type: StatementType.Assignment,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.provisionalCreditDueDate'
        },
        right: {
          type: OperatorType.Add,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.reportDate'
          },
          right: {
            type: TerminalType.Number,
            value: 10
          }
        }
      }],
      else: [{
        type: StatementType.For,
        expression: {
          type: TerminalType.Identifier,
          key: 'claim.disputes'
        },
        statements: [{
          type: StatementType.Assignment,
          left: {
            type: TerminalType.Identifier,
            key: builtinFormulaSubKey('claim.disputes', 'provisionalCreditDueDate')
          },
          right: {
            type: OperatorType.Add,
            left: {
              type: TerminalType.Identifier,
              key: 'claim.reportDate'
            },
            right: {
              type: TerminalType.Number,
              value: 10
            }
          }
        }]
      }]
    }]
  },
  [DisputesFormula.ProvisionalCreditNoticeDateSet]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'dispute'
      },
      then: [{
        type: StatementType.Assignment,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.provisionalCreditNoticeDate'
        },
        right: {
          type: TerminalType.Identifier,
          key: builtinFormulaModelKey<ModelClaimNoticeDate>('date')
        }
      }],
      else: [{
        type: StatementType.For,
        expression: {
          type: TerminalType.Identifier,
          key: 'claim.disputes'
        },
        statements: [{
          type: StatementType.Assignment,
          left: {
            type: TerminalType.Identifier,
            key: builtinFormulaSubKey('claim.disputes', 'provisionalCreditNoticeDate')
          },
          right: {
            type: TerminalType.Identifier,
            key: builtinFormulaModelKey<ModelClaimNoticeDate>('date')
          }
        }]
      }]
    }]
  },
  [DisputesFormula.RepresentmentDateSet]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimType,
            value: ClaimType.Card
          }
        },
        right: {
          type: OperatorType.Length,
          left: {
            type: OperatorType.Filter,
            expression: {
              type: TerminalType.Identifier,
              key: 'uploads'
            },
            statements: [{
              type: StatementType.Return,
              expression: {
                type: ConditionType.Equal,
                left: {
                  type: TerminalType.Identifier,
                  key: builtinFormulaSubKey('uploads', 'type')
                },
                right: {
                  type: TerminalType.Code,
                  category: CommonCode.AttachmentType,
                  value: AttachmentType.ClaimRepresentment
                }
              }
            }]
          }
        }
      },
      then: [{
        type: StatementType.For,
        expression: {
          type: TerminalType.Identifier,
          key: 'claim.disputes'
        },
        statements: [{
          type: StatementType.If,
          if: {
            type: ConditionType.Equal,
            left: {
              type: TerminalType.Identifier,
              key: 'dispute.representmentDate'
            },
            right: {
              type: TerminalType.Undefined
            }
          },
          then: [{
            type: StatementType.Assignment,
            left: {
              type: TerminalType.Identifier,
              key: builtinFormulaSubKey('claim.disputes', 'representmentDate')
            },
            right: {
              type: TerminalType.Date,
              value: 0
            }
          }]
        }]
      }]
    }]
  },
  [DisputesFormula.StatusChargebackSet]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'dispute'
      },
      then: [{
        type: StatementType.Assignment,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.status'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.DisputeStatus,
          value: DisputeStatus.Chargeback
        }
      }],
      else: [{
        type: StatementType.For,
        expression: {
          type: TerminalType.Identifier,
          key: 'claim.disputes'
        },
        statements: [{
          type: StatementType.Assignment,
          left: {
            type: TerminalType.Identifier,
            key: builtinFormulaSubKey('claim.disputes', 'status')
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.DisputeStatus,
            value: DisputeStatus.Chargeback
          }
        }]
      }]
    }]
  },
  [DisputesFormula.StatusDeniedSet]: {
    statements: [{
      type: StatementType.For,
      expression: {
        type: TerminalType.Identifier,
        key: 'claim.disputes'
      },
      statements: [{
        type: StatementType.Assignment,
        left: {
          type: TerminalType.Identifier,
          key: builtinFormulaSubKey('claim.disputes', 'status')
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.DisputeStatus,
          value: DisputeStatus.Denied
        }
      }]
    }]
  },
  [DisputesFormula.StatusWithdrawnSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeStatus,
        value: DisputeStatus.Withdrawn
      }
    }]
  },
  [DisputesFormula.WrittenProvidedDateSet]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.And,
        left: {
          type: ConditionType.Equal,
          left: {
            type: TerminalType.Identifier,
            key: 'claim.type'
          },
          right: {
            type: TerminalType.Code,
            category: DisputesCode.ClaimType,
            value: ClaimType.Card
          }
        },
        right: {
          type: OperatorType.Length,
          left: {
            type: OperatorType.Filter,
            expression: {
              type: TerminalType.Identifier,
              key: 'uploads'
            },
            statements: [{
              type: StatementType.Return,
              expression: {
                type: ConditionType.Equal,
                left: {
                  type: TerminalType.Identifier,
                  key: builtinFormulaSubKey('uploads', 'type')
                },
                right: {
                  type: TerminalType.Code,
                  category: CommonCode.AttachmentType,
                  value: AttachmentType.ClaimIntakeSigned
                }
              }
            }]
          }
        }
      },
      then: [{
        type: StatementType.For,
        expression: {
          type: TerminalType.Identifier,
          key: 'claim.disputes'
        },
        statements: [{
          type: StatementType.If,
          if: {
            type: ConditionType.Equal,
            left: {
              type: TerminalType.Identifier,
              key: 'dispute.writtenProvidedDate'
            },
            right: {
              type: TerminalType.Undefined
            }
          },
          then: [{
            type: StatementType.Assignment,
            left: {
              type: TerminalType.Identifier,
              key: builtinFormulaSubKey('claim.disputes', 'writtenProvidedDate')
            },
            right: {
              type: TerminalType.Date,
              value: 0
            }
          }]
        }]
      }]
    }]
  }
};
