import { Component, Inject } from '@angular/core';
import { TAB_DATA } from '../tab/bar/tab-bar.model';
import { CodeTabData } from './code-tab.model';

@Component({
  selector: 'app-code-tab',
  templateUrl: './code-tab.component.html',
  styleUrls: ['./code-tab.component.scss']
})

export class CodeTabComponent {

  /** JSON data to display. */
  protected json = '';

  /** Default title for tab. */
  static title() {
    return 'View Data';
  }

  constructor(
    @Inject(TAB_DATA) public data: CodeTabData
  ) {
    this.json = JSON.stringify(data.value, null, '\t');
  }
}
