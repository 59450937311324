import { Pipe } from '@angular/core';
import { Event } from "../../../../../common/model/event";
import { EVENT_RESULT_DESCRIPTION } from '../../../../../common/model/event/result';
import { eventMapGet } from '../../../../../common/toolbox/event';
import { ExpressionPipe } from './expression.pipe';

@Pipe({
  name: 'eventDescription'
})
export class EventDescriptionPipe {

  constructor(
    private pipe: ExpressionPipe
  ) { }

  async transform(event: Event): Promise<string> {
    let format = event.description || eventMapGet(EVENT_RESULT_DESCRIPTION, event.result.category, event.result.type);
    return this.pipe.transform(format, event);
  }
}