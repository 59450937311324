import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { fieldControlProviders } from '../../toolbox/angular';
import { FieldControl } from '../field/field-control';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { RADIO_GROUP } from './radio-group.model';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  host: {
    '[class.horizontal]': 'horizontal'
  },
  providers: [
    ...fieldControlProviders(RadioGroupComponent),
    {
      provide: RADIO_GROUP,
      useExisting: RadioGroupComponent
    }
  ]
})
export class RadioGroupComponent extends FieldControl implements ControlValueAccessor {
  /** All radio buttons inside group. */
  @ContentChildren(RadioButtonComponent) radios!: QueryList<RadioButtonComponent>;

  /** True to display radio buttons horizontally. */
  @Input() horizontal = false;

  /** Sets bound value of group. */
  @Input()
  get value() { return this._value; }
  set value(value: any) {
    if (this._value === value) return;
    this._value = value;

    // Update checked value of radio buttons.
    this._selected = undefined;
    for (let radio of this.radios) {
      radio.checked = this.value === radio.value;
      if (radio.checked) this._selected = radio;
    }

    this.reselect();
  }

  /** Sets selected value of group. */
  @Input()
  get selected() { return this._selected; }
  set selected(selected: RadioButtonComponent | undefined) {
    this._selected = selected;
    this.value = selected?.value;
    this.reselect();
  }

  /** Current value of group. */
  _value?: any;
  /** Current selected radio button. */
  _selected?: RadioButtonComponent | undefined;

  writeValue(value: any) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.changed(this.value = value);
  }

  /** Update selected value of current radio button. */
  reselect() {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  /** Mark all radio buttons for check. */
  recheck() {
    for (let radio of this.radios.toArray()) radio.changes.markForCheck();
  }
}
