import { Component, Inject } from "@angular/core";
import { ErrorResponse } from "../../../../../../../common/message/error";
import { Block } from "../../../../../../../common/model/formula/block";
import { ColorCode } from "../../../../../../../common/toolbox/color";
import { expressionValidate } from "../../../../../../../common/toolbox/formula/expression";
import { objectReplace } from "../../../../../../../common/toolbox/object";
import { DialogService } from "../../../../common/component/dialog/dialog.service";
import { LogService } from "../../../../common/service/log.service";
import { DIALOG_CANCEL_SYMBOL } from "../../../../common/toolbox/dialog";
import { BlockComponentMap } from "../../setup.module";
import { BlockComponent } from "../block.component";

@Component({
  selector: 'app-setup-formula-source',
  templateUrl: './setup-formula-source.component.html',
  styleUrls: ['../block.component.scss', './setup-formula-source.component.scss'],
  host: { color: ColorCode.Primary }
})
export class SetupFormulaSourceComponent extends BlockComponent<Block> {

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private log: LogService,
    private dialog: DialogService
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  /** Callback when editing source of block. */
  async onCode() {
    const result = await this.dialog.source(this.block);
    if (result === DIALOG_CANCEL_SYMBOL) return;

    const type = this.block.type;
    try {
      const block = JSON.parse(result);
      const validation = expressionValidate(block);

      if (typeof validation === 'string') return this.log.show(`${type} key was invalid: ${validation}`);
      else if (Array.isArray(validation)) return this.log.show(`${validation}`);
      objectReplace(this.block, block);
    } catch (e: any) {
      this.log.show(new ErrorResponse(`Code was not valid ${type}: ${e.message}`));
    }
  }
}
