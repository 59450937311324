import { Component, Inject, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ClaimACHReportReason, ClaimCardCancellationOrReturnType, ClaimCardDetailsType, ClaimCardProcessingErrorType, ClaimCheckDetailsType, ClaimCheckReportReason, ClaimType } from '../../../../../../../common/code/standard/disputes';
import { ClaimACHDetailsBase } from '../../../../../../../common/model/claim/ach';
import { ClaimCardDetailsBase, ClaimCardDetailsCancellationOrReturn, ClaimCardDetailsProcessingError } from "../../../../../../../common/model/claim/card";
import { ClaimCheckDetailsBase } from '../../../../../../../common/model/claim/check';
import { ClaimDisputes } from '../../../../../../../common/model/claim/claim';
import { DisplayValue } from '../../../../../../../common/model/display';
import { claimTyped, claimUnion, claimUnjoin } from '../../../../../../../common/toolbox/claim';
import { NestedKey } from '../../../../../../../common/toolbox/keys';
import { FormChange, FormListConfig } from '../../../../common/component/form/form.model';
import { FormListComponent } from '../../../../common/component/form/list/form-list.component';
import { TAB_DATA } from '../../../../common/component/tab/bar/tab-bar.model';
import { FormListService } from '../../../../common/service/form-list.service';
import { SettingGroupService } from '../../../../common/service/setting-group.service';
import { CLAIM_INTAKE_STEP_STATE, ClaimIntakeState } from '../claim-intake.model';
import { ClaimIntakeStepComponent } from '../step/claim-intake-step.component';
import { ClaimIntakeDetailsData } from './claim-intake-details.model';

@Component({
  selector: 'app-claim-intake-details',
  templateUrl: './claim-intake-details.component.html',
  styleUrls: ['./claim-intake-details.component.scss']
})
export class ClaimIntakeDetailsComponent extends ClaimIntakeStepComponent<ClaimIntakeDetailsData> {

  @ViewChild(FormListComponent) formList!: FormListComponent;

  /** Configuration for displayed form list. */
  config?: FormListConfig;
  /** Emits when a given field is dirty. */
  dirty = new Subject<NestedKey<DisplayValue>[]>();

  /** Current viewed claim. */
  private get claim() { return this.config?.value?.claim ?? claimUnion(); }
  /** Mapping of check reasons to new types. */
  private checkTypes = new Map<string, ClaimCheckDetailsType>();

  constructor(
    @Inject(CLAIM_INTAKE_STEP_STATE) public state: ClaimIntakeState,
    @Inject(TAB_DATA) public override data: ClaimIntakeDetailsData,
    private formLists: FormListService,
    private settingGroups: SettingGroupService
  ) {
    super();
  }

  async ngOnInit() {
    this.config = {
      list: await this.formLists.setting(`disputes.${this.state.claim.type}.intake.formList`),
      dirty: this.dirty,
      mutate: true,
      value: { claim: claimUnion(this.state.claim, this.state.disputes, this.state.transactions) },
      transactions: this.state.transactions
    };

    switch (this.claim.type) {
      case ClaimType.ACH:
        if (this.config.value?.claim === undefined) this.onACHType(this.claim.achDetails.type);
        break;
      case ClaimType.Card:
        if (this.config.value?.claim === undefined) this.onCardType(this.claim.cardDetails.type);
        break;
      case ClaimType.Check:
        let settings = await this.settingGroups.inst();

        // Build mapping from check reasons to types.
        this.checkTypes = new Map();
        for (let code of settings.disputes.check.intake.forgeryReasonCodes ?? []) this.checkTypes.set(code, ClaimCheckDetailsType.Forgery);
        for (let code of settings.disputes.check.intake.unauthorizedReasonCodes ?? []) this.checkTypes.set(code, ClaimCheckDetailsType.Unauthorized);
        this.onCheckReason(this.claim.checkDetails.reason);
    }
  }

  /** Callback when submitting changes. */
  onSubmit() {
    if (!this.config?.value?.claim) return;
    this.state.claim = claimUnjoin(this.config.value?.claim) as ClaimDisputes;
    this.offset.next(+1);
  }

  /** Callback when changes are emitted by forms. */
  onChange({ key, value }: FormChange) {
    switch (key) {
    case 'claim.achDetails.type':
      this.onACHType(value);
      return;
    case 'claim.cardDetails.type':
      this.onCardType(value);
      return;
    case 'claim.cardDetails.processingErrorType':
      this.onProcessingErrorType(value);
      return;
    case 'claim.cardDetails.cancellationOrReturnType':
      this.onCancellationType(value);
      return;
    case 'claim.checkDetails.reason':
      this.onCheckReason(value);
    }
  }

  /** Callback when changing check reason. */
  private onCheckReason(reason: ClaimCheckReportReason) {
    if (!claimTyped(this.claim, ClaimType.Check)) return;
    let type = this.checkTypes.get(reason);
    if (!type) return;
    
    ClaimCheckDetailsBase.retype(this.claim.checkDetails, type);
    this.dirty.next(['claim.checkDetails']);
  }

  /** Callback when changing ACH report reason. */
  private onACHType(type: ClaimACHReportReason) {
    if (!claimTyped(this.claim, ClaimType.ACH)) return;

    this.claim.verify = false;
    ClaimACHDetailsBase.retype(this.claim.achDetails, type);
    this.dirty.next(['claim.verify', 'claim.achDetails']);
  }

  /** Callback when changing card type. */
  private onCardType(type: ClaimCardDetailsType) {
    if (!claimTyped(this.claim, ClaimType.Card)) return;
    this.claim.verify = false;
    ClaimCardDetailsBase.retype(this.claim.cardDetails, type);
    this.dirty.next(['claim.verify', 'claim.cardDetails']);
  }

  /** Callback when changing processing error type. */
  private onProcessingErrorType(type: ClaimCardProcessingErrorType) {
    if (!claimTyped(this.claim, ClaimType.Card)) return;
    (this.claim.cardDetails as ClaimCardDetailsProcessingError).processingErrorType = type;
    this.claim.verify = false;

    this.dirty.next(['claim.cardDetails.processingErrorType', 'claim.verify']);
  }

  /** Callback when changing cancellation type. */
  private onCancellationType(type: ClaimCardCancellationOrReturnType) {
    if (!claimTyped(this.claim, ClaimType.Card)) return;
    (this.claim.cardDetails as ClaimCardDetailsCancellationOrReturn).cancellationOrReturnType = type;
    this.claim.verify = false;

    this.dirty.next(['claim.cardDetails.cancellationOrReturnType', 'claim.verify']);
  }
}
