import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { WorkQueue, WorkQueuePreview } from "../../../../../../common/model/work/queue";
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { LogService } from '../../../common/service/log.service';
import { WorkQueueQuery, WorkQueueService } from '../../../common/service/work-queue.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-work-queue',
  templateUrl: './setup-work-queue.component.html',
  styleUrls: ['./setup-work-queue.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupWorkQueueComponent extends SetupComponent<WorkQueue, WorkQueueQuery, WorkQueuePreview> {
  readonly Type = WorkQueue;
  readonly collection = 'workQueues';
  readonly route = 'work-queues';
  resource = idMaybe(new WorkQueue());

  static title() {
    return 'Work Queues';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public http: HttpClient,
    public auth: AuthService,
    public service: WorkQueueService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }
}
