import { DisplayValue } from "../../../../../common/model/display";
import { ClaimFormResult } from "../../../../../common/model/event/result/claim";
import { LedgerItem } from "../../../../../common/model/ledger/item";
import { Transaction } from "../../../../../common/model/transaction";
import { accountUnion } from "../../../../../common/toolbox/account";
import { disputeUnion } from "../../../../../common/toolbox/dispute";
import { PickPartial } from "../../../../../common/toolbox/object";
import { TaskOpen } from "./task";

/** A display value with a required dispute and transaction. */
export type DisplayDispute = PickPartial<DisplayValue, 'account' | 'dispute' | 'transaction' | 'ledger'>;

/** Signal to open a dispute task. */
export class DisputeTaskOpen extends TaskOpen<ClaimFormResult> {}

/** Create a display value with a required dispute and transaction. */
export function displayDispute(): DisplayDispute {
  return { account: accountUnion(), dispute: disputeUnion(), transaction: new Transaction(), ledger: new LedgerItem() };
}