import { ErrorResponse } from "../message/error";
import { NestedKey, keyNestedGet, keyUnique } from "./keys";

/** Result of partitioning an array. */
type ArrayPartition<P = any, F = any> = [pass: P[], fail: F[]];

/** Get type of item in array. */
export type ArrayType<T> = T extends Array<infer I> ? I : never;

/** An array containing at least one item. */
export type ArraySome<T> = [T, ...T[]];

/** Grouping of T by keyof T */
export interface Group<T> { [key: string]: T[] };

/** Iterator over an array-like object. */
export class ArrayIterator<T> implements Iterator<T> {
  /** Current iteration position. */
  private i = 0;

  constructor(
    /** Array being iterated over. */
    private array: ArrayLike<T>
  ) {}

  next() {
    if (this.i === this.array.length) return {
      done: true,
      value: undefined as any
    }

    return {
      done: false,
      value: this.array[this.i++]!
    };
  }
}

/** Count instances of a value in an array. */
export function arrayInstances<T>(list: T[]): Map<T, number> {
  let map = new Map<T, number>();

  for (let item of list) {
    if (map.has(item)) {
      map.set(item, map.get(item)! + 1);
    } else {
      map.set(item, 1);
    }
  }

  return map;
}

/** Concatenate arrays together. */
export function arrayConcat<T>(...arrays: (undefined | T[])[]) {
  let out: T[] = [];
  for (let array of arrays) out.push(...array ?? []);
  return out;
}

/** Remove a value from an array.
 *  @returns Removed value if found.
*/
export function arrayRemove<T>(list: T[], value: T): T | void {
  for (let i = list.length - 1; i >= 0; --i) {
    if (list[i] === value) {
      list.splice(i, 1);
      return value;
    }
  }
}

/** Determine if two arrays are equal. */
export function arrayEqual<T>(a: T[] = [], b: T[] = []) {
  if (a.length !== b.length) return false;

  let [sa, sb] = [[...a].sort(), [...b].sort()];
  for (let i = 0; i < a.length; ++i) {
    if (sa[i] !== sb[i]) return false;
  }

  return true;
}

/** Coerce a value into an array. */
export function arrayCoerce<T>(value?: T | T[] | Set<T>): T[] {
  if (value instanceof Set) return [...value];
  if (Array.isArray(value)) return value;
  if (value === undefined) return [];
  return [value];
}

/** Check that an object is array-like. */
export function arrayLike(array: any): array is ArrayLike<any> {
  return  array instanceof Object
          && typeof array.length === 'number'
          && (array.length === 0 || (array.length > 0 && (array.length - 1) in array));
}

/** Check that array is empty. */
export function arrayEmpty<T>(array?: T[]): array is [] {
  return Array.isArray(array) && array.length === 0;
}

/** Check that array has single value. */
export function arraySingle<T>(array?: T | T[]): array is [T] {
  return Array.isArray(array) && array.length === 1;
}

/** Check that array is pair of values. */
export function arrayPair<T>(array?: T[]): array is [T, T] {
  return Array.isArray(array) && array.length === 2;
}

/** Check that array has a nonezero number of values. If a predicate is provided, check that at least one value in the array satisifies the predicate */
export function arraySome<T>(array?: T | T[], predicate?: (value: T) => boolean): array is ArraySome<T> {
  if (!Array.isArray(array) || !array.length) return false;
  if (!predicate) return true;

  for (let elt of array)
    if (predicate(elt)) return true;

  return false;
}

/** Get nth array item, implementing wrapping behavior. */
export function arrayWrap<T>(array: ArraySome<T>, i: number) {
  return array[i % array.length] as T;
}

/** Get last entry of array, type-checking guarenteed size arrays. */
export function arrayLast<T>(array: ArraySome<T>): T
export function arrayLast<T>(array?: T[]): T | undefined
export function arrayLast<T>(array?: T[]): T | undefined {
  if (!array) return undefined;
  return array[array.length - 1];
}

/** Check if a value is an array of a certain type. */
export function arrayOf<A, B>(array: (A | B)[], predicate: (value: A | B) => value is A): array is A[]
export function arrayOf(array: any[], predicate: (value: any) => boolean): boolean {
  return array.every(predicate);
}

/** Check that array has multiple values. */
export function arrayMany<T>(array?: T[]): array is [T, T, ...T[]] {
  return Array.isArray(array) && array.length > 1;
}

/** Filter out undefined items of array. */
export function arrayDefined<T>(array: (T | undefined | null | void)[]): T[] {
  return array.filter(v => v !== undefined && v !== null) as T[];
}

/** Repeat an array until specified size is reached. */
export function arrayRepeat<T>(array: T[], length: number): T[] {
  if (!array.length) return array;

  let out: T[] = [];
  while (out.length < length) {
    out.push(...array);
  }

  return out.slice(0, length);
}

/** Split array into items passing and failing a condition. */
export function arrayPartition<T, V extends T>(array: Iterable<T>, filter: (item: T) => item is V): ArrayPartition<V, unknown>
export function arrayPartition<P, F>(array: Iterable<P | F>, filter: (item: P | F) => boolean): ArrayPartition<P, F>
export function arrayPartition(array: Iterable<any>, filter: (item: any) => boolean): ArrayPartition {
  let [pass, fail]: ArrayPartition = [[], []];

  for (let item of array) {
    (filter(item) ? pass : fail).push(item);
  }

  return [pass, fail];
}

/** Split array into new types. */
export function arraySplit<T, A, B>(array: T[], leftFilter: (item: T) => A[], rightFilter: (item: T) => B[]): ArrayPartition<A, B> {
  let list: ArrayPartition<A, B> = [[], []];

  for (let item of array) {
    list[0].push(...leftFilter(item));
    list[1].push(...rightFilter(item));
  }

  return list;
}

/** Create an array filled with given objects. */
export function arrayFill<T>(length: number, callback: (i: number) => T): T[] {
  return Array.from({ length }, (_, i) => callback(i));
}

/** Create an array of unique values. */
export function arraySet<T>(array?: T[]): T[] {
  return [...new Set(array)];
}

/** Filter out unique array values based on a unique key. */
export function arrayUnique<T>(array: T[], ...keys: (keyof T)[]): T[] {
  let seen = new Set<any>();
  let unique: T[] = [];

  for (let item of array) {
    let key = keyUnique(item, keys);
    if (seen.has(key)) continue;
    seen.add(key);
    unique.push(item);
  }

  return unique;
}

/** Filter out unique array values based on a set of unique nested keys. */
export function arrayUniqueNested<T>(array: T[], ...keys: NestedKey<T>[]): T[] {
  let seen = new Set<string>();
  let unique: T[] = [];

  for (let item of array) {
    let key = keys.map(key => keyNestedGet(key, item)).join('_');
    if (seen.has(key)) continue;
    unique.push(item);
    seen.add(key);
  }

  return unique;
}

/** Get sum of a list of numbers. */
export function arraySum(values?: number[]) {
  if (!Array.isArray(values) || values.length === 0) return +(values ?? 0);
  let total = 0;
  if (!values) return total;
  for (let value of values) total += +value;
  return total;
}

export function arrayFlatten(values?: unknown[]) {
  return (values ?? []).flat()
}

/** Convert list of items to percentage, rounding off.
 *  @see https://stackoverflow.com/a/13483710
 */
export function arrayPercentRound(values: number[]): number[] {
  // Calculate floored percentage of items and remainder.
  let sum = arraySum(values);
  let items = values.map((v, i) => ({ p: 100 * v / sum, i }));
  let remainder = items.map(i => Math.floor(i.p)).reduce((total, n) => total - n, 100);

  // Sort by highest to lowest remainder and distribute remainder.
  items.sort((a, b) => (b.p % 1 - a.p % 1));
  items.forEach(i => { i.p += remainder ? 1 : 0; remainder = Math.max(0, remainder - 1) });
  return items.sort((a, b) => a.i - b.i).map(i => Math.floor(i.p));
}

/** Get the values in common between two arrays */
export function arrayIntersect(first: (string | number)[], second: (string | number)[]) {
  let map: any = first.reduce((accum, curr) => { accum[curr] = true; return accum }, {} as any);
  return second.filter((val) => map[val]);
}

/** Split in array into chunks of max size. */
export function arrayChunk<T>(array: T[], chunkSize: number): T[][] {
  return array.reduce((result: T[][], item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)
    let currentChunk = result[chunkIndex];
    if (!currentChunk) {
      currentChunk = []; // start a new chunk
      result[chunkIndex] = currentChunk;
    }

    currentChunk.push(item)

    return result;
  }, []);
}

/** Creates a grouping of T[] by a given key of T */
export function groupBy<T>(array: T[], predicate: (value: T, index: number, array: T[]) => string): Group<T> {
  return array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });
}

/** Count the number of elements in an array that match a given condition, if no condition is provided, the length is returned */
export function arrayCount<T>(array: T[], predicate?: (value: T) => boolean): number {
  if (!predicate) return array.length;

  let count = 0;
  for (let elt of array)
    if (predicate(elt)) count++;

  return count;
}

/** Map an array of T to one of its properties */
export function arrayExtract<T>(array: T[], key: NestedKey<T>): unknown[] {
  if (!Array.isArray(array) || typeof key !== 'string') return [];
  return array.map(item => keyNestedGet<T>(key, item));
}

/** Return largest element in array. */
export function arrayMax<T>(array: ArraySome<T>): T {
  if (!Array.isArray(array) || array.length===0) return array as unknown as T;
  let max = array[0];
  for (let el of array) {
    if (+el > +max || isNaN(+max)) max = el;
  }
  return max;
}

/** Return smallest element in array. */
export function arrayMin<T>(array: ArraySome<T>): T {
  if (!Array.isArray(array) || array.length === 0) return array as unknown as T;
  let min = array[0];
  for (let el of array) {
    if (+el < +min || isNaN(+min)) min = el;
  }
  return min;
}

/** Get a list of values outside of available range. */
export function arrayOutsideRange(start: number, end: number, startAvailable: number, endAvailable: number): number[] {
  if (start < end) return []

  let list: number[] = [];
  for (let i = start; i <= end; ++i) {
      if (i < startAvailable || i > endAvailable) list.push(i)
  }

  return list
}

/** Return true if value exists in a sorted array */
export function arraySortedHas(array: number[], value: number): boolean {
  let start = 0;
  let end = array.length - 1;
  while (start <= end) {
    let i = (end + start) >> 1;
    if (value > array[i]!) start = i + 1;
    else if (value < array[i]!) end = i - 1;
    else return true;
  }
  return false;
}

/** Search for first index in sorted array less than or equal to value. */
export function arraySortedIndex(array: number[], value: number, start = 0, end = array.length - 1): number | undefined {
  if (start == end)  return array[start]! <= value ? start : undefined;
  let mid = start + (end - start) / 2 | 0;

  if (value < array[mid]!) return arraySortedIndex(array, value, start, mid);

  let index = arraySortedIndex(array, value, mid + 1, end);
  return index == undefined ? mid : index;
}

/** Reorder an array given a list of elements. */
export function arrayOrder<T extends number | string>(array: T[], order: T[]): T[] | ErrorResponse {
  if (array.length !== order.length) return new ErrorResponse(`Invalid order length: ${order.length} (expected: ${array.length})`, undefined, { array, order });
  let set = new Set(order);

  for (let value of array) {
    if (!set.has(value)) return new ErrorResponse(`Order missing element: ${value}`);
  }

  return order;
}

/** Reorder an array given a unique key. */
export function arrayOrderKey<T, K extends keyof T>(array: T[], key: K, order: T[K][]): T[] | ErrorResponse {
  if (array.length !== order.length) return new ErrorResponse(`Invalid order length: ${order.length} (expected: ${array.length})`, undefined, { array, order, key });
  let map = new Map(order.map((o, i) => [o, i]));
  let out: T[] = [];

  for (let value of array) {
    let index = map.get(value[key]);
    if (index === undefined) return new ErrorResponse(`Element missing from order: ${value[key]}`);
    out[index] = value;
  }

  return out;
}