import { Pipe } from '@angular/core';
import { TaskAccount, TaskClaim, TaskData, TaskDispute } from '../../module/task/task.model';

/** Check if task context is particular type. */
@Pipe({ name: 'taskData' })
export class TaskDataPipe {

  transform(data: TaskData, key: 'account'): data is TaskAccount
  transform(data: TaskData, key: 'claim'): data is TaskClaim
  transform(data: TaskData, key: 'dispute'): data is TaskDispute
  transform(data: TaskData, key: string): boolean {
    return key in data;
  }
}
