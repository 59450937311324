import { LedgerItem } from "../../../../../../common/model/ledger/item";
import { NoIdInst, idInstOmit } from "../../../../../../common/toolbox/id";
import { displayDispute } from "../../../common/toolbox/dispute";


export class LedgerWithPostInfo {
  constructor(
    public ledger: NoIdInst<LedgerItem> = idInstOmit(new LedgerItem()),
    public shouldPost: boolean = false
  ) { }
}

/** State of ledger list. */
export class LedgerListState {
  constructor(
    /** Current context being viewed. */
    public partial = displayDispute(),
    /** List of configs to display in dropdowns. */
    public _filter?: string[],
    /** List of pending ledger items. */
    public ledger: LedgerWithPostInfo[] = []
  ) { }
}