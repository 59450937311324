import { CdkColumnDef, CdkHeaderCell } from "@angular/cdk/table";
import { Component, ElementRef, Input } from "@angular/core";
import { Subject } from "rxjs";
import { Direction } from "../../../../../../../common/model/direction";
import { NestedKey } from "../../../../../../../common/toolbox/keys";
import { SEARCH_REGEX, searchQueryPattern } from "../../../../../../../common/toolbox/search";
import { ColumnFilter, ColumnSort, ColumnToggle, directionNext } from "../../../toolbox/grid";
import { GridComponent } from "../grid.component";

@Component({
  selector: 'grid-header-cell',
  styleUrls: ['./grid-header-cell.component.scss'],
  templateUrl: './grid-header-cell.component.html',
  host: {
    'role': 'columnheader',
    '(click)': 'toggle()'
  }
})
export class GridHeaderCell<T> extends CdkHeaderCell {

  /** Whether the header should be centered */
  @Input() centered = true;
  /** Key of this cell */
  @Input() key?: NestedKey<T>;
  /** Whether to use the name as a key */
  @Input() useNameAsKey = true;

  /** current sort direction of this column. */
  direction?: Direction;
  /** Current filter of this column. */
  filter = '';
  
  /** Emits to toggle visibility of filter. */
  toggled = new Subject<ColumnToggle<T>>();
  /** Emits whenever sorting of this column changes. */
  sorted = new Subject<ColumnSort<T>>();
  /** Emits whenever filter of this column changes. */
  filtered = new Subject<ColumnFilter<T>>();

  /** Getter for strongly typed column. */
  get column() {
    if (this.useNameAsKey) return this.columnDef.name as NestedKey<T>;
    else return this.key ?? this.columnDef.name as NestedKey<T>;
  }

  constructor(
    public columnDef: CdkColumnDef,
    public elementRef: ElementRef<any>,
    public grid: GridComponent
  ) {
    super(columnDef, elementRef);
  }

  /** Callback when retrieving input in field. */
  onInput(text = '') {
    this.filter = searchQueryPattern(SEARCH_REGEX, `${text}`);
    this.filtered.next({ column: this.column, filter: this.filter });
  }

  /** Toggle visibility of cell. */
  toggle() {
    this.toggled.next({ column: this.column });
  }

  /** Called when current sort changes. */
  resort() {
    this.direction = directionNext(this.direction);
    this.sorted.next({ column: this.column, direction: this.direction });
  }
}