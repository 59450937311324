import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile, ProfilePreview } from "../../../../../common/model/profile";
import { ArraySome } from '../../../../../common/toolbox/array';
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { LogService } from './log.service';

/** A query to fetch a specific profile. */
export class ProfileQuery {
  constructor(
    /** ID of profile. */
    public _id = ID_DEFAULT,
    /** Institution of profile. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Caches information about tasks. */
@Injectable({
  providedIn: 'root'
})
export class ProfileService extends CachePreviewService<Profile, ProfileQuery, ProfilePreview> {
  readonly route = 'profiles/preview';
  readonly Type = Profile;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient
  ) {
    super(ProfileQuery, log, dialog, http);
  }

  protected override multiple(queries: ArraySome<ProfileQuery>) {
    return getRequest(this.http, 'profiles', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}
