import { CollectionInfo } from "../../info/collection";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { permissionArray, permissionMask } from "../../toolbox/permission";
import { EnumValidator } from "../../validator/enum";
import { FusionCollection } from "../fusion";
import { PERMISSION_MASK_DEFAULT, Permission, PermissionMask } from "../permission";

/** Access user has been granted in an institution. */
export class UserGrant {
  constructor(
    /** Institution this grant is for. */
    public _inst = ID_DEFAULT,
    /** Profile within this institution. */
    public _profile = ID_DEFAULT,
    /** Assigned work queues of user. */
    public _queues: string[] = [],
    /** Permissions for this user. */
    public permissions: Permission[] = []
  ) {}

  static typeinfo: TypeInfo<UserGrant> = {
    _queues: [ID_DEFAULT],
    permissions: [new EnumValidator(Permission)]
  };

  static collectioninfo: CollectionInfo<FusionCollection, UserGrant> = {
    _profile: 'profiles',
    _queues: 'workQueues'
  }

  /** Construct from a session's grants. */
  static from(grant: SessionGrant): UserGrant {
    return {
      _inst: grant._inst,
      _profile: grant._profile,
      _queues: grant._queues,
      permissions: permissionArray(grant.permissions)
    };
  }
}

/** User grants in a compact session token format. */
export class SessionGrant {
  constructor(
    /** Institution this grant is for. */
    public _inst = ID_DEFAULT,
    /** Profile within this institution. */
    public _profile = ID_DEFAULT,
    /** Assigned work queues of user. */
    public _queues: string[] = [],
    /** Permissions for this user. */
    public permissions: PermissionMask = PERMISSION_MASK_DEFAULT
  ) {}

  /** Construct from a user's grants. */
  static from(grant: UserGrant): SessionGrant {
    return {
      _inst: grant._inst,
      _profile: grant._profile,
      _queues: grant._queues,
      permissions: permissionMask(grant.permissions)
    };
  }
}