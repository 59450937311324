import { IndexInfo, indexinfoOf } from ".";
import { Property, propertyFrom } from "../model/property";
import { PropertyType } from "../model/property-type";
import { arrayDefined, arrayLike, arraySet, arraySome } from "../toolbox/array";
import { NestedKey } from "../toolbox/keys";
import { objectValues, safeAssign } from "../toolbox/object";
import { Validator } from "../validator/base";
import { UnionValidator } from "../validator/union";
import { infoOf } from "./base";
import { CollectionInfo, collectioninfoOf } from "./collection";
import { QueryInfo, queryinfoOf } from "./query";
import { SchemaInfo, schemainfoOf } from "./schema";
import { TypeInfo, typeinfoOf, typeinfoValue } from "./type";

/** Model property annotations manually added to object. */
export type PropertyInfo<T> = {
  [K in keyof T]?: Partial<Property>
}

/** Merge propinfo of multiple classes and create dummy class. */
export function propInfoMerge<A>(a: A): A;
export function propInfoMerge<A, B>(a: A, b: B): A | B;
export function propInfoMerge<A, B, C>(a: A, b: B, c: C): A | B | C;
export function propInfoMerge<A, B, C, D>(a: A, b: B, c: C, d: D): A | B | C | D;
export function propInfoMerge(...types: any[]): any;
export function propInfoMerge(...types: any[]): any {
  let Prototype = class {
    constructor() { safeAssign(this, ...types); }
    static typeinfo: TypeInfo<any> = {}
    static propinfo: PropertyInfo<any> = {}
    static schemainfo: SchemaInfo<any> = {}
    static indexinfo: IndexInfo<any> = []
    static queryinfo: QueryInfo<any> = {}
    static collectioninfo: CollectionInfo<any, any> = {}
  };

  // Inherit type annotations from classes.
  let instance = new Prototype();
  let typeinfo = typeinfoOf(instance);
  let propinfo = propinfoOf(instance);
  let schemainfo = schemainfoOf(instance);
  let indexinfo = indexinfoOf(instance);
  let queryinfo = queryinfoOf(instance);
  let collectioninfo = collectioninfoOf(instance);

  for (let type of types) {
    safeAssign(typeinfo, typeinfoOf(type));
    safeAssign(propinfo, propinfoOf(type));
    safeAssign(schemainfo, schemainfoOf(type));
    safeAssign(queryinfo, queryinfoOf(type));
    safeAssign(collectioninfo, collectioninfoOf(type));

    // Prevent merging index info to avoid duplicate indexes.
    let subinfo = indexinfoOf(type);
    if (subinfo.length) {
      indexinfo.length = 0;
      indexinfo.push(...subinfo);
    }
  }

  return instance;
}

/** Pull propinfo from object. */
export function propinfoOf<T>(value: T): PropertyInfo<T> {
  return infoOf(value, 'propinfo');
}

/** Pull nested propinfo from object. */
export function propinfoProperty<T>(root: NestedKey<T>, context: T): Property | undefined {
  return propinfoPropertyDeep(root, (root as string).split('.'), undefined, context);
}

/** Recursively get nested propinfo of object. */
function propinfoPropertyDeep(root: string, path: string[], name: string | undefined, context: any): Property | undefined {
  if (arrayLike(context) && context[0]) context = context[0];
  let typeinfo = typeinfoOf(context);
  let propinfo = propinfoOf(context);
  let key = path[0]!;

  if (path[1]) {
    // Recurse into object.
    if (context instanceof Object) {
      path.shift();
      name = propertyFrom(propinfo, typeinfo, context, key, name).name;
      return propinfoPropertyDeep(root, path, name, typeinfo[key] ?? context[key]);
    }
  } else if (context instanceof UnionValidator) {
    // Treat merged object as context.
    return propinfoPropertyDeep(root, path, name, context.merged);
  } else if (context instanceof Object) {
    // Create property for this field.
    let property = propertyFrom(propinfo, typeinfo, context, key, name);
    property.required = context[key] !== undefined;
    property.key = root;
    return property;
  }

  return undefined;
}

/** Get all properties from object. */
export function propinfoProperties(...objects: any[]): Property[] {
  let properties = new Map<string, Property>();

  for (let object of objects) {
    let path: string[] = [];
    propinfoPropertiesDeep(object, properties, path, undefined);
  }

  return [...properties.values()];
}

/** Recursively get all properties from object of a given type. */
function propinfoPropertiesDeep(object: any, properties: Map<string, Property>, path: string[], name: string | undefined): void {
  if (!(object instanceof Object)) return;
    
  if (object instanceof UnionValidator) {
    // Handle nested union info.
    for (let subvalue of objectValues(object.unioninfo.classes)) {
      propinfoPropertiesDeep(subvalue, properties, path, name);
    } return;
  } else if (object instanceof Validator) {
    return propinfoPropertiesDeep(object.value(), properties, path, name);
  } else if (arraySome(object)) {
    return propinfoPropertiesDeep(object[0], properties, path, name);
  }

  let typeinfo = typeinfoOf(object);
  let propinfo = propinfoOf(object);

  for (let key in object) {
    // Prevent registering duplicate properties with same key.
    path.push(key);
    let subkey = path.join('.');
    if (properties.has(subkey)) {
      path.pop();
      continue;
    }

    // Add property to list.
    let property = propertyFrom(propinfo, typeinfo, object, key, name);
    properties.set(subkey, property);
    property.key = subkey;

    // Recursively traverse object.
    let value = typeinfoValue(object, key, typeinfo);
    propinfoPropertiesDeep(value, properties, path, property.name);
    path.pop();
  }
}

/** Pull code types from object. */
export function propinfoCodes(...objects: any[]): string[] {
  return arraySet(arrayDefined(propinfoProperties(...objects).map(p => p.type === PropertyType.Code ? p.category : undefined)));
}