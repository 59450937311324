import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";

/** Validate object is boolean. */
export class BooleanValidator extends Validator<boolean> {

  value() {
    return true;
  }

  parse(value: string) {
    if (this.undefined(value)) return undefined;
    return { true: true, false: false }[value];
  }

  schema() {
    return this.property ?? { bsonType: 'bool' };
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    return typeof value === 'boolean' ? ValidationStatus.Okay : ValidationStatus.Error;
  }

  override logs() { return [new ValidatorLog('Expected boolean.')]; }
}