import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { propinfoProperty } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { ErrorResponse } from "../../message/error";
import { arrayDefined, arraySet } from "../../toolbox/array";
import { BuiltinMap } from "../../toolbox/builtinmap";
import { blockCodes } from "../../toolbox/formula/block";
import { ID_DEFAULT, MaybeId, NoIdInst } from "../../toolbox/id";
import { errorPartition, errorResponse } from "../../toolbox/message";
import { deepCopy } from "../../toolbox/object";
import { EnumValidator } from "../../validator/enum";
import { Box } from "../box";
import { SystemFormula } from "../builtin/formula";
import { Display, DisplayType, DisplayValue } from "../display";
import { Formula } from "../formula/formula";
import { FusionCollection } from "../fusion";
import { Model } from "../model";
import { Pos } from "../pos";
import { PropertyType } from "../property-type";
import { Field } from "./field";

/** Configuration for creating a form. */
export interface FormTemplate {
  _inst: string;
  name: string;
  model?: Model;
  formulas?: SystemFormula[];
  size?: Pos;
  editable?: DisplayType[];
  fields: Field[];
  builtins?: BuiltinMap
}

/** Configuration for editing a model. */
export class Form {
  /** Minimum size for a form. */
  static readonly SIZE_MIN = new Pos(1, 1);
  /** Maximum size for a form. */
  static readonly SIZE_MAX = new Pos(5, 15);
  /** Default size for new forms. */
  static readonly SIZE_DEFAULT = new Pos(3, 5);

  constructor(
    /** Unique identifier for this form. */
    public _id = ID_DEFAULT,
    /** Institution configuring this form. */
    public _inst = ID_DEFAULT,
    /** Model this form is bound to. */
    public _model?: string,
    /** Formulas to execute upon first displaying form. */
    public _formulas?: string[],
    /** Title for this form. */
    public name = '',
    /** Size of this form */
    public size = Pos.from(Form.SIZE_DEFAULT),
    /** Which values in form should be editable. */
    public editable?: DisplayType[],
    /** True if this is a system form and cannot be modified. */
    public system?: boolean,
    /** True if form has been edited */
    public dirty?: boolean,
    /** List of fields on this form. */
    public fields: Field[] = []
  ) {}

  static typeinfo: TypeInfo<Form> = {
    _model: ID_DEFAULT,
    _formulas: [ID_DEFAULT],
    editable: [new EnumValidator(DisplayType)],
    system: false,
    dirty: false,
    fields: [new Field()]
  }

  static collectioninfo: CollectionInfo<FusionCollection, Form> = {
    _formulas: 'formulas',
    _inst: 'institutions',
    _model: 'models'
  };

  static indexinfo: IndexInfo<Form> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
  
  /** Create form configuration from a model. */
  static from(template: Omit<Form, '_id' | 'dirty' | 'system'>, builtins?: BuiltinMap): Form | ErrorResponse {
    let _inst = template._inst;
    let form = new Form();

    form._inst = _inst;
    form._model = template._model;
    form._formulas = template._formulas;
    form.name = template.name;
    form.size = Pos.from(template.size);
    form.editable = template.editable;
    form.fields = deepCopy(template.fields);

    // Map top-level formula names to IDs.
    let result = form._formulas?.map(name => builtins?.formulas?.id(_inst, name) ?? new ErrorResponse(`Missing top-level formula: ${name}`));
    let [error, _formulas] = errorPartition('There was an error finding form formulas.', result);
    if (error) return error;
    form._formulas = _formulas;

    for (let field of form.fields) {
      // Map formula names to IDs.
      let name = Display.formula(field);
      if (name) {
        let _formula = builtins?.formulas?.id(_inst, name) ?? new ErrorResponse(`Missing field formula: ${name}`);
        if (errorResponse(_formula)) return _formula;
        field.key = `formula.${_formula}`;
      }

      // Table names to IDs.
      if (field._table) {
        let _table = builtins?.tables?.id(_inst, field._table) ?? new ErrorResponse(`Missing field table: ${field._table}`);
        if (errorResponse(_table)) return _table;
        field._table = _table;
      }

      // Map validator formula names to IDs.
      if (field._validator) {
        let _validator = builtins?.formulas.id(_inst, field._validator) ?? new ErrorResponse(`Missing field validator formula: ${field._validator}`);
        if (errorResponse(_validator)) return _validator;
        field._validator = _validator;
      }
    }

    return Form.correct(form);
  }

  /** Get minimum size that fits all fields. */
  static min(form: MaybeId<Form>) {
    let min = new Pos(1, 1);

    for (let f of form.fields) {
      min.x = Math.max(min.x, f.box.r + 1);
      min.y = Math.max(min.y, f.box.b + 1);
    }

    return min;
  }

  /**
   *  Get maximum size a field can be resized to.
   *  TODO Improve this later and return list of valid new sizes.
   */
  static max(form: MaybeId<Form>, field: Field) {
    return new Pos(form.size.x - field.box.l, form.size.y - field.box.t);
  }

  /** Correct layout of form if any fields are overlapping. */
  static correct(form: Form, shrink?: boolean): Form
  static correct(form: MaybeId<Form>, shrink?: boolean): MaybeId<Form>
  static correct(form: MaybeId<Form>, shrink = true): MaybeId<Form> {
    let outside = false;
    let min = this.min(form);

    // Ensure no fields are overlapping.
    for (let a of form.fields) {
      outside = outside || !Box.inside(a.box, form.size);

      for (let b of form.fields) {
        if (a === b) continue;
        if (!Box.overlaps(a.box, b.box)) continue;
        this.reset(form);
      }
    }

    // Ensure form large enough to fit all fields.
    if (outside) form.size = Pos.move(form.size, min);

    // Shrinkwrap form to fit fields.
    if (shrink) {
      form.size.x = Math.min(form.size.x, min.x);
      form.size.y = Math.min(form.size.y, min.y);
    }
    
    return form;
  }

  /** Reset layout of form fields. */
  static reset(form: MaybeId<Form>) {
    for (let i = 0; i < form.fields.length; ++i) {
      let [x, y] = [i % form.size.x, i / form.size.x | 0];
      form.fields[i]!.box = new Box(x, y, x, y);
      form.size.y = Math.max(form.size.y, y);
    }

    return form;
  }
  
  /** Get styling for this form. */
  static style(form: NoIdInst<Form>) {
    return Pos.css(form.size);
  }

  /** List all codes on form. */
  static codes(form: NoIdInst<Form>, value: DisplayValue, formulas: Formula[]): string[] {
    let formulaCategories = blockCodes(formulas.flatMap(f => f.statements), value);

    let formCategories = arrayDefined(form.fields.map(field => {
      let property = propinfoProperty(field.key, value);
      if (!property || property.type !== PropertyType.Code) return undefined;
      return property.category;
    }));

    return arraySet([...formulaCategories, ...formCategories]);
  }
}

/** Preview for viewing form in a list. */
export class FormPreview {
  constructor(
    /** Unique identifier for this form. */
    public _id = ID_DEFAULT,
    /** Title for this form. */
    public name = '',
    /** True if this is a system form and cannot be modified. */
    public system?: boolean,
    /** True if form has been edited */
    public dirty?: boolean,
  ) { }
  
  static typeinfo: TypeInfo<FormPreview> = {
    system: false,
    dirty: false,
  }
}