import { Component, HostListener } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { race } from 'rxjs/internal/observable/race';
import { ColorCode } from "../../../../../../common/toolbox/color";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { FADE_ANIMATIONS, FadeComponent } from '../../toolbox/fade';
import { STATUS_LEVEL_ICON } from '../../toolbox/status';
import { SNACKBAR_TIMEOUT_DEFAULT, SnackbarConfig } from './snackbar.model';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  host: {
    ...FADE_ANIMATIONS,
    class: 'row elevation-high'
  }
})
export class SnackbarComponent extends FadeComponent implements SnackbarConfig {
  readonly STATUS_LEVEL_ICON = STATUS_LEVEL_ICON;

  /** Message to display in snackbar. */
  message = '';
  /** Date log was created. */
  date = new Date();
  /** Status to display. */
  status?: StatusLevel;
  /** Override for icon color. */
  color?: ColorCode;
  /** Time before snackbar expires, in milliseconds. */
  timeout?: number;

  /** Emits when close button is clicked. */
  dismiss = new Subject<void>();

  ngOnInit() {
    // Start fading out after timeout or when explicitly dismissed.
    race(
      timer(this.timeout ?? SNACKBAR_TIMEOUT_DEFAULT),
      this.dismiss
    ).subscribe(() => {
      this.dismiss.complete();
      this.fadeout();
    });
  }

  @HostListener('animationend', ['$event'])
  onAnimationEnd($event: AnimationEvent) {
    this.refade($event);
  }
}
