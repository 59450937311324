import { Component, Inject, ViewChild } from '@angular/core';
import { BUILTIN_FORMS } from "../../../../../../common/model/builtin/form";
import { MemberForm } from '../../../../../../common/model/builtin/form/member';
import { DisplayType } from "../../../../../../common/model/display";
import { Form } from "../../../../../../common/model/form/form";
import { objectTruthy } from "../../../../../../common/toolbox/object";
import { FormComponent } from '../../../common/component/form/form.component';
import { FormConfig } from '../../../common/component/form/form.model';
import { TAB_DATA, TabRef } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { MemberService } from '../../../common/service/member.service';
import { MemberSearchData } from './member-search.model';

@Component({
  selector: 'app-member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss']
})
export class MemberSearchComponent {
  readonly DisplayType = DisplayType;

  /** Reference to form. */
  @ViewChild(FormComponent) form!: FormComponent;

  /** Config for member form. */
  config: FormConfig = {
    optional: true,
    form: {
      ...new Form(),
      name: 'Member Search',
      editable: [DisplayType.Member, DisplayType.Model],
      fields: BUILTIN_FORMS[MemberForm.Search].fields ?? []
    }
  };
  
  /** Default title for tab. */
  static title() {
    return 'Member Search';
  }

  constructor(
    @Inject(TAB_DATA)
    public data: MemberSearchData,
    public ref: TabRef,
    private auth: AuthService,
    private members: MemberService
  ) {}

  /** Callback when submitting search. */
  async onSubmit() {
    this.members.open({
      ...objectTruthy(this.form.value.member),
      _inst: this.auth._inst
    });
  }
}
