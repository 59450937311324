import { CURRENCY_DEFAULT } from "../../toolbox/currency";
import { objectKeys } from "../../toolbox/object";

/** Calculated amounts for a dispute. */
export class DisputeTotal {

  constructor(
    /** Total disputed amount. */
    public disputed = CURRENCY_DEFAULT,
    /** Total amount credited. */
    public credit = CURRENCY_DEFAULT,
    /** Total amount recovered. */
    public recovered = CURRENCY_DEFAULT,
    /** Total amount lost. */
    public loss = CURRENCY_DEFAULT
  ) {}

  /** Merge a list of dispute totals. */
  static merge(totals: DisputeTotal[]) {
    let total = new DisputeTotal();

    for (let subtotal of totals) {
      for (let key of objectKeys(subtotal)) {
        total[key] += subtotal[key];
      }
    }

    return total;
  }
}