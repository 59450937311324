import { DISPLAY_TYPE_NAME, Display, DisplayType } from "../../../../../common/model/display";
import { Field } from "../../../../../common/model/form/field";
import { Form } from "../../../../../common/model/form/form";
import { FormulaPreview } from "../../../../../common/model/formula/formula";
import { Model } from "../../../../../common/model/model";
import { Property } from "../../../../../common/model/property";
import { arrayEmpty } from "../../../../../common/toolbox/array";
import { boxSource } from "../../../../../common/toolbox/box";
import { MaybeId } from "../../../../../common/toolbox/id";
import { posSource } from "../../../../../common/toolbox/pos";
import { pascalCase } from "../../../../../common/toolbox/string";

/** A model field with attached property. */
export class Control {

  /** Cached type of field. */
  public type: DisplayType;
  /** Cached nested key of field. */
  public key: string;

  constructor(
    /** Display configuration for control. */
    public field: Field,
    /** Form this control is for. */
    public form: MaybeId<Form>,
    /** Property of this control. */
    public property: Property,
    /** True to display label of control. */
    public label = true,
    /** True to display input of control. */
    public input = true
  ) {
    let [type, key] = Display.split(field);
    this.type = type;
    this.key = key;
  }
}

/** Convert form to source code. */
export function formSource(form: MaybeId<Form>, model: Model, formulas: Map<string, FormulaPreview>) {
  let out: string[] = [];
  walk({
    size: form.size,
    model: form._model,
    editable: form.editable,
    fields: form.fields
  }, out, 0, model, formulas);

  // Figure out which enum this form belongs to based on a heuristic.
  let disputes = form.fields.find(f => Display.split(f)[0] === DisplayType.Claim || Display.split(f)[0] === DisplayType.Dispute);
  let account = form.fields.find(f => Display.split(f)[0] === DisplayType.Account);
  let enumeration = disputes ? 'DisputesForm' : account ? 'CollectionsForm' : 'StandardForm';
  return `[${enumeration}.${pascalCase(form.name)}]: ${out.join('')},`;
}

/** Recursively stringify formula. */
function walk(token: any, out: string[], indent: number, model: Model, formulas: Map<string, FormulaPreview>) {
  switch (typeof token) {
    case 'string':
      out.push(`'${token}'`);
      break;
    case 'object':
      if (Array.isArray(token)) {
        out.push('[');
        for (let i = 0; i < token.length; ++i) {
          out.push('\n', ' '.repeat(indent + 2));
          walk(token[i], out, indent + 2, model, formulas);
          if (i + 1 < token.length) out.push(',');
        }
        
        if (token.length) out.push('\n');
        out.push(' '.repeat(indent), ']');
      } else if (token) {
        out.push('{');
        let keys = Object.keys(token);
        for (let i = 0; i < keys.length; ++i) {
          let key = keys[i]!;
          if (token[key] === undefined || arrayEmpty(token[key])) continue;
          out.push('\n', ' '.repeat(indent + 2));

          switch (key) {
          case 'size':
            out.push(`size: ${posSource(token[key])}`);
            break;
          case 'model':
            out.push(`model: Model.${pascalCase(model.name)}`);
            break;
          case 'fields':
            let fields = token[key] as any[];
            out.push('fields: [');
            for (let f = 0; f < fields.length; ++f) {
              let field = fields[f];
              let [type, subkey] = Display.split(field);
              out.push('\n', ' '.repeat(indent + 4));

              if (type === DisplayType.Model) {
                out.push(`builtinFormModel<${pascalCase(model.name)}>('${subkey}', { box: ${boxSource(field.box)} })`);
              } else {
                let props: string[] = [];
                subkey = type === DisplayType.Formula ? `\`formula.\${Formula.${pascalCase(formulas.get(subkey)?.name)}}\`` : `'${field.key}'`;
                if (field.name !== undefined) props.push(`name: '${field.name}'`);
                props.push(`box: ${boxSource(field.box)}`);
                out.push(`builtinFormField(${subkey}, { ${props.join(', ')} })`);
              }

              if (f + 1 < fields.length) out.push(',');
            }

            
            if (fields.length) out.push('\n', ' '.repeat(indent + 2));
            out.push(']');
            break;
          case 'editable':
            out.push(`editable: [${token.editable.map((type: DisplayType) => `DisplayType.${DISPLAY_TYPE_NAME[type]}`).join(', ')}]`);
            break;
          default:
            out.push(`${key}: `);
            walk(token[key], out, indent + 2, model, formulas);
          }

          if (i + 1 < keys.length) out.push(',');
        }

        if (keys.length) out.push('\n');
        out.push(' '.repeat(indent), '}');
      } else {
        out.push('null');
      } break;
    default:
      out.push(token);
      break;
  }

  return out;
}