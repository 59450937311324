import { Pipe } from '@angular/core';

@Pipe({
  name: 'typeof'
})
export class TypeofPipe {
  transform(value: unknown) {
    return typeof value;
  }
}
