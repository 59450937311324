<div class="flex">
  <label color="secondary" style="border-bottom-right-radius: 4px">for</label>
  <div class="fill">
    <div class="block bar" color="secondary">
      <ng-template #expression></ng-template>
      <label>do</label>
    </div>
    <div class="block indent">
      <ng-template #statements></ng-template>
    </div>
  </div>
</div>