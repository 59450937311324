import { CollectionsFollowUpResponse } from "../../../code/standard/collections"
import { CollectionsCode } from "../../../code/system"
import { PropertyInfo } from "../../../info/prop"
import { PropertyType } from "../../property-type"

/** Built-in general models used across multiple features. */
export enum StandardModel {
  FollowUp          = 'Standard - Follow Up'
}

/** Result of establishing a follow-up. */
export class ModelStandardFollowUp {
  constructor(
    /** User that completed task. */
    public _completed: string | undefined = undefined,
    /** When to establish follow-up. */
    public response = CollectionsFollowUpResponse.Always,
    /** Comment on result. */
    public comment = ''
  ) {}

  static propinfo: PropertyInfo<ModelStandardFollowUp> = {
    _completed: { type: PropertyType.User, required: false },
    response: { type: PropertyType.Code, category: CollectionsCode.FollowUpResponse }
  }
}