import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationSettingsKey } from '../../../../../common/model/setting-group';
import { Task, TaskPreview } from "../../../../../common/model/task";
import { TaskType } from '../../../../../common/model/task-config';
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { TaskBase } from '../../module/task/task.model';
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { DIALOG_CANCEL_SYMBOL } from '../toolbox/dialog';
import { getRequest } from '../toolbox/request';
import { serviceSettingsItem } from '../toolbox/service';
import { TASK_COMPONENT, TaskInput, TaskOpenConfig, TaskOutput } from '../toolbox/task';
import { AuthService } from './auth.service';
import { DisplayService } from './display.service';
import { LogService } from './log.service';
import { SettingGroupService } from './setting-group.service';
import { TriggerService } from './trigger.service';

/** A query to fetch a specific task. */
export class TaskQuery {
  constructor(
    /** ID of task. */
    public _id = ID_DEFAULT,
    /** Institution of task. */
    public _inst = ID_DEFAULT
  ) { }
}

/** Caches information about tasks. */
@Injectable({
  providedIn: 'root'
})
export class TaskService extends CachePreviewService<Task, TaskQuery, TaskPreview> {
  readonly route = 'tasks/preview';
  readonly Type = Task;

  /** Cache of global institution tasks. */
  protected globalcache = new Map<string, string[]>();

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private display: DisplayService,
    private triggers: TriggerService,
    private settings: SettingGroupService
  ) {
    super(TaskQuery, log, dialog, http);
  }

  /** Fetch a task from settings. */
  setting(key: ApplicationSettingsKey) {
    return serviceSettingsItem('Task', this, this.auth, this.log, this.settings, key);
  }

  /** Open a task with provided context. */
  async open<T extends TaskType>(open: TaskOpenConfig<T>) {
    // Populate fallback display values.
    open.input.partial = this.display.fallback({
      account: 'account' in open.input ? open.input.account : undefined,
      claim: TaskBase.claim(open.input, true),
      dispute: TaskBase.dispute(open.input, true),
      event: open.input.open?.event
    });

    // Open task dialog (potentially mutating display partial).
    const { task } = open.input;
    const taskComponentType = TASK_COMPONENT[task.config.type];

    const result = taskComponentType
      ? await open.dialog.open<TaskOutput[T], TaskInput[T]>(taskComponentType, open.input, { title: Task.title(task) }, open.options)
      : undefined as TaskOutput[T];
    if (result === DIALOG_CANCEL_SYMBOL) return result;

    // Fetch triggers and populate input.
    const triggers = await this.triggers.items(task._triggers.map(_id => ({ _id, _inst: this.auth._inst })));

    // Run each trigger.
    for (let trigger of triggers) {
      const value = await this.triggers.run(trigger, open.input);
      if (errorResponse(value)) {
        this.log.show(value);
        break;
      }
    }

    return result;
  }

  protected override multiple(queries: ArraySome<TaskQuery>) {
    return getRequest(this.http, 'tasks', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}
