
/** Formats that document templates can be exported to. */
export enum DocumentTemplateFormat {
  /** Comma-separated values. */
  CSV = 'csv',
 /** DOCX Word document. */
  DOCX = 'docx',
  /** Plaintext template. */
  HTML = 'html',
  /** PDF document template. */
  PDF = 'pdf',
  /** PNG image template */
  PNG = 'png',
  /** Plain text document template. */
  Text = 'txt',
  /** XLSX Excel spreadsheet. */
  XLSX = 'xlsx'
}

/** Mapping of extensions to document template formats. */
export const DOCUMENT_TEMPLATE_FORMAT = new Map([
  ['csv', DocumentTemplateFormat.CSV],
  ['docx', DocumentTemplateFormat.DOCX],
  ['html', DocumentTemplateFormat.HTML],
  ['pdf', DocumentTemplateFormat.PDF],
  ['png', DocumentTemplateFormat.PNG],
  ['txt', DocumentTemplateFormat.Text],
  ['xlsx', DocumentTemplateFormat.XLSX]
]);