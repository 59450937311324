import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { propinfoProperty } from "../info/prop";
import { TypeInfo } from "../info/type";
import { Model } from "../model/model";
import { arrayDefined, arraySet } from "../toolbox/array";
import { blockCodes } from "../toolbox/formula/block";
import { ID_DEFAULT, NoIdInst } from "../toolbox/id";
import { NestedKey, keyLast } from "../toolbox/keys";
import { Pair, safeAssign } from "../toolbox/object";
import { titleCase } from "../toolbox/string";
import { Validator } from "../validator/base";
import { PatternValidator } from "../validator/pattern";
import { Display, DisplayValue } from "./display";
import { Formula } from "./formula/formula";
import { FusionCollection } from "./fusion";
import { PropertyType } from "./property-type";

/** Allowed units for table columns. */
export type ColumnUnit = 'fr' | 'rem';
/** Table column size plus unit. */
export type ColumnSize = `${number}${ColumnUnit}`;
/** A value with minimal column fields. */
export type ColumnLike = { key: string, size?: ColumnSize; }

/** List of all column units. */
export const COLUMN_UNITS = ['fr', 'rem'] as const;
/** Regex to match valid columns. */
export const COLUMN_REGEX = /^(\d{1,2})(fr|rem)$/;

/** A display with extra column configuration. */
export class Column<T extends DisplayValue = DisplayValue> extends Display<T> {

  constructor(
    /** Size override for column. */
    public size?: ColumnSize,
    /** Allow text to wrap. */
    public wrap?: boolean,
    /** Color of this column */
    public _colorFormula?: string
  ) {
    super();
  }

  static override typeinfo: TypeInfo<Column> = {
    name: '',
    size: new PatternValidator(COLUMN_REGEX) as Validator<ColumnSize>,
    wrap: false,
    _colorFormula: ID_DEFAULT
  }

  static override collectioninfo: CollectionInfo<FusionCollection, Column> = {
    ...Display.collectioninfo,
    _colorFormula: 'formulas'
  }

  /** Get a pair from column. */
  static pair(column: Column): Pair<NestedKey<DisplayValue>> {
    return new Pair(column.key, column.name ?? titleCase(keyLast(column.key)));
  }

  /** Create column from options. */
  static from(key: NestedKey<DisplayValue>, options: Partial<Column> = {}) {
    let column = new Column();
    safeAssign(column, { key, ...options });
    return column;
  }
}

/** Configuration for displaying a value in a table. */
export class Table {
  constructor(
    /** Unique identifier for this table. */
    public _id = ID_DEFAULT,
    /** Institution configuring this table. */
    public _inst = ID_DEFAULT,
    /** Formula used to determine highlight color, if applicable. */
    public _highlightFormula?: string,
    /** Model this table is bound to. */
    public _model?: string,
    /** Title for this table. */
    public name = '',
    /** True if this is a system table and cannot be deleted. */
    public system?: boolean,
    /** True table has been modified. */
    public dirty?: boolean,
    /** List of columns in table. */
    public columns: Column[] = [],
  ) {}

  static typeinfo: TypeInfo<Table> = {
    _model: ID_DEFAULT,
    _highlightFormula: ID_DEFAULT,
    system: false,
    dirty: false,
    columns: [new Column()]
  }

  static collectioninfo: CollectionInfo<FusionCollection, Table> = {
    _inst: 'institutions',
    _highlightFormula: 'formulas',
    _model: 'models'
  }

  static indexinfo: IndexInfo<Table> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  /** Create table configuration from a model. */
  static from(model: Model): Table {
    let table = new Table();

    for (let property of model.properties) {
      table.columns.push(Column.from(property.key as any));
    }

    return table;
  }

  /** Process list of columns into names. */
  static names(columns: ColumnLike[]) {
    return columns.map((c,idx) => c.key+idx);
  }

  /** Get column widths for table. */
  static size(columns: ColumnLike[]) {
    return columns.map(c => c.size ?? '1fr');
  }

  /** Get column width CSS for table. */
  static css(sizes?: ColumnSize[], multiple?: boolean) {
    let columns: string[] = sizes || ['repeat(auto-fit, minmax(0, 1fr))'];
    if (multiple) columns.unshift('2rem');
    return columns.join(' ');
  }

  /** Split a column size into components. */
  static split(size: ColumnSize | undefined, required: boolean): [amount: number, unit: ColumnUnit] | []
  static split(size: ColumnSize | undefined, required: true): [amount: number, unit: ColumnUnit]
  static split(size?: ColumnSize, required?: boolean) {
    if (size === undefined) return required ? [1, 'fr'] : [];
    let match = size.match(COLUMN_REGEX);
    if (!match) return required ? [1, 'fr'] : [];
    return [+match[1]!, match[2]!];
  }

  /** List all codes on table. */
  static codes(table: NoIdInst<Table>, value: DisplayValue, formulas: Formula[]): string[] {
    let formulaCategories = blockCodes(formulas.flatMap(f => f.statements), value);
    let tableCategories = arrayDefined(table.columns.map(column => {
      let property = propinfoProperty(column.key, value);
      if (!property || property.type !== PropertyType.Code) return undefined;
      return property.category;
    }));

    return arraySet([...formulaCategories, ...tableCategories]);
  }

  /** Get list of column pairs in a table. */
  static pairs(table: NoIdInst<Table>): Pair<NestedKey<DisplayValue>>[] {
    return table.columns.map(Column.pair); 
  }
}

/** Preview for viewing table in a list. */
export class TablePreview {
  constructor(
    /** Unique identifier for this table. */
    public _id = ID_DEFAULT,
    /** Title for this table. */
    public name = '',
    /** True if this is a system table and cannot be modified. */
    public system?: boolean,
    /** True table has been modified. */
    public dirty?: boolean,
  ) { }
  
  static typeinfo: TypeInfo<TablePreview> = {
    system: false,
    dirty: false
  }
}