import { DATE_FORMAT_DEFAULT } from "../../toolbox/time";
import { Permission } from "../permission";

/** Engine used for executing formulas. */
export class Engine {
  /** Current context stack to read values from. */
  static input: any[] = [];
  /** Last return value of statement. */
  static output: any = undefined;
  /** List of mutated keys. */
  static mutated = new Set<string>();
  /** True if a return value was set. */
  static return = false;
  /** Default format for dates. */
  static dateFormat = DATE_FORMAT_DEFAULT;
  /** List of keys in current context, for validation. */
  static keys = new Set<string>();
  /** List of available enums for getting display values. */
  static enums = new Map<string, Map<string, string>>();
  /** Callback to determine whether current user has permissions. */
  static granted: (permission: Permission[]) => boolean = () => false;

  /** Walk current execution context from innermost to outermost level. */
  static context<T>(callback: (context: any) => T | undefined): T | undefined {
    for (let i = Engine.input.length - 1; i >= 0; --i) {
      let result = callback(Engine.input[i]);
      if (result !== undefined) return result;
    }

    return undefined;
  }
}