import { SchemaProperty } from "../schema";

/** Create a permissive schema for any object. */
export const SCHEMA_OBJECT: any = {
  bsonType: 'object',
  properties: {},
  additionalProperties: true
};

/**
 *  Schema used when a property should never be set.
 *  These fields are only present when performing database lookups.
 */
export const SCHEMA_LOOKUP: any = {
  bsonType: 'undefined',
  description: 'This value should never be set in the database, and is only present after lookups.'
};

/** Create a permissible schema for an array of given items. */
export function schemaArray(items: SchemaProperty): any {
  return {
    bsonType: 'array',
    uniqueItems: true,
    items
  };
}