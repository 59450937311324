import { Event } from "../../../../../common/model/event";
import { EventResult } from "../../../../../common/model/event/result";
import { Icon, IconMap } from "../../../../../common/model/icon";
import { TaskType } from "../../../../../common/model/task-config";
import { TaskClaimAttachmentComponent } from "../../module/task/claim/attachment/task-claim-attachment.component";
import { TaskClaimAttachmentData, TaskClaimAttachmentReturn } from "../../module/task/claim/attachment/task-claim-attachment.model";
import { TaskClaimReviewComponent } from "../../module/task/claim/review/task-claim-review.component";
import { TaskClaimReviewData, TaskClaimReviewReturn } from "../../module/task/claim/review/task-claim-review.model";
import { TaskDisputeWithdrawComponent } from "../../module/task/dispute/withdraw/task-dispute-withdraw.component";
import { TaskDisputeWithdrawData, TaskDisputeWithdrawReturn } from "../../module/task/dispute/withdraw/task-dispute-withdraw.model";
import { TaskDocumentTemplateComponent } from "../../module/task/document-template/task-document-template.component";
import { TaskDocumentTemplateData, TaskDocumentTemplateReturn } from "../../module/task/document-template/task-document-template.model";
import { TaskFormListComponent } from "../../module/task/form/task-form-list.component";
import { TaskFormListData, TaskFormListReturn } from "../../module/task/form/task-form-list.model";
import { TaskRunTriggersData, TaskRunTriggersReturn } from "../../module/task/trigger/task-trigger-run.model";
import { DialogOptions, DialogService } from "../component/dialog/dialog.service";

/** Signal to open a task. */
export class TaskOpen<R extends EventResult = EventResult> {
  constructor(
    /** Task to open. */
    public _task: string,
    /** True to open task in main panel. */
    public panel?: boolean,
    /** Existing event to open, if applicable. */
    public event?: Event<R>
  ) { }
}

/** Configuration for opening a task. */
export class TaskOpenConfig<T extends TaskType> {
  constructor(
    /** Handle to dialog service. */
    public dialog: DialogService,
    /** Input to pass to task. */
    public input: TaskInput[T],
    /** Overrides for dialog options. */
    public options?: DialogOptions
  ) { }
}

/** Icon for each task type. */
export const TASK_TYPE_ICON: IconMap<TaskType> = {
  [TaskType.ClaimAttachment]: Icon.UploadFile,
  [TaskType.ClaimReview]: Icon.Preview,
  [TaskType.DisputeWithdraw]: Icon.Undo,
  [TaskType.DocumentTemplate]: Icon.Mail,
  [TaskType.Form]: Icon.Checklist,
  [TaskType.FormList]: Icon.Checklist,
  [TaskType.Headless]: Icon.PlayArrow
};

/** Component for each task type. */
export const TASK_COMPONENT = {
  [TaskType.ClaimAttachment]: TaskClaimAttachmentComponent,
  [TaskType.ClaimReview]: TaskClaimReviewComponent,
  [TaskType.DisputeWithdraw]: TaskDisputeWithdrawComponent,
  [TaskType.DocumentTemplate]: TaskDocumentTemplateComponent,
  [TaskType.Form]: TaskFormListComponent,
  [TaskType.FormList]: TaskFormListComponent,
  [TaskType.Headless]: undefined
};

/** Data accepted by each task type. */
export interface TaskInput {
  [TaskType.ClaimAttachment]: TaskClaimAttachmentData
  [TaskType.ClaimReview]: TaskClaimReviewData
  [TaskType.DisputeWithdraw]: TaskDisputeWithdrawData
  [TaskType.DocumentTemplate]: TaskDocumentTemplateData
  [TaskType.Form]: TaskFormListData
  [TaskType.FormList]: TaskFormListData
  [TaskType.Headless]: TaskRunTriggersData
};

/** Data returned by each task type. */
export interface TaskOutput {
  [TaskType.ClaimAttachment]: TaskClaimAttachmentReturn
  [TaskType.ClaimReview]: TaskClaimReviewReturn
  [TaskType.DisputeWithdraw]: TaskDisputeWithdrawReturn
  [TaskType.DocumentTemplate]: TaskDocumentTemplateReturn
  [TaskType.Form]: TaskFormListReturn
  [TaskType.FormList]: TaskFormListReturn
  [TaskType.Headless]: TaskRunTriggersReturn
};
