import { TypeInfo } from "../../info/type";
import { claimDisputes, claimUnjoin } from "../../toolbox/claim";
import { disputeUnjoin } from "../../toolbox/dispute";
import { NoId, idOmit } from "../../toolbox/id";
import { validatorUnionOmit } from "../../toolbox/validate";
import { UnionValidator } from "../../validator/union";
import { AttachmentUpload } from "../attachment";
import { DISPUTE_JOIN_UNIONINFO, DISPUTE_UNIONINFO, Dispute } from "../dispute/dispute";
import { CLAIM_UNIONINFO, Claim, ClaimACH, ClaimJoin } from "./claim";

/** A claim to be uploaded. */
export class ClaimUpload {
  constructor(
    /** Claim to be uploaded. */
    public claim: NoId<Claim> = idOmit(new ClaimACH()),
    /** List of disputes for claim. */
    public disputes?: NoId<Dispute>[],
    /** Additional documents to attach. */
    public uploads?: AttachmentUpload[]
  ) {}

  static typeinfo: TypeInfo<ClaimUpload> = {
    claim: validatorUnionOmit(CLAIM_UNIONINFO, '_id'),
    disputes: [validatorUnionOmit(DISPUTE_JOIN_UNIONINFO, '_id')],
    uploads: [new AttachmentUpload()]
  }
}

/** A patch to an existing claim. */
export class ClaimPatch {
  constructor(
    /** Claim to be uploaded. */
    public claim: Claim = new ClaimACH(),
    /** List of disputes for claim. */
    public disputes?: Dispute[],
    /** Additional documents to attach. */
    public uploads?: AttachmentUpload[]
  ) {}

  static typeinfo: TypeInfo<ClaimUpload> = {
    claim: new UnionValidator(CLAIM_UNIONINFO),
    disputes: [new UnionValidator(DISPUTE_UNIONINFO)],
    uploads: [new AttachmentUpload()]
  }

  /** Create from a joined claim. */
  static from(join: ClaimJoin): ClaimPatch {
    let claim = claimUnjoin(join);
    let disputes = claimDisputes(join).map(dispute => disputeUnjoin(dispute));
    return new ClaimPatch(claim, disputes);
  }
}