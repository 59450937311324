import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { Subject } from "rxjs";
import { ErrorResponse } from "../../../../../common/message/error";
import { InstitutionGetRequest } from "../../../../../common/message/instititution";
import { collectionValue } from "../../../../../common/model/collection";
import { FusionCollection, FusionCollectionName } from "../../../../../common/model/fusion";
import { arraySingle } from "../../../../../common/toolbox/array";
import { Diff, DiffResult } from "../../../../../common/toolbox/diff";
import { HasIdName, MaybeId, idExists, idInstOmit, idNull, idOmit } from "../../../../../common/toolbox/id";
import { errorPartition, errorResponse } from "../../../../../common/toolbox/message";
import { Newable, ObjectKeys, objectDelete } from "../../../../../common/toolbox/object";
import { paginateSplit } from "../../../../../common/toolbox/paginate";
import { titleCase } from "../../../../../common/toolbox/string";
import { ArrayValidator } from "../../../../../common/validator/array";
import { ValidationStatus } from "../../../../../common/validator/base";
import { IdValidator } from "../../../../../common/validator/id";
import { StringValidator } from "../../../../../common/validator/string";
import { DialogService } from "../../common/component/dialog/dialog.service";
import { AuthService } from "../../common/service/auth.service";
import { CollectionService } from "../../common/service/collection.service";
import { LogService } from "../../common/service/log.service";
import { CachePreviewService } from "../../common/toolbox/cache-preview-service";
import { SetupOptions } from "../../common/toolbox/fusion";
import { PreviewLike } from "../../common/toolbox/preview";
import { deleteRequest, getRequest, postRequest } from "../../common/toolbox/request";
import { SetupRoute } from "../../common/toolbox/setup";
import { SetupCompareComponent } from "./compare/setup-compare.component";
import { SetupCompareDialogData } from "./compare/setup-compare.model";
import { SetupDiscardDialogComponent } from "./discard-dialog/setup-discard-dialog.component";
import { SetupDiscardDialogData, SetupDiscardDialogReturn } from "./discard-dialog/setup-discard-dialog.data";
import { SetupData } from "./setup.model";

/** Base class for main page of all setup components. */
@Component({ template: '' })
export abstract class SetupComponent<T extends HasIdName, Q extends Object = Object, P extends PreviewLike = PreviewLike> {
  abstract data?: SetupData;
  abstract log: LogService;
  abstract dialog: DialogService;
  abstract http: HttpClient;
  abstract auth: AuthService;
  abstract resource: MaybeId<T>;

  /** List of available previews. */
  previews: P[] = [];

  /** Constructor to create resource. */
  protected abstract Type: Newable<T>;
  /** Collection that handles this resource. */
  protected abstract collection: ObjectKeys<FusionCollection, T>;
  /** Route to contact when uploading or deleting resource. */
  protected abstract route: SetupRoute;
  /** Service to fetch prviews. */
  protected abstract service: CachePreviewService<T, Q, P>;
  /** Resolver to upload and download items. */
  protected abstract collections: CollectionService;

  /** True if currently loading in a new resource. */
  protected loading = false;
  /** Emits whenever the component is destroyed. */
  protected destroy = new Subject<void>();
  /** Backup of last selected resource to revert changes, check changes. */
  protected backup?: MaybeId<T>;

  async ngOnInit() {
    await this.refresh(true);
    
    // Fetch options of user.
    let options = await DB.get('setupOptions', this.auth.session._id);
    if (errorResponse(options)) return this.log.show(options);

    // Auto-open provided value, or reselect last viewed value.
    let selection = this.data?._id ?? options?.selection[this.collection as FusionCollectionName];
    if (!idNull(selection)) this.onPreview(this.previews.find(p => p._id === selection));
    //initialize preview with default values
    else this.onPreview();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Set new preview and save selection.
   *  @param preview The new value to display.
   *  @param compare True to show pop-up for unsaved changes. Defaults to true.
   */
  async onPreview(preview?: P, compare = true) {
    // Confirm we want to discard old resource.
    if (compare && this.backup) {
      let diff = new Diff(this.backup ?? {}, this.resource);
      if (!Diff.equal(diff)) {
        let data = new SetupDiscardDialogData(diff, this.resource, preview);
        if (await this.dialog.open<SetupDiscardDialogReturn, SetupDiscardDialogData>(SetupDiscardDialogComponent, data) !== true) return;
      }
    }

    // Fetch new resource.
    this.loading = true;
    let resources = await this.fetchResources(preview?._id ? [preview._id] : undefined);
    this.loading = false;
    if (errorResponse(resources)) return this.log.show(resources);
    if (!arraySingle(resources)) return this.log.show(new ErrorResponse('There was an error receiving the requested resource.'));
    let resource = resources[0];

    // Create local copy to track changes.
    if ('_inst' in resource) resource._inst = this.auth._inst;
    this.backup = structuredClone(resource);
    this.resource = resource;

    // Remember selection of preview.
    let result = await DB.partial('setupOptions', {
      selection: { [this.collection]: preview?._id }
    }, new SetupOptions(this.auth.session._id));
    if (errorResponse(result)) this.log.show(result);
  }

  /** Callback when submitting items. */
  async onSubmit(item: MaybeId<T>) {
    await this.refresh(true);
    if (idExists(item)) this.service.set(item);
    this.onPreview(this.previews.find(p => p.name === item.name), false);
  }

  async onDelete(indexes: number[]) {
    let previews = indexes.map(i=>this.previews[i]!);
    let originalLength = this.previews.length;
    if (!this.route) return this.log.show(`Cannot delete ${this.route}: route not implemented.`);
    if (!await this.dialog.confirm(`Delete ${previews.length} ${this.route}(s)? This action cannot be undone.`, `Delete ${this.route}?`)) return;
    let _ids = previews.map(preview => preview._id);
    await Promise.all(_ids.map(_id => this.service.delete(_id)))
    let deleted = await deleteRequest(this.http, this.route, { _inst: this.auth._inst, _ids });
    if (errorResponse(deleted)) return this.log.show(deleted);

    for (let index of indexes) {
      // Clear item from table.
      // if these two are not equal, we assume that the service handled the splicing for us.
      if (this.previews.length === originalLength) this.previews.splice(index, 1);
      this.previews = [...this.previews];
      if (this.resource?._id === previews[index]?._id) objectDelete(this, 'resource');      
    }
  }

  /** Callback when downloading items to clipboard. */
  async onDownload(_ids: string[]) {
    if (!this.route) return;
    let response = await this.fetchResources(_ids);
    if (errorResponse(response)) return this.log.show(response);

    let [values] = paginateSplit<any>(response);
    let collection = this.collection as FusionCollectionName;
    let results = await Promise.all(values.map(item => this.collections.export(collection, item as any)));
    let [error, items] = errorPartition(`There was an error copying ${values.length} items to clipboard.`, results);
    if (error) {
      this.log.show(error);
      return;
    }

    await navigator.clipboard.writeText(JSON.stringify(items));
    this.log.show(`Copied ${_ids.length} items to clipboard.`);
  }

  /** Callback when uploading items from clipboard. */
  async onUpload(parsed: unknown) {
    let collection: any = this.collection;
    let value = collectionValue(this.collections.COLLECTION_OVERRIDES, collection) ?? collectionValue(this.collections.COLLECTIONS, collection);
    let validator = new ArrayValidator(idInstOmit(value));
    if (validator.validate(parsed, { relax: new Set([StringValidator, IdValidator]) }) === ValidationStatus.Error) {
      return this.log.show(validator.error(parsed, `Contents of clipboard were not a valid JSON array of ${titleCase(collection)}.`));
    }

    let results = await Promise.all((parsed as any[]).map(item => this.collections.import(item)));
    let [error, items] = errorPartition('There was an error loading items from clipboard.', results);
    if (error) return this.log.show(error);

    let result = await postRequest(this.http, this.route, { items } as any);
    if (errorResponse(result)) return this.log.show(result);

    this.log.show(`Successfully uploaded ${items.length} items from clipboard.`);
    this.refresh(true);
  }

  /** Callback when uploading items to diff from clipboard. */
  async onDiff(parsed: unknown) {
    
    // Validate all uploaded items.
    let collection = this.collection as ObjectKeys<FusionCollection, { name: string }>;
    let value = collectionValue(this.collections.COLLECTION_OVERRIDES, collection) ?? collectionValue(this.collections.COLLECTIONS, collection);
    let validator = new ArrayValidator(idInstOmit(value));
    if (validator.validate(parsed, { relax: new Set([StringValidator, IdValidator]) }) === ValidationStatus.Error) {
      this.log.show(validator.error(parsed, `Contents of clipboard were not a valid JSON array of ${titleCase(collection)}.`));
      return;
    }

    // Fetch existing list of items.
    let request = this.route === 'institutions' ? new InstitutionGetRequest([this.auth._org]) : { _insts: [this.auth._inst] };
    let response = await getRequest(this.http, this.route, request);
    if (errorResponse( response)) {
      this.log.show( response);
      return;
    }

    // Run a diff on each uploaded item against current one, ignoring object IDs.
    let [items] = paginateSplit<any>(response);
    let nameMap = new Map<string, any>(items.map(result => [(result as any).name, result]));
    let foundNames = new Set<string>();
    let diffs: DiffResult[] = await Promise.all((parsed as any[]).map(async (value) => {
      foundNames.add(value.name);
      let currentValue = await this.collections.export(collection, nameMap.get(value.name));
      let result = this.collections.diff(value, currentValue);
      return result;
    }));
    for (let [name,value] of nameMap.entries()) {
      if (!foundNames.has(name)) {
        let diff = this.collections.diff(undefined, value);
        diffs.push(diff);
      }
    }
    let data = new SetupCompareDialogData(this.collection as FusionCollectionName, this.route, this.collections, diffs);
    await this.dialog.open(SetupCompareComponent, data);
    this.refresh(true);
  }

  /** Callback when refreshing items. */
  protected async refresh(force?: boolean): Promise<any> {
    let previews = await this.fetchPreviews(force);
    if (errorResponse(previews)) return this.log.show(previews);
    this.previews = previews;
  }

  /** Fetch resources by ID. */
  protected async fetchResources(_ids?: string[]): Promise<MaybeId<T>[] | ErrorResponse> {
    if (!_ids) return [idOmit(new this.Type())];
    let response = getRequest(this.http, this.route, { _insts: [this.auth._inst], _ids });
    return response as unknown as T[];
  }

  /** Get list of previews visible to user. */
  protected fetchPreviews(force?: boolean): Promise<any> {
    return this.service.previews(this.auth._inst, force);
  }
}