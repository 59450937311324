import { Expression } from "./expression";
import { TerminalIdentifier } from "./terminal";

/** A single statement of a formula. */
export type Statement<T = any>
  = StatementIf<T>
  | StatementFor<T>
  | StatementReturn<T>
  | StatementAssignment<T>;

/** Types of statements. */
export enum StatementType {
  /** Return a value. */
  Return = 'return',
  /** Perform an if-else check. */
  If = 'if',
  /** Perform a loop. */
  For = 'for',
  /** Assign a value. */
  Assignment = '='
}

/** Basic configuration shared by allstatements. */
export interface StatementTag<S extends StatementType> {
  /** Tagged type of statement. */
  type: S
}

/** A statement that returns a value. */
export interface StatementReturn<T = any> extends StatementTag<StatementType.Return> {
  /** Value to return. */
  expression: Expression<T>
}

/** An if-statement with two branches. */
export interface StatementIf<T = any> extends StatementTag<StatementType.If> {
  /** If statement to check. */
  if: Expression<T>
  /** Statement list to evaluate if true. */
  then: Statement<T>[]
  /** Statement list to evaluate if false. */
  else?: Statement<T>[]
}

/** A for loop over a value. */
export interface StatementFor<T = any> extends StatementTag<StatementType.For> {
  /** Expression to loop over */
  expression: Expression<T>
  /** Statements to execute for each item. */
  statements: Statement<T>[]
}

/** An assignment statement. */
export interface StatementAssignment<T = any> extends StatementTag<StatementType.Assignment> {
  /** Lvalue to assign to. */
  left: TerminalIdentifier<T>
  /** Value to assign. */
  right: Expression<T>
}