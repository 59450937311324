import { WorkCondition } from "../../../../../../../common/model/work/condition";
import { WorkAction, WorkStep } from "../../../../../../../common/model/work/flow";

/** A navigation breadcrumb in workflow editor. */
export type WorkCrumb = WorkCrumbStep | WorkCrumbAction | WorkCrumbCondition;

/** A stepto navigate to. */
export class WorkCrumbStep {
  readonly type = 'step';

  constructor(
    /** Step to edit. */
    public step: WorkStep
  ) {}
}

/** An action to navigate to. */
export class WorkCrumbAction {
  readonly type = 'action';

  constructor(
    /** Action to edit. */
    public action: WorkAction
  ) {}
}

/** A condition to navigate to. */
export class WorkCrumbCondition {
  readonly type = 'condition';

  constructor(
    /** Work condition to edit. */
    public condition: WorkCondition
  ) {}
}