
/** Path used for institution-defined data. */
export const INST_DATA = 'data';

/** Resolve given path. Similar to path.join() but accessible clientside. */
export function pathJoin(...elements: string[]) {
  return elements.join('/').replace('\\', '/');
}

/** Resolve institution's data path. */
export function pathInstData(root: string, _inst: string, directory: string) {
  return pathJoin(root, INST_DATA, _inst, directory);
}