import { propInfoMerge } from "../info/prop";
import { UnionInfo, unioninfoValues } from "../info/union";
import { SchemaObject } from "../model/schema";
import { NATURAL_REGEX } from "../toolbox/number";
import { objectEntries } from "../toolbox/object";
import { validatorValue } from "../toolbox/validate";
import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";
import { ObjectValidator } from "./object";

/** Get the class of a union validator. */
export type UnionValidatorClass<T> = T extends UnionValidator<infer T, any> ? T : never;
/** Get the tag of a union validator. */
export type UnionValidatorTag<T> = T extends UnionValidator<any, infer K> ? K : never;

/** Validate a union type with a tag discriminator. */
export class UnionValidator<T, K extends number | string> extends ObjectValidator<T> {
  /** Dummy value with merged typeinfo and propinfo for classes. */
  readonly merged: T;
  /** Validator for each type. */
  unions = new Map<K, ObjectValidator<T>>();

  constructor(
    /** Stored union information. */
    public unioninfo: UnionInfo<T, K>,
    /** True to force string values for keys. */
    public stringKeys?: boolean
  ) {
    super({} as any);

    // Cache validator for each type.
    this.merged = propInfoMerge(...unioninfoValues(unioninfo));
    for (let [key, value] of objectEntries(unioninfo.classes)) {
      this.unions.set(key, (value as any) instanceof Validator ? value as Validator : validatorValue(value) as any);
    }
  }

  override value(): any {
    return this.merged;
  }

  override subparse(text: string, key?: string) {
    for (let [, validator] of this.unions) {
      let value = validator.subparse(text, key);
      if (value !== undefined) return value;
    }
  }

  override schema() {
    if (this.property) return this.property;
    return {
      oneOf: [...this.unions.entries()].flatMap(([key, validator]) => {
        let property = validator.schema();
        let tag = (NATURAL_REGEX.test(`${key}`) && !this.stringKeys) ? +key : key;
        let properties: SchemaObject<any>[] = [];

        if ('properties' in property) {
          // Singly-nested unioninfo.
          properties = [property];
        } else if ('oneOf' in property) {
          // Doubly-nested unioninfo.
          properties = property.oneOf as SchemaObject<any>[];
        }

        for (let property of properties) {
          property.properties[this.unioninfo.tag] = {
            bsonType: typeof tag === 'number' ? 'int' : 'string',
            enum: [tag]
          };
        }

        return properties;
      })
    } as any;
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    this.list = [];
    
    let superstatus = super.validate(value, options);
    if (superstatus) return superstatus;

    let tag = value[this.unioninfo.tag];
    let validator = this.unions.get(`${tag}` as K);
    if (!validator) {
      this.list.push(new ValidatorLog(`Expected one of union tags: ${[...this.unions.keys()].join(', ')}`, [`.${String(this.unioninfo.tag)}`]));
      return ValidationStatus.Error;
    }

    let substatus = validator.validate(value, options);
    if (substatus) for (let log of validator.logs()) {
      this.list.push(log);
    }

    return substatus;
  }

  /** Get list of tags for union validator. */
  tags() {
    let keys = [...this.unions.keys()];
    return keys.every(v => !isNaN(+v)) ? keys.map(v => +v) : keys;
  }

  /** Get iterator over optional properties. */
  override optionalKeys(value: T) {
    let validator = this.unions.get(value[this.unioninfo.tag] as K)!;
    return validator.optionalKeys(value);
  }
}