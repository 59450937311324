import { ID_DEFAULT } from "../toolbox/id";
import { IndexInfo } from "../info";
import { PropertyInfo } from "../info/prop";
import { ImportCode } from "../code/system";
import { PropertyType } from "./property-type";

/** Many-to-many account-member relationship. */
export class AccountMemberRelationship {
  constructor(
    /** Unique identifier of this relationship. */
    public _id = ID_DEFAULT,
    /** Account member owns. */
    public _account = ID_DEFAULT,
    /** Unique ID of member. */
    public _member = ID_DEFAULT,
    /** Relationship code. */
    public relationship = ''
  ) {}

  static propinfo: PropertyInfo<AccountMemberRelationship> = {
    relationship: { type: PropertyType.Code, category: ImportCode.AccountMember }
  }

  static indexinfo: IndexInfo<AccountMemberRelationship> = [
    { key: { _account: 1, _member: 1 }, unique: true }
  ];
}
