import { Injectable, NgZone } from '@angular/core';
import { MINUTE_MILLISECONDS } from "../../../../../common/toolbox/time";
import { BehaviorSubject } from 'rxjs';

/** Frequency that idle timer is updated. */
const IDLE_FREQUENCY = MINUTE_MILLISECONDS;

/** Service for tracking user activity. */
@Injectable({
  providedIn: 'root'
})
export class IdleService {
  /** Amount of time application has been idling, in milliseconds. */
  time = new BehaviorSubject(0);

  constructor(
    private zone: NgZone
  ) {
    this.zone.runOutsideAngular(() => {
      // Emit new idle time every minute.
      setInterval(() => {
        this.time.next(this.time.value + IDLE_FREQUENCY);
      }, IDLE_FREQUENCY);
  
      // Zero the idle timer on mouse movement.
      window.addEventListener('mousemove', () => this.reset());
      window.addEventListener('keydown', () => this.reset());
      window.addEventListener('keyup', () => this.reset());
    });
  }

  /** Reset idle timer. */
  reset() {
    if (!this.time.value) return;
    this.time.next(0);
  }
}
