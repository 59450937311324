import { Component } from '@angular/core';

@Component({
  selector: 'app-demo-expansion-panel',
  templateUrl: './demo-expansion-panel.component.html',
  styleUrls: ['./demo-expansion-panel.component.scss']
})
export class DemoExpansionPanelComponent {

  static title() {
    return 'Expansion Panel';
  }
}
