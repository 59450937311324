<app-card>
  <app-card-header [attr.color]="data.color">{{data.title}}</app-card-header>
  <app-card-panel *ngIf="data.options.percentages">
    <app-label>By Type</app-label>
    <app-percent [items]="metrics"></app-percent>
  </app-card-panel>
  <app-card-panel class="headers" *ngIf="data.headers.length">
    <ng-container *ngFor="let header of data.headers">
      <b>{{header.value}}</b>
      <app-label>{{header.label}}</app-label>
    </ng-container>
  </app-card-panel>
  <app-card-body class="column">
    <div class="metrics">
      <ng-container *ngFor="let metric of metrics">
        <app-dot [attr.color]="metric.color"></app-dot>
        <div class="label">{{metric.label}}</div>
        <div [class.hidden]="!data.options.total">{{metric.value}}</div>
        <div>{{metric.percent}}%</div>
      </ng-container>
      <ng-container *ngIf="data.options.total">
        <div></div>
        <div>Total</div>
        <div>{{total}}</div>
      </ng-container>
    </div>
    <div class="fill"></div>
    <button (click)="onReport()">Open Report</button>
  </app-card-body>
</app-card>