import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[toggle-button]'
})
export class ToggleButtonDirective {
  /** Current toggle value. */
  @Input('toggle-button') value: any;
  /** True to display indeterminate. */
  @Input('toggle-button-indeterminate') indeterminate: any;
  /** Icons to use for toggle. */
  @Input('toggle-icon') icon: [string, string, string] = ['check_box_outline_blank', 'check_box', 'indeterminate_check_box']

  constructor(private element: ElementRef<HTMLElement>) {}

  ngOnChanges() {
    let index = (+!!this.value || +!!this.indeterminate * 2) as 0 | 1 | 2;
    this.element.nativeElement.innerText = this.icon[index];
    this.element.nativeElement.classList[index === 2 ? 'add' : 'remove']('toggle-button-indeterminate');
  }
}
