import { ClaimCardCancellationOrReturnType, ClaimCardLatePresentmentLength, ClaimCardProcessingErrorReason, ClaimCardProcessingErrorType, ClaimCardDetailsType, ClaimCardReturnReason, ClaimCardShippingCompany } from "../../code/standard/disputes";
import { DisputesCode } from "../../code/system";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { UnionInfo, unioninfoRetype } from "../../info/union";
import { CURRENCY_DEFAULT } from "../../toolbox/currency";
import { ID_DEFAULT } from "../../toolbox/id";
import { PropertyType } from "../property-type";

/** Reasons provided for a card claim. */
export type ClaimCardDetails = ClaimCardDetailsFraudAbsent | ClaimCardDetailsFraudPresent | ClaimCardDetailsProcessingError | ClaimCardDetailsATMTransaction | ClaimCardDetailsCancellationOrReturn | ClaimCardDetailsOther;

/** Base fields used by all card claim details. */
export class ClaimCardDetailsBase<T extends ClaimCardDetailsType = ClaimCardDetailsType> {
  constructor(
    /** Union tag for card claim. */
    public type: T,
    /** Card this claim is on. */
    public _card = ID_DEFAULT
  ) {}

  static propinfo: PropertyInfo<ClaimCardDetailsBase> = {
    type: { type: PropertyType.Code, category: DisputesCode.ClaimCardDetailsType }
  }

  /** Reassign type of claim card details while maintaining object reference. */
  static retype<T extends ClaimCardDetailsType>(details: ClaimCardDetails, type: T): ClaimCardDetailsClass[T] {
    return unioninfoRetype<ClaimCardDetailsBase, ClaimCardDetails, ClaimCardDetailsType>(new ClaimCardDetailsBase(ClaimCardDetailsType.Other), details, type, CLAIM_CARD_DETAILS_UNIONINFO) as ClaimCardDetailsClass[T];
  }
}

/** Information about a fraud (card absent) claim. */
export class ClaimCardDetailsFraudAbsent extends ClaimCardDetailsBase<ClaimCardDetailsType.FraudAbsent> {
  constructor(
    /** True if member exambined all unauthorized transactions. */
    public examinedTransactions = false,
    /** True if member has not provided card for usage by others. */
    public providedCard = false,
    /** True if member did not receive any proceeds or benefits from these transactions. */
    public proceedsOrBenefits = false,
    /** True if member is willing to file a police report. */
    public policeReport = false,
    /** True if a police report was filed. Applicable if user is willing to file a police report. */
    public reportFiled = false,
    /** Case number of police report. Applicable if user is willing to file a police report. */
    public policeCaseNumber = '',
    /** Police agency of report. Applicable if user is willing to file a police report. */
    public policeAgency = ''
  ) {
    super(ClaimCardDetailsType.FraudAbsent);
  }
}

/** Information about a fraud (card present) claim. */
export class ClaimCardDetailsFraudPresent extends ClaimCardDetailsBase<ClaimCardDetailsType.FraudPresent> {
  constructor(
    public locationDate = new Date(),
  ) {
    super(ClaimCardDetailsType.FraudPresent);
  }
}

/** Information about a processing error card claim. */
export class ClaimCardDetailsProcessingError extends ClaimCardDetailsBase<ClaimCardDetailsType.ProcessingError> {
  constructor(
    /** Type of processing error that occurred. */
    public processingErrorType = ClaimCardProcessingErrorType.AdditionalCharge,
    /** Date of the unauthorized amount. Applicable if additional charge. */
    public unauthorizedAmountDate?: Date,
    /** The original amount that was authorized. Applicable if additional charge. */
    public authorizedAmount?: number,
    /** The unauthorized amount that was observed. Applicable if additional charge. */
    public unauthorizedAmount?: number,
    /** Reason for reporting processing error. Applicable if incorrect transaction amount. */
    public processingErrorReason = ClaimCardProcessingErrorReason.DraftAltered,
    /** The amount that was posted. Applicable if incorrect transaction amount. */
    public amountPosted?: number,
    /** The amount the sales draft indicates. Applicable if incorrect transaction amount. */
    public salesDraftIndicated?: number,
    /** Amount of credit that was promised. Applicable if unreceived credit. */
    public amountOfCredit?: number,
    /** Date that credit was promised. Applicable if unreceived credit. */
    public creditPromisedDate?: Date,
    /** Amount of time ago that transaction occurred. Applicable if late presentment. */
    public latePresentmentLength?: ClaimCardLatePresentmentLength
  ) {
    super(ClaimCardDetailsType.ProcessingError);
  }

  static typeinfo: TypeInfo<ClaimCardDetailsProcessingError> = {
    amountOfCredit: CURRENCY_DEFAULT,
    amountPosted: CURRENCY_DEFAULT,
    authorizedAmount: CURRENCY_DEFAULT,
    creditPromisedDate: new Date(),
    latePresentmentLength: ClaimCardLatePresentmentLength.MoreThan30LessThan180,
    salesDraftIndicated: CURRENCY_DEFAULT,
    unauthorizedAmount: CURRENCY_DEFAULT,
    unauthorizedAmountDate: new Date()
  }

  static override propinfo: PropertyInfo<ClaimCardDetailsProcessingError> = {
    ...ClaimCardDetailsBase.propinfo,
    amountOfCredit: { type: PropertyType.Currency },
    amountPosted: { type: PropertyType.Currency },
    authorizedAmount: { type: PropertyType.Currency },
    latePresentmentLength: { type: PropertyType.Code, category: DisputesCode.ClaimCardLatePresentmentLength },
    processingErrorReason: { type: PropertyType.Code, category: DisputesCode.ClaimCardProcessingErrorReason },
    processingErrorType: { type: PropertyType.Code, category: DisputesCode.ClaimCardProcessingErrorType },
    type: { type: PropertyType.Code, category: DisputesCode.ClaimCardDetailsType },
    salesDraftIndicated: { type: PropertyType.Currency },
    unauthorizedAmount: { type: PropertyType.Currency }
  }
};

/** Information about an ATM transaction card claim. */
export class ClaimCardDetailsATMTransaction extends ClaimCardDetailsBase<ClaimCardDetailsType.AtmTransaction> {
  constructor(
    /** Amount that was requested from ATM. */
    public requestedAmount = CURRENCY_DEFAULT,
    /** Amount that was received from ATM. */
    public receivedAmount = CURRENCY_DEFAULT,
  ) {
    super(ClaimCardDetailsType.AtmTransaction);
  }

  static override propinfo: PropertyInfo<ClaimCardDetailsATMTransaction> = {
    ...ClaimCardDetailsBase.propinfo,
    requestedAmount: { type: PropertyType.Currency },
    receivedAmount: { type: PropertyType.Currency }
  }
}

/** Information about a cancellation or return card claim. */
export class ClaimCardDetailsCancellationOrReturn extends ClaimCardDetailsBase<ClaimCardDetailsType.Cancellation> {
  constructor(
    /** Type of cancellation being reported. */
    public cancellationOrReturnType = ClaimCardCancellationOrReturnType.CancelProduct,
    /** Date the cancellation occurred. Applicable if cancelled product. */
    public cancellationDate?: Date,
    /** Who the member spoke with to file the cancellation. Applicable if cancelled product. */
    public spokeWith?: string,
    /** True if member did not. Applicable if cancelled product. */
    public expectedDeliveryDate?: Date,
    /** Date the merchant was contacted. Applicable if product not received or arrived not as expected. */
    public merchantContactDate?: Date,
    /** Reason the product was returned. Applicable if product arrived not as expected or returned/refused. */
    public reasonForReturn?: ClaimCardReturnReason,
    /** Date the product was returned. Applicable if product arrived not as expected or returned/refused. */
    public dateReturned?: Date,
    /** Product that was purchased. Applicable if product arrived not as expected. */
    public whatWasPurchased?: string,
    /** Shipping company of product. Applicable if product arrived not as expected or returned/refused. */
    public shippingCompany?: ClaimCardShippingCompany,
    /** Merchant's response. Applicable if product arrived not as expected. */
    public merchantResponse?: string,
  ) {
    super(ClaimCardDetailsType.Cancellation);
  }

  static typeinfo: TypeInfo<ClaimCardDetailsCancellationOrReturn> = {
    cancellationDate: new Date(),
    dateReturned: new Date(),
    expectedDeliveryDate: new Date(),
    merchantContactDate: new Date(),
    merchantResponse: '',
    reasonForReturn: ClaimCardReturnReason.Counterfeit,
    shippingCompany: ClaimCardShippingCompany.FedEx,
    spokeWith: '',
    whatWasPurchased: ''
  }

  static override propinfo: PropertyInfo<ClaimCardDetailsCancellationOrReturn> = {
    ...ClaimCardDetailsBase.propinfo,
    cancellationOrReturnType: { type: PropertyType.Code, category: DisputesCode.ClaimCardCancellationOrReturnType },
    reasonForReturn: { type: PropertyType.Code, category: DisputesCode.ClaimCardReturnReason },
    shippingCompany: { type: PropertyType.Code, category: DisputesCode.ClaimCardShippingCompany }
  }
}

/** Information about a generic cards claim. */
export class ClaimCardDetailsOther extends ClaimCardDetailsBase<ClaimCardDetailsType.Other> {
  constructor() {
    super(ClaimCardDetailsType.Other)
  }
}

/** Mapping of card claim reasons to classes. */
export class ClaimCardDetailsClass {
  [ClaimCardDetailsType.AtmTransaction] = new ClaimCardDetailsATMTransaction();
  [ClaimCardDetailsType.Cancellation] = new ClaimCardDetailsCancellationOrReturn();
  [ClaimCardDetailsType.FraudAbsent] = new ClaimCardDetailsFraudAbsent();
  [ClaimCardDetailsType.FraudPresent] = new ClaimCardDetailsFraudPresent();
  [ClaimCardDetailsType.ProcessingError] = new ClaimCardDetailsProcessingError();
  [ClaimCardDetailsType.Other] = new ClaimCardDetailsOther();
};

/** Type information about card claim details. */
export const CLAIM_CARD_DETAILS_UNIONINFO: UnionInfo<ClaimCardDetails, ClaimCardDetailsType> = {
  tag: 'type',
  classes: new ClaimCardDetailsClass()
};
