import { Component } from '@angular/core';
import { CommonCode } from '../../../../../../../../common/code/system';
import { JobType } from '../../../../../../../../common/model/job/payload';
import { AuthService } from '../../../../../common/service/auth.service';
import { JobAccessor } from '../../edit/setup-job-edit.model';

@Component({
  selector: 'app-setup-job-payload-debug-echo',
  templateUrl: './setup-job-payload-debug-echo.component.html',
  styleUrls: ['./setup-job-payload-debug-echo.component.scss']
})
export class SetupJobPayloadDebugEchoComponent extends JobAccessor<JobType.DebugEcho> {
  readonly CommonCode = CommonCode;

  constructor(
    public auth: AuthService
  ) {
    super();
  }
}
