import { CollectionInfo } from "../../../info/collection";
import { SchemaInfo } from "../../../info/schema";
import { UnionInfo } from "../../../info/union";
import { ID_DEFAULT } from "../../../toolbox/id";
import { FusionCollection } from "../../fusion";
import { SCHEMA_OBJECT } from "../../schema/base";
import { EventCategory } from "../category";

/** Tagged type of account event. */
export enum AccountResultType {
  Form,
  Import
}

/** Modifications of an account. */
export type AccountResult =
  AccountFormResult     |
  AccountImportResult   ;

/** Tagged account result with comment. */
export interface AccountTagResult {
  /** All account results belong to account category. */
  category: EventCategory.Account;
  /** Account that was modified. */
  _account: string;
}

/** Result of completing a custom form on an account. */
export class AccountFormResult implements AccountTagResult {
  readonly category = EventCategory.Account;
  readonly type = AccountResultType.Form;
  
  constructor(
    /** Account task was performed on. */
    public _account = ID_DEFAULT,
    /** Task that was performed. */
    public _task = ID_DEFAULT,
    /** Result of completing form. */
    public value: Record<string, any> = {}
  ) {}

  static collectioninfo: CollectionInfo<FusionCollection, AccountFormResult> = {
    _account: 'accounts',
    _task: 'tasks'
  }

  static schemainfo: SchemaInfo<AccountFormResult> = {
    value: SCHEMA_OBJECT
  }
}

/** Task to be completed for an account */
export class AccountImportResult implements AccountTagResult {
  readonly category = EventCategory.Account;
  readonly type = AccountResultType.Import;

  constructor(
    /** Account that was updated. */
    public _account = ID_DEFAULT
  ) {}

  static collectioninfo: CollectionInfo<FusionCollection, AccountImportResult> = {
    _account: 'accounts'
  }
}

export class AccountResultClass {
  [AccountResultType.Import] = new AccountImportResult();
  [AccountResultType.Form] = new AccountFormResult();
}

/** Union type information for account results. */
export const ACCOUNT_RESULT_UNIONINFO: UnionInfo<AccountResult, AccountResultType> = {
  tag: 'type',
  classes: new AccountResultClass()
}