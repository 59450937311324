import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { errorResponse } from "../../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { LogService } from '../../../common/service/log.service';
import { postRequest } from '../../../common/toolbox/request';

@Component({
  selector: 'app-demo-log',
  templateUrl: './demo-log.component.html',
  styleUrls: ['./demo-log.component.scss']
})
export class DemoLogComponent {
  readonly StatusLevel = StatusLevel;

  static title() {
    return 'Log';
  }

  constructor(
    private http: HttpClient,
    private log: LogService
  ) {}

  show(message: string, status?: StatusLevel) {
    this.log.show(message, status);
  }

  async validate() {
    let response = await postRequest(this.http, 'auth/login', { foobar: 'test', values: [1, 2, 3], subobject: { a: 1, b: 2, c: 3 } } as any);
    if (errorResponse(response)) this.log.show(response);
  }
}
