import { Pipe } from '@angular/core';
import { AccountJoin } from '../../../../../common/model/account/account';
import { accountName } from '../../../../../common/toolbox/account';

@Pipe({ name: 'account' })
export class AccountPipe {

  /** Format an account to a display title. */
  transform(account: AccountJoin): string
  transform(account: AccountJoin | undefined, fallback: string): string
  transform(account?: AccountJoin, fallback?: string): string {
    if (!account) return fallback!;
    return accountName(account);
  }
}
