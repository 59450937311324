import { currency, CURRENCY_DEFAULT, currencyCoerce, currencyValidate } from "../toolbox/currency";
import { INT32_MAX, INT32_MIN } from "../toolbox/number";
import { objectDefined } from "../toolbox/object";
import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";

/** Validate object is a string. */
export class StringValidator extends Validator<string> {

  constructor(
    /** Maximum length for string. */
    public maxLength?: number,
    /** Minimum length for string. */
    public minLength?: number
  ) {
    super();
  }

  value() {
    return '';
  }

  parse(value: string): string {
    return value;
  }

  schema() {
    return this.property ?? objectDefined({
      bsonType: 'string',
      maxLength: this.maxLength,
      minLength: this.minLength
    });
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    if (typeof value !== 'string') return ValidationStatus.Error;
    if (this.minLength && value.length < this.minLength) return ValidationStatus.Error;
    if (this.maxLength && value.length > this.maxLength) return ValidationStatus.Error;
    return ValidationStatus.Okay;
  }

  override logs() {
    let message: string[] = ['Expected string', '.'];

    if (this.minLength || this.maxLength) {
      message.splice(1, 0, ` (length: ${this.minLength ?? 0} ~ ${this.maxLength ?? '∞'})`);
    }

    return [new ValidatorLog(message.join(''))];
  }
}

/** Validate object is currency. */
export class CurrencyValidator extends Validator<currency> {

  constructor(
    /** Minimum currency value. */
    public min?: number,
    /** Maximum currency value. */
    public max?: number
  ) {
    super();
  }

  value() {
    return CURRENCY_DEFAULT;
  }

  parse(value: string) {
    return currencyCoerce(value, !this.optional, this.min, this.max);
  }

  schema() {
    return this.property ?? objectDefined({
      oneOf: [{
        bsonType: 'int',
        minimum: this.min ?? INT32_MIN,
        maximum: this.max ?? INT32_MAX
      }, {
        bsonType: 'long',
        minimum: this.min ?? Number.MIN_SAFE_INTEGER,
        maximum: this.max ?? Number.MAX_SAFE_INTEGER
      }]
    });
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    return currencyValidate(value) ? ValidationStatus.Okay : ValidationStatus.Error;
  }

  override logs() {
    return [new ValidatorLog(`Expected currency between ${this.min} ~ ${this.max}.`)];
  }
}