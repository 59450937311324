import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { LedgerAccountComponent } from './account/ledger-account.component';
import { LedgerArrowComponent } from './arrow/ledger-arrow.component';
import { LedgerItemComponent } from './item/ledger-item.component';
import { LedgerListComponent } from './list/ledger-list.component';
import { LedgerStepperDialogComponent } from './stepper/dialog/ledger-stepper-dialog.component';
import { LedgerStepperComponent } from './stepper/ledger-stepper.component';

@NgModule({
  declarations: [
    LedgerArrowComponent,
    LedgerAccountComponent,
    LedgerItemComponent,
    LedgerListComponent,
    LedgerStepperComponent,
    LedgerStepperDialogComponent
  ],
  exports: [
    LedgerArrowComponent,
    LedgerAccountComponent,
    LedgerItemComponent,
    LedgerListComponent,
    LedgerStepperComponent,
    LedgerStepperDialogComponent
  ],
  imports: [
    SharedModule
  ]
})
export class LedgerModule {}
