import { arrayDefined } from "../toolbox/array";
import { DocumentationKey } from "../toolbox/docapi";

/** Partial mapping of documentation keys to value. */
export type DocumentationPartial<T> = { [key in DocumentationKey]?: T }
/** Table of contents for documentation. */
export type DocumentationContents = DocumentationPartial<DocumentationCategory>;
/** List of pages under a category. */
export type DocumentationCategory = DocumentationPartial<string>;

/** A split documentation key. */
export class DocumentationSplit {
  constructor(
    /** Category of key. */
    public category: DocumentationKey,
    /** Page of key. */
    public page?: DocumentationKey,
    /** Section of key. */
    public section?: DocumentationKey
  ) {}

  /** Create from a key. */
  static from(key: DocumentationKey): DocumentationSplit {
    let [category, page, ...section] = key.split('.') as DocumentationKey[];
    if (!category) return new DocumentationSplit('' as DocumentationKey);

    return {
      category,
      page: page ? `${category}.${page}` : undefined,
      section: section.length ? key : undefined
    } as any;
  }

  /** Join a category and page into an existing key. */
  static join(category: DocumentationKey, page?: DocumentationKey, section?: DocumentationKey): DocumentationKey {
    return arrayDefined([category, page, section]).join('.') as DocumentationKey;
  }
}