import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { WorkflowPostResponse } from '../../../../../../../common/message/workflow';
import { Direction } from '../../../../../../../common/model/direction';
import { Step } from '../../../../../../../common/model/step';
import { WORK_CONDITION_NAME, WorkCondition, WorkConditionFormula } from '../../../../../../../common/model/work/condition';
import { WorkAction, WorkStep, Workflow } from '../../../../../../../common/model/work/flow';
import { MaybeId, idMaybe, idNull } from '../../../../../../../common/toolbox/id';
import { AuthService } from '../../../../common/service/auth.service';
import { DevService } from '../../../../common/service/dev.service';
import { FormulaService } from '../../../../common/service/formula.service';
import { LogService } from '../../../../common/service/log.service';
import { TaskService } from '../../../../common/service/task.service';
import { postRequest } from '../../../../common/toolbox/request';
import { workflowSource } from '../../../../common/toolbox/workflow';
import { SetupEditComponent } from '../../setup-edit.component';
import { WorkCrumb, WorkCrumbAction, WorkCrumbCondition, WorkCrumbStep } from './setup-workflow-edit.model';

@Component({
  selector: 'app-setup-workflow-edit',
  templateUrl: './setup-workflow-edit.component.html',
  styleUrls: ['./setup-workflow-edit.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupWorkflowEditComponent extends SetupEditComponent<Workflow, WorkflowPostResponse> {
  readonly WORK_CONDITION_NAME = WORK_CONDITION_NAME;
  readonly CONDITION_DEFAULT = new WorkConditionFormula();
  readonly ACTION_DEFAULT = new WorkAction();

  /** Current workflow being edited. */
  value = idMaybe(new Workflow());

  /** Current selected workflow breadcrumb. */
  crumb?: WorkCrumb;
  /** Current stack of workflow crumbs. */
  crumbs: WorkCrumb[] = [];
  /** Current step being edited. */
  current = 0;
  /** Current step being renamed. */
  renaming?: number;
  /** Workflow steps put into format for stepper. */
  steps: Step<WorkStep>[] = [];

  constructor(
    elementRef: ElementRef,
    log: LogService,
    public dev: DevService,
    private auth: AuthService,
    private http: HttpClient,
    private formulaService: FormulaService,
    private taskService: TaskService
  ) {
    super(elementRef, log);
    this.formulaService.previews(this.auth._inst);
  }

  /** Copy workflow to clipboard. */
  async onCode() {
    let tasks = await this.taskService.previews(this.auth._inst);
    let text = workflowSource(this.value, new Map(tasks.map(task => [task._id, task])));
    await navigator.clipboard.writeText(text);
  }

  /** Callback when setting new step. */
  onStep(step: WorkStep) {
    this.crumbs = [this.crumb = new WorkCrumbStep(step)];
  }

  /** Callback when setting new action. */
  onAction(action: WorkAction) {
    this.crumbs.push(this.crumb = new WorkCrumbAction(action));
  }

  /** Callback when setting new condition. */
  onCondition(condition: WorkCondition) {
    this.crumbs.push(this.crumb = new WorkCrumbCondition(condition));
  }

  /** Callback when adding new step to workflow. */
  onNew() {
    let step = new WorkStep('New Step');
    this.resync(this.value.steps.push(step) - 1);
  }

  /** Callback when removing step from workflow. */
  onDelete(index: number) {
    this.value.steps.splice(index, 1);
    this.resync(0);
  }

  /** Callback when moving step in workflow. */
  onMove(index: number, offset: Direction) {
    [this.value.steps[index]!, this.value.steps[index + offset]!] = [this.value.steps[index + offset]!, this.value.steps[index]!];
    this.resync(index + offset);
  }

  /** Submit current changes to work queue. */
  push() {
    return postRequest(this.http, 'workflows', { items: [this.value] });
  }

  /** Reset current form with new workflow. */
  async reset(value: MaybeId<Workflow>) {
    let _old = this.value._id;
    this.value = value;

    // Reset state if new workflow.
    if (_old !== this.value._id || idNull(_old)) this.resync(0);
  }

  /** Synchronize stepper and breadcrumbs with workflow. */
  private resync(current = this.current) {
    this.steps = this.value.steps.map(step => new Step(step));
    this.current = current;

    let step = this.value.steps[current];
    this.crumbs = step ? [{ type: 'step', step }] : [];
    this.crumb = this.crumbs[0];
  }
}
