import { IndexInfo } from "../info";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT } from "../toolbox/id";
import { NestedKey } from "../toolbox/keys";
import { CollectionsSettings } from "./setting-group/collections";
import { DisputesSettings } from "./setting-group/disputes";
import { GeneralSettings } from "./setting-group/general";

/** A nested key of application settings. */
export type ApplicationSettingsKey = NestedKey<ApplicationSettings>;

/** List of application settings. */
export class ApplicationSettings {
  constructor(
    /** General settings. */
    public general = new GeneralSettings(),
    /** Collections settings. */
    public collections?: CollectionsSettings,
    /** Dispute settings. */
    public disputes?: DisputesSettings
  ) {}

  static typeinfo: TypeInfo<SettingGroup> = {
    disputes: new DisputesSettings(),
    collections: new CollectionsSettings()
  }

  /** Get a setting with all properties marked as required. */
  static required(general?: GeneralSettings, collections?: CollectionsSettings, disputes?: DisputesSettings): Required<ApplicationSettings> {
    return new ApplicationSettings(general, collections ?? new CollectionsSettings(), disputes ?? new DisputesSettings()) as Required<ApplicationSettings>;
  }
}

/** List of all feature settings for an institution. */
export class SettingGroup extends ApplicationSettings {
  constructor(
    /** Unique identifier of setting. */
    public _id = ID_DEFAULT,
    /** Institution of setting. */
    public _inst = ID_DEFAULT,
    /** Name of setting group. */
    public name = ''
  ) {
    super();
  }

  static indexinfo: IndexInfo<SettingGroup> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** A preview of a setting group. */
export class SettingGroupPreview {
  constructor(
    /** Unique identifier of setting. */
    public _id = ID_DEFAULT,
    /** Institution of setting. */
    public _inst = ID_DEFAULT,
    /** Name of setting group. */
    public name = ''
  ) { }
}