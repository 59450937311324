import { Component } from '@angular/core';

@Component({
  selector: 'app-setup-base-footer',
  templateUrl: './setup-base-footer.component.html',
  styleUrls: ['./setup-base-footer.component.scss'],
  host: {
    class: 'button-row'
  }
})
export class SetupBaseFooterComponent {}