<button icon-button [menu-trigger]="previewMenu" tooltip="Preview Options">settings</button>
<app-menu #previewMenu="appMenu">
  <div class="preview">
    <app-field *ngIf="showMode">
      <app-label>Task Mode</app-label>
      <app-select [(ngModel)]="mode" (selected)="onMode($event)" (click)="$event.stopPropagation()">
        <app-option *ngFor="let mode of TASK_MODES" [pair]="mode"></app-option>
      </app-select>
    </app-field>
    <app-field>
      <app-label>Account Category</app-label>
      <app-code [(ngModel)]="accountCategory" [category]="CommonCode.AccountCategory" (selected)="onChanges()" (click)="$event.stopPropagation()"></app-code>
    </app-field>
    <app-field>
      <app-label>Claim Type</app-label>
      <app-code [(ngModel)]="claimType" [category]="DisputesCode.ClaimType" (selected)="onChanges()" (click)="$event.stopPropagation()"></app-code>
    </app-field>
  </div>
</app-menu>