import { holidayDate, holidayDateRange } from "./holiday";
import { numberPadSigned } from "./number";

/** Possible periods for a date. */
export enum DatePeriod { AM, PM }

/** Millliseconds in a minute. */
export const MINUTE_MILLISECONDS = 60000;
/** Millliseconds in an hour. */
export const HOUR_MILLISECONDS = 60 * MINUTE_MILLISECONDS;
/** Milliseconds in a day. */
export const DAY_MILLISECONDS = 24 * HOUR_MILLISECONDS;
/** Milliseconds in a month. */
export const MONTH_MILLISECONDS = 31 * DAY_MILLISECONDS;
/** Milliseconds in a year. */
export const YEAR_MILLISECONDS = 365 * DAY_MILLISECONDS;

/** Minimum date offset, in number of days. */
export const DAYS_MIN = -99999;
/** Maximum date offset, in number of days. */
export const DAYS_MAX = 99999;
/** Maximum date offset, in milliseconds. */
export const MILLISECONDS_MAX = DAYS_MAX * DAY_MILLISECONDS;

/** Default format to use for dates. */
export const DATE_FORMAT_DEFAULT = 'M/d/yyyy';
/** Format for long, human-readable dates in the form "January 1, 2013". */
export const DATE_FORMAT_LONG = 'MMMM d, YYYY';
/** Standard format for RFC 822 dates. */
export const DATE_FORMAT_RFC_822 = 'E, dd MMM y hh:mm:ss Z';
/** Test if a string follows ISO 8601 timestamp format. */
export const ISO_TIMESTAMP_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
/** Test if a string follows file timestamp format. */
export const FILE_TIMESTAMP_REGEX = /^([\d]{4})-([\d]{2})-([\d]{2})(?:T([\d]{2})-([\d]{2})-([\d]{2}).([\d]{3}))?$/;
/** Table of days to long month names. */
export const MONTH_NAME = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
/** Table of days to short month names. */
export const MONTH_NAME_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
/** Table of weekdays to long weekday names. */
export const WEEKDAY_NAME = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/** A basic cron expression, "At midnight every day". */
export const CRON_DEFAULT = '0 0 * * *';

/** Valid values for date fields. */
export type DateModel = Date | DateRange | undefined;
/** Positions of a date, relative to a date. */
export enum DatePosition { Before, Equal, After, Invalid };
/** Positions of a date, relative to a range. */
export enum DateRangePosition { Before, Start, Middle, End, After };

/** Model representing a range of dates. */
export class DateRange {

  constructor(
    /** Start of range. */
    public start = new Date(),
    /** End of range. */
    public end = new Date(),
  ) {
    let [starttime, endtime] = DateRange.order(start, end);
    this.start = new Date(starttime);
    this.end = new Date(endtime);
  }

  /** Set correct range before sending. */
  toISOString() {
    return new DateRange(dateFloor(this.start), dateCeil(this.end));
  }

  /** Create date from given value. */
  static date(value: DateModel) {
    return new Date(DateRange.valid(value) ? value.start : value ?? new Date());
  }

  /** Create date range from given value. */
  static range(value: DateModel): DateRange {
    return DateRange.valid(value) ? new DateRange(value.start, value.end) : new DateRange(value, value);
  }

  /** Create date range from min and max offset, in days. */
  static minmax(min = DAYS_MIN, max = DAYS_MAX) {
    return new DateRange(dateOffset(new Date(), min), dateOffset(new Date(), max))
  }

  /** Check if given value is a date range. */
  static valid(value: any): value is DateRange {
    return value instanceof Object && (value.start instanceof Date) && (value.end instanceof Date);
  }

  /** Create date range from given values. */
  static sized(start: Date, days: number) {
    return new DateRange(start, dateOffset(start, days));
  }

  /** Check if two ranges fall on same days. */
  static equal(a: DateRange | undefined, b: DateRange | undefined | null) {
    return dateDay(a?.start) === dateDay(b?.start) && dateDay(a?.end) === dateDay(b?.end);
  }

  /** Check where a value falls on a date range. */
  static compare(date: Date, range: DateModel) {
    let span = DateRange.range(range);
    let day = dateFloor(date).getTime();
    let [start, end] = DateRange.order(dateFloor(span.start), dateFloor(span.end));

    if (day < start) return DateRangePosition.Before;
    else if (day > end) return DateRangePosition.After;
    else if (day === start) return DateRangePosition.Start;
    else if (day === end) return DateRangePosition.End;
    else return DateRangePosition.Middle;
  }

  /** Clamp a value into a given date range. */
  static clamp(date: Date, range: DateRange): Date
  static clamp(date: DateRange, range: DateRange): DateRange
  static clamp(date: Date | DateRange, range: DateRange): Date | DateRange {
    if (DateRange.valid(date)) return new DateRange(this.clamp(date.start, range), this.clamp(date.end, range));

    switch (this.compare(date, range)) {
      case DateRangePosition.Before:
        return new Date(range.start);
      case DateRangePosition.After:
        return new Date(range.end);
      default:
        return new Date(date);
    }
  }

  /** Return true if a date falls within the given range. */
  static contains(date: Date, value: DateRange) {
    switch (this.compare(date, value)) {
      case DateRangePosition.Before:
      case DateRangePosition.After:
        return false;
      default:
        return true;
    }
  }

  /** Get start and end range query for value. */
  static query(value: DateRange | undefined | null): [Date | undefined, Date | undefined] {
    if (!value) return [undefined, undefined];

    return [
      dateNull(value.start) ? undefined : dateFloor(value.start),
      dateNull(value.end) ? undefined : dateCeil(value.end)
    ];
  }

  /** Get two dates into the right order. */
  private static order(start: Date, end: Date): [number, number] {
    let [s, e] = [start.getTime(), end.getTime()];
    return s < e ? [s, e] : [e, s];
  }
}

/** Maps a list of objects by date. */
export class DateMap<T> {
  /** Internal mapping from dates to items. */
  private map = new Map<string, T[]>();

  constructor(items: [Date, T][] = []) {
    this.merge(items);
  }

  /** Add item to particular date. */
  set(date: Date, item: T) {
    this.list(date).push(item);
  }

  /** Get list of items on particular date */
  get(date: Date) {
    return this.list(date);
  }

  /** Set multiple items in map. */
  merge(items: [Date, T][]) {
    for (let [date, item] of items) {
      this.set(date, item);
    }
  }

  /** Clear list of items in map. */
  clear() {
    this.map.clear();
  }

  /** Get list of a particular date. */
  private list(date: Date) {
    let key = dateFloor(date).toISOString();
    let list = this.map.get(key);
    if (!list) this.map.set(key, list = []);
    return list;
  }
}

/** Returns true if date is valid. */
export function dateValid(date?: Date): date is Date {
  return !!date && !isNaN(date.getTime());
}

/** Get number of days in a date range. */
export function dateRangeDays(range: DateRange): number {
  let start = dateDay(range.start);
  let end = dateDay(range.end);
  return start <= end ? end - start + 1 : 0;
}

/** Get count of business days in a date range. */
export function dateRangeBusinessDays(range: DateRange) {
  return dateRangeDays(range) - (dateRangeWeekends(range) + holidayDateRange(range));
}

/** Check if given day is a weekend. */
export function dateWeekend(date: Date) {
  return date.getDay() === 0 || date.getDay() === 6;
}

/** Check  */

/** Get count of weekends in a date range. */
export function dateRangeWeekends(range: DateRange): number {
  let start = dateDay(range.start);
  let end = dateDay(range.end);
  if (start > end) return 0;

  // Skip past weeks.
  let weekday = range.start.getDay();
  let weeks = (end - start) / 7 | 0;
  let count = 2 * weeks;

  // Calculate remainder manually.
  start += 7 * weeks;
  while (start <= end) {
    count += +(weekday === 0 || weekday === 6);
    weekday = (weekday + 1) % 7;
    ++start;
  }

  return count;
}

/** Return true if specified date:
 *  1) Is undefined
 *  2) Is invalid
 *  3) Equals 1970-01-01 (Unix Epoch)
 *  4) Equals 1900-01-01 (SQL Server Null Date).
 */
export function dateNull(date?: Date): date is undefined {
  if (!date) return true;
  let time = date.getTime();
  return !time || time === -2208988800000;
}

/** Get date floored to nearest day. */
export function dateFloor(date = new Date()) {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
}

/** Get date rounded up to nearest day. */
export function dateCeil(date = new Date()) {
  date = new Date(date);
  date.setHours(23, 59, 59, 999);
  return date;
}

/** Get day of date, in number of days since unix. */
export function dateDay(date: Date): number
export function dateDay(date?: Date): number | undefined
export function dateDay(date?: Date) {
  return date ? date.getTime() / DAY_MILLISECONDS | 0 : undefined;
}

/** Get hour of date. */
export function dateHour(date: Date): number
export function dateHour(date?: Date): number | undefined
export function dateHour(date?: Date) {
  return date ? date.getTime() / HOUR_MILLISECONDS | 0 : undefined;
}

/** Get date floored to nearest hour. */
export function floorHour(date = new Date()): Date {
  date = new Date(date);
  date.setHours(date.getHours(), 0, 0, 0);
  return date;
}

/** Get the minimum of two dates. */
export function dateMin(d1: Date, d2: Date) {
  return d1.getTime() < d2.getTime() ? d1 : d2;
}

/** Get the maximum of two dates. */
export function dateMax(d1: Date, d2: Date) {
  return d1.getTime() > d2.getTime() ? d1 : d2;
}

/** Check if two dates fall on same day. */
export function dateEqual(d1: Date, d2: Date) {
  return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
}

/** Check if two dates fall on same month. */
export function monthEqual(d1: Date, d2: Date) {
  return d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
}

/** Check if two dates fall on same year. */
export function yearEqual(d1: Date, d2: Date) {
  return d1.getFullYear() === d2.getFullYear();
}

/** Check if a date is today. */
export function isToday(date : Date) : boolean {
  return dateEqual(date, new Date());
}

/** Get name of given month. */
export function monthName(date: Date) {
  return MONTH_NAME_SHORT[date.getMonth()] ?? 'Invalid';
}

/** Sleep specified number of milliseconds. */
export function sleep(ms = 0) {
  return new Promise(res => setTimeout(res, ms));
}

/** Offset date by certain number of time. */
export function dateOffset(date: Date, days = 0, hours = 0, minutes = 0, seconds = 0) {
  return new Date(date.getTime() + days * DAY_MILLISECONDS + hours * HOUR_MILLISECONDS + minutes * MINUTE_MILLISECONDS + seconds * 1000);
}

/** Offset date by certain number of days, skipping weekends. */
export function dateOffsetBusiness(date: Date, days = 0): Date {
  if (days === 0) {
    if (holidayDate(date) || dateWeekend(date)) {
      return dateOffsetBusiness(date, 1);
    } else {
      return date;
    }
  }
  let guess = dateOffset(date, days);
  let tomorrow = dateOffset(date, 1);
  let range = new DateRange(tomorrow, guess);
  let off = dateRangeWeekends(range) + holidayDateRange(range);
  return dateOffsetBusiness(guess, off);
}

/** Initialize a date in local time instead of UTC. */
export function dateLocal(date: string) {
  let value = new Date(date);
  return new Date(value.getTime() + value.getTimezoneOffset() * 60000);
}

/** Create a new date from parts. */
export function dateParts(year: string, month: string, day: string, hour: string, minute: string, second: string, period: DatePeriod) {
  return new Date(`${MONTH_NAME[+month - 1]} ${day} ${year} ${hour}:${minute}:${second} ${period === DatePeriod.AM ? 'AM' : 'PM'}`);
}

/** Get period of date. */
export function datePeriod(date: Date) {
  return date.getHours() >= 12 ? DatePeriod.PM : DatePeriod.AM;
}

/** Compare two dates. */
export function dateCompare(date?: Date, pivot?: Date) {
  switch (Math.sign(date?.getTime()! - pivot?.getTime()!)) {
    case -1: return DatePosition.Before;
    case 0: return DatePosition.Equal;
    case +1: return DatePosition.After;
    default: return DatePosition.Invalid;
  }
}

/** Get number of milliseconds until given time. */
export function dateUntil(date: Date): number {
  return date.getTime() - Date.now();
}

/** Format a date to a filename. */
export function dateFilename(date = new Date(), time = false) {
  let text = date.toISOString().replace(/:/g, '-').slice(0, -1);
  return time ? text : text.split('T')[0]!;
}

/** Get a date from a filename. */
export function filenameDate(filename: string) {
  let match = filename.match(FILE_TIMESTAMP_REGEX);
  if (!match) return undefined;
  let [, year, month, day, hour, minute, second, millisecond] = match;
  return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}Z`);
}

/**
 *  Format date using standard Unicode date format strings.
 *  @see http://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 */
export function dateFormat(input: string | Date, format = DATE_FORMAT_DEFAULT) {
  let date = typeof input === 'string' ? new Date(input) : input;
  return format.replace(/y+|Y+|M+|d+|E+|h+|H+|m+|s+|S+|a+|Z+/g, m => {
    switch (m) {
      case 'y': case 'yyy': case 'yyyy': case 'Y': case 'YYY': case 'YYYY':
        return `${date.getFullYear()}`;
      case 'yy': case 'YY':
        return `${date.getFullYear()}`.slice(-2);
      case 'M':
        return `${date.getMonth() + 1}`;
      case 'MM':
        return `${date.getMonth() + 1}`.padStart(2, '0');
      case 'MMM':
        return `${MONTH_NAME_SHORT[date.getMonth()]}`;
      case 'MMMM':
        return `${MONTH_NAME[date.getMonth()]}`;
      case 'd':
        return `${date.getDate()}`;
      case 'dd':
        return `${date.getDate()}`.padStart(2, '0');
      case 'E': case 'EE': case 'EEE':
        return `${WEEKDAY_NAME[date.getDay()]!.slice(0, 3)}`
      case 'EEEE':
        return `${WEEKDAY_NAME[date.getDay()]}`;
      case 'h':
        return `${date.getHours() % 12 || 12}`;
      case 'hh':
        return `${date.getHours() % 12 || 12}`.padStart(2, '0');
      case 'H':
        return `${date.getHours()}`;
      case 'HH':
        return `${date.getHours()}`.padStart(2, '0');
      case 'm':
        return `${date.getMinutes()}`;
      case 'mm':
        return `${date.getMinutes()}`.padStart(2, '0');
      case 's':
        return `${date.getSeconds()}`;
      case 'ss':
        return `${date.getSeconds()}`.padStart(2, '0');
      case 'S': case 'SS': case 'SSS':
        return `00${date.getMilliseconds()}`.slice(-m.length);
      case 'a': case 'aa': case 'aaa':
        return `${date.getHours() >= 12 ? 'pm' : 'am'}`;
      case 'Z': case 'ZZ': case 'ZZZ':
        return `${numberPadSigned(date.getTimezoneOffset(), 4)}`
      default:
        return m;
    }
  })
}

/** Convert a DateRange to a pair offsets (in days) from today to the start and end dates of the DateRange */
export function dateDayOffsets(dateRange: DateRange): { daysToStart: number, daysToEnd: number } {
  let today = dateFloor(new Date()).getTime();
  let startOffset = (dateFloor(dateRange.start).getTime() - today) / DAY_MILLISECONDS;
  let endOffset = (dateFloor(dateRange.end).getTime() - today) / DAY_MILLISECONDS;

  // Math.round necessary in case date range spans a DST event
  return { daysToStart: Math.round(startOffset), daysToEnd: Math.round(endOffset) };
}
