import { Pipe } from '@angular/core';
import { Display } from "../../../../../common/model/display";
import { Property } from "../../../../../common/model/property";
import { arrayLast } from '../../../../../common/toolbox/array';
import { KeyJoin, keyLast } from "../../../../../common/toolbox/keys";
import { titleCase } from "../../../../../common/toolbox/string";
import { AuthService } from '../service/auth.service';
import { FormulaService } from '../service/formula.service';

/** Fetch label for given field or column. */
@Pipe({ name: 'display' })
export class DisplayPipe {

  constructor(
    private auth: AuthService,
    private formulas: FormulaService
  ) {}

  /** Get title for a display name. */
  async transform(key: string, name: string | undefined, property?: Property): Promise<string> {
    // Use formula name instead of id as final fallback.
    let fallback = titleCase(keyLast(key));
    let _id = Display.formula(key);
    if (_id) {
      let formula = await this.formulas.item({ _inst: this.auth._inst, _id });
      fallback = formula.name;
    }

    // Fall back on label, model label and key name in that order.
    let title = name || property?.name || fallback;

    // Ensure "Foobar - Field" gets shortened to "Field"
    return arrayLast(title.split(KeyJoin.Separator)) ?? '';
  }
}
