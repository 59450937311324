import { Direction } from "../../../../../common/model/direction";
import { NestedKey } from "../../../../../common/toolbox/keys";
import { validatorValue } from "../../../../../common/toolbox/validate";

/** A callback to fetch a key. */
export type GridExtractCallback<T> = (key: NestedKey<T>, value: T) => any;

/** A key-value filter for a grid column. */
export type GridFilter<T> = [NestedKey<T>, string];

/** Event emitted when column is toggled. */
export interface ColumnToggle<T> {
  /** Column that toggled. */
  column: NestedKey<T>
}

/** Event emitted when column is resorted. */
export interface ColumnSort<T> {
  /** Column that emitted this resort. */
  column: NestedKey<T>
  /** Current sorting value of column. */
  direction?: Direction
}

/** Event emitted when column is refiltered. */
export interface ColumnFilter<T> {
  /** Column that emitted a filter. */
  column: NestedKey<T>
  /** Query to filter on. */
  filter: string
}

/** Cycle through sort directions. */
export function directionNext(sort?: Direction) {
  switch (sort) {
  case undefined:   return 1;
  case 1:           return -1;
  case -1:          return undefined;
  }
}

/** Create a key-value search record from a grid filter. */
export function filterRecord<T>(value: T, filter?: GridFilter<any>[]): { [K in NestedKey<T>]?: any } {
  let partial: { [K in NestedKey<T>]?: any } = {};
  if (!filter) return partial;

  let validator = validatorValue(value);
  for (let [key, value] of filter) {
    partial[key as keyof typeof partial] = validator.subparse(value, key);
  }

  return partial;
}