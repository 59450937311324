import { Component, Input } from '@angular/core';
import { CommonCode } from '../../../../../../../../../../common/code/system';
import { TaskConfigClaimReviewConfig } from '../../../../../../../../../../common/model/task-config';

@Component({
  selector: 'app-setup-task-config-claim-review-config',
  templateUrl: './setup-task-config-claim-review-config.component.html',
  styleUrls: ['./setup-task-config-claim-review-config.component.scss']
})
export class SetupTaskConfigClaimReviewConfigComponent {
  readonly CommonCode = CommonCode;

  /** Message placeholder. */
  @Input() type = '';
  /** Configuration being edited. */
  @Input() config = new TaskConfigClaimReviewConfig();
}
