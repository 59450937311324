<app-card>
  <app-card-header>Addresses</app-card-header>
  <app-card-body>
    <app-addresses-edit [(ngModel)]="member.addresses" [(dirty)]="addressesDirty"></app-addresses-edit>
  </app-card-body>
</app-card>
<app-card>
  <app-card-header>Phones</app-card-header>
  <app-card-body>
    <app-phones-edit [(ngModel)]="member.phones" [(dirty)]="phonesDirty"></app-phones-edit>
  </app-card-body>
</app-card>
<app-card>
  <app-card-header>Emails</app-card-header>
  <app-card-body>
    <app-emails-edit [(ngModel)]="member.emails" [(dirty)]="emailsDirty"></app-emails-edit>
  </app-card-body>
</app-card>
<app-save *ngIf="addressesDirty || phonesDirty || emailsDirty" (save)="onSave()"></app-save>