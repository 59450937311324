import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-row',
  templateUrl: './button-row.component.html',
  styleUrls: ['./button-row.component.scss']
})
export class ButtonRowComponent {

  /** Label for submit button, or empty to hide. */
  @Input() submitLabel = 'Submit';
  /** True if submit button disabled. */
  @Input() submitDisabled = false;

  /** Label for cancel button, or empty to hide. */
  @Input() cancelLabel = 'Cancel';
  /** True if cancel button disabled. */
  @Input() cancelDisabled = false;

  /** Emits when submit button clicked. */
  @Output() submit = new EventEmitter<void>();
  /** Emits when cancel button clicked. */
  @Output() cancel = new EventEmitter<void>();
}