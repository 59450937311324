import { Pipe } from '@angular/core';
import { FormAccessor } from '../component/form/form-accessor';

@Pipe({
  name: 'propertyReadonly'
})
export class PropertyReadonlyPipe {
  transform(readonly?: boolean, locked?: boolean, reopened?: boolean) {
    return FormAccessor.readonly(readonly, locked, reopened);
  }
}
