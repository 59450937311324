<app-icon>{{type | icon:PROPERTY_TYPE_ICON}}</app-icon>
<app-select
  [multiple]="multiple"
  [disabled]="disabled"
  [required]="required"
  [readonly]="readonly"
  [ngModel]="value"
  (ngModelChange)="writeValue(keys($event))"
  (dirtyChange)="setDirty($event)"
  (touchChange)="setTouched($event)">
  <app-option *ngFor="let i of list" [view]="i | function:display" [value]="i"></app-option>
</app-select>