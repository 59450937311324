import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { WorkFilter } from "../../../../../common/model/work/filters";
import { WorkItem } from "../../../../../common/model/work/item";
import { WorkQueue, WorkQueuePreview } from "../../../../../common/model/work/queue";
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { Newable } from "../../../../../common/toolbox/object";
import { DialogService } from "../component/dialog/dialog.service";
import { CachePreviewService } from "../toolbox/cache-preview-service";
import { QueueOptions } from "../toolbox/fusion";
import { IntervalOptions, IntervalTimer } from "../toolbox/interval";
import { getRequest, patchRequest } from "../toolbox/request";
import { AuthService } from "./auth.service";
import { IdleService } from "./idle.service";
import { LogService } from "./log.service";

/** A query to fetch a specific work queue. */
export class WorkQueueQuery {
  constructor(
    /** ID of queue. */
    public _id = ID_DEFAULT,
    /** Institution of queue. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Timer for events that updates local storage as needed. */
class QueueTimer extends IntervalTimer {
  override save(_user: string) {
    return DB.put('queueOptions', { _user, interval: this.options });
  }
}

@Injectable({
  providedIn: 'root'
})
export class WorkQueueService extends CachePreviewService<WorkQueue, WorkQueueQuery, WorkQueuePreview> {
  readonly route = 'work-queues/preview';
  readonly Type = WorkQueue as unknown as Newable<WorkQueue>;

  /** Emits when a particular queue has been updated. */
  updated = new Subject<void>();
  /** Timer for performing updates. */
  private timer!: QueueTimer;

  /** Configuration for event timer. */
  get config() { return this.timer.config; }
  set config(config: IntervalOptions) { this.timer.config = config; }

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private idle: IdleService,
    private auth: AuthService
  ) {
    super(WorkQueueQuery, log, dialog, http);
    this.load();
  }

  /** Initialize position in a work queue. */
  async initialize(queue: WorkQueue, item?: WorkItem): Promise<WorkItem | undefined> {
    if (item) return item;

    // Get next item of this queue.
    let next = await patchRequest(this.http, 'work-items/next', {
      _inst: queue._inst,
      filter: WorkFilter.Unworked,
      direction: 1,
      cursor: WorkItem.cursor(queue._id)
    });
    if (errorResponse(next)) {
      this.log.show(next);
      return undefined;
    }

    // Return item or empty log.
    if (next.cursor) return next.cursor;
    this.log.show(`Work Queue "${queue.name}" has no available items.`);
    return undefined;
  }

  protected async multiple(queries: ArraySome<WorkQueueQuery>) {
    return getRequest(this.http, 'work-queues', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }

  /** Load initial interval config. */
  private async load() {
    let options = new QueueOptions(this.auth.session._id)
    let result = await DB.get('queueOptions', this.auth.session._id);
    if (errorResponse(result)) {
      this.log.show(result);
    } else if (result) {
      options = result;
    }

    this.timer = new QueueTimer(options.interval, () => this.updated.next(), this.log, this.auth, this.idle);
  }
}