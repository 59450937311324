import { ImportCode } from "../code/system";
import { IndexInfo } from "../info";
import { PropertyInfo } from "../info/prop";
import { ID_DEFAULT } from "../toolbox/id";
import { PropertyType } from "./property-type";

/** Many-to-many member-card relationship. */
export class MemberCardRelationship {
  constructor(
    /** Unique identifier of this relationship. */
    public _id = ID_DEFAULT,
    /** Member linked to card. */
    public _member = ID_DEFAULT,
    /** Unique ID of card. */
    public _card = ID_DEFAULT,
    /** Relationship code. */
    public relationship = ''
  ) {}

  static propinfo: PropertyInfo<MemberCardRelationship> = {
    relationship: { type: PropertyType.Code, category: ImportCode.MemberCard }
  }

  static indexinfo: IndexInfo<MemberCardRelationship> = [
    { key: { _member: 1, _card: 1 }, unique: true }
  ];
}