import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'base-tab-label',
  template: ''
})
export class TabBaseComponent {
  /** True if currently active. */
  @Input() active = false;
  /** True to display close button. */
  @Input() closeable = false;
  /** Emits when tab is closed. */
  @Output() closed = new EventEmitter<boolean>();
}

@Component({
  selector: 'app-tab-label',
  templateUrl: './tab-label.component.html',
  styleUrls: ['./tab-label.component.scss'],
  host: {
    '[class.active]': 'active',
    '[attr.active]': 'active ? "" : null',
    '[class.elevation-high]': 'active'
  }
})
export class TabLabelComponent extends TabBaseComponent {

  constructor(
    public element: ElementRef<HTMLElement>
  ) {
    super();
  }
}
