import { ADJECTIVES } from "../data/adjectives";
import { BUSINESS_SUFFIXES } from "../data/business-suffixes";
import { FEMALE_NAMES } from "../data/female-names";
import { LAST_NAMES } from "../data/last-names";
import { MALE_NAMES } from "../data/male-names";
import { OCCUPATIONS } from "../data/occupations";
import { STATE_CITIES } from "../data/state-cities";
import { CARD_NETWORKS, CARD_NETWORK_FORMAT, CardNetwork } from "../model/card";
import { CodeMap } from "../model/code-type";
import { SystemCode } from "../code/system";
import { STATE_AREA_CODE, STATE_LICENSE, STATE_LIST, STATE_NAME, STATE_ZIP, State } from "../model/state";
import { arrayDefined, arrayFill, arraySome } from "./array";
import { currency } from "./currency";
import { luhnChecksum } from "./luhn";
import { objectMerge } from "./object";
import { dateOffset } from "./time";

/** A randomly generated name. */
export class RandomName {
  constructor(
    /** First name. */
    public firstName = '',
    /** Middle name. */
    public middleName?: string,
    /** Last name. */
    public lastName = ''
  ) {}

  /** Create copy of name. */
  copy() {
    return new RandomName(this.firstName, this.middleName, this.lastName);
  }

  /** Join together parts of name. */
  join(middle = true) {
    return arrayDefined([this.firstName, middle ? this.middleName : undefined, this.lastName]).join(' ');
  }
}

/** A randomly generated card. */
export class RandomCard {
  constructor(
    /** Number of card. */
    public number = '',
    /** Network of card. */
    public network = CardNetwork.Visa
  ) {}
}

/** Set the current random seed. */
export function randomSeed(value: number | string | Date = Date.now()) {
  seed = +value | 0;
}

/** Get the current random seed. */
export function randomFetch() {
  return seed;
}

/** Perform one random roll using Mulberry32.
 *  @link https://github.com/bryc/code/blob/master/jshash/PRNGs.md#mulberry32
 */
export function randomNumber() {
  seed |= 0; seed = seed + 0x6D2B79F5 | 0;
  let t = Math.imul(seed ^ seed >>> 15, 1 | seed);
  t = t + Math.imul(t ^ t >>> 7, 61 | t) ^ t;
  return (t ^ t >>> 14) >>> 0;
}

/** Get a random number in range. */
export function randomRange(min: number, max: number) {
  let [a, b] = min < max ? [min, max] : [max, min];
  return Math.floor((b - a + 1) * (randomNumber() / 0xFFFFFFFF) + a);
}

/** Get a random number of digits. */
export function randomDigits(min: number, max: number) {
  return `${randomRange(min, max)}`.padStart(Math.max(`${min}`.length, `${max}`.length), '0');
}

/** Get a random number of hex digits. */
export function randomDigitsHex(min = 0x00000000, max = 0xFFFFFFFF) {
  return randomRange(min, max).toString(16).padStart(8, '0');
}

/** Take a random roll of an n-sided die and return true if landed on 1. */
export function randomRoll(value: number | string | Date) {
  return randomRange(1, +value) === 1;
}

/** Get a random boolean. */
export function randomBool() {
  return randomNumber() < 0x3FFFFFFF;
}

/** Get a random uppercase letter. */
export function randomLetter() {
  return String.fromCharCode(randomRange(65, 90));
}

/** Get a random date in range. */
export function randomDate(start: Date, end: Date) {
  return new Date(randomRange(start.getTime(), end.getTime()));
}

/** Get a random date in range of days. */
export function randomDateDays(start: number, end: number) {
  return randomDate(dateOffset(new Date(), start), dateOffset(new Date(), end));
}

/**
 *  Get a random 6-digit challenge code that changes each hour.
 *  WARNING: This code is insecure and remains the same for all users.
 */
export function randomChallenge() {
  return '123456';
}

/** Get a random amount of currency in range. */
export function randomAmount(min: currency, max: currency): currency {
  return randomRange(min, max);
}

/** Get a random ID. */
export function randomId(length = 24) {
  return arrayFill(length, () => randomRange(0, 9)).join('');
}

/** Get a random item from array. */
export function randomElement(text: string): string
export function randomElement<T>(array: T[]): T
export function randomElement(value: any) {
  return value[randomRange(0, value.length - 1)];
}

/** Randomly pop an item from an array. */
export function randomPop<T>(value: T[]): T | undefined {
  return value.splice(randomRange(0, value.length - 1),1)[0];  
}

/** Get a random code value from available context. */
export function randomCode(context: CodeMap, category: SystemCode) {
  let list = context[category];
  return arraySome(list) ? randomElement(list).key : randomLetter();
}

/** Get random list of unique codes from available context. */
export function randomCodeSet(size: number, context: CodeMap, category: SystemCode) {
  let codes = context[category] ?? [];
  return codes.length >= size
  ? randomBlacklistArray(size, () => randomElement(codes), code => code.key, new Set()).map(c => c.key)
  : randomBlacklistArray(size, randomLetter, code => code, new Set());
}

/** Generate a random tax ID. */
export function randomTaxID() {
  return `9${randomDigits(0, 99999999)}`;
}

/** Get a random account number. */
export function randomAccountNumber() {
  return `${randomDigitsHex()}-L0${randomDigits(1, 9)}`;
}

/** Get a random occupation. */
export function randomOccupation() {
  return randomElement(occupations);
}

/** Get a random city of a given state. */
export function randomCity(state: State) {
  return randomElement(stateCities[state] ?? []) ?? '';
}

/** Get a random zip code of a given state. */
export function randomZipCode(state: State) {
  return randomRange(STATE_ZIP[state][0].low, STATE_ZIP[state][0].high);
}

/** Get a random phone number of a given state. */
export function randomPhone(state: State) {
  return `${randomElement(STATE_AREA_CODE[state])}${randomDigits(1000000, 9999999)}`;
}

/** Get a random license of a given state. */
export function randomLicense(state: State) {
  return STATE_LICENSE[state].generate();
}

/** Get a random first name. */
export function randomFirstName(male = randomBool()) {
  return randomElement(male ? maleNames : femaleNames);
}

/** Get a random last name. */
export function randomLastName() {
  return randomElement(lastNames);
}

/** Get a random name. */
export function randomName(male = randomBool(), middle = randomRoll(4)): RandomName {
  return new RandomName(randomFirstName(male), middle ? randomLetter() : undefined, randomElement(lastNames));
}

/** Get a random username for a person. */
export function randomEmailUsername(name = randomName()) {
  // Get username format.
  let username = '';
  switch (randomRange(1, 3)) {
  case 1:
    // jdoe
    username = [name.firstName[0]!, name.lastName].join('').replace(/[^a-zA-Z]/g, '').toLowerCase();
    break;
  case 2:
    // John.Doe
    username = name.join(false).replace(/[^a-zA-Z ]/g, '').replace(/ /g, '.');
    break;
  case 3:
    // johndoe john-doe john_doe
    username = name.join(false).replace(/[^a-zA-Z ]/g, '').replace(/ /g, randomElement(['', '-', '_']));
    break;
  }
  
  let number = randomRoll(5) ? randomRange(1, 9999) : '';
  return `${username}${number}`;
}

/** Get a random domain name for a business. */
export function randomBusinessDomain(name = randomBusinessName()) {
  let joiner = randomElement(['', '-']);
  let tld = randomElement(['com', 'net', 'org', 'co', 'us']);
  let server = name.join('').replace(/[^a-zA-Z0-9 ]/g, '').replace(/ +/g, joiner);
  return randomRoll(10) ? `${server}.${tld}` : `${server}.${tld}`.toLowerCase();
}

/** Get a random email for a person. */
export function randomEmail(name = randomName(), employer?: string[], business = randomRoll(5)) {
  let local = randomEmailUsername(name);
  let domain = employer && business ? randomBusinessDomain(employer) : `${randomElement(emailDomains)}.com`;
  return `${local}@${domain}`;
}

/** Get a random email for a business. */
export function randomBusinessEmail(name: string[]) {
  let local = randomRoll(5) ? randomEmailUsername() : randomElement(businessEmails);
  let domain = randomBusinessDomain(name);
  return `${local}@${domain}`;
}

/** Get a random business name. */
export function randomBusinessName(state = randomElement(STATE_LIST)): string[] {
  switch (randomRange(1, 9)) {
  case 1:
    // Smith's
    let name = randomLastName();
    return [`${name}${name.endsWith('s') ? '\'' : '\'s'}`];
  case 2:
    // John Smith & Davis
    let joiner = randomElement(['-', ' & ']);
    return [`${randomBool() ? randomLastName() : randomName(undefined, false).join(false)}`, joiner, randomLastName()];
  case 3:
    // AL Inc.
    return [state, randomElement(businessSuffixes)];
  case 4:
    // Alabama Inc.
    return [STATE_NAME[state], randomElement(businessSuffixes)];
  case 5:
    // Birmingham Inc.
    return [randomCity(state), randomElement(businessSuffixes)];
  case 6:
    // Smith Inc.
    return [randomLastName(), randomElement(businessSuffixes)];
  default:
    // Adjective Inc.
    return [randomElement(adjectives), randomElement(businessSuffixes)];
  }
}

/** Get a random card. */
export function randomCard(): RandomCard {
  let network = randomElement(CARD_NETWORKS);
  let info = randomElement(CARD_NETWORK_FORMAT[network]);
  let length = randomElement(info.length);
  let format = randomElement(info.prefixes);
  let prefix = typeof format === 'number' ? format : randomRange(format.low, format.high);
  return { network, number: randomCardNumber(length, `${prefix}`) };
}

/** Generate random card number with given prefix and number of digits. */
export function randomCardNumber(length: number, prefix: string) {
  let digits = length - prefix.length - 1;
  let number = `${prefix}${arrayFill(digits, () => randomRange(0, 9)).join('')}`;
  return `${number}${luhnChecksum(number)}`;
}

/** Generate random amounts appropriate for line chart. */
export function randomLineChart(count: number, start: [number, number], change: [number, number]) {
  let list = [randomRange(start[0], start[1])];

  for (let i = 1; i < count; ++i) {
    list.push(list[list.length - 1]! + randomRange(change[0], change[1]));
  }

  return list;
}

/** Get a random value, preventing duplicate entries. */
export function randomBlacklist<T, K>(create: () => T, key: (a: T) => K, blacklist: Set<K>) {
  for (let retry = 0; retry < 100; ++retry) {
    // Check if unique value.
    let value = create();
    let id = key(value);
    if (blacklist.has(id)) {
      collisions++;
      continue;
    }

    // Created unique value.
    blacklist.add(id);
    return value;
  }

  return create(); // Allows duplicates but never reached in testing.
}

/** Generated array of random values, preventing duplicate entries. */
export function randomBlacklistArray<T, K>(size: number, create: () => T, key: (a: T) => K, blacklist: Set<K>) {
  return arrayFill(size, () => randomBlacklist(create, key, blacklist));
}


/** Get number of collisions random blacklist has encountered, for profiling. */
export function randomBlacklistCollisions() {
  return collisions;
}

/** Global RNG seed for random functions. */
let seed = Date.now() | 0;
/** Number of collisions in random blacklist.  */
let collisions = 0;

// Stock lists of names for random functions.
const adjectives = ADJECTIVES.split(',');
const businessSuffixes = BUSINESS_SUFFIXES.split(',');
const femaleNames = FEMALE_NAMES.split(',');
const lastNames = LAST_NAMES.split(',');
const maleNames = MALE_NAMES.split(',');
const occupations = OCCUPATIONS.split(',');
const emailDomains = ['gmail', 'yahoo', 'hotmail', 'msn', 'outlook'];
const businessEmails = ['24x7', '24x7help', 'business', 'cc', 'client', 'contact', 'contactus', 'customer', 'customercare', 'emailus', 'hello', 'help', 'info', 'mailus', 'qna', 'query', 'questions', 'quick', 'quickanswer', 'quickreply', 'sales', 'solution', 'solutions', 'suggest', 'write'];

/** Mapping of state names to cities. */
const stateCities = objectMerge(STATE_CITIES.split(':').map(state => { let cities = state.split(','); return [cities[0]!, cities.slice(1)] as [State, string[]]; }));