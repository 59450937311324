import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonCode } from '../../../../../../common/code/system';
import { AccountJoin, AccountJoinLoan } from '../../../../../../common/model/account/account';
import { Member } from '../../../../../../common/model/member';
import { Permission } from '../../../../../../common/model/permission';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { MemberService } from '../../../common/service/member.service';
import { TabService } from '../../../common/service/tab.service';
import { getOneRequest } from '../../../common/toolbox/request';
import { AccountSetupComponent } from '../setup/account-setup.component';
import { AccountSetupData } from '../setup/account-setup.model';

@Component({
  selector: 'app-account-toolbar',
  templateUrl: './account-toolbar.component.html',
  styleUrls: ['./account-toolbar.component.scss'],
  host: {
    class: 'row'
  }
})
export class AccountToolbarComponent {
  readonly Permission = Permission;
  readonly CommonCode = CommonCode;

  /** Account being viewed in main interface. */
  @Input() set account(account: AccountJoin) {
    this._account = account;
    this.reaccount(account);
  }
  
  /** Emits when changes are made to account. */
  @Output() accountChange = new EventEmitter<AccountJoin>();

  /** Account being viewed. */
  _account: AccountJoin = new AccountJoinLoan();
  /** Primary owner of account. */
  member?: Member;

  constructor(
    protected auth: AuthService,
    private log: LogService,
    private http: HttpClient,
    private tabs: TabService,
    private members: MemberService
  ) {}

  /** Callback when opening account setup. */
  async onSetup() {
    // Refresh this tab if changes are submitted.
    let save = new Subject<void>();
    save.subscribe(async () => {
      // Changes made, refresh account.
      let account = await getOneRequest(this.http, 'accounts', { _insts: [this._account._inst], _ids: [this._account._id] });
      if (errorResponse(account)) {
        this.log.show(account);
        return;
      }

      this.accountChange.next(account);
    });

    // Open tab to configure account.
    let data: AccountSetupData = { account: this._account, save };
    this.tabs.open(AccountSetupComponent, data);
  }

  /** Callback when navigating to member's page. */
  onMember() {
    if (!this.member) return;
    this.members.open({ _inst: this.member._inst, _ids: [this.member._id] });
  }

  /** Set new displayed account. */
  reaccount(account: AccountJoin) {
    this.member = account.members[0];
  }
}
