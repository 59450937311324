import { AttachmentType } from "../code/standard/common";
import { ClaimCardDetailsType, ClaimDetailsType, ClaimStatus, ClaimType, DisputeStatus } from "../code/standard/disputes";
import { CommonCode } from "../code/system";
import { PropertyInfo } from "../info/prop";
import { TypeInfo } from "../info/type";
import { Attachment, AttachmentUpload } from "../model/attachment";
import { ClaimPreview } from "../model/claim/base";
import { Claim, ClaimJoin, ClaimUnion } from "../model/claim/claim";
import { ClaimCount } from "../model/claim/count";
import { ClaimPatch, ClaimUpload } from "../model/claim/upload";
import { Condition } from "../model/formula/condition";
import { Sort } from "../model/formula/sort";
import { GroupKey, GroupResult } from "../model/group";
import { PropertyType } from "../model/property-type";
import { QueryOperator } from "../model/query";
import { ArraySome } from "../toolbox/array";
import { BufferLike } from "../toolbox/binary";
import { claimUnion } from "../toolbox/claim";
import { ID_DEFAULT } from "../toolbox/id";
import { ArrayValidator } from "../validator/array";
import { EnumValidator } from "../validator/enum";
import { DocumentTemplateBuildPostRequest } from "./document-template";
import { PaginateRequest, PaginateResponse } from "./paginate";
import { SuccessResponse } from "./success";

/** Get list of claims matching query. */
export class ClaimGetRequest extends PaginateRequest<ClaimJoin> {
  constructor(
    /** Institutions that claims belong to. */
    public _insts: string[] = [],
    /** Unique identifer of claims. */
    public _ids?: string[],
    /** ID of account for claim. */
    public _account?: string,
    /** Query using specified filter. */
    public _filter?: string,
    /** ID of member for claim. */
    public _member?: string,
    /** ID of user assigned to claim. */
    public _assigned?: string,
    /** Modifier for assigned search. */
    public assignedOperator?: QueryOperator,
    /** Minimum number of disputes. */
    public disputes?: number,
    /** Displayable ID of claim. Unique per inst. */
    public displayIds?: string[],
    /** Filter by status. */
    public status?: ClaimStatus,
    /** Modifier for status search. */
    public statusOperator?: QueryOperator,
    /** Card number of card for claim (if it's a card claim). */
    public cardNumber?: string,
    /** True to include deleted claims. */
    public showDeleted?: boolean,
    /** Type to filter claims by */
    public details?: ClaimDetailsType,
    /** Type to filter claims by */
    public type?: ClaimType,
    /** Earliest reported date to filter by */
    public reportDate?: Date,
  ) {
    super();
  }

  static typeinfo: TypeInfo<ClaimGetRequest> = {
    ...PaginateRequest.typeinfoFor(claimUnion()),
    _insts: [ID_DEFAULT, ID_DEFAULT],
    _ids: [ID_DEFAULT, ID_DEFAULT],
    _account: ID_DEFAULT,
    _member: ID_DEFAULT,
    _assigned: ID_DEFAULT,
    assignedOperator: new EnumValidator(QueryOperator),
    _filter: ID_DEFAULT,
    cardNumber: '',
    disputes: 0,
    displayIds: ['0'],
    status: new EnumValidator(ClaimStatus),
    statusOperator: new EnumValidator(QueryOperator),
    showDeleted: true,
    details: ClaimCardDetailsType.Other,
    type: new EnumValidator(ClaimType),
    reportDate: new Date(),
  }
}

/** Get a list of claims for a report */
export class ClaimReportPostRequest extends ClaimGetRequest {
  constructor(
    /** Formula conditions to filter by. */
    public conditions: Condition<ClaimJoin>[] = []
  ) {
    super();
  }

  static override typeinfo: TypeInfo<ClaimReportPostRequest> = {
    ...ClaimGetRequest.typeinfo,
    conditions: [{} as Condition],
  }
}
/** Result of fetching a claim. */
export class ClaimGetResponse extends PaginateResponse<ClaimJoin> {}

/** Request to preview claims. */
export class ClaimPreviewGetRequest extends ClaimGetRequest { }

/** Result of previewing a list of claims. */
export type ClaimPreviewGetResponse = ClaimPreview[];

/** Request to build a claim document template. */
export class ClaimListPostRequest {
  constructor(
    /** Institution to pull document template from. */
    public _inst = ID_DEFAULT,
    /** Document template to build. */
    public _template = ID_DEFAULT,
    /** Output filename. */
    public output = '',
    /** Formula conditions for the report. */
    public conditions: Condition<ClaimUnion>[] = [],
    /** Sort by field in the report. */
    public reportSort: Sort<ClaimUnion>[] = []
  ) {}

  static typeinfo: TypeInfo<ClaimListPostRequest> = {
    conditions: [{} as Condition],
    reportSort: [Sort.from(claimUnion())]
  }
}

/** Result of building a claim report. */
export type ClaimListPostResponse = BufferLike;

/** Result of fetching a claim document template. */
export class ClaimDocumentTemplatePostRequest {
  constructor(
    /** Inst we are generating a claim template for. */
    public _inst = ID_DEFAULT,
    /** Claim we are generating a document template for. */
    public _claim = ID_DEFAULT,
    /** Attachment type of generated template */
    public attachmentType: string = AttachmentType.Other,
    /** Data to generate the template */
    public templateData = new DocumentTemplateBuildPostRequest(),
    /** Disputes we are generating a document template for, if applicable. */
    public _disputes?: string[]
  ) {}

  static typeinfo: TypeInfo<ClaimDocumentTemplatePostRequest> = {
    _disputes: [ID_DEFAULT]
  }

  static propinfo: PropertyInfo<ClaimDocumentTemplatePostRequest> = {
    attachmentType: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  }
}

/** Result of fetching a claim report. */
export class ClaimDocumentTemplatePostResponse {
  constructor(
    /** Data of generated report. */
    public data: BufferLike = '',
    /** List of new attachments to mirror locally. */
    public attachments: Attachment[] = []
  ) {}
}

/** Request to add new claims to system. */
export class ClaimPostRequest {
  constructor(
    /** List of claims to add. */
    public items: ArraySome<ClaimUpload>
  ) { }

  static typeinfo: TypeInfo<ClaimPostRequest> = {
    items: new ArrayValidator(new ClaimUpload(), 1)
  }
};

/** Result of creating new claim. */
export type ClaimPostResponse = Claim[];

/** Request to upload a new attachment to claim. */
export class ClaimAttachmentPostRequest {
  constructor(
    /** Institution to update claims within. */
    public _inst = ID_DEFAULT,
    /** Claim to apply attachment to. */
    public _claim = ID_DEFAULT,
    /** Attachments to upload. */
    public uploads: AttachmentUpload[],
    /** Specific Dispute(s) associated with this attachment. */
    public _disputes?: string[]
  ) { }

  static typeinfo: TypeInfo<ClaimAttachmentPostRequest> = {
    uploads: [new AttachmentUpload()],
    _disputes: [ID_DEFAULT]
  }
}

/** Result of adding new attachments to claim. */
export type ClaimAttachmentPostResponse = Attachment[];

/** Request to reorder attachments in claim. */
export class ClaimAttachmentPatchOrderRequest {
  constructor(
    /** Institution to update claims within. */
    public _inst = ID_DEFAULT,
    /** Claim update attachments of. */
    public _claim = ID_DEFAULT,
    /** New attachment order. */
    public _attachments: string[]
  ) { }

  static typeinfo: TypeInfo<ClaimAttachmentPatchOrderRequest> = {
    _attachments: [ID_DEFAULT]
  }
}

/** Result of adding new attachments to claim. */
export type ClaimAttachmentPatchOrderResponse = SuccessResponse;

/** Fetch claim counts for institution. */
export class ClaimCountGetRequest {
  constructor(
    /** Institution to fetch KPIs for. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Result of fetching claim groups. */
export type ClaimCountGetResponse = ClaimCount[];

export enum ClaimStatReduceType {
  Balance = 'balance',
  DisputeStatus = 'disputeStatus'
}
/** Request to get stats for claims report. */
export class ClaimStatPostRequest {
  constructor(
    /** Institutions these claims belong to. */
    public _insts: string[] = [],
    /** Formula conditions to apply before ClaimJoins are grouped. */
    public conditions: Condition<ClaimJoin>[] = [],
    /** Key for grouping claims. */
    public groupKey: GroupKey<ClaimUnion> = 'month',
    /** Key for reducing claims to a singular value */
    public reduceKey?: ClaimStatReduceType
  ) { }

  static typeinfo: TypeInfo<ClaimStatPostRequest> = {
    _insts: [ID_DEFAULT, ID_DEFAULT],
    conditions: [{} as Condition],
    groupKey: 'month',
    reduceKey: new EnumValidator(ClaimStatReduceType)
  }
}

/** Result of getting claim stats. */
export type ClaimStatPostResponse = GroupResult<DisputeStatus>[];

/** Request to apply modifications to specified claims. */
export class ClaimPatchRequest {
  constructor(
    /** List of claims to modify. */
    public patches: ClaimPatch[]
  ) { }

  static typeinfo: TypeInfo<ClaimPatchRequest> = {
    patches: [new ClaimPatch()]
  }
};

/** Result of updating claim(s). */
export type ClaimPatchResponse = SuccessResponse;

/** Request to mark a claim as deleted. */
export class ClaimDeleteRequest {
  constructor(
    /** Institutions to delete claims within. */
    public _insts: string[] = [],
    /** List of claims to delete. */
    public _claims: string[] = [],
    /** True to restore claims. */
    public restore?: boolean
  ) { }

  static typeinfo: TypeInfo<ClaimDeleteRequest> = {
    _insts: [ID_DEFAULT, ID_DEFAULT],
    _claims: [ID_DEFAULT, ID_DEFAULT],
    restore: true
  }
}

/** Result of deleting claims. */
export type ClaimDeleteResponse = SuccessResponse;

/** Request to delete attachments from a claim. */
export class ClaimAttachmentDeleteRequest {
  constructor(
    /** Institution to update claims. */
    public _inst = ID_DEFAULT,
    /** Claim to remove documents from. */
    public _claim = ID_DEFAULT,
    /** Attachments to delete. */
    public _ids: string[] = []
  ) { }

  static typeinfo: TypeInfo<ClaimAttachmentDeleteRequest> = {
    _ids: [ID_DEFAULT]
  }
}

/** Result of removing attachments from claim. */
export type ClaimAttachmentDeleteResponse = SuccessResponse;