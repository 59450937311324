import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-panel',
  templateUrl: './card-panel.component.html',
  styleUrls: ['./card-panel.component.scss']
})
export class CardPanelComponent {
  /** Bound color of panel. */
  @Input() color = '';
}
