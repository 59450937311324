import { Component, Inject, Injector, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../common/model/formula/block";
import { Condition } from "../../../../../../../common/model/formula/condition";
import { PLACEHOLDER_CONDITION, PlaceholderType } from "../../../../../../../common/model/formula/placeholder";
import { Pair } from "../../../../../../../common/toolbox/object";
import { BlockComponentMap } from '../../setup.module';
import { BlockComponent } from '../block.component';
import { BlockDrag, BlockSlot } from '../block.model';

@Component({
  selector: 'app-setup-formula-conditions',
  templateUrl: './setup-formula-conditions.component.html',
  styleUrls: ['./setup-formula-conditions.component.scss'],
  host: {
    class: 'column'
  }
})
export class SetupFormulaConditionsComponent extends BlockComponent<Condition[]> {
  /** Root attachment point for conditions. */
  @ViewChild('conditions', { static : true, read: ViewContainerRef }) conditions!: ViewContainerRef;
  
  /** Set new top-level conditions. */
  @Input() set value(conditions: Condition[]) {
    this.clear(this.conditions);
    this.block = conditions;
    this.placeholder = false;
    this.ngOnInit();
  }

  /** Set list of available keys. */
  @Input() set pairs(keys: Pair[]) {
    this.onKeys([keys]);
  }

  /** True if placeholder has been added. */
  private placeholder = false;

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  /** Drop a block into conditions. */
  drop(block: BlockDrag) {
    for (let slot of this.slots) {
      if (slot.drop(block)) break;
    }
  }

  ngOnInit() {
    // Add conditions of block.
    this.block = this.block ?? { conditions: [] };
    for (let condition of this.block) {
      this.recondition(undefined, condition);
    }

    // Add empty placeholder.
    if (!this.placeholder) this.recondition(undefined, PLACEHOLDER_CONDITION);
    this.placeholder = true;
  }

  /** Replace condition. */
  private recondition(slot?: BlockSlot, block?: Block | Block[]) {
    let index = this.slots.indexOf(slot as any);
    if (index === -1 && !this.placeholder) index = this.slots.length;

    if (this.slots[index]?.type === PlaceholderType.Condition) {
      // Insert condition before placeholder.
      this.attach({
        parent: this.injector,
        container: this.conditions,
        current: { block: this.block, key: index },
        next: block,
        accepts: [BlockType.Condition],
        replaced: this.recondition.bind(this),
        deletable: true
      }, index);
    } else {
      // Replace condition.
      this.replace(slot, {
        parent: this.injector,
        container: this.conditions,
        current: { block: this.block, key: index },
        next: block,
        accepts: [BlockType.Condition],
        replaced: this.recondition.bind(this),
        deletable: !!this.slots.length
      });
    }
  }
}
