import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../../common/model/formula/block";
import { PLACEHOLDER_EXPRESSION, PlaceholderType } from "../../../../../../../../common/model/formula/placeholder";
import { TerminalArray } from "../../../../../../../../common/model/formula/terminal";
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';
import { BlockSlot } from '../../block.model';

@Component({
  selector: 'app-setup-formula-array',
  templateUrl: './setup-formula-array.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-array.component.scss']
})
export class SetupFormulaArrayComponent extends BlockComponent<TerminalArray> {
  /** Root attachment point for expressions. */
  @ViewChild('expressions', { static : true, read: ViewContainerRef }) expressions!: ViewContainerRef;
  
  /** True if placeholder has been added. */
  private placeholder = false;

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  ngOnInit() {
    // Add items of array.
    for (let expression of this.block.expressions) {
      this.reexpression(undefined, expression);
    }

    // Add empty placeholder.
    if (!this.placeholder) this.reexpression(undefined, PLACEHOLDER_EXPRESSION);
    this.placeholder = true;
  }

  /** Replace expression. */
  private reexpression(slot?: BlockSlot, block?: Block | Block[]) {
    let index = this.slots.indexOf(slot as any);
    if (index === -1 && !this.placeholder) index = this.slots.length;

    if (this.slots[index]?.type === PlaceholderType.Expression) {
      // Insert expression before placeholder.
      this.attach({
        parent: this.injector,
        container: this.expressions,
        current: { block: this.block.expressions, key: index },
        next: block,
        accepts: [BlockType.Expression],
        replaced: this.reexpression.bind(this),
        deletable: true
      }, index);
    } else {
      // Replace expression.
      this.replace(slot, {
        parent: this.injector,
        container: this.expressions,
        current: { block: this.block.expressions, key: index },
        next: block,
        accepts: [BlockType.Expression],
        replaced: this.reexpression.bind(this),
        deletable: !!this.slots.length
      });
    }
  }
}
