import { Component, Inject, Optional } from '@angular/core';
import { APP_NAME } from '../../../../../common/toolbox/app';
import { DOCUMENTATION, DocumentationKey } from '../../../../../common/toolbox/docapi';
import { documentationContents } from '../../../../../common/toolbox/documentation';
import { errorResponse } from '../../../../../common/toolbox/message';
import { TAB_DATA } from '../../common/component/tab/bar/tab-bar.model';
import { DocumentationService } from '../../common/service/documentation.service';
import { LogService } from '../../common/service/log.service';
import { DocumentationData } from './documentation.model';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent {
  readonly APP_NAME = APP_NAME;

  /** Current selected category. */
  category?: DocumentationKey;
  /** Current selected page. */
  page?: DocumentationKey;
  /** Contents of current page. */
  text?: string;

  /** Table of contents. */
  contents = documentationContents();

  static title(data?: DocumentationData) {
    return data?.key ? DOCUMENTATION[data?.key] : 'Documentation';
  }

  static padding() {
    return false;
  }

  constructor(
    @Optional() @Inject(TAB_DATA)
    public data: DocumentationData | undefined,
    private documentation: DocumentationService,
    private log: LogService
  ) {
    if (data?.key) this.open(data.key);
  }

  /** Toggle specified category. */
  toggle(key: DocumentationKey) {
    this.category = this.category === key ? undefined : key;
  }

  /** Open specified page. */
  protected async open(key: DocumentationKey) {
    this.page = key;
    
    let page = await this.documentation.page(key);
    if (errorResponse(page)) {
      this.log.show(page);
      return;
    }

    this.text = page;
  }

  /** Close current page. */
  protected close() {
    this.page = undefined;
  }
}
