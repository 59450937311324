import { Pos } from "./pos";

/** Regex for matching grid area. */
const GRID_AREA_REGEX = /^(\d+) \/ (\d+) \/ (\d+) \/ (\d+)$/;

/** Points of a bounding box. */
export class Box {
  constructor(
    /** Left edge of box. */
    public l = 0,
    /** Top edge of box. */
    public t = 0,
    /** Right edge of box. */
    public r = 0,
    /** Bottom edge of box. */
    public b = 0
  ) {}

  /** Create box from existing box. */
  static from(box: Box) {
    return new Box(box.l, box.t, box.r, box.b);
  }

  /** Create a 1x1 box at specified position. */
  static position(pos: Pos) {
    return new Box(pos.x, pos.y, pos.x, pos.y);
  }

  /** Get width of a box. */
  static width(box: Box) {
    return box.r - box.l + 1;
  }

  /** Get height of a box. */
  static height(box: Box) {
    return box.b - box.t + 1;
  }

  /** Get size of a box. */
  static size(box: Box) {
    return new Pos(Box.width(box), Box.height(box));
  }

  /** Get upper-left corner of box. */
  static corner(box: Box) {
    return new Pos(box.l, box.t);
  }

  /** Check if a box is overlapping another box. */
  static overlaps(a: Box, b: Box) {
    return a.l <= b.r && a.t <= b.b && a.r >= b.l && a.b >= b.t;
  }

  /** Check if a box is entirely inside another box. */
  static inside(box: Box, pos: Pos) {
    return box.l >= 0 && box.t >= 0 && box.r < pos.x && box.b < pos.y;
  }

  /** Add an offset to a box, return new value. */
  static add(box: Box, pos: Pos) {
    return new Box(box.l + pos.x, box.t + pos.y, box.r + pos.x, box.b + pos.y);
  }

  /** Format box for grid-area CSS property. */
  static css(box: Box) {
    return { 'grid-area': `${box.t + 1} / ${box.l + 1} / ${box.b + 2} / ${box.r + 2}` };
  }

  /** Create a box from a grid area. */
  static area(gridArea: string) {
    let match = gridArea.match(GRID_AREA_REGEX);
    return match ? new Box(+match[2]! - 1, +match[1]! - 1, +match[4]! - 2, +match[3]! - 2) : new Box();
  }
}