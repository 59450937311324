import { Injectable } from "@angular/core";
import { ErrorResponse } from "../../../../../common/message/error";
import { FormulaGetRequest } from "../../../../../common/message/formula";
import { DisplayPartial, DisplayValue } from "../../../../../common/model/display";
import { Formula } from "../../../../../common/model/formula/formula";
import { WorkEngine } from "../../../../../common/model/work/engine";
import { arraySome } from "../../../../../common/toolbox/array";
import { DisplayService } from "./display.service";
import { FormulaService } from "./formula.service";

/** Client-side implementation of workflow engine. */
@Injectable({
  providedIn: 'root'
})
export class WorkEngineService extends WorkEngine {
  
  constructor(
    private display: DisplayService,
    private formulas: FormulaService
  ) {
    super();
  }

  protected override fallback(partial: Partial<DisplayValue>): DisplayPartial {
    return this.display.fallback(partial);
  }

  /** Get specified formulas. */
  protected override async formula(request: FormulaGetRequest): Promise<Formula[] | ErrorResponse> {
    if (!arraySome(request._insts) || !arraySome(request._ids)) return [];
    return this.formulas.items(request._ids.map(_id => ({ _id, _inst: request._insts[0]! })));
  }
}