import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Step, StepStatus } from '../../../../../../../common/model/step';
import { HasName } from '../../../../../../../common/toolbox/id';

@Component({
  selector: 'app-stepper-step',
  templateUrl: './stepper-step.component.html',
  styleUrls: ['./stepper-step.component.scss'],
  host: {
    '[attr.step]': 'step.data.name',
    '[class.locked]': 'step.locked',
    '[class.current]': 'current',
    '[class.status]': 'step.status !== undefined',
    '[class.rename]': '_rename'
  }
})
export class StepperStepComponent<T extends HasName = HasName> {
  readonly StepStatus = StepStatus;

  /** Reference to input. */
  @ViewChild('input', { static : true }) input!: ElementRef<HTMLInputElement>;

  /** Global autoincrement ID for steps. */
  static id = 0;

  /** True if this step is selected. */
  @Input() current = false;
  /** Step being displayed. */
  @Input() step = new Step<T>();
  /** True to display delete button. */
  @Input() deletable = true;

  /** True if step is being renamed. */
  @Input() set rename(rename: boolean) {
    if (this._rename = rename) this.input.nativeElement.focus();
  }
  
  /** Emits when a step should be deleted. */
  @Output() deleted = new EventEmitter();
  /** Emits when input is blurred. */
  @Output() blur = new EventEmitter();

  /** True if currently renaming. */
  protected _rename = false;
  /** List of points for line. */
  protected points = '';
  /** Locally selected autoincrement ID. */
  protected id = ++StepperStepComponent.id;
  /** Sanitized URL for SVG. */
  protected url = this.sanitizer.bypassSecurityTrustStyle(`url(#stepper-step-${this.id})`);

  /** Listener for resizes. */
  private observer: ResizeObserver;

  constructor(
    zone: NgZone,
    element: ElementRef<HTMLElement>,
    private sanitizer: DomSanitizer
  ) {
    // Manually run in NgZone or Angular won't trigger change detection.
    this.observer = new ResizeObserver(entries => zone.run(() => {
      for (let { contentRect: { width, height } } of entries) {
        let [w, h] = [width, height];
        this.points = `0 0, ${w - 12} 0, ${w} ${h / 2}, ${w - 12} ${h}, 0 ${h}, 12 ${h / 2}, 0 0`;
      }
    }));

    this.observer.observe(element.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
