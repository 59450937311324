import { TypeInfo } from "../info/type";
import { Direction } from "../model/direction";
import { NestedKey, keyNested } from "../toolbox/keys";
import { EnumValidator } from "../validator/enum";

/** A response of a list of paginated items. */
export class PaginateResponse<T = any> {
  constructor(
    /** List of returned items. */
    public items: T[] = [],
    /** Total items for pagination. */
    public available = items.length
  ) {}

  /** Flatten to list of items. */
  static flat<T>(responses: PaginateResponse<T>[]): T[] {
    return responses.map(r => r.items).flat();
  }
}

/** A request implementing server-side pagination. */
export class PaginateRequest<T = any> {
  constructor(
    /** Number of events to fetch. */
    public limit?: number,
    /** Offset to start fetching events. */
    public skip?: number,
    /** Field to sort by. */
    public sort?: NestedKey<T>,
    /** Direction to sort. */
    public direction?: Direction
  ) { }

  /** Get type information for a particular pagination request. */
  static typeinfoFor<T>(value: T): TypeInfo<PaginateRequest<T>> {
    return {
      limit: 0,
      skip: 0,
      direction: new EnumValidator([1, -1]),
      sort: new EnumValidator(keyNested(value)) as any
    };
  }
}
