import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { UnionValidator } from "../../validator/union";
import { FusionCollection } from "../fusion";
import { WORK_CONDITION_UNIONINFO, WorkCondition } from "./condition";

/** An action to complete within a workflow step */
export class WorkAction {
  constructor(
    /** Name of this action */
    public name = '',
    /** Description of this action. */
    public description = '',
    /** Condition used to evaluate completion status of this action. */
    public completion: WorkCondition[] = [],
    /** List of conditions required to enable this action. */
    public enable: WorkCondition[] = [],
    /** Tasks to make available under this action. */
    public _tasks?: string[],
  ) { }

  static typeinfo: TypeInfo<WorkAction> = {
    _tasks: [ID_DEFAULT],
    completion: [new UnionValidator(WORK_CONDITION_UNIONINFO)],
    enable: [new UnionValidator(WORK_CONDITION_UNIONINFO)]
  }

  static collectioninfo: CollectionInfo<FusionCollection, WorkAction> = {
    _tasks: 'tasks'
  }
}

/** A single step to complete in a workflow. */
export class WorkStep {
  constructor(
    /** Name of this step. */
    public name = '',
    /** Actions available in this step */
    public actions: WorkAction[] = [],
    /** List of conditions to unlock this step */
    public enable: WorkCondition[] = [],
  ) {}

  static typeinfo: TypeInfo<WorkStep> = {
    enable: [new UnionValidator(WORK_CONDITION_UNIONINFO)],
    actions: [new WorkAction()]
  }
}

/** A representation of a workflow that must be completed. */
export class Workflow {
  constructor(
    /** Unique identifier of workflow. */
    public _id = ID_DEFAULT,
    /** Institution configuring this workflow. */
    public _inst = ID_DEFAULT,
    /** Name of this workflow. */
    public name = '',
    /** Steps in workflow. */
    public steps: WorkStep[] = [],
    /** Global actions available in all work steps. */
    public actions?: WorkAction[],
    /** Global tasks available in all work steps. */
    public _tasks?: string[],
    /** True if model has been edited. */
    public dirty?: boolean
  ) {}

  static typeinfo: TypeInfo<Workflow> = {
    steps: [new WorkStep()],
    actions: [new WorkAction()],
    _tasks: [ID_DEFAULT, ID_DEFAULT],
    dirty: false
  }

  static collectioninfo: CollectionInfo<FusionCollection, Workflow> = {
    _inst: 'institutions',
    _tasks: 'tasks'
  }

  static indexinfo: IndexInfo<Workflow> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** A preview for a workflow. */
export class WorkflowPreview {
  constructor(
    /** Unique identifier of workflow. */
    public _id = ID_DEFAULT,
    /** Institution that created workflow. */
    public _inst = ID_DEFAULT,
    /** Name of workflow. */
    public name = ''
  ) { }
}