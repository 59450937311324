import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { SystemCode } from '../../../../../../../common/code/system';
import { ModelPostResponse } from '../../../../../../../common/message/model';
import { CodePreview } from "../../../../../../../common/model/code-type";
import { Model } from "../../../../../../../common/model/model";
import { Permission } from '../../../../../../../common/model/permission';
import { Property, PropertyFilter, propertyCreate } from "../../../../../../../common/model/property";
import { PropertyType } from "../../../../../../../common/model/property-type";
import { arrayRemove } from "../../../../../../../common/toolbox/array";
import { CURRENCY_MAX, CURRENCY_MIN } from "../../../../../../../common/toolbox/currency";
import { enumKeyValues, enumValues } from '../../../../../../../common/toolbox/enum';
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { INT32_MAX, INT32_MIN } from "../../../../../../../common/toolbox/number";
import { objectMerge } from "../../../../../../../common/toolbox/object";
import { StatusLevel } from "../../../../../../../common/toolbox/status";
import { DAYS_MAX, DAYS_MIN } from "../../../../../../../common/toolbox/time";
import { AuthService } from '../../../../common/service/auth.service';
import { CodeTypeService } from '../../../../common/service/code-type.service';
import { DevService } from '../../../../common/service/dev.service';
import { LogService } from '../../../../common/service/log.service';
import { PROPERTY_TYPE_ICON } from '../../../../common/toolbox/property';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';

@Component({
  selector: 'app-setup-model-edit',
  templateUrl: './setup-model-edit.component.html',
  styleUrls: ['./setup-model-edit.component.scss']
})
export class SetupModelEditComponent extends SetupEditComponent<Model, ModelPostResponse> {
  readonly CURRENCY_MAX = CURRENCY_MAX;
  readonly CURRENCY_MIN = CURRENCY_MIN;
  readonly DATE_HELP = 'Offset in number of days.';
  readonly DAYS_MAX = DAYS_MAX;
  readonly DAYS_MIN = DAYS_MIN;
  readonly INT32_MAX = INT32_MAX;
  readonly INT32_MIN = INT32_MIN;
  readonly Permission = Permission;
  readonly PROPERTY_TYPE_ICON = PROPERTY_TYPE_ICON;
  readonly PropertyType = PropertyType;
  readonly propertyTypes = enumKeyValues<PropertyType>(PropertyType);
  
  /** Current model being edited. */
  value = idMaybe(new Model());
  /** List of code categories for selection. */
  types: CodePreview[] = [];
  /** List of properties to link fields to. */
  links: Record<PropertyType, Property[]> = objectMerge(enumValues(PropertyType).map(p => [p, []]));

  constructor(
    elementRef: ElementRef,
    log: LogService,
    public dev: DevService,
    private auth: AuthService,
    private http: HttpClient,
    private typeService: CodeTypeService
  ) {
    super(elementRef, log);
  }

  async ngOnInit() {
    this.types = await this.typeService.previews(this.auth._inst);
  }

  /** Add new property to list at position. */
  onNew(type = PropertyType.Boolean, i = this.value.properties.length, key?: string) {
    let property = propertyCreate(type);
    if (key !== undefined) property.key = key;

    switch (property.type) {
    case PropertyType.Date:
      this.links.date.push(property);
      break;
    case PropertyType.Code:
      if (this.types[0]) {
        property.category = this.types[0].category as SystemCode;
      } else {
        this.log.show('No code categories are available for code property.', StatusLevel.Alert);
        return false;
      } break;
    case PropertyType.Phone:
      if (this.links.phone[0]) {
        property.link = this.links.phone[0].key;
      } else {
        this.log.show('No member properties are available for phone property.', StatusLevel.Alert);
        return false;
      } break;
    case PropertyType.Email:
      if (this.links.email[0]){
        property.link=this.links.email[0].key;
      } else {
        this.log.show('No member properties are available for email property.', StatusLevel.Alert);
        return false;
      } break;
    case PropertyType.Member:
      this.links.phone.push(property);
      break;
    }

    this.value.properties.splice(i, 0, property);
    return true;
  }

  /** Delete specified property from list. */
  onDelete(property: Property) {
    let key = property.key;
    switch (property.type) {
    case PropertyType.Date:
      // Check if any dates are linked to this date.
      for (let property of new PropertyFilter(this.value.properties, PropertyType.Date)) {
        if (property.link === key) property.link = undefined;
      } break;
    case PropertyType.Member:
      // Check if any phones are linked to this member.
      for (let property of new PropertyFilter(this.value.properties, PropertyType.Phone)) {
        if (property.link === key) {
          this.log.show(`Property "${String(property.key)}" is linked to this property.`, StatusLevel.Alert);
          return false;
        }
      }

      arrayRemove(this.links.phone, property);
      break;
    }

    arrayRemove(this.value.properties, property);
    return true;
  }

  /** Callback when changing type of a property. */
  onType(type: PropertyType, i: number) {
    let property = this.value.properties[i]!;
    if (!this.onDelete(property)) return;
    this.onNew(type, i, property.key);
  }

  /** Callback when updating name of a property. */
  onKey(property: Property, next: string) {
    let prev = property.key;

    // Update any links that relied on this property name.
    switch (property.type) {
    case PropertyType.Member:

      // Update any fields linked to this one.
      for (let property of this.value.properties) {
        switch (property.type) {
        case PropertyType.Phone:
          if (property.link === prev) property.link = next;
          break;
        }
      }

      break;
    }

    // Update name of property.
    property.key = next;
  }

  /** Submit current changes to model. */
  push() {
    return postRequest(this.http, 'models', { items: [this.value] });
  }

  /** Reset current form with new model. */
  async reset(value: MaybeId<Model>) {
    this.value = value;

    // Update List of date and phone links.
    // Update list of phone links.
    this.links.date = [];
    this.links.phone = [];
    for (let property of this.value.properties) {
      switch (property.type) {
      case PropertyType.Date:
        this.links.date.push(property);
        break;
      case PropertyType.Member:
        this.links.phone.push(property);
        break;
      }
    }
  }
}
