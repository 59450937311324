import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../../common/model/formula/block";
import { ExpressionBinary } from "../../../../../../../../common/model/formula/expression";
import { OperatorType } from "../../../../../../../../common/model/formula/operator";
import { ColorCode } from "../../../../../../../../common/toolbox/color";
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';
import { BlockSlot } from '../../block.model';

@Component({
  selector: 'app-setup-formula-binary',
  templateUrl: './setup-formula-binary.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-binary.component.scss'],
  host: { color: ColorCode.Primary }
})
export class SetupFormulaBinaryComponent extends BlockComponent<ExpressionBinary> {
  /** Left expression. */
  @ViewChild('left', { static : true, read: ViewContainerRef }) left!: ViewContainerRef;
  /** Right expression. */
  @ViewChild('right', { static : true, read: ViewContainerRef }) right!: ViewContainerRef;

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    protected injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  ngOnInit() {
    this.releft(undefined, this.block.left);
    this.reright(undefined, this.block.right);
  }

  /** Replace left expression. */
  protected releft(slot?: BlockSlot, block?: Block | Block[]): BlockComponent | undefined {
    let component = this.replace(slot, {
      parent: this.injector,
      container: this.left,
      current: { block: this.block, key: 'left' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.releft.bind(this)
    });

    this.widen(this.block.type === OperatorType.Join, this.slots[0]!);
    return component;
  }

  /** Replace expression. */
  private reright(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.right,
      current: { block: this.block, key: 'right' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.reright.bind(this)
    });
  }
}