import { HttpClient } from "@angular/common/http";
import { Component, ElementRef } from "@angular/core";
import { propinfoProperty } from "../../../../../../../common/info/prop";
import { FormPostResponse } from "../../../../../../../common/message/form";
import { Box } from "../../../../../../../common/model/box";
import { builtinFormField } from "../../../../../../../common/model/builtin/form/form";
import { Display, DisplayType, DisplayValue } from "../../../../../../../common/model/display";
import { Field } from "../../../../../../../common/model/form/field";
import { Form } from "../../../../../../../common/model/form/form";
import { FormulaProxy } from "../../../../../../../common/model/formula/proxy";
import { Model, ModelMap } from "../../../../../../../common/model/model";
import { Pos } from "../../../../../../../common/model/pos";
import { enumKeyValues } from "../../../../../../../common/toolbox/enum";
import { MaybeId, idMaybe } from "../../../../../../../common/toolbox/id";
import { modelValue } from "../../../../../../../common/toolbox/model";
import { DialogService } from "../../../../common/component/dialog/dialog.service";
import { AuthService } from "../../../../common/service/auth.service";
import { DevService } from "../../../../common/service/dev.service";
import { FormulaService } from "../../../../common/service/formula.service";
import { LogService } from "../../../../common/service/log.service";
import { ModelService } from "../../../../common/service/model.service";
import { TabService } from "../../../../common/service/tab.service";
import { DIALOG_CANCEL_SYMBOL } from "../../../../common/toolbox/dialog";
import { formSource } from "../../../../common/toolbox/form";
import { postRequest } from "../../../../common/toolbox/request";
import { SetupEditComponent } from "../../setup-edit.component";
import { SetupFormFieldDialogComponent } from "../field/dialog/setup-form-field-dialog.component";
import { SetupFormFieldData, SetupFormFieldReturn } from "../field/dialog/setup-form-field-dialog.model";
import { SetupFormPreviewComponent } from "../preview/setup-form-preview.component";
import { SetupFormPreviewData } from "../preview/setup-form-preview.model";

@Component({
  selector: 'app-setup-form-edit',
  templateUrl: './setup-form-edit.component.html',
  styleUrls: ['./setup-form-edit.component.scss']
})
export class SetupFormEditComponent extends SetupEditComponent<Form, FormPostResponse> {
  readonly Form = Form;
  readonly DISPLAY_TYPES = enumKeyValues<DisplayType>(DisplayType);

  /** Current form being edited. */
  value = idMaybe(new Form());
  /** Display value for fetching possible keys. */
  proxy = new DisplayValue();
  /** Current selected model. */
  model = new Model();
  /** Type information about current selected model. */
  map: ModelMap = {};

  /** Valid keys for table columns. */
  pairs = DisplayValue.pairs(this.proxy);

  /** Minimum size that will fit all fields. */
  min = new Pos();
  /** Maximum size for current selected field. */
  max = new Pos();

  /** List of positions for preview grid. */
  area = new Array<Pos>();
  /** Current field being edited. */
  field = builtinFormField('account._id');
  /** Size of current field being edited. */
  size = new Pos();
  /** Style for main form body. */
  style = Pos.css(Form.SIZE_DEFAULT, undefined, '2rem');

  constructor(
    elementRef: ElementRef,
    log: LogService,
    protected dev: DevService,
    private auth: AuthService,
    private http: HttpClient,
    private dialog: DialogService,
    private formulaService: FormulaService,
    private modelService: ModelService,
    private tabs: TabService
  ) {
    super(elementRef, log);
  }

  async ngOnInit() {
    // Fetch list of models and formulas for field selection.
    let formulas = await this.formulaService.previews(this.auth._inst);
    this.proxy.formula = FormulaProxy.previews(formulas);
  }

  /** Preview current form. */
  onPreview() {
    this.restyle();
    this.tabs.open(SetupFormPreviewComponent, new SetupFormPreviewData(this.value));
  }

  /** Copy form to clipboard. */
  async onCode() {
    let formulas = await this.formulaService.previews(this.auth._inst);
    let text = formSource(this.value, this.model, new Map(formulas.map(formula => [formula._id, formula])));
    await navigator.clipboard.writeText(text);
  }

  /**
   *  Callback when selecting new model from dropdown.
   *  TODO update this if model caching is implemented.
   */
  async onModel(_id?: string) {
    if (this.value.fields.length && _id !== this.value._model) {
      let fields = this.value.fields.filter(f => Display.split(f)[0] !== DisplayType.Model);
      let deleted = this.value.fields.length - fields.length;
      if (deleted) {
        // Fields would be removed after change.
        if (!await this.dialog.confirm(`Change model for this form? This will delete ${deleted} model fields.`, 'Change model?')) return;
      }

      // Set new model for form.
      this.value._model = _id;
      this.value.fields = fields;
      this.min = Form.min(this.value);
    }

    // Set current displayed model.
    this.model = _id ? await this.modelService.item({ _inst: this.auth._inst, _id }) : new Model();
    this.proxy.model = modelValue(this.model);
    this.pairs = DisplayValue.pairs(this.proxy);
    this.map = modelValue(this.model);
  }

  /** Callback when changing size of form. */
  onResize(size: Pos) {
    this.value.size = size;
    this.area = Pos.array(size);
    this.restyle(false);
  }

  /** Add new field to list. */
  onNew(pos: Pos) {
    this.value.fields.push(builtinFormField('account.number', { box: Box.position(pos) }));
    this.min = Form.min(this.value);
  }

  /** Delete specified field from list. */
  onDelete() {
    let i = this.value.fields.indexOf(this.field);
    if (i < 0) return this.log.show('No field to delete.');

    this.value.fields.splice(i, 1);
    this.min = Form.min(this.value);
  }

  /** Configure specified field. */
  async onConfigure() {
    const field = this.field;
    if (!field) return this.log.show('No field to configure.');

    const property = propinfoProperty(field.key, this.proxy);
    if (!property) return this.log.show(`Failed to find property: ${field.key}`);

    const config = await this.dialog.open<SetupFormFieldReturn, SetupFormFieldData>(SetupFormFieldDialogComponent, { field, property })
    if (config === DIALOG_CANCEL_SYMBOL) return;

    this.field.config = config ?? this.field.config;
  }

  /** Callback when a field is moved. */
  onFieldMove() {
    this.min = Form.min(this.value);
  }

  /** Callback when opening resize panel for field. */
  onFieldClick(field: Field) {
    this.field = field;
    this.size = Box.size(field.box);
    this.max = Form.max(this.value, field);
  }

  /** Callback when changing a field's size. */
  onFieldResize(size: Pos) {
    let next = Box.from(this.field.box);
    next.r = next.l + size.x - 1;
    next.b = next.t + size.y - 1;

    // Ensure field wouldn't go outside form.
    if (!Box.inside(next, this.value.size)) return;

    // Ensure field wouldn't overlap any nearby fields.
    for (let field of this.value.fields) {
      if (field === this.field) continue; // Do not check against self.
      if (Box.overlaps(next, field.box)) return; // Overlapping existing field.
    }

    this.field.box = next;
    this.min = Form.min(this.value);
  }

  /** Submit current changes to form. */
  push() {
    // Correct form before submission.
    this.restyle();
    return postRequest(this.http, 'forms', { items: [this.value] });
  }

  /** Reset current form with new form. */
  async reset(value: MaybeId<Form>) {
    this.value = value;
    this.min = Form.min(this.value);
    this.remodel();
  }

  /** Set valid model for form and fetch. */
  private remodel() {
    this.onModel(this.value._model);
    this.onResize(this.value.size);
  }

  /** Adjust form styling after changing form size. */
  private restyle(shrink = true) {
    Form.correct(this.value, shrink);
    this.area = Pos.array(this.value.size);
    this.style = Pos.css(this.value.size, undefined, '2rem');
  }
}
