<div class="row fill">
  <div class="column fill">
    <div class="row">
      <app-field>
        <app-date-range [required]="true" [ngModel]="range" (selected)="onRange($event!)"></app-date-range>
      </app-field>
      <div class="fill"></div>
      <app-clock></app-clock>
    </div>
    <app-stats [stats]="stats"></app-stats>
    <app-card class="fill">
      <app-card-header>Events</app-card-header>
      <app-event-list class="flex fill" [limit]="10" [assignDate]="range" [assigned]="auth.session._id"></app-event-list>
    </app-card>
  </div>
  <div class="column">
    <div class="row">
      <button>Open Collector Activity Report</button>
      <button button-prefix="refresh" position="before">Refresh Activity</button>
    </div>
    <app-work-queues class="fill" [type]="DisplayType.Account" (queue)="onQueue($event)"></app-work-queues>
  </div>
</div>
