import { BooleanInput } from '@angular/cdk/coercion';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest, map, merge, startWith, switchMap, takeUntil } from 'rxjs';
import { deepCopy } from '../../../../../../common/toolbox/object';
import { fieldControlProviders } from '../../toolbox/angular';
import { debugElementMake } from '../../toolbox/element/debug';
import { FieldControl } from '../field/field-control';
import { EditListItemComponent } from './item/edit-list-item.component';

@Component({
  selector: 'app-edit-list',
  templateUrl: './edit-list.component.html',
  styleUrls: ['./edit-list.component.scss'],
  providers: fieldControlProviders(EditListComponent),
  host: { class: 'column' }
})
export class EditListComponent<T> extends FieldControl implements ControlValueAccessor {

  /** List of CDKDrag instances injected in template. */
  @ViewChildren(CdkDrag) templateDragRef!: QueryList<CdkDrag>;
  /** List of CDKDrag instances to pass down to edit list. */
  @ContentChildren(CdkDrag) contentDragRef!: QueryList<CdkDrag>;

  /** List of drag items injected in template. */
  @ViewChildren(EditListItemComponent) templateItemRef!: QueryList<EditListItemComponent<T>>;
  /** List of drag items mirroring bound list. */
  @ContentChildren(EditListItemComponent) contentItemRef!: QueryList<EditListItemComponent<T>>;

  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  /** True to make a value required. */
  @Input() required = true;

  /** Default item to add to list. */
  @Input() default?: T;
  /** True to automatically remove deleted items from list. */
  @Input() autoremove = true;
  /** True to automatically add items to list. */
  @Input() autocreate = true;
  
  /** Emits when a new item is being created. */
  @Output() created = new EventEmitter<T>();
  /** Emits when an item is deleted. */
  @Output() deleted = new EventEmitter<number>();
  /** Emits when items are reordered. */
  @Output() reordered = new EventEmitter<void>();

  /** Merged list of available drags. */
  protected drags: CdkDrag[] = [];

  /** Emits when template-projected list of drags changes. */
  private templateDrags = new BehaviorSubject<CdkDrag[]>([]);
  /** Emits when content-projected list of drags changes. */
  private contentDrags = new BehaviorSubject<CdkDrag[]>([]);
  /** Emits when template-projected list of items changes. */
  private templateItems = new BehaviorSubject<EditListItemComponent<T>[]>([]);
  /** Emits when content-projected list of items changes. */
  private contentItems = new BehaviorSubject<EditListItemComponent<T>[]>([]);

  /** List of items to display in list. */
  protected value: T[] = [];
  /** Merged stream of available list items. */
  private items = new BehaviorSubject<EditListItemComponent<T>[]>([]);
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    public elementRef: ElementRef
  ) {
    super();
    debugElementMake(this);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterContentInit() {
    // Merge stream of content-projected and template-projected items into one.
    this.contentItemRef.changes.pipe(takeUntil(this.destroy), startWith(this.contentItemRef), map(i => i.toArray())).subscribe(this.contentItems);
    combineLatest([this.contentItems, this.templateItems]).pipe(
      takeUntil(this.destroy),
      map(([content, template]) => [...content, ...template])
    ).subscribe(this.items);

    // Get stream of template and content-projected drags
    this.contentDragRef.changes.pipe(takeUntil(this.destroy), startWith(this.contentDragRef), map(i => i.toArray())).subscribe(this.contentDrags);
    combineLatest([this.contentDrags, this.templateDrags]).pipe(
      takeUntil(this.destroy),
      map(([content, template]) => [...content, ...template])
    ).subscribe(drags => {
      this.drags = drags;
      for (let drag of drags) {
        drag.boundaryElement = this.elementRef;
      }
    });

    // Listen to items for deletions.
    this.items.pipe(
      takeUntil(this.destroy),
      switchMap(items => merge(...items.map(i => i.deleted)))
    ).subscribe(item => this.onDelete(item));
  }

  ngAfterViewInit() {
    this.templateItemRef.changes.pipe(takeUntil(this.destroy), startWith(this.templateItemRef)).subscribe(this.templateItems);
    this.templateDragRef.changes.pipe(takeUntil(this.destroy), startWith(this.templateDragRef)).subscribe(this.templateDrags);
  }

  writeValue(items: T[] | undefined) {
    if (items === null) return; // see https://github.com/angular/angular/issues/14988
    this.value = items ?? [];
    this.changed((this.required || items?.length) ? items : undefined);
  }

  override onDebug() {
    return {
      ...super.onDebug(),
      value: this.value, 
      default: this.default
    }
  }

  /** Create a new item in list. */
  create() {
    if (!this.default) return;

    if (!this.value) this.value = [];
    this.value.push(this.default);
    this.writeValue(this.value);
  }

  /** Manually add an item in list. */
  add(item: T) {
    this.value.push(item);
    this.writeValue(this.value);
    this.created.next(item);
  }

  /** Manually delete an item from list. */
  delete(i: number) {
    this.value.splice(i, 1);
    this.writeValue(this.value);
  }

  /** Callback when an item should be created. */
  protected onCreate() {
    if (!this.default) return;

    let item = deepCopy(this.default);
    if (this.autocreate) return this.add(item);
    this.created.next(item);
  }

  /** Callback when an item is deleted. */
  protected onDelete(item: EditListItemComponent<T>) {
    let i = this.items.value.findIndex(i => i === item);
    if (i < 0) return;

    this.deleted.next(i);
    if (!this.autoremove) return;

    this.value.splice(i, 1);
    this.writeValue(this.value);
  }
}
