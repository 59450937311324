import { GlobalPositionStrategy, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalOutlet } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector, Type } from '@angular/core';
import { Subject, first, firstValueFrom } from 'rxjs';
import { safeAssign } from "../../../../../../common/toolbox/object";
import { titleComponent } from '../../toolbox/component';
import { DialogConfirmationComponent } from './confirmation/dialog-confirmation.component';
import { DialogConfirmationData, DialogConfirmationReturn } from './confirmation/dialog-confirmation.model';
import { DialogComponent } from './dialog.component';
import { DIALOG_DATA, DialogCloseEvent, DialogConfig, DialogRef } from './dialog.model';
import { DialogSourceComponent } from './source/dialog-source.component';
import { DialogSourceData, DialogSourceReturn } from './source/dialog-source.model';

/** A portal outlet that can contain a dialog. */
export type DialogOutlet = PortalOutlet & { discard: Subject<void> };

/** Options for opening a dialog. */
export class DialogOptions {
  constructor(
    /** Explicit portal to use. Defaults to new overlay. */
    public portal?: DialogOutlet,
    /** List of classes to apply to injected component. */
    public classes?: string[]
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) {}

  /** Open a new dialog with specified component and data. */
  open<R = unknown, T = unknown>(component: Type<unknown>, data: T, config: DialogConfig = {}, options?: DialogOptions): Promise<DialogCloseEvent<R>> {
    
    // Fall back on default title if component implements it.
    if (!config.title) config.title = titleComponent<T>(component) ? component.title(data) : 'Are you sure?';
    if (config.cancel === undefined) config.cancel = true;
    
    let overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      positionStrategy: new GlobalPositionStrategy().centerHorizontally().centerVertically()
    });

    let outlet: PortalOutlet = options?.portal ?? this.overlay.create(overlayConfig);
    let dialogRef = new DialogRef<R>(component);
    let injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: data }
      ]
    });

    // Clear focused element so they are forced to interact with the popup.
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    
    // Create component and listen for close.
    let discard: Subject<void>;
    let type = options?.portal ? component : DialogComponent;
    let componentRef: ComponentRef<DialogComponent> = outlet.attach(new ComponentPortal(type, null, injector));
    if (options?.portal) {
      // Opened in custom portal.
      discard = options.portal.discard;
    } else {
      // Opened in standard dialog.
      discard = componentRef.instance.discard;
      safeAssign(componentRef.instance, config);
    }

    // Append any classes specified in config.
    let element: HTMLElement = componentRef.location.nativeElement;
    for (let name of options?.classes ?? []) element.classList.add(name);

    // Bubble data out to caller.
    discard.pipe(first()).subscribe(() => outlet.dispose());
    return firstValueFrom(dialogRef.data.pipe(first()));
  }

  /** Open a standard confirmation dialog and get result. */
  async confirm(message?: string, title?: string, data?: DialogConfirmationData, config?: DialogConfig) {
    // Use confirmation dialog config, fallback on just message.
    data = data ?? new DialogConfirmationData(message);
    if (message && !data.message) data.message = message;

    // Use dialog config, fallback on just title.
    config = config ?? { title };
    if (title && !config.title) config.title = title;

    // Open dialog and return promise that resolves when closed.
    return this.open<DialogConfirmationReturn>(DialogConfirmationComponent, data, config, undefined);
  }

  /** Open value in standard source code dialog. */
  async source(value: Object, readonly?: boolean, indent?: number | string) {
    return this.open<DialogSourceReturn, DialogSourceData>(DialogSourceComponent, new DialogSourceData(value, readonly, indent));
  }
}
