import { PermissionEdit } from "../../../../../../common/model/permission";

/** Data bound to permissions grid. */
export class PermissionsGridData {
  constructor(
    /** Upper-level fallback parent permissions. */
    public fallback = PermissionEdit.record(),
    /** Main list of permissions being edited. */
    public main = PermissionEdit.record()
  ) {}
}