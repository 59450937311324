import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { numberCoerce } from "../../../../../../common/toolbox/number";
import { fieldControlProviders } from '../../toolbox/angular';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  providers: fieldControlProviders(NumberComponent)
})
export class NumberComponent extends FieldControl implements ControlValueAccessor {
  /** Reference to input. */
  @ViewChild('input', { static : true }) input!: ElementRef<HTMLInputElement>;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }

  /** True to make a value required. */
  @Input() required = true;
  /** Placeholder to show in input. */
  @Input() placeholder: string | null = null;
  /** True to show button. */
  @Input() button = true;
  /** Minimum value. */
  @Input() min?: number;
  /** Maximum value. */
  @Input() max?: number;

  /** Current input value. */
  value?: number;

  writeValue(value: number | string | undefined) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.value = this.coerce(value);
    this.format();
  }

  /** Callback when typing into field. */
  onInput($event: Event) {
    let input = $event.target as HTMLInputElement;
    this.changed(this.value = this.coerce(input.value));
    this.setTouched();
    this.setDirty();
  }

  /** Callback when losing focus on field. */
  format() {
    this.input.nativeElement.value = this.value === undefined ? '' : `${this.value}`;
  }

  /** Coerce number value. */
  private coerce(value: any) {
    return numberCoerce(value, this.required, this.min, this.max)
  }
}