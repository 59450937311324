import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { Form, FormPreview } from '../../../../../../common/model/form/form';
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { FormQuery, FormService } from '../../../common/service/form.service';
import { LogService } from '../../../common/service/log.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-form',
  templateUrl: './setup-form.component.html',
  styleUrls: ['./setup-form.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupFormComponent extends SetupComponent<Form, FormQuery, FormPreview> {
  readonly Type = Form;
  readonly collection = 'forms';
  readonly route = 'forms';
  resource = idMaybe(new Form());

  static title() {
    return 'Forms';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public auth: AuthService,
    public http: HttpClient,
    public service: FormService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }
}
