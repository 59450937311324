import { SystemCode } from "../../code/system";
import { propinfoProperty } from "../../info/prop";
import { TypeValidation } from "../../info/type";
import { ConditionType } from "../../model/formula/condition";
import { Engine } from "../../model/formula/engine";
import { Expression } from "../../model/formula/expression";
import { OperatorType } from "../../model/formula/operator";
import { Statement } from "../../model/formula/statement";
import { Terminal, TerminalType } from "../../model/formula/terminal";
import { FUSION_COLLECTION_NAME } from "../../model/fusion-map";
import { Permission } from "../../model/permission";
import { BooleanProperty, CodeProperty, CurrencyProperty, DateProperty, MemberProperty, NumberProperty, Property, StringProperty, TransactionProperty, UserProperty } from "../../model/property";
import { PropertyType } from "../../model/property-type";
import { arrayDefined, arrayMax, arrayMin, arraySome, arraySum } from "../array";
import { currency } from "../currency";
import { enumHas } from "../enum";
import { OBJECTID_REGEX } from "../id";
import { NestedKey, keyNestedGet } from "../keys";
import { Comparison, ObjectKeys, objectCompare, objectPad, objectType } from "../object";
import { dateOffset } from "../time";
import { formulaNumberify, formulaStringify } from "./formula";
import { statementContext, statementLoop, statementProperty, statementQuery, statementValidateArray } from "./statement";

/** Evaluate an expression. */
export function expressionRun<T>(expression: Expression<T>): any {

  switch (expression.type) {

    // Terminals

    case TerminalType.Boolean:
    case TerminalType.Number:
    case TerminalType.Currency:
    case TerminalType.Code:
    case TerminalType.CodeList:
    case TerminalType.Permission:
    case TerminalType.Resource:
      return expression.value;
    case TerminalType.String:
      return expression.value.replace('\\n', '\n');
    case TerminalType.Date:
      return dateOffset(new Date(), expression.value);
    case TerminalType.Identifier:
      return Engine.context(input => keyNestedGet(expression.key, input));
    case TerminalType.Array:
      return expression.expressions.map(e => expressionRun(e));
    case TerminalType.Undefined:
      return undefined;
    
    // Operators

    case OperatorType.Add: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);

      if (lvalue instanceof Date) return dateOffset(lvalue, formulaNumberify(rvalue));
      return formulaNumberify(lvalue) + formulaNumberify(rvalue);
    }
    case OperatorType.Subtract: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      return formulaNumberify(lvalue) - formulaNumberify(rvalue);
    }
    case OperatorType.Multiply: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      return formulaNumberify(lvalue) * formulaNumberify(rvalue);
    }
    case OperatorType.Divide: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      return formulaNumberify(lvalue) / formulaNumberify(rvalue);
    }
    case OperatorType.Access:
    case OperatorType.Index: {
      // Access is a.b notation, Index is a[b] notation.
      let lvalue = expressionRun(expression.left);
      let rvalue = expression.type === OperatorType.Access && expression.right.type === TerminalType.Identifier
        ? expression.right.key : expressionRun(expression.right);

      // Negative array indexes start from end of array.
      if (Array.isArray(lvalue) && rvalue < 0) rvalue += lvalue.length;
      return keyNestedGet(rvalue, lvalue);
    }
    case OperatorType.Join: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      if (Array.isArray(lvalue)) {
        return lvalue.map(v => formulaStringify(v)).filter(v => v !== '' && v !== 'undefined').join(formulaStringify(rvalue)).replace(/ +/, ' ');
      } else {
        return '';
      }
    }
    case OperatorType.Not: {
      let value = expressionRun(expression.left);
      return !value;
    }
    case OperatorType.Length: {
      let value = expressionRun(expression.left);
      switch (objectType(value)) {
        case 'number':
          return `${value}`.length;
        case 'string':
        case 'array':
          return value.length;
        default:
          return NaN;
      }
    }
    case OperatorType.Display: {
      // Check if this is a code.
      let value = expressionRun(expression.left);
      let array = Array.isArray(value);
      let category = expressionCode(expression.left);
      if (!category) return array ? value.map((v: any) => `${v}?`) : `${value}?`;

      // Format out each value.
      let map = Engine.enums.get(category);
      if (array) {
        return value.map((v: any) => map?.get(v) ?? 'Unknown');
      } else {
        return map?.get(value) ?? 'Unknown';
      }
    }
    case OperatorType.Sum: {
      let value = expressionRun(expression.left);
      if (Array.isArray(value)) {
        return arraySum(value.map(formulaNumberify));
      } else {
        return NaN;
      }
    }
    case OperatorType.Map: {
      let value = expressionRun(expression.expression);
      let output: any[] = [];

      statementLoop(value, expression.statements, () => {
        output.push(Engine.return ? Engine.output : undefined);
      });
      
      return output;
    }
    case OperatorType.Max: {
      let value = expressionRun(expression.left);
      if (arraySome(value)) {
        return arrayMax(value);
      } else {
        return value;
      }
    }
    case OperatorType.Min: {
      let value = expressionRun(expression.left);
      if (arraySome(value)) {
        return arrayMin(value);
      } else {
        return value;
      }
    }
    case OperatorType.Filter: {
      let value = expressionRun(expression.expression);
      let output: any[] = [];

      statementLoop(value, expression.statements, item => {
        if (Engine.return && Engine.output) output.push(item);
      });

      return output;
    }
    case OperatorType.Pad: {
      let lvalue = expressionRun(expression.left);
      let mvalue = expressionRun(expression.middle);
      let rvalue = expressionRun(expression.right);
      return objectPad(lvalue, mvalue, rvalue);
    }
    case OperatorType.Slice: {
      let lvalue = expressionRun(expression.left);
      let mvalue = +expressionRun(expression.middle);
      let rvalue = +expressionRun(expression.right);
      if (Array.isArray(lvalue)) return lvalue.slice(mvalue, rvalue);
      else return `${lvalue}`.slice(mvalue, rvalue);
    }
    case OperatorType.Granted: {
      let lvalue = expressionRun(expression.left);

      if (typeof lvalue === 'number') return Engine.granted([lvalue]);
      if (Array.isArray(lvalue) && lvalue.every(v => typeof v === 'number')) return Engine.granted(lvalue);
      return false;
    }

    // Conditions

    case ConditionType.Every: {
      let lvalue = expressionRun(expression.left);
      return Array.isArray(lvalue) ? lvalue.every(v => v) : false;
    }
    case ConditionType.Some: {
      let lvalue = expressionRun(expression.left);
      return Array.isArray(lvalue) ? lvalue.some(v => v) : false;
    }
    case ConditionType.And: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      return lvalue && rvalue;
    }
    case ConditionType.Or: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      return lvalue || rvalue;
    }
    case ConditionType.Equal: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      let cmp = objectCompare(lvalue, rvalue);
      return cmp === Comparison.Equal;
    }
    case ConditionType.Unequal: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      let cmp = objectCompare(lvalue, rvalue);
      return cmp !== Comparison.Equal;
    }
    case ConditionType.Greater: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      let cmp = objectCompare(lvalue, rvalue);
      return cmp === Comparison.Greater;
    }
    case ConditionType.Lesser: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      let cmp = objectCompare(lvalue, rvalue);
      return cmp === Comparison.Lesser;
    }
    case ConditionType.GreaterEqual: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      let cmp = objectCompare(lvalue, rvalue);
      return cmp === Comparison.Greater || cmp === Comparison.Equal;
    }
    case ConditionType.LesserEqual: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      let cmp = objectCompare(lvalue, rvalue);
      return cmp === Comparison.Lesser || cmp === Comparison.Equal;
    }
    case ConditionType.Like: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);
      return !!String(lvalue).match(String(rvalue));
    }
    case ConditionType.In: {
      let lvalue = expressionRun(expression.left);
      let rvalue = expressionRun(expression.right);

      if (Array.isArray(rvalue)) {
        for (let value of rvalue) {
          let cmp = objectCompare(lvalue, value);
          if (cmp === Comparison.Equal) return true;
        }
      }

      return false;
    }
    case ConditionType.Between: {
      let lvalue = expressionRun(expression.left);
      let mvalue = expressionRun(expression.middle);
      let rvalue = expressionRun(expression.right);
      let cmp1 = objectCompare(lvalue, mvalue);
      let cmp2 = objectCompare(lvalue, rvalue);
      return (cmp1 === Comparison.Greater || cmp1 == Comparison.Equal) && (cmp2 === Comparison.Lesser || cmp2 === Comparison.Equal);
    }
    case ConditionType.Match: {
      let lvalue = expressionRun(expression.left);

      // Check if lvalue matches rvalue, or lvalue inside rvalue array.
      for (let arm of expression.arms) {
        let rvalue = expressionRun(arm.pattern);
        if (Array.isArray(rvalue)) {
          for (let value of rvalue) {
            let cmp = objectCompare(lvalue, value);
            if (cmp === Comparison.Equal) return expressionRun(arm.expression);
          }
        } else {
          let cmp = objectCompare(lvalue, rvalue);
          if (cmp === Comparison.Equal) return expressionRun(arm.expression);
        }
      }

      // No arms matched.
      return expression.default ? expressionRun(expression.default) : undefined;
    }
    default:
      /* angular-tslint:disable:no-unused-variable */
      const _: never = expression;
      return _;
  }
}

/** Format out an expression's query. */
export function expressionQuery<T>(e: Expression<T>): any {
  switch (e.type) {

    // Terminals

    case TerminalType.Boolean:
    case TerminalType.Number:
    case TerminalType.Currency:
    case TerminalType.Code:
    case TerminalType.CodeList:
    case TerminalType.Permission:
    case TerminalType.Resource:
      return e.value;
    case TerminalType.String:
      return e.value.replace('\\n', '\n');
    case TerminalType.Date:
      return dateOffset(new Date(), e.value);
    case TerminalType.Identifier:
      return `$${e.key as string}`;
    case TerminalType.Array:
      return e.expressions.map(expressionQuery);
    case TerminalType.Undefined:
      return undefined;
    // Operators

    case OperatorType.Add:
      return { $add: [expressionQuery(e.left), expressionQuery(e.right)] };
    case OperatorType.Subtract:
      return { $subtract: [expressionQuery(e.left), expressionQuery(e.right)] };
    case OperatorType.Multiply:
      return { $multiply: [expressionQuery(e.left), expressionQuery(e.right)] };
    case OperatorType.Divide:
      return { $divide: [expressionQuery(e.left), expressionQuery(e.right)] };
    case OperatorType.Access:
    case OperatorType.Index:
      return { $getField: { input: expressionQuery(e.left), field: expressionQuery(e.right) } };
    case OperatorType.Length:
      return { $size: expressionQuery(e.left) };
    case OperatorType.Join:
      // eg. { $concat: ['$first', ' - ', '$second', ' - ', '$third'] }
      let value: any[] = expressionQuery(e.left);
      let delimiter = expressionQuery(e.right);
      if (!Array.isArray(value)) value = [];
      return { $concat: value.map(v => [v, delimiter]).slice(0, -1) };
    case OperatorType.Sum:
      return { $sum: expressionQuery(e.left) };
    case OperatorType.Map: {
      let identifier = expressionQuery(e.expression);
      return { $map: { identifier, as: 'value', in: e.statements.map(statementQuery) } }; // TODO Untested
    }
    case OperatorType.Filter: {
      let input = expressionQuery(e.expression);
      return { $filter: { input, as: 'value', cond: e.statements.map(statementQuery) } }; // TODO Untested
    }
    case OperatorType.Granted: {
      return { $in: [] }; // TODO NOP operator here.
    }

    // Conditionals

    case ConditionType.Every:
      return { $and: expressionQuery(e.left) };
    case ConditionType.Some:
      return { $or: expressionQuery(e.left) };
    case ConditionType.And:
      return { $and: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.Or:
      return { $or: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.Equal:
      return { $eq: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.Unequal:
      return { $ne: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.Greater:
      return { $gt: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.Lesser:
      return { $lt: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.GreaterEqual:
      return { $gte: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.LesserEqual:
      return { $lte: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.Like:
      return { $regex: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.In:
      return { $in: [expressionQuery(e.left), expressionQuery(e.right)] };
    case ConditionType.Between: // TODO this returns a document that results in incorrect formatting of the mongo query, fix it.
      return {
        $gte: [expressionQuery(e.left), expressionQuery(e.middle)],
        $lte: [expressionQuery(e.left), expressionQuery(e.right)]
      };
    case ConditionType.Match: // TODO untested here.
      let lvalue = expressionQuery(e.left);
      return {
        $switch: {
          branches: e.arms.map(e => ({
            case: { $eq: [lvalue, expressionRun(e.pattern)], then: expressionRun(e.expression) }
          })),
          default: e.default ? expressionRun(e.default) : undefined
        }
      };
  }
}

/** Get the property of an expression. */
export function expressionProperty<T>(root: Statement<T>[], e: Expression<T>, input: T): Property | undefined {
  switch (e.type) {

    // Terminals

    case TerminalType.Boolean:
      return new BooleanProperty(e.value);
    case TerminalType.Number:
      return new NumberProperty(e.value);
    case TerminalType.String:
      return new StringProperty(e.value);
    case TerminalType.Currency:
      return new CurrencyProperty(e.value);
    case TerminalType.Date:
      return new DateProperty(e.value);
    case TerminalType.Code:
      return new CodeProperty(e.value, e.category as SystemCode);
    case TerminalType.Identifier: {
      let context = statementContext(root, e, []) ?? [];
      let key = [...context, e.key].join('.') as NestedKey<T>;
      return propinfoProperty(key, input);
    }
    case TerminalType.CodeList:
    case TerminalType.Undefined:
      return undefined;
    case TerminalType.Array:
      let properties = e.expressions.map(e => expressionProperty(root, e, input));
      return properties.every(p => p?.type === properties[0]?.type) ? properties[0] : undefined;
    case TerminalType.Permission:
      return new NumberProperty();
    case TerminalType.Resource:
      switch (e.collection) {
      case 'users':
        return new UserProperty(e.value);
      case 'members':
        return new MemberProperty(e.value);
      case 'transactions':
        return new TransactionProperty(e.value);
      default:
        return new StringProperty(e.value);
      }

    // Operators

    case OperatorType.Add:
    case OperatorType.Subtract:
    case OperatorType.Multiply:
    case OperatorType.Divide: {
      let lproperty = expressionProperty(root, e.left, input);
      let rproperty = expressionProperty(root, e.right, input);
      if (lproperty?.type === PropertyType.Currency && rproperty?.type === PropertyType.Currency) return lproperty;
      if (lproperty?.type === PropertyType.Date) return lproperty;
      return new NumberProperty();
    }
    case OperatorType.Access:
    case OperatorType.Index: {
      let context = statementContext(root, e, []) ?? [];
      let key = context.join('.') as NestedKey<T>;
      return propinfoProperty(key, input);
    }
    case OperatorType.Display:
    case OperatorType.Pad:
    case OperatorType.Slice:
      return Array.isArray(expressionRun(e.left)) ? undefined : new StringProperty();
    case OperatorType.Filter:
      return undefined;
    case OperatorType.Granted:
    case OperatorType.Not:
      return new BooleanProperty();
    case OperatorType.Join:
      return new StringProperty();
    case OperatorType.Length:
      return new NumberProperty();
    case OperatorType.Max:
    case OperatorType.Min:
      return expressionProperty(root, e.left, input);
    case OperatorType.Map:
      return statementProperty(root, e.statements, input);
    case OperatorType.Sum:
      return expressionProperty(root, e.left, input);

    // Conditions:

    case ConditionType.Every:
    case ConditionType.Some:
    case ConditionType.And:
    case ConditionType.Or:
    case ConditionType.Equal:
    case ConditionType.Unequal:
    case ConditionType.Greater:
    case ConditionType.Lesser:
    case ConditionType.GreaterEqual:
    case ConditionType.LesserEqual:
    case ConditionType.Like:
    case ConditionType.In:
    case ConditionType.Between:
    case ConditionType.Match:
      return new BooleanProperty();

    default:
      /* angular-tslint:disable:no-unused-variable */
      const _: never = e;
      return _;
  }
}

/** Check an expression is valid. */
export function expressionValidate<T>(expression: Expression<T>): TypeValidation<Expression<T>> {
  if (!enumHas(TerminalType, expression.type) && !enumHas(OperatorType, expression.type) && !enumHas(ConditionType, expression.type)) return 'type';

  switch (expression.type) {

    // Terminals

    case TerminalType.Boolean:
      if (typeof expression.value !== 'boolean') return 'value' as keyof Terminal;
      break;
    case TerminalType.Number:
    case TerminalType.Date:
    case TerminalType.Currency:
      if (typeof expression.value !== 'number') return 'value' as keyof Terminal;
      break;
    case TerminalType.String:
    case TerminalType.Code:
      if (typeof expression.value !== 'string') return 'value' as keyof Terminal;
      break;
    case TerminalType.CodeList:
      if (!(Array.isArray(expression.value))) return 'value' as keyof Terminal;
      break;
    case TerminalType.Identifier:
      if (typeof expression.key !== 'string') return 'key' as keyof Terminal;
      if (Engine.keys.size && !Engine.keys.has(expression.key as string)) return 'key' as keyof Terminal;
      break;
    case TerminalType.Array:
      if (!Array.isArray(expression.expressions)) return 'expressions' as keyof Terminal;
      return expressionValidateArray(expression, 'expressions');
    case TerminalType.Permission:
      if (!enumHas(Permission, expression.value)) return 'value' as keyof Terminal;
      break;
    case TerminalType.Undefined:
      return;
    case TerminalType.Resource:
      if (typeof expression.collection !== 'string' || !FUSION_COLLECTION_NAME[expression.collection]) return 'collection' as keyof Terminal;
      if (typeof expression.value !== 'string' || !OBJECTID_REGEX.test(expression.value)) return 'value' as keyof Terminal;
      break;

    // Unary expressions

    case OperatorType.Length:
    case OperatorType.Sum:
    case OperatorType.Display:
    case OperatorType.Slice:
    case OperatorType.Pad:
    case OperatorType.Max:
    case OperatorType.Min:
    case OperatorType.Not:
    case OperatorType.Granted:
    case ConditionType.Every:
    case ConditionType.Some:
      return expressionValidateKey(expression, 'left');

    // Binary expressions

    case OperatorType.Add:
    case OperatorType.Subtract:
    case OperatorType.Multiply:
    case OperatorType.Divide:
    case OperatorType.Access:
    case OperatorType.Index:
    case OperatorType.Join:
    case ConditionType.And:
    case ConditionType.Or:
    case ConditionType.Equal:
    case ConditionType.Unequal:
    case ConditionType.Greater:
    case ConditionType.Lesser:
    case ConditionType.GreaterEqual:
    case ConditionType.LesserEqual:
    case ConditionType.Like:
    case ConditionType.In:
      return expressionValidateKey(expression, 'left') || expressionValidateKey(expression, 'right');

    // Ternary expressions:
    case OperatorType.Slice:
    case OperatorType.Pad:
    case ConditionType.Between:
      return expressionValidateKey(expression, 'left') || expressionValidateKey(expression, 'middle') || expressionValidateKey(expression, 'right');

    // Many expressions:

    case ConditionType.Match:
      return expressionValidateKey(expression, 'left') || arrayDefined(expression.arms.map(arm => expressionValidateKey(arm, 'pattern') || expressionValidateKey(arm, 'expression')))[0] || (expression.default ? expressionValidateKey(expression, 'default') : undefined);
    
      // Loop expressions

    case OperatorType.Map:
    case OperatorType.Filter:
      return expressionValidateKey(expression, 'expression') && statementValidateArray(expression, 'statements');
    default:
      const _: never = expression;
      return _;
  }
}

/** Perform an expression validation on an key. */
export function expressionValidateKey<T>(value: T, key: ObjectKeys<T, Expression | undefined>): TypeValidation<Expression> {
  if (value[key] === undefined) return String(key) as any;
  let validation = expressionValidate(value[key] as unknown as Expression<T>);
  if (validation) return `${String(key)}.${validation}` as any;
}

/** Perform an expression validation on an array. */
export function expressionValidateArray<T>(value: T, key: ObjectKeys<T, Expression[]>): TypeValidation<Expression> {
  for (let expression of value[key] as unknown as Expression<T>[]) {
    let validation = expressionValidate(expression);
    if (validation) return `${String(key)}.${validation}` as any;
  }
}

/**
 *  Determine type hint context at a particular point in a statement.
 *  Note: Only identifiers of map/filter expressions influence outer context, [...context].
 */
export function expressionContext<T>(expression: Expression<T>, target: any, context: string[]): string[] | undefined {
  if (expression === target) return context;

  switch (expression.type) {

    // Terminals

    case TerminalType.Boolean:
    case TerminalType.Number:
    case TerminalType.Date:
    case TerminalType.Currency:
    case TerminalType.String:
    case TerminalType.Code:
    case TerminalType.CodeList:
    case TerminalType.Undefined:
    case TerminalType.Permission:
    case TerminalType.Resource:
      return undefined;

    case TerminalType.Identifier:
      context.push(expression.key);
      return undefined;
    case TerminalType.Array:
      for (let subexpression of expression.expressions) {
        let key = expressionContext(subexpression, target, [...context]);
        if (key !== undefined) return key;
      } return undefined;

    // Unary expressions

    case OperatorType.Length:
    case OperatorType.Sum:
    case OperatorType.Display:
    case OperatorType.Slice:
    case OperatorType.Pad:
    case OperatorType.Max:
    case OperatorType.Min:
    case OperatorType.Not:
    case OperatorType.Granted:
    case ConditionType.Every:
    case ConditionType.Some:
      return expressionContext(expression.left, target, [...context]);

    // Binary expressions

    case OperatorType.Add:
    case OperatorType.Subtract:
    case OperatorType.Multiply:
    case OperatorType.Divide:
    case OperatorType.Access:
    case OperatorType.Index:
    case OperatorType.Join:
    case ConditionType.And:
    case ConditionType.Or:
    case ConditionType.Equal:
    case ConditionType.Unequal:
    case ConditionType.Greater:
    case ConditionType.Lesser:
    case ConditionType.GreaterEqual:
    case ConditionType.LesserEqual:
    case ConditionType.Like:
    case ConditionType.In:
      return expressionContext(expression.left, target, [...context]) ?? expressionContext(expression.right, target, [...context]);

    // Ternary expressions
    case OperatorType.Slice:
    case OperatorType.Pad:
    case ConditionType.Between:
      return expressionContext(expression.left, target, [...context]) ?? expressionContext(expression.middle, target, [...context]) ?? expressionContext(expression.right, target, [...context]);

    // Many expressions

    case ConditionType.Match:
      return expressionContext(expression.left, target, [...context]) ?? arrayDefined(expression.arms.map(arm => expressionContext(arm.pattern, target, [...context]) ?? expressionContext(arm.expression, target, [...context])))[0];

    // Loop expressions

    case OperatorType.Map:
    case OperatorType.Filter:
      return expressionContext(expression.expression, target, context) ?? statementContext(expression.statements, target, [...context]);
  }
}

/** Coerce given expression to currency. */
export function expressionCurrency<T>(expression: Expression<T>): currency | currency[] | void {
  switch (expression.type) {
    case TerminalType.Currency:
      return expression.value;
    case TerminalType.Identifier:
      let property = Engine.context(input => propinfoProperty(expression.key, input));
      if (property?.type === PropertyType.Currency) return expressionRun(expression);
  }
}

/** Coerce given expression to code. */
export function expressionCode<T>(expression: Expression<T>): string | void {
  switch (expression.type) {
    case TerminalType.Code:
    case TerminalType.CodeList:
      return expression.category;
    case TerminalType.Identifier:
      let key = String(expression.key);
      let property = Engine.context(input => propinfoProperty(key, input));
      if (property?.type === PropertyType.Code) return property.category;
  }
}
