import { EnumMap } from "../../toolbox/enum";

/** Types of ledger accounts. */
export enum LedgerAccountType {
  Consumer,
  Acquirer
}

/** Name of each ledger account type. */
export const LEDGER_ACCOUNT_TYPE_NAME: EnumMap<LedgerAccountType> = {
  [LedgerAccountType.Consumer]: 'Consumer Account',
  [LedgerAccountType.Acquirer]: 'Acquirer Account'
};