import { CommonCode, DisputesCode } from "../../code/system";
import { collectioninfoOf, CollectionInfo } from "../../info/collection";
import { PropertyInfo } from "../../info/prop";
import { SettingType, SettingsInfo, settingsinfoOf } from "../../info/settings";
import { TypeInfo } from "../../info/type";
import { ID_DEFAULT } from "../../toolbox/id";
import { Newable } from "../../toolbox/object";
import { titleCase } from "../../toolbox/string";
import { FusionCollection } from "../fusion";
import { PropertyType } from "../property-type";

/** Base options shared by all claim types. */
class ClaimSettings {

  /** Default form. */
  form = ID_DEFAULT
  /** Form list for detailed information. */
  formList = ID_DEFAULT
  /** Model for custom fields or undefined if no custom fields are used. */
  model?: string
  /** Table to display for transactions, or undefined to use default transaction table. */
  transactionTable?: string
  /** Default workflow. */
  workflow = ID_DEFAULT
  /** Create claim settings for given type. */
  static type(lower: string, upper = titleCase(lower)): Newable<ClaimSettings> {
    return class extends ClaimSettings {

      static typeinfo: TypeInfo<ClaimSettings> = {
        transactionTable: ID_DEFAULT,
        model: ID_DEFAULT
      }

      static settingsinfo: SettingsInfo<ClaimSettings> = {
        form: {
          type: SettingType.Form,
          name: `${upper} Claim Form`,
          description: `Default form for ${lower} claims.`
        },
        formList: {
          type: SettingType.FormList,
          name: `${upper} Claim Advanced Form`,
          description: `Detailed information to display about ${lower} claims when expanding advanced information.`
        },
        model: {
          type: SettingType.Model,
          name: `${upper} Claim Model`,
          description: `Model containing any custom information for ${lower} claims.`
        },
        transactionTable: {
          type: SettingType.Table,
          name: `${upper} Transaction Table`,
          description: `The table used to display ${lower} claim transactions, or empty to use the default transaction table.`
        },
        workflow: {
          type: SettingType.Workflow,
          name: `${upper} Claim Workflow`,
          description: `The top-level workflow that ${lower} claims progress through.`
        }
      }

      static collectioninfo: CollectionInfo<FusionCollection, ClaimSettings> = {
        form: 'forms',
        formList: 'formLists',
        model: 'models',
        transactionTable: 'tables',
        workflow: 'workflows'
      }
    }
  }
}

/** Base options shared by all disputes types. */
class DisputeSettings {

  /** Default disputes form. */
  form = ID_DEFAULT;
  /** Form to display on preview of dispute accordion. */
  previewForm = ID_DEFAULT;
  /** Workflow for disputes. */
  workflow = ID_DEFAULT;

  /** Create dispute settings for given type. */
  static type(lower: string, upper = titleCase(lower)): Newable<DisputeSettings> {
    return class extends DisputeSettings {
      static settingsinfo: SettingsInfo<DisputeSettings> = {
        form: {
          type: SettingType.Form,
          name: `${upper} Dispute Form`,
          description: `Default form for ${lower} disputes.`,
        },
        previewForm: {
          type: SettingType.Form,
          name: `${upper} Dispute Preview Form`,
          description: `The form displayed in the header of each ${lower} dispute, visible without expanding the dispute.`
        },
        workflow: {
          type: SettingType.Workflow,
          name: `${upper} Dispute Workflow`,
          description: `The workflow that disputes on a ${lower} claim progress through.`
        }
      }
      static collectioninfo: CollectionInfo<FusionCollection, DisputeSettings> = {
        form: 'forms',
        previewForm: 'forms',
        workflow: 'workflows'
      }
    }
  }
}

/** Base intake options shared by all claim types. */
class IntakeSettings {

  /** Form to display for accounts. */
  accountForm = ID_DEFAULT;
  /** Form list for details. */
  formList = ID_DEFAULT;
  /** Formula for calculating investigation due date. */
  investigationDueDateFormula = ID_DEFAULT;
  /** Whether user can select multiple transactions for this type of claim. */
  multipleTransactions = true;
  /** Formula to prevent selection of a transaction. */
  transactionSelectFormula?: string;

  /** Create dispute settings for given type. */
  static type(lower: string, upper = titleCase(lower)): Newable<IntakeSettings> {
    return class extends IntakeSettings {

      static typeinfo: TypeInfo<IntakeSettings> = {
        transactionSelectFormula: ID_DEFAULT
      }

      static settingsinfo: SettingsInfo<IntakeSettings> = {
        accountForm: {
          type: SettingType.Form,
          name: `${upper} Claim Account Form`,
          description: `The form to use for displaying accounts of ${lower} claims.`
        },
        investigationDueDateFormula: {
          type: SettingType.Formula,
          name: `${upper} Dispute Investigation Due`,
          description: 'The formula to calculate the default due date on intake.'
        },
        formList: {
          type: SettingType.FormList,
          name: `${upper} Claim Intake Form List`,
          description: `The questionnaire to display to users when filling out the details of ${lower} claims.`
        },
        multipleTransactions: {
          type: SettingType.Boolean,
          name: `${upper} Claim Intake Allow Multiple Transactions`,
          description: `Whether a user is allowed to select multiple transactions on intake for ${lower} claims`
        },
        transactionSelectFormula: {
          type: SettingType.Formula,
          name: `${upper} Transaction Selection Formula`,
          description: `Returns an error message for ${lower} claim transactions that cannot be selected during intake.`
        }
      }
      static collectioninfo: CollectionInfo<FusionCollection, IntakeSettings> = {
        accountForm: 'forms',
        investigationDueDateFormula: 'formulas',
        formList: 'formLists',
        transactionSelectFormula: 'formulas'
      }
    }
  }
}

/** Settings for dispute feature in institution. */
export class DisputesSettings {

  /** Universal claim options. */
  claim = new class {
    /** The default earliest date used to filter out transactions on claim intake. This is the number of days before the current day. */
    defaultTransactionDate = 0
    /** The maximum earliest date used to filter out transactions on claim intake. This is the number of days before the current day. */
    earliestTransactionDate = 0
    /** Whether to show already disputed transactions by default. */
    showDisputedTransactions = false
    /** Table for reports. */
    reportTable = ID_DEFAULT
    /** Default table. */
    table = ID_DEFAULT
    /** Form to display on the toolbar. */
    toolbarForm = ID_DEFAULT
    /** Task to display when viewing. */
    viewTask = ID_DEFAULT
    /** Whether posting transactions to the core is enabled. */
    postTransactions = false
    static propinfo: PropertyInfo<DisputesSettings['claim']> = {
      earliestTransactionDate: {
        type: PropertyType.Number,
        value: 30,
        min: 0,
        max: 120
      }
    }

    static settingsinfo: SettingsInfo<DisputesSettings['claim']> = {
      defaultTransactionDate: {
        type: SettingType.Number,
        name: 'Claim Default Earliest Transaction Date',
        description: 'The default earliest date used to filter out transactions on claim intake. This is the number of days before the current day.'
      },
      earliestTransactionDate: {
        type: SettingType.Number,
        name: 'Claim Earliest Transaction Date',
        description: 'The maximum earliest date used to filter out transactions on claim intake. This is the number of days before the current day.'
      },
      postTransactions: {
        type: SettingType.Boolean,
        name: 'Post Transactions',
        description: "Enables posting transactions to the core."
      },
      reportTable: {
        type: SettingType.Table,
        name: 'Claim Report Table',
        description: 'Table for claim report results.'
      },
      showDisputedTransactions: {
        type: SettingType.Boolean,
        name: 'Show Already Disputed Transactions',
        description: 'Default setting for showing already disputed transactions.'
      },
      table: {
        type: SettingType.Table,
        name: 'Claim Table',
        description: 'Global table for claims.'
      },
      toolbarForm: {
        type: SettingType.Form,
        name: 'Claim Toolbar Form',
        description: 'Form to show at the toolbar at the top of the claim work screen'
      },
      viewTask: {
        type: SettingType.Task,
        name: 'Claim View Task',
        description:'A mandatory task for users when opening claims.'
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, DisputesSettings['claim']> = {
      reportTable: 'tables',
      table: 'tables',
      toolbarForm: 'forms',
      viewTask: 'tasks'
    }
  }

  /** Universal dispute options. */
  dispute = new class {
    /** Form to use when adding ledger items. */
    ledgerForm = ID_DEFAULT

    static settingsinfo: SettingsInfo<DisputesSettings['dispute']> = {
      ledgerForm: {
        type: SettingType.Form,
        name: 'Dispute Ledger Item Form',
        description: 'Displayed to users when adding ledger items to disputes.'
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, DisputesSettings['dispute']> = {
      ledgerForm: 'forms'
    }
  }

  /** Options for ACH claims. */
  ach = new class {
    /** Options for claims. */
    claim = new (ClaimSettings.type('ACH', 'ACH'))();
    /** Options for disputes. */
    dispute = new (DisputeSettings.type('ACH', 'ACH'));
    /** Options for intake. */
    intake = new class extends IntakeSettings.type('ACH', 'ACH') {

      /** Document template to display for intake. */
      documentTemplate = ID_DEFAULT;
      /** Transaction codes to pull when querying core. */
      transactionCodes?: string[];

      static typeinfo: TypeInfo<DisputesSettings['ach']['intake']> = {
        transactionSelectFormula: ID_DEFAULT,
        transactionCodes: ['']
      }

      static settingsinfo: SettingsInfo<DisputesSettings['ach']['intake']> = {
        ...settingsinfoOf(IntakeSettings.type('ACH', 'ACH')),
        documentTemplate: {
          type: SettingType.DocumentTemplate,
          name: 'ACH Claim Intake Document Template',
          description: 'Document template to download at the end of ACH claim intake.'
        },
        transactionCodes: {
          type: SettingType.Codes,
          name: `ACH Transaction Codes`,
          description: `The list of transaction codes applicable to ACH claims. Filters the list of available transactions when performing ACH intake.`,
          category: CommonCode.Transaction,
          multiple: true
        }
      }

      static collectioninfo: CollectionInfo<FusionCollection, DisputesSettings['ach']['intake']> = {
        ...collectioninfoOf(IntakeSettings.type('ACH', 'ACH')),
        documentTemplate: 'documentTemplates',
      }
    };
  }

  /** Options for card claims. */
  card = new class {
    /** Options for claims. */
    claim = new (ClaimSettings.type('card'))();
    /** Options for disputes. */
    dispute = new class extends DisputeSettings.type('card') {

      /** Formula for transactions above the processing threshold. */
      thresholdFormula = ID_DEFAULT;

      static settingsinfo: SettingsInfo<DisputesSettings['card']['dispute']> = {
        ...settingsinfoOf(DisputeSettings.type('card')),
        thresholdFormula: {
          type: SettingType.Formula,
          name: 'Card Dispute Threshold formula',
          description: 'If this formula evaluates to true for a dispute, it will be displayed on card claim reports.'
        }
      }

      static collectioninfo: CollectionInfo<FusionCollection, DisputesSettings['card']['dispute']> = {
        ...collectioninfoOf(DisputeSettings.type('card')),
        thresholdFormula: 'formulas'
      }

    };

    /** Options for intake. */
    intake = new class extends IntakeSettings.type('card') {
      /** Document template to display for intake. */
      documentTemplate = ID_DEFAULT;
      /** Transaction codes to pull when querying core. */
      transactionCodes?: string[];

      static typeinfo: TypeInfo<DisputesSettings['ach']['intake']> = {
        transactionSelectFormula: ID_DEFAULT,
        transactionCodes: ['']
      }

      static settingsinfo: SettingsInfo<DisputesSettings['card']['intake']> = {
        ...settingsinfoOf(IntakeSettings.type('card')),
        documentTemplate: {
          type: SettingType.DocumentTemplate,
          name: 'Card Claim Intake Document Template',
          description: 'Document template to download at the end of card claim intake.'
        },
        transactionCodes: {
          type: SettingType.Codes,
          name: `Card Transaction Codes`,
          description: `The list of transaction codes applicable to card claims. Filters the list of available transactions when performing card intake.`,
          category: CommonCode.Transaction,
          multiple: true
        }
      }

      static collectioninfo: CollectionInfo<FusionCollection, DisputesSettings['card']['intake']> = {
        ...collectioninfoOf(IntakeSettings.type('card')),
        documentTemplate: 'documentTemplates',
      }

    };
  }

  /** Options for check claims. */
  check = new class {
    /** Options for claims. */
    claim = new (ClaimSettings.type('check'))();
    /** Options for disputes. */
    dispute = new (DisputeSettings.type('check')); 
    /** Options for intake. */
    intake = new class extends IntakeSettings.type('check') {
      /** Document template to display for forgery. */
      forgeryDocumentTemplate = ID_DEFAULT;
      /** Reason codes corresponding to a forgery claim. */
      forgeryReasonCodes?: string[];
      /** Transaction codes to pull when querying core. */
      transactionCodes?: string[];
      /** Document template to display for unauthorized. */
      unauthorizedDocumentTemplate = ID_DEFAULT;
      /** Reason codes corresponding to an unauthorized claim. */
      unauthorizedReasonCodes?: string[];

      static typeinfo: TypeInfo<DisputesSettings['check']['intake']> = {
        transactionSelectFormula: ID_DEFAULT,
        forgeryReasonCodes: [''],
        transactionCodes: [''],
        unauthorizedReasonCodes: ['']
      }

      static settingsinfo: SettingsInfo<DisputesSettings['check']['intake']> = {
        ...settingsinfoOf(IntakeSettings.type('check')),
        forgeryDocumentTemplate: {
          type: SettingType.DocumentTemplate,
          name: 'Check Forgery Claim Intake Document Template',
          description: 'Document template to download at the end of the check forgery dispute intake.'
        },
        forgeryReasonCodes: {
          type: SettingType.Codes,
          name: `Check Forgery Reason Codes`,
          description: `The list of reason codes applicable to check forgery claims.`,
          category: DisputesCode.ClaimCheckReportReason,
          multiple: true
        },
        transactionCodes: {
          type: SettingType.Codes,
          name: `Check Transaction Codes`,
          description: `The list of transaction codes applicable to check claims. Filters the list of available transactions when performing check intake.`,
          category: CommonCode.Transaction,
          multiple: true
        },
        unauthorizedDocumentTemplate: {
          type: SettingType.DocumentTemplate,
          name: 'Unauthorized Check Intake Document Template',
          description: 'Document template to download at the end of the unauthorized check dispute intake.'
        },
        unauthorizedReasonCodes: {
          type: SettingType.Codes,
          name: `Check Unauthorized Reason Codes`,
          description: `The list of reason codes applicable to check unauthorized claims.`,
          category: DisputesCode.ClaimCheckReportReason,
          multiple: true
        },
      }

      static collectioninfo: CollectionInfo<FusionCollection, DisputesSettings['check']['intake']> = {
        ...collectioninfoOf(IntakeSettings.type('check')),
        forgeryDocumentTemplate: 'documentTemplates',
        unauthorizedDocumentTemplate: 'documentTemplates',
      }

    }
  }

  /** Options for credit bureau claims. */
  creditBureau = new class {
    /** Options for claims. */
    claim = new (ClaimSettings.type('credit bureau'))();
  }
  
}