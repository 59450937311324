<app-icon>{{PropertyType.Code | icon:PROPERTY_TYPE_ICON}}</app-icon>
<app-code
  [filter]="filter"
  [sort]="sort"
  [prefix]="prefix"
  [suffix]="suffix"
  [category]="category"
  [multiple]="multiple"
  [disabled]="disabled"
  [required]="required"
  [readonly]="readonly | propertyReadonly:locked:reopened"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  (dirtyChange)="setDirty($event)"
  (touchChange)="setTouched($event)">
</app-code>