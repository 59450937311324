import { ErrorResponse } from '../message/error';
import { SuccessResponse } from '../message/success';
import { arrayDefined, arrayPartition } from './array';

/** Check if object is an error response. */
export function errorResponse<T>(message: T | ErrorResponse): message is ErrorResponse {
  return message instanceof Object && typeof message.error === 'string';
}

/** Check if object is a success response. */
export function successResponse<T>(message: T | SuccessResponse): message is SuccessResponse {
  return message instanceof Object && typeof message.success === 'string';
}

/** Utility type union with error. */
type ME<T> = T | ErrorResponse

/** Find an error in a list of items. */
export function errorFirst<A>(items: [ME<A>]): [ErrorResponse] | [A];
export function errorFirst<A, B>(items: [ME<A>, ME<B>]): [ErrorResponse] | [A, B];
export function errorFirst<A, B, C>(items: [ME<A>, ME<B>, ME<C>]): [ErrorResponse] | [A, B, C];
export function errorFirst<A, B, C, D>(items: [ME<A>, ME<B>, ME<C>, ME<D>]): [ErrorResponse] | [A, B, C, D];
export function errorFirst(...items: any[]): any[] {
  let error = items.find(errorResponse);
  return error ? [error] : items;
}

/** Partition array of mixed errors into errors and non-errors. */
export function errorPartition<T>(error: string, items: Array<T | ErrorResponse>): [error: ErrorResponse | undefined, items: T[]]
export function errorPartition<T>(error: string, items?: Array<T | ErrorResponse>): [error: ErrorResponse | undefined, items: T[] | undefined]
export function errorPartition<T>(error: string, items?: Array<T | ErrorResponse>): [error: ErrorResponse | undefined, items: T[] | undefined] {
  if (!items) return [undefined, undefined];
  let [errors, results] = arrayPartition<ErrorResponse, T>(items, errorResponse);
  return [errors.length ? new ErrorResponse(error, errors) : undefined, results];
}

/** Partition array of mixed errors, non-errors and filter. */
export function errorPartitionDefined<T>(error: string, items: Array<T | ErrorResponse | undefined>): [error: ErrorResponse | undefined, items: T[]] {
  return errorPartition(error, arrayDefined(items));
}