import { ObjectKeys } from "./object";

/**
 * Utility functions for getting levenschtein distance of strings.
 * Source: https://github.com/ka-weihe/fastest-levenshtein
 */

/** Result from searching a list. */
export interface SearchDistance<T = string> {
  distance: number,
  value: T
};

/** Get closest strings in list to query, or undefined if array is empty. */
export function closestStrings(query: string, array: readonly string[]) {
  if (!array.length) return [];

  let results: SearchDistance[] = array.map(value => ({ distance: distance(query, value), value }));
  return results.sort((a, b) => a.distance - b.distance);
};

/** Get closest string in list to query, or undefined if array is empty. */
export function closestString(query: string, array: readonly string[]) {
  return closestStrings(query, array)[0];
};

/** Get list of closest objects to query, sorted by levenshtein distance. */
export function closestObjects<T>(query: string, array: readonly T[], key: ObjectKeys<T, string>) {
  if (!array.length) return [];

  let results: SearchDistance<T>[] = array.map(value => ({ distance: distance(query, (<any>value)[key]), value }));
  return results.sort((a, b) => a.distance - b.distance);
}

/** Get closest object in list to query, or undefined if array is empty. */
export function closestObject<T>(query: string, array: readonly T[], key: ObjectKeys<T, string>) {
  return closestObjects(query, array, key)[0];
}

const PEQ = new Uint32Array(0x10000);

function myers32(a: string, b: string) {
  const n = a.length;
  const m = b.length;
  const lst = 1 << (n - 1);
  let pv = -1;
  let mv = 0;
  let sc = n;
  let i = n;
  while (i--) {
    PEQ[a.charCodeAt(i)] |= 1 << i;
  }
  for (i = 0; i < m; i++) {
    let eq = PEQ[b.charCodeAt(i)]!;
    const xv = eq | mv;
    eq |= ((eq & pv) + pv) ^ pv;
    mv |= ~(eq | pv);
    pv &= eq;
    if (mv & lst) {
      sc++;
    }
    if (pv & lst) {
      sc--;
    }
    mv = (mv << 1) | 1;
    pv = (pv << 1) | ~(xv | mv);
    mv &= xv;
  }
  i = n;
  while (i--) {
    PEQ[a.charCodeAt(i)] = 0;
  }
  return sc;
};

function myersX(b: string, a: string) {
  const n = a.length;
  const m = b.length;
  const mhc: number[] = [];
  const phc: number[] = [];
  const hsize = Math.ceil(n / 32);
  const vsize = Math.ceil(m / 32);
  for (let i = 0; i < hsize; i++) {
    phc[i] = -1;
    mhc[i] = 0;
  }
  let j = 0;
  for (; j < vsize - 1; j++) {
    let mv = 0;
    let pv = -1;
    const start = j * 32;
    const vlen = Math.min(32, m) + start;
    for (let k = start; k < vlen; k++) {
      PEQ[b.charCodeAt(k)] |= 1 << k;
    }
    for (let i = 0; i < n; i++) {
      const eq = PEQ[a.charCodeAt(i)]!;
      const pb = (phc[(i / 32) | 0]! >>> i % 32) & 1;
      const mb = (mhc[(i / 32) | 0]! >>> i % 32) & 1;
      const xv = eq | mv;
      const xh = ((((eq | mb) & pv) + pv) ^ pv) | eq | mb;
      let ph = mv | ~(xh | pv);
      let mh = pv & xh;
      if ((ph >>> 31) ^ pb) {
        phc[(i / 32) | 0] ^= 1 << i % 32;
      }
      if ((mh >>> 31) ^ mb) {
        mhc[(i / 32) | 0] ^= 1 << i % 32;
      }
      ph = (ph << 1) | pb;
      mh = (mh << 1) | mb;
      pv = mh | ~(xv | ph);
      mv = ph & xv;
    }
    for (let k = start; k < vlen; k++) {
      PEQ[b.charCodeAt(k)] = 0;
    }
  }
  let mv = 0;
  let pv = -1;
  const start = j * 32;
  const vlen = Math.min(32, m - start) + start;
  for (let k = start; k < vlen; k++) {
    PEQ[b.charCodeAt(k)] |= 1 << k;
  }
  let score = m;
  for (let i = 0; i < n; i++) {
    const eq = PEQ[a.charCodeAt(i)]!;
    const pb = (phc[(i / 32) | 0]! >>> i % 32) & 1;
    const mb = (mhc[(i / 32) | 0]! >>> i % 32) & 1;
    const xv = eq | mv;
    const xh = ((((eq | mb) & pv) + pv) ^ pv) | eq | mb;
    let ph = mv | ~(xh | pv);
    let mh = pv & xh;
    score += (ph >>> ((m % 32) - 1)) & 1;
    score -= (mh >>> ((m % 32) - 1)) & 1;
    if ((ph >>> 31) ^ pb) {
      phc[(i / 32) | 0] ^= 1 << i % 32;
    }
    if ((mh >>> 31) ^ mb) {
      mhc[(i / 32) | 0] ^= 1 << i % 32;
    }
    ph = (ph << 1) | pb;
    mh = (mh << 1) | mb;
    pv = mh | ~(xv | ph);
    mv = ph & xv;
  }
  for (let k = start; k < vlen; k++) {
    PEQ[b.charCodeAt(k)] = 0;
  }
  return score;
}

function distance(a: string, b: string): number {
  if (a.length < b.length) {
    const tmp = b;
    b = a;
    a = tmp;
  }
  if (b.length === 0) return a.length;
  if (a.length <= 32) return myers32(a, b);
  return myersX(a, b);
};