<app-card>
  <div class="sidebar">
    <app-card-panel *ngIf="icon">
      <div class="row align-center">
        <button icon-button color="red" disabled>{{icon.icon}}</button>
        <h1 percentage class="row">{{increase}}</h1>
      </div>
      <app-label>Over Prior Year</app-label>
    </app-card-panel>
    <app-card-panel *ngFor="let period of _periods" class="period">
      <h1>{{period.total | cents}}</h1>
      <app-label><app-dot color="red"></app-dot>{{ period.year }}</app-label>
    </app-card-panel>
    <div class="fill"></div>
    <app-card-panel lighten color="red" class="amount">
      <h1>{{balance | cents}}</h1>
      <app-label multiline color="red">Total Charge-off Balance</app-label>
    </app-card-panel>
  </div>
  <app-card-body class="column fill">
    <app-label>Charge-Off Amount</app-label>
    <div class="fill container">
      <div class="chart">
        <img src="assets/img/executive-chart.png" alt="Executive Chart">
      </div>
    </div>
  </app-card-body>
</app-card>