<app-card class="elevation-dialog" *ngIf="type">
  <app-card-header>
    <div class="row">
      <app-icon color="green">done</app-icon>
      <h1>Unsubscribed</h1>
      <app-icon></app-icon>
    </div>
  </app-card-header>
  <app-card-body class="column">
    <div class="column">
      <div>You have been successfully unsubscribed.</div>
      <div>You will no longer receive notifications for this system event: <b>{{type === undefined ? 'Unknown' : type | enum:JOB_TYPE_NAME}}</b></div>
    </div>
    <div class="button-row">
      <button color="primary" [routerLink]="['/']">Return to Site</button>
    </div>
  </app-card-body>
</app-card>