import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorResponse } from "../../../../../common/message/error";
import { Event } from "../../../../../common/model/event";
import { EventCategory } from '../../../../../common/model/event/category';
import { PersonalResultType } from '../../../../../common/model/event/result/personal';
import { idNull } from "../../../../../common/toolbox/id";
import { errorResponse } from '../../../../../common/toolbox/message';
import { dateUntil } from '../../../../../common/toolbox/time';
import { DialogService } from '../component/dialog/dialog.service';
import { eventReminder } from '../toolbox/event';
import { Reminder } from '../toolbox/reminder';
import { EventService } from './event.service';
import { LogService } from './log.service';

/** Stores and executes queued reminders. */
@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  /** Emits when list of reminders change. */
  updates = new BehaviorSubject<Reminder[]>([]);

  /** List of queued reminders. */
  private list: Reminder[] = [];
  /** Mapping of event IDs to reminders. */
  private map = new Map<string, Reminder>();

  constructor(
    private events: EventService,
    private dialog: DialogService,
    private log: LogService
  ) {}

  /** Add reminders to queue. */
  add(events: Event[]) {
    let length = this.list.length;
    for (let event of events) {
      if (!eventReminder(event) || this.map.has(event._id)) continue;
      if (idNull(event._id)) {
        this.log.show(new ErrorResponse(`Invalid reminder ID: ${event._id}`, undefined, event));
        continue;
      }
  
      this.list.push(event);
      this.map.set(event._id, event);
      let result = event.result;
      switch (result.category) {
      case EventCategory.Personal:
        switch (result.type) {
        case PersonalResultType.Reminder:
          this.queue(event, true, () => {
            this.dialog.confirm(
              event.description || 'Your personal reminder is due.',
              event.title || 'Personal Reminder', { yes: 'Okay', no: '' });
          }); continue;
        }
      }

      this.log.show(new ErrorResponse(`Unrecognized reminder category-type: ${event.result.category}:${event.result.type}`, undefined, event));
    }

    if (this.list.length === length) return;
    this.updates.next(this.list);
  }

  /** Mark reminders as completed and remove from activity panel. */
  async complete(reminders: Reminder[]) {
    let result = await this.events.complete(reminders);
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }

    for (let reminder of reminders) {
      let index = this.list.indexOf(reminder);
      if (index === -1) {
        this.log.show(new ErrorResponse(`Reminder was not queued: ${reminder._id}`, undefined, reminder));
        return;
      }

      this.list.splice(index, 1);
      if (reminder.timeout === undefined) return;
      clearTimeout(reminder.timeout);
      reminder.timeout = undefined;
    }
  }

  /** Queue up a reminder with given callback. */
  private queue(reminder: Reminder, autocomplete: boolean, callback: Function) {
    if (reminder.result.due) reminder.timeout = setTimeout(async () => {
      if (autocomplete) this.complete([reminder]);
      callback();
    }, dateUntil(reminder.result.due)) as unknown as number;
  }
}
