import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';
import { Formula } from '../../../../../../common/model/formula/formula';
import { formulaRun } from '../../../../../../common/toolbox/formula/formula';
import { stringCoerce } from "../../../../../../common/toolbox/string";
import { fieldControlProviders } from '../../toolbox/angular';
import { debugElementMake } from '../../toolbox/element/debug';
import { FieldControl } from '../field/field-control';

/** Types of string inputs. */
export type StringComponentType = 'password' | null;

@Component({
  selector: 'app-string',
  templateUrl: './string.component.html',
  styleUrls: ['./string.component.scss'],
  providers: fieldControlProviders(StringComponent),
  host: {
    '[class.large]': 'large'
  }
})
export class StringComponent extends FieldControl implements ControlValueAccessor {
  /** Reference to inputs. */
  @ViewChildren('input', { read: ElementRef }) inputs?: QueryList<ElementRef<HTMLInputElement>>;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  
  /** Type of input field. */
  @Input() type: StringComponentType = null;
  /** Placeholder for input field. */
  @Input() placeholder: string | null = '';
  /** True to make a value required. */
  @Input() required = true;
  /** Maximum length for string. */
  @Input() maxLength = Number.MAX_SAFE_INTEGER;
  /** Minimum length for string. */
  @Input() minLength = 0;
  /** Pattern that string must adhere to. */
  @Input() pattern?: RegExp;
  /** True to put string in large mode. */
  @Input() large = false;
  /** Validator formula, if applicable */
  @Input() validator?: Formula;

  /** Emits when the field is typed into. */
  @Output() input = new EventEmitter<string | undefined>();
  /** Emits when field loses focus. */
  @Output() blur = new EventEmitter<void>();

  /** Current input value. */
  value?: string;

  constructor(
    public elementRef: ElementRef
  ) {
    super();
    debugElementMake(this);
  }

  ngAfterViewInit() {
    // Automatically unsubscribed on component destruction.
    this.inputs?.changes.subscribe(() => this.revalue(this.value));
  }

  writeValue(value?: string) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.value = this.coerce(value);
    this.revalue(value);
  }

  override validate(control: AbstractControl) {
    if (this.value === undefined) {
      this.reerror();
    } else {
      this.reerror(
        [`Must be at least ${this.minLength} characters long.`, this.value.length < this.minLength],
        [`Must be at most ${this.maxLength} characters long.`, this.value.length > this.maxLength],
        [`Does not match custom pattern: ${this.pattern}.`, this.pattern !== undefined && !this.pattern.test(this.value)],
        [`Does not satisfy formula: ${this.validator?.name}.`, this.validator ? !!formulaRun(this.validator, this.value) : false]
      );
    }

    return super.validate(control);
  }

  /** Callback when typing into field. */
  onInput($event: Event) {
    let input = $event.target as HTMLInputElement;
    this.changed(this.value = this.coerce(input.value));
    this.input.next(this.value);
    this.setTouched();
    this.setDirty();
  }

  /** Callback when clearing field. */
  onClear() {
    this.writeValue(this.value = undefined);
    this.changed(undefined);
    this.setDirty();
  }

  /** Set value of inputs */
  private revalue(value = '') {
    this.inputs?.forEach(input => input.nativeElement.value = value);
  }

  /** Coerce string value. */
  private coerce(value: any) {
    return stringCoerce(value, this.required, this.maxLength);
  }
}