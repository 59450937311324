import { TypeValidation } from "../info/type";
import { ValidationOptions, ValidationStatus } from "./base";
import { MultiValidator } from "./multi";

/** A custom validator accepting a callback. */
export abstract class CustomValidator<T> extends MultiValidator<T> {

  /** Check a value for validity. */
  abstract check(value: T): TypeValidation<T>;

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    return this.push(this.check(value));
  }
}