<div class="labels">
  <app-label>Number</app-label>
  <app-label>Extension</app-label>
  <app-label>Type</app-label>
  <app-label>Name</app-label>
</div>
<div class="list" [list-grid]="value">
  <app-drag-chip cdkDrag cdkDragBoundary=".list" *ngFor="let p of value; let i = index" [borderless]="true">
    <app-field [readonly]="p.system">
      <app-string [(ngModel)]="p.number" [readonly]="p.system" [dirty]="dirty" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-string>
    </app-field>
    <app-field [readonly]="p.system">
      <app-number [(ngModel)]="p.ext" [required]="false" [readonly]="p.system" [dirty]="dirty" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-number>
    </app-field>
    <app-field [readonly]="p.system">
      <app-code [(ngModel)]="p.type" [category]="ImportCode.Phone" [readonly]="p.system" [dirty]="dirty" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-code>
    </app-field>
    <app-field [readonly]="p.system">
      <app-string [(ngModel)]="p.name" [required]="false" [readonly]="p.system" [dirty]="dirty" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-string>
    </app-field>
    <div class="buttons">
      <button *ngIf="!p.system" icon-button (click)="onDelete(i)" tooltip="Delete">delete</button>
      <button icon-button cdkDragHandle>drag_indicator</button>
    </div>
  </app-drag-chip>
  <button crystal button-prefix="add" position="before" (click)="onNew()">New Phone</button>
</div>