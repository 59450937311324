import { SystemCode } from "../code/system";
import { propinfoProperty } from "../info/prop";
import { ErrorResponse } from "../message/error";
import { CodeEnum } from "../model/code-type";
import { Display, DisplayValue } from "../model/display";
import { Engine } from "../model/formula/engine";
import { Formula } from "../model/formula/formula";
import { Property } from "../model/property";
import { PropertyType } from "../model/property-type";
import { booleanCoerce } from "./boolean";
import { csvEscape } from "./csv";
import { currencyFormat } from "./currency";
import { formulaReturns, formulaRun } from "./formula/formula";
import { NestedKey, keyFlatGet } from "./keys";
import { Pair } from "./object";
import { dateFormat, dateNull } from "./time";

/** A general handler for formatting properties into display strings. */
export abstract class PropertyEngine {

  /** Format a single value of a row. */
  async value<T>(_inst: string, stripped: T, typed: T, key: NestedKey<T>, enums?: CodeEnum): Promise<string> {
    let property: Property | undefined;
    let data: unknown;

    let _id = Display.formula(key);
    if (_id) {
      // Run formula and check return type.
      let formula = await this.formula(_inst, _id);
      Engine.enums = enums ?? Engine.enums;
      data = formulaRun(formula, stripped);
      property = this.returns(formula);
    } else {
      // Try getting property anyways in case it's a custom property that isn't in a standard model.
      property = propinfoProperty(key, typed);
      data = keyFlatGet(key, stripped);
    }

    if (!property) {
      if (data === undefined) return `{{${String(key)}}}`;
      return data instanceof Date ? this.date(data) : `${data}`;
    }

    // Determine how to format this output.
    switch (property.type) {
      case PropertyType.Boolean:
        data = booleanCoerce(data, true);
        return (data ? property.on : property.off) ?? `${data}`;
      case PropertyType.Code:
        return typeof data === 'string' ? this.code(_inst, property.category, data) : '';
      case PropertyType.Currency:
        return currencyFormat(data);
      case PropertyType.Date:
        return this.date(data);
      case PropertyType.Member:
        return typeof data === 'string' ? this.member(_inst, data) : '';
      case PropertyType.User:
        return typeof data === 'string' ? this.user(_inst, data) : '';
      case PropertyType.Email:
      case PropertyType.Number:
      case PropertyType.Phone:
      case PropertyType.String:
      case PropertyType.Transaction:
        return `${data}`;
    }
  }

  /** Format a list of values to CSV. */
  async rows<T>(_inst: string, stripped: T[], typed: T, pairs: Pair<NestedKey<T>>[], enums?: CodeEnum): Promise<string | ErrorResponse> {
    let rows = await Promise.all(stripped.map(async row =>
      await Promise.all(pairs.map(async pair =>
        csvEscape(await this.value(_inst, row, typed, pair.value, enums))))
    ));

    let headers = pairs.map(pair => pair.view);
    return [headers, ...rows.map(row => row.join(','))].join('\r\n');
  }

  /** Get name of a particular member. */
  protected abstract member(_inst: string, _id: string): Promise<string>;
  /** Get name of a particular user. */
  protected abstract user(_inst: string, _id: string): Promise<string>;
  /** Get specified formula and return type. */
  protected abstract formula(_inst: string, _id: string): Promise<Formula>;
  /** Map a code value to a display string. */
  protected abstract code(_inst: string, category: SystemCode, value: string): Promise<string>;

  /** Get return type of a formula. */
  protected returns(formula: Formula) {
    return formulaReturns(formula, new DisplayValue());
  }

  /** Format a date for display. */
  protected date(value: unknown, format?: string) {
    let date = new Date(0);
    switch (typeof value) {
      case 'number':
      case 'string':
        date = new Date(value);
        break;
      case 'object':
        if (value instanceof Date) date = value;
    }

    // Return empty string if this is an invalid date or epoch.
    return dateNull(date) ? '' : dateFormat(date, format) ?? '';
  }
}