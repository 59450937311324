import { Component, Input, ViewChild } from "@angular/core";
import { ClaimJoin } from "../../../../../../common/model/claim/claim";
import { claimCard, claimUnion } from "../../../../../../common/toolbox/claim";
import { FormComponent } from "../../../common/component/form/form.component";
import { FormConfig, FormListConfig } from '../../../common/component/form/form.model';
import { FormListService } from "../../../common/service/form-list.service";
import { FormService } from '../../../common/service/form.service';

@Component({
  selector: 'app-claim-info',
  templateUrl: './claim-info.component.html',
  styleUrls: ['./claim-info.component.scss']
})
export class ClaimInfoComponent {
  @ViewChild(FormComponent) form?: FormComponent;

  /** Claim currently being viewed. */
  @Input() set claim(claim: ClaimJoin) {
    this.refresh(claim);
  }

  /** Configuration for claim info form. */
  config?: FormConfig;
  /** Configuration for advanced claim info form. */
  advancedConfig?: FormListConfig;

  constructor(
    private forms: FormService,
    private formLists: FormListService
  ) {}

  /** Refresh after new claim is bound. */
  private async refresh(claim: ClaimJoin) {
    this.config = {
      mutate: true,
      form: await this.forms.setting(`disputes.${claim.type}.claim.form`),
      value: { claim: claimUnion(claim) }
    };
    
    this.advancedConfig = {
      list: await this.formLists.setting(`disputes.${claim.type}.claim.formList`),
      value: {
        claim: claimUnion(claim),
        card: claimCard(claim),
        member: claim.member
      }
    };
  }
}
