import { UnionInfo } from "../../../info/union";
import { EventCategory } from "../category";
import { EventReminder } from "../reminder";

/** Tagged type of personal event. */
export enum PersonalResultType {
  Reminder
}

/** Result payload of performing a personal event. */
export type PersonalResult = PersonalReminderResult;

/** Result of establishing a personal reminder. */
export class PersonalReminderResult extends EventReminder {
  readonly category = EventCategory.Personal;
  readonly type = PersonalResultType.Reminder;
}

export class PersonalResultClass {
  [PersonalResultType.Reminder] = new PersonalReminderResult();
}

/** Union type information for personal results. */
export const PERSONAL_RESULT_UNIONINFO: UnionInfo<PersonalResult, PersonalResultType> = {
  tag: 'type',
  classes: new PersonalResultClass()
}