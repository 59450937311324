import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Direction } from "../../../../../../common/model/direction";
import { Icon } from '../../../../../../common/model/icon';
import { Permission } from '../../../../../../common/model/permission';
import { WorkFilter } from "../../../../../../common/model/work/filters";
import { WorkItem, WorkStage } from "../../../../../../common/model/work/item";
import { WorkQueue } from "../../../../../../common/model/work/queue";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { LogService } from '../../../common/service/log.service';
import { WorkQueueService } from '../../../common/service/work-queue.service';
import { getRequest, patchRequest } from '../../../common/toolbox/request';
import { WORK_FILTERS, WORK_STAGE_ICON, WorkBarData } from './work-bar.model';

@Component({
  selector: 'app-work-bar',
  templateUrl: './work-bar.component.html',
  styleUrls: ['./work-bar.component.scss']
})
export class WorkBarComponent {
  readonly Permission = Permission;
  
  /** Possible statuses for work items. */
  readonly WorkStage = WorkStage;
  /** List of search category items. */
  readonly filters = WORK_FILTERS;

  /** Set current displayed data in work bar. */
  @Input() set data(data: WorkBarData) {
    this.refresh(data.queue, data.cursor);
  }

  /** Current selected filter. */
  @Input() filter = WorkFilter.Unworked;
  /** Emits when current filter should be changed. */
  @Output() filterChange = new EventEmitter<WorkFilter>();

  /** Emits when current position within queue changes. */
  @Output() cursor = new EventEmitter<WorkItem>();
  /** Emits when queue name is clicked. */
  @Output() toggle = new EventEmitter<void>();

  /** Information about current queue. */
  queue = new WorkQueue();
  /** Current position in queue. */
  item = new WorkItem();
  /** Tooltip for status. */
  tooltip = '';
  /** Icon for status. */
  icon?: Icon;

  /** True to enable next button. */
  next = false;
  /** True to enable previous button. */
  prev = false;

  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    private log: LogService,
    private http: HttpClient,
    private queues: WorkQueueService
  ) { }

  async ngOnInit() {
    this.queues.updated.pipe(takeUntil(this.destroy)).subscribe(() => this.refetch());
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Rewind to first or last item in work queue. */
  onSeek(direction: Direction) {
    let item = WorkItem.cursor(this.queue._id, undefined, direction);
    this.onProgress(direction, item);
  }

  onFilter(){
    this.filterChange.emit(this.filter);
    this.onSeek(+1);
  }

  /** Progress to specified item in work queue. */
  async onProgress(direction: Direction = 1, item = this.item, worked?: boolean) {
    // Pop next item in queue.
    let next = await patchRequest(this.http, 'work-items/next', {
      _inst: this.queue._inst,
      filter: this.filter,
      cursor: item,
      direction,
      worked
    });
    if (errorResponse(next)) {
      this.log.show(next);
      return;
    }
    this.refresh(next.queue, next.cursor)
  };

  /** Bookmark current displayed work item. */
  async onBookmark() {
    let result = await patchRequest(this.http, 'work-items', { _id: this.item._id, bookmark: !this.item.bookmark });
    if (errorResponse(result)) {
      this.log.show(result);
      return;
    }

    this.item.bookmark = !this.item.bookmark;
  }

  /** Refresh view with given queue and cursor. */
  private async refresh(queue: WorkQueue, cursor?: WorkItem): Promise<void> {
    if (!cursor) {
      setTimeout(() => this.log.show('No more items available for selected filter.'), StatusLevel.Alert);
      return;
    }

    // Determine next and previous validity.
    this.queue = queue;
    this.next = cursor.index < (this.queue.count ?? 0) - 1;
    this.prev = cursor.index > 0;

    // Get next item to display.
    this.item = WorkItem.valid(cursor) ? cursor : this.item;
    this.icon = WORK_STAGE_ICON[this.item.stage];
    this.cursor.next(cursor);
  }
  
  private async refetch(): Promise<void> {
    let item = this.item;
    if (!item) return;

    // Refresh item if it found one.
    let newItem = await getRequest(this.http,'work-items', { _id: item._id });
    if (errorResponse(newItem)) {
      this.log.show(newItem);
      return;
    }

    if (!newItem) return;
    this.refresh(this.queue, newItem);
  }
}
