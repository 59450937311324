import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PAGINATOR_ALL } from '../../../toolbox/paginator';
import { ClientSource } from '../../../toolbox/source/client';
import { GridSource } from '../../../toolbox/source/grid';

@Component({
  selector: 'app-grid-paginator',
  templateUrl: './grid-paginator.component.html',
  styleUrls: ['./grid-paginator.component.scss']
})
export class GridPaginatorComponent<T> {
  readonly PAGINATOR_ALL = PAGINATOR_ALL;
  
  /** Grid data source linked to paginator. */
  @Input() source: GridSource<T> = new ClientSource<T>();
  /** True to force display of refresh. */
  @Input() refresh = true;
  /** True to show download button. */
  @Input() downloadable = false;

  /** Emits when download button is clicked. */
  @Output() download = new EventEmitter();
}
