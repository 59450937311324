import { Component } from '@angular/core';
import { DisputesCode } from '../../../../../../../../common/code/system';
import { WORK_CONDITION_MODE_NAME, WorkConditionDisputeStatus } from '../../../../../../../../common/model/work/condition';
import { enumMapPairs } from '../../../../../../../../common/toolbox/enum';
import { WorkConditionAccessor } from '../setup-workflow-condition.model';

@Component({
  selector: 'app-setup-workflow-condition-dispute-status',
  templateUrl: './setup-workflow-condition-dispute-status.component.html',
  styleUrls: ['./setup-workflow-condition-dispute-status.component.scss'],
})
export class SetupWorkflowConditionDisputeStatusComponent extends WorkConditionAccessor<WorkConditionDisputeStatus> {
  readonly DisputesCode = DisputesCode;
  
  /** List of modes to select. */
  modes = enumMapPairs(WORK_CONDITION_MODE_NAME);
}
