import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Component, HostListener } from "@angular/core";
import { Icon } from "../../../../../../common/model/icon";
import { HighlightComponent } from "../../../common/component/highlight/highlight.component";

/** A button that can initialize highlighting elements. */
@Component({ template: '' })
export abstract class HighlightButtonComponent<E extends HTMLElement = HTMLElement> {

  /** Icon to display inside button. */
  protected abstract readonly icon: Icon;
  /** Tooltip to display for button. */
  protected abstract readonly tooltip: string;

  /** Callback when pointer hovers. */
  protected pointermove?: ($event: PointerEvent) => void;
  /** Callback when pointer clicked. */
  protected pointerdown?: ($event: PointerEvent) => void;

  /** Current element being hovered over. */
  private element?: E;
  /** Opened overlay containing menu. */
  private overlayRef?: OverlayRef;

  constructor(
    private overlay: Overlay
  ) {}

  /** Callback to open overlay. */
  @HostListener('pointerup', ['$event'])
  onOpen($event: PointerEvent) {
    // Clear any existing overlays.
    this.unbind($event);

    // Open overlay to start highlighting elements.
    this.overlayRef = this.createOverlay();
    const componentPortal = new ComponentPortal(HighlightComponent);
    this.overlayRef.attach(componentPortal);

    // Start capturing pointer activity.
    this.pointerdown = this.onPointerDown.bind(this);
    this.pointermove = this.onPointerMove.bind(this);
    document.body.setPointerCapture($event.pointerId);
    document.body.addEventListener('pointerdown', this.pointerdown);
    document.body.addEventListener('pointermove', this.pointermove);
  }

  /** Find a valid element to select under pointer. */
  protected abstract find(element: EventTarget | null): E | undefined;
  /** Triggered when a valid element is found. */
  protected abstract trigger(element: E): void;

  /** Callback when pointer moved. */
  private onPointerMove($event: PointerEvent) {
    let element = this.find($event.target);
    if (element === this.element) return;

    if (this.element = element) {
      // Match size to new element.
      this.overlayRef?.updateSize({
        minWidth: element.offsetWidth,
        minHeight: element.offsetHeight
      });

      // Attach highlight to new element.
      this.overlayRef?.updatePositionStrategy(this.overlay.position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions()
      .withLockedPosition()
      .withPush(false)
      .withPositions([
        { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }
      ]));
    } else {
      // Hide highlight element.
      this.overlayRef?.updateSize({ minWidth: 0, minHeight: 0 });
      this.overlayRef?.updatePositionStrategy(new GlobalPositionStrategy().centerHorizontally().centerVertically());
    }
  }

  /** Callback when pointer clicked on element. */
  private onPointerDown($event: PointerEvent) {
    this.unbind($event);
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }

    let element = this.find($event.target);
    if (!element) return;
    this.trigger(element);
  }

  /** Unbind current callbacks. */
  private unbind($event: PointerEvent) {
    if (!this.pointerdown || !this.pointermove) return;
    document.body.releasePointerCapture($event.pointerId);
    document.body.removeEventListener('pointerdown', this.pointerdown);
    document.body.removeEventListener('pointermove', this.pointermove);
    this.pointerdown = undefined;
    this.pointermove = undefined;
  }

  /** Creates overlay configuration or reuses existing one. */
  private createOverlay(): OverlayRef {
    if (this.overlayRef) return this.overlayRef;

    // Create an initially hidden overlay.
    return this.overlay.create(new OverlayConfig({
      panelClass: ['highlight-panel'],
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: new GlobalPositionStrategy().centerHorizontally().centerVertically()
    }));
  }
}