import { Box } from "../../box";
import { DisplayType } from "../../display";
import { Pos } from "../../pos";
import { BuiltinForm } from "../form";
import { CollectionsModel, ModelCollectionsInboundContact, ModelCollectionsOutboundContact, ModelCollectionsPayment, ModelCollectionsPromise } from "../model/collections";
import { DisputesModel, ModelClaimNoticeDate } from "../model/disputes";
import { ModelStandardFollowUp, StandardModel } from "../model/standard";
import { StandardTable } from "../table";
import { builtinFormField, builtinFormModel } from "./form";

/** Built-in general forms. */
export enum ModelForm {
  Email        = 'Model - Emails',
  FollowUp     = 'Model - Follow Up',
  InboundCall  = 'Model - Inbound Call',
  NoticeDate   = 'Model - Notice Date',
  OutboundCall = 'Model - Outbound Call',
  Payment      = 'Model - Pay By Phone',
  Promise      = 'Model - Promise To Pay',
  Reminder     = 'Model - Reminder',
  Text         = 'Model - Texts'
}

/** List of builtin forms. */
export const BUILTIN_MODEL_FORMS: Record<ModelForm, BuiltinForm> = {
  [ModelForm.Email]: {
    model: CollectionsModel.OutboundContact,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelCollectionsOutboundContact>('_member', { box: new Box(0, 0, 0, 0) }),
      builtinFormModel<ModelCollectionsOutboundContact>('email', { box: new Box(1, 0, 1, 0) }),
      builtinFormModel<ModelCollectionsOutboundContact>('comment', { box: new Box(0, 1, 2, 2) }),
    ]
  },
  [ModelForm.FollowUp]: {
    model: StandardModel.FollowUp,
    editable: [DisplayType.Event, DisplayType.Model],
    fields: [
      builtinFormField('event.result._assigned', { box: new Box(0, 0, 0, 0) }),
      builtinFormField('event.result.due', { box: new Box(1, 0, 1, 0) }),
      builtinFormField('event.result._completed', { box: new Box(2, 0, 2, 0) }),
      builtinFormField('event.result.done', { box: new Box(0, 1, 0, 1) }),
      builtinFormModel<ModelStandardFollowUp>('response', { box: new Box(1, 1, 2, 1) }),
      builtinFormModel<ModelStandardFollowUp>('comment', { box: new Box(0, 2, 2, 3) })
    ]
  },
  [ModelForm.InboundCall]: {
    size: new Pos(2, 5),
    model: CollectionsModel.InboundContact,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelCollectionsInboundContact>('_member', { box: new Box(0, 0, 0, 2) }),
      builtinFormModel<ModelCollectionsInboundContact>('phone', { box: new Box(1, 0, 1, 2), _table: StandardTable.FieldPhone }),
      builtinFormModel<ModelCollectionsInboundContact>('response', { box: new Box(1, 3, 1, 3) }),
      builtinFormModel<ModelCollectionsInboundContact>('comment', { box: new Box(0, 3, 0, 4) })
    ]
  },
  [ModelForm.NoticeDate]: {
    editable: [DisplayType.Model],
    model: DisputesModel.ClaimNoticeDate,
    fields: [
      builtinFormModel<ModelClaimNoticeDate>('date')
    ]
  },
  [ModelForm.OutboundCall]: {
    model: CollectionsModel.OutboundContact,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelCollectionsOutboundContact>('_member', { box: new Box(0, 0, 0, 0) }),
      builtinFormModel<ModelCollectionsOutboundContact>('phone', { box: new Box(1, 0, 1, 0), _table: StandardTable.FieldPhone }),
      builtinFormModel<ModelCollectionsOutboundContact>('response', { box: new Box(2, 0, 2, 0) }),
      builtinFormModel<ModelCollectionsOutboundContact>('comment', { box: new Box(0, 1, 2, 2) })
    ]
  },
  [ModelForm.Payment]: {
    model: CollectionsModel.Payment,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelCollectionsPayment>('_member', { box: new Box(0, 0, 0, 0) }),
      builtinFormModel<ModelCollectionsPayment>('amount', { box: new Box(1, 0, 1, 0) }),
      builtinFormModel<ModelCollectionsPayment>('comment', { box: new Box(0, 1, 1, 2) })
    ]
  },
  [ModelForm.Promise]: {
    model: CollectionsModel.Promise,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelCollectionsPromise>('_member', { box: new Box(0, 0, 0, 0) }),
      builtinFormModel<ModelCollectionsPromise>('amount', { box: new Box(1, 0, 1, 0) }),
      builtinFormModel<ModelCollectionsPromise>('deadline', { box: new Box(2, 0, 2, 0) }),
      builtinFormField('event.result._assigned', { box: new Box(0, 1, 0, 1) }),
      builtinFormField('event.result.due', { box: new Box(1, 1, 1, 1) }),
      builtinFormModel<ModelCollectionsPromise>('response', { box: new Box(2, 1, 2, 1) }),
      builtinFormModel<ModelCollectionsPromise>('comment', { box: new Box(0, 2, 2, 3) })
    ]
  },
  [ModelForm.Reminder]: {
    model: StandardModel.FollowUp,
    editable: [DisplayType.Model],
    fields: [
      builtinFormField('event.result._assigned', { box: new Box(0, 0, 0, 0) }),
      builtinFormField('event.result.due', { box: new Box(1, 0, 1, 0) }),
      builtinFormField('event.result._completed', { box: new Box(2, 0, 2, 0) }),
      builtinFormField('event.result.done', { box: new Box(0, 1, 0, 1) }),
      builtinFormModel<ModelStandardFollowUp>('response', { box: new Box(1, 1, 2, 1) }),
      builtinFormModel<ModelStandardFollowUp>('comment', { box: new Box(0, 2, 2, 3) })
    ]
  },
  [ModelForm.Text]: {
    model: CollectionsModel.OutboundContact,
    editable: [DisplayType.Model],
    fields: [
      builtinFormModel<ModelCollectionsOutboundContact>('_member', { box: new Box(0, 0, 0, 0) }),
      builtinFormModel<ModelCollectionsOutboundContact>('phone', { box: new Box(1, 0, 1, 0), _table: StandardTable.FieldPhone }),
      builtinFormModel<ModelCollectionsOutboundContact>('comment', { box: new Box(0, 1, 2, 2) })
    ]
  }
};