import { ErrorResponse } from "../message/error";
import { SchemaProperty } from "../model/schema";
import { Newable } from "../toolbox/object";

/** Result of performing a validation. */
export enum ValidationStatus {
  /** No errors. */
  Okay,
  /** Validated with errors. */
  Error
}

/** Additional options for performing a validation. */
export class ValidationOptions {
  constructor(
    /** List of validator types to relax. */
    public relax?: Set<Newable<Validator>>
  ) {}
}

/** Enum context for validator. */
export class ValidatorContext {
  constructor(
    /** List of available enums. */
    public enums = new Map<string, Set<string>>()
  ) { }
}

/** A validation log to bubble up to the top level. */
export class ValidatorLog {
  constructor(
    /** Specific message of log. */
    public message = '',
    /** Property chain of log. */
    public context: string[] = [],
    /** List of sublogs. */
    public logs?: ValidatorLog[]
  ) { }

  toString() {
    return ErrorResponse.flatten(this.format());
  }

  /** Push a new property onto context chain. */
  unshift(key: string) {
    this.context.unshift(key);
  }

  /** Format out message chain. */
  format(): ErrorResponse {
    let message = `${this.context.length ? this.context.join('') + ': ' : ''}${this.message}`;
    return new ErrorResponse(message.startsWith('.') ? message.slice(1) : message, this.logs ? this.logs.map(sublog => sublog.format()) : undefined);
  }
}

/** Validator for a given type. */
export abstract class Validator<T = any> {

  constructor(
    /** True if this property is optional. */
    public optional = false,
    /** Manual override for schema info. */
    public property?: SchemaProperty
  ) {}

  /** Get a simple value from type. */
  abstract value(): T;
  /** Coerce string into required type. */
  abstract parse(text: string): T | undefined;
  /** Get database schema for specified type. */
  abstract schema(): SchemaProperty | undefined;

  /** Coerce string into required type for subobject. */
  subparse(_text: string, _key?: string): T | undefined {
    return this.parse(_text);
  }

  /** Parse querystring into object. */
  query(_: any) { }

  /** Get status of validator. */
  validate(_: any, _options?: ValidationOptions): ValidationStatus {
    return ValidationStatus.Okay;
  }

  /** Get any logs of validator. */
  logs(): ValidatorLog[] {
    return [];
  }

  /** Get all logs in an error response. */
  error(value: any, error = 'Object failed validation.'): ErrorResponse {
    let size = JSON.stringify(value).length;
    if (size > 4096) {
      value = `Too large for preview (${size / 1024 | 0}kb)`
    }
    return new ErrorResponse(error, this.logs().slice(0, 10).map(l => l.format()), value);
  }

  /** Check optional rules for object. */
  protected undefined(value: any) { return this.optional && value === undefined; }

  /**
   *  Evaluate implicit validation rules that apply to all objects.
   *  @returns A truthy value if object passes validation.
   */
  protected implicit(value: any, options?: ValidationOptions): any {
    return this.optional && value === undefined || options?.relax?.has(this.constructor as any);
  }
}