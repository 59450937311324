import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { CommentPatch } from '../../../../../../common/message/comment';
import { CollectionComments } from '../../../../../../common/model/collection';
import { CommentAction, CommentActionAdd, CommentActionDelete, CommentActionRevise, applyCommentActions } from '../../../../../../common/model/comment/action';
import { CommentData, CommentList } from '../../../../../../common/model/comment/comment';
import { FusionCollection } from '../../../../../../common/model/fusion';
import { MaybeIdInst, idNull } from '../../../../../../common/toolbox/id';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { AttachmentService } from '../../service/attachment.service';
import { AuthService } from '../../service/auth.service';
import { LogService } from '../../service/log.service';
import { fieldControlProviders } from '../../toolbox/angular';
import { patchRequest } from '../../toolbox/request';
import { DialogService } from '../dialog/dialog.service';
import { FieldControl } from '../field/field-control';

/** A view of a comment being edited. */
export class CommentView {
  /** Text of comment being edited. */
  text = '';
  /** Mentions of comment being edited. */
  mentions: string[] = [];

  constructor(
    /** Contents of content. */
    public comment = new CommentData(),
    /** True if being edited. */
    public editing = false
  ) {
    this.rollback();
  }

  /** Revert changes to comment. */
  rollback() {
    let revision = this.comment.revisions[0];
    this.text = revision.text;
    this.mentions = revision.mentions ?? [];
    this.editing = false;
  }
}

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  providers: fieldControlProviders(CommentListComponent)
})
export class CommentListComponent<T extends CommentList = CommentList> extends FieldControl implements ControlValueAccessor {

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  /** Collection to autosubmit comment changes. */
  @Input() collection?: CollectionComments<FusionCollection>;

  /** Current value being edited. */
  value?: MaybeIdInst<T>;

  /** Comment pending save. */
  protected comment = new CommentView();
  /** List of comments to display. */
  protected views: CommentView[] = [];

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();

  constructor(
    public attachmentService: AttachmentService,
    public auth: AuthService,
    private http: HttpClient,
    private dialog: DialogService,
    private log: LogService
  ) {
    super();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  writeValue(value: T | undefined) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    if (value?.comments?.length) this.views = value.comments.map(c => new CommentView(c));
    this.changed(this.value = value);
  }

  /** Callback when saving a new comment. */
  async onNewSave() {
    this.onAction(new CommentActionAdd(this.comment.text, this.comment.mentions));
  }

  /** Callback when clearing/cancelling a new comment. */
  async onNewClear() {
    this.comment = new CommentView();
  }

  /** Callback when user begins to edit an existing comment. */
  async onEditStart(view: CommentView) {
    view.editing = true;
  }

  /** Callback when user cancels an edit to an existing comment */
  async onEditCancel(view: CommentView) {
    if (!CommentData.differs(view.comment, view.text, view.mentions)) return;
    if (!(await this.dialog.confirm('Discard changes to comment?', 'Discard Comment Changes'))) return;
    view.rollback();
  }

  /** Callback when user attempts to save a edit to an existing comment */
  async onEditSave(view: CommentView, index: number) {
    this.onAction(new CommentActionRevise(index, view.text, view.mentions));
  }

  /** Callback when user attempts to delete an existing comment. */
  async onDelete(index: number) {
    // Get user confirmation of delete.
    if (!(await this.dialog.confirm('Are you sure you want to delete this comment?', 'Delete Comment'))) return;
    this.onAction(new CommentActionDelete(index));
  }

  /** Callback after changes have been made. */
  private async onAction(action: CommentAction) {
    // Submit changes to comments.
    if (!this.value || !this.collection || idNull(this.value._id) || idNull(this.value._inst)) return;
    let result = await patchRequest(this.http, 'comments', {
      collection: this.collection,
      patches: [new CommentPatch(this.value._id, this.value._inst, [action])]
    });
    if (errorResponse(result)) return this.log.show(result);

    // Apply changes locally.
    let comments = applyCommentActions(this.auth.session._id, [action], this.auth.grant.permissions, this.views.map(v => v.comment));
    if (errorResponse(comments)) return this.log.show(comments);
    this.views = comments.map(comment => new CommentView(comment));
    this.comment = new CommentView();
  }
}
