
/** Define key path info for a value. */
export type KeyInfo<T> = keyof T;

/** Pull object store key from value. */
export function keyInfoOf<T>(value: T): string | undefined {
  if (value instanceof Object) {
    let object = value as any;
    return object.constructor.keyinfo ?? object.keyinfo ?? undefined;
  } else {
    return undefined;
  }
}