import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorCode } from '../../../../../../common/toolbox/color';
import { Pair } from '../../../../../../common/toolbox/object';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  host: {
    '[attr.color]': 'color'
  }
})
export class ChipComponent<T> implements Pair<T> {

  /** Value to display for chip. */
  @Input()
  set view(view: number | string | undefined | null) {
    this.text = view == null ? '' : `${view}`;
    this.viewChange.next(this);
  }

  get view() {
    return this.text;
  }

  /** Pair to display for option. */
  @Input()
  set pair(pair: Pair<any>) {
    this.value = pair.value;
    this.view = pair.view;
  }

  get pair() {
    return new Pair(this.value, this.view);
  }

  /** Form value of chip. */
  @Input() value: T = undefined as unknown as T;
  /** Color override for chip */
  @Input() color = ColorCode.Primary;
  
  /** Emits when chip is deleted. */
  @Output() readonly deleted = new EventEmitter<ChipComponent<T>>();
  /** Emits when displayed text is changed. */
  @Output() readonly viewChange = new EventEmitter<ChipComponent<T>>();

  /** Current text being displayed. */
  text = '';
}