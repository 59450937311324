import { EnumMap } from "../../toolbox/enum";

/** Tag of account type. */
export enum AccountCategory {
  Loan  = 'L',
  Share = 'S'
}

/** Types of dispute specific attachments*/
export enum AttachmentType {
  ClaimChargeback             = 'claimChargeback',
  ClaimChargebackResponse     = 'claimChargebackResponse',
  ClaimIntakeUnsigned         = 'claimIntakeUnsigned',
  ClaimIntakeSigned           = 'claimIntakeSigned',
  ClaimInitialCorrespondence  = 'claimInitialCorrespondence',
  ClaimFinalCorrespondence    = 'claimFinalCorrespondence',
  ClaimProvisionalCredit      = 'claimProvisionalCredit',
  ClaimRepresentment          = 'claimRepresentment',
  ClaimPrearbitration         = 'claimPrearbitration',
  Other                       = 'other'
}

/** Type code of cards. */
export enum CardType {
  Credit                      = 'credit',
  Debit                       = 'debit'
}

/** Context in which a document template can be used. */
export enum DocumentTemplateType {
  ClaimCardChargeback            = 'claimCardChargeback',
  ClaimCardInitialCorrespondence = 'claimCardInitialCorrespondence',
  ClaimCardResolution            = 'claimCardResolution',
  ClaimIntake                    = 'claimIntake',
  ClaimList                      = 'claimList',
  General                        = 'general',
  Notification                   = 'notification',
  NotificationImport             = 'notificationImport',
  Test                           = 'test'
}

/** General resource search categories. */
export enum SearchCategory {
  AccountNumber               = 'accountNumber',
  CardNumber                  = 'cardNumber',
  Claim                       = 'claimNumber',
  MemberName                  = 'memberName',
  MemberNumber                = 'memberNumber',
  MemberTaxId                 = 'memberTaxId',
  
}

/** Name of each account category for banks. */
export const ACCOUNT_CATEGORY_BANK_NAME: EnumMap<AccountCategory> = {
  [AccountCategory.Loan]: 'Loan',
  [AccountCategory.Share]: 'Deposit'
};

/** Name of each account category for credit unions. */
export const ACCOUNT_CATEGORY_CREDIT_UNION_NAME: EnumMap<AccountCategory> = {
  [AccountCategory.Loan]: 'Loan',
  [AccountCategory.Share]: 'Share'
};

/** Name of each card type. */
export const CARD_TYPE_NAME: EnumMap<CardType> = {
  [CardType.Credit]: 'Credit',
  [CardType.Debit]: 'Debit'
};

/** Name of each attachment type. */
export const ATTACHMENT_TYPE_NAME: EnumMap<AttachmentType> = {
  [AttachmentType.ClaimChargeback]: 'Claim Card Processor',
  [AttachmentType.ClaimChargebackResponse]: 'Claim Card Processor Response',
  [AttachmentType.ClaimFinalCorrespondence]: 'Claim Final Correspondence',
  [AttachmentType.ClaimInitialCorrespondence]: 'Claim Initial Correspondence',
  [AttachmentType.ClaimIntakeSigned]: 'Claim Intake (Signed)',
  [AttachmentType.ClaimIntakeUnsigned]: 'Claim Intake (Unsigned)',
  [AttachmentType.ClaimPrearbitration]: 'Claim Pre-Arbitration Documentation',
  [AttachmentType.ClaimProvisionalCredit]: 'Claim Provisional Credit Letter',
  [AttachmentType.ClaimRepresentment]: 'Claim Representment Documentation',
  [AttachmentType.Other]: 'Other'
};

/** Names of each document template type. */
export const DOCUMENT_TEMPLATE_TYPE_NAME: EnumMap<DocumentTemplateType> = {
  [DocumentTemplateType.ClaimCardChargeback]:             'Card Claim Chargeback',
  [DocumentTemplateType.ClaimIntake]:                     'Card Claim Intake',
  [DocumentTemplateType.ClaimCardInitialCorrespondence]:  'Card Claim Initial Correspondence',
  [DocumentTemplateType.ClaimCardResolution]:             'Card Claim Resolution',
  [DocumentTemplateType.ClaimList]:                       'Claim List',
  [DocumentTemplateType.General]:                         'General',
  [DocumentTemplateType.Notification]:                    'Notification',
  [DocumentTemplateType.NotificationImport]:              'Import Notification',
  [DocumentTemplateType.Test]:                            'Test'
};

/** Name of each search category for banks. */
export const SEARCH_CATEGORY_BANK_NAME: EnumMap<SearchCategory> = {
  [SearchCategory.AccountNumber]: 'Account Number',
  [SearchCategory.CardNumber]: 'Card Number',
  [SearchCategory.Claim]: 'Claim Number',
  [SearchCategory.MemberName]: 'Customer Name',
  [SearchCategory.MemberNumber]: 'Customer Number',
  [SearchCategory.MemberTaxId]: 'Customer Tax ID'
};

/** Name of each search category for credit unions. */
export const SEARCH_CATEGORY_CREDIT_UNION_NAME: EnumMap<SearchCategory> = {
  [SearchCategory.AccountNumber]: 'Account Number',
  [SearchCategory.CardNumber]: 'Card Number',
  [SearchCategory.Claim]: 'Claim Number',
  [SearchCategory.MemberName]: 'Member Name',
  [SearchCategory.MemberNumber]: 'Member Number',
  [SearchCategory.MemberTaxId]: 'Member Tax ID'
};