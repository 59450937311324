import { Component, Inject } from '@angular/core';
import { AttachmentType, DocumentTemplateType } from '../../../../../../common/code/standard/common';
import { ErrorResponse } from '../../../../../../common/message/error';
import { DocumentTemplate, DocumentTemplatePreview } from '../../../../../../common/model/document-template/base';
import { DocumentTemplateClass } from '../../../../../../common/model/document-template/data';
import { Transaction } from '../../../../../../common/model/transaction';
import { formulaRun } from '../../../../../../common/toolbox/formula/formula';
import { idNull } from '../../../../../../common/toolbox/id';
import { errorResponse } from "../../../../../../common/toolbox/message";
import { ArrayValidator } from '../../../../../../common/validator/array';
import { ValidationStatus } from '../../../../../../common/validator/base';
import { DIALOG_DATA, DialogRef } from '../../../common/component/dialog/dialog.model';
import { FormConfig } from '../../../common/component/form/form.model';
import { AuthService } from '../../../common/service/auth.service';
import { ClaimService } from '../../../common/service/claim.service';
import { DocumentTemplateService } from '../../../common/service/document-template.service';
import { FormService } from '../../../common/service/form.service';
import { FormulaService } from '../../../common/service/formula.service';
import { LogService } from '../../../common/service/log.service';
import { fileDownload } from '../../../common/toolbox/file';
import { TaskBase } from '../task.model';
import { TaskDocumentTemplateData } from './task-document-template.model';

@Component({
  selector: 'app-task-document-template',
  templateUrl: './task-document-template.component.html',
  styleUrls: ['./task-document-template.component.scss']
})
export class TaskDocumentTemplateComponent {

  /** Configuration for form. */
  config?: FormConfig;

  constructor(
    @Inject(DIALOG_DATA)
    public data: TaskDocumentTemplateData,
    private log: LogService,
    private forms: FormService,
    private templates: DocumentTemplateService,
    private auth: AuthService,
    private formulas: FormulaService,
    private claims: ClaimService,
    private dialogRef: DialogRef
  ) {}

  async ngOnInit() {
    let _form = this.data.task.config._form;
    if (idNull(_form)) return;

    this.config = {
      mutate: true,
      form: await this.forms.item({ _inst: this.auth._inst, _id: _form }),
      value: this.data.partial
    };
  }

  /** Callback when document template is selected.  */
  async onDocumentTemplate(preview: DocumentTemplatePreview): Promise<ErrorResponse | void> {
    let template = await this.templates.item({ _inst: this.auth._inst, _id: preview._id });
    let context = await this.context(template.type);
    let file = await this.generate(template, context);
    if (errorResponse(file)) {
      this.log.show(file);
      return file;
    }

    fileDownload(file);
    this.dialogRef.close(true);
  }

  /** Get context for rendering document templates. */
  protected async context<T extends DocumentTemplateType>(type: T) {
    let transactions = TaskBase.transactions(this.data);

    // Check if there is a transaction filter formula to apply.
    let _formula = this.data.task.config._transactionFilter;
    if (!idNull(_formula)) {
      let formula = await this.formulas.item({ _inst: this.auth._inst, _id: _formula });
      let result = formulaRun(formula, this.data.partial);

      // Validate return value of formula was a list of transactions.
      let validator = new ArrayValidator(new Transaction());
      if (validator.validate(result) !== ValidationStatus.Okay) {
        this.log.show(validator.error(result));
      } else {
        transactions = result;
      }
    }
    
    return await this.templates.context<T>(type, {
      ...this.data.partial, transactions
    } as Partial<DocumentTemplateClass[T]>);
  }

  /** Generate a document template, and optionally attach to current claim. */
  protected async generate(template: DocumentTemplate, context: DocumentTemplateClass[DocumentTemplateType]): Promise<File | ErrorResponse> {
    if ('claim' in this.data) {
      let type = this.data.task.config.attachmentType ?? AttachmentType.Other;
      let _dispute = 'dispute' in this.data ? this.data.dispute._id : undefined;
      return this.claims.documentTemplate(this.data.claim, template._id, type, template.name, template.type, context, _dispute);
    }
    
    return this.templates.build(template, context);
  }
}