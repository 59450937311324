import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DocumentTemplateType } from '../../../../../common/code/standard/common';
import { ClaimType } from '../../../../../common/code/standard/disputes';
import { ClaimDocumentTemplatePostRequest, ClaimGetRequest } from "../../../../../common/message/claim";
import { ErrorResponse } from "../../../../../common/message/error";
import { PaginateResponse } from '../../../../../common/message/paginate';
import { ClaimAttachment } from '../../../../../common/model/claim/attachment';
import { Claim, ClaimClass, ClaimJoin } from '../../../../../common/model/claim/claim';
import { DocumentTemplateClass } from '../../../../../common/model/document-template/data';
import { DocumentTemplateFormat } from '../../../../../common/model/document-template/format';
import { Member } from '../../../../../common/model/member';
import { Transaction } from '../../../../../common/model/transaction';
import { arraySingle } from '../../../../../common/toolbox/array';
import { binaryBlobPart } from '../../../../../common/toolbox/binary';
import { claimIntakeTemplate, claimUnion } from '../../../../../common/toolbox/claim';
import { ID_DEFAULT, idNull, instAdd } from "../../../../../common/toolbox/id";
import { keyNestedGet } from '../../../../../common/toolbox/keys';
import { errorResponse } from "../../../../../common/toolbox/message";
import { modelValue } from '../../../../../common/toolbox/model';
import { ClaimComponent } from '../../module/claim/claim.component';
import { ClaimData } from '../../module/claim/claim.model';
import { ClaimResultsComponent } from '../../module/claim/results/claim-results.component';
import { ClaimResultsData } from '../../module/claim/results/claim-results.model';
import { DisputeTaskOpen } from '../toolbox/dispute';
import { fileCreate } from '../toolbox/file';
import { getRequest, postRequest } from '../toolbox/request';
import { AuthService } from './auth.service';
import { DocumentTemplateService } from './document-template.service';
import { LogService } from './log.service';
import { ModelService } from './model.service';
import { SettingGroupService } from './setting-group.service';
import { TabService } from './tab.service';

/** A query to fetch a specific table. */
export class ClaimQuery {
  constructor(
    /** ID of claim. */
    public _id = ID_DEFAULT,
    /** Institution of table. */
    public _inst = ID_DEFAULT
  ) { }
}

/** Caches information about claims. */
@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  /** Emits when new claims are added via the interface. */
  added = new Subject<Claim[]>();

  constructor(
    private auth: AuthService,
    private log: LogService,
    private http: HttpClient,
    private tabs: TabService,
    private models: ModelService,
    private documentTemplates: DocumentTemplateService,
    private settingGroups: SettingGroupService,
  ) { }

  /** Create a claim of given type, pre-populated with institution-configured model data. */
  async create<T extends ClaimType>(type: T): Promise<ClaimClass[T]> {
    let claim = new ClaimClass()[type];

    let model = await this.models.setting(`disputes.${type}.claim.model`);
    if (!idNull(model._id)) claim.value = modelValue(model);
    return claim;
  }

  /** Query for specified claims and work or display results. */
  async open(request: ClaimGetRequest, claims?: ClaimJoin[], task?: DisputeTaskOpen) {
    let response = claims ? new PaginateResponse(claims) : await getRequest(this.http, 'claims', request);
    if (errorResponse(response)) return this.log.show(response);
    
    if (arraySingle(response.items)) return this.tabs.open(ClaimComponent, new ClaimData(response.items[0], task));
    this.tabs.open(ClaimResultsComponent, new ClaimResultsData(request, response));
  }

  /** Get claim intake document template. */
  async intakeTemplate(claim: ClaimJoin) {
    let settings = await this.settingGroups.inst();
    let key = claimIntakeTemplate(claim);
    if (!key) return new ErrorResponse('Cannot generate credit bureau claim documents.');

    let _id = keyNestedGet(key, settings);
    if (idNull(_id)) {
      return new ErrorResponse(`Claim intake document template not configured: ${key}`);
    }

    return await this.documentTemplates.item({ _inst: this.auth._inst, _id });
  }

  /** Download claim intake document. */
  async intakeDownload(claim: ClaimJoin, transactions?: Transaction[], extension?: DocumentTemplateFormat): Promise<File | ErrorResponse> {
    let template = await this.intakeTemplate(claim);
    if (errorResponse(template)) return template;

    let context = await this.documentTemplates.context(template.type, {
      claim: claimUnion(claim),
      transactions
    });

    let output = Member.fullname(claim.member);
    if (extension) output += `.${extension}`;
    return this.documentTemplates.build(template, context, { output });
  }

  /**
   *  Generate and attach a document template to a claim.
   *  @modifies Appends the new document template to the claim locally.
   */
  async documentTemplate<T extends DocumentTemplateType>(claim: Claim, _template: string, attachmentType: string, output: string, _type: T, data: DocumentTemplateClass[T], _dispute?: string) {
    let _disputes = _dispute ?  [_dispute] : undefined;
    let request: ClaimDocumentTemplatePostRequest = {
      _claim: claim._id, _disputes,
      _inst: this.auth._inst,
      attachmentType,
      templateData: {
        _ids: [_template],
        _insts: [this.auth._inst],
        output,
        data: instAdd(data, this.auth._inst)
      }
    };

    // Build template and append to attachment list server-side.
    let result = await postRequest(this.http, 'claims/document-template', request);
    if (errorResponse(result)) return result;

    // Replicate server-side changes locally.
    claim.attachments.push(...result.attachments.map(a => new ClaimAttachment(a._id, _disputes)));
    return fileCreate([binaryBlobPart(result.data)], output);
  }
}
