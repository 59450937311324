import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { FilterRule, FilterRulePreview } from "../../../../../../common/model/filter-rule";
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { FilterRuleQuery, FilterRuleService } from '../../../common/service/filter-rule.service';
import { LogService } from '../../../common/service/log.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-filter-rule',
  templateUrl: './setup-filter-rule.component.html',
  styleUrls: ['./setup-filter-rule.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupFilterRuleComponent extends SetupComponent<FilterRule, FilterRuleQuery, FilterRulePreview> {
  readonly Type = FilterRule;
  readonly collection = 'filterRules';
  readonly route = 'filter-rules';
  resource = idMaybe(new FilterRule());

  static title() {
    return 'Filter Rules';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public auth: AuthService,
    public http: HttpClient,
    public service: FilterRuleService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }
}
