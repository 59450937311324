<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Type</app-label>
          <app-select [disabled]="value | setupReadonly" [ngModel]="value.type" (selected)="onType($event)">
            <app-option *ngFor="let t of types" [view]="t | enum:CLAIM_FILTER_NAME" [value]="t"></app-option>
          </app-select>
        </app-field>
        <app-field class="fill">
          <app-label>Icon</app-label>
          <app-select [disabled]="value | setupReadonly" [(ngModel)]="value.icon">
            <app-option *ngFor="let i of icons" [view]="i" [value]="i">
              <app-icon>{{i}}</app-icon>
            </app-option>
          </app-select>
        </app-field>
        <app-field class="fill">
          <app-label>Color</app-label>
          <app-select [disabled]="value | setupReadonly" [(ngModel)]="value.color">
            <app-option *ngFor="let c of colors" [view]="c" [value]="c">
              <app-icon [attr.color]="c">palette</app-icon>
            </app-option>
          </app-select>
        </app-field>
      </div>
      <ng-template #container></ng-template>
    </app-setup-base-header>
    <app-setup-base-body></app-setup-base-body>
    <app-setup-base-footer>
      <button (click)="onSubmit()">Save</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>