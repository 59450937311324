import { Component, Input } from '@angular/core';
import { PROPERTY_TYPE_ICON } from '../../../toolbox/property';
import { FormAccessor } from '../form-accessor';

@Component({
  selector: 'app-form-code',
  templateUrl: './form-code.component.html',
  styleUrls: ['./form-code.component.scss']
})
export class FormCodeComponent extends FormAccessor<string | undefined> {
  readonly PROPERTY_TYPE_ICON = PROPERTY_TYPE_ICON;

  /** True if multiple codes can be set. */
  @Input() override multiple = false;
  /** Force sort of codes on display. */
  @Input() sort?: boolean;
  /** Prefix to display before codes. */
  @Input() prefix?: [string, string];
  /** Suffix to display after codes. */
  @Input() suffix?: [string, string];
  /** Filter for list of codes. */
  @Input() filter?: string[];

  /** Current institution. */
  institution: string | undefined;
  /** Current code category. */
  category: string | undefined;
}
