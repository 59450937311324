import { Component, Inject } from '@angular/core';
import { CodePreview } from "../../../../../../../../common/model/code-type";
import { TerminalCode, TerminalCodeList, TerminalType } from "../../../../../../../../common/model/formula/terminal";
import { AuthService } from '../../../../../common/service/auth.service';
import { CodeTypeService } from '../../../../../common/service/code-type.service';
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-code',
  templateUrl: './setup-formula-code.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-code.component.scss']
})
export class SetupFormulaCodeComponent extends BlockComponent<TerminalCode | TerminalCodeList> {

  /** List of code categories for selection. */
  previews: CodePreview[] = [];
  /** True to allow multiple selection of codes. */
  multiple = false;

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    @Inject('BLOCK') block: TerminalCode | TerminalCodeList,
    public auth: AuthService,
    public types: CodeTypeService
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
    this.types.previews(this.auth._inst).then(previews => this.previews = previews);
    this.multiple = block.type === TerminalType.CodeList;
  }
}
