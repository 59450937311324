import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ComponentRef, Directive, ElementRef, HostBinding, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { LoaderComponent } from '../component/loader/loader.component';

/** Overlays a load spinner onto the specified element. */
@Directive({
  selector: '[loader]'
})
export class LoaderDirective {

  @HostBinding('style.position') position: string | null = null;
  @HostBinding('style.overflow') overflow: string | null = null;

  /** True to display loader. */
  @Input() set loader(show: BooleanInput) {
    this.toggle(coerceBooleanProperty(show));
  }

  /** Reference to created loader component. */
  private component?: ComponentRef<LoaderComponent>;

  constructor(
    private containerRef: ViewContainerRef,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  /** Toggle visibility of loader. */
  private toggle(show: boolean) {
    if (!!this.component === show) return;
    if (this.component) {
      this.component.destroy();
      this.component = undefined;
      this.position = null;
      this.overflow = null;
    } else {
      this.component = this.containerRef.createComponent(LoaderComponent);
      this.renderer.appendChild(this.elementRef.nativeElement, this.component.instance.elementRef.nativeElement);
      this.position = 'relative';
      this.overflow = 'hidden';
    }
  }
}
