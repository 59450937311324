import { Component } from '@angular/core';
import { PROPERTY_TYPE_ICON } from '../../../toolbox/property';
import { FormAccessor } from '../form-accessor';

@Component({
  selector: 'app-form-number',
  templateUrl: './form-number.component.html',
  styleUrls: ['./form-number.component.scss']
})
export class FormNumberComponent extends FormAccessor<number | undefined> {
  readonly PROPERTY_TYPE_ICON = PROPERTY_TYPE_ICON;

  /** Minimum value. */
  min?: number;
  /** Maximum value. */
  max?: number;
}
