import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Direction } from '../../../../../../common/model/direction';
import { arrayFill } from "../../../../../../common/toolbox/array";
import { deepCopy } from "../../../../../../common/toolbox/object";
import { DAYS_MAX, DAYS_MIN, DateMap, DateModel, DateRange } from "../../../../../../common/toolbox/time";
import { DateIsoPipe } from '../../pipe/date-iso.pipe';
import { fieldControlProviders } from '../../toolbox/angular';
import { FieldControl } from '../field/field-control';
import { CALENDAR_DAYS, CalendarEvent, CalendarMode, CalendarZoom, Day, Month, Year, calendarDay, calendarMonth, calendarYear } from './calendar.model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: fieldControlProviders(CalendarComponent)
})
export class CalendarComponent extends FieldControl implements ControlValueAccessor {
  /** Getter for zoom level in template. */
  readonly Zoom = CalendarZoom;

  /** List of events to display in calendar. */
  @Input() set events(events: CalendarEvent[]) {
    this.eventMap.clear();
    this.eventMap.merge(events.map(e => [e.date, e]));
    this.refresh();
  }

  /** True to preserve time of day, false to always clamp to midnight. */
  @Input() time = false;
  /** Minimum date offset, in number of days. */
  @Input() min = DAYS_MIN;
  /** Maximum date offset, in number of days. */
  @Input() max = DAYS_MAX;

  /** Emits when bound date changes. */
  @Output() dateChange = new EventEmitter<Date>();
  /** Emits when bound date range changes. */
  @Output() rangeChange = new EventEmitter<DateRange>();

  /** Text to display in header. */
  header: number | string = '';
  /** Current zoom level. */
  zoom = CalendarZoom.Days;
  /** Selected date in calendar. */
  value: DateModel = new Date();
  /** Displayed date in top bar. */
  display = new Date();
  /** Cells to display in grid. */
  cells = Array<Day>();
  /** Mapping from dates to list of events. */
  eventMap = new DateMap<CalendarEvent>();
  /** Current range selection mode. */
  mode = CalendarMode.Date;
  /** True to force date range mode. */
  range = false;
  
  /** List of days for day view. */
  private days = arrayFill(CALENDAR_DAYS, () => new Day());
  /** List of months for month view. */
  private months = arrayFill(12, () => new Month());
  /** List of years for year view. */
  private years = arrayFill(12, () => new Year());

  constructor(
    private datePipe: DateIsoPipe
  ) {
    super();
    this.refresh();
  }

  writeValue(value: DateModel): void {
    switch (this.mode) {
    case CalendarMode.Date:
      if (!value) value = this.range ? new DateRange() : new Date();
      
      if (value instanceof Date) {
        this.value = value;
        this.display = deepCopy(value);
        this.changed(value);
        this.dateChange.next(value);
        this.refresh();
      } else {
        this.value = DateRange.range(value);
        this.mode = CalendarMode.Start;
        this.refresh();
      } break;
    case CalendarMode.Start:
      if (this.value instanceof Date) return;
      this.value = DateRange.range(value);
      this.mode = CalendarMode.End;
      this.refresh();
      break;
    case CalendarMode.End:
      if (this.value instanceof Date) return;
      this.value = new DateRange(this.value?.start, DateRange.date(value));
      this.mode = CalendarMode.Start;
      this.changed(this.value);
      this.rangeChange.next(this.value);
      this.refresh();
      break;
    }
  }

  /** Change current level of zoom. */
  onHeader() {
    if (this.zoom === CalendarZoom.Years) return;
    this.zoom++;
    this.refresh();
  }

  /** Offset current displayed month, year or decade. */
  onOffset(offset: Direction) {
    switch (this.zoom) {
    case CalendarZoom.Days:
      this.display = new Date(this.display.setMonth(this.display.getMonth() + offset));
      break;
    case CalendarZoom.Months:
      this.display = new Date(this.display.setFullYear(this.display.getFullYear() + offset));
      break;
    case CalendarZoom.Years:
      this.display = new Date(this.display.setFullYear(this.display.getFullYear() + 12 * offset));
      break;
    }
    
    this.refresh();
  }

  /** Callback when selecting an item on calendar. */
  onSelect(cell: Day) {
    if (cell.disabled) return;

    switch (this.zoom) {
    case CalendarZoom.Days:
      this.writeValue(cell.date);
      break;
    case CalendarZoom.Months:
    case CalendarZoom.Years:
      this.zoom--;
      this.display = cell.date;
      this.refresh();
      break;
    }
  }

  /** Callback when hovering over an item on calendar. */
  onHover(date: Date) {
    if (this.mode !== CalendarMode.End) return;
    if (this.value instanceof Date) return;
    if (!this.value) return;
    this.value.end = date;
    this.refresh();
  }

  /** Determine calendar range  */
  private refresh() {

    // Refresh range of days.
    let date = calendarDay(this.display, this.time);
    let valid = DateRange.minmax(this.min, this.max)
    for (let day of this.days) {
      day.refresh(date, this.value, valid, this.eventMap);
      date.setDate(date.getDate() + 1);
    }

    // Refresh range of months.
    date = calendarMonth(this.display);
    for (let month of this.months) {
      month.refresh(date, this.value);
      date.setMonth(date.getMonth() + 1);
    }

    // Refresh range of years.
    date = calendarYear(this.display);
    for (let year of this.years) {
      year.refresh(date, this.value);
      date.setFullYear(date.getFullYear() + 1);
    }

    // Set new display list of items.
    let info = this.info();
    this.header = info[0];
    this.cells = [...info[1]];
  }

  /** Get header and list given current zoom level. */
  private info(): [header: number | string, list: Day[]] {
    switch (this.zoom) {
      case CalendarZoom.Days: return [this.datePipe.transform(this.display, 'MMMM YYYY')!, this.days];
      case CalendarZoom.Months: return [this.display.getFullYear(), this.months];
      case CalendarZoom.Years: return [`${this.years[0]!.display} - ${this.years[11]!.display}`, this.years];
    }
  }
}
