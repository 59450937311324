import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Component, ContentChild, ContentChildren, ElementRef, EventEmitter, HostListener, Input, NgZone, Output, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest, debounceTime, filter, first, map, merge, startWith, switchMap, takeUntil } from 'rxjs';
import { Pair } from "../../../../../../common/toolbox/object";
import { searchQuery } from '../../../../../../common/toolbox/search';
import { setCoerce } from "../../../../../../common/toolbox/set";
import { fieldControlProviders } from '../../toolbox/angular';
import { debugElementMake } from '../../toolbox/element/debug';
import { OverlayComponent, overlayOpen } from '../../toolbox/overlay';
import { FieldControl } from '../field/field-control';
import { OptionComponent } from '../option/option.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: fieldControlProviders(SelectComponent),
  host: {
    '[class.disabled]': '_disabled',
    '[class.readonly]': '_readonly'
  }
})
export class SelectComponent<T> extends FieldControl implements ControlValueAccessor, OverlayComponent {
  /** Reference to dynamic options created in template. */
  @ViewChildren(OptionComponent) templateRef!: QueryList<OptionComponent<T>>;
  /** Options statically added via ng-content or ngFor. */
  @ContentChildren(OptionComponent, { descendants: true }) contentRef!: QueryList<OptionComponent<T>>;

  /** Reference to search input. */
  @ContentChild('input') inputRef!: unknown;
  /** Reference to text area. */
  @ViewChild('text', { static: true }) textRef!: ElementRef<HTMLDivElement>;
  /** Reference to dropdown template. */
  @ViewChild('dropdown', { static: true }) dropdownRef!: TemplateRef<HTMLDivElement>;

  /** True if clicking options updates internal value. */
  @Input() set autoselect(autoselect: BooleanInput) { this._autoselect = coerceBooleanProperty(autoselect); }
  /** True if typing into field automatically filters available items. */
  @Input() set autofilter(autofilter: BooleanInput) { this._autofilter = coerceBooleanProperty(autofilter); }
  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing of code type. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  /** True if this is a multiselect dropdown. */
  @Input() set multiple(multiple: BooleanInput) { this.remultiple(multiple); }
  /** Filter for list of displayed values. */
  @Input() set filter(filter: any[] | undefined) { this._filter = filter ? new Set(filter) : undefined; }
  /** Set placeholder for search input. */
  @Input() placeholder = 'Search';
  /** True to make a value required. */
  @Input() required = true;
  /** Override for empty item label. */
  @Input() set empty(empty: string) { this.reempty(empty); }

  /** Set list of available items. */
  @Input() set items(list: Pair<T>[]) { this.templatePairs.next(this.dynamic = list); }
  get items() { return this.pairs; }

  /** Emits when a new value is selected from dropdown. */
  @Output() selected = new EventEmitter<T>();
  /** Emits when a new selection is available in response to user input. */
  @Output() selection = new EventEmitter<T[]>();
  /** Emits when a new list of items should be queried. */
  @Output() query = new EventEmitter<string>();

  /** Search form over dropdown options. */
  builder = new FormBuilder().nonNullable.group({
    query: ['', [Validators.required]]
  });

  /** Value to display inside select. */
  view = '';
  /** Current selected values. */
  set = new Set<T>();
  /** True if all items are selected. */
  all = false;
  /** Merged list of all available items. */
  pairs: Pair<T>[] = [];
  /** List of programatically-added items. */
  dynamic: Pair<T>[] = [];

  /** Emits whenever the component is destroyed. */
  destroy = new Subject<void>();
  /** True to show filter. */
  showFilter = true;
  /** Observer for size changes. */
  observer?: ResizeObserver;
  /** Emits when select should be closed. */
  overlayClose = new Subject<OptionComponent<T>>();
  /** Reference to opened overlay. */
  overlayRef?: OverlayRef;
  /** Filter for list of displayed values. */
  _filter?: Set<any>;

  /** Emits when content-projected list of options changes. */
  private contentOptions = new BehaviorSubject<OptionComponent<T>[]>([]);
  /** Emits when template-projected list of options changes. */
  private templateOptions = new BehaviorSubject<OptionComponent<T>[]>([]);
  /** Emits when template-projected pairs change. */
  private templatePairs = new BehaviorSubject<Pair<T>[]>([]);
  /** Merged stream mapping keys to template-projected options */
  private options = new BehaviorSubject<OptionComponent<T>[]>([]);
  /** Emits when an option is clicked. */
  private clicked = new Subject<OptionComponent<T>>();

  /** Label if no value selected. */
  private _empty = '';
  /** True if options are autoselected when clicking. */
  private _autoselect = true;
  /** True if options are autofiltered when typing into field. */
  private _autofilter = true;

  constructor(
    public zone: NgZone,
    public overlay: Overlay,
    public containerRef: ViewContainerRef,
    public elementRef: ElementRef<HTMLElement>
  ) {
    super();
    debugElementMake(this);

    // Refilter dropdown options when retyping query.
    this.builder.controls.query.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(250))
      .subscribe(query => {
        this.query.next(query);
        this.refilter();
      });
  }

  ngAfterContentInit() {
    // Get stream of content-projected options.
    this.contentRef.changes.pipe(takeUntil(this.destroy), startWith(this.contentRef), map(o => o.toArray())).subscribe(this.contentOptions);

    // Merge stream of content-projected and template-projected options into one.
    combineLatest([this.contentOptions, this.templateOptions]).pipe(
      takeUntil(this.destroy),
      map(([content, template]) => [...content, ...template])
    ).subscribe(this.options);

    // Update subscriptions and selection when list of options change.
    this.options.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.remultiple(this._multiple);
      Promise.resolve().then(() => this.redisplay());
    });

    // Listen to options for selection changes.
    this.options.pipe(
      takeUntil(this.destroy),
      switchMap(options => merge(...options.map(o => o.selectionChange)))
    ).subscribe(this.clicked);

    // Listen to options for view changes.
    this.options.pipe(
      takeUntil(this.destroy),
      switchMap(options => merge(...options.map(o => o.viewChange))),
      debounceTime(0)
    ).subscribe(() => this.redisplay());

    // Listen for reselections.
    this.clicked.pipe(
      takeUntil(this.destroy)
    ).subscribe(o => this.click(o));

    // Listen for new lists of pairs.
    combineLatest([this.contentOptions, this.templatePairs]).pipe(
      takeUntil(this.destroy)
    ).subscribe(([options, template]) => this.pairs = [...options, ...template]);

    // Close overlay after selections in single select mode.
    this.clicked.pipe(
      takeUntil(this.destroy),
      filter(() => !this._multiple)
    ).subscribe(this.overlayClose);
  }

  ngAfterViewInit() {
    this.templateRef.changes.subscribe(this.templateOptions);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Accept new ngModel value in form. */
  writeValue(value: any) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.set = setCoerce<T>(value);

    // Emit array if in multiple mode, or single value.
    // Emit undefined if value is not required, and no selection made.
    this.changed(this._multiple || this.set.size > 1 ? (this.required || this.set.size ? [...this.set] : undefined) : [...this.set][0]);
    this.redisplay();
  }

  /** Callback when debugging form value. */
  override onDebug() {
    return {
      ...super.onDebug(),
      set: [...this.set],
      pairs: [...this.pairs.values()].map(c => new Pair(c.value, c.view))
    };
  }

  /** Called when clicking dropdown. */
  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    $event.preventDefault();
    if (this._readonly || this._disabled) return;
    this.setTouched();

    // Reset search query.
    if (this.builder.value.query) {
      this.builder.controls.query.patchValue('');
      this.refilter();
    }

    // Open overlay and autofocus search input.
    overlayOpen(this);
    this.overlayRef?.hostElement.querySelector('input')?.focus({ preventScroll: true });

    // Wait for options to populate and apply filter.
    this.options.pipe(filter(value => !!value.length), first()).subscribe(() => {
      if (this._filter) Promise.resolve().then(() => this.refilter());
    });
  }

  /** Filter list of displayed options. */
  protected refilter() {
    if (!this._autofilter) return;
    let query = searchQuery(this.builder.value.query!);
    let filter = this.showFilter ? this._filter : undefined;
    if (!this.required) filter?.add(undefined);

    for (let option of this.options.value) {
      option.filter(query, filter);
    }
  }

  /** Called when toggling select all. */
  protected toggleAll() {
    this.all = !this.all;
    this.setTouched();
    this.setDirty();

    if (this.all) this.set = new Set(this.pairs.map(p => p.value));
    else this.set.clear();
    this.writeValue(this.set);
  }

  /** Toggle one or more dropdown values. */
  private toggle(...values: T[]) {
    if (!values.length) return;
    this.setTouched();
    this.setDirty();

    if (this._multiple) {
      for (let value of values) {
        if (this.set.has(value)) {
          this.set.delete(value);
        } else {
          this.set.add(value);
        }
      }
    } else {
      this.set.clear();
      if (values[0] !== undefined) this.set.add(values[0]);
    }

    this.writeValue(this.set);
  }


  /** Called when clicking dropdown values. */
  private click(option: OptionComponent<T>) {
    // Toggle instead of editing value if Select All clicked.
    if (option.selectall) return this.toggleAll();

    // Prevent deselection of final value if required.
    if (this.required && this.set.size === 1 && this.set.has(option.value)) return;
    if (this._autoselect) this.toggle(option.value);
    this.selected.next(option.value);
    this.selection.next([...this.set]);
  }

  /** Toggle multiple state of dropdown. */
  private remultiple(value: BooleanInput) {
    this.setMultipleState(value);
    Promise.resolve().then(() => {
      this.options.value.forEach(o => o.multiple = this._multiple);
    });
  }

  /** Refresh selected options after altering selection or list. */
  private redisplay() {
    // Update selected options.
    this.all = true;
    for (let option of this.options.value) {
      if (option.selectall) continue;
      if (this.set.has(option.value)) {
        option.selected = true;
      } else {
        option.selected = this.all = false;
      }
    }

    // Update viewed text.
    if (!this.set.size) this.view = this.pairs.find(p => p.value === undefined)?.view ?? this._empty;
    else this.view = this.pairs.filter(p => this.set.has(p.value)).map(p => p.view).join(', ');
  }

  /** Update empty display value. */
  private reempty(empty: string) {
    this._empty = empty;
    if (this.set.size) return;
    this.view = this.pairs.find(p => p.value === undefined)?.view ?? this._empty;
  }
}
