import { Component, Inject, Optional } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '../dialog.model';
import { DialogConfirmationData, DialogConfirmationReturn } from './dialog-confirmation.model';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent {
  /** Message to display. */
  message = 'This action cannot be undone.';
  /** Label for yes button. */
  yes = 'Yes';
  /** Label for no button. */
  no = 'No';

  constructor(
    @Optional() @Inject(DIALOG_DATA)
    public data: DialogConfirmationData | undefined,
    public dialogRef: DialogRef<DialogConfirmationReturn>
  ) {
    this.message = data?.message ?? this.message;
    this.yes = data?.yes ?? this.yes;
    this.no = data?.no ?? this.no;
  }
}
