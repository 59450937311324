import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { PaginateResponse } from '../../../../../../common/message/paginate';
import { AccountJoin } from '../../../../../../common/model/account/account';
import { DisplayType } from '../../../../../../common/model/display';
import { RouteMethod } from '../../../../../../common/toolbox/api';
import { arrayDefined, arrayUnique } from '../../../../../../common/toolbox/array';
import { idNull } from '../../../../../../common/toolbox/id';
import { errorPartition } from '../../../../../../common/toolbox/message';
import { AccountService } from '../../service/account.service';
import { AuthService } from '../../service/auth.service';
import { LogService } from '../../service/log.service';
import { fieldControlProviders } from '../../toolbox/angular';
import { DISPLAY_TYPE_ICON } from '../../toolbox/display';
import { bulkRequest } from '../../toolbox/request';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.scss'],
  providers: fieldControlProviders(AccountSelectComponent)
})
export class AccountSelectComponent extends FieldControl implements ControlValueAccessor {
  readonly DisplayType = DisplayType;
  readonly DISPLAY_TYPE_ICON = DISPLAY_TYPE_ICON;
  
  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  /** Text to display for empty accounts. */
  @Input() empty = '';
  /** True to make a value required. */
  @Input() required = true;

  /** Emits when a new account is selected from dropdown. */
  @Output() selected = new EventEmitter<AccountJoin | undefined>();

  /** Current selected account ID. */
  value?: string;
  /** Account corresponding to ID. */
  account?: AccountJoin;
  /** List of accounts to select. */
  accounts: AccountJoin[] = [];
  /** Last account query. */
  query?: string;

  constructor(
    private accountService: AccountService,
    private auth: AuthService,
    private http: HttpClient,
    private log: LogService
  ) {
    super();
  }

  ngOnInit() {
    if (!idNull(this.value)) this.onQuery('');
  }

  writeValue(value?: string) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.changed(this.value = value);
    if (!idNull(this.value)) this.onQuery('');
  }

  /** Callback when selecting an account. */
  onSelect(account?: AccountJoin) {
    this.writeValue(account?._id);
    this.selected.next(account);
    this.account = account;
  }

  /** Callback when opening account. */
  async onAccount() {
    if (!this.account) return;
    this.accountService.open({ _insts: [this.account._inst], _ids: [this.account._id] }, [this.account]);
  }

  /** Callback when filtering list of accounts. */
  async onQuery(query: string) {
    this.query = query;
    let responses = await bulkRequest(this.http, ...arrayDefined([
      this.query ? {
        method: RouteMethod.Get as const,
        route: 'accounts' as const,
        body: { _insts: [this.auth._inst], number: this.query }
      } : undefined,
      !idNull(this.value) ? {
        method: RouteMethod.Get as const,
        route: 'accounts' as const,
        body: { _insts: [this.auth._inst], _id: this.value }
      } : undefined
    ]));

    let [error, accounts] = errorPartition('There was an error fetching accounts.', responses);
    if (error) return this.log.show(error);

    this.accounts = arrayUnique(PaginateResponse.flat(accounts), '_id').sort((a, b) => a.number.localeCompare(b.number));
    this.account = this.accounts.find(({ _id }) => this.value === _id);
  }
}
