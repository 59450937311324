import { ComponentPortal } from "@angular/cdk/portal";
import { HttpClient } from "@angular/common/http";
import { Component, ComponentRef, Inject, Input, ViewChild, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { ClaimType } from "../../../../../common/code/standard/disputes";
import { AccountJoin, AccountJoinLoan } from "../../../../../common/model/account/account";
import { Attachment } from "../../../../../common/model/attachment";
import { ClaimJoin, ClaimJoinACH } from "../../../../../common/model/claim/claim";
import { ClaimPatch } from "../../../../../common/model/claim/upload";
import { Dispute } from "../../../../../common/model/dispute/dispute";
import { ClaimViewResult } from "../../../../../common/model/event/result/claim";
import { Permission } from "../../../../../common/model/permission";
import { Task } from "../../../../../common/model/task";
import { WorkContext } from "../../../../../common/model/work/context";
import { Workflow } from "../../../../../common/model/work/flow";
import { arrayDefined, arraySome } from "../../../../../common/toolbox/array";
import { claimDisputes, claimJoin } from "../../../../../common/toolbox/claim";
import { idNull } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { deepAssign, deepCopy } from "../../../../../common/toolbox/object";
import { DialogOptions, DialogOutlet, DialogService } from "../../common/component/dialog/dialog.service";
import { TAB_DATA, TabRef } from "../../common/component/tab/bar/tab-bar.model";
import { AuthService } from "../../common/service/auth.service";
import { EventService } from "../../common/service/event.service";
import { LogService } from "../../common/service/log.service";
import { SettingGroupService } from "../../common/service/setting-group.service";
import { TaskService } from "../../common/service/task.service";
import { WorkQueueService } from "../../common/service/work-queue.service";
import { WorkflowService } from "../../common/service/workflow.service";
import { DIALOG_CANCEL_SYMBOL } from "../../common/toolbox/dialog";
import { getOneRequest, patchRequest } from "../../common/toolbox/request";
import { TaskOpen } from "../../common/toolbox/task";
import { TaskMode } from "../task/task.model";
import { ClaimData } from "./claim.model";
import { ClaimPanelListComponent } from "./panel/list/claim-panel-list.component";

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
  host: {
    class: 'column fill'
  }
})
export class ClaimComponent implements DialogOutlet {
  readonly ClaimType = ClaimType;
  readonly Permission = Permission;
  readonly TaskMode = TaskMode;

  /** Main form to project fields into. */
  @ViewChild('task', { read: ViewContainerRef }) taskRef!: ViewContainerRef;
  /** Reference to panel list. */
  @ViewChild(ClaimPanelListComponent) panels!: ClaimPanelListComponent;

  /** Current claim being viewed. */
  @Input() set data(data: ClaimData | undefined) {
    if (!data || idNull(data.claim._id)) return;
    this.claimDirty = false;
    this.claim = claimJoin(data.claim, claimDisputes(data.claim));
    this.reclaim(data.claim)
  }

  /** List of existing attachments to display. */
  attachments: Attachment[] = [];
  /** Claim being viewed. */
  claim: ClaimJoin = new ClaimJoinACH();
  /** History of claims for undo purposes. */
  undoHistory: ClaimJoin[] = [];
  /** Current index in undo history */
  undoIndex = -1;
  /** Account of current claim. */
  account: AccountJoin = new AccountJoinLoan();
  /** Emits when created panels should be destroyed. */
  discard = new Subject<void>();
  /** True if claim or any child disputes are dirty. */
  claimDirty = false;
  /** True if claim is valid (data is in compliance with regulations) */
  error = "";

  /** Current loaded workflow. */
  protected workflow?: Workflow;
  /** Context for displaying current workflow. */
  protected context?: WorkContext;
  /** True if currently pulling down new claim information. */
  protected loading = false;

  /** Emits when component is destroyed. */
  private destroy = new Subject<void>();
  /** True once claim has been set at least once. */
  private initialized = false;

  static title(data: ClaimData) {
    return `Claim ${data.claim.displayId}`;
  }

  constructor(
    @Inject(TAB_DATA) public tab: ClaimData,
    public auth: AuthService,
    private events: EventService,
    private ref: TabRef,
    private log: LogService,
    private http: HttpClient,
    private dialog: DialogService,
    private queues: WorkQueueService,
    private tasks: TaskService,
    private workflows: WorkflowService,
    private settingGroups: SettingGroupService
  ) {
    if (ClaimData.check(tab)) {
      this.claim = claimJoin(tab.claim, claimDisputes(tab.claim));
      this.reclaim(tab.claim);
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Get and open default task */
  async defaultTask() {
    // Try to get the task to spawn on viewing this claim.
    const _task = (await this.settingGroups.inst()).disputes.claim.viewTask;
    if (idNull(_task)) {
      this.log.show('Claim view task was not configured for institution.');
      return;
    }

    // Ensure user completes task without exiting.
    const result = await this.onTask({ _task });
    if (result === DIALOG_CANCEL_SYMBOL) {
      this.log.show('You must complete the task in order to view this claim.')
      this.ref.close();
    }
  }

  /** Callback when a task should be opened. */
  async onTask(open: TaskOpen, dispute?: Dispute): Promise<void | typeof DIALOG_CANCEL_SYMBOL> {
    // Open dialog with requested task.
    const result = await this.tasks.open({
      dialog: this.dialog,
      options: open.panel ? new DialogOptions(this, ['card']) : undefined,
      input: {
        partial: {},
        task: await this.tasks.item({ _id: open._task, _inst: this.auth._inst }) as Task<any>,
        transactions: arrayDefined(claimDisputes(this.claim).map(dispute => dispute.transaction)),
        ...dispute ? { claim: this.claim, dispute } : { claim: this.claim }
      }
    });

    // Destroy any task panels in page.
    this.discard.next();

    // Refetch claim if task returned
    if (result === DIALOG_CANCEL_SYMBOL) return result;
    this.refetch();
  }

  // PortalOutlet implementation for injecting into template instead of dialog.

  attach(portal: ComponentPortal<any>): ComponentRef<any> { return this.taskRef.createComponent(portal.component, { injector: portal.injector ?? undefined }); }
  detach() { this.taskRef.detach(); }
  dispose() { this.detach(); }
  hasAttached(): boolean { return !!this.taskRef.length; }

  /** Fetch a new claim. */
  protected async refetch() {
    this.loading = true;
    const claim = await getOneRequest(this.http, 'claims', {
      _insts: [this.claim._inst],
      _ids: [this.claim._id],
      showDeleted: true
    });

    // Update view with new claim.
    if (errorResponse(claim)) {
      this.log.show(claim);
      return;
    }
    this.data = { claim };
    this.claimDirty = false;
    this.loading = false;
  }

  /** Update current claim being viewed. */
  private async reclaim(claim: ClaimJoin): Promise<void> {
    // Initially viewing claim?
    if (!this.initialized) {
      this.initialized = true;
      if (!this.auth.permission(Permission.ClaimsViewTaskBypass)) {
        this.defaultTask();
      }

      this.events.add({
        event: {
          _inst: claim._inst,
          result: new ClaimViewResult(claim._id)
        }
      });
    }

    // View workflow of this claim type.
    this.workflow = await this.workflows.setting(`disputes.${claim.type}.claim.workflow`);

    // Fetch tasks and full account of this claim.
    let account = await getOneRequest(this.http, 'accounts', {
      _insts: [this.auth._inst],
      _ids: [claim._account]
    });
    if (errorResponse(account)) return this.log.show(account);
    this.account = account;
    this.storeUndo(this.claim);
    // Update workflow context.
    this.recontext();
  }

  /** Save current displayed items. */
  async onSave() {
    let result = await patchRequest(this.http, 'claims', { patches: [ClaimPatch.from(this.claim)] });
    if (errorResponse(result)) return this.log.show(result);

    this.queues.updated.next();
    this.log.show('Successfully updated claim.');
    this.claimDirty = false;
    if (this.tab) {
      this.storeUndo(this.claim);
      deepAssign(this.tab.claim, this.claim);
    }
    this.recontext();
  }

  /** Pulls a claim from history and replaces current claim. */
  public async undo() {
    if (this.undoHistory.length === 0 || this.undoIndex < 0) return;
    let newClaim = this.undoHistory[this.undoIndex]!;
    //we don't undo ledgers
    let currentClaimLedgerMap = new Map<string, string[]>(claimDisputes(this.claim).map((dispute)=> [dispute._id,dispute._ledgers]));
    let hasRolledBackLedgers = false;
    for (let dispute of claimDisputes(newClaim)) {
      let currentLedgers = currentClaimLedgerMap.get(dispute._id);
      if (currentLedgers?.length !== dispute._ledgers.length) {
        hasRolledBackLedgers = true;
        dispute._ledgers = currentLedgers ?? [];
      }
    }
    //show a confirmation dialog warning if we detected a difference in ledgers from this undo
    if (hasRolledBackLedgers) {
      let confirm = await this.dialog.confirm('You are attempting to undo an action that potentially posted a transaction. Please contact a supervisor to roll back a transaction.');
      if (!confirm) return;
    }
    this.undoIndex--;
    this.claimDirty = true;
    this.claim = newClaim;
  }

  /** Callback when attachments are modified. */
  onAttachments(attachments: Attachment[]) {
    this.attachments = attachments;
    this.recontext();
  }

  /** Update workflow context. */
  private recontext() {
    this.context = {
      claim: this.claim,
      attachments: this.attachments
    };
  }

  /** Updates undo history. */
  private storeUndo(claim: ClaimJoin) {
    if (idNull(claim._id)) return;
    //this shouldn't ever happen, but if we somehow managed to switch to a different claim, reset undo history
    if (arraySome(this.undoHistory) && this.undoHistory[0]._id !== claim._id) this.undoHistory = [];
    //if we've made a change after doing an undo, we don't need to store future history anymore
    this.undoHistory.splice(this.undoIndex+2);
    this.undoHistory.push(deepCopy(claim));
    this.undoIndex = this.undoHistory.length - 2;
  }
}
