import { Component, ElementRef, Input, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

/** Hardcoded space allocated for inner text. */
const INNER_TEXT = 48;

/** A coordinate position. */
class Pos {
  constructor(
    public x = 0,
    public y = 0
  ) {}
}

@Component({
  selector: 'app-ring',
  templateUrl: './ring.component.html',
  styleUrls: ['./ring.component.scss']
})
export class RingComponent {
  /** Current amount of value. */
  @Input() value = 0;
  /** Total value available. */
  @Input() max = 100;

  /** Calculated center of element. */
  center = new Pos();
  /** Radius of circle. */
  radius = 0;
  /** Width of stroke, in pixels. */
  strokeWidth = 0;
  /** Get dash array of circle. */
  strokeDashArray = '';
  /** Get dash offset of circle. */
  strokeDashOffset = 0;
  /** Size of full circle background. */
  fullDashOffset = 0;

  /** Circumference of ring, in pixels. */
  private circumference = 120;
  /** Listener for onscreen elements. */
  private observer: ResizeObserver;
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    zone: NgZone,
    element: ElementRef<HTMLElement>
  ) {
    // Manually run in NgZone or Angular won't trigger change detection.
    this.observer = new ResizeObserver(entries => zone.run(() => {
      for (let { contentRect: { width, height } } of entries) {
        // Determine position and size of circle.
        this.center = new Pos(width / 2, height / 2);
        this.circumference = Math.min(width, height);
        this.strokeWidth = (this.circumference - INNER_TEXT) / 2;
        this.radius = this.circumference / 2 - this.strokeWidth / 2;

        // Manipulate dotted line to draw partial ring.
        let r = 2 * Math.PI * this.radius;
        this.strokeDashArray = `${r} ${r}`;
        this.strokeDashOffset = r - r * (this.value / this.max);
        this.fullDashOffset = r - r;
      }
    }));

    this.observer.observe(element.nativeElement);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.observer.disconnect();
  }
}
