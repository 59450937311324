import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WORK_CONDITION_NAME, WorkCondition, WorkConditionFormula } from '../../../../../../../common/model/work/condition';

@Component({
  selector: 'app-setup-workflow-conditions',
  templateUrl: './setup-workflow-conditions.component.html',
  styleUrls: ['./setup-workflow-conditions.component.scss']
})
export class SetupWorkflowConditionsComponent {
  readonly WORK_CONDITION_NAME = WORK_CONDITION_NAME;
  readonly DEFAULT = new WorkConditionFormula();

  /** Conditions being configured. */
  @Input() conditions: WorkCondition[] = [];

  /** Emits when a condition is selected or created. */
  @Output() condition = new EventEmitter<WorkCondition>();
}
