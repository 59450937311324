import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentType } from '../../../../../../common/code/standard/common';
import { AttachmentUpload } from "../../../../../../common/model/attachment";
import { errorPartition } from "../../../../../../common/toolbox/message";
import { LogService } from '../../service/log.service';
import { fileAttachmentUpload } from '../../toolbox/file';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
  /** Label to display for upload. */
  @Input() label = 'Upload';
  /** True if file uploads are being processed. */
  @Input() loading = false;
  /** True to only allow one upload at a time. */
  @Input() single = false;
  /** Attachment type to default to. */
  @Input() type: string = AttachmentType.Other;

  /** Emits when new list of files is available. */
  @Output() uploads = new EventEmitter<AttachmentUpload[]>();

  /** True if currently dragging a file over drop area. */
  active = false;

  constructor(
    private log: LogService
  ) {}

  /** Callback when dragging items into element. */
  onDragEnter($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.loading) return;
    this.active = true;
  }

  /** Callback when ending drag over element. */
  onDragExit($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.loading) return;
    this.active = false;
    this.process($event.dataTransfer?.files);
  }

  /** Callback when manually adding files via browser. */
  onFiles($event: Event) {
    if (this.loading) return;
    let input = $event.target as HTMLInputElement;
    this.process(input.files);
    input.value = '';
  }

  /** Process list of attachments. */
  private async process(list: FileList | null | undefined): Promise<any> {
    if (!list?.length) return;
    let array = Array.from(this.single ? [list[0]!] : list);

    let results = await Promise.all(array.map(file => fileAttachmentUpload(file, this.type)));
    let [error, files] = errorPartition('There was an error with provided files.', results);
    if (error) return this.log.show(error);
    this.uploads.emit(files);
  }
}
