import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PASSWORD_HELP } from "../../../../../../../common/model/event/result/auth";
import { PASSWORD_REGEX } from "../../../../../../../common/model/user/password";
import { DialogRef, DIALOG_DATA } from '../../../../common/component/dialog/dialog.model';
import { validatorMatch } from '../../../../common/toolbox/angular';
import { PasswordModel } from '../../../../common/toolbox/auth';
import { SetupUserPasswordData, SetupUserPasswordReturn } from './setup-user-password-dialog.model';

@Component({
  selector: 'app-setup-user-password-dialog',
  templateUrl: './setup-user-password-dialog.component.html',
  styleUrls: ['./setup-user-password-dialog.component.scss']
})
export class SetupUserPasswordDialogComponent {
  readonly PASSWORD_HELP = PASSWORD_HELP;

  static title(data: SetupUserPasswordData) {
    return `${data.reset ? 'Reset' : 'Create'} ${data.name}'s Password`;
  }

  /** Password setup form. */
  builder = new FormBuilder().nonNullable.group({
    password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
    confirm: ['', [validatorMatch<PasswordModel>('password')]]
  });

  constructor(
    @Inject(DIALOG_DATA)
    public data: SetupUserPasswordData,
    protected dialogRef: DialogRef<SetupUserPasswordReturn>
  ) { }
}
