import { CdkDrag, CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { Directive, ElementRef, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, map, merge, startWith, switchMap, takeUntil } from 'rxjs';
import { Box } from "../../../../../common/model/box";

@Directive()
export abstract class GridDirective<T> {
  
  /** Additional content-projected drags passed in from parent component. */
  @Input() set drags(drags: CdkDrag[]) {
    this.parentDrags.next(drags);
  }

  /** Emit when an item reordering is complete. */
  @Output() reorder = new EventEmitter<void>();
  
  /** List of CdkDrag instances projected via content. */
  protected abstract contentRef: QueryList<CdkDrag>;
  /** List to manipulate. */
  protected abstract list: T[];

  /** Emits when new drag instances are projected via ng-content. */
  private contentDrags = new BehaviorSubject<CdkDrag[]>([]);
  /** Emits when parent component passes in more drags. */
  private parentDrags = new BehaviorSubject<CdkDrag[]>([]);
  /** Merged emitter of content and parent-projected drag instances. */
  private allDrags = new BehaviorSubject<CdkDrag[]>([]);
  /** Emits when directive is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    public element: ElementRef<HTMLElement>
  ) {}

  ngAfterContentInit() {
    // Get stream of content-projected drags
    this.contentRef.changes.pipe(takeUntil(this.destroy), startWith(this.contentRef), map(o => o.toArray())).subscribe(this.contentDrags);

    // Merge stream of content-projected and parent-projected options into one.
    combineLatest([this.contentDrags, this.parentDrags]).pipe(
      takeUntil(this.destroy),
      map(([content, parent]) => [...content, ...parent])
    ).subscribe(this.allDrags);

    // Listen to drag movement on children.
    this.allDrags.pipe(
      takeUntil(this.destroy),
      switchMap(drags => merge(...drags.map(drag => drag.moved)))
    ).subscribe($event => this.onMove($event));

    // Listen to drag ends on children.
    this.allDrags.pipe(
      takeUntil(this.destroy),
      switchMap(drags => merge(...drags.map(drag => drag.ended)))
    ).subscribe($event => this.onEnd($event));
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when dragging any child element. */
  abstract onMove($event: CdkDragMove): void;

  /** Callback when ending a drag for an item. */
  private onEnd($event: CdkDragEnd) {
    $event.source._dragRef.reset();
    this.reorder.next();
  }

  /** Correct offset of dragging item. */
  protected adjust($event: CdkDragMove, from: Box, to: Box) {
    // Offset drag origin to new element position.
    let distance = { x: to.l - from.l, y: to.t - from.t }
    let dragRef = $event.source._dragRef as any;
    dragRef._passiveTransform.x -= distance.x;
    dragRef._passiveTransform.y -= distance.y;

    // Trigger manual transform update.
    dragRef._applyRootElementTransform(
      dragRef._activeTransform.x - distance.x,
      dragRef._activeTransform.y - distance.y
    );
  }
}
