import { TypeInfo } from "../info/type";
import { arrayDefined } from "../toolbox/array";
import { mimePartBody } from "../toolbox/mime";
import { DATE_FORMAT_RFC_822, dateFormat } from "../toolbox/time";
import { MimePart } from "./mime";

/** Options for SMTP connections. */
export interface SmtpOptions {
  /** Host server to send email from. */
  host: string
  /** Port for host server. */
  port: number
  /** Email service to use. */
  service: string
  /** Address to send emails from. */
  address: string
  /** Name of email sender. */
  name: string
}

/** An attachment on an email payload. */
export class SmtpAttachment {
  constructor(
    /** Name of attachment. Will be used to reference attachment in HTML. */
    public filename = '',
    /** Payload of attachment. */
    public content = ''
  ) {}
}

/** An SMTP address. */
export class SmtpAddress {
  constructor(
    /** Name of address. */
    public name = '',
    /** Email address. */
    public address = ''
  ) {}

  /** Split from string. */
  static split(text: string) {
    let match = text.match(/^(?:"?([^"]*)"?\s)?(?:<?(.+@[^>]+)>?)$/) ?? [];
    return new SmtpAddress(match[1], match[2]);
  }

  /** Format out to "Name" <address@email.com> format. */
  static format(value: SmtpAddress) {
    return value.name ? `"${value.name}" <${value.address}>` : `<${value.address}>`;
  }
}

/** Payload for an email to send. */
export class SmtpMessage {
  constructor(
    /** Author of email. Defaults to configured server address. */
    public from?: SmtpAddress,
    /** Destination for email. */
    public to: SmtpAddress[] = [],
    /** Additional CC for email. */
    public cc?: SmtpAddress[],
    /** Subject line of email. Defaults to APP_NAME. */
    public subject?: string,
    /** Body of email */
    public body: MimePart = { type: 'text/plain', body: '' }
  ) {}

  static typeinfo: TypeInfo<SmtpMessage> = {
    from: new SmtpAddress(),
    to: [new SmtpAddress()],
    cc: [new SmtpAddress()],
    subject: ''
  }

  /** Format out a message to a standard DATA body. */
  static async body(message: SmtpMessage, from: SmtpAddress) {
    return arrayDefined([
      `From: ${SmtpAddress.format(message.from ?? from)}`,
      `To: ${message.to.map(SmtpAddress.format).join(', ')}`,
      message.cc?.length ? `Cc: ${message.cc.map(SmtpAddress.format)}` : undefined,
      message.subject ? `Subject: ${message.subject ?? 'no sub'}` : undefined,
      `Date: ${dateFormat(new Date(), DATE_FORMAT_RFC_822)}`,
      await mimePartBody(message.body),
      '.'
    ]).join('\r\n');
  }

  /** Get text parts of a message. */
  static parts() {

  }
}