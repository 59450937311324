import { Injectable } from '@angular/core';
import { ErrorResponse } from "../../../../../common/message/error";
import { RingBuffer } from "../../../../../common/model/ring-buffer";
import { StatusLevel } from "../../../../../common/toolbox/status";
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from '../component/snackbar/snackbar.service';
import { Log } from '../toolbox/log';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  /** Emits when list of logs change. */
  updates = new BehaviorSubject<Log[]>([]);

  /** Buffer of logs. */
  private buffer = new RingBuffer<Log>(100);

  constructor(
    private snackbar: SnackbarService
  ) {}

  /** Add a new log. */
  show(message: string | Log | ErrorResponse, status?: StatusLevel) {
    let log = Log.from(message, status);
    this.snackbar.open(log);
    this.buffer.push(log);
    this.updates.next([...this.buffer]);
  }

  /** Remove a log. */
  remove(i: number) {
    let log = this.buffer.at(i);
    if (!log) return;
    this.buffer.remove(i);
    this.updates.next([...this.buffer]);
  }

  /** Clear list of logs. */
  clear() {
    this.buffer.clear();
    this.updates.next([...this.buffer]);
  }
}
