
/** Valid filters when retrieving work queue items. */
export enum WorkFilter {
  /** Display all items. */
  All,
  /** Display bookmarked items. */
  Bookmarks,
  /** Display unviewed items. */
  Unviewed,
  /** Display viewed items. */
  Viewed,
  /** Display unviewed and viewed items. */
  Unworked,
  /** Display worked items. */
  Worked
};
