import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { PropertyType } from "../../../../../../common/model/property-type";
import { durationParts, durationSplit } from "../../../../../../common/toolbox/duration";
import { numberCoerce } from "../../../../../../common/toolbox/number";
import { ObjectKeys } from "../../../../../../common/toolbox/object";
import { DAYS_MAX, DAYS_MIN, DAY_MILLISECONDS, DatePeriod } from "../../../../../../common/toolbox/time";
import { fieldControlProviders } from '../../toolbox/angular';
import { KeyResponse, KeyResponseType, inputProcessKey } from '../../toolbox/input';
import { FieldControl } from '../field/field-control';

/** Keys of DurationComponent that are numbers. */
type DateString = 'days' | 'hours' | 'minutes' | 'seconds';
/** Keys of DurationComponent that are element references. */
type DateElement = ObjectKeys<DurationComponent, ElementRef<HTMLInputElement> | undefined>;

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
  providers: fieldControlProviders(DurationComponent)
})
export class DurationComponent extends FieldControl implements ControlValueAccessor {
  readonly PropertyType = PropertyType;
  readonly DatePeriod = DatePeriod;

  /** Reference to days input. */
  @ViewChild('dayInput', { static : true }) dayRef!: ElementRef<HTMLInputElement>;
  /** Reference to hours input. */
  @ViewChild('hourInput', { static : true }) hourRef!: ElementRef<HTMLInputElement>;
  /** Reference to minutes input. */
  @ViewChild('minuteInput', { static : true }) minuteRef!: ElementRef<HTMLInputElement>;
  /** Reference to seconds input. */
  @ViewChild('secondInput', { static : true }) secondRef!: ElementRef<HTMLInputElement>;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }

  /** True to make a value required. */
  @Input() required = true;
  /** True to show button. */
  @Input() button = true;
  /** Minimum value. */
  @Input() min = DAYS_MIN * DAY_MILLISECONDS;
  /** Maximum value. */
  @Input() max = DAYS_MAX * DAY_MILLISECONDS;

  /** Days of duration. */
  days = '';
  /** Hour of duration. */
  hours = '';
  /** Minute of duration. */
  minutes = '';
  /** Second of duration. */
  seconds = '';

  /** Value bound to duration field. */
  value: number | undefined;

  ngOnInit() {
    if (this.value === undefined) this.split();
  }

  writeValue(value?: number) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.changed(this.value = this.clamp(value));
    this.split(this.value);
  }

  /** Callback when typing into day field. */
  onDayKey($event: KeyboardEvent) {
    this.onTypeField($event, 'days', 'dayRef', 'hours', 'hourRef');
  }

  /** Callback when typing into hour field. */
  onHourKey($event: KeyboardEvent) {
    this.onTypeField($event, 'hours', 'hourRef', 'minutes', 'minuteRef', 'dayRef');
  }

  /** Callback when typing into minute field. */
  onMinuteKey($event: KeyboardEvent) {
    this.onTypeField($event, 'minutes', 'minuteRef', 'seconds', 'secondRef', 'hourRef');
  }

  /** Callback when typing into second field. */
  onSecondKey($event: KeyboardEvent) {
    this.onTypeField($event, 'seconds', 'secondRef', undefined, undefined, 'minuteRef');
  }

  /** Split milliseconds into components. */
  private split(ms = 0) {
    let split = durationSplit(ms);
    this.days = this.dayRef.nativeElement.value = `${split[0]}`.padStart(2, '0');
    this.hours = this.hourRef.nativeElement.value = `${split[1]}`.padStart(2, '0');
    this.minutes = this.minuteRef.nativeElement.value = `${split[2]}`.padStart(2, '0');
    this.seconds = this.secondRef.nativeElement.value = `${split[3]}`.padStart(2, '0');
  }

  /** Handle typing into field. */
  private async onTypeField(
    $event: KeyboardEvent,
    text: DateString,
    ref: DateElement,
    nextText?: DateString,
    nextRef?: DateElement,
    prevRef?: DateElement
  ) {
    if (this._readonly) { $event.preventDefault(); return; }
    
    let response = await inputProcessKey($event, true, nextRef ? this[nextRef].nativeElement : undefined);
    switch (response.type) {
      case KeyResponseType.Edit:
        return this.onSetField(text, ref, response);
      case KeyResponseType.EditNext:
        if (!nextText || !nextRef) return;
        return this.onSetField(nextText, nextRef, response);
      case KeyResponseType.ExitLeft:
        if (!prevRef) return;
        this[prevRef].nativeElement.focus();
        break;
      case KeyResponseType.ExitRight:
        if (!nextRef) return;
        this[nextRef].nativeElement.focus();
        this[nextRef].nativeElement.setSelectionRange(0, 0);
        break;
    }
  }

  /** Set value at position of field. */
  private onSetField(text: DateString, ref: DateElement, response: KeyResponse) {
    this[text] = response.value;
    this[ref].nativeElement.value = this[text];
    this[ref].nativeElement.focus();
    this[ref].nativeElement.setSelectionRange(response.position + 1, response.position + 1);

    // Assemble values into date and update validity.
    this.changed(this.value = this.clamp(durationParts(0, 0, this.days, this.hours, this.minutes, this.seconds, 0)));
  }

  /** Clamp value if needed. */
  private clamp(milliseconds: number | undefined) {
    if (this._readonly || milliseconds === undefined) return milliseconds;
    return numberCoerce(milliseconds, this.required, this.min, this.max);
  }
}
