import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClaimFilter, ClaimFilterAssigned, ClaimFilterPreview } from '../../../../../common/model/claim/filter';
import { ArraySome } from '../../../../../common/toolbox/array';
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { LogService } from './log.service';
import { TabService } from './tab.service';

/** A query to fetch a specific claim filter. */
export class ClaimFilterQuery {
  constructor(
    /** ID of claim filter. */
    public _id = ID_DEFAULT,
    /** Institution of claim filter. */
    public _inst = ID_DEFAULT
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class ClaimFilterService extends CachePreviewService<ClaimFilter, ClaimFilterQuery, ClaimFilterPreview> {
  readonly route = 'claim-filters/preview';
  readonly Type = ClaimFilterAssigned;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    public tabs: TabService
  ) {
    super(ClaimFilterQuery, log, dialog, http);
  }

  protected override multiple(queries: ArraySome<ClaimFilterQuery>) {
    return getRequest(this.http, 'claim-filters', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}