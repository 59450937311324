import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { Subject, debounceTime, merge, startWith, takeUntil } from 'rxjs';
import { ClaimDetailsType, ClaimStatus, ClaimType } from '../../../../../../common/code/standard/disputes';
import { ClaimGetRequest } from "../../../../../../common/message/claim";
import { Account } from '../../../../../../common/model/account/account';
import { ClaimJoin } from "../../../../../../common/model/claim/claim";
import { DisplayPartial, DisplayType, DisplayValue } from "../../../../../../common/model/display";
import { MemberJoin } from "../../../../../../common/model/member-join";
import { QueryOperator } from '../../../../../../common/model/query';
import { errorResponse } from "../../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { ModelGridComponent } from '../../../common/component/model/grid/model-grid.component';
import { GridConfig } from '../../../common/component/model/grid/model-grid.model';
import { AuthService } from '../../../common/service/auth.service';
import { ClaimService } from '../../../common/service/claim.service';
import { LogService } from '../../../common/service/log.service';
import { TableService } from '../../../common/service/table.service';
import { getRequest } from '../../../common/toolbox/request';
import { gridPaginateRequest } from '../../../common/toolbox/source/grid';
import { ServerSource } from '../../../common/toolbox/source/server';
import { dateOffset } from '../../../../../../common/toolbox/time';

/** Options for filtering claims. */
type ClaimListOptions = {
  /** Display claims for a specific Member. */
  member?: MemberJoin
  /** Display claims for a specific Account. */
  account?: Account
  /** Display a user's assigned claims. */
  _assigned?: string
  /** Display claims for a specified type. */
  type?: ClaimType
  /** Filter for claim details. */
  details?: ClaimDetailsType
  /** Display claims reported within a specified number of days */
  reportDate?: number
  /** Modifier for assigned user query. */
  assignedOperator?: QueryOperator
  /** Status to filter by. */
  status?: ClaimStatus
  /** Modifier for claim status search. */
  statusOperator?: QueryOperator
}

@Component({
  selector: 'app-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss'],
  host: {
    class: 'card'
  }
})
export class ClaimListComponent {

  /** Reference to grid containing events. */
  @ViewChild(ModelGridComponent) grid?: ModelGridComponent;

  /** Title for panel. */
  @Input() title = 'Claims';
  /** True to expand table to full height. */
  @Input() expand = false;

  /** Current filter being used to query claims. */
  @Input() set options(options: ClaimListOptions) {
    this.filter = {
      _account: options.account?._id,
      _insts: [this.auth._inst],
      _member: options.member?._id,
      _assigned: options._assigned,
      assignedOperator: options.assignedOperator,
      details: options.details,
      reportDate: options.reportDate ? dateOffset(new Date(), options.reportDate) : undefined,
      status: options.status,
      statusOperator: options.statusOperator,
      type: options.type
    };
    this.updated.next();
  };

  /** Configuration for table */
  config?: GridConfig;

  /** Emits when options are updated */
  protected updated = new Subject<void>();

  /** Current filter for fetching claims. */
  private filter: ClaimGetRequest = { _insts: [this.auth._inst] };
  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();

  constructor(
    public claimService: ClaimService,
    private log: LogService,
    private tables: TableService,
    private auth: AuthService,
    private http: HttpClient
  ) { }

  async ngOnInit() {
    let table = await this.tables.setting('disputes.claim.table');
    this.config = new GridConfig(new ServerSource<Partial<DisplayValue>>([], 0, DisplayType.Claim), table);

    /**
     * Refresh if:
     * 1) Selected new institution.
     * 2) Clicked refresh button.
     * 3) New claims added via interface (eg. intake).
     *
     * We debounce time to prevent multiple requests at once.
     */
    merge(this.config.source.dataRequest, this.updated, this.claimService.added)
      .pipe(startWith(0), takeUntil(this.destroy), debounceTime(0))
      .subscribe(() => this.refresh());
  }


  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Callback when selecting a claim. */
  onClaim(partial: DisplayPartial) {
    let claim = partial.claim as ClaimJoin;
    if (!claim) {
      this.log.show('Selected claim did not exist.', StatusLevel.Alert);
      return;
    }

    this.claimService.open({ _insts: [this.auth._inst], _ids: [claim._id] }, [claim]);
  }

  /** Update view with new query. */
  private async refresh() {
    if (!this.config) return;
    let claims = await getRequest(this.http, 'claims', {
      ...gridPaginateRequest(DisplayType.Claim, this.config.source),
      ...this.filter
    });

    if (errorResponse(claims)) {
      this.log.show(claims);
      return;
    }

    this.config.source.available = claims.available;
    this.config.source.items = DisplayValue.claims(claims.items);
  }
}
