import { Component } from '@angular/core';
import { DisplayPartial } from "../../../../../../../common/model/display";
import { Table } from "../../../../../../../common/model/table";
import { ID_DEFAULT } from "../../../../../../../common/toolbox/id";
import { NestedKey } from '../../../../../../../common/toolbox/keys';
import { ClientSource } from '../../../toolbox/source/client';
import { GridConfig } from '../../model/grid/model-grid.model';
import { FormMultiAccessor } from '../form-multi-accessor';

@Component({
  selector: 'app-form-grid',
  templateUrl: './form-grid.component.html',
  styleUrls: ['./form-grid.component.scss']
})
export class FormGridComponent extends FormMultiAccessor<DisplayPartial> {
  readonly ID_DEFAULT = ID_DEFAULT;

  /** List of items to display. */
  set items(partials: DisplayPartial[]) { this.config.source.items = partials; this.writeValue(this.value); }
  get items() { return this.config.source.items; }

  key: NestedKey<DisplayPartial> = 'account._id';
  display = (_: DisplayPartial) => '';

  /** Configuration for model table. */
  config = new GridConfig<DisplayPartial>(new ClientSource());

  /** Configure table after being passed ID. */
  configure(table = new Table(), partials: DisplayPartial[] = []) {
    this.config = new GridConfig(this.config.source, table);
    if (partials.length) this.items = partials;
    this.writeValue(this.value);
  }

  override update(partials: DisplayPartial[]) {
    if (super.update(partials)) {
      this.config.source.selection.next(new Set(partials));
      return true;
    } return false;
  }
}
