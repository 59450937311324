import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { ProfilePostResponse } from '../../../../../../../common/message/profile';
import { PermissionEdit } from '../../../../../../../common/model/permission';
import { Profile } from "../../../../../../../common/model/profile";
import { ColumnSize } from "../../../../../../../common/model/table";
import { WorkQueuePreview } from "../../../../../../../common/model/work/queue";
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { objectValues } from '../../../../../../../common/toolbox/object';
import { PermissionsGridData } from '../../../../common/component/permissions-grid/permissions-grid.model';
import { AuthService } from '../../../../common/service/auth.service';
import { LogService } from '../../../../common/service/log.service';
import { WorkQueueService } from '../../../../common/service/work-queue.service';
import { postRequest } from '../../../../common/toolbox/request';
import { ClientSource } from '../../../../common/toolbox/source/client';
import { SetupEditComponent } from '../../setup-edit.component';

@Component({
  selector: 'app-setup-profile-edit',
  templateUrl: './setup-profile-edit.component.html',
  styleUrls: ['./setup-profile-edit.component.scss']
})
export class SetupProfileEditComponent extends SetupEditComponent<Profile, ProfilePostResponse> {

  /** Current profile being edited. */
  value = idMaybe(new Profile());
  /** List of permissions being edited. */
  data = new PermissionsGridData();
  /** List of work queues to assign this profile to. */
  queues = new ClientSource<WorkQueuePreview>();
  /** Columns to display in work queue grid. */
  queueColumns: (keyof WorkQueuePreview)[] = ['name'];
  /** Sizes for work queue columns. */
  sizes: ColumnSize[] = ['1fr'];

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private auth: AuthService,
    private http: HttpClient,
    private queueService: WorkQueueService
  ) {
    super(elementRef, log);
  }

  push() {
    return postRequest(this.http, 'profiles', { items: [this.value] });
  }

  /** Reset current form with new profile. */
  async reset(value: MaybeId<Profile>) {
    this.value = value;
    this.relist();
  }

  /** Decompress profile permissions into editable list. */
  private async relist() {
    // Determine selected work queues of profile.
    let queues = new Set(this.value._queues);
    this.queues.items = await this.queueService.previews(this.auth._inst);
    this.queues.selection.next(new Set(this.queues.items.filter(d => queues.has(d._id))));

    // Get list of selected permissions for profile.
    this.data.main = PermissionEdit.record(this.value.permissions);
    this.data = { ...this.data };
  }

  /** Synchronize changes made back to profile. */
  protected resync() {
    this.value.permissions = PermissionEdit.array(objectValues(this.data.main));
    this.value._queues = [...this.queues.selection.value].map(d => d._id);
  }
}
