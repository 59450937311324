<div class="row">
  <app-field class="fill">
    <app-label>Form</app-label>
    <app-resource-select collection="forms" [required]="false" [(ngModel)]="config._form"></app-resource-select>
  </app-field>
  <app-field class="fill">
    <app-label>Form List</app-label>
    <app-resource-select collection="formLists" [required]="false" [(ngModel)]="config._form"></app-resource-select>
  </app-field>
</div>
<div class="row">
  <app-field class="fill">
    <app-label>Document Template Types</app-label>
    <app-code [(ngModel)]="config.documentTemplateTypes" [category]="CommonCode.DocumentTemplate" [multiple]="true"></app-code>
  </app-field>
  <app-field class="fill">
    <app-label>Attachment Type</app-label>
    <app-code [(ngModel)]="config.attachmentType" [category]="CommonCode.AttachmentType" [required]="false"></app-code>
  </app-field>
</div>