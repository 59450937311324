import { InjectionToken } from "@angular/core";

/** Injection token passing parent menu down to child. */
export const MENU_PARENT = new InjectionToken('MENU_PARENT');

/** Reason why the menu was closed. */
export enum MenuCloseReason {
  Click,
  Keydown,
  Tab
}