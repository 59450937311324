<app-card body class="fill column">
  <app-expansion-panel [closeOthers]="false">
    <app-expansion-panel-item *ngFor="let i of [1,2,3,4,5]">
      <app-expansion-panel-item-header>
        Test header {{i}}
      </app-expansion-panel-item-header>
      <app-expansion-panel-item-body>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mattis ultricies ligula, at hendrerit
          turpis volutpat non. Nullam volutpat egestas nisl, ut cursus ex lacinia sit amet. Sed lacinia commodo magna.
          Fusce risus dui, condimentum eget tristique vitae, mollis ut dui. Praesent at odio scelerisque, pharetra erat
          at, semper velit. Pellentesque vehicula eu justo vitae commodo. Duis gravida tellus velit, in facilisis sapien
          faucibus eget.</p>
      </app-expansion-panel-item-body>
    </app-expansion-panel-item>
  </app-expansion-panel>
  <app-expansion-panel>
    <app-expansion-panel-item *ngFor="let i of [1,2,3,4,5]">
      <app-expansion-panel-item-header>
        Test header {{i}}
      </app-expansion-panel-item-header>
      <app-expansion-panel-item-body>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mattis ultricies ligula, at hendrerit
          turpis volutpat non. Nullam volutpat egestas nisl, ut cursus ex lacinia sit amet. Sed lacinia commodo magna.
          Fusce risus dui, condimentum eget tristique vitae, mollis ut dui. Praesent at odio scelerisque, pharetra erat
          at, semper velit. Pellentesque vehicula eu justo vitae commodo. Duis gravida tellus velit, in facilisis sapien
          faucibus eget.</p>
      </app-expansion-panel-item-body>
    </app-expansion-panel-item>
  </app-expansion-panel>
</app-card>