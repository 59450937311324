<app-card>
  <app-card-header>
    <div>Disputed Transactions</div>
  </app-card-header>
  <app-card-body>
    <app-expansion-panel *ngIf="headerConfig?.form?.fields?.length" readonly>
      <app-expansion-panel-item>
        <app-expansion-panel-item-header>
          <app-form class="fill" style="margin-right: 4rem" [config]="headerConfig"></app-form>
        </app-expansion-panel-item-header>
      </app-expansion-panel-item>
    </app-expansion-panel>
    <div class="column">
      <app-expansion-panel dark>
        <app-expansion-panel-item *ngFor="let state of states" [headerless]="!state.header.form.fields.length">
          <app-expansion-panel-item-header>
            <app-form class="fill" [config]="state.header" (changed)="onChange(state.header.value!.dispute!)"></app-form>
            <div class="flex" (click)="$event.stopPropagation()">
              <app-icon [visible-if]="!state.meetsThreshold" color="red" tooltip="This transaction is under the dispute amount threshold.">money_off</app-icon>
              <button icon-button tooltip="{{state.form ? 'Show Ledger' : 'Show Dispute'}}" (click)="state.form = !state.form">{{(state.form ? DisplayType.Ledger : DisplayType.Dispute) | icon:DISPLAY_TYPE_ICON}}</button>
              <app-comment-list-button collection="disputes" tooltip="Comments" header="Dispute Comments" [value]="state.header.value!.dispute!"></app-comment-list-button>
            </div>
          </app-expansion-panel-item-header>
          <app-expansion-panel-item-body class="column">
            <app-banner *ngIf="state.body.value?.dispute?.deleted" [color]="'alert' | statusColor">
              <div>This dispute has been abandoned.</div>
            </app-banner>
            <div class="panel-content" [class.no-workflow]="!workflow?.steps?.length">
              <app-form *ngIf="state.form; else ledger" [config]="state.body" (changed)="onChange(state.body.value!.dispute!)"></app-form>
              <ng-template #ledger>
                <app-card>
                  <app-model-grid [expand]="true" [config]="state.ledger"></app-model-grid>
                </app-card>
              </ng-template>
              <app-workflow *ngIf="workflow?.steps?.length" [compact]="true" [mode]="TaskMode.Dispute" [workflow]="workflow" [context]="state.context" (task)="onTask($event, state)"></app-workflow>
            </div>
          </app-expansion-panel-item-body>
        </app-expansion-panel-item>
      </app-expansion-panel>
      <div class="totals row">
        <app-field class="fill">
          <app-label>Total Disputed</app-label>
          <app-field>
            <app-currency [(ngModel)]="total.disputed" readonly></app-currency>
          </app-field>
        </app-field>
        <app-field class="fill">
          <app-label>Total Credit</app-label>
          <app-field>
            <app-currency [(ngModel)]="total.credit" readonly></app-currency>
          </app-field>
        </app-field>
        <app-field class="fill">
          <app-label>Total Recovered</app-label>
          <app-field>
            <app-currency [(ngModel)]="total.recovered" readonly></app-currency>
          </app-field>
        </app-field>
        <app-field class="fill">
          <app-label>Total Loss</app-label>
          <app-field>
            <app-currency [tooltip]="total.loss < 0  ? 'Loss must not be negative': ''" [(ngModel)]="total.loss" readonly></app-currency>
          </app-field>
        </app-field>
      </div>
    </div>
  </app-card-body>
</app-card>