import { TypeInfo } from "../../info/type";
import { DisplayType, DisplayValue } from "../../model/display";
import { WorkQueue, WorkQueueJoin, WorkQueuePreview } from "../../model/work/queue";
import { ID_DEFAULT, MaybeId, idOmit } from "../../toolbox/id";
import { EnumValidator } from "../../validator/enum";
import { ItemRequest } from "../item";
import { SuccessResponse } from "../success";

/** Request to search work queues. */
export class WorkQueueGetRequest {
  constructor(
    /** Institution of work queues. */
    public _insts: string[] = [],
    /** ID of work queues. */
    public _ids?: string[],
    /** Type of queues to fetch. */
    public type?: DisplayType,
    /** Last build date of work queue. */
    public buildDate?: Date,
    /** Name of work queue's definition. */
    public name?: string
  ) {}

  static typeinfo: TypeInfo<WorkQueueGetRequest> = {
    _insts: [ID_DEFAULT, ID_DEFAULT],
    _ids: [ID_DEFAULT, ID_DEFAULT],
    type: new EnumValidator(DisplayType),
    buildDate: new Date(),
    name: ''
  }
}

/** Response of searching work queues. */
export type WorkQueueGetResponse = WorkQueue[];

/** Request to preview work queues. */
export class WorkQueuePreviewGetRequest extends WorkQueueGetRequest {}

/** Result of previewing work queues. */
export type WorkQueuePreviewGetResponse = WorkQueuePreview[];

/** Request to get work queues joined on their filter rules. */
export class WorkQueueFilterRulesGetRequest extends WorkQueueGetRequest {}

/** Result of getting work queues joined on their filter rules. */
export type WorkQueueFilterRulesGetResponse = WorkQueueJoin[];

/** Request to get assigned work queues. */
export class WorkQueueAssignedGetRequest {
  constructor(
    /** Institution of work queues. */
    public _inst = ID_DEFAULT,
    /** Type of queues to fetch. */
    public type = DisplayType.Account
  ) {}

  static typeinfo: TypeInfo<WorkQueueAssignedGetRequest> = {
    type: new EnumValidator(DisplayType)
  }
}

/** Response of getting work queues. */
export type WorkQueueAssignedGetResponse = WorkQueue[];

/** Request to add new work queues. */
export class WorkQueuePostRequest {
  constructor(
    /** Work queues to add. */
    public items: MaybeId<WorkQueue>[] = []
  ) {}

  static typeinfo: TypeInfo<WorkQueuePostRequest> = {
    items: [idOmit(new WorkQueue())]
  }
}

/** Response of adding work queues. */
export type WorkQueuePostResponse = SuccessResponse;

/** Request to preview built work queue. */
export class WorkQueuePrebuildPostRequest {
  constructor(
    /** Institution of work queue. */
    public _inst = ID_DEFAULT,
    /** Definition to preview. */
    public _queue = ID_DEFAULT
  ) {}
}

/** Response of previewing a built work queue */
export class WorkQueuePrebuildPostResponse<T extends DisplayType = DisplayType> {
  constructor(
    /** Type of this queue. */
    public type = DisplayType.Account,
    /** Number of items that would be in queue. */
    public count = 0,
    /** Sample of items that would be in queue */
    public sample: DisplayValue[T][],
    /** Filters that would be used to search items. */
    public filters = {} as any
  ) {}
}

/** Request to build work queues. */
export class WorkQueueBuildPostRequest {
  constructor(
    /** Institution to build queues. */
    public _inst = ID_DEFAULT,
    /** Specific queues to rebuild. */
    public _ids?: string[]
  ) {}

  static typeinfo: TypeInfo<ItemRequest> = {
    _ids: [ID_DEFAULT, ID_DEFAULT]
  }
}

/** Information about a built queue. */
export class WorkQueueBuildResult {
  constructor(
    /** ID of this work queue. */
    public _queue = ID_DEFAULT,
    /** Items in this queue. */
    public items = 0
  ) {}
}

/** Response of previewing a built work queue */
export type WorkQueueBuildPostResponse = WorkQueueBuildResult[];

/** Request to recalculate counts on work queues. */
export class WorkQueueRefreshPatchRequest extends ItemRequest {}

/** Response when refreshing work queues. */
export type WorkQueueRefreshPatchResponse = SuccessResponse;

/** Request to clear work queues. */
export class WorkQueueClearPatchRequest extends ItemRequest {}

/** Response when clearing work queues. */
export type WorkQueueClearPatchResponse = SuccessResponse;

/** Request to delete work queues. */
export class WorkQueueDeleteRequest extends ItemRequest {}

/** Result of deleting work queues. */
export type WorkQueueDeleteResponse = SuccessResponse;