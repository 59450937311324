import { ArraySome } from "../toolbox/array";
import { BufferLike } from "../toolbox/binary";

/** Any type of MIME type. */
export type MimeType = MimeTypeSimple | MimeTypeMultipart;
/** A multipart MIME type. */
export type MimeTypeMultipart = 'multipart/alternative' | 'multipart/form-data' | 'multipart/mixed' | 'multipart/related';
/** A simple MIME type. */
export type MimeTypeSimple = `application/${string}` | `audio/${string}` | `font/${string}` | `image/${string}` | `message/${string}` | `model/${string}` | `text/${string}` | `video/${string}`;
/** A mime type body to be encoded */
export type MimePart = MimePartMultipart | MimePartSimple | MimePartContent;

/** Possible content dispositions for mime types. */
export type MimeContentDisposition = 'inline' | 'attachment';

/** General categories of document types. */
export enum MimeCategory {
  Archive,
  Audio,
  Binary,
  Calendar,
  Chart,
  Document,
  Font,
  Image,
  Multipart,
  PDF,
  Slideshow,
  Spreadsheet,
  Text,
  Video
};

/** A multipart mime body. */
export interface MimePartMultipart {
  /** Content type. */
  type: MimeTypeMultipart,
  /** Child mime bodies. */
  body: ArraySome<MimePart>
}

/** A single-part mime body. */
export interface MimePartSimple {
  /** Content type. */
  type: MimeTypeSimple
  /** Content of body. */
  body: BufferLike
}

/** A single-part mime body with a content disposition. */
export interface MimePartContent {
  /** Content type. */
  type: MimeTypeSimple
  /** Content of body. */
  body: BufferLike
  /** Type of content disposition. */
  disposition: MimeContentDisposition
  /** Name of inline file or attachment. */
  filename: string
}