import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ClaimACHReportReason, DisputeStatus } from '../../../../../../common/code/standard/disputes';
import { DisputesCode } from '../../../../../../common/code/system';
import { CLAIM_ACH_REPORT_REASON_FILTER } from '../../../../../../common/model/claim/ach';
import { ClaimUnion } from '../../../../../../common/model/claim/claim';
import { GroupKey } from '../../../../../../common/model/group';
import { ArraySome } from '../../../../../../common/toolbox/array';
import { claimUnion } from '../../../../../../common/toolbox/claim';
import { CURRENCY_SCALE } from '../../../../../../common/toolbox/currency';
import { enumValues } from '../../../../../../common/toolbox/enum';
import { keyLast, keyNestedSet } from '../../../../../../common/toolbox/keys';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { titleCase } from '../../../../../../common/toolbox/string';
import { MONTH_NAME_SHORT } from '../../../../../../common/toolbox/time';
import { ChartSeries } from '../../../common/component/chart/chart.model';
import { PropertyValuePipe } from '../../../common/pipe/property-value.pipe';
import { AuthService } from '../../../common/service/auth.service';
import { CodeTypeService } from '../../../common/service/code-type.service';
import { LogService } from '../../../common/service/log.service';
import { TabService } from '../../../common/service/tab.service';
import { postRequest } from '../../../common/toolbox/request';
import { ClaimReportComponent } from '../report/claim-report.component';
import { ClaimStatReduceType } from '../../../../../../common/message/claim';
import { ColorCode } from '../../../../../../common/toolbox/color';

/** Set of intial series to display. */
const STATUS_SHOW = new Set([
  DisputeStatus.NotWorked,
  DisputeStatus.Arbitration,
  DisputeStatus.Chargeback,
  DisputeStatus.Denied,
  DisputeStatus.MerchantRefunded,
  DisputeStatus.Prearbitration,
  DisputeStatus.Withdrawn
]);

@Component({
  selector: 'app-claim-chart',
  templateUrl: './claim-chart.component.html',
  styleUrls: ['./claim-chart.component.scss']
})
export class ClaimChartComponent {
  /** Claim union to pull property names from. */
  readonly CLAIM_UNION = claimUnion();

  /** List of selectable group keys. */
  groupKeys: ArraySome<GroupKey<ClaimUnion>> = ['month', 'newAccount', 'status', 'type', 'achDetails.type', 'cardDetails.type', 'checkDetails.reason'];
  /** Current selected group key. */
  groupKey = this.groupKeys[0];
  /** List of selectable reduce keys. */
  reduceKeys: ArraySome<ClaimStatReduceType> = [ClaimStatReduceType.Balance, ClaimStatReduceType.DisputeStatus];
  /** Current selected reduce key. */
  reduceKey = this.reduceKeys[0];

  /** Overrides for colors for particular series. */
  colorOverrides: Record<string, ColorCode> = {
    'Disputed Amount': ColorCode.Blue,
    'Loss': ColorCode.Red,
    'Credit': ColorCode.Yellow,
    'Recovered': ColorCode.Green
  }
  /** Title for x-axis. */
  xAxis = '';
  /** Title for y-axis. */
  yAxis = '';
  /** Names of each category. */
  categories: string[] = [];
  /** Calculated list of series for chart. */
  series: ChartSeries[] = [];

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private log: LogService,
    private tabs: TabService,
    private codes: CodeTypeService,
    private pipe: PropertyValuePipe
  ) { }

  ngOnInit() {
    this.render();
  }

  async render() {
    let result = await postRequest(this.http, 'claims/stat', { _insts: [this.auth._inst], groupKey: this.groupKey, reduceKey: this.reduceKey, conditions: [] });
    if (errorResponse(result)) {
      this.log.show('Could not get claim stats.');
      return;
    }
    //We don't send loss because it's always just credit-recovered
    if (this.reduceKey === ClaimStatReduceType.Balance) {
      result = result.map((el: any)=>({...el, Loss: (el.Credit-el.Recovered)}))
    }
    // Set new axis labels.
    this.xAxis = titleCase(keyLast(this.groupKey));
    this.yAxis = '$ Amount';

    // Order groups alphabetically or numerically.
    let groups = result.sort((a, b) => {
      let na = Number.parseInt(a._id);
      let nb = Number.parseInt(b._id);
      return Number.isNaN(na) || Number.isNaN(nb) ? a._id.localeCompare(b._id) : na - nb;
    });

    // Add some hardcoded filters for excessive numbers of groups.
    // TODO Consider annotating this in CodeProperty, maybe.
    switch (this.groupKey) {
      case 'achDetails.type':
        groups = groups.filter(g => CLAIM_ACH_REPORT_REASON_FILTER.includes(g._id as ClaimACHReportReason));
        break;
    }

    switch (this.reduceKey) {
      case ClaimStatReduceType.Balance:
        this.series = await Promise.all(['Disputed Amount', 'Recovered', 'Credit', 'Loss'].map(async category => {
          let data: number[] = [];
          for (let group of groups as any) {
            data.push((group[category] ?? 0) / CURRENCY_SCALE);
          }

          return {
            data,
            name: category,
            color: this.colorOverrides[category]
          }
        }));
        break;
      case ClaimStatReduceType.DisputeStatus:
      default:
        // Get list of chart series based off result.
        this.series = await Promise.all(enumValues<DisputeStatus>(DisputeStatus).map(async status => {
          let data: number[] = [];
          for (let group of groups) {
            data.push((group[status] ?? 0) / CURRENCY_SCALE);
          }

          return {
            data,
            name: await this.codes.codename(status, { _inst: this.auth._inst, category: DisputesCode.DisputeStatus }),
            show: STATUS_SHOW.has(status)
          }
        }));
    }

    // Get category names, list of months or formatting group ids.j
    if (this.groupKey === 'month') {
      this.categories = groups.map(group => MONTH_NAME_SHORT[Number.parseInt(group._id) - 1]!) // i is 1 based for months, array is zero based
    } else {
      this.categories = await Promise.all(groups.map(group => {
        keyNestedSet(this.groupKey as any, this.CLAIM_UNION, group._id);
        return this.pipe.value(this.auth._inst, this.CLAIM_UNION, this.CLAIM_UNION, this.groupKey as any);
      }));
    }
  }

  /** Open claim report component. */
  open() {
    this.tabs.open(ClaimReportComponent, {});
  }
}
