import { Subject } from "rxjs";
import { DisplayPartial } from "../../../../../../../common/model/display";
import { Icon } from "../../../../../../../common/model/icon";
import { ColorCode } from "../../../../../../../common/toolbox/color";

/** Configuration for an icon displayed in a grid. */
export class GridIcon {
  constructor(
    /** Icon to display. */
    public icon = Icon.Search,
    /** Override for icon color. */
    public color?: ColorCode,
    /** Tooltip for icon. */
    public tooltip: string | null = null,
    /** Emits when icon is clicked. */
    public click = new Subject<DisplayIcon>()
  ) {}
}

/** A display partial with an attached icon. */
export type DisplayIcon = DisplayPartial & { icon?: GridIcon };