import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthGuard } from "./common/guard/auth.guard";
import { DashboardComponent } from "./module/main/dashboard/dashboard.component";
import { ErrorComponent } from "./module/main/error/error.component";
import { LoginComponent } from "./module/main/login/login.component";
import { ResetPasswordComponent } from "./module/main/reset-password/reset-password.component";
import { UnsubscribeComponent } from "./module/main/unsubscribed/unsubscribe.component";

@NgModule({
  imports: [RouterModule.forRoot([
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'unsubscribe/:job', component: UnsubscribeComponent },
    { path: 'error/:message', component: ErrorComponent },
    { path: '**', component: DashboardComponent, canActivate: [AuthGuard] }
  ])],
  exports: [RouterModule]
})
export class AppRoutingModule {}