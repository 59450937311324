import { arrayFill } from "./array";
import { randomElement, randomRange } from "./random";

/** Valid license letters. */
const LICENSE_LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
/** Valid license digits. */
const LICENSE_DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

/** Information for formatting out a license number. */
class LicenseSpan {
    constructor(
        /** Available values. */
        public values: string[],
        /** Minimum values to output. */
        public low = 1,
        /** Maximum values to output. */
        public high = low
    ) {}
}

/** Utility for parsing and generating license plate numbers. */
export class LicenseFormat {
    /** List of available patterns. */
    private spans: LicenseSpan[][];

    constructor(
        /** List of patterns for parser. */
        public patterns: RegExp[] = []
    ) {
        this.spans = patterns.map(p => this.parse(p));
    }

    /** Validate a number is correct. */
    validate(number: string) {
        return this.patterns.some(p => p.test(number));
    }

    /** Generate a random number. */
    generate() {
      return randomElement(this.spans).flatMap(span => arrayFill(randomRange(span.low, span.high), () => randomElement(span.values))).join('');
    }

    /** Output license number for given pattern. */
    private parse(pattern: RegExp) {
        return this.pattern(pattern.source);
    }

    /** Parse license plate pattern. */
    private pattern(s: string) {
        let i = 0, spans: LicenseSpan[] = [];
        for (let c = s[i]; c; c = s[++i]) {
            switch (c) {
            case '\\': {
                let [span, o] = this.span(LICENSE_DIGITS, s, i + 2);
                spans.push(span);
                i += o + 1;
            } break;
            case '[': {
                let [span, o] = this.span(LICENSE_LETTERS, s, i + 5);
                spans.push(span);
                i += o + 4;
            } break;
            case '^': case '$':
                break;
            default:
                spans.push(new LicenseSpan([c]));
                break;
            }
        }

        return spans;
    }

    /** Parse range descriptor in license plate. */
    private span(values: string[], s: string, i: number) {
        let match = s.slice(i).match(/^{(\d+)(?:,(\d+))?}/);
        let span = match ? new LicenseSpan(values, +match[1]!, +(match[2] ?? match[1]!)) : new LicenseSpan(values);
        let offset = match?.[0]?.length ?? 0;
        return [span, offset] as const;
    }
}