import { Component, Inject } from '@angular/core';
import { Member } from "../../../../../common/model/member";
import { MemberErrorJoin, MemberJoin } from '../../../../../common/model/member-join';
import { FeatureType } from "../../../../../common/model/organization/feature";
import { Permission } from '../../../../../common/model/permission';
import { TAB_DATA } from '../../common/component/tab/bar/tab-bar.model';
import { LogService } from '../../common/service/log.service';
import { MemberData } from './member.model';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
  host: {
    class: 'column fill'
  }
})
export class MemberComponent {
  readonly FeatureType = FeatureType;
  readonly Permission = Permission;

  /** Current member being displayed. */
  member!: MemberJoin;
  /** Loading status for member comment. */
  loading = false;

  /** Get title to display for tab. */
  static title(data: MemberData) {
    return Member.fullname(data.member);
  }

  constructor(
    @Inject(TAB_DATA)
    public data: MemberData,
    private log: LogService
  ) {
    this.remember(data.member);
  }

  /** Update displayed member. */
  protected remember(memberError: MemberErrorJoin) {
    let [error, member] = MemberErrorJoin.partition(memberError);
    if (error) this.log.show(error);
    this.member = member;
  }
}
