import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ErrorResponse } from "../../../../../../common/message/error";
import { ImportPostResponse } from "../../../../../../common/message/import";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { postRequest } from '../../../common/toolbox/request';

@Component({
  selector: 'app-build-import',
  templateUrl: './build-import.component.html',
  styleUrls: ['./build-import.component.scss']
})
export class BuildImportComponent {

  /** True if import is being processed. */
  loading = false;
  /** Results of performing import. */
  response?: ImportPostResponse | ErrorResponse;

  static title() {
    return 'Run Import';
  }

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private log: LogService
  ) {}

  /** Perform full import. */
  async run() {
    this.loading = true;
    this.response = await postRequest(this.http, 'import', { _org: this.auth._org, _inst: this.auth._inst});
    if (errorResponse(this.response)) this.log.show(this.response);
    else this.log.show('Import was successful.');
    this.loading = false;
  }
}
