import { Icon, IconMap } from "../../../../../common/model/icon";
import { PropertyType } from "../../../../../common/model/property-type";

/** Icon of each property type. */
export const PROPERTY_TYPE_ICON: IconMap<PropertyType> = {
  [PropertyType.Boolean]: Icon.CheckBox,
  [PropertyType.Code]: Icon.Code,
  [PropertyType.Currency]: Icon.AttachMoney,
  [PropertyType.Date]: Icon.Today,
  [PropertyType.Email]: Icon.Email,
  [PropertyType.Member]: Icon.Face,
  [PropertyType.Number]: Icon.Numbers,
  [PropertyType.Phone]: Icon.Phone,
  [PropertyType.String]: Icon.Notes,
  [PropertyType.Transaction]: Icon.ReceiptLong,
  [PropertyType.User]: Icon.SupportAgent
};