import { Pipe } from '@angular/core';
import { Member, MemberLike } from '../../../../../common/model/member';

@Pipe({
  name: 'memberName'
})
export class MemberNamePipe {
  transform(member: MemberLike) {
    return Member.fullname(member);
  }
}
