import { Pipe } from '@angular/core';

@Pipe({
  name: 'today'
})
export class TodayPipe {
  transform(value?: string | Date) {
    return value ?? new Date();
  }
}
