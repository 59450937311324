import { Component } from '@angular/core';
import { Code } from '../../../../../../../../common/model/code-type';
import { WORK_CONDITION_MODE_NAME, WorkConditionClaimStatus } from '../../../../../../../../common/model/work/condition';
import { enumMapPairs } from '../../../../../../../../common/toolbox/enum';
import { AuthService } from '../../../../../common/service/auth.service';
import { CodeTypeService } from '../../../../../common/service/code-type.service';
import { WorkConditionAccessor } from '../setup-workflow-condition.model';
import { DisputesCode } from '../../../../../../../../common/code/system';

@Component({
  selector: 'app-setup-workflow-condition-claim-status',
  templateUrl: './setup-workflow-condition-claim-status.component.html',
  styleUrls: ['./setup-workflow-condition-claim-status.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupWorkflowConditionClaimStatusComponent extends WorkConditionAccessor<WorkConditionClaimStatus> {
  readonly DisputesCode = DisputesCode;

  /** List of types to select. */
  statuses: Code[] = [];
  /** List of modes to select. */
  modes = enumMapPairs(WORK_CONDITION_MODE_NAME);

  constructor(
    protected auth: AuthService,
    private codeTypes: CodeTypeService
  ) {
    super();
  }

  async ngOnInit() {
    this.statuses = await this.codeTypes.list({ _inst: this.auth._inst, category: DisputesCode.ClaimStatus });
  }
}
