import { Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldConfig, FieldConfigClass, FieldEmpty } from '../../../../../../../../common/model/form/field-config';
import { PropertyType } from '../../../../../../../../common/model/property-type';
import { TablePreview } from '../../../../../../../../common/model/table';
import { Newable, deepCopy } from '../../../../../../../../common/toolbox/object';
import { DIALOG_DATA, DialogRef } from '../../../../../common/component/dialog/dialog.model';
import { AuthService } from '../../../../../common/service/auth.service';
import { TableService } from '../../../../../common/service/table.service';
import { SetupFormFieldBooleanComponent } from '../boolean/setup-form-field-boolean.component';
import { SetupFormFieldCodeComponent } from '../code/setup-form-field-code.component';
import { SetupFormFieldEmptyComponent } from '../empty/setup-form-field-empty.component';
import { FormFieldAccessor } from '../setup-form-field.model';
import { SetupFormFieldStringComponent } from '../string/setup-form-field-string.component';
import { SetupFormFieldData, SetupFormFieldReturn } from './setup-form-field-dialog.model';

/** Component for configuring each form field type. */
export const FORM_FIELD_SETUP_COMPONENT = {
  [PropertyType.Boolean]: SetupFormFieldBooleanComponent,
  [PropertyType.Code]: SetupFormFieldCodeComponent,
  [PropertyType.Currency]: SetupFormFieldEmptyComponent,
  [PropertyType.Date]: SetupFormFieldEmptyComponent,
  [PropertyType.Email]: SetupFormFieldEmptyComponent,
  [PropertyType.Member]: SetupFormFieldEmptyComponent,
  [PropertyType.Number]: SetupFormFieldEmptyComponent,
  [PropertyType.Phone]: SetupFormFieldEmptyComponent,
  [PropertyType.String]: SetupFormFieldStringComponent,
  [PropertyType.Transaction]: SetupFormFieldEmptyComponent,
  [PropertyType.User]: SetupFormFieldEmptyComponent
};

@Component({
  selector: 'app-setup-form-field-dialog',
  templateUrl: './setup-form-field-dialog.component.html',
  styleUrls: ['./setup-form-field-dialog.component.scss']
})
export class SetupFormFieldDialogComponent {
  /** Container to inject components. */
  @ViewChild('container', { static : true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** Component injected into template. */
  component?: FormFieldAccessor;
  /** List of tables to select from. */
  tables: TablePreview[] = [];
  /** Configuration for current field. */
  config: FieldConfig = new FieldEmpty(PropertyType.String);

  static title(data: SetupFormFieldData) {
    return `Configure ${data.property.name}`;
  }

  constructor(
    @Inject(DIALOG_DATA)
    public data: SetupFormFieldData,
    protected dialogRef: DialogRef<SetupFormFieldReturn>,
    private authService: AuthService,
    private tableService: TableService
  ) {
    this.tableService.previews(this.authService._inst).then(tables => this.tables = tables);
  }

  async ngOnInit() {
    let prototype: Newable<FormFieldAccessor> = FORM_FIELD_SETUP_COMPONENT[this.data.property.type];
    this.component = this.containerRef.createComponent(prototype).instance;
    this.component.config = this.config = deepCopy(this.data.field.config) ?? new FieldConfigClass()[this.data.property.type];
    this.component.property = this.data.property;
    this.component.reset();
  }

  apply() {
    this.dialogRef.close(this.component?.config ?? this.config);
  }
}
