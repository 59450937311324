import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { Profile, ProfilePreview } from '../../../../../../common/model/profile';
import { idMaybe } from '../../../../../../common/toolbox/id';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { CollectionService } from '../../../common/service/collection.service';
import { LogService } from '../../../common/service/log.service';
import { ProfileQuery, ProfileService } from '../../../common/service/profile.service';
import { SetupComponent } from '../setup.component';
import { SetupData } from '../setup.model';

@Component({
  selector: 'app-setup-profile',
  templateUrl: './setup-profile.component.html',
  styleUrls: ['./setup-profile.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupProfileComponent extends SetupComponent<Profile, ProfileQuery, ProfilePreview> {
  readonly Type = Profile;
  readonly collection = 'profiles';
  readonly route = 'profiles';
  resource = idMaybe(new Profile());

  static title() {
    return 'Profiles';
  }

  constructor(
    public log: LogService,
    public dialog: DialogService,
    public http: HttpClient,
    public auth: AuthService,
    public service: ProfileService,
    public collections: CollectionService,
    @Optional() @Inject(TAB_DATA) public data?: SetupData
  ) {
    super();
  }
}
