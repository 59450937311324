<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header class="row">
      <app-field class="fill">
        <app-label>Name</app-label>
        <app-string [(ngModel)]="value.name"></app-string>
      </app-field>
      <app-field class="fill">
        <app-label>Category</app-label>
        <app-sublabel>
          <app-hover-help [tooltip]="CATEGORY_HELP"></app-hover-help>
        </app-sublabel>
        <app-string [(ngModel)]="value.category" [disabled]="value | setupReadonly"></app-string>
      </app-field>
    </app-setup-base-header>
    <app-setup-base-body>
      <div class="list" [list-grid]="codes">
        <app-drag-chip cdkDrag cdkDragBoundary=".list" *ngFor="let c of codes; let i = index">
          <app-string class="key" placeholder="Key" [(ngModel)]="c.key" [disabled]="c.system" (input)="resync()"></app-string>
          <app-string class="value" placeholder="Value" [(ngModel)]="c.value" (input)="resync()"></app-string>
          <button icon-button (click)="onDelete(i)" tooltip="Delete" [disabled]="c.system">delete</button>
          <button icon-button cdkDragHandle>drag_indicator</button>
        </app-drag-chip>
        <button button-prefix="add" position="before" *ngIf="!value.system || !value.locked" (click)="onNew()">New</button>
      </div>
    </app-setup-base-body>
    <app-setup-base-footer>
      <app-icon *ngIf="value.system && value.locked" tooltip="This code type is locked, new values cannot be added.">lock</app-icon>
      <div class="fill"></div>
      <button (click)="onSubmit()">Save</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>