import { MaybeId } from "./id";

/** Get a value without a system flag. */
export type NoSystem<T> = Omit<T, 'system'>;
/** Value that has a system flag. */
export type MaybeSystem<T = any> = NoSystem<T> & { system?: boolean };

/** Revert illegal changes to a mixed array of system and non-system values. */
export function systemRevert<T extends MaybeSystem>(values: T[] | undefined, patch: MaybeId<T>[] | undefined, compare: (a: T, b: MaybeId<T>) => boolean) {
  if (!values || !patch) return;

  // Revert modifications to system values.
  let required = values.filter(v => v.system);
  for (let i = 0; i < patch.length; ++i) {
    let value = patch[i]!;
    let system = required.find(v => compare(v, value));
    if (system) patch[i] = system;
  }

  // Ensure all system values are present.
  for (let system of required) {
    let value = patch.find(v => compare(system, v));
    if (!value) patch.push(system);
  }
}