import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { CodeTypePostResponse } from '../../../../../../../common/message/code-type';
import { Code, CodeType } from "../../../../../../../common/model/code-type";
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { LogService } from '../../../../common/service/log.service';
import { postRequest } from '../../../../common/toolbox/request';
import { SetupEditComponent } from '../../setup-edit.component';

@Component({
  selector: 'app-setup-code-type-edit',
  templateUrl: './setup-code-type-edit.component.html',
  styleUrls: ['./setup-code-type-edit.component.scss']
})
export class SetupCodeTypeEditComponent extends SetupEditComponent<CodeType, CodeTypePostResponse> {
  readonly CATEGORY_HELP = 'Unique identifier for this code type. Can include:\n * Uppercase and lowercase letters.\n * Numbers and underscores.';

  /** Current code type being edited. */
  value = idMaybe(new CodeType());
  /** Code type converted to an editable list form. */
  codes: Code[] = [];

  constructor(
    elementRef: ElementRef,
    log: LogService,
    private http: HttpClient,
  ) {
    super(elementRef, log);
  }

  /** Add new code type to list. */
  onNew() {
    this.codes.push(new Code());
    this.resync();
  }

  /** Delete specified code type from list. */
  onDelete(i: number) {
    this.codes.splice(i, 1);
    this.resync();
  }

  /** Submit current changes to code type. */
  push() {
    return postRequest(this.http, 'code-types', { items: [this.value] });
  }

  /** Reset current form with new code type. */
  async reset(value: MaybeId<CodeType>) {
    this.value = value;
    this.codes = CodeType.list(this.value);
  }

  /** Synchronize changes made to code list back to code type. */
  protected resync() {
    CodeType.apply(this.value, this.codes);
  }
}
