import { Icon, IconMap } from "../../../../../common/model/icon";
import { MimeCategory } from "../../../../../common/model/mime";

/** Standard icons for MIME types. */
export const MIME_CATEGORY_ICON: IconMap<MimeCategory> = {
  [MimeCategory.Archive]: Icon.FolderZip,
  [MimeCategory.Audio]: Icon.AudioFile,
  [MimeCategory.Binary]: Icon.QuestionMark,
  [MimeCategory.Calendar]: Icon.Event,
  [MimeCategory.Chart]: Icon.BarChart,
  [MimeCategory.Document]: Icon.Description,
  [MimeCategory.Font]: Icon.TextFields,
  [MimeCategory.Image]: Icon.Image,
  [MimeCategory.Multipart]: Icon.ContentCopy,
  [MimeCategory.PDF]: Icon.PictureAsPdf,
  [MimeCategory.Slideshow]: Icon.Slideshow,
  [MimeCategory.Spreadsheet]: Icon.TableChart,
  [MimeCategory.Text]: Icon.Notes,
  [MimeCategory.Video]: Icon.Movie
};