import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { EMAIL_HELP, PASSWORD_HELP } from "../../../../../../common/model/event/result/auth";
import { PASSWORD_REGEX } from "../../../../../../common/model/user/password";
import { AuthService } from "../../../common/service/auth.service";
import { validatorMatch } from "../../../common/toolbox/angular";
import { PasswordModel } from "../../../common/toolbox/auth";


export const RESET_PASSWORD_PARAM = 'email';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  host: {
    class: 'center dialog-backdrop'
  }
})
export class ResetPasswordComponent {
  readonly EMAIL_HELP = EMAIL_HELP;
  readonly PASSWORD_HELP = PASSWORD_HELP;

  /** Password reset form. */
  builder = new FormBuilder().nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    old: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
    password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
    confirm: ['', [validatorMatch<PasswordModel>('password')]]
  });

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      //if we are resetting on a logged in user, auto populate the email field
      let email = this.route.snapshot.queryParamMap.get(RESET_PASSWORD_PARAM);
      if(email) this.builder.controls.email.reset({ value: email, disabled: true });
    })
  }
  /** Callback when submitting password reset. */
  submit() {
    //disabled properties are undefined so have to grab it from control instead.
    this.auth.reset(this.builder.controls.email.value, this.builder.value.old!, this.builder.value.password!);
  }
}
