import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT } from "../toolbox/id";
import { EnumValidator } from "../validator/enum";
import { FusionCollection } from "./fusion";
import { Permission } from "./permission";

/** Profile assigned to a user with one or more permissions and work queue assignments. */
export class Profile {
  constructor(
    /** Unique identifier of profile. */
    public _id = ID_DEFAULT,
    /** Institution of this profile. */
    public _inst = ID_DEFAULT,
    /** Name of this profile. */
    public name = '',
    /** Mask of permissions for this profile. */
    public permissions: Permission[] = [],
    /** List of queues assigned to this profile. */
    public _queues: string[] = [],
    /** True if profile has been edited. */
    public dirty?: boolean
  ) {}

  static typeinfo: TypeInfo<Profile> = {
    _queues: [ID_DEFAULT],
    permissions: [new EnumValidator(Permission)],
    dirty: false
  };

  static collectioninfo: CollectionInfo<FusionCollection, Profile> = {
    _inst: 'institutions',
    _queues: 'workQueues'
  };

  static indexinfo: IndexInfo<Profile> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** Short preview of profile displayed in list. */
export class ProfilePreview {
  constructor(
    /** Unique identifier of profile. */
    public _id = ID_DEFAULT,
    /** Institution of this profile. */
    public _inst = ID_DEFAULT,
    /** Name of this profile. */
    public name = ''
  ) {}
}