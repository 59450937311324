import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, ValidationErrors, Validator } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

@Directive()
export class FieldControl implements Validator {
  
  /** True if changes have been made to value. */
  @Input() dirty = false;
  /** Emits when dirty state of control changes. */
  @Output() dirtyChange = new EventEmitter<boolean>();
  /** Emits when touched state of control changes. */
  @Output() touchChange = new EventEmitter<boolean>();

  /** Emits when errors of control change. */
  errors = new BehaviorSubject<string[]>([]);
  /** Emits when control is touched. */
  touches = new BehaviorSubject<boolean>(false);

  /** Function called when changed. */
  changed: Function = () => {};
  /** Function called when touched. */
  touched: Function = () => {};

  /** True if currently disabled. */
  _disabled = false;
  /** True if field control is read-only. */
  _readonly = false;
  /** True to allow empty selection. */
  _optional = false;
  /** True if multiple values can be bound. */
  _multiple = false;

  onDebug(): Object {
    let data: any = {
      disabled: this._disabled,
      readonly: this._readonly,
      optional: this._optional,
      multiple: this._multiple,
      dirty: this.dirty
    };

    if ('value' in this) data.value = this.value;
    return data;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return Object.fromEntries(this.errors.value.map(error => [error, 1]));
  }

  registerOnChange(callback: Function) {
    this.changed = callback;
  }

  registerOnTouched(callback: any) {
    this.touched = callback;
  }

  setDisabledState(disabled: boolean) {
    this._disabled = disabled;
  }

  setReadonlyState(readonly: any) {
    this._readonly = coerceBooleanProperty(readonly);
  }

  setMultipleState(multiple: any) {
    this._multiple = coerceBooleanProperty(multiple);
  }

  setDirty(dirty = true) {
    this.dirtyChange.emit(this.dirty = dirty);
  }

  setTouched(touched = true) {
    this.touchChange.next(touched);
    this.touches.next(touched);
    if (touched) this.touched();
  }

  /** Update internal list of errors. */
  protected reerror(...values: [string, boolean][]) {
    values = values.filter(([_, error]) => error);
    return this.errors.next(values.map(v => v[0]));
  }
}