import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Account } from '../../../../../../../common/model/account/account';
import { AccountLoan } from '../../../../../../../common/model/account/loan';
import { AttachmentList } from "../../../../../../../common/model/attachment";
import { AttachmentListComponent } from '../../../../common/component/attachment/list/attachment-list.component';
import { DialogService } from '../../../../common/component/dialog/dialog.service';
import { TAB_DATA } from '../../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../../common/service/auth.service';
import { LogService } from '../../../../common/service/log.service';
import { AccountAttachmentsData } from './account-attachment-list.model';

@Component({
  selector: 'app-account-attachment-list',
  templateUrl: '../../../../common/component/attachment/list/attachment-list.component.html',
  styleUrls: ['../../../../common/component/attachment/list/attachment-list.component.scss']
})
export class AccountAttachmentListComponent extends AttachmentListComponent {

  /** Current account being viewed. */
  account: Account = new AccountLoan();

  constructor(
    @Inject(TAB_DATA)
    data: AccountAttachmentsData | null,
    auth: AuthService,
    log: LogService,
    http: HttpClient,
    dialog: DialogService
  ) {
    super(auth, log, http, dialog);
    this.collection = 'accounts';
    data?.account.pipe(takeUntil(this.destroy)).subscribe(account => this.writeValue(account as AttachmentList));
  }
}
