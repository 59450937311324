import { ClaimACHReportReason } from "../../code/standard/disputes";
import { DisputesCode } from "../../code/system";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { UnionInfo, unioninfoRetype } from "../../info/union";
import { CURRENCY_DEFAULT } from "../../toolbox/currency";
import { PropertyType } from "../property-type";

/** Reasons provided for a card claim. */
export type ClaimACHDetails = ClaimACHDetailsBase | ClaimACHDetailsNotInAccordance | ClaimACHDetailsRCKEntryIneligible;

/** Base fields used by all ACH claim details. */
export class ClaimACHDetailsBase<T extends ClaimACHReportReason = ClaimACHReportReason> {
  constructor(
    /** Union tag for ACH claim. */
    public type: T
  ) {}

  static propinfo: PropertyInfo<ClaimACHDetailsBase> = {
    type: { type: PropertyType.Code, category: DisputesCode.ClaimACHReportReason }
  }

  /** Reassign type of claim card details while maintaining object reference. */
  static retype<T extends ClaimACHReportReason>(details: ClaimACHDetails, type: T): ClaimACHDetailsClass[T] {
    return unioninfoRetype<ClaimACHDetailsBase, ClaimACHDetails, ClaimACHReportReason>(new ClaimACHDetailsBase(ClaimACHReportReason.InsufficientFunds), details, type, CLAIM_ACH_DETAILS_UNIONINFO) as ClaimACHDetailsClass[T];
  }
}

/** Information about an ACH claim not in accordance. */
export class ClaimACHDetailsNotInAccordance extends ClaimACHDetailsBase<ClaimACHReportReason.NotInAccordance> {
  
  constructor(
    /** Amount that was authorized, if different. */
    public authorizedAmount?: number,
    /** Date the account debit was authorized, if different. */
    public authorizedDate?: Date,
    /** True if the intended payee was not credited. */
    public incompleteTransaction = false,
    /** True if company improperly reinitiated the debit. */
    public improperlyReinitiated = false
  ) {
    super(ClaimACHReportReason.NotInAccordance);
  }

  static typeinfo: TypeInfo<ClaimACHDetailsNotInAccordance> = {
    authorizedAmount: CURRENCY_DEFAULT,
    authorizedDate: new Date()
  }

  static override propinfo: PropertyInfo<ClaimACHDetailsNotInAccordance> = {
    ...ClaimACHDetailsBase.propinfo,
    authorizedAmount: { type: PropertyType.Currency }
  }
}

/** Information about an ACH claim with an ineligible RCK entry. */
export class ClaimACHDetailsRCKEntryIneligible extends ClaimACHDetailsBase<ClaimACHReportReason.RCKEntryIneligible> {
  constructor(
    /** True if notice stating RCK entry policy was not provided by originator. */
    public policyUnprovided = false,
    /** True if check is ineligible. */
    public checkIneligible = false,
    /** True if signatures on the check were inauthentic or unauthorized. */
    public signatureIneligible = false,
    /** True if amount of the entry was not accurately obtained from the item. */
    public amountInaccurate = false
  ) {
    super(ClaimACHReportReason.RCKEntryIneligible);
  }
}

/** Mapping of card claim reasons to classes. */
export class ClaimACHDetailsClass {
  [ClaimACHReportReason.InsufficientFunds] = new ClaimACHDetailsBase(ClaimACHReportReason.InsufficientFunds);
  [ClaimACHReportReason.AccountClosed] = new ClaimACHDetailsBase(ClaimACHReportReason.AccountClosed);
  [ClaimACHReportReason.NoAccount] = new ClaimACHDetailsBase(ClaimACHReportReason.NoAccount);
  [ClaimACHReportReason.InvalidAccountNumber] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidAccountNumber);
  [ClaimACHReportReason.UnauthorizedDebit] = new ClaimACHDetailsBase(ClaimACHReportReason.UnauthorizedDebit);
  [ClaimACHReportReason.ReturnedODFIRequest] = new ClaimACHDetailsBase(ClaimACHReportReason.ReturnedODFIRequest);
  [ClaimACHReportReason.AuthorizationRevoked] = new ClaimACHDetailsBase(ClaimACHReportReason.AuthorizationRevoked);
  [ClaimACHReportReason.PaymentStopped] = new ClaimACHDetailsBase(ClaimACHReportReason.PaymentStopped);
  [ClaimACHReportReason.UncollectedFunds] = new ClaimACHDetailsBase(ClaimACHReportReason.UncollectedFunds);
  [ClaimACHReportReason.OriginatorUnauthorized] = new ClaimACHDetailsBase(ClaimACHReportReason.OriginatorUnauthorized);
  [ClaimACHReportReason.NotInAccordance] = new ClaimACHDetailsNotInAccordance();
  [ClaimACHReportReason.AccountSold] = new ClaimACHDetailsBase(ClaimACHReportReason.AccountSold);
  [ClaimACHReportReason.InvalidRoutingNumber] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidRoutingNumber);
  [ClaimACHReportReason.PayeeDeceased] = new ClaimACHDetailsBase(ClaimACHReportReason.PayeeDeceased);
  [ClaimACHReportReason.BeneficiaryDeceased] = new ClaimACHDetailsBase(ClaimACHReportReason.BeneficiaryDeceased);
  [ClaimACHReportReason.AccountFrozen] = new ClaimACHDetailsBase(ClaimACHReportReason.AccountFrozen);
  [ClaimACHReportReason.ImproperReversal] = new ClaimACHDetailsBase(ClaimACHReportReason.ImproperReversal);
  [ClaimACHReportReason.ImproperEffectiveDate] = new ClaimACHDetailsBase(ClaimACHReportReason.ImproperEffectiveDate);
  [ClaimACHReportReason.AmountFieldError] = new ClaimACHDetailsBase(ClaimACHReportReason.AmountFieldError);
  [ClaimACHReportReason.NonTransactionAccount] = new ClaimACHDetailsBase(ClaimACHReportReason.NonTransactionAccount);
  [ClaimACHReportReason.InvalidCompanyIdentification] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidCompanyIdentification);
  [ClaimACHReportReason.InvalidIdNumber] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidIdNumber);
  [ClaimACHReportReason.CreditRefused] = new ClaimACHDetailsBase(ClaimACHReportReason.CreditRefused);
  [ClaimACHReportReason.DuplicateEntry] = new ClaimACHDetailsBase(ClaimACHReportReason.DuplicateEntry);
  [ClaimACHReportReason.AddendaError] = new ClaimACHDetailsBase(ClaimACHReportReason.AddendaError);
  [ClaimACHReportReason.MandatoryFieldError] = new ClaimACHDetailsBase(ClaimACHReportReason.MandatoryFieldError);
  [ClaimACHReportReason.TraceNumberError] = new ClaimACHDetailsBase(ClaimACHReportReason.TraceNumberError);
  [ClaimACHReportReason.RoutingNumberError] = new ClaimACHDetailsBase(ClaimACHReportReason.RoutingNumberError);
  [ClaimACHReportReason.CorporateUnauthorized] = new ClaimACHDetailsBase(ClaimACHReportReason.CorporateUnauthorized);
  [ClaimACHReportReason.CheckTruncationProgram] = new ClaimACHDetailsBase(ClaimACHReportReason.CheckTruncationProgram);
  [ClaimACHReportReason.PermissibleReturnOnly] = new ClaimACHDetailsBase(ClaimACHReportReason.PermissibleReturnOnly);
  [ClaimACHReportReason.NonSettlement] = new ClaimACHDetailsBase(ClaimACHReportReason.NonSettlement);
  [ClaimACHReportReason.ReturnXCKEntry] = new ClaimACHDetailsBase(ClaimACHReportReason.ReturnXCKEntry);
  [ClaimACHReportReason.LimitedParticipation] = new ClaimACHDetailsBase(ClaimACHReportReason.LimitedParticipation);
  [ClaimACHReportReason.ReturnImproperDebit] = new ClaimACHDetailsBase(ClaimACHReportReason.ReturnImproperDebit);
  [ClaimACHReportReason.ReturnImproperCredit] = new ClaimACHDetailsBase(ClaimACHReportReason.ReturnImproperCredit);
  [ClaimACHReportReason.SourceDocumentPresented] = new ClaimACHDetailsBase(ClaimACHReportReason.SourceDocumentPresented);
  [ClaimACHReportReason.StopPaymentSourceDocument] = new ClaimACHDetailsBase(ClaimACHReportReason.StopPaymentSourceDocument);
  [ClaimACHReportReason.ImproperSourceDocument] = new ClaimACHDetailsBase(ClaimACHReportReason.ImproperSourceDocument);
  [ClaimACHReportReason.ReturnENREntry] = new ClaimACHDetailsBase(ClaimACHReportReason.ReturnENREntry);
  [ClaimACHReportReason.InvalidTransactionCode] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidTransactionCode);
  [ClaimACHReportReason.CheckDigitError] = new ClaimACHDetailsBase(ClaimACHReportReason.CheckDigitError);
  [ClaimACHReportReason.InvalidDFIAccountNumber] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidDFIAccountNumber);
  [ClaimACHReportReason.InvalidIdentificationNumber] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidIdentificationNumber);
  [ClaimACHReportReason.InvalidName] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidName);
  [ClaimACHReportReason.InvalidPayeeIndicator] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidPayeeIndicator);
  [ClaimACHReportReason.DuplicateEnrollment] = new ClaimACHDetailsBase(ClaimACHReportReason.DuplicateEnrollment);
  [ClaimACHReportReason.RCKStateLawAcceptance] = new ClaimACHDetailsBase(ClaimACHReportReason.RCKStateLawAcceptance);
  [ClaimACHReportReason.RCKEntryIneligible] = new ClaimACHDetailsRCKEntryIneligible();
  [ClaimACHReportReason.RCKEntryStopPayment] = new ClaimACHDetailsBase(ClaimACHReportReason.RCKEntryStopPayment);
  [ClaimACHReportReason.ItemRCKPresented] = new ClaimACHDetailsBase(ClaimACHReportReason.ItemRCKPresented);
  [ClaimACHReportReason.MisroutedReturn] = new ClaimACHDetailsBase(ClaimACHReportReason.MisroutedReturn);
  [ClaimACHReportReason.ReturnErroneousDebit] = new ClaimACHDetailsBase(ClaimACHReportReason.ReturnErroneousDebit);
  [ClaimACHReportReason.DuplicateReturn] = new ClaimACHDetailsBase(ClaimACHReportReason.DuplicateReturn);
  [ClaimACHReportReason.UntimelyReturn] = new ClaimACHDetailsBase(ClaimACHReportReason.UntimelyReturn);
  [ClaimACHReportReason.FieldError] = new ClaimACHDetailsBase(ClaimACHReportReason.FieldError);
  [ClaimACHReportReason.ReturnNotAccepted] = new ClaimACHDetailsBase(ClaimACHReportReason.ReturnNotAccepted);
  [ClaimACHReportReason.MisroutedDishonoredReturn] = new ClaimACHDetailsBase(ClaimACHReportReason.MisroutedDishonoredReturn);
  [ClaimACHReportReason.UntimelyishonoredReturn] = new ClaimACHDetailsBase(ClaimACHReportReason.UntimelyishonoredReturn);
  [ClaimACHReportReason.TimelyOriginalReturn] = new ClaimACHDetailsBase(ClaimACHReportReason.TimelyOriginalReturn);
  [ClaimACHReportReason.CorrectedReturn] = new ClaimACHDetailsBase(ClaimACHReportReason.CorrectedReturn);
  [ClaimACHReportReason.ReturnNotDuplicate] = new ClaimACHDetailsBase(ClaimACHReportReason.ReturnNotDuplicate);
  [ClaimACHReportReason.NoErrorsFound] = new ClaimACHDetailsBase(ClaimACHReportReason.NoErrorsFound);
  [ClaimACHReportReason.NonAcceptedDishonoredReturn] = new ClaimACHDetailsBase(ClaimACHReportReason.NonAcceptedDishonoredReturn);
  [ClaimACHReportReason.IATCodingError] = new ClaimACHDetailsBase(ClaimACHReportReason.IATCodingError);
  [ClaimACHReportReason.IATNonParticipant] = new ClaimACHDetailsBase(ClaimACHReportReason.IATNonParticipant);
  [ClaimACHReportReason.InvalidForeignDFI] = new ClaimACHDetailsBase(ClaimACHReportReason.InvalidForeignDFI);
  [ClaimACHReportReason.UnsettledForeignDFI] = new ClaimACHDetailsBase(ClaimACHReportReason.UnsettledForeignDFI);
  [ClaimACHReportReason.GatewayUnprocessed] = new ClaimACHDetailsBase(ClaimACHReportReason.GatewayUnprocessed);
  [ClaimACHReportReason.IncorrectOutboundPayment] = new ClaimACHDetailsBase(ClaimACHReportReason.IncorrectOutboundPayment);
};

/** Type information about card claim details. */
export const CLAIM_ACH_DETAILS_UNIONINFO: UnionInfo<ClaimACHDetails, ClaimACHReportReason> = {
  tag: 'type',
  classes: new ClaimACHDetailsClass()
};

/** Standard ACH report reasons to display in dropdowns. */
export const CLAIM_ACH_REPORT_REASON_FILTER = [ClaimACHReportReason.UnauthorizedDebit, ClaimACHReportReason.AuthorizationRevoked, ClaimACHReportReason.OriginatorUnauthorized, ClaimACHReportReason.NotInAccordance, ClaimACHReportReason.CorporateUnauthorized, ClaimACHReportReason.SourceDocumentPresented, ClaimACHReportReason.RCKEntryIneligible, ClaimACHReportReason.ItemRCKPresented];
/** ACH report reasons to display for consumer disputes. */
export const CLAIM_ACH_REPORT_REASON_CONSUMER_FILTER = CLAIM_ACH_REPORT_REASON_FILTER;
/** ACH report reasons to display for corporate disputes. */
export const CLAIM_ACH_REPORT_REASON_CORPORATE_FILTER = CLAIM_ACH_REPORT_REASON_FILTER;