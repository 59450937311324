import { CollectionExport, CollectionInfo } from "../info/collection";
import { propinfoProperty } from "../info/prop";
import { TypeInfo } from "../info/type";
import { accountUnion } from "../toolbox/account";
import { arrayDefined } from "../toolbox/array";
import { claimUnion } from "../toolbox/claim";
import { disputeUnion } from "../toolbox/dispute";
import { enumPrototype } from "../toolbox/enum";
import { hydrateObject } from "../toolbox/hydrate";
import { ID_DEFAULT } from "../toolbox/id";
import { NestedKey, keyNestedGet, keyNestedPairs } from "../toolbox/keys";
import { modelValue } from "../toolbox/model";
import { Pair, objectEntries } from "../toolbox/object";
import { UnionValidator } from "../validator/union";
import { ACCOUNT_JOIN_UNIONINFO, AccountUnion } from "./account/account";
import { Attachment, AttachmentUpload } from "./attachment";
import { Card } from "./card";
import { CLAIM_JOIN_UNIONINFO, ClaimJoin, ClaimJoinClass, ClaimUnion } from "./claim/claim";
import { DISPUTE_JOIN_UNIONINFO } from "./dispute/dispute";
import { Email } from "./email";
import { Event } from "./event";
import { FormulaProxy } from "./formula/proxy";
import { FusionCollection } from "./fusion";
import { Institution } from "./institution";
import { LedgerItem } from "./ledger/item";
import { Member } from "./member";
import { Model } from "./model";
import { Organization } from "./organization/organization";
import { Phone } from "./phone";
import { PropertyType } from "./property-type";
import { Transaction } from "./transaction";
import { User } from "./user/user";

/** Possible types of values that can be displayed. */
export enum DisplayType {
  Account           = 'account',
  Attachment        = 'attachment',
  Card              = 'card',
  Claim             = 'claim',
  Dispute           = 'dispute',
  Email             = 'email',
  Event             = 'event',
  Formula           = 'formula',
  Institution       = 'institution',
  Ledger            = 'ledger',
  Member            = 'member',
  Model             = 'model',
  Organization      = 'organization',
  Phone             = 'phone',
  Transaction       = 'transaction',
  Uploads           = 'uploads',
  User              = 'user'
}

/** A mapping of display values. */
export type DisplayMap<T> = Record<DisplayType, T | undefined>
/** Display value with all optional fields. */
export type DisplayPartial = Partial<DisplayValue>;
/** Display value with all optional fields, defined. */
export type DisplayOptional = { [T in DisplayType]: DisplayValue[T] | undefined }

/** Common fields shared by all displays. */
export class Display<T extends DisplayValue = DisplayValue> {
  constructor(
    /** Field to display. */
    public key = 'account._id' as NestedKey<T>,
    /** Override for property display name. */
    public name?: string
  ) { }

  static typeinfo: TypeInfo<Display> = {
    name: ''
  }

  static collectioninfo: CollectionInfo<FusionCollection, Display> = {
    key: key => {
      let _id = Display.formula(key);
      return _id ? new CollectionExport(['formula.', ['formulas', _id]]) : key;
    }
  }

  /** Split a display into type and accessed field. */
  static split(display: string | Display): [DisplayType, string]
  static split<T extends DisplayType>(display: undefined | string | Display, type: T): keyof DisplayValue[T] | undefined
  static split(display: string | Display = '', type?: DisplayType): any {
    let match = (typeof display === 'string' ? display : display.key).match(/^([a-z]+)\.(.+)$/);
    let pair = match ? match.slice(1) as [DisplayType, string] : [DisplayType.Model, ''];

    if (!type) return pair;
    if (pair[0] !== type) return undefined;
    
    if (type && pair[0] !== type) return pair[0] === type ? pair[1] : undefined;
    return pair[1];
  }

  /** Check if a display is a formula. */
  static formula(display: Display | string) {
    let match = (typeof display === 'string' ? display : display.key).match(/^formula\.(.+)$/);
    return match ? match[1] : undefined;
  }

  /** List all formulas present in displays. */
  static formulas(displays: Display[]) {
    return arrayDefined(displays.map(Display.formula));
  }
}

/** Values available to display in forms, tables and formulas. */
export class DisplayValue {

  constructor(
    /** Account being displayed. */
    public account = accountUnion(),
    /** Attachment being displayed */
    public attachment = new Attachment(),
    /** Card being displayed */
    public card = new Card(),
    /** Claim being displayed */
    public claim = claimUnion(),
    /** Dispute being displayed */
    public dispute = disputeUnion(),
    /** Primary email being displayed in context. */
    public email = new Email(),
    /** Event being viewed. */
    public event = new Event(),
    /** List of available formulas. */
    public formula = new FormulaProxy(),
    /** Institution being displayed. */
    public institution = new Institution(),
    /** A ledger item associated with a transaction. */
    public ledger = new LedgerItem(),
    /** First available member of account. */
    public member = new Member(),
    /** Model bound to current context. */
    public model: Record<string, any> = {},
    /** Organization being displayed. */
    public organization = new Organization(),
    /** Phone number being viewed. */
    public phone = new Phone(),
    /** Transaction of an account. */
    public transaction = new Transaction(),
    /** List of uploaded attachments. */
    public uploads: AttachmentUpload[] = [],
    /** User being viewed. */
    public user = User.like()
  ) { }

  static typeinfo: TypeInfo<DisplayValue> = {
    account: new UnionValidator(ACCOUNT_JOIN_UNIONINFO),
    uploads: [new AttachmentUpload()],
    claim: new UnionValidator(CLAIM_JOIN_UNIONINFO),
    dispute: new UnionValidator(DISPUTE_JOIN_UNIONINFO)
  }

  /** Get keys of a display value. */
  static pairs(value: DisplayValue): Pair<NestedKey<DisplayValue>>[] {
    return keyNestedPairs(value);
  }

  /** Create display value with given model type information. */
  static model(model?: Model) {
    let value = new DisplayValue();
    if (model) value.model = modelValue(model);
    return value;
  }

  /** Get unique set of values of given property type. */
  static unique(partials: DisplayPartial[], model: Model, displays: Display[], type: PropertyType): string[] {
    let typed = this.model(model);
    let set = new Set<string>();

    for (let display of displays) {
      let property = propinfoProperty(display.key, typed);
      if (property?.type !== type) continue;

      for (let partial of partials) {
        let value = keyNestedGet(display.key, partial);
        if (value !== undefined) set.add(value);
      }
    }

    return [...set];
  }

  /** Expand a list of joined claims into a display value. */
  static claims(claims: ClaimJoin[]) {
    let joins = claims.map(claim => hydrateObject(claim, new ClaimJoinClass()[claim.type]));
    return joins.map<DisplayPartial>(claim => ({
      claim: claim as ClaimUnion,
      account: claim.account as AccountUnion,
      member: claim.member,
      card: 'card' in claim ? claim.card : undefined
    }));
  }
}

/** Object ID assigned to each display type. */
export class DisplayId {

  constructor(
    public account?: string,
    public attachment?: string,
    public card?: string,
    public claim?: string,
    public dispute?: string,
    public email?: string,
    public event?: string,
    public formula?: string,
    public institution?: string,
    public ledger?: string,
    public member?: string,
    public model?: string,
    public organization?: string,
    public phone?: string,
    public transaction?: string,
    public user?: string
  ) { }

  static typeinfo: TypeInfo<DisplayId> = enumPrototype<DisplayType, string>(DisplayType, ID_DEFAULT);
}

/** Object ID list assigned to each display type. */
export class DisplayList {

  constructor(
    public account?: string[],
    public card?: string[],
    public claim?: string[],
    public dispute?: string[],
    public event?: string[],
    public formula?: string[],
    public ledger?: string[],
    public member?: string[],
    public model?: string[],
    public phone?: string[],
    public email?: string[],
    public transaction?: string[],
    public user?: string[]
  ) { }

  static typeinfo: TypeInfo<DisplayList> = enumPrototype<DisplayType, [string]>(DisplayType, [ID_DEFAULT]);
}

/** Mapping of display types back to code names. */
export const DISPLAY_TYPE_NAME: Record<DisplayType, string> = {
  [DisplayType.Account]: 'Account',
  [DisplayType.Attachment]: 'Attachment',
  [DisplayType.Card]: 'Card',
  [DisplayType.Claim]: 'Claim',
  [DisplayType.Dispute]: 'Dispute',
  [DisplayType.Email]: 'Email',
  [DisplayType.Event]: 'Event',
  [DisplayType.Formula]: 'Formula',
  [DisplayType.Institution]: 'Institution',
  [DisplayType.Ledger]: 'Ledger Item',
  [DisplayType.Member]: 'Member',
  [DisplayType.Model]: 'Model',
  [DisplayType.Organization]: 'Organization',
  [DisplayType.Phone]: 'Phone',
  [DisplayType.Transaction]: 'Transaction',
  [DisplayType.Uploads]: 'Uploads',
  [DisplayType.User]: 'User'
};

/** List of display type pairs. */
export const DISPLAY_TYPES = objectEntries(DISPLAY_TYPE_NAME).map(([type, name]) => new Pair(type, name));