import { AttachmentType } from "../../code/standard/common";
import { ClaimStatus, DisputeStatus } from "../../code/standard/disputes";
import { CommonCode } from "../../code/system";
import { CollectionInfo, collectioninfoIds } from "../../info/collection";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { UnionInfo, unioninfoRetype } from "../../info/union";
import { EnumMap, enumCodeMap } from "../../toolbox/enum";
import { ID_DEFAULT } from "../../toolbox/id";
import { EnumValidator } from "../../validator/enum";
import { FusionCollection } from "../fusion";
import { PropertyType } from "../property-type";

/** What type of check is performed for condition. */
export enum WorkConditionType {
  Formula,
  Attachment,
  ClaimStatus,
  DisputeStatus
}

export type WorkCondition =
  | ClaimWorkCondition
  | GeneralWorkCondition;

export type GeneralWorkCondition =
  | WorkConditionAttachment
  | WorkConditionFormula;

export type ClaimWorkCondition =
  | WorkConditionClaimStatus
  | WorkConditionDisputeStatus;

/** A condition to unlock work steps. */
export class WorkConditionBase<T extends WorkConditionType = WorkConditionType> {
  constructor(
    /** Tagged type of condition. */
    public type: T,
    /** Override for condition description. */
    public description?: string
  ) {}

  static typeinfo: TypeInfo<WorkCondition> = {
    description: ''
  }

  /** Get formulas needed by condition. */
  static formulas(condition: WorkCondition): string[] {
    let typed = WORK_CONDITION_UNIONINFO.classes[condition.type];
    return collectioninfoIds<FusionCollection, WorkCondition>(condition, typed).filter(id => id[0] === 'formulas').map(id => id[1]);
  }

  /** Reassign type of a work action while maintaining object reference. */
  static retype<T extends WorkConditionType>(condition: WorkCondition, type: T): WorkConditionClass[T] {
    return unioninfoRetype<WorkConditionBase, WorkCondition, WorkConditionType>(new WorkConditionBase(WorkConditionType.Attachment), condition, type, WORK_CONDITION_UNIONINFO) as WorkConditionClass[T];
  }
}

/** Mode to execute a condition. */
export enum WorkConditionMode {
  /** Execute condition once for each dispute and return result. */
  DisputesAny,
  /** Execute condition once for each dispute and return if all results are truthy. */
  DisputesAll
}

/** A work condition to check if given attachment exists. */
export class WorkConditionAttachment extends WorkConditionBase<WorkConditionType.Attachment> {
  
  constructor(
    /** Attachment that must exist. */
    public attachmentType = AttachmentType.Other,
    /** Formula to filter available disputes before checking. */
    public _filters?: string[],
    /** Mode for executing formula. */
    public mode?: WorkConditionMode
  ) {
    super(WorkConditionType.Attachment);
  }

  static override typeinfo: TypeInfo<WorkConditionAttachment> = {
    ...WorkConditionBase.typeinfo,
    type: WorkConditionType.Attachment,
    _filters: [ID_DEFAULT],
    mode: new EnumValidator(WorkConditionMode)
  };

  static propinfo: PropertyInfo<WorkConditionAttachment> = {
    attachmentType: { type: PropertyType.Code, category: CommonCode.AttachmentType }
  }

  static collectioninfo: CollectionInfo<FusionCollection, WorkConditionAttachment> = {
    _filters: 'formulas'
  }
}

/** A work condition to evaluate a formula. */
export class WorkConditionFormula extends WorkConditionBase<WorkConditionType.Formula> {
  constructor(
    /** Formula that is used to calculate the value. */
    public _formula = ID_DEFAULT,
    /** Formulas to filter available disputes before checking. */
    public _filters?: string[],
    /** Mode for executing formula. */
    public mode?: WorkConditionMode
  ) {
    super(WorkConditionType.Formula);
  }

  static override typeinfo: TypeInfo<WorkConditionFormula> = {
    ...WorkConditionBase.typeinfo,
    type: WorkConditionType.Formula,
    _formula: ID_DEFAULT,
    _filters: [ID_DEFAULT],
    mode: new EnumValidator(WorkConditionMode),
  };

  static collectioninfo: CollectionInfo<FusionCollection, WorkConditionFormula> = {
    _formula: 'formulas',
    _filters: 'formulas'
  };
}

/** A work condition to check if each dispute is at least given status. */
export class WorkConditionDisputeStatus extends WorkConditionBase<WorkConditionType.DisputeStatus> {
  constructor(
    /** Status that dispute must be at. */
    public status = DisputeStatus.NotWorked,
    /** Formulas to filter available disputes before checking. */
    public _filters?: string[],
    /** Run an exact check for this status instead of comparing order. */
    public exact?: boolean,
    /** Mode for executing status check. */
    public mode?: WorkConditionMode
  ) {
    super(WorkConditionType.DisputeStatus);
  }

  static override typeinfo: TypeInfo<WorkConditionDisputeStatus> = {
    ...WorkConditionBase.typeinfo,
    type: WorkConditionType.DisputeStatus,
    status: new EnumValidator(DisputeStatus),
    _filters: [ID_DEFAULT],
    exact: false,
    mode: new EnumValidator(WorkConditionMode)
  };

  static collectioninfo: CollectionInfo<FusionCollection, WorkConditionDisputeStatus> = {
    _filters: 'formulas'
  };
}

/** A work condition to check if a claim is at least given status. */
export class WorkConditionClaimStatus extends WorkConditionBase<WorkConditionType.ClaimStatus> {
  constructor(
    /** Status that dispute must be at. */
    public status = ClaimStatus.Initiated,
    /** Run an exact check for this status instead of comparing order. */
    public exact?: boolean
  ) {
    super(WorkConditionType.ClaimStatus);
  }

  static override typeinfo: TypeInfo<WorkConditionClaimStatus> = {
    ...WorkConditionBase.typeinfo,
    type: WorkConditionType.ClaimStatus,
    status: new EnumValidator(ClaimStatus),
    exact: false
  };
}

/** Mapping of work conditions to classes. */
export class WorkConditionClass {
  [WorkConditionType.Attachment] = new WorkConditionAttachment();
  [WorkConditionType.ClaimStatus] = new WorkConditionClaimStatus();
  [WorkConditionType.DisputeStatus] = new WorkConditionDisputeStatus();
  [WorkConditionType.Formula] = new WorkConditionFormula();
};

/** Enumeration names of work condition types. */
export const WORK_CONDITION_CODE = enumCodeMap<WorkConditionType>(WorkConditionType);

/** Type information about work action classes. */
export const WORK_CONDITION_UNIONINFO: UnionInfo<WorkCondition, WorkConditionType> = {
  tag: 'type',
  classes: new WorkConditionClass()
};

/** Names of work condition types. */
export const WORK_CONDITION_NAME: EnumMap<WorkConditionType> = {
  [WorkConditionType.Attachment]: 'Attachment',
  [WorkConditionType.ClaimStatus]: 'Claim Stage',
  [WorkConditionType.DisputeStatus]: 'Dispute Stage',
  [WorkConditionType.Formula]: 'Formula',
};

/** Name for each execution mode for conditions. */
export const WORK_CONDITION_MODE_NAME: EnumMap<WorkConditionMode> = {
  [WorkConditionMode.DisputesAny]: 'Any Dispute',
  [WorkConditionMode.DisputesAll]: 'All Disputes'
};