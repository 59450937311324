import { Component, ElementRef, Type } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  host: {
    '[class.hidden]': '!active',
    '[class.full]': '!padding'
  }
})
export class TabComponent {

  /** Title of this tab displayed in bar. */
  title = 'New Tab';
  /** Component injected into tab. */
  component!: Type<any>;

  /** True if currently active. */
  active = false;
  /** True to allow closing this tab via button. */
  closeable = false;
  /** True if component has implicit padding. */
  padding = true;

  constructor(
    public element: ElementRef<HTMLDivElement>
  ) {}
}
