import { arrayDefined } from "./array"
import { NestedKey } from "./keys"

/** Callbacks for performing string transformations. */
interface StringConvertCallback {
  camel: (_: unknown, a: string, b: string, c: string) => string
  lower: (_: unknown, a: string, b: string) => string
  pascal: (_: unknown, a: string, b: string, c: string) => string
  snake: (_: unknown, a: string, b: string) => string
  title: (_: unknown, a: string, b: string) => string
}

/** A replacement to make. */
export class StringRename {
  constructor(
    /** Old replacement string. */
    public from = '',
    /** New replacement string. */
    public to = ''
  ) {}
}

/** Regex for a quoted string (no escapes). */
export const STRING_REGEX = /(?:^"[^"]*"$)|(?:^'[^']*'$)/;

/** Matches on camelCase strings. */
export const CAMEL_CASE_REGEX = /^_*[a-z][a-zA-Z0-9]*$/;
/** Matches on lower case strings. */
export const LOWER_CASE_REGEX = /(^[a-z0-9]+)( [a-z0-9]+)*$/;
/** Matches on PascalCase strings. */
export const PASCAL_CASE_REGEX = /^_*[A-Z][a-zA-Z0-9]*$/;
/** Matches on snake_case and kebab-case strings. */
export const SNAKE_CASE_REGEX = /^[a-z0-9-]+$|^[a-z0-9_]+$/;
/** Matches on Title Case strings. */
export const TITLE_CASE_REGEX = /(^[A-Z0-9][A-Za-z0-9]*)( [A-Z0-9][A-Za-z0-9]*)*$/;
/** Matches on strings containing at least one non-whitespace char. */
export const NON_WHITESPACE_REGEX = /\S+/;

/** Extracts slices from camelCase strings. */
export const CAMEL_CASE_SLICE = /(^[a-z])|([A-Z]+|[0-9]+)|([a-z][A-Z])/g;
/** Extracts slices from lower case strings. */
export const LOWER_CASE_SLICE = /(^[a-z0-9]+)|( [a-z0-9]+)/g;
/** Extracts slices from PascalCase strings. */
export const PASCAL_CASE_SLICE = /(^[A-Z])|([A-Z]+|[0-9]+)|([a-z][A-Z])/g;
/** Extracts slices from snake_case strings. */
export const SNAKE_CASE_SLICE = /(^[a-z0-9])|([_-][a-z0-9])/g;
/** Extracts slices from Title Case strings. */
export const TITLE_CASE_SLICE = /(^[a-zA-Z0-9]+)|( [A-Z0-9][a-zA-Z0-9]+)/g;

/** Regex for valid javascript identifiers. */
export const IDENTIFIER_REGEX = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/;
/** Regex for valid nested javascript identifiers. */
export const IDENTIFIER_NESTED_REGEX = /^[a-zA-Z_$][a-zA-Z0-9_.$]*$/;
/** Regex for valid nested javascript identifiers anywhere in string. */
export const IDENTIFIER_NESTED_GLOBAL_REGEX = new RegExp(IDENTIFIER_NESTED_REGEX.source.slice(1, -1), 'g');

/** Sizes for each power of bytes. */
export const BYTES_SUFFIX = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

/** Replace character at specified index in string. */
export function stringReplace(text: string, i: number, c: string) {
  return `${text.substring(0, i)}${c}${text.substring(i + 1)}`;
}

/**
 * Splice one string into another. Modelled after Array.splice.
 * @param text The string to splice.
 * @param index The position to insert/delete tex.
 * @param remove The number of characters to remove.
 * @param insert The new text to insert.
 */
export function stringSplice(text: string, index: number, remove: number, insert = '') {
  let array = text.split('');
  array.splice(index, remove, insert);
  return array.join('');
}

/** Add additional indentation to string. */
export function stringIndent(text: string, indent = '  ') {
  return text.replace(/^ +|\n +/g, match => match.startsWith('\n') ? `\n${indent}${match.slice(1)}` : `${indent}${match}`)
}

/** Shorthand for space-joining pieces of text. */
export function stringJoin(...strings: (string | undefined)[]): string {
  return arrayDefined(strings.filter(s => s?.length)).join(' ');
}

/** Convert text to camelCase. */
export function camelCase(text?: string) {
  return convertCase(text, {
    camel: (_, a, b, c) =>
      a ?? b ?? c,
    lower: (_, a, b) =>
      a ?? `${b[1]!.toUpperCase()}${b.slice(2)}`,
    pascal: (_, a, b, c) =>
      a ? a.toLowerCase() : b ?? c,
    snake: (_, a, b) =>
      a ?? b[1]!.toUpperCase(),
    title: (_, a, b) =>
      a ? a.toLowerCase() : `${b[1]!.toUpperCase()}${b.slice(2)}`
  });
}

/** Convert text to kebab-case. */
export function kebabCase(text?: string) {
  return convertCase(text, {
    camel: (_, a, b, c) =>
      a ?? (b ? `-${b.toLowerCase()}` : `${c[0]}-${c[1]!.toLowerCase()}`),
    lower: (_, a, b) =>
      a ?? `-${b.slice(1)}`,
    pascal: (_, a, b, c) =>
      a ? a.toLowerCase() : b ? `-${b.toLowerCase()}` : `${c[0]}-${c[1]!.toLowerCase()}`,
    snake: (_, a, b) =>
      a ?? `-${b[1]}`,
    title: (_, a, b) =>
      a ? a.toLowerCase() : `-${b.slice(1).toLowerCase()}`
  });
}

/** Convert text to lower case. */
export function lowerCase(text?: string) {
  return convertCase(text, {
    camel: (_, a, b, c) =>
      a ?? (b ? ` ${b.toLowerCase()}` : `${c[0]} ${c[1]!.toLowerCase()}`),
    lower: (_, a, b) =>
      a ?? b,
    pascal: (_, a, b, c) =>
      a ? a.toLowerCase() : b ? ` ${b.toLowerCase()}` : `${c[0]} ${c[1]!.toLowerCase()}`,
    snake: (_, a, b) =>
      a ?? ` ${b[1]}`,
    title: (_, a, b) =>
      a ? a.toLowerCase() : b.toLowerCase()
  });
}

/** Convert text to PascalCase. */
export function pascalCase(text?: string) {
  return convertCase(text, {
    camel: (_, a, b, c) =>
      a ? a.toUpperCase() : b ?? c,
    lower: (_, a, b) =>
      a ? `${a[0]!.toUpperCase()}${a.slice(1)}` : `${b[1]!.toUpperCase()}${b.slice(2)}`,
    pascal: (_, a, b, c) =>
      a ? a.toUpperCase() : b ?? c,
    snake: (_, a, b) =>
      a ? a.toUpperCase() : b[1]!.toUpperCase(),
    title: (_, a, b) =>
      a ? `${a[0]!.toUpperCase()}${a.slice(1)}` : `${b[1]!.toUpperCase()}${b.slice(2)}`
  });
}

/** Convert text to snake case. */
export function snakeCase(text?: string) {
  return convertCase(text, {
    camel: (_, a, b, c) =>
      a ?? (b ? `_${b.toLowerCase()}` : `${c[0]}_${c[1]!.toLowerCase()}`),
    lower: (_, a, b) =>
      a ?? `_${b.slice(1)}`,
    pascal: (_, a, b, c) =>
      a ? a.toLowerCase() : b ? `_${b.toLowerCase()}` : `${c[0]}_${c[1]!.toLowerCase()}`,
    snake: (_, a, b) =>
      a ?? `_${b[1]}`,
    title: (_, a, b) =>
      a ? a.toLowerCase() : `_${b.slice(1).toLowerCase()}`
  });
}

/** Convert snake_case or camelCase to Title Case. */
export function titleCase(text?: string) {
  return convertCase(text, {
    camel: (_, a, b, c) =>
      a ? a.toUpperCase() : b ? ` ${b.toUpperCase()}` : `${c[0]} ${c[1]!.toUpperCase()}`,
    lower: (_, a, b) =>
      a ? `${a[0]!.toUpperCase()}${a.slice(1)}` : ` ${b[1]!.toUpperCase()}${b.slice(2)}`,
    pascal: (_, a, b, c) =>
      a ?? (b ? ` ${b}` : `${c[0]} ${c[1]}`),
    snake: (_, a, b) =>
      a ? a.toUpperCase() : ` ${b[1]!.toUpperCase()}`,
    title: (_, a, b) =>
      a ?? b
  });
}

/** Truncate text if it is too long. */
export function stringEllipsis(text: string, length = 2048) {
  return text.length > length ? `${text.slice(0, length - 3)}...` : text;
}

/** Conduct black magic to make a printout yellow. */
export function stringYellow(message: string) {
  // @ts-ignore Console available in Node.JS and browser contexts.
  console.warn('\x1b[33m%s\x1b[0m', message);
}

/** Format a number of bytes as a human-readable string, following Windows format. */
export function bytesFormat(bytes: number) {
  if (bytes <= 0) return '0 bytes';
  let i = Math.floor(Math.log(bytes) / Math.log(1024));
  let b = bytes / Math.pow(1024, i);
  return `${roundDigits(b, 3)} ${BYTES_SUFFIX[i]}`;
}

/** Round number to specified number of digits. */
export function roundDigits(value: number, digits: number) {
  let i = Math.floor(Math.log10(value)) + 1;
  let f = Math.max(0, digits - i);
  return f ? `${value.toFixed(6)}`.slice(0, i + 1 + f) : `${Math.trunc(value)}`;
}

/** Coerce a value into a number. */
export function stringCoerce(value: unknown, required: true, length: number): string
export function stringCoerce(value: unknown, required: boolean, length: number): string | undefined
export function stringCoerce(value: unknown, required: boolean, length = Number.MAX_SAFE_INTEGER) {
  let empty = value === '' || value === undefined;
  if (empty) return required ? '' : undefined;
  return `${value}`.substring(0, length);
}

/** Mask a string value. */
export function stringMask(text: string, count = 4, mask = '*') {
  let indexes = [...text.matchAll(/[\w\d]/g)]
    .slice(0, count ? -count : text.length)
    .map(({ index }) => index!);

  let letters = [...text];
  for (let i of indexes) letters[i] = mask;
  return letters.join('');
}

/** Perform string conversion, using each provided callback. */
function convertCase(text: string | undefined, callbacks: StringConvertCallback) {
  if (text === undefined) return '';
  text = text.replace(/^(_+)/, () => '');
  if (text.includes(' ')) text = text.replace(/[^a-zA-Z0-9]+/g, ' ').trim();

  if (CAMEL_CASE_REGEX.test(text)) {
    return text.replace(CAMEL_CASE_SLICE, callbacks.camel);
  } else if (LOWER_CASE_REGEX.test(text)) {
    return text.replace(LOWER_CASE_SLICE, callbacks.lower);
  } else if (PASCAL_CASE_REGEX.test(text)) {
    return text.replace(PASCAL_CASE_SLICE, callbacks.pascal);
  } else if (SNAKE_CASE_REGEX.test(text)) {
    return text.replace(SNAKE_CASE_SLICE, callbacks.snake);
  } else if (TITLE_CASE_REGEX.test(text)) {
    return text.replace(TITLE_CASE_SLICE, callbacks.title);
  } else return text;
}

/** Wrapper around string.slice */
export function stringSlice(value: any, start?: number, end?: number): string {
  if (!value) return ''
  const text = `${value}`
  if (start !== undefined) start = isNaN(start) ? 0 : +start
  if (end !== undefined) end = isNaN(end) ? text.length : +end
  return text.slice(start, end);
}

/** Result of performing a string replace in an object. */
export class StringReplaceResult {
  constructor(
    /** True if changes were made. */
  ) {}
}

/** Perform a deep replace of string values in an object.
 *  @returns True if any replacement was made.
 */
export function stringRename<T extends Object>(value: T, renames: StringRename[], whitelist: Set<NestedKey<T>>) {
  let result = { dirty: false };
  stringRenameDeep(value, renames, whitelist, result, []);
  return result.dirty;
}

/** Deeply replace strings within an object. */
function stringRenameDeep(value: any, renames: StringRename[], whitelist: Set<string>, result: { dirty: boolean }, path: string[]) {
  let current = path.join('.');

  switch (typeof value) {
  case 'string':
    if (!whitelist.has(current)) break;
    for (let rename of renames) {
      // Perform shallow non-global replace for performance.
      let renamed: string = value.replace(rename.from, rename.to); 
      if (value === renamed) continue;

      // Performed a replacement.
      value = renamed;
      result.dirty = true;
    } break;
  case 'object':
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; ++i) {
        value[i] = stringRenameDeep(value[i], renames, whitelist, result, path);
      }
    } else if (value) {
      for (let key in value) {
        path.push(key);
        value[key] = stringRenameDeep(value[key], renames, whitelist, result, path);
        path.pop();
      }
    }
  }

  return value;
}