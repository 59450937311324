import { ImportCode } from "../../../code/system";
import { ConditionType } from "../../formula/condition";
import { OperatorType } from "../../formula/operator";
import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { BuiltinFormula } from "../formula";

/** Formulas that operate on accounts. */
export enum AccountFormula {
  Bankrupt              = 'Account - Bankrupt',
  DaysLate              = 'Account - Days Late',
  HighRisk              = 'Account - High Risk',
  LatePeriods           = 'Account - Late Periods',
  Number                = 'Account - Number',
  PaymentsMissed        = 'Account - Payments Missed',
  Risk                  = 'Account - Risk',
  State                 = 'Account - State',
  TotalDelinquentAmount = 'Account - Total Delinquent Amount',
  WarningCodes          = 'Account - Warning Codes'
}

export const BUILTIN_ACCOUNT_FORMULAS: Record<AccountFormula, BuiltinFormula> = {
  [AccountFormula.Bankrupt]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'account.bankruptcyCode'
        },
        right: {
          type: TerminalType.Code,
          category: ImportCode.BankruptcyChapter,
          value: '1'
        }
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: 'Bankrupt'
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: ''
        }
      }]
    }]
  },
  [AccountFormula.DaysLate]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.Greater,
        left: {
          type: TerminalType.Date,
          value: 0
        },
        right: {
          type: TerminalType.Identifier,
          key: 'account.paymentDueDate'
        }
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: OperatorType.Subtract,
          left: {
            type: TerminalType.Date,
            value: 0
          },
          right: {
            type: TerminalType.Identifier,
            key: 'account.paymentDueDate'
          }
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.Number,
          value: 0
        }
      }]
    }]
  },
  [AccountFormula.HighRisk]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'account.highRisk'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: 'High Risk'
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: ''
        }
      }]
    }]
  },
  [AccountFormula.LatePeriods]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'account.delinquencyPeriods'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: OperatorType.Join,
          left: {
            type: OperatorType.Pad,
            left: {
              type: TerminalType.Identifier,
              key: 'account.delinquencyPeriods.timesLate'
            },
            middle: {
              type: TerminalType.Number,
              value: 3
            },
            right: {
              type: TerminalType.Number,
              value: 0
            }
          },
          right: {
            type: TerminalType.String,
            value: ' / '
          }
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: '0 / 0 / 0'
        }
      }]
    }]
  },
  [AccountFormula.Number]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Join,
        left: {
          type: TerminalType.Array,
          expressions: [
            {
              type: TerminalType.Identifier,
              key: 'account.number'
            },
            {
              type: TerminalType.String,
              value: '-'
            },
            {
              type: TerminalType.Identifier,
              key: 'account.category'
            },
            {
              type: TerminalType.Identifier,
              key: 'account.subAccount'
            }
          ]
        },
        right: {
          type: TerminalType.String,
          value: ''
        }
      }
    }]
  },
  [AccountFormula.PaymentsMissed]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'account.delinquencyPeriods'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: OperatorType.Sum,
          left: {
            type: TerminalType.Identifier,
            key: 'account.delinquencyPeriods.timesLate'
          }
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.Number,
          value: 0
        }
      }]
    }]
  },
  [AccountFormula.Risk]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'account.originalRiskGrade'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: OperatorType.Join,
          left: {
            type: TerminalType.Array,
            expressions: [
              {
                type: TerminalType.String,
                value: 'C/O Risk: '
              },
              {
                type: TerminalType.Identifier,
                key: 'account.originalRiskGrade'
              }
            ]
          },
          right: {
            type: TerminalType.String,
            value: ''
          }
        }
      }],
      else: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: ''
        }
      }]
    }]
  },
  [AccountFormula.State]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: TerminalType.Identifier,
        key: 'account.pastDueAmount'
      },
      then: [{
        type: StatementType.Return,
        expression: {
          type: TerminalType.String,
          value: 'Delinquent'
        }
      }],
      else: [{
        type: StatementType.If,
        if: {
          type: TerminalType.Identifier,
          key: 'account.chargeOffDate'
        },
        then: [{
          type: StatementType.Return,
          expression: {
            type: TerminalType.String,
            value: 'Charged Off'
          }
        }],
        else: [{
          type: StatementType.Return,
          expression: {
            type: TerminalType.String,
            value: 'New'
          }
        }]
      }]
    }]
  },
  [AccountFormula.TotalDelinquentAmount]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Add,
        left: {
          type: TerminalType.Identifier,
          key: 'account.pastDueAmount'
        },
        right: {
          type: TerminalType.Identifier,
          key: 'account.lateFees'
        }
      }
    }]
  },
  [AccountFormula.WarningCodes]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Join,
        left: {
          type: OperatorType.Display,
          left: {
            type: TerminalType.Identifier,
            key: 'account.warningCodes'
          }
        },
        right: {
          type: TerminalType.String,
          value: '\\n'
        }
      }
    }]
  }
}