import { AccountCategory, SearchCategory } from "../../../../../common/code/standard/common"
import { ClaimType } from "../../../../../common/code/standard/disputes"
import { TypeInfo } from "../../../../../common/info/type"
import { fusionCollectionRecord } from "../../../../../common/model/fusion-map"
import { FeatureType } from "../../../../../common/model/organization/feature"
import { enumPrototype } from "../../../../../common/toolbox/enum"
import { ID_DEFAULT } from "../../../../../common/toolbox/id"
import { StatusLevel } from "../../../../../common/toolbox/status"
import { EnumValidator } from "../../../../../common/validator/enum"
import { TaskMode } from "../../module/task/task.model"
import { ActivityTab } from "./activity"
import { IntervalOptions } from "./interval"
import { KeyInfo } from "./keyinfo"
import { ObjectStoreClass } from "./object-store"

/** Options for activity panel. */
export class ActivityOptions {

  constructor(
    /** User of these activity options.  */
    public _user: string,
    /** Hidden log levels. */
    public filter = enumPrototype(StatusLevel, true),
    /** Last viewed tab. */
    public tab = ActivityTab.Logs
  ) {}

  static keyinfo: KeyInfo<ActivityOptions>  = '_user';

  static typeinfo: TypeInfo<ActivityOptions> = {
    _user: ID_DEFAULT
  }
}

/** Options for developer mode. */
export class DeveloperOptions {

  constructor(
    /** User of these developer options.  */
    public _user: string,
    /** True if enabled. */
    public on: boolean
  ) {}

  static keyinfo: KeyInfo<DeveloperOptions>  = '_user';

  static typeinfo: TypeInfo<DeveloperOptions> = {
    _user: ID_DEFAULT,
    on: true
  }
}

/** Options for refreshing events. */
export class EventOptions {

  constructor(
    /** User of these event options. */
    public _user: string,
    /** Interval options for event. */
    public interval = new IntervalOptions()
  ) {}

  static keyinfo: KeyInfo<EventOptions> = '_user';

  static typeinfo: TypeInfo<EventOptions> = {
    _user: ID_DEFAULT
  }
}

/** Options for refreshing queues. */
export class QueueOptions {

  constructor(
    /** User of these queue options. */
    public _user: string,
    /** Interval options for queues. */
    public interval = new IntervalOptions()
  ) {}

  static keyinfo: KeyInfo<QueueOptions> = '_user';

  static typeinfo: TypeInfo<QueueOptions> = {
    _user: ID_DEFAULT
  }
}

/** Search history of user. */
export class SearchOptions {

  constructor(
    /** User this search history applies to. */
    public _user: string,
    /** Last selected institution in top dropdown. */
    public _inst?: string,
    /** Last selected feature of user. */
    public feature = FeatureType.Collections,
    /** Last selected search category. */
    public category = SearchCategory.AccountNumber,
    /** Last entered query for categories. */
    public query = enumPrototype<SearchCategory, string | undefined>(SearchCategory, undefined, true)
  ) {}

  static keyinfo: KeyInfo<SearchOptions> = '_user';

  static typeinfo: TypeInfo<SearchOptions> = {
    _user: ID_DEFAULT,
    _inst: ID_DEFAULT,
    feature: new EnumValidator(FeatureType)
  }
}

/** Setup options for tasks. */
export class SetupTaskOptions {
  constructor(
    /** Last selected task mode. */
    public mode = TaskMode.Account,
    /** Last selected account category. */
    public accountCategory = AccountCategory.Loan,
    /** Last selected claim type. */
    public claimType = ClaimType.ACH
  ) {}

  static typeinfo: TypeInfo<SetupTaskOptions> = {
    mode: new EnumValidator(TaskMode),
    accountCategory: new EnumValidator(AccountCategory),
    claimType: new EnumValidator(ClaimType)
  }
}

/** Setup options of user. */
export class SetupOptions {
  constructor(
    /** User this search history applies to. */
    public _user: string,
    /** True to display setup pages in list view. */
    public list = true,
    /** Filter used on each setup page. */
    public filter = fusionCollectionRecord('', true),
    /** Last selected value on each setup page. */
    public selection = fusionCollectionRecord('', true),
    /** Options for setting up tasks. */
    public task = new SetupTaskOptions()
  ) {}

  static keyinfo: KeyInfo<SetupOptions> = '_user';

  static typeinfo: TypeInfo<SetupOptions> = {
    _user: ID_DEFAULT
  }
}

/** A cached translation. */
export class Translation {

  constructor(
    /** Language of translation. */
    public lang: string,
    /** Cached contents of transation. */
    public cache: Object = {}
  ) {}

  static keyinfo: KeyInfo<Translation> = 'lang';

  static typeinfo: TypeInfo<Translation> = {
    lang: ''
  }
}

/** List of object stores in IndexedDB. */
export interface FusionObjectStore {
  activityOptions: ActivityOptions
  developerOptions: DeveloperOptions
  eventOptions: EventOptions
  queueOptions: QueueOptions
  searchOptions: SearchOptions
  setupOptions: SetupOptions
  translations: Translation
}

/** Current version number of database. Must be bumped on any DB change. */
export const FUSION_STORE_VERSION = 6;

/** List of classes in object store. */
export const FUSION_STORE_CLASS: ObjectStoreClass<FusionObjectStore> = {
  activityOptions: ActivityOptions,
  developerOptions: DeveloperOptions,
  eventOptions: EventOptions,
  queueOptions: QueueOptions,
  searchOptions: SearchOptions,
  setupOptions: SetupOptions,
  translations: Translation
}