import { Pipe } from "@angular/core";
import { Event, EventPartialMap } from "../../../../../common/model/event";
import { EventCategory, EventCategoryClass } from "../../../../../common/model/event/category";
import { EVENT_RESULT_NAME, EventResult } from "../../../../../common/model/event/result";
import { AccountResultType } from "../../../../../common/model/event/result/account";
import { ClaimResultType } from "../../../../../common/model/event/result/claim";
import { eventMapGet } from "../../../../../common/toolbox/event";
import { AuthService } from "../service/auth.service";
import { TaskService } from "../service/task.service";

/** A callback for overriding a particular event name. */
type EventOverrideCallback<T = EventResult> = (result: T, event: Event) => string | Promise<string>;

@Pipe({
  name: 'eventTitle'
})
export class EventTitlePipe {

  /** Overrides for event type names. */
  private readonly EVENT_TYPE_NAME_OVERRIDE: { [C in EventCategory]?: { [R in keyof EventCategoryClass[C]]?: EventOverrideCallback<EventCategoryClass[C][R]> } } = {
    [EventCategory.Account]: {
      [AccountResultType.Form]: result => this.task.name(this.auth._inst, result._task)
    },
    [EventCategory.Claim]: {
      [ClaimResultType.ClaimDelete]: result => result.restore ? 'Claim Restored' : 'Claim Abandoned',
      [ClaimResultType.ClaimForm]: result => this.task.name(this.auth._inst, result._task)
    }
  };

  constructor(
    private auth: AuthService,
    private task: TaskService
  ) { }

  async transform(event: Event): Promise<string> {
    // Start with explicit event title.
    if (event.title) return event.title;

    // Fallback on name override.
    let override = eventMapGet(this.EVENT_TYPE_NAME_OVERRIDE as EventPartialMap<EventOverrideCallback>, event.result.category, event.result.type);
    if (override) return override(event.result, event);

    // Fallback on default name.
    return eventMapGet(EVENT_RESULT_NAME, event.result.category, event.result.type);
  }
}
