import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ErrorResponse } from '../../../../../../common/message/error';
import { Step, StepStatus } from '../../../../../../common/model/step';
import { TASK_TYPE_NAME } from '../../../../../../common/model/task-config';
import { WorkContext } from '../../../../../../common/model/work/context';
import { Workflow } from '../../../../../../common/model/work/flow';
import { WorkActionStatus, WorkStepStatus, WorkflowStatus } from '../../../../../../common/model/work/status';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { TASK_MODE_NAME, TASK_TYPE_MODE, TaskMode } from '../../../module/task/task.model';
import { AuthService } from '../../service/auth.service';
import { DevService } from '../../service/dev.service';
import { LogService } from '../../service/log.service';
import { TaskService } from '../../service/task.service';
import { WorkEngineService } from '../../service/work-engine.service';
import { WorkflowService } from '../../service/workflow.service';
import { debugElementMake } from '../../toolbox/element/debug';
import { setupElementMake } from '../../toolbox/element/setup';
import { TaskOpen } from '../../toolbox/task';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
  providers: [WorkEngineService], // Obtain unique instance of service to allow multiple to run in parallel.
  host: {
    '[attr.compact]': 'compact',
    class: 'column'
  }
})
export class WorkflowComponent {
  readonly StepStatus = StepStatus;

  /** Set new workflow to view. */
  @Input() set workflow(workflow: Workflow | undefined) {
    this._workflow = workflow;
    this.refresh();
  }

  /** Set new context for workflow. */
  @Input() set context(context: WorkContext | undefined) {
    this._context = context;
    this.refresh();
  }

  /** Current mode of workflow. */
  @Input() mode?: TaskMode;
  /** True to show compact view for workflow. */
  @Input() compact = false;
  /** True if currently loading. */
  @Input() loading = false;

  /** Emits when a task should be opened. */
  @Output() task = new EventEmitter<TaskOpen>();

  /** Current index into steps. */
  protected current = 0;
  /** Items to display for stepper. */
  protected steps: Step<WorkStepStatus>[] = [];
  /** Current status of workflow. */
  protected status = new WorkflowStatus();
  /** Last bound workflow. */
  protected _workflow?: Workflow;

  /** Last bound context. */
  private _context?: WorkContext;
  /** Emits when element is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    public elementRef: ElementRef,
    protected dev: DevService,
    private auth: AuthService,
    private tasks: TaskService,
    private log: LogService,
    private engine: WorkEngineService,
    private workflows: WorkflowService
  ) {
    debugElementMake(this);
    this.workflows.added.pipe(takeUntil(this.destroy)).subscribe(workflows => {
      for (let workflow of workflows) {
        if (workflow._id !== this._workflow?._id) continue;
        this.workflow = workflow;
        return;
      };
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  onDebug() {
    return this.status;
  }

  /** Callback when selecting a step. */
  protected onStep(step: number) {
    let newStep = this.status.steps[step];
    if (newStep) {
      this.status.step = newStep;
      this.current = step;
    }
  }

  /** Callback when selecting a task to perform. */
  protected async onTask(_task: string) {
    let task = await this.tasks.item({ _inst: this.auth._inst, _id: _task });
    if (this.mode && !TASK_TYPE_MODE[task.config.type].find(mode => mode === this.mode)) {
      return this.log.show(new ErrorResponse(`Cannot open task type "${TASK_TYPE_NAME[task.config.type]}" in workflow mode: ${TASK_MODE_NAME[this.mode]}`));
    }

    this.task.next({ _task });
  }

  /** Re-run single action of workflow engine. */
  protected async reaction(action: WorkActionStatus) {
    if (!this._context) return;
    let result = await this.engine.executeAction(action, this._context);
    if (errorResponse(result)) return this.log.show(result);
  }

  /** Re-run workflow engine and update interface. */
  private async refresh() {
    if (!this._workflow || !this._context) return;
    setupElementMake(this.elementRef.nativeElement, 'workflows', this._workflow._id);
    let result = await this.engine.execute(this._workflow, this._context);
    if (errorResponse(result)) return this.log.show(result);

    this.status = result;
    this.steps = WorkflowStatus.steps(this.status);
    this.current = this.status.current;
  }
}
