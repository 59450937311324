import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { RadioGroupComponent } from '../radio-group.component';
import { RADIO_GROUP } from '../radio-group.model';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonComponent {
  /** Auto-increment for unique radio button IDs. */
  private static id = 0;
  
  /** Unique ID for radio button. */
  @Input() public id = `radio-${RadioButtonComponent.id++}`;
  /** Emits when value changes via a direct click. */
  @Output() change = new EventEmitter<any>();

  /** True if radio is checked. */
  @Input()
  public get checked() { return this._checked; }
  public set checked(value: BooleanInput) {
    value = coerceBooleanProperty(value);
    this._checked = value;
    if (value && this._group && this._group.value !== this.value) {
      this._group.selected = this;
    } else if (!value && this._group && this._group.value === this.value) {
      this._group.selected = undefined;
    }

    this.changes.markForCheck();
  }

  /** Value of this radio button. */
  @Input()
  public get value() { return this._value; }
  public set value(value: any) {
    if (this._value === value) return;
    this._value = value;
    
    if (!this._group) return;
    if (!this.checked) this.checked = this._group.value === value;
    if (this.checked) this._group.selected = this;
  }
  
  /** True if currently selected. */
  _checked = false;
  /** Value bound to button. */
  _value?: any;

  @Input() disabled = false;

  constructor(
    @Optional() @Inject(RADIO_GROUP)
    public _group: RadioGroupComponent | undefined,
    public changes: ChangeDetectorRef
  ) {}

  /** Callback when input is clicked. */
  onChange() {
    this.change.next(this.value);
    if (this.checked) return;
    this.checked = true;

    if (!this._group) return;
    this._group.changed(this.value);
  }
}
