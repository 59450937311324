import { DisputeType } from "../code/standard/disputes";
import { propInfoMerge, propinfoOf } from "../info/prop";
import { DisputeLookup } from "../model/dispute/base";
import { Dispute, DisputeACH, DisputeCard, DisputeCheck, DisputeClass, DisputeJoin, DisputeJoinClass, DisputeUnion, DisputeUploadJoinClass } from "../model/dispute/dispute";
import { PropertyType } from "../model/property-type";
import { hydrateObject } from "./hydrate";
import { MaybeId, NoId } from "./id";
import { NestedKey } from "./keys";
import { objectEntries, safeAssign } from "./object";

/** Get list of all amount fields of all disputes. */
export function disputeAmounts(): NestedKey<DisputeUnion>[] {
  return objectEntries(propinfoOf(disputeUnion()))
    .filter(([, property]) => property.type === PropertyType.Currency)
    .map(([key]) => key as NestedKey<DisputeUnion>);
}

/** Create a dispute union. */
export function disputeUnion(dispute: Dispute | DisputeJoin = new DisputeACH()): DisputeUnion {
  let union = propInfoMerge(new DisputeACH(), new DisputeCard(), new DisputeCheck(), new DisputeLookup());
  return safeAssign(union, hydrateObject(dispute, union));
}

/** Converts a joined dispute back to a basic dispute. */
export function disputeUnjoin<T extends DisputeType>(dispute: MaybeId<DisputeUploadJoinClass[T]>): DisputeClass[T]
export function disputeUnjoin<T extends DisputeType>(dispute: DisputeJoinClass[T]): DisputeClass[T]
export function disputeUnjoin<T extends DisputeType>(dispute: MaybeId<DisputeJoinClass[T]>): MaybeId<DisputeClass[T]>
export function disputeUnjoin<T extends DisputeType>(dispute: DisputeJoinClass[T]): DisputeClass[T] {
  return safeAssign(new DisputeClass()[dispute.type] as DisputeClass[T], {
    ...dispute,
    transaction: undefined,
    ledger: undefined
  });
}

/** Type-assert an ID-less dispute. */
export function disputeTyped<T extends DisputeType>(claim: NoId<Dispute>, type: T): claim is NoId<DisputeClass[T]> {
  return claim.type === type;
}