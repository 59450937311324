import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { ErrorResponse } from "../../../../../common/message/error";
import { RoutePreviewPath } from "../../../../../common/toolbox/api";
import { ArraySome, arraySome } from "../../../../../common/toolbox/array";
import { HasIdName, ID_DEFAULT } from "../../../../../common/toolbox/id";
import { keyUnique } from "../../../../../common/toolbox/keys";
import { errorResponse } from "../../../../../common/toolbox/message";
import { Newable, objectKeys } from "../../../../../common/toolbox/object";
import { StatusLevel } from "../../../../../common/toolbox/status";
import { lowerCase } from "../../../../../common/toolbox/string";
import { DialogService } from "../component/dialog/dialog.service";
import { LogService } from "../service/log.service";

/** Basic cache service for fetching items. */
export abstract class CacheService<T extends HasIdName, Q extends Object> {

  /** Emits when new items are added to cache. */
  added = new Subject<T[]>();
  
  /** Internal cache of pending items. */
  private cache = new Map<string, Promise<T>>();
  /** List of unique keys in queries. */
  private keys: (keyof Q)[];

  constructor(
    Query: Newable<Q>,
    protected log: LogService,
    protected dialog: DialogService,
    protected http: HttpClient
  ) {
    this.keys = objectKeys(new Query);
  }

  /** Route to preview list of items. */
  protected abstract route: RoutePreviewPath;
  /** Constructor for creating dummy item. */
  abstract Type: Newable<T>;

  /** Fetch multiple items, either by looping through queries or merging into one query. */
  protected abstract multiple(queries: ArraySome<Q>): Promise<T[] | ErrorResponse>

  /** Get an item from cache. */
  async item(query: Q): Promise<T> {
    return (await this.fetch(query))[0]!;
  }

  /** Get multiple items from cache. */
  async items(queries: Q[]): Promise<T[]> {
    return await Promise.all(this.fetch(...queries));
  }

  /** Get a list of items from cache and create an ID mapping. */
  async map(queries: Q[]): Promise<Map<string, T>> {
    return new Map((await this.items(queries)).map(value => [value._id, value]));
  }

  /** Fetch name of specified item. */
  async name(query: Q): Promise<string> {
    let item = await this.item(query);
    return item?.name ?? item?._id ?? '';
  }

  /** Push items into internal cache. */
  set(...items: T[]) {
    for (let item of items) {
      this.cache.set(this.key(item), Promise.resolve(item));
    }

    this.added.next(items);
  }

  /** Remove items from internal cache. */
  async delete(_id: string) {
    for (let [key, value] of [...this.cache.entries()]) {
      let item = await value;
      if (item._id !== _id) continue;
      this.cache.delete(key);
    }
  }

  /** Clear all items from cache. */
  clear() {
    this.cache.clear();
  }

  /** Get unique key of a query. */
  protected key(query: any): string {
    return keyUnique(query, this.keys);
  }

  /** Fetch existing item from cache or server. */
  protected fetch(...queries: Q[]): Promise<T>[] {
    let items: Promise<T>[] = [];
    let missing: Q[] = [];

    for (let query of queries) {
      if (this.invalid(query)) {
        items.push(Promise.resolve(new this.Type()));
        continue;
      }

      // Check if already exists in cache.
      let key = this.key(query);
      let item = this.cache.get(key);
      if (item) {
        items.push(item);
        continue;
      }
  
      // Need to fetch from server.
      missing.push(query);
    }

    // Fetch missing items.
    if (!arraySome(missing)) return items;
    let response = this.multiple(missing);

    for (let i = 0; i < missing.length; ++i) {
      let query = missing[i]!;
      let key = this.key(query);
      this.cache.set(key, response.then(items => {
        if (errorResponse(items)) {
          this.log.show(items);
          return new this.Type();
        }

        let item = items[i];
        if (!item) {
          let name = lowerCase(this.route.replace('/preview', '')).replace(/s$/, '');
          let value = (query as any)._id ?? JSON.stringify(query);
          let error = new ErrorResponse(`Failed to fetch ${name}: ${value}`, undefined, query)
          this.log.show(error, StatusLevel.Alert);
          return new this.Type();
        }
  
        return item;
      }));
    }

    // Results definitely exist in map now.
    return this.fetch(...queries);
  }

  /** Check if requested query contains any null IDs. */
  protected invalid(query: Q): boolean {
    return Object.keys(query).some(key => (query as any)[key] === ID_DEFAULT);
  }
}