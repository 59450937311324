import { SchemaIndex } from "../model/schema";
import { objectKeys } from "../toolbox/object";
import { infoOf } from "./base";

/** Additional annotations for database schema indexes. */
export type IndexInfo<T = any> = SchemaIndex<T>[];

/** A diff of two indexes. */
export class IndexDiff {
  /** Added indexes. */
  add?: SchemaIndex[];
  /** Removed indexes. */
  remove?: SchemaIndex[];

  /** True if any changes have been made. */
  get dirty() { return this.add?.length || this.remove?.length; }

  constructor(
    /** Old indexes. */
    from: IndexInfo,
    /** New indexes. */
    to: IndexInfo
  ) {
    // Ensure indexes have named.
    for (let index of from) index.name = index.name ?? indexinfoName(index);
    for (let index of to) index.name = index.name ?? indexinfoName(index);

    // Find removed indexes.
    let remove: SchemaIndex[] = [];
    for (let index of from) {
      if (index.name === '_id_') continue;
      if (!to.find(i => i.name === index.name)) remove.push(index);
    }

    // Find added indexes.
    let add: SchemaIndex[] = [];
    for (let index of to) {
      if (index.name === '_id_') continue;
      if (!from.find(i => i.name === index.name)) add.push(index);
    }

    this.remove = remove.length ? remove : undefined;
    this.add = add.length ? add : undefined;
  }
}

/** Pull indexinfo from object. */
export function indexinfoOf<T>(value: T): IndexInfo<T> {
  return infoOf(value, 'indexinfo', []);
}

/** Get unique name for schema index. */
export function indexinfoName(indexinfo: SchemaIndex) {
  let suffix = indexinfo.collation ? 'text' : '1';
  let keys = Object.keys(indexinfo.key);
  return keys.map(key => `${key}_${suffix}`).join('_');
}

/** Get unique keys of an object's index. */
export function indexinfoKeys<T>(value: T): (keyof T)[] {
  let indexinfo = indexinfoOf(value);
  let unique = indexinfo.find(i => i.unique) ?? { key: { _id: 1 } };
  return objectKeys(unique.key) as (keyof T)[];
}