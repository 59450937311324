import { ErrorResponse } from "../message/error";
import { BufferLike, binaryUnstring } from "./binary";

/** Handler for unzipping files. */
export type Unzip = (buffer: BufferLike) => Promise<ZipArchive>;

/** Handle to a zip archive. */
interface ZipArchive {
  entries: { [K in string]?: ZipFile }
}

/** Handle to a zip file. */
interface ZipFile {
  text: () => Promise<string>
}

/** Extract specified file from ZIP as string. */
export async function zipExtract(unzip: Unzip, data: BufferLike, filename: string) {
  try {
    let buffer = await binaryUnstring(data);
    let { entries } = await unzip(buffer);
    return await entries[filename]?.text() ?? new ErrorResponse(`File not found in zip: ${filename}`);
  } catch (e: any) {
    return new ErrorResponse(`Failed to extract zip file: ${e.message}`);
  }
}