import { Component, Input } from '@angular/core';
import { AccountJoin } from '../../../../../../common/model/account/account';
import { LedgerItem } from '../../../../../../common/model/ledger/item';
import { idInstOmit } from '../../../../../../common/toolbox/id';

@Component({
  selector: 'app-ledger-account',
  templateUrl: './ledger-account.component.html',
  styleUrls: ['../ledger.scss', './ledger-account.component.scss'],
  host: {
    '[class.application]': 'account?.application'
  }
})
export class LedgerAccountComponent {

  /** Account involved in transaction. */
  @Input() account?: AccountJoin;
  /** Ledger item being edited. */
  @Input() ledger = idInstOmit(new LedgerItem());
}
