import { Component, HostBinding, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, map, takeUntil } from 'rxjs';
import { propinfoProperty } from "../../../../../../../common/info/prop";
import { Box } from "../../../../../../../common/model/box";
import { CodeMap } from '../../../../../../../common/model/code-type';
import { Display, DisplayType, DisplayValue } from "../../../../../../../common/model/display";
import { Email } from "../../../../../../../common/model/email";
import { Field } from "../../../../../../../common/model/form/field";
import { Form } from "../../../../../../../common/model/form/form";
import { Member } from "../../../../../../../common/model/member";
import { Phone } from "../../../../../../../common/model/phone";
import { StringProperty } from "../../../../../../../common/model/property";
import { PropertyType } from "../../../../../../../common/model/property-type";
import { Table } from "../../../../../../../common/model/table";
import { Transaction } from "../../../../../../../common/model/transaction";
import { UserPreview } from "../../../../../../../common/model/user/user";
import { arrayDefined } from "../../../../../../../common/toolbox/array";
import { MaybeId } from '../../../../../../../common/toolbox/id';
import { keyNestedGet } from '../../../../../../../common/toolbox/keys';
import { Newable, safeAssign } from "../../../../../../../common/toolbox/object";
import { DAYS_MAX, DAYS_MIN } from "../../../../../../../common/toolbox/time";
import { PhonePipe } from '../../../pipe/phone.pipe';
import { AuthService } from '../../../service/auth.service';
import { Control } from '../../../toolbox/form';
import { CodeGridComponent } from '../../code-grid/code-grid.component';
import { FormBooleanComponent } from '../boolean/form-boolean.component';
import { FormCodeComponent } from '../code/form-code.component';
import { FormCurrencyComponent } from '../currency/form-currency.component';
import { FormDateComponent } from '../date/form-date.component';
import { FormAccessor } from '../form-accessor';
import { FormMultiAccessor } from '../form-multi-accessor';
import { FormStatus } from '../form.model';
import { FormGridComponent } from '../grid/form-grid.component';
import { FormNumberComponent } from '../number/form-number.component';
import { FormSelectComponent } from '../select/form-select.component';
import { FormStringComponent } from '../string/form-string.component';

/** A component that can carry a list of items. */
type FormMultiComponent<T> = FormSelectComponent<T> | FormGridComponent;

/** Component for each field type. */
const PROPERTY_COMPONENT = {
  [PropertyType.Boolean]: FormBooleanComponent,
  [PropertyType.Number]: FormNumberComponent,
  [PropertyType.String]: FormStringComponent,
  [PropertyType.Currency]: FormCurrencyComponent,
  [PropertyType.Date]: FormDateComponent,
  [PropertyType.Code]: FormCodeComponent
};

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent {

  /** Pre-created display value to fetch type information. */
  private static readonly DISPLAY_VALUE = new DisplayValue();

  /** Sets position of control on form. */
  @HostBinding('style.grid-area') gridArea = 'unset';
  /** Container to inject components. */
  @ViewChild('container', { static : true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** True to display label for control. */
  label = true;
  /** Information about displaying this control. */
  control!: Control;
  /** Emits when new list of phones becomes available. */
  phoneChange: Observable<Phone[]> = new BehaviorSubject<Phone[]>([]);
  /** Emits when new list of emails becomes available. */
  emailChange: Observable<Email[]> = new BehaviorSubject<Email[]>([]);
  /** Emits when value of field changes. */
  valueChange = new ReplaySubject<any>(1);
  /** Emits when new errors are propagated. */
  errors = new BehaviorSubject<string[]>([]);
  /** Emits when touches occur. */
  touches = new BehaviorSubject<boolean>(false);
  /** Component injected into template. */
  component?: FormAccessor;

  /** True if field has been touched. */
  protected touched = false;
  /** Error to display within field component. */
  protected error = '';
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  constructor(
    private auth: AuthService
  ) {}

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Populate control with given field. */
  populate(field: Field, form: MaybeId<Form>, value: DisplayValue, codes: CodeMap, members: Member[], users: UserPreview[], transactions: Transaction[], tables: Map<string, Table>, status: FormStatus) {
    let property = propinfoProperty(field.key, value) ?? propinfoProperty(field.key, FormControlComponent.DISPLAY_VALUE) ?? new StringProperty();
    let control = this.control = new Control(field, form, property);
    let large = Box.height(control.field.box) > 1;
    this.label = status.labels;
    this.gridArea = Box.css(field.box)['grid-area'];
    this.containerRef.clear();
    if (!status.inputs) return;

    let prototype: Newable<FormAccessor>;
    let table = Box.height(control.field.box) > 1;
    switch (control.property.type) {
    case PropertyType.Code:
      prototype = table ? CodeGridComponent : FormCodeComponent;
      break;
    case PropertyType.Member:
    case PropertyType.Phone:
    case PropertyType.Email:
    case PropertyType.User:
    case PropertyType.Transaction:
      prototype = table ? FormGridComponent : FormSelectComponent;
      break;
    default:
      prototype = PROPERTY_COMPONENT[control.property.type];
      break;
    }

    // Create component and inject into field.
    let [type] = Display.split(field);
    this.component = this.containerRef.createComponent(prototype).instance;
    this.component.control = control;
    this.component.required = FormAccessor.required(control.property.required, status.optional);
    this.component.locked = control.property.locked;
    this.component.readonly = type === DisplayType.Formula || !form.editable?.find(t => t === type);
    this.component.reopened = status.reopened;
    this.component.type = control.property.type;
    this.component.multiple = control.property.multiple;
    let config = this.control.field.config;

    switch (control.property.type) {
    case PropertyType.Boolean:
      let boolean = this.component as FormBooleanComponent;
      boolean.on = control.property.on || boolean.on;
      boolean.off = control.property.off || boolean.off;
      boolean.large = large;

      if (config?.type === PropertyType.Boolean) {
        safeAssign(boolean, { ...config });
      }

      break;
    case PropertyType.Number:
      let number = this.component as FormNumberComponent;
      number.min = control.property.min;
      number.max = control.property.max;
      break; 
    case PropertyType.String:
      let string = this.component as FormStringComponent;
      string.minLength = control.property.minLength;
      string.maxLength = control.property.maxLength;
      string.large = large;

      if (config?.type === PropertyType.String) {
        safeAssign(string, { ...config });
      }

      break;
    case PropertyType.Currency:
      let amount = this.component as FormCurrencyComponent;
      amount.min = control.property.min;
      amount.max = control.property.max;
      break;
    case PropertyType.Date:
      let date = this.component as FormDateComponent;
      date.min = control.property.min ?? DAYS_MIN;
      date.max = control.property.max ?? DAYS_MAX;
      break;
    case PropertyType.Code:
      let code = this.component as FormCodeComponent;

      if (code instanceof CodeGridComponent) {
        code.items = codes[control.property.category] ?? [];
      } else {
        code.institution = this.auth._inst;
        code.category = control.property.category;
      }

        if (config?.type === PropertyType.Code) {
        safeAssign(code, { ...config });
      }
      
      break;
    case PropertyType.User:
      let user = this.component as FormMultiComponent<UserPreview>;
        user.key = this.component.multiple ? 'user._id' : '_id';

      if (user instanceof FormGridComponent) {
        user.configure(tables.get(field.key), users.map(user => ({ user })));
      } else {
        user.items = users;
      }
      
      user.display = (user:UserPreview)=>user.name;
      break;
    case PropertyType.Member:
      let member = this.component as FormMultiComponent<Member>;
        member.key = this.component.multiple ? 'member._id': '_id';

      if (member instanceof FormGridComponent) {
        member.configure(tables.get(field.key), members.map(member => ({ member })));
        this.phoneChange = member.valueChange.pipe(
          takeUntil(this.destroy),
          map(displays => {
            let members = arrayDefined(displays.map(v => v.member));
            return members[0]?.phones ?? [];
          })
        );

        this.emailChange = member.valueChange.pipe(
          takeUntil(this.destroy),
          map(displays => {
            let members = arrayDefined(displays.map(v => v.member));
            return members[0]?.emails ?? [];
          })
        );
      } else {
        member.display = Member.fullname;
        member.items = members;
        this.phoneChange = member.valueChange.pipe(
          takeUntil(this.destroy),
          map(members => members[0]?.phones ?? [])
        );

        this.emailChange = member.valueChange.pipe(
          takeUntil(this.destroy),
          map(members => members[0]?.emails ?? [])
        );
      }

      break;
    case PropertyType.Phone:
      let phone = this.component as FormMultiComponent<Phone>;
        phone.key = this.component.multiple ? 'phone.number' : 'number';

      if (phone instanceof FormGridComponent) {
        phone.configure(tables.get(field.key));
      } else {
        phone.display = PhonePipe.prototype.transform;
      }
      
      break;
    case PropertyType.Email:
      let email = this.component as FormMultiComponent<Email>;
        email.key = this.component.multiple ? 'email.address' : 'address';

      if (email instanceof FormGridComponent) {
        email.configure(tables.get(field.key));
      } else {
        email.display = email => email.address;
      }
      
      break;
    case PropertyType.Transaction:
      let transaction = this.component as FormMultiComponent<Transaction>;
        transaction.key = this.component.multiple ? 'transaction._id' : '_id';

      if (transaction instanceof FormGridComponent) {
        transaction.configure(tables.get(field.key), transactions.map(transaction => ({ transaction })));
      } else {
        transaction.display = Transaction.fullname;
        transaction.items = transactions;
      } break;
    }

    if (this.component instanceof FormMultiAccessor) {
      // Convert list of items to list of keys.
      let key = this.component.key;
      let multiple = this.component.multiple;
      this.component.valueChange.pipe(
        takeUntil(this.destroy),
        map(values => {
          values = values.map(v => keyNestedGet(key, v));
          return multiple ? values : values[0];
        })
      ).subscribe(this.valueChange);
    } else {
      // Pipe values as-is.
      this.valueChange = this.component.valueChange;
    }

    // Propagate errors up.
    this.component.errors.pipe(takeUntil(this.destroy)).subscribe(errors => {
      Promise.resolve().then(() => {
        this.error = errors[0] ?? '';
        this.errors.next(errors);
      });
    });

    // Propogate touches up.
    this.component.touches.pipe(takeUntil(this.destroy)).subscribe(touched => {
      Promise.resolve().then(() => this.touches.next(this.touched = touched));
    });
  }
  
  /** Set list of available items. */
  reitem<T extends DisplayType>(type: T, items: DisplayValue[T][]) {
    if (this.component instanceof FormSelectComponent) {
      this.component.items = items;
    } else if (this.component instanceof FormGridComponent) {
      this.component.items = items.map(item => ({ [type]: item }));
    }
  }

  /** Patch value of field. */
  writeValue(value: any, linked = false) {
    // Don't update linked properties if readonly.
    if (linked && !this.component?.editable) return;
    this.component?.writeValue(value);
  }
}
