import { Component } from '@angular/core';
import { CommonCode } from '../../../../../../../../common/code/system';
import { TaskConfigClaimAttachment } from '../../../../../../../../common/model/task-config';
import { AuthService } from '../../../../../common/service/auth.service';
import { TaskAccessor } from '../../edit/setup-task-edit.model';

@Component({
  selector: 'app-setup-task-config-attachment',
  templateUrl: './setup-task-config-attachment.component.html',
  styleUrls: ['./setup-task-config-attachment.component.scss']
})
export class SetupTaskConfigAttachmentComponent extends TaskAccessor<TaskConfigClaimAttachment> {
  readonly CommonCode = CommonCode;

  constructor(
    public auth: AuthService
  ) {
    super();
  }
}
