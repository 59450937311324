<app-card class="fill">
  <app-card-header>{{state.name}}</app-card-header>
  <app-card-body scroll>
    <div class="row">
      <app-field>
        <app-label>Post Date</app-label>
        <!-- TODO Fix issues with invalid dates causing console errors, inconsistent re-requests. -->
        <app-date-range class="fill" [min]="min" [max]="max" [(ngModel)]="data.post" (selected)="onSearch($event)"></app-date-range>
      </app-field>
      <div class="fill"></div>
      <app-field>
        <app-label>Show disputed transactions?</app-label>
        <app-boolean [radio]="true" [(ngModel)]="showExisting" (ngModelChange)="onFilter()"></app-boolean>
      </app-field>
    </div>
    <app-model-grid [loading]="loading" class="elevation-low" [config]="config" [multiple]="multiple" [postcolumns]="$any(postcolumns)" (selectionChange)="onTransactions($event)"></app-model-grid>
    <div class="button-row">
      <button (click)="offset.emit(-1)">Back</button>
      <button (click)="offset.emit(+1)" [disabled]="!state.transactions.length">Next</button>  
    </div>
  </app-card-body>
</app-card>