import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DisplayPartial } from "../../../../../../common/model/display";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { GridConfig } from '../../../common/component/model/grid/model-grid.model';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { LogService } from '../../../common/service/log.service';
import { TableService } from '../../../common/service/table.service';
import { getRequest } from '../../../common/toolbox/request';
import { AccountTransactionsData } from './account-transactions.model';
import { ClientSource } from '../../../common/toolbox/source/client';

@Component({
  selector: 'app-account-transactions',
  templateUrl: './account-transactions.component.html',
  styleUrls: ['./account-transactions.component.scss']
})
export class AccountTransactionsComponent {

  /** Configuration for transaction display. */
  config?: GridConfig;

  /** Emits on component being destroyed. */
  private destroy = new Subject<void>();

  constructor(
    @Optional() @Inject(TAB_DATA)
    data: AccountTransactionsData | null,
    private log: LogService,
    private tables: TableService,
    private http: HttpClient
  ) {
    data?.account.pipe(takeUntil(this.destroy)).subscribe(async account => {
      // Update items of table.
      let transactions = await getRequest(this.http, 'transactions', { _inst: account._inst, _account: account._id });
      if (errorResponse(transactions)) {
        this.log.show(transactions);
        return;
      }

      let table = await this.tables.setting('general.transaction.table');
      let partials = transactions.items.map<DisplayPartial>(transaction => ({ transaction }));
      this.config = new GridConfig(new ClientSource(partials), table);
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
