import { TypeInfo } from "../info/type";
import { BufferLike, BufferLikeValidator } from "../toolbox/binary";
import { ID_DEFAULT } from "../toolbox/id";

/** Request a file conversion. */
export class FileConvertPostRequest {
  constructor(
    /** Temporary institution folder to use. */
    public _inst = ID_DEFAULT,
    /** Input name. */
    public input = '',
    /** Output name. */
    public output = '',
    /** Data of file, encoded as base64 */
    public data: BufferLike = ''
  ) {}

  static typeinfo: TypeInfo<FileConvertPostRequest> = {
    data: new BufferLikeValidator()
  }
}

/** Result of converting a file. */
export type FileConvertPostResponse = Blob;