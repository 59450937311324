import { UnionInfo } from "../../../info/union";
import { EventCategory } from "../category";

//** Tagged type of trigger related event */
export enum TriggerResultType {
  TriggerRun
}

/** Result payload of performing a trigger event. */
export type TriggerResult = TriggerRunResult;

/** Result of running one or more triggers. */
export class TriggerRunResult {
  readonly category = EventCategory.Trigger;
  readonly type = TriggerResultType.TriggerRun;
}

export class TriggerResultClass {
  [TriggerResultType.TriggerRun] = new TriggerRunResult();
}

/** Union type information for personal results. */
export const TRIGGER_RESULT_UNIONINFO: UnionInfo<TriggerResult, TriggerResultType> = {
  tag: 'type',
  classes: new TriggerResultClass()
}
