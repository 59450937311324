import { Log } from "../../toolbox/log";

/** Timeout for snackbar. */
export const SNACKBAR_TIMEOUT_DEFAULT = 5000;

/** Configuration for opening snackbar. */
export class SnackbarConfig extends Log {
  constructor(
    /** Time before snackbar expires, in milliseconds. */
    public timeout?: number
  ) {
    super();
  }
}