import { arrayPercentRound, arrayWrap } from "../../../../../../common/toolbox/array";
import { COLOR_SERIES, ColorCode } from "../../../../../../common/toolbox/color";

/** Item for percentages. */
export class PercentItem {
  constructor(
    /** Label for item. */
    public label = '',
    /** Value for item. */
    public value = 0
  ) {}
}

/** Percent item with pre-calculated proportion and color. */
export class PercentItemColor extends PercentItem {
  constructor(
    /** Pre-calculated color for item. */
    public color = ColorCode.Primary,
    /** Pre-calculated percentage of item. */
    public percent = 0,
    /** Displayed tooltip for item. */
    public tooltip = ''
  ) {
    super();
  }

  /** Add percent and color to list of items. */
  static from(items: PercentItem[] | PercentItemColor[]): PercentItemColor[] {
    if (!items.length) return [];
    if ('color' in items[0]!) return items as PercentItemColor[];

    let percent = arrayPercentRound(items.map(item => item.value));
    return  items.map((item, i) => ({
      ...item,
      percent: percent[i]!,
      color: arrayWrap(COLOR_SERIES, i),
      tooltip: `${item.label} (${percent[i]}%)`
    }));
  }
}