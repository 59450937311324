<app-loader *ngIf="loading"></app-loader>
<ng-container *ngIf="compact; else full">
  <header>
    <button icon-button (click)="onStep(current - 1)" [disabled]="current === 0">arrow_back</button>
    <app-select fit-content [ngModel]="current" (selected)="onStep($event)">
      <app-option *ngFor="let step of status.steps; let i = index" [view]="step.name" [value]="i"></app-option>
    </app-select>
    <button icon-button (click)="onStep(current + 1)" [disabled]="current >= steps.length - 1">arrow_forward</button>
  </header>
  <div class="panels">
    <app-expansion-panel>
      <app-expansion-panel-item *ngFor="let action of status.step.actions">
        <app-expansion-panel-item-header>
          <ng-container [ngSwitch]="action.status">
            <app-icon *ngSwitchCase="StepStatus.Ok" color="primary">circle</app-icon>
            <app-spinner icon-spinner *ngSwitchCase="StepStatus.Loading"></app-spinner>
            <app-icon *ngSwitchCase="StepStatus.Warning" color="orange">warning</app-icon>
            <app-icon *ngSwitchCase="StepStatus.Alert" color="red">error</app-icon>
            <ng-container *ngSwitchDefault>
              <app-icon *ngIf="action.value >= action.max" color="green">check_circle</app-icon>
              <app-icon *ngIf="action.value < action.max" color="gray">radio_button_unchecked</app-icon>
            </ng-container>
          </ng-container>
          <div class="title">{{action.name}}</div>
          <div class="fill"></div>
          <b>({{action.value}} / {{action.max}})</b>
          <button icon-button tooltip="Refresh action" *ngIf="dev.on" (click)="reaction(action); $event.stopPropagation()">refresh</button>
        </app-expansion-panel-item-header>
        <app-expansion-panel-item-body class="column">
          <div class="description">{{action.description}}</div>
          <div class="button-row" *ngIf="action._tasks?.length">
            <!-- TODO Quick hack here to ensure separators dont show up on task buttons. Come up with proper config for making button names differ from task names. -->
          <button *ngFor="let _task of action._tasks" [setup]="'tasks'" [setup-id]="_task" [disabled]="action.value >= action.max" (click)="onTask(_task)">{{_task | resourceName:'tasks' | async}}</button>
          </div>
        </app-expansion-panel-item-body>
      </app-expansion-panel-item>
    </app-expansion-panel>
  </div>
</ng-container>
<ng-template #full>
  <app-stepper [(current)]="current" [steps]="steps" (currentChange)="onStep($event)">
    <app-expansion-panel>
      <app-expansion-panel-item *ngFor="let action of status.step.actions">
        <app-expansion-panel-item-header>
          <ng-container [ngSwitch]="action.status">
            <app-icon *ngSwitchCase="StepStatus.Ok" color="primary">circle</app-icon>
            <app-spinner icon-spinner *ngSwitchCase="StepStatus.Loading"></app-spinner>
            <app-icon *ngSwitchCase="StepStatus.Warning" color="orange">warning</app-icon>
            <app-icon *ngSwitchCase="StepStatus.Alert" color="red">error</app-icon>
            <ng-container *ngSwitchDefault>
              <app-icon *ngIf="action.value >= action.max" color="green">check_circle</app-icon>
              <app-icon *ngIf="action.value < action.max" color="gray">radio_button_unchecked</app-icon>
            </ng-container>
          </ng-container>
          <div class="title">{{action.name}}</div>
          <div class="fill"></div>
          <app-progress [value]="action.value" [max]="action.max"></app-progress>
          <button icon-button tooltip="Refresh action" *ngIf="dev.on" (click)="reaction(action); $event.stopPropagation()">refresh</button>
        </app-expansion-panel-item-header>
        <app-expansion-panel-item-body class="column">
          <div class="description">{{action.description}}</div>
          <div class="button-row" *ngIf="action._tasks?.length">
            <button *ngFor="let _task of action._tasks" [setup]="'tasks'" [setup-id]="_task" [disabled]="action.value >= action.max" (click)="onTask(_task)">{{_task | resourceName:'tasks' | async}}</button>
          </div>
        </app-expansion-panel-item-body>
      </app-expansion-panel-item>
    </app-expansion-panel>
  </app-stepper>
  <app-task-list *ngIf="_workflow && _workflow._tasks" [tasks]="_workflow._tasks" (open)="task.emit($event)"></app-task-list>
</ng-template>