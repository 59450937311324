<app-card-body>
  <div class="table">
    <table #grid app-grid multiple="true" [source]="source" [sizes]="sizes">
      <ng-container grid-column-def="upload">
        <grid-header-cell *grid-header-cell-def></grid-header-cell>
        <grid-cell *grid-cell-def="let row">
          <button [class.hidden]="!row.dirty || row.uploaded===undefined" icon-button (click)="$event.stopPropagation(); onUpload([row])">upload</button>
        </grid-cell>
      </ng-container>
      <ng-container grid-column-def="delete">
        <grid-header-cell *grid-header-cell-def></grid-header-cell>
        <grid-cell *grid-cell-def="let row">
          <button [class.hidden]="row.current===undefined" icon-button (click)="$event.stopPropagation(); onDelete([row])">delete</button>
        </grid-cell>
      </ng-container>
      <ng-container grid-column-def="uploaded">
        <grid-header-cell *grid-header-cell-def>Uploaded Value</grid-header-cell>
        <grid-cell *grid-cell-def="let row">{{row.uploaded?.name}}</grid-cell>
      </ng-container>
      <ng-container grid-column-def="current">
        <grid-header-cell *grid-header-cell-def>Current Value</grid-header-cell>
        <grid-cell *grid-cell-def="let row">{{row.current?.name}}</grid-cell>
      </ng-container>
      <grid-header-row *grid-header-row-def="columns"></grid-header-row>
      <grid-row class="selectable" (mouseenter)="result = row" *gridRowDef="let row; columns: columns;"></grid-row>
    </table>
    <app-grid-paginator [source]="source" [downloadable]="true">
      <button color="red" *ngIf="deleteable" (click)="onDelete(source.selection.value)">Delete {{deleteable}} item(s)</button>
      <button color="primary" *ngIf="uploadable" (click)="onUpload(source.selection.value)">Upload {{uploadable}} item(s)</button>
      <div class="fill"></div>
      <button icon-button *ngIf="data.route === 'document-templates'" tooltip="Override server-side document template validation." (click)="override = !override">{{override ? 'report' : 'report_off'}}</button>
    </app-grid-paginator>
  </div>
  <app-field autosize style="grid-area: current">
    <app-label>Current Value</app-label>
    <code class="block">{{result.current | jsonSafe}}</code>
  </app-field>
  <app-field autosize style="grid-area: uploaded">
    <app-label>Uploaded Value</app-label>
    <code class="block">{{result.uploaded | jsonSafe}}</code>
  </app-field>
  <app-field autosize style="grid-area: diff">
    <app-label>Difference</app-label>
    <code class="block">{{result.diff | jsonSafe}}</code>
  </app-field>
</app-card-body>