import { Event } from "../../../../../common/model/event";
import { EventCategory } from "../../../../../common/model/event/category";
import { PersonalResultType } from "../../../../../common/model/event/result/personal";
import { Reminder } from "./reminder";

/** Check if event is a reminder. */
export function eventReminder(event: Event): event is Reminder {
  switch (event.result.category) {
    case EventCategory.Personal:
      switch (event.result.type) {
        case PersonalResultType.Reminder:
          return true;
      }
  }

  return false;
}
