<app-card body class="column fill">
  <app-setup-base-header class="row align-end">
    <app-field class="fill">
      <app-label>Name</app-label>
      <app-string [(ngModel)]="value.name"></app-string>
    </app-field>
    <app-field class="fill">
      <app-label>Model</app-label>
      <app-resource-select collection="models" [required]="false" [(ngModel)]="value._model" (selected)="onModel($event)"></app-resource-select>
    </app-field>
  </app-setup-base-header>
  <app-setup-base-body>
    <ng-template #statements></ng-template>
  </app-setup-base-body>
  <app-setup-base-footer>
    <button icon-button *ngIf="dev.on" tooltip="Copy code" (click)="onCode()">code</button>
    <button (click)="onSubmit()">Save</button>
    <button (click)="onCancel()">Cancel</button>
  </app-setup-base-footer>
</app-card>
<app-setup-formula-palette (block)="onBlock($event)"></app-setup-formula-palette>
