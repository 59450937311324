import { Component, Inject } from '@angular/core';
import { TAB_DATA } from '../../../../common/component/tab/bar/tab-bar.model';
import { userStats, StatType, STAT_TYPE_NAME } from '../../../../common/toolbox/stat';
import { CollectionsSupervisorHistoryData } from './collections-supervisor-history.model';
import { ClientSource } from '../../../../common/toolbox/source/client';

/** Stat history flipped into an hourly format. */
type StatHour = { time: Date; disable: boolean; } & Record<StatType, number>

@Component({
  selector: 'app-collections-supervisor-history',
  templateUrl: './collections-supervisor-history.component.html',
  styleUrls: ['./collections-supervisor-history.component.scss'],
  host: {
    class: 'column'
  }
})
export class CollectionsSupervisorHistoryComponent {
  /** Names for each stat type. */
  readonly STAT_TYPE_NAME = STAT_TYPE_NAME;
  /** List of columns to exclude from history view. */
  readonly STAT_DISABLE: Partial<Record<StatType, boolean>> = {
    [StatType.AccountsDistinctWorked]: true,
    [StatType.PromisesHonored]: true
  };

  /** Pre-calculated totals for footer. */
  footer = userStats(0);
  /** Columns to display in stats table. */
  columns: (keyof StatHour)[] = [];
  /** Data displayed in stats table. */
  source = new ClientSource<StatHour>();
  /** True if given column is disabled. */
  disable = userStats(false);

  static title(data: CollectionsSupervisorHistoryData) {
    return data.history.name;
  }

  constructor(
    @Inject(TAB_DATA)
    public data: CollectionsSupervisorHistoryData
  ) {
    this.columns = ['time', ...data.types];
    let end = data.hours[1] - data.hours[0];
    let hours: StatHour[] = [];

    // Convert nested list of hourly stats to flat list.
    for (let i = 0; i <= end; ++i) {
      // Get time of this hour.
      let time = new Date();
      time.setHours(i + data.hours[0], 0, 0, 0);

      // Get stat of this hour.
      let hour: StatHour = { time, disable: false, ...userStats(0) };
      for (let type of data.types) {
        hour[type] = data.history[type][i] ?? 0;
        this.footer[type] += hour[type]
      }
      hours.push(hour);
    }

    this.source.items = hours;
  }
}
