import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { WorkBarComponent } from './bar/work-bar.component';
import { WorkListButtonsComponent } from './list/buttons/work-list-buttons.component';
import { WorkListComponent } from './list/work-list.component';
import { WorkQueuesComponent } from './queues/work-queues.component';

@NgModule({
  declarations: [
    WorkBarComponent,
    WorkListComponent,
    WorkListButtonsComponent,
    WorkQueuesComponent
  ],
  exports: [
    WorkBarComponent,
    WorkQueuesComponent,
    WorkListComponent,
    WorkListButtonsComponent,
  ],
  imports: [
    SharedModule
  ]
})
export class WorkModule { }
