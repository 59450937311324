<div class="row">
  <app-field class="fill">
    <app-label>Sort</app-label>
    <app-boolean [required]="false" [(ngModel)]="config.sort"></app-boolean>
  </app-field>
  <app-field class="fill">
    <app-label>Filter</app-label>
    <app-code [(ngModel)]="config.filter" [category]="property.category" [multiple]="true" [required]="false"></app-code>
  </app-field>
</div>
<div class="row">
  <app-field class="fill">
    <app-label>Prefix</app-label>
    <app-sublabel>
      <app-hover-help tooltip="Text to display before all codes." tooltip-position="right"></app-hover-help>
    </app-sublabel>
    <app-string align-right [(ngModel)]="prefix[0]"></app-string>
    <div class="divider">code</div>
    <app-string [(ngModel)]="prefix[1]"></app-string>
    <button icon-button (click)="config.prefix = undefined">clear</button>
    <button *ngIf="!config.prefix" color="white" class="add fill" button-prefix="add" position="before" (click)="prefix = config.prefix = ['', '']">Add Prefix</button>
  </app-field>
  <app-field class="fill">
    <app-label>Suffix</app-label>
    <app-sublabel>
      <app-hover-help tooltip="Text to display after all codes." tooltip-position="right"></app-hover-help>
    </app-sublabel>
    <app-string align-right [(ngModel)]="suffix[0]"></app-string>
    <div class="divider">code</div>
    <app-string [(ngModel)]="suffix[1]"></app-string>
    <button icon-button (click)="config.suffix = undefined">clear</button>
    <button *ngIf="!config.suffix" color="white" class="add fill" button-prefix="add" position="before" (click)="suffix = config.suffix = ['', '']">Add Suffix</button>
  </app-field>
</div>