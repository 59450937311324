import { Injectable, isDevMode } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { errorResponse } from '../../../../../common/toolbox/message';
import { DeveloperOptions } from '../toolbox/fusion';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class DevService {
  /** True if application in devmode. */
  on = isDevMode();
  /** Emits when devmode is toggled. */
  changed = new ReplaySubject<boolean>();

  constructor(
    private log: LogService
  ) {}

  /** Refresh after changing logged in user. */
  async refresh(_user: string) {
    let options = await DB.get('developerOptions', _user);
    if (errorResponse(options)) {
      this.log.show(options);
      return;
    }
    
    if (options) {
      this.on = options.on;
      return;
    }

    // Set initial options.
    this.toggle(_user, isDevMode());
  }

  /** Toggle dev mode. */
  async toggle(_user: string, on: boolean) {
    this.changed.next(this.on = on);
    let options = new DeveloperOptions(_user, on);
    let result = await DB.put('developerOptions', options);
    if (errorResponse(result)) {
      this.log.show(result);
    }
  }
}
