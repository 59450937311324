import { TypeInfo } from "../info/type";
import { CollectionComments } from "../model/collection";
import { COMMENT_ACTION_UNIONINFO, CommentAction } from "../model/comment/action";
import { CommentList } from "../model/comment/comment";
import { FusionCollection } from "../model/fusion";
import { FUSION_COLLECTION_COMMENTS } from "../model/fusion-map";
import { ID_DEFAULT } from "../toolbox/id";
import { objectKeys } from "../toolbox/object";
import { EnumValidator } from "../validator/enum";
import { UnionValidator } from "../validator/union";

/** A list of comment changes to apply to a resource. */
export class CommentPatch {
  constructor(
    /** Resource to modify. */
    public _id = ID_DEFAULT,
    /** Institution of resource. */
    public _inst = ID_DEFAULT,
    /** Comment actions to apply. */
    public actions: CommentAction[] = []
  ) {}

  static typeinfo: TypeInfo<CommentPatch> = {
    actions: [new UnionValidator(COMMENT_ACTION_UNIONINFO)]
  }
}

/** A request to modify comments on resources. */
export class CommentPatchRequest {
  constructor(
    /** Collection to patch comments on. */
    public collection: CollectionComments<FusionCollection> = 'attachments',
    /** List of patches to make. */
    public patches: CommentPatch[] = []
  ) {}

  static typeinfo: TypeInfo<CommentPatchRequest> = {
    collection: new EnumValidator(objectKeys(FUSION_COLLECTION_COMMENTS)),
    patches: [new CommentPatch()]
  }
}

/** Response of modifying comments on a resource. */
export type CommentPatchResponse = CommentList[];