import { Pipe } from '@angular/core';
import { arrayLast } from '../../../../../common/toolbox/array';
import { KeyJoin } from '../../../../../common/toolbox/keys';
import { FusionCollectionQuery, ResourceService } from '../service/resource.service';

/** Get display name of a resource. */
@Pipe({ name: 'resourceName' })
export class ResourceNamePipe {

  constructor(
    private resource: ResourceService
  ) {}

  async transform(_id: string | undefined, collection: FusionCollectionQuery): Promise<string> {
    let name = await this.resource.name(_id, collection);
    return arrayLast(name.split(KeyJoin.Separator)) ?? '';
  }
}
