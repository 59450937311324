import { Component } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-panel',
  templateUrl: '../tab/tab.component.html',
  styleUrls: ['../tab/tab.component.scss']
})
export class PanelComponent extends TabComponent {
  /** True if currently onscreen. */
  onscreen = false;
}
