import { ClaimCheckReportReason, ClaimDetailsType, ClaimType } from "../code/standard/disputes";
import { propInfoMerge, propinfoProperty } from "../info/prop";
import { typeinfoOf } from "../info/type";
import { Card } from "../model/card";
import { ClaimLookup } from "../model/claim/base";
import { CLAIM_TYPE_DETAILS_KEY, Claim, ClaimACH, ClaimCard, ClaimCheck, ClaimClass, ClaimCreditBureau, ClaimDisputes, ClaimJoin, ClaimJoinClass, ClaimDisputesJoin, ClaimUnion } from "../model/claim/claim";
import { CodeType } from "../model/code-type";
import { CoreClaim } from "../model/core/claim";
import { CoreDispute } from "../model/core/dispute";
import { DISPUTE_JOIN_UNIONINFO, Dispute, DisputeJoin, DisputeJoinClass, DisputeUnion } from "../model/dispute/dispute";
import { PropertyType } from "../model/property-type";
import { ApplicationSettingsKey } from "../model/setting-group";
import { Transaction } from "../model/transaction";
import { ArrayValidator } from "../validator/array";
import { UnionValidator } from "../validator/union";
import { hydrateObject } from "./hydrate";
import { MaybeId, NoId, idAdd } from "./id";
import { safeAssign } from "./object";
import { stringJoin } from "./string";

/** Regex to open claim in a new tab. */
const CLAIM_LINK_REGEX = /\/claim\/([a-f0-9]{24})$/;

/** Build a quick link to visit specified claim. */
export function claimLinkBuild(host: string, _claim: string) {
  return `${host}/claim/${_claim}`;
}

/** Extract an ID from a claim quick link. */
export function claimLinkParse(url: string) {
  let match = url.match(CLAIM_LINK_REGEX);
  return match?.[1];
}

/** Get full name of a claim from pieces. */
export function claimName(claim: Claim | ClaimUnion, codeType?: CodeType) {
  return stringJoin(codeType?.codes[claim.type], 'Claim', claim.displayId);
}

/** Build a filter for a specific reason field. */
export function claimDetailsQuery(type = ClaimType.Card, details: ClaimDetailsType) {
  let key = CLAIM_TYPE_DETAILS_KEY[type];
  return key ? { [key]: details } : {};
}

/** Get code type to filter over for a claim type. */
export function claimDetailsCode(type?: ClaimType) {
  if (type === undefined) return undefined;
  let key = CLAIM_TYPE_DETAILS_KEY[type];
  if (!key) return undefined;

  let property = propinfoProperty(key, claimUnion());
  return property?.type === PropertyType.Code ? property.category : undefined;
}

/** Get settings key of intake document template for a particular claim. */
export function claimIntakeTemplate(claim: Claim): ApplicationSettingsKey | undefined {
  switch (claim.type) {
  case ClaimType.Check:
    return claim.checkDetails.reason === ClaimCheckReportReason.Forgery ? 'disputes.check.intake.forgeryDocumentTemplate' : 'disputes.check.intake.unauthorizedDocumentTemplate';
  case ClaimType.CreditBureau:
    return undefined;
  default:
    return `disputes.${claim.type}.intake.documentTemplate`;
  }
}

/** Creates a claim join from given values, creating new claim. */
export function claimJoin(claim: MaybeId<ClaimDisputes>, disputes: MaybeId<Dispute>[]): ClaimDisputesJoin
export function claimJoin(claim: MaybeId<Claim>, disputes: MaybeId<Dispute>[]): ClaimJoin
export function claimJoin(claim: MaybeId<Claim>, disputes: MaybeId<Dispute>[] = []): ClaimJoin {
  if (claim.type === ClaimType.CreditBureau) return safeAssign(new ClaimJoinClass()[claim.type] as any, claim);
  return safeAssign(new ClaimJoinClass()[claim.type] as any, claim, { disputes: disputes.map(dispute => idAdd(dispute) ) });
}

/** Create a claim union. */
export function claimUnion(claim: MaybeId<Claim> | undefined = undefined, disputes: MaybeId<Dispute>[] = [], transactions: Transaction[] = []): ClaimUnion {
  let union = propInfoMerge(new ClaimACH(), new ClaimCard(), new ClaimCheck(), new ClaimCreditBureau(), new ClaimLookup()) as ClaimUnion;
  typeinfoOf(union).disputes = new ArrayValidator(new UnionValidator(DISPUTE_JOIN_UNIONINFO));

  let transactionMap = new Map(transactions.map(t => [t._id, t]));
  return safeAssign(union, hydrateObject(claim as ClaimUnion, union), { disputes: disputes.map(dispute => {
    let transaction = transactionMap.get(dispute._transaction) ?? new Transaction();
    let join = new DisputeJoinClass()[dispute.type];
    return safeAssign(join, hydrateObject(dispute, join), { transaction })
  })});
}

/** Converts a joined claim back to a basic claim. */
export function claimUnjoin<T extends ClaimType>(claim: ClaimUnion): ClaimClass[T]
export function claimUnjoin<T extends ClaimType>(claim: ClaimJoinClass[T]): ClaimClass[T]
export function claimUnjoin<T extends ClaimType>(claim: MaybeId<ClaimJoinClass[T]>): MaybeId<ClaimClass[T]>
export function claimUnjoin<T extends ClaimType>(claim: MaybeId<ClaimJoinClass[T]>): MaybeId<ClaimClass[T]> {
  return safeAssign(new ClaimClass()[claim.type] as ClaimClass[T], {
    ...claim,
    account: undefined,
    member: undefined,
    card: undefined,
    disputes: undefined
  } as any);
}

/** Get list of dispute IDs on a claim. */
export function claimDisputeIds(claim: MaybeId<Claim>): string[] {
  return '_disputes' in claim ? claim._disputes as string[] : [];
}

/** Get card of a claim. */
export function claimCard(claim?: ClaimJoin | ClaimUnion | CoreClaim): Card | undefined {
  return (claim as ClaimDisputesJoin)?.card;
}

/** Get list of disputes on a claim. */
export function claimDisputes(claim?: ClaimUnion): DisputeUnion[]
export function claimDisputes(claim?: ClaimJoin): DisputeJoin[]
export function claimDisputes(claim?: CoreClaim): CoreDispute[]
export function claimDisputes(claim?: ClaimJoin | ClaimUnion | CoreClaim): DisputeJoin[] | DisputeUnion[] | CoreDispute[] {
  return (claim && claim.type !== ClaimType.CreditBureau ? claim?.disputes : []) ?? [];
}

/** Type-assert an ID-less claim. */
export function claimTyped<T extends ClaimType>(claim: NoId<Claim>, type: T): claim is NoId<ClaimClass[T]> {
  return claim.type === type;
}