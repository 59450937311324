import { Component } from '@angular/core';
import { CommonCode } from '../../../../../../../../../common/code/system';
import { TaskConfigClaimReview } from '../../../../../../../../../common/model/task-config';
import { TaskAccessor } from '../../../edit/setup-task-edit.model';

@Component({
  selector: 'app-setup-task-config-claim-review',
  templateUrl: './setup-task-config-claim-review.component.html',
  styleUrls: ['./setup-task-config-claim-review.component.scss']
})
export class SetupTaskConfigClaimReviewComponent extends TaskAccessor<TaskConfigClaimReview> {
  readonly CommonCode = CommonCode;
}
