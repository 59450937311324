import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-footer',
  templateUrl: './dialog-footer.component.html',
  styleUrls: ['./dialog-footer.component.scss'],
  host: {
    class: 'row'
  }
})
export class DialogFooterComponent {}
