import { Pipe } from '@angular/core';
import { Permission } from '../../../../../common/model/permission';
import { PermissionGroup } from '../../../../../common/model/permission-group';
import { AuthService } from '../service/auth.service';

@Pipe({
  name: 'permissionSome'
})
export class PermissionSomePipe {

  constructor(
    private auth: AuthService
  ) {}

  transform(permissions?: PermissionGroup | Permission[]) {
    return this.auth.permissionSome(permissions);
  }
}
