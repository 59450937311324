<app-card body class="row fill">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Type</app-label>
          <app-code [category]="DisputesCode.LedgerType" [(ngModel)]="value.type"></app-code>
        </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>Amount Formula</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Returns the default amount of the ledger item. Defaults to {{0 | cents}}."></app-hover-help>
          </app-sublabel>
          <app-resource-select collection="formulas" [(ngModel)]="value._amountFormula"></app-resource-select>
        </app-field>
        <app-field class="fill">
          <app-label>Submit Formula</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Executes when the ledger item is submitted."></app-hover-help>
          </app-sublabel>
          <app-resource-select collection="formulas" [multiple]="true" [required]="false" [(ngModel)]="value._submitFormulas"></app-resource-select>
        </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>Description</app-label>
          <app-string [(ngModel)]="value.description" [placeholder]="value.name"></app-string>
          <button icon-button tooltip="Available fields" (click)="refield()">storage</button>
        </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>GL Account</app-label>
          <app-account-select [(ngModel)]="value._glAccount"></app-account-select>
        </app-field>
        <app-field class="fill">
          <app-label>Account</app-label>
          <app-select [(ngModel)]="value.account">
            <app-option *ngFor="let type of types" [pair]="type"></app-option>
          </app-select>
        </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>Transaction Code</app-label>
          <app-code [(ngModel)]="value.tranCode" [category]="CommonCode.Transaction"></app-code>
        </app-field>
        <app-field class="fill">
          <app-label>Transaction Source</app-label>
          <app-code [(ngModel)]="value.sourceCode" [category]="CommonCode.TransactionSource"></app-code>
        </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>Batch</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Leave empty to auto-assign batch."></app-hover-help>
          </app-sublabel>
          <app-string [(ngModel)]="value.batch" [required]="false"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Run</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Leave empty to auto-assign run."></app-hover-help>
          </app-sublabel>
          <app-string [(ngModel)]="value.run" [required]="false"></app-string>
        </app-field>
      </div>
    </app-setup-base-header>
    <app-setup-base-body></app-setup-base-body>
    <app-setup-base-footer>
      <button (click)="onSubmit()">Save</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>
<app-setup-property-list *ngIf="proxy" [object]="proxy"></app-setup-property-list>