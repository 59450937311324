<app-card-body class="column">
  <div>Enter a temporary password for {{data.name}}.</div>
  <div>{{data.name}} will need to reset their password on next login.</div>
  <form [formGroup]="builder" (ngSubmit)="dialogRef.close(builder.value.password!)">
    <app-field>
      <app-label>Password</app-label>
      <app-sublabel>
        <app-hover-help [tooltip]="PASSWORD_HELP" tooltip-position="right"></app-hover-help>
      </app-sublabel>
      <app-string type="password" formControlName="password"></app-string>
    </app-field>
    <app-field>
      <app-label>Confirm Password</app-label>
      <app-string type="password" formControlName="confirm"></app-string>
    </app-field>
    <app-button-row style="margin-top: 0.5rem" [submitDisabled]="!builder.valid" (cancel)="dialogRef.cancel()"></app-button-row>
  </form>
</app-card-body>