import { EnumMap } from "../toolbox/enum";

/** List of all built-in system codes. */
export type SystemCode = CollectionsCode | CommonCode | DisputesCode | ImportCode;

/** General code types used by all products */
export enum CommonCode {
  AccountCategory   = 'accountCategory',
  AttachmentType    = 'attachmentType',
  CardType          = 'cardType',
  ColorCode         = 'colorCode',
  Country           = 'country',
  DocumentTemplate  = 'documentTemplate',
  SearchCategory    = 'searchCategory',
  State             = 'state',
  Transaction       = 'transaction',
  TransactionSource = 'transactionSource'
}

/** Code types required for collections. */
export enum CollectionsCode {
  FollowUpResponse          = 'responseFollowUp',
  InboundContactResponse    = 'responseInboundContact',
  OutboundContactResponse   = 'responseOutboundContact',
  PromiseResponse           = 'responsePromise'
}

/** Code types requried for disputes product. */
export enum DisputesCode {
  ClaimACHReportReason                = 'claimACHReportReason',
  ClaimCardCancellationOrReturnType   = 'claimCardCancellationOrReturnType',
  ClaimCardCloseReason                = 'claimCardCloseReason',
  ClaimCardDeclineReason              = 'claimCardDeclineReason',
  ClaimCardDetailsType                = 'claimCardDetailsType',
  ClaimCardLatePresentmentLength      = 'claimCardLatePresentmentLength',
  ClaimCardLocation                   = 'claimCardLocation',
  ClaimCardOverturnReason             = 'claimCardOverturnReason',
  ClaimCardProcessingErrorReason      = 'claimCardProcessingErrorReason',
  ClaimCardProcessingErrorType        = 'claimCardProcessingErrorType',
  ClaimCardReportType                 = 'claimCardReportType',
  ClaimCardResolutionReason           = 'claimCardResolutionReason',
  ClaimCardReturnReason               = 'claimCardReturnReason',
  ClaimCardShippingCompany            = 'claimCardShippingCompany',
  ClaimCardSignatureMethod            = 'claimCardSignatureMethod',
  ClaimCardTransactionAmountError     = 'claimCardTransactionAmountError',
  ClaimCardTransactionError           = 'claimCardTransactionError',
  ClaimCheckDetailsType               = 'claimCheckDetailsType',
  ClaimCheckReportReason              = 'claimCheckReportReason',
  ClaimStatus                         = 'claimStatus',
  ClaimType                           = 'claimType',
  ClaimViewReason                     = 'claimViewReason',
  DisputeCreditStatus                 = 'disputeCreditStatus',
  DisputeStatus                       = 'disputeStatus',
  DisputeType                         = 'disputeType',
  LedgerType                          = 'ledgerTransactionType' // TODO shorten this to ledgerType in future PR.
}

/** Hardcoded code types expected to exist for imports. */
export enum ImportCode {
  AccessStatus         = 'accessStatus',
  AccountCard          = 'accountCard',
  AccountComment       = 'accountComment',
  AccountMember        = 'accountMember',
  AccountType          = 'accountType',
  AccountWarning       = 'accountWarning',
  ActiveDuty           = 'activeDuty',
  BankruptcyChapter    = 'bankruptcyChapter',
  CardBlock            = 'cardBlock',
  CardReason           = 'cardReason',
  CardStatus           = 'cardStatus',
  CardType             = 'cardType',
  ChargeOff            = 'chargeOff',
  Collateral           = 'collateral',
  Email                = 'email',
  Gender               = 'gender',
  LoanPurpose          = 'loanPurpose',
  MemberCard           = 'memberCard',
  Occupancy            = 'occupancy',
  Phone                = 'phone',
  ShareCode            = 'shareCode',
  ShareInsurance       = 'shareInsurance',
  TaxID                = 'taxId',
  StandardEntryClass   = 'standardEntryClass'
}

/** Name of each phone type code. */
export const STANDARD_PHONE_NAME: EnumMap = {
  'PRIMARY': 'Primary',
  'HOME': 'Home',
  'BUSN': 'Work',
  'CELL': 'Cell',
  'FAX': 'Fax'
};

/** Name of each email type code. */
export const STANDARD_EMAIL_NAME: EnumMap = {
  '0': '#0'
};

/** Name of each code type. */
export const CODE_TYPE_NAME = [
  [CollectionsCode, 'CollectionsCode'],
  [DisputesCode, 'DisputesCode'],
  [ImportCode, 'ImportCode'],
  [CommonCode, 'CommonCode']
];

/** Mapping of code types to source code names. */
export const CODE_TYPE_MAP = new Map(CODE_TYPE_NAME.flatMap(
  ([type, name]) => Object.entries(type as any).map(([key, value]) => [value, `${name}.${key}`])
));