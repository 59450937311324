import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.disabled]': 'disabled'
  }
})
export class IconComponent {
  @Input() disabled = false;
}
