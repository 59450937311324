import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { ExecutiveReportComponent } from "./report/executive-report.component";

@NgModule({
  declarations: [
    ExecutiveReportComponent
  ],
  exports: [
    ExecutiveReportComponent
  ],
  imports: [
    SharedModule
  ],
  providers: []
})
export class ExecutiveModule { }