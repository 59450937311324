<table #grid app-grid [required]="required" [multiple]="_multiple" [sizes]="sizes" [source]="source" [class.expand]="expand" (rowChange)="rowChange.next($event)" (sortChange)="sortChange.next($event)" (selectionChange)="selectionChange.next($event)">
  
  <ng-container *ngFor="let column of precolumns; index as i;" [grid-column-def]="column.key+i">
    <grid-header-cell *grid-header-cell-def [useNameAsKey]="false">{{column.name}}</grid-header-cell>
    <grid-cell *grid-cell-def="let row; let i = index">
      <ng-container *ngIf="(column.component | typeof) !== 'string'">
        <ng-container *ngComponentOutlet="$any(column.component); injector: injectors[i]"></ng-container>
      </ng-container>
      <ng-container *ngIf="(column.component | typeof) ==='string'">
        {{row | propertyValue:VALUE:column.key:enums | async}}
      </ng-container>
    </grid-cell>
  </ng-container>

  <ng-container *ngFor="let column of table.columns; index as i;" [grid-column-def]="column.key+(i+precolumns.length)">
    <grid-header-cell *grid-header-cell-def [key]="column.key" [useNameAsKey]="false">{{column.key | display:column.name:map[column.key+(i+precolumns.length)] | async}}</grid-header-cell>
    <grid-cell *grid-cell-def="let row" [class.wrap]="column.wrap" [attr.color]="cellHighlightFormulas[i] | formula:row">{{row | propertyValue:VALUE:column.key:enums | async}}</grid-cell>
  </ng-container>

  <ng-container *ngFor="let column of postcolumns; index as i;" [grid-column-def]="column.key+(i+precolumns.length+table.columns.length)">
    <grid-header-cell *grid-header-cell-def [useNameAsKey]="false">{{column.name}}</grid-header-cell>
    <grid-cell *grid-cell-def="let row; let i = index">
      <ng-container *ngIf="(column.component | typeof) !=='string'">
        <ng-container *ngComponentOutlet="$any(column.component); injector: injectors[i]"></ng-container>
      </ng-container>
      <ng-container *ngIf="(column.component | typeof) ==='string'">
        {{row | propertyValue:VALUE:column.key:enums | async}}
      </ng-container>
    </grid-cell>
  </ng-container>
  <grid-header-row *grid-header-row-def="names"></grid-header-row>
  <grid-row class="selectable" *gridRowDef="let row; columns: names;" [attr.color]="highlightFormula | formula:row"></grid-row>
</table>
<div class="row">
  <app-grid-paginator class="fill" *ngIf="paginator" [source]="source" [downloadable]="true" (download)="onDownload()">
    <ng-content></ng-content>
  </app-grid-paginator>
</div>
<app-loader *ngIf="loading"></app-loader>