<app-field class="fill">
  <app-label>Formula</app-label>
  <app-resource-select collection="formulas" [(ngModel)]="condition._formula"></app-resource-select>
</app-field>
<app-field class="fill">
  <app-label>Filters</app-label>
  <app-resource-select collection="formulas" [multiple]="true" [required]="false" [(ngModel)]="condition._filters"></app-resource-select>
</app-field>
<app-field class="fill">
  <app-label>Mode</app-label>
  <app-select empty="Default" [(ngModel)]="condition.mode" [required]="false">
    <app-option *ngFor="let mode of modes" [pair]="mode"></app-option>
  </app-select>
</app-field>