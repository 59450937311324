import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ErrorResponse } from '../../../../../../common/message/error';
import { CodeMap, CodeType } from '../../../../../../common/model/code-type';
import { Display, DisplayPartial, DisplayType, DisplayValue } from "../../../../../../common/model/display";
import { Field } from "../../../../../../common/model/form/field";
import { Form } from "../../../../../../common/model/form/form";
import { Formula } from '../../../../../../common/model/formula/formula';
import { FormulaProxy } from "../../../../../../common/model/formula/proxy";
import { Member } from "../../../../../../common/model/member";
import { Model } from "../../../../../../common/model/model";
import { Pos } from "../../../../../../common/model/pos";
import { PropertyType } from "../../../../../../common/model/property-type";
import { Table } from "../../../../../../common/model/table";
import { Transaction } from "../../../../../../common/model/transaction";
import { UserPreview } from "../../../../../../common/model/user/user";
import { arrayDefined } from '../../../../../../common/toolbox/array';
import { formulaRun } from '../../../../../../common/toolbox/formula/formula';
import { MaybeId, idMaybe, idNull } from "../../../../../../common/toolbox/id";
import { keyFlatGet, keyNestedGet, keyNestedSet } from "../../../../../../common/toolbox/keys";
import { Comparison, deepAssign, objectCompare, shallowAssign } from "../../../../../../common/toolbox/object";
import { dateOffset } from "../../../../../../common/toolbox/time";
import { AuthService } from '../../service/auth.service';
import { CodeTypeService } from '../../service/code-type.service';
import { DisplayService } from '../../service/display.service';
import { FormulaService } from '../../service/formula.service';
import { ModelService } from '../../service/model.service';
import { TableService } from '../../service/table.service';
import { UserService } from '../../service/user.service';
import { debugElementMake } from '../../toolbox/element/debug';
import { setupElementMake } from '../../toolbox/element/setup';
import { FormControlComponent } from './control/form-control.component';
import { FormChange, FormConfig, FormStatus } from './form.model';

/** 
 *  Mapping of property types to display types.
 *  TODO remove this later once missing types added to property type.
 */
export const DISPLAY_TYPE: { [P in PropertyType]?: DisplayType } = {
  [PropertyType.Member]: DisplayType.Member,
  [PropertyType.Phone]: DisplayType.Phone,
  [PropertyType.Email]: DisplayType.Email,
  [PropertyType.User]: DisplayType.User,
  [PropertyType.Transaction]: DisplayType.Transaction
};

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  host: {
    class: 'column'
  }
})
export class FormComponent {
  /** Main form to project fields into. */
  @ViewChild('fields', { static: false, read: ViewContainerRef }) private fieldsRef?: ViewContainerRef;

  /** True to prevent writes to value, overriding mutate. */
  @Input() readonly = false;

  /** Configuration for form. */
  @Input() set config(config: FormConfig | undefined) {
    this.reconfigure(config);
  }

  /** Emits on submission. */
  @Output() submit = new EventEmitter<DisplayValue>();
  /** Emits on value changed */
  @Output() changed = new EventEmitter<FormChange>();
  /** Emits on cancelling. */
  @Output() cancel = new EventEmitter<void>();
  /** Emits when new errors are available. */
  @Output() errors = new EventEmitter<string[]>();
  
  /** Current value of form. */
  value = new DisplayValue();
  /** True if form is valid. */
  valid = true;

  /** Style for form body. */
  protected style = Pos.css(Form.SIZE_DEFAULT);
  /** Current form status to pass down to components. */
  protected status = new FormStatus();

  /** Information about how to display fields. */
  private form = idMaybe(new Form());
  /** List of codes in form. */
  private codes: CodeMap = {};
  /** Last bound list of available members. */
  private members: Member[] = [];
  /** List of available users. */
  private users: UserPreview[] = [];
  /** List of available transactions. */
  private transactions: Transaction[] = [];
  /** Mapping of field keys to prefetched tables. */
  private tables = new Map<string, Table>();
  /** Mapping of formulaIds to prefetched formulas. */
  private formulas = new Map<string, Formula>();
  /** Model of current form. */
  private model = new Model();

  /** Mapping from field names to concrete components. */
  private components: FormControlComponent[] = [];
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();
  /** Subscriptions for linked fields. */
  private subscription = Subscription.EMPTY;

  constructor(
    public auth: AuthService,
    public elementRef: ElementRef,
    private codeTypeService: CodeTypeService,
    private displayService: DisplayService,
    private formulaService: FormulaService,
    private modelService: ModelService,
    private tableService: TableService,
    private userService: UserService
  ) {
    debugElementMake(this);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.subscription.unsubscribe();
  }

  /** Force specific field to refresh value. */
  onChange(change: FormChange) {
    let proxy = this.value.formula;

    // Patch all components related to this value.
    for (let component of this.components) {
      // Patch components displaying this value.
      if (component.control.field.key === change.key) {
        component.writeValue(change.value);
        return;
      }

      // Patch components showing calculated value that involves this key.
      if (component.control.type !== DisplayType.Formula) continue;
      if (!proxy.keys[component.control.key]?.has(change.key)) continue;
      component.writeValue(this.value.formula[component.control.key]);
    }
  }

  /** Callback when debugging form value. */
  onDebug() {
    return this.value;
  }

  /** Refresh form layout and contents after getting new config. */
  async reconfigure(config?: FormConfig) {
    // Reset form style of overlapping fields detected.
    if (!config) return;
    Form.correct(config.form);
    setupElementMake(this.elementRef.nativeElement, 'forms', config.form._id);

    // Prefetch all needed users, tables, formulas and models.
    await Promise.all([this.retable(config.form), this.reformula(config.form), this.remodel(config.form), this.reuser()]);

    // Listen to changes propagated down to form.
    if (config.changes) {
      config.changes.pipe(takeUntil(this.destroy)).subscribe(change => this.onChange(change));
    }

    // Determine grid layout of form.
    config.labels = config.labels ?? true;
    config.inputs = config.inputs ?? true;
    let height = config.labels && config.inputs ? undefined : '2rem' as const;
    this.style = Pos.css(config.form.size, undefined, height);

    // Configure fallback values.
    if (config.value) {
      this.displayService.fallback(config.value, this.model);
      config.members = config.members ?? config.value.account?.members;
    }

    // Set up initial form status.
    this.status = new FormStatus(!!config.value?.model, config.optional, config.mutate, config.labels, config.inputs);
    this.form = config.form;

    // Set up available data.
    this.revalue(config.value);
    if (config.members) this.members = config.members;
    if (config.transactions) this.transactions = config.transactions;

    // Fetch all necessary formulas.
    let _inst = config.form._inst;
    let _formulas = Display.formulas(config.form.fields);
    let formulas = await this.formulaService.items(_formulas.map(_id => ({ _inst, _id })));

    // Create dummy object to transparently access formulas.
    this.value.formula = FormulaProxy.formulas(formulas);
    this.value.formula.input = this.value;

    // Fetch all needed codes.
    let categories = Form.codes(config.form, this.value, formulas);
    let types = await this.codeTypeService.items(categories.map(category => ({ _inst, category })));
    this.value.formula.enums = CodeType.enum(types);
    this.codes = CodeType.map(types);

    // Patch initial data and connect form fields.
    this.populate();
    this.patch();
    this.listen();
  }

  /** Create control for each field in form. */
  private populate() {
    this.clear();
    for (let field of this.form.fields) {
      this.field(field);
    }
  }

  /** Patch controls with initial value. */
  private patch() {

    // Execute each formula to set values.
    let error = new ErrorResponse('Failed to execute form open formulas.', [])
    for (let _formula of this.form._formulas ?? []) {
      let formula = this.formulas.get(_formula);

      if (!formula) {
        error.list!.push(new ErrorResponse(`Failed to find formula: ${_formula}`));
        continue;
      }

      formulaRun(formula, this.value);
    }

    // Set initial value in each component.
    for (let component of this.components) {
      let value = keyFlatGet(component.control.field.key, this.value);
      if (value) component.writeValue(value);
    }
  }

  /** Start listening to value changes on components. */
  private listen() {
    // Clear existing links.
    this.subscription.unsubscribe();
    this.subscription = new Subscription();

    // Join up any linked fields.
    // TODO This will link to first field found, ignoring duplicates.
    // Change from linking to components, to linking to observables on top-level form?
    for (let component of this.components) {
      this.subscription.add(component.errors.subscribe(() => {
        let values = this.components.map(c => c.errors.value).flat();
        this.errors.next(values);
        this.valid = !values.length;
      }));

      switch (component.control.property.type) {
        case PropertyType.Date:
          // Update date when linked component emits new date.
          let dateProperty = component.control.property;
          if (!dateProperty.link) break;

          let dateComponent = this.components.find(c => c.control.field.key === dateProperty.link);
          if (!dateComponent) break;

          this.subscription.add(dateComponent.valueChange.subscribe((value: Date | undefined) => {
            component.writeValue(dateOffset(value ?? new Date(), dateProperty.value), true);
          }))
          break;
        case PropertyType.Phone:
          // Update list of phones when linked component emits new list.
          let phoneProperty = component.control.property;
          let phoneLink = `model.${phoneProperty.link}`;
          let phoneComponent = this.components.find(c => c.control.field.key === phoneLink);
          if (!phoneComponent) break;

          this.subscription.add(phoneComponent.phoneChange.subscribe(phones => {
            component.reitem(DisplayType.Phone, phones);
          }));
          break;
        case PropertyType.Email:
          let emailProperty = component.control.property;
          let emailLink = `model.${emailProperty.link}`;
          let emailComponent = this.components.find(c => c.control.field.key === emailLink);
          if (!emailComponent) break;

          this.subscription.add(emailComponent.emailChange.subscribe(emails => {
            component.reitem(DisplayType.Email, emails);
          }));
      }
    }
    for (let component of this.components) {
      let [type] = Display.split(component.control.field);
      this.subscription.add(component.valueChange.subscribe(value => {

        if (!this.readonly) {
          // This event fires any time the value is set, even if the new value is the same.
          // So we have to compare to the current value here to prevent extra events from being emitted.
          let key = component.control.field.key;
          let old = keyNestedGet(key, this.value);
          let dirty = objectCompare(old, value) !== Comparison.Equal;

          // Apply changes to form value.
          keyNestedSet(component.control.field.key, this.value, value);
          if (dirty) this.changed.emit(new FormChange(key, value));
          if (type !== DisplayType.Model) return;
        }
      }));
    }
  }

  /** Clear all components from field list. */
  private clear() {
    if (!this.fieldsRef) return;
    this.fieldsRef.clear();
    this.components = [];
  }

  /** Add a new field to main form. */
  private field(field: Field) {
    if (!this.fieldsRef) return;

    // Create component and inject into field.
    let reference = this.fieldsRef.createComponent<FormControlComponent>(FormControlComponent);
    let component = reference.instance;
    component.populate(field, this.form, this.value, this.codes, this.members, this.users, this.transactions, this.tables, this.status);
    this.components.push(component);
  }

  /**
   *  Prefetch all tables for given form.
   *  TODO Indexing table by field.key means that all fields with same key will have same table.
   */
  private async retable(form: MaybeId<Form>) {
    this.tables = new Map<string, Table>();

    for (let field of form.fields) {
      if (idNull(field._table)) {
        // Set empty table.
        this.tables.set(field.key, new Table());
      } else {
        // Get specified table.
        this.tables.set(field.key, await this.tableService.item({ _inst: this.auth._inst, _id: field._table }));
      }
    }
  }

  /** Prefetch all formulas for given form. */
  private reformula(form: MaybeId<Form>) {
    this.formulas = new Map<string, Formula>();

    // Find all form-level and field-level forms.
    let _formulas = [
      ...(form._formulas ?? []),
      ...arrayDefined(form.fields.map(field => idNull(field._validator) ? undefined : field._validator))
    ];

    // Fetch all simultaneously.
    return Promise.all(_formulas.map(async _id => this.formulas.set(_id, await this.formulaService.item({ _inst: this.auth._inst, _id }))))
  }

  /** Prefetch model for given form. */
  private async remodel(form: MaybeId<Form>) {
    this.model = idNull(form._model) ? new Model() : await this.modelService.item({ _inst: this.auth._inst, _id: form._model });
  }

  /** Prefetch all users of institution. */
  private async reuser() {
    this.users = await this.userService.previews(this.auth._inst);
  }

  /** Reset to default value, populate missing input properties, then copy in value. */
  private revalue(partial?: DisplayPartial) {
    if (this.status.mutate) {
      this.value = shallowAssign(new DisplayValue(), partial);
    } else {
      this.value = deepAssign(new DisplayValue(), partial);
    }
  }

  /** Returns id of this form. */
  id() {
    return this.form._id;
  }
}
