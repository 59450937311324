import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Box } from '../../../../../../common/model/box';
import { Pos } from '../../../../../../common/model/pos';
import { PropertyType } from "../../../../../../common/model/property-type";
import { DatePeriod } from "../../../../../../common/toolbox/time";
import { fieldControlProviders } from '../../toolbox/angular';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-size',
  templateUrl: './size.component.html',
  styleUrls: ['./size.component.scss'],
  providers: fieldControlProviders(SizeComponent),
  host: {
    '(focusout)': 'onFocusOut($event)'
  }
})
export class SizeComponent extends FieldControl implements ControlValueAccessor {
  readonly PropertyType = PropertyType;
  readonly DatePeriod = DatePeriod;

  /** Reference to width input. */
  @ViewChild('widthInput', { static : true }) widthRef!: ElementRef<HTMLInputElement>;
  /** Reference to height input. */
  @ViewChild('heightInput', { static : true }) heightRef!: ElementRef<HTMLInputElement>;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }

  /** True to make a value required. */
  @Input() required = true;
  /** True to show button. */
  @Input() button = true;
  /** Minimum size. */
  @Input() min = new Pos(1, 1);
  /** Maximum size. */
  @Input() max = new Pos(5, 5);

  /** Value bound to size field. */
  value: Pos | undefined;

  constructor(
    private element: ElementRef
  ) {
    super();
  }

  writeValue(value?: Pos) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.changed(this.value = this.clamp(value));
    this.format(this.value);
  }

  /** Callback when focus changes within or outside component. */
  onFocusOut($event: FocusEvent) {
    if (this.element.nativeElement.contains($event.relatedTarget)) return;
    this.format(this.value);
  }

  /** Set width of value. */
  onInput(field: keyof Pos, $event: Event) {
    let target = $event.target as HTMLInputElement;
    let value = +target.value;
    if (isNaN(value)) return;
    if (!this.value) this.value = new Pos(this.min.x, this.min.y);

    this.value[field] = value;
    this.changed(this.value = this.clamp(this.value));
  }

  /** Split size into components and set input fields. */
  protected format(value = this.value) {
    this.widthRef.nativeElement.value = `${value?.x ?? this.min.x}`;
    this.heightRef.nativeElement.value = `${value?.y ?? this.min.y}`;
  }

  /** Clamp size value if needed. */
  private clamp(size: Pos | undefined) {
    if (this._readonly || size === undefined) return size;
    return Pos.clamp(size, new Box(this.min.x, this.min.y, this.max.x, this.max.y));
  }
}
