import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FileConvertPostRequest } from '../../../../../../common/message/file';
import { MimeCategory } from '../../../../../../common/model/mime';
import { base64ObjectURL } from '../../../../../../common/toolbox/base64';
import { errorResponse } from "../../../../../../common/toolbox/message";
import { mimeCategory, mimeChange, mimeSplit } from '../../../../../../common/toolbox/mime';
import { AuthService } from '../../service/auth.service';
import { LogService } from '../../service/log.service';
import { fileBase64, fileCreate, fileDownload } from '../../toolbox/file';
import { postRequest } from '../../toolbox/request';

/** File types that can be reliably displayed within in an iframe. */
const IFRAME_WHITELIST: Record<string, string> = {
  doc: 'html',
  docx: 'html',
  gif: 'gif',
  html: 'html',
  jpeg: 'jpeg',
  png: 'png',
  txt: 'txt'
};

@Component({
  selector: 'app-object',
  templateUrl: './object.component.html',
  styleUrls: ['./object.component.scss'],
  host: {
    class: 'fill'
  }
})
export class ObjectComponent {

  /** Tagged file blob to display. */
  @Input() set file(file: File | undefined) {
    this.display(file);
  }

  /** True if currently loading. */
  @Input() loading = false;
  /** Explicit override for file download, or toggle to disable download. */
  @Input() download?: File | boolean;
  
  /** Current data URI if displaying image. */
  image?: string;
  /** Current data URI for viewing file. */
  iframe?: string;
  /** True to display preview warning. */
  preview = false;
  /** True to display convert warning. */
  warn = false;

  /** Last bound file to object component. */
  protected _file?: File;

  constructor(
    private auth: AuthService,
    private log: LogService,
    private http: HttpClient
  ) {}

  ngOnDestroy() {
    this.cleanup();
  }

  /** Callback when downloading viewed file. */
  onDownload() {
    let download = this.download instanceof File ? this.download : this._file;
    if (download) fileDownload(download);
  }

  /** Convert file to form that can be displayed. */
  private async display(file?: File) {
    this.cleanup();
    if (!file) return;
    this._file = file;

    // Attempt conversion to a viewable format.
    let [input, output] = [file.name, file.name];
    let [inExt, outExt] = [mimeSplit(input)[1], IFRAME_WHITELIST[mimeSplit(input)[1]]];
    if (outExt && inExt !== outExt) {
      this.loading = true;

      // Convert file to base64.
      let data = await fileBase64(file);
      if (errorResponse(data)) {
        this.log.show(data);
        this.loading = false;
        return;
      }

      // Request file conversion from server.
      output = mimeChange(input, outExt);
      let request = new FileConvertPostRequest(this.auth._inst, file.name, output, data);
      let converted = await postRequest(this.http, 'files/convert', request, { responseType: 'blob' });
      if (errorResponse(converted)) {
        this.log.show(converted);
        this.loading = false;
        return;
      }

      file = fileCreate([converted], output);
      this.loading = false;
      this.warn = true;
    }

    let base64 = await fileBase64(file);
    if (errorResponse(base64)) {
      this.log.show(base64);
      return;
    }

    if (mimeCategory(output) === MimeCategory.Image) {
      this.image = base64ObjectURL(output, base64);
    } else {
      this.iframe = base64ObjectURL(output, base64);
    }
  }

  /** Cleanup current data URI. */
  private cleanup() {
    this._file = this.iframe = this.image = undefined;
    this.warn = this.preview = this.loading = false;
  }
}
