import { Component, Input } from '@angular/core';
import { AccountJoin } from '../../../../../../common/model/account/account';
import { BUILTIN_FORMULAS } from "../../../../../../common/model/builtin/formula";
import { AccountFormula } from '../../../../../../common/model/builtin/formula/account';
import { Card } from '../../../../../../common/model/card';
import { Chip, ChipResult } from "../../../../../../common/model/chip";
import { DisplayValue } from "../../../../../../common/model/display";
import { ApplicationSettingsKey } from '../../../../../../common/model/setting-group';
import { formulaStatements } from '../../../../../../common/toolbox/formula/formula';
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { FormConfig } from '../../../common/component/form/form.model';
import { FormService } from '../../../common/service/form.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
  host: {
    class: 'card'
  }
})
export class AccountInfoComponent {
  
  /** Configuration for account form. */
  config?: FormConfig;
  /** Chips to display along top of account. */
  results: ChipResult[] = [];

  /** Title for form. */
  @Input() title = 'Account Info';
  /** Override for displayed account form. */
  @Input() form?: ApplicationSettingsKey;
  /** True to display loading spinner. */
  @Input() loading = false;
  /** Account currently being viewed. */
  @Input() set account(account: AccountJoin) {
    this.value.account = account;
    this.refresh();
  }

  @Input() set card(card: Card) {
    this.value.card = card;
    this.refresh();
  };

  /** Display value containing current viewed account. */
  value = new DisplayValue();

  constructor(
    private forms: FormService
  ) {}

  /** Fetch configuration for new account to display. */
  private async refresh(): Promise<boolean | void> {

    // TODO associate chips with a particular account type and fetch these from API.
    let chips: Chip[] = [
      new Chip(StatusLevel.Notice, formulaStatements(BUILTIN_FORMULAS[AccountFormula.Risk].statements)),
      new Chip(StatusLevel.Warning, formulaStatements(BUILTIN_FORMULAS[AccountFormula.HighRisk].statements)),
      new Chip(StatusLevel.Alert, formulaStatements(BUILTIN_FORMULAS[AccountFormula.Bankrupt].statements))
    ];
    
    let input = new DisplayValue();
    input.account = this.value.account;
    let member = this.value.account.members[0];

    if (member) input.member = member;
    if (this.value.card) input.card = this.value.card;

    this.results = Chip.list(chips, input);
    this.config = {
      form: await this.forms.setting(this.form ?? 'general.account.form'),
      value: input
    };
  }
}
