import { LedgerType } from "../../code/standard/disputes";
import { Account } from "../account/account";
import { DisputeJoin } from "./dispute";
import { DisputeTotal } from "./total";

/** Engine for calculating current dispute amount totals. */
export class DisputeEngine {
  
  /** Calculate totals for a single dispute. */
  run(dispute: DisputeJoin, account: Account): DisputeTotal {
    let total = new DisputeTotal(dispute.transaction?.amount);

    for (let ledger of dispute.ledger ?? []) {
    switch (ledger.type) {
      case LedgerType.Credit:
        // Credits that are from the customer's account are reversed credit.
        if (ledger.account === account.number) total.credit -= ledger.amount;
        else total.recovered += ledger.amount;
        break;
      case LedgerType.Debit:
        // This seems inverted but is correct, because a debit for the bank is a credit for the consumer.
        if (ledger.account === account.number) total.credit += ledger.amount;
        else total.recovered -= ledger.amount;
        break;
      }
    }

    total.loss = total.credit - total.recovered;
    return total;
  }

  /** Calculate grant total for a list of disputes. */
  runAll(disputes: DisputeJoin[], account: Account): DisputeTotal {
    return DisputeTotal.merge(disputes.map(dispute => this.run(dispute, account)));
  }
}