import { ClaimJoin } from "../../../../../common/model/claim/claim";
import { DisputeTaskOpen } from "../../common/toolbox/dispute";

/** Configuration for opening claim component. */
export class ClaimData {
  constructor(
    /** Claim to open. */
    public claim: ClaimJoin,
    /** Task to open in claim. */
    public task?: DisputeTaskOpen
  ) {}

  /** Check if a value is ClaimData. */
  static check(value: any): value is ClaimData {
    return value instanceof Object && 'claim' in value;
  }
}