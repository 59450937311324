import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ElementRef, Provider, forwardRef } from "@angular/core";
import { AbstractControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Newable } from "../../../../../common/toolbox/object";
import { FieldControl } from "../component/field/field-control";
import { overlayDropdown } from "./overlay";

/** Validator to ensure field matches another field. */
export function validatorMatch<T>(field: keyof T) {
  return function (control: AbstractControl) {
    let parent = control.parent as FormGroup | undefined;
    let value = parent?.controls[field]?.value;
    return !!value && !!control.value && value === control.value ? null : { isMatching: false };
  };
}

/** Creates overlay configuration or reuses existing one. */
export function overlayCreate(element: ElementRef<HTMLElement>, overlay: Overlay, overlayRef?: OverlayRef) {
  if (overlayRef) return overlayRef;

    let overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['menu-panel', 'elevation-high'],
      scrollStrategy: overlay.scrollStrategies.reposition(),
      positionStrategy: overlay.position()
        .flexibleConnectedTo(element)
        .withLockedPosition()
        .withGrowAfterOpen()
        .withTransformOriginOn('.menu-panel')
    });

    overlayDropdown(overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy);
    return overlay.create(overlayConfig);
}

/** Generate standard list of providers for component. */
export function fieldControlProviders<T extends FieldControl>(Component: Newable<T>): Provider[] {
  return [{
    provide: FieldControl,
    useExisting: forwardRef(() => Component)
  }, {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => Component)
  }, {
    multi: true,
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => Component)
  }];
}