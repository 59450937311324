<div class="row">
  <app-field class="fill" [readonly]="value.system">
    <app-label>Name</app-label>
    <app-string [(ngModel)]="value.name" [readonly]="value.system" [required]="false" [dirty]="dirty" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-string>
  </app-field>
  <app-field class="fill" [readonly]="value.system">
    <app-label>Street Address</app-label>
    <app-string [(ngModel)]="value.street" [readonly]="value.system" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-string>
  </app-field>
</div>
<div class="row">
  <app-field class="fill" [readonly]="value.system">
    <app-label>City</app-label>
    <app-string [(ngModel)]="value.city" [readonly]="value.system" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-string>
  </app-field>
  <app-field class="fill" [readonly]="value.system">
    <app-label>Postal / ZIP Code</app-label>
    <app-string [(ngModel)]="value.zip" [readonly]="value.system" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-string>
  </app-field>
</div>
<div class="row">
  <app-field class="fill" [readonly]="value.system">
    <app-label>State</app-label>
    <app-code [(ngModel)]="value.state" [readonly]="value.system" [category]="CommonCode.State" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-code>
  </app-field>
  <app-field class="fill" [readonly]="value.system">
    <app-label>Country</app-label>
    <app-code [(ngModel)]="value.country" [readonly]="value.system" [category]="CommonCode.Country" [required]="false" (dirtyChange)="dirtyChange.next(dirty = $event)"></app-code>
  </app-field>
</div>