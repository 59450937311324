import { SchemaInfo } from "../info/schema";
import { TypeInfo } from "../info/type";
import { SCHEMA_OBJECT } from "../model/schema/base";

/** Message returned when there is an authentication error. */
export class ErrorResponse {
  constructor(
    /** Error message. */
    public error = 'An error has occurred.',
    /** Additional suberrors. */
    public list?: ErrorResponse[],
    /** A detail object for error. */
    public details?: any
  ) {}

  // Can only validate top-level list due to infinite recursion of errors.
  static typeinfo: TypeInfo<ErrorResponse> = {
    list: [{ error: '' }],
    details: {}
  }

  static schemainfo: SchemaInfo<ErrorResponse> = {
    list: SCHEMA_OBJECT
  }

  /** Flatten an error list into a single string. */
  static flatten(error: ErrorResponse, depth = 0): string {
    let indent = `\t`.repeat(depth);
    if (!error.list?.length) return `${indent}${error.error}`;
    return `${indent}${error.error}\n${error.list.map(error => ErrorResponse.flatten(error, depth + 1)).join('\n')}`;
  }

  /** Create from a list of strings. */
  static list(error: string, list: string[], details?: any) {
    return new ErrorResponse(error, list.map(e => new ErrorResponse(e)), details);
  }
}