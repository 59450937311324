import { CollectionInfo } from "../../info/collection";
import { SettingsInfo, SettingType } from "../../info/settings";
import { ID_DEFAULT } from "../../toolbox/id";
import { FusionCollection } from "../fusion";

/** General settings used across all products. */
export class GeneralSettings {

  /** List of default attachment values. */
  attachment = new class {
    /** Default table. */
    table = ID_DEFAULT;

    static settingsinfo: SettingsInfo<GeneralSettings['attachment']> = {
      table: {
        type: SettingType.Table,
        name: 'Attachment Table',
        description: 'Default table for attachments.'
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, GeneralSettings['attachment']> = {
      table: 'tables'
    }
  };

  /** List of default account values */
  account = new class {
    /** Default table for card accounts. */
    cardTable = ID_DEFAULT;
    /** Default form. */
    form = ID_DEFAULT;
    /** Form used to setup accounts. */
    setupForm = ID_DEFAULT;
    /** Default form for member sidebar on account page. */
    memberForm = ID_DEFAULT;
    /** Default table. */
    table = ID_DEFAULT;

    static settingsinfo: SettingsInfo<GeneralSettings['account']> = {
      cardTable: {
        type: SettingType.Table,
        name: 'Account Card Table',
        description: 'Default table for card accounts.',
      },
      form: {
        type: SettingType.Form,
        name: 'Account Form',
        description: 'Default form for accounts.'
      },
      memberForm: {
        bank: {
          type: SettingType.Form,
          name: 'Account Customer Form',
          description: 'Default form for customer sidebar on account page.'
        },
        creditUnion: {
          type: SettingType.Form,
          name: 'Account Member Form',
          description: 'Default form for member sidebar on account page.'
        }
      },
      setupForm: {
        type: SettingType.Form,
        name: 'Account Setup Form',
        description: 'Form for account setup page.'
      },
      table: {
        type: SettingType.Table,
        name: 'Account Table',
        description: 'Default table for accounts.',
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, GeneralSettings['account']> = {
      cardTable: 'tables',
      form: 'forms',
      memberForm: 'forms',
      table: 'tables'
    }
  };
  

  /** List of default event values. */
  event = new class {
    /** Default table. */
    table = ID_DEFAULT;

    static settingsinfo: SettingsInfo<GeneralSettings['event']> = {
      table: {
        type: SettingType.Table, 
        name: 'Event Table',
        description: 'Default table for events.'
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, GeneralSettings['event']> = {
      table: 'tables'
    }

  };

  /** List of default member values. */
  member = new class {
    /** Default form for account table on member page. */
    accountTable = ID_DEFAULT;
    /** Default form. */
    form = ID_DEFAULT;
    /** Default table. */
    table = ID_DEFAULT;

    static settingsinfo: SettingsInfo<GeneralSettings['member']> = {
      accountTable: {
        bank: {
          type: SettingType.Table,
          name: 'Customer Account Table',
          description: 'Default form for account table on customer page.'
        },
        creditUnion: {
          type: SettingType.Table,
          name: 'Member Account Table',
          description: 'Default form for account table on member page.'
        }
      },
      form: {
        bank: {
          type: SettingType.Form,
          name: 'Customer Form',
          description: 'Default form for customers.'
        },
        creditUnion: {
          type: SettingType.Form,
          name: 'Member Form',
          description: 'Default form for members.'
        }
      },
      table: {
        bank: {
          type: SettingType.Table,
          name: 'Customer Table',
          description: 'Default table for customers.'
        },
        creditUnion: {
          type: SettingType.Table,
          name: 'Member Table',
          description: 'Default table for members.'
        }
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, GeneralSettings['member']> = {
      accountTable: 'tables',
      form: 'forms',
      table: 'tables'
    }

  };

  /** List of default phone values. */
  phone = new class {
    /** Default table. */
    table = ID_DEFAULT;

    static settingsinfo: SettingsInfo<GeneralSettings['phone']> = {
      table: {
        type: SettingType.Table,
        name: 'Phone Table',
        description: 'Default table for phones.'
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, GeneralSettings['phone']> = {
      table: 'tables'
    }

  };

  /** List of default transaction values. */
  transaction = new class {
    /** Default table. */
    table = ID_DEFAULT;

    static settingsinfo: SettingsInfo<GeneralSettings['transaction']> = {
      table: {
        type: SettingType.Table,
        name: 'Transaction Table',
        description: 'Default table for transactions.'
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, GeneralSettings['transaction']> = {
      table: 'tables'
    }

  };

  /** List of default ledger values. */
  ledger = new class {
    /** Default table. */
    table = ID_DEFAULT;

    static settingsinfo: SettingsInfo<GeneralSettings['ledger']> = {
      table: {
        type: SettingType.Table,
        name: 'Ledger Table',
        description: 'Default table for ledger items.'
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, GeneralSettings['ledger']> = {
      table: 'tables'
    }
  };

  /** List of default user values. */
  user = new class {
    /** Default table. */
    table = ID_DEFAULT;

    static settingsinfo: SettingsInfo<GeneralSettings['user']> = {
      table: {
        type: SettingType.Table,
        name: 'User Table',
        description: 'Default table for users.'
      }
    }

    static collectioninfo: CollectionInfo<FusionCollection, GeneralSettings['user']> = {
      table: 'tables'
    }

  };
}