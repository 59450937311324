
/** 
 *  Tagged types for custom model fields.
 *  Note: If new field types are added to the system, search "PropertyType"
 *  in the project and add your new property to ALL switch statements.
 */
export enum PropertyType {
  Boolean = 'boolean',
  Code = 'code',
  Currency = 'currency',
  Date = 'date',
  Email = 'email',
  Member = 'member',
  Number = 'number',
  Phone = 'phone',
  String = 'string',
  Transaction = 'transaction',
  User = 'user'
}