import { Pipe } from '@angular/core';

@Pipe({
  name: 'function'
})
export class FunctionPipe<V, R> {
  transform(value: V, lambda: (value: V) => R) {
    return lambda(value);
  }
}
