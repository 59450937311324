
/** Possible status levels. */
export enum StatusLevel {
  Notice,
  Warning,
  Alert
}

/** List of all status levels. */
export const STATUS_LEVELS = [
  StatusLevel.Notice,
  StatusLevel.Warning,
  StatusLevel.Alert
];