<app-card body class="row fill">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row align-end">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name" [pattern]="FILENAME_REGEX"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Type</app-label>
          <app-select [disabled]="value | setupReadonly" [(ngModel)]="value.type" (selected)="refield(!!proxy)">
            <app-option *ngFor="let t of types" [view]="t | enum:DOCUMENT_TEMPLATE_TYPE_NAME" [value]="t"></app-option>
          </app-select>
        </app-field>
        <app-field class="fill">
          <app-label>Filter Rule</app-label>
          <app-resource-select collection="filterRules" [required]="false" [(ngModel)]="value._rule"></app-resource-select>
        </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>Description</app-label>
          <app-string [(ngModel)]="value.description"></app-string>
          <button icon-button tooltip="Available fields" (click)="refield(!proxy)">storage</button>
        </app-field>
      </div>
    </app-setup-base-header>
    <app-setup-base-body class="column">
      <app-object class="fill" [file]="file"></app-object>
      <app-attachment-list collection="documentTemplates" [(ngModel)]="value" (uploaded)="uploads = $event"></app-attachment-list>
    </app-setup-base-body>
    <app-setup-base-footer>
      <app-upload (uploads)="onUpload($event)"></app-upload>
      <button (click)="onSubmit()" [disabled]="!file">Save</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>
<app-setup-property-list *ngIf="proxy" [keywords]="DOCUMENT_TEMPLATE_KEYWORDS" [object]="proxy"></app-setup-property-list>