import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressName, AddressPreviewName } from '../../../../../common/model/address';
import { ArraySome } from '../../../../../common/toolbox/array';
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { LogService } from './log.service';
 
/** A query to fetch a specific address. */
export class AddressQuery {
  constructor(
    /** ID of address. */
    public _id = ID_DEFAULT,
    /** Institution of address. */
    public _inst = ID_DEFAULT
  ) {}
}

/** Caches information about addresses. */
@Injectable({
  providedIn: 'root'
})
export class AddressService extends CachePreviewService<AddressName, AddressQuery, AddressPreviewName> {
  readonly route = 'addresses/preview';
  readonly Type = AddressName;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient) {
    super(AddressQuery, log, dialog, http);
  }

  protected override async preview(_inst: string) {
    let response = await super.preview(_inst);
    return errorResponse(response) ? response : response.map(AddressPreviewName.from);
  }

  protected override async multiple(queries: ArraySome<AddressQuery>) {
    let addresses = await getRequest(this.http, 'addresses', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
    return errorResponse(addresses) ? addresses : addresses.map(AddressName.from);
  }
}
