import { arrayDefined } from "../../toolbox/array";
import { BuiltinMap } from "../../toolbox/builtinmap";
import { Display } from "../display";
import { Column } from "../table";
import { SystemFormula } from "./formula";
import { AccountFormula } from "./formula/account";
import { ClaimFormula } from "./formula/claim";
import { MemberFormula } from "./formula/member";
import { SystemModel } from "./model";

/** All possible builtin tables. */
export type SystemTable = StandardTable | CollectionsTable | DisputesTable;

/** Built-in collections tables. */
export enum CollectionsTable {
  WorkList = 'Work List'
}

/** Built-in disputes tables. */
export enum DisputesTable {
  Account = 'Account (Claims)',
  AccountCard = 'Account (Card Claims)',
  Claim = 'Claim',
  ClaimReport = 'Claim (Report View)',
  Event = 'Event (Claims)',
  Transaction = 'Transaction (Claims)'
}

/** Built-in system tables. */
export enum StandardTable {
  Account = 'Account',
  Attachment = 'Attachment',
  Email = 'Email',
  Event = 'Event',
  FieldEmail = 'Email Field',
  FieldMember = 'Member Field',
  FieldPhone = 'Phone Field',
  Ledger = 'Ledger',
  Member = 'Member',
  Phone = 'Phone',
  Transaction = 'Transaction',
  User = 'User'
}

/** Configuration for a built-in table. */
interface BuiltinTable {
  /** Override for built-in table name. */
  name?: string
  /** Model for table. */
  model?: SystemModel
  /** List of columns for table. */
  columns: Column[],
  /** Formula used to decide highlight colors for this table. */
  highlightFormula?: SystemFormula
}

/** List of builtin tables. */
export const BUILTIN_TABLES: Record<SystemTable, BuiltinTable> = {
  [CollectionsTable.WorkList]: {
    columns: [
      Column.from(`formula.${AccountFormula.Number}`, { name: 'Account' }),
      Column.from(`formula.${MemberFormula.TableName}`, { name: 'Account Holder' }),
      Column.from('account.description', { size: `2fr` }),
      Column.from('account.paymentDueDate'),
      Column.from('account.lastPaymentDate'),
      Column.from('account.balance'),
      Column.from(`formula.${AccountFormula.TotalDelinquentAmount}`, { name: 'Delinquent Amount' }),
      Column.from(`formula.${AccountFormula.DaysLate}`, { name: 'Days Late' })
    ]
  },
  [DisputesTable.Account]: {
    name: 'Account',
    columns: [
      Column.from('account.number'),
      Column.from('account.type'),
      Column.from('account.balance')
    ]
  },
  [DisputesTable.AccountCard]: {
    name: 'Account (Cards)',
    columns: [
      Column.from('account.number', { name: 'Account Number' }),
      Column.from('account.category'),
      Column.from('account.type'),
      Column.from('account.balance'),
      Column.from('card.number', { name: 'Card Number' })
    ]
  },
  [DisputesTable.Claim]: {
    columns: [
      Column.from('claim.displayId', { name: 'Claim Number' }),
      Column.from(`formula.${MemberFormula.TableName}`, { name: 'Account Holder' }),
      Column.from('claim.reportDate', { name: 'Date Reported' }),
      Column.from('claim.type', { name: 'Claim Type' }),
      Column.from(`formula.${ClaimFormula.Reason}`, { name: 'Reason', _colorFormula: ClaimFormula.TableHighlightCell }),
      Column.from('claim.status'),
      Column.from('claim._assigned'),
      Column.from(`formula.${ClaimFormula.TotalDisputed}`),
    ],
    highlightFormula: ClaimFormula.TableHighlight
  },
  [DisputesTable.ClaimReport]: {
    columns: [
      Column.from('claim.displayId', { name: 'Claim Number' }),
      Column.from(`formula.${MemberFormula.TableName}`, { name: 'Account Holder' }),
      Column.from('claim.reportDate', { name: 'Date Reported' }),
      Column.from('claim.type', { name: 'Claim Type' }),
      Column.from(`formula.${ClaimFormula.Reason}`, { name: 'Reason' }),
      Column.from('claim.status'),
      Column.from('claim._assigned')
    ]
  },
  [DisputesTable.Event]: {
    name: 'Event',
    columns: [
      Column.from('event.title', { size: '2fr' }),
      Column.from('event.description', { size: '3fr', wrap: true }),
      Column.from('event._user', { name: 'Assigned User' }),
      Column.from('event.date'),
      Column.from('event.result.due')
    ]
  },
  [DisputesTable.Transaction]: {
    name: 'Transaction',
    columns: [
      Column.from('transaction.amount'),
      Column.from('transaction.effectiveDate'),
      Column.from('transaction.description', { size: '2fr', wrap: true })
    ]
  },
  [StandardTable.Account]: {
    columns: [
      Column.from('account.number'),
      Column.from('account.category'),
      Column.from('account.type'),
      Column.from('account.paymentDueDate'),
      Column.from('account.lastPaymentDate'),
      Column.from('account.balance')
    ]
  },
  [StandardTable.Attachment]: {
    columns: [
      Column.from('attachment.name', { name: 'Title' }),
      Column.from('user.name', { name: 'Uploaded by' }),
      Column.from('attachment.date'),
      Column.from('attachment.type', { name: 'Type' })
    ]
  },
  [StandardTable.Event]: {
    columns: [
      Column.from('event.title', { size: '2fr' }),
      Column.from('event.description', { size: '3fr', wrap: true }),
      Column.from('event._user'),
      Column.from('event.date'),
      Column.from(`formula.${MemberFormula.Name}`, { name: 'Account Holder' }),
      Column.from(`formula.${AccountFormula.Number}`, { name: 'Account' }),
      Column.from('event.result._assigned'),
      Column.from('event.result.due'),
      Column.from('event.result._completed'),
      Column.from('event.result.done', { name: 'Done' })
    ]
  },
  [StandardTable.Ledger]: {
    columns: [
      Column.from('ledger.amount'),
      Column.from('ledger.account'),
      Column.from('ledger.description', { size: '2fr', wrap: true }),
      Column.from('ledger.tranCode'),
      Column.from('ledger.type')
    ]
  },
  [StandardTable.Member]: {
    columns: [
      Column.from('member.taxId', { name: 'Tax ID' }),
      Column.from('member.number', { name: 'Account Holder No.' }),
      Column.from(`formula.${MemberFormula.Name}`, { name: 'Name' })
    ]
  },
  [StandardTable.Phone]: {
    columns: [
      Column.from('phone.type'),
      Column.from('phone.name'),
      Column.from('phone.number')
    ]
  },
  [StandardTable.FieldMember]: {
    columns: [
      Column.from('member.relationship'),
      Column.from(`formula.${MemberFormula.Name}`, { name: 'Name' })
    ]
  },
  [StandardTable.FieldPhone]: {
    columns: [
      Column.from('phone.type'),
      Column.from('phone.name'),
      Column.from('phone.number')
    ]
  },
  [StandardTable.Email]: {
    columns: [
      Column.from('email.address')
    ]
  },
  [StandardTable.FieldEmail]: {
    columns: [
      Column.from('email.address')
    ]
  },
  [StandardTable.Transaction]: {
    columns: [
      Column.from('transaction.amount'),
      Column.from('transaction.effectiveDate'),
      Column.from('transaction.description', { size: `2fr`, wrap: true }),
      Column.from('transaction.comment', { size: `2fr`, wrap: true })
    ]
  },
  [StandardTable.User]: {
    columns: [
      Column.from('user.name')
    ]
  }
};

/** Recursively determine formulas needed by table. */
export function builtinTableFormulas(map: BuiltinMap, name: SystemTable) {
  let table = map.tables.builtin(name);
  return arrayDefined([
    table.highlightFormula,
    ...Display.formulas(table.columns ?? []) as SystemFormula[],
    ...arrayDefined(table.columns.map(column => column._colorFormula as SystemFormula))
  ]);
}
