import { DisputeCreditStatus, DisputeStatus } from "../../../code/standard/disputes";
import { DisputesCode } from "../../../code/system";
import { CURRENCY_SCALE } from "../../../toolbox/currency";
import { ConditionType } from "../../formula/condition";
import { OperatorType } from "../../formula/operator";
import { StatementType } from "../../formula/statement";
import { TerminalType } from "../../formula/terminal";
import { BuiltinFormula } from "../formula";
import { ModelDisputeIssueCredit } from "../model/disputes";
import { builtinFormulaModelKey } from "./formula";

/** Formulas that operate on single disputes. */
export enum DisputeFormula {
  AmountThreshold                       = 'Dispute - Amount Threshold',
  AmountUnderThreshold                  = 'Dispute - Amount Under Threshold',
  ChargebackDate                        = 'Dispute - Chargeback Date',
  ChargebackDateSet                     = 'Dispute - Set Chargeback Date',
  ChargebackReviewedDate                = 'Dispute - Chargeback Reviewed Date',
  ChargebackReviewedDateSet             = 'Dispute - Set Chargeback Reviewed Date',
  CreditStatusApprovedSet               = 'Dispute - Credit Status - Set Approved',
  CreditStatusFinal                     = 'Dispute - Credit Status - Final Credit',
  CreditStatusFinalSet                  = 'Dispute - Credit Status - Set Final Credit',
  CreditStatusFinalReversed             = 'Dispute - Credit Status - Final Credit or Reversed',
  CreditStatusIssued                    = 'Dispute - Credit Status - Issued',
  CreditStatusReversed                  = 'Dispute - Credit Status - Reversed',
  CreditStatusReversedSet               = 'Dispute - Credit Status - Set Reversed Credit',
  CreditStatusProvisionalSet            = 'Dispute - Credit Status - Set Provisional Credit',
  DeniedDateSet                         = 'Dispute - Set Denied Date',
  InvestigationCompletedDateSet         = 'Dispute - Investigation Completed Date',
  InvestigationDueDate                  = 'Dispute - Investigation Due Date',
  PrearbitrationDate                    = 'Dispute - Pre-Arbitration Date',
  PrearbitrationDateSet                 = 'Dispute - Set Pre-Arbitration Date',
  ProvisionalCreditDateSet              = 'Dispute - Set Provisional Credit Date',
  ProvisionalCreditNoticeDate           = 'Dispute - Provisional Credit Notice Date',
  ProvisionalCreditNoticeDueDate        = 'Dispute - Provisional Credit Notice Due Date',
  RecoveryDateSet                       = 'Dispute - Set Recovery Date',
  RepresentmentDate                     = 'Dispute - Representment Date',
  RepresentmentReviewedDate             = 'Dispute - Representment Reviewed Date',
  RepresentmentReviewedDateSet          = 'Dispute - Set Representment Reviewed Date',
  ResolvedReason                        = 'Dispute - Resolution Reason',
  StatusApprovedSet                     = 'Dispute - Status - Set Approved',
  StatusArbitrationSet                  = 'Dispute - Status - Set Arbitration',
  StatusClosed                          = 'Dispute - Status - Closed',
  StatusDeniedSet                       = 'Dispute - Status - Set Denied',
  StatusOpen                            = 'Dispute - Status - Open',
  StatusPrearbitrationSet               = 'Dispute - Status - Set Pre-Arbitration',
  StatusRepresentmentSet                = 'Dispute - Status - Set Representment',
  StatusValueDenied                     = 'Dispute - Status Value - Denied',
  TransactionCreditTransactionAmountSet = 'Dispute - Set Transaction Credit to Transaction Amount'
}

export const BUILTIN_DISPUTE_FORMULAS: Record<DisputeFormula, BuiltinFormula> = {
  [DisputeFormula.AmountThreshold]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Greater,
        left: {
          type: TerminalType.Identifier,
          key: 'transaction.amount'
        },
        right: {
          type: TerminalType.Currency,
          value: 30 * CURRENCY_SCALE
        }
      }
    }]
  },
  [DisputeFormula.AmountUnderThreshold]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.LesserEqual,
        left: {
          type: TerminalType.Identifier,
          key: 'transaction.amount'
        },
        right: {
          type: TerminalType.Currency,
          value: 30 * CURRENCY_SCALE
        }
      }
    }]
  },
  [DisputeFormula.ChargebackDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Identifier,
        key: 'dispute.chargebackDate'
      }
    }]
  },
  [DisputeFormula.ChargebackDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.chargebackDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [DisputeFormula.ChargebackReviewedDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Identifier,
        key: 'dispute.chargebackReviewedDate'
      }
    }]
  },
  [DisputeFormula.ChargebackReviewedDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.chargebackReviewedDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [DisputeFormula.CreditStatusApprovedSet]: {
    statements: [{
      type: StatementType.If,
      if: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.creditStatus'
        },
        right: {
          type: TerminalType.Code,
          value: DisputeCreditStatus.Final,
          category: DisputesCode.DisputeCreditStatus
        }
      },
      then: [{
        type: StatementType.Assignment,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.status'
        },
        right: {
          type: TerminalType.Code,
          value: DisputeStatus.Approved,
          category: DisputesCode.DisputeStatus
        }
      }]
    }]
  },
  [DisputeFormula.CreditStatusFinal]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.creditStatus'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.DisputeCreditStatus,
          value: DisputeCreditStatus.Final
        }
      }
    }]
  },
  [DisputeFormula.CreditStatusFinalSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.creditStatus'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeCreditStatus,
        value: DisputeCreditStatus.Final
      }
    }]
  },
  [DisputeFormula.CreditStatusFinalReversed]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.In,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.creditStatus'
        },
        right: {
          type: TerminalType.CodeList,
          category: DisputesCode.DisputeCreditStatus,
          value: [DisputeCreditStatus.Final, DisputeCreditStatus.Reversed]
        }
      }
    }]
  },
  [DisputeFormula.CreditStatusIssued]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Unequal,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.creditStatus'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.DisputeCreditStatus,
          value: DisputeCreditStatus.None
        }
      }
    }]
  },
  [DisputeFormula.CreditStatusReversed]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.Equal,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.creditStatus'
        },
        right: {
          type: TerminalType.Code,
          category: DisputesCode.DisputeCreditStatus,
          value: DisputeCreditStatus.Reversed
        }
      }
    }]
  },
  [DisputeFormula.CreditStatusReversedSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.creditStatus'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeCreditStatus,
        value: DisputeCreditStatus.Reversed
      }
    }]
  },
  [DisputeFormula.CreditStatusProvisionalSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.creditStatus'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeCreditStatus,
        value: DisputeCreditStatus.Provisional
      }
    }]
  },
  [DisputeFormula.DeniedDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.deniedDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [DisputeFormula.RepresentmentReviewedDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
          type: TerminalType.Identifier,
          key: 'dispute.representmentReviewedDate'
      }
    }]
  },
  [DisputeFormula.InvestigationCompletedDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.investigationCompletedDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [DisputeFormula.InvestigationDueDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Add,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.reportDate'
        },
        right: {
          type: TerminalType.Number,
          value: 60
        }
      }
    }]
  },
  [DisputeFormula.ProvisionalCreditNoticeDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Identifier,
        key: 'dispute.provisionalCreditNoticeDate'
      }
    }]
  },
  [DisputeFormula.ProvisionalCreditNoticeDueDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Add,
        left: {
          type: TerminalType.Identifier,
          key: 'claim.reportDate'
        },
        right: {
          type: TerminalType.Number,
          value: 10
        }
      }
    }]
  },
  [DisputeFormula.PrearbitrationDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Identifier,
        key: 'dispute.secondChargebackDate'
      }
    }]
  },
  [DisputeFormula.PrearbitrationDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.secondChargebackDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [DisputeFormula.ProvisionalCreditDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.provisionalCreditDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [DisputeFormula.RecoveryDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.recoveryDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [DisputeFormula.RepresentmentDate]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Identifier,
        key: 'dispute.representmentDate'
      }
    }]
  },
  [DisputeFormula.RepresentmentReviewedDateSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.representmentReviewedDate'
      },
      right: {
        type: TerminalType.Date,
        value: 0
      }
    }]
  },
  [DisputeFormula.ResolvedReason]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Identifier,
        key: 'dispute.resolvedReason'
      }
    }]
  },
  [DisputeFormula.StatusApprovedSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeStatus,
        value: DisputeStatus.Approved
      }
    }]
  },
  [DisputeFormula.StatusArbitrationSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeStatus,
        value: DisputeStatus.Arbitration
      }
    }]
  },
  [DisputeFormula.StatusClosed]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: ConditionType.In,
        left: {
          type: TerminalType.Identifier,
          key: 'dispute.status'
        },
        right: {
          type: TerminalType.Array,
          expressions: [{
            type: TerminalType.Code,
            category: DisputesCode.DisputeStatus,
            value: DisputeStatus.Approved
          }, {
            type: TerminalType.Code,
            category: DisputesCode.DisputeStatus,
            value: DisputeStatus.Denied
          }, {
            type: TerminalType.Code,
            category: DisputesCode.DisputeStatus,
            value: DisputeStatus.Withdrawn
          }]
        }
      }
    }]
  },
  [DisputeFormula.StatusDeniedSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeStatus,
        value: DisputeStatus.Denied
      }
    }]
  },
  [DisputeFormula.StatusOpen]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: OperatorType.Not,
        left: {
          type: ConditionType.In,
          left: {
            type: TerminalType.Identifier,
            key: 'dispute.status'
          },
          right: {
            type: TerminalType.CodeList,
            category: DisputesCode.DisputeStatus,
            value: [DisputeStatus.Denied, DisputeStatus.Withdrawn]
          }
        }
      }
    }]
  },
  [DisputeFormula.StatusPrearbitrationSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeStatus,
        value: DisputeStatus.Prearbitration
      }
    }]
  },
  [DisputeFormula.StatusRepresentmentSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: 'dispute.status'
      },
      right: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeStatus,
        value: DisputeStatus.Representment
      }
    }]
  },
  [DisputeFormula.StatusValueDenied]: {
    statements: [{
      type: StatementType.Return,
      expression: {
        type: TerminalType.Code,
        category: DisputesCode.DisputeStatus,
        value: DisputeStatus.Denied
      }
    }]
  },
  [DisputeFormula.TransactionCreditTransactionAmountSet]: {
    statements: [{
      type: StatementType.Assignment,
      left: {
        type: TerminalType.Identifier,
        key: builtinFormulaModelKey<ModelDisputeIssueCredit>('transactionCredit')
      },
      right: {
        type: TerminalType.Identifier,
        key: 'transaction.amount'
      }
    }]
  }
}
