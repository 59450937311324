import { EnumMap } from "../toolbox/enum";
import { permissionHas, permissionMask } from "../toolbox/permission";

/** A masked value for one or more permissions. */
export type PermissionMask = string;
/** List of permissions as a record. */
export type PermissionRecord = { [Key in Permission]?: boolean };
/** Partial list of partial permissions. */
export type PermissionEditRecord = Record<Permission, PermissionEdit>;

/** Default value for permission mask. */
export const PERMISSION_MASK_DEFAULT = '0';

/**
 *  A permission that a user has access to.
 *  Most permissions should be in format NounsVerb, where
 *  "Nouns" is a plural noun of a system resource and
 *  "Verb" is the action that can be performed on that resource.
 */
export enum Permission {
  System,
  CodeTypesEdit,
  FilterRulesEdit,
  FormsEdit,
  FormulasEdit,
  ModelsEdit,
  ProfilesEdit,
  DocumentTemplatesEdit,
  TablesEdit,
  TasksEdit,
  UsersEdit,
  WorkQueuesEdit,
  ImportsRun,
  CollectionsAgentDashboardView,
  CollectionsSupervisorDashboardView,
  CollectionsExecutiveDashboardView,
  ClaimsCardIntake,
  LedgerConfigsEdit,
  ClaimsManage,
  ClaimsACHIntake,
  AccountsEdit,
  AttachmentsEdit,
  ClaimsEdit,
  Unused1,
  InstitutionsEdit,
  JobsEdit,
  MembersEdit,
  OrganizationsEdit,
  Unused2,
  WorkItemsEdit,
  WorkQueuesBuild,
  CommentsPrivateView,
  DisputesExecutiveDashboardView,
  ClaimsDelete,
  ClaimsViewTaskBypass,
  WorkflowsEdit,
  FormListsEdit,
  SettingGroupsEdit,
  Unused3,
  ClaimsCheckIntake,
  ClaimsCreditBureauIntake,
  CommentsDelete,
  ClaimFiltersEdit,
  ClaimFiltersDelete,
  TriggersEdit,
  TriggersDelete,
  TransactionsPost
}

/** A permission-boolean pair for editing. */
export class PermissionEdit {
  /** Name of permission. */
  name: string;

  constructor(
    /** Permission to check. */
    public permission = Permission.System,
    /** True if has permission. */
    public access = false
  ) {
    this.name = PERMISSION_NAME[permission];
  }

  /** Convert list of permissions to edit record. */
  static record(value: PermissionMask | PermissionRecord | Permission[] = ''): PermissionEditRecord {
    let record = {} as PermissionEditRecord;
    value = permissionMask(value);

    for (let p of PERMISSION_LIST) {
      record[p] = new PermissionEdit(p, permissionHas(value, p, false));
    }

    return record;
  }

  /** Convert edit record back to list of permissions. */
  static array(value: PermissionEdit[]): Permission[] {
    return value.filter(p => p.access).map(p => p.permission);
  }
}

/** Blacklist of permissions to not display on pages. */
export const PERMISSION_BLACKLIST = new Set<Permission>([]);

/** List of names for each permission. */
export const PERMISSION_NAME: EnumMap<Permission> = {
  [Permission.AccountsEdit]: 'Edit Accounts',
  [Permission.AttachmentsEdit]: 'Edit Attachments',
  [Permission.ClaimsACHIntake]: 'Intake ACH Claims',
  [Permission.ClaimsCardIntake]: 'Intake Card Claims',
  [Permission.ClaimsCheckIntake]: 'Intake Check Claims',
  [Permission.ClaimFiltersDelete]: 'Delete Claim Filters',
  [Permission.ClaimFiltersEdit]: 'Edit Claim Filters',
  [Permission.ClaimsCreditBureauIntake]: 'Intake Credit Bureau Claims',
  [Permission.ClaimsDelete]: 'Delete Claims',
  [Permission.ClaimsEdit]: 'Edit Claims',
  [Permission.ClaimsManage]: 'Manage Claims',
  [Permission.ClaimsViewTaskBypass]: 'Bypass View Claims Task',
  [Permission.CodeTypesEdit]: 'Edit Code Types',
  [Permission.CollectionsAgentDashboardView]: 'View Collections Agent Dashboard',
  [Permission.CollectionsExecutiveDashboardView]: 'View Collections Executive Dashboard',
  [Permission.CollectionsSupervisorDashboardView]: 'View Collections Supervisor Dashboard',
  [Permission.CommentsDelete]: 'Delete Comments',
  [Permission.CommentsPrivateView]: 'View Private Comments',
  [Permission.DisputesExecutiveDashboardView]: 'View Disputes Executive Dashboard',
  [Permission.DocumentTemplatesEdit]: 'Edit Document Templates',
  [Permission.FilterRulesEdit]: 'Edit Filter Rules',
  [Permission.FormListsEdit]: 'Edit Form Lists',
  [Permission.FormsEdit]: 'Edit Forms',
  [Permission.FormulasEdit]: 'Edit Formulas',
  [Permission.ImportsRun]: 'Run Import',
  [Permission.InstitutionsEdit]: 'Edit Institutions',
  [Permission.JobsEdit]: 'Edit Jobs',
  [Permission.LedgerConfigsEdit]: 'Edit GL Posting Configurations',
  [Permission.MembersEdit]: 'Edit Members',
  [Permission.ModelsEdit]: 'Edit Models',
  [Permission.OrganizationsEdit]: 'Edit Organizations',
  [Permission.ProfilesEdit]: 'Edit Profiles',
  [Permission.SettingGroupsEdit]: 'Edit Settings',
  [Permission.System]: 'System',
  [Permission.TablesEdit]: 'Edit Tables',
  [Permission.TasksEdit]: 'Edit Tasks',
  [Permission.TransactionsPost]: 'Post Transactions',
  [Permission.TriggersDelete]: 'Delete Triggers',
  [Permission.TriggersEdit]: 'Edit Triggers',
  [Permission.Unused1]: 'Unused 1',
  [Permission.Unused2]: 'Unused 2',
  [Permission.Unused3]: 'Unused 3',
  [Permission.UsersEdit]: 'Edit Users',
  [Permission.WorkflowsEdit]: 'Edit Workflows',
  [Permission.WorkItemsEdit]: 'Edit Work Items',
  [Permission.WorkQueuesBuild]: 'Build Work Queues',
  [Permission.WorkQueuesEdit]: 'Edit Work Queues'
};

/** List of all permissions. */
export const PERMISSION_LIST: Permission[] = Object.entries(PERMISSION_NAME).sort(([, na], [pb, nb]) => {
  if (+pb === Permission.System) return 1; // System permission always displayed first.
  return na.localeCompare(nb);
}).map(([p]) => +p).filter(p => !PERMISSION_BLACKLIST.has(p));
