import { Pipe } from '@angular/core';
import { UserService } from '../service/user.service';
import { ID_DEFAULT } from '../../../../../common/toolbox/id';

@Pipe({ name: 'user' })
export class UserPipe {

  constructor(
    private users: UserService
  ) {}

  /** Format a code type by ID. */
  transform(_id: string | undefined, _inst: string) {
    return this.users.name(_inst, _id ?? ID_DEFAULT);
  }
}
