import { indexinfoOf } from ".";
import { Schema, SchemaProperty } from "../model/schema";
import { validatorValue } from "../toolbox/validate";
import { infoOf } from "./base";

/** Type annotations to define database schema for an object. */
export type SchemaInfo<T> = {
  [K in keyof T]?: SchemaProperty<T[K]>
}

/** Pull schemainfo from object. */
export function schemainfoOf<T>(value: T): SchemaInfo<T> {
  return infoOf(value, 'schemainfo');
}

/** Get schema for specified type. */
export function schemainfoSchema<T>(value: T): Schema<T> {
  let validator = validatorValue(value);
  return {
    schema: validator.schema() as SchemaProperty<T>,
    indexes: indexinfoOf(value),
    validator,
    value
  }
}