import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Setting, SettingType } from '../../../../../../../common/info/settings';
import { NestedKey } from '../../../../../../../common/toolbox/keys';
import { searchQuery } from '../../../../../../../common/toolbox/search';
import { SettingsBooleanComponent } from '../boolean/settings-boolean.component';
import { SettingsCodesComponent } from '../codes/settings-codes.component';
import { SettingsNumberComponent } from '../number/settings-number.component';
import { SettingsResourceComponent } from '../resource/settings-resource.component';
import { SettingsAccessor } from '../settings-accessor';
import { SettingsStringComponent } from '../string/settings-string.component';

/**
 *  Component for each setting type.
 *  TODO implement actual components for Formula, Form and Task settings.
 */
const SETTINGS_COMPONENT = {
  [SettingType.Boolean]: SettingsBooleanComponent,
  [SettingType.CodeType]: SettingsResourceComponent,
  [SettingType.Codes]: SettingsCodesComponent,
  [SettingType.DocumentTemplate]: SettingsResourceComponent,
  [SettingType.Form]: SettingsResourceComponent,
  [SettingType.FormList]: SettingsResourceComponent,
  [SettingType.Formula]: SettingsResourceComponent,
  [SettingType.Model]: SettingsResourceComponent,
  [SettingType.Number]: SettingsNumberComponent,
  [SettingType.String]: SettingsStringComponent,
  [SettingType.Table]: SettingsResourceComponent,
  [SettingType.Task]: SettingsResourceComponent,
  [SettingType.Workflow]: SettingsResourceComponent
};

@Component({
  selector: 'app-settings-control',
  templateUrl: './settings-control.component.html',
  styleUrls: ['./settings-control.component.scss'],
  host: {
    '[style.display]': "visible ? null : 'none'"
  }
})
export class SettingsControlComponent {

  /** Container to inject components. */
  @ViewChild('container', { static : true, read: ViewContainerRef }) containerRef!: ViewContainerRef;

  /** Key this control is editing. */
  key!: NestedKey<Setting>;
  /** Information about displaying this setting. */
  setting!: Setting;
  /** Component injected into template. */
  component!: SettingsAccessor;
  /** True if description should be shown. */
  description!: boolean;
  /** Emits when value of field changes. */
  valueChange = new ReplaySubject<any>(1);
  /** True if control is currently visible. */
  visible = true;

  /** Search string for field. */
  private contents = '';
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Populate control with given setting. */
  populate(key: NestedKey<Setting>, setting: Setting, value: any) {
    let prototype = SETTINGS_COMPONENT[setting.type];
    this.component = this.containerRef.createComponent<any>(prototype).instance;
    this.component.setting = this.setting = setting;
    this.valueChange = this.component.valueChange;
    this.description = !(prototype as any).description();
    this.key = key;
    this.component.value = value;
    this.contents = searchQuery(key, setting.name, setting.description);
  }

  /** Patch value of field. */
  writeValue(value: any) {
    this.component.writeValue(value);
  }

  /** Apply filtering to control. */
  filter(query: string) {
    this.visible = this.contents.includes(query);
  }
}
