import { Expression } from "./expression";

/** Possible conditions for each rule. */
export type Condition<T = any> = ConditionUnary<T> | ConditionBinary<T> | ConditionTernary<T> | ConditionMany<T>;

/** Conditions with a single operator. */
export type ConditionUnary<T = any> =
  | ConditionEvery<T>
  | ConditionSome<T>;

/** Conditions with two operators. */
export type ConditionBinary<T = any> =
  | ConditionAnd<T>
  | ConditionOr<T>
  | ConditionEqual<T>
  | ConditionUnequal<T>
  | ConditionGreater<T>
  | ConditionLess<T>
  | ConditionGreaterEqual<T>
  | ConditionLessEqual<T>
  | ConditionLike<T>
  | ConditionIn<T>;

/** Conditions with three operators. */
export type ConditionTernary<T = any> =
  | ConditionBetween<T>;

/**  Conditions with many values. */
export type ConditionMany<T = any> =
  | ConditionMatch<T>

/** Conditions for query field. */
export enum ConditionType {
  Every = 'every',
  Some = 'some',
  And = '&&',
  Or = '||',
  Equal = '==',
  Unequal = '!=',
  Greater = '>',
  Lesser = '<',
  GreaterEqual = '>=',
  LesserEqual = '<=',
  Like = 'like',
  In = 'in',
  Between = 'between',
  Match = 'match'
}

/** Basic configuration shared by all rules. */
export interface ConditionTag<C extends ConditionType, T> {
  /** Operator for condition. */
  type: C
  /** First value in comparison. */
  left: Expression<T>
}

/** Check every value in array is truthy. */
export interface ConditionEvery<T = any> extends ConditionTag<ConditionType.Every, T> {}

/** Check that some values in array are truthy. */
export interface ConditionSome<T = any> extends ConditionTag<ConditionType.Some, T> {}

/** Check both values are truthy. */
export interface ConditionAnd<T = any> extends ConditionTag<ConditionType.And, T> {
  right: Expression<T>
}

/** Check either value is truthy. */
export interface ConditionOr<T = any> extends ConditionTag<ConditionType.Or, T> {
  right: Expression<T>
}

/** Check value equals a value. */
export interface ConditionEqual<T = any> extends ConditionTag<ConditionType.Equal, T> {
  right: Expression<T>
}

/** Check values are not equal. */
export interface ConditionUnequal<T = any> extends ConditionTag<ConditionType.Unequal, T> {
  right: Expression<T>
}

/** Check value is greater than a value. */
export interface ConditionGreater<T = any> extends ConditionTag<ConditionType.Greater, T> {
  right: Expression<T>
}

/** Check value is less than a value. */
export interface ConditionLess<T = any> extends ConditionTag<ConditionType.Lesser, T> {
  right: Expression<T>
}

/** Check value is greater than or equal to a value. */
export interface ConditionGreaterEqual<T = any> extends ConditionTag<ConditionType.GreaterEqual, T> {
  right: Expression<T>
}

/** Check value is less than or equal to a value. */
export interface ConditionLessEqual<T = any> extends ConditionTag<ConditionType.LesserEqual, T> {
  right: Expression<T>
}

/** Check value is like another value. */
export interface ConditionLike<T = any> extends ConditionTag<ConditionType.Like, T> {
  right: Expression<T>
}

/** Check value is in a list of values. */
export interface ConditionIn<T = any> extends ConditionTag<ConditionType.In, T> {
  right: Expression<T>
}

/** Check value is between two values. */
export interface ConditionBetween<T = any> extends ConditionTag<ConditionType.Between, T> {
  middle: Expression<T>
  right: Expression<T>
}

/** Check a number of conditions, mapping input values to outputs values.
 *  NOTE: This should support expressions and statements, but just handles expressions for now.
 */
export interface ConditionMatch<T = any> extends ConditionTag<ConditionType.Match, T> {
  arms: MatchArm<T>[]
  default?: Expression<T>
}

/** A single arm of a match expression. */
export interface MatchArm<T = any> {
  pattern: Expression<T>
  expression: Expression<T>
}