import { ErrorResponse } from "../../../../../common/message/error";
import { ColorCode } from "../../../../../common/toolbox/color";
import { errorResponse } from "../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../common/toolbox/status";

/** A message to display a log. */
export type LogMessage = string | Log | ErrorResponse;

/** Information about a single log. */
export class Log {
  constructor(
    /** Message to display. */
    public message = '',
    /** Date that log was created. */
    public date = new Date(),
    /** List of additional messages to show. */
    public list?: string[],
    /** Large object to display. */
    public details?: any,
    /** Status to display. */
    public status?: StatusLevel,
    /** Override for icon color. */
    public color?: ColorCode
  ) {}

  /** Check if given value is a log. */
  static check(value: any): value is Log {
    return value && typeof value === 'object' && typeof value.message === 'string' && value.date instanceof Date;
  }

  /** Extract log from provided object. */
  static from(message: LogMessage, status?: StatusLevel): Log {
    if (errorResponse(message)) {
      return { message: message.error, date: new Date(), list: message.list?.map(m => m.error), details: message.details, status: status ?? StatusLevel.Alert };
    } else if (Log.check(message)) {
      return message;
    } else {
      return { message, date: new Date(), status };
    }
  }
}