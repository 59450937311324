import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { AccountJoin, AccountJoinShare } from '../../../../../../common/model/account/account';
import { accountName, accountRetype, accountUnjoin } from '../../../../../../common/toolbox/account';
import { MaybeId } from '../../../../../../common/toolbox/id';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { deepCopy } from '../../../../../../common/toolbox/object';
import { FormChange, FormConfig } from '../../../common/component/form/form.model';
import { TAB_DATA, TabRef } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { FormService } from '../../../common/service/form.service';
import { LogService } from '../../../common/service/log.service';
import { postRequest } from '../../../common/toolbox/request';
import { AccountSetupData } from './account-setup.model';

@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss'],
  host: {
    class: 'fill column'
  }
})
export class AccountSetupComponent {

  /** Configuration for form. */
  config?: FormConfig;
  /** Account being configured. */
  account: MaybeId<AccountJoin>;
  /** True if changes can be saved on current account. */
  dirty = false;

  static title(data: AccountSetupData) {
    return `Setup - ${data.account ? accountName(data.account) : 'New Account'}`;
  }

  constructor(
    @Inject(TAB_DATA)
    public data: AccountSetupData,
    private auth: AuthService,
    private forms: FormService,
    private ref: TabRef,
    private log: LogService,
    private http: HttpClient
  ) {
    if (data.account) {
      this.account = deepCopy(data.account);
      return;
    }

    // Creating a new account.
    this.account = new AccountJoinShare();
    this.account._inst = this.auth._inst;
  }
  
  async ngOnInit() {
    this.config = {
      mutate: true,
      form: await this.forms.setting('general.account.setupForm'),
      value: {
        account: this.account as AccountJoin
      }
    }
  }

  ngOnDestroy() {
    this.data.save?.complete();
  }

  /** Callback when saving changes. */
  async onSave() {
    let response = await postRequest(this.http, 'accounts', { items: [accountUnjoin(this.account)] });
    if (errorResponse(response)) {
      this.log.show(response);
      return;
    }

    this.data.save?.next();
    this.dirty = false;
  }

  /** Callback when changes occur. */
  onChange({ key, value }: FormChange) {
    this.dirty = true;
    switch (key) {
    case 'account.number':
      this.retitle();
      return;
    case 'account.category':
      accountRetype(this.account, value);
      return;
    }
  }

  /** Update display name of tab. */
  private retitle() {
    this.ref.title(AccountSetupComponent.title({ account: this.account }));
  }
}
