import { Component, Input } from '@angular/core';
import { Job, JobNotification } from '../../../../../../../../common/model/job/job';
import { JobType } from '../../../../../../../../common/model/job/payload';
import { ProfilePreview } from '../../../../../../../../common/model/profile';
import { UserPreview } from '../../../../../../../../common/model/user/user';
import { ID_DEFAULT, MaybeId } from '../../../../../../../../common/toolbox/id';
import { notificationUnsubscribeLink } from '../../../../../../../../common/toolbox/notification';
import { objectDelete } from '../../../../../../../../common/toolbox/object';
import { AuthService } from '../../../../../common/service/auth.service';
import { DevService } from '../../../../../common/service/dev.service';
import { LogService } from '../../../../../common/service/log.service';
import { ProfileService } from '../../../../../common/service/profile.service';
import { UserService } from '../../../../../common/service/user.service';

@Component({
  selector: 'app-setup-job-payload-notification',
  templateUrl: './setup-job-payload-notification.component.html',
  styleUrls: ['./setup-job-payload-notification.component.scss'],
  host: {
    class: 'row'
  }
})
export class SetupJobPayloadNotificationComponent {

  /** Job to be configured. */
  @Input() job: MaybeId<JobNotification> = Job.job(JobType.NotificationEvent);

  /** List of unsubscribed users. */
  protected notUsers = new Set<string>();
  /** List of users to select. */
  protected users: UserPreview[] = [];
  /** List of profiles to select. */
  protected profiles: ProfilePreview[] = [];

  constructor(
    protected dev: DevService,
    private log: LogService,
    private auth: AuthService,
    private userService: UserService,
    private profileService: ProfileService
  ) {}

  async ngOnInit() {
    if (this.job.payload._notUsers) this.notUsers = new Set(this.job.payload._notUsers);
    this.userService.previews(this.auth._inst).then(users => this.users = users);
    this.profileService.previews(this.auth._inst).then(profiles => this.profiles = profiles);
  }

  /** Callback when selecting a certain user. */
  async onUser(_user: string) {
    let index = this.job.payload._notUsers?.findIndex(_id => _user) ?? -1;
    if (index === -1) return;

    // Force remove from unsubscribe list.
    this.job.payload._notUsers!.splice(index, 1);
    if (this.job.payload._notUsers?.length === 0) objectDelete(this.job.payload, '_notUsers');
    this.notUsers = new Set(this.job.payload._notUsers);
  }

  /** Callback when copying unsubscribe link for user for testing. */
  async onUnsubscribeLink(user: UserPreview) {
    await navigator.clipboard.writeText(notificationUnsubscribeLink(API_URL, this.job._id ?? ID_DEFAULT));
    this.log.show(`Copied unsubscribe link for ${user.name} to clipboard.`);
  }
}
