import { FocusMonitor } from '@angular/cdk/a11y';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { ConnectedPosition, Overlay, OverlayRef, ScrollDispatcher } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, NgZone, Renderer2, ViewContainerRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ColorCode } from '../../../../../common/toolbox/color';
import { TooltipComponent } from '../component/tooltip/tooltip.component';
import { TooltipPosition } from '../component/tooltip/tooltip.model';
import { overlayMouseExit } from '../toolbox/overlay';

/** Distance from parent element for tooltip, in pixels. */
const TOOLTIP_MARGIN = 4;

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  /** Sets text of tooltip. */
  @Input() set tooltip(message: string | null) {
    if (message === null) message = '';
    this.setMessage(message);
  }
  
  /** Sets position of tooltip. */
  @Input('tooltip-position') position: TooltipPosition = 'unset';
  /** Sets color of tooltip. */
  @Input('tooltip-color') color?: ColorCode;

  /** Last applied message of tooltip. */
  private _message = '';
  /** Emits whenever the directive is destroyed. */
  private _destroy = new Subject<void>();
  /** Component portal for tooltip. */
  private _portal?: ComponentPortal<TooltipComponent>;
  /** Reference to created overlay. */
  private _overlayRef?: OverlayRef;
  /** Created instance of tooltip component. */
  private _instance?: TooltipComponent;
  /** Listener for global mouse movement. */
  private _listener?: Function;

  constructor(
    private zone: NgZone,
    private overlay: Overlay,
    private elementRef: ElementRef,
    private scroll: ScrollDispatcher,
    private containerRef: ViewContainerRef,
    private renderer: Renderer2,
    private focus: FocusMonitor
  ) {}

  ngAfterViewInit() {
    this.focus.monitor(this.elementRef).pipe(takeUntil(this._destroy)).subscribe(origin => {
      if (!origin) this.zone.run(() => this.hide());
      else if (origin === 'keyboard') this.zone.run(() => this.show());
    });
  }

  ngOnDestroy() {
    this.hide();
    this._destroy.next();
    this._destroy.complete();
  }

  /** Callback when mouse enters area. */
  @HostListener('mouseenter')
  onMouseEnter() {
    this.show();
  }

  /** Shows tooltip if not already displayed. */
  private show() {
    if (!this._message) return;
    let overlayRef = this.getOverlay();
    this.hide();

    // Create component portal and attach component.
    this._portal = this._portal || new ComponentPortal(TooltipComponent, this.containerRef);
    this._instance = overlayRef.attach(this._portal).instance;
    this._instance.color = this.color;
    this.setMessage();
    this._listener = overlayMouseExit(this.elementRef, this.renderer, () => this.hide());
  }

  /** Detaches currently-attached tooltip. */
  private hide() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }

    if (this._listener) {
      this._listener();
    }
  }

  /** Sets message of tooltip. */
  private setMessage(message = this._message) {
    this._message = message;
    if (!this._instance) return;

    this._instance.message = this._message;
    this._instance.changes.markForCheck();
  }

  /** Configures overlay and position strategy. */
  private getOverlay() {
    if (this._overlayRef) return this._overlayRef;

    let ancestors = this.scroll.getAncestorScrollContainers(this.elementRef);
    let positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withViewportMargin(4)
      .withScrollableContainers(ancestors)
      .withPositions(this.getPosition());
    this._overlayRef = this.overlay.create({ positionStrategy });

    // Hide tooltip when overlay detaches, mouse moves or keys pressed.
    this._overlayRef.detachments().pipe(takeUntil(this._destroy)).subscribe(() => this.hide());
    this._overlayRef.outsidePointerEvents().pipe(takeUntil(this._destroy)).subscribe(() => this.hide());
    this._overlayRef.keydownEvents().pipe(takeUntil(this._destroy)).subscribe(event => {
        if (event.key !== 'Escape' && !hasModifierKey(event)) {
          event.preventDefault();
          event.stopPropagation();
          this.zone.run(() => this.hide());
        }
    });

    return this._overlayRef;
  }

  /** Get position strategy of overlay. */
  private getPosition(): [ConnectedPosition, ConnectedPosition] {
    switch (this.position) {
    case 'top-left':
      return [
        { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom', offsetY: TOOLTIP_MARGIN },
        { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: -TOOLTIP_MARGIN }
      ];
    case 'top':
      return [
        { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -TOOLTIP_MARGIN },
        { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: TOOLTIP_MARGIN }
      ];
    case 'top-right':
      return [
        { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -TOOLTIP_MARGIN },
        { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetY: TOOLTIP_MARGIN }
      ];
    case 'left':
      return [
        { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: -TOOLTIP_MARGIN },
        { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: TOOLTIP_MARGIN }
      ];
    case 'right':
      return [
        { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: TOOLTIP_MARGIN },
        { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: -TOOLTIP_MARGIN }
      ];
    case 'bottom-left':
      return [
        { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetY: TOOLTIP_MARGIN },
        { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -TOOLTIP_MARGIN }
      ];
    case 'unset':
    case 'bottom':
      return [
        { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: TOOLTIP_MARGIN },
        { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -TOOLTIP_MARGIN }
      ];
    case 'bottom-right':
      return [
        { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: TOOLTIP_MARGIN },
        { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom', offsetY: -TOOLTIP_MARGIN }
      ];
    }
  }
}
