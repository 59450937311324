import { StatHistory, StatType } from "../../../../common/toolbox/stat";

export class CollectionsSupervisorHistoryData {
  constructor(
    /** List of stats for user. */
    public history: StatHistory,
    /** List of columns to display. */
    public types: StatType[],
    /** Date of stats being displayed. */
    public date: Date,
    /** Hour range to display for chart. */
    public hours: [number, number]
  ) {}
}