import { TaskPreview } from "../../../../../common/model/task";
import { WORK_CONDITION_CODE, WorkConditionType } from "../../../../../common/model/work/condition";
import { Workflow } from "../../../../../common/model/work/flow";
import { arrayEmpty } from "../../../../../common/toolbox/array";
import { MaybeId } from "../../../../../common/toolbox/id";
import { pascalCase } from "../../../../../common/toolbox/string";

/** List of keys that aren't emitted in builtin output. */
const KEY_BLACKLIST = new Set(['index', 'autoincrement'])

/** Convert workflow to source code. */
export function workflowSource(workflow: MaybeId<Workflow>, tasks: Map<string, TaskPreview>) {
  let out: string[] = [];
  walk({
    name: workflow.name,
    steps: workflow.steps
  }, out, 0, tasks);

  // Figure out which enum this workflow belongs to based on a heuristic.
  let disputes = ['dispute', 'claim'].some(name => workflow.name.toLowerCase().includes(name));
  let enumeration = disputes ? 'DisputesWorkflow' : 'CollectionsWorkflow';
  return `[${enumeration}.${pascalCase(workflow.name)}]: ${out.join('')},`;
}

/** Recursively stringify workflow. */
function walk(token: any, out: string[], indent: number, tasks: Map<string, TaskPreview>) {
  switch (typeof token) {
    case 'string':
      out.push(`'${token}'`);
      break;
    case 'object':
      if (Array.isArray(token)) {
        
        if (token.every(n => typeof n === 'number')) {
          out.push(`[${token.join(', ')}]`);
          break;
        }
        
        out.push('[');
        for (let i = 0; i < token.length; ++i) {
          out.push('\n', ' '.repeat(indent + 2));
          walk(token[i], out, indent + 2, tasks);
          if (i + 1 < token.length) out.push(',');
        }
        
        if (token.length) out.push('\n');
        out.push(' '.repeat(indent), ']');
      } else if (token) {
        out.push('{');
        let keys = Object.keys(token).filter(key => token[key] !== undefined && !arrayEmpty(token[key]) && !KEY_BLACKLIST.has(key));
        for (let i = 0; i < keys.length; ++i) {
          let key = keys[i]!;
          out.push('\n', ' '.repeat(indent + 2));

          switch (key) {
          case 'type':
            out.push(`type: WorkConditionType.${WORK_CONDITION_CODE[token[key] as WorkConditionType]}`);
            break;
          case '_tasks':
            out.push(`tasks: [${token[key].map((_task: string) => `SystemTask.${pascalCase(tasks.get(_task)!.name)}`).join(', ')}]`)
            break;
          default:
            if (token[key] === undefined) continue;
            out.push(`${key}: `);
            walk(token[key], out, indent + 2, tasks);
          }

          if (i + 1 < keys.length) out.push(',');
        }

        if (keys.length) out.push('\n');
        out.push(' '.repeat(indent), '}');
      } else {
        out.push('null');
      } break;
    default:
      out.push(token);
      break;
  }

  return out;
}