import { Component } from '@angular/core';
import { ClaimType } from '../../../../../../common/code/standard/disputes';
import { DisputesCode } from '../../../../../../common/code/system';
import { Permission } from '../../../../../../common/model/permission';
import { AuthService } from '../../../common/service/auth.service';
import { ClaimService } from '../../../common/service/claim.service';
import { LogService } from '../../../common/service/log.service';
import { TabService } from '../../../common/service/tab.service';
import { ClaimIntakeComponent } from '../../claim/intake/claim-intake.component';
import { ClaimDisputesType } from '../../../../../../common/model/claim/claim';
import { ClaimIntakeData } from '../../claim/intake/claim-intake.model';

/** Information about claim cards. */
class ClaimPanel {
  constructor(
    /** Type of claim. */
    public type: ClaimDisputesType,
    /** Icon to display for claim. */
    public icon: string,
    /** Permission needed to open. */
    public permission: Permission
  ) {}
}

@Component({
  selector: 'app-disputes-intake',
  templateUrl: './disputes-intake.component.html',
  styleUrls: ['./disputes-intake.component.scss'],
  host: {
    class: "center"
  }
})
export class DisputesIntakeComponent {
  readonly ClaimType = ClaimType;
  readonly DisputesCode = DisputesCode;

  /** Search query for finding claims. */
  query = '';

  /** List of claim card types. */
  panels = [
    new ClaimPanel(ClaimType.ACH, 'domain_add', Permission.ClaimsACHIntake),
    new ClaimPanel(ClaimType.Card, 'add_card', Permission.ClaimsCardIntake),
    new ClaimPanel(ClaimType.Check, 'local_atm', Permission.ClaimsCheckIntake)
  ];

  static title() {
    return 'Dispute Intake Dashboard';
  }

  constructor(
    public tabs: TabService,
    public claims: ClaimService,
    public auth: AuthService,
    public log: LogService,
  ) {}

  /** Initiate a new claim. */
  onClaim(type: ClaimDisputesType) {
    this.tabs.open(ClaimIntakeComponent, new ClaimIntakeData(type));
  }

  /** Perform search of existing claims. */
  onSearch() {
    this.claims.open({ _insts: [this.auth._inst], displayIds: [this.query] });
  }
}
