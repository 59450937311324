import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { AttachmentType } from '../../../../../../../common/code/standard/common';
import { CommonCode } from '../../../../../../../common/code/system';
import { ErrorResponse } from '../../../../../../../common/message/error';
import { Attachment, AttachmentLike, AttachmentList, AttachmentUpload } from "../../../../../../../common/model/attachment";
import { CollectionAttachments } from '../../../../../../../common/model/collection';
import { FusionCollection } from '../../../../../../../common/model/fusion';
import { Permission } from '../../../../../../../common/model/permission';
import { MaybeIdInst, idNull } from '../../../../../../../common/toolbox/id';
import { errorResponse } from "../../../../../../../common/toolbox/message";
import { AuthService } from '../../../service/auth.service';
import { LogService } from '../../../service/log.service';
import { fieldControlProviders } from '../../../toolbox/angular';
import { MIME_CATEGORY_ICON } from '../../../toolbox/mime';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../../toolbox/request';
import { DialogService } from '../../dialog/dialog.service';
import { EditListComponent } from '../../edit-list/edit-list.component';
import { FieldControl } from '../../field/field-control';

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss'],
  providers: fieldControlProviders(AttachmentListComponent)
})
export class AttachmentListComponent<T extends AttachmentList = AttachmentList> extends FieldControl implements ControlValueAccessor {
  protected readonly MIME_CATEGORY_ICON = MIME_CATEGORY_ICON;
  protected readonly AttachmentType = AttachmentType;
  protected readonly Permission = Permission;
  protected readonly CommonCode = CommonCode;

  /** Reference to edit list within template. */
  @ViewChild(EditListComponent) list!: EditListComponent<AttachmentLike>;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True if this is a readonly display of attachments. */
  @Input() set readonly(readonly: BooleanInput) { this._readonly = coerceBooleanProperty(readonly); }

  /** Label for adding new attachment. */
  @Input() label = 'New Attachment';
  /** True to automatically submit new attachments. */
  @Input() autosubmit = true;
  /** Restricted set of attachment types to allow. */
  @Input() types?: string[];
  /** Collection to autosubmit comment changes. */
  @Input() collection?: CollectionAttachments<FusionCollection>;

  /** List of attachments that are already uploaded. */
  @Output() attached = new EventEmitter<Attachment[]>();
  /** Emits a new attachment should be uploaded. */
  @Output() uploaded = new EventEmitter<AttachmentUpload[]>();
  /** Emits new list of IDs after deletions. */
  @Output() deleted = new EventEmitter<string[]>();

  /** List of uploaded attachments. */
  get attachments() { return this.items.filter(Attachment.check); }
  /** List of pending attachments. */
  get uploads() { return this.items.filter(AttachmentUpload.check); }

  /** Current value being edited. */
  protected value?: MaybeIdInst<T>;
  /** Attachment being created. */
  protected creating?: AttachmentUpload;
  /** Attachment currently being edited. */
  protected editing?: AttachmentLike;
  /** Mixed list of attachments and uploads to display. */
  protected items: AttachmentLike[] = [];
  /** Emits on component being destroyed. */
  protected destroy = new Subject<void>();

  constructor(
    protected auth: AuthService,
    protected log: LogService,
    protected http: HttpClient,
    protected dialog: DialogService
  ) {
    super();
  }

  writeValue(value: T | undefined) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.changed(this.value = value);
    this.refresh(value?._attachments);
  }

  protected ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Manually add a new attachment to list. */
  add(upload: AttachmentUpload) {
    this.list.add(upload);
  }

  /** Manually delete an attachment by index. */
  async delete(i: number) {
    let attachment = this.items[i];
    if (!attachment) {
      this.log.show(new ErrorResponse(`Invalid attachment index to delete: ${i}`));
      return;
    }

    // Remove attachment from server.
    if (this.value && !idNull(this.value._id) && this.collection && Attachment.check(attachment)) {
      let result = await deleteRequest(this.http, 'attachments', {
        _inst: this.value._inst ?? this.auth._inst,
        _id: this.value._id,
        collection: this.collection,
        _ids: [attachment._id]
      });

      if (errorResponse(result)) {
        this.log.show(result);
        return;
      }

      this.log.show(result.success);
    }

    // Remove item from local list.
    this.list.delete(i);
  }

  /** Confirm any pending uploads. */
  confirm() {
    if (!this.creating) return;

    let creating = this.creating;
    this.creating = undefined;
    this.items.push(creating);
    this.uploaded.emit([creating]);
    
    if (!this.autosubmit) return;
    this.submit();
  }

  /** Submit list of pending uploads. */
  async submit(): Promise<void | ErrorResponse> {
    // Confirm any pending uploads.
    this.confirm();

    // Bail if no server-side resource to upload to.
    if (!this.value || !this.collection || idNull(this.value._id) || idNull(this.value._inst)) return;
    
    // Add list of attachments to server resource.
    let attachments = await postRequest(this.http, 'attachments', {
      _inst: this.value._inst,
      _id: this.value._id,
      collection: this.collection,
      uploads: this.uploads
    });

    if (errorResponse(attachments)) {
      this.log.show(attachments);
      return attachments;
    }

    this.log.show('Successfully uploaded attachments.');
    this.items = [...this.attachments, ...attachments];
  }

  /** Callback when list of items change. */
  protected onItems(items: AttachmentLike[]) {
    this.items = items;
    this.uploaded.emit(this.uploads);
  }

  /** Called when upload control emits new files. */
  protected onUploads(uploads: AttachmentUpload[]) {
    this.creating = uploads[0];
  }

  /** Callback when done editing an existing attachment. */
  protected async onEdit(attachment: AttachmentLike): Promise<any> {
    if (!Attachment.check(attachment)) return this.editing = undefined;

    let result = await patchRequest(this.http, 'attachments', { attachments: [attachment] });
    if (errorResponse(result)) {
      this.log.show(result.error);
      return;
    }

    this.log.show(result.success);
    this.editing = undefined;
  }

  /** Callback when finished reordering items. */
  protected async onReorder() {
    if (!this.value?._id || !this.collection || idNull(this.value._inst)) return;
    let _attachments = this.attachments.map(a => a._id);
    if (!_attachments.length) return; // No server-side attachments yet.

    let response = await patchRequest(this.http, 'attachments/order', {
      _id: this.value._id,
      _inst: this.value._inst,
      collection: this.collection,
      _attachments
    });

    this.log.show(errorResponse(response) ? response : response.success);
  }

  /** Fetch list of attachments from IDs. */
  protected async refresh(_attachments?: string[]) {
    // Stop editing any existing items.
    this.editing = this.creating = undefined;
    if (!_attachments?.length) {
      this.attached.emit(this.items = []);
      return;
    }

    let attachments = await getRequest(this.http, 'attachments', { _insts: [this.auth._inst], _ids: _attachments! });
    if (errorResponse(attachments)) {
      this.log.show(attachments);
      return;
    }

    // Emit new list of attachments.
    this.items = [...attachments, ...this.uploads];
    this.attached.emit(this.attachments);
  }
}
