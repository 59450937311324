import { ArraySome } from "../../toolbox/array";
import { EnumMap } from "../../toolbox/enum";

/** Type tag for claim details. */
export type ClaimDetailsType = ClaimACHReportReason | ClaimCardDetailsType | ClaimCheckDetailsType;
/** Reason for reporting a claim. */
export type ClaimReportReason = ClaimCheckReportReason;

/** Reason for opening an ACH claim. */
export enum ClaimACHReportReason {
  InsufficientFunds               = '01',
  AccountClosed                   = '02',
  NoAccount                       = '03',
  InvalidAccountNumber            = '04',
  UnauthorizedDebit               = '05',
  ReturnedODFIRequest             = '06',
  AuthorizationRevoked            = '07',
  PaymentStopped                  = '08',
  UncollectedFunds                = '09',
  OriginatorUnauthorized          = '10',
  NotInAccordance                 = '11',
  AccountSold                     = '12',
  InvalidRoutingNumber            = '13',
  PayeeDeceased                   = '14',
  BeneficiaryDeceased             = '15',
  AccountFrozen                   = '16',
  ImproperReversal                = '17',
  ImproperEffectiveDate           = '18',
  AmountFieldError                = '19',
  NonTransactionAccount           = '20',
  InvalidCompanyIdentification    = '21',
  InvalidIdNumber                 = '22',
  CreditRefused                   = '23',
  DuplicateEntry                  = '24',
  AddendaError                    = '25',
  MandatoryFieldError             = '26',
  TraceNumberError                = '27',
  RoutingNumberError              = '28',
  CorporateUnauthorized           = '29',
  CheckTruncationProgram          = '30',
  PermissibleReturnOnly           = '31',
  NonSettlement                   = '32',
  ReturnXCKEntry                  = '33',
  LimitedParticipation            = '34',
  ReturnImproperDebit             = '35',
  ReturnImproperCredit            = '36',
  SourceDocumentPresented         = '37',
  StopPaymentSourceDocument       = '38',
  ImproperSourceDocument          = '39',
  ReturnENREntry                  = '40',
  InvalidTransactionCode          = '41',
  CheckDigitError                 = '42',
  InvalidDFIAccountNumber         = '43',
  InvalidIdentificationNumber     = '44',
  InvalidName                     = '45',
  InvalidPayeeIndicator           = '46',
  DuplicateEnrollment             = '47',
  RCKStateLawAcceptance           = '50',
  RCKEntryIneligible              = '51',
  RCKEntryStopPayment             = '52',
  ItemRCKPresented                = '53',
  MisroutedReturn                 = '61',
  ReturnErroneousDebit            = '62',
  DuplicateReturn                 = '67',
  UntimelyReturn                  = '68',
  FieldError                      = '69',
  ReturnNotAccepted               = '70',
  MisroutedDishonoredReturn       = '71',
  UntimelyishonoredReturn         = '72',
  TimelyOriginalReturn            = '73',
  CorrectedReturn                 = '74',
  ReturnNotDuplicate              = '75',
  NoErrorsFound                   = '76',
  NonAcceptedDishonoredReturn     = '77',
  IATCodingError                  = '80',
  IATNonParticipant               = '81',
  InvalidForeignDFI               = '82',
  UnsettledForeignDFI             = '83',
  GatewayUnprocessed              = '84',
  IncorrectOutboundPayment        = '85'
}

/** Reason for cancellation of a card dispute. */
export enum ClaimCardCancellationOrReturnType {
  CancelProduct               = 'cancelledProduct',
  NotReceived                 = 'notReceived',
  NotExpected                 = 'notExpected',
  ReturnedRefused             = 'returnedRefused',
  PaidOtherMeans              = 'paidOtherMeans'
}

/** Reason that a card was closed. */
export enum ClaimCardCloseReason {
  AccountClosed               = 'accountClosed',
  Counterfeit                 = 'counterfeit',
  CreditProblem               = 'creditProblem',
  Lost                        = 'lost',
  Stolen                      = 'stolen'
}

/** Reason that a card claim was declined. */
export enum ClaimCardDeclineReason {
  Unspecified                 = 'unspecified'
}

/** Type of card claim being initiated. */
export enum ClaimCardDetailsType {
  AtmTransaction              = 'atmTransaction',
  Cancellation                = 'cancellation',
  FraudAbsent                 = 'fraudAbsent',
  FraudPresent                = 'fraudPresent',
  ProcessingError             = 'processingError',
  Other                       = 'other'
}

/** Period that presentment was provided for claim. */
export enum ClaimCardLatePresentmentLength {
  MoreThan180                 = 'moreThan180',
  MoreThan30LessThan180       = 'less30More180'
}

/** Location of card when initiating card dispute. */
export enum ClaimCardLocation {
  Possessed                   = 'possessed',
  Lost                        = 'lost',
  Stolen                      = 'stolen',
  Unreceived                  = 'unreceived'
}

/** Reason that a card dispute was overturned. */
export enum ClaimCardOverturnReason {
  Unspecified                 = 'unspecified'
}

/** Reason that a card transaction amount error occurred. */
export enum ClaimCardProcessingErrorReason {
  DraftAltered                = 'draftAltered',
  IncorrectTotal              = 'incorrectTotal',
  IncorrectTransmit           = 'incorrectTransmit'
}

/** Reason for processing errors in a claim. */
export enum ClaimCardProcessingErrorType {
  AdditionalCharge            = 'additionalCharge',
  ComputationalError          = 'computationalError',
  CreditNotReceived           = 'creditNotReceived',
  DuplicateTransaction        = 'duplicateTransaction',
  IncorrectTransactionAmount  = 'incorrectTransactionAmount',
  IncorrectTransactionCode    = 'incorrectTransactionCode',
  LatePresentment             = 'latePresentment',
  UnrecognizedTransaction     = 'unrecognizedTransaction'
}

/** Method that a card dispute was reported. */
export enum ClaimCardReportType {
  Oral                        = 'oral',
  Written                     = 'written'
}

/** Methods of resolving a claim */
export enum ClaimCardResolutionReason {
  ApproveCredit               = 'approve',
  DeclineCredit               = 'decline'
}

/** Reason that merchandise/service was returned. */
export enum ClaimCardReturnReason {
  Counterfeit                 = 'counterfeit',
  Defective                   = 'defective',
  Description                 = 'description'
}

/** Shipping company linked to claim. */
export enum ClaimCardShippingCompany {
  FedEx                       = 'fedex',
  Other                       = 'other',
  UPS                         = 'ups',
  USPS                        = 'usps'
}

/** Method that a claim was signed. */
export enum ClaimCardSignatureMethod {
  Electronic                  = 'electronic',
  NotRequired                 = 'notRequired',
  Written                     = 'written'
}

/** Reason an incorrect card transaction amount is being reported. */
export enum ClaimCardTransactionAmountError {
  DraftAltered                = 'draftAltered',
  IncorrectTotal              = 'incorrectTotal',
  IncorrectTransmit           = 'incorrectTransmit'
}

/** Reason for an error on claim transactions. */
export enum ClaimCardTransactionError {
  FalseCredit                 = 'falseCredit',
  FalseDebit                  = 'falseDebit'
}

/** Type of check dispute being initiated. */
export enum ClaimCheckDetailsType {
  Forgery                     = 'forgery',
  Unauthorized                = 'unauthorized'
}

/** Reason for opening a check claim. */
export enum ClaimCheckReportReason {
  NSFNotSufficientFunds               = 'A',
  UCFUncollectedFundsHold             = 'B',
  StopPayment                         = 'C',
  ClosedAccount                       = 'D',
  UTLAUnableToLocateAccount           = 'E',
  FrozenOrBlockedAccount              = 'F',
  StaleDated                          = 'G',
  PostDated                           = 'H',
  EndorsementMissing                  = 'I',
  EndorsementIrregular                = 'J',
  SignatureMissing                    = 'K',
  SignatureIrregularSuspectedForgery  = 'L',
  NonCashItem                         = 'M',
  AlteredOrfFictitiousItem            = 'N',
  UnableToProcess                     = 'O',
  ItemExceedsStatedMaxValue           = 'P',
  NotAuthorized                       = 'Q',
  BranchOrAccountSold                 = 'R',
  ReferToMaker                        = 'S',
  ItemCannotBeRepresented             = 'T',
  UnusableImage                       = 'U',
  CannotDetermineAmount               = 'W',
  ReferToImage                        = 'X',
  DuplicatePresentment                = 'Y',
  Forgery                             = 'Z',
  WarrantyBreach                      = '3',
  RCCWarrantyBreach                   = '4',
  ForgedAndCounterfeitWarrantyBreach  = '5',
  RetiredOrIneligibleRoutingNumber    = '6',
  ReservedForFutureUse1               = '7',
  ReservedForFutureUse2               = '8',
  ReservedForFutureUse3               = '9',
  ReservedForFutureUse4               = '0'
}

/** Result of establishing a follow-up. */
export enum ClaimFollowUpResponse {
  Always                      = 'always',
  UnlessCurrent               = 'unlessCurrent'
}

/** Union type flag of claim. */
export enum ClaimType {
  ACH                         = 'ach',
  Card                        = 'card',
  Check                       = 'check',
  CreditBureau                = 'creditBureau'
}

/** Union type flag of disputes. */
export enum DisputeType {
  ACH                         = 'ach',
  Card                        = 'card',
  Check                       = 'check'
}

/** Given reason for viewing a claim. */
export enum ClaimViewReason {
  CustomerInquiry             = 'customerInquiry',
  Other                       = 'other',
  UploadingDocuments          = 'uploadDocuments'
}

/** Current status of credit on a disputed transaction */
export enum DisputeCreditStatus {
  Final                       = 'finalCredit',
  None                        = 'noCredit',
  Provisional                 = 'provisionalCredit',
  Reversed                    = 'reversed'
}

/** Type of transaction in a dispute ledger (this is always from the perspective of the FI - e.g. crediting a consumer would be a debit). */
export enum LedgerType {
  Credit                      = 'credit',
  Debit                       = 'debit'
}

/** Current status of a disputed transaction */
export enum DisputeStatus {
  Approved                    = 'approved',
  Arbitration                 = 'arbitration',
  Chargeback                  = 'chargeback',
  Denied                      = 'denied',
  MerchantRefunded            = 'merchantRefunded',
  NotWorked                   = 'notWorked',
  Prearbitration              = 'prearbitration',
  Representment               = 'representment',
  Withdrawn                   = 'withdrawn'
};

/** Current status of claim. */
export enum ClaimStatus {
  Chargeback                  = 'chargeback',
  Credit                      = 'credit',
  Initiated                   = 'initiated',
  Resolved                    = 'resolved',
  Return                      = 'return'
}

/** ACH Standard Entry Codes. */
export enum StandardEntryClass {
  ACHPaymentAcknowledgement   = 'ACK',
  AutomatedAccountingAdvice   = 'ADV',
  AccountsReceivableEntries   = 'ARC',
  FinancialEDIAcknowledgement = 'ATX',
  BackOfficeConversion        = 'BOC',
  CorporateCreditOrDebit      = 'CCD',
  CustomerInitiatedEntry      = 'CIE',
  NotificationOfChange        = 'COR',
  CorporateTradeExchange      = 'CTX',
  DeathNotificationEntry      = 'DNE',
  AutomatedEnrollmentEntry    = 'ENR',
  InternationalACHTransaction = 'IAT',
  MachineTransferEntry        = 'MTE',
  PointOfPurchase             = 'POP',
  PointOfSale                 = 'POS',
  PrearrangedPaymentOrDeposit = 'PPD',
  RepresentedCheckEntry       = 'RCK',
  SharedNetworkTransaction    = 'SHR',
  TelephoneInitiatedEntry     = 'TEL',
  TruncatedEntry              = 'TRC',
  TruncatedEntryExchange      = 'TRX',
  InternetInitiatedEntry      = 'WEB',
  DestroyedCheckEntry         = 'XCK'
}

/** Standard Entry Codes appropriate for randomly generating transactions. */
export const STANDARD_ENTRY_CLASS_COMMON = [StandardEntryClass.CorporateCreditOrDebit, StandardEntryClass.PrearrangedPaymentOrDeposit]

/** Name of each check details type. */
export const CLAIM_CHECK_DETAILS_TYPE_NAME: EnumMap<ClaimCheckDetailsType> = {
  [ClaimCheckDetailsType.Forgery]: 'Forgery',
  [ClaimCheckDetailsType.Unauthorized]: 'Unauthorized'
};

/** Name of each ACH report reason. */
export const CLAIM_ACH_REPORT_REASON_TYPE_BANK_NAME: EnumMap<ClaimACHReportReason> = {
  [ClaimACHReportReason.InsufficientFunds]: 'Insufficient funds',
  [ClaimACHReportReason.AccountClosed]: 'Account Closed',
  [ClaimACHReportReason.NoAccount]: 'No Account / Unable to Locate Account',
  [ClaimACHReportReason.InvalidAccountNumber]: 'Invalid Account Number Structure',
  [ClaimACHReportReason.UnauthorizedDebit]: 'Unauthorized Debit to Consumer Account Using Corporate SEC Code',
  [ClaimACHReportReason.ReturnedODFIRequest]: 'Returned per ODFI\'s Request',
  [ClaimACHReportReason.AuthorizationRevoked]: 'Authorization Revoked by Customer',
  [ClaimACHReportReason.PaymentStopped]: 'Payment Stopped',
  [ClaimACHReportReason.UncollectedFunds]: 'Uncollected Funds',
  [ClaimACHReportReason.OriginatorUnauthorized]: 'Customer Advises Originator is Not Known to Receiver and/or Originator is Not Authorized by Receiver to Debit Receiver\'s Account',
  [ClaimACHReportReason.NotInAccordance]: 'Customer Advises Entry Not in Accordance with the Terms of the Authorization',
  [ClaimACHReportReason.AccountSold]: 'Account Sold to Another DFI',
  [ClaimACHReportReason.InvalidRoutingNumber]: 'Invalid ACH Routing Number',
  [ClaimACHReportReason.PayeeDeceased]: 'Representative Payee Deceased or Unable to Continue in That Capacity',
  [ClaimACHReportReason.BeneficiaryDeceased]: 'Beneficiary or Account Holder (Other Than a Representative Payee) Deceased',
  [ClaimACHReportReason.AccountFrozen]: 'Account Frozen / Entry Returned Per OFAC Instruction',
  [ClaimACHReportReason.ImproperReversal]: 'File Record Edit Criteria / Entry with Invalid Account Number Initiated Under Questionable Circumstances / Return of Improperly Initiated Reversal',
  [ClaimACHReportReason.ImproperEffectiveDate]: 'Improper Effective Entry Date',
  [ClaimACHReportReason.AmountFieldError]: 'Amount Field Error',
  [ClaimACHReportReason.NonTransactionAccount]: 'Non-Transaction Account',
  [ClaimACHReportReason.InvalidCompanyIdentification]: 'Invalid Company Identification',
  [ClaimACHReportReason.InvalidIdNumber]: 'Invalid Individual ID Number',
  [ClaimACHReportReason.CreditRefused]: 'Credit Entry Refused by Receiver',
  [ClaimACHReportReason.DuplicateEntry]: 'Duplicate Entry',
  [ClaimACHReportReason.AddendaError]: 'Addenda Error',
  [ClaimACHReportReason.MandatoryFieldError]: 'Mandatory Field Error',
  [ClaimACHReportReason.TraceNumberError]: 'Trace Number Error',
  [ClaimACHReportReason.RoutingNumberError]: 'Routing Number Check Digit Error',
  [ClaimACHReportReason.CorporateUnauthorized]: 'Corporate Customer Advises Not Authorized',
  [ClaimACHReportReason.CheckTruncationProgram]: 'RDFI Not Participant in Check Truncation Program',
  [ClaimACHReportReason.PermissibleReturnOnly]: 'Permissible Return Entry (CCD and CTX) only',
  [ClaimACHReportReason.NonSettlement]: 'RDFI Non-Settlement',
  [ClaimACHReportReason.ReturnXCKEntry]: 'Return of XCK Entry',
  [ClaimACHReportReason.LimitedParticipation]: 'Limited Participation DFI',
  [ClaimACHReportReason.ReturnImproperDebit]: 'Return of Improper Debit Entry',
  [ClaimACHReportReason.ReturnImproperCredit]: 'Return of Improper Credit Entry',
  [ClaimACHReportReason.SourceDocumentPresented]: 'Source Document Presented for Payment',
  [ClaimACHReportReason.StopPaymentSourceDocument]: 'Stop Payment on Source Document',
  [ClaimACHReportReason.ImproperSourceDocument]: 'Improper Source Document / Source Document Presented for Payment',
  [ClaimACHReportReason.ReturnENREntry]: 'Return ENR Entry by Federal Government Agency',
  [ClaimACHReportReason.InvalidTransactionCode]: 'Invalid Transaction Code',
  [ClaimACHReportReason.CheckDigitError]: 'Routing Number / Check Digit Error',
  [ClaimACHReportReason.InvalidDFIAccountNumber]: 'Invalid DFI Account Number',
  [ClaimACHReportReason.InvalidIdentificationNumber]: 'Invalid Individual ID Number / Identification Number',
  [ClaimACHReportReason.InvalidName]: 'Invalid Individual Name / Company Name',
  [ClaimACHReportReason.InvalidPayeeIndicator]: 'Invalid Representative Payee Indicator',
  [ClaimACHReportReason.DuplicateEnrollment]: 'Duplicate Enrollment',
  [ClaimACHReportReason.RCKStateLawAcceptance]: 'State Law Affecting RCK Acceptance ',
  [ClaimACHReportReason.RCKEntryIneligible]: 'Item related to RCK Entry is Ineligible for RCK Entry is Improper',
  [ClaimACHReportReason.RCKEntryStopPayment]: 'Stop Payment on Item Related to RCK Entry',
  [ClaimACHReportReason.ItemRCKPresented]: 'Item and RCK Entry Presented for Payment',
  [ClaimACHReportReason.MisroutedReturn]: 'Misrouted Return',
  [ClaimACHReportReason.ReturnErroneousDebit]: 'Return of Erroneous or Reversing Debit',
  [ClaimACHReportReason.DuplicateReturn]: 'Duplicate Return',
  [ClaimACHReportReason.UntimelyReturn]: 'Untimely Return',
  [ClaimACHReportReason.FieldError]: 'Field Error(s)',
  [ClaimACHReportReason.ReturnNotAccepted]: 'Permissible Return Entry Not Accepted / Return Not Requested by ODFI',
  [ClaimACHReportReason.MisroutedDishonoredReturn]: 'Misrouted Dishonored Return',
  [ClaimACHReportReason.UntimelyishonoredReturn]: 'Untimely Dishonored Return',
  [ClaimACHReportReason.TimelyOriginalReturn]: 'Timely Original Return',
  [ClaimACHReportReason.CorrectedReturn]: 'Corrected Return',
  [ClaimACHReportReason.ReturnNotDuplicate]: 'Return Not a Duplicate',
  [ClaimACHReportReason.NoErrorsFound]: 'No Errors Found',
  [ClaimACHReportReason.NonAcceptedDishonoredReturn]: 'Non-Acceptance of R62 Dishonored Return',
  [ClaimACHReportReason.IATCodingError]: 'IAT Entry Coding Error',
  [ClaimACHReportReason.IATNonParticipant]: 'Non-Participant in IAT Program',
  [ClaimACHReportReason.InvalidForeignDFI]: 'Invalid Foreign Receiving DFI Identification',
  [ClaimACHReportReason.UnsettledForeignDFI]: 'Foreign Receiving DFI Unable to Settle',
  [ClaimACHReportReason.GatewayUnprocessed]: 'Entry Not Processed by Gateway',
  [ClaimACHReportReason.IncorrectOutboundPayment]: 'Incorrectly Coded Outbound International Payment'
}

/** Name of each ACH report reason. */
export const CLAIM_ACH_REPORT_REASON_TYPE_CREDIT_UNION_NAME: EnumMap<ClaimACHReportReason> = {
  [ClaimACHReportReason.InsufficientFunds]: 'Insufficient funds',
  [ClaimACHReportReason.AccountClosed]: 'Account Closed',
  [ClaimACHReportReason.NoAccount]: 'No Account / Unable to Locate Account',
  [ClaimACHReportReason.InvalidAccountNumber]: 'Invalid Account Number Structure',
  [ClaimACHReportReason.UnauthorizedDebit]: 'Unauthorized Debit to Consumer Account Using Corporate SEC Code',
  [ClaimACHReportReason.ReturnedODFIRequest]: 'Returned per ODFI\'s Request',
  [ClaimACHReportReason.AuthorizationRevoked]: 'Authorization Revoked by Member',
  [ClaimACHReportReason.PaymentStopped]: 'Payment Stopped',
  [ClaimACHReportReason.UncollectedFunds]: 'Uncollected Funds',
  [ClaimACHReportReason.OriginatorUnauthorized]: 'Member Advises Originator is Not Known to Receiver and/or Originator is Not Authorized by Receiver to Debit Receiver\'s Account',
  [ClaimACHReportReason.NotInAccordance]: 'Member Advises Entry Not in Accordance with the Terms of the Authorization',
  [ClaimACHReportReason.AccountSold]: 'Account Sold to Another DFI',
  [ClaimACHReportReason.InvalidRoutingNumber]: 'Invalid ACH Routing Number',
  [ClaimACHReportReason.PayeeDeceased]: 'Representative Payee Deceased or Unable to Continue in That Capacity',
  [ClaimACHReportReason.BeneficiaryDeceased]: 'Beneficiary or Account Holder (Other Than a Representative Payee) Deceased',
  [ClaimACHReportReason.AccountFrozen]: 'Account Frozen / Entry Returned Per OFAC Instruction',
  [ClaimACHReportReason.ImproperReversal]: 'File Record Edit Criteria / Entry with Invalid Account Number Initiated Under Questionable Circumstances / Return of Improperly Initiated Reversal',
  [ClaimACHReportReason.ImproperEffectiveDate]: 'Improper Effective Entry Date',
  [ClaimACHReportReason.AmountFieldError]: 'Amount Field Error',
  [ClaimACHReportReason.NonTransactionAccount]: 'Non-Transaction Account',
  [ClaimACHReportReason.InvalidCompanyIdentification]: 'Invalid Company Identification',
  [ClaimACHReportReason.InvalidIdNumber]: 'Invalid Individual ID Number',
  [ClaimACHReportReason.CreditRefused]: 'Credit Entry Refused by Receiver',
  [ClaimACHReportReason.DuplicateEntry]: 'Duplicate Entry',
  [ClaimACHReportReason.AddendaError]: 'Addenda Error',
  [ClaimACHReportReason.MandatoryFieldError]: 'Mandatory Field Error',
  [ClaimACHReportReason.TraceNumberError]: 'Trace Number Error',
  [ClaimACHReportReason.RoutingNumberError]: 'Routing Number Check Digit Error',
  [ClaimACHReportReason.CorporateUnauthorized]: 'Corporate Member Advises Not Authorized',
  [ClaimACHReportReason.CheckTruncationProgram]: 'RDFI Not Participant in Check Truncation Program',
  [ClaimACHReportReason.PermissibleReturnOnly]: 'Permissible Return Entry (CCD and CTX) only',
  [ClaimACHReportReason.NonSettlement]: 'RDFI Non-Settlement',
  [ClaimACHReportReason.ReturnXCKEntry]: 'Return of XCK Entry',
  [ClaimACHReportReason.LimitedParticipation]: 'Limited Participation DFI',
  [ClaimACHReportReason.ReturnImproperDebit]: 'Return of Improper Debit Entry',
  [ClaimACHReportReason.ReturnImproperCredit]: 'Return of Improper Credit Entry',
  [ClaimACHReportReason.SourceDocumentPresented]: 'Source Document Presented for Payment',
  [ClaimACHReportReason.StopPaymentSourceDocument]: 'Stop Payment on Source Document',
  [ClaimACHReportReason.ImproperSourceDocument]: 'Improper Source Document / Source Document Presented for Payment',
  [ClaimACHReportReason.ReturnENREntry]: 'Return ENR Entry by Federal Government Agency',
  [ClaimACHReportReason.InvalidTransactionCode]: 'Invalid Transaction Code',
  [ClaimACHReportReason.CheckDigitError]: 'Routing Number / Check Digit Error',
  [ClaimACHReportReason.InvalidDFIAccountNumber]: 'Invalid DFI Account Number',
  [ClaimACHReportReason.InvalidIdentificationNumber]: 'Invalid Individual ID Number / Identification Number',
  [ClaimACHReportReason.InvalidName]: 'Invalid Individual Name / Company Name',
  [ClaimACHReportReason.InvalidPayeeIndicator]: 'Invalid Representative Payee Indicator',
  [ClaimACHReportReason.DuplicateEnrollment]: 'Duplicate Enrollment',
  [ClaimACHReportReason.RCKStateLawAcceptance]: 'State Law Affecting RCK Acceptance ',
  [ClaimACHReportReason.RCKEntryIneligible]: 'Item related to RCK Entry is Ineligible for RCK Entry is Improper',
  [ClaimACHReportReason.RCKEntryStopPayment]: 'Stop Payment on Item Related to RCK Entry',
  [ClaimACHReportReason.ItemRCKPresented]: 'Item and RCK Entry Presented for Payment',
  [ClaimACHReportReason.MisroutedReturn]: 'Misrouted Return',
  [ClaimACHReportReason.ReturnErroneousDebit]: 'Return of Erroneous or Reversing Debit',
  [ClaimACHReportReason.DuplicateReturn]: 'Duplicate Return',
  [ClaimACHReportReason.UntimelyReturn]: 'Untimely Return',
  [ClaimACHReportReason.FieldError]: 'Field Error(s)',
  [ClaimACHReportReason.ReturnNotAccepted]: 'Permissible Return Entry Not Accepted / Return Not Requested by ODFI',
  [ClaimACHReportReason.MisroutedDishonoredReturn]: 'Misrouted Dishonored Return',
  [ClaimACHReportReason.UntimelyishonoredReturn]: 'Untimely Dishonored Return',
  [ClaimACHReportReason.TimelyOriginalReturn]: 'Timely Original Return',
  [ClaimACHReportReason.CorrectedReturn]: 'Corrected Return',
  [ClaimACHReportReason.ReturnNotDuplicate]: 'Return Not a Duplicate',
  [ClaimACHReportReason.NoErrorsFound]: 'No Errors Found',
  [ClaimACHReportReason.NonAcceptedDishonoredReturn]: 'Non-Acceptance of R62 Dishonored Return',
  [ClaimACHReportReason.IATCodingError]: 'IAT Entry Coding Error',
  [ClaimACHReportReason.IATNonParticipant]: 'Non-Participant in IAT Program',
  [ClaimACHReportReason.InvalidForeignDFI]: 'Invalid Foreign Receiving DFI Identification',
  [ClaimACHReportReason.UnsettledForeignDFI]: 'Foreign Receiving DFI Unable to Settle',
  [ClaimACHReportReason.GatewayUnprocessed]: 'Entry Not Processed by Gateway',
  [ClaimACHReportReason.IncorrectOutboundPayment]: 'Incorrectly Coded Outbound International Payment'
}

/** Name of each card cancellation type. */
export const CLAIM_CARD_CANCELLATION_OR_RETURN_TYPE_NAME: EnumMap<ClaimCardCancellationOrReturnType> = {
  [ClaimCardCancellationOrReturnType.CancelProduct]: 'I notified the merchant that I wished to cancel the service/product.',
  [ClaimCardCancellationOrReturnType.NotReceived]: 'I have not received the merchandise/services.',
  [ClaimCardCancellationOrReturnType.NotExpected]: 'The merchandise was shipped to me but arrived not as expected. I returned it and requested a credit.',
  [ClaimCardCancellationOrReturnType.ReturnedRefused]: 'The merchandise was returned/refused. I have asked for a refund. Copy or return receipt attached.',
  [ClaimCardCancellationOrReturnType.PaidOtherMeans]: 'The merchandise / service was paid for the other means. Documentation for the other method of payment is attached.'
};

/** Name of each card close reason. */
export const CLAIM_CARD_CLOSE_REASON_NAME: EnumMap<ClaimCardCloseReason> = {
  [ClaimCardCloseReason.AccountClosed]: 'Account Closed',
  [ClaimCardCloseReason.Counterfeit]: 'Counterfeit',
  [ClaimCardCloseReason.CreditProblem]: 'Credit Problem',
  [ClaimCardCloseReason.Lost]: 'Lost',
  [ClaimCardCloseReason.Stolen]: 'Stolen'
};

/** Name of each card decline reason. */
export const CLAIM_CARD_DECLINE_REASON_NAME: EnumMap<ClaimCardDeclineReason> = {
  [ClaimCardDeclineReason.Unspecified]: 'Unspecified'
};

/** Name for each card late presentment period. */
export const CLAIM_CARD_LATE_PRESENTMENT_LENGTH_NAME: EnumMap<ClaimCardLatePresentmentLength> = {
  [ClaimCardLatePresentmentLength.MoreThan180]: 'More than 180 days prior to the settlement date.',
  [ClaimCardLatePresentmentLength.MoreThan30LessThan180]: 'More than 30 days but less than 180 days prior to the settlement date.'
};

/** Name of each card location. */
export const CLAIM_CARD_LOCATION_NAME: EnumMap<ClaimCardLocation> = {
  [ClaimCardLocation.Possessed]: 'In Possession',
  [ClaimCardLocation.Lost]: 'Lost',
  [ClaimCardLocation.Stolen]: 'Stolen',
  [ClaimCardLocation.Unreceived]: 'Unreceived'
};

/** Name of each card overturn reason. */
export const CLAIM_CARD_OVERTURN_REASON_NAME: EnumMap<ClaimCardOverturnReason> = {
  [ClaimCardOverturnReason.Unspecified]: 'Unspecified'
};

/** Name of each card claim processing error reason. */
export const CLAIM_CARD_PROCESSING_ERROR_REASON_NAME: EnumMap<ClaimCardProcessingErrorReason> = {
  [ClaimCardProcessingErrorReason.DraftAltered]: 'Draft altered by merchant',
  [ClaimCardProcessingErrorReason.IncorrectTotal]: 'Total added incorrectly',
  [ClaimCardProcessingErrorReason.IncorrectTransmit]: 'Total lost in transmission'
};

/** Name of each card claim processing error type. */
export const CLAIM_CARD_PROCESSING_ERROR_TYPE_NAME: EnumMap<ClaimCardProcessingErrorType> = {
  [ClaimCardProcessingErrorType.AdditionalCharge]: 'I authorized a transaction from the merchant. I was billed for an additional charge that I did not authorize.',
  [ClaimCardProcessingErrorType.ComputationalError]: 'A computational error made by the financial institution relating to an EFT.',
  [ClaimCardProcessingErrorType.CreditNotReceived]: 'A credit from the merchant has not been received.',
  [ClaimCardProcessingErrorType.DuplicateTransaction]: 'The charge was a single transaction. It posted twice to my account.',
  [ClaimCardProcessingErrorType.IncorrectTransactionAmount]: 'The transaction amount was incorrect.',
  [ClaimCardProcessingErrorType.IncorrectTransactionCode]: 'An incorrect transaction code was present.',
  [ClaimCardProcessingErrorType.LatePresentment]: 'Late Presentment (120 calendar days)',
  [ClaimCardProcessingErrorType.UnrecognizedTransaction]: 'I do not recognize this transaction; a copy of the sales draft is requested. (Signature transaction only)'
};

/** Name of each card report reason. */
export const CLAIM_CARD_DETAILS_TYPE_NAME: EnumMap<ClaimCardDetailsType> = {
  [ClaimCardDetailsType.AtmTransaction]: 'ATM Transaction',
  [ClaimCardDetailsType.Cancellation]: 'Cancellation',
  [ClaimCardDetailsType.FraudAbsent]: 'Fraud (Absent)',
  [ClaimCardDetailsType.FraudPresent]: 'Fraud (Present)',
  [ClaimCardDetailsType.ProcessingError]: 'Processing Error',
  [ClaimCardDetailsType.Other]: 'Other'
};

/** Name of each card report type. */
export const CLAIM_CARD_REPORT_TYPE_NAME: EnumMap<ClaimCardReportType> = {
  [ClaimCardReportType.Oral]: 'Oral',
  [ClaimCardReportType.Written]: 'Written'
};

/** Name of each card resolution reason. */
export const CLAIM_CARD_RESOLUTION_REASON_NAME: EnumMap<ClaimCardResolutionReason> = {
  [ClaimCardResolutionReason.ApproveCredit]: 'Approve Credit',
  [ClaimCardResolutionReason.DeclineCredit]: 'Decline Credit'
};

/** name of each card return reason. */
export const CLAIM_CARD_RETURN_REASON_NAME: EnumMap<ClaimCardReturnReason> = {
  [ClaimCardReturnReason.Counterfeit]: 'Counterfeit Merchandise',
  [ClaimCardReturnReason.Defective]: 'Defective Merchandise',
  [ClaimCardReturnReason.Description]: 'Not as Described'
};

/** Name of each card shipping company. */
export const CLAIM_CARD_SHIPPING_COMPANY_NAME: EnumMap<ClaimCardShippingCompany> = {
  [ClaimCardShippingCompany.FedEx]: 'FedEx',
  [ClaimCardShippingCompany.Other]: 'Other',
  [ClaimCardShippingCompany.UPS]: 'UPS',
  [ClaimCardShippingCompany.USPS]: 'USPS'
};

/** Name of each card claim signature method. */
export const CLAIM_CARD_SIGNATURE_METHOD_NAME: EnumMap<ClaimCardSignatureMethod> = {
  [ClaimCardSignatureMethod.Electronic]: 'E-Signature',
  [ClaimCardSignatureMethod.NotRequired]: 'Not Required',
  [ClaimCardSignatureMethod.Written]: 'Written'
};

/** Name of each card claim transaction amount error type. */
export const CLAIM_CARD_TRANSACTION_AMOUNT_ERROR_NAME: EnumMap<ClaimCardTransactionAmountError> = {
  [ClaimCardTransactionAmountError.DraftAltered]: 'Draft altered by merchant',
  [ClaimCardTransactionAmountError.IncorrectTotal]: 'Total added incorrectly',
  [ClaimCardTransactionAmountError.IncorrectTransmit]: 'Total transmitted incorrectly'
};

/** Name of each claim transaction error type. */
export const CLAIM_CARD_TRANSACTION_ERROR_NAME: EnumMap<ClaimCardTransactionError> = {
  [ClaimCardTransactionError.FalseCredit]: 'Debit posted as a credit',
  [ClaimCardTransactionError.FalseDebit]: 'Credit posted as a debit'
};

/** Name of each card claim status. */
export const CLAIM_STATUS_NAME: EnumMap<ClaimStatus> = {
  [ClaimStatus.Chargeback]: 'Chargeback',
  [ClaimStatus.Credit]: 'Credit',
  [ClaimStatus.Initiated]: 'Initiated',
  [ClaimStatus.Resolved]: 'Resolved',
  [ClaimStatus.Return]: 'Return'
};

/** Name of each card report reason. */
export const CLAIM_CHECK_REPORT_REASON_TYPE_NAME: EnumMap<ClaimCheckReportReason> = {
  [ClaimCheckReportReason.NSFNotSufficientFunds]: "NSF - Not sufficient funds",
  [ClaimCheckReportReason.UCFUncollectedFundsHold]: "UCF - Uncollected funds hold",
  [ClaimCheckReportReason.StopPayment]: "Stop payment",
  [ClaimCheckReportReason.ClosedAccount]: "Closed account",
  [ClaimCheckReportReason.UTLAUnableToLocateAccount]: "UTLA - Unable to locate account",
  [ClaimCheckReportReason.FrozenOrBlockedAccount]: "Frozen/blocked account. Account has restrictions placed on it by either customer or bank",
  [ClaimCheckReportReason.StaleDated]: "Stale dated",
  [ClaimCheckReportReason.PostDated]: "Post dated",
  [ClaimCheckReportReason.EndorsementMissing]: "Endorsement missing",
  [ClaimCheckReportReason.EndorsementIrregular]: "Endorsement irregular",
  [ClaimCheckReportReason.SignatureMissing]: "Signature(s) missing",
  [ClaimCheckReportReason.SignatureIrregularSuspectedForgery]: "Signature(s) irregular, suspected forgery",
  [ClaimCheckReportReason.NonCashItem]: "Non-cash item (non negotiable)",
  [ClaimCheckReportReason.AlteredOrfFictitiousItem]: "Altered/fictitious Item. Suspected counterfeit/counterfeit",
  [ClaimCheckReportReason.UnableToProcess]: "Unable to process (e.g. unable to process physical item/mutilated such that critical payment information is missing). This code shall not be used for unusable images or system problems (see administrative code ‘U’)",
  [ClaimCheckReportReason.ItemExceedsStatedMaxValue]: "Item exceeds stated max value",
  [ClaimCheckReportReason.NotAuthorized]: "Not authorized (Includes drafts) – unauthorized item such as a draft",
  [ClaimCheckReportReason.BranchOrAccountSold]: "Branch/account sold (wrong bank) – divested account, not our item",
  [ClaimCheckReportReason.ReferToMaker]: "Refer to maker",
  [ClaimCheckReportReason.ItemCannotBeRepresented]: "Item cannot be re-presented (exceeds number of allowable times the item can be presented)",
  [ClaimCheckReportReason.UnusableImage]: "Unusable Image (image could not be used for required business purpose, e.g. gross image defects, illegible, etc.)",
  [ClaimCheckReportReason.CannotDetermineAmount]: "Cannot determine amount – amount cannot be verified",
  [ClaimCheckReportReason.ReferToImage]: "Refer to image – return reason information is contained within the image of the item.",
  [ClaimCheckReportReason.DuplicatePresentment]: "Duplicate presentment (supporting documentation shall be readily available)",
  [ClaimCheckReportReason.Forgery]: "Forgery – an affidavit shall be available upon request",
  [ClaimCheckReportReason.WarrantyBreach]: "Warranty breach (includes rule 8 & 9 claims)",
  [ClaimCheckReportReason.RCCWarrantyBreach]: "RCC warranty breach (rule 8)",
  [ClaimCheckReportReason.ForgedAndCounterfeitWarrantyBreach]: "Forged and counterfeit warranty breach (rule 9)",
  [ClaimCheckReportReason.RetiredOrIneligibleRoutingNumber]: "Retired/ineligible routing number",
  [ClaimCheckReportReason.ReservedForFutureUse1]: "Reserved for future use by X9",
  [ClaimCheckReportReason.ReservedForFutureUse2]: "Reserved for future use by X9",
  [ClaimCheckReportReason.ReservedForFutureUse3]: "Reserved for future use by X9",
  [ClaimCheckReportReason.ReservedForFutureUse4]: "Reserved for future use by X9"
}


/** Name of each promise type. */
export const CLAIM_FOLLOW_UP_RESPONSE_NAME: EnumMap<ClaimFollowUpResponse> = {
  [ClaimFollowUpResponse.Always]: 'Always',
  [ClaimFollowUpResponse.UnlessCurrent]: 'Unless Current'
};

/** Name of each claim type. */
export const CLAIM_TYPE_NAME: EnumMap<ClaimType> = {
  [ClaimType.ACH]: 'ACH',
  [ClaimType.Card]: 'Card',
  [ClaimType.Check]: 'Check Fraud',
  [ClaimType.CreditBureau]: 'Credit Bureau'
};

/** Name of each claim view reason. */
export const CLAIM_VIEW_REASON_NAME: EnumMap<ClaimViewReason> = {
  [ClaimViewReason.CustomerInquiry]: 'Customer Inquiry',
  [ClaimViewReason.Other]: 'Other (details below)',
  [ClaimViewReason.UploadingDocuments]: 'Uploading Supporting Documents'
};

/** Name of each dispute credit status. */
export const DISPUTE_CREDIT_STATUS_NAME: EnumMap<DisputeCreditStatus> = {
  [DisputeCreditStatus.Final]: 'Final Credit',
  [DisputeCreditStatus.None]: 'No Credit',
  [DisputeCreditStatus.Provisional]: 'Provisional Credit',
  [DisputeCreditStatus.Reversed]: 'Reversed Provisional Credit'
};

/** Name of each dispute status. */
export const DISPUTE_STATUS_NAME: EnumMap<DisputeStatus> = {
  [DisputeStatus.Approved]: 'Approved',
  [DisputeStatus.Arbitration]: 'Arbitration',
  [DisputeStatus.Chargeback]: 'Chargeback',
  [DisputeStatus.Denied]: 'Denied',
  [DisputeStatus.MerchantRefunded]: 'Merchant Refunded',
  [DisputeStatus.NotWorked]: 'Not Worked',
  [DisputeStatus.Prearbitration]: 'Pre-Arbitration',
  [DisputeStatus.Representment]: 'Representment',
  [DisputeStatus.Withdrawn]: 'Withdrawn'
};

/** Name of each dispute type. */
export const DISPUTE_TYPE_NAME: EnumMap<DisputeType> = {
  [DisputeType.ACH]: 'ACH',
  [DisputeType.Card]: 'Card',
  [DisputeType.Check]: 'Check Fraud',
};

/** Available credit statuses for each possible dispute credit status. */
export const DISPUTE_AVAILABLE_CREDIT_STATUSES: Record<DisputeCreditStatus, ArraySome<DisputeCreditStatus>> = {
  [DisputeCreditStatus.Final]: [DisputeCreditStatus.Final],
  [DisputeCreditStatus.None]: [DisputeCreditStatus.None],
  [DisputeCreditStatus.Provisional]: [DisputeCreditStatus.Reversed, DisputeCreditStatus.Final],
  [DisputeCreditStatus.Reversed]: [DisputeCreditStatus.Reversed]
};

/** Available types to show on a ledger. */
export const LEDGER_TYPES: EnumMap<LedgerType> = {
  [LedgerType.Credit]: 'Credit',
  [LedgerType.Debit]: 'Debit'
}

/** Available SEC Codes to show on an ACH transaction. */
export const STANDARD_ENTRY_CLASS_TYPES: EnumMap<StandardEntryClass> = {
  [StandardEntryClass.ACHPaymentAcknowledgement]: 'ACH Payment Acknowledge',
  [StandardEntryClass.AutomatedAccountingAdvice]: 'Automated Accounting Advice',
  [StandardEntryClass.AccountsReceivableEntries]: 'Accounts Receivable Entry',
  [StandardEntryClass.FinancialEDIAcknowledgement]: 'Financial EDI Acknowledgment',
  [StandardEntryClass.BackOfficeConversion]: 'Back Office Conversion Entry',
  [StandardEntryClass.CorporateCreditOrDebit]: 'Corporate Credit or Debit Entry',
  [StandardEntryClass.CustomerInitiatedEntry]: 'Customer Initiated Entry',
  [StandardEntryClass.NotificationOfChange]: 'Notification of Change, or Refused Notification of Change',
  [StandardEntryClass.CorporateTradeExchange]: 'Corporate Trade Exchange',
  [StandardEntryClass.DeathNotificationEntry]: 'Death Notification Entry',
  [StandardEntryClass.AutomatedEnrollmentEntry]: 'Automated Enrollment Entry',
  [StandardEntryClass.InternationalACHTransaction]: 'International ACH Transaction',
  [StandardEntryClass.MachineTransferEntry]: 'Machine Transfer Entry',
  [StandardEntryClass.PointOfPurchase]: 'Point of Purchase Entry',
  [StandardEntryClass.PointOfSale]: 'Point of Sale Entry',
  [StandardEntryClass.PrearrangedPaymentOrDeposit]: 'Prearranged Payment and Deposit',
  [StandardEntryClass.RepresentedCheckEntry]: 'Re-presented Check Entry',
  [StandardEntryClass.SharedNetworkTransaction]: 'Shared Network Transaction',
  [StandardEntryClass.TelephoneInitiatedEntry]: 'Telephone-Initiated Entry',
  [StandardEntryClass.TruncatedEntry]: 'Truncated Entry',
  [StandardEntryClass.TruncatedEntryExchange]: 'Truncated Entry Exchange',
  [StandardEntryClass.InternetInitiatedEntry]: 'Internet Initiated/Mobile Entry',
  [StandardEntryClass.DestroyedCheckEntry]: 'Destroyed Check Entry',
}
