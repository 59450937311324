<app-card body class="column">
  <div class="row align-end">
    <app-field class="fill">
      <app-label>Name</app-label>
      <app-string disabled [(ngModel)]="user.name"></app-string>
    </app-field>
    <app-field class="fill">
      <app-label>Email</app-label>
      <app-string disabled [(ngModel)]="user.email"></app-string>
    </app-field>
    <button [routerLink]="['/reset-password']" [queryParams]="{email:user.email}" [disabled]="!(user.authentication | function:UserAuthenticationFusion.check)">Reset Password</button>
  </div>
  <app-permissions-grid [data]="data" [readonly]="true"></app-permissions-grid>
  <div class="button-row">
    <app-field class="fill">
      <app-boolean [on]="'Developer Mode'" [off]="'Developer Mode'" tooltip="Display additional developer features for debugging." [ngModel]="dev.on"  (ngModelChange)="onDev($event)"></app-boolean>
    </app-field>
  </div>
</app-card>
