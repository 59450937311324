import { CdkDrag, CdkDragMove } from '@angular/cdk/drag-drop';
import { ContentChildren, Directive, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { Box } from "../../../../../common/model/box";
import { Pos } from "../../../../../common/model/pos";
import { boxOffset, boxOnscreen } from '../toolbox/box';
import { GridDirective } from './grid.directive';

/** Get a view of a given element. */
class BoxGridView {
  /** Available area of box, in cells. */
  readonly size: Pos;

  /** Onscreen position of grid. */
  private box: Box;
  /** Size of individual grid cells. */
  private cell: Pos;
  /** Gap between grid cells. */
  private gap: Pos;
  /** Size of grid cells and gap. */
  private track: Pos;

  constructor(element: HTMLElement) {
    this.size = Pos.size(element);
    this.box = boxOnscreen(element);
    this.cell = Pos.cell(element);
    this.gap = Pos.gap(element);
    this.track = new Pos(this.cell.x + this.gap.x, this.cell.y + this.gap.y);
  }

  /** Get row at position. */
  row(y: number) {
    if (y < this.box.t || y > this.box.b) return;

    // Get offset within grid.
    let offset = y - this.box.t;
    let inside = offset % this.track.y;
    if (inside >= this.cell.y) return;

    // Return offset, in rows.
    return offset / this.track.y | 0;
  }

  /** Get column at position. */
  column(x: number) {
    if (x < this.box.l || x > this.box.r) return;

    // Get offset within grid.
    let offset = x - this.box.l;
    let inside = offset % this.track.x;
    if (inside >= this.cell.x) return;

    // Return offset, in rows.
    return offset / this.track.x | 0;
  }
}

/** A grid item with a box position. */
export interface BoxGridItem {
  /** Position of item. */
  box: Box
}

@Directive({
  selector: '[box-grid]'
})
export class BoxGridDirective<T extends BoxGridItem> extends GridDirective<T> {

  /** Drags interpolated via ng-content. */
  @ContentChildren(CdkDrag) contentRef!: QueryList<CdkDrag>;
  
  /** Set list of items in grid. */
  @Input('box-grid') list: T[] = [];

  /** Emits whenever an item is moved. */
  @Output() moved = new EventEmitter<void>();

  /**
   *  Move box positions of items.
   *  TODO diagnose an issue where dragging items really fast causes them to overlap
   */
  onMove($event: CdkDragMove) {
    // Get start and end of drag.
    let view = new BoxGridView(this.element.nativeElement);
    let [item, start, end] = this.drag($event, view);
    if (!item || !start || !end) return; // Outside grid.

    // Get next position of moving element.
    let offset = Pos.sub(Box.corner(end), Box.corner(start));
    if (offset.x === 0 && offset.y === 0) return;

    // Find all items occupying next position, and distance they'd move.
    let overlapping = this.list.filter(i => i !== item && Box.overlaps(i.box, end!));
    let sign = new Pos(Math.sign(offset.x), Math.sign(offset.y));

    // Verify all of these moves are valid.
    let subboxes: Box[] = [];
    for (let o of overlapping) {
      // Add additional offset in case of extra-wide or tall item.
      let move = new Pos();

      switch (sign.x) {
        case 1: move.x = end.l - o.box.r - 1; break;
        case -1: move.x = end.r - o.box.l + 1; break;
      }

      switch (sign.y) {
        case 1: move.y = end.t - o.box.b - 1; break;
        case -1: move.y = end.b - o.box.t + 1; break;
      }

      let box = Box.add(o.box, move);
      subboxes.push(box);

      // Ensure this move stays within grid.
      if (!Box.inside(box, view.size)) return;

      // Check this item against existing positions.
      for (let i of this.list) {
        if (i === item || i === o) continue; // Ignore collisions with dragging item and self.
        if (Box.overlaps(box, i.box)) return; // Moved item would clip into existing item
      }
    }

    // Verified moved boxes won't overlap.
    for (let o = 0; o < overlapping.length; ++o) {
      for (let i = 0; i < overlapping.length; ++i) {
        if (o === i) continue; // Ignore collisions with self.
        if (Box.overlaps(subboxes[i]!, subboxes[o]!)) return; // Moved item would clip into moved item.
      }
    }

    // All moves valid, move overlapping items.
    for (let o = 0; o < overlapping.length; ++o) {
      overlapping[o]!.box = subboxes[o]!;
    }

    // Move dragging item, adjust style, correct drag offset.
    let element = $event.source.element.nativeElement;
    let from = boxOffset(element);
    element.style.gridArea = Box.css(item.box = end)['grid-area'];
    this.adjust($event, from, boxOffset(element));
    this.moved.next();
  }

  /** Get start and end positions of a drag. */
  private drag($event: CdkDragMove, view: BoxGridView): [] | [item: T, start: Box, end: Box] {
    // Find item being dragged.
    let element = $event.source.element.nativeElement;
    let item = this.list[+element.getAttribute('data-index')!];
    if (!item) return [];

    // Get parameters of current view.
    let [start, delta] = [item.box, new Pos()];

    // Get change in x offset.
    switch (Math.sign($event.delta.x)) {
    case -1:
      let l = view.column(boxOnscreen(element).l);
      if (l !== undefined) delta.x = l - start.l;
      break;
    case 1:
      let r = view.column(boxOnscreen(element).r);
      if (r !== undefined) delta.x = r - start.r;
      break;
    }

    // Get change in y offset.
    switch (Math.sign($event.delta.y)) {
    case -1:
      let t = view.row(boxOnscreen(element).t);
      if (t !== undefined) delta.y = t - start.t;
      break;
    case 1:
      let b = view.row(boxOnscreen(element).b);
      if (b !== undefined) delta.y = b - start.b;
      break;
    }

    return [item, start, Box.add(start, delta)];
  }
}
