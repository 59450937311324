import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayType } from "../../../../../common/model/display";
import { Form, FormPreview } from "../../../../../common/model/form/form";
import { ApplicationSettingsKey } from '../../../../../common/model/setting-group';
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { getRequest } from '../toolbox/request';
import { serviceSettingsItem } from '../toolbox/service';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { SettingGroupService } from './setting-group.service';

/** A query to fetch a specific form. */
export class FormQuery {
  constructor(
    /** ID of form. */
    public _id = ID_DEFAULT,
    /** Institution of form. */
    public _inst = ID_DEFAULT
  ) {}
}

/** A query to fetch a specific form, for a type. */
export class FormQueryType extends FormQuery {
  constructor(
    _id = ID_DEFAULT,
    _inst = ID_DEFAULT,
    /** Display type of form. */
    public display = DisplayType.Account
  ) {
    super(_id, _inst);
  }
}

/** Caches information about tasks. */
@Injectable({
  providedIn: 'root'
})
export class FormService extends CachePreviewService<Form, FormQuery, FormPreview> {
  readonly route = 'forms/preview';
  readonly Type = Form;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private settings: SettingGroupService
  ) {
    super(FormQuery, log, dialog, http);
  }

  /** Fetch a form from settings. */
  setting(key: ApplicationSettingsKey) {
    return serviceSettingsItem('Form', this, this.auth, this.log, this.settings, key);
  }

  protected override multiple(queries: ArraySome<FormQuery>) {
    return getRequest(this.http, 'forms', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}
