import { JAVASCRIPT_CONSTANTS } from "../model/javascript";
import { keyNestedGet } from "./keys";
import { FLOAT_REGEX } from "./number";
import { STRING_REGEX } from "./string";

/** Available pipes for formatting angular templates. */
export type AngularPipes = Record<string, (value: any, ...args: any[]) => string | Promise<string>>;

/** Regex used to find {{interpolated}} values in template string. */
export const ANGULAR_EXPRESSION_REGEX = /{{([^}]+)}}/g;

/** A single segment of a template expression. */
class ExpressionSegment {
  constructor(
    /** Pipe being invoked. */
    public pipe = '',
    /** Evaluated arguments to pipe. */
    public args: string[] = []
  ) {}

  toString() {
    return this.args.length ? `${this.pipe}:${this.args.join(',')}` : `${this.pipe}`
  }
}

/** Parser for basic Angular expressions in form {{nested.key | pipe1:arg1 | pipe2:arg1:arg2}}.... */
export class AngularExpression {

  /** Value at front of angular expression. */
  public value: string;
  /** List of segments in expression. */
  public segments: ExpressionSegment[] = [];

  constructor(text: string) {
    let items = text.split(/\s*\|\s*/g);
    this.value = items[0]!;

    for (let i = 1; i < items.length; ++i) {
      let split = items[i]!.split(':');

      // Evaluate each segment.
      this.segments.push(new ExpressionSegment(split[0], split.slice(1)));
    }
  }

  toString() {
    return [this.value, ...this.segments.map(segment => `${segment}`)].join(' | ')
  }

  /** Evaluate all angular expressions in a string. */
  static async evaluate(format: string, context?: any, pipes: AngularPipes = {}) {
    let i = 0;
    let regex = new RegExp(ANGULAR_EXPRESSION_REGEX);
    let spans: (string | Promise<string>)[] = [];
    let match: RegExpExecArray | null;
    
    while (match = regex.exec(format)) {
      // Push plain string span before expression.
      spans.push(format.slice(i, match.index));
  
      // Resolve all pipes asynchrously.
      spans.push(new Promise(async (resolve) => {
        // Pull value from current context.
        let expression = new AngularExpression(match![1] ?? '');
        let out = AngularExpression.token(expression.value, context);
  
        for (let segment of expression.segments) {
          // Check if pipe is valid.
          let pipe = pipes[segment.pipe];
          if (!pipe) {
            out = `{{Unknown pipe: ${segment.pipe}}}`;
            break;
          }

          // Chain pipe with result of previous one.
          let args = segment.args.map(arg => AngularExpression.token(arg, context));
          out = await pipe(out, ...args);
        }
  
        resolve(out);
      }));

      // Save position of end of match.
      i = regex.lastIndex;
    }
  
    // Add remaining span to format string.
    spans.push(format.slice(i));
    return Promise.all(spans).then(spans => spans.join(''));
  }

  /** Evaluate a single token in an Angular expression. */
  static token(token: string, context: any) {
    if (token in JAVASCRIPT_CONSTANTS) return JAVASCRIPT_CONSTANTS[token];
    else if (FLOAT_REGEX.test(token)) return +token;
    else if (STRING_REGEX.test(token)) return token.slice(1, -1);
    else return keyNestedGet(token, context);
  }
}