import { CommonCode, ImportCode } from "../code/system"
import { IndexInfo } from "../info"
import { PropertyInfo } from "../info/prop"
import { TypeInfo } from "../info/type"
import { PropertyType } from "../model/property-type"
import { arrayDefined } from "../toolbox/array"
import { CURRENCY_DEFAULT, currencyFormat } from "../toolbox/currency"
import { ID_DEFAULT } from "../toolbox/id"
import { errorResponse } from "../toolbox/message"
import { dateFormat } from "../toolbox/time"
import { AccountReference } from "./account/base"
import { IndexMap } from "./indexmap"

/** A transaction made on an account or subaccount. */
export class Transaction {
  constructor(
    /** Unique identifier of transaction. */
    public _id = ID_DEFAULT,
    /** Institution this transaction belongs to. */
    public _inst = ID_DEFAULT,
    /** Account this transaction belongs to. */
    public _account = ID_DEFAULT,
    /** Host-provided action code on transaction. */
    public tranCode = '',
    /** Amount made on transaction. */
    public amount = CURRENCY_DEFAULT,
    /** Comment applied to transaction. */
    public comment?: string,
    /** Short description of transaction. */
    public description?: string,
    /** Date transaction was processed. */
    public effectiveDate = new Date(),
    /** Date transaction was submitted. */
    public postDate = new Date(),
    /** Batch sequence number of transaction. */
    public sequence = '',
    /** Host-provided code for where transaction was initiated, eg. online, atm. */
    public sourceCode?: string,
    /** True if this is test data. */
    public test?: boolean,
    /** Routing/Transit Number */
    public rt?: string,
    /** Serial/Check Number */
    public serial?: string,
    /** True if this is a pending transaction */
    public pending?: boolean,
    /** An counter that is used to deduplicate transactions with matching sequence */
    public counter = 0,
    /** Run number */
    public run?: string,
    /** Batch number */
    public batch?: string,
    /** ACH Standard Entry Class Code */
    public secCode?: string
  ) {}

  static typeinfo: TypeInfo<Transaction> = {
    comment: '',
    description: '',
    sourceCode: '',
    test: false,
    rt: '',
    serial: '',
    pending: false,
    run: '',
    batch: '',
    secCode: ''
  }

  static propinfo: PropertyInfo<Transaction> = {
    amount: { type: PropertyType.Currency },
    tranCode: { type: PropertyType.Code, category: CommonCode.Transaction },
    sourceCode: { type: PropertyType.Code, category: CommonCode.TransactionSource },
    secCode: { type: PropertyType.Code, category: ImportCode.StandardEntryClass }
  }
  
  static indexinfo: IndexInfo<Transaction> = [
    { key: { _account: 1 }},
    { key: { _inst: 1, _account: 1, postDate: 1, sequence: 1, counter: 1 }, unique: true }
  ];

  /** Get a short description of transaction. */
  static fullname(transaction: Transaction) {
    return arrayDefined([dateFormat(transaction.postDate), transaction.description, `(${currencyFormat(transaction.amount, true)})`]).join(' ');
  }
}

/** Unique fields of a transaction for lookup. */
export class TransactionReference {
  constructor(
    /** Institution of transaction. */
    public _inst = ID_DEFAULT,
    /** Account of transaction. */
    public _account: string = ID_DEFAULT,
    /** Date transaction was submitted. */
    public postDate = new Date(),
    /** Batch sequence number of transaction. */
    public sequence = '', 
    /** A counter that is used to deduplicate transactions with matching sequence */
    public counter = 0
  ) {}
}

/** Unique fields of a transaction-account for lookup. */
export class TransactionAccountReference {
  constructor(
    /** Institution of transaction. */
    public _inst = ID_DEFAULT,
    /** Account of transaction. */
    public account = new AccountReference(),
    /** Date transaction was submitted. */
    public postDate = new Date(),
    /** Batch sequence number of transaction. */
    public sequence = '', 
    /** A counter that is used to deduplicate transactions with matching sequence */
    public counter = 0
  ) {}
}

/** An index map specialized for looking up transactions. */
export class TransactionIndexMap<V extends string | Object> extends IndexMap<TransactionReference, V> {

  /** Get via a transaction reference. */
  remap(key: TransactionAccountReference, map: IndexMap<AccountReference, string>) {
    let _account = map.get(key.account);
    if (errorResponse(_account)) return _account;
    return this.get({ _inst: key._inst, _account, postDate: key.postDate, sequence: key.sequence, counter: key.counter });
  }

  /** Create from an existing map. */
  static from<V extends string | Object>(map: IndexMap<TransactionReference, V>): TransactionIndexMap<V> {
    Object.setPrototypeOf(map, TransactionIndexMap.prototype);
    return map as TransactionIndexMap<V>;
  }
}