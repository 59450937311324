import { Pipe } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe {

  constructor(protected sanitizer: DomSanitizer) {}
 
  public transform(value: string, _: 'Yes, I am using this pipe on a sandboxed iframe.'): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}