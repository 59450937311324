<app-card class="elevation-dialog">
  <app-card-header color="red">
    <div class="row">
      <app-icon color="white">error</app-icon>
      <h1>Error</h1>
      <app-icon></app-icon>
    </div>
  </app-card-header>
  <app-card-body class="column">
    <div>{{error}}</div>
    <div class="button-row">
      <button color="primary" [routerLink]="['/']">Return to Site</button>
    </div>
  </app-card-body>
</app-card>