<nav>
  <div #labels class="tabs">
    <app-tab-label *ngFor="let t of tabs" (click)="tab = t" (closed)="close(t)" [active]="t.active" [closeable]="t.closeable">{{t.title}}</app-tab-label>
  </div>
  <div class="buttons">
    <button [menu-trigger]="tabMenu" tooltip="Browse tabs">Tab Options <app-icon>more_vert</app-icon></button>
  </div>
</nav>
<main>
  <ng-template #content></ng-template>
</main>
<app-menu #tabMenu="appMenu">
  <app-menu-item [class.active]="t.active" *ngFor="let t of tabs" (click)="tab = t" (closed)="close(t)">
    <div class="fill">{{t.title}}</div>
    <app-icon class="menu-item-icon" icon-button (click)="close(t)" *ngIf="t.closeable">close</app-icon>
  </app-menu-item>
  <app-menu-item (click)="clear()">
    <div class="fill">Close All Tabs</div>
    <app-icon class="menu-item-icon" icon-button>close</app-icon>
  </app-menu-item>
</app-menu>