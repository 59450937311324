import { BankUnion } from "../model/bank";

/** Check if a value is a bank name. */
export function bankUnion<T>(union: BankUnion<T>): union is { bank: T, creditUnion: T } {
  return union instanceof Object && !!union.bank && !!union.creditUnion;
}

/** Resolve a bank / credit union sensitive name. */
export function bankUnionResolve<T>(union: BankUnion<T>, bank: boolean): T
export function bankUnionResolve<T>(union: BankUnion<T> | undefined, bank: boolean): T | undefined
export function bankUnionResolve<T>(union: BankUnion<T> | undefined, bank: boolean, fallback: T): T
export function bankUnionResolve<T>(union: BankUnion<T> | undefined, bank: boolean, fallback?: T): T | undefined {
  if (bankUnion(union)) return bank ? union.bank : union.creditUnion;
  return union ?? fallback;
}