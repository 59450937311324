import { Component, Inject } from '@angular/core';
import { DisplayChip } from '../../../toolbox/display';
import { STATUS_LEVEL_ICON } from '../../../toolbox/status';
import { GRID_ROW } from '../../model/grid/model-grid.component';

@Component({
  selector: 'app-event-list-icon',
  templateUrl: './event-list-icon.component.html',
  styleUrls: ['./event-list-icon.component.scss']
})
export class EventListIconComponent {
  readonly STATUS_LEVEL_ICON = STATUS_LEVEL_ICON;
  
  constructor(
    @Inject(GRID_ROW) protected row: DisplayChip
  ) {}
}
