import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ExecutiveModule } from '../executive/executive.module';
import { NotificationModule } from '../notification/notification.module';
import { WorkModule } from '../work/work.module';
import { ClaimChartComponent } from './chart/claim-chart.component';
import { ClaimComponent } from './claim.component';
import { ClaimDisputesComponent } from './disputes/claim-disputes.component';
import { ClaimEventsComponent } from './events/claim-events.component';
import { ClaimInfoComponent } from './info/claim-info.component';
import { ClaimIntakeAccountsComponent } from './intake/accounts/claim-intake-accounts.component';
import { ClaimIntakeComponent } from './intake/claim-intake.component';
import { ClaimIntakeDetailsComponent } from './intake/details/claim-intake-details.component';
import { ClaimIntakeMembersComponent } from './intake/members/claim-intake-members.component';
import { ClaimIntakeSummaryComponent } from './intake/summary/claim-intake-summary.component';
import { ClaimIntakeTransactionsComponent } from './intake/transactions/claim-intake-transactions.component';
import { ClaimListComponent } from './list/claim-list.component';
import { ClaimNotificationsComponent } from './notifications/claim-notifications.component';
import { ClaimPanelComponent } from './panel/claim-panel.component';
import { ClaimPanelListComponent } from './panel/list/claim-panel-list.component';
import { ClaimReportComponent } from './report/claim-report.component';
import { ClaimResultsComponent } from './results/claim-results.component';
import { ClaimSearchComponent } from './search/claim-search.component';
import { ClaimToolbarComponent } from './toolbar/claim-toolbar.component';

@NgModule({
  declarations: [
    ClaimChartComponent,
    ClaimComponent,
    ClaimDisputesComponent,
    ClaimEventsComponent,
    ClaimInfoComponent,
    ClaimIntakeAccountsComponent,
    ClaimIntakeComponent,
    ClaimIntakeDetailsComponent,
    ClaimIntakeMembersComponent,
    ClaimIntakeSummaryComponent,
    ClaimIntakeTransactionsComponent,
    ClaimListComponent,
    ClaimNotificationsComponent,
    ClaimPanelComponent,
    ClaimPanelListComponent,
    ClaimReportComponent,
    ClaimResultsComponent,
    ClaimSearchComponent,
    ClaimToolbarComponent
  ],
  exports: [
    ClaimListComponent,
    ClaimChartComponent,
  ],
  imports: [
    ExecutiveModule,
    NotificationModule,
    SharedModule,
    WorkModule
  ]
})
export class ClaimModule { }
