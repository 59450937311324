<div class="row">
  <b class="title">{{_claim.type | codeType:auth._inst:DisputesCode.ClaimType | async}} Claim ({{_claim.displayId}})</b>
  <span class="title">for</span>
  <a class="title" (click)="onMember()">{{_claim.member | memberName}}</a>
  <app-icon button-icon *ngIf="Permission.ClaimsDelete | permission" [tooltip]="_claim.deleted ? 'Restore Claim' : 'Abandon Claim'" (click)="onDelete()">delete</app-icon>
</div>
<div class="fill"></div>
<app-form class="fill" [config]="config" (changed)="changed.emit()"></app-form>
<button icon-button tooltip="Refresh" (click)="refreshed.emit()">refresh</button>
<app-comment-list-button collection="claims" tooltip="Comments" header="Account Comments" [value]="_claim"></app-comment-list-button>
<app-claim-notifications tooltip="Notifications" [claim]="_claim"></app-claim-notifications>
<button [disabled]="undoIndex < 0" icon-button tooltip="Undo" (click)="undo.emit()">undo</button>