import { Component } from '@angular/core';
import { TerminalResource } from "../../../../../../../../common/model/formula/terminal";
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-resource',
  templateUrl: './setup-formula-resource.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-resource.component.scss']
})
export class SetupFormulaResourceComponent extends BlockComponent<TerminalResource> {}
