<apx-chart
  [series]="apexSeries"
  [chart]="apexChart"
  [plotOptions]="apexPlotOptions"
  [dataLabels]="apexDataLabels"
  [yaxis]="apexYAxis"
  [legend]="apexLegend"
  [fill]="apexFill"
  [stroke]="apexStroke"
  [xaxis]="apexXAxis">
</apx-chart>