import { TypeValidator } from "../info/type";
import { validatorValue } from "../toolbox/validate";
import { ValidationOptions, ValidationStatus, Validator, ValidatorContext, ValidatorLog } from "./base";
import { ObjectValidator } from "./object";

/** Validate a mapped record type. */
export class RecordValidator<T, K extends number | string> extends ObjectValidator<Record<K, T>> {
  /** Validator for property. */
  private validator: Validator<T>;

  constructor(value: TypeValidator<T>) {
    super({} as any);
    this.validator = validatorValue(value, new ValidatorContext()) as Validator<T>;
  }

  override value() {
    return {} as Record<K, T>;
  }

  override schema() {
    return {
      bsonType: 'object',
      properties: {},
      additionalProperties: this.validator.schema()
    } as any;
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    this.list = [];
    
    if (!(value instanceof Object)) {
      this.list.push(new ValidatorLog('Expected object.'));
      return ValidationStatus.Error;
    }

    let status = ValidationStatus.Okay;
    for (let key in value) {
      let substatus = this.validator.validate(value[key], options);
      status = Math.max(status, substatus);

      if (substatus) for (let log of this.validator.logs()) {
        log.unshift(`.${String(key)}`);
        this.list.push(log);
      }
    }

    return status;
  }
}