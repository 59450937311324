import { Component, Input } from '@angular/core';
import { ColorCode } from '../../../../../../common/toolbox/color';

@Component({
  selector: 'app-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.scss'],
  host: {
    '[attr.color]': 'color'
  }
})
export class ActionPanelComponent {
  /** Icon to display. */
  @Input() icon = '';
  /** Color override for chip */
  @Input() color = ColorCode.Primary;
}
