<app-card class="elevation-dialog">
  <app-card-header>Password Reset</app-card-header>
  <app-card-body>
    <form [formGroup]="builder" (ngSubmit)="submit()">
      <app-field>
        <app-label>Email</app-label>
        <app-sublabel>
          <app-hover-help [tooltip]="EMAIL_HELP" tooltip-position="right"></app-hover-help>
        </app-sublabel>
        <app-string formControlName="email"></app-string>
      </app-field>
      <app-field>
        <app-label>Old Password</app-label>
        <app-sublabel>
          <app-hover-help [tooltip]="PASSWORD_HELP" tooltip-position="right"></app-hover-help>
        </app-sublabel>
        <app-string type="password" formControlName="old"></app-string>
      </app-field>
      <app-field>
        <app-label>New Password</app-label>
        <app-sublabel>
          <app-hover-help [tooltip]="PASSWORD_HELP" tooltip-position="right"></app-hover-help>
        </app-sublabel>
        <app-string type="password" formControlName="password"></app-string>
      </app-field>
      <app-field>
        <app-label>Confirm Password</app-label>
        <app-string type="password" formControlName="confirm"></app-string>
      </app-field>
      <div class="button-row" style="margin-top: 1rem">
        <button type="submit" color="primary" [disabled]="!builder.valid">Reset Password</button>
      </div>
    </form>
  </app-card-body>
</app-card>