<form [formGroup]="builder" class="row" (ngSubmit)="onSearch()">
  <app-field class="fill">
    <app-label>Name</app-label>
    <button icon-button type="submit">search</button>
    <input placeholder="Search" formControlName="name">
  </app-field>
  <div class="fill"></div>
  <app-field class="filter">
    <app-label>Limit</app-label>
    <app-select formControlName="limit">
      <ng-container *ngFor="let l of limits">
        <app-option [view]="l" [value]="l"></app-option>
      </ng-container>
    </app-select>
  </app-field>
  <app-field *ngIf="showTypes" class="fill">
    <app-label>Types</app-label>
    <app-select multiple formControlName="types">
      <ng-container *ngFor="let t of available">
        <app-option [view]="t | enum:DOCUMENT_TEMPLATE_TYPE_NAME" [value]="t"></app-option>
      </ng-container>
    </app-select>
  </app-field>
  <button icon-button [tooltip]="grid ? 'Grid View' : 'List View'" (click)="grid = !grid">{{grid ? 'grid_view' : 'list'}}</button>
</form>
<div class="list" [class.grid]="grid">
  <button *ngFor="let t of templates" crystal (click)="template.next(t)" [tooltip]="t.description ?? t.name">
    <app-icon>{{t.name | mimeCategory | icon:MIME_CATEGORY_ICON}}</app-icon>
    <div class="name">{{t.name}}</div>
  </button>
</div>