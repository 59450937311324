import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Attachment, AttachmentLike, AttachmentPreview } from "../../../../../common/model/attachment";
import { RouteMethod } from "../../../../../common/toolbox/api";
import { ArraySome } from "../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { StatusLevel } from "../../../../../common/toolbox/status";
import { DialogService } from "../component/dialog/dialog.service";
import { AttachmentInfo } from "../toolbox/attachment";
import { CachePreviewService } from "../toolbox/cache-preview-service";
import { fileDownload } from "../toolbox/file";
import { getRequest, rawRequest } from "../toolbox/request";
import { LogService } from "./log.service";
import { TabService } from "./tab.service";

/** A query to fetch a specific attachment. */
export class AttachmentQuery {
  constructor(
    /** ID of attachment. */
    public _id = ID_DEFAULT,
    /** Institution of attachment. */
    public _inst = ID_DEFAULT
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends CachePreviewService<Attachment, AttachmentQuery, AttachmentPreview> {
  readonly route = 'attachments/preview';
  readonly Type = Attachment;

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    public tabs: TabService
  ) {
    super(AttachmentQuery, log, dialog, http);
  }

  /** Download specified attachments. */
  download(...attachments: AttachmentLike[]) {
    return Promise.all(attachments.map(async attachment => {
      // Normalize attachment information.
      let info = await AttachmentInfo.from(attachment);
      if (!info) return;

      // Download real URL or base64.
      let response = await rawRequest<Blob>(this.http, RouteMethod.Get, info.data, undefined, { withCredentials: true, responseType: 'blob' });
      if (errorResponse(response)) {
        this.log.show(`Failed to download file: ${info.name}`, StatusLevel.Alert);
        return;
      }

      // Download attachment.
      fileDownload(response, info.name);
    }));
  }

  protected override multiple(queries: ArraySome<AttachmentQuery>) {
    return getRequest(this.http, 'attachments', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}