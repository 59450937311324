import { ErrorResponse } from "../message/error";
import { DirectoryTree } from "../model/directory";
import { htmlSanitizeInner, htmlSanitizeLink } from "./html";

/** Whitelisted tags for markdown nodes. */
export type MarkdownTag = 'a' | 'b' | 'body' | 'code' | 'details' | `h${number}` | 'i' | 'li' | 'ol' | 'p' | 'summary' | 'ul';
/** Whitelisted attributes for markdown nodes. */
export type MarkdownAttribute = 'class' | 'href' | 'id';

/** Pattern to match a markdown header node. */
const HEADER_REGEX = /^h(\d)$/;

/** A node in the markdown tree. */
export class MarkdownNode extends Array<string | MarkdownNode> {

  /** Indentation of node. */
  indent = 0;

  constructor(
    /** Type of node. */
    public type: MarkdownTag = 'p',
    /** Inner value of node. */
    inner?: string | MarkdownNode,
    /** Attributes to apply to node. */
    public attributes: { [Key in MarkdownAttribute]?: string } = {}
  ) {
    super();
    if (inner) this.push(inner);
  }

  /** Create unique section ID from title. */
  static id(title: string) {
    return title.replace(/[^a-zA-Z0-9 ]g/, '').toLowerCase().replace(/ /g, '-');
  }

  /** Convert node to HTML. */
  override toString() {
    let attributes = Object.entries(this.attributes).map(([key, value]) => `${key}="${value}"`).join(' ');
    let children = this.map(c => `${c}`).join('');
    let open = attributes ? `${this.type} ${attributes}` : this.type;
    return `<${open}>${children}</${this.type}>`;
  }

  /** Add new node to children. */
  add(node: string): string
  add(node: MarkdownNode): MarkdownNode
  add(node: string | MarkdownNode): string | MarkdownNode
  add(node: any) {
    if (typeof node === 'string' && !node.length) return node;
    let i = this.push(node);
    return this[i - 1]!;
  }

  /** Add a list of nodes. */
  addAll(nodes: (string | MarkdownNode)[]) {
    this.push(...nodes);
    return this;
  }

  /** Get inner text of node. */
  plaintext(): string {
    return this.map(c => typeof c === 'string' ? c : c.plaintext()).join('');
  }

  /** Get given child as node. */
  node(i: number) {
    let node = this[i];
    return typeof node === 'object' ? node : new MarkdownNode();
  }

  /** Shrink all headers down one level. */
  shrink(id: string) {
    for (let node of this) {
      if (typeof node === 'string') continue;

      let match = node.type.match(HEADER_REGEX);
      if (!match) continue;

      node.type = `h${+match[1]! + 1}`;
      node.shrink(id);
    }
  }
}

/** A section in a markdown document. */
export class MarkdownSection extends Array<MarkdownSection> {
  constructor(
    /** Header node of this section. */
    public header: MarkdownNode,
    /** Display name of this section. */
    public title = '',
    /** ID of this section. */
    public id = '',
    /** ID of parent section. */
    public parent?: MarkdownSection,
    /** Level of this section. */
    public level = 0
  ) {
    super();
  }

  /** Get node as HTML. */
  override toString() {
    return `${this.node()}`;
  }

  /** Get node of section. */
  node(): MarkdownNode {
    if (this.parent) {
      let li = new MarkdownNode('li');
      let id = this.header.attributes.id = `${this.path()}`;
      let a = new MarkdownNode('a', this.title, { href: `#${id}` });

      if (this.length) {
        // Nested category in table of contents.
        let details = li.add(new MarkdownNode('details', new MarkdownNode('summary', a)));
        details.add(new MarkdownNode('ol')).addAll(this.map(section => section.node()));
      } else {
        // Leaf node in table of contents.
        li.add(a);
      }
      
      return li;
    }

    // Top-level table of contents.
    return new MarkdownNode('ol').addAll(this.map(section => section.node()));
  }

  /** Get contents as a nested tree. */
  tree(): DirectoryTree {
    let tree: DirectoryTree = {};
    this.treeDeep(tree);

    let subtree = tree[this.title];
    return typeof subtree === 'object' ? subtree : {};
  }

  /** Get fully-qualified ID of this section. */
  path() {
    return this.pathDeep([]);
  }

  /** Recursively get contents as a tree. */
  private treeDeep(parent: DirectoryTree) {
    if (!this.length) {
      parent[this.title] = 0;
      return;
    }

    let tree = parent[this.title] = {};
    for (let section of this) {
      section.treeDeep(tree);
    }
  }

  /** Recursively get fully-qualified ID of section. */
  private pathDeep(list: string[]): string {
    if (!this.parent) {
      // Hit table of contents.
      return list.join('.');
    }

    list.unshift(this.id);
    return this.parent.pathDeep(list);
  }
}

/**
 *  A DOM tree for a parsed markdown file.
 *  Only a small subset of full markdown syntax is supported.
 */
export class MarkdownDocument extends MarkdownNode {

  /** Table of contents for generated document. */
  contents = new MarkdownSection(this);
  
  /** Errors of generated document. */
  private errors: string[] = [];
  /** Current parsing position. */
  private i = 0;

  /** Merge one or more documents into one. */
  static merge(documents: MarkdownDocument[], title: string, indent = true): MarkdownDocument {
    let merged = new MarkdownDocument('', title);

    for (let document of documents) {
      document.parent(merged, indent);
    }

    return merged;
  }

  constructor(
    /** Text to parse. */
    public text = '',
    /** Title of document. */
    public title = ''
  ) {
    super('body');
    this.contents.title = title;
    this.contents.id = MarkdownNode.id(title);
    this.text = text.replace(/\r\n/g, '\n');
    this.document(this);
  }

  /** Set document as a child of given parent. */
  parent(document: MarkdownDocument, indent: boolean) {
    // Add new title header to front of document.
    let id = MarkdownNode.id(document.title);
    this.unshift(this.contents.header = new MarkdownNode('h1', this.title));

    // Indent all headers one level.
    if (indent) super.shrink(id);
    
    // Add my contents to parent's contents.
    this.contents.parent = document.contents;
    document.contents.push(this.contents);
    document.push(...this);
  }

  /** Get errors of built document. */
  error(name?: string) {
    if (!this.errors.length) return undefined;

    let title = name
      ? `${name}.md: there was an error building document.`
      : 'There was an error building document.';
    return ErrorResponse.list(title, this.errors);
  }

  /** Get inner text of document as string. */
  override toString() {
    let out: string[] = [];
    for (let node of this) out.push(`${node}`);
    return out.join('');
  }

  /** Parse each top-level line of document. */
  private document(parent: MarkdownNode) {
    let indent = 0;
    let clear = false;
    let ordered = 0;

    while (true) switch (this.text[this.i]) {
    case ' ':
      ++indent;
      ++this.i;
      break;
    case '\t':
      indent += 2;
      ++this.i;
      break;
    case '#':
      let level = 1;
      while (this.text[++this.i] === '#') ++level;
      let header = parent.add(new MarkdownNode(`h${level}`));
      this.inner(header);

      // Determine unique ID for this section.
      let plaintext = header.plaintext();
      let id = MarkdownNode.id(plaintext);
      let section = this.section(this.contents, level) ?? this.contents;

      // Append new section as subsection.
      if (section.find(s => s.id === id)) {
        this.errors.push(`Title "${plaintext}" was resolved to duplicate section name "${id}". Please create a unique title for this header to enable section linking.`);
      } else {
        section.push(new MarkdownSection(header, plaintext, id, section, level));
      } break;
    // @ts-ignore
    case '1': case '2': case '3': case '4': case '5': case '6': case '7': case '8': case '9':
      ordered = this.orderedValid();
    case '+': case '-': case '*':
      if (this.text[this.i] === '*' && this.peek(1) === '*') {
        // Treat two ** at start of line as bold.
        this.inner(parent.add(new MarkdownNode('p')));
        break;
      }

      let list = clear ? undefined : this.list(parent, indent);
      if (!list) {
        // Start a new list.
        let ul = parent.add(new MarkdownNode(ordered ? 'ol' : 'ul'));
        ul.indent = indent;
        this.i += ordered || 1;
        this.inner(ul.add(new MarkdownNode('li')));
      } else if (indent == list.indent) {
        this.i += ordered || 1;
        this.inner(list.add(new MarkdownNode('li')));
      } else if (indent > list.indent) {
        // Add new sublist element to list.
        let li = list.add(new MarkdownNode('li'));
        let ul = li.add(new MarkdownNode(ordered ? 'ol' : 'ul'));
        ul.indent = indent;
        this.i += ordered || 1;
        this.inner(ul.add(new MarkdownNode('li')));
      }
      
      clear = false;
      ordered = 0;
      indent = 0;
      break;
    case '`':
      // Parse standalone code.
      let block = this.peek(1) === '`' && this.peek(2) === '`';
      if (block) this.code(parent.add(new MarkdownNode('code', undefined, { class: 'block' })), '```');
      else this.code(parent.add(new MarkdownNode('code')), '`');
      break;
    case '\n':
      clear = true;
      ++this.i;
      break;
    case undefined:
      return;
    default:
      this.inner(parent.add(new MarkdownNode('p')));
      break;
    }
  }

  /** Consume text between two angle brackets. */
  private inner(parent: MarkdownNode, end?: string) {
    this.skip();
    let start = this.i;
    while (true) switch (this.text[this.i]) {
    case '*':
      let bold = this.peek(1) === '*';
      if (bold && end === '**') {
        // End bold.
        parent.add(this.span(start));
        this.i += 2;
        return;
      } else if (end === '*') {
        // End italics.
        parent.add(this.span(start));
        ++this.i;
        return;
      } else if (bold) {
        // Start bold.
        parent.add(this.span(start));
        this.i += 2;
        this.inner(parent.add(new MarkdownNode('b')), '**');
        start = this.i;
        break;
      } else {
        // Start italics.
        parent.add(this.span(start));
        ++this.i;
        this.inner(parent.add(new MarkdownNode('i')), '*');
        start = this.i;
      } break;
    case '[':
      if (this.anchorValid()) {
        // Start anchor.
        parent.add(this.span(start));
        ++this.i;
        parent.add(this.anchor());
        start = this.i;
        break;
      }
      
      ++this.i;
      break;
    case '`':
      parent.add(this.span(start));
      if (this.peek(1) === '`' && this.peek(2) === '`') {
        this.code(parent.add(new MarkdownNode('code', undefined, { class: 'block' })), '```');
      } else {
        this.code(parent.add(new MarkdownNode('code')), '`');
      }
      start = this.i;
      break;
    case '\n':
      // End current parent node.
      parent.add(this.span(start));
      ++this.i;
      return;
    case undefined:
      parent.add(this.span(start));
      return;
    default:
      ++this.i;
      break;
    }
  }

  /** Consume text until endpoint. */
  private code(parent: MarkdownNode, end: string) {
    // Continue until end marker.
    let start = this.i += end.length;
    while (!this.equals(end) && this.text[this.i] !== undefined) ++this.i;

    // End code.
    parent.add(this.span(start).trim());
    this.i += end.length;
  }

  /** Check if ordered list header at position. */
  private orderedValid() {
    let start = this.i;
    let i = this.i;

    list:
    while (true) switch (this.text[i]) {
    case '0': case '1': case '2': case '3': case '4': case '5': case '6': case '7': case '8': case '9': 
      ++i;
      break;
    case '.':
      ++i;
      break list;
    default:
      return 0;
    }

    return i - start;
  }

  /** Lookahead if token at position is valid anchor. */
  private anchorValid() {
    let i = this.i;

    // Check if link terminated.
    brackets:
    while (true) switch (this.text[i]) {
    case ']':
      break brackets;
    case '\n':
    case undefined:
      return false;
    default:
      ++i;
      break;
    }

    // Check if label immediately after brackets.
    if (this.text[++i] !== '(') return false;

    while (true) switch (this.text[i]) {
    case ')':
      return true;
    case '\n':
    case undefined:
      return false;
    default:
      ++i;
      break;
    }
  }

  /** Consume an anchor. */
  private anchor() {
    let href = '';
    let inner = '';
    let start = this.i;

    link:
    while (true) switch (this.text[this.i]) {
    case ']':
      inner = this.span(start);
      break link;
    case undefined:
      this.errors.push('Unexpected end of input while parsing link label.');
      return new MarkdownNode('a');
    default:
      ++this.i;
      break;
    }

    start = this.i += 2;
    while (true) switch (this.text[this.i]) {
    case ')':
      href = this.span(start);
      ++this.i;
      let a = new MarkdownNode('a', inner, { href: htmlSanitizeLink(href) });
      return a;
    case undefined:
      this.errors.push('Unexpected end of input while parsing link destination.');
      return new MarkdownNode('a');
    default:
      ++this.i;
      break;
    }
  }

  /** Skip ahead past whitespace. */
  private skip() {
    while (this.text[this.i] === ' ') ++this.i;
  }

  /** Get text at offset. */
  private peek(offset: number) {
    return this.text[this.i + offset];
  }

  /** Check if text at current position matches value. */
  private equals(value: string) {
    for (let i = 0; i < value.length; ++i) {
      if (value[i] !== this.text[this.i + i]) return false;
    } return true;
  }

  /** Extract selected range of inner text and sanitize. */
  private span(start: number) {
    return htmlSanitizeInner(this.text.slice(start, this.i));
  }

  /** Scan backwards through sections, finding appropriate level to append to. */
  private section(section: MarkdownSection, level: number): MarkdownSection | undefined {

    for (let i = section.length - 1; i >= 0; --i) {
      let child = section[i]!;

      // See if nested section is a match.
      let subsection = this.section(child, level);
      if (subsection) return subsection;
    }

    return level > section.level ? section : undefined;
  }

  /** Iterate backwards through nodes, finding appropriate list to attach to. */
  private list(node: MarkdownNode, indent: number): MarkdownNode | undefined {
    let child = node[node.length - 1];
    if (typeof child !== 'object') return undefined;

    // See if nested list is a match.
    let list = this.list(child, indent);
    if (list) return list;

    if (child.type !== 'ul' && child.type !== 'ol') return undefined;
    return indent >= child.indent ? child : undefined;
  }
}