import { Pipe } from '@angular/core';

@Pipe({
  name: 'string'
})
export class StringPipe {

  transform(value: any) {
    return String(value);
  }
}
