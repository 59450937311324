import { Overlay } from '@angular/cdk/overlay';
import { Component, ElementRef, Input, NgZone, ViewContainerRef } from '@angular/core';
import { CodeType } from '../../../../../../common/model/code-type';
import { AuthService } from '../../service/auth.service';
import { CodeTypeService } from '../../service/code-type.service';
import { fieldControlProviders } from '../../toolbox/angular';
import { setupElementMake } from '../../toolbox/element/setup';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'app-code',
  templateUrl: '../select/select.component.html',
  styleUrls: ['../select/select.component.scss'],
  providers: fieldControlProviders(CodeComponent)
})
export class CodeComponent extends SelectComponent<string> {

  /** Category to pull code types from. */
  @Input() set category(category: string | undefined) { this._category = category; this.refresh(); }

  /** Filter for allowed codes. */
  @Input() set types(types: string[] | undefined) {
    this._types = types;
    this.refresh();
  }

  /** True to show select in compact mode. */
  @Input() compact = false;
  /** Force sort of codes on display. */
  @Input() sort?: boolean;
  /** Prefix to display before codes. */
  @Input() prefix?: [string, string];
  /** Suffix to display after codes. */
  @Input() suffix?: [string, string];

  /** List of selectable values. */
  private _types?: string[];
  /** Current code category. */
  private _category: string | undefined;

  constructor(
    zone: NgZone,
    overlay: Overlay,
    containerRef: ViewContainerRef,
    elementRef: ElementRef<HTMLElement>,
    public service: CodeTypeService,
    private auth: AuthService
  ) {
    super(zone, overlay, containerRef, elementRef);
  }

  /** Refresh list of types after changing institution or table. */
  private async refresh() {
    // Fetch new list of codes.
    let list = this._category ? await this.service.item({ _inst: this.auth._inst, category: this._category }) : new CodeType();
    let codes = CodeType.list(list);
    setupElementMake(this.elementRef.nativeElement, 'codeTypes', list._id);

    // Refresh dropdown list.
    let set = new Set(this._types ?? codes.map(c => c.key));
    this.items = CodeType.pairs(codes.filter(c => set.has(c.key)), this.sort, this.prefix, this.suffix);

    // Find options in new list.
    let value = [...this.set].filter(v => set.has(v));
    if (this.required && !value.length) value = this.pairs[0] ? [this.pairs[0].value] : [];
    this.writeValue(value);
  }
}
