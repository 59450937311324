import { DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Subject } from "rxjs";
import { ColumnSort, GridExtractCallback, GridFilter } from "../grid";
import { PAGINATOR_DEFAULT } from "../paginator";
import { PaginateRequest } from "../../../../../../common/message/paginate";
import { objectType } from "../../../../../../common/toolbox/object";
import { ISO_TIMESTAMP_REGEX, MILLISECONDS_MAX } from "../../../../../../common/toolbox/time";
import { keyNestedGet } from "../../../../../../common/toolbox/keys";

/** Generic interface of all grid sources. */
export abstract class BaseSource<A extends boolean, T = any> implements DataSource<T> {

  /** True if this is an asynchrous source and allows refreshing. */
  abstract readonly asynchronous: A;

  /** Unfiltered list of items. */
  get items() { return this.data; }
  set items(data: T[]) {
    this.availableData = data;
    this.data = data;
  }

  /** Filtered list of items. */
  get filteredItems() { return this.data; }

  /** True if all available items are selected. */
  get all() { return !!this.items.length && this.selection.value.size === this.items.length; }
  set all(all: boolean) { if (this.all !== all) this.toggleAll(); }

  /** Current page to display. */
  page = 1;
  /** Total number of pages. */
  pages = 1;
  /** Start range of displayed items. */
  start = 0;
  /** end range of displayed items. */
  end = 0;
  /** Number of items to display on each page. */
  limit = PAGINATOR_DEFAULT;
  /** Total number of available items. */
  available = 0;
  /** True if previous button is enabled. */
  prev = false;
  /** True if next button is enabled. */
  next = false;
  /** Last passed sort direction and column. */
  sort?: ColumnSort<T>;

  /** Emits when selection changes. */
  selection = new BehaviorSubject(new Set<T>());
  /** Emits when viewed data changes. */
  dataChange = new BehaviorSubject<T[]>([]);
  /** Emits when requesting new data. */
  dataRequest = new Subject<PaginateRequest<T>>();
  /** Callback for accessing a column from a row. */
  extract: GridExtractCallback<T> = keyNestedGet;

  /** List of items actually being rendered. */
  protected data: T[] = [];
  /** List of all items potentially available. */
  protected availableData: T[] = [];
  /** Last passed filter. */
  protected filter?: GridFilter<T>[];

  constructor(items?: T[], available = 0) {
    this.available = available;
    if (!items) return;
    this.items = items;
    this.repage();
  }

  /** Called when connecting to data source. */
  connect() { return this.dataChange; }
  /** Called when disconnecting from data source. */
  disconnect() {}
  /** Skip to first page. */
  first() { this.repage(0); }
  /** Skip to last page. */
  last() { this.repage(this.pages); }
  /** Offset current page. */
  offset(offset: number) { this.repage(this.page + offset); }

  /** Add a new row to source. */
  push(...items: T[]) {
    this.items.push(...items);
    this.items = [...this.items];
    this.refilter(this.filter);
  }

  /** Splice an item from source. */
  splice(start: number, count?: number | undefined) {
    this.items.splice(start, count);
    this.items = [...this.items];
    this.refilter(this.filter);
  }

  /** Toggle selection for given item. */
  toggle(...values: T[]) {
    if (!values[0]) return;

    for (let value of values) {
      if (this.selection.value.has(value)) {
        this.selection.value.delete(value);
      } else {
        this.selection.value.add(value);
      }
    }

    this.selection.next(this.selection.value);
  }

  /** Toggle all items on or off. */
  toggleAll() {
    let all = this.all;
    this.toggle(...this.items.filter(i => {
      let selected = this.selection.value.has(i);
      return all ? selected : !selected;
    }));
  }

  /** Filter items using specified query. */
  abstract refilter(filter?: GridFilter<T>[]): void;

  /** Resort data after filtering. */
  abstract resort(sort?: ColumnSort<T>): void;

  /** Manually refresh data of source. */
  refresh() {
    this.dataRequest.next({
      skip: this.start,
      limit: this.limit,
      sort: this.sort?.column,
      direction: this.sort?.direction
    });
  }

  /** Set number of items to display per page. */
  relimit(limit: number) {
    this.limit = limit;
    this.repage(this.page);
  }

  /** Set current page. */
  repage(page?: number): void {
    // Check if next and previous buttons should be enabled.
    this.pages = Math.ceil(this.available / this.limit);
    this.page = Math.max(1, Math.min(page ?? this.page, this.pages));
    this.prev = this.page > 1;
    this.next = this.page < this.pages;
    this.start = Math.min(this.available, (this.page - 1) * this.limit);
    this.end = Math.min(this.available - 1, this.start + this.limit - 1);
  }


  /** Get sort callback for current set of data. */
  sortCallback(sort: ColumnSort<T>): Function | undefined {
    let c = sort.column;
    let e = this.extract;

    for (let row of this.data) {
      if (sort.direction === 1) {
        switch (objectType(e(c, row))) {
          case 'boolean': return (a: any, b: any) => e(c, a) - e(c, b);
          case 'bigint': return (a: any, b: any) => Number(e(c, a)) - Number(e(c, b));
          case 'number': return (a: any, b: any) => (e(c, a) || 0) - (e(c, b) || 0);
          case 'string': return this.sortStringAscending(sort, e(c, row) as any, e);
          case 'symbol': return (a: any, b: any) => String(e(c, a)).localeCompare(String(e(c, b)));
          case 'date': return (a: any, b: any) => (e(c, a)?.getTime() || MILLISECONDS_MAX) - (e(c, b)?.getTime() || MILLISECONDS_MAX);
          case 'array': return (a: any, b: any) => e(c, a).length - e(c, b).length;
        }
      } else if (sort.direction) {
        switch (objectType(e(c, row))) {
          case 'boolean': return (b: any, a: any) => +e(c, a) - +e(c, b);
          case 'bigint': return (b: any, a: any) => Number(e(c, a)) - Number(e(c, b));
          case 'number': return (b: any, a: any) => (e(c, a) || 0) - (e(c, b) || 0);
          case 'string': return this.sortStringDescending(sort, e(c, row) as any, e);
          case 'symbol': return (b: any, a: any) => String(e(c, a)).localeCompare(String(e(c, b)));
          case 'date': return (b: any, a: any) => (e(c, a)?.getTime() || MILLISECONDS_MAX) - (e(c, b)?.getTime() || MILLISECONDS_MAX);
          case 'array': return (b: any, a: any) => e(c, a).length - e(c, b).length;
        }
      }
    }

    return undefined;
  }

  /** Get specialized ascending sort callback for strings. */
  sortStringAscending(sort: ColumnSort<T>, value: string, accessor: Function): Function {
    let c = sort.column;
    let g = accessor;

    if (ISO_TIMESTAMP_REGEX.test(value)) {
      return (a: any, b: any) => new Date(g(c, a)).getTime() - +new Date(g(c, b)).getTime();
    } else if (value.length && !isNaN(+value)) {
      return (a: any, b: any) => +g(c, a) - +g(c, b);
    } else {
      return (a: any, b: any) => (g(c, a) ?? '').localeCompare(g(c, b) ?? '');
    }
  }

  /** Get specialized descending sort callback for strings. */
  sortStringDescending(sort: ColumnSort<T>, value: string, accessor: Function): Function {
    let c = sort.column;
    let g = accessor;

    if (ISO_TIMESTAMP_REGEX.test(value)) {
      return (b: any, a: any) => new Date(g(c, a)).getTime() - +new Date(g(c, b)).getTime();
    } else if (value.length && !isNaN(+value)) {
      return (b: any, a: any) => +g(c, a) - +g(c, b);
    } else {
      return (b: any, a: any) => (g(c, a) ?? '').localeCompare(g(c, b) ?? '');
    }
  }
}