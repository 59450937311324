import { Pipe } from '@angular/core';
import { SystemCode } from '../../../../../common/code/system';
import { CodeEnum } from '../../../../../common/model/code-type';
import { Formula } from '../../../../../common/model/formula/formula';
import { Property } from '../../../../../common/model/property';
import { NestedKey } from '../../../../../common/toolbox/keys';
import { PropertyEngine } from '../../../../../common/toolbox/property';
import { AuthService } from '../service/auth.service';
import { CodeTypeService } from '../service/code-type.service';
import { FormulaService } from '../service/formula.service';
import { MemberService } from '../service/member.service';
import { UserService } from '../service/user.service';
import { DateIsoPipe } from './date-iso.pipe';

/** Dynamically format a pproperty value. */
@Pipe({ name: 'propertyValue' })
export class PropertyValuePipe extends PropertyEngine {

  constructor(
    private authService: AuthService,
    private dateService: DateIsoPipe,
    private codeService: CodeTypeService,
    private userService: UserService,
    private memberService: MemberService,
    private formulaService: FormulaService
  ) {
    super();
  }

  async transform<T>(stripped: T, typed: T, key: NestedKey<T>, enums?: CodeEnum): Promise<string> {
    return this.value(this.authService._inst, stripped, typed, key, enums);
  }

  protected override date(value: unknown, format?: string) {
    return this.dateService.transform(value, format);
  }

  protected override member(_inst: string, _id: string): Promise<string> {
    return this.memberService.name({ _inst, _id });
  }

  protected override user(_inst: string, _id: string): Promise<string> {
    return this.userService.name(_inst, _id);
  }

  protected override formula(_inst: string, _id: string): Promise<Formula> {
    return this.formulaService.item({ _inst, _id });
  }

  protected override returns(formula: Formula): Property | undefined {
    return this.formulaService.returns(formula);
  }

  protected override code(_inst: string, category: SystemCode, value: string): Promise<string> {
    return this.codeService.codename(value, { _inst, category });
  }
}
