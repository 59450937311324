import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';
import { booleanCoerce } from '../../../../../../common/toolbox/boolean';
import { fieldControlProviders } from '../../toolbox/angular';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss'],
  providers: fieldControlProviders(BooleanComponent)
})
export class BooleanComponent extends FieldControl implements ControlValueAccessor {
  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  
  /** True to make a value required. */
  @Input() required = true;
  /** Required value for this boolean to be submitted. */
  @Input() require?: boolean;
  /** True to display indeterminate. */
  @Input() indeterminate = false;
  /** True to use radio instead of checkbox */
  @Input() radio = false;
  /** True if large input. */
  @Input() large = false;

  /** Displayed label for true. */
  @Input() on = 'Yes';
  /** Displayed label for false. */
  @Input() off = 'No';

  /** Current value. */
  value?: boolean;

  override validate(control: AbstractControl) {
    if (this.value === undefined) {
      this.reerror();
    } else {
      this.reerror(
        [`Expected ${this.require}.`, this.require !== undefined && this.value !== this.require]
      );
    }

    return super.validate(control);
  }

  writeValue(value: BooleanInput) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.value = this.coerce(value);
  }

  /** Set new value after toggling boolean. */
  protected onValue(value: boolean | undefined) {
    this.changed(this.value = this.coerce(value));
    this.setTouched();
  }

  /** Coerce boolean value. */
  private coerce(value: unknown) {
    return booleanCoerce(value, this.required);
  }
}
