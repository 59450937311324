import { TypeInfo } from "../../../info/type";
import { UnionInfo } from "../../../info/union";
import { ErrorResponse } from "../../../message/error";
import { ImportPostResponse } from "../../../message/import";
import { ChoiceValidator } from "../../../validator/choice";
import { EventCategory } from "../category";

/** Tagged type of import result. */
export enum ImportResultType {
  Update
}

/** Result payload of performing an import. */
export type ImportResult = ImportUpdateResult;

/** Tagged import result. */
export interface ImportTagResult {
  /** All import results belong to import category. */
  category: EventCategory.Import;
}

/** Result of running a full import. */
export class ImportUpdateResult implements ImportTagResult {
  readonly category = EventCategory.Import;
  readonly type = ImportResultType.Update;

  constructor(
    /** Result of performing import. */
    public result: ImportPostResponse | ErrorResponse = new ImportPostResponse()
  ) {}

  static typeinfo: TypeInfo<ImportUpdateResult> = {
    result: new ChoiceValidator(new ImportPostResponse(), new ErrorResponse())
  }
}

export class ImportResultClass {
  [ImportResultType.Update] = new ImportUpdateResult();
}

/** Union type information for import results. */
export const IMPORT_RESULT_UNIONINFO: UnionInfo<ImportResult, ImportResultType> = {
  tag: 'type',
  classes: new ImportResultClass()
}