import { Overlay } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { Icon } from '../../../../../../common/model/icon';
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { elementFind } from '../../../common/toolbox/element/base';
import { HTMLDebugElement, debugElement } from '../../../common/toolbox/element/debug';
import { HighlightButtonComponent } from '../highlight-button/highlight-button.component';

@Component({
  selector: 'app-debug-button',
  templateUrl: '../highlight-button/highlight-button.component.html',
  styleUrls: ['../highlight-button/highlight-button.component.scss']
})
export class DebugButtonComponent extends HighlightButtonComponent<HTMLDebugElement> {
  readonly icon = Icon.Code;
  readonly tooltip = 'Click to Debug';

  constructor(
    overlay: Overlay,
    private dialog: DialogService
  ) {
    super(overlay);
  }

  find(target: EventTarget | null) {
    return elementFind(target, debugElement);
  }

  trigger(element: HTMLDebugElement) {
    let value = element.debug();
    if (value === undefined) return;
    this.dialog.source(value);
  }
}
