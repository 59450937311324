import { Component } from "@angular/core";
import { WorkConditionBase } from "../../../../../../../common/model/work/condition";

/** Interface that allows a component to configure a specific work condition type. */
@Component({
  selector: 'app-workflow-condition-accessor',
  template: ''
})
export abstract class WorkConditionAccessor<T extends WorkConditionBase = WorkConditionBase> {

  /** Condition being configured. */
  condition!: T;

  /** Called upon condition being set. */
  reset() {}
}