import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ClaimModule } from '../claim/claim.module';
import { MemberAccountsComponent } from './accounts/member-accounts.component';
import { MemberComponent } from './member.component';
import { MemberResultsComponent } from './results/member-results.component';
import { MemberSearchComponent } from './search/member-search.component';
import { MemberSetupComponent } from './setup/member-setup.component';
import { MemberToolbarComponent } from './toolbar/member-toolbar.component';

@NgModule({
  declarations: [
    MemberAccountsComponent,
    MemberComponent,
    MemberResultsComponent,
    MemberSearchComponent,
    MemberSetupComponent,
    MemberToolbarComponent
  ],
  imports: [
    ClaimModule,
    SharedModule
  ]
})
export class MemberModule { }
