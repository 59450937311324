import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FilterRulePostResponse } from '../../../../../../../common/message/filter-rule';
import { DISPLAY_TYPE_NAME, DisplayType, DisplayValue } from "../../../../../../../common/model/display";
import { FilterRule } from "../../../../../../../common/model/filter-rule";
import { BlockType } from "../../../../../../../common/model/formula/block";
import { ModelPreview } from "../../../../../../../common/model/model";
import { enumValues } from '../../../../../../../common/toolbox/enum';
import { MaybeId, idMaybe } from '../../../../../../../common/toolbox/id';
import { keyNestedPairs } from "../../../../../../../common/toolbox/keys";
import { Pair } from "../../../../../../../common/toolbox/object";
import { DialogService } from '../../../../common/component/dialog/dialog.service';
import { AuthService } from '../../../../common/service/auth.service';
import { DevService } from '../../../../common/service/dev.service';
import { LogService } from '../../../../common/service/log.service';
import { ModelService } from '../../../../common/service/model.service';
import { postRequest } from '../../../../common/toolbox/request';
import { BlockDrag } from '../../formula/block.model';
import { SetupFormulaConditionsComponent } from '../../formula/conditions/setup-formula-conditions.component';
import { SetupEditComponent } from '../../setup-edit.component';

@Component({
  selector: 'app-setup-filter-rule-edit',
  templateUrl: './setup-filter-rule-edit.component.html',
  styleUrls: ['./setup-filter-rule-edit.component.scss'],
  providers: [{
    provide: 'BLOCK_PARENT',
    useValue: undefined
  }],
  host: {
    class: 'row'
  }
})
export class SetupFilterRuleEditComponent extends SetupEditComponent<FilterRule, FilterRulePostResponse> {
  readonly DISPLAY_TYPE_NAME = DISPLAY_TYPE_NAME;
  readonly DisplayType = DisplayType;
  readonly types = enumValues<DisplayType>(DisplayType);

  /** Reference to conditions. */
  @ViewChild(SetupFormulaConditionsComponent, { static: true }) container!: SetupFormulaConditionsComponent;

  /** Current filter rule being edited. */
  value = idMaybe(new FilterRule());
  /** List of keys available in current context. */
  keys: Pair[] = [];
  /** List of available models. */
  models: ModelPreview[] = [];
  /** Displayed groups in palette. */
  groups: BlockType[] = [BlockType.Condition, BlockType.Expression];

  constructor(
    elementRef: ElementRef,
    log: LogService,
    public dev: DevService,
    private auth: AuthService,
    private dialog: DialogService,
    private http: HttpClient,
    private modelService: ModelService
  ) {
    super(elementRef, log);
  }

  async ngOnInit() {
    this.models = await this.modelService.previews(this.auth._inst);
  }

  /** Callback when changing type. */
  async onType(type: DisplayType) {
    if (this.value.type === type) return;

    if (this.value.conditions.length) {
      // Show warning for deleting fields.
      if (!await this.dialog.confirm('Change type for these conditions? This will delete all current conditions.', 'Change type?')) return;
      this.value.conditions = [];
    }
    
    // Set current displayed model.
    this.value._model = type === DisplayType.Model ? this.models[0]?._id : undefined;
    this.value.type = type;
    this.relist();
  }

  /** Callback when ending drag of a chip. */
  onBlock(drag: BlockDrag) {
    this.container.drop(drag);
  }

  /** Submit current changes to filter rule. */
  push() {
    return postRequest(this.http, 'filter-rules', { items: [this.value] });
  }

  /** Reset current form with new filter rule. */
  async reset(value: MaybeId<FilterRule>) {
    this.value = value;
    this.relist();
  }

  /** Update listing for current filter rule type. */
  private relist() {
    let value = new DisplayValue()[this.value.type];
    this.keys = keyNestedPairs(value);
  }
}
