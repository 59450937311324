import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { SchemaInfo } from "../../info/schema";
import { TypeInfo } from "../../info/type";
import { blockIds } from "../../toolbox/formula/block";
import { StatementValidator } from "../../toolbox/formula/statement";
import { ID_DEFAULT } from "../../toolbox/id";
import { VersionValidator } from "../../validator/version";
import { FusionCollection } from "../fusion";
import { SCHEMA_OBJECT, schemaArray } from "../schema/base";
import { Statement } from "./statement";

/** An abstract syntax tree for calculating values. */
export class Formula {
  
  constructor(
    /** Unique identifier of formula. */
    public _id = ID_DEFAULT,
    /** Institution that created formula. */
    public _inst = ID_DEFAULT,
    /** Model this formula is associated with. */
    public _model?: string,
    /** Name of formula. */
    public name = '',
    /** True if this is a sytem formula and cannot be edited. */
    public system?: boolean,
    /** True if formula has been edited */
    public dirty?: boolean,
    /** Statements to execute. */
    public statements: Statement[] = [],
    /** Version bump to force schema updates. */
    public version?: never
  ) {}
  
  static typeinfo: TypeInfo<Formula> = {
    system: false,
    dirty: false,
    _model: ID_DEFAULT,
    statements: [new StatementValidator()],
    version: new VersionValidator(1)
  }

  // Can only validate top-level statement due to infinite recursion of formulas.
  static schemainfo: SchemaInfo<Formula> = {
    statements: schemaArray(SCHEMA_OBJECT)
  }

  static collectioninfo: CollectionInfo<FusionCollection, Formula> = {
    _inst: 'institutions',
    _model: 'models',
    statements: block => blockIds(block) as Statement[]
  }

  static indexinfo: IndexInfo<Formula> = [
    { key: { _inst: 1, _model: 1 } },
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];
}

/** A preview for a formula. */
export class FormulaPreview {
  constructor(
    /** Unique identifier of formula. */
    public _id = ID_DEFAULT,
    /** Institution that created formula. */
    public _inst = ID_DEFAULT,
    /** Name of formula. */
    public name = '',
    /** True if this is a sytem formula and cannot be edited. */
    public system?: boolean,
    /** True if formula has been edited */
    public dirty?: boolean
  ) { }
  
  static typeinfo: TypeInfo<FormulaPreview> = {
    system: false,
    dirty: false
  }
}