import { Component, ElementRef, Input, NgZone, Renderer2 } from '@angular/core';
import { arrayDefined } from '../../../../../../common/toolbox/array';
import { DurationFormat, durationFormat } from "../../../../../../common/toolbox/duration";
import { DateIsoPipe } from '../../pipe/date-iso.pipe';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent {

  /** Prefix before text. */
  @Input() prefix?: string;
  /** Postfix after text. */
  @Input() postfix?: string;
  
  /** Time to count down to. */
  @Input() date?: Date;
  /** True to force showing duration. */
  @Input() duration = false;
  /** Format to use when displaying durations. */
  @Input() durationFormat = DurationFormat.Short;
  /** Format to use when displaying times. */
  @Input() timeFormat = 'MMM d, yyyy h:mm:ss a';

  /** Reference to 1ms interval for updating clock. */
  private interval?: ReturnType<typeof setTimeout>;

  constructor(
    private zone: NgZone,
    private pipe: DateIsoPipe,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    // Get timer going for updating silly clock.
    // Manually update outside Angular loop to not tank performance.
    this.zone.runOutsideAngular(() => {
      this.interval = setInterval(() => {
        let text: string;
        if (this.duration || this.date) {
          // Display duration until date.
          text = durationFormat(this.date, this.durationFormat);
        } else {
          // Display date.
          text = this.pipe.transform(new Date(), this.timeFormat);
        }
        
        if (text === this.elementRef.nativeElement.innerText) return;
        this.renderer.setProperty(this.elementRef.nativeElement, 'textContent', arrayDefined([this.prefix, text, this.postfix]).join(''));
      }, 1);
    });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
