import { DirectoryTree } from "../model/directory";
import { DocumentationContents, DocumentationSplit } from "../model/documentation";
import { DOCUMENTATION, DocumentationKey } from "./docapi";
import { MarkdownNode } from "./markdown";
import { objectKeys, objectMerge } from "./object";

/** Build table of contents from flattened documentation list. */
export function documentationContents(): DocumentationContents {
  let contents: DocumentationContents = {};
  let keys = objectKeys(DOCUMENTATION) as DocumentationKey[];

  for (let key of keys) {
    // Get top-level category.
    let split = DocumentationSplit.from(key) as DocumentationSplit;
    let category = contents[split.category] ?? (contents[split.category] = {});

    // Add page to category.
    if (!split.page) continue;
    category[split.page] ?? (category[split.page] = DOCUMENTATION[key]);
  }

  return contents;
}

/** Get mapping of directory keys to original names. */
export function documentationKeys(tree: DirectoryTree[string]): Record<DocumentationKey, string> {
  let keys: [string, string][] = [];
  documentationKeysDeep(tree, [], keys);
  return objectMerge(keys);
}

/** Recursively map nested keys to names. */
export function documentationKeysDeep(tree: DirectoryTree[string], path: string[], keys: [string, string][]) {
  if (tree === 0) return;

  for (let [title, subtree] of Object.entries(tree)) {
    path.push(MarkdownNode.id(title));
    keys.push([path.join('.'), title]);

    if (subtree !== 0) {
      // Recurse into tree.
      documentationKeysDeep(subtree, path, keys);
    }

    path.pop();
  }
}

/** Check if given string is a documentation key. */
export function documentationKey(key: string): key is DocumentationKey {
  return !!(DOCUMENTATION as any)[key];
}