import { TypeValidator } from "../info/type";
import { ArrayIterator, arrayDefined } from "../toolbox/array";
import { objectDefined } from "../toolbox/object";
import { validatorValue } from "../toolbox/validate";
import { ValidationOptions, ValidationStatus, Validator, ValidatorContext, ValidatorLog } from "./base";
import { MultiValidator } from "./multi";

/** Validate object is an array. */
export class ArrayValidator<T> extends MultiValidator<T[]> implements ArrayLike<TypeValidator<T>> {
  /** Minimum number of items for array. */
  private minItems?: number;
  /** Maximum number of items for array. */
  private maxItems?: number;
  /** Items of array must be unique. */
  private uniqueItems?: boolean;
  /** Validator for array items. */
  private validator: Validator<T>;
  /** Value of array. */
  private array: T[];

  /** Length of pseudo-array. */
  readonly length: number;
  /** Items of pseudo-array. */
  [n: number]: TypeValidator<T>;

  constructor(value: TypeValidator<T>, minItems?: number, maxItems?: number, uniqueItems?: boolean, context = new ValidatorContext()) {
    super();
    
    // Populate array interface.
    this.length = minItems ?? 1;
    for (let i = 0; i < this.length; ++i) this[i] = value;

    this.minItems = minItems;
    this.maxItems = maxItems;
    this.uniqueItems = uniqueItems;
    this.validator = validatorValue(value, context, value, '') as Validator<T>;
    this.array = [value instanceof Validator ? value.value() : value];
  }

  [Symbol.iterator](): Iterator<T> {
    return new ArrayIterator(this);
  }

  value() {
    return this.array;
  }

  parse(value: unknown) {
    if (this.undefined(value)) return undefined;
    if (typeof value === 'string') {
      return value.length ? arrayDefined(value.split(',').map(v => this.validator.parse(v))) : [];
    } else if (Array.isArray(value)) {
      return value;
    } else {
      return [];
    }
  }

  schema() {
    return this.property ?? objectDefined({
      bsonType: 'array' as const,
      uniqueItems: this.uniqueItems,
      items: this.validator.schema(),
      minItems: this.minItems,
      maxItems: this.maxItems
    }) as any;
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    this.list = [];

    let status = super.validate(value, options);
    if (status) {
      this.list.push(...super.logs());
      return status;
    }

    if (!Array.isArray(value)) {
      this.list.push(new ValidatorLog('Expected array.'));
      return ValidationStatus.Error;
    }

    let minItems = this.minItems ?? 0;
    if (value.length < minItems) {
      this.list.push(new ValidatorLog(`Array too short. (expected ${minItems}, got ${value.length})`));
      return ValidationStatus.Error;
    }

    let maxItems = this.maxItems ?? Number.POSITIVE_INFINITY;
    if (value.length > maxItems) {
      this.list.push(new ValidatorLog(`Array too long. (expected ${maxItems}, got ${value.length})`));
      return ValidationStatus.Error;
    }

    for (let i = 0; i < value.length; ++i) {
      let substatus = this.validator.validate(value[i], options);
      status = Math.max(status, substatus);

      if (substatus) for (let log of this.validator.logs()) {
        log.unshift(`[${i}]`);
        this.list.push(log);
      }
    }

    return status;
  }
}