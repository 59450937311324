import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '../../../common/component/dialog/dialog.model';
import { SetupDiscardDialogData, SetupDiscardDialogReturn } from './setup-discard-dialog.data';

@Component({
  selector: 'app-setup-discard-dialog',
  templateUrl: './setup-discard-dialog.component.html',
  styleUrls: ['./setup-discard-dialog.component.scss'],
  host: {
    class: 'card column'
  }
})
export class SetupDiscardDialogComponent {

  /** Name of old resource. */
  protected open: string;
  /** Name of resource being opened. */
  protected opening: string;
  /** Diff of unsaved and saved value. */
  protected code?: string;

  static title() {
    return 'Discard Changes?';
  }

  constructor(
    @Inject(DIALOG_DATA)
    public data: SetupDiscardDialogData,
    public dialogRef: DialogRef<SetupDiscardDialogReturn>
  ) {
    this.open = data.open?.name ? `"${data.open.name}"` : 'an unnamed resource';
    this.opening = data.opening?.name ? `"${data.opening.name}"` : 'a new resource';
    this.code = JSON.stringify(data.diff, null, 2);
    if (this.open === this.opening) this.opening = 'this resource';
  }
}