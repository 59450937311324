import { ErrorResponse } from "../../../../../common/message/error";
import { errorPartition, errorResponse } from "../../../../../common/toolbox/message";
import { PartialDeep, deepAssign } from "../../../../../common/toolbox/object";
import { validatorValue } from "../../../../../common/toolbox/validate";
import { ValidationStatus, Validator } from "../../../../../common/validator/base";
import { keyInfoOf } from "./keyinfo";
import { ObjectStoreClass } from "./object-store";

/** Initialize new client for accessing database. */
export class Database<C extends Record<string, any> = Record<string, any>> {

  /** Private handle to database instance. */
  private db?: IDBDatabase;
  /** Cached validators for each object store. */
  private validators: { [key in keyof C]: Validator<C> };

  constructor(
    /** Name for database. */
    private name: string,
    /** Current version of database. */
    private version: number,
    /** List of values stored in database. */
    private classes: ObjectStoreClass<C>
  ) {
    let validators = {} as any;
    for (let [name, Type] of Object.entries(classes)) {
      validators[name] = validatorValue(new Type());
    }

    this.validators = validators;
  }

  /** Initialize database connection. */
  async init(): Promise<Database<C> | ErrorResponse> {
    let result = await new Promise<void | ErrorResponse | Promise<void | ErrorResponse>[]>(res => {
      let request = indexedDB.open(this.name, this.version);

      // !!! Always called, even if upgrade needed.
      request.onsuccess = (event: any) => {
        let db = this.db = event.target.result as IDBDatabase;
        if (db.version === this.version) res();
      }

      request.onerror = event => {
        res(new ErrorResponse(`Database failed to initialize: ${this.name}`, undefined, event));
      }

      // !!! addEventListener is needed here because same transaction object is always returned.
      request.onupgradeneeded = (event: any) => {
        let db = this.db = event.target.result as IDBDatabase;
        const names = Array.from({ length: db.objectStoreNames.length }, (_, i) => db.objectStoreNames.item(i));

        res(Object.entries(this.classes).map(([name, value]) => new Promise<void | ErrorResponse>(res => {
          if (names.includes(name)) return res();

          let keyPath = keyInfoOf(value);
          if (!keyPath) return res(new ErrorResponse(`Database store did not specify a key: ${name}.`));
          let store = db.createObjectStore(name, { keyPath });
          
          store.transaction.addEventListener('complete', () => {
            res();
          });

          store.transaction.addEventListener('error', event => {
            res(new ErrorResponse(`Database store failed to create: ${name}`, undefined, event));
          });
        })));
      }
    });

    if (!result) return this; // No upgrade needed.
    if (errorResponse(result)) return result; // Error initializing database.
    let [error] = errorPartition('There was an error initializing object stores.', await Promise.all(result));
    return error ?? this;
  }

  /** Find a single value in database. */
  async get<T extends keyof C>(store: T, query: IDBKeyRange | IDBValidKey): Promise<C[T] | undefined | ErrorResponse> {
    if (!this.db) return;

    let name = store as string;
    return new Promise(res => {
      let transaction = this.db!.transaction([name], 'readonly');
      let store = transaction.objectStore(name);
      let get = store.get(query);

      get.onsuccess = () => {
        res(get.result);
      }

      get.onerror = event => {
        res(new ErrorResponse(`There was an error getting item "${query}" from object store: ${name}`, undefined, event));
      }
    });
  }

  /** Overwrite an existing value in database. */
  async put<T extends keyof C>(store: T, value: C[T]): Promise<void | ErrorResponse> {
    if (!this.db) return;
    let name = store as string;
    let validator = this.validators[store];
    if (!validator) return new ErrorResponse(`Could not find validator for object store: ${name}`);

    let status = validator.validate(value);
    if (status === ValidationStatus.Error) {
      return validator.error(value);
    }
    
    return new Promise(res => {
      let transaction = this.db!.transaction([name], 'readwrite');
      let store = transaction.objectStore(name);
      let put = store.put(value);

      put.onsuccess = (event: any) => {
        res(event.target.result.value);
      }

      put.onerror = event => {
        res(new ErrorResponse(`There was adding item to object store: ${name}.`, value, event));
      }
    });
  }

  /** Peform a partial update of value in database. */
  async partial<T extends keyof C>(store: T, value: PartialDeep<C[T]>, initial: C[T]): Promise<void | ErrorResponse> {
    // Get key path for this value.
    let name = store as string;
    let keyPath = keyInfoOf(this.classes[store]);
    if (!keyPath) return new ErrorResponse(`Could not find key for object store: ${name}`);

    // Extract key via key path.
    let key = initial[keyPath];
    if (!key) return new ErrorResponse(`Could not extract key for object store: ${name}`, undefined, initial)

    let existing = await this.get(store, key);
    if (errorResponse(existing)) return existing;
    existing = existing ?? initial;

    deepAssign(existing, value);
    return this.put(store, existing as any);
  }
}