<div  *ngFor="let pos of area"
      class="cell"
      tooltip="{{pos.x}} x {{pos.y}}"
      tooltip-position="top"
      (mouseenter)="move(pos)"
      (click)="submit(pos)">
  <div  class="inner"
        [class.preview]="pos.x <= preview.x && pos.y <= preview.y"
        [class.invalid]="(pos.x > hover.x || pos.y > hover.y) && pos.x <= _min.x && pos.y <= _min.y"
        [class.hover]="pos.x === hover.x && pos.y === hover.y">
  </div>
</div>