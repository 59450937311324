import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FeatureType } from "../../../../../../common/model/organization/feature";
import { Permission } from '../../../../../../common/model/permission';
import { PermissionGroup } from '../../../../../../common/model/permission-group';
import { arrayDefined } from '../../../../../../common/toolbox/array';
import { claimLinkParse } from '../../../../../../common/toolbox/claim';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { Newable } from "../../../../../../common/toolbox/object";
import { TabBarComponent } from '../../../common/component/tab/bar/tab-bar.component';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { TabService } from '../../../common/service/tab.service';
import { getOneRequest } from '../../../common/toolbox/request';
import { ClaimComponent } from '../../claim/claim.component';
import { ClaimData } from '../../claim/claim.model';
import { CollectionsAgentComponent } from '../../collections/agent/collections-agent.component';
import { CollectionsAgentData } from '../../collections/agent/collections-agent.model';
import { CollectionsExecutiveComponent } from '../../collections/executive/collections-executive.component';
import { CollectionsExecutiveData } from '../../collections/executive/collections-executive.model';
import { CollectionsSupervisorComponent } from '../../collections/supervisor/collections-supervisor.component';
import { CollectionsSupervisorData } from '../../collections/supervisor/collections-supervisor.model';
import { DisputesAgentComponent } from '../../disputes/agent/disputes-agent.component';
import { DisputesAgentData } from '../../disputes/agent/disputes-agent.model';
import { DisputesExecutiveComponent } from '../../disputes/executive/disputes-executive.component';
import { DisputesExecutiveData } from '../../disputes/executive/disputes-executive.model';
import { DisputesIntakeComponent } from '../../disputes/intake/disputes-intake.component';

/** A rule for opening a pinned tab. */
class PinnedRule {
  constructor(
    /** Component to open. */
    public component: Newable<any>,
    /** Returns true to open component. */
    public check: () => boolean | Promise<boolean> = () => false,
    /** Evaluted to get component data. */
    public data: () => any | Promise<any> = async () => ({}),
    /** True if pin is closable. */
    public closeable = false
  ) {}
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  /** Reference to tab bar. */
  @ViewChild(TabBarComponent, { static: true }) tabRef!: TabBarComponent;

  /** Patterns to match for opening pinned tabs. */
  private readonly PINNED_RULES: PinnedRule[] = [
    new PinnedRule(ClaimComponent, () => !!claimLinkParse(LOCATION_HOSTNAME), this.claim.bind(this), true),

    new PinnedRule(CollectionsAgentComponent, () => this.auth.enabled(FeatureType.Collections) && this.auth.permission(Permission.CollectionsAgentDashboardView), () => new CollectionsAgentData()),
    new PinnedRule(CollectionsSupervisorComponent, () => this.auth.enabled(FeatureType.Collections) && this.auth.permission(Permission.CollectionsSupervisorDashboardView), () => new CollectionsSupervisorData()),
    new PinnedRule(CollectionsExecutiveComponent, () => this.auth.enabled(FeatureType.Collections) && this.auth.permission(Permission.CollectionsExecutiveDashboardView), () => new CollectionsExecutiveData()),
    new PinnedRule(DisputesAgentComponent, () => this.auth.enabled(FeatureType.Disputes) && this.auth.permission(Permission.ClaimsEdit), () => new DisputesAgentData()),
    new PinnedRule(DisputesExecutiveComponent, () => this.auth.enabled(FeatureType.Disputes) && this.auth.permission(Permission.DisputesExecutiveDashboardView), () => new DisputesExecutiveData()),
    new PinnedRule(DisputesIntakeComponent, () => this.auth.enabled(FeatureType.Disputes) && this.auth.permissionSome(PermissionGroup.ClaimIntake))
  ];
  
  constructor(
    private auth: AuthService,
    private tabs: TabService,
    private log: LogService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.tabs.set(this.tabRef);

    // Evaluate all pinned rules simultaneously.
    let tabs = arrayDefined(
      await Promise.all(
        this.PINNED_RULES.map(async rule => {
          if (!await rule.check()) return;
          let data = await rule.data();
          if (errorResponse(data)) {
            this.log.show(data);
            return;
          }

          return [rule, data];
        })
      )
    );

    for (let [rule, data] of tabs) this.tabs.open(rule.component, data, { closeable: rule.closeable });
    this.tabs.first();
  }

  ngOnDestroy() {
    this.tabs.clear();
  }

  /** Open specified claim in query parameters. */
  private async claim() {
    let _ids = [claimLinkParse(LOCATION_HOSTNAME)!];
    let claim = await getOneRequest(this.http, 'claims', { _insts: [this.auth._inst], _ids });
    return errorResponse(claim) ? claim : new ClaimData(claim);
  }
}
