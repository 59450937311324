import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { StandardFormList } from '../../../../../../common/model/builtin/form-list';
import { ModelTestFields } from '../../../../../../common/model/builtin/model/test';
import { DisplayValue } from '../../../../../../common/model/display';
import { NestedKey, keyNestedSet } from '../../../../../../common/toolbox/keys';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { FormChange, FormListConfig } from '../../../common/component/form/form.model';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { getOneRequest } from '../../../common/toolbox/request';

/** TODO Remove this test component once form list configuration is added. */
@Component({
  selector: 'app-demo-form-list',
  templateUrl: './demo-form-list.component.html',
  styleUrls: ['./demo-form-list.component.scss']
})
export class DemoFormListComponent {

  /** Change to force on child form. */
  change = new FormChange('model.string' as NestedKey<DisplayValue>, '');
  /** Configuration for demo form. */
  config?: FormListConfig;
  /** Emits when a given field is dirty. */
  dirty = new Subject<NestedKey<DisplayValue>[]>();

  static title() {
    return 'Form List';
  }

  constructor(
    private log: LogService,
    private auth: AuthService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    let list = await getOneRequest(this.http, 'form-lists', { _insts: [this.auth._inst], name: StandardFormList.Test });
    if (errorResponse(list)) {
      this.log.show(list);
      return;
    }

    this.config = {
      list,
      mutate: true,
      value: { model: new ModelTestFields() },
      dirty: this.dirty
    };
  }

  /** Force a change on child form. */
  onDirty() {
    keyNestedSet(this.change.key, this.config!.value, this.change.value);
    this.dirty.next([this.change.key])
  }
}
