import { deepCopy, objectEntries, objectKeys, ObjectKeys, objectOmit, objectValues } from "../toolbox/object";
import { Validator } from "../validator/base";
import { UnionValidator } from "../validator/union";

/** Type information for a tagged type. */
export interface UnionInfo<T = any, K extends PropertyKey = any> {
  /** Key that signals tagged union. */
  tag: ObjectKeys<T, K>,
  /** Mapping of union tags to classes. */
  classes: Record<K, T | Validator<T>>
}

/** Omit id from union info. */
export function unioninfoOmit<T, K extends PropertyKey, V extends keyof T>(unioninfo: UnionInfo<T, K>, ...keys: V[]): UnionInfo<Omit<T, V>, K> {
  let omitinfo: UnionInfo = deepCopy<any>(unioninfo);

  for (let [key, value] of objectEntries(omitinfo.classes)) {
    omitinfo.classes[key] = objectOmit(value, ...keys);
  }

  return omitinfo;
}

/** Get a value from a unioninfo given a type tag. */
export function unioninfoType<T = any, K extends PropertyKey = any>(unioninfo: UnionInfo<T, K>, type: K): T
export function unioninfoType<T = any, K extends PropertyKey = any>(unioninfo: UnionInfo<T, K>, type?: K): T | undefined
export function unioninfoType<T = any, K extends PropertyKey = any>(unioninfo: UnionInfo<T, K>, type?: K): T | undefined {
  if (type === undefined) return undefined;
  let value = unioninfo.classes[type];
  return value instanceof Validator ? value.value() : value;
}

/** Get all values of unioninfo. */
export function unioninfoValues<T, K extends PropertyKey>(unioninfo: UnionInfo<T, K>) {
  let values: T[] = [];
  unioninfoValuesDeep(unioninfo, values);
  return values;
}

/** Recursively get values of unioninfo. */
export function unioninfoValuesDeep<T, K extends PropertyKey>(unioninfo: UnionInfo<T, K>, values: T[]) {
  let availableValues = objectValues(unioninfo.classes);

  for (let value of availableValues) {
    if (value instanceof UnionValidator) unioninfoValuesDeep(value.unioninfo, values);
    else if (value instanceof Validator) continue;
    else values.push(value as unknown as T);
  }  
}

/** Resolve a union info given a value. */
export function unioninfoResolve<T>(stripped: T, typed: T | UnionValidator<T, any>): T {
  if (!(typed instanceof UnionValidator)) return typed;
  let tag = stripped[typed.unioninfo.tag];
  
  return ((typed.unioninfo as UnionInfo).classes[tag] as T) ?? stripped;
}

/** Retype a tagged object, keeping properties in common between tagged types. */
export function unioninfoRetype<B extends Object, T extends B, K extends PropertyKey = any>(base: B, object: T, type: K, unioninfo: UnionInfo<T, K>) {
  let validator = unioninfo.classes[type];
  let next = validator instanceof Validator ? validator.value() as T : validator as T;
  
  // Unset all values not in base object.
  let keep = new Set(objectKeys(object).filter(key => key in base || key in next));
  for (let key in object) {
    if (keep.has(key)) continue;
    object[key] = undefined as any;
  }

  // Copy values from new tagged type to provided object.
  object[unioninfo.tag] = type as any;
  for (let [key, value] of objectEntries(next)) {
    if (keep.has(key)) continue;
    object[key] = value as any;
  }

  return object;
}