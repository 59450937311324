import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DebugData } from "../../../../../../common/model/debug";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { LogService } from '../../../common/service/log.service';
import { getRequest } from '../../../common/toolbox/request';
import { ServerSource } from '../../../common/toolbox/source/server';

@Component({
  selector: 'app-demo-grid',
  templateUrl: './demo-grid.component.html',
  styleUrls: ['./demo-grid.component.scss']
})
export class DemoGridComponent {
  /** Paginated source for table. */
  source = new ServerSource<DebugData>();
  /** List of columns to display. */
  columns: (keyof DebugData)[] = ['id', 'name', 'birth', 'balance', 'index'];

  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();

  static title() {
    return 'Grid';
  }

  constructor(
    log: LogService,
    http: HttpClient
  ) {
    this.source.dataRequest.pipe(takeUntil(this.destroy)).subscribe(async request => {
      let response = await getRequest(http, 'debug/paginate', request);
      if (errorResponse(response)) {
        log.show(response);
        return;
      }

      this.source.available = response.available;
      this.source.items = response.items;
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
