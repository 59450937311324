import { DateRange } from "../../../../../../../common/toolbox/time";

/** Data passed to claim intake transactions step. */
export class ClaimIntakeTransactionsData {
  constructor(
    /** Last searched post date range. */
    public post = new DateRange(),
    /** True if a search was performed. */
    public search = false
  ) {}
}