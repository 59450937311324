import { Pipe } from '@angular/core';
import { Phone } from "../../../../../common/model/phone";
import { phoneFormat } from '../../../../../common/toolbox/phone';

@Pipe({
  name: 'phone'
})
export class PhonePipe {
  /** Dummy phone if full information unavailable. */
  private static empty = new Phone();
  
  transform(value: string | Phone): string {
    // Create dummy phone if only string provided.
    if (typeof value === 'string') {
      PhonePipe.empty.number = value;
      value = PhonePipe.empty;
    }

    return phoneFormat(value.number);
  }
}
