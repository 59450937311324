import { DAY_MILLISECONDS, HOUR_MILLISECONDS, MINUTE_MILLISECONDS, MONTH_MILLISECONDS, YEAR_MILLISECONDS, dateUntil } from "./time";

/** Valid formats for logging durations. */
export enum DurationFormat {
  Long = 'long',
  Short = 'short',
  Single = 'single'
}

/** Create a new duration from parts. */
export function durationParts(years: number | string, months: number | string, days: number | string, hours: number | string, minutes: number | string, seconds: number | string, milliseconds: number | string): number {
  return  (+years || 0) * YEAR_MILLISECONDS +
          (+months || 0) * MONTH_MILLISECONDS +
          (+days || 0) * DAY_MILLISECONDS +
          (+hours || 0) * HOUR_MILLISECONDS +
          (+minutes || 0) * MINUTE_MILLISECONDS +
          (+seconds || 0) * 1000 +
          (+milliseconds || 0);
}

/** Split a duration into its components. */
export function durationSplit(milliseconds: number): [days: number, hours: number, minutes: number, seconds: number, milliseconds: number] {
  return DURATIONS.map(([ms]) => {
    let value = milliseconds / ms | 0;
    milliseconds -= value * ms;
    return value;
  }) as any;
}

/** Get a human-readable description of duration until date. */
export function durationFormat(date?: Date, format = DurationFormat.Short) {
  if (!date) return '';
  let milliseconds = dateUntil(date);
  let past = milliseconds < 0;
  if (past) milliseconds *= -1;
  let values = durationSplit(milliseconds);

  switch (format) {
    case DurationFormat.Long: {
      let out = values.map((value, i) => value ? `${value} ${DURATIONS[i]![1]}${value > 1 ? 's' : ''}` : '').filter(v => v).join(' ');
      if (!out.length) return 'now';
      return past ? `${out} ago` : out;
    }
    case DurationFormat.Short: {
      if (!values[0]) values.shift();
      let out = values.map(value => `${value}`.padStart(2, '0')).join(':');
      return past ? `-${out}` : out;
    }
    case DurationFormat.Single: {
      let out = values.map((value, i) => value ? `${value} ${DURATIONS[i]![1]}${value > 1 ? 's' : ''}` : '').find(v => v);
      if (!out) return 'now';
      return past ? `${out} ago` : out;
    }
  }
}

/** List of length of durations. */
const DURATIONS = [
  [1000 * 60 * 60 * 24, 'day'],
  [1000 * 60 * 60, 'hour'],
  [1000 * 60, 'minute'],
  [1000, 'second']
] as const;