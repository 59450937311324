<div class="column">
  <div class="row" *ngIf="data | taskData:'dispute'">
    <div class="column fill"></div>
    <div class="column">
      <app-field class="row">
        <app-label>Hide Claim Level Attachments</app-label>
        <app-boolean [(ngModel)]="disputesOnly"></app-boolean>  
      </app-field>
    </div>
  </div>
  <app-claim-attachment-list *ngIf="data | taskData:'claim'" [claim]="data.claim" [disputes]="_disputes" [types]="data.task.config.types" [autosubmit]="false" [disputesOnly]="disputesOnly" (uploaded)="uploads = $event"></app-claim-attachment-list>
  <div class="button-row">
    <app-task-message></app-task-message>
    <button (click)="onSubmit()" [disabled]="!uploads?.length || !(Permission.AttachmentsEdit | permission)">Submit</button>
  </div>
</div>
