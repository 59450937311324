import { Component } from '@angular/core';
import { TaskAccessor } from '../../edit/setup-task-edit.model';
import { TaskConfigTag } from '../../../../../../../../common/model/task-config';

@Component({
  selector: 'app-setup-task-config-empty',
  templateUrl: './setup-task-config-empty.component.html',
  styleUrls: ['./setup-task-config-empty.component.scss']
})
export class SetupTaskConfigEmptyComponent<T extends TaskConfigTag> extends TaskAccessor<T> {}
