import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { PropertyType } from "../../../../../../common/model/property-type";
import { CURRENCY_MAX, CURRENCY_MIN, currency, currencyCoerce, currencyFormat } from "../../../../../../common/toolbox/currency";
import { fieldControlProviders } from '../../toolbox/angular';
import { PROPERTY_TYPE_ICON } from '../../toolbox/property';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  providers: fieldControlProviders(CurrencyComponent)
})
export class CurrencyComponent extends FieldControl implements ControlValueAccessor {
  readonly PROPERTY_TYPE_ICON = PROPERTY_TYPE_ICON;
  readonly PropertyType = PropertyType;
  readonly CURRENCY_MIN = CURRENCY_MIN;
  readonly CURRENCY_MAX = CURRENCY_MAX;

  /** Reference to input. */
  @ViewChild('input', { static : true }) input!: ElementRef<HTMLInputElement>;
  
  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  /** True to make a value required. */
  @Input() required = true;

  /** Minimum value. */
  @Input() min?: number;
  /** Maximum value. */
  @Input() max?: number;

  /** Current input value. */
  value?: currency;

  writeValue(value: number | string | undefined) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    value = this.coerce(value);
    
    this.changed(this.value = value);
    this.format();
  }

  /** Callback when typing into field. */
  onInput($event: Event) {
    let input = $event.target as HTMLInputElement;
    this.changed(this.value = this.coerce(input.value));
    this.setTouched();
  }

  /** Callback when losing focus on field. */
  format() {
    this.input.nativeElement.value = this.value === undefined ? '' : currencyFormat(this.value, this.required, this._readonly).replace('$', '');
  }

  /** Coerce a value using component configuration. */
  private coerce(value: any) {
    return currencyCoerce(value, this.required, this.min, this.max)
  }
}