import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { CollectionsSupervisorComponent } from './collections-supervisor.component';
import { CollectionsSupervisorHistoryComponent } from './history/collections-supervisor-history.component';
import { CollectionsSupervisorSummaryComponent } from './summary/collections-supervisor-summary.component';

@NgModule({
  declarations: [
    CollectionsSupervisorComponent,
    CollectionsSupervisorHistoryComponent,
    CollectionsSupervisorSummaryComponent
  ],
  imports: [
    SharedModule
  ]
})
export class CollectionsSupervisorModule {}
