<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row">
        <app-field class="fill">
        <app-label>Name</app-label>
        <app-string [(ngModel)]="value.name"></app-string>
      </app-field>
      <app-field class="fill">
        <app-label>Type</app-label>
        <app-select [ngModel]="value.type" (selected)="onType($event)">
          <app-option *ngFor="let t of types" [view]="t | enum:DISPLAY_TYPE_NAME" [value]="t"></app-option>
        </app-select>
      </app-field>
      </div>
      <div class="row">
        <app-field class="fill">
          <app-label>Limit</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Number of items in this queue before further are discarded."></app-hover-help>
          </app-sublabel>
          <app-number [(ngModel)]="value.limit" [required]="false"></app-number>
        </app-field>
        <app-field class="fill">
          <app-label>Filter Rule</app-label>
          <app-sublabel>
            <app-hover-help tooltip="Conditions that must be fulfilled for an item to appear in this value."></app-hover-help>
          </app-sublabel>
          <app-resource-select collection="filterRules" [required]="false" [filter]="filter" [(ngModel)]="value._rule"></app-resource-select>
        </app-field>
      </div>
    </app-setup-base-header>
    <app-setup-base-body></app-setup-base-body>
    <app-setup-base-footer>
      <button (click)="onSubmit()">Save</button>
      <button *ngIf="dev.on" (click)="onLog()">Log</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>