import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../../common/model/formula/block";
import { PLACEHOLDER_EXPRESSION, PLACEHOLDER_IDENTIFIER } from "../../../../../../../../common/model/formula/placeholder";
import { StatementAssignment } from "../../../../../../../../common/model/formula/statement";
import { TerminalType } from '../../../../../../../../common/model/formula/terminal';
import { ColorCode } from "../../../../../../../../common/toolbox/color";
import { BlockComponentMap } from '../../../setup.module';
import { BlockComponent } from '../../block.component';
import { BlockSlot } from '../../block.model';

@Component({
  selector: 'app-setup-formula-assignment',
  templateUrl: './setup-formula-assignment.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-assignment.component.scss'],
  host: { color: ColorCode.Secondary }
})
export class SetupFormulaAssignmentComponent extends BlockComponent<StatementAssignment> {
  /** Left identifier. */
  @ViewChild('left', { static : true, read: ViewContainerRef }) left!: ViewContainerRef;
  /** Right expression. */
  @ViewChild('right', { static : true, read: ViewContainerRef }) right!: ViewContainerRef;

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  ngOnInit() {
    this.releft(undefined, this.block.left ?? PLACEHOLDER_IDENTIFIER);
    this.reright(undefined, this.block.right ?? PLACEHOLDER_EXPRESSION)
  }

  /** Replace left identifier. */
  private releft(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.left,
      current: { block: this.block, key: 'left' },
      next: block,
      accepts: [TerminalType.Identifier],
      replaced: this.releft.bind(this)
    });
  }

  /** Replace right expression. */
  private reright(slot?: BlockSlot, block?: Block | Block[]) {
    this.replace(slot, {
      parent: this.injector,
      container: this.right,
      current: { block: this.block, key: 'right' },
      next: block,
      accepts: [BlockType.Expression],
      replaced: this.reright.bind(this)
    });
  }
}
