import { Component, Inject, Injector, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Block, BlockType } from "../../../../../../../common/model/formula/block";
import { PLACEHOLDER_STATEMENT, PlaceholderType } from "../../../../../../../common/model/formula/placeholder";
import { Statement } from '../../../../../../../common/model/formula/statement';
import { Pair } from "../../../../../../../common/toolbox/object";
import { BlockComponentMap } from '../../setup.module';
import { BlockComponent } from '../block.component';
import { BlockDrag, BlockSlot } from '../block.model';

@Component({
  selector: 'app-setup-formula-statements',
  templateUrl: './setup-formula-statements.component.html',
  styleUrls: ['./setup-formula-statements.component.scss'],
  host: {
    class: 'column'
  }
})
export class SetupFormulaStatementsComponent extends BlockComponent<Statement[]> {
  /** Root attachment point for statements. */
  @ViewChild('statements', { static : true, read: ViewContainerRef }) statements!: ViewContainerRef;
  
  /** Set new top-level statements. */
  @Input() set value(statements: Statement[]) {
    this.clear(this.statements);
    this.block = statements;
    this.placeholder = false;
    this.ngOnInit();
  }

  /** Set list of available keys. */
  @Input() set pairs(keys: Pair[]) {
    this.onKeys([keys]);
  }

  /** True if placeholder has been added. */
  private placeholder = false;

  constructor(
    @Inject('BLOCK_COMPONENT_MAP') BLOCK_COMPONENT_MAP: BlockComponentMap,
    @Inject('BLOCK_PARENT') parent: BlockComponent,
    private injector: Injector
  ) {
    super(BLOCK_COMPONENT_MAP, parent);
  }

  /** Drop a block into statements. */
  drop(block: BlockDrag) {
    for (let slot of this.slots) {
      if (slot.drop(block)) break;
    }
  }

  ngOnInit() {
    // Add statements of block.
    this.block = this.block ?? { statements: [] };
    for (let statement of this.block) {
      this.restatement(undefined, statement);
    }

    // Add empty placeholder.
    if (!this.placeholder) this.restatement(undefined, PLACEHOLDER_STATEMENT);
    this.placeholder = true;
  }

  /** Replace statement. */
  private restatement(slot?: BlockSlot, block?: Block | Block[]) {
    let index = this.slots.indexOf(slot as any);
    if (index === -1 && !this.placeholder) index = this.slots.length;

    if (this.slots[index]?.type === PlaceholderType.Statement) {
      // Insert statement before placeholder.
      this.attach({
        parent: this.injector,
        container: this.statements,
        current: { block: this.block, key: index },
        next: block,
        accepts: [BlockType.Statement],
        replaced: this.restatement.bind(this),
        deletable: true
      }, index);
    } else {
      // Replace statement.
      this.replace(slot, {
        parent: this.injector,
        container: this.statements,
        current: { block: this.block, key: index },
        next: block,
        accepts: [BlockType.Statement],
        replaced: this.restatement.bind(this),
        deletable: !!this.slots.length
      });
    }
  }
}
