<div class="logo">
  <img src="assets/img/fintegrate-logo.png" alt="Fintegrate Logo" width="109" height="20">
</div>
<div class="flex">
  <ng-container *ngFor="let m of menus">
    <button color="secondary" [menu-trigger]="menu" *ngIf="m.visible">{{m.name}}</button>
    <app-menu #menu="appMenu">
      <ng-container *ngFor="let g of m.groups">
        <app-menu-item [menu-trigger]="submenu" *ngIf="g.visible">{{g.name}}</app-menu-item>
        <app-menu #submenu="appMenu">
          <ng-container *ngFor="let o of g.options">
            <app-menu-item (click)="open(o.component, o.config.data)" *ngIf="o.visible">{{o.name | promisify | async}}</app-menu-item>
          </ng-container>
        </app-menu>
      </ng-container>
      <ng-container *ngFor="let o of m.options">
        <app-menu-item (click)="open(o.component, o.config.data)" *ngIf="o.visible">{{o.name}}</app-menu-item>
      </ng-container>
    </app-menu>
  </ng-container>
</div>
<app-menu-bar-search class="fill" *ngIf="(FeatureType.Collections | feature) || ((FeatureType.Disputes | feature) && (Permission.ClaimsEdit | permission))"></app-menu-bar-search>
<app-menu-bar-feature class="fill"></app-menu-bar-feature>
<div class="flex">
  <app-debug-button *ngIf="dev.on"></app-debug-button>
  <app-setup-button *ngIf="dev.on"></app-setup-button>
  <app-activity></app-activity>
  <button type="button" class="user-button" color="secondary" button-prefix="expand_more" position="after" [menu-trigger]="userMenu">
    <app-icon class="user-icon">account_circle</app-icon>
    {{auth.session.name}}
  </button>
</div>
<app-menu #userMenu="appMenu">
  <app-menu-item (click)="open(UserSettingsComponent)">Settings</app-menu-item>
  <app-menu-item (click)="open(DocumentationComponent)">Documentation</app-menu-item>
  <app-menu-item (click)="auth.logout()">Logout</app-menu-item>
</app-menu>