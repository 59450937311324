import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Directive, ElementRef, EventEmitter, Inject, Input, NgZone, Optional, Output, Self, ViewContainerRef } from "@angular/core";
import { Observable, Subscription, asapScheduler, delay, filter, merge, of } from "rxjs";
import { overlayDropdown } from "../../../toolbox/overlay";
import { MenuComponent } from "../menu.component";
import { MENU_PARENT, MenuCloseReason } from "../menu.model";
import { MenuItemComponent } from "./menu-item.component";

@Directive({
  selector: '[menu-trigger]',
  exportAs: 'menuTrigger',
  host: {
    '(click)': 'onClick($event)'
  }
})
export class MenuTriggerDirective {

  get menu() {
    return this.component;
  }

  @Input('menu-trigger')
  set menu(menu: MenuComponent | undefined) {
    if (!menu) return;
    this.component = menu;
    this.closeSubscription.unsubscribe();
    this.closeSubscription = menu.close.subscribe((reason: MenuCloseReason) => {
      this.cleanup();

      // If click closed menu, close entire chain of nested menus.
      if (reason === MenuCloseReason.Click || reason === MenuCloseReason.Tab) {
        this.parent?.close.emit(reason);
      }
    })
  }

  /** Data to make available in menu. */
  @Input('menu-trigger-data') data?: any;

  /** True to automatically open menu when clicked. */
  @Input('menu-trigger-autoopen') autoopen = true;

  /** Emits when menu is opened. */
  @Output() menuOpen = new EventEmitter<void>();
  
  /** True if menu currently opened. */
  private opened = false;
  /** Reference to menu this trigger opens. */
  private component!: MenuComponent;
  /** Opened overlay containing menu. */
  private overlayRef?: OverlayRef;

  /** Observer for size changes. */
  private observer?: ResizeObserver;
  /** Subscription to menu closing. */
  private closeSubscription = Subscription.EMPTY;
  /** Subscription to any action that caused menu to close. */
  private actionSubscription = Subscription.EMPTY;
  /** Subscirption to item being hovered over. */
  private hoverSubscription = Subscription.EMPTY;

  constructor(
    private zone: NgZone,
    private overlay: Overlay,
    private containerRef: ViewContainerRef,
    private element: ElementRef<HTMLElement>,
    @Optional() @Self() private item?: MenuItemComponent,
    @Optional() @Inject(MENU_PARENT) public parent?: MenuComponent
  ) {
    if (item) item.submenu = !!parent;
  }

  ngAfterContentInit() {
    if (!this.component || !this.parent) return;

    // Listen for when I'm hovered over, and add small delay to prevent conflicts with other menu items.
    this.hoverSubscription = this.parent.hovered().pipe(
      filter(active => active === this.item && !active.disabled),
      delay(0, asapScheduler)
    ).subscribe(() => this.open());
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    this.closeSubscription.unsubscribe();
    this.actionSubscription.unsubscribe();
    this.hoverSubscription.unsubscribe();
  }

  /** Opens the menu. */
  open() {
    if (this.opened || !this.menu) return;
    this.menuOpen.emit();

    // Attach menu's template to portal.
    this.overlayRef = this.createOverlay();
    const templatePortal = new TemplatePortal(this.menu.templateRef, this.containerRef, { $implicit: this.data });
    this.overlayRef.attach(templatePortal);

    // Listen to select element size changes.
    this.observer = new ResizeObserver(entries => this.zone.run(() => {
      for (let { borderBoxSize } of entries) {
        this.overlayRef?.updateSize({ minWidth: borderBoxSize[0]!.inlineSize });
        this.overlayRef?.updatePosition();
      }
    }));
    this.observer.observe(this.element.nativeElement);

    // Do cleanup if closed.
    this.opened = true;
    this.actionSubscription = this.menuCloseEvent().subscribe(() => {
      this.observer!.disconnect();
      this.close();
    });
  }

  /** Closes the menu. */
  close() {
    this.menu?.close.emit();
  }

  /** Callback when menu is clicked. */
  onClick($event: MouseEvent) {
    if (this.parent) {
      // Stop even propagation to avoid closing parent menu.
      $event.stopPropagation();
      if (this.autoopen) this.open();
    } else if (this.autoopen && !this.opened) {
      this.open();
    } else if (this.opened) {
      this.close();
    }
  }

  /** Closes menu and does necessary cleanup. */
  private cleanup() {
    if (!this.overlayRef) return;

    this.overlayRef.detach();
    this.actionSubscription.unsubscribe();
    this.opened = false;
  }

  /** Returns stream that emits whenever menu closing action occurs. */
  private menuCloseEvent() {
    const backdrop = this.overlayRef!.backdropClick();
    const detachments = this.overlayRef!.detachments();
    const parentClose = this.parent ? this.parent.close : of();
    const hover = this.parent?.hovered().pipe(
      filter(active => active !== this.item),
      filter(() => this.opened)
    ) ?? of();

    return merge(backdrop, detachments, hover, parentClose as Observable<MenuCloseReason>)
  }

  /** Creates overlay configuration or reuses existing one. */
  private createOverlay(): OverlayRef {
    if (this.overlayRef) return this.overlayRef;

    let overlayConfig = new OverlayConfig({
      hasBackdrop: !this.parent,
      panelClass: ['menu-panel', 'elevation-high'],
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.element)
        .withLockedPosition()
        .withGrowAfterOpen()
    });

    overlayDropdown(overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy, !!this.parent);
    return this.overlay.create(overlayConfig);
  }
}