import { Pipe } from '@angular/core';
import { Formula } from '../../../../../common/model/formula/formula';
import { formulaRun } from '../../../../../common/toolbox/formula/formula';

@Pipe({ name: 'formula' })
export class FormulaPipe {

  /** Execute formula with given input. */
  transform(formula: Formula | undefined | null, input: any, fallback: any = undefined) {
    return formula ? formulaRun(formula, input) : fallback;
  }
}
