import { Component } from '@angular/core';
import { TriggerConfigTag } from '../../../../../../../../common/model/trigger-config';
import { TriggerAccessor } from '../../edit/setup-trigger-edit.model';

@Component({
  selector: 'app-setup-trigger-config-empty',
  templateUrl: './setup-trigger-config-empty.component.html',
  styleUrls: ['./setup-trigger-config-empty.component.scss']
})
export class SetupTriggerConfigEmptyComponent<T extends TriggerConfigTag> extends TriggerAccessor<T> {}
