<app-card body class="column">
  <app-setup-base>
    <app-setup-base-header>
      <div class="row align-end">
        <app-field class="fill">
          <app-label>Name</app-label>
          <app-string [(ngModel)]="value.name"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Email</app-label>
          <app-string [(ngModel)]="value.email"></app-string>
        </app-field>
        <app-field class="fill">
          <app-label>Profile</app-label>
          <app-resource-select collection="profiles" [ngModel]="grant._profile" (selected)="onProfile($event)"></app-resource-select>
        </app-field>
        <button *ngIf="!(value._id | idNull)" (click)="onResetPassword()">Reset Password</button>
      </div>
    </app-setup-base-header>
    <app-setup-base-body>
      <div class="row tables">
        <app-permissions-grid [data]="data" (selectionChange)="resync()"></app-permissions-grid>
        <table app-grid class="fill elevation-low" *ngIf="FeatureType.Collections | feature" [source]="queues" [multiple]="true" [sizes]="sizes" (selectionChange)="resync()">
          <ng-container grid-column-def="name">
            <grid-header-cell *grid-header-cell-def>Work Queues</grid-header-cell>
            <grid-cell *grid-cell-def="let row">{{row.name}}</grid-cell>
            <grid-footer-cell *grid-footer-cell-def></grid-footer-cell>
          </ng-container>
          <grid-header-row *grid-header-row-def="queueColumns"></grid-header-row>
          <grid-row *gridRowDef="let row; columns: queueColumns;" [indeterminate]="queueFallback[row._id]"></grid-row>
          <grid-footer-row *grid-footer-row-def="queueColumns"></grid-footer-row>
        </table>
      </div>
    </app-setup-base-body>
    <app-setup-base-footer>
      <app-upload [label]="'Import from CSV'" (uploads)="onImport($event)"></app-upload>
      <button (click)="onSubmit()">{{access ? 'Save' : 'Grant Access'}}</button>
      <button *ngIf="access" (click)="onRevoke()">Revoke Access</button>
      <button (click)="onCancel()">Cancel</button>
    </app-setup-base-footer>
  </app-setup-base>
</app-card>