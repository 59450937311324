import { DisplayValue } from "../../../../../common/model/display";
import { Member } from "../../../../../common/model/member";
import { accountUnion } from "../../../../../common/toolbox/account";
import { claimUnion } from "../../../../../common/toolbox/claim";
import { disputeUnion } from "../../../../../common/toolbox/dispute";
import { PickPartial } from "../../../../../common/toolbox/object";

/** A display value with required values for doing setup preview. */
export type DisplaySetup = PickPartial<DisplayValue, 'account' | 'claim' | 'dispute' | 'member'>;

/** Configuration to setup a resource. */
export class SetupData {
  constructor(
    /** Initial resource to auto-open. */
    public _id: string
  ) {}
}

/** Create a display value with a required dispute and transaction. */
export function displaySetup(): DisplaySetup {
  return { account: accountUnion(), claim: claimUnion(), dispute: disputeUnion(), member: new Member() };
}