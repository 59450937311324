import { Pipe } from '@angular/core';
import { EnumMap } from "../../../../../common/toolbox/enum";

@Pipe({
  name: 'enum'
})
export class EnumPipe {
  transform<T extends number | string>(value: T, map: EnumMap<T>, override?: string): string {
    return override || map[value];
  }
}
