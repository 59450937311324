import { AccountJoin, AccountJoinLoan } from "../../../../../common/model/account/account";
import { ClaimJoin, ClaimJoinACH, ClaimUnion } from "../../../../../common/model/claim/claim";
import { DisplayPartial } from "../../../../../common/model/display";
import { DisputeJoin, DisputeJoinACH, DisputeUnion } from "../../../../../common/model/dispute/dispute";
import { Task } from "../../../../../common/model/task";
import { TaskType } from "../../../../../common/model/task-config";
import { Transaction } from "../../../../../common/model/transaction";
import { arrayDefined } from "../../../../../common/toolbox/array";
import { claimDisputes, claimUnion } from "../../../../../common/toolbox/claim";
import { disputeUnion } from "../../../../../common/toolbox/dispute";
import { EnumMap } from "../../../../../common/toolbox/enum";
import { TaskOpen } from "../../common/toolbox/task";

/** Mode that the task was called in. */
export enum TaskMode {
  Account,
  Claim,
  Dispute
}

/** Tagged type of task data. */
export type TaskData<T extends TaskType = TaskType> = TaskAccount<T> | TaskClaim<T> | TaskDispute<T>;

/** Common data always sent to tasks. */
export class TaskBase<T extends TaskType = TaskType> {

  constructor(
    /** Pre-populated display context for use in tasks. */
    public partial: DisplayPartial = {},
    /** Transactions being viewed. */
    public transactions?: Transaction[],
    /** Task that was opened. */
    public task = new Task<T>(),
    /** Existing result to edit, if applicable. */
    public open?: TaskOpen
  ) { }

  /**
   *  Get a claim union from task data.
   *  @param data Data context passed to task.
   *  @param mutate True to mutate provided claim reference instead of creating a copy.
   */
  static claim(data: TaskData, mutate: boolean) {
    const claim = 'claim' in data ? data.claim : undefined;
    return claim
      ? mutate ? claim as ClaimUnion : claimUnion(claim, TaskBase.disputes(data))
      : undefined;
  }

  /**
   *  Get a dispute union from task data.
   *  @param data Data context passed to task.
   *  @param mutate True to mutate provided claim reference instead of creating a copy.
   */
  static dispute(data: TaskData, mutate: boolean) {
    const dispute = 'dispute' in data ? data.dispute : undefined;
    return dispute
      ? mutate ? dispute as DisputeUnion : disputeUnion(dispute)
      : undefined;
  }

  /** Simple method of getting disputes from task data. */
  static disputes(data: TaskData): DisputeJoin[] {
    return 'dispute' in data ? [data.dispute] :
      'claim' in data ? claimDisputes(data.claim) :
      [];
  }

  /** Simple method of getting transactions from task data. */
  static transactions(data: TaskData) {
    if (data.transactions) return data.transactions;
    const transactions =
      'dispute' in data ? [data.dispute.transaction] :
      'claim' in data ? claimDisputes(data.claim).map(dispute => dispute.transaction) :
      [];
    return arrayDefined(transactions);
  }
}

/** Data when a dispute is in main view. */
export class TaskAccount<T extends TaskType = TaskType> extends TaskBase<T> {
  constructor(
    /** Account being viewed. */
    public account: AccountJoin = new AccountJoinLoan()
  ) {
    super();
  }
}

/** Data when a claim is in main view. */
export class TaskClaim<T extends TaskType = TaskType> extends TaskBase<T> {
  constructor(
    /** Claim being viewed. */
    public claim: ClaimJoin = new ClaimJoinACH()
  ) {
    super();
  }
}

/** Data when a dispute is in main view. */
export class TaskDispute<T extends TaskType = TaskType> extends TaskBase<T> {
  constructor(
    /** Claim being viewed. */
    public claim: ClaimJoin = new ClaimJoinACH(),
    /** Dispute being viewed */
    public dispute: DisputeJoin = new DisputeJoinACH()
  ) {
    super();
  }
}

/** Name for each task mode. */
export const TASK_MODE_NAME: EnumMap<TaskMode> = {
  [TaskMode.Account]: 'Account',
  [TaskMode.Claim]: 'Claim',
  [TaskMode.Dispute]: 'Dispute'
};

/** Allowed modes for each task type. */
export const TASK_TYPE_MODE: Record<TaskType, TaskMode[]> = {
  [TaskType.ClaimAttachment]  : [TaskMode.Claim, TaskMode.Dispute],
  [TaskType.ClaimReview]      : [TaskMode.Claim, TaskMode.Dispute],
  [TaskType.DisputeWithdraw]  : [TaskMode.Dispute],
  [TaskType.DocumentTemplate] : [TaskMode.Account, TaskMode.Claim, TaskMode.Dispute],
  [TaskType.Form]             : [TaskMode.Account, TaskMode.Claim, TaskMode.Dispute],
  [TaskType.FormList]         : [TaskMode.Account, TaskMode.Claim, TaskMode.Dispute],
  [TaskType.Headless]       : [TaskMode.Account, TaskMode.Claim, TaskMode.Dispute]
};
