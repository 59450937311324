import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Step, StepStatus } from '../../../../../../common/model/step';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent<T> {
  readonly StepStatus = StepStatus;
  readonly NEW_STEP = new Step({ name: 'Add New' });

  /** Current breadcrumb state. */
  @Input() crumbs: T[] = [];
  /** Emits when available list of breadcrumbs changes. */
  @Output() crumbsChange = new EventEmitter<T[]>();
  
  /** Current selected crumb. */
  @Input() crumb?: T;
  /** Emits when a crumb is selected. */
  @Output() crumbChange = new EventEmitter<T>();
  
  /** Template to render each item. */
  @Input() template: TemplateRef<T> | null = null;

  /** Callback when selecting a crumb. */
  onCrumb(i: number) {
    let crumb = this.crumbs[i];
    if (!crumb) return;

    // Truncate crumbs after this one, select crumb.
    this.crumbsChange.emit(this.crumbs = this.crumbs.slice(0, i + 1))
    this.crumbChange.emit(this.crumb = crumb);
  }
}
