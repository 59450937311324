import { CdkFooterRow } from "@angular/cdk/table";
import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from "@angular/core";
import { GridBase } from "../grid.base";

@Component({
  selector: 'grid-footer-row',
  styleUrls: ['./grid-footer-row.component.scss'],
  templateUrl: './grid-footer-row.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'gridFooterRow',
  providers: [ { provide: CdkFooterRow, useExisting: GridFooterRow } ]
})
// @ts-ignore Supress TS2417
export class GridFooterRow extends GridBase(CdkFooterRow) {
  /** Calculated style for grid items. */
  @HostBinding('style.grid-template-columns') style = '';
  
  /** True if at least one item must be selected. */
  required = false;
  /** True if all items are selected. */
  all = false;
}