import { CdkDrag, CdkDragMove } from '@angular/cdk/drag-drop';
import { ContentChildren, Directive, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { Box } from "../../../../../common/model/box";
import { Pos } from "../../../../../common/model/pos";
import { boxOffset, boxOnscreen } from '../toolbox/box';
import { GridDirective } from './grid.directive';

/** Information for performing list drags. */
class ListGridItem {
  constructor(
    /** Index of element in array. */
    public i = -1,
    /** Onscreen position of element. */
    public offset = new Box(),
    /** Grid area of element. */
    public area = new Box()
  ) {}
}

/** A swap that occurred after a list drag. */
export class ListGridSwap {
  constructor(
    /** Index that was dragged from. */
    public from = 0,
    /** Index that was dragged to. */
    public to = 0
  ) {}
}

@Directive({
  selector: '[list-grid]'
})
export class ListGridDirective<T> extends GridDirective<T> {

  /** Drags interpolated via ng-content. */
  @ContentChildren(CdkDrag) contentRef!: QueryList<CdkDrag>;

  /** List of items to reorganize. */
  @Input('list-grid') list: T[] = [];
  /** Callback when items swap positions. */
  @Output() swap = new EventEmitter<ListGridSwap>();

  /** Move list positions of items. */
  onMove($event: CdkDragMove) {
    let dragging = $event.source.element.nativeElement;
    let [from, to] = [new ListGridItem(), new ListGridItem()];

    // Find old and new position of dragged element.
    for (let i = 0; i < this.element.nativeElement.children.length; ++i) {
      let element = this.element.nativeElement.children[i]! as HTMLElement;
      if (dragging === element) {
        from = new ListGridItem(i, boxOffset(element));
        continue;
      } else if (Pos.inside($event.pointerPosition, boxOnscreen(element))) {
        to = new ListGridItem(i, boxOffset(element));
        continue;
      }
    }

    // Swap positions of elements in array.
    let list = this.list;
    if (to.i < 0 || from.i < 0 || to.i >= list.length) return;
    [list[from.i]!, list[to.i]!] = [list[to.i]!, list[from.i]!];
    this.swap.next(new ListGridSwap(from.i, to.i));
    this.adjust($event, from.offset, to.offset);
  }
}
