import { Component, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { PropertyType } from "../../../../../../common/model/property-type";
import { Control } from "../../toolbox/form";
import { FieldControl } from "../field/field-control";

/** Interface that allows a field to be used in a model form or standard angular form. */
@Component({ template: '' })
export abstract class FormAccessor<T = any> extends FieldControl {
  readonly PropertyType = PropertyType;

  /** Reference to form control inside field. */
  @ViewChild(FieldControl) controlRef!: FieldControl;

  /** Check model accessor rules for editability. */
  static readonly(readonly?: boolean, locked?: boolean, reopened?: boolean) {
    return !!(readonly || (locked && reopened));
  }

  /** Check model accessor rules for required. */
  static required(required: boolean, optional: boolean) {
    return required && !optional;
  }
  
  /** True if this field is disabled. */
  disabled = false;
  /** True if this field is readonly. */
  readonly = false;
  /** True if this field is required. */
  required = false;
  /** True if this field holds multiple values. */
  multiple = false;
  /** True if field should be readonly if reopened. */
  locked = false;
  /** True if this field is reopened. */
  reopened = true;
  /** True if this field is valid. */
  valid = true;

  /** Type of property to display icon. */
  type = PropertyType.User;
  /** Field this component is bound to. */
  control!: Control;

  /** Current value of field. */
  value!: T;
  /** Emits when value changes. */
  valueChange = new ReplaySubject<T>(1);

  /** Check if current value can be modified. */
  get editable() {
    return !this.disabled && !FormAccessor.readonly(this.readonly, this.locked, this.reopened);
  }
  
  /** Write value for field. */
  writeValue(value: T) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    this.update(value);
  }

  /** Update field value. */
  update(value: T): boolean {
    if (value === this.value) return false;
    this.valueChange.next(this.value = value);
    return true;
  }

  ngAfterViewInit() {
    // Update field validity when fields recalculate errors.
    if (!this.controlRef) return;
    this.controlRef.errors.subscribe(errors => {
      this.errors.next(errors);
      this.valid = errors === null;
    });
  };
}