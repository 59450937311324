import { Pipe } from '@angular/core';
import { AngularExpression, AngularPipes } from '../../../../../common/toolbox/angular';
import { idNull } from '../../../../../common/toolbox/id';
import { AttachmentService } from '../service/attachment.service';
import { AuthService } from '../service/auth.service';
import { FormService } from '../service/form.service';
import { MemberService } from '../service/member.service';
import { TaskService } from '../service/task.service';
import { CentsPipe } from './cents.pipe';
import { CodeTypePipe } from './code-type.pipe';
import { DateIsoPipe } from './date-iso.pipe';
import { PhonePipe } from './phone.pipe';
import { UserPipe } from './user.pipe';

@Pipe({
  name: 'expression'
})
export class ExpressionPipe {

  /** Pipes available for evaluating Angular expressions. */
  pipes: AngularPipes = {
    form: (value: any) => this.form.name(this.auth._inst, value),
    attachment: (value: any) => this.attachment.name(this.auth._inst, value),
    boolean: (value: any, on: any = 'yes', off: any = 'no') => !!value ? on : off,
    code: (value: any, category = '') => this.code.transform(value, this.auth._inst, category),
    currency: (value: any, required = false, readonly = true) => this.cent.transform(value, required, readonly) ?? '',
    date: (value: any, format?: string) => this.date.transform(value, format),
    member: value => this.member.name({ _id: value, _inst: this.auth._inst }),
    phone: value => this.phone.transform(value),
    task: value => this.task.name(this.auth._inst, value),
    user: value => idNull(value) ? 'System' : this.user.transform(value, this.auth._inst)
  }

  constructor(
    private user: UserPipe,
    private date: DateIsoPipe,
    private code: CodeTypePipe,
    private phone: PhonePipe,
    private cent: CentsPipe,

    private attachment: AttachmentService,
    private form: FormService,
    private auth: AuthService,
    private task: TaskService,
    private member: MemberService
  ) { }

  transform(format: string, context: any): Promise<string> {
    return AngularExpression.evaluate(format, context, this.pipes);
  }
}