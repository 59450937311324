import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentTemplateType } from '../../../../../common/code/standard/common';
import { DisputeType } from '../../../../../common/code/standard/disputes';
import { DocumentTemplateBuildPostRequest } from '../../../../../common/message/document-template';
import { Address } from '../../../../../common/model/address';
import { DisplayValue } from '../../../../../common/model/display';
import { DocumentTemplate, DocumentTemplatePreview } from "../../../../../common/model/document-template/base";
import { DocumentTemplateClass, DocumentTemplateDataGeneral, DocumentTemplateDataTestPerson } from "../../../../../common/model/document-template/data";
import { APP_NAME } from '../../../../../common/toolbox/app';
import { ArraySome, arrayDefined, arraySome, arraySum } from "../../../../../common/toolbox/array";
import { claimDisputes } from '../../../../../common/toolbox/claim';
import { ID_DEFAULT, idNull, instAdd } from "../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../common/toolbox/message";
import { mimeChange, mimeSplit } from '../../../../../common/toolbox/mime';
import { objectDefined } from '../../../../../common/toolbox/object';
import { randomRange } from '../../../../../common/toolbox/random';
import { DatePosition, dateCompare } from '../../../../../common/toolbox/time';
import { DialogService } from '../component/dialog/dialog.service';
import { CachePreviewService } from '../toolbox/cache-preview-service';
import { fileBase64, fileCreate } from '../toolbox/file';
import { getRequest, postRequest } from '../toolbox/request';
import { AddressService } from './address.service';
import { AuthService } from './auth.service';
import { DisplayService } from './display.service';
import { LogService } from './log.service';

/** A query to fetch a specific document template. */
export class DocumentTemplateQuery {
  constructor(
    /** ID of profile. */
    public _id = ID_DEFAULT,
    /** Institution of profile. */
    public _inst = ID_DEFAULT
  ) { }
}

/** Options for building document templates. */
export class DocumentTemplateBuildOptions {
  constructor(
    /** Override for output filename. */
    public output?: string,
    /** Override for template file. */
    public file?: string | File
  ) {}
}

/** Caches information about tasks. */
@Injectable({
  providedIn: 'root'
})
export class DocumentTemplateService extends CachePreviewService<DocumentTemplate, DocumentTemplateQuery, DocumentTemplatePreview>{
  readonly route = 'document-templates/preview';
  readonly Type = DocumentTemplate;

  /** How to populate data for each type of template. */
  private TEMPLATE_DATA: { [K in DocumentTemplateType]: (context: DocumentTemplateClass[K]) => Omit<DocumentTemplateClass[K], keyof DocumentTemplateDataGeneral> } = {
    [DocumentTemplateType.ClaimIntake]: context => ({
      totalDisputedAmount: arraySum(context.transactions?.map(t => t.amount))
    }),
    [DocumentTemplateType.ClaimCardChargeback]: context => ({
      totalDisputedAmount: arraySum(context.transactions?.map(t => t.amount))
    }),
    [DocumentTemplateType.ClaimCardInitialCorrespondence]: context => ({
      investigationDueDate: claimDisputes(context.claim).reduce((due, dispute) => {
        if (dispute.type !== DisputeType.Card || !dispute.investigationDueDate) return due;
        if (dateCompare(dispute.investigationDueDate, due) === DatePosition.After) return dispute.investigationDueDate;
        return due;
      }, new Date(0))
    }),
    [DocumentTemplateType.ClaimCardResolution]: context => ({
      investigationDueDate: claimDisputes(context.claim).reduce((due, dispute) => {
        if (dispute.type !== DisputeType.Card || !dispute.investigationDueDate) return due;
        if (dateCompare(dispute.investigationDueDate, due) === DatePosition.After) return dispute.investigationDueDate;
        return due;
      }, new Date(0))
    }),
    [DocumentTemplateType.ClaimList]: context => ({
      claims: context.claims
    }),
    [DocumentTemplateType.General]: () => ({}),
    [DocumentTemplateType.Test]: () => ({
      value: 5,
      amount: randomRange(500, 5000),
      people: Array.from({ length: randomRange(10, 20) }, () => new DocumentTemplateDataTestPerson())
    }),
    [DocumentTemplateType.Notification]: context => context,
    [DocumentTemplateType.NotificationImport]: context => context
  }

  constructor(
    log: LogService,
    dialog: DialogService,
    http: HttpClient,
    private auth: AuthService,
    private display: DisplayService,
    private addresses: AddressService
  ) {
    super(DocumentTemplateQuery, log, dialog, http);
  }

  /** Directly build the given document template with the provided values.  */
  async build<T extends DocumentTemplateType>(template: DocumentTemplate<T>, data: DocumentTemplateClass[T], options: DocumentTemplateBuildOptions = {}) {
    // Use output name, falling back on template name.
    // Use output extension, falling back on template extension.
    let output = options.output ?? template.name;
    output = mimeChange(output, mimeSplit(output)[1] || template.name);

    // Process buffer into base64 if passed.
    let buffer = options.file instanceof Blob ? await fileBase64(options.file) : options.file;
    if (errorResponse(buffer)) return buffer;
    let request: DocumentTemplateBuildPostRequest = {
      _ids: [template._id],
      _insts: [this.auth._inst],
      output, buffer,
      template,
      data: instAdd(data, this.auth._inst)
    };

    // Submit stripped out template data.
    let blob = await postRequest(this.http, 'document-templates/build', request, { responseType: 'blob' });
    if (errorResponse(blob)) return blob;
    return fileCreate([blob], output);
  }

  /** Take a partial document template context and apply common defaults.  */
  async context<T extends DocumentTemplateType>(type: T, data: Partial<DocumentTemplateClass[T]>): Promise<DocumentTemplateClass[T]> {

    this.display.fallback(data);
    const transactions = data.transactions ?? arrayDefined((data.claim?.disputes ?? []).map(dispute => dispute.transaction));
    const _memberAddress = arraySome(data.member?._addresses) ? data.member?._addresses[0] : undefined;
    const _instAddress = data.institution?._address;

    let general: DocumentTemplateDataGeneral = {
      ...new DisplayValue(),
      ...objectDefined(data),
      today: new Date(),
      application: APP_NAME,
      transactions,
      memberAddress: data.memberAddress ?? (idNull(_memberAddress) ? new Address() : await this.addresses.item({ _inst: this.auth._inst, _id: _memberAddress })),
      instAddress: data.instAddress ?? idNull(_instAddress) ? new Address() : await this.addresses.item({ _inst: this.auth._inst, _id: _instAddress }),
    };

    return {
      ...general,
      ...this.TEMPLATE_DATA[type]({ ...data, ...general } as any)
    } as any;
  }

  protected override multiple(queries: ArraySome<DocumentTemplateQuery>) {
    return getRequest(this.http, 'document-templates', { _insts: [queries[0]._inst], _ids: queries.map(q => q._id) });
  }
}
