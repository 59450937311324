import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DocumentTemplateType } from '../../../../../../common/code/standard/common';
import { ClaimListPostRequest } from '../../../../../../common/message/claim';
import { DocumentTemplatePreview } from '../../../../../../common/model/document-template/base';
import { ConditionType } from '../../../../../../common/model/formula/condition';
import { TerminalType } from '../../../../../../common/model/formula/terminal';
import { errorResponse } from '../../../../../../common/toolbox/message';
import { dateDayOffsets, dateOffset, DateRange, dateFloor } from '../../../../../../common/toolbox/time';
import { AuthService } from '../../../common/service/auth.service';
import { LogService } from '../../../common/service/log.service';
import { fileDownload } from '../../../common/toolbox/file';
import { postRequest } from '../../../common/toolbox/request';

@Component({
  selector: 'app-claim-report',
  templateUrl: './claim-report.component.html',
  styleUrls: ['./claim-report.component.scss']
})
export class ClaimReportComponent {

  readonly types = [DocumentTemplateType.ClaimList];
  /** Displayed date range of document template. */
  range = new DateRange(dateOffset(dateFloor(new Date()), -6), dateFloor(new Date()));

  /** Default title for tab. */
  static title() {
    return 'Claim Reports';
  }

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private log: LogService
  ) {}

  async onDocumentTemplate(template: DocumentTemplatePreview) {
    let { daysToStart, daysToEnd } = dateDayOffsets(this.range);

    let request: ClaimListPostRequest = {
      _template: template._id,
      _inst: this.auth._inst,
      output: template.name,
      reportSort: [],
      conditions: [
        // TODO convert to ConditionType.Between once logic is fixed.
        {
          type: ConditionType.Greater,
          left: { type: TerminalType.Identifier, key: 'intakeDate' },
          right: { type: TerminalType.Date, value: daysToStart }
        },
        {
          type: ConditionType.Lesser,
          left: { type: TerminalType.Identifier, key: 'intakeDate' },
          right: { type: TerminalType.Date, value: daysToEnd }
        }
      ]
    }

    let blob = await postRequest(this.http, 'claims/list', request, { responseType: 'blob' });
    if (errorResponse(blob)) return this.log.show(blob);
    fileDownload(blob, template.name);
  }

  /** Callback when selecting new date range. */
  onRange(range: DateRange) {
    this.range = range;
  }
}
