import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { FusionCollectionName } from '../../../../../../../common/model/fusion';
import { arraySingle, arraySome } from '../../../../../../../common/toolbox/array';
import { ColorCode } from '../../../../../../../common/toolbox/color';
import { idNull } from '../../../../../../../common/toolbox/id';
import { Pair } from '../../../../../../../common/toolbox/object';
import { FusionCollectionQuery, ResourceService } from '../../../service/resource.service';
import { fieldControlProviders } from '../../../toolbox/angular';
import { setupElementMake } from '../../../toolbox/element/setup';
import { FieldControl } from '../../field/field-control';

@Component({
  selector: 'app-resource-select',
  templateUrl: './resource-select.component.html',
  styleUrls: ['./resource-select.component.scss'],
  providers: fieldControlProviders(ResourceSelectComponent)
})
export class ResourceSelectComponent extends FieldControl implements ControlValueAccessor {

  /** Set current configured collection. */
  @Input() set collection(collection: FusionCollectionName) {
    if (!this.resource.queryable(collection)) return;
    this.refresh(collection);
  }
  
  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }
  /** Filter for list of displayed values. */
  @Input() filter?: string[];
  /** True to make a value required. */
  @Input() required = true;
  /** True to allow multiple selection. */
  @Input() multiple = false;
  /** True to allow reselecting collection. */
  @Input() recollection = false;
  /** Color set for select. */
  @Input() color: ColorCode | null = null;

  /** Emits when selected collection changes. */
  @Output() collectionChange = new EventEmitter<FusionCollectionQuery>();
  /** Emits when a new item is selected. */
  @Output() selected = new EventEmitter<string>();

  /** Current selected IDs. */
  value?: string | string[];
  
  /** List of selectable pairs. */
  protected pairs: Pair[] = [];
  /** List of selectable collections. */
  protected collections = this.resource.available();
  /** Collection to pull values from. */
  protected _collection: FusionCollectionQuery = 'codeTypes';

  constructor(
    private resource: ResourceService,
    private elementRef: ElementRef
  ) {
    super();
  }

  writeValue(value?: string | string[]) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988

    // Force selection of a valid ID.
    if (this.required && arraySome(this.pairs)) {
      if (this._multiple) {
        if (!arraySome(value)) value = [this.pairs[0]?.value];
      } else if (Array.isArray(value) || idNull(value)) {
        value = this.pairs[0]?.value;
      }
    }

    this.changed(this.value = value);
    this.resetup();
  }

  /** Callback when new selection emitted from dropdown. */
  onSelection(value: string[]) {
    this.writeValue(this.multiple ? value : value[0]);
  }

  /** Fetch new list of values. */
  protected async refresh(collection: FusionCollectionQuery) {
    let previews = await this.resource.previews(this._collection = collection);
    this.pairs = previews.map(p => new Pair(p._id, p.name));
    this.resetup();
  }

  /** Reconfigure what resource to setup. */
  protected resetup() {
    let _id = arraySingle(this.value) ? this.value[0] : typeof this.value === 'string' ? this.value : undefined;
    setupElementMake(this.elementRef.nativeElement, this._collection, _id);
  }
}
