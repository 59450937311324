import { SearchCategory } from "../../../../../../../common/code/standard/common";
import { SearchFieldData } from "../../../../common/component/search-field/search-field.model";

/** Data passed to claim intake members step. */
export class ClaimIntakeMembersData implements SearchFieldData {

  constructor(
    /** Last search query. */
    public query = '',
    /** Last search category. */
    public category = SearchCategory.MemberName,
    /** True if a search was performed. */
    public search = false
  ) {}
}