import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { _DisposeViewRepeaterStrategy, _RecycleViewRepeaterStrategy, _VIEW_REPEATER_STRATEGY } from '@angular/cdk/collections';
import { CDK_TABLE, CDK_TABLE_TEMPLATE, CdkTable, STICKY_POSITIONING_LISTENER, _COALESCED_STYLE_SCHEDULER, _CoalescedStyleScheduler } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, ContentChildren, Directive, EventEmitter, Input, Output, QueryList, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription, merge, takeUntil } from 'rxjs';
import { ColumnSize, Table } from "../../../../../../common/model/table";
import { ColumnSort, GridFilter } from '../../toolbox/grid';
import { GridSource } from '../../toolbox/source/grid';
import { GridHeaderCell } from './header-cell/grid-header-cell.component';
import { GridHeaderRow } from './header-row/grid-header-row.component';
import { GridRow } from './row/grid-row.component';

@Directive({
  selector: 'app-grid[recycleRows]',
  providers: [ { provide: _VIEW_REPEATER_STRATEGY, useClass: _RecycleViewRepeaterStrategy } ],
})
export class GridRecycleRows {}

@Component({
  selector: 'table[app-grid], app-grid',
  exportAs: 'appGrid',
  template: CDK_TABLE_TEMPLATE,
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    { provide: CdkTable, useExisting: GridComponent },
    { provide: CDK_TABLE, useExisting: GridComponent },
    { provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy },
    { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
    { provide: STICKY_POSITIONING_LISTENER, useValue: null }
  ]
})
export class GridComponent<T extends Object = Object> extends CdkTable<T> {
  /** Header row with select all checkbox. */
  @ContentChildren(GridHeaderRow, { descendants: true }) headers!: QueryList<GridHeaderRow>;
  /** List of header cells that can be sorted and filtered. */
  @ContentChildren(GridHeaderCell, { descendants: true }) cells!: QueryList<GridHeaderCell<T>>;
  /** List of rows with bound values. */
  @ContentChildren(GridRow, { descendants: true }) rows!: QueryList<GridRow<T>>;

  /** True to make this a multiselect table. */
  @Input() set multiple(multiple: BooleanInput) { this.remultiple(multiple); }
  /** True to not allow row selection. */
  @Input() set selectable(selectable: BooleanInput) { this._selectable = coerceBooleanProperty(selectable); }
  /** Set widths of columns. */
  @Input() set sizes(sizes: ColumnSize[] | undefined) { this.resize(sizes); }
  /** True if value selection is required. */
  @Input() required = false;

  /** Emits when a row is selected. */
  @Output() rowChange = new EventEmitter<T>();
  /** Emits when sort changes. */
  @Output() sortChange = new EventEmitter<ColumnSort<T>>();
  /** Emits when current selection changes. */
  @Output() selectionChange = new EventEmitter<T[]>();

  /** Update current source. */
  @Input()
  set source(source: GridSource<T>) { this.resource(source); }
  get source() { return this.dataSource as GridSource<T>; }
  
  /** True if currently expanded. */
  expanded = false;
  /** True if multiple values can be bound. */
  _multiple = false;
  /** True if selections are allowed. */
  _selectable = true;

  /** Style to apply to rows */
  private style = '';
  /** Emits whenever the component is destroyed. */
  private destroy = new Subject<void>();
  /** Get current value of selection. */
  private get value() { return this.source.selection.value; }

  ngAfterContentInit() {
    // Listen to changes on header row(s).
    let headerSub = new Subscription();
    this.headers.changes.pipe(takeUntil(this.destroy)).subscribe((headers: QueryList<GridHeaderRow>) => {
      headerSub.unsubscribe();
      headerSub = new Subscription();
      this.reheader();

      if (!headers.length) return;
      headerSub.add(headers.get(0)!.toggled.subscribe(() => {
        this.source.toggleAll();
        this.selectionChange.next([...this.value]);
      }));
    });

    // Listen to changes on column header cells.
    let cellSub = new Subscription();
    this.cells.changes.pipe(takeUntil(this.destroy)).subscribe((cells: QueryList<GridHeaderCell<T>>) => {
      cellSub.unsubscribe();
      cellSub = new Subscription();

      // Listen to toggles on header cells.
      cellSub.add(merge(...cells.map(header => header.toggled)).subscribe(() => {
        this.expanded = !this.expanded;
      }));

      // Listen to resorts on header cells.
      cellSub.add(merge(...cells.map(header => header.sorted)).subscribe(event => {
        for (let header of this.cells) if (header.column !== event.column) header.direction = undefined;
        this.source.resort(event);
        this.sortChange.next(event);
      }));

      // Listen to refilters on header cells.
      cellSub.add(merge(...cells.map(header => header.filtered)).subscribe(() => {
        this.source.refilter(this.refilter());
      }));
    });

    // Listen to changes on rows.
    let rowSub = new Subscription();
    this.rows.changes.pipe(takeUntil(this.destroy)).subscribe((rows: QueryList<GridRow<T>>) => {
      rowSub.unsubscribe();
      rowSub = new Subscription();
      this.rerow();

      // Emit row click events.
      rowSub.add(merge(...rows.map(row => row.clicked)).subscribe(row => {
        this.value.clear();
        this.source.toggle(row.value);
        this.rowChange.next(row.value);
        this.selectionChange.next([...this.value]);
      }));

      // Emit changes to row selection.
      rowSub.add(merge(...rows.map(row => row.toggled)).subscribe(row => {
        // Don't clear last row if value is required.
        if (this.required && this.value.size === 1 && this.value.has(row.value)) return;

        this.source.toggle(row.value);
        this.selectionChange.next([...this.value]);
      }));
    })
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   *  Update with new data source.
   *  Note: This logic assumes a data source will be bound once and only once.
   */
  private resource(source: GridSource<T>) {
    this.dataSource = source;
    source.selection.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.reheader();
      this.rerow();
    });
  }

  /** Process list of headers into filter. */
  private refilter() {
    let filter: GridFilter<T>[] = [];

    for (let header of this.cells) {
      if (!header.key || !header.filter) continue;
      filter.push([header.key, header.filter]);
    }

    return filter.length ? filter : undefined;
  }

  /** Update multiple mode of grid. */
  private remultiple(multiple: BooleanInput) {
    this._multiple = coerceBooleanProperty(multiple);
    setTimeout(() => {
      for (let header of this.headers ?? []) header.multiple = this._multiple;
      for (let row of this.rows ?? []) row.multiple = this._multiple;
    });
  }

  /** Update style of headers and rows. */
  private resize(sizes?: ColumnSize[]) {
    this.style = Table.css(sizes, this._multiple);
    setTimeout(() => {
      for (let header of this.headers ?? []) header.style = this.style;
      for (let row of this.rows ?? []) row.style = this.style;
    });
  }

  /** Refresh headers. */
  private reheader() {
    setTimeout(() => {
      for (let header of this.headers ?? []) {
        header!.all = this.source.all;
        header.multiple = this._multiple;
        header.required = this.required;
        header.style = this.style;
      }
    });
  }

  /** Refresh rows. */
  private rerow() {
    setTimeout(() => {
      let length = this.rows?.length || 0;
      let values = this.source.filteredItems;
      for (let i = 0; i < length; ++i) {
        let [row, value] = [this.rows.get(i)!, values[i]!];
        if(row.value===undefined) row.value = value;
        row.multiple = this._multiple;
        row.selected = this.source.selection.value.has(value);
        row.style = this.style;
      }
    });
  }
}
