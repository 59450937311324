import { Component } from '@angular/core';
import { arrayFill } from "../../../../../../common/toolbox/array";
import { ColorCode } from "../../../../../../common/toolbox/color";
import { enumValues } from "../../../../../../common/toolbox/enum";
import { randomElement } from "../../../../../../common/toolbox/random";

@Component({
  selector: 'app-demo-color',
  templateUrl: './demo-color.component.html',
  styleUrls: ['./demo-color.component.scss']
})
export class DemoColorComponent {
  /** Get randomly generated list of colors. */
  colors = arrayFill(7 * 7, () => randomElement(enumValues(ColorCode)));

  static title() {
    return 'Color';
  }
}
