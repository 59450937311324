import { TypeInfo } from "../info/type";
import { SmtpAddress } from "./smtp";

/** Maximum count for querying IMAP inbox. */
export const IMAP_LIMIT = 100;

/** Options for IMAP connections. */
export interface ImapOptions {
  /** Host server to read inboxes. */
  host: string
  /** Port for host server. */
  port: number
}

/** Query for searching IMAP inbox. */
export class ImapQuery {
  constructor(
    /** Maximum number of messages to fetch. */
    public limit = IMAP_LIMIT,
    /** Sender of message. */
    public from?: string,
    /** Receiver of message. */
    public to?: string
  ) {}

  static typeinfo: TypeInfo<ImapQuery> = {
    from: '',
    to: ''
  }
}

/** Preview of an already-sent SMTP message. */
export class ImapPreview {
  constructor(
    /** Author of email. Defaults to configured server address. */
    public from = new SmtpAddress(),
    /** Destination for email. */
    public to: SmtpAddress[] = [],
    /** Subject line of email. */
    public subject = ''
  ) {}

  static typeinfo: TypeInfo<ImapPreview> = {
    to: [new SmtpAddress()]
  }
}