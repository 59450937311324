import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { PropertyType } from "../../../../../../common/model/property-type";
import { ObjectKeys } from "../../../../../../common/toolbox/object";
import { DAYS_MAX, DAYS_MIN, DatePosition, DateRange, dateCompare } from "../../../../../../common/toolbox/time";
import { fieldControlProviders } from '../../toolbox/angular';
import { PROPERTY_TYPE_ICON } from '../../toolbox/property';
import { FieldControl } from '../field/field-control';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: fieldControlProviders(DateRangeComponent)
})
export class DateRangeComponent extends FieldControl implements ControlValueAccessor {
  readonly PropertyType = PropertyType;
  readonly PROPERTY_TYPE_ICON = PROPERTY_TYPE_ICON;

  /** Current disabled state. */
  @Input() set disabled(disabled: BooleanInput) { this._disabled = coerceBooleanProperty(disabled); }
  /** True to disable editing. */
  @Input() set readonly(readonly: BooleanInput) { this.setReadonlyState(readonly); }

  /** Minimum value. */
  @Input() min = DAYS_MIN;
  /** Maximum value. */
  @Input() max = DAYS_MAX;
  /** True to make a value required. */
  @Input() required = true;

  /** Emits when value changes as a result of input or picker selection. */
  @Output() selected = new EventEmitter<DateRange | undefined>();

  /** Value bound to date field. */
  value: DateRange | undefined;
  /** Value bound to first date field. */
  start: Date | undefined | null = null;
  /** Value bound to second date field. */
  end: Date | undefined | null = null;

  writeValue(value?: DateRange) {
    if (value === null) return; // see https://github.com/angular/angular/issues/14988
    if (DateRange.equal(this.value, value)) return;

    this.changed(this.value = value);
    this.start = value?.start;
    this.end = value?.end;
  }

  writePart(value: Date, part: ObjectKeys<DateRange, Date>) {
    let range = DateRange.range(this.value);
    range[part] = value;

    // Clamp value into valid range.
    if (part === 'start' && dateCompare(range.start, range.end) === DatePosition.After) range.end = range.start;
    if (part === 'end' && dateCompare(range.end, range.start) === DatePosition.Before) range.start = range.end;
    this.writeValue(DateRange.clamp(range, DateRange.minmax(this.min, this.max)));
  }

  /** Callback when user types into date range fields. */
  onSelect() {
    this.selected.next(this.value);
  }
}