import { Component, Inject, Optional } from '@angular/core';
import { DIALOG_DATA } from '../../../../common/component/dialog/dialog.model';
import { TaskData } from '../../task.model';

@Component({
  selector: 'app-task-message',
  templateUrl: './task-message.component.html',
  styleUrls: ['./task-message.component.scss']
})
export class TaskMessageComponent {

  /** Message for task. */
  message = this.data?.task.message;

  constructor(
    @Optional() @Inject(DIALOG_DATA)
    public data: TaskData | undefined
  ) {}
}
