import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { DisplayPartial, DisplayType, DisplayValue } from "../../../../../../common/model/display";
import { ApplicationSettingsKey } from '../../../../../../common/model/setting-group';
import { ID_DEFAULT } from '../../../../../../common/toolbox/id';
import { TAB_DATA } from '../../../common/component/tab/bar/tab-bar.model';
import { ClaimService } from '../../../common/service/claim.service';
import { LogService } from '../../../common/service/log.service';
import { TableService } from '../../../common/service/table.service';
import { ResultsComponent } from '../../../common/toolbox/results/results.component';
import { ClaimResultsData } from './claim-results.model';
import { ClaimJoin } from '../../../../../../common/model/claim/claim';

@Component({
  selector: 'app-claim-results',
  templateUrl: '../../../common/toolbox/results/results.component.html',
  styleUrls: ['../../../common/toolbox/results/results.component.scss']
})
export class ClaimResultsComponent extends ResultsComponent<'claims'> {

  route: 'claims' = 'claims';
  table: ApplicationSettingsKey = 'disputes.claim.table';
  type = DisplayType.Claim;

  static override title() {
    return 'Claim Results';
  }

  constructor(
    @Inject(TAB_DATA)
    data: ClaimResultsData,
    log: LogService,
    tables: TableService,
    http: HttpClient,
    private claims: ClaimService
  ) {
    super(data, http, log, tables);
  }

  /** Callback when opening specified claim. */
  async onRow(partial: DisplayPartial) {
    this.claims.open({
      _ids: [partial.claim?._id ?? ID_DEFAULT],
      _insts: [partial.claim?._inst ?? ID_DEFAULT]
    });
  }
  override toPartial(item: ClaimJoin): Partial<DisplayValue> {
    return DisplayValue.claims([item])[0]!;
  }
}
