import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Step, StepStatus } from '../../../../../../common/model/step';
import { HasName } from '../../../../../../common/toolbox/id';
import { MenuTriggerDirective } from '../menu/item/menu-trigger.directive';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  host: {
    class: 'column'
  }
})
export class StepperComponent<T extends HasName = HasName> {
  readonly StepStatus = StepStatus;
  readonly NEW_STEP = new Step({ name: 'Add New' });

  /** Reference to menu directives in component. */
  @ViewChildren('step') menus!: QueryList<MenuTriggerDirective>;

  /** Current step. */
  @Input() current = 0;
  /** Step being renamed. */
  @Input() renaming?: number;
  /** Menu to open on clicking step. */
  @Input() menu?: MenuComponent;

  /** True to allow manual navigation. */
  @Input() navigate = true;
  /** List of steps. */
  @Input() steps: Step<T>[] = [];
  /** False if steps can be edited and dragged. */
  @Input() readonly = true;
  /** True to display delete button. */
  @Input() deletable = true;

  /** Emits when current step changes. */
  @Output() currentChange = new EventEmitter<number>();
  /** Emits when step being renamed changes. */
  @Output() renamingChange = new EventEmitter<number | undefined>();

  /** Emits when a step is directly visited. */
  @Output() step = new EventEmitter<Step<T>>();
  /** Emits when a new step should be created. */
  @Output() created = new EventEmitter();

  /** Callback when directly visiting a step. */
  onStep(i: number) {
    if (this.steps[i]?.locked) return;
    if (i === this.current) {
      // Open menu.
      this.menus.get(i)?.open();
    } else {
      // Navigate to step.
      this.currentChange.next(this.current = i);
      this.step.emit(this.steps[this.current]);
    }
  }

  /** Callback when adding a new step. */
  onNew() {
    this.created.next(null);
  }
}
