import { ErrorResponse } from "../message/error";
import { Permission, PermissionMask, PermissionRecord, PERMISSION_LIST, PERMISSION_NAME } from "../model/permission";
import { objectEntries } from "./object";

/** Format out a standard permission error. */
export function permissionError(...permissions: Permission[]) {
  return new ErrorResponse(`Missing required permission${permissions.length > 1 ? 's' : ''}: ${permissions.map(p => PERMISSION_NAME[p]).join(', ')}`)
}

/** Check if value exists in permission mask. */
export function permissionHas(mask: PermissionMask, permission: Permission, system = true): boolean {
  let flags = BigInt(mask);
  if (!!(flags & (1n << BigInt(permission)))) return true;

  // Lacks specific permission, check system.
  return !system || permission === Permission.System ? false : !!(flags & (1n << BigInt(Permission.System)));
}

/** Compress list of permissions into bitmask. */
export function permissionMask(value: PermissionMask | PermissionRecord | Permission[]): PermissionMask {
  value = permissionArray(value);
  let mask = 0n;

  for (let p of value) {
    mask |= (1n << BigInt(p));
  }

  return `${mask}`;
}

/** Convert value to array of permissions. */
export function permissionArray(value: PermissionMask | PermissionRecord | Permission[]): Permission[] {
  if (Array.isArray(value)) return value;
  if (typeof value === 'object') return objectEntries(value).filter(([, has]) => has).map(([permission]) => +permission);
  let array: Permission[] = [];
  let mask = BigInt(value);

  for (let p of PERMISSION_LIST) {
    if (mask & (1n << BigInt(p))) array.push(p);
  }

  return array;
}

/** Convert value to record of permissions. */
export function permissionRecord(value: PermissionMask | PermissionRecord | Permission[], system = true): PermissionRecord {
  let record: PermissionRecord = {};
  value = permissionMask(value);

  for (let p of PERMISSION_LIST) {
    if (permissionHas(value, p, system)) record[p] = true;
  }
  
  return record;
}