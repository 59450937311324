import { ValidationStatus, Validator, ValidatorLog } from "./base";

/** Null validator that allows no object to pass. */
export class NeverValidator extends Validator<never> {
  constructor() { super(true); }

  value(): never { return undefined as never; }

  parse(_: string) { return _ as never; }

  schema() {
    return this.property;
  }

  override validate(_: never) {
    return ValidationStatus.Error;
  }

  override logs() { return [new ValidatorLog('Expected never.')]; }
}