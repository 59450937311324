import { ClaimStatus, DisputeCreditStatus, DisputeStatus } from "../../code/standard/disputes";
import { DisputesCode } from "../../code/system";
import { IndexInfo } from "../../info";
import { CollectionInfo } from "../../info/collection";
import { PropertyInfo } from "../../info/prop";
import { TypeInfo } from "../../info/type";
import { UnionInfo, unioninfoRetype } from "../../info/union";
import { ColorCode } from "../../toolbox/color";
import { EnumMap } from "../../toolbox/enum";
import { ID_DEFAULT, MaybeId } from "../../toolbox/id";
import { EnumValidator } from "../../validator/enum";
import { FusionCollection } from "../fusion";
import { Icon } from "../icon";
import { PropertyType } from "../property-type";

/** Configuration for a specific claim filter type. */
export type ClaimFilter =
  | ClaimFilterAssigned
  | ClaimFilterClaimStatus
  | ClaimFilterDisputeStatus
  | ClaimFilterDisputeCreditStatus;

/** Type of hardcoded claim filter types. */
export enum ClaimFilterType {
  Assigned    = 'assigned',
  ClaimStatus  = 'claimStatus',
  DisputeStatus = 'disputeStatus',
  DisputeCreditStatus = 'disputeCreditStatus'
};

/** Base fields shared by all filter types. */
export class ClaimFilterBase {
  constructor(
    /** Institution of this filter. */
    public _id = ID_DEFAULT,
    /** Institution configuring this filter. */
    public _inst = ID_DEFAULT,
    /** Name of filter. */
    public name = '',
    /** Icon used when displaying this filter in the UI. */
    public icon = Icon.Search,
    /** Color used when displaying this filter in the UI. */
    public color = ColorCode.Primary
  ) {}

  static indexinfo: IndexInfo<ClaimFilter> = [
    { key: { _inst: 1, name: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

  static collectioninfo: CollectionInfo<FusionCollection, ClaimFilter> = {
    _inst: 'institutions'
  }

  /** Set new type for claim filter. */
  static retype<T extends ClaimFilterType>(filter: ClaimFilter, type: T): ClaimFilterClass[T]
  static retype<T extends ClaimFilterType>(filter: MaybeId<ClaimFilter>, type: T): MaybeId<ClaimFilterClass[T]>
  static retype<T extends ClaimFilterType>(filter: MaybeId<ClaimFilter>, type: T): MaybeId<ClaimFilterClass[T]> {
    return unioninfoRetype<MaybeId<ClaimFilterBase>, MaybeId<ClaimFilter>, ClaimFilterType>(new ClaimFilterBase(), filter, type, CLAIM_FILTER_UNIONINFO) as MaybeId<ClaimFilterClass[T]>;
  }
}

/** Configuration for an assigned filter. */
export class ClaimFilterAssigned extends ClaimFilterBase {
  readonly type = ClaimFilterType.Assigned;
  
  constructor(
    /** True to fetch unassigned. */
    public unassigned?: boolean
  ) {
    super();
  }

  static typeinfo: TypeInfo<ClaimFilterAssigned> = {
    unassigned: true
  };
}

/** Configuration for a claim status filter. */
export class ClaimFilterClaimStatus extends ClaimFilterBase {
  readonly type = ClaimFilterType.ClaimStatus;

  constructor(
    /** Status to filter. */
    public status = ClaimStatus.Initiated
  ) {
    super();
  }

  static propinfo: PropertyInfo<ClaimFilterClaimStatus> = {
    status: { type: PropertyType.Code, category: DisputesCode.ClaimStatus }
  }
}

/** Configuration for a dispute status filter. */
export class ClaimFilterDisputeStatus extends ClaimFilterBase {
  readonly type = ClaimFilterType.DisputeStatus;

  constructor(
    /** Status to filter. */
    public status = DisputeStatus.Approved
  ) {
    super();
  }

  static propinfo: PropertyInfo<ClaimFilterDisputeStatus> = {
    status: { type: PropertyType.Code, category: DisputesCode.DisputeStatus }
  }
}

/** Configuration for a dispute credit status filter. */
export class ClaimFilterDisputeCreditStatus extends ClaimFilterBase {
  readonly type = ClaimFilterType.DisputeCreditStatus;

  constructor(
    /** Status to filter. */
    public status = DisputeCreditStatus.None
  ) {
    super();
  }

  static typeinfo: TypeInfo<ClaimFilterDisputeCreditStatus> = {
    status: new EnumValidator(DisputeCreditStatus)
  }

  static propinfo: PropertyInfo<ClaimFilterDisputeCreditStatus> = {
    status: { type: PropertyType.Code, category: DisputesCode.DisputeCreditStatus }
  }
}

/** A preview for a claim filter. */
export class ClaimFilterPreview {
  constructor(
    /** ID of this filter. */
    public _id = ID_DEFAULT,
    /** Name of filter. */
    public name = '',
    /** Type of filter. */
    public type = ClaimFilterType.Assigned
  ) {}
}

/** Mapping of claim filter types to configuration objects. */
export class ClaimFilterClass {
  [ClaimFilterType.Assigned] = new ClaimFilterAssigned();
  [ClaimFilterType.ClaimStatus] = new ClaimFilterClaimStatus();
  [ClaimFilterType.DisputeStatus] = new ClaimFilterDisputeStatus();
  [ClaimFilterType.DisputeCreditStatus] = new ClaimFilterDisputeCreditStatus();
}

/** Union type information for claim filters. */
export const CLAIM_FILTER_UNIONINFO: UnionInfo<ClaimFilter, ClaimFilterType> = {
  tag: 'type',
  classes: new ClaimFilterClass()
};

/** Name of each claim filter type. */
export const CLAIM_FILTER_NAME: EnumMap<ClaimFilterType> = {
  [ClaimFilterType.Assigned]: 'Assigned',
  [ClaimFilterType.ClaimStatus]: 'Claim Status',
  [ClaimFilterType.DisputeStatus]: 'Dispute Status',
  [ClaimFilterType.DisputeCreditStatus]: 'Dispute Credit Status'
};