import { ISO_TIMESTAMP_REGEX } from "../toolbox/time";
import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";

/** Validate object is a date. */
export class DateValidator extends Validator<Date> {

  value() {
    return new Date();
  }

  parse(value: string) {
    if (this.undefined(value)) return undefined;
    return new Date(value);
  }

  schema() {
    return this.property ?? { bsonType: 'date' };
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    if (value instanceof Date || ISO_TIMESTAMP_REGEX.test(value)) return ValidationStatus.Okay;
    return ValidationStatus.Error;
  }

  override logs() { return [new ValidatorLog(`Expected date in format YYYY-MM-DDTHH:mm:ss.sssZ.`)]; }
}