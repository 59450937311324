import { propinfoProperty } from "../../info/prop";
import { TypeValidation } from "../../info/type";
import { Engine } from "../../model/formula/engine";
import { PLACEHOLDER_STATEMENT } from "../../model/formula/placeholder";
import { Statement, StatementType } from "../../model/formula/statement";
import { Property } from "../../model/property";
import { SCHEMA_OBJECT } from "../../model/schema/base";
import { CustomValidator } from "../../validator/custom";
import { enumHas } from "../enum";
import { keyNestedSet } from "../keys";
import { ObjectKeys } from "../object";
import { expressionContext, expressionProperty, expressionRun, expressionValidateKey } from "./expression";

/** A validator for a statement. */
export class StatementValidator extends CustomValidator<Statement> {

  value() {
    return PLACEHOLDER_STATEMENT;
  }

  parse(text: string) {
    return JSON.parse(text);
  }

  schema() {
    return SCHEMA_OBJECT;
  }

  check = statementValidate;
}

/** Evaluate a statement. */
export function statementRun<T>(statement: Statement<T>) {
  switch (statement.type) {
  case StatementType.Return:
    Engine.output = expressionRun(statement.expression);
    Engine.return = true;
    break;
  case StatementType.If:
    if (expressionRun(statement.if)) {
      statementsRun(statement.then);
      return;
    }

    if (statement.else) {
      statementsRun(statement.else);
      return;
    }

    break;
  case StatementType.For:
    let value = expressionRun(statement.expression);
    statementLoop(value, statement.statements);
    break;
  case StatementType.Assignment:
    // Find innermost context where assignment makes sense.
    let input = Engine.context(input => propinfoProperty(statement.left.key, input) ? input : undefined);

    if (input) {
      // Inform execution context this key was modified.
      keyNestedSet(statement.left.key, input, expressionRun(statement.right));
      Engine.mutated.add(statement.left.key);
    }

    break;
  }
}

/** Evaluate a statement list. */
export function statementsRun<T>(statements: Statement<T>[]) {
  for (let statement of statements) {
    statementRun(statement);
    if (Engine.return) return Engine.output;
  }
}

/** Perform a loop over a value in formula system. */
export function statementLoop<T extends Statement>(array: any, statements: T[], callback: (input: any) => void = () => {}) {
  if (!Array.isArray(array)) return;

  for (let item of array) {
    Engine.input.push(item);
    Engine.return = false;

    statementsRun(statements);
    callback(item);

    Engine.input.pop();
  }
  
  Engine.return = false;
}

/** Formats out a statement's query. */
export function statementQuery<T>(s: Statement<T>): any {
  switch (s.type) {
  case StatementType.Return:
    return expressionRun(s.expression);
  case StatementType.If:
    return {
      $cond: {
        if: expressionRun(s.if),
        then: s.then.map(statementRun),
        else: s.else ? s.else.map(statementRun) : undefined
      }
    };
  }
}

/** Get property of a statement. */
export function statementProperty<T>(root: Statement<T>[], statement: Statement<T> | Statement<T>[] | undefined, input: T): Property | undefined {
  if (!statement) return;

  if (Array.isArray(statement)) {
    for (let substatement of statement) {
      let subproperty = statementProperty(root, substatement, input);
      if (subproperty) return subproperty;
    } return;
  }

  switch (statement.type) {
  case StatementType.Return:
    return expressionProperty(root, statement.expression, input);
  case StatementType.If:
    return statementProperty(root, statement.then, input) || statementProperty(root, statement.else, input);
  case StatementType.For:
    return statementProperty(root, statement.statements, input);
  case StatementType.Assignment:
    return undefined;
  default:
    /* angular-tslint:disable:no-unused-variable */
    const _: never = statement;
    return _;
  }
}

/** Validate a statement. */
export function statementValidate<T>(statement: Statement<T>): TypeValidation<Statement<T>> {
  if (!enumHas(StatementType, statement.type)) return 'type';

  switch (statement.type) {
  case StatementType.Return:
    return expressionValidateKey(statement, 'expression');
  case StatementType.If:
    return expressionValidateKey(statement, 'if') ?? statementValidateArray(statement, 'then') ?? (statement.else ? statementValidateArray(statement, 'else') : undefined);
  case StatementType.For:
    return expressionValidateKey(statement, 'expression') ?? statementValidateArray(statement, 'statements');
  case StatementType.Assignment:
    return expressionValidateKey(statement, 'left') ?? expressionValidateKey(statement, 'right');
  }
}

/** Perform a statement validation on an key. */
export function statementValidateKey<T>(value: T, key: ObjectKeys<T, Statement>): TypeValidation<Statement> {
  if (value[key] === undefined) return String(key) as any;
  let validation = statementValidate(value[key] as unknown as Statement<T>);
  if (validation) return `${String(key)}.${validation}` as any;
}

/** Perform a statement validation on an index. */
export function statementValidateArray<T>(value: T, key: ObjectKeys<T, Statement[] | undefined>): TypeValidation<Statement> {
  let statements = (value[key] ?? []) as unknown as Statement<T>[];
  for (let i = 0; i < statements.length; ++i) {
    let validation = statementValidate(statements[i]!);
    if (validation) return `${String(key)}[${i}].${validation}` as any;
  }
}

/**
 *  Determine type hint context at a particular point in a statement.
 *  Note: Only identifiers of for loops create new contexts, so [...context].
 */
export function statementContext<T>(statement: Statement<T> | Statement<T>[] | undefined, target: any, context: string[]): string[] | undefined {
  if (statement === target) return context;
  if (!statement) return;

  if (Array.isArray(statement)) {
    for (let substatement of statement) {
      let subcontext = statementContext(substatement, target, context);
      if (subcontext) return subcontext;
    } return;
  }

  switch (statement.type) {
  case StatementType.If:
    return expressionContext(statement.if, target, [...context]) ?? statementContext(statement.then, target, [...context]) ?? statementContext(statement.else, target, [...context]);
  case StatementType.For:
    return expressionContext(statement.expression, target, context) ?? statementContext(statement.statements, target, [...context]);
  case StatementType.Return:
    return expressionContext(statement.expression, target, [...context]);
  case StatementType.Assignment:
    return expressionContext(statement.left, target, [...context]) ?? expressionContext(statement.right, target, [...context]);
  }
}