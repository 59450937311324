import { ValidationOptions, ValidationStatus, Validator, ValidatorLog } from "./base";

/** Validate object matches a pattern. */
export class PatternValidator extends Validator<string> {

  constructor(
    /** Pattern that must be matched. */
    public pattern: RegExp
  ) {
    super();
  }

  value() {
    return '';
  }

  parse(value: string) {
    if (this.undefined(value)) return undefined;
    return this.pattern.test(value) ? value : undefined;
  }

  schema() {
    return this.property ?? {
      bsonType: 'string',
      pattern: this.pattern.source
    };
  }

  override validate(value: any, options?: ValidationOptions) {
    if (this.implicit(value, options)) return ValidationStatus.Okay;
    return this.pattern.test(value) ? ValidationStatus.Okay : ValidationStatus.Error;
  }

  override logs() { return [new ValidatorLog(`Expected string matching pattern: ${this.pattern.source}.`)]; }
}