import { Component } from '@angular/core';
import { TerminalDate } from "../../../../../../../../common/model/formula/terminal";
import { DAYS_MAX, DAYS_MIN } from "../../../../../../../../common/toolbox/time";
import { BlockComponent } from '../../block.component';

@Component({
  selector: 'app-setup-formula-date',
  templateUrl: './setup-formula-date.component.html',
  styleUrls: ['../../block.component.scss', './setup-formula-date.component.scss']
})
export class SetupFormulaDateComponent extends BlockComponent<TerminalDate> {
  readonly DAYS_MIN = DAYS_MIN;
  readonly DAYS_MAX = DAYS_MAX;
}
