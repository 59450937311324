import { IndexInfo } from "../info";
import { CollectionInfo } from "../info/collection";
import { TypeInfo } from "../info/type";
import { ID_DEFAULT } from "../toolbox/id";
import { Email } from "./email";
import { FusionCollection } from "./fusion";
import { Phone } from "./phone";

/** One of many banks or credit unions being serviced. */
export class Institution {
  constructor(
    /** Unique identifier of institution. */
    public _id = ID_DEFAULT,
    /** Organization this institution belongs to. */
    public _org = ID_DEFAULT,
    /** Primary address of institution. */
    public _address = ID_DEFAULT,
    /** Institution wide default settings. */
    public _settingGroup = ID_DEFAULT,
    /** Name of institution. */
    public name = '',
    /** Organization-assigned identifier of institution. */
    public hostId = '',
    /** Phones of institution. */
    public phones: Phone[] = [],
    /** Email contacts of institution. */
    public emails: Email[] = [],
    /** True to use bank terminology for unconfigurable interface values. */
    public bank = false
  ) { }

  static typeinfo: TypeInfo<Institution> = {
    phones: [new Phone()],
    emails: [new Email()]
  };

  static collectioninfo: CollectionInfo<FusionCollection, Institution> = {
    _org: 'organizations',
    _address: 'addresses',
    _settingGroup: 'settingGroups'
  };

  static indexinfo: IndexInfo<Institution> = [
    { key: { _org: 1, name: 1 }, unique: true },
    { key: { _org: 1, hostId: 1 }, unique: true },
    { key: { name: 'text' }, collation: { locale: 'simple', strength: 2 } }
  ];

}

/** A short preview for an institution displayed in a list. */
export class InstitutionPreview {
  constructor(
    /** Unique identifier of institution. */
    public _id = ID_DEFAULT,
    /** Name of institution. */
    public name = ''
  ) { }
}

/** A reference to a specific item of an institution */
export class InstitutionItem {
  constructor(
    /** Institution of resource. */
    public _inst = ID_DEFAULT,
    /** ID of resource. */
    public _id = ID_DEFAULT
  ) { }
}
