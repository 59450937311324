import { ArraySome } from "../../toolbox/array";
import { ConditionType } from "./condition";
import { Expression } from "./expression";
import { OperatorType } from "./operator";
import { PLACEHOLDER_CONDITION, PLACEHOLDER_EXPRESSION, PLACEHOLDER_STATEMENT, Placeholder, PlaceholderType } from "./placeholder";
import { Statement, StatementType } from "./statement";
import { TerminalType } from "./terminal";

/** A code block that can be added to a formula. */
export type Block = Expression | Statement | Placeholder;
/** A union of all possible block types. */
export type BlockUnion = TerminalType | OperatorType | ConditionType | StatementType | PlaceholderType;

/** Types of blocks. */
export enum BlockType {
  Condition = 'condition',
  Expression = 'expression',
  Statement = 'statement'
}

/** Mapping of block types to placeholders. */
export const BLOCK_PLACEHOLDER: Record<BlockType, Block> = {
  [BlockType.Condition]: PLACEHOLDER_CONDITION,
  [BlockType.Expression]: PLACEHOLDER_EXPRESSION,
  [BlockType.Statement]: PLACEHOLDER_STATEMENT
}

/** Mapping of expression types to block types. */
export const BLOCK_TYPE: Record<BlockUnion, ArraySome<BlockType>> = {
  [TerminalType.Boolean]: [BlockType.Expression],
  [TerminalType.Number]: [BlockType.Expression],
  [TerminalType.String]: [BlockType.Expression],
  [TerminalType.Currency]: [BlockType.Expression],
  [TerminalType.Date]: [BlockType.Expression],
  [TerminalType.Code]: [BlockType.Expression],
  [TerminalType.CodeList]: [BlockType.Expression],
  [TerminalType.Identifier]: [BlockType.Expression],
  [TerminalType.Array]: [BlockType.Expression],
  [TerminalType.Permission]: [BlockType.Expression],
  [TerminalType.Resource]: [BlockType.Expression],
  [TerminalType.Undefined]: [BlockType.Expression],
  
  [OperatorType.Not]: [BlockType.Expression],
  [OperatorType.Length]: [BlockType.Expression],
  [OperatorType.Display]: [BlockType.Expression],
  [OperatorType.Map]: [BlockType.Expression],
  [OperatorType.Max]: [BlockType.Expression],
  [OperatorType.Min]: [BlockType.Expression],
  [OperatorType.Filter]: [BlockType.Expression],
  [OperatorType.Sum]: [BlockType.Expression],
  [OperatorType.Add]: [BlockType.Expression],
  [OperatorType.Subtract]: [BlockType.Expression],
  [OperatorType.Multiply]: [BlockType.Expression],
  [OperatorType.Divide]: [BlockType.Expression],
  [OperatorType.Access]: [BlockType.Expression],
  [OperatorType.Index]: [BlockType.Expression],
  [OperatorType.Join]: [BlockType.Expression],
  [OperatorType.Pad]: [BlockType.Expression],
  [OperatorType.Slice]: [BlockType.Expression],
  [OperatorType.Granted]: [BlockType.Expression],

  [ConditionType.Every]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Some]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.And]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Or]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Equal]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Unequal]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Greater]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Lesser]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.GreaterEqual]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.LesserEqual]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Like]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Between]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.In]: [BlockType.Expression, BlockType.Condition],
  [ConditionType.Match]: [BlockType.Expression, BlockType.Condition],

  [StatementType.Return]: [BlockType.Statement],
  [StatementType.If]: [BlockType.Statement],
  [StatementType.For]: [BlockType.Statement],
  [StatementType.Assignment]: [BlockType.Statement],

  [PlaceholderType.Expression]: [BlockType.Expression],
  [PlaceholderType.Statement]: [BlockType.Statement],
  [PlaceholderType.Condition]: [BlockType.Expression]
};

/** Name of each block type. */
export const BLOCK_NAME = [
  [TerminalType, 'TerminalType'],
  [OperatorType, 'OperatorType'],
  [ConditionType, 'ConditionType'],
  [StatementType, 'StatementType'],
  [PlaceholderType, 'PlaceholderType']
];

/** Mapping of block types to source code names. */
export const BLOCK_NAME_MAP = new Map(BLOCK_NAME.flatMap(
  ([type, name]) => Object.entries(type as any).map(([key, value]) => [value, `${name}.${key}`])
));