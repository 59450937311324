import { Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ClaimType } from '../../../../../../common/code/standard/disputes';
import { DisplayPartial } from "../../../../../../common/model/display";
import { ApplicationSettingsKey } from '../../../../../../common/model/setting-group';
import { Step, StepStatus } from '../../../../../../common/model/step';
import { idNull } from "../../../../../../common/toolbox/id";
import { Newable } from "../../../../../../common/toolbox/object";
import { StatusLevel } from "../../../../../../common/toolbox/status";
import { DialogService } from '../../../common/component/dialog/dialog.service';
import { GridConfig } from '../../../common/component/model/grid/model-grid.model';
import { TAB_DATA, TabRef } from '../../../common/component/tab/bar/tab-bar.model';
import { AuthService } from '../../../common/service/auth.service';
import { ClaimService } from '../../../common/service/claim.service';
import { LogService } from '../../../common/service/log.service';
import { MemberService } from '../../../common/service/member.service';
import { TableService } from '../../../common/service/table.service';
import { ClientSource } from '../../../common/toolbox/source/client';
import { ServerSource } from '../../../common/toolbox/source/server';
import { ClaimIntakeAccountsComponent } from './accounts/claim-intake-accounts.component';
import { ClaimIntakeAccountsData } from './accounts/claim-intake-accounts.model';
import { CLAIM_INTAKE_STEP_STATE, ClaimIntakeData, ClaimIntakeState } from './claim-intake.model';
import { ClaimIntakeDetailsComponent } from './details/claim-intake-details.component';
import { ClaimIntakeDetailsData } from './details/claim-intake-details.model';
import { ClaimIntakeMembersComponent } from './members/claim-intake-members.component';
import { ClaimIntakeMembersData } from './members/claim-intake-members.model';
import { ClaimIntakeStepComponent } from './step/claim-intake-step.component';
import { ClaimIntakeSummaryComponent } from './summary/claim-intake-summary.component';
import { ClaimIntakeSummaryData } from './summary/claim-intake-summary.model';
import { ClaimIntakeTransactionsComponent } from './transactions/claim-intake-transactions.component';
import { ClaimIntakeTransactionsData } from './transactions/claim-intake-transactions.model';

/** Data for each claim intake step. */
class IntakeStep<T extends ClaimIntakeStepComponent = ClaimIntakeStepComponent> {
  constructor(
    /** Name of step. */
    public name = '',
    /** Component to open. */
    public component: Newable<T>,
    /** Data to pass to component. */
    public data: T['data']
  ) {}
}

@Component({
  selector: 'app-claim-intake',
  templateUrl: './claim-intake.component.html',
  styleUrls: ['./claim-intake.component.scss'],
  host: {
    class: 'column fill'
  }
})
export class ClaimIntakeComponent {

  /** List of steps in each claim type. */
  readonly CLAIM_STEPS: Record<ClaimType, IntakeStep[]> = {
    [ClaimType.ACH]: [
      new IntakeStep('Choose Account Holder', ClaimIntakeMembersComponent, new ClaimIntakeMembersData()),
      new IntakeStep('Choose Account', ClaimIntakeAccountsComponent, new ClaimIntakeAccountsData()),
      new IntakeStep('Choose Transaction', ClaimIntakeTransactionsComponent, new ClaimIntakeTransactionsData()),
      new IntakeStep('Claim Details', ClaimIntakeDetailsComponent, new ClaimIntakeDetailsData()),
      new IntakeStep('Review and Submit', ClaimIntakeSummaryComponent, new ClaimIntakeSummaryData())
    ],
    [ClaimType.Card]: [
      new IntakeStep('Choose Cardholder', ClaimIntakeMembersComponent, new ClaimIntakeMembersData()),
      new IntakeStep('Choose Account', ClaimIntakeAccountsComponent, new ClaimIntakeAccountsData()),
      new IntakeStep('Choose Transactions', ClaimIntakeTransactionsComponent, new ClaimIntakeTransactionsData()),
      new IntakeStep('Claim Details', ClaimIntakeDetailsComponent, new ClaimIntakeDetailsData()),
      new IntakeStep('Review and Submit', ClaimIntakeSummaryComponent, new ClaimIntakeSummaryData())
    ],
    [ClaimType.Check]: [
      new IntakeStep('Choose Account Holder', ClaimIntakeMembersComponent, new ClaimIntakeMembersData()),
      new IntakeStep('Choose Account', ClaimIntakeAccountsComponent, new ClaimIntakeAccountsData()),
      new IntakeStep('Choose Transactions', ClaimIntakeTransactionsComponent, new ClaimIntakeTransactionsData()),
      new IntakeStep('Claim Details', ClaimIntakeDetailsComponent, new ClaimIntakeDetailsData()),
      new IntakeStep('Review and Submit', ClaimIntakeSummaryComponent, new ClaimIntakeSummaryData())
    ],
    [ClaimType.CreditBureau]: []
  };

  /** Container for current intake step. */
  @ViewChild('container', {read: ViewContainerRef}) container!: ViewContainerRef;

  /** Data shared between all claim intake steps. */
  state = new ClaimIntakeState();
  /** Current step in process. */
  current = 0;

  /** List of steps in claim intake process. */
  steps: Step<IntakeStep>[];

  /** Form to display for account. */
  accountForm: ApplicationSettingsKey;
  /** Emits when subscriptions should be cleared. */
  clear = new Subject<void>();
  /** Configuration for displaying transactions. */
  config = new GridConfig<DisplayPartial>(new ServerSource());

  /** Default title for tab. */
  static title() {
    return 'New Claim';
  }

  constructor(
    @Inject(TAB_DATA)
    data: ClaimIntakeData,
    private claims: ClaimService,
    private log: LogService,
    private dialog: DialogService,
    private tables: TableService,
    private injector: Injector,
    private auth: AuthService,
    private ref: TabRef,
    private members: MemberService,
  ) {
    this.accountForm = `disputes.${data.type}.intake.accountForm`;
    this.steps = this.CLAIM_STEPS[data.type].map(data => ({ name: data.data.title, locked: true, data }));
    if(this.steps[0]) this.steps[0].locked = false;
    if (!this.steps.length) return;

    this.claims.create(data.type).then(claim => {
      this.state = new ClaimIntakeState(claim);
      this.goto(0);
    });
  }

  async ngOnInit () {
    let table = await this.tables.fallback(`disputes.${this.state.claim.type}.claim.transactionTable`, 'general.transaction.table');
    let partials = this.state.transactions.map<DisplayPartial>(transaction => ({ transaction }));
    this.config = new GridConfig(new ClientSource(partials), table);
  }

  cancel() {
    this.ref.close();
  }

  goto(i: number) {
    let step = this.steps[i];
    if (!step) {
      this.log.show(`Step index out of range: ${i}`, StatusLevel.Alert);
      return;
    }

    let injector = Injector.create({
      parent: this.injector,
      providers: [{
        provide: TAB_DATA,
        useValue: step.data.data
      }, {
        provide: CLAIM_INTAKE_STEP_STATE,
        useValue: this.state
      }]
    });

    // Listen to step change prompt on child component.
    this.current = i;
    this.clear.next();
    this.container.clear();
    this.state.name = step.data.name;
    let instance = this.container.createComponent(step.data.component, { injector }).instance;

    // Change tab when tab requests.
    instance.offset.pipe(takeUntil(this.clear)).subscribe(offset => this.restep(this.current + offset));
    // Change tab when tab requests.
    instance.lock.pipe(takeUntil(this.clear)).subscribe(_ => this.lockSteps(this.current+1));

    // Update list of transactions when tab provides new one.
    instance.transactions.pipe(takeUntil(this.clear)).subscribe(transactions => {
      this.config.source.items = transactions.map(transaction => ({ transaction }));
    });
  }

  // Close current tab or return to first step.
  close() {
    if (this.ref.tab.closeable) return this.ref.close();
    this.state = new ClaimIntakeState();
    this.goto(0);
  }

  // Open the member details page.
  onDetails() {
    let member = this.state.member;
    if (!idNull(member._id)) this.members.open({ _inst: this.auth._inst, _ids: [member._id] });
  }

  /** Callback when navigating to particular step. */
  async restep(index: number) {
    if (index < 0) {
      let confirm = await this.dialog.confirm('Your changes will not be saved.', 'Cancel New Claim?');
      if (confirm == true) this.close();
      return;
    }

    if (index === this.steps.length) {
      this.close();
      return;
    }

    let step = this.steps[index]!;
    if (step.locked) {
      this.steps[this.current]!.status = StepStatus.Ok;
      step.locked = false;
    }
    
    this.goto(index);
  }
  /** Lock steps after index. (Used when navigating back and chose a new selection) */
  async lockSteps(index:number) {
    for (let i = index; i < this.steps.length; i++) {
      let currentStep = this.steps[i]!;
      currentStep.locked = true;
      currentStep.status = undefined;
    }
  }
}
