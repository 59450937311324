import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { JOB_TYPE_NAME, JobType } from "../../../../../../common/model/job/payload";
import { arrayLast } from "../../../../../../common/toolbox/array";
import { ID_DEFAULT } from "../../../../../../common/toolbox/id";
import { errorResponse } from "../../../../../../common/toolbox/message";
import { getRequest } from "../../../common/toolbox/request";

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
  host: {
    class: 'center dialog-backdrop'
  }
})
export class UnsubscribeComponent {
  readonly JOB_TYPE_NAME = JOB_TYPE_NAME;

  /** True if dialog can be displayed. */
  show = false;
  /** Type of notification unsubscribed from. */
  type?: JobType;

  constructor(
    private http: HttpClient,
    private router: Router,
    route: ActivatedRoute
  ) {
    let _job = decodeURIComponent(arrayLast(route.snapshot.url)?.path ?? ID_DEFAULT);
    getRequest(this.http, 'notifications/unsubscribe', { _job }).then(response => {
      if (errorResponse(response)) {
        this.router.navigate(['error', response.error]);
        return;
      }

      this.type = response.type;
    });
  }
}
